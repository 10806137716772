import Grid from "@mui/material/Grid";
import { DetailsPanel } from "am-web-ui-shared/components";
import { Input, SelectAutoComplete } from "am-web-ui-shared/elements";
import React from "react";
import { withTranslation } from "react-i18next";
import { ValidationConstants } from "../../../../utils/enums/validationConstants";
import { maxLength, regexValidation, required } from "../../../../utils/errorUtils";
import { IServiceFormProps } from "../iServiceFormProps";

class OtherDetailsPanel extends React.PureComponent<IServiceFormProps> {
  state = {
    isCostCurrencyRequired: this.props.cost ? true : false,
  };
  onCostChange = (evant) => {
    this.setState({ isCostCurrencyRequired: !!evant.target.value });
  };

  render() {
    const { currenciesList, t } = this.props;
    const isCostCurrencyRequired = this.state.isCostCurrencyRequired;
    return (
      <DetailsPanel panelTitleId="pnlTemplateDetails" title={t("services:OTHER_DETAILS")}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <Input
              name="cost"
              label={t("services:COST")}
              placeholder={t("services:PLACEHOLDER_COST")}
              isFormControl={true}
              validation={[
                maxLength(t, ValidationConstants.MAX_LENGTH.NUM_64),
                regexValidation(
                  ValidationConstants.NUMBER_WITH_TWO_DECIMAL_PLACES,
                  t("common:VALIDATION_PLEASE_PROVIDE_VALID_COST"),
                ),
              ]}
              onChange={this.onCostChange}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <SelectAutoComplete
              id="costCurrency"
              label={t("services:COST_CURRENCY")}
              autoComplete="on"
              className="form-control"
              isFormControl={true}
              name="currency"
              placeholder={t("common:PLACEHOLDER_TYPE_OR_SELECT")}
              data={currenciesList || []}
              icon="search"
              valueKey="value"
              valueId="code"
              required={isCostCurrencyRequired}
              validation={isCostCurrencyRequired ? [required(t, "services:VALIDATION_MESSAGE_COST_CURRENCY")] : null}
              isDisabled={false}
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <Input
              name="provider"
              label={t("services:PROVIDER")}
              placeholder={t("services:PLACEHOLDER_ENTER_PROVIDER")}
              isFormControl={true}
              validation={[maxLength(t, ValidationConstants.MAX_LENGTH.NUM_255)]}
            />
          </Grid>
        </Grid>
      </DetailsPanel>
    );
  }
}

export default withTranslation()(OtherDetailsPanel);
