const ContactInfoActionConstants = {
  OM_GET_MARKET_LIST: "OM_GET_MARKET_LIST",
  OM_GET_MARKET_LIST_SUCCESS: "OM_GET_MARKET_LIST_SUCCESS",
  OM_GET_PRODUCT_DESCRIPTION: "OM_GET_PRODUCT_DESCRIPTION",
  OM_GET_PRODUCT_DESCRIPTION_SUCCESS: "OM_GET_PRODUCT_DESCRIPTION_SUCCESS",
  OM_GET_SUBPROCESSOR_LIST: "OM_GET_SUBPROCESSOR_LIST",
  OM_GET_SUBPROCESSOR_LIST_SUCCESS: "OM_GET_SUBPROCESSOR_LIST_SUCCESS",
  OM_GET_SYSTEM_REQUIREMENT: "OM_GET_SYSTEM_REQUIREMENT",
  OM_GET_SYSTEM_REQUIREMENT_SUCCESS: "OM_GET_SYSTEM_REQUIREMENT_SUCCESS",
};

export default ContactInfoActionConstants;
