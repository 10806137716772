import Gateway from "../gateway/gateway";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";

export type LockedFeatures = {
  imageUrl: string;
  title: string;
  description: string;
  learnMoreUrl: string;
  contactSalesUrl: string;
  featureKey: string;
};

export const getLockedFeatureInfo = async (featureKey): Promise<LockedFeatures> => {
  const url = getOrgMgmtEndpoint(`/features/learn-more/${featureKey}`);
  return Gateway.get(url, null, null) as unknown as LockedFeatures;
};
