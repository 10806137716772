/**
 * @description - Loader to execute data is at the end
 */
import React from "react";
import styled from "styled-components";
// @ts-ignore
import InfoIcon from "../../infoCard/assets/Info.svg";

export const LoaderWrapper = styled.div`
  text-align: center;
  margin: 10px auto;
`;
export const LoaderText = styled.div`
  color: ${(props) => props.theme.colors.base.steel40};
`;
export const InfoImage = styled.img`
  width: 20px;
  height: 20px;
  opacity: 40%;
`;

interface IInfiniteScrollLoaderEndProps {
  readonly loadEnd: string;
}

export const InfiniteScrollLoaderEnd: React.FC<IInfiniteScrollLoaderEndProps> = (props) => (
  <LoaderWrapper id="infiniteLoadEnd" key="infiniteLoadEnd">
    <InfoImage id="infoImage" src={InfoIcon} alt="infoIcon" />
    <LoaderText id="loaderText">{props.loadEnd}</LoaderText>
  </LoaderWrapper>
);
export default InfiniteScrollLoaderEnd;
