import React from "react";
const SVG = () => (
  <svg width="20px" height="22px" viewBox="0 0 20 22" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2010.000000, -1489.000000)">
        <g transform="translate(2008.000000, 1488.000000)">
          <g id="repeat">
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M7,17 L17,17 L17,12 L20,12 L20,20 L17,20 L7,20 L7,23 L2,18.5 L7,14 L7,17 Z M17,7 L7,7 L7,12 L4,12 L4,7 L4,4 L17,4 L17,1 L22,5.5 L17,10 L17,7 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
