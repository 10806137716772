// @ts-ignore
import Grid from "@mui/material/Grid";
import React from "react";
import ExpandableRowTemplate from "../gridList/expandableRowTemplate";
import Header from "../gridList/header";
import RowTemplate from "../gridList/rowTemplate";
import CheckboxCombinationCell from "./cells/checkboxCombinationCell";
import GridCombinationalCell from "./cells/gridCombinationalCell";
import GridCustomCell from "./cells/gridCustomCell";
import GridDropdownCell from "./cells/gridDropdownCell";
import GridLinkCell from "./cells/gridLinkCell";
import GridTextCell from "./cells/gridTextCell";
import ImageFieldCombination from "./cells/imageFieldCombinationCell";
import QuickActionsCell from "./cells/quickActionsCell";
import RadioCombinationCell from "./cells/radioCombinationCell";
import { CellTypes } from "./gridEnum";
import { dependencyResolver, getCellClassName } from "./gridUtils";
import IGridColumnSetting from "./iGridSetting";
import { IGridView } from "./iGridView";
import { RowTemplateType } from "./rowTemplateEnum";
import RowTemplateLink from "../gridList/rowTemplateLink";
import { HelperLodash } from "../../helpers";
import LazyLoad from "react-lazyload";

export const getRowTemplate = (props) => {
  const {
    templateProps: { lazyLoadList = true, listScrollRef, isWindowScroll },
  } = props;
  return lazyLoadList ? (
    <LazyLoad
      overflow={!isWindowScroll}
      height={50}
      offset={300}
      {...(!isWindowScroll ? { scrollContainer: listScrollRef } : {})}
    >
      <RenderRowTemplate {...props} />
    </LazyLoad>
  ) : (
    <RenderRowTemplate {...props} />
  );
};

export const RenderRowTemplate = (props) => {
  const { indeterminate, data, templateProps, rowIndex } = props;
  const {
    handleRowClick,
    handleCheckboxClick,
    idKey,
    disableCheckBoxColumn,
    columns,
    rowAttributes,
    notActionable,
    hideRowArrow,
    dependencies,
    rowSettings,
    noHoverBackground,
  } = templateProps;
  return (
    <RowTemplate
      key={rowIndex}
      model={data}
      selected={data.isSelected}
      isChecked={!!(data.metaData && data.metaData.isChecked)}
      rowClick={handleRowClick}
      checkBoxClick={handleCheckboxClick}
      id={data[idKey || "id"]}
      disableCheckBoxColumn={disableCheckBoxColumn}
      notActionable={notActionable}
      rowAttributes={rowAttributes}
      rowDisabled={data.isDisabled}
      dependencies={dependencies}
      rowSettings={rowSettings}
      indeterminate={indeterminate || !!(data.metaData && data.metaData.indeterminate)}
      hideRowArrow={hideRowArrow}
      noHoverBackground={noHoverBackground}
    >
      {columns &&
        columns.map((column, columnIndex) => {
          return (
            <Grid
              item={true}
              xs={column.cellSize}
              key={`grid_${rowIndex}_${columnIndex}`}
              className={getCellClassName(column)}
            >
              <RenderCells
                column={column}
                data={data}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                templateProps={templateProps}
              />
            </Grid>
          );
        })}
    </RowTemplate>
  );
};
const rowComponents = {
  default: getRowTemplate,
  expandable: ExpandableRowTemplate,
  link: RowTemplateLink,
};

export const getRowTemplateType = (props, data, rowSettings) => {
  if (rowSettings && rowSettings.customInfo.templateDependency) {
    const rowTemplateType = dependencyResolver(props.dependencies, rowSettings.customInfo.templateDependency);
    return rowTemplateType(data, rowSettings);
  }

  if (rowSettings && rowSettings.templateMode) {
    return rowSettings.templateMode;
  }
  return RowTemplateType.Default;
};

export const GridView = (props: IGridView) => {
  const { columns, dependencies, disableCheckBoxColumn, displayHeader, rowSettings, rowsData } = props;
  return (
    <React.Fragment>
      {displayHeader && <Header dependencies={dependencies} columns={columns} />}
      {rowsData.map((data, rowIndex) => {
        const componentType = getRowTemplateType(props, data, rowSettings);
        const RenderComponent = HelperLodash.get(rowComponents, componentType);
        return (
          <RenderComponent
            renderRowTemplate={getRowTemplate}
            data={data}
            disableCheckBoxColumn={disableCheckBoxColumn}
            templateProps={props}
            rowIndex={rowIndex}
            key={`grid_${data[props.idKey]}${rowIndex}`}
          />
        );
      })}
    </React.Fragment>
  );
};

export const RenderLinkCell = ({ column, data, rowIndex, columnIndex, templateProps }: RenderCellType) => {
  return column.type === CellTypes.Link ? (
    <GridLinkCell
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      column={column}
      data={data}
      dependencies={templateProps.dependencies}
      showTooltip={templateProps.showTooltip}
    />
  ) : null;
};

type RenderCellType = {
  column: IGridColumnSetting;
  data: any;
  rowIndex: number;
  columnIndex: number;
  templateProps: IGridView;
};

export const RenderCheckbox = (type, rowIndex, columnIndex, column, data, templateProps) => {
  return type === CellTypes.CheckBox ? (
    <CheckboxCombinationCell
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      column={column}
      data={data}
      dependencies={templateProps.dependencies}
    />
  ) : null;
};
export const RenderCells = ({ column, data, rowIndex, columnIndex, templateProps }: RenderCellType) => (
  <>
    {column.type === CellTypes.ImageFieldCombination && (
      <ImageFieldCombination
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        data={data}
        column={column}
        dependencies={templateProps.dependencies}
        showTooltip={templateProps.showTooltip}
        showValueTooltip={templateProps.showValueTooltip}
      />
    )}
    {column.type === CellTypes.Custom && (
      <GridCustomCell
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        data={data}
        column={column}
        dependencies={templateProps.dependencies}
      />
    )}
    {column.type === CellTypes.FieldCombination && (
      <GridCombinationalCell
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        column={column}
        data={data}
        dependencies={templateProps.dependencies}
        showTooltip={templateProps.showTooltip}
        showValueTooltip={templateProps.showValueTooltip}
      />
    )}
    {column.type === CellTypes.Text && (
      <GridTextCell
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        column={column}
        data={data}
        showTooltip={templateProps.showTooltip}
        showValueTooltip={templateProps.showValueTooltip}
      />
    )}
    {column.type === CellTypes.GridDropdown && (
      <GridDropdownCell rowIndex={rowIndex} columnIndex={columnIndex} column={column} data={data} {...templateProps} />
    )}
    {column.type === CellTypes.GridDatePicker && <column.component column={column} data={data} {...templateProps} />}
    {column.type === CellTypes.QuickActions && (
      <QuickActionsCell
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        column={column}
        data={data}
        dependencies={templateProps.dependencies}
        getQuickActionPopupPermission={templateProps.getQuickActionPopupPermission}
        quickActionPopupPermissionList={templateProps.quickActionPopupPermissionList}
      />
    )}
    {column.type === CellTypes.RadioCombination && (
      <div className="radio-container">
        <RadioCombinationCell
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          column={column}
          data={data}
          dependencies={templateProps.dependencies}
        />
      </div>
    )}
    {RenderCheckbox(column.type, rowIndex, columnIndex, column, data, templateProps)}

    <RenderLinkCell
      column={column}
      data={data}
      rowIndex={rowIndex}
      columnIndex={rowIndex}
      templateProps={templateProps}
    />
  </>
);
export default GridView;
