export default class ComboBoxUtils {
  static getInfoMessage = (
    loadedRecordsCount: number,
    totalRecords: number,
    t: any,
    infoMessage = "common:INFO_MESSAGE",
  ): string => {
    return totalRecords > loadedRecordsCount
      ? t(infoMessage, {
          defaultLimit: loadedRecordsCount,
          totalRecords,
        })
      : null;
  };

  static formatResponse = (data, t?) => {
    return {
      infoText: ComboBoxUtils.getInfoMessage((data.response || []).length, data.totalRecords, t),
      ...ComboBoxUtils.formatNoInfoResponse(data),
    };
  };

  static formatNoInfoResponse = (data) => {
    return {
      limit: data.limit || 0,
      offset: data.offset || 0,
      response: data.response || [],
      totalRecords: data.totalRecords || 0,
    };
  };
}
