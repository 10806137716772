import React from "react";
import { DynamicModuleLoader } from "redux-dynamic-modules";
import transferCartModule from "../../../../transferCart/redux/transferCartModule";
import activeTrackingModule from "../../../redux/activeTracking/activeTrackingModule";
import SmartInventoryAssetsContainer from "./smartInventoryAssetsContainer";

const SmartInventoryAssetsContainerWrapper = (props) => {
  return (
    <DynamicModuleLoader modules={[activeTrackingModule, transferCartModule]}>
      <SmartInventoryAssetsContainer {...props} />
    </DynamicModuleLoader>
  );
};

export default SmartInventoryAssetsContainerWrapper;
