import { IconName } from "am-web-ui-shared/components";
import { LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconBoldIcon from "../../../components/DetailsPanel/SingleRow/IconBoldIcon/iconBoldIcon";
import IQtyItemsLinkProps from "./iQtyItemsLinkProps";
import { connect } from "react-redux";
import { toggleAlertList } from "../../../modules/alert/actions/alertNotificationAction";
import SingletonFeatureManager from "../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../featureFlagManager/featureConfig";
import LocationCount from "../../../modules/location/locationCount";
import { useTranslation } from "react-i18next";

const StyledWrapper = styled("div")`
  padding: 12px 16px 12px 16px;
  border-top: 1px solid ${(props) => props.theme.locationDetail.borderColor.color};
  cursor: default;
`;

export const QtyItemsLink = (props: IQtyItemsLinkProps) => {
  const { qtyItemsCount, showAlertListPopup, getItemCounts, data } = props;
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (qtyItemsCount > 0 && showLoader && SingletonFeatureManager.isFeatureEnabled(FEATURES.LOCATION_COUNT)) {
      setShowLoader(false);
    }
  }, [qtyItemsCount, showLoader]);

  const onRedirectClick = () => {
    if (showAlertListPopup) {
      props.toggleAlertList();
    }
  };

  const handleLoader = (e: Event) => {
    e.preventDefault();
    setShowLoader(true);
    getItemCounts({ locationDetail: data });
  };

  return qtyItemsCount > 0 ? (
    <StyledWrapper>
      <Link to={props.redirectionLink()} onClick={onRedirectClick}>
        <IconBoldIcon
          id="assetIcon"
          boldId="assetsId"
          boldText={`${t("companies:QTY_ITEMS")}: `}
          iconUrl={IconName.Comodity}
          postIconUrl={IconName.Chevron}
          countText={LocaleManager.numberHelpers.formatNumber(qtyItemsCount)}
          iconClass="polygon"
          type={"innerText"}
        />
      </Link>
    </StyledWrapper>
  ) : (
    <StyledWrapper>
      <IconBoldIcon
        id="assetIcon"
        boldId="assetsId"
        boldText={`${t("companies:QTY_ITEMS")}: `}
        iconUrl={IconName.Comodity}
        postIconUrl={IconName.Chevron}
        countText={<LocationCount count={qtyItemsCount} handleLoader={handleLoader} />}
        iconClass="polygon"
        type={"innerText"}
      />
    </StyledWrapper>
  );
};

const mapStateToProps = (state) => ({
  showAlertListPopup: state.HeaderCount?.showAlertListPopup,
});

export default connect(mapStateToProps, { toggleAlertList })(QtyItemsLink);
