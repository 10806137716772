const StyleAttrConstants = {
  BORDER: {
    B0: 0,
  },
  FONT_WEIGHT: {
    W300: 300,
    W400: 400,
  },
  HEIGHT: {
    H32: 32,
    H38: 38,
    H64: 64,
    H72: 72,
    H96: 96,
  },
  GRIDSIZE: {
    S3: 3,
    S4: 4,
  },
  LEFT: {
    L176: 176,
    L320: 320,
    L350: 350,
    LN176: -176,
  },
  OPACITY: {
    OP3: 0.3,
    OP5: 0.5,
  },
  POSITION: {
    BOTTOM: "bottom",
    TOP: "top",
    RIGHT: "right",
  },
  RANDOM: {
    R100: 100,
  },
  TOP: {
    T4: 4,
    T10: 10,
    T112: 112,
    T12: 12,
    T192: 192,
    T40: 40,
    T48: 48,
    T60: 60,
    T71: 71,
    T72: 72,
  },
  WIDTH: {
    W100: 100,
    W32: 32,
    W38: 38,
    W40: 40,
    W44: 44,
    W60: 60,
    W9: 9,
    W315: 315,
    W992: 992,
    W512: 512,
  },
  ZOOM: {
    Z2: 2,
  },
};
export default StyleAttrConstants;
