import { Dialog, IconName } from "am-web-ui-shared/components";
import Tooltip from "@hilti/components/core/tooltip";
import { Button } from "am-web-ui-shared/elements";
import { CheckAuthorization, LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import { TParam } from "../../../../localization/types";
import { deepCloneArray } from "../../../utils/commonUtils";
import { ActionItem } from "../../../utils/enums/actionItemEnum";
import { StatusCode } from "../../../utils/enums/assetEnum";
import DiscardDialog from "../../discardDialog/discardDialog";
import { INoServiceProps } from "../model/iNoServiceProps";
import AssignServiceForm from "../serviceFormController/assignServiceForm";
import ServiceForm from "../serviceFormController/serviceForm";
import { ServiceResultEnum, ServiceStatus } from "../serviceFormController/serviceFormEnum";
import { isServiceResultSettingEnabled } from "../../../../common/utils/configUtil";
import { AssetServiceCategory } from "../../../utils/enums/serviceEnum";
import withLicenseContext from "TARGET_BUILD/licenseStrategy/withLicenseContext";
const BtnWrapper = styled.div`
  button {
    width: 100%;
  }
`;
// class Component
class AssignServiceComponent extends React.PureComponent<INoServiceProps> {
  state = {
    isFormChange: false,
    isRecurrentDateChange: false,
    isRecurrentCompleationChange: false,
    showDiscardDialog: false,
    showModal: !!this.props.showModal,
    serviceResult: {},
  };

  componentWillUnmount() {
    this.props.clearServiceTemplateDetails();
  }

  componentDidMount() {
    this.props.assignedServiceDetail?.serviceResult
      ? this.setState({
          serviceResult: this.props.assignedServiceDetail?.serviceResult,
        })
      : this.setState({
          serviceResult: {
            code: ServiceResultEnum.PASSED.toUpperCase(),
            value: ServiceResultEnum.PASSED,
          },
        });
  }

  openAssignServiceDialog = () => {
    this.setState({ showModal: true });
    if (this.props.setServiceActionType) {
      this.props.setServiceActionType(ActionItem.assign);
    }
  };
  closeAddAssetDialog = () => {
    if (this.props.handleFormClose) {
      this.props.handleFormClose();
    }
    this.setState({ isFormChange: false, showModal: false });
  };
  setFormChange = (isFormChange) => {
    this.setState({ isFormChange });
  };
  openConfirmChange = () => {
    this.setState({ showDiscardDialog: true });
  };
  confirmFormClose = () => {
    this.setState({ showDiscardDialog: false });
    this.closeAddAssetDialog();
  };
  cancelFormClose = () => {
    this.setState({ showDiscardDialog: false });
  };
  checkStatus = (status) => {
    return status === StatusCode.Retired || status === StatusCode.Disposed || status === StatusCode.Lost;
  };
  assetStatusResponseValue = (assetFieldsData) => {
    return (
      assetFieldsData &&
      ((assetFieldsData.statusCode && this.checkStatus(assetFieldsData.statusCode)) ||
        (assetFieldsData.assetStatusResponse && this.checkStatus(assetFieldsData.assetStatusResponse.code)))
    );
  };
  isButtonDisabled = () => {
    const { assetFieldsData } = this.props;
    if (this.assetStatusResponseValue(assetFieldsData)) {
      return "disabled";
    }
    return "cta";
  };

  renderHeadertext = (itemActionType) => {
    const { t } = this.props;
    return itemActionType
      ? t(`services:${itemActionType.toUpperCase()}_SERVICE` as TParam)
      : t("services:ASSIGN_SERVICE");
  };

  serviceResultValue = (serviceResult) => {
    if (serviceResult) {
      this.setState({
        serviceResult: serviceResult.item ? serviceResult.item : serviceResult,
      });
    }
  };

  renderAddButton = () => {
    const { assetFieldsData, t } = this.props;
    return (
      <Tooltip
        id="servicebtnContainerForm"
        title={this.assetStatusResponseValue(assetFieldsData) && t("services:ASSIGN_DISABLE")}
        placement="top"
      >
        <BtnWrapper>
          <Button
            iconName={IconName.Add}
            id="servicebtnContainer"
            buttonType={this.isButtonDisabled()}
            onClick={this.openAssignServiceDialog}
          >
            {t("services:ASSIGN_SERVICE")}
          </Button>
        </BtnWrapper>
      </Tooltip>
    );
  };

  handleRequestPayload = (serviceInfo) => {
    const { assignedServiceDetail, serviceStatus } = this.props;
    const { completionDate, completionAt, scheduleDate, provider, notes, cost, currency } = serviceInfo;
    const IntCompleationAt = parseInt(completionAt, 10);
    const selectedService = deepCloneArray(assignedServiceDetail);
    const validScheduleDate = scheduleDate ? LocaleManager.dateHelpers.getDateInAPIFormat(scheduleDate) : null;
    const validCompletionDate = completionDate ? LocaleManager.dateHelpers.getDateInAPIFormat(completionDate) : null;
    let payload = {
      ...selectedService,
      completionDate: [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(
        selectedService.basedOn,
      )
        ? null
        : validCompletionDate,
      completionAt: IntCompleationAt,
      cost,
      costCurrency: currency,
      notes,
      provider,
      scheduleDate: [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(
        selectedService.basedOn,
      )
        ? null
        : validScheduleDate,
      serviceAttachments: serviceInfo.serviceAttachments,
      serviceStatus,
    };
    if (isServiceResultSettingEnabled()) {
      payload = { ...payload, serviceResult: this.state.serviceResult };
    } else {
      delete payload.serviceResult;
    }
    return payload;
  };

  handleCompletionDateChange = () => {
    this.setState({
      isRecurrentDateChange: true,
    });
  };
  /**
   * To handle edit of service for open and history
   */
  handleCompletionDataChange = (dataFlag) => {
    this.setState({
      isRecurrentDateChange: dataFlag,
    });
  };

  /**
   * To handle edit of service for open and history
   */
  handleEditService = (serviceInfo) => {
    const { assignedServiceDetail, assetId, serviceStatus } = this.props;
    const { scheduleDate } = serviceInfo;
    const requestPayload = this.handleRequestPayload(serviceInfo);
    if (serviceStatus === ServiceStatus.OPEN) {
      const isServiceOfHeavyEquipment = [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(
        serviceInfo?.basedOn,
      );
      const serviceData = {
        isLifeLong: serviceInfo.isLifeLong,
        scheduleDate:
          serviceInfo.isLifeLong || isServiceOfHeavyEquipment
            ? ""
            : LocaleManager.dateHelpers.getDateInAPIFormat(scheduleDate),
        serviceAttachments: serviceInfo.serviceAttachments,
        serviceId: this.props.assignedServiceDetail.id,
        scheduleAt: serviceInfo?.scheduleAt,
      };
      this.props.editAssignedService(assetId, serviceData, assignedServiceDetail.id, serviceStatus);
    } else {
      this.props.editAssignedService(
        assetId,
        requestPayload,
        assignedServiceDetail.id,
        serviceStatus,
        this.state.isRecurrentDateChange,
      );
    }
  };

  /**
   * To handle completion of service
   */

  handleCompleteService = (serviceInfo) => {
    const { assetId } = this.props;
    const requestPayload = this.handleRequestPayload(serviceInfo);
    this.props.completeAssignedService(assetId, requestPayload);
  };

  getAssignServiceForm = () => {
    const {
      actionType,
      assetId,
      assignHistoryService,
      serviceOperationError,
      assignServiceSuccess,
      assignedServiceDetail,
      assignedServicesList,
      clearAssignedServiceList,
      getServiceTemplateDetails,
      onFormchange,
      clearServiceData,
      currenciesList,
      editAssignedService,
      getAssignServicesList,
      getServicesList,
      handleServiceList,
      isServiceStatus,
      moduleName,
      scheduleDate,
      completionDate,
      costCurrency,
      serviceActionType,
      serviceList,
      serviceStatus,
      serviceValue,
      setServiceFilterQuery,
      showToaster,
      submitForm,
      getCurrenciesList,
      defaultCurrency,
      distanceUnitValue,
    } = this.props;
    return (
      <AssignServiceForm
        actionType={actionType}
        getCurrenciesList={getCurrenciesList}
        currenciesList={currenciesList}
        closeAddAssetDialog={this.closeAddAssetDialog}
        editAssignedService={editAssignedService}
        getServicesList={getServicesList}
        assignHistoryService={assignHistoryService}
        assignServiceSuccess={assignServiceSuccess}
        assetId={assetId}
        scheduleDate={scheduleDate}
        serviceOperationError={serviceOperationError}
        completionDate={completionDate}
        serviceStatus={serviceStatus}
        onFormchange={onFormchange}
        costCurrency={costCurrency}
        assignedServicesList={assignedServicesList}
        getAssignServicesList={getAssignServicesList}
        handleServiceList={handleServiceList}
        clearServiceData={clearServiceData}
        assignedServiceDetail={assignedServiceDetail}
        serviceActionType={serviceActionType}
        submitForm={submitForm}
        serviceList={serviceList}
        serviceValue={serviceValue}
        showToaster={showToaster}
        isServiceStatus={isServiceStatus}
        moduleName={moduleName}
        setServiceFilterQuery={setServiceFilterQuery}
        defaultCurrency={defaultCurrency}
        clearAssignedServiceList={clearAssignedServiceList}
        getServiceTemplateDetails={getServiceTemplateDetails}
        serviceTemplateDetails={this.props.serviceTemplateDetails}
        allAssignedService={this.props.allAssignedService}
        serviceResultValue={this.serviceResultValue}
        distanceUnitValue={distanceUnitValue}
      />
    );
  };

  getEditCompleteServiceForm = () => {
    const {
      getCurrenciesList,
      currenciesList,
      clearServiceData,
      serviceValue,
      isServiceStatus,
      assetId,
      onFormchange,
      assignedServiceDetail,
      serviceActionType,
      scheduleDate,
      serviceStatus,
      serviceOperationError,
      completionDate,
      costCurrency,
      showToaster,
      submitForm,
      defaultCurrency,
    } = this.props;
    return (
      <ServiceForm
        getCurrenciesList={getCurrenciesList}
        currenciesList={currenciesList}
        assetId={assetId}
        serviceValue={serviceValue}
        isServiceStatus={isServiceStatus}
        serviceOperationError={serviceOperationError}
        assignedServiceDetail={assignedServiceDetail}
        serviceStatus={serviceStatus}
        serviceResult={this.state.serviceResult}
        scheduleDate={scheduleDate}
        serviceActionType={serviceActionType}
        completionDate={completionDate}
        costCurrency={costCurrency}
        clearServiceData={clearServiceData}
        defaultCurrency={defaultCurrency}
        showToaster={showToaster}
        onFormchange={onFormchange}
        submitForm={submitForm}
        handleCompletionDateChange={this.handleCompletionDateChange}
        handleCompletionDataChange={this.handleCompletionDataChange}
        handleServiceFormSubmit={
          serviceActionType === ActionItem.edit ? this.handleEditService : this.handleCompleteService
        }
        serviceResultValue={this.serviceResultValue}
        distanceUnitValue={this.props.distanceUnitValue}
      />
    );
  };

  getServiceFormWrapper = () => {
    const { serviceActionType, t, showModal, licenseContext } = this.props;
    return (
      <React.Fragment>
        {!showModal && licenseContext?.isAssignServiceEnabled() && this.renderAddButton()}
        {this.state.showModal && (
          <Dialog
            containerId="assignServiceContainer"
            headerId="assignServiceHeader"
            onClose={!this.state.isFormChange ? this.closeAddAssetDialog : this.openConfirmChange}
            headerText={this.renderHeadertext(serviceActionType)}
          >
            {serviceActionType && (serviceActionType === ActionItem.edit || serviceActionType === ActionItem.complete)
              ? this.getEditCompleteServiceForm()
              : this.getAssignServiceForm()}
          </Dialog>
        )}
        {this.state.showDiscardDialog && (
          <DiscardDialog handleClose={this.cancelFormClose} handleConfirm={this.confirmFormClose} t={t} />
        )}
      </React.Fragment>
    );
  };
  render() {
    return <CheckAuthorization yes={this.getServiceFormWrapper} permissionKey="service.manage" />;
  }
}

export default withLicenseContext(withTranslation()(AssignServiceComponent));
