import { call as sagaCall, put, select, takeEvery, takeLatest, delay, all } from "redux-saga/effects";
// @ts-ignore
import { API_DELAY_TIME, ASSET_COLUMN_ATTRIBUTES, MAX_QUERY_STRING_SIZE } from "TARGET_BUILD/config";
import { showMultipleToastr, showToastr, ToastrType } from "../../../../actions/toastrAction";

import {
  cancleLastTransfer,
  createAssetCustomView,
  createUniqueAsset,
  deleteAsset,
  deleteCustomView,
  fetchChildAsset,
  getAssetCustomViewFields,
  getAssetDetails,
  getAssetFilterAttributes,
  getAssetsList,
  getAssetUtilizationSummary,
  getAssetUtilizationDetails,
  getCommonAttributes,
  renameCustomViewApi,
  setDefaultCustomViewApi,
  updateAssetCustomViewApi,
  updateCustomGridFieldsApi,
  updateUniqueAsset,
  updateUniqueAssetStatus,
} from "../../../../api/assetApi";
import { getAssetSystemView, reorderCustomViewApi, resetAssetOriginalViewApi } from "../../../../api/customViewsApi";
import { call } from "../../../../sagas/common/commonSaga";
import {
  getChildAssetQuery,
  getFilterQueryObj,
  getQueryObj,
  getQueryObjForMultiPayload,
} from "../../../../sagas/stateSelector";
import { SharedUtils, HelperLodash } from "am-web-ui-shared/helpers";

import {
  AssetActions,
  AssetServiceActions,
  ChargesActions,
  LoaderAction,
  ManufactureActions,
  ToastrActions,
  // @ts-ignore
} from "custom-actions";
import { getTransferHistoryList } from "../../../../api/transferHistoryApi";
import AssetList from "../../../../models/asset/assetList";
import {
  canUserEditChargeSettings,
  getMethod,
  isChargeSettingLoaded,
  isNewTemplateCreated,
  shouldProcessChargeSetting,
} from "../../../../models/charges/chargesOrchestration";
import { HILTI_SAP_FIELDS } from "../../../../modules/asset/components/hint/hintHelpers";
import { includeLockerSubType, mergeAssetUsageStateAndDate } from "../../../../utils/commonUtils";
import { prepareAddCustomViewData } from "../../../../utils/customViewUtils";
import { AssetKeys, StatusCode } from "../../../../utils/enums/assetEnum";
import { ModuleName } from "../../../../utils/enums/moduleNameEnum";
import { errorDataGenerator, validateResponse } from "../../../../utils/errorUtils";
import {
  getMultiSelectDeletePayloadAttributes,
  getMultiSelectPayloadAttributes,
} from "../../../../utils/list/listUtils";
import { getTransferModelList } from "../../../../utils/transferUtil";
import GTMConstants from "../../../../gtmConstants";
import { clearLastSeen, getLastSeen, initLastSeen } from "../../../assetTracking/actions/activeTrackingActionRoot";
import { shouldFetchLastSeen } from "../../../../../common/utils/activeTrackingUtils";
import {
  GTMEventsForAppliedFilter,
  GTMEventsForFilterSearch,
  GTMEventsForShowMoreFilter,
  entityName,
} from "../../../../utils/GTMUtils";
import { getSelectedCustomView } from "TARGET_BUILD/common/modules/asset/reduxModule/assetSelectors";
import { trackAssetsCombinedFilters } from "TARGET_BUILD/analytics/events/asset";
import asset from "TARGET_BUILD/common/modules/asset";
import { LostAndFound } from "TARGET_BUILD/common/utils/enums/alertGroupEnum";
import { t } from "i18next";

export function* assetsListSaga() {
  yield takeLatest(AssetActions.GET_ASSET_LIST, fetchAssetsList);
}

/**
 * @description method takes latest action and callback of delayedAssetsList
 */
export function* searchAssetsListSaga() {
  yield takeLatest(AssetActions.GET_ASSET_LIST_FOR_SEARCH, fetchDelayedAssetsList);
}

/**
 * @description method delay call and call to fetch assetslist after delay promise is resolved.
 * Blocks the fetchAssetsList till delay promise is resolved. If user types the search quickly
 * it will not allow to many calls.
 */
export function* fetchDelayedAssetsList(action) {
  yield fetchAssetsList(action);
}

/**
 * @description: Iterates the list array and gets the model data with the given input value
 * @param: dataObj of the list.
 */
export const getAssetModelList = (dataObj, skipHierarchy?) => {
  const assetList = [];
  const dataList = dataObj.response;
  (dataList || []).forEach((data) => {
    assetList.push(new AssetList(data, skipHierarchy));
  });
  return {
    ...dataObj,
    response: assetList,
  };
};

const isSearchOrFilterApplied = (queryObj) => {
  return queryObj && (queryObj.q || queryObj.filter);
};

function* fetchAssetsList(action) {
  const isLoader = action.offset === 0;
  if (isLoader) {
    yield put({ type: LoaderAction.SET_LIST_LOADER });
  }

  /*
    offset : 0 -> Load with initial state, show grid loader only for initial state.
    offset : (>0) -> Load list for pagination, paginated loader is shown by the infitescroll
    component at the bottom of the page
  */

  const queryObj = yield select(getQueryObj, action.offset, "Assets");
  if (queryObj?.filter?.length > MAX_QUERY_STRING_SIZE) {
    yield put({ type: LoaderAction.UNSET_LIST_LOADER });
    yield delay(API_DELAY_TIME);
    yield put(showToastr(ToastrType.error, `assets:ERROR_ASSET_REDUCE_FILTERS_IN_SEARCH`));
  } else {
    const appliedFilter = yield select((state) => state?.Assets?.appliedFilterQuery);
    let assetsData = yield call(() => {
      return getAssetsList(HelperLodash.clone(includeLockerSubType(queryObj)), false);
    });
    if (assetsData && assetsData.error) {
      assetsData = { response: [] };
    }

    const assetIds = HelperLodash.getPathValue(assetsData?.response, "assetId");
    // offset is required to decide whether to update the list or replace with new one.
    const payloadObj = {
      assetsData: getAssetModelList(assetsData, isSearchOrFilterApplied(queryObj)),
      id: action.id,
      offset: action.offset,
      selectFirstRow:
        queryObj?.filter?.includes("assetId") && appliedFilter.some((item) => item.filterName === "chargerName")
          ? true
          : action.isSelectFirstRow,
    };
    yield put({ type: AssetActions.PUT_ASSET_LIST, payload: payloadObj });
    if (isLoader) {
      yield put({ type: LoaderAction.UNSET_LIST_LOADER });
    }

    if (!action.offset) {
      yield put({ type: AssetActions.AM_ASSET_LIST_LOADER_END });
    }

    const resultCount = assetsData?.totalRecords;
    if (queryObj?.q && resultCount) {
      SharedUtils.pushDataToGA(GTMConstants.EVENTS.ADD_ASSET_SEARCH_RESULT_LOADED, null, { dataValue: resultCount });
    }

    if (appliedFilter?.length) {
      GTMEventsForAppliedFilter(appliedFilter[appliedFilter?.length - 1], resultCount, entityName.ASSET);
    }
    if (appliedFilter?.length > 1) {
      yield trackAssetsCombinedFilters(appliedFilter);
    }
    //get asset's last seen
    if (shouldFetchLastSeen(queryObj?.fields, assetIds)) {
      const payloadLastSeen = {
        assetIds,
        assetsData: payloadObj.assetsData,
        offset: action.offset,
        resetScrollPosition: action.resetScrollPosition,
        selectFirstRow: !!action.order_by,
      };
      if (queryObj.offset === 0) {
        yield put(clearLastSeen());
      }
      yield put(initLastSeen(assetIds));
      yield put(getLastSeen(payloadLastSeen));
    }
  }
}

export function* assetDetailsSaga() {
  yield takeLatest(AssetActions.AM_ASSET_DETAILS, fetchAssetDetails);
}

function* fetchAssetDetails(action) {
  if (!action.disableLoader) {
    yield put({ type: LoaderAction.SET_DETAIL_LOADER });
  }

  // Updating the new hilti assets badge number after each change on the new asset.
  const queryObj = yield select(getQueryObj, action.offset, "Assets");
  if (queryObj?.fields?.length) {
    const selectedView = yield select((state) => {
      return getSelectedCustomView(state?.Assets);
    });
    yield put({ type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW, selectedView });
  }
  const assetDetails = yield call(() => {
    return getAssetDetails(action.assetId, false);
  });

  yield put({
    payload: assetDetails,
    type: AssetActions.SET_ASSET_DETAILS_WITH_ID,
  });
  yield put({ type: LoaderAction.UNSET_DETAIL_LOADER });
}

export function* fetchAfterDeleteAsset() {
  yield takeLatest("DELETE_ASSETS", deleteAssetItem);
}

export function* refreshAssetsListSaga() {
  yield takeLatest(AssetActions.REFRESH_ASSETS, fetchAssets);
}

function* fetchAssets(action) {
  const assetDetails = yield call(() => {
    return getAssetDetails(action.assetId, false);
  });
  if (action.isRepeat) {
    yield put({
      payload: assetDetails,
      type: AssetActions.SET_ASSET_DETAILS,
    });
  } else {
    yield put({
      payload: assetDetails,
      type: AssetActions.SET_ASSET_DETAILS_WITH_ID,
    });
  }
  yield put({
    payload: new AssetList(assetDetails),
    type: AssetActions.REFRESH_ASSETS_LIST,
  });
}

function* deleteAssetItem(action) {
  const multiSelectQueryFields = yield select(getQueryObjForMultiPayload, "Assets");
  const multiSelectPayloadFields = getMultiSelectDeletePayloadAttributes(
    action.assetIds,
    multiSelectQueryFields.isSelectAll,
    AssetKeys,
  );
  const multiRowPayload = {
    ...multiSelectQueryFields,
    ...multiSelectPayloadFields,
  };
  const response = yield call(() => {
    return deleteAsset(multiRowPayload);
  });
  if (!response.error) {
    yield put({
      payload: {
        response,
        selectedAssetList: multiSelectPayloadFields[AssetKeys.primaryRowIds],
      },
      type: AssetActions.SET_ASSET_DELETE_RESPONSE,
    });
    if (response.invalidResponse && response.invalidResponse.length) {
      const errorObj = errorDataGenerator(action.assetIds.count, response, action.assetIds.checkedRows);
      if (errorObj.isSingleFail) {
        const { errorCode, message: errorMsg } = response.invalidResponse[0];
        yield put(showToastr(ToastrType.error, `errorCodes:${errorCode}`));

        SharedUtils.pushDataToGA(GTMConstants.EVENTS.DELETE_ASSET_FAILED, {
          errorCode,
          errorMsg,
        });
      } else {
        yield put(showToastr(ToastrType.error, "assets:MULTIPLE_DELETION_ERROR"));
      }
    } else {
      yield put({
        payload: {
          toastrType: ToastrType.success,
          toastrMessage: "common:DELETED_SUCCESSFULLY",
          translate: true,
          gtmEvent: GTMConstants.EVENTS.DELETE_ASSET_SUCCESS,
        },
        type: ToastrActions.SHOW_TOASTR,
      });
    }
    yield delay(API_DELAY_TIME); //Added delay(1000) to allow delete message processing from search MS.
    yield put({ offset: 0, type: AssetActions.GET_ASSET_LIST });
    yield put({ type: ManufactureActions.RESET_MANUFACTURE_DELETE_RESPONSE });
  } else {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(
        showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`, null, GTMConstants.EVENTS.DELETE_ASSET_FAILED),
      );
    }
  }
}
/**
 * use to update status code and refresh the list with offset 0
 */
export function* updateUniqueAssetStatusSaga() {
  yield takeEvery(AssetActions.AM_UPDATE_ASSET_STATUS, updateAssetStatus);
}

export function* updateAssetStatus(action) {
  const multiSelectQueryFields = yield select(getQueryObjForMultiPayload, "Assets");
  const multiSelectPayloadFields = getMultiSelectPayloadAttributes(
    action.payload.checkedListInfo,
    multiSelectQueryFields.isSelectAll,
    AssetKeys,
  );
  const multiRowPayload = {
    ...multiSelectQueryFields,
    ...multiSelectPayloadFields,
  };
  const response = yield call(updateUniqueAssetStatus, {
    ...multiRowPayload,
    assetStatus: action.payload.assetStatus,
  });
  if (!response.error) {
    yield put({ type: AssetActions.AM_ASSETS_SET_SELECTED_ROW, rowId: null });
    yield delay(API_DELAY_TIME);
    yield put({ type: AssetActions.AM_GET_ASSET_FILTER_FIELD });
    yield put({ type: AssetActions.GET_ASSET_LIST, offset: 0 });
    yield put({ type: AssetActions.CLEAR_ASSET_DETAILS });
    yield put({ type: AssetActions.UPDATE_STATUS_SUCCESS });
    if (response.invalidResponse && response.invalidResponse.length) {
      const errorObj = errorDataGenerator(
        action.payload.checkedListInfo.count,
        response,
        action.payload.checkedListInfo?.checkedRows,
      );
      if (errorObj.isSingleFail) {
        yield put(showToastr(ToastrType.error, `errorCodes:${response.invalidResponse[0].errorCode}`));
      } else {
        yield put(
          showToastr(ToastrType.error, "common:PARTIAL_STATUS_CHANGE", {
            ...errorObj,
          }),
        );
      }
    } else {
      yield put({ type: ToastrActions.SHOW_TOASTR, payload: action.payload });
    }
  } else {
    yield put({ type: AssetActions.UPDATE_STATUS_ERROR });
  }
}

/**
 * use to update status code and refresh the list with offset 0
 */
export function* addEditUniqueAssetSaga() {
  yield takeEvery(AssetActions.AM_ASSET_ADD_EDIT, addEditUniqueAsset);
}
const chargesMethod = (action) => {
  // if it is a delink case, method will have "POST" or "PUT", else undefined
  return action.assetInfo.assetId ? action.method || getMethod(action.chargesDetail) : "POST";
};
const addEditUniqueAssetFirstStepError = (response) => {
  const firstStepperFields = [
    "scanCode",
    "scanCodeType",
    "inventoryNumber",
    "assetUsageConditionDate",
    "usageStateCode",
    "manufacturerName",
    "scanCode/inventoryNumber",
    "assetTypeCode",
    "assetSubTypeCode",
    "statusCode",
    "groupId",
    "serialNumber",
    "parentId/defaultParentId",
  ];
  return isErrorFieldIntheList(firstStepperFields, response);
};
const isNotRenderedField = (response) => {
  const notRenderendFields = ["asset-state", "entityId"];
  return isErrorFieldIntheList(notRenderendFields, response);
};

const isSAPAssetError = (assetInfo, response) => {
  return assetInfo.isSapAsset && isErrorFieldIntheList(HILTI_SAP_FIELDS, response);
};
const isErrorFieldIntheList = (fieldsList, response) => {
  const errorResponse =
    response.error.response &&
    response.error.response.data &&
    response.error.response.data.errors &&
    response.error.response.data.errors[0];
  return fieldsList
    .toString()
    .toLowerCase()
    .split(",")
    .includes(errorResponse.fieldName && errorResponse.fieldName.split(".").pop().toLowerCase());
};

export function* addEditUniqueAsset(action) {
  const response = yield call(() => {
    return action.assetInfo.assetId && action.thingType
      ? updateUniqueAsset(action.assetInfo.assetId, {
          ...action.assetInfo,
          thingType: action.thingType,
        })
      : action.assetInfo.assetId
        ? updateUniqueAsset(action.assetInfo.assetId, action.assetInfo)
        : action.thingType
          ? createUniqueAsset({ ...action.assetInfo, thingType: action.thingType })
          : createUniqueAsset(action.assetInfo);
  });

  // to know if new template is being created
  const isCreatingNewTemplate = isNewTemplateCreated(action.assetInfo, action.templateInfo);
  // to process charge setting or not
  const isProcessChargeSetting = shouldProcessChargeSetting(response, isCreatingNewTemplate);

  yield put({
    addEditResponse: response,
    supressEditAssetSuccessMessage: isProcessChargeSetting,
    type: AssetActions.ASSET_ADD_EDIT_RESPONSE,
  });

  if (!response.error) {
    yield sagaCall(handleAddEditUniqueAssetSuccess, action, response, isCreatingNewTemplate, isProcessChargeSetting);
  }
  if (response.error && !response.isSystemError) {
    if (
      addEditUniqueAssetFirstStepError(response) ||
      isSAPAssetError(action.assetInfo, response) ||
      isNotRenderedField(response)
    ) {
      yield put(
        showToastr(
          ToastrType.error,
          `errorCodes:${response.error.response.data.errors[0].code}`,
          null,
          action.assetInfo.assetId ? GTMConstants.EVENTS.EDIT_ASSET_FAILED : GTMConstants.EVENTS.ADD_ASSET_FAILED,
        ),
      );
    }
  } else if (!response.isSystemError) {
    // system error tostr are shown from common
    if (action.assetInfo.assetId) {
      SharedUtils.pushDataToGA(GTMConstants.EVENTS.EDIT_ASSET_SUCCESS);
      yield put({ type: AssetActions.CLEAR_ASSET_DETAILS });

      if (action.assetInfo.isTrackable && action.assetInfo.statusCode === StatusCode.Lost) {
        const monitoringTranslation = yield call(t, "lostAndFound:SUCCESS_MONITORING_CHANGE");
        const savedSuccessfulTranslation = yield call(t, "common:SAVED_SUCCESSFULLY");
        yield put(
          showMultipleToastr([
            { type: ToastrType.success, toastrMessage: monitoringTranslation },
            { type: ToastrType.success, toastrMessage: savedSuccessfulTranslation },
          ]),
        );
      } else {
        yield put(showToastr(ToastrType.success, "common:SAVED_SUCCESSFULLY"));
      }
    }
  }
}

export function* handleAddEditUniqueAssetSuccess(action, response, isCreatingNewTemplate, isProcessChargeSetting) {
  if (!action.assetInfo.assetId) {
    yield put({
      assetId: response.assetId,
      type: AssetActions.AM_ASSET_DETAILS,
    });
    yield put({ type: AssetActions.SET_ASSET_SEARCH_QUERY, searchQuery: "" });
  }
  yield put({ type: AssetServiceActions.ASSET_SERVICE_CLEAR_LIST });

  const { assetInfo, chargesDetail } = action;
  const isEdit = Boolean(assetInfo.assetId);
  /*
    check if charge details recived from API
    Add Asset: No need to validate charge details. Just check if chargeSetting is present
    in the form(assetInfo)
    Edit Asset: Check if charge details recieved from API and set in the form.

    Prevent chargesAPI call(post/put) in edit asset case, if charge details
    are no recieved from API
  */
  const assetChargeDetail = chargesDetail && chargesDetail[assetInfo.assetId];
  const isChargeDetailsLoadedForEdit = !isEdit || assetChargeDetail;
  if (
    isProcessChargeSetting &&
    canUserEditChargeSettings() &&
    isChargeSettingLoaded(isChargeDetailsLoadedForEdit, assetInfo.chargeSetting)
  ) {
    if (isCreatingNewTemplate) {
      const chargeSettingData = {
        chargeSetting: assetInfo.chargeSetting,
        method: "POST",
        t: action.t,
        templateId: response.assetTemplateId,
      };
      yield put({
        asset: response,
        chargeSettingData,
        isCreatingNewTemplate: true,
        isEdit,
        source: "asset",
        type: ChargesActions.CH_SUBMIT_TEMPLATE_CHARGE_SETTING,
      });
    } else if (!response.assetTemplateId && !HelperLodash.isEqual(assetInfo.chargeSetting, assetChargeDetail)) {
      // delink, edit asset cost setting

      const method = chargesMethod(action);
      yield put({
        asset: response,
        chargeSettingData: {
          assetId: response.assetId,
          chargeSetting: assetInfo.chargeSetting,
          isEdit: Boolean(assetInfo.assetId),
          method,
          t: action.t,
        },
        type: ChargesActions.CH_SUBMIT_ASSET_CHARGE_SETTING,
      });
    }
  }
}

export function* getCommonAttributesSaga() {
  yield takeEvery(AssetActions.AM_ASSET_COMMON_ATTRIBUTES, fetchColummnCommonAttributes);
}

export function* fetchColummnCommonAttributes(action) {
  const multiSelectQueryFields = yield select(getQueryObjForMultiPayload, "Assets");
  const multiSelectPayloadFields = getMultiSelectPayloadAttributes(
    action?.payload?.checkedListInfo,
    multiSelectQueryFields.isSelectAll,
    AssetKeys,
  );
  const multiRowPayload = {
    ...multiSelectQueryFields,
    ...multiSelectPayloadFields,
  };
  let response = yield call(() => {
    return getCommonAttributes(multiRowPayload);
  });
  if (!response) {
    response = ASSET_COLUMN_ATTRIBUTES;
  }
  response.attributes = mergeAssetUsageStateAndDate(response.attributes, action?.payload?.t);
  yield put({
    type: AssetActions.AM_ASSET_SET_COMMON_ATTRIBUTES,
    payload: response,
  });
}

/**
 * used make API call for retrieving asset attributes
 */
export function* getAssetFilterAttributesSaga() {
  yield takeEvery(AssetActions.AM_GET_ASSET_FILTER_FIELD, fetchAssetFilterAttributes);
}

function* hideFilterLoader() {
  yield put({ type: LoaderAction.UNSET_FILTER_LOADER });
}
function* showFilterLoader() {
  yield put({ type: LoaderAction.SET_FILTER_LOADER });
}

/**
 * used make API call for retrieving asset attributes
 *
 * @param filters
 */
export function* fetchAssetFilterAttributes(filters) {
  if (filters.delay) {
    yield delay(API_DELAY_TIME);
  }
  yield showFilterLoader();
  const filterQuery = yield select(getFilterQueryObj, "Assets", filters.payload, true);
  if (filterQuery?.length < MAX_QUERY_STRING_SIZE) {
    const { response, error } = yield call(getAssetFilterAttributes, filterQuery);

    if (!error) {
      const patchedResponse = {
        ...response,
        ...(response.viewLabel
          ? {
              viewLabel: [
                { value: "filters:YES", code: "NEW" },
                { value: "filters:NO", code: "null" },
              ],
            }
          : {}),
      };
      yield put({
        payload: {
          filterPayload: filters.payload,
          response: patchedResponse,
        },
        type: AssetActions.ASSET_FILTER_FIELD_SUCCESS,
      });
      if (filters.payload?.query) {
        GTMEventsForFilterSearch(filters.payload, entityName.ASSET);
      }
      if (filters.payload?.query === "") {
        GTMEventsForShowMoreFilter(filters.payload, entityName.ASSET);
      }
    }
  }
  yield hideFilterLoader();
}

/**
 * used make API call for retrieving asset transfer history
 */
export function* getTransferCartForAsset() {
  yield takeEvery(AssetActions.AM_GET_ASSET_TRANSFER_DETAILS, fetchTransferDetails);
}

export function* fetchTransferDetails(action) {
  if (action.limit === 1) {
    yield put({ type: AssetActions.CLEAR_SELECTED_ASSET_TRANSFER_HISTORY });
  }
  let transferHistory = yield call(() => {
    return getTransferHistoryList(
      action.id,
      { limit: action.limit, offset: action.offset },
      ModuleName.asset.toUpperCase(),
    );
  });
  if (transferHistory && transferHistory.error) {
    transferHistory = { response: [] };
  }
  const payloadObj = {
    assetId: action.id,
    offset: action.offset,
    transferHistory: getTransferModelList(transferHistory),
  };
  yield put({
    type: AssetActions.SET_ASSET_TRANSFER_DETAILS,
    payload: payloadObj,
  });
}

/**
 * @description - Saga to create custom view.
 */
export function* addAssetCustomViewSaga() {
  yield takeLatest(AssetActions.AM_CREATE_CUSTOM_VIEW, addAssetCustomView);
}

/**
 * @description - Function to create custom view.
 */
export function* addAssetCustomView(action) {
  const state = yield select();
  const filterQuery = state.Assets && state.Assets.filterQuery;
  const sortQuery = state.Assets && state.Assets.sortQuery;
  const gridFields = state.Assets && state.Assets.selectedCustomView?.listOfFields;
  const customViewData = prepareAddCustomViewData(
    action.customViewData,
    filterQuery,
    sortQuery,
    gridFields,
    ModuleName.asset,
  );

  const response = yield call(() => {
    return createAssetCustomView(customViewData);
  });
  if (response && !response.error) {
    const selectedView = Object.assign(customViewData, response);
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
    yield put({ type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW, selectedView });
    yield put(showToastr(ToastrType.success, "common:ADDED_SUCCESSFULLY"));
  } else {
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
  }
}

/**
 * @description - Saga to get custom views list.
 */
export function* getAssetCustomViewSaga() {
  yield takeLatest(AssetActions.AM_GET_ASSET_CUSTOM_VIEW, getAssetCustomView);
}

/**
 * @description - Function to get custom views list from api.
 */
export function* getAssetCustomView(action) {
  const response = yield call(() => {
    return getAssetCustomViewFields();
  });
  if (response && response.customViewDetailsResponses) {
    yield put({
      payload: response.customViewDetailsResponses,
      selectedView: action && action.selectedView ? action.selectedView : null,
      type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW_SUCCESS,
    });
  } else if (response && response.error) {
    yield put({
      payload: [],
      selectedView: null,
      type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW_FAILURE,
    });
  }
}

/**
 *
 * Saga to cancel transfer history.
 */
export function* cancelAssetLastTransfer() {
  yield takeLatest(AssetActions.AM_CANCEL_TRANSFER, cancelTransfer);
}

export function* cancelTransfer(action) {
  const response = yield call(() => {
    return cancleLastTransfer(action);
  });
  if (!response || (response && !response.error)) {
    yield fetchTransferDetails({ id: action.payload.assetId, offset: 0 });
    yield put(showToastr(ToastrType.success, `assets:ASSET_CANCEL_TRANSFER_SUCCESS`));
    yield put({
      type: AssetActions.GET_ASSET_LIST,
      offset: 0,
      id: action.payload.assetId,
    });
    yield put({
      type: AssetActions.AM_ASSET_DETAILS,
      assetId: action.payload.assetId,
    });
  } else if (response.error && !response.isSystemError) {
    yield put(showToastr(ToastrType.error, `errorCodes:${response.error.response.data.errors[0].code}`));
  }
}
/**
 *
 * @description - Saga to update or rename custom view
 */
export function* updateAssetCustomViewSaga() {
  yield takeLatest(AssetActions.AM_UPDATE_ASSET_CUSTOM_VIEW, updateAssetCustomView);
}

/**
 * @description - Function to update or rename custom view.
 */
export function* updateAssetCustomView(action) {
  const state = yield select();
  const filterQuery = state.Assets && state.Assets.filterQuery;
  const sortQuery = state.Assets && state.Assets.sortQuery;
  const gridFields = state.Assets && state.Assets.gridFields;

  const customViewData = prepareAddCustomViewData(
    action.customView,
    filterQuery,
    sortQuery,
    gridFields,
    ModuleName.asset,
  );
  const customViewId = action.customView.customViewId;
  const response = yield call(() => {
    return updateAssetCustomViewApi(customViewId, customViewData);
  });
  if (response && !response.error) {
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
    yield put({
      type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW,
      selectedView: { ...customViewData, customViewId },
    });

    yield put(showToastr(ToastrType.success, "common:UPDATED_SUCCESSFULLY"));
  } else {
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
  }
}

/**
 *
 * @description - Saga to update custom grid fields
 */
export function* updateCustomGridFieldsSaga() {
  yield takeLatest(AssetActions.UPDATE_CUSTOM_GRID_FIELDS, updateCustomGridFields);
}

/**
 * @description - Function to update custom grid fields
 */
export function* updateCustomGridFields(action) {
  const state = yield select();

  const listOfFields = {
    listOfFields: state.Assets && state.Assets.gridFields,
  };
  const response = yield call(() => {
    return updateCustomGridFieldsApi(action.customView && action.customView.customViewId, listOfFields);
  });
  if (!response.error) {
    yield put({ type: AssetActions.AM_CLEAR_DEFAULT_GRID_FIELDS });
    yield put({
      type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW,
      selectedView: response,
    });
    yield put(showToastr(ToastrType.success, "common:SAVED_SUCCESSFULLY"));
  }
}

export function* deleteCustomViewSaga() {
  yield takeLatest(AssetActions.AM_DELETE_CUSTOM_VIEW, deleteCustomViewHandle);
}

/**
 * @description - Function to create custom view.
 */
export function* deleteCustomViewHandle(action) {
  const response = yield call(() => {
    return deleteCustomView(action.id);
  });
  if (!response.error) {
    yield put({ type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW });
    yield put(showToastr(ToastrType.success, "common:DELETED_SUCCESSFULLY"));
  }
}

/**
 * @description - Function to reorder custom view.
 */

export function* reorderAssetCustomViewSaga() {
  yield takeLatest(AssetActions.AM_REORDER_ASSET_CUSTOM_VIEW, reorderAssetCustomView);
}

export function* reorderAssetCustomView(action) {
  const customViewId = action.item.id;
  const index = action.item.dropIndex;
  const entityName = action.modName;
  const response = yield call(() => {
    return reorderCustomViewApi({ customViewId, index, entityName }, ModuleName.asset);
  });
  if (!response.error) {
    yield put({
      type: AssetActions.AM_REORDER_ASSET_CUSTOM_VIEW_SUCCESS,
      payload: response,
    });
  } else {
    yield showToastr(ToastrType.error, `errorCodes:${validateResponse(response).code}`);
  }
}

export function* renameAssetCustomViewSaga() {
  yield takeLatest(AssetActions.AM_RENAME_CUSTOM_VIEW, renameCustomView);
}

export function* renameCustomView(action) {
  const customViewId = action.selectedCustomView.customViewId;
  const response = yield call(() => {
    return renameCustomViewApi(customViewId, action.customViewName);
  });
  if (response && !response.error) {
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
    yield put({
      type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW,
      selectedView: response,
    });
    yield put(showToastr(ToastrType.success, "common:RENAMED_SUCCESSFULLY"));
  } else {
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
  }
}

export function* getSystemViewSaga() {
  yield takeLatest(AssetActions.AM_GET_SYSTEM_VIEW, getSystemView);
}

export function* getSystemView() {
  const response = yield call(() => {
    return getAssetSystemView(ModuleName.asset);
  });
  if (response && !response.error) {
    yield put({
      type: AssetActions.AM_GET_SYSTEM_VIEW_SUCCESS,
      fields: response.listOfFields,
    });
  }
}

/**
 * Saga to reset original custom view
 */

export function* resetAssetOriginalViewSaga() {
  yield takeLatest(AssetActions.AM_RESET_ORIGINAL_VIEW, resetOriginalView);
}

export function* resetOriginalView() {
  const response = yield call(() => {
    return resetAssetOriginalViewApi(ModuleName.asset);
  });
  if (!response.error) {
    yield put({ type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW });
  }
}

export function* setDefaultAssetCustomViewSaga() {
  yield takeLatest(AssetActions.AM_SET_DEFAULT_CUSTOM_VIEW, setDefaultCustomView);
}

export function* setDefaultCustomView(action) {
  const customViewId = action.selectedCustomView.customViewId;
  const response = yield call(() => {
    return setDefaultCustomViewApi(customViewId, { defaultView: true });
  });
  if (!response.error) {
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
    yield put({ type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW });
    yield put(showToastr(ToastrType.success, "common:SET_DEFAULT_SUCCESSFULLY"));
  } else {
    yield put({
      type: AssetActions.AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE,
      payload: response,
    });
  }
}

export function* getChildAssetsSaga() {
  yield takeLatest(AssetActions.AM_GET_CHILD_ASSETS, fetchChildAssetsSaga);
}

export function* fetchChildAssetsSaga(action) {
  const { query = {} } = action;
  const { model } = query;
  const requestQuery = {
    limit: query.limit,
    offset: query.offset,
    parentId: model.assetId,
  };
  const queryObj = yield select(getChildAssetQuery, requestQuery, "Assets");
  const response = yield call(() => fetchChildAsset(queryObj));
  const assetIds = HelperLodash.getPathValue(response?.response, "assetId") || [];
  if (!response.error) {
    yield put({
      id: model,
      payload: getAssetModelList(response),
      type: AssetActions.GET_CHILD_ASSETS_SUCCESS,
    });
  }
  if (shouldFetchLastSeen(queryObj?.fields, assetIds)) {
    yield put(initLastSeen(assetIds));
    yield put(getLastSeen({ assetIds }));
  }
}

/**
 * used make API call for retrieving asset transfer history
 */
export function* getCheckedAssetTransferdetails() {
  yield takeEvery(AssetActions.AM_GET_CHECKED_ASSET_TRANSFER_DETAILS, fetchCheckedAssetTransferDetails);
}

export function* fetchCheckedAssetTransferDetails(action) {
  let transferHistory = yield call(() => {
    return getTransferHistoryList(action.id, { limit: 1, offset: 0 }, "ASSET");
  });
  if (transferHistory && transferHistory.error) {
    transferHistory = { response: [] };
  }
  const payloadObj = {
    transferHistory: getTransferModelList(transferHistory),
  };
  yield put({
    type: AssetActions.SET_CHECKED_ASSET_TRANSFER_DETAILS,
    payload: payloadObj,
  });
}

/**
 * @description This function is used to take latest request to get the assets
 */
export function* assetsListToTransferSaga() {
  yield takeLatest(AssetActions.GET_SELECT_ALL_ASSETS, fetchAssetsListFromApi);
}

/**
 * @description This function is used to fetch the assets from the api
 *
 * @param action
 */
function* fetchAssetsListFromApi(action) {
  const queryObj = yield select(getQueryObj, action.offset, "Assets", action.limit);
  const response = yield call(() => {
    return getAssetsList(HelperLodash.clone(queryObj), false);
  });

  if (!response.error) {
    yield put({
      payload: {
        assetsData: getAssetModelList(response),
      },
      type: AssetActions.SELECT_ALL_ASSETS_SUCCESS,
    });
  }
}

/**
 * @description This function is used to fetch the assets utilization summary from the api
 *
 * @param action
 */

export function* assetUtilizationSummarySaga() {
  yield takeLatest(AssetActions.GET_ASSET_UTILIZATION_SUMMARY, fetchAsseUtilizationSummary);
}

function* fetchAsseUtilizationSummary(action) {
  const utilizationSummary = yield call(getAssetUtilizationSummary, action.assetId, action.queryObj);
  yield put({
    payload: utilizationSummary,
    type: AssetActions.SET_ASSET_UTILIZATION_SUMMARY,
  });
}

/**
 * @description This function is used to fetch the assets utilization Details from the api
 *
 * @param action
 */

export function* assetUtilizationDetailsSaga() {
  yield takeLatest(AssetActions.GET_ASSET_UTILIZATION_DETAILS, fetchAsseUtilizationDetails);
}

function* fetchAsseUtilizationDetails(action) {
  yield put({
    payload: [],
    type: AssetActions.SET_ASSET_UTILIZATION_DETAILS,
  });

  yield put({ type: LoaderAction.SET_DIALOG_DETAIL_LOADER });
  const utilizationDetails = yield call(getAssetUtilizationDetails, action.assetId, action.queryObj);

  utilizationDetails && (yield put({ type: LoaderAction.UNSET_DIALOG_DETAIL_LOADER }));
  yield put({
    payload: utilizationDetails,
    type: AssetActions.SET_ASSET_UTILIZATION_DETAILS,
  });
}

export function* resetAssetUtilizationSummarySaga() {
  yield takeLatest(AssetActions.RESET_ASSET_UTILIZATION_SUMMARY, resetUtilizationSummary);
}

export function* resetUtilizationSummary() {
  yield put({
    payload: null,
    type: AssetActions.SET_ASSET_UTILIZATION_SUMMARY,
  });
}

function* assetListSaga() {
  yield all([
    addAssetCustomViewSaga(),
    addEditUniqueAssetSaga(),
    assetDetailsSaga(),
    assetsListSaga(),
    assetsListToTransferSaga(),
    cancelAssetLastTransfer(),
    deleteCustomViewSaga(),
    fetchAfterDeleteAsset(),
    getAssetCustomViewSaga(),
    getAssetFilterAttributesSaga(),
    getCheckedAssetTransferdetails(),
    getChildAssetsSaga(),
    getCommonAttributesSaga(),
    getSystemViewSaga(),
    getTransferCartForAsset(),
    refreshAssetsListSaga(),
    renameAssetCustomViewSaga(),
    reorderAssetCustomViewSaga(),
    resetAssetOriginalViewSaga(),
    searchAssetsListSaga(),
    setDefaultAssetCustomViewSaga(),
    updateAssetCustomViewSaga(),
    updateCustomGridFieldsSaga(),
    updateUniqueAssetStatusSaga(),
    assetUtilizationSummarySaga(),
    assetUtilizationDetailsSaga(),
    resetAssetUtilizationSummarySaga(),
  ]);
}

export default assetListSaga;
