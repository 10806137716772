import React from "react";
import Grid from "@mui/material/Grid";
import { CurrencyInput, Input } from "am-web-ui-shared/elements";
import { IChargesScheduler, IChargesSchedulerReadOnly, IWorkDaysCurrencyFieldCombination } from "./iChargesScheduler";
// @ts-ignore
import styled from "custom-styled-components";
import { maxLength, minValueValidator, rangeValidator, regexValidation, required } from "../../utils/errorUtils";
import { ValidationConstants } from "../../utils/enums/validationConstants";
import {
  AFTER_X_WORKDAYS,
  MONTHLYASSETCOST,
  MONTHLY_ASSET_COST,
  WEEKLYASSETCOST,
  WEEKLY_ASSET_COST,
  WORKDAYS_FOR_MONTHLY_ASSET_COST,
  WORKDAYS_FOR_WEEKLY_ASSET_COST,
  MIN_DAYS_WEEKLY,
  MAX_DAYS_WEEKLY,
  MIN_DAYS_MONTHLY,
  MAX_DAYS_MONTHLY,
  INCORRECTVALTRANSCONST,
  WORKDAYS_FOR_WEEKLY_PLACEHOLDER,
  WORKDAYS_FOR_MONTHLY_PLACEHOLDER,
} from "./chargesSchedulerConstants";
import { ReadOnlyField } from "./chargePanelField";
import { useTranslation } from "react-i18next";

const SpacedChild = styled.div`
  margin-left: 32px;
`;

export const ChargesScheduler = (props: IChargesScheduler) => {
  return <ShowTimeBasedFields {...props} />;
};

export const ShowTimeBasedFields = (props) => {
  const {
    currency,
    timeBasedDailyAssetCost,
    timeBasedWeeklyAssetCost,
    timeBasedMonthlyAssetCost,
    workdaysForMonthlyAssetCost,
    workdaysForWeeklyAssetCost,
  } = props;

  return (
    <>
      <SpacedChild>
        <WorkDaysCurrencyFieldCombination
          workDaysInfo={{
            label: AFTER_X_WORKDAYS,
            name: WORKDAYS_FOR_WEEKLY_ASSET_COST,
            placeholder: WORKDAYS_FOR_WEEKLY_PLACEHOLDER,
            mandatoryField: timeBasedDailyAssetCost && timeBasedWeeklyAssetCost,
          }}
          currencyInputInfo={{
            label: WEEKLY_ASSET_COST,
            name: WEEKLYASSETCOST,
            mandatoryField: workdaysForWeeklyAssetCost || timeBasedMonthlyAssetCost,
          }}
          currency={currency}
          workdaysForMonthlyAssetCost={workdaysForMonthlyAssetCost}
          workdaysForWeeklyAssetCost={workdaysForWeeklyAssetCost}
        />
        <WorkDaysCurrencyFieldCombination
          workDaysInfo={{
            label: AFTER_X_WORKDAYS,
            name: WORKDAYS_FOR_MONTHLY_ASSET_COST,
            placeholder: WORKDAYS_FOR_MONTHLY_PLACEHOLDER,
            mandatoryField: timeBasedWeeklyAssetCost && timeBasedMonthlyAssetCost,
          }}
          currencyInputInfo={{
            label: MONTHLY_ASSET_COST,
            name: MONTHLYASSETCOST,
            mandatoryField: workdaysForMonthlyAssetCost,
          }}
          currency={currency}
          workdaysForMonthlyAssetCost={workdaysForMonthlyAssetCost}
          workdaysForWeeklyAssetCost={workdaysForWeeklyAssetCost}
        />
      </SpacedChild>
    </>
  );
};

export const WorkDaysCurrencyFieldCombination = (props: IWorkDaysCurrencyFieldCombination) => {
  const { workDaysInfo, currencyInputInfo, currency, workdaysForMonthlyAssetCost, workdaysForWeeklyAssetCost } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  const MIN_VALUE = workDaysInfo.name === WORKDAYS_FOR_WEEKLY_ASSET_COST ? MIN_DAYS_WEEKLY : MIN_DAYS_MONTHLY;
  const MAX_VALUE = workDaysInfo.name === WORKDAYS_FOR_WEEKLY_ASSET_COST ? MAX_DAYS_WEEKLY : MAX_DAYS_MONTHLY;

  const workDaysValidation = [
    regexValidation(ValidationConstants.NUMBER_REGEX, t("errorCodes:600028")),
    rangeValidator(MIN_VALUE, MAX_VALUE, t("common:RANGE_VALIDATION", { minValue: MIN_VALUE, maxValue: MAX_VALUE })),
  ];

  const currencyInputValidation = [
    maxLength(t, ValidationConstants.MAX_LENGTH.NUM_12),
    regexValidation(ValidationConstants.NUMBER_WITH_TWO_DECIMAL_PLACES, t(INCORRECTVALTRANSCONST)),
    minValueValidator(ValidationConstants.MAX_LENGTH.NUM_0, t("common:VALIDATION_MIN_VALUE"), false),
  ];

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <Input
            name={workDaysInfo.name}
            label={t(`charges:${workDaysInfo.label}`)}
            isFormControl={true}
            placeholder={workDaysInfo.placeholder}
            required={workDaysInfo.mandatoryField}
            validation={getWorkdaysValidations(
              workDaysInfo,
              workDaysValidation,
              workdaysForMonthlyAssetCost,
              workdaysForWeeklyAssetCost,
            )}
          />
        </Grid>

        <Grid item={true} xs={6}>
          <CurrencyInput
            name={currencyInputInfo.name}
            label={t(`charges:${currencyInputInfo.label}`)}
            isFormControl={true}
            currencyCode={currency?.code}
            required={currencyInputInfo.mandatoryField}
            validation={
              currencyInputInfo.mandatoryField
                ? [...currencyInputValidation, required(t, `charges:${currencyInputInfo.label}`)]
                : currencyInputValidation
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export const ChargesSchedulerReadOnly = (props: IChargesSchedulerReadOnly) => {
  const {
    weeklyAssetCost,
    monthlyAssetCost,
    timeBasedDailyAssetCost,
    timeBasedMonthlyDays,
    timeBasedWeeklyDays,
    currency,
  } = props;

  const { t } = useTranslation();
  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          {ReadOnlyField({
            label: t("common:DAILY_ASSET_COST"),
            value: timeBasedDailyAssetCost || "-",
            currencyCode: currency?.code,
          })}
        </Grid>
      </Grid>
      {timeBasedWeeklyDays && (
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            {ReadOnlyField({
              label: t(`charges:${AFTER_X_WORKDAYS}`),
              value: timeBasedWeeklyDays || "-",
              currencyCode: null,
            })}
          </Grid>
          <Grid item={true} xs={6}>
            {ReadOnlyField({
              label: t(`charges:${WEEKLY_ASSET_COST}`),
              value: weeklyAssetCost || "-",
              currencyCode: currency?.code,
            })}
          </Grid>
        </Grid>
      )}
      {timeBasedMonthlyDays && (
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            {ReadOnlyField({
              label: t(`charges:${AFTER_X_WORKDAYS}`),
              value: timeBasedMonthlyDays || "-",
              currencyCode: null,
            })}
          </Grid>
          <Grid item={true} xs={6}>
            {ReadOnlyField({
              label: t(`charges:${MONTHLY_ASSET_COST}`),
              value: monthlyAssetCost || "-",
              currencyCode: currency?.code,
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const getWorkdaysValidations = (
  workDaysInfo,
  workDaysValidation,
  workdaysForMonthlyAssetCost,
  workdaysForWeeklyAssetCost,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  let minValue = MIN_DAYS_MONTHLY;
  if (workDaysInfo.mandatoryField) {
    workDaysValidation = [...workDaysValidation, required(t, `charges:${workDaysInfo.label}`)];
  }

  if (
    workdaysForWeeklyAssetCost &&
    workdaysForMonthlyAssetCost &&
    workDaysInfo.name === WORKDAYS_FOR_MONTHLY_ASSET_COST
  ) {
    if (workdaysForWeeklyAssetCost >= workdaysForMonthlyAssetCost && workdaysForMonthlyAssetCost <= MAX_DAYS_WEEKLY) {
      minValue = Number(workdaysForWeeklyAssetCost) + 1;
    }

    return [...workDaysValidation, minValueValidator(minValue, t("common:VALIDATION_WORKDAYS"), true)];
  }
  return [...workDaysValidation];
};
