import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-727.000000, -918.000000)">
        <g transform="translate(709.000000, 453.000000)">
          <g transform="translate(16.000000, 463.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <path
              d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,5 C13.66,5 15,6.34 15,8 C15,9.66 13.66,11 12,11 C10.34,11 9,9.66 9,8 C9,6.34 10.34,5 12,5 Z M12,19.2 C9.5,19.2 7.29,17.92 6,15.98 C6.03,13.99 10,12.9 12,12.9 C13.99,12.9 17.97,13.99 18,15.98 C16.71,17.92 14.5,19.2 12,19.2 Z"
              id="Shape"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
