import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ActionBar, EmptyList, IconName, LazyGrid, ListContainer } from "am-web-ui-shared/components";
import { MultiSelectValidation, appConstants } from "TARGET_BUILD/config";
import { useTranslation } from "react-i18next";
import SuggestedTransfer, { SuggestedTransferListResponse } from "TARGET_BUILD/common/models/alert/suggestedTransfer";
import { ACCEPT_SUGGESTED_TRANSFER, columnConfig, quickActionsConfig } from "./gridConfig";
import { getPropertyLabel } from "TARGET_BUILD/common/utils/commonUtils";
import actionBarConfig, { CONFIRM_TRANSFER } from "./actionBarConfig";
import {
  setCheckboxWithRowSelection as selectSingleRow,
  getCheckedRowsInfo,
} from "TARGET_BUILD/common/utils/list/listUtils";
import { rowActionType } from "TARGET_BUILD/common/utils/list/list";
import SidePanel from "./suggestedTransferSidePanel";
import Filter from "TARGET_BUILD/common/models/filter";
import { PAGE_SIZE, transferPermissionKey } from "./configConstants";
import NoRecord from "TARGET_BUILD/common/components/noRecord";
import { AuthorizationCore, CheckAuthorization } from "am-web-ui-shared/helpers";
import LazyGridWrapper from "TARGET_BUILD/common/components/lazyGridWrapper";

const ListWithSidebar = styled.div`
  display: flex;
  width: 100%;
  &.open-sidebar {
    width: calc(100% - 315px);
  }
`;

const ListBody = styled.div`
  overflow: auto;
  flex: 1;
  min-height: 0;
  background-color: ${(props) => props.theme.colors.base.white};
`;

const initialMetadata = {
  multiSelectCount: MultiSelectValidation.MULTISELECTCOUNT,
  noOfSelectedRecords: 0,
  totalRecords: 0,
  limit: PAGE_SIZE,
  offset: 0,
  areAllSelected: false,
  selectedAssetId: null,
  selectedAlertId: null,
};

type SuggestedTransferWithMetadata = SuggestedTransfer & {
  metaData: {
    isChecked: boolean;
  };
  isSelected: boolean;
};

const initListWithMetadata = (list: SuggestedTransfer[], areAllSelected = false): SuggestedTransferWithMetadata[] =>
  list.map((item) => ({
    ...item,
    metaData: {
      isChecked: areAllSelected,
    },
    isSelected: false,
  }));

const getQuickActions = () => quickActionsConfig;

const NoItems = ({
  searchstring,
  handleNoSearchResultsLink,
}: {
  searchstring: string;
  handleNoSearchResultsLink: () => void;
}) => {
  const { t } = useTranslation();
  return searchstring ? (
    <NoRecord
      searchString={searchstring}
      objectType={"actionBar:SUGGESTED_TRANSFERS"}
      resetHandler={handleNoSearchResultsLink}
    />
  ) : (
    <EmptyList name={IconName.LastTransfer} emptyListTitle={t("alertAndNotification:EMPTY_SUGGESTEDTRANSFER_LIST")} />
  );
};

export type SuggestedTransferListProps = {
  isMoreFiltersOpen: boolean;
  fetchSuggestedTransfers: (offset: number, filters?: Filter[]) => void;
  suggestedTransfersResponse: SuggestedTransferListResponse;
  closeMoreFilters: () => void;
  onConfirmTransfer: (ids: string[]) => Promise<void>;
  searchstring: string;
  handleNoSearchResultsLink: () => void;
};

const SuggestedTransferList = ({
  isMoreFiltersOpen,
  fetchSuggestedTransfers,
  suggestedTransfersResponse,
  closeMoreFilters,
  onConfirmTransfer,
  searchstring,
  handleNoSearchResultsLink,
}: SuggestedTransferListProps) => {
  const [listMetadata, setListMetadata] = useState({
    ...initialMetadata,
  });
  const [suggestedTransfers, setSuggestedTransfers] = useState<SuggestedTransferWithMetadata[]>([]);
  const listScrollRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const getScrollRef = () => listScrollRef;
  const fetchList = async ({ offset }: { offset: number }) => {
    fetchSuggestedTransfers(offset);
  };
  const alertTypeHasQuickAction = () => AuthorizationCore.checkPermission(transferPermissionKey);

  const confirmTransfers = async (suggestedTransferIds: string[]) => {
    await onConfirmTransfer(suggestedTransferIds);
    //clear selection metadata
    setListMetadata({
      ...listMetadata,
      selectedAssetId: null,
      selectedAlertId: null,
    });
  };

  const isShrinkedView = () => isMoreFiltersOpen || listMetadata.selectedAssetId !== null;

  const handleActionClick = (actionName: string) => {
    if (actionName === CONFIRM_TRANSFER) {
      confirmTransfers(
        listMetadata.areAllSelected ? [] : getCheckedRowsInfo(suggestedTransfers).checkedRows.map((row) => row.id),
      );
    }
  };

  useEffect(() => {
    const isFirstPageLoaded = suggestedTransfersResponse.offset === 0;
    setListMetadata({
      ...listMetadata,
      noOfSelectedRecords: isFirstPageLoaded ? 0 : listMetadata.noOfSelectedRecords,
      areAllSelected: isFirstPageLoaded ? false : listMetadata.areAllSelected,
      selectedAssetId: isFirstPageLoaded ? null : listMetadata.selectedAssetId,
      selectedAlertId: isFirstPageLoaded ? null : listMetadata.selectedAlertId,
      limit: suggestedTransfersResponse.limit,
      offset: suggestedTransfersResponse.offset,
      totalRecords: suggestedTransfersResponse.totalRecords,
    });
    setSuggestedTransfers(
      isFirstPageLoaded
        ? initListWithMetadata(suggestedTransfersResponse.response, listMetadata.areAllSelected)
        : suggestedTransfers.concat(
            initListWithMetadata(suggestedTransfersResponse.response, listMetadata.areAllSelected),
          ),
    );
  }, [suggestedTransfersResponse]);

  const getFieldTooltipText = (fieldKey: string) => getPropertyLabel(fieldKey, t);
  const disableConfirm = () => listMetadata.noOfSelectedRecords === 0;
  const getActionItemTooltip = () => t("actionBar:CONFIRMTRANSFER");

  const handleRowSelect = (
    selectedRowData: SuggestedTransferWithMetadata,
    rowAction: rowActionType = rowActionType.checked,
  ) => {
    const isRowSelected = rowAction === rowActionType.selected ? true : !selectedRowData.metaData?.isChecked;
    const updatedSuggestedTransfers = [
      ...selectSingleRow(suggestedTransfers, selectedRowData, isRowSelected, "id", rowAction),
    ];
    setSuggestedTransfers(updatedSuggestedTransfers);
    const count = getCheckedRowsInfo(updatedSuggestedTransfers).checkedRowscount;
    setListMetadata({
      ...listMetadata,
      noOfSelectedRecords: count,
      selectedAssetId: isRowSelected ? selectedRowData.assetId : null,
      selectedAlertId: isRowSelected ? selectedRowData.id : null,
    });
  };

  const handleRowClick = (selectedRowData: SuggestedTransferWithMetadata) => {
    handleRowSelect(selectedRowData, rowActionType.selected);
    closeMoreFilters();
  };

  const handleQuickActionClick = (actionConfig: { code: string }, data: SuggestedTransfer) => {
    if (actionConfig.code === ACCEPT_SUGGESTED_TRANSFER) {
      confirmTransfers([data.id]);
    }
  };

  const getSelectedAlertId = () => listMetadata.selectedAlertId;

  return (
    <>
      <ActionBar
        actionBarSettings={actionBarConfig}
        actionBarItemInfo={listMetadata}
        onActionItemClicked={handleActionClick}
        shouldRenderCheckBox={false}
        dependencies={{
          getActionItemTooltip,
          disableConfirm,
        }}
        selectedItemListCount={listMetadata.noOfSelectedRecords}
        maxLabelLength={20}
        checkAuthorization={CheckAuthorization}
      />
      <ListContainer modalFooter={appConstants.MODAL_FOOTER_HEIGHT}>
        <ListWithSidebar className={isMoreFiltersOpen ? "open-sidebar" : ""}>
          <ListBody data-testid="scroll-container" ref={listScrollRef}>
            {suggestedTransfers.length ? (
              <LazyGridWrapper searchString={searchstring}>
                <LazyGrid
                  hideRowArrow={isMoreFiltersOpen}
                  t={t}
                  listScrollRef={listScrollRef?.current}
                  totalRecords={listMetadata.totalRecords}
                  listLength={suggestedTransfers.length}
                  resetScrollPosition={false}
                  isMoreFilterClosed={null}
                  pageStart={0}
                  threshold={100}
                  handleRowClick={handleRowClick}
                  handleCheckboxClick={handleRowSelect}
                  disableCheckBoxColumn={false}
                  columns={columnConfig}
                  rowsData={suggestedTransfers}
                  idKey="id"
                  showTooltip={true}
                  paginationInfo={{
                    getList: fetchList,
                    limit: listMetadata.limit,
                    offset: listMetadata.offset,
                  }}
                  dependencies={{
                    getPropertyLabel: getFieldTooltipText,
                    getScrollRef,
                    getQuickActions,
                    alertTypeHasQuickAction,
                    // true if more filters is open
                    isShrinkedView,
                    t,
                    handleQuickActionClick,
                    getSelectedAlertId,
                  }}
                />
              </LazyGridWrapper>
            ) : (
              <NoItems searchstring={searchstring} handleNoSearchResultsLink={handleNoSearchResultsLink} />
            )}
          </ListBody>
          {listMetadata.selectedAssetId !== null && !isMoreFiltersOpen ? (
            <SidePanel assetId={listMetadata.selectedAssetId} />
          ) : null}
        </ListWithSidebar>
      </ListContainer>
    </>
  );
};

export default SuggestedTransferList;
