import { get, intersection } from "../../helpers/helperLodash";
import { PermissionConditions } from "./permissionConstants";

class AuthorizationCore {
  activeModules: string[] = ["ASSET_COST", "QTY_ITEMS", "DASHBOARD"];
  storedUserPermissions;
  storedUserRole;
  modulePermissions;
  private userRoles = "";
  private readonly userPermissions = [];

  setup = (userPermissions, userRole, modulePermissions) => {
    this.storedUserPermissions = userPermissions;
    this.storedUserRole = userRole;
    this.modulePermissions = modulePermissions;
  };

  setupActiveModule = (activeModules: string[]): void => {
    this.activeModules = activeModules;
  };

  hasStoredUserRolesOrPermissions = (storedUserRole, storedUserPermissions) => storedUserRole && storedUserPermissions;

  setUserRolesAndPermissionsFromStorage = (checkStorageUserPermissions) => {
    if (this.userRoles === "" && this.userPermissions.length === 0) {
      const storedUserRole = this.storedUserRole;
      if (checkStorageUserPermissions !== null) {
        const storedUserPermissions = JSON.parse(this.storedUserPermissions).userPermissions;
        if (this.hasStoredUserRolesOrPermissions(storedUserRole, storedUserPermissions)) {
          this.userRoles = storedUserRole;
          storedUserPermissions.forEach((permission: { code: string; value: string }) =>
            this.userPermissions.push(permission.code),
          );
        }
      }
    }
  };

  hasPermission = (permissions: object | string) => {
    const permissionObj = typeof permissions === "object" ? (permissions as any).allow : permissions;
    const permissionCondition = (permissions as any).condition;
    const checkStorageUserPermissions = this.storedUserPermissions;
    this.setUserRolesAndPermissionsFromStorage(checkStorageUserPermissions);
    if (checkStorageUserPermissions !== null) {
      if (permissionCondition && permissionCondition === PermissionConditions.ANY) {
        return intersection(permissionObj, this.userPermissions).length > 0;
      } else {
        if (Array.isArray(permissionObj)) {
          let compareValue = true;
          permissionObj.forEach((data) => {
            if (!this.userPermissions.includes(data)) {
              compareValue = false;
            }
          });
          return compareValue;
        } else {
          return this.userPermissions.some((data) => data.includes(permissionObj));
        }
      }
    }
    return true;
  };

  hasActiveModule = (module: string): boolean => {
    return this.activeModules.includes(module);
  };

  checkPermission = (permissionKey: string, validation: any = () => true) => {
    const modulePermission = get(this.modulePermissions, permissionKey);
    return modulePermission && this.hasPermission(modulePermission) && validation();
  };
}

const authCore = new AuthorizationCore();
export default authCore;
