import React from "react";
import theme from "TARGET_BUILD/theme";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FullViewMap from "../../fullViewMap";
import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { RenderProximityRadiusLabel } from "./accuractyCircleMapComponents";
import AssetDetailsMapSmall from "./AssetDetailsMapSmall";
import { trackLocationDetailsMap } from "../../../../../analytics/events/assetDetails";
import { IAccuracyCircleMapView } from "../../iMapWrapper";

const assetTransString = "assets:ASSET_GEO_POSITION";

const FullViewMapWrapper = styled.div`
  position: relative;
  height: calc(100vh - 69px);
`;

const FullScreenIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
  top: 10px;
  left: 10px;
  cursor: pointer;
  border: 2px solid ${() => theme.colors.base.heavyConcrete60};
  background-color: ${() => theme.colors.base.white};
`;

export const AccuracyCircleMapView = (props: IAccuracyCircleMapView) => {
  const { t } = useTranslation();
  const { mapPosition, assetSubType, infoDetails } = props;

  const handleMapClick = () => {
    const { isFullView, mapClicked } = props;
    if (!isFullView) {
      mapClicked(true);
    }
  };

  return (
    <>
      {props.isFullView ? (
        <FullViewMap headerText={t(assetTransString)}>
          {(isFullViewClosed) => {
            if (isFullViewClosed) {
              props.mapClicked(false);
            }
            return (
              <FullViewMapWrapper>
                <RenderProximityRadiusLabel
                  t={t}
                  mapPosition={mapPosition}
                  fromTheBigMap={true}
                ></RenderProximityRadiusLabel>
                <AssetDetailsMapSmall
                  mapPosition={mapPosition}
                  assetSubType={assetSubType}
                  isFullView={props.isFullView}
                  infoDetails={infoDetails}
                ></AssetDetailsMapSmall>
              </FullViewMapWrapper>
            );
          }}
        </FullViewMap>
      ) : (
        <div
          style={{ width: "100%", height: "150px" }}
          onClick={(e) => {
            trackLocationDetailsMap();
            e.stopPropagation();
          }}
        >
          <FullScreenIconWrapper onClick={handleMapClick}>
            <SquareIcon name={IconName.FullScreen} />
          </FullScreenIconWrapper>
          <RenderProximityRadiusLabel t={t} mapPosition={mapPosition}></RenderProximityRadiusLabel>
          <AssetDetailsMapSmall
            mapPosition={mapPosition}
            assetSubType={assetSubType}
            isFullView={props.isFullView}
            infoDetails={infoDetails}
          ></AssetDetailsMapSmall>
        </div>
      )}
    </>
  );
};

export default AccuracyCircleMapView;
