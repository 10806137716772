// @ts-ignore
import { CommonActions } from "custom-actions";
import { call as sagaCall, put, takeLatest } from "redux-saga/effects";
import ErrorCodes from "../../../common/utils/enums/errorCodes";
import ToastrActions from "../../actionConstants/toastrConstant";
import CommonApi from "../../api/commonApi";
import { ToastrType } from "../../actions/toastrAction";
import GTMConstants from "../../gtmConstants";

/**
 * @description the saga function to get states list
 *
 * @export
 */
export function* fetchStates() {
  yield takeLatest(CommonActions.OM_GET_STATES, getStateList);
}

/**
 * @description the function to get states list from API
 */
export function* getStateList(action) {
  const response = yield call(invokeGetStatesApi(action));
  yield handleGetStateResponse(response);
}

export function* handleGetStateResponse(response: any) {
  if (!response.error) {
    yield put({
      payload: response,
      type: CommonActions.OM_GET_STATES_SUCCESS,
    });
  }
}

/**
 * method to invoke get state api
 *
 * @param action params to invoke api
 */
export function invokeGetStatesApi(action: any): any {
  return () => {
    return CommonApi.getStates(action.payload);
  };
}

/**
 * @description Saga created to show toast message for System Errors.
 * @prop {any} func - Function to invoke api functions.
 * @prop {any} args - Function arguments corrsponding to api function.
 */
export function* call(func, ...args) {
  const response = yield sagaCall(func, ...args);
  return yield handleSagaCallResponse(response);
}

/**
 * method to handle response from saga call
 *
 * @param response response from saga call
 */
export function* handleSagaCallResponse(response) {
  if (!response || (response && !response.error) || (response.error && response.error.isCancel)) {
    return response;
  }

  const error = response.error.response;
  const systemErrorCodes = [
    ErrorCodes.E403,
    ErrorCodes.E405,
    ErrorCodes.E500,
    ErrorCodes.E502,
    ErrorCodes.E503,
    ErrorCodes.E504,
  ];
  let systemErrorMessage;
  if (!error) {
    systemErrorMessage = `errorCodes:networkError`;
  } else if (systemErrorCodes.includes(error.status)) {
    systemErrorMessage = `errorCodes:${error.status}`;
  }
  if (systemErrorMessage) {
    yield put({
      payload: {
        toastrMessage: systemErrorMessage,
        translate: true,
        toastrType: ToastrType.error,
        gtmEvent: GTMConstants.EVENTS.UNABLE_PERFORM_ACTION,
      },
      type: ToastrActions.SHOW_TOASTR,
    });
    return { ...response, isSystemError: true };
  }
  return response;
}

function* commonSaga() {
  yield call(fetchStates);
}

export default commonSaga;
