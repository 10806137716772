import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import GoogleMapWrapper from "../../googleMapWrapper";
import { RenderInfoWindow, getIconByAssetSubType } from "./accuractyCircleMapComponents";
import { IAccuracyCircleMapView } from "../../iMapWrapper";
import { ValidationConstants } from "TARGET_BUILD/common/utils/enums/validationConstants";
import { IBLEMap } from "../../model/iAssetBLEMap";
import { colorPalette } from "am-web-ui-shared";
export const isPositionValid = (position: IBLEMap) => position?.latitude || position?.longitude;

export const createCircle = (map: google.maps.Map, p: IBLEMap) => {
  const position = { lat: p?.latitude || 0, lng: p?.longitude || 0 };
  return new google.maps.Circle({
    strokeColor: "transparent",
    fillColor: colorPalette.mapAccuracyBlue,
    fillOpacity: 0.4,
    map,
    center: position,
    radius: p.accuracy,
  });
};

const addPopupToMapWidget = () => {
  const container = document.createElement("div");
  const infowindow = new google.maps.InfoWindow({});
  infowindow.setContent(container);
  return { container, infowindow };
};

export const assetDetailsMapSmallOptions = (p: IBLEMap) => {
  return {
    disableDefaultUI: true,
    minZoom: 2,
    disableDoubleClickZoom: true,
    draggableCursor: "pointer",
    center: { lat: p?.latitude || 0, lng: p?.longitude || 0 },
    zoom: isPositionValid(p) ? 14 : ValidationConstants.MAX_LENGTH.NUM_0,
  };
};

export const assetDetailsMapLargeOptions = (p: IBLEMap) => {
  return {
    minZoom: 2,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
    streetViewControl: false,
    scaleControl: true,
    draggableCursor: "pointer",
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
      style: google.maps.MapTypeControlStyle.DEFAULT,
    },
    center: { lat: p?.latitude || 0, lng: p?.longitude || 0 },
    zoom: isPositionValid(p) ? 14 : ValidationConstants.MAX_LENGTH.NUM_0,
  };
};

export const MapComponent = ({ mapPosition: p, assetSubType, isFullView, infoDetails }: IAccuracyCircleMapView) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map>(null);
  const [popupContainer, setPopupContainer] = useState<HTMLDivElement>(null);

  useEffect(() => {
    let marker;
    const init = async () => {
      // render map
      const { Map: GoogleMap } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;
      const instance = new GoogleMap(
        containerRef.current,
        isFullView ? assetDetailsMapLargeOptions(p) : assetDetailsMapSmallOptions(p),
      );
      mapRef.current = instance;

      const { container, infowindow } = addPopupToMapWidget();
      setPopupContainer(container);

      if (!isPositionValid(p)) {
        return;
      }
      createCircle(instance, p);

      marker = new google.maps.Marker({
        map: mapRef.current,
        icon: getIconByAssetSubType(assetSubType),
        position: { lat: p?.latitude, lng: p?.longitude },
      });

      if (isFullView) {
        marker.addListener("click", () => {
          infowindow.open(mapRef.current, marker);
        });
      }
    };
    init();
    return () => {
      google.maps.event.clearListeners(marker, "click");
    };
  }, []);
  useEffect(() => {
    if (mapRef?.current && p) {
      mapRef.current.setCenter({ lat: p.latitude || 0, lng: p.longitude || 0 });
    }
  }, [p]);
  return (
    <div ref={containerRef} id="assetDetailsMapSmall" style={{ width: "100%", height: "100%" }}>
      {popupContainer && createPortal(<RenderInfoWindow infoDetails={infoDetails} />, popupContainer)}
    </div>
  );
};
export const AssetDetailsMapSmall = ({
  mapPosition,
  assetSubType,
  isFullView,
  infoDetails,
}: IAccuracyCircleMapView) => {
  return (
    <GoogleMapWrapper>
      <MapComponent
        mapPosition={mapPosition}
        assetSubType={assetSubType}
        isFullView={isFullView}
        infoDetails={infoDetails}
      />
    </GoogleMapWrapper>
  );
};
export default AssetDetailsMapSmall;
