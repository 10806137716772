// @ts-ignore
import { MoreFilterActions } from "custom-actions";

/**
 * @description - This method is used to trigger the action to show the more filter.
 *
 * @param type - This is the type of more filter needs to be shown.
 */

export const openMoreFilter = () => ({
  type: MoreFilterActions.OPEN_MORE_FILTER,
});
export const closeMoreFilter = () => ({
  type: MoreFilterActions.CLOSE_MORE_FILTER,
});
