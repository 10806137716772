import React from "react";
const SVG = () => (
  <svg className="openNew" width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3241.000000, -3509.000000)" fill="#524F53">
        <g transform="translate(3238.000000, 3506.000000)">
          <path
            d="M9,3 L9,6 L6,6 L6,18 L17.999,18 L18,15 L21,15 L20.999,18 L21,18 L21,21 L3,21 L3,3 L9,3 Z M21,3 L21,12 L18,12 L18,8.106 L11.3284271,14.7781746 L9,12.4497475 L15.449,6 L12,6 L12,3 L18.449,3 L21,3 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
