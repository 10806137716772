export enum LocationProviderOrigin {
  Nuron = "cdm", // Hilti Nuron charger
  Fused = "fused", // mobile app
  Symbolic = "symbolic", //rigado gateway
}

export enum ServiceProviderCode {
  Gateway = "gateway",
  Charger = "charger",
  Mobile = "BLE",
  HeavyEquipment = "telematic",
  Van = "van",
  GeoTag = "geotag",
}

export enum LastSeenStateEnum {
  SUCCESS = "success",
  LOADING = "loading",
  FAILURE = "failure",
}

export enum GeoTagBatteryStatusEnum {
  GOOD = "good",
  LOW = "low",
  CRITICAL = "critical",
}
