import {
  AllocationActions,
  QtyItemsActions,
  TransferCartConstants,
  AlertNotificationAction,
  ConfirmTransferActions,
  // @ts-ignore
} from "custom-actions";
import { all, call as sagaCall, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../../actions/toastrAction";
import { getAssetsList, getTransferDate, submitTransfer } from "../../../api/assetApi";
import CommonApi from "../../../api/commonApi";
import GTMConstants from "../../../gtmConstants";
import { assetsGridSetting } from "../../asset/components/list/presentational/assetSetting";
import ModuleName from "../../../utils/enums/moduleNameEnum";
import { parseInvalidResponse, validateResponse, isDuplicateTransfer } from "../../../utils/errorUtils";
import {
  checkIfQtyItemPresent,
  getItemsToBeTransferred,
  getTransferCartFromStorage,
  getTransferCartIdFromStorage,
  removeAllCartItems,
  removeItemFromSessionStore,
  transformItems,
  updateItemsToSesionStore,
} from "../../../utils/transferCartUtils";
import { call } from "../../../sagas/common/commonSaga";
import { getQueryObj } from "../../../sagas/stateSelector";
import { logMessage } from "../../../utils/sentryUtils";

/**
 * Saga to add item to transfer cart.
 */
export function* transferCartSaga() {
  yield takeLatest(TransferCartConstants.AM_SET_TRASNFER_CART_ITEMS, addTransferCartInStore);
}

function* addTransferCartInStore(action) {
  if (getTransferCartIdFromStorage() === null) {
    yield sagaCall(() => generateTransferCartId());
  }
  let cartList = [];
  const storedTransferCart = getTransferCartFromStorage();
  if (action.payload.items) {
    if (storedTransferCart.length === 0) {
      yield sagaCall(() => generateTransferCartId());
    }
    cartList = action.payload.items.map((item) => {
      if (item.metaData.type === ModuleName.allocation && item.metaData.isChecked === undefined) {
        item.metaData.isChecked = true;
      }
      return item;
    });
    updateItemsToSesionStore(cartList);
  } else {
    cartList = storedTransferCart;
  }
  yield put({ type: TransferCartConstants.UPDATE_ADD_QTY_ITEM_BY_SCANCODE_RESPONSE, payload: null });
  yield put({ type: TransferCartConstants.AM_SET_TRASNFER_CART_ITEM_STORE, payload: cartList });
  yield put({ type: action.payload.type });
}

function* generateTransferCartId() {
  const state = yield select();
  const tenantId = state?.UserReducer?.userMeData?.tenantId;
  yield put({ type: TransferCartConstants.AM_SET_TRASNFER_CART_ID, tenantId });
}

/**
 * Saga to remove item from transfer cart.
 */
export function* transferCartRemoveSaga() {
  yield takeLatest(TransferCartConstants.AM_REMOVE_TRANSFER_CART_ITEM, removeTransferCartInStore);
}

function* removeTransferCartInStore(action) {
  if (action.payload) {
    removeItemFromSessionStore(action.payload);
  }
  const cartList = getTransferCartFromStorage();
  yield put({ type: TransferCartConstants.AM_SET_TRASNFER_CART_ITEM_STORE, payload: cartList });
  yield put(showToastr(ToastrType.success, `common:REMOVED_SUCCESSFULLY`));
}

/**
 * Saga to remove all items from transfer cart.
 */
export function* transferCartRemoveAllItemsSaga() {
  yield takeLatest(TransferCartConstants.AM_REMOVE_ALL_TRANSFER_CART_ITEMS, removeAllTransferCartInStore);
}

function* removeAllTransferCartInStore(action) {
  removeAllCartItems();
  const cartList = getTransferCartFromStorage();
  yield put({ type: TransferCartConstants.AM_SET_TRASNFER_CART_ITEM_STORE, payload: cartList });
  if (action.isToastVisible) {
    yield put(showToastr(ToastrType.success, `assets:REMOVED_ALL_SUCCESSFULLY`));
  } else {
    yield sagaCall(() => generateTransferCartId());
  }
}

/**
 * use to update status code and refresh the list with offset 0
 */
export function* submitTransferSaga() {
  yield takeLeading(TransferCartConstants.AM_SUBMIT_TRANSFER, submitTransferCall);
}

// Submit call to transfer the assets.
export function* submitTransferCall(action) {
  const isQtyItemPresent = checkIfQtyItemPresent(action.transferCartInfo?.assets);

  const response = yield call(() => {
    return submitTransfer(action.transferCartInfo, action?.hasConsent);
  });

  // If transfer took place.
  if (response.transferId && !response.invalidResponse) {
    // If all of the assets have been transfered. status code 201.
    if (isQtyItemPresent) {
      logMessage(TransferCartConstants.QTY_TRANSFER_SUCCESS);
    }
    yield put({ type: TransferCartConstants.TRANSFER_SUBMIT_CART_RESPONSE, payload: response });
    if (getTransferCartFromStorage()?.length > 0) {
      yield sagaCall(() => generateTransferCartId());
    }
    yield put({ type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT });
    // set duplicate transfer false
    if (action?.hasConsent) {
      yield put({ type: TransferCartConstants.EDIT_TRANSFERCART_INFO });
    }
  } else if (isDuplicateTransfer(response)) {
    yield put({
      type: TransferCartConstants.DUPLICATE_TRANSFER_DETECTED,
      payload: action.transferCartInfo,
      isQuickTransfer: false,
    });
  } else if (response.invalidResponse) {
    // If there is 207.
    if (parseInvalidResponse(response)) {
      // When only some of the assets have been transfered. status code 207.
      if (isQtyItemPresent) {
        logMessage(TransferCartConstants.QTY_TRANSFER_PARTIAL_SUCCESS);
      }
      yield put({
        payload: response,
        type: TransferCartConstants.AM_PARTIAL_TRANSFERED_ASSETS,
      });
      yield put({ type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT });
      yield sagaCall(() => generateTransferCartId());
    }
  } else {
    // when there is an error.
    if (validateResponse(response)) {
      if (isQtyItemPresent) {
        logMessage(`Error Occured while transferring the items. Error ${validateResponse(response).code}`);
      }
      yield put(
        showToastr(
          ToastrType.error,
          `errorCodes:${validateResponse(response).code}`,
          null,
          GTMConstants.EVENTS.SUBMIT_TRANSFER_FAILED,
        ),
      );
    } else {
      yield put(showToastr(ToastrType.error, `common:UNHANDLED_EXCEPTION`));
    }
  }
}

/**
 * Saga to fetch asset from scan code or inventory number.
 */
export function* fetchAssetViaScanCodeOrInventoryNumber() {
  yield takeLatest(TransferCartConstants.FETCH_ASSET_VIA_SCANCODE_OR_INVENTORYCODE, fetchAsset);
}

function* fetchAsset(action) {
  /**
   * Invalidation of previous stored error message from redux if any.
   */
  yield put({
    payload: "",
    type: TransferCartConstants.ADD_BY_SCANCODE_INVENTORY_NUMBER_FAILED,
  });
  const response = yield call(() => {
    return CommonApi.seekAnAssetWithScanCodeOrInventoryNumber(action.payload.searchString);
  });

  if (!response.error) {
    yield sagaCall(fetchAssetSuccess, action, response);
  } else {
    yield sagaCall(fetchAssetError, action, response);
  }
}

function* fetchAssetSuccess(action, response) {
  if (response.assetResponse) {
    response.assetResponse.isSelected = false;
    response.assetResponse.metaData = { isChecked: true, type: ModuleName.asset };
    const assetsInfo = getItemsToBeTransferred(transformItems([response.assetResponse]), action.payload.cartItems);
    if (
      assetsInfo.existingAssetCount === 0 ||
      (assetsInfo.existingAssetCount === action.payload.cartItems.length && assetsInfo.isAdded)
    ) {
      yield put(showToastr(ToastrType.success, `common:TRANSFER_CART_ADDED_SUCESSFULLY`));
    } else if (assetsInfo.existingAssetCount === action.payload.cartItems.length) {
      yield put(showToastr(ToastrType.info, `common:ASSET_EXIST_IN_CART`));
    }
    if (getTransferCartFromStorage()?.length === 0) {
      yield sagaCall(() => generateTransferCartId());
    }

    updateItemsToSesionStore(assetsInfo.resultantTransferCartList);
    const cartList = getTransferCartFromStorage();
    yield put({
      payload: cartList,
      type: TransferCartConstants.AM_UPDATE_TRANSFER_CART_LIST,
    });
    yield put({
      payload: false,
      type: TransferCartConstants.AM_ERROR_CAPTURED,
    });
  } else {
    response.quantityItemDetailResponse.isSelected = false;
    response.quantityItemDetailResponse.metaData = { isChecked: true, type: ModuleName.allocation };
    yield put({
      payload: response.quantityItemDetailResponse,
      type: TransferCartConstants.UPDATE_ADD_QTY_ITEM_BY_SCANCODE_RESPONSE,
    });
  }
}

function* fetchAssetError(action, response) {
  if (validateResponse(response) && validateResponse(response).code) {
    yield put({
      payload: [action.payload.t(`errorCodes:${validateResponse(response).code}`), true],
      type: TransferCartConstants.ADD_BY_SCANCODE_INVENTORY_NUMBER_FAILED,
    });
  }
}

export function* fetchTransferDate() {
  yield takeLatest(TransferCartConstants.GET_TRANSFER_DATE, getTransferDateFromAssetList);
}

function* getTransferDateFromAssetList(action) {
  let response = yield call(() => {
    return getTransferDate((action.payload || []).toString());
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: TransferCartConstants.SET_TRANSFER_DATE, payload: response });
}

/**
 * @description method takes latest action and callback of delayedTrnasferCartStorageAssetList
 */
export function* searchStorageAssetsListSaga() {
  yield takeLatest(TransferCartConstants.GET_TRANSFER_CART_ASSET_LIST_FOR_SEARCH, fetchStorageAssetsList);
}

export function* storageAssetsListSaga() {
  yield takeLatest(TransferCartConstants.AM_GET_STORAGE_ASSET_LIST, fetchStorageAssetsList);
}

function* fetchStorageAssetsList(action) {
  const isLoader = action.offset === 0;
  if (isLoader) {
    yield put({ type: TransferCartConstants.TRANSFER_STORAGE_ASSET_LOADER_START });
  }
  const queryObj = yield select(getQueryObj, action.offset, "TransferCart");
  const view = "view=storage";
  queryObj.filter = view;
  queryObj.fields = assetsGridSetting.defaultQueryFields.join();
  let storageAssetData = yield call(() => {
    return getAssetsList(queryObj, false);
  });
  if (storageAssetData && storageAssetData.error) {
    storageAssetData = { response: [] };
  }
  const payloadObj = {
    id: action.id,
    offset: action.offset,
    storageAssetData,
  };

  yield put({ type: TransferCartConstants.AM_GET_STORAGE_ASSET_LIST_SUCCESS, payload: payloadObj });
  if (isLoader) {
    yield put({ type: TransferCartConstants.TRANSFER_STORAGE_ASSET_LOADER_END });
  }
}

export function* submitQuickTransferSaga() {
  yield takeLatest(TransferCartConstants.AM_SUBMIT_QUICK_TRANSFER, submitQuickTransferCall);
}

// Submit call to quick transfer the allocations.
export function* submitQuickTransferCall(action) {
  const { transferCartInfo, alertId } = action;
  const isQtyItemPresent = checkIfQtyItemPresent(transferCartInfo.assets);
  const response = yield call(() => {
    return submitTransfer(transferCartInfo, action?.hasConsent);
  });
  if (response.transferId) {
    if (isQtyItemPresent) {
      logMessage(TransferCartConstants.QTY_QUICK_TRANSFER_SUCCESS);
    }
    yield put({ type: AllocationActions.REFRESH_QTYITEMS, shouldRefresh: true });
    yield put({
      quickTransferResponse: response,
      type: TransferCartConstants.SUBMIT_QUICK_TRANSFER_RESPONSE,
    });
    yield put({ type: QtyItemsActions.GET_QTY_ITEMS, offset: 0 });
    /*
     *
     * in case of successful transfer if the transfer was initiated from a quick action from an alert,
     * we have to refresh the alert list
     *
     * */
    if (alertId) {
      yield put({ type: AlertNotificationAction.AN_GET_ALERTS_LIST });
    }
    yield put({ type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT });
    // set duplicate transfer false
    if (action?.hasConsent) {
      yield put({ type: TransferCartConstants.EDIT_TRANSFERCART_INFO });
    }
  } else if (isDuplicateTransfer(response)) {
    yield put({
      type: TransferCartConstants.DUPLICATE_TRANSFER_DETECTED,
      payload: action.transferCartInfo,
      isQuickTransfer: true,
    });
  } else if (response.invalidResponse) {
    if (parseInvalidResponse(response)) {
      if (isQtyItemPresent) {
        logMessage(`Quick transfer unsuccessfull with error ${parseInvalidResponse(response).code}`);
      }
      yield put(
        showToastr(
          ToastrType.error,
          `errorCodes:${parseInvalidResponse(response).code}`,
          null,
          GTMConstants.EVENTS.QTY_ITEMS_QUICK_TRANSFER_FAILED,
        ),
      );
      yield put({ type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT });
      yield put({ type: TransferCartConstants.TOGGLE_CONFIRM_TRANSFER, payload: false });
    }
  } else {
    if (validateResponse(response)) {
      if (isQtyItemPresent) {
        logMessage(`Quick transfer unsuccessfull with error ${validateResponse(response).code}`);
      }
      yield put(
        showToastr(
          ToastrType.error,
          `errorCodes:${validateResponse(response).code}`,
          null,
          GTMConstants.EVENTS.QTY_ITEMS_QUICK_TRANSFER_FAILED,
        ),
      );
      yield put({ type: TransferCartConstants.TOGGLE_CONFIRM_TRANSFER, payload: false });
    } else {
      yield put(showToastr(ToastrType.error, `common:UNHANDLED_EXCEPTION`));
    }
  }
}

function* composeTransferCartSaga() {
  yield all([
    fetchAssetViaScanCodeOrInventoryNumber(),
    fetchTransferDate(),
    searchStorageAssetsListSaga(),
    storageAssetsListSaga(),
    submitQuickTransferSaga(),
    submitTransferSaga(),
    transferCartRemoveAllItemsSaga(),
    transferCartRemoveSaga(),
    transferCartSaga(),
  ]);
}

export default composeTransferCartSaga;
