import { ISagaModule } from "redux-dynamic-modules-saga";
import ActivityReducer from "./activityReducer";
import { IActivityTrackerModule } from "./iActivityTrackerModule";

const activityTrackerModule: ISagaModule<IActivityTrackerModule> = {
  id: "activityTrackerModule",
  reducerMap: {
    ActivityTracker: ActivityReducer,
  },
  sagas: [],
};

export default activityTrackerModule;
