import toastrActions from "../../../actionConstants/toastrConstant";

const defaultState = {
  showMultipleNotification: false,
  showNotification: false,
  toastrData: [],
  toastrDynamicMessageParams: {},
  toastrMessage: "",
  toastrType: "",
  gtmEvent: "",
  toastrActionInfo: null,
};

/**
 * @description toastr reducer constants
 *
 * @param state : current state of store
 * @param action : action for which reducer will reduce
 */
const toastrReducer = (state = defaultState, action) => {
  switch (action.type) {
    case toastrActions.SHOW_TOASTR:
      return {
        showNotification: true,
        toastrDynamicMessageParams: action.payload.toastrDynamicMessageParams,
        toastrMessage: action.payload.toastrMessage,
        toastrType: action.payload.toastrType,
        translate: action.payload.translate,
        gtmEvent: action.payload.gtmEvent,
        toastrActionInfo: action.payload.toastrActionInfo,
      };
    case toastrActions.CLOSE_TOASTR:
      return {
        showNotification: false,
        toastrMessage: "",
        toastrType: "",
        toastrActionInfo: null,
      };
    case toastrActions.CLOSE_MULTIPLE_TOASTR:
      return {
        showMultipleNotification: false,
        toastrData: [],
      };
    case toastrActions.SHOW_MULTIPLE_TOASTR:
      return {
        showMultipleNotification: true,
        toastrData: action.payload,
      };
    default:
      return state;
  }
};

export default toastrReducer;
