import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M19,2 L19,12.606 L15.364,16.243 L13.2426407,14.1213203 L9,18.363961 L12.636,22 L3,22 L3,2 L19,2 Z M19.6066017,14.8284271 L21.0208153,16.2426407 L15.363961,21.8994949 L13.9497475,20.4852814 L11.8284271,18.363961 L13.2426407,16.9497475 L15.364,19.071 L19.6066017,14.8284271 Z M16,10 L6,10 L6,12 L16,12 L16,10 Z M16,6 L6,6 L6,8 L16,8 L16,6 Z"></path>
    </g>
  </svg>
);

export default SVG;
