import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { default as TextField } from "../Field/field";
import IInputProps from "./iInputProps";

const InputStyle = styled.input`
  width: 100%;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: 700;
  border: 2px solid
    ${(props) =>
      props["data-error"]
        ? props.theme.colors.base.hiltiRedError
        : props.disabled
          ? props.theme.colors.base.heavyConcrete20
          : props.theme.colors.base.heavyConcrete60};
  background-color: ${(props) => props.theme.colors.base.white};
  color: ${(props) => (props.disabled ? props.theme.colors.base.steel20 : props.theme.colors.base.steel)};
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    opacity: 1; /* Firefox */
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }
`;

export interface ITextComponentProps {
  readonly input: any;
  readonly label: any;
  readonly placeholder: any;
  readonly required: any;
  readonly onSelectChange: any;
  readonly selectedValue: any;
  readonly disabled: any;
  readonly ref: any;
  readonly readOnly: any;
  readonly onBlur: any;
  readonly meta: { touched; error; warning };
  readonly type?: string;
  readonly showSuccess?: boolean;
  readonly successMessage?: string;
}

export const TextComponent = (props: ITextComponentProps) => {
  const {
    input,
    label,
    placeholder,
    required,
    onSelectChange,
    selectedValue,
    disabled,
    ref,
    readOnly,
    onBlur,
    meta: { touched, error, warning },
    type,
  } = props;

  return (
    <TextField
      name={input.name}
      htmlFor={input.name}
      label={label}
      error={error}
      touched={touched}
      warning={warning}
      required={required}
      showSuccess={props.showSuccess}
      successMessage={props.successMessage}
    >
      <InputStyle
        {...input}
        ref={ref}
        id={input.name}
        data-error={error && touched ? error : ""}
        placeholder={placeholder}
        type={type || "text"}
        value={selectedValue ? selectedValue : input.value}
        disabled={disabled}
        readonly={readOnly}
        onChange={(val) => {
          input.onChange(val);
          if (onSelectChange) {
            onSelectChange(val);
          }
        }}
        onBlur={(val) => {
          const trimVal = val.target.value.trim();
          input.onBlur(trimVal);
          if (onBlur) {
            onBlur(trimVal);
          }
        }}
      />
    </TextField>
  );
};

class FormInput extends React.PureComponent<IInputProps> {
  render() {
    return (
      <Field
        id={this.props.id || this.props.name}
        name={this.props.name}
        label={this.props.label}
        type={this.props.type}
        placeholder={this.props.placeholder}
        required={this.props.required}
        component={TextComponent}
        validate={this.props.validation}
        ref={this.props.ref}
        {...this.props}
      />
    );
  }
}

export default FormInput;
