import React from "react";
const SVG = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-795.000000, -4134.000000)" fill="#524F53">
        <g transform="translate(792.000000, 4131.000000)">
          <path
            d="M10.0217753,18.455487 C9.57396483,18.3184277 9.14595387,18.1360512 8.74308953,17.9137047 L6.5260077,19.0374931 L4.92361676,17.4351021 L6.04623467,15.1832454 C5.84086048,14.7999279 5.67142235,14.3944735 5.54242373,13.9713855 L3,13.1205853 L3,10.8544624 L5.54451296,10.0217753 C5.67631227,9.59115072 5.85001717,9.17883508 6.06087285,8.78958321 L4.92361676,6.50836375 L6.5260077,4.90597282 L8.79678416,6.05697779 C9.18391899,5.84787725 9.59380519,5.67549983 10.0217753,5.54451296 L10.8544624,3 L13.1205853,3 L13.9713855,5.54242373 C14.3917235,5.67058389 14.7946564,5.83865956 15.1757681,6.04223457 L17.4174582,4.90597282 L19.0198491,6.50836375 L17.9095335,8.73554334 C18.1337711,9.14060774 18.3175716,9.57116771 18.455487,10.0217753 L21,10.8544624 L21,13.1205853 L18.4575763,13.9713855 C18.3224643,14.414524 18.1429891,14.838318 17.9243251,15.2375929 L19.0198491,17.4351021 L17.4174582,19.0374931 L15.229769,17.9286032 C14.8327831,18.1453308 14.4116307,18.3233465 13.9713855,18.4575763 L13.1205853,21 L10.8544624,21 L10.0217753,18.455487 Z M12,14.25 C13.2426407,14.25 14.25,13.2426407 14.25,12 C14.25,10.7573593 13.2426407,9.75 12,9.75 C10.7573593,9.75 9.75,10.7573593 9.75,12 C9.75,13.2426407 10.7573593,14.25 12,14.25 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
