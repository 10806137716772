import React from "react";
// @ts-ignore
import styled from "styled-components";
import IconComponent from "../icons/iconComponent/iconComponent";
import { visibleDependencyResolver } from "./actionBarUtil";
import IActionViewsProps from "./iactionViewsProps";

const ActionViewWrapper = styled.div`
  display: flex;
  .action-wrapper {
    padding: 8px;
    &.active {
      background-color: ${(props) => props.theme.colors.base.heavyConcrete20};
    }
  }
`;

export class ActionView extends React.PureComponent<IActionViewsProps> {
  renderActions = (actionViewItem, index) => {
    if (actionViewItem.type === "TextWithIcon") {
      return this.renderTextWithIcon(actionViewItem, index);
    }
    return null;
  };

  isActiveView = (isDefaultActive, activeIndex, currentIndex) => {
    if (activeIndex === null && isDefaultActive) {
      return true;
    }
    return activeIndex === currentIndex;
  };

  actionItemNameConversion = (actionViewItem) => `actionBar:${actionViewItem.name.toUpperCase()}`;
  renderTextWithIcon = (actionViewItem, index) => {
    const isActiveClass = this.isActiveView(actionViewItem.isDefaultActive, this.props.activeIndex, index)
      ? actionViewItem.showActiveBackground
      : false;
    const isVisible = visibleDependencyResolver(actionViewItem, this.props.dependencies);
    return isVisible ? (
      <IconComponent
        key={`${actionViewItem.name}_${index}`}
        icon={actionViewItem.name}
        labelText={this.props.t(this.actionItemNameConversion(actionViewItem))}
        id={actionViewItem.name}
        className={`action-wrapper ${isActiveClass ? "active" : ""}`}
        handleClick={(_e, element) => this.props.onActionClicked(actionViewItem.name, element, index)}
        isIconDisabled={false}
      />
    ) : null;
  };

  render() {
    const CheckAuthorization = this.props.checkAuthorization;

    return (
      <ActionViewWrapper id="ActionViewWrapper">
        {this.props.actionViews &&
          this.props.actionViews.map((item, index) => {
            return item.authKey ? (
              <CheckAuthorization
                key={item.authKey}
                yes={() => this.renderActions(item, index)}
                permissionKey={item.authKey}
              />
            ) : (
              this.renderActions(item, index)
            );
          })}
      </ActionViewWrapper>
    );
  }
}

export default ActionView;
