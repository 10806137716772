import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Final-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-New-vs-Old" transform="translate(-3182.000000, -3708.000000)">
        <g id="Icons/Activate" transform="translate(3182.000000, 3708.000000)">
          <rect id="Rectangle" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
          <path
            d="M6.73998561,6.69559788 L23.625,12.9939469 L15.9891696,15.7342973 L13.1848754,23.1964309 L6.73998561,6.69559788 Z M10.0698565,9.95037367 L13.162868,17.8695464 L14.4261324,14.5093327 L14.5090259,14.2883205 L14.7351952,14.2073172 L18.173552,12.9731167 L10.0698565,9.95037367 Z M0.904716764,6.43199407 L4.85857652,7.46727025 L4.32885975,9.3991219 L0.375,8.36384572 L0.904716764,6.43199407 Z M4.13496996,2.18935338 L7.02939618,5.0177805 L5.58218307,6.43199407 L2.68775685,3.60356694 L4.13496996,2.18935338 Z M10.0448212,0.75 L10.0448212,4.75 L7.99815277,4.75 L7.99815277,0.75 L10.0448212,0.75 Z"
            id="Combined-Shape"
            fill="#524F53"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
