import { SquareIcon } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import StyledBoldText from "../../Styled/BoldText/styledBoldText";
import StyledBeforeIconContainer from "../../Styled/Containers/BeforeIconContainer/styledBeforeIconContainer";
import StyledRowsWrapper from "../../Styled/RowsWrapper/styledRowsWrapper";
import IIconBoldProps from "./iIconBold";
import Beta from "../../../beta/beta";

/**
 * @description - IconBold is a Styled Component that consist of 2 things:
 * 1. Icon: An Icon that will be visible at the left side of the content (::before).\
 * 2. Bold Text: This is the text with bold font-familiy and it is the bottom row.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 *
 * @const IconBold
 * @interface IIconBoldProps
 */
/**
 * Styled Container
 */
const StyledBeforeIconContainerWrapper = styled(StyledBeforeIconContainer)`
  padding: 0;
  &&:last-child {
    padding: 0px;
  }
`;
export const StyledBoldColor = styled(StyledBoldText)`
  && {
    padding-right: ${({ isPanelOverlap }) => (isPanelOverlap ? "8px" : "0")};
    color: ${(props) => props.theme.colors.innerHeadingText.color};
  }
`;
const IconBold = (props: IIconBoldProps) => {
  const { id, iconUrl, boldId, boldText, style, withBeta, isPanelOverlap } = props;
  return (
    <StyledBeforeIconContainerWrapper style={style} id={id}>
      <SquareIcon name={iconUrl} className="mr-16" />
      <StyledRowsWrapper>
        <StyledBoldColor id={boldId} isPanelOverlap={isPanelOverlap}>
          {boldText}
          {withBeta && <Beta />}
        </StyledBoldColor>
      </StyledRowsWrapper>
    </StyledBeforeIconContainerWrapper>
  );
};

/**
 * @export IconBold - Styled Component.
 */
export default IconBold;
