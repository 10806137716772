import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import ApplicationConstants from "../../constant/applicationConstants";

export const StyledDiv = styled.label`
  display: flex;
  position: relative;
  cursor: ${(props: ICheckboxProps) => (props.disabled ? "not-allowed;" : "pointer")};
  opacity: ${(props: ICheckboxProps) => (props.disabled ? ApplicationConstants.OPACITY.O50 : 1)};
  align-items: center;
`;
export const StyledInput = styled.input.attrs((props) => ({
  indeterminate: (props as any).indeterminate,
  type: "checkbox",
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 100%;
  z-index: -1;
  &:checked ~ span:after {
    content: "";
    position: absolute;
    color: ${(props) => props.theme.colors.base.steel};
    height: 6px;
    margin: 0;
    top: 1px;
  }
  &:checked + span:after {
    transform: ${(props) => (props.indeterminate ? "none" : "rotate(45deg)")};
    border-width: ${(props) => (props.indeterminate ? "0 0 2px 0" : "0 2px 2px 0")};
    width: ${(props) => (props.indeterminate ? "10px" : "5px")};
    height: ${(props) => (props.indeterminate ? "6px" : "9px")};
    left: ${(props) => (props.indeterminate ? "3px" : "5px")};
  }
`;
export const StyledLabel = styled.span<{ disabled?: boolean; error?: boolean }>`
  margin: 2px;
  border: 2px solid
    ${({ disabled, error, theme }) => {
      if (disabled) {
        return theme.colors.base.steel20;
      }
      return error ? theme.colors.base.hiltiRed : theme.colors.base.steel;
    }};
  min-height: 16px;
  min-width: 16px;
  background-color: ${(props) => props.theme.colors.base.white};
  box-sizing: content-box;
  position: relative;
  &:after {
    left: 7px;
    width: 6px;
    height: 13px;
    border: solid ${(props) => props.theme.colors.base.steel};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledText = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: 300;
  line-height: 17px;
  font-size: ${(props) => props.theme.fontSize.base};
  margin-left: 12px;
  word-break: break-word;
  white-space: normal;
  &.lbl {
    flex: 1;
  }
  &.bold {
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
  &.truncated {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: unset;
    white-space: nowrap;
  }
`;

const ToggleStyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  cursor: ${(props: ICheckboxProps) => (props.disabled ? "not-allowed;" : "pointer")};
  opacity: ${(props: ICheckboxProps) => (props.disabled ? ApplicationConstants.OPACITY.O50 : 1)};
  height: 34px;
`;

const ToggleStyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + span {
    background-color: #19af37;
    &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }
`;

const ToggleStyledSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;
const Asterisk = styled.sup`
  margin-left: 3px;
  color: ${(props) => props.theme.colors.base.hiltiRed};
`;

interface ICheckboxProps {
  checked?: boolean;
  isLabelBold?: boolean;
  onChange?: (event: any) => void;
  isFormControl?: boolean;
  name?: string;
  label?: any;
  style?: React.CSSProperties;
  selectedValue?: boolean;
  toggleSwitch?: boolean;
  id?: string;
  disabled?: boolean;
  toggleSpanId?: string;
  indeterminate?: boolean;
  checboxChange?: any;
  noDisableDesign?: boolean;
  validation?: any[];
  wrapperStyles?: any;
  truncateLabel?: boolean;
  error?: boolean;
  required?: boolean;
}

export const ErrorLabel = styled.div`
  color: #ff0000;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: 16px;
  min-height: 14px;
  max-width: 100%;
  padding-top: 2px;
`;

export const CheckBoxElement = (props) => {
  const {
    meta: { touched, error },
  } = props;

  const checkValidation = () => {
    if (props.hideValidation) {
      return " ";
    }
    return touched && error ? (
      <ErrorLabel id={`${props.input.name}Err`}>{error}</ErrorLabel>
    ) : (
      <ErrorLabel id={`${props.input.name}Err`} />
    );
  };
  return (
    <>
      <StyledDiv disabled={props.disabled} className="checkbox">
        <StyledInput
          {...props.input}
          id={`${props.input.name}Ck`}
          checked={Boolean((props.input && props.input.value) || props.value)}
          onChange={(event) => {
            event.stopPropagation();
            props.input.onChange(event.target.checked);
            if (props.checboxChange) {
              props.checboxChange(event.target.checked);
            }
          }}
          disabled={props.disabled}
          indeterminate={props.indeterminate}
        />
        <StyledLabel className="styledCheckbox" />
        {props.label && (
          <StyledText
            className={`${props.truncateLabel ? "truncated " : ""}styledText lbl`}
            id={`${props.input.name}Lbl`}
          >
            {props.label}
          </StyledText>
        )}
      </StyledDiv>
      {checkValidation()}
    </>
  );
};

export const ToggleSwitchElement = (props) => {
  const checked = Boolean((props.input && props.input.value) || props.value || props.checked);
  return (
    <ToggleStyledLabel disabled={props.disabled && !props.noDisableDesign}>
      <ToggleStyledInput
        {...props.input}
        id={`${props.input.name}Ck`}
        name={props.name}
        type="checkbox"
        disabled={props.disabled}
        checked={checked}
        className={checked ? "switch-on" : "switch-off"}
        onChange={(event) => {
          props.input.onChange(event.target.checked);
          if (props.checboxChange) {
            props.checboxChange(event.target.checked);
          }
        }}
      />
      <ToggleStyledSpan />
    </ToggleStyledLabel>
  );
};

export class Checkbox extends React.PureComponent<ICheckboxProps> {
  render() {
    const {
      checboxChange,
      onChange,
      id,
      toggleSpanId,
      label,
      indeterminate,
      isFormControl,
      toggleSwitch,
      disabled,
      noDisableDesign,
      checked,
      isLabelBold = false,
      name,
      validation,
      wrapperStyles,
      truncateLabel,
      error,
      required,
    } = this.props;
    return !isFormControl ? (
      !toggleSwitch ? (
        <StyledDiv disabled={disabled && !noDisableDesign} style={wrapperStyles}>
          <StyledInput
            checked={checked}
            onChange={(event) => {
              event.stopPropagation();
              return onChange?.(event.target.checked);
            }}
            id={id}
            disabled={disabled}
            indeterminate={indeterminate}
            data-testid="checkbox"
          />
          <StyledLabel error={error} className="styledCheckbox" disabled={disabled && !noDisableDesign} />
          {label ? (
            <>
              <StyledText
                className={`styledText${isLabelBold ? " bold" : ""}${truncateLabel ? " truncated" : ""}`}
                id={`${name}Lbl`}
              >
                {label}
              </StyledText>
              {required && <Asterisk>*</Asterisk>}
            </>
          ) : null}
        </StyledDiv>
      ) : (
        <ToggleStyledLabel>
          <ToggleStyledInput
            id={name}
            {...this.props}
            checked={checked}
            type="checkbox"
            className={checked ? "switch-on" : "switch-off"}
          />
          <ToggleStyledSpan id={toggleSpanId} />
        </ToggleStyledLabel>
      )
    ) : (
      <Field
        name={name}
        component={!toggleSwitch ? CheckBoxElement : ToggleSwitchElement}
        label={label}
        id={name}
        checboxChange={checboxChange}
        validate={validation}
        {...this.props}
      />
    );
  }
}

export default Checkbox;
