import { ISagaModule } from "redux-dynamic-modules-saga";
import IMasterDataModuleState from "./iMasterDataModuleState";
import masterDataReducer from "./masterDataReducer";
import masterDataSaga from "./masterDataSaga";

export const masterDataModule: ISagaModule<IMasterDataModuleState> = {
  id: "masterDataModule",
  reducerMap: {
    MasterData: masterDataReducer,
  },
  sagas: [masterDataSaga],
};

export default masterDataModule;
