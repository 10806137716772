import React from "react";
import { FieldArray } from "redux-form";
import AttachmentFormInput from "./attachmentFormInput";
import AttachmentInput from "./attachmentInput";

class Attachment extends React.PureComponent<any> {
  fields = [];

  setFields = (fields) => {
    this.fields = fields;
  };

  pushItem = (item) => {
    this.fields.push(item);
  };

  render() {
    const {
      allowDownloadAttachment,
      downloadAttachement,
      id,
      name,
      label,
      type,
      placeholder,
      required,
      validation,
      ref,
      attachmentName,
      isFormControl,
    } = this.props;
    return isFormControl ? (
      <FieldArray
        name={name}
        component={AttachmentInput}
        setFields={this.setFields}
        placeholder={placeholder}
        validate={validation}
        responseFormatMapper={this.props.responseFormatMapper}
        downloadAttachement={downloadAttachement}
        allowDownloadAttachment={allowDownloadAttachment}
      />
    ) : (
      <AttachmentFormInput
        id={id || name}
        data-testid={id || name}
        name={name}
        label={label}
        type={type}
        placeholder={placeholder}
        required={required}
        ref={ref}
        attachmentName={attachmentName}
        responseFormatMapper={this.props.responseFormatMapper}
        allowDownloadAttachment={allowDownloadAttachment}
        downloadAttachement={downloadAttachement}
      />
    );
  }
}

export default Attachment;
