import ICompanyModel from "../../../models/company/iCompanyModel";

// @ts-ignore
import { HiltiCloudActions } from "custom-actions";

export const searchRepairOrder = (assetDetails: any, companyDetails: ICompanyModel) => ({
  payload: {
    assetDetails,
    companyDetails,
  },
  type: HiltiCloudActions.HC_REPAIR_ORDER_SEARCH,
});
