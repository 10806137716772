import { IconName } from "am-web-ui-shared/components";
import { LocaleManager } from "am-web-ui-shared/helpers";
import React from "react";
import { Trans } from "react-i18next";
import { IconBold, IconNormalBold } from "../../../../../common/components/DetailsPanel";
import { UpfrontExpansionPanel } from "../../../../components/DetailsPanel/index";
import { IServiceDetail } from "./iServiceDetail";

const ServiceDatesPanel: React.FC<IServiceDetail> = (props: IServiceDetail) => {
  const { selectedRow } = props;
  return (
    (selectedRow.scheduleDate || selectedRow.completionDate) && (
      <UpfrontExpansionPanel
        panelId="serviceDatesSection"
        detailsData={selectedRow}
        summaryComponents={[date]}
        detailComponents={[scheduledDate, completionDate]}
        associatedRowId={selectedRow.id}
      />
    )
  );
};

export const date = () => {
  return (
    <IconBold
      id="pnlSummaryserviceDates"
      iconUrl={IconName.Calendar}
      boldId="lblSummaryserviceDates"
      boldText={<Trans>{"certificateTemplates:DATES"}</Trans>}
    />
  );
};

/**
 * @description - Function to get scheduledDate.
 *
 * @param {object} data - Service detail object.
 */
export const scheduledDate = (data, t) => {
  return (
    data.scheduleDate && (
      <IconNormalBold
        id="pnlDatesScheduledDate"
        normalId="lblCost"
        normalText={t("services:FIELD_NAME_SCHEDULEDATE")}
        boldId="txtCost"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(data.scheduleDate)}
      />
    )
  );
};

/**
 * @description - Function to get completionDate.
 *
 * @param {object} data - Service detail object.
 */
export const completionDate = (data, t) => {
  return (
    data.completionDate && (
      <IconNormalBold
        id="pnlDatesCompletionDate"
        normalId="lblCompletionDate"
        normalText={t("services:FIELD_NAME_COMPLETIONDATE")}
        boldId="txtCompletionDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(data.completionDate)}
      />
    )
  );
};

export default ServiceDatesPanel;
