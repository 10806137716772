import { ISagaModule } from "redux-dynamic-modules-saga";
import IChargesModuleState from "./iChargesModuleState";
import chargesReducer from "./chargesReducer";
import chargesSaga from "./chargesSaga";
import ChargeLocationsListReducer from "./chargeLocationsReducerRoot";

const chargesModule: ISagaModule<IChargesModuleState> = {
  id: "chargesModule",
  reducerMap: {
    Charges: chargesReducer,
    ChargeLocations: ChargeLocationsListReducer,
  },
  sagas: [chargesSaga],
};

export default chargesModule;
