/**
 * Represents a generic list from API
 */
export default class PendingChargesListModel<T> {
  constructor(
    public isRecalculationInProgress: boolean,
    public totalRecords: number,
    public limit: number,
    public offset: number,
    public response: T[],
    public error: { response: { data: { errors: [{ message: string }] } } },
  ) {}
}
