import React from "react";
const SVG = () => (
  <svg width="19px" height="22px" viewBox="0 0 19 22" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2013.000000, -3314.000000)">
        <g transform="translate(2008.000000, 3312.000000)">
          <g id="location-empty">
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M17.7751601,13.5325194 L17.4142136,13.1715729 L13.1715729,17.4142136 L14.5610956,18.8037363 C13.1743785,20.6997952 12,22 12,22 C12,22 5,14.25 5,9 C5,5.13 8.13,2 12,2 C15.87,2 19,5.13 19,9 C19,10.3980739 18.5035909,11.9734368 17.7751601,13.5325194 Z M20.9497475,19.5355339 L23.0710678,21.6568542 L21.6568542,23.0710678 L19.5355339,20.9497475 L17.4142136,23.0710678 L16,21.6568542 L18.1213203,19.5355339 L16,17.4142136 L17.4142136,16 L19.5355339,18.1213203 L21.6568542,16 L23.0710678,17.4142136 L20.9497475,19.5355339 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
