import React from "react";
import styled from "styled-components";
import { IListProps } from "./iListProps";

const listViewFlexDirection = (props: IListProps) => (props.direction ? `${props.direction}` : "");
const listViewJustifyContent = (props: IListProps) => (props.direction ? `${props.justify}` : "");

const heightOffset = (props) => `calc(100vh - ${props.top}px - ${props.modalFooter ? props.modalFooter : 0}px)`;
const ListView = styled.div<any>`
  height: ${(props) => heightOffset(props)};
  display: flex;
  flex-direction: ${listViewFlexDirection};
  justify-content: ${listViewJustifyContent};
`;

/**
 * @description getViewPortWidth
 * @param width : number
 */
export function getViewPortWidth(width?) {
  const widthPx = `${width}px`;

  return `width: calc(${widthPx || "100vw"});`;
}

class ListContainer extends React.Component<IListProps> {
  state = { offSetTop: 0 };
  gridViewRef;

  setRef = (ele) => {
    this.gridViewRef = ele;
  };
  componentDidMount() {
    this.setState({ offSetTop: this.gridViewRef?.offsetTop });
  }
  componentDidUpdate() {
    if (this.state.offSetTop !== this.gridViewRef.offsetTop && this.gridViewRef.offsetTop !== 0) {
      this.setState({ offSetTop: this.gridViewRef.offsetTop });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ListView
          ref={this.setRef}
          top={this.state.offSetTop}
          direction={this.props.direction}
          justify={this.props.justify}
          modalFooter={this.props.modalFooter}
          className="list-container"
        >
          {this.props.children}
        </ListView>
      </React.Fragment>
    );
  }
}
export default ListContainer;
