import React from "react";
import "./tagsInput.css";
import styled from "styled-components";
import { IMultiTagInputProps } from "./iMultiTaginput";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import Icons from "../../components/icons/svgs";

const TagsWrapper = styled.div`
  flex-wrap: wrap;
  max-width: 100%;
`;
const TagWrapper = styled.div`
  border-radius: 20px;
  cursor: default;
  background-color: rgb(229, 229, 229) !important;
  margin: 3px !important;
  display: inline-flex;
  min-width: 0px;
  box-sizing: border-box;
  max-width: 100%;
`;
const TagContent = styled.div`
  border-radius: 2px;
  color: rgb(51, 51, 51);
  font-size: 85%;
  overflow: hidden;
  padding: 3px 3px 3px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;
const TagRemove = styled.div`
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  cursor: text;
`;
const ClearWrapper = styled.div`
  width: 5%;
  position: relative;
`;

const StyledInfo = styled.span`
  color: #524f53;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`;
const ErrorWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.hiltiRed};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`;
const StyledInput = styled.input`
  background: transparent;
  border: 0;
  outline: none;
  padding: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  min-width: 200px;
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: 400;
  font-family: ${(props) => (props["data-value"] ? props.theme.fontBold.fontFamily : props.theme.fontRoman.fontFamily)};
  width: 100%;
  height: auto;
  padding-left: 16px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  word-wrap: normal;
  border: 2px solid
    ${(props) =>
      props["data-error"] ? props.theme.colors.base.hiltiRedError : props.theme.colors.base.heavyConcrete60};
  background-color: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.base.steel};
`;
const StyledClearAll = styled.span`
  position: absolute;
  right: 5px;
  top: 40%;
  cursor: pointer;
`;

const DEFAULTADDKEY = 188;

declare global {
  interface Window {
    clipboardData: any;
  }
}
const focusedClassName = "";
class MultiTagInput extends React.PureComponent<IMultiTagInputProps> {
  static defaultProps = {
    addKeys: [DEFAULTADDKEY],
    errorMessage: {
      maxBadgeMessage: "Max limit reached",
      validationMessage: "Please enter valid text",
    },
    showClearAll: true,
  };
  state: any = {
    unTagedText: "",
    isFocused: false,
    showError: false,
  };

  wrapperRef;
  inputRef;
  className = this.props.wrapperClassName;

  componentDidUpdate(_prevProps, prevState) {
    if (this.props.setError && prevState.showError !== this.state.showError) {
      this.props.setError(this.state.showError);
    }
  }

  uniq = (arr) => {
    const out = [];

    /*
      use tag display name instead of row.
    */
    for (const row of arr) {
      if (out.indexOf(row) === -1) {
        out.push(row);
      }
    }

    return out;
  };

  getTagDisplayValue = (tag) => {
    const { tagDisplayProp } = this.props;

    if (tagDisplayProp) {
      return tag[tagDisplayProp];
    }

    return tag;
  };

  makeTag = (tag) => {
    const { tagDisplayProp } = this.props;

    if (tagDisplayProp) {
      return {
        [tagDisplayProp]: tag,
      };
    }

    return tag;
  };

  removeTag = (index) => {
    let propValue = this.props.value;
    if (!propValue) {
      propValue = [];
    }
    if (this.isMaxLimitReached()) {
      this.setState({ showError: false });
    }
    const value = propValue.concat([]);
    if (index > -1 && index < value.length) {
      const changed = value.splice(index, 1);
      this.props.onChange(value, { changed, index: [index] });
    }
  };

  clearInput = () => {
    this.setState({
      unTagedText: "",
    });
  };

  tag = () => {
    return this.state.unTagedText;
  };

  addTags = (tags) => {
    let { onChange, onValidationReject, onlyUnique, maxTags, value } = this.props;
    if (!value) {
      value = [];
    }
    if (onlyUnique) {
      tags = this.uniq(tags);
      tags = tags.filter((tag) =>
        value.every((currentTag) => this.getTagDisplayValue(currentTag) !== this.getTagDisplayValue(tag)),
      );
    }

    const rejectedTags = tags.filter((tag) => !this.validate(this.getTagDisplayValue(tag)));
    tags = tags.filter((tag) => this.validate(this.getTagDisplayValue(tag)));
    tags = tags.filter((tag) => {
      const tagDisplayValue = this.getTagDisplayValue(tag);
      if (typeof tagDisplayValue.trim === "function") {
        return tagDisplayValue.trim().length > 0;
      } else {
        return tagDisplayValue;
      }
    });

    if (maxTags >= 0) {
      const remainingLimit = Math.max(maxTags - value.length, 0);
      tags = tags.slice(0, remainingLimit);
    }

    if (onValidationReject && rejectedTags.length > 0) {
      onValidationReject(rejectedTags);
    }

    if (tags.length) {
      const newValue = value.concat(tags);
      const indexes = [];
      for (let i = 0; i < tags.length; i++) {
        indexes.push(value.length + i);
      }
      onChange(newValue, { tags, index: indexes });
      this.clearInput();
      return true;
    }

    if (rejectedTags.length > 0) {
      this.setState({ showError: true });
      return false;
    }

    this.clearInput();
    return false;
  };

  validate = (tag) => {
    const { validationRegex } = this.props;
    // if user didnt give any validation then all tags are valid.
    if (!validationRegex) {
      return true;
    }

    return validationRegex && validationRegex.test(tag);
  };

  focus = () => {
    if (this.inputRef && typeof this.inputRef.focus === "function") {
      this.inputRef.focus();
    }

    this.handleOnFocus();
  };

  accept = () => {
    let tag = this.tag();

    if (tag !== "") {
      tag = this.makeTag(tag);
      return this.addTags([tag]);
    }

    return false;
  };

  getClipboardData(e) {
    if (window.clipboardData) {
      return window.clipboardData.getData("Text");
    }

    if (e.clipboardData) {
      return e.clipboardData.getData("text/plain");
    }

    return "";
  }

  defaultPasteSplit(data) {
    return data.split(" ").map((d) => d.trim());
  }

  handlePaste = (e) => {
    const { addOnPaste } = this.props;

    if (!addOnPaste) {
      return;
    }

    e.preventDefault();

    const data = this.getClipboardData(e);
    const tags = this.defaultPasteSplit(data).map((tag) => this.makeTag(tag));

    this.addTags(tags);
  };

  isMaxLimitReached = () => {
    return this.props.maxTags === (this.props.value && this.props.value.length);
  };

  handleKeyDown = (e) => {
    if (e.defaultPrevented) {
      return;
    }
    const { value, removeKeys, addKeys } = this.props;
    const tag = this.tag();
    const empty = tag === "";
    const keyCode = e.keyCode;
    const key = e.key;
    const add = addKeys.indexOf(keyCode) !== -1 || addKeys.indexOf(key) !== -1;
    const remove = removeKeys.indexOf(keyCode) !== -1 || removeKeys.indexOf(key) !== -1;

    if (this.isMaxLimitReached() && !remove) {
      this.setState({ showError: true });
      this.clearInput();
      e.preventDefault();
      return;
    }

    this.handleAddKey(e, add);

    if (remove && value.length > 0 && empty) {
      e.preventDefault();
      this.removeTag(value.length - 1);
    }
  };

  handleAddKey = (e, add) => {
    let added = false;
    if (add) {
      added = this.accept();
      e.preventDefault();
    } else {
      this.setState({ showError: false });
    }
    if (added) {
      this.setState({ showError: false });
    }
  };

  handleClick = (e) => {
    if (e.target === this.wrapperRef) {
      this.focus();
    }
  };

  handleChange = (e) => {
    const tag = e.target.value;

    this.setState({
      unTagedText: tag,
    });
    e.preventDefault();
  };

  handleOnFocus = () => {
    this.setState({ isFocused: true });
  };

  handleOnBlur = (e) => {
    this.setState({ isFocused: false });

    if (e == null) {
      return;
    }
    if (this.isMaxLimitReached()) {
      this.setState({ showError: false });
    }
    if (this.props.addOnBlur && e.target.value) {
      const tag = this.makeTag(e.target.value);
      this.addTags([tag]);
    }
  };

  handleRemove = (tag) => {
    const tags = this.props.value;
    const index = tags.findIndex((tagVal) => this.getTagDisplayValue(tagVal) === this.getTagDisplayValue(tag));
    this.removeTag(index);
  };

  clearAll = () => {
    this.props.onChange([]);
    this.clearInput();
    this.setState({ showError: false });
  };

  getInputInitialProps = () => {
    const { onChange, onFocus, onBlur, ...otherInputProps } = this.props.inputProps;

    const updatedProps = {
      ...otherInputProps,
    };

    if (this.props.disabled) {
      updatedProps.disabled = true;
    }

    return updatedProps;
  };

  renderTag = (tagProps) => {
    const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = tagProps;
    return (
      <TagWrapper key={key} {...other}>
        <TagContent>{getTagDisplayValue(tag)}</TagContent>
        <TagRemove onClick={() => onRemove(tag)}>{!disabled && <Icons name={IconName.RemoveCircle} />}</TagRemove>
      </TagWrapper>
    );
  };

  renderInput = (inputRenderProps) => {
    const { onChange, placeholder, ...other } = inputRenderProps;
    const { value = [] } = this.props;
    return (
      <StyledInput
        type="text"
        placeholder={!value.toString() && placeholder}
        onChange={onChange}
        value={this.state.unTagedText}
        {...other}
      />
    );
  };
  getTagComponents = () =>
    (this.props.value || []).map((tag, index) => {
      return this.renderTag({
        key: index,
        tag,
        onRemove: this.handleRemove,
        disabled: this.props.disabled,
        getTagDisplayValue: this.getTagDisplayValue,
        ...this.getInputInitialProps(),
      });
    });
  getInputComponent = () =>
    this.renderInput({
      ref: (r) => {
        this.inputRef = r;
      },
      value: this.tag(),
      onPaste: this.handlePaste,
      onKeyDown: this.handleKeyDown,
      onChange: this.handleChange,
      onFocus: this.handleOnFocus,
      onBlur: this.handleOnBlur,
      placeholder: this.props.placeholder,
    });
  info = () => {
    const { infoText } = this.props;
    return infoText && <StyledInfo>{infoText}</StyledInfo>;
  };
  getClearAllIcon = () => {
    const { value } = this.props;
    const hasTag = !!(value && value.length);
    return (
      hasTag && (
        <StyledClearAll>
          <SquareIcon name={IconName.RemoveCircle} className="small" onClick={this.clearAll} imageSize={16} />
        </StyledClearAll>
      )
    );
  };

  showError = () => {
    const { errorMessage } = this.props;
    const errorText = this.isMaxLimitReached() ? errorMessage.maxBadgeMessage : errorMessage.validationMessage;
    return this.state.showError && <ErrorWrapper>{errorText}</ErrorWrapper>;
  };

  renderLayout = (tags, input, clear) => {
    return (
      <>
        <ContentWrapper onClick={this.focus}>
          <TagsWrapper>
            {tags}
            {input}
          </TagsWrapper>
        </ContentWrapper>
        {this.props.showClearAll && <ClearWrapper>{clear}</ClearWrapper>}
      </>
    );
  };
  render() {
    if (this.state.isFocused) {
      this.className += ` ${focusedClassName}`;
    }
    return (
      <>
        <StyledWrapper
          ref={(r) => {
            this.wrapperRef = r;
          }}
          onClick={this.handleClick}
          className={this.className}
          data-error={this.state.showError}
        >
          {this.renderLayout(this.getTagComponents(), this.getInputComponent(), this.getClearAllIcon())}
        </StyledWrapper>
        {this.showError()}
        {this.info()}
      </>
    );
  }
}

export default MultiTagInput;
