import React from "react";
import { StyleNormalTextArea } from "../../../../../components/DetailsPanel/index";

/**
 * @description - Render the notes section of side panel.
 */
const notes = (data) => {
  return <StyleNormalTextArea headingId="headAssetNotes" textId="txtNotes" text={data.notes} />;
};

/**
 * @description - Exporting components that are used for notes section used on asset details.
 */
export default notes;
