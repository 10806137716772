import AuthorizationCore from "./authorizationCore";

interface IAuthorization {
  readonly permissionKey: string;
  readonly yes: any;
  readonly no?: any;
  readonly validation?: any;
}

const CheckAuthorization = (props: IAuthorization) => {
  if (AuthorizationCore.checkPermission(props.permissionKey, props.validation)) {
    return props.yes();
  } else {
    return props.no();
  }
};

CheckAuthorization.defaultProps = {
  no: () => null,
  validation: () => true,
};

export default CheckAuthorization;
