import React from "react";
// @ts-ignore
import styled from "custom-styled-components";
import ILogoProps from "./Logo/iLogoProps";

const StyledLogo = styled("div")`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

const StyledText = styled.span.attrs({
  id: "logoText",
})`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  cursor: default;
`;

const LogoImage = styled.a`
  width: 105px;
  height: 24px;
  display: inline-flex;
  margin-right: 16px;
`;

export default function Logo({ logoText }: ILogoProps) {
  return (
    <StyledLogo>
      <LogoImage>
        <svg width="104px" height="24px" viewBox="0 0 208 48" version="1.1">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="AM-3.0---Web-Icons-Copy" transform="translate(-162.000000, -48.000000)">
              <g id="Cover">
                <g id="Group" transform="translate(162.000000, 43.000000)">
                  <g id="Group-2">
                    <g id="Hilti_Logo" transform="translate(0.000000, 5.000000)">
                      <polygon id="Fill-2" fill="#D2051E" points="0 48 208 48 208 0 0 0"></polygon>
                      <path
                        d="M120,22.4 L140.8,22.4 L140.8,36.8 L155.2,36.8 L155.2,22.4 L176,22.4 L176,11.2 L120,11.2 L120,22.4 Z M86.4,36.8 L128,36.8 L128,25.6 L99.2,25.6 L99.2,11.2 L86.4,11.2 L86.4,36.8 Z M16,36.8 L30.4,36.8 L30.4,28.8965104 L52.8,28.8965104 L52.8,36.8 L67.2,36.8 L67.2,11.2 L52.8,11.2 L52.8,19.2 L30.4,19.2 L30.4,11.2 L16,11.2 L16,36.8 Z M179.2,36.8 L192,36.8 L192,11.2 L179.2,11.2 L179.2,36.8 Z M70.4,36.8 L83.2,36.8 L83.2,11.2 L70.4,11.2 L70.4,36.8 Z"
                        id="Fill-3"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </LogoImage>
      <StyledText id="logoText">{logoText}</StyledText>
    </StyledLogo>
  );
}
