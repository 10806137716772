import { colorPalette } from "am-web-ui-shared/materials";
import hiltiTheme from "@am/web-ui-components/theme";
import { createTheme } from "@mui/material/styles";

const colors = {
  base: {
    black: colorPalette.black,
    black10: colorPalette.black10,
    blackrgba: colorPalette.blackrgba,
    blackrgba20: colorPalette.blackrgba20,
    borderColor: colorPalette.heavyConcrete20,
    darkPink: colorPalette.darkPink,
    green: colorPalette.green,
    greyrgba: colorPalette.greyrgba,
    heavyConcrete: colorPalette.heavyConcrete,
    heavyConcrete10: colorPalette.heavyConcrete10,
    heavyConcrete20: colorPalette.heavyConcrete20,
    heavyConcrete30: colorPalette.heavyConcrete30,
    heavyConcrete40: colorPalette.heavyConcrete40,
    heavyConcrete60: colorPalette.heavyConcrete60,
    heavyConcreteAlpha: colorPalette.heavyConcreteAlpha,
    hiltiOffWhite: colorPalette.offWhite,
    hiltiRed: colorPalette.hiltiRed,
    hiltiRedError: colorPalette.hiltiRedError,
    lightGrey: colorPalette.lightGrey,
    lightGreyColor: colorPalette.lightGreyColor,
    lightGreyFadeColor: colorPalette.lightGreyFadeColor,
    lightRedColor: colorPalette.lightRedColor,
    lightBlue: colorPalette.lightBlue,
    linkColor: colorPalette.steel,
    statusBlue: colorPalette.statusBlue,
    mapAccuracyBlue: colorPalette.mapAccuracyBlue,
    orange: colorPalette.orange,
    pink: colorPalette.pink,
    placeholderColor: colorPalette.placeholderColor,
    steel: colorPalette.steel,
    steel10: colorPalette.steel10,
    steel20: colorPalette.steel20,
    steel40: colorPalette.steel40,
    titleColor: colorPalette.titleColor,
    transparent: colorPalette.transparent,
    warmConcrete100: colorPalette.warmConcrete100,
    white: colorPalette.white,
    whiteFaded: colorPalette.whiteFaded,
    customLightGrey: colorPalette.customLightGrey,
    greyDark: colorPalette.greyDark,
    dashboardGreen: colorPalette.dashboardGreen,
    dashboardRed: colorPalette.dashboardRed,
    highlightColor: colorPalette.highlightColor,
  },
  divBorder: {
    backgroundLightGrey: colorPalette.customLightGrey,
    darkGrey: colorPalette.customDarkGrey,
    warmConcrete: colorPalette.warmConcrete,
  },
  headingText: {
    color: colorPalette.steel,
  },
  innerHeadingText: {
    color: colorPalette.steel,
  },
};

const customTheme = {
  allocationStatus: {
    Available: {
      backgroundColor: colorPalette.statusLightGreen,
      color: colorPalette.statusgreen,
    },
    Disposed: {
      backgroundColor: colorPalette.pink,
      color: colorPalette.breakRed,
    },
    Main: {
      backgroundColor: colorPalette.lightBlue,
      color: colorPalette.statusBlue,
    },
  },
  assetSummary: {
    border: colorPalette.heavyConcrete,
  },
  assetTemplateRow: {
    backgroundColor: colorPalette.white,
    backgroundColorHover: colorPalette.heavyConcrete10,
    borderColor: colorPalette.heavyConcrete20,
  },
  boldBlackText: {
    color: colorPalette.black,
  },
  boldSteelText: {
    color: colorPalette.steel,
  },
  buttonStyle: {
    cta: {
      backgroundColor: colorPalette.hiltiRed,
      backgroundHoverColor: colorPalette.hiltiRedHover,
      borderColor: colorPalette.hiltiRed,
      color: colorPalette.white,
    },
    disabled: {
      backgroundColor: colorPalette.steel20,
      color: colorPalette.white,
    },
    primary: {
      backgroundColor: colorPalette.steel,
      backgroundHoverColor: colorPalette.black,
      borderColor: colorPalette.steel,
      color: colorPalette.white,
    },
    secondary: {
      backgroundColor: colorPalette.heavyConcrete20,
      backgroundHoverColor: colorPalette.heavyConcrete40,
      borderColor: colorPalette.heavyConcrete60,
      color: colorPalette.steel,
    },
  },

  detailsComponents: {
    assetStatus: {
      broken: {
        backgroundColor: colorPalette.warningSecondaryYellow,
        borderColor: "#e1af00",
      },
      default: {
        backgroundColor: colorPalette.white,
        borderColor: colorPalette.heavyConcrete,
      },
      serviceExpired: {
        backgroundColor: "#fcc",
        borderColor: "#f00",
      },
    },
  },
  detailsPanel: {
    detailBackground: colorPalette.black2,
    detailLabelColor: colorPalette.steel,
    detailTextColor: colorPalette.steel,
    summaryLabelColor: colorPalette.steel,
    summaryTextColor: colorPalette.steel,
  },
  dotrgba: "rgba(82, 79, 83, 0.8)",
  fontBold: {
    fontFamily: `"Hilti-Bold", Arial,Verdana,sans-serif`,
    fontWeight: 700,
  },
  fontNormal: {
    fontFamily: `"Hilti-Roman", Arial,Verdana,sans-serif`,
    fontWeight: 400,
  },
  fontRoman: {
    fontFamily: `"Hilti-Roman", Arial,Verdana,sans-serif`,
    fontWeight: 400,
  },
  gridBackground: {
    color: colorPalette.heavyConcrete40,
  },
  gridRow: {
    rowTextColor: "#534F53",
  },
  header: {
    color: colorPalette.steel,
  },
  img: {
    add: "icAdd.svg",
    emptyAsset: "ic_EmptyAsset.svg",
  },
  importComponents: {
    detailsPanelBorder: colorPalette.lightGreyFadeColor,
  },
  labels: {
    assetTypeBG: colorPalette.black10,
    badgeBG: colorPalette.steel,
    containerShadowX: "rgba(82, 79, 83, 0.5)",
    containerShadowY: colorPalette.heavyConcrete,
    innerSection: colorPalette.white,
    labelTextStyle: "#979598",
  },
  locationDetail: {
    backgroundColor: colorPalette.white,
    borderColor: {
      color: colorPalette.heavyConcrete20,
    },
    locationBadge: {
      backgroundYellowColor: colorPalette.warningSecondaryYellow,
      darkLimeGreen: "#117A26",
      lightGrayishCyanLimeGreen: "#D8F4DF",
      steel: "#524F53",
      steelGrey: "#E5E5E5",
      strongRed: "#C30000",
      veryPaleRed: "#FFD7D8",
      yellow: colorPalette.yellow,
    },
    locationRow: {
      color: colorPalette.heavyConcrete10,
    },
    scrollBar: {
      backgroundColor: colorPalette.whiteSmoke,
      scrollbarThumbColor: "#888",
      scrollbarThumbHoverColor: "#555",
      scrollbarTrackColor: colorPalette.greyDark,
    },
    searchBar: {
      backgroundColor: colorPalette.heavyConcrete10,
      borderBottom: colorPalette.grey,
    },
  },
  normalRedText: {
    color: colorPalette.hiltiRed,
  },
  normalSteelText: {
    color: colorPalette.steel,
  },
  notes: {
    text: colorPalette.hiltiRedNotes,
  },
  qtyItemStatus: {
    Available: {
      backgroundColor: colorPalette.statusLightGreen,
      color: colorPalette.statusgreen,
    },
    Consumed: {
      backgroundColor: colorPalette.pink,
      color: colorPalette.breakRed,
    },
    Reserved: {
      backgroundColor: colorPalette.warningSecondaryYellow,
      color: colorPalette.yellow,
    },
  },
  recentlyUsedTempaltes: {
    color: colorPalette.black80,
  },
  selectCategoryContainer: {
    color: colorPalette.heavyConcrete10,
  },
  sort: {
    selected: colorPalette.greyDark,
    text: colorPalette.steel,
    hover: colorPalette.customLightGrey,
  },
  squareImage: {
    borderColor: colorPalette.heavyConcrete20,
  },
  statusStyle: {
    Broken: {
      backgroundColor: colorPalette.pink,
      color: colorPalette.breakRed,
    },
    Lost: {
      backgroundColor: colorPalette.pink,
      color: colorPalette.breakRed,
    },
    Operational: {
      backgroundColor: colorPalette.statusLightGreen,
      color: colorPalette.statusgreen,
    },
    Repair: {
      backgroundColor: colorPalette.lightBlue,
      color: colorPalette.statusBlue,
    },
    Retired: {
      backgroundColor: colorPalette.warningSecondaryYellow,
      color: colorPalette.yellow,
    },
    Warning: {
      backgroundColor: colorPalette.warningSecondaryYellow,
      color: colorPalette.yellow,
    },
    gateway: {
      offline: {
        backgroundColor: colorPalette.pink,
        color: colorPalette.breakRed,
      },
      online: {
        backgroundColor: colorPalette.statusLightGreen,
        color: colorPalette.statusgreen,
      },
    },
    New: {
      backgroundColor: colorPalette.lightBlue,
      color: colorPalette.statusBlue,
    },
  },
  titleColor: {
    color: colorPalette.titleColor,
  },
  uploadPanel: {
    boxShadow: "rgba(0, 0, 0, 0.5)",
    componentBorder: colorPalette.heavyConcrete20,
  },
  viewCategoryCard: {
    assetDescriptionColor: colorPalette.steel,
    assetHeadingColor: colorPalette.steel,
    backgroundColor: colorPalette.white,
  },
  workerDetail: {
    borderColor: colorPalette.heavyConcrete20,
  },
  ...hiltiTheme,
  fontSize: {
    ...hiltiTheme.fontSizes,
    base: "14px",
    extraLarge: "20px",
    extraSmall: "10px",
    large: "18px",
    medium: "16px",
    small: "12px",
    largest: "32px",
  },
  ...hiltiTheme,
  colors: {
    ...hiltiTheme.colors,
    ...colors,
  },
};

const theme = createTheme(customTheme as any) as any;
export default theme;
