import React from "react";
import "react-input-range/lib/css/index.css";
import { Field } from "redux-form";
import DataRangeControl from "./dataRangeControl";
import { IDataRangeContainerProps } from "./iDataRange";

export default class DataRangeContainer extends React.Component<IDataRangeContainerProps> {
  onSelectionChange = (value) => {
    return this.props.onSelectionChange && this.props.onSelectionChange(value);
  };

  render() {
    const { clearButtonText, id, name } = this.props;
    return this.props.isFormControl ? (
      <Field
        id={id || name}
        name={name}
        component={DataRangeControl}
        className="multiSelectControl"
        clearButtonText={clearButtonText}
        {...this.props}
      />
    ) : (
      <DataRangeControl {...this.props} onSelectionChange={this.onSelectionChange} />
    );
  }
}
