import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { StyledDiv, StyledInput, StyledLabel, StyledText } from "../../elements/Checkbox/checkbox";
import MandatoryMark from "../../elements/Field/mandatoryMark";
import { HelperLodash } from "../../helpers";
import { ICheckBoxCollection } from "./iCheckBoxCollection";

const CollectionHeader = styled.div`
  display: inline-flex;
  align-items: center;
`;
const LabelStyle = styled.label`
  color: #524f53;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: bold;
  line-height: 17px;
`;
const CheckBoxWrapper = styled.div`
  margin: 14px 8px 8px 0;
`;

export const ErrorLabel = styled.div`
  color: #ff0000;
  font-size: 12px;
  font-weight: 300;
  line-height: 6px;
  min-height: 20px;
`;

const showValidation = (props) => {
  const { errormsg } = props;

  return errormsg ? <ErrorLabel>{errormsg}</ErrorLabel> : null;
};

const CheckBoxElement = (props) => {
  return (
    <CheckBoxWrapper>
      <StyledDiv disabled={props.disabled} className="checkbox">
        <StyledInput
          checked={Boolean((props.input && props.input.value) || props.value)}
          onChange={(event) => {
            event.stopPropagation();
            props.input.onChange(event.target.checked);
            if (props.checboxChange) {
              props.checboxChange(event.target.checked);
            }
          }}
          indeterminate={false}
        />
        <StyledLabel className={"styledCheckbox"} />
        {props.label && (
          <StyledText className={"styledText lbl"} id={HelperLodash.camelCase(props.input.name)}>
            {props.label}
          </StyledText>
        )}
      </StyledDiv>
    </CheckBoxWrapper>
  );
};

const getCheckBoxes = (props) => {
  const { collection } = props;
  return collection.map((ele) => {
    return (
      <Field
        key={ele.name}
        name={ele.name}
        component={CheckBoxElement}
        label={ele.label}
        id={ele.name}
        checboxChange={ele.checboxChange}
        {...props}
      />
    );
  });
};

const CheckBoxCollection = React.memo((props: ICheckBoxCollection) => {
  const { header, isValid, required } = props;
  return (
    <>
      <CollectionHeader>
        <LabelStyle>{header}</LabelStyle>
        {required && <MandatoryMark />}
      </CollectionHeader>
      {getCheckBoxes(props)}
      {!isValid && showValidation(props)}
    </>
  );
});

export default CheckBoxCollection;
