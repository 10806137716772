import { SharedUtils } from "am-web-ui-shared/helpers";
import LicenseTypes from "TARGET_BUILD/common/utils/enums/licenseTypesEnum";
import { CustomerType } from "./user";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import {
  LicenseBundleToLicenseType,
  LicenseBundleTypes,
  LicenseBundleValues,
} from "TARGET_BUILD/common/utils/enums/licenseBundleEnum";

export const SUBMIT_REPAIR = "submit_repair";
export const REPORT_FLEET_LOST_STOLEN = "report_fleet_lost_stolen";
export const MANAGE_EXPIRING_FLEET_ASSETS = "manage_expiring_fleet_assets";
export const REPAIR_STATUS_TRACKING = "repair_status_tracking";
export const SUBMIT_RETURN = "submit_return";

export const lisenceTypes = new Map<LicenseTypes, CustomerType>([
  [LicenseTypes.OT, CustomerType.OT],
  [LicenseTypes.FM, CustomerType.FM],
]);

export const lisenceBundles = new Map<LicenseBundleTypes, LicenseBundleValues>([
  [LicenseBundleTypes.ONTRACK_LITE, LicenseBundleValues.ONTRACK_LITE],
  [LicenseBundleTypes.ONTRACK_PRO, LicenseBundleValues.ONTRACK_PRO],
  [LicenseBundleTypes.ONTRACK_ENTERPRISE, LicenseBundleValues.ONTRACK_ENTERPRISE],
  [LicenseBundleTypes.ONTRACK_READY, LicenseBundleValues.ONTRACK_READY],
]);

export const trackSubmitRepair = (asset_count: number) =>
  SharedUtils.pushDataToGA(SUBMIT_REPAIR, null, {
    asset_count,
  });

export const trackReportFleetLostStolen = (asset_count: number) =>
  SharedUtils.pushDataToGA(REPORT_FLEET_LOST_STOLEN, null, {
    asset_count,
  });

export const trackManageExpiringFleetAssets = () => SharedUtils.pushDataToGA(MANAGE_EXPIRING_FLEET_ASSETS);

export const trackRepairStatusTracking = () => SharedUtils.pushDataToGA(REPAIR_STATUS_TRACKING);

export const trackReturnLoanRentAssets = (
  licenseType: CustomerType,
  asset_count: number,
  tool_group: string,
  licenseBundle: LicenseBundleValues,
) => {
  const licenseBundleFeatureEnabled = SingletonFeatureManager.isFeatureEnabled(FEATURES.LICENSE_BUNDLE);
  if (licenseBundleFeatureEnabled) {
    //not changing google analytics data from licenseType to licenseBundle
    const license = LicenseBundleToLicenseType[licenseBundle];
    SharedUtils.pushDataToGA(SUBMIT_RETURN, null, { license, asset_count, tool_group });
  } else {
    SharedUtils.pushDataToGA(SUBMIT_RETURN, null, { licenseType, asset_count, tool_group });
  }
};
