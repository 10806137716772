import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M5.53553391,15.0208153 L9.07106781,18.5563492 L3.76776695,20.3241161 L5.53553391,15.0208153 Z M17.5563492,3 L21.0918831,6.53553391 L10.4852814,17.1421356 L6.94974747,13.6066017 L17.5563492,3 Z" />
    </g>
  </svg>
);

export default SVG;
