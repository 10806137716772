import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { memo } from "react";
import Select, { components } from "react-select";
import { IconName } from "../../components/icons/iconEnum";
import styled from "styled-components";
// @ts-ignore
import InfoIcon from "../../../app-ui/src/ui-assets/icons/Info.svg";
import colorPalette from "../../materials/Colors";
import { HelperLodash } from "../../helpers";
import { LoaderElement } from "../loader/loaderElement";
// @ts-ignore
import iconArrowdown from "./arrow_down.svg";
const DATA_SEARCHABLE = "data-searchable";
const DATA_VALUE = "data-value";
const DATA_BORDER_COLOR = "data-borderColor";
const DATA_COLOR = "data-color";
const DISABLED = "isDisabled";
import { SquareIcon } from "../../components/icons/iconWrapper";
import ApplicationConstants from "../../constant/applicationConstants";
import Icons from "../../components/icons/svgs";
import KeyCodes from "../../constant/keyCodes";
import Tooltip from "@hilti/components/core/tooltip";

const dropDownSearchElementWidth = (props) =>
  HelperLodash.get(props, DATA_SEARCHABLE) ? `calc(100% - 64px)` : `calc(100% - 48px)`;

const styles = (theme) => ({
  input: {
    display: "flex",
    height: 32,
    padding: 8,
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing()}px ${theme.spacing(ApplicationConstants.NUMBER.NUM2)}px`,
  },
  placeholder: {},
  singleValue: {
    fontSize: 16,
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
});

const Remove = styled.button`
  position: absolute;
  top: 50%;
  right: 48px;
  margin-top: -12px;
  border: 0;
  background-color: transparent;
  svg {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`;

const AutoCompleteDropdown = styled.div`
  overflow: hidden;
  height: 100%;
  margin-right: 8px;
`;

const StyledSelect = styled(Select)`
  .filedstatus {
    color: ${(props) => props.theme.colors.base.lightRedColor};
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 300;
    line-height: 25px;
    border-radius: 10px;
    background: ${(props) => props.theme.colors.base.pink};
    padding: 3px 8px;
    margin-left: 10px;
  }
  > div {
    > div {
      color: ${(props) => props.theme.colors.base.steel};
      && {
        :hover {
          border: 0;
        }
      }
      :before {
        border: 0 !important;
        transition: none !important;
      }
      :after {
        transition: none !important;
        border: 0 !important;
      }
    }

    .dropDownSingleValue {
      p {
        position: absolute;
        top: 11px;
        left: 0px;
        font-size: ${(props) => props.theme.fontSize.medium};
        font-family: ${(props) =>
          HelperLodash.get(props, DATA_VALUE) ? props.theme.fontBold.fontFamily : props.theme.fontRoman.fontFamily};
        font-weight: ${(props) =>
          HelperLodash.get(props, DATA_VALUE) ? props.theme.fontBold.fontWeight : props.theme.fontRoman.fontWeight};
        padding-left: 16px;
        color: ${(props) =>
          HelperLodash.get(props, DATA_VALUE) && HelperLodash.get(props, DATA_COLOR)
            ? HelperLodash.get(props, DATA_COLOR)
            : colorPalette.steel40};
        line-height: 19px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: ${dropDownSearchElementWidth};
        + div {
          position: relative;
        }
      }
      input {
        text-indent: ${(props) =>
          HelperLodash.get(props, DATA_VALUE) || HelperLodash.get(props, DATA_SEARCHABLE) ? "0px" : "100px"};
      }
      div {
        background: transparent !important;
        margin: 0 !important;
      }
      + div {
        background: ${(props) => props.theme.colors.base.heavyConcrete20};
        height: 43px;
        position: ${(props) => (HelperLodash.get(props, DATA_SEARCHABLE) ? "" : "absolute")};
        top: ${(props) => (HelperLodash.get(props, DATA_SEARCHABLE) ? "" : "1px")};
        right: ${(props) => (HelperLodash.get(props, DATA_SEARCHABLE) ? "" : "0")};
        div {
          padding: 10px 8px 14px 0;
        }
        .imgarrow {
          padding-top: 10px;
          padding-left: 10px;
          span {
            display: block;
          }
          .arrow {
            transform: rotate(90deg);
            &.up {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }

  .typeselect {
    opacity: ${(props) => HelperLodash.get(props, DISABLED) && 0.5};
    > div:last-child {
      border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete60};
      background-color: ${(props) =>
        HelperLodash.get(props, DATA_SEARCHABLE) ? colorPalette.white : colorPalette.heavyConcrete20};
      border-color: ${(props) =>
        props["data-error"] && HelperLodash.get(props, DATA_BORDER_COLOR)
          ? colorPalette.hiltiRedError
          : HelperLodash.get(props, DATA_BORDER_COLOR)};
      color: ${(props) =>
        HelperLodash.get(props, DATA_VALUE) && HelperLodash.get(props, DATA_COLOR)
          ? HelperLodash.get(props, DATA_COLOR)
          : colorPalette.steel};
      height: 48px;
      > div {
        padding: 0 0 0 16px;
        height: ${(props) => (HelperLodash.get(props, DATA_SEARCHABLE) ? "44px" : "")};
        overflow: ${(props) => (HelperLodash.get(props, DATA_SEARCHABLE) ? "" : "hidden")};
      }
      cursor: pointer;
      span {
        display: none;
      }
    }
  }
`;

const TextFieldStyle = styled(TextField)`
  && {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    height: 48px;
    .filedvalue {
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
  + div {
    margin-top: 0px;
    width: 100%;
    z-index: 99999;
    border-radius: 0;
  }
`;

const ElementWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dropdownMenuItem {
    display: block;
    white-space: inherit;
    word-break: break-all;
    height: auto;
    width: 100%;
  }
  .iconStyle {
    margin-right: 16px;
  }
`;

const ImageWrapper = styled.div`
  margin-right: 16px;
  cursor: pointer;
`;

const Loader = styled.div`
  max-height: calc(100vh - 200px);
  overflow: hidden;
  padding: 32px 0;
  position: relative;
  box-sizing: border-box;
  width: 400px;
  box-shadow: 0 2px 12px 0px rgba(82, 79, 83, 0.5);
  background: ${(props) => props.theme.colors.base.white};
  z-index: 1000;
`;
export const NoOptionsMessage = (props) => {
  return (
    <Typography
      id={props.name}
      color="textSecondary"
      className={`optionMessage ${props.selectProps.classes.noOptionsMessage}`}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const inputComponent = React.forwardRef<HTMLDivElement, { name: string }>((props, inputRef) => {
  return <div id={props.name} className="inputComponent" ref={inputRef} {...props} />;
});

const Menu = (props) => {
  if (props.selectProps.data === null) {
    return (
      <Loader>
        <LoaderElement />
      </Loader>
    );
  } else {
    return (
      <>
        <components.Menu {...props}>{props.children}</components.Menu>
      </>
    );
  }
};

const Control = (props) => (
  <TextFieldStyle
    // @ts-ignore
    url={iconArrowdown}
    id={props.name}
    className="typeselect"
    InputProps={{
      inputComponent,
      inputProps: {
        children: props.children,
        className: props.selectProps.classes.input,
        ref: props.ref,
        ...props.innerProps,
      },
    }}
    variant={"standard"}
  />
);

export const Option = (props) => {
  let selectedStyle: any = { fontWeight: 400, background: "transparent" };

  // Change the style of selected option.
  if (props.isSelected) {
    selectedStyle = {
      background: colorPalette.heavyConcrete40,
      fontWeight: 700,
    };
  }

  // This is used to style the disabled option.
  if (props.isDisabled) {
    selectedStyle = {
      color: colorPalette.heavyConcrete60,
      fontWeight: 400,
    };
  }
  // This is used to style the option on mouse hover.
  const mouseEnterHandler = (e) => {
    if (props.isSelected) {
      e.target.style.background = colorPalette.heavyConcrete40;
    } else if (props.isDisabled) {
      e.target.style.background = colorPalette.white;
      e.target.style.cursor = "inherit";
    } else {
      e.target.style.background = colorPalette.heavyConcrete10;
    }
  };

  // This is used to revert the hover style to default.
  const mouseLeaveHandler = (e) => {
    if (props.isSelected) {
      e.target.style.background = colorPalette.heavyConcrete40;
    } else {
      e.target.style.background = colorPalette.white;
    }
  };
  return (
    <ElementWithIcon>
      <MenuItem
        {...props.innerProps}
        id={`${props.selectProps.name}Op${props.innerProps.id.split("-").pop()}`}
        className={`dropdownMenuItem op_${props.selectProps.name}${props.innerProps.id.split("-").pop()}`}
        buttonRef={props.ref}
        selected={props.isFocused}
        component="div"
        style={selectedStyle}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        {props.children}
        {props.data.item && props.data.item.inActive ? (
          <label className="filedstatus">{props.selectProps && props.selectProps.inactiveMessage}</label>
        ) : null}
      </MenuItem>
      {props.isDisabled && props.data.item && props.data.item.disabledReason ? (
        <ImageWrapper>
          <Tooltip title={props.data.item.disabledReason} placement="right">
            <img src={InfoIcon} alt="" />
          </Tooltip>
        </ImageWrapper>
      ) : null}
    </ElementWithIcon>
  );
};

export const Placeholder = (props) => {
  return (
    <Typography
      id={`${props.selectProps.name}-placeholder`}
      color="textSecondary"
      className={`dropDownPlaceholder ${props.selectProps.classes.placeholder}`}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const SingleValue = (props) => {
  const { data } = props;
  return (
    <Typography
      id={`${props.selectProps.name}Value`}
      className={`dropDownSingleValue ${props.selectProps.classes.singleValue}`}
      {...props.innerProps}
    >
      {data.item && data.item.inActive ? (
        <>
          <label className="filedvalue">{props.children}</label>{" "}
          <label className="filedstatus">{props.selectProps && props.selectProps.inactiveMessage}</label>
        </>
      ) : (
        props.children
      )}
    </Typography>
  );
};

function ValueContainer(props) {
  return (
    <AutoCompleteDropdown
      data-id="dropDownParent"
      id={`${props.selectProps.name}Dd`}
      className={`dropDownSingleValue ${props.selectProps.classes.valueContainer}`}
    >
      {props.children}
    </AutoCompleteDropdown>
  );
}

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} className={`${props.selectProps.name}Img imgarrow`}>
        <SquareIcon name={IconName.Arrow} className={props.selectProps.menuIsOpen ? "arrow up" : "arrow"} />
      </components.DropdownIndicator>
    )
  );
};

const ClearIndicator = memo((props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return props.selectProps.isClearable ? (
    <Remove {...restInnerProps}>
      <Icons name={IconName.RemoveCircle} ref={ref} />
    </Remove>
  ) : null;
});

const componentsArray = {
  Control,
  DropdownIndicator,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator,
};

interface ISearchSelectProps {
  classes: any;
  options: any[];
  clearable: boolean;
  placeholder: string;
  [key: string]: any;
}

export class SearchSelect extends React.Component<ISearchSelectProps> {
  selectRef = null;
  /**
   * @description - grey out the select compoenent when it's disabled and when autoComplete is on.
   */
  getStyles() {
    const { isDisabled, autoComplete } = this.props;
    if (isDisabled && autoComplete === "on") {
      return {
        borderColor: colorPalette.heavyConcrete20,
        color: colorPalette.steel20,
      };
    }
    return {
      borderColor: colorPalette.heavyConcrete60,
      color: colorPalette.steel,
    };
  }
  onKeyDown(e) {
    if (e.keyCode === KeyCodes.backspace_key_code) {
      this.selectRef.select.clearValue();
      this.props.input.onChange(null);
    }
  }
  styleSelectChangeHandler = (option) => {
    if (!option) {
      option = {};
    }
    this.props.input.onChange(option.value);
    if (this.props.onSelectChange) {
      this.props.onSelectChange({
        id: option.value,
        item: option.item,
        value: option.label,
      });
    }
  };
  render() {
    const {
      menuPlacement,
      options,
      placeholder,
      input: { value },
      name,
    } = this.props;
    return (
      <StyledSelect
        ref={(ref) => {
          this.selectRef = ref;
        }}
        data-select="search-complete"
        {...this.props}
        value={options.filter((option) => option.value === value.toString())}
        onChange={this.styleSelectChangeHandler}
        options={options}
        placeholder={placeholder}
        components={componentsArray}
        isSearchable={this.props.autoComplete !== "off"}
        isMulti={this.props.isMulti}
        data-error={this.props.error}
        data-searchable={this.props.autoComplete !== "off"}
        data-value={value.toString()}
        menuPlacement={menuPlacement ? menuPlacement : "auto"}
        data-borderColor={this.getStyles().borderColor}
        data-color={this.getStyles().color}
        inactiveMessage={this.props.inactiveMessage}
        onKeyDown={this.onKeyDown.bind(this)}
        key={`form_search_complete___${name}`}
      />
    );
  }
}

export default withStyles(styles)(SearchSelect);
