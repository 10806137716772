import React from "react";
const SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2H14V4.17756C14.4422 4.27659 14.8609 4.41705 15.2561 4.59893C16.1237 4.99822 16.7979 5.56684 17.2787 6.30481C17.7596 7.04278 18 7.88591 18 8.83422H14.3415C14.3415 8.19964 14.1516 7.70767 13.7718 7.35829C13.392 7.00891 12.8781 6.83422 12.23 6.83422C11.5749 6.83422 11.0575 6.98217 10.6777 7.27808C10.2979 7.57398 10.108 7.95009 10.108 8.40642C10.108 8.80571 10.3171 9.16756 10.7352 9.49198C11.1533 9.8164 11.8885 10.1515 12.9408 10.4973C13.993 10.8431 14.8571 11.2157 15.5331 11.615C17.1777 12.5847 18 13.9216 18 15.6257C18 16.9875 17.4983 18.057 16.4948 18.8342C15.8279 19.3507 14.9963 19.6956 14 19.8689V22.0079H10V19.6826C9.66021 19.5847 9.3321 19.4641 9.01568 19.3209C8.01567 18.8681 7.26307 18.2478 6.75784 17.4599C6.25261 16.672 6 15.7647 6 14.738H9.67944C9.67944 15.5722 9.89024 16.1872 10.3118 16.5829C10.7335 16.9786 11.4181 17.1765 12.3659 17.1765C12.9721 17.1765 13.4512 17.0428 13.8031 16.7754C14.1551 16.508 14.331 16.1319 14.331 15.6471C14.331 15.098 14.1411 14.6702 13.7613 14.3636C13.3815 14.057 12.7143 13.7398 11.7596 13.4118C10.8049 13.0838 10.0244 12.7665 9.41812 12.4599C7.44598 11.4688 6.45993 10.107 6.45993 8.37433C6.45993 7.51158 6.70557 6.75045 7.19686 6.09091C7.68816 5.43137 8.38327 4.91801 9.28223 4.5508C9.51407 4.4561 9.75333 4.37361 10 4.30333V2Z"
      fill="#524F53"
    />
  </svg>
);

export default SVG;
