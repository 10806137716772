import React from "react";
const SVG = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-794.000000, -2270.000000)" fill="#524F53">
        <g transform="translate(792.000000, 2268.000000)">
          <path
            d="M22,11.875 L19,14.875 L19,13 L5,13 L5,19 L14.875,19 L11.875,22 L2,22 L2,21 L2,2 L5,2 L19,2 L22,2 L22,11.875 Z M5,5 L5,10 L9,10 L9,5 L5,5 Z M12,5 L12,10 L19,10 L19,5 L12,5 Z M19.5355339,20.9497475 L17.4142136,23.0710678 L16,21.6568542 L18.1213203,19.5355339 L16,17.4142136 L17.4142136,16 L19.5355339,18.1213203 L21.6568542,16 L23.0710678,17.4142136 L20.9497475,19.5355339 L23.0710678,21.6568542 L21.6568542,23.0710678 L19.5355339,20.9497475 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
