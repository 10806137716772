import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M16,14 L16,17 L4,17 L4,14 L16,14 Z M20,9 L20,12 L4,12 L4,9 L20,9 Z M20,4 L20,7 L4,7 L4,4 L20,4 Z M21,16 L21,19 L24,19 L24,21 L21,21 L21,24 L19,24 L19,21 L16,21 L16,19 L19,19 L19,16 L21,16 Z" />
    </g>
  </svg>
);

export default SVG;
