import React from "react";
const SVG = () => (
  <svg width="18px" height="21px" viewBox="0 0 18 21">
    <g id="completeService" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3.000000, -1.000000)" fill="#524F53">
        <path
          d="M12,1 C13.6568542,1 15,2.34314575 15,4 L15,4 L21,4 L21,22 L3,22 L3,4 L9,4 C9,2.34314575 10.3431458,1 12,1 Z M17.1318409,8.5613211 L10.768,14.925 L7.232346,11.3897482 L5.81813244,12.8039618 L10.7678799,17.7537093 L12.1820935,16.3394957 L18.5460545,9.97553466 L17.1318409,8.5613211 Z M12,3 C11.4477153,3 11,3.44771525 11,4 C11,4.55228475 11.4477153,5 12,5 C12.5522847,5 13,4.55228475 13,4 C13,3.44771525 12.5522847,3 12,3 Z"
          id="🎨Color"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
