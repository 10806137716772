import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { clearAttachmentData, getDocumentById, uploadDocument } from "../../actions/documentAction";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import { UploadComponent } from "./uploadComponent";
import ImageTypeEnum from "../../utils/enums/imageTypeEnum";

// @ts-ignore
export const mapStateToProps = (state) => ({
  documentKeys: state.Document && state.Document.documentKeys,
  error: state.Document && state.Document.error,
  getAttachments: (form, field) => {
    const selector = formValueSelector(form);
    const attachments = selector(state, field);
    return attachments && Array.isArray(attachments) && attachments.filter((v) => !v.isReadOnly);
  },
  getDocument: (documentId) => HelperLodash.get(state.Document, ["document", documentId]),
});

export const mapDispatchToProps = (dispatch) => ({
  clearData: () => dispatch(clearAttachmentData()),
  getDocumentById: (documentId, showLoader?, type = ImageTypeEnum.original, width?, height?) =>
    dispatch(getDocumentById(documentId, showLoader, type, width, height)),
  removeImage: (formName, fieldName) => dispatch(change(formName, fieldName, null)),
  showToaster: (type: ToastrType, message: string) => dispatch(showToastr(type, message)),
  uploadDocument: (formData, config, responseFormatMapper, width?, height?, type?) =>
    dispatch(uploadDocument(formData, config, responseFormatMapper, width, height, type)),
});

export default withTranslation()(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(UploadComponent),
);
