const CertificateTemplateActionConstants = {
  ADD_CERTIFICATE_TEMPLATE_SUCCESS: "ADD_CERTIFICATE_TEMPLATE_SUCCESS",
  CERTIFICATE_TEMPLATE_FAIL: "CERTIFICATE_TEMPLATE_FAIL",
  CERTIFICATE_TEMPLATE_FILTER_ATTRIBUTES: "CERTIFICATE_TEMPLATE_FILTER_ATTRIBUTES",
  CERTIFICATE_TEMPLATE_FILTER_ATTRIBUTES_SUCCESS: "CERTIFICATE_TEMPLATE_FILTER_ATTRIBUTES_SUCCESS",
  CERTIFICATE_TEMPLATE_LIST_SUCCESS: "CERTIFICATE_TEMPLATE_LIST_SUCCESS",
  CERTIFICATE_TEMPLATE_SELECT_ROW: "CERTIFICATE_TEMPLATE_SELECT_ROW",
  CERTIFICATE_TEMPLATE_UNSELECT_ROW: "CERTIFICATE_TEMPLATE_UNSELECT_ROW",
  CLEAR_CERTIFICATE_TEMPLATE_RESPONSE: "CLEAR_CERTIFICATE_TEMPLATE_RESPONSE",
  EDIT_CERTIFICATE_TEMPLATE_SUCCESS: "EDIT_CERTIFICATE_TEMPLATE_SUCCESS",
  RESET_CERTIFICATE_TEMPLATE_QUERY_PARAM: "RESET_CERTIFICATE_TEMPLATE_QUERY_PARAM",
  SET_CERTIFICATE_TEMPLATE_FILTER_QUERY: "SET_CERTIFICATE_TEMPLATE_FILTER_QUERY",
  SET_CERTIFICATE_TEMPLATE_SEARCH_QUERY: "SET_CERTIFICATE_TEMPLATE_SEARCH_QUERY",
  SET_CERTIFICATE_TEMPLATE_SORT_QUERY: "SET_CERTIFICATE_TEMPLATE_SORT_QUERY",
  TOGGLE_ADD_DIALOG: "TOGGLE_ADD_DIALOG",
  UM_ADD_CERTIFICATE_TEMPLATE: "UM_ADD_CERTIFICATE_TEMPLATE",
  UM_CERTIFICATE_TEMPLATE_LIST: "UM_CERTIFICATE_TEMPLATE_LIST",
  UM_DELETE_CERTIFICATE_TEMPLATE: "UM_DELETE_CERTIFICATE_TEMPLATE",
  UM_EDIT_CERTIFICATE_TEMPLATE: "UM_EDIT_CERTIFICATE_TEMPLATE",
  UM_SET_CERTIFICATE_TEMPLATE_FILTER_APPLIED_FIELD: "UM_SET_CERTIFICATE_TEMPLATE_FILTER_APPLIED_FIELD",
};
export default CertificateTemplateActionConstants;
