// @ts-ignore
import { AssetServiceActions, AssetTemplatesActions, ChargesActions, LoaderAction } from "custom-actions";
import { all, put, select, takeEvery, takeLatest, delay } from "redux-saga/effects";
import { getFormValues } from "redux-form";
// @ts-ignore
import { API_DELAY_TIME } from "TARGET_BUILD/config";
import { AssetGroupActions } from "../../../actionConstants";
import { showToastr, ToastrType } from "../../../actions/toastrAction";
import {
  assetServiceTemplateAssociation,
  createTemplate,
  deleteAssetTemplates,
  getAssetTemplateDetails,
  getAssetTemplateFilterAttributes,
  getAssetTemplateSerivceList,
  getAssetTemplatesList,
  getModelsList,
  getTemplateAssetList,
  getTemplateDetails,
  getTemplatesOnSearch,
  removeServiceTemplate,
  updateMergeAssetTemplates,
  updateTemplate,
} from "../../../api/assetTemplatesApi";
import { getTemplateChargeSetting } from "../../../api/chargesApi";
import { canUserEditChargeSettings, getMethod } from "../../../models/charges/chargesOrchestration";
import { prepareChargeSettingMergeData } from "../../../modules/administration/template/components/merge/assetTemplateMergeUtil";
import {
  getTemplateChargeSettingValue,
  handleTemplateResponseErrors,
  prepareTemplateMergeData,
} from "../../../modules/administration/template/components/merge/templateMergeOrchestration";
import { validateResponse } from "../../../utils/errorUtils";
import { call } from "../../../sagas/common/commonSaga";
import { getFilterQueryObj, getQueryObj } from "../../../sagas/stateSelector";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { costTypeErrorCode } from "../../../components/charges/costCodeTypeEnum";
import ApplicationConstants from "../../../applicationConstants";
import {
  FILTER_SEARCH_VALUE,
  FILTER_SHOW_MORE,
  trackAddTemplateWorkflowFailure,
  trackAddTemplateWorkflowSuccess,
  trackAssetTemplateEventsForAppliedFilter,
  trackAssetTemplatesCombinedFilters,
  trackEditTemplateWorkflowFailures,
  trackEditTemplateWorkflowSuccess,
  trackEventsForFilterSearch,
  trackMergeTemplateWorkflowFailure,
  trackMergeTemplateWorkflowSuccess,
} from "TARGET_BUILD/analytics/events/assetTemplates";

/**
 * @description - Saga which is responsible for giving the list of asset templates.
 */
export function* assetTemplatesListSaga() {
  yield takeLatest(AssetTemplatesActions.AM_GET_ASSET_TEMPLATES_LIST, fetchAssetTemplatesList);
}

/**
 * @description method takes latest action and callback of delayedAssetTemplatesList
 */
export function* searchAssetTemplatesListForSearch() {
  yield takeLatest(AssetTemplatesActions.AM_GET_ASSET_TEMPLATES_LIST_FOR_SEARCH, fetchDelayedAssetTemplatesList);
}

/**
 * @description method delay call and call to fetch assetTemplateslist after delay promise is resolved.
 * Blocks the fetchAssetTemplatesList till delay promise is resolved. If user types the search quickly
 * it will not allow to many calls.
 */
export function* fetchDelayedAssetTemplatesList(action) {
  yield fetchAssetTemplatesList(action);
}

/**
 * @description - Fetching the asset templates list by making an API call and returning it back to the view.
 *
 * @param action - Name of the action that needs to be watched.
 */
function* fetchAssetTemplatesList(action) {
  const queryObj = yield select(getQueryObj, action.offset, "assetTemplates");
  const appliedFilter = yield select((state) => state?.assetTemplates.appliedFilterQuery);
  const isLoader = !action.offset;
  if (isLoader) {
    yield put({ type: LoaderAction.SET_LIST_LOADER });
  }
  let assetTemplatessData = yield call(() => {
    return getAssetTemplatesList(queryObj, false);
  });
  if (assetTemplatessData.error) {
    assetTemplatessData = { response: [] };
  }
  yield put({
    payload: {
      assetTemplatessData,
      id: action.templateId,
      offset: action.offset,
      selectFirstRow: action.isSelectFirstRow,
    },
    type: AssetTemplatesActions.AM_PUT_ASSET_TEMPLATES,
  });
  const resultsCount = assetTemplatessData.totalRecords;
  if (appliedFilter?.length) {
    yield trackAssetTemplateEventsForAppliedFilter(appliedFilter, resultsCount);
  }
  if (appliedFilter?.length > 1) {
    yield trackAssetTemplatesCombinedFilters(appliedFilter);
  }
  if (isLoader) {
    yield put({ type: LoaderAction.UNSET_LIST_LOADER });
  }
}

export function* templateDetailsSaga() {
  yield takeLatest(AssetTemplatesActions.GET_TEMPLATE_DETAILS, fetchTemplateDetails);
}

function* fetchTemplateDetails(action) {
  const templateDetails = yield call(() => {
    return getTemplateDetails(action.templateId, action.loader);
  });
  if (templateDetails.error) {
    const errorResponse = validateResponse(templateDetails);
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
    } else {
      yield put(showToastr(ToastrType.error, `errorCodes:${templateDetails.error?.response?.data?.status}`));
    }
    yield put({
      payload: null,
      type: AssetTemplatesActions.GET_TEMPLATE_INFO_DETAILS,
    });
  } else {
    const newTemplateDetails = yield select((state) => getFormValues("addTemplate")(state));
    // Services and it's count are not managed by the form
    const { services, serviceTemplateCount, ...overrides } = newTemplateDetails ?? {};
    yield put({
      payload: { ...templateDetails, ...overrides },
      type: AssetTemplatesActions.GET_TEMPLATE_INFO_DETAILS,
    });
  }
}

export function* templateAssetListSaga() {
  yield takeLatest(AssetTemplatesActions.GET_TEMPLATE_ASSET_LIST, fetchTemplateAssetsList);
}

function* fetchTemplateAssetsList(action) {
  const templateAssetsList = yield call(() => {
    return getTemplateAssetList(action.templateId);
  });
  yield put({
    payload: templateAssetsList,
    type: AssetTemplatesActions.PUT_TEMPLATE_ASSET_LIST,
  });
}

export function* getTemplateDetailsSaga() {
  yield takeLatest(AssetTemplatesActions.GET_TEMPLATE_DETAILS_BY_ID, getAssetTemplateDetail);
}

function* getAssetTemplateDetail(action) {
  const templateDetails = yield call(() => {
    return getAssetTemplateDetails(action.id);
  });
  yield put({
    payload: templateDetails,
    type: AssetTemplatesActions.PUT_TEMPLATE_DETAILS,
  });
}

export function* refreshTemplatesListSaga() {
  yield takeLatest(AssetTemplatesActions.REFRESH_TEMPLATES, fetchTemplates);
}

function* fetchTemplates(action) {
  const TemplateDetails = yield call(() => {
    return getTemplateDetails(action.templateId, action.loader);
  });
  yield put({
    payload: TemplateDetails,
    type: AssetTemplatesActions.GET_Template_INFO_DETAILS,
  });
  yield put({
    payload: TemplateDetails,
    type: AssetTemplatesActions.REFRESH_TEMPLATES_LIST,
  });
}
/**
 * @description - Saga which is responsible for removing asset template.
 */
export function* removeAssetTemplates() {
  yield takeLatest(AssetTemplatesActions.DELETE_ASSET_TEMPLATE, deleteTemplates);
}

/**
 * @description - Deleting the asset template by making an API call and returning it back to the view.
 *
 * @param action - Name of the action that needs to be watched.
 */
function* deleteTemplates(action) {
  const selectedAssetTemplateIds = action.payload.ids;
  const multiSelectPayload = {
    isSelectAll: false,
    templateIds: selectedAssetTemplateIds,
  };
  const response = yield call(() => {
    return deleteAssetTemplates(multiSelectPayload);
  });

  if (!response.error) {
    yield put({
      payload: { response, selectedAssetTemplateIds },
      type: AssetTemplatesActions.DELETE_ASSET_TEMPLATE_SUCCESS,
    });
  }
}

/**
 * @description - Get list of templates searched from the template details section on add and edit asset pages.
 */
export function* getTemplatesOnSearchSaga() {
  yield takeLatest(AssetTemplatesActions.SEARCH_TEMPLATES, fetchgetTemplatesOnSearch);
}

function* fetchgetTemplatesOnSearch(action) {
  let templateLists = yield call(() => {
    return getTemplatesOnSearch(action.templateName);
  });
  if (templateLists.error) {
    templateLists = { response: [] };
  }
  yield put({
    payload: templateLists,
    type: AssetTemplatesActions.SEARCH_TEMPLATES_LIST,
  });
}

/**
 * use to update status code and refresh the list with offset 0
 */
export function* addEditUniqueTemplateSaga() {
  yield takeEvery(AssetTemplatesActions.AM_TEMPLATE_ADD_EDIT, addEditUniqueTemplate);
}

export function* addEditUniqueTemplate(action) {
  const response = yield call(() => {
    return action.templateInfo.templateId
      ? updateTemplate(action.templateInfo.templateId, action.templateInfo)
      : createTemplate(action.templateInfo);
  });
  yield put({ type: AssetTemplatesActions.TEMPLATE_ADD_EDIT_RESPONSE, addEditResponse: response ? response : {} });
  if (!response.error) {
    yield delay(API_DELAY_TIME);
    yield put({ type: AssetTemplatesActions.AM_GET_ASSET_TEMPLATES_FILTER_FIELD });
    yield put({
      type: AssetGroupActions.AM_ASSEST_GROUP_LIST,
    });
    if (!action.templateInfo.templateId) {
      yield put({
        loader: false,
        templateId: response.templateId,
        type: AssetTemplatesActions.GET_TEMPLATE_DETAILS,
      });
      trackAddTemplateWorkflowSuccess();
    } else {
      yield put(showToastr(ToastrType.success, `common:SAVED_SUCCESSFULLY`));
      yield put({
        type: AssetTemplatesActions.RESET_CHECKED_TEMPLATE_DETAIL,
      });
      trackEditTemplateWorkflowSuccess();
    }
  } else {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(
        showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`, {
          manufacturerName: action.templateInfo.manufacturerName,
        }),
      );
      action.templateInfo.templateId
        ? trackEditTemplateWorkflowFailures(errorResponse.code)
        : trackAddTemplateWorkflowFailure(errorResponse.code);
    }
  }
}
/**
 * @description - Merge the selected assetTemplates into one asset template and delete the other one.
 */
export function* mergeAssetTemplatesSaga() {
  yield takeLatest(AssetTemplatesActions.AM_MERGE_ASSETTEMPLATES_REQUEST, mergeAssetTemplates);
}

/**
 * @description - API call to merge the assetTemplates.
 */
function* mergeAssetTemplates(action) {
  const { mergeAssetTemplatesData, templateChargeSetting } = action.mergeAssetTemplatesData;
  const { templateData, chargeSetting, selectedTemplateId } = mergeAssetTemplatesData;
  const response = yield call(() => {
    return updateMergeAssetTemplates(templateData);
  });
  const errorResponse = validateResponse(response);
  if (response.error) {
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
      trackMergeTemplateWorkflowFailure(errorResponse.code);
    }
  } else {
    yield put({
      payload: { isMerged: templateData },
      type: AssetTemplatesActions.AM_MERGE_ASSETTEMPLATES_RESPONSE,
    });
    if (canUserEditChargeSettings()) {
      const httpMethod = getMethod(HelperLodash.get(templateChargeSetting, selectedTemplateId));
      const chargeSettingData = {
        method: httpMethod,
        ...prepareChargeSettingMergeData(templateChargeSetting, chargeSetting),
        templateId: selectedTemplateId,
      };
      yield put({
        chargeSettingData,
        type: ChargesActions.CH_SUBMIT_TEMPLATE_CHARGE_SETTING,
      });
    }
    yield delay(API_DELAY_TIME);
    yield put({ type: AssetTemplatesActions.AM_GET_ASSET_TEMPLATES_LIST, offset: 0 });
    yield put(showToastr(ToastrType.success, "common:MERGE_SUCCESSFULLY"));
    trackMergeTemplateWorkflowSuccess();
  }
}

/**
 * @generator - getSelectedAssetTemplatesInfo
 * @description - responsible for taking latest of assetTemplate details
 */
export function* getSelectedAssetTemplatesInfo() {
  yield takeLatest(AssetTemplatesActions.AM_GET_ASSETTEMPLATES_INFO, fetchSelectedAssetTemplatesDetails);
}

function* setMergeResponseData(templateDetails, templateChargeSetting) {
  // @ts-ignore
  const { chargeSettingResponseError, templateDetailResponseError } = handleTemplateResponseErrors(
    templateDetails,
    templateChargeSetting,
  ); // We can extract chargeSettingResponseError also from this
  if (templateDetailResponseError) {
    yield put(showToastr(ToastrType.error, `errorCodes:${templateDetailResponseError}`));
  } else {
    if (
      chargeSettingResponseError &&
      chargeSettingResponseError !== costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_ASSET
    ) {
      yield put(showToastr(ToastrType.error, `errorCodes:${chargeSettingResponseError}`));
      yield put({
        payload: {
          ...prepareTemplateMergeData(templateDetails, []),
          chargeSettingError: chargeSettingResponseError !== costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_ASSET,
        }, // if error is there don't show charge setting columns in merge view
        type: AssetTemplatesActions.AM_SET_SELECTED_ASSETTEMPLATES_INFO,
      });
    } else {
      yield put({
        payload: prepareTemplateMergeData(templateDetails, templateChargeSetting),
        type: AssetTemplatesActions.AM_SET_SELECTED_ASSETTEMPLATES_INFO,
      });
    }
  }
}

/**
 * @generator - fetchSelectedAssetTemplatesDetails
 * @arguments - action - contains --- selectedAssetTemplateIds - Array collection of selected assetTemplates to be merged.
 * Saga for fetching selected set of assetTemplate details.
 */
function* fetchSelectedAssetTemplatesDetails(action) {
  const templateChargeSetting = [];
  const templateDetails = [];
  const isChargeSettingEditable = canUserEditChargeSettings();
  const ids = action.selectedAssetTemplateIds;

  // merge template can happen with only two templates
  if (!ids || ids.length !== ApplicationConstants.NUMBER.NUM2) {
    return;
  }

  // fetch charge settings and template details parallelly
  const [templateA, templateB, chargeA, chargeB] = yield all([
    call(getAssetTemplateDetails, ids[0]),
    call(getAssetTemplateDetails, ids[1]),
    isChargeSettingEditable && call(getTemplateChargeSetting, ids[0]),
    isChargeSettingEditable && call(getTemplateChargeSetting, ids[1]),
  ]);

  templateDetails.push(templateA, templateB);
  isChargeSettingEditable && templateChargeSetting.push(chargeA, chargeB);

  for (let i = 0; i < templateChargeSetting.length; i++) {
    const templateCharge = HelperLodash.get(templateChargeSetting, i);

    if (templateCharge) {
      yield put({
        templateChargeSetting: getTemplateChargeSettingValue(templateCharge),
        templateId: HelperLodash.get(action, ["selectedAssetTemplateIds", i]),
        type: ChargesActions.CH_SUCCESS_TEMPLATE_CHARGES_SETTING,
      });
    }
  }
  yield setMergeResponseData(templateDetails, templateChargeSetting);
}

/**
 * used make API call for retrieving asset attributes
 */
export function* getAssetTemplateFilterAttributesSaga() {
  yield takeEvery(AssetTemplatesActions.AM_GET_ASSET_TEMPLATES_FILTER_FIELD, fetchAssetTemplateFilterAttributes);
}

/**
 * used make API call for retrieving asset attributes
 *
 * @param filters : filters to apply while retreiving data
 */
export function* fetchAssetTemplateFilterAttributes(filters) {
  yield put({ type: LoaderAction.SET_FILTER_LOADER });
  if (filters.delay) {
    yield delay(API_DELAY_TIME);
  }
  const filterQuery = yield select(getFilterQueryObj, "assetTemplates", filters.payload, true);
  const response = yield call(() => {
    return getAssetTemplateFilterAttributes(filterQuery);
  });
  if (!response.error) {
    yield put({
      payload: {
        filterPayload: filters.payload,
        response: response.response,
      },
      type: AssetTemplatesActions.ASSET_TEMPLATE_FILTER_FIELD_SUCCESS,
    });
    if (filters.payload?.query?.trim().length) {
      yield trackEventsForFilterSearch(filters.payload, FILTER_SEARCH_VALUE);
    }
    if (filters.payload?.query === "") {
      yield trackEventsForFilterSearch(filters.payload, FILTER_SHOW_MORE);
    }
  }
  yield put({ type: LoaderAction.UNSET_FILTER_LOADER });
}

/**
 * used make API call for retrieving asset attributes
 */
export function* getModelsListSaga() {
  yield takeEvery(AssetTemplatesActions.AM_GET_MODELS_LIST, fetchModelsList);
}

/**
 * used make API call for retrieving asset attributes
 *
 * @param fitlers : filters to apply while retreiving data
 */
export function* fetchModelsList(action) {
  const response = yield call(() => {
    return getModelsList(action && action.manufacturerId);
  });
  if (!response.error) {
    yield put({ type: AssetTemplatesActions.SET_MODELS_LIST, payload: response.response });
  }
}

/**
 * @description - Saga which is responsible for giving the list of asset  templates service list.
 */
export function* assetTemplatesSeriveListSaga() {
  yield takeLatest(AssetTemplatesActions.AM_GET_SELECTED_SERVICE_TEMPLAE_LIST, fetchAssetTemplatesServiceList);
}
function* fetchAssetTemplatesServiceList(action) {
  /*
    offset : 0 -> Load with initial state, show grid loader only for initial state.
    offset : (>0) -> Load list for pagination, paginated loader is shown by the infitescroll
    component at the bottom of the page
  */
  const queryObj = yield select(
    getQueryObj,
    action.offset,
    "AssetService",
    null,
    "assetTemplateServiceAppliedFilterQuery",
  );
  let serviceData = yield call(() => {
    return getAssetTemplateSerivceList(queryObj, action.id, action.offset === 0);
  });
  if (serviceData && serviceData.error) {
    serviceData = { response: [] };
  }
  // offset is required to decide whether to update the list or replace with new one.
  const payloadObj = {
    offset: action.offset,
    serviceList: serviceData,
    status: action.status,
  };
  yield put({ type: AssetServiceActions.AM_PUT_ASSET_SERVICE_LIST, payload: payloadObj });
}

/**
 * @description - Saga to get custom views list.
 */
export function* assignTemplateServiceSaga() {
  yield takeLatest(AssetTemplatesActions.ASSIGN_ASSET_TEMPLATE_SERVICE, assignTemplateService);
}

/**
 * @description - Function to get custom views list from api.
 */
function* assignTemplateService(action) {
  const response = yield call(() => {
    return assetServiceTemplateAssociation(action.serviceData, action.assetTemplateId);
  });
  if (!response.error) {
    yield put({
      searchQuery: "",
      type: AssetTemplatesActions.AM_SET_ASSET_TEMPLATES_SEARCH_QUERY,
    });
    yield put({
      type: AssetServiceActions.ASSIGN_SERVICE_SUCCESS,
    });
    yield put(showToastr(ToastrType.success, `services:ASSIGNED_SUCCESSFULLY`));
    yield delay(API_DELAY_TIME);
    yield put({
      filter: null,
      loader: false,
      templateId: action.assetTemplateId,
      type: AssetServiceActions.GET_ASSET_TEMPLATE_SERVICE_FILTER_ATTRIBUTES,
    });
  } else {
    yield put({
      payload: response.error,
      type: AssetServiceActions.SERVICE_OPERATION_ERROR,
    });
  }
}
/**
 * @description - Saga to remove Service Template.
 */
export function* removeTemplateServiceSaga() {
  yield takeLatest(AssetTemplatesActions.REMOVE_TEMPLATE_SERVICE, removeTemplateService);
}
/**
 * @description - Function to get custom views list from api to delete.
 */
function* removeTemplateService(action) {
  const response = yield call(() => {
    return removeServiceTemplate(action.assetTemplateId, action.serviceId);
  });
  if (response.error && !response.isSystemError) {
    yield put(showToastr(ToastrType.error, `errorCodes:${validateResponse(response).code}`));
  } else {
    yield put({
      templateId: action.assetTemplateId,
      type: AssetTemplatesActions.GET_TEMPLATE_DETAILS,
    });
    yield put({
      id: action.assetTemplateId,
      type: AssetTemplatesActions.AM_GET_SELECTED_SERVICE_TEMPLAE_LIST,
    });
    yield put(showToastr(ToastrType.success, `common:REMOVED_SUCCESSFULLY`));
    yield delay(API_DELAY_TIME);
    yield put({
      filter: null,
      loader: false,
      templateId: action.assetTemplateId,
      type: AssetServiceActions.GET_ASSET_TEMPLATE_SERVICE_FILTER_ATTRIBUTES,
    });
  }
}

function* assetTemplatesSaga() {
  yield all([
    addEditUniqueTemplateSaga(),
    assetTemplatesListSaga(),
    assetTemplatesSeriveListSaga(),
    assignTemplateServiceSaga(),
    getAssetTemplateFilterAttributesSaga(),
    getModelsListSaga(),
    getSelectedAssetTemplatesInfo(),
    getTemplateDetailsSaga(),
    mergeAssetTemplatesSaga(),
    refreshTemplatesListSaga(),
    removeAssetTemplates(),
    removeTemplateServiceSaga(),
    searchAssetTemplatesListForSearch(),
    templateAssetListSaga(),
    templateDetailsSaga(),
  ]);
}

export default assetTemplatesSaga;
