/**
 * @description - List of action constants that will be used for for multiple actions associated with the document.
 */
const DocumentConstant = {
  AM_GET_DOCUMENT_URL: "AM_GET_DOCUMENT_URL",
  AM_UPLOAD_DOCUMENT: "AM_UPLOAD_DOCUMENT",
  CLEAR_ATTACHMENT_DATA: "CLEAR_ATTACHMENT_DATA",
  SET_DOCUMENT: "SET_DOCUMENT",
  SET_DOCUMENT_ERROR: "SET_DOCUMENT_ERROR",
  SET_DOCUMENT_ERROR_CLEAN: "SET_DOCUMENT_ERROR_CLEAN",
  SET_DOCUMENT_URL: "SET_DOCUMENT_URL",
};

export default DocumentConstant;
