import React from "react";
import Checkbox from "../../../elements/Checkbox/checkbox";
import { dependencyResolver, getElementId } from "../gridUtils";
import { IGridCell } from "./iGridCell";

/**
 * Grid cell for combinational field and column having multiple values
 */

export const CheckboxCombinationCell = (props: IGridCell) => {
  const { column, rowIndex, columnIndex, dependencies } = props;
  return (
    <>
      {column.bindProperties.map((bindProperty, index) => {
        return renderCombinationColumn(bindProperty, rowIndex, columnIndex, index, dependencies, props);
      })}
    </>
  );
};

/**
 * @description renderCombinationColumn will render the values for single colum.
 * @param bindProperty { Array Object} contains the keys of the combination.
 * @param rowIndex
 * @param columnIndex
 * @param index
 * @param dependenciesFunction
 * @param props
 * @index {number} contains the index of the data.
 */
export const renderCombinationColumn = (
  bindProperty,
  rowIndex: number,
  columnIndex: number,
  index: number,
  dependenciesFunction,
  props,
) => {
  const handleClick = (event) => {
    return (
      bindProperty.properties[0].dependencies.format &&
      dependencyResolver(dependenciesFunction, bindProperty.properties[0].dependencies.format)(
        event,
        props.data,
        bindProperty.key,
      )
    );
  };
  const getCheckedValue = () => {
    return (
      bindProperty.properties[0].isChecked &&
      dependencyResolver(dependenciesFunction, bindProperty.properties[0].isChecked)(
        bindProperty.key,
        props.data,
        "isEnabled",
      )
    );
  };

  const id = props.elementId ? props.elementId : getElementId(rowIndex, columnIndex, index, bindProperty);
  return (
    <Checkbox
      name={`checkBox${id}`}
      id={id}
      key={`checkbox_${rowIndex}${index}`}
      isFormControl={false}
      onChange={(event) => {
        return handleClick(event);
      }}
      checked={getCheckedValue()}
      disabled={false}
    />
  );
};

export default React.memo(CheckboxCombinationCell);
