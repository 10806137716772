// @ts-ignore
import { HiltiCloudActions } from "custom-actions";
import HiltiCloudApi from "../../../../api/hiltiCloudApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import ICompanyModel from "../../../../models/company/iCompanyModel";
import { redirectToHOLPageForRepairTracking } from "../../../../utils/holUtils";
import { showToastr, ToastrType } from "../../../../actions/toastrAction";

export function* searchRepairOrderSaga() {
  yield takeLatest(HiltiCloudActions.HC_REPAIR_ORDER_SEARCH, searchRepairOrder);
}

export function* searchRepairOrder(action) {
  const assetDetails = action.payload.assetDetails;
  const companyDetails: ICompanyModel = action.payload.companyDetails;

  const payload = {
    repair_orders: [
      {
        tool_related_attributes: [
          {
            material_number: assetDetails.materialNumber,
            serial_number: assetDetails.serialNumber,
          },
        ],
      },
    ],
  };
  const hiltiCloudResponse = yield call(() => {
    return HiltiCloudApi.searchRepairOrder(payload);
  });

  const repairs =
    hiltiCloudResponse &&
    hiltiCloudResponse.repair_orders &&
    Array.isArray(hiltiCloudResponse.repair_orders) &&
    hiltiCloudResponse.repair_orders;
  const isEmpty = repairs && repairs.length === 0;
  const repairNotificationId = repairs && repairs.length && repairs[0] && repairs[0].repair_notification_id;

  // case 1 - isEmpty - tool is not in repair
  // case 2 - valid notification means, tool is in repair and can be tracked in HOL
  // case 3 - network error while getting the repair status
  if (isEmpty) {
    yield put(showToastr(ToastrType.error, "common:HILTI_CLOULD_REPAIR_SEARCH_EMPTY"));
  } else if (repairNotificationId) {
    redirectToHOLPageForRepairTracking(assetDetails, companyDetails, repairNotificationId);
  } else {
    yield put(showToastr(ToastrType.error, "errorCodes:networkError"));
  }
}

function* hiltiCloudSaga() {
  yield all([searchRepairOrderSaga()]);
}

export default hiltiCloudSaga;
