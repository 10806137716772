import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "am-web-ui-shared/components";
import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import { IconBold, Inventory, NormalBoldIcon, NormalIcon } from "../../../../../components/DetailsPanel";
import { AssetsType } from "./iSmartInventoryAssetsProps";
import { getSmartInventoryIcon, isOfflineInventoryEnabled } from "./smartInventoryUtils";
import { AssetSubType } from "../../../../../utils/enums/assetEnum";
import IInventory from "../models/inventory";

const FOUND_ASSET_COUNT = "foundAssetCount";
const NOT_FOUND_ASSET_COUNT = "notFoundAssetCount";
const EXPECTED_ASSET_COUNT = "expectedAssetCount";
const UNEXPECTED_ASSET_COUNT = "unexpectedAssetCount";

const validInventoryAttribute = [
  FOUND_ASSET_COUNT,
  NOT_FOUND_ASSET_COUNT,
  EXPECTED_ASSET_COUNT,
  UNEXPECTED_ASSET_COUNT,
];

export const StyledWrapperExpandCollpase = styled("div")`
  && {
    .inventory-wrapper {
      padding: 12px 0 12px 38px;
    }
    .inventory-wrapper-footer {
      padding: 16px 0 16px 54px;
    }
    .send-report {
      padding: 12px 12px 0px 16px;
    }
  }
`;

const StyledBottomText = styled.div`
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 300;
  color: ${(props) => props.theme.colors.base.steel};
  word-break: break-all;
  max-width: 370px;
  @media (max-width: 1280px) {
    max-width: 230px;
  }
`;

const StyledSendReport = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  padding: 0 16px;
  svg g {
    fill: ${(props) =>
      props.enable ? props.theme.colors.base.hiltiRedError : props.theme.colors.base.heavyConcrete40};
  }
  cursor: ${(props) => (props.enable ? "pointer" : "default")};
`;

const BoldWrapper = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  color: ${(props) => props.theme.colors.headingText.color};
  padding-left: 36px;
`;

export const ExtendedStyledWrapperExpandCollpase = styled(StyledWrapperExpandCollpase)`
  & .MuiAccordion-root {
    padding-top: 16px;
  }
  & .MuiCollapse-root {
    padding: 0px;
    & .MuiAccordionDetails-root {
      padding: 0px;
    }
  }
`;

export interface IAssetType {
  readonly assetSubType?: AssetSubType;
}

const getValueText = ({ count }: { count: number }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  if (count === 0) {
    return "-";
  } else if (count === 1) {
    return `${count} ${t("common:LABEL_ASSET_SINGULAR")}`;
  } else {
    return `${count} ${t("common:LABEL_ASSETS_PLURAL")}`;
  }
};

export const InventoryList = ({
  assetData,
  handleInventoryListClick,
  hasSmartInventoryInDialog = true,
  sectionName = null,
}): any => {
  const { t } = useTranslation();
  return (
    <>
      {isOfflineInventoryEnabled() && assetData?.lastScanned ? (
        <BoldWrapper>{`${t("common:LAST_UPDATED")}: ${assetData?.lastScanned || ""}`}</BoldWrapper>
      ) : null}
      {Object.keys(assetData || {}).map((key) => {
        if (validInventoryAttribute.includes(key)) {
          const value = HelperLodash.get(assetData, key);
          return (
            <Inventory
              key={key}
              id={`${key}Id`}
              normalId={`${key}normalId`}
              normalText={t(AssetsType[HelperLodash.constantCase(key)])}
              valueId={`${key}valueId`}
              data-testid={`${key}`}
              valueText={getValueText({ count: value })}
              disabled={!value}
              onItemClick={handleInventoryListClick}
              hasSmartInventoryInDialog={hasSmartInventoryInDialog}
              sectionName={sectionName}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export const Header = ({ title }: { title: string }) => {
  return <IconBold id="textIcon" boldId="lblLocationId" boldText={title} iconUrl={getSmartInventoryIcon()} />;
};

export const Footer = ({ assetSubType }: IAssetType) => {
  const { t } = useTranslation();
  return assetSubType === AssetSubType.Van || assetSubType === AssetSubType.HeavyEquipment ? null : (
    <StyledBottomText className="inventory-wrapper">{t("activeTracking:UPDATE_EVERY_5_MINS")}</StyledBottomText>
  );
};

export const Offline = () => {
  const { t } = useTranslation();
  return (
    <NormalIcon
      id="pnlOfflineGateway"
      normalId="lblOfflineGateway"
      normalText={t("locations:GATEWAY_INVENTORY_AVAILABLE_ONLINE")}
    />
  );
};

export const SendReport = ({ sendReport, assetData }: { sendReport: () => void; assetData: IInventory }) => {
  const { t } = useTranslation();
  const [enable, setEnable] = useState<boolean>(false);

  useEffect(() => {
    if (assetData) {
      Object.keys(assetData || {}).forEach((key) => {
        if (validInventoryAttribute.includes(key)) {
          const value = HelperLodash.get(assetData, key);
          if (value > 0) {
            setEnable(true);
          }
        }
      });
    }
  }, [assetData]);

  return (
    <StyledSendReport id="send" className="send-report" enable={enable}>
      <NormalBoldIcon
        id="gatewayInventoryReportId"
        normalId="sendReportId"
        normalText={t("activeTracking:SEND_REPORT")}
        boldId="sendId"
        boldText={null}
        iconUrl={IconName.Send}
        onIconClick={enable ? sendReport : null}
      />
    </StyledSendReport>
  );
};
