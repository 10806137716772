import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import ApplicationConstants from "../../constant/applicationConstants";
import { INotificationModalProps } from "./iNotificationModalProps";

export const ToastrContainer = styled.div`
  min-height: 56px;
  padding: 17px !important;
`;

export const NotificationImg = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 16px;
  vertical-align: middle;
`;

const Content = styled.div`
  display: inline-flex;
  max-width: 415px;
  color: ${(props) => props.theme.colors.base.white};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  letter-spacing: 0.44px;
  line-height: 24px;
  text-align: left;
  word-break: break-word;
  word-wrap: break-word;
  vertical-align: baseline;
`;

export const LabelContent = styled.a`
  color: ${(props) => props.theme.colors.base.white};
  cursor: pointer;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  margin-right: 16px;
`;
const IconWrap = styled.div`
  display: flex;
  align-items: center;
  path {
    cursor: pointer;
    fill: ${(props) => props.theme.colors.base.white};
  }
`;

export const NotificationModalToastr = (props: INotificationModalProps) => {
  const { handleClose, actionInfo, message, notificationIcon, gtmEvent, gtmData } = props;

  useEffect(() => {
    if (gtmEvent) {
      const windowObj: any = window;

      windowObj.dataLayer = windowObj.dataLayer || [];
      windowObj.dataLayer.push({ event: gtmEvent, ...(gtmData || {}) });
    }
  }, []);

  setTimeout(handleClose, ApplicationConstants.TIMEOUT.TOUT5000);
  return (
    <ToastrContainer id="popperContainer" {...props}>
      <div>
        {notificationIcon ? <NotificationImg id="feedbackImg" src={notificationIcon} /> : null}
        <Content id="feedbackText">{message}</Content>
      </div>
      <IconWrap>
        {actionInfo && <LabelContent onClick={actionInfo.clickHandler}>{actionInfo.name}</LabelContent>}
        <SquareIcon id="feedbackClose" name={IconName.Cross} onClick={handleClose} />
      </IconWrap>
    </ToastrContainer>
  );
};
