// @ts-ignore
import { UserProfileActions, UserSettingActions } from "custom-actions";

/**
 * @description Action method to get User Profile
 * @export
 */
export const userData = () => ({
  type: UserProfileActions.USER_PROFILE,
});

/**
 * @description Action method to get User Me Data
 * @export
 */
export const userMeData = () => ({
  type: UserProfileActions.USER_ME_DATA,
});

export const resetPassword = (data) => ({
  data,
  type: UserProfileActions.RESET_PASSWORD,
});

export const getAppLinks = (data) => ({
  data,
  type: UserSettingActions.GET_APP_LINKS,
});

export const acceptTncResponse = (data) => ({
  data,
  type: UserProfileActions.TNC_ACCEPTED,
});

export const getTouLinks = () => ({
  type: UserSettingActions.GET_TOU_LINKS,
});

export const updateUserAcceptanceFlag = (extWarningFlag: boolean) => ({
  type: UserProfileActions.UPDATE_USER_ACCEPTANCE_FLAG,
  payload: {
    extWarningFlag,
  },
});
