// @ts-ignore
import { UniteActions } from "custom-actions";
import { IUniteState } from "./IUniteModuleState";

const uniteInitialState: IUniteState = {
  managedFields: {},
};

const uniteReducer = (state = uniteInitialState, action) => {
  switch (action.type) {
    case UniteActions.SET_MANAGED_FIELDS:
      return {
        ...state,
        managedFields: action.payload,
      };
    default:
      return state;
  }
};

export default uniteReducer;
