const RoleActionConstants = {
  CLEAR_EDIT_SUCCESS_FLAG: "CLEAR_EDIT_SUCCESS_FLAG",
  CLEAR_ROLE_ERRORS: "CLEAR_ROLE_ERRORS",
  FILTER_FIELD_SUCCESS: "FILTER_FIELD_SUCCESS",
  RESET_ROLE_QUERY_PARAMS: "RESET_ROLE_QUERY_PARAMS",
  ROLE_DESELECT_ROW: "ROLE_DESELECT_ROW",
  ROLE_SELECT_ROW: "ROLE_SELECT_ROW",
  SET_FILTER_QUERY: "SET_FILTER_QUERY",
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  UM_CREATE_ROLE: "UM_CREATE_ROLE",
  UM_CREATE_ROLE_FAILURE: "UM_CREATE_ROLE_FAILURE",
  UM_CREATE_ROLE_SUCCESS: "UM_CREATE_ROLE_SUCCESS",
  UM_DELETE_ROLE: "UM_DELETE_ROLE",
  UM_DELETE_ROLE_FAIL: "UM_DELETE_ROLE_FAIL",
  UM_DELETE_ROLE_SUCCESS: "UM_DELETE_ROLE_SUCCESS",
  UM_EDIT_ROLE: "UM_EDIT_ROLE",
  UM_EDIT_ROLE_FAIL: "UM_EDIT_ROLE_FAIL",
  UM_EDIT_ROLE_SUCCESS: "UM_EDIT_ROLE_SUCCESS",
  UM_GET_FILTER_FIELD: "UM_GET_FILTER_FIELD",
  UM_GET_PERMISSIONS_COUNT: "UM_GET_PERMISSIONS_COUNT",
  UM_GET_PERMISSIONS_GROUP: "UM_GET_PERMISSIONS_GROUP",
  UM_GET_PERMISSIONS_GROUP_SUCCESS: "UM_GET_PERMISSIONS_GROUP_SUCCESS",
  UM_GET_ROLE_DETAILS: "UM_GET_ROLE_DETAILS",
  UM_GET_ROLE_DETAILS_FAILURE: "UM_GET_ROLE_DETAILS_FAILURE",
  UM_GET_ROLE_DETAILS_SUCCESS: "UM_GET_ROLE_DETAILS_SUCCESS",
  UM_SET_ROLE_FILTER_APPLIED_FIELD: "UM_SET_ROLE_FILTER_APPLIED_FIELD",
  UM_SET_SORT_QUERY: "UM_SET_SORT_QUERY",
};

export default RoleActionConstants;
