import React from "react";
import { UpfrontExpansionPanel } from "../../../../../components/DetailsPanel/index";
import { OwnershipType } from "./ownershipEnum";

import {
  costCode,
  fleetCostCenter,
  fleetCurrency,
  fleetDeliveryDate,
  fleetExchangeDate,
  fleetInventoryNumber,
  fleetPurchaseOrderNumber,
  fleetRefNumber,
  loanEndDate,
  loanStartDate,
  loanToolClaim,
  monthlyFleetRate,
  purchaseCurrency,
  purchaseDate,
  purchaseOrderNumber,
  purchasePrice,
  rentReturnDate,
  rentStartDate,
  rentToolClaim,
  replacementCost,
  theftInsurance,
  vendor,
  warrantyExpDate,
  ownershipHiltiAssetStatus,
  rentPurchaseOrderNumber,
  loanPurchaseOrderNumber,
  rentRefNumber,
  loanCostCenter,
  loanInventoryNumber,
  loanRefNumber,
  rentCostCenter,
  rentInventoryNumber,
} from "./detailComponents";
import { ownershipCode, ownershipType } from "./summaryComponents";
import { HelperLodash } from "am-web-ui-shared/helpers";

const detailPanelView = (assetDetailsData) => {
  const type =
    assetDetailsData && assetDetailsData.ownerShipTypeResponse && assetDetailsData.ownerShipTypeResponse.code;
  switch (type) {
    case OwnershipType.OWNED:
      return [
        ownershipHiltiAssetStatus,
        purchaseDate,
        purchasePrice,
        replacementCost,
        purchaseCurrency,
        purchaseOrderNumber,
        warrantyExpDate,
        vendor,
        costCode,
      ];
    case OwnershipType.FLEET:
      return [
        ownershipHiltiAssetStatus,
        fleetDeliveryDate,
        fleetExchangeDate,
        fleetInventoryNumber,
        fleetRefNumber,
        fleetCostCenter,
        fleetPurchaseOrderNumber,
        fleetCurrency,
        costCode,
        loanToolClaim,
        theftInsurance,
        replacementCost,
        monthlyFleetRate,
      ];
    case OwnershipType.LOANED:
      return [
        ownershipHiltiAssetStatus,
        loanStartDate,
        loanEndDate,
        replacementCost,
        costCode,
        loanRefNumber,
        loanCostCenter,
        loanInventoryNumber,
        loanPurchaseOrderNumber,
      ];
    case OwnershipType.RENTED:
      return [
        ownershipHiltiAssetStatus,
        rentStartDate,
        rentReturnDate,
        replacementCost,
        costCode,
        rentToolClaim,
        rentRefNumber,
        rentCostCenter,
        rentInventoryNumber,
        rentPurchaseOrderNumber,
      ];
    default:
      return [];
  }
};

const ownershipObj = {
  FLEET: "fleetAssetDetails",
  LOAN: "loanedAssetDetails",
  OWNED: "ownedAssetDetails",
  RENT: "rentedAssetDetails",
};

/**
 * @description - deciding whether to show expansion panel or not.
 */
const showExpandedPanel = (assetDetailsData) => {
  const type =
    assetDetailsData && assetDetailsData.ownerShipTypeResponse && assetDetailsData.ownerShipTypeResponse.code;
  const assetDetailsDataownershipType = HelperLodash.get(assetDetailsData, HelperLodash.get(ownershipObj, type));
  if (
    (assetDetailsDataownershipType && Object.keys(assetDetailsDataownershipType).length > 0) ||
    assetDetailsData.costCode ||
    assetDetailsData.replacementCost ||
    assetDetailsData.hiltiAssetStatus?.code
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * @description - This is the notes section on the asset details panel.
 */
const ownership = (assetDetailsData) => {
  return (
    <UpfrontExpansionPanel
      panelId="ownership"
      detailsData={assetDetailsData}
      summaryComponents={[ownershipType, ownershipCode]}
      detailComponents={detailPanelView(assetDetailsData)}
      associatedRowId={assetDetailsData.id}
      isEnable={showExpandedPanel(assetDetailsData)}
    />
  );
};

/**
 * @exports ownership - Notes section that renders both header and detail components.
 */
export default ownership;
