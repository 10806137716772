import { EmptyList, IconName, LazyGrid, RowTemplateType } from "am-web-ui-shared/components";
import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
import React, { lazy } from "react";
import { SuspenseWrapper } from "../../../../../reactSuspenseWrapper";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getListObjectValue } from "../../../../../utils/commonUtils";
import { IAssetsListProps } from "../model/iAssetsList";
import AssetStatus from "./assetStatus";
import { targetType } from "../../../../../utils/enums/locationEnums";
import LastSeenCell from "./lastSeenCell";
import LastSeenSourceCell from "./lastSeenSourceCell";
import { View } from "../../../../../utils/enums/assetEnum";
import { getIconByAssetSubType } from "../../../../../utils/assetTypeUtils";
import LazyGridWrapper from "TARGET_BUILD/common/components/lazyGridWrapper";

const NoAssets = lazy(() => import("../../noAssets"));
const NoRecord = lazy(() => import("../../../../../components/noRecord"));
const ImportAssetsButton = lazy(() => import("../../importAssetsButton"));

export class AssetsList extends React.Component<IAssetsListProps> {
  /**
   * @description - Handle grid checkbox selection
   *
   * @param rowObj
   */
  handleCheckboxClick = (rowObj) => {
    this.props.closeMoreFilter();
    const assetModel = rowObj;

    if (rowObj.metaData && rowObj.metaData.isChecked) {
      this.props.onUncheckedRow(assetModel);
    } else {
      this.props.onCheckedRow(assetModel);
    }

    if (!this.props.assetStatusList) {
      this.props.getScanCodeTypeList();
    }
  };

  /**
   * @description - Function to return AssetsStatus component.
   * @param column - Column settings.
   * @param rowData - API response Data.
   * @param {number} rowIndex - Row index.
   * @param {number} columnIndex - Column index.
   */
  getAssetsStatus = (column: any, rowData: any, rowIndex: number, columnIndex: number) => {
    return (
      <AssetStatus
        showTooltip={this.props.showTooltip}
        tooltipValue={this.props.t("common:LABEL_ASSET_STATUS")}
        column={column}
        data={rowData}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
      />
    );
  };

  isChildDataExists = (model) => {
    return model.childData && model.childData.response && model.childData.response.length;
  };

  getListQueryFactors = (model) => {
    const { limit, totalRecords } = model.childData || { limit: 20, totalRecords: 0 };
    let { offset } = model.childData || { offset: 0 };
    offset = limit + offset < totalRecords ? limit + offset : offset;
    return { limit, offset };
  };

  showStorageAssetChildren = (e, model) => {
    // prevent the row click.
    e.stopPropagation();
    if (this.isSearchOrFilterApplied()) {
      this.props.onRowSelection(model);
    } else {
      if (this.isChildDataExists(model)) {
        this.props.setRowExpandedProperty(model);
      } else {
        this.getChildData(model);
      }
    }
  };

  getChildData = (model) => {
    const { limit, offset } = this.getListQueryFactors(model);
    this.props.getChildAssetsData({ limit, offset, model });
  };

  /**
   * @description - Method to get label of grid column property.
   * @param {string} property - Property name.
   */
  getPropertyLabel = (property: string) => {
    return getListObjectValue(this.props.assetFields, property);
  };

  isFilterApplied = () => {
    return HelperLodash.size(this.props.filterQuery);
  };

  showQtyItemRowLink = (data) => {
    return data.qtyItemCount > 0;
  };

  isSearchOrFilterApplied = () => {
    const { searchString, filterQuery } = this.props;
    return searchString.length > 0 || HelperLodash.size(filterQuery);
  };

  getRowTemplate = (data, _rowSetting) => {
    if (this.isSearchOrFilterApplied() && data.childCountWithQTYItem !== 0) {
      return RowTemplateType.Link;
    }
    if (data.childCountWithQTYItem !== 0) {
      return RowTemplateType.Expandable;
    }
    return RowTemplateType.Default;
  };

  getImageField = (data) => {
    if (data.assetImageId) {
      return data.assetImageId;
    } else if (data.templateImageId) {
      return data.templateImageId;
    } else {
      return null;
    }
  };

  getRowLinkText = (data) => {
    if (this.showQtyItemRowLink(data)) {
      return this.props.t("qtyItems:STORED_QTY_ITEMS");
    }
    return this.props.rowSettings.customInfo.textLink;
  };

  getAddtionalText = (data) => {
    if (this.isSearchOrFilterApplied() && data.childCount) {
      return `${data.childCount} ${this.props.t("assets:STORED_ASSETS")}`;
    }
    return null;
  };

  getRowLinkCount = (data) => data.qtyItemCount;

  onRowLinkSelection = (data) => {
    const { history } = this.props;
    const routeLocation = {
      pathname: "/qtyItems",
      state: {
        additionalData: {
          assetId: data.assetId,
        },
        initialFilterValue: [
          {
            assetName: [data.name || " "],
          },
        ],
      },
    };
    if (this.isSearchOrFilterApplied()) {
      return history.push(routeLocation);
    }
    return routeLocation;
  };

  getLocationNameField = (value, type) => {
    let locationType;
    if (type === targetType.LOCATION) {
      locationType = this.props.t("common:LABEL_LOCATION");
    } else if (type === targetType.WORKER) {
      locationType = this.props.t("common:LABEL_WORKER_SINGULAR");
    } else {
      locationType = this.props.t("assets:STORAGE_ASSET");
    }
    return `${value} (${locationType})`;
  };

  /**
   * @description - Function to format idle asset cell.
   * @param value - Idle Days.
   */

  getBooleanToString = (value: boolean | undefined) => {
    const { t } = this.props;
    if (typeof value === "boolean") {
      return value ? t("common:YES") : t("common:NO");
    }
    return "";
  };
  getIdleTimeDuration = (value: string | number) => {
    const { t } = this.props;
    return value || value === 0
      ? value > 1
        ? `${value} ${t("common:DAYS_IDLE")}`
        : `${value} ${t("common:DAY_IDLE")}`
      : "";
  };
  dependencies;

  getCachedDependecies = (t) => {
    if (!this.dependencies) {
      this.dependencies = {
        getAlternateDefaultImage: getIconByAssetSubType,
        getAddtionalText: this.getAddtionalText,
        getAssetsStatus: this.getAssetsStatus,
        getChildData: this.getChildData,
        getImageField: this.getImageField,
        getPropertyLabel: this.getPropertyLabel,
        getRowLinkCount: this.getRowLinkCount,
        getRowLinkText: this.getRowLinkText,
        getRowTemplate: this.getRowTemplate,
        propertyDependencies: {
          assetUsageConditionDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          fleetDeliveryDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          fleetExchangeOrWarrantyDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          lastServiceDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          lastUpdate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          loanEndDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          loanStartDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          nextServiceDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          purchaseDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          rentalReturnDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          rentalStartDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          warrantyExpirationDate: (value) => LocaleManager.dateHelpers.getDateInTenantTimezone(value),
          idleTimeDuration: (value) => this.getIdleTimeDuration(value),
          loanToolClaim: (value) => this.getBooleanToString(value),
          theftInsurance: (value) => this.getBooleanToString(value),
        },
        prefixComponentDependencies: {
          toolLastSeen: (data, name, index, bindProperty) => this.getToolLastSeen(data, name, index, bindProperty),
          lastSeenBy: (data, name, index, bindProperty) => this.getToolLastSeenBy(data, name, index, bindProperty),
        },
        showStorageAssetChildren: this.showStorageAssetChildren,
        t,
      };
    }
    return this.dependencies;
  };

  getToolLastSeen = (value, name, index, bindProperty) => {
    return (
      <LastSeenCell
        assetId={value?.assetId}
        tooltip={this.getPropertyLabel(name)}
        style={bindProperty?.style}
        index={index}
      ></LastSeenCell>
    );
  };

  getToolLastSeenBy = (value, name, index, bindProperty) => {
    return value?.assetId ? (
      <LastSeenSourceCell
        assetId={value?.assetId}
        tooltip={this.getPropertyLabel(name)}
        style={bindProperty?.style}
        index={index}
      ></LastSeenSourceCell>
    ) : null;
  };

  getTotalCount = () => {
    const { parentCount, totalRecords, location } = this.props;
    const additionalData = location?.state?.additionalData ?? { view: View.Parent };
    return additionalData?.view === View.Child ? totalRecords : parentCount;
  };

  renderAssetList = (response, t) => {
    const {
      columnConfig,
      listLoader,
      listScrollRef,
      paginationInfo,
      showTooltip,
      onRowSelection,
      rowSettings,
      isMoreFilterClosed,
      isCustomRefScroll,
      lazyLoadList,
      searchString,
    } = this.props;

    // enforcing usage of custom element scroll ref by negating below, otherwise use window scroll
    const isWindowScroll = isCustomRefScroll ? false : true;
    const totalCount = this.getTotalCount();

    if (listLoader || (response && response.length)) {
      return (
        <LazyGridWrapper searchString={searchString}>
          <LazyGrid
            t={t}
            listScrollRef={listScrollRef}
            totalRecords={totalCount}
            listLength={response && response.length}
            isMoreFilterClosed={isWindowScroll}
            handleRowClick={onRowSelection}
            handleRowLinkClick={this.onRowLinkSelection}
            handleCheckboxClick={this.handleCheckboxClick}
            columns={columnConfig}
            dependencies={this.getCachedDependecies(t)}
            rowsData={response}
            idKey="assetId"
            showTooltip={showTooltip}
            disableCheckBoxColumn={false}
            paginationInfo={paginationInfo}
            isLoading={listLoader}
            rowSettings={rowSettings}
            hideRowArrow={isMoreFilterClosed}
            lazyLoadList={lazyLoadList}
          />
        </LazyGridWrapper>
      );
    } else {
      return this.props.showNoRecordFoundView ? (
        <SuspenseWrapper>
          <NoRecord
            searchString={this.props.searchString}
            objectType={"common:LABEL_ASSET_SINGULAR"}
            resetHandler={this.props.resetHandler}
          />
        </SuspenseWrapper>
      ) : response !== undefined ? (
        <SuspenseWrapper>
          <EmptyList
            name={IconName.EmptyAsset}
            emptyListTitle={t("assets:ASSETS_EMPTY_LIST")}
            emptyList={[{ component: NoAssets }, { component: ImportAssetsButton }]}
          />
        </SuspenseWrapper>
      ) : null;
    }
  };

  render() {
    const { assetsList, t } = this.props;
    return this.renderAssetList(assetsList, t);
  }
}

const translatedAssetsList: any = withTranslation()(AssetsList);
export default withRouter(translatedAssetsList);
