const importHistoryConstants = {
  CLEAR_IMPORT_HISTORY_ERRORS: "CLEAR_IMPORT_HISTORY_ERRORS",
  GET_IMPORT_HISTORY: "GET_IMPORT_HISTORY",
  GET_IMPORT_HISTORY_ERRORS: "GET_IMPORT_HISTORY_ERRORS",
  GET_IMPORT_HISTORY_ERRORS_RESPONSE: "GET_IMPORT_HISTORY_ERRORS_RESPONSE",
  GET_IMPORT_HISTORY_RESPONSE: "GET_IMPORT_HISTORY_RESPONSE",
  IMPORT_HISTORY_RESPONSE: "IMPORT_HISTORY_RESPONSE",
  CLEAR_SERVICE_IMPORT_HISTORY_ERRORS: "CLEAR_SERVICE_IMPORT_HISTORY_ERRORS",
  GET_SERVICE_IMPORT_HISTORY: "GET_SERVICE_IMPORT_HISTORY",
  GET_SERVICE_IMPORT_HISTORY_ERRORS: "GET_SERVICE_IMPORT_HISTORY_ERRORS",
  GET_IMPORT_SERVICE_HISTORY_ERRORS_RESPONSE: "GET_IMPORT_SERVICE_HISTORY_ERRORS_RESPONSE",
  GET_IMPORT_SERVICE_HISTORY_RESPONSE: "GET_IMPORT_SERVICE_HISTORY_RESPONSE",
  IMPORT_SERVICE_HISTORY_RESPONSE: "IMPORT_SERVICE_HISTORY_RESPONSE",
  IMPORT_TYPE_ASSET_TEMPLATES: "asset-templates",
  IMPORT_TYPE_UNIQUE_ASSETS: "unique-assets",
  IMPORT_TYPE_QUANTITY_ITEMS: "quantity-items",
};
export default importHistoryConstants;
