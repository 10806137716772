import React from "react";
import styled from "styled-components";
import { StyledRowsWrapper } from "../../index";
import StyledBoldText from "../BoldText/styledBoldText";
import IAddressPropsProps from "./iAddress";

/**
 * @const StyledWrapper - styled wrapper
 */
const BoldWrapper = styled(StyledBoldText)`
  color: #524f53;
`;

/**
 * @const Address is a Styled Component that consist of 6 things:
 * 1. Normal Text: This is the text with hilti-roman font-family for address header.
 * 2. Bold Text: This is the text with hilti-bold font-family for addressline1.
 * 3. Bold Text: This is the text with hilti-bold font-family for addressline2.
 * 4. Bold Text: This is the text with hilti-bold font-family for city.
 * 5. Bold Text: This is the text with hilti-bold font-family for status and postal code.
 * 6. Bold Text: This is the text with hilti-bold font-family for country.
 * IDs and Texts for the rows are configurable from the component using this component.
 */
const Address = (props: IAddressPropsProps) => {
  const {
    id,
    boldAddressLine1Id,
    boldAddressLine1Text,
    boldAddressLine2Id,
    boldAddressLine2Text,
    boldCityId,
    boldCityText,
    boldStatePostalCodeId,
    boldStatePostalCodeText,
    boldCountryId,
    boldCountryText,
  } = props;
  return (
    <div id={id} className="address-wrapper">
      <StyledRowsWrapper>
        <BoldWrapper id={boldAddressLine1Id}>{boldAddressLine1Text}</BoldWrapper>
        <BoldWrapper id={boldAddressLine2Id}>{boldAddressLine2Text}</BoldWrapper>
        <BoldWrapper id={boldCityId}>{boldCityText}</BoldWrapper>
        <BoldWrapper id={boldStatePostalCodeId}>{boldStatePostalCodeText}</BoldWrapper>
        <BoldWrapper id={boldCountryId}>{boldCountryText}</BoldWrapper>
      </StyledRowsWrapper>
    </div>
  );
};

/**
 * @export Address - Styled Component.
 */
export default Address;
