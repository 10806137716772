import React from "react";
import { change, getFormValues } from "redux-form";
import SearchBar from "TARGET_BUILD/common/components/searchBox/searchBar";
import filterConfig from "./filterConfig";
import styled from "styled-components";
import Filter, { FilterAttributes } from "TARGET_BUILD/common/models/filter";
import { useDispatch, useSelector } from "react-redux";
import SearchBoxWrapper from "TARGET_BUILD/common/components/searchBox/searchBoxWrapper";
import { useTranslation } from "react-i18next";

const FilterWrapper = styled.div`
  width: 996px;
  padding: 0;
  .field-wrapper {
    margin-bottom: 0;
  }
`;

const SUGGESTEDTRANSFERS_FILTER_FORM = "suggestedTransfersFilters";

export type SuggestedTransfersFiltersProps = {
  getFilterAttributes: (appliedFilterQuery: Filter[]) => void;
  filterAttributes: FilterAttributes;
  isMoreFiltersOpen: boolean;
  setIsMoreFiltersOpen: (value: boolean) => void;
  handleListChange: (filterQuery: Filter[]) => void;
  appliedFilterQuery: Filter[];
  setAppliedFilterQuery: (filterQuery: Filter[]) => void;
  searchstring: string;
  handleSearchstringChange: (value: string) => void;
};

const SuggestedTransfersFilters = ({
  getFilterAttributes,
  filterAttributes,
  isMoreFiltersOpen,
  setIsMoreFiltersOpen,
  handleListChange,
  appliedFilterQuery,
  setAppliedFilterQuery,
  searchstring,
  handleSearchstringChange,
}: SuggestedTransfersFiltersProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterFormValues = useSelector((state) => getFormValues(SUGGESTEDTRANSFERS_FILTER_FORM)(state));

  const handleChangeFilterFormValues = (field: string, value: string) => {
    dispatch(change(SUGGESTEDTRANSFERS_FILTER_FORM, field, value));
  };

  const handleFilterChange = (filterQuery: Filter[]) => {
    handleListChange(filterQuery);
    setAppliedFilterQuery(filterQuery);
  };

  return (
    <FilterWrapper>
      <SearchBar
        searchComponent={
          // component has debounce handled within
          <SearchBoxWrapper
            placeholder={t("alertAndNotification:SEARCH_SUGGESTED_TRANSFERS")}
            handleSearchChange={handleSearchstringChange}
            value={searchstring}
          />
        }
        filterAttributes={filterAttributes}
        handleFilterChange={handleFilterChange}
        filterSettings={filterConfig}
        getFilterAttributes={getFilterAttributes}
        parentMoreFilterHandler={{
          closeMoreFilter: () => setIsMoreFiltersOpen(false),
          isMoreFilterClosed: isMoreFiltersOpen,
          openMoreFilter: () => setIsMoreFiltersOpen(true),
        }}
        appliedFilterQuery={appliedFilterQuery}
        filterFormValues={filterFormValues}
        filterFormName={SUGGESTEDTRANSFERS_FILTER_FORM}
        changeFilterFormValues={handleChangeFilterFormValues}
      />
    </FilterWrapper>
  );
};

export default SuggestedTransfersFilters;
