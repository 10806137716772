/**
 * @description This file is for all the api hitting using
 * axios for the deletion settings module.
 */

import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import IDeletionSettingsResponse from "../modules/deletionSettings/iDeletionSettingsResponse";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";

/**
 * Returns Deletion settings response
 */
export const getTransferSettingsApi = async (): Promise<IDeletionSettingsResponse | IErrorResponse> => {
  const url = getOrgMgmtEndpoint(`/company/transfer-asset-setting`);
  return Gateway.get(url, null, null, null, false) as unknown as IDeletionSettingsResponse;
};

/**
 * Update Deletion Settings
 * @param updatedData: Tracking flag to enable or disable the settings
 */
export const updateTransferSettingsApi = async (updatedData): Promise<IDeletionSettingsResponse | IErrorResponse> => {
  const url = getOrgMgmtEndpoint(`/company/transfer-asset-setting`);
  return Gateway.put(url, null, { isEnabled: updatedData }, null) as unknown as IDeletionSettingsResponse;
};
