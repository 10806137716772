import React from "react";
const SVG = () => (
  <svg width="8px" height="20px" viewBox="0 0 8 20" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-8.000000, -2.000000)" fill="#524F53">
        <g>
          <path
            d="M12.0111933,2 L16,7 L13,7 L13,17 L16,17 L12.0111933,22 L8,17 L11,17 L11,7 L8,7 L12.0111933,2 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
