import { CellTypes, IconName, IGridColumnSetting } from "am-web-ui-shared/components";
// @ts-ignore
import { ImageSizeEnum } from "../../styled/squareImage/imageSizeUtil";
import squareImageContainer from "../../styled/squareImage/squareImageContainer";

export const AssetTransferGridSettings: IGridColumnSetting[] = [
  {
    bindProperties: [
      {
        key: "manufacturerName",
        properties: [{ name: "manufacturerName" }, { name: "model" }],
      },
      {
        key: "name",
        properties: [{ name: "name" }],
      },
    ],
    cellSize: 4,
    image: {
      attributes: {
        image: { maxWidth: ImageSizeEnum.SMALL, maxHeight: ImageSizeEnum.SMALL },
        wrapper: {
          height: ImageSizeEnum.SMALL,
          imageSize: 42,
          style: { marginRight: "16px" },
          width: ImageSizeEnum.SMALL,
        },
      },
      component: squareImageContainer,
      defaultImage: IconName.Category,
      dependencies: {
        getAlternateDefaultImage: "getAlternateDefaultImage",
      },
      fieldName: "assetImageId",
    },
    isSortable: false,
    type: CellTypes.ImageFieldCombination,
  },
  {
    bindProperties: [
      {
        key: "currentLocationName",
        properties: [{ name: "currentLocationName" }],
      },
      {
        key: "responsibleEmployeeName",
        properties: [{ name: "responsibleEmployeeName" }],
      },
    ],
    cellSize: 3,
    isSortable: false,
    type: CellTypes.FieldCombination,
  },
  {
    bindProperties: [
      {
        key: "inventoryNumber",
        properties: [{ name: "inventoryNumber" }],
      },
      {
        key: "scanCode",
        properties: [{ name: "scanCode" }],
      },
    ],
    cellSize: 2,
    isSortable: true,
    type: CellTypes.FieldCombination,
  },
  {
    bindProperties: [
      {
        key: "assetStatusResponse",
        properties: [{ name: "assetStatusResponse" }],
      },
    ],
    cellSize: 3,
    component: "getAssetsStatus",
    isSortable: false,
    type: "custom",
  },
];
