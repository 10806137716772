import React, { createContext } from "react";

export type SearchString = { searchString: string; isSearchHighlightEnabled: boolean };

export const SearchContext = createContext<SearchString>({
  searchString: "",
  isSearchHighlightEnabled: false,
});

export const SearchProvider = SearchContext.Provider;
