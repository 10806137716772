/**
 * @description - Add class on body
 * @param - class name
 *
 */
export const addClassOnBody = (className: string) => document.body.classList.add(className);

/**
 * @description - Remove class on body
 * @param - class name
 *
 */
export const removeClassOnBody = (className: string) => document.body.classList.remove(className);

/**
 * @description - toggle class on body
 * @param - class name
 *
 */
export const toggleClassOnBody = (className: string) => document.body.classList.toggle(className);
export default { addClassOnBody, removeClassOnBody, toggleClassOnBody };
