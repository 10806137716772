import { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { get } from "TARGET_BUILD/../../shared/helpers/helperLodash";
import { analyticsLicenseTypes } from "TARGET_BUILD/../../shared/helpers/sharedUtils";
import { hasFleetContract } from "TARGET_BUILD/common/api/userProfileApi";
import { getCompanyDetails } from "TARGET_BUILD/common/modules/dashboard/redux/dashboardSelectors";
import { RootState } from "TARGET_BUILD/common/store/store";
import { trackUserData, setUserLicenseForGA } from "../events/user";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import { getEnabledTenantIdsForUtilizationAndBenchmarking } from "TARGET_BUILD/common/utils/configUtil";
import { getTenantIdFromState } from "TARGET_BUILD/common/modules/asset/reduxModule/assetSelectors";
import { LicenseBundleToLicenseTypeValue } from "../../common/utils/enums/licenseBundleEnum";

export const UserDataDetails = () => {
  const companyDetails = useSelector(getCompanyDetails, shallowEqual);
  const userData = useSelector(({ UserReducer }: RootState) => UserReducer?.userMeData);
  const tenantId = useSelector(getTenantIdFromState);

  const enableWalkMeFleet = (licenseType: string, licenseBundle: string) => {
    const isLicenseBundleFeatureEnabled = SingletonFeatureManager.isFeatureEnabled(FEATURES.LICENSE_BUNDLE);
    if (isLicenseBundleFeatureEnabled) {
      //not changing google analytics data from licenseType to licenseBundle
      window["hilti_license_type"] = get(LicenseBundleToLicenseTypeValue, licenseBundle);
    } else {
      window["hilti_license_type"] = get(analyticsLicenseTypes, licenseType); //Enabled WalkMe for OnTrack ready
    }
  };

  const setUAndBStatusForWalkMe = () => {
    const hasFeatureFlagForUAndB = Boolean(
      tenantId &&
        SingletonFeatureManager.isFeatureEnabled(FEATURES.DASHBOARD_UTILIZATION_AND_BENCHMARKING) &&
        getEnabledTenantIdsForUtilizationAndBenchmarking().includes(tenantId),
    );

    window["hilti_u_and_b_enabled"] = hasFeatureFlagForUAndB;
  };

  const masterContract = async () => {
    const { licenseType, email, userRole, licenseBundle } = userData;
    const { originCustomerId, country, displayName, tenantType } = companyDetails;
    const fleetMasterContract = await hasFleetContract(originCustomerId, country.code);
    let isFleetContract: null | boolean = null;

    if ("isFleetContract" in fleetMasterContract) {
      isFleetContract = fleetMasterContract.isFleetContract;
    }

    trackUserData({
      isLoggedIn: true,
      userRole: userRole.value,
      email,
      customerId: originCustomerId + country.code,
      displayName,
      countryCode: country.code,
      licenseType,
      isMasterContract: isFleetContract,
      tenantType,
      licenseBundle,
    });
  };

  useEffect(() => {
    if (userData && companyDetails) {
      const { licenseType, licenseBundle } = userData;
      setUAndBStatusForWalkMe();
      setUserLicenseForGA(licenseType, licenseBundle);
      enableWalkMeFleet(licenseType, licenseBundle);
      masterContract();
    }
  }, [userData, companyDetails]);

  return null;
};

export default UserDataDetails;
