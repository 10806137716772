import React from "react";
import { withTranslation } from "react-i18next";
import { Status } from "../../../../../components/DetailsPanel/index";
import {
  getChildGatewayStatus,
  isChildGatewayStatusVisible,
  isVanGatewaysSmartInventoryEnabled,
} from "TARGET_BUILD/common/modules/assetTracking/components/smartInventory/presentational/smartInventoryUtils";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import { ITrackingPayload } from "TARGET_BUILD/common/models/map/bleMap/trackingPayloalModel";
import { IAssetDetailsData } from "TARGET_BUILD/common/models/asset/assetModel";
import { useTranslation } from "react-i18next";
import { AssetSubType } from "TARGET_BUILD/common/utils/enums/assetEnum";
import { useLocationContext } from "TARGET_BUILD/common/modules/assetTracking/components/locationDetails/locationContext";
import { isAssetTrackedByVanEnabled } from "TARGET_BUILD/common/utils/activeTrackingUtils";
import { GeoTagBatteryStatusEnum } from "TARGET_BUILD/common/utils/enums/activeTrackingEnum";

/*
Priority is private mode over child gateway status
*/

enum BatteryStatus {
  low = "common:GEO_TAG_BATTERY_LOW",
  critical = "common:GEO_TAG_BATTERY_CRITICAL",
}

const batteryStatusMap: Record<string, string> = BatteryStatus;

export const isPrivateModeEnabled = (asset: Partial<IAssetDetailsData>, location: ITrackingPayload) =>
  isAssetTrackedByVanEnabled() &&
  (asset?.assetSubType?.code === AssetSubType.Van || asset?.assetSubType?.code === AssetSubType.Unique) &&
  location?.privacyModeEnabled;

export const isChildGatewayAvailable = (data: Partial<IAssetDetailsData>) =>
  isVanGatewaysSmartInventoryEnabled() && isChildGatewayStatusVisible(data);

const ChildStatus = (props: { asset: Partial<IAssetDetailsData> }) => {
  const { asset } = props;
  const { t } = useTranslation();
  const { location } = useLocationContext();
  const GEO_TAG_BATTERY = "common:GEO_TAG_BATTERY";
  const status = location?.position?.geoTagBatteryStatus || "";

  if (status.toLowerCase() !== GeoTagBatteryStatusEnum.GOOD && batteryStatusMap[status.toLowerCase()]) {
    return (
      <Status
        id={`geoTagBtryStatus`}
        statusCode={status}
        statusText={`${t(GEO_TAG_BATTERY)} ${t(batteryStatusMap[status])}`}
      />
    );
  }

  return isPrivateModeEnabled(asset, location?.position) ? (
    <Status id={`txtPrivateModeVan`} statusCode={"ONLINE"} statusText={t("companies:PRIVATE_MODE_ASSET_DETAILS")} />
  ) : (
    isChildGatewayAvailable(asset) && (
      <Status
        id={`txtGatewayStatus`}
        statusCode={asset.childGatewayStatus.code}
        statusText={t(getChildGatewayStatus(asset))}
      />
    )
  );
};

export const ChildGatewayStatus = withTranslation()(ChildStatus);
