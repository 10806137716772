import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M10,16 L10,20 L2,20 L2,16 L10,16 Z M16,10 L16,14 L2,14 L2,10 L16,10 Z M22,4 L22,8 L2,8 L2,4 L22,4 Z" />
    </g>
  </svg>
);

export default SVG;
