import { CellTypes, IGridSetting } from "am-web-ui-shared/components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";

const editServiceHistoryListGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          key: "provider",
          properties: [{ name: "provider" }],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "serviceName",
          properties: [{ name: "serviceName" }],
          style: {
            fontWeight: 700,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceDueDate",
          properties: [
            {
              name: "scheduleDate",
              dependencies: { format: "formatScheduleDate" },
            },
          ],
        },
        {
          key: "serviceCompletionDate",
          properties: [
            {
              name: "completionDate",
              dependencies: { format: "formatCompletionDate" },
            },
          ],
        },
        {
          key: "scheduleAt",
          properties: [
            {
              name: "scheduleAt",
              dependencies: { format: "formatScheduleAt" },
            },
          ],
        },
        {
          key: "completionAt",
          properties: [
            {
              name: "completionAt",
              dependencies: { format: "formatCompletionAt" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "cost",
          properties: [{ name: "cost" }],
        },
        {
          key: "attachmentsLabel",
          properties: [
            {
              name: "attachmentCount",
              dependencies: { format: "formatAttachmentCount" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceState",
          properties: [{ name: "serviceState" }],
        },
      ],
      cellSize: 3,
      component: "ServiceStatus",
      isSortable: true,
      type: CellTypes.Custom,
    },
  ],
  showTooltip: true,
};
const editServiceHistoryListGridSettingWithResult: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          key: "provider",
          properties: [{ name: "provider" }],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "serviceName",
          properties: [{ name: "serviceName" }],
          style: {
            fontWeight: 700,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceDueDate",
          properties: [
            {
              name: "scheduleDate",
              dependencies: { format: "formatScheduleDate" },
            },
          ],
        },
        {
          key: "serviceCompletionDate",
          properties: [
            {
              name: "completionDate",
              dependencies: { format: "formatCompletionDate" },
            },
          ],
        },
        {
          key: "scheduleAt",
          properties: [
            {
              name: "scheduleAt",
              dependencies: { format: "formatScheduleAt" },
            },
          ],
        },
        {
          key: "completionAt",
          properties: [
            {
              name: "completionAt",
              dependencies: { format: "formatCompletionAt" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "cost",
          properties: [{ name: "cost" }],
        },
        {
          key: "attachmentsLabel",
          properties: [
            {
              name: "attachmentCount",
              dependencies: { format: "formatAttachmentCount" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceResult",
          properties: [{ name: "serviceResult" }],
        },
      ],
      cellSize: 3,
      component: "ServiceResult",
      isSortable: true,
      type: CellTypes.Custom,
    },
  ],
  showTooltip: true,
};
const addServiceHistoryListGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          key: "provider",
          properties: [{ name: "provider" }],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "serviceName",
          properties: [{ name: "name" }],
          style: {
            fontWeight: 700,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceDueDate",
          properties: [
            {
              name: "scheduleDate",
              dependencies: { format: "formatScheduleDate" },
            },
          ],
        },
        {
          key: "serviceCompletionDate",
          properties: [
            {
              name: "completionDate",
              dependencies: { format: "formatCompletionDate" },
            },
          ],
        },
        {
          key: "scheduleAt",
          properties: [
            {
              name: "scheduleAt",
              dependencies: { format: "formatScheduleAt" },
            },
          ],
        },
        {
          key: "completionAt",
          properties: [
            {
              name: "completionAt",
              dependencies: { format: "formatCompletionAt" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "cost",
          properties: [{ name: "cost" }, { name: "costCurrency" }],
        },
        {
          key: "attachmentsLabel",
          properties: [
            {
              name: "serviceAttachmentsCount",
              dependencies: { format: "formatServiceAttachments" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
  ],
  showTooltip: true,
};

export default {
  editServiceHistoryListGridSetting,
  editServiceHistoryListGridSettingWithResult,
  addServiceHistoryListGridSetting,
};
