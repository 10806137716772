import { IconName, Prompt, SquareIcon } from "am-web-ui-shared/components";
import Tooltip from "@hilti/components/core/tooltip";
import { AuthorizationCore, CheckAuthorization } from "am-web-ui-shared/helpers";
import has from "lodash/has";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import Grid from "@mui/material/Grid";
// @ts-ignore;
import theme from "TARGET_BUILD/theme";
import LocationDetailsWrapper from "../../../assetTracking/components/locationDetails/locationDetailsWrapper";
import { ServiceStatus } from "../../../../components/services/serviceFormController/serviceFormEnum";
import SmartInventoryAssetsContainerWrapper from "../../../assetTracking/components/smartInventory/container/smartInventoryAssetsContainerWrapper";
import SmartInventoryDetailsView from "../../../assetTracking/components/smartInventory/presentational/smartInventoryDetailsView";
import { ImageSizeEnum } from "../../../../components/styled/squareImage/imageSizeUtil";
import SquareImage from "../../../../components/styled/squareImage/squareImageContainer";
import { isTemplateAssociated } from "../../../../models/charges/chargesOrchestration";
import { storageAssetsGridSetting } from "../../../transferCart/components/list/storageAssetGridSetting";
import { IAssetDetailsPanelProps } from "./iAssetDetails";
import {
  attachments,
  ChargeSettingSection,
  mainSection,
  NotesSection,
  ownership,
  responsibleWorkerSection,
  RepairTrackingSection,
} from "./index";
import Services from "./servicesSection/services";
import {
  nextServiceDateDetails,
  nextServiceNameDetails,
  lastServiceDateDetails,
  lastServiceNameDetails,
} from "./servicesSection/detailComponents";
import StorageAsset from "./storageAsset/storageAsset";
import AssetTemplateSection from "./template/assetTemplateSection";
import TransferDetailsView from "./transferDetails/transferDetailsView";
import { StatusCode, HiltiStatusCode, AssetSubType } from "../../../../utils/enums/assetEnum";
import { HolRepairConstant } from "../../../../utils/holUtils";
import ImageTypeEnum from "../../../../utils/enums/imageTypeEnum";
import { HighResolutionPreviewEnum } from "../../../../utils/enums/imageThumbnailRatioEnum";
import OriginalImage from "./../../../../../common/components/originalImage/originalImage";
import LockedPanelList from "TARGET_BUILD/common/modules/lockedPanel/lockedPanelList";
import { getIconByAssetSubType } from "../../../../utils/assetTypeUtils";
import { IAssetDetailsData } from "../../../../models/asset/assetModel";

import SingletonFeatureManager from "../../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../../featureFlagManager/featureConfig";
import {
  getAssetSmartInventoryHeaderText,
  isSmartInventoryVisible,
} from "../../../assetTracking/components/smartInventory/presentational/smartInventoryUtils";
import { LocationProvider } from "TARGET_BUILD/common/modules/assetTracking/components/locationDetails/locationContext";
import UtilizationSection from "./utilizationSection/utilizationSection";
import SingletonLicenseContext from "TARGET_BUILD/licenseStrategy/singletonLicenseContext";

const ImageWrapper = styled(Grid)`
  height: 150px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.base.white};
  align-items: center;
  display: flex;
  justify-content: center;
`;
const TopPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`;
const AssetImageContainer = styled.div`
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: ${(props) => props.theme.colors.base.white};
  cursor: pointer;
`;
const FullScreenIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  cursor: pointer;
`;

export class AssetDetailPanel extends React.PureComponent<IAssetDetailsPanelProps> {
  state = {
    showAssetOriginalImageDialog: false,
    showTemplateOriginalImageDialog: false,
  };
  heavyEquipmentFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE);
  UtilizationFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_UTILIZATION);
  proLicenseFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.PRO_LICENSING);
  licensePermissions = SingletonLicenseContext.getAssetsContext();
  openAssetOriginalImageDialog = () => {
    this.setState({ showAssetOriginalImageDialog: true });
  };

  openTemplateOriginalImageDialog = () => {
    this.setState({ showTemplateOriginalImageDialog: true });
  };

  closeOriginalImageDialog = () => {
    this.setState({
      showAssetOriginalImageDialog: false,
      showTemplateOriginalImageDialog: false,
    });
  };

  showSmartInventory = (hasSmartInventory, assetDetails) => {
    const smartInventoryFlag = hasSmartInventory && isSmartInventoryVisible(assetDetails);
    return this.proLicenseFF
      ? this.licensePermissions?.getProLicenses()?.hasSmartInventory && smartInventoryFlag
      : smartInventoryFlag;
  };

  showUtilizationPanel = (assetDetailsData) => {
    const utiliationFlag =
      this.UtilizationFF &&
      assetDetailsData?.assetSubType?.code === AssetSubType.HeavyEquipment &&
      (has(assetDetailsData, "totalEngineHours") || has(assetDetailsData, "totalDistance"));

    return this.proLicenseFF
      ? this.licensePermissions?.getProLicenses()?.hasUtilization && utiliationFlag
      : utiliationFlag;
  };

  renderTopPanel = () => {
    const { assetImageId, templateImageId } = this.props.assetDetailsData;
    const { t } = this.props;
    return assetImageId || templateImageId ? (
      <TopPanelContainer>
        {assetImageId ? (
          <Tooltip
            id="originalImageTooltipId"
            className="action-wrapper"
            placement="top"
            followCursor
            title={t("common:CLICK_TO_ENLARGE")}
          >
            <AssetImageContainer onClick={this.openAssetOriginalImageDialog}>
              {this.renderImage(assetImageId)}
            </AssetImageContainer>
          </Tooltip>
        ) : null}

        {templateImageId ? (
          <Tooltip
            id="originalImageTooltipId"
            className="action-wrapper"
            placement="top"
            title={t("common:CLICK_TO_ENLARGE")}
            followCursor
          >
            <AssetImageContainer onClick={this.openTemplateOriginalImageDialog}>
              {this.renderImage(templateImageId)}
            </AssetImageContainer>
          </Tooltip>
        ) : null}
      </TopPanelContainer>
    ) : (
      <TopPanelContainer>
        <AssetImageContainer>{this.renderImage(null, this.props.assetDetailsData)}</AssetImageContainer>
      </TopPanelContainer>
    );
  };

  originalImageDialog = () => {
    const { showAssetOriginalImageDialog, showTemplateOriginalImageDialog } = this.state;
    const { assetImageId, templateImageId } = this.props.assetDetailsData;
    return showAssetOriginalImageDialog || showTemplateOriginalImageDialog ? (
      <Prompt
        handleClose={this.closeOriginalImageDialog}
        showCancel={false}
        showConfirm={false}
        crossIcon={true}
        isHeader={false}
        dialogScrollClassName="auto-margin-dialog"
        wrapperStyleAttributes={{ minWidth: "300px", width: "100%" }}
      >
        {showAssetOriginalImageDialog && <OriginalImage id="assetOriginalImageId" documentId={assetImageId} />}
        {showTemplateOriginalImageDialog && <OriginalImage id="assetOriginalTemplateId" documentId={templateImageId} />}
      </Prompt>
    ) : null;
  };

  /**
   * @description renderImage render the image
   * @param imageId contains the image id
   */
  renderImage = (imageId, assetDetailsData?: IAssetDetailsData) => {
    const iconName = getIconByAssetSubType(assetDetailsData) as string;
    return (
      <>
        {imageId ? (
          <SquareImage
            id="imgAssetTemplate"
            wrapperAttributes={{
              height: ImageSizeEnum.MEDIUM,
              style: { background: theme.colors.base.white },
              width: "100%",
            }}
            defaultSrc={null}
            documentId={imageId}
            type={ImageTypeEnum.thumbnail}
            thumbnailHeight={HighResolutionPreviewEnum.HighResHeight}
            thumbnailWidth={HighResolutionPreviewEnum.HighResWidth}
          />
        ) : (
          <ImageWrapper>
            <SquareIcon name={iconName} className="large" imageSize={128} />
          </ImageWrapper>
        )}
      </>
    );
  };

  /**
   * @description show repair status tracking link on detail panel
   */
  showRepairStatusTracking = () => {
    const { assetDetailsData, companyDetails } = this.props;
    const { isSapAsset } = assetDetailsData;
    const { isDomainUser } = this.props?.userMeData;

    const customerId =
      assetDetailsData?.originCustomerId === companyDetails?.originCustomerId &&
      assetDetailsData?.moCountryCode === companyDetails?.country?.code;

    return (
      isSapAsset &&
      (assetDetailsData?.assetStatusResponse?.code === StatusCode.Repair ||
        assetDetailsData?.assetStatusResponse?.code === StatusCode.Operational) &&
      assetDetailsData?.hiltiAssetStatus?.code !== HiltiStatusCode.COLLECTORRETURN &&
      companyDetails?.tenantType !== HolRepairConstant.TENANT_TYPE_BYD &&
      !isDomainUser &&
      customerId
    );
  };

  /**
   * get the details of charged
   */
  getChargesDetail = (assetInfo) => () => {
    const {
      templateChargeSetting,
      chargesDetail,
      fetchChargeSetting,
      expansionPanelDetailsLoader,
      t,
      tenantId,
      timeBasedEnabledTenantList,
    } = this.props;
    if (isTemplateAssociated(assetInfo)) {
      return (
        <ChargeSettingSection
          chargeSettingData={{
            ...templateChargeSetting(assetInfo.assetTemplateId),
            tenantId,
            timeBasedEnabledTenantList,
          }}
          fetchChargeSetting={fetchChargeSetting}
          expansionPanelDetailsLoader={expansionPanelDetailsLoader}
          t={t}
          id={assetInfo.assetId}
        />
      );
    } else {
      return (
        <ChargeSettingSection
          chargeSettingData={{
            ...chargesDetail,
            tenantId,
            timeBasedEnabledTenantList,
          }}
          fetchChargeSetting={fetchChargeSetting}
          expansionPanelDetailsLoader={expansionPanelDetailsLoader}
          t={t}
          id={assetInfo.assetId}
        />
      );
    }
  };

  showTransferView = (hasTransferView: boolean) => {
    const { assetDetailsData } = this.props;
    return hasTransferView && assetDetailsData?.lastTransferDate;
  };

  onBeforeRedirect = () => {
    const { showAlertListPopup, toggleAlertList } = this.props;
    return showAlertListPopup && toggleAlertList();
  };

  /**
   * render function of the component
   */
  render() {
    const {
      assetType,
      assetDetailsData,
      companyDetails,
      t,
      transferHistory,
      showManageServices,
      gatewayAssets,
      getList,
      updateAssetType,
      sendAssetReport,
      showAssetListDialog,
      getFilterAttributes,
      getAssetTransferDetails,
      searchRepairOrderInfo,
      config,
      distanceUnitValue,
      utilizationSummary,
      utilizationDetails,
      getAssetUtiliationSummary,
      getAssetUtilizationDetails,
    } = this.props;
    const {
      hasSmartInventory,
      hasSmartInventoryInDialog = true,
      hasStorage,
      hasCost,
      lockedAssetItems,
      hasTransferView = true,
      hasRepairStatusTracking = true,
      hasAssetUsageConditionState = true,
      hasLocationMap = true,
    } = config;

    return (
      <LocationProvider>
        {this.originalImageDialog()}
        {this.renderTopPanel()}
        {mainSection(assetDetailsData, { hasAssetUsageConditionState })}
        <LocationDetailsWrapper
          item={assetDetailsData}
          onBeforeRedirect={this.onBeforeRedirect}
          hasLocationMap={hasLocationMap}
        />
        {responsibleWorkerSection(assetDetailsData)}
        {this.showUtilizationPanel(assetDetailsData) && (
          <UtilizationSection
            utilizationSummary={utilizationSummary}
            utilizationDetails={utilizationDetails}
            getAssetUtiliationSummary={getAssetUtiliationSummary}
            getAssetUtilizationDetails={getAssetUtilizationDetails}
            assetDetailsData={assetDetailsData}
            distanceUnitValue={distanceUnitValue}
          />
        )}
        {this.showSmartInventory(hasSmartInventory, assetDetailsData) && (
          <SmartInventoryDetailsView
            id={assetDetailsData?.assetId}
            getGatewaySmartInventory={this.props.getStorageAssetGatewayAssets}
            assetSubType={assetDetailsData?.assetSubType?.code}
            gatewayAssets={gatewayAssets}
            getList={getList}
            updateAssetType={updateAssetType}
            sendReport={sendAssetReport}
            expansionPanelDetailsLoader={this.props.expansionPanelDetailsLoader}
            hasSmartInventoryInDialog={hasSmartInventoryInDialog}
          />
        )}
        {showAssetListDialog && (
          <SmartInventoryAssetsContainerWrapper
            assetType={assetType}
            id={assetDetailsData.assetId}
            getList={getList}
            header={t(getAssetSmartInventoryHeaderText(assetDetailsData?.assetSubType?.code))}
            columnConfig={storageAssetsGridSetting}
            getFilterAttributes={getFilterAttributes}
            rowData={assetDetailsData}
          />
        )}
        {hasStorage && <StorageAsset assetDetailsData={assetDetailsData} />}
        {this.showTransferView(hasTransferView) && (
          <TransferDetailsView
            handleCancelTransfer={this.props.cancelLastTransfer}
            fetchList={this.props.getPaginatedAssetTransferDetails}
            transferHistory={transferHistory}
            selectedRow={assetDetailsData}
            getAssetTransferDetails={getAssetTransferDetails}
          />
        )}
        <Services
          assetId={assetDetailsData.assetId}
          icon={IconName.Assign}
          servicesCount={assetDetailsData.openServicesCount}
          status={ServiceStatus.OPEN}
          text={"services:UPCOMING_SERVICES"}
          t={t}
          showManageServices={showManageServices}
          clearAssetServiceList={this.props.clearAssetServiceList}
          showServiceList={this.props.showServiceList}
          closeMoreFilter={this.props.closeMoreFilter}
          assetDetailsData={assetDetailsData}
          showMoreText={"services:MORE_UPCOMING_SERVICES"}
          detailComponents={[nextServiceDateDetails, nextServiceNameDetails]}
          defaultExpanded={true}
          distanceUnitValue={distanceUnitValue}
        />
        <Services
          assetId={assetDetailsData.assetId}
          icon={IconName.HistoricServices}
          servicesCount={assetDetailsData.historyServicesCount}
          status={ServiceStatus.HISTORY}
          text={"services:HISTORIC_SERVICES"}
          t={t}
          showManageServices={showManageServices}
          clearAssetServiceList={this.props.clearAssetServiceList}
          showServiceList={this.props.showServiceList}
          closeMoreFilter={this.props.closeMoreFilter}
          assetDetailsData={assetDetailsData}
          showMoreText={"services:MORE_HISTORIC_SERVICES"}
          detailComponents={[lastServiceDateDetails, lastServiceNameDetails]}
          distanceUnitValue={distanceUnitValue}
        />
        {hasRepairStatusTracking && this.showRepairStatusTracking() && (
          <RepairTrackingSection
            assetDetailsData={assetDetailsData}
            companyDetails={companyDetails}
            searchRepairOrder={searchRepairOrderInfo}
          />
        )}
        {attachments(assetDetailsData.assetAttachments)}
        <NotesSection assetDetailsData={assetDetailsData} />
        {ownership(assetDetailsData)}
        {hasCost && AuthorizationCore.hasActiveModule("ASSET_COST") && (
          <CheckAuthorization yes={this.getChargesDetail(assetDetailsData)} permissionKey={"assetCost.settings.view"} />
        )}
        <AssetTemplateSection assetDetailsData={assetDetailsData} />
        {lockedAssetItems ? <LockedPanelList items={lockedAssetItems} /> : null}
      </LocationProvider>
    );
  }
}

export default AssetDetailPanel;
