import { DetailsPanel, FieldWrapper, GridLayout, IconName, SquareIcon } from "am-web-ui-shared/components";
import Tooltip from "@hilti/components/core/tooltip";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { fieldSpacingSetting } from "../../utils/styledUtils";
import INotificationPanel from "./iNotificationPanel";
const IconHeading = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
const Heading = styled.h3`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: 19px;
  margin-right: 8px;
`;

export const NotificationPanel = (props: INotificationPanel) => {
  const {
    notificationNumber,
    notificationPeriod,
    notificationPeriodLabel,
    notificationTooltipMessage,
    panelTitleId,
    t,
    tooltipPlace,
    tooltipWidth,
  } = props;

  return (
    <DetailsPanel panelTitleId={panelTitleId}>
      <IconHeading>
        <Heading>{t("services:NOTIFICATIONS")}</Heading>
        <Tooltip id="NotificationToolTip" title={notificationTooltipMessage} placement={tooltipPlace}>
          <span>
            <SquareIcon name={IconName.Info} />
          </span>
        </Tooltip>
      </IconHeading>

      <GridLayout>
        <FieldWrapper labelName={t("services:LABEL_NUMBER")} fieldName={notificationNumber} />
        <FieldWrapper
          labelName={notificationPeriodLabel ? notificationPeriodLabel : t("services:LABEL_PERIOD")}
          fieldName={notificationPeriod}
        />
      </GridLayout>
    </DetailsPanel>
  );
};

NotificationPanel.defaultProps = {
  cssSetting: {
    marginBottom: fieldSpacingSetting.marginBottomNone,
  },
  tooltipPlace: "bottom",
  tooltipWidth: 240,
};

export default withTranslation()(NotificationPanel);
