import React from "react";
// @ts-ignore;
import theme from "TARGET_BUILD/theme";
// @ts-ignore
import styled from "custom-styled-components";

import ic_Tool from "TARGET_BUILD/ui-assets/icons/ic_Asset_ActiveTracking.svg";
import ic_Heavy_Equipment from "TARGET_BUILD/ui-assets/icons/ic_Heavy_Equipment.svg";
import ic_Van from "TARGET_BUILD/ui-assets/icons/ic_Van.svg";

import { Status } from "../../../DetailsPanel";
import { LabelBadge, LabelBadgeWrapper } from "../../../labels/renderLabels";
// @ts-ignore
import { LocaleManager } from "am-web-ui-shared/helpers";
import { AssetSubType } from "TARGET_BUILD/common/utils/enums/assetEnum";
import { useTranslation } from "react-i18next";

const assetLastScanned = "assets:LAST_SCANNED";
const proximityRadius = "common:PROXIMITY_RADIUS";

const LabelsWrapper = styled.div`
  background-color: ${() => theme.colors.base.transparent};
  display: flex;
  flex-direction: row;
  align-items: center;
  & span,
  & div {
    margin-bottom: 0;
  }
`;

const ProximityRadiusWrapper = styled.div`
  background-color: ${() => theme.colors.base.white};
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &.from-big-map {
    top: 4px;
  }
`;

const ProximityRadiusLabel = styled.span`
  color: ${() => theme.colors.base.steel};
  margin-left: 5px;
  font-family: ${() => theme.fontNormal.fontFamily};
  font-size: ${() => theme.fontSize.extraSmall};
`;

const ProximityRadiusOval = styled.span`
  background-color: ${() => theme.colors.base.mapAccuracyBlue};
  border-radius: 50%;
  width: 12px;
  height: 12px;
`;

export const InfoWrapper = styled.div`
  div {
    margin-bottom: 4px;
  }
`;

export const TimeStamp = styled.span`
  margin-left: 4px;
`;

/*
  Theme obj is not available in the props when styled component is rendered
  inside google-map-react infoWindow. Temporarily removing the theme reference
  and hard coding the values.
*/
export const FriendlyName = styled.div`
  font-family: ${() => theme.fontBold.fontFamily};
  font-weight: ${() => theme.fontBold.fontWeight};
  width: 95%;
  align-items: unset;
  line-height: 16px;
  font-size: ${() => theme.fontSize.medium};
  color: ${() => theme.colors.base.black};
`;
const RedText = styled.div`
  font-family: ${() => theme.fontNormal.fontFamily};
  color: ${() => theme.colors.base.hiltiRed};
  width: 100%;
  align-items: unset;
  line-height: 16px;
  font-size: ${() => theme.fontSize.base};
`;

const CurrentLocation = styled.div`
  color: ${() => theme.colors.base.steel};
  font-family: ${() => theme.fontBold.fontFamily};
  font-weight: ${() => theme.fontBold.fontWeight};
  line-height: 19px;
  text-align: left;
  margin-top: 16px;
  font-size: ${() => theme.fontSize.medium};
  word-break: break-all;
`;
const LastScanWrapper = styled.div`
  line-height: 17px;
  color: ${() => theme.colors.base.steel};
  font-family: ${() => theme.fontNormal.fontFamily};
  font-size: ${() => theme.fontSize.small};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
`;

export const getIconByAssetSubType = (type: AssetSubType) => {
  if (type === AssetSubType.HeavyEquipment) {
    return ic_Heavy_Equipment;
  } else if (type === AssetSubType.Van) {
    return ic_Van;
  }
  return ic_Tool;
};

export const RenderProximityRadiusLabel = (props) => {
  const { mapPosition, fromTheBigMap = false } = props;
  const { t } = useTranslation();
  if (mapPosition.latitude && mapPosition.longitude && mapPosition.accuracy) {
    return (
      <ProximityRadiusWrapper className={fromTheBigMap ? "from-big-map" : ""}>
        <ProximityRadiusOval />
        <ProximityRadiusLabel>{t(proximityRadius)}</ProximityRadiusLabel>
      </ProximityRadiusWrapper>
    );
  } else {
    return null;
  }
};

const LabelsWrapperForInfo = (props) => {
  const { infoDetails } = props;
  const { statusCode, statusValue, scanCode, inventoryNumber } = infoDetails;
  return (
    <LabelsWrapper>
      {statusCode && statusValue ? (
        <Status id={`txtAssetStatus${statusValue}`} statusCode={statusCode} statusText={statusValue} />
      ) : null}
      {scanCode || inventoryNumber ? (
        <LabelBadgeWrapper>
          <LabelBadge id={`txtLabel${scanCode || inventoryNumber}`} />
          {scanCode || inventoryNumber}
        </LabelBadgeWrapper>
      ) : null}
    </LabelsWrapper>
  );
};

/**
 * @description get the last scan of asset.
 * @memberof getLastUpdate
 */
const getLastUpdate = (infoDetails, t) => {
  if (!infoDetails.lastUpdate) {
    return "-";
  }
  const typeCheck = LocaleManager.dateHelpers.getTimeDifference(
    infoDetails.lastUpdate,
    LocaleManager.dateHelpers.getDateInUTC(),
    t,
  );
  switch (typeCheck.type) {
    case "seconds":
      return `${typeCheck.value} ${t("common:SECS_AGO")}`;
    case "minutes":
      return `${typeCheck.value} ${t("common:MINS_AGO")}`;
    case "year":
      return `${typeCheck.value} ${t("common:YEAR_AGO")}`;
    case "hour":
      return `${typeCheck.value} ${t("common:HOURS_AGO")}`;
    case "day":
      return `${typeCheck.value} ${t("common:DAY_AGO")}`;
    default:
      return `${typeCheck.value} ${t("common:SECS_AGO")}`;
  }
};

/**
 * @description update the info details in the info window
 * @memberof AccuracyCircleMap
 */
export const RenderInfoWindow = (props) => {
  const { infoDetails } = props;
  const { t } = useTranslation();
  const { manufacturerModel, name, currentLocationName, locationType, lastSeen } = infoDetails;
  return (
    <InfoWrapper>
      {manufacturerModel ? <RedText>{manufacturerModel}</RedText> : null}
      {name ? <FriendlyName>{name}</FriendlyName> : null}
      <CurrentLocation>
        {currentLocationName}
        {locationType}
      </CurrentLocation>
      <LabelsWrapperForInfo infoDetails={infoDetails} />
      <LastScanWrapper>
        {t(assetLastScanned)} {":"}
        <TimeStamp>{lastSeen}</TimeStamp>
      </LastScanWrapper>
    </InfoWrapper>
  );
};
