import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { HelperLodash } from "../../../../helpers";
import { Checkbox } from "../../../../elements";
import SearchBox from "../../../../elements/SearchBox/searchBox";
import { includes } from "../../../../helpers/helperLodash";
import ControlType from "../../controlType";
import { separateCheckedAndUncheckedOptions } from "../../filterUtils";
import { getIntersectionOptions, isApplyButtonActive } from "../../filterUtils";
import ApplyButton from "../applyButton/applyButton";
import { ICheckboxGroupProps, ICheckboxGroupState } from "./iCheckboxGroup";
const FilterWrapper = styled.div`
  padding: 8px 0 26px;
`;
export const HeaderWrapper = styled.h4`
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  margin: 10px 0 0;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  cursor: default;
`;
const List = styled(MenuItem)`
  && {
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
  }
`;
export const ClearButton = styled.button`
  text-decoration: underline;
  background: none;
  border: none;
  font-size: ${(props) => props.theme.fontSize.base};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  &:hover {
    color: ${(props) => props.theme.colors.base.steel10};
  }
`;

/**
 * Header styles for the checkbox list
 */
export const HeaderText = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;
export const ToggleRecords = styled.span`
  text-decoration: underline;
  padding: 10px 0;
  display: inline-block;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.base};
  &:hover {
    color: ${(props) => props.theme.colors.base.steel10};
  }
`;
/**
 * SearchBar styles for the search bar
 */
const searchStyle = {
  margin: "16px",
  minWidth: "calc(100% - 32px)",
};
export const DisabledLabels = styled.span`
  color: ${(props) => props.theme.colors.base.black30};
  font-weight: 300;
  line-height: 19px;
  text-align: left;
`;
export const NoValuesLabel = styled.li`
  padding: 0 16px;
  display: flex;
  color: ${(props) => props.theme.colors.base.placeholderColor};
`;
const Footer = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
`;

interface IOption {
  label: string;
  value: string;
}

export const HiddenAssetInfo = (props) => {
  const { t } = props;
  return <DisabledLabels>({t("filters:HIDDEN_BY_DEFAULT")})</DisabledLabels>;
};
export const getLabel = (item, valueKey) => {
  if (Array.isArray(valueKey)) {
    const key = valueKey.find((value) => !!HelperLodash.get(item, value));
    return HelperLodash.get(item, key);
  }
  return HelperLodash.get(item, valueKey);
};
const isOptionLength = (showSelectAll, optionsList) => showSelectAll && optionsList && optionsList.length > 1;
const isDisplayCount = (optionsList, displayCount) => optionsList && optionsList.length > 0 && displayCount > 0;

const shouldRenderClearButton = (props) => {
  const { clearButtonText, clearSelection, input, selectedOptions, optionsList, searchString } = props;
  const intersectionOptions = searchString ? selectedOptions : getIntersectionOptions(selectedOptions, optionsList);
  return (
    intersectionOptions &&
    intersectionOptions.length > 0 && (
      <ClearButton
        onClick={() => clearSelection(input.onChange)}
      >{`${clearButtonText} ${intersectionOptions.length}`}</ClearButton>
    )
  );
};

const displayOptionListCheckbox = (props) => {
  const { options, selectedOptions, displayCount, valueId, valueKey, id, input, onCheckboxClicked } = props;
  return options.slice(0, displayCount).map((item, index) => {
    const itemValueId = HelperLodash.get(item, valueId);
    const opt = (selectedOptions && selectedOptions.filter((o) => HelperLodash.get(o, valueId) === itemValueId)) || [];
    const label = (
      <>
        {getLabel(item, valueKey)}
        {includes([ControlType.retiredFilterCode, ControlType.disposedFilterCode], itemValueId) && (
          <>
            <HiddenAssetInfo {...props} />
          </>
        )}
      </>
    );
    return (
      <List key={`${id}_wrapper_${index}`}>
        <Checkbox
          key={`${id}_opt_${index}`}
          isFormControl={false}
          id={`${id}_opt_${index}`}
          name={`${input.name}_opt_${index}`}
          label={label}
          onChange={(isSelected) => {
            return onCheckboxClicked && onCheckboxClicked(isSelected, item, input.onChange);
          }}
          checked={opt.length > 0}
          validation={[() => props.validation && props.validation(item)]}
          wrapperStyles={{ width: "100%" }}
          truncateLabel
        />
      </List>
    );
  });
};

const renderOptionListCheckboxes = (props) => {
  const { displayCount, optionsList, noValuesLabel, appliedOptions } = props;

  const updatedOptionsList: { appliedOptions: IOption[]; options: IOption[] } = separateCheckedAndUncheckedOptions(
    optionsList,
    appliedOptions,
  );

  const { options: calculatedOptions, appliedOptions: calculatedAppliedOptions } = updatedOptionsList;

  return isDisplayCount(optionsList, displayCount) ? (
    <>{displayOptionListCheckbox({ ...props, options: calculatedAppliedOptions.concat(calculatedOptions) })}</>
  ) : (
    <NoValuesLabel>{noValuesLabel}</NoValuesLabel>
  );
};

export const isChecked = (selectedOptions, optionsList) =>
  selectedOptions && selectedOptions.length === optionsList.length;

export const handleShowMore = (props, searchValue, clearFilterSelection, resetSearchString) => {
  const { handleShowMore: showMore, appliedOptions, filter, mode, input } = props;
  showMore(filter, appliedOptions, mode, searchValue, clearFilterSelection.bind(null, input.onChange, true));
  resetSearchString();
};

export const handleApplyOnClick = (onSubmit, filterFormValues, resetSearchString) => {
  resetSearchString();
  if (onSubmit) {
    onSubmit(filterFormValues);
  }
};

export const CheckBoxControl = (props) => {
  const {
    appliedOptions,
    onSearchInputChange,
    displayCount,
    id,
    handleSelectAll,
    header,
    hideHeader,
    name,
    t,
    input,
    selectedOptions,
    showSearch,
    showSelectAll,
    optionsCount,
    searchString,
    searchCount,
    optionsList,
    clearSelection,
    resetSearchString,
    onSubmit,
    filterFormValues,
    shouldHaveApplyButton,
  } = props;

  const optionListCount = `(${optionsList ? optionsList.length : ""})`;
  const getSelectAllLabel = () => `${t("common:SELECT_ALL_LABEL")} ${props.showSelectAllCount ? optionListCount : ""}`;
  const showMoreCount = searchString && searchCount !== undefined ? searchCount : optionsCount;
  const isFilterApplyButtonActive = () => isApplyButtonActive(appliedOptions, selectedOptions, optionsList);

  return (
    <FilterWrapper key={id} id={id}>
      {!hideHeader && (
        <HeaderWrapper>
          {header && <HeaderText key={`${id}_header`}>{t(header)}</HeaderText>}
          {shouldRenderClearButton(props)}
        </HeaderWrapper>
      )}
      {showSearch && (
        <SearchBox
          id={`searchBox${id}`}
          placeholder={`${t("filters:SEARCH")} ${header.toLowerCase()}`}
          enableClear={true}
          value={searchString}
          onChange={onSearchInputChange}
          style={searchStyle}
        />
      )}
      <ul>
        {isOptionLength(showSelectAll, optionsList) && (
          <List key={`${id}_wrapper_selectAll`}>
            <Checkbox
              key={`${id}_selectAll`}
              isFormControl={false}
              id={`${id}_selectAll`}
              name={`${name || input.name}__selectAll`}
              label={getSelectAllLabel()}
              onChange={(isSelected) => handleSelectAll(isSelected, input.onChange)}
              checked={isChecked(selectedOptions, optionsList)}
            />
          </List>
        )}
        {renderOptionListCheckboxes(props)}
      </ul>
      <Footer>
        {showMoreCount > displayCount ? (
          <ToggleRecords onClick={handleShowMore.bind(null, props, searchString, clearSelection, resetSearchString)}>
            {t("filters:SHOW_MORE_WITH_COUNT", { count: showMoreCount - displayCount })}
          </ToggleRecords>
        ) : (
          <span />
        )}
        {shouldHaveApplyButton ? (
          <ApplyButton
            isActive={isFilterApplyButtonActive()}
            onClick={handleApplyOnClick.bind(null, onSubmit, filterFormValues, resetSearchString)}
          />
        ) : null}
      </Footer>
    </FilterWrapper>
  );
};

/**
 * @class MenuWrapper component for checkbox component // To DO : as discussed, the component array should be used as a prop.
 */
class CheckboxGroup extends React.PureComponent<ICheckboxGroupProps, ICheckboxGroupState> {
  state: ICheckboxGroupState = {
    displayCount: this.props.defaultDisplayCount,
    searchString: "",
    selectedOptions: getIntersectionOptions(this.props.selectedOptions, this.props.options) || [],
  };

  resetSearchString = () => {
    this.setState({ searchString: "" });
  };

  /**
   * handle on change value in search input box
   *
   * @param e current event
   */
  onChange = (searchString = "") => {
    const { filter, getFilterAttributes } = this.props;
    getFilterAttributes(filter.filterName, searchString);
    this.setState({
      searchString,
    });
  };

  componentDidUpdate(prevProps) {
    const { searchString } = this.state;
    if (this.props.selectedOptions !== prevProps.selectedOptions || this.props.options !== prevProps.options) {
      this.setState({
        selectedOptions: searchString
          ? this.props.selectedOptions
          : getIntersectionOptions(this.props.selectedOptions, this.props.options) || [],
      });
    }
    if (this.props.defaultDisplayCount !== prevProps.defaultDisplayCount) {
      this.setState({
        displayCount: this.props.defaultDisplayCount,
      });
    }
  }
  /**
   * @description checkboxClicked on change of checkbox this needs to execute
   *
   * @param isSelected: define if option is selected or not
   */
  checkboxClicked = (isSelected, currentOption, onChange) => {
    const valueId = this.props.valueId;
    const updateSelectedOptions = (options) => {
      if (isSelected) {
        options.push(currentOption);
        return {
          selectedOptions: options,
        };
      } else {
        return {
          selectedOptions: options.filter(
            (o) => HelperLodash.get(o, valueId) !== HelperLodash.get(currentOption, valueId),
          ),
        };
      }
    };

    this.setState(
      (prevState) => {
        return {
          ...updateSelectedOptions(prevState.selectedOptions),
        };
      },
      () => {
        const options = this.state.selectedOptions;
        onChange(
          options.reduce((accumlator, option) => accumlator.concat(HelperLodash.get(option, valueId)), []).join(";"),
        );
        return this.props.onSelectionChange && this.props.onSelectionChange(options, isSelected, currentOption);
      },
    );
  };

  /**
   * method to increase number of records displayed
   */
  showMoreRecords = () => {
    this.setState(
      {
        displayCount: this.props.options.length,
      },
      () => {
        return this.props.updateHeight && this.props.updateHeight();
      },
    );
  };

  /**
   * method to reset number of records displayed
   */
  showLessRecords = () => {
    const defaultDisplayCount = this.props.defaultDisplayCount;
    this.setState(
      {
        displayCount: defaultDisplayCount,
      },
      () => {
        return this.props.updateHeight && this.props.updateHeight();
      },
    );
  };

  /**
   * clear seelcted records
   */
  clearSelection = (onChange, preventClearAppliedFilters = false) => {
    const valueId = this.props.valueId;
    const { searchString } = this.state;
    const appliedOptions = searchString
      ? this.props.appliedOptions
      : getIntersectionOptions(this.props.appliedOptions, this.props.options) || [];
    const valuesAfterReset = preventClearAppliedFilters ? appliedOptions : [];
    this.setState(
      {
        selectedOptions: valuesAfterReset,
      },
      () => {
        onChange(
          valuesAfterReset
            .reduce((accumlator, option) => accumlator.concat(HelperLodash.get(option, valueId)), [])
            .join(";"),
        );
        const options = this.state.selectedOptions;
        return this.props.onSelectionChange && this.props.onSelectionChange(options);
      },
    );
  };

  componentDidMount() {
    if (this.props.updateHeight) {
      this.props.updateHeight();
    }
  }

  handleSelectAll = (isSelected, onChange) => {
    const valueId = this.props.valueId;
    let selectedOptions;
    if (!isSelected) {
      selectedOptions = [];
    } else {
      selectedOptions = this.props.options;
    }

    this.setState(
      {
        selectedOptions,
      },
      () => {
        const options = this.state.selectedOptions;
        onChange(
          options.reduce((accumlator, option) => accumlator.concat(HelperLodash.get(option, valueId)), []).join(";"),
        );
        return this.props.onSelectAll && this.props.onSelectAll(options, isSelected);
      },
    );
  };

  render() {
    return !this.props.isFormControl ? (
      <CheckBoxControl
        onCheckboxClicked={this.checkboxClicked}
        displayCount={this.state.displayCount}
        clearSelection={this.clearSelection}
        onSearchInputChange={this.onChange}
        optionsList={this.props.options}
        searchString={this.state.searchString}
        resetSearchString={this.resetSearchString}
        {...this.props}
      />
    ) : (
      <Field
        name={this.props.name}
        component={CheckBoxControl}
        onCheckboxClicked={this.checkboxClicked}
        selectedOptions={this.state.selectedOptions}
        displayCount={this.state.displayCount}
        showMoreRecords={this.showMoreRecords}
        showLessRecords={this.showLessRecords}
        displayChangeCount={this.props.defaultDisplayCount}
        clearSelection={this.clearSelection}
        onSearchInputChange={this.onChange}
        optionsList={this.props.options}
        searchString={this.state.searchString}
        handleSelectAll={this.handleSelectAll}
        validation={this.props.validation}
        resetSearchString={this.resetSearchString}
        {...this.props}
      />
    );
  }
}

export default CheckboxGroup;
