import { IAlertDetails } from "TARGET_BUILD/common/models/alert/alertDetails";
import { IAssetDetailsData } from "TARGET_BUILD/common/models/asset/assetModel";
import IBattery from "TARGET_BUILD/common/models/iBattery";
import { AlertFields } from "TARGET_BUILD/common/modules/alert/components/alertTypes";
import { ScanCodeType } from "TARGET_BUILD/common/utils/enums/assetEnum";
import { isHeavyEquipment, isVan } from "./helpers";
import { LostAndFoundAlertType, LostAndFoundStatus } from "TARGET_BUILD/common/utils/enums/alertGroupEnum";

export type LocationInfo = {
  assetId: number;
  isInvalidLocation: boolean;
  isVanOrHeOrOstOrThingType: boolean;
  isPbeAlert: boolean;
  isFoundAlert: boolean;
  isBattery: boolean;
  isStorageLocation: boolean;
  isGeoTaggedAsset: boolean;
};

export const getLocationInfo = (
  asset: IAssetDetailsData | IAlertDetails | IBattery,
  forceFetch: boolean,
): LocationInfo => {
  const { assetId } = asset;
  const isHeavyAsset = assetId && isHeavyEquipment(asset);
  const isVanAsset = assetId && isVan(asset);
  const isTrackedBySmartTag =
    forceFetch ||
    (assetId && "scanCodeTypeDetails" in asset && asset.scanCodeTypeDetails?.code === ScanCodeType.ONTRACK_SMART_TAG);
  const hasThingType = assetId && "thingType" in asset && asset.thingType !== "OTHER";
  const isPbeAlert = "alertType" in asset && asset.alertType?.code === AlertFields.ALERT_TYPE_PROACTIVE_BATTERY;
  const isFoundAlert =
    "alertType" in asset &&
    asset.alertType?.code === LostAndFoundAlertType &&
    asset.alertStatus?.code === LostAndFoundStatus.FOUND_INTERNALLY;
  const isBattery = "sohReported" in asset;
  const isStorageLocation = "storageLocation" in asset;
  const isGeoTaggedAsset = "geoTagDetails" in asset && asset.geoTagDetails?.isTagAttached === true;

  const isInvalidLocation =
    !isHeavyAsset &&
    !isVanAsset &&
    !isTrackedBySmartTag &&
    !hasThingType &&
    !isPbeAlert &&
    !isBattery &&
    !isFoundAlert &&
    !isGeoTaggedAsset;

  const isVanOrHeOrOstOrThingType = isHeavyAsset || isVanAsset || isTrackedBySmartTag || hasThingType;

  return {
    assetId,
    isInvalidLocation,
    isVanOrHeOrOstOrThingType,
    isPbeAlert,
    isBattery,
    isStorageLocation,
    isFoundAlert,
    isGeoTaggedAsset,
  };
};
