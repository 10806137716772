import React from "react";
import { DynamicModuleLoader } from "redux-dynamic-modules";
import activeTrackingModule from "../../redux/activeTracking/activeTrackingModule";
import LocationDetails from "./locationDetails";

const LocationDetailsWrapper = (props) => {
  return (
    <DynamicModuleLoader modules={[activeTrackingModule]}>
      <LocationDetails {...props} />
    </DynamicModuleLoader>
  );
};

export default LocationDetailsWrapper;
