import React from "react";
import styled from "styled-components";
import IDetailsPanelProps from "./iDetailsPanelProps";

const PanelWrapper = styled("div")`
  border: 1px solid ${(props) => props.theme.colors.base.borderColor};
  background: white;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  box-shadow: 0 2px 0 0 ${(props) => props.theme.colors.base.borderColor};
`;

const TitleWrapper = styled("div")`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
  margin-bottom: 24px;
`;

/**
 * Class to act as Wrapper for all the Details Panels
 *
 * @class DetailsPanel
 * @extends {React.PureComponent<IDetailsPanelProps>}
 */
class DetailsPanel extends React.PureComponent<IDetailsPanelProps> {
  render() {
    const { children, panelTitleId, title, titleComponent, className } = this.props;
    return (
      <PanelWrapper className={className}>
        {titleComponent ? titleComponent : title && <TitleWrapper id={panelTitleId}>{title}</TitleWrapper>}
        {children}
      </PanelWrapper>
    );
  }
}

export default DetailsPanel;
