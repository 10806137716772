import React, { FC, HTMLAttributes } from 'react';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import StyledButton from './Button.styled';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    info: false;
    warning: false;
    success: false;
    error: false;
    inherit: false;
  }
}

type Props = MuiButtonProps;

type NativeAttrs = Omit<HTMLAttributes<HTMLButtonElement | HTMLLinkElement>, keyof Props>;

export type ButtonProps = Props & NativeAttrs;

const defaultProps: Partial<ButtonProps> = {
  color: 'primary',
  variant: 'outlined',
  disableRipple: true,
};

const Button: FC<ButtonProps> = ({ children, ...rest }) => (
  <StyledButton {...rest}>{children}</StyledButton>
);

Button.defaultProps = defaultProps;

export default Button;
