import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import { MasterActions } from "custom-actions";
import store, { RootState } from "../../store/store";

const dispatchAction = (action) => {
  store.dispatch(action);
};

/**
 * @description Function for getAssetCodeList action
 *
 * @action getAssetCodeList
 */
export const getAssetCodeList = () => {
  const currentState = store.getState() as RootState;
  if (
    HelperLodash.isEmpty(currentState.MasterData.assetStatusResponse) &&
    HelperLodash.isEmpty(currentState.MasterData.ownerShipTypeResponse)
  ) {
    dispatchAction({
      type: MasterActions.GET_ASSET_CODE_LIST,
    });
  }
};

/**
 * @description Function for getScanCodeTypeList action
 *
 * @action getScanCodeTypeList
 */
export const getScanCodeTypeList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.scanCodeTypeList)) {
    dispatchAction({
      type: MasterActions.GET_SCAN_CODE_TYPE_LIST,
    });
  }
};

/**
 * @description Function for getAssetUsageConditionList action
 *
 * @action getAssetUsageConditionList
 */
export const getAssetUsageConditionList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.assetUsageConditionStateResponse)) {
    dispatchAction({
      type: MasterActions.GET_ASSET_USAGE_CONDITION_LIST,
    });
  }
};

/**
 * @description Function for getCurrenciesList action
 *
 * @action getCurrenciesList
 */
export const getCurrenciesList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.currenciesList)) {
    dispatchAction({
      type: MasterActions.GET_CURRENCIES_LIST,
    });
  }
};

export const getRolesList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.rolesList)) {
    dispatchAction({
      type: MasterActions.OM_GET_ROLES_LIST,
    });
  }
};

export const getCountryList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.countryList)) {
    dispatchAction({
      type: MasterActions.OM_GET_COUNTRIES,
    });
  }
};

/**
 * @description Action method to get Location States Lists
 * @export
 */
export const getLocationsStates = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.locationStatesList)) {
    dispatchAction({
      type: MasterActions.GET_LOCATION_STATES,
    });
  }
};

/**
 * @description Action method to get Location States Lists
 * @export
 */
export const getLanguageList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.languageList)) {
    dispatchAction({
      type: MasterActions.GET_LANGUAGE_LIST,
    });
  }
};

/**
 * @description Action method to get allocation status
 * @export
 */
export const getAllocationStatusList = () => {
  const currentState = store.getState() as RootState;
  if (
    HelperLodash.isEmpty(currentState.MasterData.allocationStatusList) ||
    HelperLodash.isEmpty(currentState.MasterData.allocationStatusListWithoutINTRAN)
  ) {
    dispatchAction({
      type: MasterActions.GET_ALLOCATION_STATUS_LIST,
    });
  }
};

/**
 * @description Action method to get allocation status
 * @export
 */
export const getReportTemplateCategories = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.reportTemplateCategoriesList)) {
    dispatchAction({
      type: MasterActions.GET_REPORT_TEMPLATES_CATEGORIES,
    });
  }
};

/**
 * @description Action method to get allocation status
 * @export
 */
export const getAllocationStockLevel = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.allocationStockLevel)) {
    dispatchAction({
      type: MasterActions.GET_ALLOCATION_STOCK_LEVEL,
    });
  }
};

/**
 * @description Action method to get service status
 * @export
 */
export const getServiceStatusList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.serviceStatusList)) {
    dispatchAction({
      type: MasterActions.AM_GET_SERVICE_STATUS_LIST,
    });
  }
};

/**
 * @description Action method to get service results
 * @export
 */
export const getServiceResultsList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.serviceResultsList)) {
    dispatchAction({
      type: MasterActions.AM_GET_SERVICE_RESULTS_LIST,
    });
  }
};

/**
 * @description Action method to get certificate status
 * @export
 */
export const getCertificateStatusList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.certificateStatusList)) {
    dispatchAction({
      type: MasterActions.AM_GET_CERTIFICATE_STATUS_LIST,
    });
  }
};

/**
 * @description Action method to get hilti asset status
 * @export
 */
export const getHiltiAssetStatusList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.hiltiAssetStatusList)) {
    dispatchAction({
      type: MasterActions.HI_GET_HILTI_ASSET_STATUS_LIST,
    });
  }
};

/**
 * @description Action method to get qty item activity type list
 * @export
 */
export const getQtyItemActivityTypeList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.qtyItemActivityTypeList)) {
    dispatchAction({
      type: MasterActions.AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST,
    });
  }
};

export const resetMasterData = () => ({
  type: MasterActions.RESET_MASTER_DATA,
});

export const getTimeBasedEnabledTenantList = () => {
  const currentState = store.getState() as RootState;
  if (HelperLodash.isEmpty(currentState.MasterData.timeBasedEnabledTenantList)) {
    dispatchAction({ type: MasterActions.GET_TIME_BASED_ENABLED_TENANT_LIST });
  }
};

export const getTimeBasedEnabledTenantListSuccess = (response) => ({
  type: MasterActions.PUT_TIME_BASED_ENABLED_TENANT_LIST,
  payload: response,
});

/**
 * @description Function for getCostCodeList action
 *
 * @action getCostCodeList
 */
export const getCostCodeList = () => {
  dispatchAction({ type: MasterActions.GET_COST_CODE_LIST });
};

export const getCostCodeListSuccess = (response) => ({
  type: MasterActions.ACR_COST_CODE_SUCCESS,
  payload: response,
});

export const showActiveTrackingBanner = (show: boolean) => ({
  payload: show,
  type: MasterActions.AT_UI_SHOW_ONE_MAP_BANNER,
});
