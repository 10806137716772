import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { LicenseBundleTypes } from "./enums/licenseBundleEnum";
import { LicenseTypes } from "./enums/licenseTypesEnum";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";

export const isFleetTenant = (licenseType, licenseBundle) => {
  if (SingletonFeatureManager.isFeatureEnabled(FEATURES.LICENSE_BUNDLE)) {
    return licenseBundle === LicenseBundleTypes.ONTRACK_READY;
  }

  return licenseType === LicenseTypes.FM;
};
