import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M14.6213203,21 C14.6213203,22.056 13.6213203,22.9881577 12.0606602,23.01 C10.5,23.0318423 9.5,22.076 9.5,21.02 L9.5,21.02 Z M13.5,2 L13.5,4 C16.64,4.6528 19,7.364 19,10.58 L19,10.58 L19,16 L21,16 L21,19 L3,19 L3,16 L5,16 L5,10.58 C5,7.364 7.36,4.6528 10.5,4 L10.5,4 L10.5,2 L13.5,2 Z" />
    </g>
  </svg>
);

export default SVG;
