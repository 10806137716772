import { useEffect, useState } from "react";
import { GeocoderResult, getGeoCode } from "../../../../../common/components/maps/googleAPI";
import { IGeoLocation, ITrackingPayload } from "TARGET_BUILD/common/models/map/bleMap/trackingPayloalModel";
import { getGoogleLanguage } from "TARGET_BUILD/common/components/maps/languageMap";
import { isLocationDetailsAddressEnabled } from "TARGET_BUILD/common/utils/activeTrackingUtils";

export default function useReverseGeocode(locationDetails: ITrackingPayload | IGeoLocation, languageCode: string) {
  const [address, setAddress] = useState<string>("");

  useEffect(() => {
    if (locationDetails?.position) {
      const lat = locationDetails.position.latitude;
      const lng = locationDetails.position.longitude;
      const latlng = `${lat},${lng}`;

      if ((!!lat || !!lng) && isLocationDetailsAddressEnabled()) {
        const getAddressFromResponse = (response: GeocoderResult) => {
          const { results } = response;
          if (results && Array.isArray(results) && results.length) {
            return results?.[0].formatted_address ?? "";
          }
          return "";
        };

        getGeoCode({ latlng, language: getGoogleLanguage(languageCode) })
          .then((response) => {
            setAddress(getAddressFromResponse(response));
          })
          .catch(() => setAddress(""));
      } else {
        setAddress("");
      }
    }
  }, [locationDetails, languageCode]);

  return address;
}
