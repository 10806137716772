const transferHistoriesConfig = [
  {
    columns: [
      {
        appendHash: true,
        bindProperty: "transferId",
        cellSize: "3",
        label: "common:TRANSFER_ID",
        type: "text",
      },
      {
        bindProperty: "fromLocationDetails",
        cellSize: "3",
        component: "fromLocation",
        key: "locationName",
        label: "common:LABEL_FROM_STORAGE_ASSET_LOCATION",
        type: "customLabel",
      },
      {
        bindProperty: "toLocationDetails",
        cellSize: "3",
        component: "toLocation",
        key: "locationName",
        label: "common:LABEL_TO_STORAGE_ASSET_LOCATION",
        type: "customLabel",
      },
      {
        bindProperty: "applicationType",
        cellSize: "3",
        label: "common:APPLICATION",
        type: "text",
      },
    ],
  },
  {
    columns: [
      {
        bindProperty: "transferDate",
        cellSize: "3",
        label: "common:TRANSFER_DATE",
        type: "date",
      },
      {
        bindProperty: "fromWorkerDetails",
        cellSize: "3",
        key: "workerName",
        label: "common:LABEL_FROM_WORKER",
        type: "object",
      },
      {
        bindProperty: "toWorkerDetails",
        cellSize: "3",
        key: "workerName",
        label: "common:LABEL_TO_WORKER",
        type: "object",
      },
      {
        bindProperty: "transferByDetails",
        cellSize: "2",
        key: "userName",
        label: "common:LABEL_TRANSFERRED_BY",
        type: "object",
      },
    ],
  },
  {
    columns: [
      {
        bindProperty: "entityActionActorType",
        key: "value",
        cellSize: "3",
        component: "confirmOrRecalledBy",
        type: "customLabel",
      },
      {
        bindProperty: "entityActionDateTime",
        cellSize: "3",
        component: "confirmedDate",
        type: "customLabel",
      },
    ],
  },
  {
    columns: [
      {
        bindProperty: "taskCode",
        cellSize: "3",
        hideIfEmpty: true,
        label: "common:TASK_CODE",
        type: "text",
      },
      {
        bindProperty: "notes",
        cellSize: "9",
        hideIfEmpty: true,
        label: "common:LABEL_NOTES",
        showMoreORLess: true,
        type: "text",
      },
    ],
  },
  {
    columns: [
      {
        bindProperty: "showTransferButton",
        cellSize: "12",
        component: "cancelTransferView",
        hideIfEmpty: true,
        label: "",
        type: "custom",
      },
    ],
  },
  {
    columns: [
      {
        bindProperty: "showTransferLabel",
        cellSize: "12",
        component: "cancelTransferLabel",
        hideIfEmpty: true,
        label: "",
        type: "custom",
      },
    ],
  },
  {
    columns: [
      {
        appendHash: true,
        bindProperty: "canceledTransferDetails",
        cellSize: "3",
        hideIfEmpty: true,
        key: "transferId",
        label: "common:CANCEL_TRANSFER_ID",
        type: "object",
      },
      {
        bindProperty: "canceledTransferDetails",
        cellSize: "3",
        hideIfEmpty: true,
        key: "transferDate",
        label: "common:CANCEL_TRANSFER_DATE",
        type: "date",
      },
      {
        bindProperty: "canceledTransferDetails",
        cellSize: "6",
        hideIfEmpty: true,
        key: "notes",
        label: "common:CANCEL_LABEL_NOTES",
        showMoreORLess: true,
        type: "object",
      },
    ],
    hideIfEmpty: true,
  },
];

export default transferHistoriesConfig;
