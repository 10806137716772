import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import { dependencyResolver } from "../grid/gridUtils";
import { Count, CountWrapper, ExpandableRow } from "./gridStyle";

const ExpandMore = styled.span`
  margin-left: 71px;
  border-left: 1px solid ${(props) => props.theme.colors.base.borderColor};
  padding: 6px 0px 6px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 36px;
  svg {
    transform: scale(0.8);
  }
  &.show-more:hover {
    background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
  }
`;

export const getLinkText = (dependencies, rowSettings, data) => {
  if (rowSettings.customInfo.rowLinkText) {
    return dependencyResolver(dependencies, rowSettings.customInfo.rowLinkText)(data);
  } else {
    return dependencies.t(rowSettings.customInfo.textLink);
  }
};

export const getRowLinkCount = (dependencies, rowSettings, data) => {
  if (rowSettings.customInfo.rowLinkCount) {
    return dependencyResolver(dependencies, rowSettings.customInfo.rowLinkCount)(data);
  }
  return null;
};

export const getLinkCount = (dependencies, rowSettings, data) => {
  return getRowLinkCount(dependencies, rowSettings, data)
    ? getRowLinkCount(dependencies, rowSettings, data)
    : data[rowSettings.customInfo.countKey];
};

/**
 * @description - Generic Row template accepts View model
 *
 * @class RowTemplate
 * @interface IRowTemplateProps<T>
 */

export class RowTemplateLink extends React.Component<any> {
  render() {
    const { data, rowIndex, renderRowTemplate, templateProps } = this.props;
    const { dependencies, handleRowLinkClick, rowSettings } = templateProps;
    const chevronIconPosition = rowSettings.customInfo.chevronIconPosition;
    const linkCount = getLinkCount(dependencies, rowSettings, data);
    return (
      <ExpandableRow className={linkCount ? "row-has-childreen additional-link" : "row-has-childreen"}>
        {renderRowTemplate({ data, indeterminate: false, templateProps, rowIndex })}
        {linkCount ? (
          <ExpandMore className="show-more" onClick={() => handleRowLinkClick(data)}>
            {chevronIconPosition === "first" ? <SquareIcon name={IconName.Chevron} /> : null}
            <CountWrapper>
              <Count>{linkCount}</Count>
              <span>{getLinkText(dependencies, rowSettings, data)}</span>
            </CountWrapper>
            {chevronIconPosition === "last" ? <SquareIcon name={IconName.Chevron} /> : null}
          </ExpandMore>
        ) : null}
      </ExpandableRow>
    );
  }
}

export default withRouter(RowTemplateLink);
