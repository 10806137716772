/**
 * @description Action constants to be used for asset group module
 *
 * format: {feature}_{action}
 */
const AssetGroupActionConstants = {
  AM_ASSEST_GROUP_LIST: "AM_ASSEST_GROUP_LIST",
  AM_ASSEST_GROUP_LIST_FOR_FILTER: "AM_ASSEST_GROUP_LIST_FOR_FILTER",
  PUT_ASSET_GROUP_LIST_FOR_FILTER: "PUT_ASSET_GROUP_LIST_FOR_FILTER",
  PUT_ASSET_GROUP_LIST_FOR_FILTER_FAIL: "PUT_ASSET_GROUP_LIST_FOR_FILTER_FAIL",
  AM_ASSET_GROUP_ADD_EDIT: "AM_ASSET_GROUP_ADD_EDIT",
  AM_ASSET_GROUP_ADD_EDIT_RESET: "AM_ASSET_GROUP_ADD_EDIT_RESET",
  AM_ASSET_GROUP_ADD_EDIT_RESPONSE: "AM_ASSET_GROUP_ADD_EDIT_RESPONSE",
  AM_ASSET_GROUP_CLEAR_SELECTED_ARRAY: "AM_ASSET_GROUP_CLEAR_SELECTED_ARRAY",
  AM_ASSET_GROUP_DELETE: "AM_ASSET_GROUP_DELETE",
  AM_ASSET_GROUP_DELETE_RESPONSE_NULLIFY: "AM_ASSET_GROUP_DELETE_RESPONSE_NULLIFY",
  AM_ASSET_GROUP_DELETE_RESPONSE_RESET: "AM_ASSET_GROUP_DELETE_RESPONSE_RESET",
  AM_ASSET_GROUP_GET_SELECTED_ARRAY: "AM_ASSET_GROUP_GET_SELECTED_ARRAY",
  AM_ASSET_GROUP_PARENT_DELETED: "AM_ASSET_GROUP_PARENT_DELETED",
  AM_ASSET_GROUP_TOAST_MSG: "AM_ASSET_GROUP_TOAST_MSG",
  AM_ASSET_GROUP_UPDATE: "AM_ASSET_GROUP_UPDATE",
  AM_ASSET_GROUP_UPDATE_ARRAY: "AM_ASSET_GROUP_UPDATE_ARRAY",
  PUT_ASSET_GROUP: "PUT_ASSET_GROUP",
  PUT_ASSET_GROUP_FAIL: "PUT_ASSET_GROUP_FAIL",
  AM_GET_ASSET_GROUP_DETAILS: "AM_GET_ASSET_GROUP_DETAILS",
  AM_SET_ASSET_GROUP_DETAILS: "AM_SET_ASSET_GROUP_DETAILS",
};

export default AssetGroupActionConstants;
