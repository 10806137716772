import React from "react";
import { connect } from "react-redux";
import { getAssetTemplateDetailsById } from "../../../../../modules/administration/template/actions/assetTemplatesActionRoot";
import AssetTemplateDetailView from "./assetTemplateDetailView";
import { IAssetTemplateContainerProps } from "./iAssetTemplateDetailProps";
import { getTemplateData } from "../../../reduxModule/assetSelectors";

class AssetTemplateDialogContainer extends React.Component<IAssetTemplateContainerProps> {
  componentDidMount() {
    const { assetTemplateId } = this.props;
    this.props.getAssetTemplateDetailsById(assetTemplateId);
  }
  render() {
    return <AssetTemplateDetailView templateId={this.props.templateData} />;
  }
}

export const mapStateToProps = ({ assetTemplates }) => ({
  templateData: getTemplateData(assetTemplates),
});
export const mapDispatchToProps = (dispatch) => ({
  getAssetTemplateDetailsById: (id: number) => dispatch(getAssetTemplateDetailsById(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssetTemplateDialogContainer);
