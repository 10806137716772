import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";
import { cloneDeep } from "../../../../helpers/helperLodash";
import GroupsBreadcrumb from "../../../groups/groupsBreadcrumb";
import HierarchyControlWrapper from "./hierarchyControlWrapper";
import { IHierarchyControlProps } from "./iDropdownMenu";
import StyleAttrConstants from "../../../../constant/styleAttrConstants";
import { HelperLodash } from "../../../../helpers";
import { Scroll, ScrollContext } from "../../../../providers/scrollProvider";

const bindProperties = {
  id: "id",
  name: "name",
  parentId: "parentEntityId",
};
const PARENT_FORM_WIDTH = StyleAttrConstants.WIDTH.W992 - StyleAttrConstants.WIDTH.W2; // 2px is reduced to avoid horizontal scroll due to custom scrollbar scroll width
const groupHeaderHeight = (props) => `calc(100vh - ${props.top}px - ${StyleAttrConstants.HEIGHT.H50}px)`;

export const getListColumnWidth = (isViewPortWidth) => {
  return isViewPortWidth ? `calc(100%/4)` : `${PARENT_FORM_WIDTH / StyleAttrConstants.WIDTH.W3}px`;
};

export const FixedContainer = styled.div<any>`
  background: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.innerHeadingText.color};
  line-height: 19px;
  overflow: auto;
  height: ${(props) => groupHeaderHeight(props)};
  max-height: ${(props: any) => (props.maxHeight ? `${props.maxHeight}px` : "none")};
  width: 100%;
  display: flex;
  flex-direction: column;
  .search-container {
    padding: 16px;
  }
`;

export const ClearContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
  padding: 8px;
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  button {
    background: none;
    border: 0;
    text-decoration: underline;
    font-size: ${(props) => props.theme.fontSize.base};
    color: ${(props) => props.theme.colors.base.steel};
  }
`;

const ListContainerStyle = styled.div`
  font-size: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  flex: 1;
`;
const scrollheight = (props: IHierarchyControlProps) => (props.suggestionSearch ? "calc(100% - 40px)" : "100%");

export const ListContainer = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.innerHeadingText.color};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
  width: ${(props: any) => getListColumnWidth(props.isViewPortWidth)};
  display: inline-block;
  border-right: 1px solid ${(props) => props.theme.colors.base.borderColor};
  vertical-align: top;
  height: ${scrollheight};
  font-size: ${(props) => props.theme.fontSize.medium};
`;

const OccupiedContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

/**
 * @constant - WrapperDiv
 * @description - wrapper div for group summary
 */
export const WrapperDiv = styled(Grid).attrs({
  id: "groupSummaryWrapper",
})`
  background-color: ${(props) => props.theme.colors.base.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  height: 40px;
  justify-content: space-between;
  padding-right: 16px;
  display: flex;
`;

const BreadcrumbContainer = styled.div`
  align-self: center;
  width: 100%;
`;

/**
 * @description EntityGroupBreadcrumb class
 *
 * @class EntityGroupBreadcrumb
 * @extends {React.PureComponent<IEntityGroupBreadcrumbProps>}
 */
class HierarchyControl extends React.PureComponent<IHierarchyControlProps> {
  entityGroupScrollRef = null;
  state = {
    hierarchyGroupScrollRef: null,
    offSetTop: 0,
  };

  static contextType?: React.Context<Scroll> = ScrollContext;

  /**
   * @description - Componet did mount - After DOM is rendered move the scroll position
   * to the selected item.
   *
   * @memberof EntityGroupWrapper
   */
  componentDidMount() {
    const { selectedEntityGroupId } = this.props;
    if (selectedEntityGroupId) {
      this.moveScrollPosition();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedItems } = this.props;
    if (selectedItems && selectedItems.length && selectedItems !== prevProps.selectedItems) {
      this.moveScrollPosition();
    }
    if (prevProps.filterContainerRef) {
      this.setState({ offSetTop: prevProps.filterContainerRef.offsetTop + prevProps.filterContainerRef.offsetHeight });
    }
  }

  hasChild = (selectedItem) => {
    if (selectedItem && selectedItem.locationDetails && selectedItem.locationDetails.hasChild) {
      return 1;
    }
    return 0;
  };

  /**
   * code to scroll the view port as the new options are loaded in hierarchy
   */
  moveScrollPosition = () => {
    const scrollDiv = this.entityGroupScrollRef;
    if (scrollDiv && scrollDiv.scrollBy) {
      const scrollOptions = {
        behavior: "smooth",
        left: scrollDiv.scrollWidth,
      };
      scrollDiv.scrollBy(scrollOptions);
    }
  };

  /**
   * handle unselection on click on blank space
   */
  handleBlankSpace = (level) => {
    const { selectedItems } = this.props;
    if (selectedItems.length) {
      if (level) {
        const selectedId = selectedItems[level - 1].id;
        this.props.getEntityGroups(selectedId, true);
      } else {
        this.clearSelection();
      }
    }
  };
  searchedId = (info) => {
    this.props.getEntityGroups(info.id, true);
    this.props.setSelectedItems([{ id: info.id, name: info.path }]);
  };
  clearSelection = () => {
    this.props.setSelectedItems([]);
    this.props.getEntityGroups(null);
  };

  setScrollRef = (ref, index) => {
    const prevRef = this.state.hierarchyGroupScrollRef ? this.state.hierarchyGroupScrollRef : {};
    if (!HelperLodash.get(prevRef, index)) {
      const locationScrollRef = {
        ...prevRef,
        [index]: ref,
      };
      this.setState({ hierarchyGroupScrollRef: locationScrollRef });
    }
  };

  render() {
    const {
      defaultId,
      selectedEntityGroupId,
      isAdmin,
      selectedItems,
      handleRowClick,
      isInitalLoad,
      suggestionSearch,
      maxHeight,
    } = this.props;
    const entityGroupsList = cloneDeep(this.props.entityGroupsList);
    const { isScrollBehaviourEnabled } = this.context;
    return (
      <FixedContainer
        top={isScrollBehaviourEnabled ? StyleAttrConstants.OFFSET.O200 : this.state.offSetTop}
        maxHeight={maxHeight}
      >
        {suggestionSearch && suggestionSearch.search && (
          <suggestionSearch.component
            filterContainerRef={this.props.filterContainerRef}
            topCalculation={120}
            hierarchySearch={suggestionSearch.searchApi}
            selectedSearchedId={this.searchedId}
            inputFocus={this.props.inputFocus}
          />
        )}
        <ListContainerStyle ref={(element) => (this.entityGroupScrollRef = element)}>
          {suggestionSearch && suggestionSearch.search && (
            <WrapperDiv
              style={{
                width: `${
                  StyleAttrConstants.WIDTH.W332 *
                  (selectedItems.length > 0
                    ? selectedItems.length + this.hasChild(selectedItems[selectedItems.length - 1])
                    : 1)
                }px`,
              }}
            >
              <BreadcrumbContainer>
                <GroupsBreadcrumb
                  selectedItems={selectedItems}
                  handleBlankSpace={this.handleBlankSpace}
                  handleRowClick={handleRowClick}
                />
              </BreadcrumbContainer>
            </WrapperDiv>
          )}
          {entityGroupsList &&
            entityGroupsList.map((filteredList, index) => {
              const list = this.props.isPaging ? filteredList.listRes : filteredList;
              return (
                <>
                  {list && list.length ? (
                    <ListContainer isViewPortWidth={isAdmin} suggestionSearch={suggestionSearch}>
                      <OccupiedContainer
                        id="blankContainer"
                        onClick={() => this.handleBlankSpace(index)}
                        ref={(element) => this.setScrollRef(element, index)}
                      >
                        <HierarchyControlWrapper
                          index={index}
                          hierarchyGroupScrollRef={this.state.hierarchyGroupScrollRef}
                          paginatedData={this.props.getPaginatedData}
                          handleRowClick={handleRowClick}
                          selectedItems={selectedItems}
                          level={index}
                          groupsList={filteredList}
                          isInitalLoad={isInitalLoad}
                          defaultId={defaultId}
                          bindProperties={this.props.bindProperties || bindProperties}
                          selectedId={selectedEntityGroupId}
                          isPaging={this.props.isPaging}
                          t={this.props.t}
                          tReady={this.props.tReady}
                          i18n={this.props.i18n}
                        />
                      </OccupiedContainer>
                    </ListContainer>
                  ) : null}
                </>
              );
            })}
        </ListContainerStyle>
        {selectedItems.length > 0 && (
          <ClearContainer>
            <button onClick={this.clearSelection} aria-label={this.props.t("filters:CLEAR")}>
              {this.props.t("filters:CLEAR")}
            </button>
          </ClearContainer>
        )}
      </FixedContainer>
    );
  }
}

export default HierarchyControl;
