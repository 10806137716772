import MUIAccordion from "@mui/material/Accordion";
import MUIAccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import styled from "styled-components";
// @ts-ignore
import iconExpand from "./assets/expandIcon.svg";
import { CustomTypography } from "./customTypography";
import { IAccordion } from "./iAccordion";

/**
 * @description - This is a upfront expansion panel that holds the summary and details panel.
 * User can render "n" number of components into the summary and details sections by passing the components thru props.
 * By default the panel will be collapsed.
 *
 * @const Accordion
 * @interface any
 */
const ExpansionPanelStyle = styled(MUIAccordion).attrs({
  id: "AccordianPanel",
})`
  && {
    box-shadow: none;
    /* background: #FAFAFA; USE WHEN ACCORDIAN OPEN */
    padding: 0 24px;
    ::before {
      background: transparent;
    }
    .MuiExpansionPanelSummary-expandIcon-133 {
      right: 0;
      padding: 12px 0;
    }
  }
`;

const ExpansionPanelSummaryStyle = styled(MUIAccordionSummary)`
  && {
    padding: 0;
    min-height: auto !important;
    > div:first-child {
      margin: 12px 0;
    }
  }
`;

const AccordionWrapper = styled.div`
  max-height: 320px;
  overflow-x: auto;
`;

class Accordion extends React.Component<IAccordion> {
  render() {
    const {
      accordionHead,
      uid,
      showExpandIcon,
      details,
      errorDetail,
      getDetails,
      initialRecordCount,
      nextShowMore,
      currObject,
      t,
      handleViewMore,
    } = this.props;
    return (
      <div>
        <ExpansionPanelStyle key={this.props.key} expanded={uid === this.props.selectedUID}>
          <ExpansionPanelSummaryStyle
            onClick={() => (showExpandIcon ? this.props.handleClick(uid) : null)}
            expandIcon={showExpandIcon ? <img src={iconExpand} alt="" /> : null}
          >
            {accordionHead.map((item, i) => {
              return (
                <div key={i}>
                  <CustomTypography id={`typographyIconId${i}`} {...item} />
                </div>
              );
            })}
          </ExpansionPanelSummaryStyle>
          <AccordionWrapper>
            {getDetails({
              currObject,
              details,
              errorDetail,
              handleViewMore,
              initialRecordCount,
              nextShowMore,
              t,
              uid,
            })}
          </AccordionWrapper>
        </ExpansionPanelStyle>
      </div>
    );
  }
}

/**
 * @export Accordion - Upfront Expansion Panel.
 */
export default Accordion;
