import { IconName } from "am-web-ui-shared/components";
import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import Accordion from "@mui/material/Accordion";
import React from "react";
import { Trans } from "react-i18next";
import { attachments } from "../../../../components/attachmentSection/attachmentsSection";
import {
  CountExpansionPanel,
  IconBold,
  IconNormalBold,
  UpfrontExpansionPanel,
} from "../../../../components/DetailsPanel/index";
import Title from "../../../../components/DetailsPanel/SingleRow/Title/title";
import SidePanel from "../../../../components/sidePanel";
import { formatFrequency } from "../../../../utils/commonUtils";
import { IAttachmentDocument } from "../../../attachmentSection/attachmentDocument";
import { ServiceStatus } from "../../serviceFormController/serviceFormEnum";
import { IServiceDetail } from "./iServiceDetail";
import ServiceDatesPanel from "./serviceDatesPanel";
import BasedOnPanel from "./basedOnPanel";
import ServiceNamePanel from "./serviceNamePanel";
import { autoLinkText } from "../../../../../common/utils/commonUtils";
import SingletonFeatureManager from "../../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../../featureFlagManager/featureConfig";
import IconBoldCount from "TARGET_BUILD/common/components/DetailsPanel/SingleRow/IconBoldCount/iconBoldCount";
const StyledWrapper = styled("div")`
  && {
    border-top: 1px solid ${(props) => props.theme.locationDetail.borderColor.color};
  }
`;
const DivDetailWrapper = styled.div.attrs({
  id: "service-detail-container",
})`
  width: 100%;
`;
const attachmentProperty: IAttachmentDocument = {
  desciption: "description",
  displayName: "displayName",
  fileId: "serviceAttachmentId",
  fileName: "fileName",
  id: "id",
  type: "type",
  fileSource: "fileSource",
  path: "path",
};

const StyledCostPanel = styled(Accordion)`
  && {
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
    box-shadow: none;
    padding: 0 16px;
    &:before {
      height: 0;
      margin: 0;
    }
  }
`;

/**
 * Function to display service frequency panel.
 * @param props
 */
export const getFrequencyPanel = (props: IServiceDetail) => {
  const { selectedRow, t } = props;
  return (
    selectedRow.frequency && (
      <CountExpansionPanel
        panelId="frequencyPanel"
        headingId="frequencyHeading"
        boldId="lblFrequencyService"
        boldText={t("filters:FREQUENCY")}
        countId="lblFrequencyService"
        countText={selectedRow.isLifeLong ? t("services:LIFELONG_SERVICE") : formatFrequency(selectedRow.frequency, t)}
        iconUrl={IconName.Calendar}
        showExpandIcon={false}
      />
    )
  );
};

/**
 * Function to display notes panel.
 * @param props
 */
export const ServiceNotesPanel: React.FC<IServiceDetail> = (props: IServiceDetail) => {
  const { selectedRow } = props;
  return selectedRow.notes ? (
    <UpfrontExpansionPanel
      panelId="serviceNotesSection"
      detailsData={selectedRow}
      summaryComponents={[notesHeading]}
      detailComponents={[notesDetails]}
      associatedRowId={selectedRow.id}
      isEnable={true}
    />
  ) : null;
};

export const notesDetails = (data) => {
  return (
    data.notes && (
      <IconNormalBold id="pnlDatesCompletionDate" boldId="txtCompletionDateCost" boldText={autoLinkText(data.notes)} />
    )
  );
};

export const notesHeading = () => {
  return (
    <IconBold
      id="pnlNotesHeading"
      iconUrl={IconName.DeliveryNotes}
      boldId="lblNotesHeading"
      boldText={<Trans>{"common:LABEL_NOTES"}</Trans>}
    />
  );
};

const cost = (data, t, serviceStatus) => {
  const currency = data.cost ? data.cost + " " + data.costCurrency : "0.00";
  return data.isSystemService && serviceStatus === ServiceStatus.HISTORY ? (
    <StyledCostPanel>
      <IconBoldCount
        iconUrl={IconName.Dollar}
        panelId={"costPanel"}
        boldId={"lblCost"}
        boldText={t("services:REPAIR_COST")}
        countId={"lblCost"}
        countText={currency}
        displayCountInline={true}
      />
    </StyledCostPanel>
  ) : null;
};

export const ServiceDetailWrapper = (props: IServiceDetail) => {
  const serviceAttachments = HelperLodash.compact(
    HelperLodash.concat(props.selectedRow.serviceTemplateAttachments, props.selectedRow.serviceAttachments),
  );
  const heavyEquipmentTemplateFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE);
  const { serviceStatus, selectedRow, distanceUnitValue, t } = props;
  return (
    <SidePanel sidePanelWidth={315}>
      <DivDetailWrapper>
        <StyledWrapper id="serviceDetailsWrapper">
          <Title id="serviceDetails">{props.t("services:SERVICE_DETAILS")}</Title>
          <>
            <ServiceNamePanel
              distanceUnitValue={distanceUnitValue}
              serviceStatus={serviceStatus}
              selectedRow={selectedRow}
            />
            {!heavyEquipmentTemplateFF && serviceStatus === ServiceStatus.HISTORY && (
              <ServiceDatesPanel selectedRow={selectedRow} />
            )}
            {heavyEquipmentTemplateFF && serviceStatus === ServiceStatus.HISTORY && (
              <BasedOnPanel distanceUnitValue={distanceUnitValue} selectedRow={selectedRow} />
            )}
            {cost(selectedRow, t, serviceStatus)}
            {getFrequencyPanel(props)}
            {attachments(serviceAttachments.length && serviceAttachments, attachmentProperty)}
            {serviceStatus === ServiceStatus.HISTORY && <ServiceNotesPanel selectedRow={selectedRow} />}
          </>
        </StyledWrapper>
      </DivDetailWrapper>
    </SidePanel>
  );
};

export default ServiceDetailWrapper;
