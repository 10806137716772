import { HelperLodash } from "am-web-ui-shared/helpers";
import { validateChargeSettingResponse, validateResponse } from "../../../../../../common/utils/errorUtils";
import { getTotalCount } from "../../../../administration/asset/manufacturer/components/merge/manufacturerMergeUtil";
import AssetTemplateMergeConfig from "../../../../administration/template/components/merge/assetTemplateMergeConfig";
import { blankValueEnum, responseModelEnum } from "./responseModelEnum";

/**
 * @description hasColumnInConfigmethod check column found in the config
 *
 * @param - columns - input array of config columns in merge box
 * @param - bindProperty- input data index from data array
 */
const hasColumnInConfig = (columns, bindProperty) => {
  const returnValue = columns().filter((column) => {
    return column.bindProperty === bindProperty;
  });
  return returnValue && returnValue.length > 0;
};
/**
 * @description addResponseRow method handle the row for preview response`
 *
 * @param - config - input array of config columns in merge box
 * @param - row - input data single row from data array
 * @param - responseRow - response row
 */
const addResponseRow = (config, row) => {
  const responseRow = {};
  const parentKey = config.mappedKeyProperty;
  row[responseModelEnum.costTypeValueDisplay] = row[responseModelEnum.costTypeValueDisplay] || blankValueEnum.NONE;
  row[responseModelEnum.templateImageId] = row[responseModelEnum.templateImageId] || blankValueEnum.NO_VALUE;

  const costCodeKey = "costCode";
  if (!HelperLodash.get(row, costCodeKey)) {
    HelperLodash.set(row, costCodeKey, blankValueEnum.NO_VALUE);
  }
  const descriptionKey = "description";
  if (!HelperLodash.get(row, descriptionKey)) {
    HelperLodash.set(row, descriptionKey, blankValueEnum.NO_VALUE);
  }
  const defaultGroupNameKey = "defaultGroupName";
  if (!HelperLodash.get(row, defaultGroupNameKey)) {
    HelperLodash.set(row, defaultGroupNameKey, blankValueEnum.NO_VALUE);
  }
  const { columns, responseKeyProperty } = config;
  Object.entries(row).forEach(([key, keyValue]) => {
    const newKey = key === parentKey ? responseKeyProperty : key;
    if (hasColumnInConfig(columns, key) || key === parentKey) {
      responseRow[`${newKey}`] = key === parentKey ? keyValue : { value: keyValue, isSelected: false };
    }
  });
  return responseRow;
};
/**
 * @description generateResponse method handle to create preview response
 * & create subheader Total count based on sub header eq: asset counts
 * @param - props - input props in merge box
 */
const generateResponse = (data, config) => {
  const response = [];
  data.forEach((row) => {
    response.push(addResponseRow(config, row));
  });
  return response;
};
const updateData = (data, templateChargeSetting) => {
  data.forEach((row, index) => {
    const selectedTemplateChargeSetting = HelperLodash.get(templateChargeSetting, index);
    row[responseModelEnum.manufacturerNameAndModelWithDash] = `${row[responseModelEnum.manufacturerName]} - ${
      row[responseModelEnum.model]
    }`;
    row[responseModelEnum.costTypeValueDisplay] = HelperLodash.get(
      selectedTemplateChargeSetting,
      responseModelEnum.costTypeValueDisplay,
    );
    row[responseModelEnum.manufacturerNameAndModel] = `${row[responseModelEnum.manufacturerName]} / ${
      row[responseModelEnum.model]
    }`;
  });
  return data;
};

const handleTemplateResponseErrors = (templateDetails, chargeSettingDetails) => {
  // Need to have different error methods
  let chargeSettingResponseError = null;
  let templateDetailResponseError = null;

  chargeSettingDetails.map((response) => {
    const errorCode = validateChargeSettingResponse(response);
    if (errorCode) {
      chargeSettingResponseError = errorCode;
    }
  });
  templateDetails.map((response) => {
    const errorCode = validateResponse(response);
    if (errorCode) {
      templateDetailResponseError = errorCode;
    }
  });
  return { chargeSettingResponseError, templateDetailResponseError };
};
const prepareTemplateMergeData = (templateDetails, templateChargeSetting) => {
  const data = updateData(templateDetails, templateChargeSetting) || [];
  const responseData = generateResponse(data, AssetTemplateMergeConfig);
  return {
    data,
    responseData,
    totalCount: getTotalCount(data, "assetCount"),
  };
};
// const prepareChargeSettingData = ()
const getTemplateChargeSettingValue = (templateChargeSetting) =>
  templateChargeSetting.costTypeCode
    ? templateChargeSetting
    : { error: true, code: validateChargeSettingResponse(templateChargeSetting) };
export { handleTemplateResponseErrors, prepareTemplateMergeData, getTemplateChargeSettingValue };
