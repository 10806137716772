import Dialog, { dialogClasses } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
export default styled(Dialog)(({ fullScreen, theme }) => {
  return {
    [`& .${dialogClasses.paper}`]: {
      borderRadius: 0,
      margin: 0,
      display: 'inline-flex',
      flexDirection: 'column',
      height: fullScreen ? `calc(100vh - ${theme.spacing(8)})` : 'auto',
      width: fullScreen ? `calc(100vw - ${theme.spacing(8)})` : 'auto',
    },
  };
});

export const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    overflowY: 'auto',
    flex: 1,
    margin: 0,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  };
});

export const DialogActions = styled(MuiDialogActions)<{ fullScreen?: boolean }>(
  ({ theme, fullScreen }) => {
    return {
      margin: 0,
      padding: theme.spacing(3),
      boxShadow: fullScreen ? `0px -3px 5px ${theme.colors.shadowBox[50]}` : '0',
    };
  },
);

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => {
  return {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(0),
    padding: 0,
    fontWeight: theme.fonts.bold.fontWeight,
    fontFamily: theme.fonts.bold.fontFamily,
    fontSize: 18,
    display: 'flex',
    justifyContent: 'space-between',
  };
});
