import { change } from "redux-form";
import { all, put, select, takeLatest } from "redux-saga/effects";
import {
  createUniqueAsset,
  getAssetGroups,
  getImportHistory,
  getImportHistoryErrors,
  getManufacturerAndModel,
  getManufacturers,
  getPDPTemplate,
  getRecentlyUsedTemplates,
  getWorkersData,
  saveMultiEditAsset,
  getServiceImportHistory,
  getServiceImportHistoryErrors,
} from "../../../../api/assetApi";
import { getTemplateDetails } from "../../../../api/assetTemplatesApi";
import { getTemplateCall, hasError, isManufactureModelExist } from "../../../../components/pdpIntegration/pdpHelper";
import { DocumentType } from "../../../../components/upload/iUploadComponentProps";
import { getTemplateCallForUnregisteredTool } from "../../../../modules/asset/components/hint/hintHelpers";
import { getFileFromURL } from "../../../../utils/pdpImageUtils";
import { call } from "../../../../sagas/common/commonSaga";

// @ts-ignore
import { AssetActions, DocumentActions, ImportHistoryActions } from "custom-actions";
import { AssetKeys } from "../../../../utils/enums/assetEnum";
import { getMultiSelectPayloadAttributes } from "../../../../utils/list/listUtils";
import { getQueryObjForMultiPayload } from "../../../../sagas/stateSelector";
import { getGeoTags } from "../../../../api/hiltiIntegrationApi";

export function* recentlyUsedTemplatesSaga() {
  yield takeLatest("GET_RECENTLY_USED_TEMPLATES", fetchRecentlyUsedTemplates);
}

export function* fetchRecentlyUsedTemplates() {
  let response = yield call(() => {
    return getRecentlyUsedTemplates();
  });
  if (response.error) {
    response = [];
  }
  yield put({
    payload: response.response,
    type: AssetActions.AM_RECENTLY_USED_TEMPLATES_RESPONSE,
  });
}

export function* addAssetSaga() {
  yield takeLatest("CREATE_ASSET", createAssetData);
}

export function* createAssetData(action) {
  const response = yield call(() => {
    return createUniqueAsset(action.assetData);
  });

  if (response.error) {
    yield put({ type: AssetActions.CREATE_ASSET_ERROR, payload: response.error });
  } else {
    yield put({ type: AssetActions.CREATE_ASSET_RESPONSE, payload: response });
  }
}

export function* getManufacturerListSaga() {
  yield takeLatest("GET_MANUFACTURER_LIST", getManufacturerData);
}

export function* getManufacturerData() {
  let response = yield call(() => {
    return getManufacturers();
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: "SET_MANUFACTURER_LIST_RESPONSE", payload: response });
}

export function* getServiceImportHistoryDataSaga() {
  yield takeLatest(ImportHistoryActions.GET_SERVICE_IMPORT_HISTORY, getServiceImportHistoryData);
}

export function* getServiceImportHistoryData() {
  let response = yield call(() => {
    return getServiceImportHistory();
  });
  if (response?.error) {
    response = [];
  }
  yield put({ type: ImportHistoryActions.GET_IMPORT_SERVICE_HISTORY_RESPONSE, payload: response });
}

export function* getImportHistoryDataSaga() {
  yield takeLatest(ImportHistoryActions.GET_IMPORT_HISTORY, getImportHistoryData);
}

export function* getImportHistoryData() {
  let response = yield call(() => {
    return getImportHistory();
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: ImportHistoryActions.IMPORT_HISTORY_RESPONSE, payload: response });
}

export function* getServiceImportHistoryErrorsDataSaga() {
  yield takeLatest(ImportHistoryActions.GET_SERVICE_IMPORT_HISTORY_ERRORS, getServiceImportHistoryDataErrors);
}

export function* getServiceImportHistoryDataErrors(action) {
  let newHistoryErrorPayload = null;
  const response = yield call(() => {
    return getServiceImportHistoryErrors(action.historyId);
  });

  if (response.error) {
    newHistoryErrorPayload = {
      id: action.accordinId,
      response: [],
    };
  } else {
    const historyId = action.historyId;
    const detailObj = {
      [historyId]: response.uploadHistoryErrorDetailResponses,
    };
    newHistoryErrorPayload = detailObj;
  }
  yield put({ type: "GET_SERVICE_IMPORT_HISTORY_ERRORS_RESPONSE", payload: newHistoryErrorPayload });
}

export function* getImportHistoryErrorsDataSaga() {
  yield takeLatest(ImportHistoryActions.GET_IMPORT_HISTORY_ERRORS, getImportHistoryDataErrors);
}

export function* getImportHistoryDataErrors(action) {
  let newHistoryErrorPayload = null;
  const response = yield call(() => {
    return getImportHistoryErrors(action.historyId);
  });

  if (response.error) {
    newHistoryErrorPayload = {
      id: action.accordinId,
      response: [],
    };
  } else {
    const historyId = action.historyId;
    const detailObj = {
      [historyId]: response.uploadHistoryErrorDetailResponses,
    };
    newHistoryErrorPayload = detailObj;
  }
  yield put({ type: "GET_IMPORT_HISTORY_ERRORS_RESPONSE", payload: newHistoryErrorPayload });
}

export function* getAssetGroupsSaga() {
  yield takeLatest("GET_ASSET_GROUPS", getAssetGroupsData);
}

export function* getAssetGroupsData() {
  let response = yield call(() => {
    return getAssetGroups();
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: "SET_ASSET_GROUPS_RESPONSE", payload: response });
}

/**
 * @description Function to get the inventory list
 */
export function* getInventoryWorkersListSaga() {
  yield takeLatest("GET_INVENTORY_WORKERS_LIST", getInventoryWorkersList);
}

/**
 * @description Function to call for the workers data and put the
 * response for the given type action.
 * @param {object} data - having action type and action object
 */
function* fetchWorkersData(data) {
  let response = yield call(() => {
    return getWorkersData(data.action.responsibilityType);
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: data.type, payload: response.response });
}

/**
 * @description Function to get the worker list
 */
export function* getInventoryWorkersList(action) {
  yield call(fetchWorkersData, { action, type: AssetActions.SET_INVENTORY_WORKERS_RESPONSE });
}

export function* getResponsibleWorkersListSaga() {
  yield takeLatest("GET_RESPONSIBLE_WORKERS_LIST", getResponsibleWorkersList);
}

export function* getResponsibleWorkersList(action) {
  yield call(fetchWorkersData, { action, type: AssetActions.SET_RESPONSIBLE_WORKERS_RESPONSE });
}

export function* getByManufacturerAndModelSaga() {
  yield takeLatest("GET_BY_MANUFACTURER_AND_MODEL", getByManufacturerAndModel);
}

const filterAttachment = (action) => {
  const assetAttachment = action.assetAttachment && action.assetAttachment.filter((item) => !item.isReadOnly);
  return assetAttachment ? assetAttachment : [];
};

const getAssetAttachments = (action, attachemnt) =>
  action.assetAttachment && action.assetAttachment.length > 0 ? attachemnt : [];

export function* getByManufacturerAndModel(action) {
  let response: any = {};
  if (isManufactureModelExist(action)) {
    response = yield call(() => {
      if (action.model.isUnregisteredHiltiAsset) {
        return getTemplateCallForUnregisteredTool(getTemplateDetails, getPDPTemplate, getManufacturerAndModel, action);
      } else {
        return getTemplateCall(getPDPTemplate, getManufacturerAndModel, action);
      }
    });

    if (hasError(response)) {
      response = {};
    }
  }

  /*
    upload image to doc-mgmt from the url only
    if there is productImageURL and there is no templateImageId for the template.
    No need to check for PDP, rangeId or AMTemplate flags in the above case.
  */

  if (response.productImageURL && !response.templateImageId) {
    const imageURL = response.productImageURL;
    const formData = yield getFileFromURL(imageURL);
    if (formData) {
      yield put({
        payload: {
          callback: setPDPTemplateId,
          documentType: DocumentType.Image,
          formData,
          pdpImages: true,
          showLoader: false,
        },
        type: DocumentActions.AM_UPLOAD_DOCUMENT,
      });
    }
  }

  function* setPDPTemplateId(id) {
    yield put({
      documentType: DocumentType.Image,
      payload: id,
      type: AssetActions.SET_TEMPLATE_IMAGE_ID,
    });
  }

  yield put({
    payload: response,
    type: "SET_MANUFACTURER_AND_MODEL_RESPONSE",
  });
  const attachemnt = filterAttachment(action);
  if (response.templateAttachments) {
    const templateAttachments = response.templateAttachments.map((item) => {
      item.isReadOnly = true;
      return item;
    });
    yield put(change("addAssets", "assetAttachments", [...templateAttachments, ...attachemnt]));
  } else {
    yield put(change("addAssets", "assetAttachments", getAssetAttachments(action, attachemnt)));
  }
}

export function* updateMultipleAssetsSaga() {
  yield takeLatest(AssetActions.AM_MULTI_EDIT_ASSET, updateMultipleAssets);
}

export function* updateMultipleAssets(action) {
  let response: any = {};
  const multiSelectQueryFields = yield select(getQueryObjForMultiPayload, "Assets");
  const multiSelectPayloadFields = getMultiSelectPayloadAttributes(
    action.model.checkedListInfo,
    multiSelectQueryFields.isSelectAll,
    AssetKeys,
  );
  const multiRowPayload = {
    attributes: action.model.attributes,
    ...multiSelectQueryFields,
    ...multiSelectPayloadFields,
  };
  if (action.model) {
    response = yield call(() => {
      return saveMultiEditAsset(multiRowPayload);
    });
  }
  if (response && response.error) {
    response = response.error.response ? response.error.response.data : { invalidResponse: [] };
  }
  yield put({
    payload: response,
    type: AssetActions.MULTI_EDIT_RESPONSE,
  });
}

export function* getGeoTagsSerialSaga() {
  yield takeLatest(AssetActions.GET_GPS_TAGS_SERIAL, getGeoTagsSerial);
}

export function* getGeoTagsSerial() {
  let response: any = yield call(() => {
    return getGeoTags();
  });
  if (response && response.error) {
    response = response.error.response ? response.error.response.data : { invalidResponse: [] };
  }
  yield put({
    payload: response.response,
    type: AssetActions.SET_GPS_TAGS_SERIAL,
  });
}

function* AddEditAssetSaga() {
  yield all([
    addAssetSaga(),
    getAssetGroupsSaga(),
    getByManufacturerAndModelSaga(),
    getImportHistoryDataSaga(),
    getImportHistoryErrorsDataSaga(),
    getInventoryWorkersListSaga(),
    getManufacturerListSaga(),
    getResponsibleWorkersListSaga(),
    recentlyUsedTemplatesSaga(),
    updateMultipleAssetsSaga(),
    getGeoTagsSerialSaga(),
    getServiceImportHistoryDataSaga(),
    getServiceImportHistoryErrorsDataSaga(),
  ]);
}

export default AddEditAssetSaga;
