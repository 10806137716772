import { DialogContainer, Footer, ListContainer } from "am-web-ui-shared/components";
import { Form } from "am-web-ui-shared/elements";
import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import NotificationPanel from "../../../components/notificationPanel/notificationPanel";
import { ActionItem } from "../../../utils/enums/actionItemEnum";
import { DEFAULT_NOTIFICATION_NUMBER } from "../../../utils/enums/serviceEnum";
import { injectError } from "../../../utils/errorUtils";
import AdditionalInformationPanel from "./additionalInformation/additionalInformationPanel";
import AttachmentPanel from "./attachment/attachmentPanel";
import { IServiceForm } from "./iServiceForm";
import OtherDetailsPanel from "./otherDetail/otherDetailsPanel";
import ServiceDetailsPanel from "./serviceDetail/serviceDetailsPanel";
import { ServiceFormEnum, ServiceResultEnum, ServiceStatus } from "./serviceFormEnum";
import ServiceFrequencyPanel from "./serviceFrequency/serviceFrequencyPanel";
import SingletonFeatureManager from "../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../featureFlagManager/featureConfig";
const FullWidth = styled.div`
  width: 100%;
`;
const Notes = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;
export class ServiceForm extends React.PureComponent<IServiceForm, {}> {
  static formatInitialValues(serviceActionType, assignedServiceDetail, defaultCurrency) {
    const intialServiceValue = HelperLodash.clone(assignedServiceDetail);
    if (serviceActionType === ActionItem.complete) {
      return {
        ...intialServiceValue,
        completionDate: LocaleManager.dateHelpers.getNativeDateInTenantTimezone(),
        currency: defaultCurrency && defaultCurrency.code,
        serviceResult: ServiceResultEnum.PASSED.toUpperCase(),
      };
    } else {
      return {
        ...intialServiceValue,
        serviceResult: intialServiceValue?.serviceResult?.code,
      };
    }
  }
  state = {
    formInititalValue: ServiceForm.formatInitialValues(
      this.props.serviceActionType,
      this.props.assignedServiceDetail,
      this.props.defaultCurrency,
    ),
    isLifeLong: this.props.assignedServiceDetail.isLifeLong,
    serviceResult: this.props.assignedServiceDetail?.serviceResult?.code,
    isCompleationChange: false,
    completionAt: this.props.assignedServiceDetail?.completionAt,
  };

  componentDidMount() {
    if (!this.props.currenciesList && this.props.getCurrenciesList) {
      this.props.getCurrenciesList();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.serviceOperationError && this.props.serviceOperationError !== prevProps.serviceOperationError) {
      this.props.submitForm("editCompleteServiceForm");
    }
    if (this.props.assignedServiceDetail !== prevProps.assignedServiceDetail) {
      this.setState({
        formInititalValue: ServiceForm.formatInitialValues(
          this.props.serviceActionType,
          this.props.assignedServiceDetail,
          this.props.defaultCurrency,
        ),
      });
    }
  }
  /**
   * To Check if HeavyEquipment Feature flag is enabled
   */
  heavyEquipmentTemplateFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE);
  /**
   * its a callback to show unhandle error
   */
  getErrorMessage = (error) => {
    this.setState({ errorMessage: error });
  };

  handleErrorMessage = (error, props) => {
    injectError(error, props, this.props.t, null);
    this.props.clearServiceData();
  };

  showComponent = () => {
    return this.props.serviceStatus === ServiceStatus.HISTORY || this.props.serviceActionType === ActionItem.complete;
  };

  /**
   *  To handle submit button
   */

  handleSubmitForm = (serviceInfo, _dispatch, props) => {
    if (this.props.serviceOperationError) {
      this.handleErrorMessage(this.props.serviceOperationError, props);
    } else {
      this.props.handleServiceFormSubmit(serviceInfo);
    }
  };

  handleSubmitBtnText = () => {
    return this.props.serviceActionType === ActionItem.complete
      ? this.props.t("services:COMPLETE")
      : this.props.t("common:DONE");
  };

  handleCheckboxChange = (value) => {
    this.setState({ isLifeLong: value }, () => {
      if (value) {
        this.props.onFormchange(ServiceFormEnum.EDIT_COMPLETE_SERVICE_FORM, "scheduleDate", null);
        this.props.onFormchange(ServiceFormEnum.EDIT_COMPLETE_SERVICE_FORM, "scheduleAt", "");
      }
    });
  };
  handleServiceResult = (value) => {
    if (value) {
      this.setState({ serviceResult: value });
      this.props.serviceResultValue(value);
    }
  };

  render() {
    const {
      assignedServiceDetail,
      onFormchange,
      currenciesList,
      handleCompletionDateChange,
      isServiceStatus,
      serviceActionType,
      serviceStatus,
      t,
      distanceUnitValue,
      handleCompletionDataChange,
    } = this.props;

    return (
      <Form
        form="editCompleteServiceForm"
        initialValues={this.state.formInititalValue}
        onSubmit={this.handleSubmitForm}
      >
        <DialogContainer>
          <ListContainer modalFooter={appConstants.MODAL_FOOTER_HEIGHT}>
            <FullWidth>
              {assignedServiceDetail && (
                <ServiceDetailsPanel
                  selectedService={assignedServiceDetail}
                  serviceStatus={serviceStatus}
                  showDescription={true}
                  showScheduleDate={true}
                  showCompletionDate={this.showComponent()}
                  onFormchange={onFormchange}
                  handleCompletionDateChange={handleCompletionDateChange}
                  handleCompletionDataChange={handleCompletionDataChange}
                  showServiceDropDown={false}
                  showServiceRadioButton={false}
                  isServiceStatus={isServiceStatus}
                  serviceActionType={serviceActionType}
                  isLifeLong={this.state.isLifeLong}
                  handleCheckboxChange={this.handleCheckboxChange}
                  assetId={this.props.assetId}
                  handleServiceResult={this.props.serviceResultValue}
                  distanceUnitValue={distanceUnitValue}
                />
              )}
              {assignedServiceDetail && (
                <ServiceFrequencyPanel
                  distanceUnitValue={distanceUnitValue}
                  serviceActionType={serviceActionType}
                  selectedService={assignedServiceDetail}
                />
              )}
              {assignedServiceDetail && (
                <NotificationPanel
                  panelTitleId="serviceNotifications"
                  notificationTooltipMessage={t("services:NOTIFICATION_TOOLTIP_MSG")}
                  distanceUnitValue={distanceUnitValue}
                  notificationPeriodLabel={
                    this.heavyEquipmentTemplateFF ? t("services:UNIT_OF_MEASUREMENT") : t("services:LABEL_PERIOD")
                  }
                  notificationNumber={
                    assignedServiceDetail.notificationNumber
                      ? assignedServiceDetail.notificationNumber
                      : DEFAULT_NOTIFICATION_NUMBER
                  }
                  notificationPeriod={
                    assignedServiceDetail.notificationPeriod
                      ? assignedServiceDetail.notificationPeriod.value
                      : t("common:DAYS")
                  }
                />
              )}
              {this.showComponent() && (
                <OtherDetailsPanel cost={this.state.formInititalValue.cost} currenciesList={currenciesList} />
              )}
              <AttachmentPanel
                associatedEntity="assetOpenService"
                formName={"editCompleteServiceForm"}
                viewOnlyAttachment={assignedServiceDetail ? assignedServiceDetail.serviceTemplateAttachments : []}
              />
              {this.showComponent() && <AdditionalInformationPanel />}
              <Footer buttonType="cta" buttonText={this.handleSubmitBtnText()} type="submit" />
            </FullWidth>
          </ListContainer>
        </DialogContainer>
      </Form>
    );
  }
}

export default withTranslation()(ServiceForm);
