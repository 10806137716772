import React, { ReactElement } from "react";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { SearchProvider } from "am-web-ui-shared/components";

interface ILazyGridWrapperProps {
  children: ReactElement;
  searchString: string;
}

const LazyGridWrapper = ({ children, searchString }: ILazyGridWrapperProps) => {
  const searchedText = searchString ?? "";
  const isSearchHighlightEnabled = SingletonFeatureManager.isFeatureEnabled(FEATURES.SEARCH_HIGHLIGHT);
  return <SearchProvider value={{ searchString: searchedText, isSearchHighlightEnabled }}>{children}</SearchProvider>;
};

export default LazyGridWrapper;
