import React, { ReactElement, useContext, useState } from "react";
import { Response } from "./usePositionData";

type Loc = { location: Response; actions: { set: (value) => void } };

// creating context
const LocationContext = React.createContext<Loc>({ location: null, actions: { set: null } });

export const useLocationContext = () => useContext(LocationContext);

export const LocationProvider = ({ children }): ReactElement => {
  const [location, setLocation] = useState<Response>();

  const set = (value: Response) => {
    setLocation(value);
  };

  return (
    <LocationContext.Provider
      value={{
        location,
        actions: {
          set,
        },
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
