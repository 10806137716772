import React from "react";

const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M13.3333333,9.33333333 L26.6666667,9.33333333 L26.6666667,25.3333333 L8,25.3333333 L8,21.3333333 L22.6666667,21.3333333 L22.6666667,13.3333333 L13.3333333,13.3333333 L13.3333333,17.3333333 L4,11.3333333 L13.3333333,5.33333333 L13.3333333,9.33333333 Z" />
    </g>
  </svg>
);

export default SVG;
