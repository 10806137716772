import React from "react";
import { dependencyResolver } from "../gridUtils";
import { IGridCell } from "./iGridCell";

/**
 * Grid cell for custom component.
 */

export const GridCustomCell = React.memo((props: IGridCell) => {
  const { column, data, dependencies, rowIndex, columnIndex } = props;
  const Component = dependencyResolver(dependencies, column.component);
  return <>{Component(column, data, rowIndex, columnIndex)}</>;
});

export default GridCustomCell;
