import {
  INotificationInterface,
  IServiceAttachmentsInterface,
  IServiceInterface,
} from "../../components/services/model/iAssignedServiceDetail";

export default class ServiceDetail {
  "id": string;
  "basedOn": string;
  "scheduleDate": string;
  "completionDate": string;
  "costCurrency": string;
  "name": string;
  "description": string;
  "frequency": string;
  "lastRecord": boolean;
  "notes": string;
  "notificationNumber": number;
  "notificationPeriod": INotificationInterface;
  "cost": string;
  "currency": string;
  "provider": string;
  "serviceState": IServiceInterface;
  "serviceType": IServiceInterface;
  "serviceTemplateAttachments": IServiceAttachmentsInterface;
  "serviceInterval": number;
  "servicePeriod": IServiceInterface;
  "source": string;
  "isLifeLong": boolean;
  "serviceResult": string;
  "scheduleAt": number;
  "completionAt": number;
  "isSystemService": boolean;
  "serviceAttachments": IServiceAttachmentsInterface[];

  constructor(response: any) {
    this.id = response.id;
    this.basedOn = response.basedOn;
    this.scheduleDate = response.scheduledDate;
    this.completionDate = response.completionDate;
    this.name = response.serviceName;
    this.costCurrency = response.costCurrency;
    this.description = response.description;
    this.frequency = response.frequency;
    this.lastRecord = response.lastRecord;
    this.notes = response.notes;
    this.notificationNumber = response.notificationNumber;
    this.notificationPeriod = response.notificationPeriod;
    this.cost = response.cost;
    this.currency = response.costCurrency;
    this.provider = response.provider;
    this.serviceAttachments = response.serviceAttachments;
    this.serviceTemplateAttachments = response.serviceTemplateAttachments;
    this.serviceState = response.serviceState;
    this.serviceType = response.serviceType;
    this.serviceInterval = response.serviceInterval;
    this.servicePeriod = response.servicePeriod;
    this.source = response.source;
    this.isLifeLong = response.isLifeLong;
    this.serviceResult = response.serviceResult;
    this.scheduleAt = response.scheduleAt;
    this.completionAt = response.completionAt;
    this.isSystemService = response.isSystemService;
  }
}
