import React from "react";
// @ts-ignore
import styled from "custom-styled-components";

export const StyledSup = styled.sup`
  color: ${(props) => props.theme.colors.base.steel40};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 0 3px;
`;

// the "BETA" label will not be translatable
const Beta = () => <StyledSup>BETA</StyledSup>;

export default Beta;
