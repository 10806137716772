import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M3,1 L3,20 L18,20 L18,7 L12,1 L3,1 Z M5,24 L5,22 L20,22 L20,9 L22,9 L22,24 L5,24 Z M11,2.5 L16.5,8 L11,8 L11,2.5 Z" />
    </g>
  </svg>
);

export default SVG;
