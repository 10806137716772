import React from "react";
import styled from "styled-components";

const dialogBodyPadding = (props: IoverFlowProp) => `${props.paddingTop}px`;
const DialogBody = styled.div`
  padding-top: ${dialogBodyPadding};
`;
const DialogInner = styled.div`
  width: 992px;
  margin: 0px auto;
`;
interface IoverFlowProp {
  height?: number;
  paddingTop?: number;
}
export const DialogBodyContent = (props: any) => {
  return (
    <DialogBody paddingTop={props.paddingTop}>
      <DialogInner>{props.children}</DialogInner>
    </DialogBody>
  );
};

DialogBodyContent.defaultProps = {
  paddingTop: 24,
};
