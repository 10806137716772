import React from "react";
const SVG = ({ color = "#524F53" }: { color?: string }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill={color}>
      <path
        d="M4.51471863,18.4852814 L4.51471863,14.4852814 L12.5147186,14.4852814 L12.5147186,6.48528137 L16.5147186,6.48528137 L16.5147186,18.4852814 L4.51471863,18.4852814 Z"
        transform="translate(10.514719, 12.485281) rotate(-45.000000) translate(-10.514719, -12.485281) "
      />
    </g>
  </svg>
);

export default SVG;
