import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3236.000000, -986.000000)" fill="#524F53">
        <g transform="translate(3232.000000, 982.000000)">
          <path
            fill="#524F53"
            fill-rule="evenodd"
            d="M5.347 2.667h13.32l8 8v18.666H5.333l.014-26.666zM9.333 16v9.333H12V16H9.333zm5.334-4v13.333h2.666V12h-2.666zM20 20v5.333h2.667V20H20z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
