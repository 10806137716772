import {
  CellTypes,
  DetailsPanel,
  EmptyList,
  GridView,
  IconName,
  IGridSetting,
  InfiniteScrollComponent,
  ListContainer,
  InfiniteScrollLoadEnd,
} from "am-web-ui-shared/components";
import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import { formatCount, formatFrequency, formatNotificationPeriod, getPropertyLabel } from "../../../utils/commonUtils";
import { ActionItem } from "../../../utils/enums/actionItemEnum";
import ModuleName from "../../../utils/enums/moduleNameEnum";
import InfiniteScrollLoader from "../../../utils/loader/infinitescrollLoader";
import NoRecord from "../../noRecord";
import IserviceListModel, { IServiceListViewProps } from "../model/iserviceList";
import { ServiceStatus, StatusTypeFilter } from "../serviceFormController/serviceFormEnum";
import AssignServiceComponent from "./assignService";
import ServiceDetailWrapper from "./details/serviceDetailWrapper";
import serviceHistoryListGridSetting from "./serviceHistoryListGridSettings";
import ServiceStatusComponent from "./serviceStatus";
import ServiceResult from "./serviceResult";
import { isServiceResultSettingEnabled } from "../../../../common/utils/configUtil";
import { AssetServiceCategory } from "../../../utils/enums/serviceEnum";
import SingletonFeatureManager from "../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../featureFlagManager/featureConfig";
const ServiceListBody = styled.div`
  overflow: auto;
  flex: 1;
  min-height: 0;
`;
const ListBody = styled.div`
  display: flex;
  width: 100%;
  &.open-sidebar {
    width: calc(100% - 315px);
  }
`;

export const serviceTemplateGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          key: "scheduleAt",
          properties: [
            {
              name: "scheduleAt",
              dependencies: { format: "formatScheduleAt" },
            },
          ],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "isLifeLong",
          properties: [
            {
              name: "isLifeLong",
              dependencies: { format: "formatIsLifeLong" },
            },
          ],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "serviceDueDate",
          properties: [
            {
              name: "scheduleDate",
              dependencies: { format: "formatScheduleDate" },
            },
          ],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "serviceName",
          properties: [{ name: "name" }],
          style: {
            fontWeight: 700,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 4,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "notificationPeriod",
          properties: [
            { name: "notificationNumber" },
            {
              name: "notificationPeriod",
              dependencies: { format: "formatNotificationPeriod" },
            },
          ],
        },
        {
          key: "attachmentsLabel",
          properties: [
            {
              name: "serviceAttachmentsCount",
              dependencies: { format: "formatAttachmentCount" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceDescription",
          properties: [{ name: "description" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
  ],
  showTooltip: true,
};
export const serviceTemplateEditGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          key: "serviceDueDate",
          properties: [
            {
              name: "scheduleDate",
              dependencies: { format: "formatScheduleDate" },
            },
          ],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "serviceName",
          properties: [{ name: "serviceName" }],
          style: {
            fontWeight: 700,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceFrequency",
          properties: [{ name: "frequency" }],
        },
        {
          key: "attachmentsLabel",
          properties: [
            {
              name: "attachmentCount",
              dependencies: { format: "formatAttachmentCount" },
            },
          ],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceDescription",
          properties: [{ name: "description" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "serviceState",
          properties: [{ name: "serviceState" }],
        },
      ],
      cellSize: 3,
      component: "ServiceStatus",
      isSortable: true,
      type: CellTypes.Custom,
    },
  ],
  showTooltip: true,
};

/**
 * @description - LabelList component that is used to create the list of label under the Label main menu.
 *
 * @class LabelList
 * @interface IProps
 */
export class ServiceListView extends React.Component<
  IServiceListViewProps,
  { serviceList: any; serviceActionType: string }
> {
  listScrollRef;
  constructor(props) {
    super(props);
    this.state = {
      serviceActionType: null,
      serviceList: this.getServiceList(),
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.serviceStatus !== this.props.serviceStatus || prevProps.serviceList !== this.props.serviceList) {
      this.setState({ serviceList: this.getServiceList() });
    }
  }

  /**
   * @description - Function to format attachment count cell.
   * @param value - Attachment Count.
   */
  formatAttachmentCount = (value: string, item: IserviceListModel) => {
    const { t } = this.props;
    const messages = {
      noValueText: t("common:NO_ATTACHMENTS"),
      pluralText: t("common:ATTACHMENTS"),
      singulerText: t("common:ATTACHMENT"),
    };
    return formatCount(value, messages, item.isSystemService);
  };

  /**
   * @description - Function to format Notification Period.
   * @param value - Notification period value.
   */
  formatNotificationPeriod = (value: string) => formatNotificationPeriod(value, this.props.t);

  /**
   * @description - Function to format Service Frequency.
   * @param value - Service frequency value.
   */
  formatFrequency = (value: string) => formatFrequency(value, this.props.t);
  /**
   * @description - Function to format Life Long.
   * @param value - rowData.
   */
  formatIsLifeLong = (_column: any, rowData: any, _rowIndex: number, _columnIndex: number) => {
    const { t } = this.props;
    if (rowData.isLifeLong) {
      return t("services:LIFELONG_SERVICE");
    } else {
      return "";
    }
  };
  /**
   * @description - Function to format scheduleAt.
   * @param value - rowData.
   */
  formatScheduleAt = (_column: any, rowData: any, _rowIndex: number, _columnIndex: number) => {
    const { t, distanceUnitValue } = this.props;
    if (
      (!rowData.isLifeLong && rowData.basedOn === AssetServiceCategory.DISTANCE) ||
      rowData.basedOn?.code === AssetServiceCategory.DISTANCE
    ) {
      return t("services:SCHDEULE_AT_DISTANCE", {
        scheduleAt: rowData.scheduleAt,
        distanceUnitValue: distanceUnitValue?.value,
      });
    } else if (
      (!rowData.isLifeLong && rowData.basedOn === AssetServiceCategory.OPERATING_TIME) ||
      rowData.basedOn?.code === AssetServiceCategory.OPERATING_TIME
    ) {
      return t("services:SCHDEULE_AT_HOURS", {
        scheduleAt: rowData.scheduleAt,
      });
    } else {
      return "";
    }
  };
  /**
   * @description - Function to format completionAt.
   * @param value - rowData.
   */
  formatCompletionAt = (_column: any, rowData: any, _rowIndex: number, _columnIndex: number) => {
    const { t, distanceUnitValue } = this.props;
    if (rowData.basedOn === AssetServiceCategory.DISTANCE || rowData.basedOn?.code === AssetServiceCategory.DISTANCE) {
      return t("services:SCHDEULE_AT_DISTANCE", {
        scheduleAt: rowData.completionAt,
        distanceUnitValue: distanceUnitValue?.value,
      });
    } else if (
      rowData.basedOn === AssetServiceCategory.OPERATING_TIME ||
      rowData.basedOn?.code === AssetServiceCategory.OPERATING_TIME
    ) {
      return t("services:SCHDEULE_AT_HOURS", {
        scheduleAt: rowData.completionAt,
      });
    } else {
      return "";
    }
  };

  /**
   * @description - get formatedScheduleDate in ediit case
   */
  formatScheduleDate = (value: string) => LocaleManager.dateHelpers.getDateInTenantTimezone(value);

  /**
   * @description - get formatCompletionDate
   */
  formatCompletionDate = (_column: any, rowData: any, _rowIndex: number, _columnIndex: number) => {
    if (
      rowData.basedOn === AssetServiceCategory.DISTANCE ||
      rowData.basedOn?.code === AssetServiceCategory.DISTANCE ||
      rowData.basedOn === AssetServiceCategory.OPERATING_TIME ||
      rowData.basedOn?.code === AssetServiceCategory.OPERATING_TIME
    ) {
      return "";
    } else {
      return LocaleManager.dateHelpers.getDateInTenantTimezone(rowData.completionDate);
    }
  };

  /**
   * @description - get serviceStatus in edit case
   */
  getServiceStatus = (column: any, rowData: any, rowIndex: number, columnIndex: number) => {
    return (
      <ServiceStatusComponent
        showTooltip={serviceTemplateEditGridSetting.showTooltip}
        tooltipValue={this.props.t("fieldLabels:STATUS")}
        column={column}
        data={rowData}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
      />
    );
  };

  getServiceResult = (column: any, rowData: any, rowIndex: number, columnIndex: number) => {
    return (
      <ServiceResult
        showTooltip={serviceTemplateEditGridSetting.showTooltip}
        tooltipValue={this.props.t("fieldLabels:RESULT")}
        column={column}
        data={rowData}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
      />
    );
  };

  getServiceDependencies = () => {
    const dependencies: any = {
      ServiceStatus: this.getServiceStatus,
      formatAttachmentCount: this.formatAttachmentCount,
      getPropertyLabel: (property: string) => getPropertyLabel(property, this.props.t),
      ServiceResult: this.getServiceResult,
    };

    if (this.props.moduleName === ModuleName.asset) {
      dependencies.formatScheduleDate = this.formatScheduleDate;
      dependencies.formatScheduleAt = this.formatScheduleAt;
      dependencies.formatCompletionAt = this.formatCompletionAt;
      dependencies.formatIsLifeLong = this.formatIsLifeLong;
      dependencies.formatCompletionDate = this.formatCompletionDate;
    }
    return dependencies;
  };
  /**
   * @description -getServiceColumns for list
   */
  getServiceColumns = () => {
    const gridSettings: IGridSetting = HelperLodash.cloneDeep(
      this.props.serviceStatus === ServiceStatus.HISTORY
        ? isServiceResultSettingEnabled() !== null && isServiceResultSettingEnabled()
          ? serviceHistoryListGridSetting.editServiceHistoryListGridSettingWithResult
          : serviceHistoryListGridSetting.editServiceHistoryListGridSetting
        : serviceTemplateEditGridSetting,
    );
    if (this.props.moduleName === ModuleName.assetTemplate) {
      gridSettings.columns[0].bindProperties[0] = {
        key: "serviceFrequency",
        properties: [{ name: "frequency" }],
        style: { color: `${theme.colors.base.hiltiRed}` },
      };
      gridSettings.columns[1].bindProperties[0] = {
        key: "notificationPeriod",
        properties: [{ name: "notification" }],
      };
    } else if (
      SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE) &&
      this.props.moduleName === ModuleName.asset &&
      this.props.serviceStatus === ServiceStatus.OPEN
    ) {
      const secheduleAtField = {
        key: "scheduleAt",
        properties: [
          {
            name: "scheduleAt",
            dependencies: { format: "formatScheduleAt" },
          },
        ],
        style: { color: `${theme.colors.base.hiltiRed}` },
      };
      const isLifeLong = {
        key: "isLifeLong",
        properties: [
          {
            name: "isLifeLong",
            dependencies: { format: "formatIsLifeLong" },
          },
        ],
        style: { color: `${theme.colors.base.hiltiRed}` },
      };

      gridSettings.columns[0].bindProperties = [
        secheduleAtField,
        isLifeLong,
        ...gridSettings.columns[0].bindProperties,
      ];
    }
    return gridSettings.columns;
  };
  formatServiceAttachments = (value: any, item: IserviceListModel) => {
    return this.formatAttachmentCount(value, item);
  };
  getSevicesAddDependecies = () => {
    const dependencies: any = {
      getPropertyLabel: (property: string) => getPropertyLabel(property, this.props.t),
      formatIsLifeLong: this.formatIsLifeLong,
    };
    if (this.props.serviceStatus === ServiceStatus.HISTORY) {
      dependencies.formatServiceAttachments = this.formatServiceAttachments;
      dependencies.formatScheduleDate = this.formatScheduleDate;
      dependencies.formatScheduleAt = this.formatScheduleAt;
      dependencies.formatCompletionAt = this.formatCompletionAt;
      dependencies.formatCompletionDate = this.formatCompletionDate;
    } else {
      dependencies.formatAttachmentCount = this.formatAttachmentCount;
      dependencies.formatFrequency = this.formatFrequency;
      dependencies.formatScheduleAt = this.formatScheduleAt;
      dependencies.formatScheduleDate = this.formatScheduleDate;
      dependencies.formatNotificationPeriod = this.formatNotificationPeriod;
      dependencies.formatCompletionDate = this.formatCompletionDate;
    }
    return dependencies;
  };
  /**
   * @description -serviceList in edit case with pagination
   */
  getSeviceList = (type: any, service, loadPaginatedData) => {
    const gridSettings: IGridSetting = HelperLodash.cloneDeep(
      this.props.serviceStatus === ServiceStatus.HISTORY
        ? serviceHistoryListGridSetting.addServiceHistoryListGridSetting
        : serviceTemplateGridSetting,
    );

    return type === ActionItem.add ? (
      <GridView
        disableCheckBoxColumn={true}
        handleRowClick={this.props.handleRowClick}
        columns={gridSettings.columns}
        rowsData={service.list}
        idKey="assetServiceTemplateId"
        dependencies={this.getSevicesAddDependecies()}
        showTooltip={serviceTemplateGridSetting.showTooltip}
        lazyLoadList={false}
      />
    ) : (
      <InfiniteScrollComponent
        loader={() => <InfiniteScrollLoader />}
        item={this.listScrollRef}
        hasMore={service.list.length < service.totalRecords}
        loadMore={loadPaginatedData}
        pageStart={0}
        threshold={100}
        resetPage={null}
        loadEnd={() => <InfiniteScrollLoadEnd loadEnd={this.props.t("common:INFINITE_LOAD_END")} />}
      >
        <GridView
          disableCheckBoxColumn={true}
          handleRowClick={this.props.handleRowClick}
          columns={this.getServiceColumns()}
          rowsData={service.list}
          idKey="id"
          dependencies={this.getServiceDependencies()}
          showTooltip={serviceTemplateGridSetting.showTooltip}
          hideRowArrow={this.props.isMoreFilterOpen}
          lazyLoadList={false}
        />
      </InfiniteScrollComponent>
    );
  };
  getStatus = () =>
    this.props.serviceStatus === ServiceStatus.OPEN ? StatusTypeFilter.OPEN_SERVICE : StatusTypeFilter.HISTORY_SERVICE;

  filterList = (actionType, serviceList) => {
    const list = serviceList.list;
    if (list && actionType === ActionItem.add && this.props.moduleName !== ModuleName.assetTemplate) {
      return list.filter((item) => item.serviceStatus === this.props.serviceStatus);
    }
    return list;
  };

  shouldOpenDetailPanel = () => {
    const { assignedServiceDetail, selectedServiceInfo, actionType, isMoreFilterOpen } = this.props;

    return selectedServiceInfo && assignedServiceDetail && actionType === ActionItem.edit && !isMoreFilterOpen;
  };

  setServiceActionType = (serviceActionType) => {
    this.setState({ serviceActionType });
  };
  /**
   * @description renderPaginatedRows method trigger to show list
   *
   */
  renderPaginatedRows = (_list) => {
    const {
      actionType,
      assetFieldsData,
      assignHistoryService,
      assignedServicesList,
      assetId,
      clearAssignedServiceList,
      serviceOperationError,
      assignServiceSuccess,
      onFormchange,
      clearServiceData,
      completionDate,
      costCurrency,
      currenciesList,
      defaultCurrency,
      getCurrenciesList,
      getServicesList,
      getServiceTemplateDetails,
      serviceTemplateDetails,
      getAssignServicesList,
      handleServiceList,
      scheduleDate,
      serviceStatus,
      serviceValue,
      showToaster,
      submitForm,
      t,
      loadPaginatedData,
      isServiceStatus,
      moduleName,
      clearServiceTemplateDetails,
      allAssignedService,
      isMoreFilterOpen,
      distanceUnitValue,
    } = this.props;
    const { serviceList } = this.state;
    if (serviceList && serviceList.list && serviceList.list.length > 0) {
      return (
        <ListContainer modalFooter={appConstants.MODAL_FOOTER_HEIGHT}>
          <ListBody className={isMoreFilterOpen ? "open-sidebar" : null}>
            <ServiceListBody id="serviceListBody" ref={this.setScrollRef}>
              {this.getSeviceList(actionType, serviceList, loadPaginatedData)}
            </ServiceListBody>

            {this.shouldOpenDetailPanel() && (
              <ServiceDetailWrapper
                selectedRow={this.props.assignedServiceDetail}
                t={this.props.t}
                serviceStatus={serviceStatus}
                distanceUnitValue={distanceUnitValue}
              />
            )}
          </ListBody>
        </ListContainer>
      );
    } else {
      return (
        <ListContainer modalFooter={24}>
          {this.props.showNoRecordFoundView ? (
            <DetailsPanel>
              <NoRecord
                searchString={this.props.searchString}
                objectType={"actionBar:SERVICE"}
                resetHandler={this.props.resetHandler}
              />
            </DetailsPanel>
          ) : (
            <ServiceListBody>
              <DetailsPanel>
                <EmptyList
                  name={IconName.Assign}
                  emptyListTitle={
                    moduleName === ModuleName.asset
                      ? t("services:MANAGE_SERVICES_EMPTY_VIEW", { name: "" })
                      : t("services:MANAGE_SERVICES_EMPTY_VIEW", {
                          name: "template",
                        })
                  }
                  emptyList={[
                    {
                      component: AssignServiceComponent,
                      props: {
                        actionType,
                        allAssignedService,
                        assetFieldsData,
                        assetId,
                        assignHistoryService,
                        assignServiceSuccess,
                        assignedServicesList,
                        clearAssignedServiceList,
                        clearServiceData,
                        clearServiceTemplateDetails,
                        completionDate,
                        costCurrency,
                        currenciesList,
                        defaultCurrency,
                        getAssignServicesList,
                        getCurrenciesList,
                        getServiceTemplateDetails,
                        getServicesList,
                        handleServiceList,
                        isServiceStatus,
                        moduleName,
                        onFormchange,
                        scheduleDate,
                        serviceActionType: this.state.serviceActionType,
                        serviceOperationError,
                        serviceStatus,
                        serviceTemplateDetails,
                        serviceValue,
                        setServiceActionType: this.setServiceActionType,
                        showToaster,
                        submitForm,
                        distanceUnitValue,
                      },
                    },
                  ]}
                />
              </DetailsPanel>
            </ServiceListBody>
          )}
        </ListContainer>
      );
    }
  };

  getServiceList() {
    const { actionType, serviceList } = this.props;
    return {
      ...serviceList,
      list: this.filterList(actionType, serviceList),
    };
  }

  /*
    using callback ref to set the DOM reference
  */
  setScrollRef = (ele) => {
    this.listScrollRef = ele;
  };

  render() {
    const { serviceList } = this.props;
    return this.renderPaginatedRows(serviceList.list);
  }
}

/**
 * @exports RoleListView - Exporting component "LabelList"
 */
export default withTranslation()(ServiceListView);
