import React from "react";
import styled from "styled-components";
import Input from "../Input/input";
import { ISelectProps } from "../Select/iSelectProps";
import Select from "../Select/select";

const Wrapper = styled.div`
  background: #eee;
  padding: 20px;
  margin: 20px;
`;

interface IAddableSelectState {
  newValue: string;
}

interface IAddableSelectProps extends ISelectProps {
  onCreate: (createdOption: string) => void;
  onKeyPress: (createdOption: string) => void;
}

class AddableSelect extends React.Component<IAddableSelectProps, IAddableSelectState> {
  state = {
    newValue: "",
  };

  inputRef: React.RefObject<any>;

  constructor(props: IAddableSelectProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault();

      const element = event.target as HTMLInputElement;

      this.props.onCreate(element.value);
      this.setState({ newValue: "" });

      if (this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  render() {
    const { value, name, label, onChange, options, error, id } = this.props;
    const { newValue } = this.state;

    return (
      <Wrapper>
        <Input
          name={name}
          label={label}
          value={newValue}
          placeholder="Add new"
          className="addNew"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ newValue: event.target.value })}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => this.onKeyPress(event)}
          required={true}
        />
        <Select
          id={id}
          name={name}
          className="addableSelect"
          value={value}
          onChange={(event) => onChange(event)}
          ref={this.inputRef}
          options={options}
          error={error}
        />
      </Wrapper>
    );
  }
}

export default AddableSelect;
