const ACTION_BAR_TEXT_MAX_LENGTH = 12;
/**
 * @description Function returns the label text if more than max length
 * @param {lableText} - l
 */
export const actionBarLabelText = (labelText: string, maxLength = ACTION_BAR_TEXT_MAX_LENGTH) => {
  return labelText.length > maxLength ? `${labelText.substring(0, maxLength)}...` : labelText;
};

export const handleActionItemDisable = (items, actionitems, isDisable) => {
  return items.filter((item) => {
    if (actionitems.includes(item.name)) {
      item.isDisable = !isDisable;
    }

    return item;
  });
};

export const tooltipDependencyResolver = (actionItem, dependencies) => {
  if (actionItem.dependencies && actionItem.dependencies.toolTip && dependencies) {
    const tooltipCallBack = dependencies[actionItem.dependencies.toolTip];
    return tooltipCallBack ? tooltipCallBack() : "";
  }
  return "";
};

export const disableDependencyResolver = (actionItem, dependencies) => {
  if (actionItem.dependencies && actionItem.dependencies.disable && dependencies) {
    const disableCallBack = dependencies[actionItem.dependencies.disable];
    return disableCallBack ? disableCallBack() : actionItem.isDisable;
  }
  return actionItem.isDisable;
};

export const visibleDependencyResolver = (actionItem, dependencies) => {
  if (actionItem.dependencies && actionItem.dependencies.visible && dependencies) {
    const visibleCallBack = dependencies[actionItem.dependencies.visible];
    return visibleCallBack ? visibleCallBack() : actionItem.isVisible;
  }
  return actionItem.isVisible;
};

export const authKeyDependencyResolver = (actionItem, dependencies) => {
  if (actionItem.dependencies && actionItem.dependencies.permissionKey && dependencies) {
    return dependencies[actionItem.dependencies.permissionKey]();
  }
  return actionItem.authKey;
};
