import React from "react";
import { Field } from "redux-form";
import ISelectAutoCompleteProps from "./iSelectAutoCompleteProps";
import { AutoCompleteComponent } from "./selectAutoComplete";

class FormTextArea extends React.PureComponent<ISelectAutoCompleteProps> {
  render() {
    return (
      <Field
        name={this.props.name}
        label={this.props.label}
        component={AutoCompleteComponent}
        validate={this.props.validation}
        {...this.props}
      />
    );
  }
}

export default FormTextArea;
