import { LocaleManager, AuthorizationCore, HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import {
  CheckInventoryActions,
  CompanyActions,
  TransferedAssetSettingsActions,
  UniteActions,
  // @ts-ignore
} from "custom-actions";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { storageSetItem } from "../../../utils/storageUtils";
import { HiltiAssetStatusActions, FieldRequestConstants } from "../../../actionConstants";
import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../../actions/toastrAction";
import { getCheckInventorySettings, updateCheckInventorySettings } from "../../../api/checkInventoryApi";
import { getHiltiAssetStatus, updateHiltiAssetStatus } from "../../../api/hiltiAssetStatusApi";
import CompanyApi from "../../../api/companyApi";
import { getTransferSettingsApi, updateTransferSettingsApi } from "../../../api/deletionSettingsApi";
import {
  getTransferConfirmationSettingsApi,
  updateTransferConfirmationSettingsApi,
} from "../../../api/transferConfirmationSettingsApi";
import { validateResponse } from "../../../utils/errorUtils";
import { call } from "../../../sagas/common/commonSaga";
import ModuleName from "../../../utils/enums/moduleNameEnum";
import { getFieldRequestSettingsApi, updateFieldRequestSettingsApi } from "../../../api/fieldRequestApi";
import HiltiCloudApi from "../../../api/hiltiCloudApi";
import {
  trackCompanySettingsPolicy,
  trackSavedOptions,
  trackVanPrivateModeSetting,
} from "../../../../analytics/events/company";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import featureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { IPrivateModeSettingRequest } from "./iCompanyModuleState";

const savedSuccessMsg = "common:SAVED_SUCCESSFULLY";
/**
 * Saga for get company detail.
 */
export function* fetchCompanyDetail() {
  yield takeLatest(CompanyActions.OM_GET_COMPANY_DETAILS, getCompanyDetail);
}

export function* editCompanyDetailsSaga() {
  yield takeLatest(CompanyActions.OM_EDIT_COMPANY_DETAILS, editCompanyDetails);
}

/**
 * get company detail.
 */
function* getCompanyDetail() {
  const response = yield call(() => {
    return CompanyApi.getCompanyDetail();
  });

  if (!response.error) {
    const timeZone = response.timeZone && response.timeZone.code;
    if (timeZone) {
      LocaleManager.dateHelpers.setDefaultTimeZone(timeZone);
    }

    const symbol = (response.currency && response.currency.code) || "";

    LocaleManager.initCompanyData(
      {
        baseFormat: "##",
        decimalSeprator: response.monetaryDecimalSeparator,
        symbol,
      },
      {
        baseFormat: "##",
        decimalSeprator: response.decimalSeparator,
      },
    );

    yield put({
      payload: response,
      type: CompanyActions.OM_GET_COMPANY_DETAILS_SUCCESS,
    });

    AuthorizationCore.setupActiveModule(response.activeModules || []);
    if (response?.activeModules?.includes(ModuleName.UNITE)) {
      yield put({
        type: UniteActions.GET_MANAGED_FIELDS,
      });
    }

    if (response?.tenantCategory) {
      storageSetItem(appConstants.TENANT_CATEGORY, response?.tenantCategory);
    }

    if (response?.moduleFeatures) {
      storageSetItem(appConstants.MODULE_FEATURES, JSON.stringify(response?.moduleFeatures));
    }
  }
}

/**
 * @description the function to edit company details.
 *
 * @export
 * @param action - the action object
 */
function* editCompanyDetails(action) {
  const response = yield call(() => {
    return CompanyApi.editCompanyDetails(action.item);
  });
  if (!response.error) {
    yield put({
      isRequestInitiated: false,
      payload: true,
      type: CompanyActions.OM_EDIT_COMPANY_DETAILS_SUCCESS,
    });
  } else {
    yield put({
      isRequestInitiated: false,
      payload: false,
      type: CompanyActions.OM_EDIT_COMPANY_DETAILS_SUCCESS,
    });
    yield put(showToastr(ToastrType.error, `errorCodes:${response.error.response.data.errors[0].code}`));
  }
}

export function* fetchLicenseAndUsageDetail() {
  yield takeLatest(CompanyActions.OM_GET_LICENSE_AND_USAGE_DETAILS, getLicenseAndUsageDetail);
}
/**
 * get License And Usage detail.
 */
function* getLicenseAndUsageDetail(action) {
  const response = yield call(() => {
    return CompanyApi.getLicenseAndUsageDetail(action.includeCount);
  });
  if (!response.error) {
    yield put({
      payload: response,
      type: CompanyActions.GET_LICENSE_AND_USAGE_DETAILS_SUCCESS,
    });
  }
}

export function* fetchLicenseAndUsageDetailsSaga() {
  yield takeLatest(CompanyActions.AM_GET_LICENSE_AND_USAGE_DETAILS, getLicenseAndUsageDetailsCount);
}
/**
 * get License And Usage detail.
 */
function* getLicenseAndUsageDetailsCount() {
  const response = yield call(CompanyApi.getLicenseAndUsageDetailsCount);
  if (!response.error) {
    yield put({
      payload: response,
      type: CompanyActions.GET_LICENSE_AND_USAGE_DETAILS_SUCCESS_COUNT,
    });
  } else {
    yield put(showToastr(ToastrType.error, "common:ERROR_SOMETHING_WENT_WRONG"));
  }
}

/**
 * Saga to fetch Check inventory settings.
 */
export function* getCheckInventorySettingSaga() {
  yield takeLatest(CheckInventoryActions.GET_CHECK_INVENTORY_SETTINGS, getInventorySettings);
}

/**
 * Function to call get Check inventory Api and to call appropriate success/failure action accordingly.
 */
function* getInventorySettings() {
  const response = yield call(() => {
    return getCheckInventorySettings();
  });
  if (!response.error) {
    yield put({
      payload: response.isEnabled,
      type: CheckInventoryActions.GET_CHECK_INVENTORY_SETTINGS_SUCCESS,
    });
  }
}

export function* updatePrivateModeSettingSaga() {
  yield takeLatest(CompanyActions.UPDATE_PRIVATE_MODE_SETTINGS, updatePrivateSetting);
}

export function* updatePrivateSetting(action: { payload: IPrivateModeSettingRequest; type: string }) {
  const additionalSettingValues = Object.entries(action?.payload).map(([k, v]) => ({
    name: k,
    value: v,
  }));
  const response = yield call(CompanyApi.updateAdditionalSettings, additionalSettingValues);
  const errorResponse = validateResponse(response);
  if (errorResponse && !response.isSystemError) {
    yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
  } else {
    yield put({ type: CompanyActions.UPDATE_PRIVATE_MODE_SETTINGS_SUCCESS, payload: action?.payload });
    yield put(showToastr(ToastrType.success, savedSuccessMsg));
    yield trackVanPrivateModeSetting(additionalSettingValues);
  }
}

export function* updateCheckInventorySettingSaga() {
  yield takeLatest(CheckInventoryActions.UPDATE_CHECK_INVENTORY_SETTINGS, updateInventorySetting);
}

/**
 * Function to call update check Inventory Api and to call appropriate success/failure action accordingly.
 */
export function* updateInventorySetting(action) {
  const response = yield call(() => {
    return updateCheckInventorySettings(action.updatedData);
  });
  if (response && !response.error) {
    yield put({ type: CheckInventoryActions.UPDATE_CHECK_INVENTORY_SUCCESS, payload: action.updatedData });
  }
}

/**
 * Saga to fetch hilti asset status.
 */
export function* getHiltiAssetStatusSettingSaga() {
  yield takeLatest(HiltiAssetStatusActions.GET_HILTI_ASSET_STATUS_SETTINGS, getHiltiAssetStatusSettings);
}

/**
 * Function to call get hilti asset status Api and to call appropriate success/failure action accordingly.
 */
function* getHiltiAssetStatusSettings() {
  const response = yield call(() => {
    return getHiltiAssetStatus();
  });
  if (!response.error) {
    yield put({
      payload: response.isEnabled,
      type: HiltiAssetStatusActions.GET_HILTI_ASSET_STATUS_SETTINGS_SUCCESS,
    });
  }
}

export function* updateHiltiAssetStatusSettingSaga() {
  yield takeLatest(HiltiAssetStatusActions.UPDATE_HILTI_ASSET_STATUS_SETTINGS, updateHiltiAssetStatusSetting);
}

/**
 * Function to call update hilti asset status Api and to call appropriate success/failure action accordingly.
 */
export function* updateHiltiAssetStatusSetting(action) {
  const response = yield call(() => {
    return updateHiltiAssetStatus(action.updatedData);
  });
  if (response && !response.error) {
    yield put({ type: HiltiAssetStatusActions.UPDATE_HILTI_ASSET_STATUS_SUCCESS, payload: action.updatedData });
  }
}

/**
 * Saga to fetch Deletion settings.
 */
export function* getDeletionSettingSaga() {
  yield takeLatest(TransferedAssetSettingsActions.GET_TRANSFER_SETTINGS, getTransferSettings);
}

/**
 * Function to call get Deletion settings Api and to call appropriate success/failure action accordingly.
 */
function* getTransferSettings() {
  const response = yield call(() => {
    return getTransferSettingsApi();
  });
  if (response && !response.error) {
    yield put({
      payload: response.isEnabled,
      type: TransferedAssetSettingsActions.GET_TRANSFER_SETTINGS_SUCCESS,
    });
  }
}

export function* updateDeletionSettingSaga() {
  yield takeLatest(TransferedAssetSettingsActions.UPDATE_TRANSFER_SETTINGS, updateDeletionSetting);
}

export function* updateDeletionSetting(action) {
  const response = yield call(() => {
    return updateTransferSettingsApi(action.updatedData);
  });
  if (!response) {
    yield put({
      payload: action.updatedData,
      type: TransferedAssetSettingsActions.UPDATE_TRANSFER_SETTINGS_SUCCESS,
    });
    yield put(showToastr(ToastrType.success, savedSuccessMsg));
  }
}

export function* fetchTransferConfirmationSettings() {
  yield takeLatest(CompanyActions.OM_GET_TRANSFER_CONFIRMATION_SETTINGS, getTransferConfirmationSettings);
}

function* getTransferConfirmationSettings() {
  const response = yield call(() => {
    return getTransferConfirmationSettingsApi();
  });
  const errorResponse = validateResponse(response);
  if (errorResponse && !response.isSystemError) {
    yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
  } else {
    yield put({
      payload: response,
      type: CompanyActions.OM_GET_TRANSFER_CONFIRMATION_SETTINGS_SUCCESS,
    });
  }
}

export function* updateTransferConfirmationSettingsSaga() {
  yield takeLatest(CompanyActions.OM_UPDATE_TRANSFER_CONFIRMATION_SETTINGS, updateTransferConfirmationSettings);
}

function* updateTransferConfirmationSettings(action) {
  const additionalSettingValues = HelperLodash.getObjectKeys(action.additionalSettings).map((key) => ({
    name: key,
    value: action.additionalSettings[key],
  }));
  const transferSettingsResponse = yield call(updateTransferConfirmationSettingsApi, {
    transferConfirmationPolicy: action.newPolicyValue,
    isDeliveryNoteEmailEnabled: action.deliveryNoteSetting,
  });
  let additionalSettingsResponse = {};
  if (featureManager.isFeatureEnabled(FEATURES.PAT_SUGGESTED_TRANSFERS)) {
    additionalSettingsResponse = yield call(CompanyApi.updateAdditionalSettings, additionalSettingValues);
  }
  const transferSettingsErrorResponse = validateResponse(transferSettingsResponse);
  const isAditionalSettingsError = validateResponse(additionalSettingsResponse);
  if (transferSettingsErrorResponse || isAditionalSettingsError) {
    yield put(
      showToastr(ToastrType.error, `errorCodes:${(transferSettingsErrorResponse || isAditionalSettingsError).code}`),
    );
  } else {
    yield put({
      type: CompanyActions.OM_GET_TRANSFER_CONFIRMATION_SETTINGS,
    });
    yield put({
      type: CompanyActions.OM_GET_ADDITIONAL_SETTINGS,
    });
    yield trackCompanySettingsPolicy(action.newPolicyValue, action.deliveryNoteSetting);
    yield put(showToastr(ToastrType.success, savedSuccessMsg));
    yield trackSavedOptions(additionalSettingValues);
  }
}

/**
 * Saga to fetch Field Request settings.
 */
export function* getFieldRequestSettingSaga() {
  yield takeLatest(FieldRequestConstants.OM_GET_FIELD_REQUEST_SETTINGS, getFieldRequestSettings);
}

/**
 * Function to call get Field Request Api and to call appropriate success/failure action accordingly.
 */
function* getFieldRequestSettings() {
  const response = yield call(() => {
    return getFieldRequestSettingsApi();
  });
  const errorResponse = validateResponse(response);
  if (errorResponse) {
    yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
  } else {
    yield put({
      payload: { isEnabled: response.isEnabled },
      type: FieldRequestConstants.GET_FIELD_REQUEST_SETTINGS_SUCCESS,
    });
  }
}

export function* updateFieldRequestSettingSaga() {
  yield takeLatest(FieldRequestConstants.OM_UPDATE_FIELD_REQUEST_SETTINGS, updateFieldRequestSetting);
}

/**
 * Function to call update field request Api and to act on success/failure action accordingly.
 */
export function* updateFieldRequestSetting(action) {
  const response = yield call(() => {
    return updateFieldRequestSettingsApi(action.updatedData);
  });
  const errorResponse = validateResponse(response);
  if (errorResponse) {
    yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
  } else {
    yield put({ type: FieldRequestConstants.UPDATE_FIELD_REQUEST_SETTINGS_SUCCESS, payload: action.updatedData });
    yield put(showToastr(ToastrType.success, savedSuccessMsg));
    if (action.updatedData.isEnabled) {
      yield put({ type: FieldRequestConstants.AM_GET_FIELD_REQUESTS_COUNT });
    }
  }
}

export function* getTechnicalUserSaga() {
  yield takeEvery(CompanyActions.GET_TECHNICAL_USER, getTechnicalUser);
}

export function* getTechnicalUsersSaga() {
  yield takeEvery(CompanyActions.GET_TECHNICAL_USERS, getTechnicalUsers);
}

export function* getTechnicalUserPasswordSaga() {
  yield takeEvery(CompanyActions.GET_TECHNICAL_USER_PASSWORD, getTechnicalUserPassword);
}

function* getTechnicalUser() {
  const response = yield call(() => CompanyApi.getTechnicalUser());
  if (response && !response.error) {
    yield put({ type: CompanyActions.GET_TECHNICAL_USER_SUCCESS, payload: response });
  } else {
    yield put(showToastr(ToastrType.error, "common:TECHNICAL_USER_IS_NOT_ONBOARDED"));
  }
}

function* getTechnicalUsers() {
  const response = yield call(() => CompanyApi.getTechnicalUsers());
  if (response && !response.error && response.technicalUsers.length > 0) {
    yield put({ type: CompanyActions.GET_TECHNICAL_USERS_SUCCESS, payload: response });
  } else {
    yield put(showToastr(ToastrType.error, "common:TECHNICAL_USER_IS_NOT_ONBOARDED"));
  }
}

function* getTechnicalUserPassword({ payload }) {
  const response = yield call(() => CompanyApi.getTechnicalUserPassword(payload));
  if (response && !response.error) {
    yield put({
      type: CompanyActions.GET_TECHNICAL_USER_PASSWORD_SUCCESS,
      payload: {
        password: response.password,
        employeeId: payload,
      },
    });
    yield put(showToastr(ToastrType.success, "common:CONFIRM_TECHNICAL_USER_PASSWORD_RESET"));
  } else {
    yield put(showToastr(ToastrType.error, "common:PLEASE_TRY_LATER"));
  }
}

export function* resetTechnicalUserPasswordSaga() {
  yield takeEvery(CompanyActions.RESET_TECHNICAL_USER_PASSWORD, resetTechnicalUserPassword);
}

function* resetTechnicalUserPassword() {
  const response = yield call(CompanyApi.resetTechnicalUserPassword);
  if (response && !response.error) {
    yield put({ type: CompanyActions.RESET_TECHNICAL_USER_PASSWORD_SUCCESS, payload: response });
    yield put(showToastr(ToastrType.success, "common:CONFIRM_TECHNICAL_USER_PASSWORD_RESET"));
  } else {
    yield put(showToastr(ToastrType.error, "common:PLEASE_TRY_LATER"));
  }
}

export function* getAdditionalDetailsSaga() {
  yield takeLatest(CompanyActions.OM_GET_ADDITIONAL_SETTINGS, getAdditionalDetails);
}

function* getAdditionalDetails() {
  const response = yield call(CompanyApi.getAdditionalSettings);
  if (!response.error) {
    yield put({
      payload: response,
      type: CompanyActions.OM_SET_ADDITIONAL_SETTINGS,
    });
  } else {
    yield put(showToastr(ToastrType.error, "common:ERROR_SOMETHING_WENT_WRONG"));
  }
}

function* composeCompanySaga() {
  yield all([
    fetchCompanyDetail(),
    editCompanyDetailsSaga(),
    fetchLicenseAndUsageDetail(),
    getCheckInventorySettingSaga(),
    updateCheckInventorySettingSaga(),
    getHiltiAssetStatusSettingSaga(),
    updateHiltiAssetStatusSettingSaga(),
    getDeletionSettingSaga(),
    updateDeletionSettingSaga(),
    fetchTransferConfirmationSettings(),
    updateTransferConfirmationSettingsSaga(),
    getFieldRequestSettingSaga(),
    updateFieldRequestSettingSaga(),
    getTechnicalUserSaga(),
    getTechnicalUsersSaga(),
    getTechnicalUserPasswordSaga(),
    resetTechnicalUserPasswordSaga(),
    updatePrivateModeSettingSaga(),
    fetchLicenseAndUsageDetailsSaga(),
    getAdditionalDetailsSaga(),
  ]);
}

export default composeCompanySaga;
