import React from "react";
const SVG = () => (
  <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
    <title>3006C6DB-5E0B-4665-8E0F-99C2D8CDD9DC</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Web---Screen-5" transform="translate(-496.000000, -386.000000)">
        <g id="HE" transform="translate(496.000000, 386.000000)">
          <g id="Image">
            <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="54" height="54"></rect>
            <g id="🧰-Image-paceholder" transform="translate(8.000000, 23.000000)" fill="#757275">
              <path
                d="M17.25,0 L19.372,9.364 L33.5416667,1.87878788 L35.4583333,4.6969697 L39.139,17.817 L39.1690304,17.8105802 L37.2902481,24.0786689 L33.5707811,24.9877087 L26.6005298,20.8823289 L35.791,18.636 L32.5833333,8.45454545 L21.0833333,16.9090909 L17.25,16.9090909 L17.25,18.7878788 L20.125,18.7878788 L20.2939281,18.7926628 C21.8031184,18.8783792 23,20.1052089 23,21.6060606 C23,23.1624994 21.7128187,24.4242424 20.125,24.4242424 L2.875,24.4242424 C1.28718134,24.4242424 0,23.1624994 0,21.6060606 C0,20.0496218 1.28718134,18.7878788 2.875,18.7878788 L5.75,18.7878788 L5.75,16.9090909 L0,16.9090909 L0,7.51515152 L5.75,7.51515152 L5.75,0 L17.25,0 Z M2.875,20.6666667 C2.34572711,20.6666667 1.91666667,21.0872477 1.91666667,21.6060606 C1.91666667,22.1248736 2.34572711,22.5454545 2.875,22.5454545 C3.40427289,22.5454545 3.83333333,22.1248736 3.83333333,21.6060606 C3.83333333,21.0872477 3.40427289,20.6666667 2.875,20.6666667 Z M20.125,20.6666667 C19.5957271,20.6666667 19.1666667,21.0872477 19.1666667,21.6060606 C19.1666667,22.1248736 19.5957271,22.5454545 20.125,22.5454545 C20.6542729,22.5454545 21.0833333,22.1248736 21.0833333,21.6060606 C21.0833333,21.0872477 20.6542729,20.6666667 20.125,20.6666667 Z M14.375,3.75757576 L9.58333333,3.75757576 L9.58333333,7.51515152 L15.3333333,7.51515152 L14.375,3.75757576 Z"
                id="🎨Color"
              ></path>
            </g>
          </g>
          <g id="wifi_signal" transform="translate(15.428571, 0.000000)">
            <path
              d="M0.964285714,8.67857143 L2.89285714,10.6071429 C7.68535714,5.81464286 15.4575,5.81464286 20.25,10.6071429 L22.1785714,8.67857143 C16.3253571,2.82535714 6.82714286,2.82535714 0.964285714,8.67857143 Z M8.67857143,16.3928571 L11.5714286,19.2857143 L14.4642857,16.3928571 C12.8732143,14.7921429 10.2792857,14.7921429 8.67857143,16.3928571 Z M4.82142857,12.5357143 L6.75,14.4642857 C9.41142857,11.8028571 13.7314286,11.8028571 16.3928571,14.4642857 L18.3214286,12.5357143 C14.5992857,8.81357143 8.55321429,8.81357143 4.82142857,12.5357143 Z"
              id="🎨Color"
              fill="#757275"
            ></path>
            <rect id="Rectangle" opacity="0" x="0" y="0" width="23.1428571" height="23.1428571"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
