// @ts-ignore
import Grid from "@mui/material/Grid";
import { FormPrompt } from "am-web-ui-shared/components";
import { Form, TextArea } from "am-web-ui-shared/elements";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import { ValidationConstants } from "../../../utils/enums/validationConstants";
import { ICancelTransferViewFormState, ICancelTransferViewProps } from "../model/iCancelTransferView";
/**
 * Form Body for the component.
 */
const FormBody = styled(Grid)`
  margin: 16px 0 32px 0;
  && textarea {
    width: 100%;
    height: 100px;
    border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete60};
  }
`;

/**
 * Hint Wrapper for the component.
 */
const HintWrapper = styled.div`
  color: ${(props) => (props.isValid ? props.theme.colors.base.steel : props.theme.colors.base.hiltiRedError)};
  font-family: ${(props) => (props.isValid ? props.theme.fontRoman.fontFamily : props.theme.fontBold.fontFamily)};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 300;
  line-height: 14px;
`;

/**
 * Class to render cancle transfer form.
 *
 * @class CancelTransferViewForm
 * @extends {React.Component<ICancelTransferView>}
 */
export class CancelTransferViewForm extends React.Component<ICancelTransferViewProps, ICancelTransferViewFormState> {
  state = {
    cancelNote: "",
    errorMessage: "",
    errorOnSubmit: false,
    isValid: true,
    saveButtonDisabled: true,
  };

  /**
   * @description - Method trigger on view name change.
   * @param e - event target value.
   */
  onChange = (e) => {
    const value = e.target.value;
    let errorMessage = "";
    const isError = value.length > ValidationConstants.MAX_LENGTH.NUM_2048;
    if (isError) {
      errorMessage = "common:VALIDATION_MAXIMUM_OF_2048_CHARACTERS_ALLOWED";
    }
    this.setState({
      cancelNote: value,
      errorMessage,
      isValid: !isError,
    });
  };

  /**
   * @description - Method trigger on cancel transfer
   */
  onSaveClick = () => {
    if (this.state.isValid) {
      this.props.handleSave(this.state.cancelNote);
      this.props.handleCancel("close");
    }
  };
  /**
   * @description - Method to render Cancel transfer view button
   */
  renderForm = () => {
    const { handleCancel, t } = this.props;
    return (
      <FormPrompt
        id="CancelTransferViewForm"
        handleClose={handleCancel}
        handleSave={this.onSaveClick}
        header={t("assets:CANCEL_TRANSFER")}
        cancelButtonText={t("common:CLOSE")}
        saveButtonText={t("assets:CANCEL_TRANSFER")}
        title={t("common:CANCEL_TRANSFER_TITLE")}
        saveButtonDisabled={!this.state.isValid}
      >
        {this.getCancelTransferForm(t)}
      </FormPrompt>
    );
  };

  /**
   * @description - Method to Create Cancel Transfer Form
   */
  getCancelTransferForm = (t) => {
    return (
      <Form form="CancelTransferViewForm" enableReinitialize={true}>
        <FormBody>
          <TextArea
            name="cancelationNote"
            value={""}
            maxLength={ValidationConstants.MAX_LENGTH.NUM_2048}
            isFormControl={true}
            label={t("common:CANCEL_TRANSFER_LABEL")}
            htmlFor={this.props.name}
            placeholder={t("common:CANCEL_TRANSFER_PLACEHOLDER")}
            onChange={this.onChange}
          />
          {
            <HintWrapper id="viewNameErrorMessage" isValid={this.state.isValid}>
              {t(this.state.errorMessage)}
            </HintWrapper>
          }
        </FormBody>
      </Form>
    );
  };

  render() {
    return this.renderForm();
  }
}

export default withTranslation()(CancelTransferViewForm);
