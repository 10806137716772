import { IconName } from "am-web-ui-shared/components";
import React from "react";
import { Trans } from "react-i18next";
import { IconBold, IconNormalBold } from "../../../../../components/DetailsPanel/index";

/**
 * @description - Heading of the ownership label.
 */
export const ownershipType = () => {
  return (
    <IconBold
      id="pnlOwnership"
      iconUrl={IconName.Category}
      boldId="lblOwnership"
      boldText={<Trans>{"common:OWNERSHIP"}</Trans>}
    />
  );
};

/**
 * @description - ownership code.
 *
 * @param {object} data - Asset details object.
 */
export const ownershipCode = (data) => {
  const ownershipTypeValue = data && data.ownerShipTypeResponse && data.ownerShipTypeResponse.value;
  return (
    ownershipTypeValue && (
      <IconNormalBold
        id="pnlOwnershipType"
        normalId="lblOwnershipType"
        normalText={<Trans>{"assets:OWNERSHIP_TYPE"}</Trans>}
        boldId="txtOwnershipType"
        boldText={`${ownershipTypeValue}`}
      />
    )
  );
};
