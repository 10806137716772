import styled from "styled-components";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const ButtonIconWrapper = styled.div`
  float: left;
  margin-right: 10px;
  margin-top: 4px;
  &.white-icon path {
    fill: ${(props) => props.theme.colors.base.white};
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  margin-left: 16px;
`;

export const HeaderFirstRow = styled.div`
  font-weight: Bold;
  font-size: 14px;
  font-family: Hilti-Bold;
  line-height: 18px;
  text-align: center;
`;

export const HeaderSecondRow = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const CheckmarkContainer = styled.div`
  height: 35px;
  color: ${(props) => props.theme.colors.base.green};
  text-align: center;
  .checkmark {
    position: relative;
    top: 2px;
    path {
      fill: ${(props) => props.theme.colors.base.green};
    }
  }
`;

export const Text = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  top: -10px;
`;

export const EmptyRow = styled.div`
  width: 5px;
  height: 2px;
  position: relative;
  left: 50%;
  border-top: 2px solid ${(props) => props.theme.colors.base.steel};
`;

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.colors.brown[200],
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  height: 60,
  [`&.${tableCellClasses.head}`]: {
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "&.first-cell": {
    width: `calc(100vw - ${theme.spacing(10)})`,
  },
  "&.second-cell": {
    borderLeft: "2px solid",
    borderLeftColor: theme.colors.brown[200],
    width: 200,
    textAlign: "center",
  },
}));
