import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import IErrorBoundaryState from "./iErrorBoundaryState";

class ErrorBoundary extends React.Component<WithTranslation, IErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  /**
   * @description Function to catch error generated by child component
   * @memberof errorBoundary
   * @param {String} - error
   * @param {Object} - errorInfo
   */

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { t } = this.props;
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>{t("common:ERROR_SOMETHING_WENT_WRONG")}</h2>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
