import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="tool_return" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        d="M18.2941176,16 L18.294,17.399 L23,17.4 L23,23 L16.4117647,23 L16.4117647,21.6 L21.5882353,21.6 L21.5882353,18.8 L18.294,18.799 L18.2941176,20.2 L15,18.1 L18.2941176,16 Z M18.3050847,14.2033898 L18.305,14.585 L12.7932868,18.1 L13.063,18.271 L10.1694915,18.2711864 L10.1694915,14.2033898 L18.3050847,14.2033898 Z M16.779661,2 L18.3050847,3.52542373 L18.3050847,7.08474576 L17.4152542,8.10169492 L15.777,8.101 L16.779661,13.1864407 L12.2298041,13.1864407 L11.084,8.101 L7.62711864,8.10169492 L4.06779661,7.08474576 L4.06779661,3.01694915 L7.62711864,2 L16.779661,2 Z M3.05084746,4.03389831 L3.05084746,6.06779661 L1.26121336e-13,6.06779661 L1.26121336e-13,4.03389831 L3.05084746,4.03389831 Z"
        id="🎨Color"
        fill="#524F53"
      />
      <rect id="Rectangle" opacity={0} x={0} y={0} width={24} height={24} />
    </g>
  </svg>
);

export default SVG;
