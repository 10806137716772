/**
 * @description Action constants to be used for loader.
 */
const LoaderActionConstants = {
  SET_DETAIL_LOADER: "SET_DETAIL_LOADER",
  SET_DIALOG_DETAIL_LOADER: "SET_DIALOG_DETAIL_LOADER",
  SET_EXPANTION_PANEL_DETAIL_LOADER: "SET_EXPANTION_PANEL_DETAIL_LOADER",
  SET_FILTER_LOADER: "SET_FILTER_LOADER",
  SET_LIST_LOADER: "SET_LIST_LOADER",
  UNSET_DETAIL_LOADER: "UNSET_DETAIL_LOADER",
  UNSET_DIALOG_DETAIL_LOADER: "UNSET_DIALOG_DETAIL_LOADER",
  UNSET_EXPANTION_PANEL_DETAIL_LOADER: "UNSET_EXPANTION_PANEL_DETAIL_LOADER",
  UNSET_FILTER_LOADER: "UNSET_FILTER_LOADER",
  UNSET_LIST_LOADER: "UNSET_LIST_LOADER",
};

export default LoaderActionConstants;
