import { ActionBar, DialogPosition } from "am-web-ui-shared/components";
import { CheckAuthorization } from "am-web-ui-shared/helpers";
import React from "react";
import { withTranslation } from "react-i18next";
import { TParam } from "../../../../localization/types";
import { getOffset } from "../../../utils/commonUtils";
import { ActionItem } from "../../../utils/enums/actionItemEnum";
import { StatusCode } from "../../../utils/enums/assetEnum";
import { Remove } from "../actionItems/remove";
import { IServicesSummaryProps, IServicesSummaryState } from "../model/iServicesSummaryProps";
import AssignServiceComponent from "./assignService";
import { ServicesSummarySortComponent } from "./sort/servicesSummarySortComponent";
import { ServiceStatusStates } from "../model/iserviceList";
import withLicenseContext from "TARGET_BUILD/licenseStrategy/withLicenseContext";

class ServicesSummary extends React.Component<IServicesSummaryProps, IServicesSummaryState> {
  state = {
    handleRemoveConfirm: null,
    leftPosition: 0,
    serviceActionType: null,
    showRemoveModal: false,
    showSortingModal: false,
    topPosition: 0,
  };

  onActionClicked = (actionName, e) => {
    switch (actionName) {
      case ActionItem.sort:
        const leftPosition = -176;
        const topPosition = 72;
        const positionObj = getOffset(e, leftPosition, topPosition);

        this.toggleSort();
        this.setState({
          leftPosition: positionObj.leftPosition,
          topPosition: positionObj.topPosition,
        });
        break;
      case ActionItem.assign:
      case ActionItem.edit:
      case ActionItem.complete:
        this.setState(
          {
            serviceActionType: actionName,
          },
          () => this.props.showServiceForm(true),
        );
        break;
      case ActionItem.remove:
        this.setState({
          showRemoveModal: true,
        });
        break;
      default:
    }
  };

  toggleSort = () => {
    this.props.closeMoreFilter();
    this.setState({ showSortingModal: !this.state.showSortingModal });
  };

  handleServiceList = (value) => {
    this.props.handleServiceList(value);
    this.setState({
      serviceActionType: null,
    });
  };

  handleFormClose = () => {
    this.setState({
      serviceActionType: null,
    });
  };
  closeMenu = () => {
    this.setState({ showSortingModal: false });
  };
  fetchList = () => {
    this.props.getServicesList(this.props.serviceStatus);
  };

  disableItem = () => {
    const { actionType, assignedServiceDetail, assetFieldsData } = this.props;
    return !(
      assignedServiceDetail &&
      actionType === ActionItem.edit &&
      !this.assetStatusResponseValue(assetFieldsData)
    );
  };

  /*
   * Method for render tooltip text
   */
  renderTooltip = (messageKey: TParam, initialMessage: TParam) => {
    const { assetFieldsData, t, selectedServiceInfo } = this.props;
    if (selectedServiceInfo && this.assetStatusResponseValue(assetFieldsData)) {
      return t(messageKey);
    }
    return t(initialMessage);
  };

  /*
   * Method for Complete Button Default tolltip if Service status is not operational
   */
  completeDefaultTooltip = () => this.renderTooltip("services:COMPLETE_DISABLE", "services:COMPLETE");

  /*
   * Method for edit buttton tolltip if Service status is not operational and no service select
   */
  editTooltip = () => this.renderTooltip("services:EDIT_DISABLE", "common:EDIT");

  /*
   * Method for Remove buttton tolltip if Service status is not operational and no service select and a Hilti tool
   */
  removeTooltip = () => {
    const { selectedServiceInfo } = this.props;
    if (selectedServiceInfo?.isSystemService) {
      return this.renderTooltip("services:DELETE_DISABLE", "services:DELETE_HILI_ASSET_DISABLE");
    } else {
      return this.renderTooltip("services:DELETE_DISABLE", "actionBar:REMOVE");
    }
  };

  disableComplete = () => {
    const { assetFieldsData, actionType, assignedServiceDetail } = this.props;
    return !(
      assignedServiceDetail &&
      assignedServiceDetail.serviceState.code !== ServiceStatusStates.COMPLETED &&
      !this.assetStatusResponseValue(assetFieldsData) &&
      actionType === ActionItem.edit
    );
  };

  /**
   * @description - Method to close delete confirmation dialog.
   */
  handleRemoveClose = () => {
    this.setState({
      showRemoveModal: false,
    });
  };

  /**
   * Disable Complete Toll tip
   */

  completeToolTip = () => {
    const { assignedServiceDetail } = this.props;
    if (
      assignedServiceDetail &&
      assignedServiceDetail.serviceState.code === ServiceStatusStates.COMPLETED &&
      this.props.actionType === ActionItem.edit
    ) {
      return this.props.t("services:COMPLETE_SERVICE_DISABLED");
    }
    return this.completeDefaultTooltip();
  };

  /**
   * Disable Assign button
   */

  assetStatusResponseValue = (assetFieldsData) => {
    return (
      assetFieldsData &&
      ((assetFieldsData.statusCode && this.checkStatus(assetFieldsData.statusCode)) ||
        (assetFieldsData.assetStatusResponse && this.checkStatus(assetFieldsData.assetStatusResponse.code)))
    );
  };
  checkStatus = (status) => {
    return status === StatusCode.Retired || status === StatusCode.Disposed || status === StatusCode.Lost;
  };
  disableAdd = () => {
    const { assetFieldsData, selectedServiceInfo, licenseContext } = this.props;
    return (
      this.assetStatusResponseValue(assetFieldsData) ||
      selectedServiceInfo?.isSystemService ||
      !licenseContext?.isAssignServiceEnabled()
    );
  };

  disableRemove = () => {
    const { assetFieldsData, selectedServiceInfo } = this.props;
    if (!selectedServiceInfo && this.assetStatusResponseValue(assetFieldsData)) {
      return true;
    } else if (selectedServiceInfo?.isSystemService) {
      return true;
    }
    return false;
  };
  addToolTip = () => {
    const { assetFieldsData, t } = this.props;
    if (this.assetStatusResponseValue(assetFieldsData)) {
      return t("services:ASSIGN_DISABLE");
    }
    return t("actionBar:ASSIGN");
  };

  actionItemVisible = () => this.props.actionType !== ActionItem.add;

  render() {
    const {
      actionType,
      actionBarSettings,
      assetId,
      assignHistoryService,
      serviceOperationError,
      assignServiceSuccess,
      assignedServiceDetail,
      assignedServicesList,
      clearServiceData,
      completeAssignedService,
      currenciesList,
      costCurrency,
      defaultCurrency,
      editAssignedService,
      getAssignServicesList,
      getServicesList,
      moduleName,
      onFormchange,
      scheduleDate,
      serviceStatus,
      showServiceForm,
      completionDate,
      serviceList,
      serviceValue,
      setServiceFilterQuery,
      showToaster,
      submitForm,
      t,
      totalRecords,
      distanceUnitValue,
    } = this.props;
    const actionBarItemInfo = { totalRecords };
    return (
      <>
        <ActionBar
          actionBarSettings={actionBarSettings}
          actionBarItemInfo={actionBarItemInfo}
          onActionItemClicked={this.onActionClicked}
          renderCheckBox={true}
          dependencies={{
            actionItemVisible: this.actionItemVisible,
            addToolTip: this.addToolTip,
            completeToolTip: this.completeToolTip,
            disableAdd: this.disableAdd,
            disableComplete: this.disableComplete,
            disableItem: this.disableItem,
            disableRemove: this.disableRemove,
            editTooltip: this.editTooltip,
            removeSort: this.actionItemVisible,
            removeTooltip: this.removeTooltip,
          }}
          checkAuthorization={CheckAuthorization}
        />

        {this.state.showSortingModal && this.props.actionType === ActionItem.edit && (
          <DialogPosition left={this.state.leftPosition} top={this.state.topPosition}>
            <ServicesSummarySortComponent
              closeMenu={this.closeMenu}
              showSortingModal={this.state.showSortingModal}
              sortQuery={this.props.sortQuery}
              defaultSort={this.props.sortQuery}
              setSortQuery={this.props.setSortQuery}
              getSortedServiceList={this.fetchList}
              serviceStatus={this.props.serviceStatus}
              t={t}
            />
          </DialogPosition>
        )}
        {this.state.serviceActionType &&
          this.state.serviceActionType !== ActionItem.delete &&
          this.props.showManageServices.showServiceForm && (
            <AssignServiceComponent
              distanceUnitValue={distanceUnitValue}
              actionType={actionType}
              assignHistoryService={assignHistoryService}
              assignServiceSuccess={assignServiceSuccess}
              assetId={assetId}
              getCurrenciesList={this.props.getCurrenciesList}
              currenciesList={currenciesList}
              getServicesList={getServicesList}
              showModal={true}
              onFormchange={onFormchange}
              serviceValue={serviceValue}
              serviceStatus={serviceStatus}
              assignedServicesList={assignedServicesList}
              clearServiceData={clearServiceData}
              getAssignServicesList={getAssignServicesList}
              serviceList={serviceList}
              scheduleDate={scheduleDate}
              completionDate={completionDate}
              costCurrency={costCurrency}
              editAssignedService={editAssignedService}
              completeAssignedService={completeAssignedService}
              serviceOperationError={serviceOperationError}
              assignedServiceDetail={assignedServiceDetail}
              handleServiceList={this.handleServiceList}
              handleFormClose={this.handleFormClose}
              submitForm={submitForm}
              showServiceForm={showServiceForm}
              serviceActionType={this.state.serviceActionType}
              showToaster={showToaster}
              moduleName={moduleName}
              setServiceFilterQuery={setServiceFilterQuery}
              defaultCurrency={defaultCurrency}
              getServiceTemplateDetails={this.props.getServiceTemplateDetails}
              clearAssignedServiceList={this.props.clearAssignedServiceList}
              serviceTemplateDetails={this.props.serviceTemplateDetails}
              clearServiceTemplateDetails={this.props.clearServiceTemplateDetails}
              allAssignedService={this.props.allAssignedService}
            />
          )}
        {this.state.showRemoveModal ? (
          <Remove
            rowData={this.props.selectedServiceInfo}
            handleRemoveClose={this.handleRemoveClose}
            t={this.props.t}
            handleRemoveConfirm={this.props.handleRemoveConfirm}
          />
        ) : null}
      </>
    );
  }
}

export default withLicenseContext(withTranslation()(ServicesSummary));
