const QtyItemsActionConstants = {
  AM_ASSOCIATE_QTYITEM_LABEL: "AM_ASSOCIATE_QTYITEM_LABEL",
  AM_ASSOCIATE_QTYITEM_LABEL_DETAILS: "AM_ASSOCIATE_QTYITEM_LABEL_DETAILS",
  AM_QTYITEM_ADD_EDIT: "AM_QTYITEM_ADD_EDIT",
  AM_ASSOCIATE_QTYITEM_LABEL_DETAILS_SUCCESS: "AM_ASSOCIATE_QTYITEM_LABEL_DETAILS_SUCCESS",
  AM_SET_QTY_LABEL_UPDATED: "AM_SET_QTY_LABEL_UPDATED",
  CLEAR_ALLOCATIONS_FOR_TRANSFER: "CLEAR_ALLOCATIONS_FOR_TRANSFER",
  CLEAR_ALL_QTY_ITEMS: "CLEAR_ALL_QTY_ITEMS",
  CLEAR_SELECTED_QTYITEM_DETAIL: "CLEAR_SELECTED_QTYITEM_DETAIL",
  CLEAR_QTY_ITEM_ADD_EDIT_RESPONSE: "CLEAR_QTY_ITEM_ADD_EDIT_RESPONSE",
  CLEAR_IMPORT_QTY_ITEMS_HISTORY_ERROR_DETAILS: "CLEAR_IMPORT_QTY_ITEMS_HISTORY_ERROR_DETAILS",
  CLEAR_QTY_ITEM_MULTI_DELETE_RESPONSE: "CLEAR_QTY_ITEM_MULTI_DELETE_RESPONSE",
  CLEAR_QTY_ITEM_ALLOCATION_STATUS_RESPONSE: "CLEAR_QTY_ITEM_ALLOCATION_STATUS_RESPONSE",
  DELETE_QTY_ITEMS: "DELETE_QTY_ITEMS",
  GET_IMPORT_QTY_ITEM_HISTORY: "GET_IMPORT_QTY_ITEM_HISTORY",
  GET_IMPORT_QTY_ITEMS_HISTORY_ERROR_DETAILS: "GET_IMPORT_QTY_ITEMS_HISTORY_ERROR_DETAILS",
  GET_IMPORT_QTY_ITEMS_HISTORY_ERROR_DETAILS_RESPONSE: "GET_IMPORT_QTY_ITEMS_HISTORY_ERROR_DETAILS_RESPONSE",
  GET_IMPORT_QTY_ITEM_HISTORY_RESPONSE: "GET_IMPORT_QTY_ITEM_HISTORY_RESPONSE",
  GET_QTY_FILTER_ATTRIBUTES: "GET_QTY_FILTER_ATTRIBUTES",
  GET_QTY_FILTER_ATTRIBUTES_SUCCESS: "GET_QTY_FILTER_ATTRIBUTES_SUCCESS",
  GET_QTY_ITEMS: "GET_QTY_ITEMS",
  GET_QTY_ITEM_DETAILS: "GET_QTY_ITEM_DETAILS",
  GET_QTY_ITEM_TRANSFER_DETAILS: "GET_QTY_ITEM_TRANSFER_DETAILS",
  GET_QTY_LIST_FOR_SEARCH: "GET_QTY_LIST_FOR_SEARCH",
  MULTI_EDIT_QTY_ITEM: "MULTI_EDIT_QTY_ITEM",
  PUT_QTY_ITEMS_LIST: "PUT_QTY_ITEMS_LIST",
  QTY_ITEMS_ADD_EDIT_RESPONSE: "QTY_ITEMS_ADD_EDIT_RESPONSE",
  QTY_ITEMS_GET_COMMON_ATTRIBUTES: "QTY_ITEMS_GET_COMMON_ATTRIBUTES",
  QTY_ITEMS_SELECT_GRID_CHECKBOX: "QTY_ITEMS_SELECT_GRID_CHECKBOX",
  QTY_ITEMS_SET_COMMON_ATTRIBUTES: "QTY_ITEMS_SET_COMMON_ATTRIBUTES",
  QTY_ITEMS_UNSELECT_GRID_CHECKBOX: "QTY_ITEMS_UNSELECT_GRID_CHECKBOX",
  QTY_ITEM_MULTI_EDIT_RESPONSE: "QTY_ITEM_MULTI_EDIT_RESPONSE",
  QTY_ITEM_RESET_MULTI_EDIT_RESPONSE: "QTY_ITEM_RESET_MULTI_EDIT_RESPONSE",
  QTY_ITEM_RESET_TO_INITIAL_STATE: "QTY_ITEM_RESET_TO_INITIAL_STATE",
  QTY_ITEM_SELECT_ROW: "QTY_ITEM_SELECT_ROW",
  QTY_ITEM_UNSELECT_ROW: "QTY_ITEM_UNSELECT_ROW",
  QTY_RESET_QUERY_PARAM: "QTY_RESET_QUERY_PARAM",
  SELECT_ALL_QTY_ITEMS: "SELECT_ALL_QTY_ITEMS",
  SET_ALLOCATIONS_FOR_TRANSFER: "SET_ALLOCATIONS_FOR_TRANSFER",
  SET_SELECTED_QTYITEM_DETAIL: "SET_SELECTED_QTYITEM_DETAIL",
  SET_QTY_FILTER_APPLIED_FIELD: "SET_QTY_FILTER_APPLIED_FIELD",
  SET_QTY_FILTER_ATTRIBUTES: "SET_QTY_FILTER_ATTRIBUTES",
  SET_QTY_FITLER_QUERY: "SET_QTY_FITLER_QUERY",
  SET_QTY_ITEM_DETAIL_SUCCESS: "SET_QTY_ITEM_DETAIL_SUCCESS",
  SET_QTY_ITEM_MULTI_DELETE_RESPONSE: "SET_QTY_ITEM_MULTI_DELETE_RESPONSE",
  SET_QTY_ITEM_SORT_QUERY: "SET_QTY_ITEM_SORT_QUERY",
  SET_QTY_ITEM_TRANSFER_DETAILS: "SET_QTY_ITEM_TRANSFER_DETAILS",
  SET_QTY_SEARCH_QUERY: "SET_QTY_SEARCH_QUERY",
  SET_QTY_ITEM_STATUS: "SET_QTY_ITEM_STATUS",
  SET_QTY_ITEM_STATUS_RESPONSE: "SET_QTY_ITEM_STATUS_RESPONSE",
  SET_ALLOCATION_ITEM_STATUS_RESPONSE: "SET_ALLOCATION_ITEM_STATUS_RESPONSE",
  SET_ALOCATION_ITEM_STATUS: "SET_ALOCATION_ITEM_STATUS",
  SET_SELECTED_VIEW: "SET_SELECTED_VIEW",
  GET_QTY_ITEM_SYSTEM_VIEW: "GET_QTY_ITEM_SYSTEM_VIEW",
  GET_QTY_ITEM_SYSTEM_VIEW_SUCCESS: "GET_QTY_ITEM_SYSTEM_VIEW_SUCCESS",
  REORDER_QTY_ITEM_CUSTOM_VIEW: "REORDER_QTY_ITEM_CUSTOM_VIEW",
  REORDER_QTY_ITEM_CUSTOM_VIEW_SUCCESS: "REORDER_QTY_ITEM_CUSTOM_VIEW_SUCCESS",
  GET_QTY_ITEM_CUSTOM_VIEW: "GET_QTY_ITEM_CUSTOM_VIEW",
  QTY_ITEM_DELETE_CUSTOM_VIEW: "QTY_ITEM_DELETE_CUSTOM_VIEW",
  GET_QTY_ITEM_CUSTOM_VIEW_SUCCESS: "GET_QTY_ITEM_CUSTOM_VIEW_SUCCESS",
  QTY_ITEM_RESET_ORIGINAL_VIEW: "QTY_ITEM_RESET_ORIGINAL_VIEW",
  SET_QTY_ITEM_LIST_FIELDS_QUERY: "SET_QTY_ITEM_LIST_FIELDS_QUERY",
  QTY_ITEM_RENAME_CUSTOM_VIEW: "QTY_ITEM_RENAME_CUSTOM_VIEW",
  QTY_ITEM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA: "QTY_ITEM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA",
  QTY_ITEM_ADD_RENAME_CUSTOM_VIEW_RESPONSE: "QTY_ITEM_ADD_RENAME_CUSTOM_VIEW_RESPONSE",
  QTY_ITEM_CREATE_CUSTOM_VIEW: "QTY_ITEM_CREATE_CUSTOM_VIEW",
  QTY_ITEM_CLEAR_DEFAULT_GRID_FIELDS: "QTY_ITEM_CLEAR_DEFAULT_GRID_FIELDS",
  UPDATE_QTY_ITEM_CUSTOM_GRID_FIELDS: "UPDATE_QTY_ITEM_CUSTOM_GRID_FIELDS",
  GET_QTY_ITEM_CUSTOM_VIEW_FAILURE: "GET_QTY_ITEM_CUSTOM_VIEW_FAILURE",
  UPDATE_QTY_ITEM_CUSTOM_VIEW: "UPDATE_QTY_ITEM_CUSTOM_VIEW",
  QTY_ITEM_SET_DEFAULT_CUSTOM_VIEW: "QTY_ITEM_SET_DEFAULT_CUSTOM_VIEW",
  SET_QTY_ITEM_CUSTOM_VIEW_MODIFIED_FLAG: "SET_QTY_ITEM_CUSTOM_VIEW_MODIFIED_FLAG",
  UPDATE_QTY_ITEM_LIST: "UPDATE_QTY_ITEM_LIST",
};

export default QtyItemsActionConstants;
