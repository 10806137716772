import React from "react";
const SVG = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-173.000000, -5148.000000)">
        <g transform="translate(173.000000, 5148.000000)">
          <g id="move">
            <path
              d="M8,0 L0,8 L5.2,13.2 L8,16 L16,8 L8,0 Z M6,11 L5,11 L5,10 L6,10 L6,11 Z M6,6 L5,6 L5,5 L6,5 L6,6 Z M11,11 L10,11 L10,10 L11,10 L11,11 Z M10,5 L11,5 L11,6 L10,6 L10,5 Z"
              id="Shape"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <polygon
              id="Path"
              fill="#B7B3AA"
              fillRule="nonzero"
              points="14.6 8 12 5.2 12 7 8 7 4 7 4 5.2 1.4 8 4 10.8 4 9 8 9 12 9 12 10.8"
            />
            <polygon
              id="Path"
              fill="#B7B3AA"
              fillRule="nonzero"
              points="9 8 9 8 9 4 10.8 4 8 1.4 5.2 4 7 4 7 8 7 8 7 12 5.2 12 8 14.6 10.8 12 9 12"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
