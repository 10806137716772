/**
 * @description Action constants to be used for common module and its feature
 *
 * format: {feature}_{action}
 */
const CommonActionConstants = {
  AM_WORKER_LIST_LOADER_END: "AM_WORKER_LIST_LOADER_END", // loader for asset list
  AM_WORKER_LIST_LOADER_START: "AM_WORKER_LIST_LOADER_START", // loader for asset list
  AM_WORKER_RESET_QUERY_PARAM: "AM_WORKER_RESET_QUERY_PARAM", // Reset search and sort query to default
  OM_CLEAR_WORKER_DELETE_FLAG: "OM_CLEAR_WORKER_DELETE_FLAG",
  OM_CLOSE_WORKER_DETAILS: "OM_CLOSE_WORKER_DETAILS",
  OM_CONTRACT_TYPE_RESPONSE: "OM_CONTRACT_TYPE_RESPONSE",
  OM_CREATE_WORKER: "OM_CREATE_WORKER",
  OM_CREATE_WORKER_RESPONSE: "OM_CREATE_WORKER_RESPONSE",
  OM_DELETE_USER: "OM_DELETE_USER",
  OM_DELETE_WORKER: "OM_DELETE_WORKER",
  OM_DELETE_WORKER_FAIL: "OM_DELETE_WORKER_FAIL",
  OM_DELETE_WORKER_SUCCESS: "OM_DELETE_WORKER_SUCCESS",
  OM_DESIGNATION_RESPONSE: "OM_DESIGNATION_RESPONSE",
  OM_GET_CONTRACT_TYPE_LIST: "OM_GET_CONTRACT_TYPE_LIST",
  OM_GET_DESIGNATION_LIST: "OM_GET_DESIGNATION_LIST",
  OM_GET_RESPONSIBILITIES_LIST: "OM_GET_RESPONSIBILITIES_LIST",
  OM_GET_STATES: "OM_GET_STATES",
  OM_GET_STATES_SUCCESS: "OM_GET_STATES_SUCCESS",
  OM_GET_STATE_LIST: "OM_GET_STATE_LIST",
  OM_GET_WORKERS: "OM_GET_WORKERS",
  OM_GET_WORKER_DETAILS: "OM_GET_WORKER_DETAILS",
  OM_GET_WORKER_FILTER_FIELD: "OM_GET_WORKER_FILTER_FIELD",
  OM_GET_WORKER_LIST_FOR_SEARCH: "OM_GET_WORKER_LIST_FOR_SEARCH", // searched results of workers list.
  OM_LANGUAGE_RESPONSE: "OM_LANGUAGE_RESPONSE",
  OM_PUT_WORKERS: "OM_PUT_WORKERS",
  OM_RESET_CREATE_WORKER_INFO: "OM_RESET_CREATE_WORKER_INFO",
  OM_RESPONSIBILITIES_RESPONSE: "OM_RESPONSIBILITIES_RESPONSE",
  OM_SET_WORKER_SEARCH_QUERY: "OM_SET_WORKER_SEARCH_QUERY",
  OM_SET_WORKER_SORT_QUERY: "OM_SET_WORKER_SORT_QUERY",
  OM_STATE_RESPONSE: "OM_STATE_RESPONSE",
  OM_UPDATE_WORKER: "OM_UPDATE_WORKER",
  OM_UPDATE_WORKER_DETAILS: "OM_UPDATE_WORKER_DETAILS",
  OM_UPDATE_WORKER_DETAILS_SUCCESS: "OM_UPDATE_WORKER_DETAILS_SUCCESS",
  ORC_RESET_UPDATE_WORKER_INFO: "ORC_RESET_UPDATE_WORKER_INFO",
  ORC_UPDATE_WORKER_RESPONSE: "ORC_UPDATE_WORKER_RESPONSE",
  RESET_MODULE_DATA: "RESET_MODULE_DATA",
  SET_STATE_RESPONSE_NULL: "SET_STATE_RESPONSE_NULL",
  SET_WORKER_FILTER_QUERY: "SET_WORKER_FILTER_QUERY",
  WORKERS_SELECT_ROW: "WORKERS_SELECT_ROW",
  WORKERS_UNSELECT_ROW: "WORKERS_UNSELECT_ROW",
  WORKER_FILTER_FIELD_SUCCESS: "WORKER_FILTER_FIELD_SUCCESS",
  WORKER_REQUEST_COMPLETED: "WORKER_REQUEST_COMPLETED",
};

export default CommonActionConstants;
