import Gateway from "../gateway/gateway";
import { IAssetsGatewayFilter, IError, IGatewayInventory } from "../models/activeTracking/iGatewayAssets";
import { SmartInventoryDetailPanel } from "../models/activeTracking/smartINventoryEnum";
import { SuggestedTransferAttributes, SuggestedTransferListResponse } from "../models/alert/suggestedTransfer";
import { IAssetsList } from "../models/assets.list";
import { IAPIResponse } from "../models/iApiResponse";
import { ITrackingPayload, IGeoLocation } from "../models/map/bleMap/trackingPayloalModel";
import { stripFieldInFilter } from "../modules/assetTracking/components/smartInventory/presentational/smartInventoryUtils";
import { IOneMapRequest } from "../modules/oneMap/model/cluster";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { HelperLodash, getVersionedApiUrl } from "am-web-ui-shared/helpers";

export type ErrorObject = {
  code: number;
  message: string;
  fieldName: string;
};

export interface IErrorResponse {
  readonly error?: string;
  readonly errors?: string | ErrorObject[];
}

const getEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.ACTIVE_TRACKING, path, params);
};

export default class ActiveTrackingApi {
  /**
   * Returns list of Assets by calling relevant API
   * @param assetId
   */
  static findTrackingPayload = async (assetId): Promise<ITrackingPayload | IErrorResponse> => {
    const url = getEndpoint(`/assets/${assetId}/tracking-payloads`, { assetId });
    return Gateway.get(url, null, null, null, false) as unknown as ITrackingPayload;
  };

  static findGeolocationBySerialNumber = async (
    serialNumber,
    materialNumber,
  ): Promise<IGeoLocation | IErrorResponse> => {
    const urlWithPathVersion = getEndpoint(`/assets/${serialNumber}/geolocation`, {
      serialNumber,
    });
    const url = `${urlWithPathVersion}?materialNumber=${materialNumber}`;
    return Gateway.get(url, null, null, null, false, null) as unknown as IGeoLocation;
  };

  static getLocationGatewayAssets = async (locationId: number): Promise<IGatewayInventory | IError> => {
    const url = getEndpoint(`/locations/${locationId}/smart-inventory`, {
      locationId,
    });
    return Gateway.get(url, null, null, null, false) as unknown as IGatewayInventory;
  };

  /**
   * Returns list of Assets by calling relevant API
   * @param assetId : storage asset ID.
   */
  static getStorageAssetGatewayAssets = async (assetId: number): Promise<IGatewayInventory | IError> => {
    const url = getEndpoint(`/unique-assets/${assetId}/smart-inventory`, {
      assetId,
    });
    return Gateway.get(url, null, null, null, false) as unknown as IGatewayInventory;
  };
  /**
   * getSmartInventoryUrl : return the url part based on type od detail panel
   * @param detailType
   */
  static getSmartInventoryUrl = (detailType) =>
    detailType === SmartInventoryDetailPanel.LOCATIONS ? "smart-inventory/asset-details" : "smart-inventory-assets";

  /**
   * Method to Make API call for retreiving values for filter attributes &
   * their values in Asset grid
   * @param urlFor
   * @param id
   * @param statsType
   * @param filter
   */
  static getAssetViewFilterAttributes = async (
    urlFor: string,
    id: number,
    statsType: string,
    filter?: string,
  ): Promise<IAssetsGatewayFilter | IError> => {
    const filterVal = stripFieldInFilter(filter, "inventoryName");
    const inventoryUrl = ActiveTrackingApi.getSmartInventoryUrl(urlFor);
    const urlWithPathVersion = getEndpoint(`/${urlFor}/${id}/${inventoryUrl}/attributes`, { urlFor, id, inventoryUrl });
    let url = `${urlWithPathVersion}?stats_type=${statsType}`;
    if (filterVal) {
      url = `${url}&${filterVal}`;
    }
    return Gateway.get(url, null, null, null, false) as unknown as IAssetsGatewayFilter;
  };

  /**
   * Returns list of Assets by calling relevant API
   * @param urlFor
   * @param id
   * @param statsType
   * @param queryObj
   * @param loader
   */
  static getAssetsList = async (
    urlFor: string,
    id: number,
    statsType: string,
    queryObj,
    loader = true,
  ): Promise<IAssetsList | IError> => {
    // remove filter inventory type
    let filter = stripFieldInFilter(queryObj.filter, "inventoryName");

    // current selection filter is not recognised in backend api
    filter = stripFieldInFilter(filter, "currentSelectedFilter");

    delete queryObj.filter;
    const inventoryUrl = ActiveTrackingApi.getSmartInventoryUrl(urlFor);
    const urlWithPathVersion = getEndpoint(`/${urlFor}/${id}/${inventoryUrl}`, { urlFor, id, inventoryUrl });
    let url = `${urlWithPathVersion}?stats_type=${statsType}`;
    if (filter) {
      url = `${url}&${filter}`;
    }
    return Gateway.get(url, { ...queryObj }, null, null, loader) as unknown as IAssetsList;
  };

  static onboardAsset = (scanCode: string, materialNumber: string, loader = true) => {
    const url = getEndpoint(`/tags/${scanCode}/onboarding`, {
      scanCode,
    });
    const body = { materialNumber };
    return Gateway.post(url, null, { ...body }, null, null, loader);
  };

  static offboardAsset = (scanCodes: string[], loader = false) => {
    const url = getEndpoint("/tags/offboarding");
    const body = { scanCodes };
    return Gateway.post(url, null, { ...body }, null, null, loader);
  };

  static getTagInformationForScanCode = (scanCode: string) => {
    const url = getEndpoint(`/tags/${scanCode}`);
    return Gateway.get<{ status: string }>(url, null, null, null, false);
  };

  static getLastSeenForAssets = (ids: number[], loader = false) => {
    try {
      const url = getEndpoint("/assets/last-seen") + `?assetIds=${ids.join(",")}`;
      return Gateway.get(url, null, null, null, loader);
    } catch (error) {
      return { error };
    }
  };

  static getSuggestedTransfers = (offset: number, limit: number, filterString?: string, q?: string) => {
    const url = getEndpoint("/suggested-transfers") + `?${filterString}`;
    return Gateway.get<SuggestedTransferListResponse>(
      url,
      {
        q,
        limit,
        offset,
      },
      null,
      null,
      true,
    );
  };

  static getSuggestedTransfersAttributes = (filterString?: string) => {
    const url = getEndpoint("/suggested-transfers/attributes") + `?${filterString}`;
    return Gateway.get<SuggestedTransferAttributes>(url, null, null, null, false);
  };

  static confirmSuggestedTransfers = (suggestedTransferIds: string[]) => {
    const url = getEndpoint("/suggested-transfers/confirm");
    return Gateway.post<null>(
      url,
      null,
      {
        origin: "WEB",
        ids: suggestedTransferIds,
      },
      null,
      null,
      false,
    );
  };

  static getClusteredAssets = (req: IOneMapRequest): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.post(getEndpoint("/onemap/assets"), null, { ...req }, null, null, false);
  };

  static getAssetsForSingleCluster = (req: { assetIds: number[] }): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.post(getEndpoint("/onemap/assets/assetsByAssetIds"), null, { ...req }, null, null, false);
  };
}
