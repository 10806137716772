import React from "react";
import SingletonLicenseContext from "TARGET_BUILD/licenseStrategy/singletonLicenseContext";
import { LicenseContext } from "TARGET_BUILD/licenseStrategy/licenseContext";

type WithStrategyContext = { licenseContext: LicenseContext };
export default function withLicenseContext<Props extends WithStrategyContext>(Cmp: React.ComponentType<Props>) {
  const displayName = Cmp.displayName || Cmp.name || "Component";

  const InnerComponent = (props: Omit<Props, keyof WithStrategyContext>) => (
    <Cmp licenseContext={SingletonLicenseContext.getAssetsContext()} {...(props as Props)} />
  );

  InnerComponent.displayName = `WithLicenceContext(${displayName})`;

  return InnerComponent;
}
