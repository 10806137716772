export enum IconName {
  Add = "Add",
  Access = "Access",
  AccessLocation = "AccessLocation",
  Activate = "Activate",
  Alert = "Alert",
  AlertSetting = "AlertSetting",
  AssetCapSetting = "AssetCapSetting",
  BusinessHours = "BusinessHours",
  CostLocation = "CostLocation",
  CostLocationEmpty = "CostLocationEmpty",
  DaysOfOperation = "DaysOfOperation",
  Deactivate = "Deactivate",
  DocumentChart = "DocumentChart",
  AddFolder = "AddFolder",
  AddUser = "AddUser",
  ArrowDown = "ArrowDown",
  ArrowUpward = "ArrowUpward",
  Assign = "Assign",
  BackToAssetList = "BackToAssetList",
  Basket = "Basket",
  Battery = "Battery",
  Bell = "Bell",
  CallBack = "CallBack",
  CartAdd = "CartAdd",
  Category = "Category",
  Certificate = "Certificate",
  Chat = "Chat",
  Clone = "Clone",
  CompleteService = "CompleteService",
  ConfirmTransfer = "ConfirmTransfer",
  Contact = "Contact",
  Cross = "Cross",
  Delete = "Delete",
  Dismiss = "Dismiss",
  DeliveryNotes = "DeliveryNotes",
  DragArrow = "DragArrow",
  Edit = "Edit",
  EllipsisVertical = "EllipsisVertical",
  ExchangeBattery = "ExchangeBattery",
  Export = "Export",
  Import = "Import",
  Merge = "Merge",
  More = "More",
  ShelfBox = "ShelfBox",
  Sort = "Sort",
  SortAscending = "SortAscending",
  SortDescending = "SortDescending",
  Status = "Status",
  TransferAdd = "TransferAdd",
  Link = "Link",
  ListView = "ListView",
  Map = "MapIcon",
  LocationEmpty = "LocationEmpty",
  RecallTransfer = "RecallTransfer",
  Refresh = "Refresh",
  RescheduleService = "RescheduleService",
  Resync = "Resync",
  Transfer = "Transfer",
  TransferCart = "TransferCart",
  TransferCartFull = "TransferCartFull",
  User = "User",
  UserEmpty = "UserEmpty",
  Setting = "Setting",
  SettingCompany = "SettingCompany",
  SettingUser = "SettingUser",
  Info = "Info",
  LocationGroupEmpty = "LocationGroupEmpty",
  LocationManager = "LocationManager",
  Location = "Location",
  EmptyAsset = "EmptyAsset",
  EmptyCost = "EmptyCost",
  UserGroupEmpty = "UserGroupEmpty",
  Logout = "Logout",
  Label = "Label",
  MultiEdit = "MultiEdit",
  Remove = "Remove",
  RemoveAll = "RemoveAll",
  RemoveCircle = "RemoveCircle",
  Repeat = "Repeat",
  Search = "Search",
  Move = "Move",
  OpenNew = "OpenNew",
  Feedback = "Feedback",
  Warning = "Warning",
  Template = "Template",
  Promote = "Promote",
  Download = "Download",
  Chevron = "Chevron",
  Checkmark = "Checkmark",
  UploadImage = "UploadImage",
  Image = "Image",
  DropFile = "DropFile",
  HomeIcon = "HomeIcon",
  Arrow = "Arrow",
  Complete = "Complete",
  MoveCursor = "MoveCursor",
  Calendar = "Calendar",
  LocationGroup = "LocationGroup",
  Attachment = "Attachment",
  Star = "Star",
  TransferHistory = "TransferHistory",
  AssetCosts = "AssetCosts",
  Email = "Email",
  CertificateEmpty = "CertificateEmpty",
  TemplateEmpty = "TemplateEmpty",
  Worker = "Worker",
  StoredAsset = "StoredAsset",
  StorageAsset = "StorageAsset",
  Radio = "Radio",
  RadioChecked = "RadioChecked",
  NoSearchResult = "NoSearchResult",
  LastTransfer = "LastTransfer",
  Holiday = "Holiday",
  WareHouse = "WareHouse",
  Comodity = "Comodity",
  EditAssetsTemplate = "EditAssetsTemplate",
  EditAssetsNotLinkedTemplate = "EditAssetsNotLinkedTemplate",
  EditQuantityItemsCosts = "EditQuantityItemsCosts",
  Truck = "Truck",
  Time = "Time",
  Filter = "Filter",
  FilterWhite = "FilterWhite",
  Folder = "Folder",
  LocationJobSite = "LocationJobSite",
  QuickTransfer = "QuickTransfer",
  AddAllocation = "AddAllocation",
  AllocationStatus = "AllocationStatus",
  View = "View",
  ViewRequest = "ViewRequest",
  Success = "Success",
  EmptyQTYItem = "EmptyQTYItem",
  Send = "Send",
  GenerateReport = "GenerateReport",
  StockChange = "StockChange",
  DuplicateReport = "DuplicateReport",
  Purchase = "Purchase",
  AddPurchase = "AddPurchase",
  Repair = "Repair",
  RepairHiltiAssets = "RepairHiltiAssets",
  RenewTool = "RenewTool",
  HiltiServices = "HiltiServices",
  IdleAsset = "IdleAsset",
  HistoricServices = "HistoricServices",
  FullScreen = "FullScreen",
  RepairStatus = "RepairStatus",
  PlugCost = "PlugCost",
  HeavyEquipment = "HeavyEquipment",
  LockIcon = "LockIcon",
  BriefCase = "BriefCase",
  Container = "Container",
  VanGateway = "VanGateway",
  TelematicsGateway = "TelematicsGateway",
  SmartInventory = "SmartInventory",
  ExpiredLink = "ExpiredLink",
  UniteTenant = "UniteTenant",
  upgradeButton = "UpgradeButton",
  DatePostponeIcon = "DatePostponeIcon",
  SandClock = "SandClock",
  ReturnHiltiAssets = "ReturnHiltiAssets",
  Translation = "Translation",
  Dollar = "Dollar",
  Target = "Target",
  Undo = "Undo",
}
