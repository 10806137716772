import React, { PropsWithChildren } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// @ts-ignore
import styled from "custom-styled-components";
// @ts-ignore
import ExpendIconUrl from "TARGET_BUILD/ui-assets/icons/expand.svg";
import { IconBold } from "../../index";
import ISimpleExpansionPanelProps from "./iSimpleExpansionPanel";
import { LoaderWrapper } from "am-web-ui-shared/elements";

/**
 * @const StyledExpansionPanel - Styling the Expansion Panel to fixed width of 380px
 */
const StyledExpansionPanel = styled(Accordion)`
  && {
    width: 100%;
    margin: 0;
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
    box-shadow: none;
    &:before {
      height: 0;
      margin: 0;
    }
  }
`;

/**
 * @const StyledExpansionPanelSummary - Styling the Expansion Panel Summary.
 */
const StyledExpansionPanelSummary = styled(AccordionSummary)`
  && {
    padding: 12px 16px;
  }
  &&& {
    min-height: unset;
    div {
      margin: 0;
    }
  }
`;

/**
 * @const StyledExpansionPanelDetails - Styling the Expansion Panel Details.
 */
const StyledExpansionPanelDetails = styled(AccordionDetails)`
  && {
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.detailsPanel.detailBackground};
    padding: 0 16px;
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  }
`;
const ExpantionDetailLoader = styled.div`
  min-height: 100px;
  #commonLoading {
    top: 48px;
  }
`;

/**
 * @description - This is a simple expansion panel summary that holds the header of the panel.
 */
const styledExpansionPanelSummary = (props) => {
  const { headingId, iconUrl, boldId, boldText, type, hasValue, withBeta } = props;

  return (
    <StyledExpansionPanelSummary expandIcon={hasValue ? <img src={ExpendIconUrl} alt="" /> : ""}>
      <IconBold
        id={headingId}
        iconUrl={iconUrl}
        boldId={boldId}
        boldText={boldText}
        type={type}
        isPanelOverlap={true}
        withBeta={withBeta}
      />
    </StyledExpansionPanelSummary>
  );
};

/**
 * @description - This is a simple expansion panel details that holds the children of the panel.
 */
const styledExpansionPanelDetails = (props) => {
  const { children, data, t, isExpanded } = props;
  return data === null && isExpanded ? (
    <ExpantionDetailLoader>
      <LoaderWrapper loadingText={t("common:LOADING")} activity={true} />
    </ExpantionDetailLoader>
  ) : (
    <StyledExpansionPanelDetails> {children}</StyledExpansionPanelDetails>
  );
};

/**
 * @description - This is a simple expansion panel that holds the summary and details panel.
 * By default the panel will be collapsed.
 * @const hasValue use to set panel expanded OR collapse by default
 *
 * @const SimpleExpansionPanel
 * @interface ISimpleExpansionPanelProps
 */
type ISimpleExpansionPanel = PropsWithChildren<ISimpleExpansionPanelProps>;

const SimpleExpansionPanel = (props: ISimpleExpansionPanel) => {
  const { panelId, hasValue } = props;
  return (
    <StyledExpansionPanel
      data-testid="styledExpansionPanel"
      id={panelId}
      data-exp-pnl="simple"
      onChange={props.accordianOnChange}
    >
      {styledExpansionPanelSummary(props)}
      {hasValue ? styledExpansionPanelDetails(props) : null}
    </StyledExpansionPanel>
  );
};

/**
 * @export SimpleExpansionPanel - Simple Expansion Panel.
 */
export default SimpleExpansionPanel;
