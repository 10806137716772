import React from "react";
const SVG = ({ color = "#524F53" }: { color?: string }) => (
  <svg width="24px" height="24px" viewBox="0 0 240 240">
    <path
      fill={color}
      d="M124.6 10.8c-1.1.6-2.6 1.9-3.3 2.7-1.2 1.6-1.3 2.7-1.6 17.6l-.3 15.9-4.5.7c-16 2.4-31.1 10-43.1 21.6-5.9 5.7-8.6 9-13.2 16.4-5.4 8.5-9.3 19.1-10.8 29.2l-.7 4.5-15.9.3c-14.9.3-16 .4-17.6 1.6-4.8 3.6-4.7 10.6.2 13.8 1.4 1 3.6 1.1 17.4 1.4l15.8.3.7 4.5c5.4 34.8 35.3 63.7 70.1 67.6l1.5.2.3 15.8c.3 17.3.4 17.8 3.9 20.1 2 1.3 6.8 1.3 8.8 0 3.5-2.3 3.6-2.9 3.9-20.1l.3-15.8 4.8-.8c29.8-4.8 55.1-27 64.2-56 1-3.3 2.2-8.1 2.6-10.8l.8-4.8 15.8-.3c17.2-.3 17.7-.4 20.1-3.9 1.3-2 1.3-6.8 0-8.8-2.3-3.5-2.9-3.6-20.1-3.9l-15.8-.3-.7-4.5c-5.4-34.8-35.3-63.7-70.1-67.6l-1.5-.2-.3-15.8c-.2-13.9-.4-16.1-1.4-17.5-2.1-3.6-6.8-5-10.3-3.1m13 53.7c21.6 3.2 39.7 16.9 48.9 36.9 9.4 20.1 7.5 43.9-5 62.5-13 19.6-36.9 30.9-59.9 28.3-8.4-1-14-2.6-21.4-6.1-32-15.5-45.5-53.2-30.3-85.4 12.1-25.8 39.6-40.5 67.7-36.2"
    />
    <path
      fill={color}
      d="M118.5 87.2c-4.9 1.1-12.4 4.8-16.8 8.4-6.9 5.4-12.8 15-14.6 23.6-2 9.3-.4 20.3 4 28.7 10.5 19.8 34.9 27.7 55.2 17.9 14.6-7.1 23.6-21.4 23.6-37.6 0-18.3-11.5-34.1-29.3-40-5-1.8-16.4-2.3-22.1-1"
    />
  </svg>
);

export default SVG;
