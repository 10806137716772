import Grid from "@mui/material/Grid";
import React from "react";
import ApplicationConstants from "../../constant/applicationConstants";

export const layoutType = {
  COL12: "col12",
  COL6: "col6",
  ROW3: "row3", // renders 3 columns in a row
};
const renderWithLayout = (props) => {
  if (props.mode === layoutType.COL6) {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={6}>
          {props.children}
        </Grid>
      </Grid>
    );
  }
  if (props.mode === layoutType.COL12) {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          {props.children}
        </Grid>
      </Grid>
    );
  }
  if (props.mode === layoutType.ROW3) {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={4}>
          {props.children[0]}
        </Grid>
        <Grid item={true} xs={12} md={4}>
          {props.children[1]}
        </Grid>
        <Grid item={true} xs={12} md={4}>
          {props.children.slice(ApplicationConstants.LENGTH.LEN2)}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container={true} spacing={2}>
      {props.children[0] ? (
        <Grid item={true} xs={6}>
          {props.children[0]}
        </Grid>
      ) : null}
      {props.children.slice(1) ? (
        <Grid item={true} xs={6}>
          {props.children.slice(1)}
        </Grid>
      ) : null}
    </Grid>
  );
};

const GridLayout = (props) => {
  return renderWithLayout(props);
};

export default GridLayout;
