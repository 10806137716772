/**
 * @description Date helper is utility service for date related operations in javascript.
 */

import moment from "moment-timezone";

const DIGIT_COUNT = 2;

const MAX_FUTUREYEARS = 100;

const DATETIMECONSTANTS = {
  HOUR: 24,
  MINSEC: 60,
  MONTH: 31,
  YEAR: 12,
};

export interface IDateFormats {
  dateFormat: string; // date
  timeFormat: string; // time
  dateTimeFormat: string; // datetime
  apiDateFormat: string; // dateformat send to api
  apiDateTimeFormat: string; // dateTimeformat send to api
  isoDateFormat: string;
}

export default class DateHelpers {
  defaultTimeFormat = "h:mm a";
  defaultDateTimeFormat = "MMM D, YYYY h:mm a";
  defaultDateFormat = "MMM D, YYYY";
  defaultApiDateFormat = "YYYY-MM-DD";
  defaultApiDateTimeFormat = "";
  locale: string = "en-US";
  isoDateFormat = "YYYY-MM-DD";

  // object to store the current format of locale
  currentFormat: any = {};
  localFormat;

  formats: IDateFormats = {
    apiDateFormat: this.defaultApiDateFormat,
    apiDateTimeFormat: this.defaultApiDateTimeFormat,
    dateFormat: this.defaultDateFormat,
    dateTimeFormat: this.defaultDateTimeFormat,
    isoDateFormat: this.isoDateFormat,
    timeFormat: this.defaultTimeFormat,
  };

  init = (dateFormat, locale) => {
    this.locale = locale;
    this.formats = dateFormat;
  };

  // Browser Language

  /**
   * @description Function to get browser language
   * @memberof DateHelpers
   * @returns {String} - Browser langauge code
   */
  getBrowserLanguage = () => {
    return window.navigator.language;
  };

  /**
   * @description Function to get converted date based on action to be performed
   * @memberof DateHelpers
   * @returns {Object} - resultant date as moment's date object
   */
  getDateByDays = (days: number) => {
    if (days > 0) {
      return moment().add(days, "day");
    }
    if (days < 0) {
      return moment().subtract(days * -1, "day");
    }
    return moment().toDate();
  };

  /**
   * @description Function to get current date
   * @memberof DateHelpers
   * @returns {String} - Todays Date
   */
  getDate = () => {
    return moment().format("l"); // 05/10/2018
  };

  /**
   * @description Function to get current month
   * @memberof DateHelpers
   * @returns {String} - Current Month
   */
  getMonth = (date?) => {
    return date ? moment(date).format("MMMM") : moment().format("MMMM"); // October
  };

  /**
   * @description Function to get current year
   * @memberof DateHelpers
   * @returns {String} - Current Year
   */
  getYear = (date?) => {
    return date ? moment(date).format("YYYY") : moment().format("YYYY"); // 2018
  };

  getFutureYear = (date?) => {
    return date
      ? moment(date).add(MAX_FUTUREYEARS, "years").format("YYYY")
      : moment().add(MAX_FUTUREYEARS, "years").format("YYYY");
  };

  /**
   * @description get difference of time in hour,
   * @param prevTime
   * @param currenTime
   * @returns {String} - time in hours
   */

  getDayDifference = (prevTime, currenTime) => {
    return moment(currenTime).diff(moment(prevTime), "day");
  };
  /**
   * @description get difference of time in hour,
   * @param prevTime
   * @param currenTime
   * @returns {String} - time in hours
   */

  getMonthDifference = (prevTime, currenTime) => {
    return moment(currenTime).diff(moment(prevTime), "month");
  };

  /**
   * @description get difference of time in hour,
   * @param prevTime
   * @param currenTime
   * @returns {String} - time in hours
   */

  getHourDifference = (prevTime, currenTime) => {
    return moment(currenTime).diff(moment(prevTime), "hour");
  };
  getSecDifference = (prevTime, currenTime) => {
    return moment(currenTime).diff(moment(prevTime), "seconds");
  };

  /**
   * @description get difference of time in minutes,
   * @param prevTime
   * @param currenTime
   * @returns {String} - time in minutes
   */
  getMinutesDifference = (prevTime, currenTime) => {
    return moment(currenTime).diff(moment(prevTime), "minutes");
  };

  getCurrentDate = () => {
    // need to check again
    return moment().format("LL");
  };

  /**
   * @description Function to format the given date format in upper case.
   * @memberof DateHelpers
   * @returns {String} - Date
   * @param format
   */
  toUpperCase = (format: string) => {
    return format.toUpperCase();
  };

  /**
   * @description Function to set locale to moment
   * @memberof DateHelpers
   * @param localeCode
   */
  setLocale = (localeCode: string) => {
    // need to check
    moment.locale(localeCode);
  };

  /**
   * @description Function to set the locale formatted date
   * @memberof DateHelpers
   * @param format
   */
  setCustomFormat = (format) => {
    if (format && format.locale) {
      const mappedFormat = this.toUpperCase(format.dateFormat);
      format.dateTimeFormat = `${mappedFormat ? mappedFormat : format.dateFormat} ${format.timeFormat}`;
      this.currentFormat[format.locale] = format;
      this.localFormat = format.locale;
    }
  };

  getDateInUTC = (date = new Date()) => {
    return moment(date).utc().format();
  };

  /**
   * @description Function to convert given date to the mentioned format
   * @memberof DateHelpers
   * @return {Object} - Code of locale. Like en-US, de-DE
   */
  changeDateFormat = (date: string, fromFormat: string, toFormat: string) => {
    return moment(date, fromFormat).format(this.toUpperCase(toFormat));
  };

  monthDateYearFromUTC = (time: string, format: string) => {
    return moment(time).format(this.toUpperCase(format));
  };

  getYearDifference = (prevTime, currenTime) => {
    return moment(currenTime).diff(moment(prevTime), "year");
  };
  getBarcharLegendFormat = (date: string) => {
    return moment(date).format("ddd DD MMM YYYY");
  };
  getBarcharHeaderFormat = (date: string) => {
    return moment(date).format("DD MMM YYYY");
  };
  /**
   * get time difference in day, year, hour, minutes for same day uploaded file
   * accepting current time and upload time of the file.
   * @param prevTime
   * @param currentTime
   */

  getTimeDifference = (prevTime, currentTime?) => {
    const currentTimeVal = currentTime || moment().format;
    const yearDifference = this.getYearDifference(prevTime, currentTimeVal);
    const dayDifference = this.getDayDifference(prevTime, currentTimeVal);
    const monthDifference = this.getMonthDifference(prevTime, currentTimeVal);
    const hourDifference = this.getHourDifference(prevTime, currentTimeVal);
    const minDifference = this.getMinutesDifference(prevTime, currentTimeVal);
    const secDifference = this.getSecDifference(prevTime, currentTimeVal);
    if (secDifference < DATETIMECONSTANTS.MINSEC) {
      return { type: "seconds", value: secDifference };
    } else if (minDifference < DATETIMECONSTANTS.MINSEC) {
      return { type: "minutes", value: minDifference };
    } else if (hourDifference < DATETIMECONSTANTS.HOUR) {
      return { type: "hour", value: hourDifference };
    } else if (dayDifference < DATETIMECONSTANTS.MONTH) {
      return { type: "day", value: dayDifference };
    } else if (monthDifference < DATETIMECONSTANTS.YEAR) {
      return { type: "month", value: monthDifference };
    } else {
      return { type: "year", value: yearDifference };
    }
  };

  /**
   * @description Function to return userLocale
   * @memberof DateHelpers
   */
  getUserLocaleTimeFormat = (isDateTime = false) => {
    return isDateTime ? this.formats.dateTimeFormat : this.formats.timeFormat;
  };

  /**
   * @description Extract the local time from UTC date.
   * @param utcDateTime
   * @param isDateTime
   */
  getLocalTime = (utcDateTime, isDateTime = false) => {
    return moment.utc(utcDateTime).local().format(this.getUserLocaleTimeFormat(isDateTime));
  };

  getLocalDate = (utcDateTime) => {
    return moment.utc(utcDateTime).local().format(this.formats.dateFormat);
  };

  addDaysInDate = (daysToAdd: number, startDate) => {
    return moment(startDate).add(daysToAdd, "days").format("l");
  };

  /**
   * @description function used to convert given time format to locale time in HH:MM format
   * @param time
   */
  getLocaleTimeWithoutSeconds = (time) => {
    return moment(time, "HH:mm").format(this.getTimeFormatWithoutSeconds());
  };
  /**
   * @description function used to convert given time format from HH:mm:ss to HH:MM format
   */
  getTimeFormatWithoutSeconds = () => {
    const format = this.formats.timeFormat;
    return format && format.split(":ss").join("");
  };

  /**
   * @description Extract the local time from UTC date.
   * @param utcDateTime
   */
  getFormattedDateFromUTC = (utcDateTime: string, format: string) => {
    return moment.utc(utcDateTime).local().format(format);
  };

  setDefaultTimeZone = (timeZone?) => {
    moment.tz.setDefault(timeZone);
  };

  getCurrentMonthYearInTenantTimezone = () => {
    const format = "MMMYYYY";
    return moment().format(format).toUpperCase();
  };

  getDateInTenantTimezone = (date) => {
    return date ? moment(date).format(this.formats.dateFormat) : "";
  };

  getDateTimeInTenantTimezone = (dateTime) => {
    return dateTime ? moment(dateTime).format(this.formats.dateTimeFormat) : "";
  };

  getTenantCurrentDate = () => {
    return moment().format(this.formats.dateFormat);
  };

  getTenantCurrentDateTime = () => {
    return moment().format(this.formats.dateTimeFormat);
  };

  getDateByFormat = (date, format) => {
    return moment(date).format(format); // can be removed
  };

  getLocaleDateByFormat = (date: string, format) => {
    return moment(date).local().format(this.toUpperCase(format));
  };

  /**
   * @description Function to get the format of the date only for react Datepicker
   * @memberof DateHelpers
   */
  getLocalizedDatePickerFormat = () => {
    return this.toDatePickerFormat(this.formats.dateFormat);
  };

  toDatePickerFormat = (format: string) => {
    return format.replace(/Y|D/g, (v) => {
      if (v === "Y") {
        return "y";
      } else if (v === "D") {
        return "d";
      } else {
        return v;
      }
    });
  };

  localFormats = () => {
    return this.locale;
  };

  getPreviousDate = () => {
    return moment().subtract(1, "day");
  };

  getDateInAPIFormat = (date) => {
    const nativeDate = this.getDateFromNativeDateObject(date);
    return moment(nativeDate).format(this.formats.apiDateFormat);
  };

  getDateTimeInAPIFormat = (dateTime: string) => {
    return moment(dateTime).format(this.formats.apiDateTimeFormat);
  };

  getDateWithoutTimeZone = (date) => {
    const d = moment(date);
    let month = "" + (d.month() + 1);
    let day = "" + d.date();
    const year = d.year();
    if (month.length < DIGIT_COUNT) {
      month = "0" + month;
    }
    if (day.length < DIGIT_COUNT) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  isValidDate = (dateString) => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) {
      return false; // Invalid format
    }
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) {
      return false; // NaN value, Invalid date
    }
    return true;
  };

  isTimeZonePositive = (date) => {
    return date && typeof date === "string" && this.isValidDate(date) && new Date().getTimezoneOffset() > 0;
  };

  getDateFromNativeDateObject = (date) => {
    if (this.isTimeZonePositive(date)) {
      return this.getDateWithoutTimeZone(date);
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < DIGIT_COUNT) {
      month = "0" + month;
    }
    if (day.length < DIGIT_COUNT) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  getNativeDateInTenantTimezone = (date?: string) => {
    const isoFormattedDate = moment(date);
    const tenantMonth = parseInt(this.getMomentPreviousMonth(isoFormattedDate), 10);
    const tenantYear = parseInt(this.getMomentYear(isoFormattedDate), 10);
    const tenantDay = parseInt(this.getMomentday(isoFormattedDate), 10);
    return new Date(tenantYear, tenantMonth, tenantDay);
  };

  getNativeDateTimeInTenantTimezone = (dateTime?: string) => {
    const d = dateTime ? moment(dateTime).format(this.formats.dateTimeFormat) : this.getTenantCurrentDateTime();
    return new Date(d);
  };

  getNativeDateObject = (date?: string) => {
    return date ? new Date(date) : new Date();
  };

  getNativeDateInMomentObject = (date) => {
    const nativeDate = this.getDateFromNativeDateObject(date);
    return moment(nativeDate);
  };

  getTenantDateWithCurrentTime = (dateObj) => {
    const date = this.getDateFromNativeDateObject(dateObj);
    const time = this.getTenantCurrentTime();
    return `${date} ${time}`;
  };

  getTenantCurrentTime = () => {
    return moment().format("HH:mm:ss");
  };

  getMomentPreviousMonth = (date) => {
    let month = (Number(date.format("MM")) - 1).toString();
    if (month.length < DIGIT_COUNT) {
      month = "0" + month;
    }
    return month;
  };

  getMomentYear = (date) => {
    return date.format("YYYY");
  };

  getMomentday = (date) => {
    let day = date.format("DD");
    if (day.length < DIGIT_COUNT) {
      day = "0" + day;
    }
    return day;
  };

  unixTimestamp = () => moment().unix();

  getDateOnly = (dateWithTime: Date) => moment(dateWithTime).startOf("day");
}
