import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { CheckAuthorization } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// @ts-ignore
import { RouteEnum } from "TARGET_BUILD/config";
import { setTransferCartItem } from "../../../actions/transferCartActions";
import { redirect } from "../../../utils/sessionUtils";
import { getTransferCartListCount } from "../../../utils/transferCartUtils";
import { ITransferCartProps } from "./iTransferCart";

export const StyledCartText = styled.span`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 16px;
  color: ${(props) => props.theme.colors.base.steel};
  margin-left: 16px;
`;

export const StyledTransferCart = styled("div")`
  position: relative;
  padding: 12px 16px 12px 8px;
  cursor: ${(props) => (props.isTransferCartSelected ? "default" : "pointer")};
  display: inline-flex;
  align-items: center;
  border-bottom: ${(props) => (props.isTransferCartSelected ? `2px solid ${props.theme.colors.base.hiltiRed}` : "")};
  height: 46px;
`;

export const CountWrapper = styled.span`
  position: absolute;
  left: 30px;
  top: 4px;
  height: 16px;
  text-align: center;
  padding: 0 5px;
  overflow: hidden;
  display: flex;
  background-color: ${(props) => props.theme.colors.base.hiltiRed};
  color: ${(props) => props.theme.colors.base.white};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.small};
  border-radius: 50px;
`;

interface ITransferCartState {
  isTransferCartSelected: boolean;
}

export class TransferCart extends React.Component<ITransferCartProps, ITransferCartState> {
  constructor(props) {
    super(props);
    const path = this.props.location ? this.props.location.pathname : "";
    this.state = {
      isTransferCartSelected: path.indexOf(RouteEnum.TRANSFER_CART) > -1,
    };
  }
  componentDidMount() {
    this.props.setTransferCartItemToStore();
  }
  showTransferCartList = () => {
    redirect(RouteEnum.TRANSFER_CART, this.props.history);
    this.setState({ isTransferCartSelected: true });
  };

  getTransferCartView = () => {
    const { isTransferCartLoaded, translate, transferCartItems } = this.props;
    return (
      <StyledTransferCart
        isTransferCartSelected={this.state.isTransferCartSelected}
        onClick={this.showTransferCartList}
        isTransferCartLoaded={isTransferCartLoaded}
      >
        <SquareIcon
          name={transferCartItems && transferCartItems.length > 0 ? IconName.TransferCartFull : IconName.TransferCart}
          id="cartImageId"
        />
        {transferCartItems && transferCartItems.length > 0 && (
          <CountWrapper>{getTransferCartListCount(transferCartItems)}</CountWrapper>
        )}
        <StyledCartText id="cartImageText">{translate("common:TRANSFER_CART")}</StyledCartText>
      </StyledTransferCart>
    );
  };

  render() {
    return <CheckAuthorization yes={this.getTransferCartView} permissionKey={"asset.transfer.view"} />;
  }
}

export const mapStateToProps = (state) => ({
  isTransferCartLoaded: state.TransferCart && state.TransferCart.isTransferCartLoaded,
  transferCartItems: state.TransferCart && state.TransferCart.list,
});

export const mapDispatchToProps = (dispatch) => ({
  setTransferCartItemToStore: (item) => dispatch(setTransferCartItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransferCart));
