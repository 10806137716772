import styled from "styled-components";

/**
 * @const StyledBannerWrapper - Styled Component that will act as a container for other component.
 * It is having the an icon at the right of the content rows (::before).
 */
const StyledBannerWrapper = styled("div")`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  align-items: center;
  content: "";
  width: 100%;
  height: 150px;
  background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
  margin-bottom: -4px;
  justify-content: center;
`;

/**
 * @export StyledBannerWrapper -  Styled Component.
 */
export default StyledBannerWrapper;
