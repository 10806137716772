import React from "react";
const SVG = () => (
  <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-795.000000, -1037.000000)" fill="#524F53">
        <g transform="translate(793.000000, 1033.000000)">
          <path
            d="M17,20 L17,14 L7,14 L7,20 L2,20 L2,9 L12,4 L22,9 L22,20 L17,20 Z M8,18 L10,18 L10,20 L8,20 L8,18 Z M8,15 L10,15 L10,17 L8,17 L8,15 Z M11,18 L13,18 L13,20 L11,20 L11,18 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
