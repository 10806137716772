import { captureMessage, Severity } from "@sentry/react";
import { Scope } from "@sentry/types";
import { FEATURES } from "../../featureFlagManager/featureConfig";
import SingletonFeatureManager from "../../featureFlagManager/featureFlagManager";
import store, { RootState } from "../store/store";
import { getTransferCartIdFromStorage } from "./transferCartUtils";

export const logMessage = (message: string) => {
  const state = store.getState() as RootState;

  const workerId = state?.UserReducer?.userMeData?.workerId;
  if (SingletonFeatureManager.isFeatureEnabled(FEATURES.WEB_SENTRY_DEBUGGING)) {
    const transferCartID = getTransferCartIdFromStorage();
    captureMessage(`${message}`, (scope: Scope) => {
      scope.setLevel(Severity.Info);
      scope.setTags({ logs: "transferLogs", transferCartID: transferCartID });
      scope.setUser({
        workerId,
      });
      return scope;
    });
  }
};
