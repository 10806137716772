import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { DownloadFileHandler } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import CommonApi from "../../api/commonApi";
import { ellipsis } from "../../utils/styledUtils";
import IAttachmentsListProps from "./iAttachmentListProps";

const List = styled("div")`
  display: flex;
  flex-wrap: wrap;
  > div:nth-child(odd) {
    margin-right: 16px;
  }
`;

export const Attachment = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: flex-start;
  background: ${(props) => props.theme.colors.divBorder.backgroundLightGrey};
  padding: 12px 16px;
  width: 460px;
  height: 64px;
  margin: 24px 0 0 0;
  cursor: default;
`;

export const Title = styled("div")`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  ${ellipsis("400px")}
`;

const Wrapper = styled("div")`
  width: 400px;
`;

const Download = styled("div")`
  top: 16px;
  position: relative;
  cursor: pointer;
`;

export const FileName = styled("div")`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  ${ellipsis("400px")}
`;
class AttachmentsList extends React.Component<IAttachmentsListProps> {
  downloadDocument = (id, fileName) => {
    DownloadFileHandler.download(CommonApi, id, fileName);
  };
  render() {
    const { attachments } = this.props;
    return (
      <List>
        {attachments &&
          attachments.map((attachment, index) => {
            return (
              <Attachment id={`item-${attachment.attachmentId}`} key={`attachments${attachment.attachmentId}${index}`}>
                <Wrapper>
                  <Title>{attachment.displayName || ""}</Title>
                  <FileName>{attachment.fileName || ""}</FileName>
                </Wrapper>
                <Download onClick={() => this.downloadDocument(attachment.attachmentId, attachment.fileName)}>
                  <SquareIcon name={IconName.Download} id="downloadFileImgId" />
                </Download>
              </Attachment>
            );
          })}
      </List>
    );
  }
}

export default withTranslation()(AttachmentsList);
