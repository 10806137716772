import React from "react";
import styled from "styled-components";
import Field from "../Field/field";
import FormInput from "../Input/formInput";
import IInputProps from "./iPanelHolderInputProps";

// @ts-ignore
import iconSearch from "./search.svg";

interface ICommonIconUrl {
  readonly iconUrl?: string;
}
const StyledInput = styled.input`
  width: 190px;
  height: 44px;
  padding-left: 10px;
  border: 0;
  font-size: ${(props) => props.theme.fontSize.medium};
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

/**
 * Wrapper box for the component.
 */
const StyledBox = styled("div")`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  height: 48px;
  width: 238px;
  margin: 0 16px;
  border: 2px solid
    ${(props) =>
      props["data-error"] ? props.theme.colors.base.hiltiRedError : props.theme.colors.base.heavyConcrete60};
`;

/**
 * Search icon and aligned to the left of the input control.
 */
const StyledIcon = styled("div")`
  border-left: 0px;
  width: 44px;
  height: 44px;
  background: url(${(props: ICommonIconUrl) => props.iconUrl}) center no-repeat
    ${(props) => props.theme.colors.base.heavyConcrete20};
  background-size: 23px;
`;

class Input extends React.Component<IInputProps> {
  inputRef: React.RefObject<any> = React.createRef();
  /**
   * @description onKeyPress method trigger on key press
   * param event is handle the target
   */
  onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    const { onKeyPress } = this.props;
    if (onKeyPress) {
      onKeyPress(event);
    }
  }
  /**
   * @description onFocus method handle the focus of input
   */
  onFocus() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  setInputRef = (element) => {
    this.inputRef = element;
  };

  render() {
    const { value, name, label, disabled, onChange, placeholder, maxLength, error } = this.props;

    return !this.props.isFormControl ? (
      <Field name={name} htmlFor={name} label={label} error={error} touched={true}>
        <StyledBox id="searchContainer" data-error={error}>
          <StyledInput
            id={name}
            name={name}
            value={value}
            disabled={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => this.onKeyPress(event)}
            maxLength={maxLength}
            placeholder={placeholder}
            ref={this.setInputRef}
          />
          <StyledIcon iconUrl={iconSearch} />
        </StyledBox>
      </Field>
    ) : (
      <FormInput
        name={this.props.name}
        label={this.props.label}
        type={this.props.type}
        placeholder={this.props.placeholder}
        validation={this.props.validation}
        required={this.props.required}
      />
    );
  }
}

export default Input;
