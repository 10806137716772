import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { SelectAutoComplete } from "../../elements";
import { default as TextField } from "../../elements/Field/field";
import ICommonProps from "../commonInterfaceProps/iCommonProps";
import IconComponent from "../icons/iconComponent/iconComponent";
import { IconName } from "../icons/iconEnum";
import IDropdownOrInfoIconProps from "./iDropdownOrInfoIconProps";
const TextIconStyle = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextHeadingStyle = styled(TextField)`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
`;

export const TextValueStyle = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 17px;
  margin-right: 16px;
  margin-top: 4px;
  text-align: left;
`;

/**
 * common Interface implementaion
 */
type DropdownOrInfoIconProps = IDropdownOrInfoIconProps & ICommonProps;

/**
 * @description - A class for Dropdown or Info Icon Component
 *
 * @class DropdownOrInfoIcon
 * @extends {React.PureComponent<dropDownProps, {}>}
 */
class DropdownOrInfoIcon extends React.PureComponent<DropdownOrInfoIconProps, {}> {
  static defaultProps = {
    showIcon: true,
  };
  getContentVal = () => this.props.getContent(this.props.textValue);
  renderTextControl = (selectedTextLabel) => {
    const { id, textHeading, place, textValue, showIcon } = this.props;
    return (
      <TextHeadingStyle name={textHeading} htmlFor={textHeading} label={textHeading}>
        <TextIconStyle>
          <TextValueStyle>{textValue || selectedTextLabel}</TextValueStyle>
          {showIcon && (
            <IconComponent
              icon={IconName.Info}
              id={id}
              isIconDisabled={true}
              getContent={this.getContentVal}
              place={place}
            />
          )}
        </TextIconStyle>
      </TextHeadingStyle>
    );
  };

  renderFieldControl = (fieldVal) => {
    const { blankLiterals, isFormControl } = this.props;
    const selectedOption =
      isFormControl && fieldVal.data ? fieldVal.data.filter((option) => option.id === fieldVal.input.value) : [];
    let selectedTextLabel = selectedOption.length > 0 ? selectedOption[0].name : "";
    selectedTextLabel = selectedTextLabel ? selectedTextLabel : blankLiterals;
    return this.renderTextControl(selectedTextLabel);
  };

  renderField = (id, name, isFormControl) => {
    if (isFormControl) {
      return <Field id={id} name={name} component={this.renderFieldControl} {...this.props} />;
    } else {
      return this.renderTextControl;
    }
  };

  render() {
    const {
      autoComplete,
      data,
      id,
      isFormControl,
      label,
      name,
      placeholder,
      validation,
      showDropdown,
      required,
      onSelectChange,
      valueKey,
      valueId,
      menuPlacement,
      isDisabled,
      isClearable,
    } = this.props;

    if (showDropdown) {
      return (
        <SelectAutoComplete
          autoComplete={autoComplete}
          className="form-control"
          id={id}
          isFormControl={isFormControl}
          label={label}
          name={name}
          required={required}
          validation={validation}
          placeholder={placeholder}
          onSelectChange={onSelectChange}
          data={data}
          icon="search"
          valueKey={valueKey}
          valueId={valueId}
          menuPlacement={menuPlacement}
          isDisabled={isDisabled}
          isClearable={isClearable}
        />
      );
    } else {
      return this.renderField(id, name, isFormControl);
    }
  }
}

export default DropdownOrInfoIcon;
