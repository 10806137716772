import React from "react";
// @ts-ignore
import styled from "custom-styled-components";
import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { HelperLodash } from "am-web-ui-shared/helpers";
import {
  getLocationSource,
  getSourceTrackedByHeavyEquipment,
  isTrackedByGlobal,
} from "../../../../utils/activeTrackingUtils";
import { ServiceProviderCode } from "../../../../utils/enums/activeTrackingEnum";
import { AssetRedirectionType, getAssetSubType } from "./helpers";
import { IconBold, IconNormalBold, NormalBoldWithTooltip } from "../../../../components/DetailsPanel";
import AssetBleMap from "../../../../components/maps/assetBLEMap";
import {
  IValueWithTranslationProps,
  ITrackingPayloadWithTranslation,
  IAssetPosition,
  IFieldWithTooltipAndRedirectProps,
  IAddress,
} from "./interfaces";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { TParam } from "TARGET_BUILD/localization/types";
import { useTranslation } from "react-i18next";

export const RedirectionIcon = styled(SquareIcon)`
  cursor: pointer;
`;

const RedirectionField = styled.div`
  margin: 16px -24px 0px -16px;
  padding: 0px 0px 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  cursor: pointer;
  span {
    margin: 0px !important;
    align-self: auto !important;
  }
  .row-extra-space {
    padding: 0px 0px 0px 40px;
  }
  ${(props) =>
    !props.isSummary &&
    `
     margin: 8px 0px 0px;
     padding: 0px;
 `}
`;

const AssetMapContainer = styled.div`
  &&& {
    width: 100%;
    padding: 0px;
  }
`;

const NURON_TOOLTIP = "common:NURON_TOOLTIP";
const LABEL_LAST_SEEN = "common:LABEL_LAST_SEEN";
const LABEL_LAST_SEEN_BY = "common:LABEL_LAST_SEEN_BY";
const GATEWAY_TOOLTIP = "common:GATEWAY_TOOLTIP";
const LABEL_TRACKING_GATEWAYS = "common:LABEL_TRACKING_GATEWAYS";
const LABEL_SOURCE = "common:LABEL_LAST_SEEN_SOURCE";
const LABEL_HEAVY_EQUIPMENT_TOOLTIP = "common:TELEMATICS_TITLE_GLOBAL_TOOLTIP";

export const LocationTitleComponent = ({ t }: WithTranslation) => (
  <IconBold
    id="pnlLocationDetails"
    iconUrl={IconName.Map}
    boldId="lblLocationDetails"
    boldText={t("common:LABEL_LOCATION_DETAILS")}
  />
);

export const LocationTitle = withTranslation()(LocationTitleComponent);

export const StorageLocationComponent = ({ value, t }: IValueWithTranslationProps) => {
  return value ? (
    <IconNormalBold
      id="pnlStorageLocation"
      normalId="lblStorageLocation"
      normalText={t("common:LABEL_STORAGE_LOCATION")}
      boldId="txtStorageLocation"
      boldText={value}
    />
  ) : null;
};

export const StorageLocation = withTranslation()(StorageLocationComponent);

export const OwnerShipTypeComponent = ({ value, t }: IValueWithTranslationProps) =>
  value ? (
    <IconNormalBold
      id="pnlOwnerShipType"
      normalId="lblOwnerShipType"
      normalText={t("assets:OWNERSHIP_TYPE")}
      boldId="txtOwnerShipType"
      boldText={value}
    />
  ) : null;

export const OwnerShipType = withTranslation()(OwnerShipTypeComponent);

export const FieldWithTooltipAndRedirectComponent = (props: IFieldWithTooltipAndRedirectProps) => {
  const { label, value, onBeforeRedirect, clearAllSelection, history, t } = props;
  if (!value) {
    return null;
  }
  const redirectionDetails = HelperLodash.get(props, "redirectionDetails", {});
  const { id, name, type } = redirectionDetails;
  const assetName = name ? name : " ";
  const redirect = () => {
    const initialFilterObject =
      type === AssetRedirectionType.CHARGER
        ? {
            chargerName: [assetName],
          }
        : {
            assetName: [assetName],
          };
    if (clearAllSelection) {
      clearAllSelection("CLEAR_ALL_ASSETS_SELECTION");
    }
    const redirectObject = {
      pathname: "/",
      state: {
        additionalData: {
          assetId: id,
        },
        initialFilterValue: [initialFilterObject],
      },
    };
    if (onBeforeRedirect) {
      onBeforeRedirect();
    }
    history.push(redirectObject);
    window.scrollTo(0, 0);
  };
  return (
    <RedirectionField isSummary={props.isSummary}>
      <NormalBoldWithTooltip
        id={`pnl${label}`}
        title={t(label)}
        value={t(value)}
        tooltipValue={redirectionDetails?.type === AssetRedirectionType.CHARGER ? t(NURON_TOOLTIP) : null}
        tooltipId={`pnl${label}Tooltip`}
      />
      {redirectionDetails?.id ? <RedirectionIcon name={IconName.Chevron} onClick={redirect} /> : null}
    </RedirectionField>
  );
};

export const FieldWithTooltipAndRedirect = withRouter(withTranslation()(FieldWithTooltipAndRedirectComponent));

export const AssetPosition = (props: IAssetPosition) => {
  const { tool, trackingPayload } = props;
  const isAssetBLEEnabled = !!(tool && (trackingPayload?.position?.latitude || trackingPayload?.position?.longitude));
  const currentLocation = HelperLodash.get(tool, "currentLocationName", "");
  const inventoryNumber = HelperLodash.get(tool, "inventoryNumber", "");
  const scanTimestamp = HelperLodash.get(trackingPayload, "scanTimestamp", "");
  const lastSeen = HelperLodash.get(trackingPayload, "lastSeen", "");
  const name = HelperLodash.get(tool, "name", "");
  const scanCode = HelperLodash.get(tool, "scanCode", "");
  const assetStatusResponseCode = HelperLodash.get(tool, "assetStatusResponse.code");
  const assetStatusResponseValue = HelperLodash.get(tool, "assetStatusResponse.value");
  const currentLocationStateValue = HelperLodash.get(tool, "currentLocationState.value", "");
  const manufacturerModel = `${tool && "manufacturerName" in tool ? tool.manufacturerName : ""} ${tool?.model || ""}`;
  const scanCodeTypeDetailsValue = HelperLodash.get(tool, "scanCodeTypeDetails.value", "");
  return isAssetBLEEnabled ? (
    <AssetMapContainer>
      <AssetBleMap
        currentLocationName={currentLocation}
        inventoryNumber={inventoryNumber}
        lastUpdate={scanTimestamp}
        lastSeen={lastSeen}
        locationType={currentLocationStateValue}
        manufacturerModel={manufacturerModel}
        name={name}
        scanCode={scanCode}
        statusCode={assetStatusResponseCode}
        statusValue={assetStatusResponseValue}
        scanCodeTypeDetails={scanCodeTypeDetailsValue}
        positionInfo={trackingPayload}
        assetSubType={getAssetSubType(tool)}
      />
    </AssetMapContainer>
  ) : null;
};

export const LastSeenField = (props: ITrackingPayloadWithTranslation) => {
  const { trackingPayload, t } = props;
  const lastSeen = HelperLodash.get(trackingPayload, "lastSeen", "");
  const label: TParam = LABEL_LAST_SEEN;
  return lastSeen ? (
    <IconNormalBold
      id="pnlLastSeen"
      normalId="lblLastSeen"
      normalText={t(label)}
      boldId="txtLastSeen"
      boldText={lastSeen}
    />
  ) : null;
};
export const LastSeen = withTranslation()(LastSeenField);

export const ConfirmedLocationNameComponent = (props: ITrackingPayloadWithTranslation) => {
  const { trackingPayload, t } = props;
  const gatewayLocationName = HelperLodash.get(trackingPayload, "gatewayLocationName", "");
  return gatewayLocationName ? (
    <NormalBoldWithTooltip
      id="pnlConfirmedAt"
      title={t(LABEL_LAST_SEEN_BY)}
      value={gatewayLocationName}
      tooltipValue={t(GATEWAY_TOOLTIP)}
      tooltipId="pnlConfirmedAtTooltip"
    />
  ) : null;
};
export const ConfirmedLocationName = withTranslation()(ConfirmedLocationNameComponent);

export const TrackingGatewaysComponent = (props: ITrackingPayloadWithTranslation) => {
  const { trackingPayload, t } = props;
  const gateways = HelperLodash.get(trackingPayload, "gateways", []).filter((item) => item);
  const displayGateways = gateways.join(", ");
  const serviceProviderCode = HelperLodash.get(trackingPayload, "serviceProviderCode", "");
  const value = getLocationSource(serviceProviderCode);
  return gateways.length || serviceProviderCode === ServiceProviderCode.Gateway ? (
    <>
      {gateways.length > 0 && (
        <IconNormalBold
          id="pnlTrackingGateways"
          normalId="lblTrackingGateways"
          normalText={t(LABEL_TRACKING_GATEWAYS)}
          boldId="txtTrackingGateways"
          boldText={displayGateways}
        />
      )}
      {serviceProviderCode === ServiceProviderCode.Gateway && (
        <IconNormalBold
          id="pnlSource"
          normalId="lblSource"
          normalText={t(LABEL_SOURCE)}
          boldId="txtSource"
          boldText={t(value)}
        />
      )}
    </>
  ) : null;
};
export const TrackingGateways = withTranslation()(TrackingGatewaysComponent);

export const TrackingByHeavyEquipmentComponent = (props: ITrackingPayloadWithTranslation) => {
  const { trackingPayload, t } = props;
  const serviceProviderCode = HelperLodash.get(trackingPayload, "serviceProviderCode", "");
  const value = getSourceTrackedByHeavyEquipment(serviceProviderCode, trackingPayload);
  return value ? (
    <>
      <NormalBoldWithTooltip
        id={`pnlSource`}
        title={t(LABEL_SOURCE)}
        value={t(value)}
        tooltipValue={isTrackedByGlobal(trackingPayload) ? t(LABEL_HEAVY_EQUIPMENT_TOOLTIP) : null}
        tooltipId={`pnlSource`}
        isPadding={false}
      />
    </>
  ) : null;
};

export const TrackingByHeavyEquipment = withTranslation()(TrackingByHeavyEquipmentComponent);

export const AddressField = (props: IAddress) => {
  const { address } = props;
  const { t } = useTranslation();
  return address ? (
    <IconNormalBold
      id="pnlLastSeen"
      normalId="lblLastSeen"
      normalText={t("common:LABEL_LAST_SEEN_AT")}
      boldId="txtLastSeen"
      boldText={address}
    />
  ) : null;
};
export const Address = withTranslation()(AddressField);
