import { ISagaModule } from "redux-dynamic-modules-saga";
import uniteReducer from "./uniteReducer";
import uniteSaga from "./uniteSaga";
import IUniteModuleState from "./IUniteModuleState";

const uniteModule: ISagaModule<IUniteModuleState> = {
  id: "uniteModule",
  reducerMap: {
    Unite: uniteReducer,
  },
  sagas: [uniteSaga],
};

export default uniteModule;
