import { ISagaModule } from "redux-dynamic-modules-saga";
import IDocumentModuleState from "./redux/iDocumentModuleState";
import documentReducer from "./redux/documentReducer";
import documentSaga from "./redux/documentSaga";

const documentModule: ISagaModule<IDocumentModuleState> = {
  id: "documentModule",
  reducerMap: {
    Document: documentReducer,
  },
  sagas: [documentSaga],
};

export default documentModule;
