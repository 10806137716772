const CustomViewActionsConstants = {
  GET_ASSETS_CUSTOM_GRID_FIELDS: "GET_ASSETS_CUSTOM_GRID_FIELDS",
  GET_ASSETS_CUSTOM_GRID_RESPONSE: "GET_ASSETS_CUSTOM_GRID_RESPONSE",
  OM_GET_LOCATIONS_CUSTOM_GRID_FIELDS: "OM_GET_LOCATIONS_CUSTOM_GRID_FIELDS",
  OM_GET_LOCATIONS_CUSTOM_GRID_RESPONSE: "OM_GET_LOCATIONS_CUSTOM_GRID_RESPONSE",
  OM_GET_WORKERS_CUSTOM_GRID_FIELDS: "OM_GET_WORKERS_CUSTOM_GRID_FIELDS",
  OM_GET_WORKERS_CUSTOM_GRID_RESPONSE: "OM_GET_WORKERS_CUSTOM_GRID_RESPONSE",
  GET_QTY_ITEM_CUSTOM_GRID_FIELDS: "GET_QTY_ITEM_CUSTOM_GRID_FIELDS",
  GET_QTY_ITEM_CUSTOM_GRID_RESPONSE: "GET_QTY_ITEM_CUSTOM_GRID_RESPONSE",
};

export default CustomViewActionsConstants;
