import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M20.9984377,15.46 L15.7284377,14.85 L14,16.5 C11.17,15.06 8.94,12.84 7.5,10 L9.14843768,8.25 L8.53843768,3 L3.02843768,3 C2.44843768,13.18 10.8184377,21.55 20.9984377,20.97 L20.9984377,15.46 Z" />
    </g>
  </svg>
);

export default SVG;
