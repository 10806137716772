// @ts-ignore
import { MultipleNotificationModal, NotificationModal } from "am-web-ui-shared/components";
import { LoaderWrapper } from "am-web-ui-shared/elements";
import React from "react";
import { connect } from "react-redux";
import shortid from "shortid";
// @ts-ignore
import styled from "custom-styled-components";
// @ts-ignore
import FailureIcon from "TARGET_BUILD/ui-assets/icons/error.svg";
// @ts-ignore
import InfoIcon from "TARGET_BUILD/ui-assets/icons/icAlertInfoWhite.svg";
// @ts-ignore
import successIcon from "TARGET_BUILD/ui-assets/icons/positive_feedback_small.svg";
// @ts-ignore
import WarningIcon from "TARGET_BUILD/ui-assets/icons/WarningIcon.svg";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { closeMoreFilter } from "../actions/moreFilterAction";
import { closeMultipleToastr, closeToastr, ToastrType } from "../actions/toastrAction";
import NavBar from "../components/navBar";
import { storageGetItem } from "../utils/storageUtils";
import Header from "./header/container/header";
import ILayoutProps from "./iLayoutProps";
import { getTranslationKeyWithoutQty } from "../utils/qtyItemUtils";
import { QtyItemErrorCodes } from "../utils/enums/qtyItemEnum";
import { LicenseTypes } from "../utils/enums/licenseTypesEnum";
import { SharedUtils, HelperLodash } from "am-web-ui-shared/helpers";
import Banners from "./stickyBanner/banners";
import { IGtmData } from "../models/iGTMModel";
import LicenseAgreement from "./licencesAgreement/licenseAgreement";
import { LicenseBundleTypes } from "../utils/enums/licenseBundleEnum";
import { isFleetTenant } from "../utils/userUtils";

/**
 * @interface IRoot for hide the layout.
 *
 * @props {boolean}: hide the layout is path is "login"
 */
interface IRoot {
  isHide: boolean;
}
const Root = styled.div`
  display: ${(props: IRoot) => (props.isHide ? "none" : "block")};
`;
const HeaderWrapper = styled.header``;
const NotificationContainer = styled.div`
  display: block;
`;

const Main = styled.main``;
const modalRoot = document.getElementById("modalRoot");

export class Layout extends React.Component<ILayoutProps> {
  userToken;
  constructor(props) {
    super(props);
    this.userToken = storageGetItem(appConstants.USER_TOKEN);
  }

  getToastrIcon = (type) => {
    switch (type) {
      case ToastrType.error:
        return FailureIcon;
      case ToastrType.success:
        return successIcon;
      case ToastrType.info:
        return InfoIcon;
      case ToastrType.warning:
        return WarningIcon;
      default:
        return FailureIcon;
    }
  };

  componentDidUpdate() {
    if (
      modalRoot.querySelector("#dialogPortal") &&
      this.props.activityTracker &&
      !this.props.activityTracker.isInprogress
    ) {
      window.scrollTo(0, 0);
      document.getElementById("root").style.visibility = "hidden";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    if (
      modalRoot.querySelector("#dialogPortal") &&
      this.props.activityTracker &&
      this.props.activityTracker.isInprogress
    ) {
      window.scrollTo(0, 0);
      document.getElementById("root").style.visibility = "visible";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }

  /**
   * function used to close toastr notification
   * @function
   * @memberof Layout
   */
  closeToastrNotification = () => {
    this.props.closeToastr();
  };

  /**
   * method to show/hide container
   */
  hideLayout = (): boolean => {
    const noUserToken = !Boolean(this.userToken);
    const url = window.location.href;
    if (process.env.NODE_ENV === "development") {
      return false;
    } else {
      return url.includes("login") && noUserToken;
    }
  };

  getNotificationModal = (notificationData) => {
    const icons = {
      default: FailureIcon,
      error: FailureIcon,
      info: InfoIcon,
      success: successIcon,
      warning: WarningIcon,
    };
    return this.props.showMultipleNotification ? (
      <MultipleNotificationModal
        icons={icons}
        data={notificationData}
        translate={this.props.translate}
        t={this.props.t}
        handleClose={this.props.closeMultipleToastr}
      />
    ) : (
      this.props.showNotification &&
        !this.props.activityTracker.isInprogress &&
        notificationData.map((toastrData, index) => {
          const { toastrMessage, type, gtmEvent, errorCode, toastrActionInfo } = toastrData;
          const message = this.props.translate ? this.props.t(toastrMessage) : toastrMessage;

          return (
            <NotificationContainer key={shortid.generate()}>
              <NotificationModal
                key={shortid.generate()}
                actionInfo={
                  toastrActionInfo && { name: toastrActionInfo.name, clickHandler: toastrActionInfo.clickHandler }
                }
                notificationIcon={this.getToastrIcon(type)}
                message={
                  message && message.indexOf && message.indexOf("errorCodes:") === 0
                    ? this.props.t("common:ERROR_SOMETHING_WENT_WRONG")
                    : message
                }
                handleClose={this.props.showNotification ? this.props.closeToastr : this.props.closeMultipleToastr}
                className={`styleNotify${index}`}
                gtmEvent={gtmEvent}
                gtmData={this.prepareGTMData(type, errorCode, message)}
              />
            </NotificationContainer>
          );
        })
    );
  };

  prepareGTMData = (type: string, code: string | number, message: string) => {
    let gtmObj: IGtmData | undefined;
    if (type === ToastrType.error && code) {
      gtmObj = SharedUtils.getGTMDataObject(code, message);
    }

    return gtmObj || {};
  };

  getActivityTracker = () => {
    const { activityTracker, t } = this.props;
    return (
      activityTracker && (
        <LoaderWrapper loadingText={t("common:LOADING")} activity={this.props.activityTracker.isInprogress} />
      )
    );
  };

  render() {
    const {
      children,
      noHeader,
      notificationMessage,
      t,
      toastrDynamicMessageParams,
      licenseType,
      legalAgreementFlag,
      gtmEvent,
      countryCode,
      toastrActionInfo,
      licenseContext,
      appContextInit,
      licenseBundle,
    } = this.props;

    const hide = this.hideLayout();

    let notificationMsg = notificationMessage ? notificationMessage : "";
    const errorCode = notificationMsg && HelperLodash.get(notificationMsg.split(":"), "[1]");
    if (errorCode && QtyItemErrorCodes.includes(errorCode)) {
      notificationMsg = getTranslationKeyWithoutQty(notificationMessage);
    }

    if (toastrActionInfo?.name) {
      toastrActionInfo.name = t(toastrActionInfo.name);
    }

    const notificationMsgV0 = notificationMsg ? `${notificationMsg}_V0` : "";
    const notificationData = this.props.showMultipleNotification
      ? this.props.toastrData
      : [
          {
            toastrMessage: toastrDynamicMessageParams
              ? t([notificationMsgV0, notificationMsg], toastrDynamicMessageParams)
              : t([notificationMsgV0, notificationMsg]),
            type: this.props.toastrType,
            gtmEvent,
            errorCode,
            toastrActionInfo,
          },
        ];

    const appInitialized = this.userToken && appContextInit;
    return (
      <Root isHide={hide}>
        {!noHeader && (
          <HeaderWrapper>
            <Header closeMoreFilter={this.props.closeMoreFilter} translate={t} licenseContext={licenseContext} />
            {appInitialized && <NavBar />}
          </HeaderWrapper>
        )}
        <Main className="main">{children}</Main>
        {this.getNotificationModal(notificationData)}
        {this.getActivityTracker()}

        {!legalAgreementFlag && isFleetTenant(licenseType, licenseBundle) && (
          <LicenseAgreement countryCode={countryCode} />
        )}
      </Root>
    );
  }
  componentDidMount() {
    const rootDocument = document.getElementById("root");
    if (rootDocument && rootDocument.addEventListener) {
      rootDocument.addEventListener("click", this.closeToastrNotification);
    }
  }

  componentWillUnmount() {
    const rootDocument = document.getElementById("root");
    if (rootDocument && rootDocument.removeEventListener) {
      rootDocument.removeEventListener("click", this.closeToastrNotification);
    }
  }
}

const getGTMEvent = (state) => state.toastr && state.toastr.gtmEvent;

export const mapStateToProps = (state) => ({
  activityTracker: state.ActivityTracker,
  notificationMessage: state.toastr && state.toastr.toastrMessage,
  showMultipleNotification: state.toastr && state.toastr.showMultipleNotification,
  showNotification: state.toastr && state.toastr.showNotification,
  toastrData: state.toastr && state.toastr.toastrData,
  toastrDynamicMessageParams: state.toastr && state.toastr.toastrDynamicMessageParams,
  toastrType: state.toastr && state.toastr.toastrType,
  toastrActionInfo: state.toastr && state.toastr.toastrActionInfo,
  translate: state.toastr && state.toastr.translate,
  gtmEvent: getGTMEvent(state),
  legalAgreementFlag: state.UserReducer?.userMeData?.legalAgreementFlag,
  licenseType: state.UserReducer?.userMeData?.licenseType,
  countryCode: state.Company?.companyDetails?.country?.code,
  tenantCategory: state.Company?.companyDetails?.tenantCategory,
  licenseBundle: state.Company?.companyDetails?.licenseBundle,
});

export const mapDispatchToProps = (dispatch) => ({
  closeMoreFilter: () => dispatch(closeMoreFilter()),
  closeMultipleToastr: () => dispatch(closeMultipleToastr()),
  closeToastr: () => dispatch(closeToastr()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
