import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SelectChangeEvent } from "@mui/material/Select";
import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { acceptLiceseAgreement } from "../../api/userProfileApi";
import ILicenseAgreementProps from "./iLicenseAgreementProps";
import { userMeData } from "../../../common/actions/userProfileActionRoot";
import { useAppDispatch } from "../../store/store";
import {
  getLanguageTermsAndCondition,
  getLicenseAgreementContent,
  ILanguage,
} from "TARGET_BUILD/common/api/licenseAgreementApi";
import DropDown from "TARGET_BUILD/../../component-lib/src/core/dropDown";
import CheckBox from "@am/web-ui-components/core/checkbox";
import Button from "@am/web-ui-components/core/button";
import { MenuProps } from "@mui/material/Menu";
import {
  StyledCheckboxLabel,
  StyledLinkText,
  StyledAsterix,
  ValidationText,
  StyledDialog,
  FlexContainer,
  StyledHeader,
  Form,
  StyledIcon,
  StyledFooterText,
  Separator,
  LinkWrapper,
} from "./licenseAgreementStyles";

const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: 50 * 4.5,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

export const LicenseAgreement = ({ countryCode }: ILicenseAgreementProps) => {
  const country = countryCode?.toLowerCase();
  const languageCode = localStorage.getItem("i18nextLng");
  const language = languageCode?.substring(0, languageCode?.indexOf("-"));

  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState<ILanguage[]>([]);
  const [dialogContent, setDialogContent] = useState("");
  const dispatch = useAppDispatch();
  const [defaultLanguage, setDefaultLanguage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [selectedLanguagecode, setSelectedLanguagecode] = useState<string>(country);

  useEffect(() => {
    checked && setError(false);
  }, [checked]);

  const getPrivacyLink = (lan: string): string => {
    switch (lan) {
      case "de":
        return "https://www.hilti.de/content/hilti/E3/DE/de/company/hilti-website/datenschutzerklaerung.html";
      case "fr":
        return "https://www.hilti.fr/content/hilti/E2/FR/fr/france/footer/politique-confidentialite.html";
      case "se":
        return "https://www.hilti.se/content/hilti/E1/SE/sv/various/footer-links/privacy-policy.html";
      case "pl":
        return "https://www.hilti.pl/content/hilti/E3/PL/pl/local-temp/local-temp/datenschutzerklaerung.html";
      case "nl":
        return "https://www.hilti.nl/content/hilti/E3/NL/nl/company/corporate-information/Privacybeleid.html";
      case "us":
        return "https://www.hilti.com/content/hilti/W1/US/en/company/legal-and-footer-information/privacy-policy.html";
      case "ca":
        return "https://www.hilti.ca/content/hilti/W1/CA/en/company/legal-and-footer-information/privacy-policy.html";
      case "gb":
        return "https://www.hilti.co.uk/content/hilti/E1/GB/en/various/footer-links/privacy-policy.html";
      case "it":
        return "https://www.hilti.ch/content/hilti/E3/CH/it/company/hilti-website/datenschutzerklaerung.html";
      default:
        return "https://www.hilti.group/content/hilti/CP/XX/en/non-transactional/footer-seiten/gb_privacy.html";
    }
  };

  const handleChangecheckBox = () => {
    setChecked(!checked);
  };

  const handleCloseDialog = () => {
    if (checked && !error) {
      setIsOpen(false);
      acceptLiceseAgreement().then(() => dispatch(userMeData()));
    } else {
      setError(true);
    }
  };

  const checkboxLabel = () => (
    <StyledCheckboxLabel>
      <span>{t("common:FLEET_LICENSE_AGREEMENT_CONFIRMATION_TEXT")}</span>
      <Link
        to={{
          pathname: getPrivacyLink(selectedLanguagecode),
        }}
        target="_blank"
        rel="noopener noreferrer"
        data-testId="license-policy-link"
      >
        <LinkWrapper>
          <StyledLinkText>{t("common:FLEET_LICENSE_AGREEMENT_CONFIRMATION_LINK_TEXT")}</StyledLinkText>
          <SquareIcon name={IconName.OpenNew} imageSize={12} />
          <StyledAsterix>*</StyledAsterix>
        </LinkWrapper>
      </Link>
      {!checked && error && <ValidationText>{t("common:FLEET_LICENSE_AGREEMENT_VALIDATION_TEXT")}</ValidationText>}
    </StyledCheckboxLabel>
  );

  const fetchLanguages = async (lang: string) => {
    const res = await getLanguageTermsAndCondition(countryCode);
    setResponse(res);

    let value = res?.filter(({ code }) => code.includes(lang)).map(({ code }) => code);
    if (value.length === 0) {
      value = [res?.[0].code];
    }
    const [codeLanguage, codeCountry] = value.toString().split("-");

    setSelectedLanguagecode(codeLanguage);
    const dialogContent = getLicenseAgreementContent(codeLanguage, codeCountry);
    res?.map(({ code }) => setDefaultLanguage(code));
    setDialogContent(dialogContent);
  };

  useEffect(() => {
    fetchLanguages(language);
  }, [language]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setDefaultLanguage(value);

    const [languageCode, countryCode] = value.split("-");
    setSelectedLanguagecode(languageCode);
    const dialogContent = getLicenseAgreementContent(languageCode, countryCode);
    setDialogContent(dialogContent);
  };

  return (
    <StyledDialog
      open={isOpen}
      header={
        <>
          <FlexContainer>
            <StyledHeader>{t("common:FLEET_LICENSE_AGREEMENT_WELCOME_MESSAGE")}</StyledHeader>
            {response.length > 1 && (
              <Form>
                <StyledIcon>
                  <SquareIcon name={IconName.Translation} imageSize={18} />
                </StyledIcon>
                <DropDown list={response} value={defaultLanguage} onChange={handleChange} menuProps={menuProps} />
              </Form>
            )}
          </FlexContainer>
        </>
      }
      actions={
        <>
          <FlexContainer>
            <StyledFooterText>
              <CheckBox
                data-testId="checkbox"
                error={error}
                onChange={handleChangecheckBox}
                sx={{ height: 16, width: 16 }}
              />
              {checkboxLabel()}
            </StyledFooterText>

            <Button data-testId="confirm-button" onClick={handleCloseDialog}>
              {t("common:CONFIRM")}
            </Button>
          </FlexContainer>
        </>
      }
      handleClose={handleCloseDialog}
      fullScreen={true}
      maxWidth="xl"
    >
      <Separator />
      <iframe
        title="unique title"
        data-testId="iframe"
        src={dialogContent}
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"
      />
    </StyledDialog>
  );
};

export default LicenseAgreement;
