import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "./common/components/layout";
import TermsDialog from "./common/components/termsDialog/termsDialog";
import { useTranslation } from "react-i18next";
import { storageGetItem, storageClearAll, sessionStorageClearAll } from "./common/utils/storageUtils";

import { appConstants } from "./config";
import RoutesConfig, { protectedRoutes, publicRoutes } from "./routesConfig";
import { LicenseContext } from "TARGET_BUILD/licenseStrategy/licenseContext";

const oAuth = () => {
  return process.env.NODE_ENV === "development" ? true : !!storageGetItem(appConstants.USER_TOKEN);
};

const showTerms = () => {
  return !!(
    !JSON.parse(storageGetItem(appConstants.IS_TNC_ACCEPTED)) && JSON.parse(storageGetItem(appConstants.IS_DOMAIN_USER))
  );
};

const renderChildren = (props, route) => {
  const isTokenValid = oAuth();
  if (!isTokenValid) {
    //Clear any previous browser storage
    storageClearAll();
    sessionStorageClearAll();
    return <Redirect to="/login" />;
  } else {
    return showTerms() ? (
      <TermsDialog />
    ) : (
      <Layout t={route.t} appContextInit={route.appContextInit} licenseContext={route.licenseContext}>
        <route.component {...props} routes={route.routes} />
      </Layout>
    );
  }
};

const ProtectedRoutes = (route) => (
  <Route path={route.path} exact={route.exact} children={(props) => renderChildren(props, route)} />
);

interface IRoot {
  appContextInit?: boolean;
  licenseContext?: LicenseContext;
}

const Root = ({ appContextInit, licenseContext }: IRoot) => {
  const { t } = useTranslation();
  const [routes, setRoutes] = useState(protectedRoutes);

  useEffect(() => {
    if (appContextInit) {
      setRoutes(licenseContext.getRoutes());
    }
  }, [appContextInit]);

  return (
    <Switch>
      {publicRoutes &&
        publicRoutes.map((route, i) => (
          <Route
            path={route.path}
            key={i}
            render={(props) => (
              <Layout t={t} key={`${i}_public`} noHeader={route.noHeader}>
                <route.component {...props} />
              </Layout>
            )}
          />
        ))}
      {routes &&
        routes.map((route, i) => {
          if (RoutesConfig.shouldDisplayRoute(route)) {
            return (
              <ProtectedRoutes
                key={`${i}_protected`}
                t={t}
                appContextInit={appContextInit}
                licenseContext={licenseContext}
                {...route}
              />
            );
          }
          return null;
        })}
    </Switch>
  );
};

export default Root;
