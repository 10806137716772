import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
import ApplicationConstants from "../../applicationConstants";
import { AssetSubType, AssetType, CapReachedResponse } from "../../utils/enums/assetEnum";
import IDropDownModel from "../iDropDownModel";
import { TIMEBASED } from "../../components/charges/chargesSchedulerConstants";

export default class PendingCharge {
  pendingCostId: number;
  currentAssetCostDisplay: string;
  dateArrived: string;
  dateLeft: string;
  dailyAssetCostDisplay: string;
  chargeCenter: string;
  workdaysOnSiteDisplay: string;
  assetGroupName: string;
  purchasePrice: string;
  costLocationManagerName: string;
  assetValue: number;
  assetCostType: string;
  daysOnSite: string;
  defaultLocationCustomId: string;
  currentLocationCustomId: string;
  costLocationCustomId: string;
  costLocationCostCenter: string;
  costCategory: string;
  costCategoryResponse: IDropDownModel;
  scanCode: string;
  assetId: number;
  inventoryNumber: string;
  defaultLocationName: string;
  currentLocationName: string;
  ownerShipTypeResponse: IDropDownModel;
  assetCostTypeResponse: IDropDownModel;
  ownerShipType: string;
  assetImageId: string;
  manufacturerName: string;
  model: string;
  description: string;
  costCode: string;
  assetValueDisplay: string;
  costTypeValue: IDropDownModel;
  daysOnSiteDisplay: string;
  costTypeValueForExport: string;
  currentAssetCostForExport: string;
  assetValueForExport: string;
  totalRecords: number;
  serialNumber: string;
  notes: string;
  fleetDeliveryDate: string;
  fleetCostCenter: string;
  monthlyFleetRate: string;
  fleetOrganizationReferenceNumber: string;
  fleetExchangeOrWarrantyDate: string;
  fleetInventoryNumber: string;
  assetStatusName: string;
  assetStatusResponse: IDropDownModel;
  assetName: string;
  labels: string;
  isSelected: boolean;
  currency: string;
  assetType: { code: AssetType; value: string };
  assetSubType: { code: AssetSubType; value: string };
  timeBasedWeeklyDays: string;
  timeBasedMonthlyDays: string;
  timeBasedDailyAssetCost: string;
  timeBasedWeeklyAssetCost: string;
  timeBasedMonthlyAssetCost: string;
  capReached: string;
  cappedAtValue: string;
  capReachedResponse: { code: CapReachedResponse; value: string };
  cappedOnDate: string;

  constructor(response: any) {
    this.pendingCostId = this.getFieldValue(response, "pendingCostId");
    this.dateArrived = this.getFieldValue(response, "dateArrived");
    this.dateLeft = this.getFieldValue(response, "dateLeft");
    this.chargeCenter = this.getFieldValue(response, "chargeCenter");
    this.workdaysOnSiteDisplay = this.getFieldValue(response, "workdaysOnSiteDisplay");
    this.assetGroupName = this.getFieldValue(response, "assetGroupName");
    this.purchasePrice = this.getFieldValue(response, "purchasePrice");
    this.costLocationManagerName = this.getFieldValue(response, "costLocationManagerName");
    this.assetValue = this.getFieldValue(response, "assetValueDisplay");
    this.daysOnSite = this.getFieldValue(response, "daysOnSiteDisplay");
    this.defaultLocationCustomId = this.getFieldValue(response, "defaultLocationCustomId");
    this.currentLocationCustomId = this.getFieldValue(response, "currentLocationCustomId");
    this.costLocationCustomId = this.getFieldValue(response, "costLocationCustomId");
    this.costLocationCostCenter = this.getFieldValue(response, "costLocationCostCenter");
    this.costCategoryResponse = this.getFieldValue(response, "costCategoryResponse");
    this.costCategory = this.getFieldValue(response, "costCategory");
    this.currency = this.getFieldValue(response, "currency");
    this.scanCode = this.getFieldValue(response, "scanCode");
    this.assetId = this.getFieldValue(response, "assetId");
    this.currentAssetCostDisplay = this.formatCostDisplay(this.getFieldValue(response, "currentAssetCost"));
    this.inventoryNumber = this.getFieldValue(response, "inventoryNumber");
    this.defaultLocationName = this.getFieldValue(response, "defaultLocationName");
    this.currentLocationName = this.getFieldValue(response, "currentLocationName");
    this.assetCostType = this.getFieldValue(response, "assetCostType");
    this.dailyAssetCostDisplay =
      this.assetCostType === TIMEBASED
        ? this.formatCostDisplay(this.getFieldValue(response, "timeBasedDailyAssetCost"))
        : this.formatCostDisplay(this.getFieldValue(response, "dailyAssetCost"));

    this.ownerShipTypeResponse = this.getFieldValue(response, "ownerShipTypeResponse");
    this.assetCostTypeResponse = this.getFieldValue(response, "assetCostTypeResponse");
    this.ownerShipType = this.getFieldValue(response, "ownerShipType");
    this.timeBasedWeeklyDays = this.getFieldValue(response, "timeBasedWeeklyDays");
    this.timeBasedMonthlyDays = this.getFieldValue(response, "timeBasedMonthlyDays");
    this.timeBasedDailyAssetCost = this.formatCostDisplay(this.getFieldValue(response, "timeBasedDailyAssetCost"));
    this.timeBasedWeeklyAssetCost = this.formatCostDisplay(this.getFieldValue(response, "timeBasedWeeklyAssetCost"));
    this.timeBasedMonthlyAssetCost = this.formatCostDisplay(this.getFieldValue(response, "timeBasedMonthlyAssetCost"));

    this.assetImageId = this.getFieldValue(response, "assetImageId");
    this.manufacturerName = this.getFieldValue(response, "manufacturerName");
    this.model = this.getFieldValue(response, "model");
    this.description = this.getFieldValue(response, "description");
    this.costCode = this.getFieldValue(response, "costCode");
    this.assetValueDisplay = this.getFieldValue(response, "assetValueDisplay");
    this.costTypeValue = this.getFieldValue(response, "costTypeValue");
    this.daysOnSiteDisplay = this.getFieldValue(response, "daysOnSiteDisplay");
    this.costTypeValueForExport = this.getFieldValue(response, "costTypeValueForExport");
    this.currentAssetCostForExport = this.getFieldValue(response, "currentAssetCostForExport");
    this.assetValueForExport = this.getFieldValue(response, "assetValueForExport");
    this.totalRecords = this.getFieldValue(response, "totalRecords");

    this.notes = this.getFieldValue(response, "notes");
    this.serialNumber = this.getFieldValue(response, "serialNumber");
    this.labels = this.getFieldValue(response, "labels");
    this.assetStatusName = this.getFieldValue(response, "assetStatus");
    this.assetStatusResponse = this.getFieldValue(response, "assetStatusResponse");
    this.assetName = this.getFieldValue(response, "assetName");

    this.fleetCostCenter = this.getFieldValue(response, "fleetCostCenter");
    this.monthlyFleetRate = this.getFieldValue(response, "monthlyFleetRate");
    this.fleetDeliveryDate = this.getFieldValue(response, "fleetDeliveryDate");
    this.fleetOrganizationReferenceNumber = this.getFieldValue(response, "fleetOrganizationReferenceNumber");
    this.fleetExchangeOrWarrantyDate = this.getFieldValue(response, "fleetExchangeOrWarrantyDate");
    this.fleetInventoryNumber = this.getFieldValue(response, "fleetInventoryNumber");
    this.isSelected = this.getFieldValue(response, "isSelected");
    this.assetType = this.getFieldValue(response, "assetType");
    this.assetSubType = this.getFieldValue(response, "assetSubType");
    this.capReached = this.getFieldValue(response, "capReached");
    this.cappedOnDate = this.getFieldValue(response, "cappedOnDate");
    this.capReachedResponse = this.getFieldValue(response, "capReachedResponse");
    this.cappedAtValue = this.getFieldValue(response, "cappedAtValue");
  }

  getFieldValue = (pendingCharge, field: string) => HelperLodash.get(pendingCharge, field, "");

  formatCostDisplay = (cost) => {
    return cost === ""
      ? ""
      : LocaleManager.numberHelpers.formatCurrency(cost, this.currency, ApplicationConstants.COUNT.CNT2);
  };
}
