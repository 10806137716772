import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M21,15 L21,17 L24,17 L24,24 L14,24 L14,17 L16.5,17 L16.5,15 L21,15 Z M21,9 L21,13 L18,13 L18,12 L16,12 L16,13 L14.5204131,13 L14.5204131,15 L12,15 L12,16 L11,16 L11,18 L12,18 L12,21 L3,21 L3,9 L21,9 Z M8,16 L6,16 L6,18 L8,18 L8,16 Z M20,16 L17.5,16 L17.5,17 L20,17 L20,16 Z M8,12 L6,12 L6,14 L8,14 L8,12 Z M13,12 L11,12 L11,14 L13,14 L13,12 Z M8,2 L8,4 L16,4 L16,2 L19,2 L19,4 L21,4 L21,7 L3,7 L3,4 L5,4 L5,2 L8,2 Z" />
    </g>
  </svg>
);

export default SVG;
