/**
 * @interface IListTemplateProps - Interface for the Generic Template component.
 *
 * @prop {T} model - generic model.
 * @prop {boolean} selected - boolean property for row selection.
 */
export interface IListTemplateProps {
  model: any;
  isSelected: boolean;
}

export interface ICheckedListInfo {
  count: number;
  excludedIds: any[];
  hierarchyRowsInfo?: IHierarchyRow[];
  primaryRowIds?: number[];
  checkedRows: any[];
}

export interface ICheckedPayloadInfo {
  Ids: number[];
  excludedIds: number[];
  selectAll: boolean;
  hierarchyRowsInfo?: any[];
}

export enum ListMetaEnum {
  IsChecked = "isChecked",
  IsRowCheckedOnCollapsedMode = "isRowCheckedOnCollapsedMode",
  IsExpanded = "isExpanded",
}

interface IHierarchyRow {
  id: number;
  [ListMetaEnum.IsRowCheckedOnCollapsedMode]: boolean;
  excludedIds: number[];
  primaryRowIds: number[];
}

export enum checkedListInfoEnum {
  excludedIds = "excludedIds",
  primaryRowIds = "primaryRowIds",
  checkedRows = "checkedRows",
  hierarchyRowsInfo = "hierarchyRowsInfo",
}

export enum rowActionType {
  selected = "selected",
  checked = "checked",
}
