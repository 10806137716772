import React from "react";
const SVG = () => (
  <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-985.000000, -1122.000000)">
        <g transform="translate(709.000000, 454.000000)">
          <g transform="translate(275.000000, 666.000000)">
            <rect x="0" y="0" width="24" height="24" />
            <path
              d="M18.6776827,16 L18.6776827,17.5906184 C20.1716763,17.6020229 23,18.0924178 23,21.6278224 C23,21.6278224 22.5438188,19.9285473 18.6776827,19.6662431 L18.6776827,19.6662431 L18.6776827,21.4203134 L15,18.7766896 L18.6776827,16 Z M11,14 L18.8013122,14 L13.6063763,18 L11,18 L11,14 Z M17.5,2 L19,3.5 L19,7 L18.125,8 L16.515,8 L17.5,13 L13.025974,13 L11.9,8 L8.5,8 L5,7 L5,3 L8.5,2 L17.5,2 Z M4,4 L4,6 L1,6 L1,4 L4,4 Z"
              id="Combined-Shape"
              fill="#524F53"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
