import Grid from "@mui/material/Grid";
import { DetailsPanel, FieldWrapper } from "am-web-ui-shared/components";
import React from "react";
import { withTranslation } from "react-i18next";
import { ActionItem } from "../../../../utils/enums/actionItemEnum";
import { fieldSpacingSetting } from "../../../../utils/styledUtils";
import { IServiceFormProps } from "../iServiceFormProps";
import { AssetServiceCategory } from "../../../../utils/enums/serviceEnum";
import SingletonFeatureManager from "../../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../../featureFlagManager/featureConfig";
import { basedOnList } from "../../../../utils/commonUtils";

class ServiceFrequencyPanel extends React.PureComponent<IServiceFormProps> {
  basedOnTranlatedList = [];
  unitOfServiceInterval = this.props.t("common:DAYS");
  cssSetting = {
    marginBottom: fieldSpacingSetting.marginBottomNone,
  };
  // checks for Feature Flag
  heavyEquipmentTemplateFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE);
  unitsOfMeasurement = (basedOnTyped) => {
    const { t, selectedService, distanceUnitValue } = this.props;
    switch (basedOnTyped) {
      case AssetServiceCategory.DISTANCE:
        this.unitOfServiceInterval = distanceUnitValue?.value;
        return t("services:SERVICES_LABEL_DISTANCE");
      case AssetServiceCategory.OPERATING_TIME:
        this.unitOfServiceInterval = t("services:ENGINE_HOURS");
        return t("services:SERVICES_LABEL_OPERATING_TIME");
      case AssetServiceCategory.PERIOD:
        this.unitOfServiceInterval =
          selectedService?.serviceType?.code === "RECURRENT" ? selectedService?.servicePeriod?.value : t("common:DAYS");
        return t("services:SERVICES_LABEL_PERIOD");
      default:
        return t("services:SERVICES_LABEL_PERIOD");
    }
  };

  dataFromTemplate = () => {
    this.basedOnTranlatedList = basedOnList(this.props.t);
    const { selectedService, serviceActionType, t } = this.props;
    const selectedServiceValue =
      serviceActionType === ActionItem.edit || serviceActionType === ActionItem.complete
        ? this.unitsOfMeasurement(this.props.selectedService?.basedOn)
        : this.props.selectedService?.basedOn?.value;
    const serviceInterval = selectedService && selectedService.serviceInterval;
    return this.heavyEquipmentTemplateFF ? (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <FieldWrapper labelName={t("services:BASED_ON_FROM_TEMPLATE")} fieldName={selectedServiceValue} />
        </Grid>

        {selectedService?.serviceType?.code === "RECURRENT" ? (
          <Grid item={true} xs={6}>
            <FieldWrapper
              labelName={t("services:NUMBER_FROM_TEMPLATE")}
              fieldName={serviceInterval + " " + (selectedService?.servicePeriod?.value || this.unitOfServiceInterval)}
            />
          </Grid>
        ) : null}
      </Grid>
    ) : (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <FieldWrapper
            labelName={t("services:NUMBER_FROM_TEMPLATE")}
            fieldName={selectedService && selectedService.serviceInterval}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <FieldWrapper
            labelName={t("services:PERIOD_FROM_TEMPLATE")}
            fieldName={selectedService && selectedService.servicePeriod && selectedService.servicePeriod.value}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { selectedService, t } = this.props;
    return (
      <DetailsPanel panelTitleId="pnlTemplateDetails" title={t("services:SERVICE_FREQUENCY")}>
        <FieldWrapper
          labelName={t("services:SERVICE_TYPE_TEMPLATE")}
          fieldName={selectedService && selectedService.serviceType && selectedService.serviceType.value}
        />
        {this.dataFromTemplate()}
      </DetailsPanel>
    );
  }
}

export default withTranslation()(ServiceFrequencyPanel);
