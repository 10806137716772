import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { LoaderWrapper } from "am-web-ui-shared/elements";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import IUpfrontExpPanelProps from "./iUpfrontExpPanelProps";
import UpfrontExpansionPanelState from "./upfrontExpansionPanelState";
import { withTranslation } from "react-i18next";

interface IUpfronExpansionPanel {
  isPadding?: boolean;
}
/**
 * @const StyledUpfrontExpansionPanel - Styling the Expansion Panel container.
 */
const StyledUpfrontExpansionPanel = styled(Accordion)<IUpfronExpansionPanel>`
  && {
    width: 100%;
    margin: 0;
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
    box-shadow: none;
    &.expansion-panel-root {
      border-radius: 0;
    }
    &:before {
      height: 0;
      margin: 0;
    }
    .expansion-panel-root__content {
      display: flex;
      flex-direction: column;
      margin: 0;
      & > div > span {
        margin: 0;
        align-self: flex-start;
        margin-right: 8px;
      }
      & > div > div:nth-child(2) {
        padding-right: 16px;
        padding-bottom: ${({ isPadding }) => (!isPadding ? "5px" : "0")};
      }
      & + .MuiIconButton-edgeEnd {
        margin-right: 0px !important;
      }

      & + div {
        padding: 0;
        margin: 0px;
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
`;

/**
 * @const StyledUpfrontExpansionPanelSummary - Styling the Expansion Panel Summary.
 */
export const StyledUpfrontExpansionPanelSummary = styled(AccordionSummary)<IUpfronExpansionPanel>`
  && {
    user-select: auto;
    padding: ${({ isPadding }) => (isPadding ? "12px 16px" : "0 16px 16px")};
    display: flex;
    //flex-direction: column;
    align-items: flex-start;
    div:last-child {
      padding-bottom: 0;
      align-items: initial;
    }
  }
  &&& {
    min-height: unset;
    height: auto;
  }
`;

export const ExpantionDetailLoader = styled.div`
  min-height: 100px;
  #commonLoading {
    top: 48px;
  }
`;

/**
 * @const StyledUpfrontExpansionPanelDetails - Styling the Expansion Panel Details.
 */
export const StyledUpfrontExpansionPanelDetails = styled(AccordionDetails)`
  && {
    display: flex;
    flex-direction: column;
    background: #fafafa;
    padding: 0 16px;
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  }
  & > div {
    padding-top: 12px;
    padding-bottom: 10px;
  }
`;

export const StyledLink = styled(Link)`
  padding-right: 0 !important;
`;

/**
 * @description - This is a upfront expansion panel that holds the summary and details panel.
 * User can render "n" number of components into the summary and details sections by passing the components thru props.
 * By default the panel will be collapsed.
 *
 * @const UpfrontExpansionPanel
 * @class UpfrontExpansionPanelProps
 */

export class UpfrontExpansionPanel extends React.PureComponent<IUpfrontExpPanelProps, UpfrontExpansionPanelState> {
  static defaultProps = {
    isEnable: true,
  };

  state = new UpfrontExpansionPanelState(this.props.isPanelExpanded, this.props.associatedRowId);

  componentDidUpdate(prevProps) {
    if (prevProps.isPanelExpanded !== this.props.isPanelExpanded) {
      this.setState({ isPanelExpanded: this.props.isPanelExpanded });
    }
  }

  handlePanelToggle = (panelId) => (event, isExpanded) => {
    if (this.props.allowAccordianControl && event) {
      this.props.allowAccordianControl(panelId, isExpanded);
    } else {
      this.setState(
        (prevState) => {
          return { isPanelExpanded: !prevState.isPanelExpanded };
        },
        () => {
          return (
            this.props.onPannelToggle && this.props.onPannelToggle(this.props.detailsData, this.state.isPanelExpanded)
          );
        },
      );
    }
  };

  /*
   *to check is view is expaned or not */
  isExpanded = () => {
    return this.props.isEnable && !this.props.isPanelExpanded;
  };

  getDetailsComponent = () => {
    const { detailComponents, detailsData, t } = this.props;
    return (
      detailComponents &&
      detailComponents.map((item, index) => (
        <React.Fragment key={`keyUEPDetails_${index}`}>{item(detailsData, t)}</React.Fragment>
      ))
    );
  };

  onClickLink = () => {
    const { onClickLink } = this.props;
    if (onClickLink) {
      onClickLink();
    }
  };

  render() {
    const {
      expansionPanelDetailsLoader,
      panelId,
      summaryComponents,
      detailsData,
      isEnable,
      redirect,
      showToggleIcon,
      isPadding = true,
      t,
    } = this.props;
    return (
      <StyledUpfrontExpansionPanel
        id={panelId}
        expanded={this.state.isPanelExpanded}
        onChange={this.handlePanelToggle(panelId)}
        classes={{ root: "expansion-panel-root" }}
        isPadding={isPadding}
      >
        <StyledUpfrontExpansionPanelSummary
          data-exp-pnl="upfront"
          expandIcon={
            this.isExpanded() || showToggleIcon ? <SquareIcon id="logoutIcon" name={IconName.Chevron} /> : null
          }
          onClick={this.isExpanded() ? this.props.onSummaryClick : null}
          classes={{ content: "expansion-panel-root__content" }}
          isPadding={isPadding}
        >
          {summaryComponents.map((item, index) => (
            <React.Fragment key={`keyUEPSummary_${index}`}>
              {item(detailsData, this.state.isPanelExpanded, t)}
            </React.Fragment>
          ))}
          {redirect && isEnable && (
            <StyledLink data-testid="redirect-link" to={redirect.link} onClick={this.onClickLink}>
              {redirect.label}
            </StyledLink>
          )}
        </StyledUpfrontExpansionPanelSummary>
        {isEnable ? (
          <StyledUpfrontExpansionPanelDetails>
            {expansionPanelDetailsLoader && this.state.isPanelExpanded ? (
              <ExpantionDetailLoader>
                <LoaderWrapper loadingText={t("common:LOADING")} activity={expansionPanelDetailsLoader} />
              </ExpantionDetailLoader>
            ) : (
              this.getDetailsComponent()
            )}
          </StyledUpfrontExpansionPanelDetails>
        ) : null}
      </StyledUpfrontExpansionPanel>
    );
  }
}

/**
 * @export UpfrontExpansionPanel - Upfront Expansion Panel.
 */
export default withTranslation()(UpfrontExpansionPanel);
