/**
 * @description Action constants to be used across the project
 *
 * format: {feature}_{action}
 */
const MoreFilterConstants = {
  // action for more filter
  CLOSE_MORE_FILTER: "CLOSE_MORE_FILTER",
  OPEN_MORE_FILTER: "OPEN_MORE_FILTER",
};

export default MoreFilterConstants;
