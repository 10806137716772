import React from "react";

// @ts-ignore
import LogoExternal from "../logo";
import ILogoProps from "./iLogoProps";

class Logo extends React.PureComponent<ILogoProps> {
  render() {
    const { logoText } = this.props;
    return <LogoExternal logoText={logoText} />;
  }
}

export default Logo;
