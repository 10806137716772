import Gateway from "../gateway/gateway";
import { getAssetMgmtEndpoint } from "../utils/apiUtils";

export const getConfirmTransferListApi = async (queryObj) => {
  return Gateway.get(getAssetMgmtEndpoint(`/assets/transfers/confirm-deliveries`), { ...queryObj });
};

export const getConfirmRecallListApi = async (transferId) => {
  return Gateway.get(getAssetMgmtEndpoint(`/assets/transfers/${transferId}/confirm-delivery-assets`));
};

export const confirmTransferApi = async (transferId, queryObj) => {
  return Gateway.patch(getAssetMgmtEndpoint(`/assets/transfers/${transferId}/confirm-delivery`), null, queryObj, null);
};
export const recallTransferApi = async (transferId, queryObj) => {
  return Gateway.patch(getAssetMgmtEndpoint(`/assets/transfers/${transferId}/recall`), null, queryObj, null);
};
export const getConfirmTransferCountApi = async () => {
  return Gateway.get(getAssetMgmtEndpoint(`/assets/transfers/confirm-delivery-count`), null, null, null, false);
};
