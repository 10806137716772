import { saveAs } from "file-saver";

enum ToastrType {
  error = "error",
  success = "success",
}

/**
 * @description - downloads the excel file by converting blob data to excel file.
 * @param downloadAPI - api endpoint to download the excel file
 * @param fileType - define the download filetype format
 * @param query - other params of api like filter and search
 * @param message - optional, to show the message after file download.
 * @param showToast - function to show the toast message
 */
export const downloadFile = (
  downloadAPI,
  fileType,
  query,
  fileName,
  t?: any,
  message?: any,
  showToast?: (type: any, message: any) => any,
  failureMessage?: any,
  getMessageFromErrorCode?: any,
  pushAnalyticsData: () => void = () => undefined,
) => {
  downloadAPI(query).then((response) => {
    if (response.error || response.errors) {
      if (getMessageFromErrorCode) {
        getMessageFromErrorCode(response, t, showToast, pushAnalyticsData);
      } else if (showToast && failureMessage) {
        showToast(ToastrType.error, `${failureMessage}`);
      }
    } else {
      const blob = new Blob([response], {
        type: fileType,
      });
      saveAs(blob, fileName);
      pushAnalyticsData();
      if (showToast && message) {
        showToast(ToastrType.success, `${message}`);
      }
    }
  });
};
export const download = (downloadAPI, id, fileName) => {
  downloadAPI.getDocumentById(id).then((blob) => {
    if (!blob.error) {
      saveAs(blob, fileName);
    }
  });
};

export const downloadHCDocuments = async (downloadAPI, fileName, path) => {
  const blob = (await downloadAPI.getDocument(path)) as Blob;
  saveAs(blob, fileName);
};

export default { download, downloadFile, downloadHCDocuments };
