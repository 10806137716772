import MenuItem from "@mui/material/MenuItem";
import React from "react";
import styled from "styled-components";
import { IRadioSelectProps } from "../multiSelectDropdown/iMultiSelectDropDownProps";
import { StyledDiv, StyledInput, StyledLabel } from "../styled";

/**
 * @description MenuListContainer to style the MenuListContainer
 */
export const MenuListContainer = styled("div").attrs({
  id: "menuListContainer",
})`
  color: ${(props) => props.theme.colors.base.steel};
  box-shadow:
    0 2px 5px 3px ${(props) => props.theme.colors.base.greyrgba},
    inset 0 1px 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
  background: ${(props) => props.theme.colors.base.white};
  width: 400px;
`;

/**
 * OptionWrapper styles for the menuItem in flyout
 */
export const OptionWrapper = styled(MenuItem)`
  background: ${(props) => props.theme.colors.base.white};
  padding: 16px;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
`;

/**
 * @description ClearButton to style the ClearButton of the option list
 */
export const ClearButton = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  width: 100%;
  height: 48px;
  text-align: right;
  text-decoration: underline;
  padding-right: 16px;
  line-height: 44px;
  & span {
    cursor: pointer;
  }
`;

/**
 * @class MultiSelectCheckboxFilter component for multiselect dropdown
 */
export class RadioSelectFilter extends React.PureComponent<IRadioSelectProps> {
  radioOptionsConfig = [
    { label: this.props.t("filters:YES"), value: "true" },
    { label: this.props.t("filters:NO"), value: "false" },
  ];

  containerRef: any;
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  /**
   * method to identify if it is clicked inside the component
   */
  isClickedOnComponent = (target) => {
    const eleArr = this.containerRef.current && Array.from(this.containerRef.current.children);
    const arr = eleArr.filter((child: any) => child.contains(target));
    return arr.length > 0;
  };

  /**
   * onDocumentClick to disable focus while click outside
   */
  onDocumentClick = (e) => {
    if (this.isClickedOnComponent(e.target)) {
      this.props.inputFocus(); // open
    } else {
      this.props.disableFocus(); // close
    }
  };

  /**
   * handle on mouse down event on search input box
   *
   * @param e current event
   */
  onMouseDown = (e: any) => {
    e.stopPropagation();
    e.target.focus();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentClick);
  }

  render() {
    const { dropDownProps, showClearButton, handleInputChange } = this.props;
    if (this.props.dropDownProps && this.props.dropDownProps.options) {
      this.radioOptionsConfig = this.props.dropDownProps.options;
    }
    return (
      <MenuListContainer ref={this.containerRef} className="drodownMenuRadioSelect">
        {this.radioOptionsConfig.map((option) => (
          <OptionWrapper
            {...dropDownProps.innerProps}
            id={`${dropDownProps.selectProps.name}`}
            className={`dropdownMenuItem op_${dropDownProps.selectProps.name}`}
            buttonRef={dropDownProps.ref}
            component="div"
            key={option.label}
          >
            <StyledDiv>
              <StyledInput
                name={dropDownProps.selectProps.input.name}
                checked={
                  dropDownProps.selectProps.selectedValue.length > 0 &&
                  dropDownProps.selectProps.selectedValue[0].value === option.value
                }
                onChange={(event) =>
                  handleInputChange([{ label: option.label, value: event.target.value }], event.target.checked)
                }
                value={option.value}
              />
              <StyledLabel>
                <span className="styledBox" />
                <span className="styledText">{option.label}</span>
              </StyledLabel>
            </StyledDiv>
          </OptionWrapper>
        ))}
        {this.radioOptionsConfig.length === 0 ? <div className="optionWrapper">{dropDownProps.children}</div> : null}
        {showClearButton && (
          <ClearButton>
            <span onClick={this.props.clearFilterSelection.bind(null, false)}>
              {dropDownProps.selectProps.clearButtonText}
            </span>
          </ClearButton>
        )}
      </MenuListContainer>
    );
  }
}

export default RadioSelectFilter;
