import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M16.3994949,8.32842712 L10.742,13.985 L7.91421356,11.1568542 L6.5,12.5710678 L10.7426407,16.8137085 L17.8137085,9.74264069 L16.3994949,8.32842712 Z" />
    </g>
  </svg>
);

export default SVG;
