/**
 * enum- Celltype for different type of grid cells
 * @ ImageFieldCombination- type for grid containing combination of text and image
 * @ Label - type for only grid containing text
 * @ FieldCombination - type for grid containing combination of fields
 */

export enum CellTypes {
  ImageFieldCombination = "imageFieldCombination",
  CheckBox = "CheckBox",
  Custom = "custom",
  CustomComponent = "CustomComponent",
  Date = "date",
  EmptyCell = "emptyCell",
  FieldCombination = "fieldCombination",
  Text = "text",
  GridDropdown = "gridDropdown",
  GridDatePicker = "gridDatePicker",
  Object = "object",
  RadioCombination = "RadioCombination",
  QuickActions = "quickActions",
  Link = "Link",
  SelectBox = "SelectBox",
  TextBox = "TextBox",
}
// This file will be removed when we will get field mapping from API.
export enum GridFieldsEnum {
  "locationManagerMobileNumber" = "locationManagerDetails.mobileContactNumber",
  "assetStatus" = "assetStatusName",
  "assetState" = "assetStateName",
  "locationManagerFirstName" = "locationManagerDetails.firstName",
  "locationManagerLastName" = "locationManagerDetails.lastName",
}
