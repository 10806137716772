import { DetailsPanel, IconName } from "am-web-ui-shared/components";
import React from "react";
import { withTranslation } from "react-i18next";
// @ts-ignore
import { MAX_FILE_SIZE } from "TARGET_BUILD/config";
import { ValidationConstants } from "../../../../utils/enums/validationConstants";
import { fileSizeValidation, fileTypeValidation, maxFileValidation } from "../../../../utils/errorUtils";
import Document from "../../../upload/documentContainer";
import { DocumentType } from "../../../upload/iUploadComponentProps";
import { IServiceFormProps } from "../iServiceFormProps";
export const formatAttachment = (serviceTemplateAttachments: any) => {
  return serviceTemplateAttachments.map((templateAttachment) => ({
    attachmentId: templateAttachment.serviceAttachmentId,
    displayName: templateAttachment.displayName,
    fileName: templateAttachment.fileName,
  }));
};

const attachmentProperty: any = {
  fileId: "serviceAttachmentId",
  fileName: "fileName",
};

export const AttachmentPanel = (props: IServiceFormProps) => {
  const { formName, t, viewOnlyAttachment, associatedEntity } = props;
  return (
    <DetailsPanel panelTitleId="pnlServiceAttachments" title={t("common:ATTACHMENTS")}>
      <Document
        responseFormatMapper={{
          documentId: "serviceAttachmentId",
        }}
        uploadImage={IconName.DropFile}
        label={t("common:ADD_ATTACHMENTS")}
        documentType={DocumentType.Attachment}
        name="serviceAttachments"
        allowedFileMessage={t("common:ALLOWED_DOCUMENT_MESSAGE")}
        formName={formName}
        associatedEntity={associatedEntity}
        uploadError="uploadError"
        validations={[
          fileSizeValidation(MAX_FILE_SIZE),
          maxFileValidation(ValidationConstants.MAX_LENGTH.NUM_20, "common:MAXIMUM_SERVICE_ATTACHMENT"),
          fileTypeValidation(ValidationConstants.ALLOWED_FILE_TYPES),
        ]}
        viewOnlyAttachment={viewOnlyAttachment && formatAttachment(viewOnlyAttachment)}
        viewOnlyAttachmentLabelName={t("services:ATTACHMENTS_TEMPLATE")}
        attachmentProperty={attachmentProperty}
        allowDownloadAttachment={true}
      />
    </DetailsPanel>
  );
};

export default withTranslation()(AttachmentPanel);
