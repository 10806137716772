import React, { createContext, useContext, useReducer, ReactElement, PropsWithChildren } from "react";

const initialState = { loader: null };

export type GoogleMapContext = { state; setLoader: (loader) => void; resetLoader: () => void };

export const MapContext = createContext<GoogleMapContext>({
  state: { ...initialState },
  setLoader: () => null,
  resetLoader: () => null,
});

const googleMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADER":
      return {
        loader: action.payload,
      };
    default:
      return state;
  }
};

export const useGoogleMapStore = (): GoogleMapContext => useContext(MapContext);

export const GoogleMapProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const [state, dispatch] = useReducer(googleMapReducer, initialState);

  const setLoader = (loader) => dispatch({ type: "SET_LOADER", payload: loader });

  const resetLoader = () => {
    const loader = state.loader;
    if (loader) {
      loader.reset();
      delete window.google.maps;
      if (loader.constructor) {
        loader.constructor.instance = undefined;
      }
    }
  };

  return <MapContext.Provider value={{ state, setLoader, resetLoader }}>{children}</MapContext.Provider>;
};
