import acceptLanguageParser from "accept-language-parser";
import { LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import { CommonActions, UserSettingActions } from "custom-actions";
import { all, put, takeLatest } from "redux-saga/effects";
// @ts-ignore
import { API_DATE_FORMAT } from "TARGET_BUILD/config";
import { showToastr, ToastrType } from "../../../actions/toastrAction";
import { getFormatFromLocale } from "../../../api/formatterApi";
import { getLanguage } from "../../../api/masterDataApi";
import {
  getActivationUserInfo,
  getBrowserLanguage,
  getLinks,
  getLocations,
  getTouLinks,
  userSetting,
  userSettingDetail,
} from "../../../api/userSettingApi";
import { call } from "../../../sagas/common/commonSaga";
import getApplicationLanguage, {
  findFitLanguage,
  getFilteredLanguageList,
} from "../../../utils/localization/localizationUtil";
/**
 * Saga to fetch user setting.
 */
export function* userSettingSaga() {
  yield takeLatest(UserSettingActions.UM_GET_USER_SETTING, userSettingFn);
}

function* userSettingFn(action) {
  let settingData;
  let languageList;
  let userSettingData;
  let acceptHeaderLang;
  if (action.payload !== "SET_DEFAULT") {
    [languageList, userSettingData, acceptHeaderLang] = yield all([
      call(getLanguage),
      call(userSetting),
      call(getBrowserLanguage),
    ]);
  }
  languageList = getFilteredLanguageList(languageList);
  let acceptHeader = acceptHeaderLang;
  if (!acceptHeader) {
    acceptHeader = { "accept-language": "en-US,en;q=0.9,hi;q=0.8" };
  }
  const acceptLanguages = acceptLanguageParser.parse(acceptHeader["accept-language"]);
  if (!userSettingData) {
    settingData = { languageCode: "en-US", location: null };
  } else {
    settingData = userSettingData;
  }
  settingData.applyLanguageCode = getApplicationLanguage(languageList, settingData, acceptLanguages);
  if (action.payload !== "SET_DEFAULT") {
    yield call(getFormattedDate, settingData);
  }
  yield put({ type: UserSettingActions.UM_USER_SETTING_RESPONSE, payload: settingData });
  if (window.WINDOW_LANGUAGE_CODE !== settingData.applyLanguageCode) {
    window.WINDOW_LANGUAGE_CODE = settingData.applyLanguageCode;
    findFitLanguage(window.WINDOW_LANGUAGE_CODE);
  }
  if (action.payload !== "SET_DEFAULT" && !languageList.error) {
    yield put({ type: CommonActions.OM_LANGUAGE_RESPONSE, payload: languageList });
  }
}

/**
 * @description Saga to get formatted date.
 * @memberof userSettingSaga
 */
function* getFormattedDate(userSettingData) {
  LocaleManager.dateHelpers.setLocale(userSettingData.applyLanguageCode);
  const format = yield call(getFormat, userSettingData.applyLanguageCode);
  if (format && !format.error && format[0]) {
    LocaleManager.initUserData(
      {
        apiDateFormat: API_DATE_FORMAT,
        dateFormat: format[0].dateFormat.toUpperCase(),
        dateTimeFormat: `${format[0].dateFormat.toUpperCase()} ${format[0].timeFormat}`,
        timeFormat: format[0].timeFormat,
      },
      format[0].locale,
      format[0].localeText,
    );
  }
}

/**
 * @description function to get response from getFormatFromLocale
 * @memberof userSettingSaga
 */
function* getFormat(locale) {
  let response = yield call(() => {
    return getFormatFromLocale(locale);
  });
  if (!response || response.error) {
    response = [];
  }
  return response;
}

/**
 * Saga to fetch user setting detail.
 */
export function* userSettingDetailSaga() {
  yield takeLatest(UserSettingActions.UM_GET_USER_SETTING_DETAIL, userSettingDetailFn);
}

function* userSettingDetailFn() {
  const userSettingDetailData = yield call(() => {
    return userSettingDetail();
  });
  yield put({ type: UserSettingActions.UM_USER_SETTING_DETAIL_RESPONSE, payload: userSettingDetailData });
}

/**
 * Saga to fetch all locations
 */
export function* userSettingLocationSaga() {
  yield takeLatest(UserSettingActions.OM_GET_USER_SETTING_LOCATION, getLocationData);
}

export function* getLocationData(action) {
  let response = yield call(() => {
    return getLocations(action.locationType);
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: UserSettingActions.OM_USER_SETTING_LOCATIONS_RESPONSE, payload: response });
}

/**
 * Saga to fetch links
 */
export function* getAppLinksSaga() {
  yield takeLatest(UserSettingActions.GET_APP_LINKS, getAppLinks);
}

export function* getAppLinks(payload) {
  let response = yield call(() => {
    return getLinks(payload.data);
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: UserSettingActions.GET_APP_LINKS_RESPONSE, payload: response });
}

/**
 * Saga to fetch links
 */
export function* getTouLinksSaga() {
  yield takeLatest(UserSettingActions.GET_TOU_LINKS, getTouLinksData);
}

export function* getTouLinksData() {
  let response = yield call(() => {
    return getTouLinks();
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: UserSettingActions.GET_TOU_LINKS_RESPONSE, payload: response });
}

/**
 * Saga to fetch all locations
 */
export function* activationUserInfoSaga() {
  yield takeLatest(UserSettingActions.UM_GET_ACTIVATION_USER_INFO, getActivationUserData);
}

export function* getActivationUserData(action) {
  const response = yield call(() => {
    return getActivationUserInfo(action.nonce);
  });
  if (response.error) {
    yield put(
      showToastr(
        ToastrType.error,
        `errorCodes:${
          response.error.response && response.error.response.data.errors && response.error.response.data.errors[0].code
        }`,
      ),
    );
  } else {
    yield put({ type: UserSettingActions.UM_GET_ACTIVATION_USER_INFO_SUCCESS, payload: response });
  }
}

function* composedUserSettingSaga() {
  yield all([
    activationUserInfoSaga(),
    getAppLinksSaga(),
    getTouLinksSaga(),
    userSettingDetailSaga(),
    userSettingLocationSaga(),
    userSettingSaga(),
  ]);
}

export default composedUserSettingSaga;
