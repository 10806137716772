// @ts-ignore
import { GatewayReportSettingsConstants } from "custom-actions";
export const sendLocationReport = (locationId) => ({
  locationId,
  type: GatewayReportSettingsConstants.RM_SEND_LOCATION_REPORT,
});

export const sendAssetReport = (assetId) => ({
  assetId,
  type: GatewayReportSettingsConstants.RM_SEND_ASSET_REPORT,
});
