import { CellTypes, IconName, IGridSetting } from "am-web-ui-shared/components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import { ImageSizeEnum } from "../../../../../common/components/styled/squareImage/imageSizeUtil";
import squareImageContainer from "../../../../../common/components/styled/squareImage/squareImageContainer";

export const storageAssetsGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          key: "manufacturermodel",
          properties: [{ name: "manufacturerName" }, { name: "model" }],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "name",
          properties: [{ name: "name" }],
          style: {
            color: `${theme.colors.base.black}`,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 4,
      image: {
        attributes: {
          image: { maxWidth: ImageSizeEnum.SMALL, maxHeight: ImageSizeEnum.SMALL },
          wrapper: {
            height: ImageSizeEnum.SMALL,
            imageSize: 42,
            style: { marginRight: "16px" },
            width: ImageSizeEnum.SMALL,
          },
        },
        dependencies: {
          getAlternateDefaultImage: "getAlternateDefaultImage",
        },
        component: squareImageContainer,
        defaultImage: IconName.Category,
        fieldName: "assetImageId",
      },
      isSortable: true,
      type: CellTypes.ImageFieldCombination,
    },
    {
      bindProperties: [
        {
          key: "responsibleEmployeeId",
          properties: [{ name: "responsibleEmployeeName" }],
        },
        {
          key: "currentLocationName",
          properties: [{ name: "currentLocationName" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
    {
      bindProperties: [
        {
          key: "scanCode",
          properties: [{ name: "scanCode" }],
        },
        {
          key: "inventoryNumber",
          properties: [{ name: "inventoryNumber" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: CellTypes.FieldCombination,
    },
  ],
  defaultQueryFields: [
    "manufacturerName",
    "model",
    "name",
    "responsibleEmployeeName",
    "currentLocationName",
    "inventoryNumber",
    "scanCode",
    "currentLocationId",
    "responsibleEmployeeId",
    "lastTransferDate",
  ],
  showTooltip: true,
};
