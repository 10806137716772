import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M9.87867966,8.46446609 L12.7071068,8.46446609 L14.8284271,10.5857864 L13.4142136,12 L12,10.5857864 L10.5857864,10.5857864 L5.63603897,15.5355339 L5.63603897,16.9497475 L7.05025253,18.363961 L8.46446609,18.363961 L10.5857864,16.2426407 L12,17.6568542 L9.17157288,20.4852814 L6.34314575,20.4852814 L3.51471863,17.6568542 L3.51471863,14.8284271 L9.87867966,8.46446609 Z M14.8284271,3.51471863 L17.6568542,3.51471863 L20.4852814,6.34314575 L20.4852814,9.17157288 L14.1213203,15.5355339 L11.2928932,15.5355339 L9.17157288,13.4142136 L10.5857864,12 L12,13.4142136 L13.4142136,13.4142136 L18.363961,8.46446609 L18.363961,7.05025253 L16.9497475,5.63603897 L15.5355339,5.63603897 L13.4142136,7.75735931 L12,6.34314575 L14.8284271,3.51471863 Z"></path>
    </g>
  </svg>
);

export default SVG;
