// @ts-ignore
import { AssetActions, TransferCartConstants } from "custom-actions";
import { storageAssetsGridSetting } from "../modules/transferCart/components/list/storageAssetGridSetting";

export const setTransferCartItem = (items?, type = AssetActions.CLEAR_ALL_ASSETS_SELECTION) => ({
  payload: { items, type },
  type: TransferCartConstants.AM_SET_TRASNFER_CART_ITEMS,
});

export const removeTransferCartItem = (item?) => ({
  payload: item,
  type: TransferCartConstants.AM_REMOVE_TRANSFER_CART_ITEM,
});

export const removeAllTransferCartItem = (isToastVisible = true) => ({
  isToastVisible,
  type: TransferCartConstants.AM_REMOVE_ALL_TRANSFER_CART_ITEMS,
});

export const setisTransferCartLoaded = (isVisible) => ({
  payload: isVisible,
  type: TransferCartConstants.AM_SET_TRANSFER_CART_LOADED,
});

export const getAssetFromScanCodeOrInventoryNumber = (cartItems, searchString, t) => ({
  payload: { cartItems, searchString, t },
  type: TransferCartConstants.FETCH_ASSET_VIA_SCANCODE_OR_INVENTORYCODE,
});

export const getTransferDate = (assetList) => ({
  payload: assetList,
  type: TransferCartConstants.GET_TRANSFER_DATE,
});

export const setTransferDate = (transferDate) => ({
  payload: transferDate,
  type: TransferCartConstants.SET_TRANSFER_DATE,
});

export const nullifyAssetGroupDelResponse = () => ({
  type: TransferCartConstants.NULLIFY_ERRORS,
});

export const fetchStorageAssetsList = (offset, queryObj) => ({
  offset,
  queryObj,
  type: TransferCartConstants.AM_GET_STORAGE_ASSET_LIST,
});

export const setSearchQuery = (searchString) => ({
  searchString,
  type: TransferCartConstants.SET_TRANSFER_CART_SEARCH_QUERY,
});

/**
 * @description Function to get the asset list with searched value.
 */
export const getStorageAssetsForSearch = () => ({
  offset: 0,
  queryObj: { fields: storageAssetsGridSetting.defaultQueryFields.join() },
  type: TransferCartConstants.GET_TRANSFER_CART_ASSET_LIST_FOR_SEARCH,
});

export const quickTransfer = (transferCartInfo, alertId?) => ({
  transferCartInfo,
  alertId,
  type: TransferCartConstants.AM_SUBMIT_QUICK_TRANSFER,
});

export const clearPartialTransferedAssetsResponse = () => ({
  type: TransferCartConstants.AM_PARTIAL_TRANSFERED_ASSETS_CLEAR_RESPONSE,
});
export const updateQtyItemSearchResponse = (payload) => ({
  payload,
  type: TransferCartConstants.UPDATE_ADD_QTY_ITEM_BY_SCANCODE_RESPONSE,
});

export const setSelectedLocationGroupId = (id) => ({
  payload: id,
  type: TransferCartConstants.SET_SELECTED_LOCATION_GROUP_ID_TRANSFER,
});

/**
 * @description Function to set the selected items list
 * @param {Array} selectedItems - selected items in the group list tree.
 */
export const setSelectedItems = (selectedItems) => ({
  payload: selectedItems || [],
  type: TransferCartConstants.SET_LOCATION_GROUPS_SELECTED_ITEMS,
});

/**
 * @description Function to update the group list with given items
 * @param {Array} items - group list for the particular level
 */
export const setSelectedGroupList = (items) => ({
  payload: items,
  type: TransferCartConstants.SET_LOCATION_GROUPS_LIST,
});

/**
 * @description - to lear all data regarding location groups in transfer cart
 */

export const clearLocationGroupsData = () => ({
  type: TransferCartConstants.CLEAR_LOCATION_GROUP_TRANSFER,
});

export const setTransferList = (payload) => ({
  payload,
  type: TransferCartConstants.SET_TRANSFER_LIST,
});

export const setQtyIsItemPresent = (payload) => ({
  payload,
  type: TransferCartConstants.SET_QTY_ITEM_PRESENT,
});

export const handleEditTransfer = () => ({
  type: TransferCartConstants.EDIT_TRANSFERCART_INFO,
});

export const toggleConfirmDuplicateTransfer = (payload: boolean) => ({
  payload,
  type: TransferCartConstants.TOGGLE_DUPLICATE_TRANSFER_CONFIRM,
});

export const toggleConfirmTransfer = (payload: boolean) => ({
  payload,
  type: TransferCartConstants.TOGGLE_CONFIRM_TRANSFER,
});
