// @ts-ignore
import { MasterActions, UserLicenseActions, UserSettingActions } from "custom-actions";

// action to get user license(module) detail
export const getLicenseInfo = (includeCount = false) => ({
  includeCount,
  type: UserLicenseActions.ORG_GET_USER_LICENSE,
});
// action to get user setting
export const getUserSetting = (type = undefined) => ({
  payload: type,
  type: UserSettingActions.UM_GET_USER_SETTING,
});
// action to get language list
export const getLanguageList = (countryCode = null) => ({
  countryCode,
  type: MasterActions.OM_GET_LANGUAGE_LIST,
});
// action to get user setting detail
export const getUserSettingDetail = () => ({
  type: UserSettingActions.UM_GET_USER_SETTING_DETAIL,
});
// action to get user setting detail
export const getLocationList = () => ({
  type: UserSettingActions.OM_GET_USER_SETTING_LOCATION,
});

export const updateUserLanguage = (language) => ({
  language,
  type: UserSettingActions.UPDATE_USER_LANGUAGE,
});

export const getUserInfo = (nonce) => ({
  nonce,
  type: UserSettingActions.UM_GET_ACTIVATION_USER_INFO,
});
