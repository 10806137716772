// @ts-ignore
import { CompanyActions } from "custom-actions";
import IActionMethod from "../../../models/iActionMethod";
import { ITransferSettingsObject } from "../../transferSettings/iTransferSettingsProps";
import CompanyAdditionalSettings from "TARGET_BUILD/common/models/company/CompanyAdditionalSettings";
import { IPrivateModeSettingRequest } from "../redux/iCompanyModuleState";

// action to edit company detail
export const editCompanyDetails = (item) => ({
  item,
  type: CompanyActions.OM_EDIT_COMPANY_DETAILS,
});

/** Action method for retrieving company detail */
export const getCompanyDetails = (): IActionMethod => ({
  type: CompanyActions.OM_GET_COMPANY_DETAILS,
});

export const updateIsEditedState = (check = true) => ({
  isRequestInitiated: check,
  payload: false,
  type: CompanyActions.OM_EDIT_COMPANY_DETAILS_SUCCESS,
});

export const getLicenseAndUsageDetails = (includeCount = true): IActionMethod => ({
  includeCount,
  type: CompanyActions.OM_GET_LICENSE_AND_USAGE_DETAILS,
});

export const getLicenseAndUsageDetailsCount = (): IActionMethod => ({
  type: CompanyActions.AM_GET_LICENSE_AND_USAGE_DETAILS,
});

export const getTransferConfirmationSettings = () => ({
  type: CompanyActions.OM_GET_TRANSFER_CONFIRMATION_SETTINGS,
});

export const updateTransferConfirmationSettings = (
  transferSettings: ITransferSettingsObject,
  additionalSettings: CompanyAdditionalSettings,
) => ({
  ...transferSettings,
  additionalSettings,
  type: CompanyActions.OM_UPDATE_TRANSFER_CONFIRMATION_SETTINGS,
});

export const getTechnicalUser = () => ({
  type: CompanyActions.GET_TECHNICAL_USER,
});
export const clearTechnicalUser = () => ({
  type: CompanyActions.CLEAR_TECHNICAL_USER,
});

export const resetTechnicalUserPassword = () => ({
  type: CompanyActions.RESET_TECHNICAL_USER_PASSWORD,
});

export const putPrivateModeSettings = (data: IPrivateModeSettingRequest) => ({
  type: CompanyActions.UPDATE_PRIVATE_MODE_SETTINGS,
  payload: data,
});

export const getCompanyAdditionalSettings = () => ({
  type: CompanyActions.OM_GET_ADDITIONAL_SETTINGS,
});
export const getTechnicalUsers = () => ({
  type: CompanyActions.GET_TECHNICAL_USERS,
});

export const getTechnicalUserPassword = (employeeId: string) => ({
  type: CompanyActions.GET_TECHNICAL_USER_PASSWORD,
  payload: employeeId,
});

export const clearTechnicalUserandPassword = () => ({
  type: CompanyActions.CLEAR_MULTIPLE_TECHNICAL_USER,
});
