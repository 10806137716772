// @ts-ignore
import { AlertNotificationAction } from "TARGET_BUILD/common/actionConstants";

export const getAlertCount = () => ({
  type: AlertNotificationAction.AN_GET_ALERTS_COUNT,
});

export const toggleAlertList = () => ({
  type: AlertNotificationAction.AN_TOGGLE_ALERT_LIST,
});

export const setAlertSubscriptionSetting = (payload, groupId) => ({
  groupId,
  payload,
  type: AlertNotificationAction.AN_SET_ALERTS_SUBSCRIPTION,
});

/**
 * @description Function to fetch the Active alert with offset and filters applied
 */

export const getAlertList = (offset?: number, currentView?: string) => ({
  currentView,
  offset: offset || 0,
  type: AlertNotificationAction.AN_GET_ALERTS_LIST,
});

export const setAlertView = (currentView) => ({
  payload: currentView,
  type: AlertNotificationAction.AN_SET_ALERT_VIEW,
});

export const getAlertDetails = (alertType, selectedId) => ({
  alertType,
  selectedId,
  type: AlertNotificationAction.AN_GET_ALERT_DETAILS,
});

export const additionalWorkerSelected = (workerDetails) => ({
  additionalWorkerDetails: workerDetails,
  type: AlertNotificationAction.ADDITIONAL_WORKER_CLICKED,
});

export const getAlertGroups = () => ({
  type: AlertNotificationAction.AN_GET_ALERT_GROUPS,
});

export const getAlertSettingsList = (groupId) => ({
  groupId,
  type: AlertNotificationAction.AN_GET_ALERTS_SETTINGS_LIST,
});

export const getQuickActionPopupPermission = (materialNumber, serialNumber, id) => ({
  type: AlertNotificationAction.AN_GET_QUICK_ACTION_POPUP_ACCESS,
  payload: { materialNumber, serialNumber, id },
});

export const getToolAlertsList = () => ({
  type: AlertNotificationAction.AN_GET_TOOL_ALERTS_LIST,
});

export const clearSubscriptionAlerts = () => ({
  type: AlertNotificationAction.CLEAR_ALERT_SETTINGS,
});

export const deleteQuickActionPopupPermission = (alertId) => ({
  type: AlertNotificationAction.DELETE_QUICK_ACTION_POPUP_PERMISSION,
  payload: { alertId },
});

export const clearQuickActionPopupPermissionList = () => ({
  type: AlertNotificationAction.CLEAR_QUICK_ACTION_POPUP_PERMISSION_LIST,
});

export const receiveNotificationAlertSettings = (alertId, groupId, storeGroupName, toolReturnName) => ({
  alertId,
  groupId,
  storeGroupName,
  toolReturnName,
  type: AlertNotificationAction.RECEIVE_NOTIFICATIONS_ALERT_SETTINGS,
});

/**
 * @description action to get the attribute for the filters.
 * @param {string} filters - filters fields.
 * @param alertType
 * @param delay
 * @param customAttribute
 * @param query
 */
export const getAlertFilterAttributes = (
  filters,
  alertType,
  delay = true,
  customAttribute?: string,
  query?: string,
) => ({
  payload: { alertType, customAttribute, delay, filters, query },
  type: AlertNotificationAction.AN_GET_ALERT_FILTER_FIELD,
});

export const setAlertFilterAttributes = (action) => ({
  payload: action.payload,
  type: AlertNotificationAction.AN_SET_ALERT_FILTER_FIELD,
});

export const setAppliedAttributes = (filterQuery) => ({
  payload: {
    filterQuery,
  },
  type: AlertNotificationAction.AN_SET_ALERT_FILTER_APPLIED_FIELD,
});

/**
 * @description Function to closeAlertFilter.
 */
export const openAlertFilter = () => ({
  type: AlertNotificationAction.AN_OPEN_ALERT_MORE_FILTER,
});

/**
 * @description Function to closeAlertFilter.
 */
export const closeAlertFilter = () => ({
  type: AlertNotificationAction.AN_CLOSE_ALERT_MORE_FILTER,
});
export const getNotificationAlertSettings = () => ({
  type: AlertNotificationAction.AN_GET_NOTIFICATION_ALERT_SETTINGS,
});

export const putInAppSettings = (alertId, setInAppData, storeGroupName, toolReturnName) => ({
  alertId,
  model: setInAppData,
  storeGroupName,
  toolReturnName,
  type: AlertNotificationAction.AN_PUT_IN_APP_SETTINGS,
});

export const getAlertUserSettingDataAction = () => ({
  type: AlertNotificationAction.AN_GET_ALERT_USER_SETTINGS,
});

export const updateAlertUserSetting = (currentView, settings) => ({
  currentView,
  settings,
  type: AlertNotificationAction.AN_UPDATE_ALERT_USER_SETTINGS,
});

export const checkUncheckSettingAction = (settings, rowData) => ({
  rowData,
  settings,
  type: AlertNotificationAction.CHECK_UNCHECK_USER_ALERT_SETTING,
});

export const selectEmailSettingAction = (settings, rowData) => ({
  rowData,
  settings,
  type: AlertNotificationAction.SELECT_EMAIL_SETTING,
});

export const clearActiveAlertDataAction = () => ({
  type: AlertNotificationAction.CLEAR_ALERT_DATA,
});

export const clearAlertDetailDataAction = () => ({
  type: AlertNotificationAction.CLEAR_ALERT_DETAIL_DATA,
});

export const clearReceiveNotificationAction = () => ({
  type: AlertNotificationAction.CLEAR_ALERT_NOTIFICATION,
});

/**
 * @description Function to dispatch the method to set search value in the store
 * @param searchQuery
 */
export const setSearchQuery = (searchQuery) => ({
  searchQuery,
  type: AlertNotificationAction.SET_ALERT_SEARCH_QUERY,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param filterQuery
 */
export const setAlertFilterQuery = (filterQuery) => ({
  filterQuery,
  type: AlertNotificationAction.SET_ALERT_FILTER_QUERY,
});

/**
 * @description Function to get the asset list with searched value.
 */
export const alertListForSearch = (currentView) => ({
  currentView,
  offset: 0,
  type: AlertNotificationAction.GET_ALERT_LIST_FOR_SEARCH,
});

/**
 * @description Function to dispatch the method to reset query params to default
 */
export const resetQueryObjToDefault = () => ({
  type: AlertNotificationAction.AN_ALERT_RESET_QUERY_PARAM,
});

export const dismissAlert = (alertIds, excludeIds, currentView, selectedAll) => ({
  alertIds,
  currentView,
  excludeIds,
  offset: 0,
  selectedAll,
  type: AlertNotificationAction.AN_DISMISS_ALERT,
});

export const prepareQtyItemsForQuickTransferFromAlert = (qtyItemId) => ({
  type: AlertNotificationAction.AN_QUICK_TRANSFER_ALERT,
  payload: {
    qtyItemId,
  },
});

export const prepareAllocationDataForSelectedAlert = (allocationDetails) => ({
  type: AlertNotificationAction.AN_MANAGE_ALLOCATIONS_ALERT,
  payload: {
    allocationDetails,
  },
});

export const getSuggestedTransfersCount = () => ({
  type: AlertNotificationAction.GET_SUGGESTED_TRANSFER_COUNT,
});

export const setAssetToOperationalAndDismissAlert = ({
  alertId,
  assetId,
  alertView,
}: {
  alertId: number;
  assetId: number;
  alertView: string;
}) => ({
  type: AlertNotificationAction.SET_ASSET_TO_OPERATIONAL_AND_DISMISS_ALERT,
  payload: {
    alertId,
    assetId,
    alertView,
  },
});

export const requestHOLPageForLostStolen = ({
  alertId,
  assetId,
  alertView,
}: {
  alertId: number;
  assetId: number;
  alertView: string;
}) => ({
  type: AlertNotificationAction.REQUEST_HOL_REDIRECT,
  payload: { alertId, assetId, alertView },
});
