// @ts-ignore
import { UserProfileActions } from "custom-actions";

/**
 * @description - Switch cases for the dispatched User Actions.
 *
 * @param state - State of the store.
 * @param action { type: UserProfileActions; payload?: object } - Dispatched action.
 */
const userReducer = (state: any = {}, action: { type: UserProfileActions; payload?: any }) => {
  switch (action.type) {
    case UserProfileActions.USER_PROFILE:
    case UserProfileActions.USER_ME_DATA:
      return {
        ...state,
      };
    case UserProfileActions.USER_PROFILE_RESPONSE:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case UserProfileActions.USER_ME_RESPONSE:
      return {
        ...state,
        userMeData: action.payload,
      };
    case UserProfileActions.UPDATE_USER_ACCEPTANCE_FLAG:
      return {
        ...state,
        userMeData: {
          ...state.userMeData,
          extWarningFlag: action?.payload?.extWarningFlag,
        },
      };
    case UserProfileActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordSuccess: true,
      };
    case UserProfileActions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordError: action.payload,
        resetPasswordSuccess: null,
      };
    case UserProfileActions.CLEAR_RESET_PASSWORD:
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordSuccess: null,
      };
    default:
      return state;
  }
};

export default userReducer;
