import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { default as TextField } from "../Field/field";
import ICurrencyProps from "./iCurrencyProps";
import IInputProps from "./iInputProps";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
  border: 2px solid
    ${(props) => {
      if (props["data-error"]) {
        return props.theme.colors.base.hiltiRedError;
      }
      if (props["data-disabled"]) {
        return props.theme.colors.base.heavyConcrete20;
      }
      return props.theme.colors.base.heavyConcrete60;
    }};
  align-items: center;
`;
export const Currency = styled.span`
  padding-left: 16px;
  align-self: center;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  color: ${(props) => props.theme.colors.base.heavyConcrete60};
`;
export const InputStyle = styled.input`
  flex: 1;
  height: 43px;
  padding-right: 16px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  background-color: ${(props) => props.theme.colors.base.white};
  color: ${(props) => (props.disabled ? props.theme.colors.base.steel20 : props.theme.colors.base.steel)};
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    font-weight: ${(props) => props.theme.fontNormal.fontWeight};
    opacity: 1; /* Firefox */
  }
  border: 0px;
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    font-weight: ${(props) => props.theme.fontNormal.fontWeight};
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    font-weight: ${(props) => props.theme.fontNormal.fontWeight};
  }
`;

export const CurrencyTemplate = (props: ICurrencyProps) => {
  const {
    input,
    label,
    placeholder,
    required,
    onInputChange,
    disabled,
    ref,
    readOnly,
    onBlur,
    meta: { touched, error, warning },
  } = props;

  return (
    <TextField
      name={input.name}
      htmlFor={input.name}
      label={label}
      error={error}
      touched={touched}
      warning={warning}
      required={required}
    >
      <Wrapper data-error={error && touched ? error : ""}>
        {props.currencyCode && <Currency data-disabled={disabled}>{props.currencyCode}</Currency>}
        <InputStyle
          {...input}
          ref={ref}
          id={input.name}
          placeholder={placeholder}
          type={"text"}
          value={input.value}
          disabled={disabled}
          readonly={readOnly}
          onChange={(val) => {
            input.onChange(val);
            if (onInputChange) {
              onInputChange(val);
            }
          }}
          onBlur={(val) => {
            const trimVal = val.target.value.trim();
            input.onBlur(trimVal);
            if (onBlur) {
              onBlur(trimVal);
            }
          }}
        />
      </Wrapper>
    </TextField>
  );
};

class CurrencyInput extends React.PureComponent<IInputProps> {
  render() {
    return (
      <Field
        id={this.props.id || this.props.name}
        name={this.props.name}
        label={this.props.label}
        type={this.props.type}
        placeholder={this.props.placeholder}
        required={this.props.required}
        component={CurrencyTemplate}
        validate={this.props.validation}
        ref={this.props.ref}
        {...this.props}
      />
    );
  }
}

export default CurrencyInput;
