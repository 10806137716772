import React from "react";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import { default as TextField } from "../Field/field";
import Tooltip from "@hilti/components/core/tooltip";

const RadioContainer = styled.div`
  padding: 8px 0;
  display: flex;
`;

const RadioWrapper = styled.label`
  position: relative;
  font-size: ${(props) => props.theme.fontSize.large};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  .rd-checked {
    display: none;
  }
  input {
    &:checked {
      ~ .rd-checked {
        display: ${(props) => (props["data-readonly"] ? "none" : "inline-flex")};
      }
      ~ .rd-unchecked {
        display: none;
      }
    }
  }
  &.error {
    svg path {
      fill: ${(props) => props.theme.colors.base.hiltiRedError};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  &:disabled ~ span {
    opacity: 0.5;
    cursor: default;
  }
`;
const Label = styled.span`
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: 300;
  line-height: 17px;
  font-size: ${(props) => props.theme.fontSize.base};
  cursor: pointer;
`;

const LabelDesc = styled.label`
  font-size: ${(props) => props.theme.fontSize.small};
`;

const LabelContainer = styled.span`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.base.steel};
  padding-left: ${(props) => (props["data-readonly"] ? "0" : "8px")};
`;

/**
 * @description - Radio button component that will be rendered under the redux field.
 *
 * @param props - Props of the radio button.
 */
export class RadioElement extends React.Component<any> {
  inputRef = React.createRef<any>();

  componentDidMount() {
    if (
      this.props.allSelectIndex == null &&
      this.props.onSelectionChange &&
      this.inputRef?.current?.value === this.props.input.value
    ) {
      this.inputRef.current.click();
    }
    if (this.inputRef?.current?.value === `select${this.props.allSelectIndex}`) {
      this.inputRef.current.click();
    }
  }

  /**
   * @description - Change event handler.
   *
   * @param event - Event of the element.
   */
  onChangeHandler = (event) => {
    this.props.input.onChange(event);
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(event);
    }
  };

  render() {
    const {
      input,
      checked = false,
      id,
      radioValue,
      radioLabel,
      labelDescription,
      labelOnly,
      required,
      showInlineError,
      style,
      disabled,
      meta: { touched, error, warning },
      toolTip,
    } = this.props;
    const showToolTip = toolTip && toolTip.showToolTip;
    return (
      <TextField
        name={input.name}
        htmlFor={input.name}
        label={""}
        error={showInlineError && error}
        touched={touched}
        warning={warning}
        required={required}
      >
        <RadioContainer className="radio-container">
          <Tooltip title={showToolTip ? toolTip.value : ""} placement={toolTip?.place}>
            <RadioWrapper
              style={style}
              data-disabled={disabled}
              data-tip=""
              data-for={showToolTip && `${id}Tooltip`}
              data-error={touched && error}
              className={touched && error ? "error" : null}
              data-readonly={labelOnly}
            >
              <Input
                {...input}
                id={id}
                type="radio"
                label={radioLabel}
                value={radioValue}
                key={`${radioValue}RBtn`}
                checked={checked ? checked : radioValue === input.value}
                onChange={this.onChangeHandler}
                ref={(element: any) => {
                  this.inputRef = element;
                }}
                disabled={disabled}
              />
              <SquareIcon name={IconName.Radio} className="rd-unchecked" />
              <SquareIcon name={IconName.RadioChecked} className="rd-checked" />
              <LabelContainer data-readonly={labelOnly}>
                <Label className="label">{this.props.radioLabel}</Label>
                {labelDescription && <LabelDesc> {labelDescription}</LabelDesc>}
              </LabelContainer>
            </RadioWrapper>
          </Tooltip>
        </RadioContainer>
      </TextField>
    );
  }
}
