import React from "react";
import ReactDatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";
import styled from "styled-components";
import "./datepicker-override.css";
import DatePickerForm from "./datePickerForm";
import IDatePickerProps from "./iDatePickerProps";

// using dynamic import for picking up locale
const getLocale = (locale) => import(`date-fns/locale/${locale}/index.js`);

const FormWrapper = styled("div")``;
const DatePickerHeaderContainer = styled("div")`
  display: flex;
  justify-content: center;
`;
const DatePickerHeader = styled("h2")`
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
`;

const DatePickerSelect = styled("select")`
  margin: 1px 2px;
`;

export const ActionButton = styled("button")`
  line-height: 1.7rem;
  text-align: center;
  position: absolute;
  top: 10px;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  overflow: hidden;
  display: inline;
  &.prev {
    left: 10px;
    border-right-color: ${(props) => props.theme.colors.base.steel30};
    &:hover {
      border-right-color: ${(props) => props.theme.colors.base.steel40};
    }
  }
  &.next {
    right: 10px;
    border-left-color: ${(props) => props.theme.colors.base.steel30};
    &:hover {
      border-left-color: ${(props) => props.theme.colors.base.steel40};
    }
  }
  &[disabled] {
    display: none;
  }
`;

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendarPortal");

  return <Portal container={el}>{children}</Portal>;
};

/**
 * Class acts as wrapper of Date Picker Component
 *
 * @class DatePicker
 * @extends {React.PureComponent<IDatePickerProps>}
 */
class DatePicker extends React.PureComponent<IDatePickerProps> {
  static defaultProps = {
    showTimeSelect: false,
    showTimeSelectOnly: false,
    timeFormat: "HH:mm",
  };

  state = { selectedDate: "MM/dd/yyyy" };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    if (this.props.onSelectChange) {
      this.props.onSelectChange(date);
    }
  };

  componentDidMount() {
    if (this.props.locale) {
      getLocale(this.props.locale).then((value) => {
        setDefaultLocale(value);
      });
    }
  }

  /**
   * disable user manual input in datepicker
   * @param event - event object of current target
   */
  disableManualInput = (event) => {
    event.preventDefault();
  };

  getOptions = (optionArr) =>
    optionArr.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));

  render() {
    const {
      disabled,
      format,
      isFormControl,
      id,
      name,
      label,
      getRef,
      validation,
      required,
      onSelectChange,
      placeholder,
      minDate,
      maxDate,
      isDisableInputBox = true,
      selected,
      isClearable,
      selectsStart,
      startDate,
      selectsEnd,
      endDate,
      showTimeSelect,
      showTimeSelectOnly,
      timeIntervals,
      timeFormat,
      openToDate,
      timeCaption,
    } = this.props;
    return !isFormControl ? (
      <FormWrapper>
        <ReactDatePicker
          id={id}
          selected={selected}
          onChange={this.handleDateChange}
          name={name}
          label={label}
          keyboard={true}
          clearable={true}
          dateFormat={format}
          placeholderText={placeholder ? placeholder : format}
          ref={getRef}
          minDate={minDate}
          maxDate={maxDate}
          showMonthDropdown={true}
          showYearDropdown={true}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          timeFormat={timeFormat}
          dropdownMode="select"
          disabled={disabled}
          popperContainer={CalendarContainer}
          isClearable={isClearable}
          onChangeRaw={isDisableInputBox ? (event) => this.disableManualInput(event) : null}
          selectsStart={selectsStart}
          startDate={startDate}
          selectsEnd={selectsEnd}
          endDate={endDate}
          openToDate={openToDate}
          timeCaption={timeCaption}
        />
      </FormWrapper>
    ) : (
      <DatePickerForm
        id={id}
        name={name}
        dateFormat={format}
        label={label}
        validation={validation}
        required={required}
        disabled={disabled}
        onSelectChange={onSelectChange}
        placeholder={placeholder}
        getRef={getRef}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeFormat={timeFormat}
        isDisableInputBox={isDisableInputBox}
        selected={selected}
        isClearable={isClearable}
        selectsStart={selectsStart}
        startDate={startDate}
        selectsEnd={selectsEnd}
        endDate={endDate}
        openToDate={openToDate}
        timeCaption={timeCaption}
      />
    );
  }
}

export default DatePicker;
