import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M7,21 L1,16 L7,11 L7,14 L15,14 L15,18 L7,18 L7,21 Z M17,3 L23,8 L17,13 L17,10 L9,10 L9,6 L17,6 L17,3 Z" />
    </g>
  </svg>
);

export default SVG;
