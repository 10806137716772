import { IGridSetting, IconName } from "am-web-ui-shared/components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import { BlankLiterals } from "../../../utils/enums/gridEnum";
import { ImageSizeEnum } from "../../styled/squareImage/imageSizeUtil";
import squareImageContainer from "../../styled/squareImage/squareImageContainer";

export const AddAssetInfoGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          blankLiteral: BlankLiterals.DASH,
          key: "manufacturerModel",
          properties: [{ name: "manufacturerName" }, { name: "model" }],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          blankLiteral: BlankLiterals.DASH,
          key: "name",
          properties: [{ name: "name" }],
          style: { color: `${theme.colors.base.black}`, fontFamily: `${theme.fontBold.fontFamily}`, fontSize: 16 },
        },
      ],

      cellSize: 4,
      image: {
        attributes: {
          image: { maxWidth: ImageSizeEnum.SMALL, maxHeight: ImageSizeEnum.SMALL },
          wrapper: {
            height: ImageSizeEnum.SMALL,
            imageSize: 42,
            style: { marginRight: "16px" },
            width: ImageSizeEnum.SMALL,
          },
        },
        component: squareImageContainer,
        defaultImage: IconName.Category,
        dependencies: {
          getAlternateDefaultImage: "getAlternateDefaultImage",
        },
        fieldName: "assetImageId",
      },
      isSortable: false,
      type: "imageFieldCombination",
    },
    {
      bindProperties: [
        {
          blankLiteral: BlankLiterals.DASH,
          key: "currentLocationName",
          properties: [{ name: "currentLocationName" }],
        },
        {
          blankLiteral: BlankLiterals.DASH,
          key: "responsibleEmployeeId",
          properties: [{ name: "responsibleEmployeeId" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      bindProperties: [
        {
          blankLiteral: BlankLiterals.DASH,
          key: "scanCode",
          properties: [{ name: "scanCode" }],
        },
        {
          blankLiteral: BlankLiterals.DASH,
          key: "inventoryNumber",
          properties: [{ name: "inventoryNumber" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      bindProperties: [
        {
          properties: [{ name: "statusCode" }],
        },
      ],
      cellSize: 2,
      component: "getAssetsStatus",
      isSortable: false,
      type: "custom",
    },
  ],
  showTooltip: true,
};

export const EditAssetInfoGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          blankLiteral: BlankLiterals.DASH,
          key: "manufacturerModel",
          properties: [{ name: "manufacturerName" }, { name: "model" }],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          blankLiteral: BlankLiterals.DASH,
          key: "name",
          properties: [{ name: "name" }],
          style: { color: `${theme.colors.base.black}`, fontFamily: `${theme.fontBold.fontFamily}`, fontSize: 16 },
        },
      ],

      cellSize: 4,
      image: {
        attributes: {
          image: { maxWidth: ImageSizeEnum.SMALL, maxHeight: ImageSizeEnum.SMALL },
          wrapper: {
            height: ImageSizeEnum.SMALL,
            imageSize: 42,
            style: { marginRight: "16px" },
            width: ImageSizeEnum.SMALL,
          },
        },
        component: squareImageContainer,
        defaultImage: IconName.Category,
        dependencies: {
          getAlternateDefaultImage: "getAlternateDefaultImage",
        },
        fieldName: "assetImageId",
      },
      isSortable: false,
      type: "imageFieldCombination",
    },
    {
      bindProperties: [
        {
          blankLiteral: BlankLiterals.DASH,
          key: "currentLocationId",
          properties: [{ name: "currentLocationName" }],
        },
        {
          blankLiteral: BlankLiterals.DASH,
          key: "responsibleEmployeeId",
          properties: [{ name: "responsibleEmployeeName" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      bindProperties: [
        {
          blankLiteral: BlankLiterals.DASH,
          key: "scanCode",
          properties: [{ name: "scanCode" }],
        },
        {
          blankLiteral: BlankLiterals.DASH,
          key: "inventoryNumber",
          properties: [{ name: "inventoryNumber" }],
        },
      ],
      cellSize: 3,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      bindProperties: [
        {
          properties: [{ name: "assetStatusResponse" }],
        },
      ],
      cellSize: 2,
      component: "getAssetsStatus",
      isSortable: false,
      type: "custom",
    },
  ],
  showTooltip: true,
};
