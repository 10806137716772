import { CheckAuthorization } from "am-web-ui-shared/helpers";
// @ts-ignore
import React from "react";
import { withTranslation } from "react-i18next";
import { ICancelTransferViewProps, ICancelTransferViewState } from "../model/iCancelTransferView";
// @ts-ignore
import CancelTransferViewForm from "./cancelTransferViewForm";
import { AssetSubType } from "TARGET_BUILD/common/utils/enums/assetEnum";
import Tooltip from "@hilti/components/core/tooltip";
import Button from "@am/web-ui-components/core/button";

/**
 * Class to act as panel for cancel transfer dialog.
 *
 * @class CancelTransferView
 * @extends {React.Component<ICancelTransferView>}
 *
 */
export class CancelTransferView extends React.Component<ICancelTransferViewProps, ICancelTransferViewState> {
  state = {
    showCancelTransferForm: false,
  };

  /**
   * @description - Function to show cancel transfer view form when cancel transfer button clicked.
   */
  onCancelTransferClick = () => {
    this.setState({ showCancelTransferForm: true });
  };

  /**
   * @description - Function to hide cancle transfer form when cancel button is clicked.
   */
  handleCancel = () => {
    this.setState({ showCancelTransferForm: false });
  };

  handleSave = (cancelNote) => {
    this.props.handleSave(cancelNote, this.props.transferHistoryResponse);
  };

  checkDisable = () => {
    return this.props.fromAssetSubType === AssetSubType.LOCKER || this.props.toAssetSubType === AssetSubType.LOCKER;
  };

  getTooltip = () => {
    return this.checkDisable() ? this.props.t("assets:CANCEL_TRANSFER_BUTTON_TOOLTIP") : "";
  };

  cancelTransferButton = () => {
    return (
      <Tooltip title={this.getTooltip()}>
        <span>
          <Button disabled={this.checkDisable()} onClick={this.onCancelTransferClick}>
            {this.props.t("assets:CANCEL_TRANSFER")}
          </Button>
        </span>
      </Tooltip>
    );
  };

  render() {
    return (
      <>
        <CheckAuthorization yes={this.cancelTransferButton} permissionKey={"asset.transfer.cancel"} />
        <>
          {this.state.showCancelTransferForm && (
            <CancelTransferViewForm
              name={this.props.name}
              handleCancel={this.handleCancel}
              handleSave={this.handleSave}
            />
          )}
        </>
      </>
    );
  }
}

export default withTranslation()(CancelTransferView);
