// @ts-ignore
import { MasterActions } from "custom-actions";
import { all, put, takeLatest } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import {
  allocationStatusListApi,
  allocationStockLevelApi,
  getAssetCodes,
  getAssetUsageCondition,
  getCountries,
  getCurrencies,
  getLanguage,
  getLocationStatesList,
  getReportTemplateCategories,
  getRoles,
  getScanCodeType,
  getServiceStatus,
  getCertificateStatus,
  getHiltiAssetStatus,
  getQtyItemActivityTypes,
  getServiceResults,
  getTimeBasedEnabledTenants,
  getCostCodes,
} from "../../api/masterDataApi";
import { validateResponse } from "../../utils/errorUtils";
import { getFilteredLanguageList } from "../../utils/localization/localizationUtil";
import { call } from "../../sagas/common/commonSaga";
import { masterDataInitialState } from "./masterDataReducer";
import { getTimeBasedEnabledTenantListSuccess } from "./masterDataActionCreator";

export function* getAssetCodeListSaga() {
  yield takeLatest(MasterActions.GET_ASSET_CODE_LIST, getAssetCodeListData);
}

export function* getAssetCodeListData() {
  yield put({
    type: MasterActions.AM_ASSET_CODES_SUCCESS,
    payload: {
      assetStatusResponse: masterDataInitialState.assetStatusResponse,
      ownerShipTypeResponse: masterDataInitialState.ownerShipTypeResponse,
    },
  });
  let response = yield call(() => {
    return getAssetCodes();
  });

  if (response.error) {
    response = {
      assetStatusResponse: [],
      ownerShipTypeResponse: [],
    };
  }
  yield put({ type: MasterActions.AM_ASSET_CODES_SUCCESS, payload: response });
}

export function* getScanCodeTypeListSaga() {
  yield takeLatest(MasterActions.GET_SCAN_CODE_TYPE_LIST, getScanCodeTypeList);
}

export function* getScanCodeTypeList() {
  yield put({
    type: MasterActions.AM_SCAN_CODE_TYPE_SUCCESS,
    payload: { assetIdentificationMechanismResponses: masterDataInitialState.scanCodeTypeList },
  });
  let response = yield call(() => {
    return getScanCodeType();
  });

  if (response.error) {
    response = {
      assetIdentificationMechanismResponses: [],
    };
  }
  yield put({ type: MasterActions.AM_SCAN_CODE_TYPE_SUCCESS, payload: response });
}

export function* getAssetUsageConditionListSaga() {
  yield takeLatest(MasterActions.GET_ASSET_USAGE_CONDITION_LIST, getAssetUsageConditionList);
}

export function* getAssetUsageConditionList() {
  yield put({
    type: MasterActions.AM_ASSET_USAGE_CONDITION_SUCCESS,
    payload: { assetUsageConditionStateResponse: masterDataInitialState.assetUsageConditionStateResponse },
  });
  let response = yield call(() => {
    return getAssetUsageCondition();
  });

  if (response.error) {
    response = {
      assetUsageConditionStateResponse: [],
    };
  }
  yield put({ type: MasterActions.AM_ASSET_USAGE_CONDITION_SUCCESS, payload: response });
}

export function* getCurrenciesListSaga() {
  yield takeLatest(MasterActions.GET_CURRENCIES_LIST, getCurrenciesList);
}

export function* getCurrenciesList() {
  yield put({ type: MasterActions.AM_CURRENCIES_SUCCESS, payload: masterDataInitialState.currenciesList });
  let response = yield call(() => {
    return getCurrencies();
  });

  if (response.error) {
    response = [];
  }
  yield put({ type: MasterActions.AM_CURRENCIES_SUCCESS, payload: response });
}

/**
 * Saga to fetch all Worker's Roles.
 */
export function* getRolesListSaga() {
  yield takeLatest(MasterActions.OM_GET_ROLES_LIST, getRolesList);
}

export function* getRolesList() {
  yield put({ type: MasterActions.GET_ROLES_SUCCESS, payload: masterDataInitialState.rolesList });
  let response = yield call(() => {
    return getRoles();
  });

  if (response.error) {
    response = [];
  }
  yield put({ type: MasterActions.GET_ROLES_SUCCESS, payload: response });
}

/**
 * @description the function to get Countries list from API
 */
export function* getCountriesSaga() {
  yield takeLatest(MasterActions.OM_GET_COUNTRIES, getCountriesList);
}

export function* getCountriesList() {
  yield put({ type: MasterActions.GET_COUNTRIES_SUCCESS, payload: masterDataInitialState.countryList });
  let response = yield call(() => {
    return getCountries();
  });

  if (response.error) {
    response = [];
  }
  yield put({ type: MasterActions.GET_COUNTRIES_SUCCESS, payload: response });
}

/**
 * @description the saga function to get Location States
 *
 * @export
 */
export function* getLocationStatesSaga() {
  yield takeLatest(MasterActions.GET_LOCATION_STATES, getLocationStates);
}

/**
 * @description the function to get Location Manager Details from API
 *
 * @export
 * @param {countryCode: number} action - the action object
 */
export function* getLocationStates() {
  yield put({ type: MasterActions.LOCATION_STATES_SUCCESS, payload: masterDataInitialState.locationStatesList });
  let response = yield call(() => {
    return getLocationStatesList();
  });

  if (response.error) {
    response = [];
  }
  yield put({
    payload: response,
    type: MasterActions.LOCATION_STATES_SUCCESS,
  });
}

/**
 * Saga to fetch all languages list.
 */
export function* getLanguageListSaga() {
  yield takeLatest(MasterActions.OM_GET_LANGUAGE_LIST, getLanguageList);
}

export function* getLanguageList(action) {
  yield put({ type: MasterActions.LOCATION_STATES_SUCCESS, payload: masterDataInitialState.languageList });
  let response = yield call(() => {
    return getLanguage(action.countryCode);
  });
  if (response.error) {
    response = [];
  } else {
    response = getFilteredLanguageList(response);
  }
  yield put({ type: MasterActions.OM_LANGUAGE_RESPONSE, payload: response });
}

export function* fetchAllocationStatusListSaga() {
  yield takeLatest(MasterActions.GET_ALLOCATION_STATUS_LIST, getAllocationStatusList);
}

function* getAllocationStatusList() {
  const response = yield call(() => allocationStatusListApi());
  if (!response.error) {
    yield put({
      payload: response,
      type: MasterActions.ALLOCATION_STATUS_LIST_SUCCESS,
    });
  } else {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
    }
  }
}

export function* fetchReportTemplateCategoriesSaga() {
  yield takeLatest(MasterActions.GET_REPORT_TEMPLATES_CATEGORIES, fetchReportTemplateCategories);
}

export function* fetchReportTemplateCategories() {
  const response = yield call(() => {
    return getReportTemplateCategories();
  });
  if (!response.error) {
    yield put({
      payload: response.categories,
      type: MasterActions.REPORT_TEMPLATES_CATEGORIES_SUCCESS,
    });
  }
}

export function* fetchAllocationStockLevelSaga() {
  yield takeLatest(MasterActions.GET_ALLOCATION_STOCK_LEVEL, fetctAllocationStockLevel);
}

export function* fetctAllocationStockLevel() {
  const response = yield call(() => {
    return allocationStockLevelApi();
  });
  if (!response.error) {
    yield put({
      payload: response,
      type: MasterActions.ALLOCATION_STOCK_LEVEL_SUCCESS,
    });
  }
}

export function* fetchServiceStatusListSaga() {
  yield takeLatest(MasterActions.AM_GET_SERVICE_STATUS_LIST, fetchServiceStatusList);
}

export function* fetchServiceStatusList() {
  const response = yield call(() => {
    return getServiceStatus();
  });
  if (!response.error) {
    yield put({
      payload: response.serviceStatusResponses,
      type: MasterActions.SERVICE_STATUS_LIST_SUCCESS,
    });
  }
}

export function* fetchServiceResultsListSaga() {
  yield takeLatest(MasterActions.AM_GET_SERVICE_RESULTS_LIST, fetchServiceResultsList);
}

export function* fetchServiceResultsList() {
  const response = yield call(() => {
    return getServiceResults();
  });
  if (!response.error) {
    yield put({
      payload: response.serviceResults,
      type: MasterActions.SERVICE_RESULTS_LIST_SUCCESS,
    });
  }
}

export function* fetchCertificateStatusListSaga() {
  yield takeLatest(MasterActions.AM_GET_CERTIFICATE_STATUS_LIST, fetchCertificateStatusList);
}

export function* fetchCertificateStatusList() {
  const response = yield call(() => {
    return getCertificateStatus();
  });
  if (!response.error) {
    yield put({
      payload: response,
      type: MasterActions.CERTIFICATE_STATUS_LIST_SUCCESS,
    });
  }
}

export function* fetchHiltiAssetStatusListSaga() {
  yield takeLatest(MasterActions.HI_GET_HILTI_ASSET_STATUS_LIST, fetchHiltiAssetStatusList);
}

export function* fetchHiltiAssetStatusList() {
  const response = yield call(() => {
    return getHiltiAssetStatus();
  });
  if (!response.error) {
    yield put({
      payload: response,
      type: MasterActions.HI_GET_HILTI_ASSET_STATUS_LIST_SUCCESS,
    });
  }
}

export function* fetchQtyItemActivityTypeListSaga() {
  yield takeLatest(MasterActions.AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST, fetchQtyItemActivityTypeList);
}

export function* fetchQtyItemActivityTypeList() {
  const response = yield call(() => {
    return getQtyItemActivityTypes();
  });
  if (!response.error) {
    yield put({
      payload: response,
      type: MasterActions.AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST_SUCCESS,
    });
  }
}

export function* getTimeBasedEnabledTenantListSaga() {
  yield takeLatest(MasterActions.GET_TIME_BASED_ENABLED_TENANT_LIST, fetchTimeBasedEnabledTenantList);
}
export function* fetchTimeBasedEnabledTenantList() {
  const response = yield call(getTimeBasedEnabledTenants);

  if (!response.error) {
    yield put(getTimeBasedEnabledTenantListSuccess(response));
  }
}

export function* getCostCodeListSaga() {
  yield takeLatest(MasterActions.GET_COST_CODE_LIST, getCostCodeListData);
}

export function* getCostCodeListData() {
  yield put({
    type: MasterActions.ACR_COST_CODE_SUCCESS,
    payload: {
      costTypeResponse: masterDataInitialState.costTypeResponse,
      costCategoryResponse: masterDataInitialState.costCategoryResponse,
    },
  });
  let response = yield call(getCostCodes);

  if (response.error) {
    response = {
      costTypeResponse: [],
      costCategoryResponse: [],
    };
  }
  yield put({ type: MasterActions.ACR_COST_CODE_SUCCESS, payload: response });
}

function* masterDataSaga() {
  yield all([
    getAssetCodeListSaga(),
    getScanCodeTypeListSaga(),
    getAssetUsageConditionListSaga(),
    getCurrenciesListSaga(),
    getRolesListSaga(),
    getCountriesSaga(),
    getLocationStatesSaga(),
    getLanguageListSaga(),
    fetchAllocationStatusListSaga(),
    fetchReportTemplateCategoriesSaga(),
    fetchAllocationStockLevelSaga(),
    fetchServiceStatusListSaga(),
    fetchServiceResultsListSaga(),
    fetchCertificateStatusListSaga(),
    fetchHiltiAssetStatusListSaga(),
    fetchQtyItemActivityTypeListSaga(),
    getTimeBasedEnabledTenantListSaga(),
    getCostCodeListSaga(),
  ]);
}

export default masterDataSaga;
