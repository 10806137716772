import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import styled from "styled-components";
import { colorPalette } from "../../materials";
import IEllipsisProps from "./iEllipsis";

const TestEllipsis = styled.div`
  .text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    line-height: 19px;
    cursor: pointer;
  }
  .custom-highlight {
    background-color: #ffaf0080;
  }
`;

const TooltipTitleSpan = styled.span`
  white-space: pre-wrap;
`;

/**
 * @description - Styles for the ellipsis component.
 */
const styles = () => ({
  arrow: {
    color: colorPalette.black,
  },
  bootstrapTooltip: {
    maxWidth: "512px",
    borderRadius: 0,
    backgroundColor: colorPalette.black,
    fontFamily: "inherit",
    fontSize: "14px",
    lineHeight: "17px",
    padding: "8px",
    textAlign: "left" as "left",
    wordBreak: "break-all" as "break-all",
  },

  bootstrapPlacementBottom: {
    margin: "8px 0",
  },
  bootstrapPlacementLeft: {
    margin: "0 8px",
  },
  bootstrapPlacementRight: {
    margin: "0 8px",
  },
  bootstrapPlacementTop: {
    margin: "8px 0",
  },
  listItem: {
    fontFamily: "inherit",
    fontSize: "inherit",
    maxWidth: "100%",
    overflow: "hidden",
    paddingRight: "14px",
    position: "relative" as "relative",
    textAlign: "justify" as "justify",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  },
  listItemInitial: {
    fontFamily: "inherit",
    fontSize: "inherit",
    maxWidth: "100%",
    overflow: "hidden",
    paddingRight: "14px",
    position: "relative" as "relative",
    textAlign: "justify" as "justify",
    whiteSpace: "nowrap" as "nowrap",
  },
});

/**
 * @description - Ellipsis component that added the ellipsis if the text overflow the specified height and width and it adds the tooltip too.
 */
export const Field = (props: IEllipsisProps) => {
  const { classes, children, className, id, style, tooltipValue, showTooltip } = props;
  return showTooltip ? (
    <TestEllipsis id={`${id}`} className={className}>
      <Tooltip
        arrow
        id={`${id}Tooltip`}
        title={<TooltipTitleSpan>{tooltipValue}</TooltipTitleSpan>}
        classes={{
          arrow: classes.arrow,
          tooltip: classes.bootstrapTooltip,
          tooltipPlacementBottom: classes.bootstrapPlacementBottom,
          tooltipPlacementLeft: classes.bootstrapPlacementLeft,
          tooltipPlacementRight: classes.bootstrapPlacementRight,
          tooltipPlacementTop: classes.bootstrapPlacementTop,
        }}
      >
        <span className="text" style={{ ...style }}>
          {children}
        </span>
      </Tooltip>
    </TestEllipsis>
  ) : (
    <TestEllipsis id={`${id}`} className={className}>
      <span className="text" style={{ ...style }}>
        {children}
      </span>
    </TestEllipsis>
  );
};

/**
 * @description - Adding the styles and returning the styled component.
 */

const Ellipsis = withStyles(styles)(Field);

export default Ellipsis;
