/**
 * @description - responseModelEnum is used to create response of asset template merge
 *
 */
export enum responseModelEnum {
  "costTypeValueDisplay" = "costTypeValueDisplay",
  "defaultGroupId" = "defaultGroupId",
  "defaultGroupName" = "defaultGroupName",
  "manufacturerId" = "manufacturerId",
  "manufacturerName" = "manufacturerName",
  "manufacturerNameAndModel" = "manufacturerNameAndModel",
  "manufacturerNameAndModelWithDash" = "manufacturerNameAndModelWithDash",
  "model" = "model",
  "services" = "services",
  "templateAttachments" = "templateAttachments",
  "templateImageId" = "templateImageId",
}

export enum blankValueEnum {
  "NONE" = "common:NONE",
  "NO_VALUE" = "common:NO_VALUE",
}
