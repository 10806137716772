import { SharedUtils } from "am-web-ui-shared/helpers";
import IFilterFieldModel from "TARGET_BUILD/common/models/customView/iFilterFieldModel";
import { getGTMFiltersFields } from "./utils/analyticsUtils";

export enum EntityTitle {
  ASSET = "asset",
}

export const COMBINED_FILTER = "combined_filter";

export enum FiltersMapping {
  currentLocationName = 1,
  responsibleEmployeeName,
  assetStatus,
  scanCodeType,
  groupName,
  labelNames,
  manufacturerName,
  model,
  assetState,
  assetType,
  hasAttachments,
  assetUsageConditionState,
  assetCategory,
  connectedCharger,
  costCode,
  currentLocationManagerName,
  currentLocationType,
  defaultLocationName,
  defaultLocationType,
  hiltiAssetOwnerName,
  hiltiAssetStatus,
  idleTimeDuration,
  hasImage,
  loanToolClaim,
  parentLocation,
  monthlyFleetRate,
  ownerName,
  ownerShipType,
  purchaseCurrencyCode,
  purchaseDate,
  registeredBy,
  rentalReturnDate,
  replacementCost,
  tagStatus,
  batteryStatus,
  theftInsurance,
  transferred,
  vendor,
  purchasePrice,
}

export const trackAssetsCombinedFilters = (filters: IFilterFieldModel[]) => {
  const field = getGTMFiltersFields(filters, FiltersMapping);
  SharedUtils.pushDataToGA(COMBINED_FILTER, null, {
    entity: EntityTitle.ASSET,
    count: filters.length,
    field,
  });
};
