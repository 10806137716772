// @ts-ignore
import { TransferCartConstants } from "custom-actions";

/**
 * @description Function to set unset the selection and also for select all and unselect all
 * @param {number} rowId - row to toggle selection
 * @param {string} source - source of action creator
 * @param {string} rowObj - row object data
 */
export const selectRow = (rowId: any, source: string, isMultiSelect = false, rowObj?: object) => ({
  id: rowId,
  isMultiSelect,
  rowObj,
  type: source,
});

/**
 * @description Function to set unset the selection and also for select all and unselect all
 * @param {number} rowId - row to toggle selection
 * @param {string} source - source of action creator
 */
export const clearRowSelection = (rowId: any, source: string, rowObj?: string) => ({
  id: rowId,
  rowObj,
  type: source,
});

/**
 * @description Function to set unset the selection and also for select all and unselect all
 * @param {number} rowId - row to toggle selection
 * @param {string} source - source of action creator
 */
export const selectAll = (source: string) => ({
  type: source,
});

/**
 * @description Function to set unset the selection and also for select all and unselect all
 * @param {number} rowId - row to toggle selection
 * @param {string} source - source of action creator
 */
export const clearAllSelection = (source: string) => ({
  type: source,
});

/**
 * @description setTransferDate to set the transfer date
 */
export const setTransferDate = () => ({
  type: TransferCartConstants.TRANSFER_SET_TRANSFER_DATE,
});

// It will give the "Set Return Date" status. i.e. Is it enabled or disabled.
export const setReturnDateStatus = (isSetReturnDate) => ({
  isSetReturnDate,
  type: TransferCartConstants.TRANSFER_SET_RETURN_DATE_STATUS,
});

/**
 * @description setSubscription, select/deselct the row for subscription
 * @param selectedRow the object conatins the data about the row
 * @param source contains the type for the action
 * @param isSubscribed contains the boolean value need to subscribe/unsubscribe
 */
export const setSubscription = (selectedRow: object, source: string, isSubscribed: boolean) => ({
  isSubscribed,
  selectedRow,
  type: source,
});

/**
 * @description setRowDisabled, make row disabled
 * @param rowCode the object conatins the data to disable/enable the row
 * @param source the type of the action
 * @param isRowDisabled boolean, decides make row disable/enable
 */
export const setRowDisabled = (rowCode, source, isRowDisabled) => ({
  isRowDisabled,
  row: rowCode,
  type: source,
});

export const setExpandedProperty = (rowObj, source) => ({
  rowObj,
  type: source,
});

/**
 * @description Function to select/unselect checkbox of row
 * @param {number} rowId - row to toggle selection
 * @param {string} source - source of action creator
 *  @param {string} value - true for selecting a checkbox
 */
export const selectGridCheckbox = (rowId: number, source: string) => ({
  id: rowId,
  type: source,
  value: true,
});

/**
 * @description Function to set unset the checkbox selection
 * @param {number} rowId - row to toggle selection
 * @param {string} source - source of action creator
 * @param {string} value - false for deselecting a checkbox
 */
export const unSelectGridCheckbox = (rowId: number, source: string) => ({
  id: rowId,
  type: source,
  value: false,
});

/**
 * @description Function to set unset the selection and also for select all and unselect all
 *
 * @param {number} item - row to toggle selection
 * @param {string} source - source of action creator
 */
export const handleRowSelection = (item: any, source: string) => ({
  item,
  type: source,
});

/**
 * @description Function to select/unselect checkbox of row
 * @param {number} item - checked row item
 * @param {string} type - source type of action creator
 * @param {string} value - true for selecting a checkbox
 */
export const handleGridCheckboxSelection = (item, type: string, value: boolean) => ({
  item,
  type,
  value,
});
