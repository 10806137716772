import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import IComponentHeader from "./iComponentHeader";

// @ts-ignore
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";

/**
 * @const StyledTitle - This is styled component that will be used to create the title of the details panel.
 */
const StyledTitle = styled("div")`
  font-size: ${(props) => props.theme.fontSize.large};
  min-height: 44px;
  box-sizing: border-box;
  padding: 8px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  width: 100%;
  background: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.header.color};
  border-bottom: 2px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  .fixed-container {
    width: 992px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close {
    cursor: pointer;
  }
`;

/**
 * @description - This is any styled component that will be used to create the title of the details panel.
 * It is having the text row along with a cross icon on its right (::after).
 *
 * @param props - Properties of the component.
 * @interface ITitleProps
 */
type IComponentHeaderProps = PropsWithChildren<IComponentHeader>;

const ComponentHeader = (props: IComponentHeaderProps) => {
  const { id, children, panelCloseHandler } = props;
  return (
    <StyledTitle>
      <div className="fixed-container">
        {children}
        <SquareIcon id={id} onClick={panelCloseHandler} name={IconName.Cross} className="close" />
      </div>
    </StyledTitle>
  );
};

/**
 * @export ComponentHeader - Styled Component.
 */
export default ComponentHeader;
