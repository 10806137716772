import { SharedUtils } from "am-web-ui-shared/helpers";

export const CLICK_UPGRADE = "click_upgrade";
export const ClOSE_UPGRADE_DIALOG = "close-upgrade-dialog";
export const CLICK_UPGRADE_LEARN_MORE = "click_upgrade-learn-more";
export const CLICK_UPGRADE_CALL_BACK = "click_upgrade-call-back";

export const trackUpgradeDialogEvents = (event: string) => {
  SharedUtils.pushDataToGA(event);
};
