import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React from "react";
import styled from "styled-components";
import IMenuListProps from "./iMenuListProps";

const MenuListStyled = styled(MenuList)`
  width: 240px;

  ::before {
    content: "";
    position: absolute;
    right: -3%;
    top: 3px;
    box-sizing: border-box;
    border: 10px solid black;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: ${(props) => props.theme.colors.base.white};
    border-left-color: ${(props) => props.theme.colors.base.white};
    transform-origin: 0 0;
    transform: rotate(-223deg);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
  && {
    padding: 0;
    li {
      position: unset;
      font-family: ${(props) => props.theme.fontRoman.fontFamily};
      #labelcontainer {
        top: 1px;
        left: -30px;
      }
      #sortMenuList {
        top: 0;
        left: 0;
      }
      a div {
        display: flex;
        flex-direction: unset;
        align-items: baseline;
        img {
          top: 3px;
        }
        div {
          color: ${(props) => props.theme.colors.base.black};
          margin-left: 16px;
        }
      }
      div {
        margin-bottom: 0;
        a {
          display: flex;
          flex-direction: unset;
          align-items: baseline;
          img {
            top: 3px;
          }
          div {
            color: ${(props) => props.theme.colors.base.black};
            margin-left: 16px;
          }
        }
      }
    }
  }
`;
const ListContainer = styled.div`
  display: inline;
  &&& {
    div[role="tooltip"] {
      left: -90px !important;
      top: 9px !important;
      box-shadow: 0px 2px 5px 2px ${(props) => props.theme.colors.base.heavyConcrete20};
      z-index: 1;
    }
  }
  .moreLink {
    span {
      flex-direction: column-reverse;
      text-transform: capitalize;
      font-size: 10px;
      color: ${(props) => props.theme.colors.base.steel};
      margin-top: 3px;
    }
  }
`;

/**
 * Class ListMenu is the shared class for reponsive action list
 *
 * @class ListMenu
 * @extends {React.PureComponent<IMenuListProps>}
 */

class ListMenu extends React.PureComponent<IMenuListProps> {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClose = (event) => {
    if (this.state.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  renderPopper = (TransitionProps, placement, options) => (
    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
      <Paper>
        <ClickAwayListener onClickAway={this.handleClose}>
          <MenuListStyled>
            {options.map((option) => (
              <MenuItem key={option} selected={option === "Pyxis"}>
                {option.icon}
              </MenuItem>
            ))}
          </MenuListStyled>
        </ClickAwayListener>
      </Paper>
    </Grow>
  );

  render() {
    const { options } = this.props;

    return (
      <ListContainer id="icons">
        <Button
          disableRipple={true}
          className="moreLink"
          ref={(node) => {
            this.state.anchorEl = node;
          }}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <span> {this.props.textValue}</span>
          <i className="material-icons">more_horiz</i>
        </Button>
        <Popper open={this.state.open} anchorEl={this.state.anchorEl} transition={true} disablePortal={true}>
          {({ TransitionProps, placement }) => this.renderPopper(TransitionProps, placement, options)}
        </Popper>
      </ListContainer>
    );
  }
}

export default ListMenu;
