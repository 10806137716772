// @ts-ignore
import { ConfirmTransferActions } from "custom-actions";

export const getConfirmTransferList = (offset = 0) => ({
  offset,
  type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_LIST,
});

export const getConfirmTransferCount = () => ({
  type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT,
});

export const getConfirmRecallList = (transferId: number, actionName: string) => ({
  transferId,
  actionName,
  type: ConfirmTransferActions.GET_CONFIRM_RECALL_LIST,
});

export const saveConfirmRecall = (payload) => ({
  payload,
  type: ConfirmTransferActions.SET_CONFIRM_RECALL_LIST,
});

export const setConfirmRecallCheckboxSelection = (checkboxStatus: boolean, entityId: number) => ({
  payload: { checkboxStatus, entityId },
  type: ConfirmTransferActions.SET_CONFIRM_RECALL_CHECKBOX,
});
