import React from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import styled from "styled-components";
import { HelperLodash } from "../../../../helpers";
import { IDataRangeControlProps, IDataRangeControlState } from "./iDataRange";
import ApplyButton from "../applyButton/applyButton";

const IDLE_TIME_DURATION = "idleTimeDuration";

export const DataRangeWrapper = styled.div`
  padding: 25px 16px 16px;
  background-color: ${(props) => props.theme.colors.base.white};
  &.dropdown-range-wrapper {
    box-shadow:
      0 2px 12px 0 rgba(82, 79, 83, 0.5),
      inset 0 1px 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
    width: 400px;
  }
  .disabled {
    opacity: 0.5;
  }
  .input-range {
    margin: 0 12px;
    width: auto;
    .input-range__track {
      height: 3px;
      &.input-range__track--background {
        background-color: ${(props) => props.theme.colors.base.heavyConcrete60};
      }
      &.input-range__track--active {
        background-color: ${(props) => props.theme.colors.base.steel};
      }
    }
    .input-range__slider {
      background-color: ${(props) => props.theme.colors.base.white};
      border: 3px solid ${(props) => props.theme.colors.base.steel};
      height: 24px;
      width: 24px;
      margin-left: -12px;
      margin-top: -14px;
      &:hover,
      &:focus {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }
    &--disabled {
      .input-range__track--background {
        cursor: default;
      }
      .input-range__slider {
        cursor: default;
        border-color: ${(props) => props.theme.colors.base.heavyConcrete60};
        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .value-wrapper {
    margin-top: 15px;
    &__container {
      height: 48px;
      max-width: 200px;
      border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete60};
      padding: 10px 18px;
      font-family: ${(props) => props.theme.fontBold.fontFamily};
      font-weight: ${(props) => props.theme.fontBold.fontWeight};
      color: ${(props) => props.theme.colors.base.steel};
      display: flex;
      align-items: center;
      &.disabled {
        opacity: 0.5;
      }
      .date-unit {
        color: ${(props) => props.theme.colors.base.heavyConcrete60};
        font-size: ${(props) => props.theme.fontSize.small};
      }
    }
    &__separator {
      width: 18px;
      height: 2px;
      background-color: ${(props) => props.theme.colors.base.heavyConcrete60};
      align-self: center;
      margin: 0 2px;
    }
    &__center {
      width: 22px;
      display: flex;
      flex-direction: column;
      padding: 0 2px;
      label {
        height: 17px;
      }
      span {
        margin-top: 22px;
      }
    }
    &__field {
      border: 0;
      padding: 0;
      background-color: transparent;
      min-width: 0;
      width: 100%;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      -moz-appearance: textfield;
    }
    &__lbl {
      font-size: ${(props) => props.theme.fontSize.base};
      display: block;
      color: ${(props) => props.theme.colors.base.steel};
      margin: 0 0 20px 5px;
    }
  }

  .dropdown-range {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .label {
    color: ${(props) => props.theme.colors.base.steel};
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
  .validation-msg {
    font-size: ${(props) => props.theme.fontSize.small};
  }
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const NoRecord = styled.div`
  .label {
    margin-bottom: 10px;
  }
  &.more-panel {
    margin-top: -25px;
  }
`;

/**
 * @description ClearButton to style the ClearButton of the rangeData list
 */
export const ClearButton = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  text-align: right;
  margin-top: ${(props) => (props["data-showInDropdown"] ? "20px" : "")};
  font-size: ${(props) => props.theme.fontSize.base};
  & span {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: ${(props) => props.theme.colors.base.steel10};
    }
  }
`;

export const Footer = styled.div`
  padding: 8px 0 0;
  display: flex;
  justify-content: space-between;
`;

export class DataRangeControl extends React.Component<IDataRangeControlProps, IDataRangeControlState | any> {
  static defaultProps = {
    showSlideLabel: false,
  };
  containerRef = React.createRef<any>();
  constructor(props) {
    super(props);
    let selectedValue =
      props.selectedOptions &&
      props.selectedOptions.hasOwnProperty("min") &&
      props.selectedOptions.hasOwnProperty("max")
        ? props.selectedOptions
        : props.rangeData;
    selectedValue = this.getSelectedValues(selectedValue);
    this.state = {
      count: props.count || 0,
      max: selectedValue.max,
      min: selectedValue.min,
      isClearVisible: false,
      selectedValue,
      isFilterApplyButtonActive: false, // true: enabled, false: disabled
    };
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  isIdleTimeFilter = () => {
    const { filter } = this.props;
    return filter.filterName === IDLE_TIME_DURATION;
  };

  getSelectedValues = (selectedValuesObj) => {
    if (this.isIdleTimeFilter()) {
      if (selectedValuesObj?.min !== "" && parseInt(selectedValuesObj?.min, 10) === 0) {
        selectedValuesObj.min = "";
      }
      if (selectedValuesObj?.max !== "" && parseInt(selectedValuesObj?.max, 10) === 0) {
        selectedValuesObj.max = "";
      }
      return {
        max: selectedValuesObj?.max !== "" ? this.getMaxima(selectedValuesObj?.max) : "",
        min: selectedValuesObj?.min !== "" ? this.getMinima(selectedValuesObj?.min) : "",
      };
    }
    return {
      max: this.getMaxima(selectedValuesObj?.max),
      min: this.getMinima(selectedValuesObj?.min),
    };
  };

  getMaxima = (val) => {
    return Math.ceil(val);
  };

  getMinima = (val) => {
    return Math.floor(val);
  };

  setDefaultState = (val, count?) => {
    const selectedValue = this.getSelectedValues(val);
    this.setState({
      count: count || 0,
      max: selectedValue.max,
      min: selectedValue.min,
      selectedValue,
    });
  };

  isClickedOnComponent = (target) => {
    const eleArr = this.containerRef.current && Array.from(this.containerRef.current.children);
    const arr = eleArr.filter((child: any) => child.contains(target));
    return arr.length > 0;
  };

  /**
   * onDocumentClick to disable focus while click outside
   */
  onDocumentClick = (e) => {
    if (this.isClickedOnComponent(e.target)) {
      return this.props.inputFocus && this.props.inputFocus(); // open
    } else {
      return this.props.disableFocus && this.props.disableFocus(); // close
    }
  };

  /**
   * handle on mouse down event on search input box
   *
   * @param e current event
   */
  onMouseDown = (e: any) => {
    e.stopPropagation();
    e.target.focus();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.onDocumentClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentClick, true);
  }

  shouldSetDefaultState(props, prevProps) {
    return (
      prevProps.meta &&
      prevProps.meta.dirty &&
      props.meta &&
      !props.meta.dirty &&
      !props.meta.touched &&
      !props.selectedOptions &&
      this.state.count > 0
    );
  }

  componentDidUpdate(prevProps) {
    if (this.shouldSetDefaultState(this.props, prevProps)) {
      const selectedValue = this.isIdleTimeFilter()
        ? this.getIdleTimeValue(this.props.selectedOptions)
        : this.getValue(this.props.selectedOptions);
      this.setDefaultState(selectedValue);
    }
    if (
      this.props.rangeData &&
      (this.compareRangeData(prevProps.selectedOptions, this.props.selectedOptions) ||
        this.compareRangeData(prevProps.rangeData, this.props.rangeData))
    ) {
      const selectedValue = this.isIdleTimeFilter()
        ? this.getIdleTimeValue(this.props.selectedOptions)
        : this.getValue(this.props.selectedOptions);
      this.setDefaultState(selectedValue, this.props.count);
    }
  }

  compareRangeData = (data1, data2) => {
    return !!data1 && !!data2 && (data1.min !== data2.min || data1.max !== data2.max);
  };
  getValue = (selectedOptions) => {
    const { rangeData } = this.props;
    const newSelectedOptions = {
      max: this.getMaxima(rangeData.max),
      min: this.getMinima(rangeData.min),
    };
    if (selectedOptions) {
      if (selectedOptions.min > newSelectedOptions.min && selectedOptions.min <= newSelectedOptions.max) {
        newSelectedOptions.min = this.getMinima(selectedOptions.min);
      }
      if (selectedOptions.max > newSelectedOptions.min && selectedOptions.max <= newSelectedOptions.max) {
        newSelectedOptions.max = this.getMaxima(selectedOptions.max);
      }
    }
    return newSelectedOptions;
  };

  onSelectionChange = (selectedValue?) => {
    const { rangeData } = this.props;
    let count = 0;
    let label = null;
    if (selectedValue) {
      if (selectedValue.min !== rangeData.min) {
        count = count + 1;
        label = `${rangeData.unit} ${selectedValue.min}`.trim();
      }
      if (selectedValue.max !== rangeData.max) {
        count = count + 1;
        label = `${rangeData.unit} ${selectedValue.max}`.trim();
      }
    }
    this.setState({ count, isClearVisible: true });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.props.onSelectionChange && this.props.onSelectionChange(selectedValue, count, count === 1 ? label : null);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.props.input && this.props.input.onChange(selectedValue && `${selectedValue.min};${selectedValue.max}`);
  };

  onChange = (val) => {
    const selectedValue = this.isIdleTimeFilter() ? this.getIdleTimeValue(val) : this.getValue(val);
    this.setState({ selectedValue, min: selectedValue.min, max: selectedValue.max });
  };

  /**
   * method to clear filter selection
   */
  clearFilterSelection = () => {
    this.onChange(this.props.rangeData);
    this.onSelectionChange(null);
    this.setState({ isFilterApplyButtonActive: false, isClearVisible: false });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name: string | "min" | "max" = target.name;

    this.setState({
      [name]: parseFloat(value),
    });

    if (this.isIdleTimeFilter()) {
      this.setApplyBtnValidation(value, name);
    }
  };

  setApplyBtnValidation = (value, inputFieldName) => {
    const minVal = this.state.min;
    const maxVal = this.state.max;
    let buttonDisabled = true;
    const { defaultRange } = this.props.rangeControlProps;

    if (inputFieldName === "min" || inputFieldName === "max") {
      if (value === "0" && (minVal === "" || maxVal === "")) {
        buttonDisabled = false;
      }
    }

    if (inputFieldName === "min") {
      if (value === "" && maxVal !== "") {
        buttonDisabled = true;
      } else if (value !== "" && maxVal === "") {
        buttonDisabled =
          this.getMinima(value) !== 0 && this.getMinima(value) <= this.getMinima(defaultRange.max) ? true : false;
      } else if (value !== "" && maxVal !== "") {
        if (value > maxVal) {
          buttonDisabled = false;
        } else if (value === maxVal) {
          buttonDisabled = true;
        }
      }
    } else if (inputFieldName === "max") {
      if (value === "" && minVal !== "" && minVal !== 0) {
        buttonDisabled = true;
      } else if (value !== "" && minVal === "") {
        if (this.getMinima(value) !== 0 && this.getMinima(value) <= this.getMaxima(defaultRange.max)) {
          buttonDisabled = this.getMinima(value) === minVal ? false : true;
        } else {
          buttonDisabled = false;
        }
      } else if (value !== "" && minVal !== "" && minVal !== 0) {
        if (value < minVal || this.getMinima(value) > defaultRange.max) {
          buttonDisabled = false;
        } else if (value === minVal) {
          buttonDisabled = true;
        }
      }
    }

    this.setState({
      isFilterApplyButtonActive: buttonDisabled,
    });
  };

  getIdleTimeValue = (selectedOptions) => {
    const { rangeData } = this.props;
    const { defaultRange } = this.props.rangeControlProps;
    const newSelectedOptions = {
      max: rangeData.max,
      min: rangeData.min,
    };
    const minNumber = Number(selectedOptions != null ? selectedOptions.min : "");
    const maxNumber = Number(selectedOptions != null ? selectedOptions.max : "");
    if (selectedOptions) {
      if (selectedOptions.min !== "") {
        if (minNumber < 1) {
          newSelectedOptions.min = rangeData.min;
        } else if (!isNaN(minNumber) && minNumber.toString().indexOf(".") !== -1) {
          newSelectedOptions.min = rangeData.min;
        } else if (
          selectedOptions.min >= this.getMinima(defaultRange.min) &&
          selectedOptions.min <= selectedOptions.max
        ) {
          newSelectedOptions.min = this.getMinima(selectedOptions.min);
        } else if (selectedOptions.min < this.getMinima(defaultRange.min)) {
          newSelectedOptions.min = this.getMinima(rangeData.min);
        } else if (selectedOptions.min > this.getMinima(defaultRange.max)) {
          if (this.getMinima(selectedOptions.min) === parseInt(defaultRange.max, 10)) {
            newSelectedOptions.min = this.getMinima(selectedOptions.min);
          } else {
            newSelectedOptions.min = rangeData.min !== "" && rangeData.min !== "0" ? this.getMinima(rangeData.min) : "";
          }
        } else {
          newSelectedOptions.min = this.getMinima(selectedOptions.min);
        }
      } else {
        newSelectedOptions.min = "";
      }

      if (selectedOptions.max !== "") {
        if (maxNumber < 1) {
          newSelectedOptions.max = rangeData.max;
        } else if (!isNaN(maxNumber) && maxNumber.toString().indexOf(".") !== -1) {
          newSelectedOptions.max = rangeData.max;
        } else if (selectedOptions.max > selectedOptions.min && selectedOptions.max <= this.getMinima(rangeData.max)) {
          newSelectedOptions.max = this.getMinima(selectedOptions.max);
        } else if (selectedOptions.max > this.getMinima(defaultRange.max)) {
          if (this.getMinima(selectedOptions.max) === parseInt(defaultRange.max, 10)) {
            newSelectedOptions.max = this.getMinima(selectedOptions.max);
          } else {
            newSelectedOptions.max = rangeData.max !== "" && rangeData.max !== "0" ? this.getMinima(rangeData.max) : "";
          }
        } else {
          newSelectedOptions.max = this.getMinima(selectedOptions.max);
        }
      } else {
        newSelectedOptions.max = "";
      }
    }
    return newSelectedOptions;
  };

  updateFilterState = (prevState, target, name, getValueCallback) => {
    let min = "";
    let max = "";
    if (name === "min") {
      min = target.value;
      max = prevState.max;
    } else {
      min = prevState.min;
      max = target.value;
    }

    if (this.isIdleTimeFilter()) {
      const val = { min, max };
      const selectedValue = getValueCallback(val);
      return {
        max: selectedValue.max,
        min: selectedValue.min,
        selectedValue,
      };
    } else {
      const val = { min: this.getMinima(min), max: this.getMaxima(max) };
      if (this.getMinima(min) >= this.getMinima(max)) {
        HelperLodash.set(val, name, HelperLodash.get(this.props, ["rangeData", name]));
      }
      const selectedValue = getValueCallback(val);
      return {
        max: this.getMaxima(selectedValue.max).toString(),
        min: this.getMinima(selectedValue.min).toString(),
        selectedValue,
      };
    }
  };

  applyFilter = () => {
    const { selectedValue } = this.state;
    if (this.isIdleTimeFilter()) {
      // It is required to set 0 as value for API in case when min or max is empty.
      const filterValue = {
        min: this.state.selectedValue.min === "" ? 0 : this.state.selectedValue.min,
        max: this.state.selectedValue.max === "" ? 0 : this.state.selectedValue.max,
      };
      this.onSelectionChange(filterValue);
      this.setState({ isFilterApplyButtonActive: false });
    } else {
      if (this.props.rangeData.min === selectedValue.min && this.props.rangeData.max === selectedValue.max) {
        this.onSelectionChange(null);
      } else {
        this.onSelectionChange(this.state.selectedValue);
      }
    }
  };

  onBlur = (event) => {
    const target = event.target;
    const name = target.name;

    this.setState(
      (prevState) => {
        return this.updateFilterState(prevState, target, name, this.getValue);
      },
      () => {
        this.applyFilter();
      },
    );
  };

  isFilterApplyButtonActive = () => {
    return this.state.isFilterApplyButtonActive;
  };

  handleApplyOnClick = (_e?: any) => {
    this.applyFilter();
  };

  handleBlur = (event) => {
    const target = event.target;
    const name = target.name;

    this.setState((prevState) => {
      return this.updateFilterState(prevState, target, name, this.getIdleTimeValue);
    });
  };

  renderIdleTime = () => {
    const {
      hideApplyButton,
      rangeData,
      placeholder,
      handleRangeDisable,
      clearButtonText,
      noValuesLabel,
      showInDropdown,
    } = this.props;
    const {
      minFieldLabel,
      maxFieldLabel,
      minFieldPlaceholder,
      maxFieldPlaceholder,
      minFieldUnit,
      maxFieldUnit,
      maxFieldMessage,
      showMaximumInput,
      minFieldMessage,
    } = this.props.rangeControlProps;
    const showClearButton = this.state.count > 0;
    return (
      <DataRangeWrapper className={showInDropdown ? "dropdown-range-wrapper" : ""}>
        {rangeData ? (
          <div>
            <div className="header-wrapper">
              <div className="label">{placeholder}</div>
              {showClearButton && !showInDropdown ? (
                <ClearButton data-showInDropdown={showInDropdown}>
                  <span onClick={this.clearFilterSelection}>{clearButtonText}</span>
                </ClearButton>
              ) : (
                <span />
              )}
            </div>
            <div className="dropdown-range">
              <div>
                <label className="value-wrapper__lbl">{minFieldLabel}</label>
                <div className="value-wrapper__container">
                  <input
                    type="number"
                    name="min"
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                    value={this.state.min}
                    className="value-wrapper__field"
                    id="min"
                    onMouseDown={this.onMouseDown}
                    disabled={handleRangeDisable}
                    placeholder={minFieldPlaceholder}
                  />
                  <span className="date-unit">{minFieldUnit}</span>
                </div>
                <span className="validation-msg">{minFieldMessage}</span>
              </div>
              {showMaximumInput && (
                <>
                  <div className="value-wrapper__center">
                    <label className="value-wrapper__lbl"></label>
                    <span className="value-wrapper__separator" />
                  </div>
                  <div>
                    <label className="value-wrapper__lbl">{maxFieldLabel}</label>
                    <div className="value-wrapper__container">
                      <input
                        type="number"
                        name="max"
                        onChange={this.handleInputChange}
                        onBlur={this.handleBlur}
                        value={this.state.max}
                        className="value-wrapper__field"
                        id="max"
                        onMouseDown={this.onMouseDown}
                        placeholder={maxFieldPlaceholder}
                        disabled={handleRangeDisable}
                      />
                      <span className="date-unit">{maxFieldUnit}</span>
                    </div>
                    <span className="validation-msg">{maxFieldMessage}</span>
                  </div>
                </>
              )}
            </div>
            <Footer>
              {showClearButton && showInDropdown ? (
                <ClearButton data-showInDropdown={showInDropdown}>
                  <span onClick={this.clearFilterSelection}>{clearButtonText}</span>
                </ClearButton>
              ) : (
                <span />
              )}
              {!hideApplyButton && (
                <ApplyButton isActive={this.isFilterApplyButtonActive()} onClick={(e) => this.handleApplyOnClick(e)} />
              )}
            </Footer>
          </div>
        ) : (
          <NoRecord>
            <div className="label">{placeholder}</div>
            <p>{noValuesLabel}</p>
          </NoRecord>
        )}
      </DataRangeWrapper>
    );
  };

  renderAsDropdown = () => {
    const { rangeData, placeholder, clearButtonText, showSlideLabel, noValuesLabel, showInDropdown } = this.props;
    const showClearButton = this.state.count > 0;
    return (
      <DataRangeWrapper className="dropdown-range-wrapper">
        {rangeData ? (
          <div className={this.rangeEquality() ? "disabled" : ""}>
            <InputRange
              formatLabel={(value) => (showSlideLabel ? value.toString() : "")}
              maxValue={this.getMaxima(rangeData.max)}
              minValue={this.getMinima(rangeData.min)}
              value={this.state.selectedValue}
              onChange={this.onChange}
              onChangeComplete={this.onSelectionChange}
              disabled={this.rangeEquality()}
            />

            <div className="dropdown-range">
              <div className="value-wrapper__container">
                <input
                  type="number"
                  name="min"
                  onChange={this.handleInputChange}
                  onBlur={this.onBlur}
                  value={this.state.min}
                  className="value-wrapper__field"
                  id="min"
                  onMouseDown={this.onMouseDown}
                  disabled={this.rangeEquality()}
                />
              </div>
              <span className="value-wrapper__separator" />
              <div className="value-wrapper__container">
                <input
                  type="number"
                  name="max"
                  onChange={this.handleInputChange}
                  onBlur={this.onBlur}
                  value={this.state.max}
                  className="value-wrapper__field"
                  id="max"
                  onMouseDown={this.onMouseDown}
                  disabled={this.rangeEquality()}
                />
              </div>
            </div>
            {showClearButton && (
              <ClearButton data-showInDropdown={showInDropdown}>
                <span onClick={this.clearFilterSelection}>{clearButtonText}</span>
              </ClearButton>
            )}
          </div>
        ) : (
          <NoRecord>
            <div className="label">{placeholder}</div>
            <p>{noValuesLabel}</p>
          </NoRecord>
        )}
      </DataRangeWrapper>
    );
  };

  rangeEquality = () => Math.ceil(this.props.rangeData?.max) === Math.ceil(this.props.rangeData?.min);

  renderAsMorefilter = () => {
    const { rangeData, placeholder, clearButtonText, minLabel, maxLabel, showSlideLabel, noValuesLabel } = this.props;
    const showClearButton = this.state.isClearVisible;
    return (
      <div ref={this.containerRef}>
        <DataRangeWrapper>
          {rangeData ? (
            <>
              <LabelWrapper>
                <div className="label">{placeholder}</div>
                {showClearButton && (
                  <ClearButton>
                    <span onClick={this.clearFilterSelection}>{clearButtonText}</span>
                  </ClearButton>
                )}
              </LabelWrapper>
              <InputRange
                formatLabel={(value) => (showSlideLabel ? value.toString() : "")}
                maxValue={this.getMaxima(rangeData.max)}
                minValue={this.getMinima(rangeData.min)}
                value={this.state.selectedValue}
                onChange={this.onChange}
                onChangeComplete={this.onSelectionChange}
                disabled={this.rangeEquality()}
              />
              <div className="value-wrapper">
                <label className="value-wrapper__lbl">{minLabel}</label>
                <div className="value-wrapper__container">
                  <input
                    type="number"
                    name="min"
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    value={this.state.min}
                    className="value-wrapper__field"
                    id={`min${this.props.id}`}
                    onMouseDown={this.onMouseDown}
                    disabled={this.rangeEquality()}
                  />
                </div>
                <label className="value-wrapper__lbl">{maxLabel}</label>
                <div className="value-wrapper__container">
                  <input
                    type="number"
                    name="max"
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    value={this.state.max}
                    className="value-wrapper__field"
                    id={`max${this.props.id}`}
                    onMouseDown={this.onMouseDown}
                    disabled={this.rangeEquality()}
                  />
                </div>
              </div>
            </>
          ) : (
            <NoRecord className="more-panel">
              <div className="label">{placeholder}</div>
              <p>{noValuesLabel}</p>
            </NoRecord>
          )}
        </DataRangeWrapper>
      </div>
    );
  };

  getRenderType = (filterName) => {
    if (filterName === IDLE_TIME_DURATION) {
      return this.renderIdleTime();
    }
    return this.renderIdleTime();
  };

  render() {
    const { showInDropdown, id, filter } = this.props;
    const { renderCustomComponent } = this.props.rangeControlProps;
    return (
      <div id={id} ref={this.containerRef}>
        {renderCustomComponent ? (
          this.getRenderType(filter.filterName)
        ) : (
          <div>{showInDropdown ? this.renderAsDropdown() : this.renderAsMorefilter()}</div>
        )}
      </div>
    );
  }
}
export default DataRangeControl;
