import { SharedUtils } from "am-web-ui-shared/helpers";

// Constants
export const CLICK_LOCKED_ITEM_NAV = "click_locked_item_nav";
export const CLICK_LOCKED_ITEM_LINK_LEARN_MORE = "click_learn_more";
export const CLICK_LOCKED_ITEM_LINK_CONTANCT_SALES = "click_contact_sales";

// Enums
export enum TrackLockedItem {
  AssetCost = "asset_cost",
  QuantityItem = "quantity_item",
}

export enum TrackLocketItemLink {
  LearnMore = "learn_more",
  ContactSales = "contact_sales",
}

// Functions
export type TrackLockedItemNavPayload = {
  entity: TrackLockedItem;
};

export const trackLockedItemNav = (payload: TrackLockedItemNavPayload) => {
  SharedUtils.pushDataToGA(CLICK_LOCKED_ITEM_NAV, null, payload);
};

export type TrackLockedItemLinkPayload = TrackLockedItemNavPayload & {
  link: TrackLocketItemLink;
};

export const trackLockedItemLink = (payload: TrackLockedItemLinkPayload) => {
  const { link, entity } = payload;
  const event =
    link === TrackLocketItemLink.LearnMore ? CLICK_LOCKED_ITEM_LINK_LEARN_MORE : CLICK_LOCKED_ITEM_LINK_CONTANCT_SALES;
  SharedUtils.pushDataToGA(event, null, { entity });
};
