import { HelperLodash } from "am-web-ui-shared/helpers";
import { isQtyItemModuleEnable } from "./commonUtils";
import { ModuleName } from "./enums/moduleNameEnum";
import { QtyItemEnum, QtyItemAllocationEnum } from "./enums/qtyItemEnum";
import { getFlatArray } from "./list/listUtils";
import { ActionItem } from "./enums/actionItemEnum";
import QtyItems from "../models/qtyItems/qtyModel";
import ICheckedRowsInfo from "../models/iCheckedRowsInformation";

export const MAX_SLECTION_ALLOCATION_COUNT = 500;
/**
 * @description - This method will check if allocation status code is not Consumed, Lost or Stolen or Disposed.
 *
 */
export const isAllowedAllocationStatus = (statusCode) => {
  const allocationStatus = [
    QtyItemAllocationEnum.CONSUMED,
    QtyItemAllocationEnum.LSTORSTOL,
    QtyItemAllocationEnum.DISPOSED,
  ];
  return !allocationStatus.includes(statusCode);
};

const checkIntransitAllocationByCode = (statusCode) => {
  return statusCode === QtyItemAllocationEnum.INTRAN;
};

/**
 * @description - This method will filter all items whose allocation status code is apart from Consumed, Lost or Stolen and Disposed.
 *
 */
export const getFilteredTransferList = (list = []) => {
  return list.filter((item) => {
    const { qtyAllocationStatus } = item;
    const code = HelperLodash.get(qtyAllocationStatus, "code");
    return item.metaData.type === ModuleName.asset || isAllowedAllocationStatus(code);
  });
};

/**
 * @description - retrun the allocation count of selected qty items
 */
export const getAllocationCount = (items = [], allocationDataForTransferCount = 0) => {
  return items.reduce((acc, obj) => acc + obj.allocationsCount, allocationDataForTransferCount);
};

export const isAllocationStatusIntransit = (checkedRowsInfo) => {
  if (checkedRowsInfo?.firstCheckedRow?.allocationStatus) {
    const { code } = checkedRowsInfo.firstCheckedRow.allocationStatus;
    return checkedRowsInfo.isOneRowChecked && checkIntransitAllocationByCode(code);
  }
  return false;
};

export const isLockerAllocationSelected = (checkedRowsInfo) => {
  for (const { assetSubType } of checkedRowsInfo) {
    if (assetSubType === ModuleName.LOCKER) {
      return true;
    }
  }
  return false;
};

export const isStockChangeDisable = (items) => {
  if (items.length === 1) {
    return items[0].totalStockQuantity;
  } else if (items.length > 0) {
    return HelperLodash.sum(items, "allocationsCount") <= MAX_SLECTION_ALLOCATION_COUNT;
  }
  return 0;
};
export const isStockChangeAllocationDisable = (items) => {
  if (items.length === 0) {
    return false;
  } else if (items.length <= MAX_SLECTION_ALLOCATION_COUNT) {
    return true;
  }
  return false;
};
/**
 * @description - retrun the allocation count of selected qty items is less than or equal 50
 */
export const isTransferDisable = (checkedRowsInfo, allocationDataForTransfer) => {
  const allocationCount = getAllocationCount(
    checkedRowsExcludingIndeterminate(checkedRowsInfo.checkedRows),
    allocationListForTransfer(allocationDataForTransfer).length,
  );

  return HelperLodash.get(checkedRowsInfo, "isAnyRowChecked") && allocationCount > 0
    ? allocationCount > Number(QtyItemEnum.allocationTransferLimit)
    : true;
};

export const isNoAllocationAvailable = (checkedRowsInfo: ICheckedRowsInfo) => {
  const { checkedRows } = checkedRowsInfo;
  return checkedRows.every(({ allocationsCount }) => allocationsCount < 1);
};

export const checkedRowsExcludingIndeterminate = (checkedAllocationList) =>
  checkedAllocationList.filter((row) => !row.metaData.indeterminate).map((item) => item);

export const checkedRowsIdsExcludingIndeterminate = (checkedAllocationList) =>
  checkedAllocationList.filter((row) => !row.metaData.indeterminate).map((item) => item.id);

export const allocationListForTransfer = (allocationDataForTransfer) =>
  getFlatArray(Object.values(allocationDataForTransfer) || []);

export const qtyItemIds = (allocationDataForTransfer) => Object.keys(allocationDataForTransfer || {});

/**
 * @description - Function that validates the value entered in textbox against input label.
 *
 * @param t - Translation function.
 * @param textValue - text value against entered value.
 */
export const inputValidation =
  (t, textValue, moduleName: string = ModuleName.qtyitems) =>
  (value) => {
    if (value && value.length > 0 && Number(value) === 0 && moduleName !== ModuleName.transfer) {
      return `${t("qtyItems:TRANSFER_QTY_ZERO_VALIDATION")}`;
    }
    if (!!Number(value) === false && moduleName === ModuleName.transfer) {
      return `${t("qtyItems:TRANSFER_QTY_ZERO_VALIDATION")}`;
    }
    return value && value > textValue && `${t("qtyItems:TRANSFER_QTY_VALIDATION")}`;
  };

/**
 * @description - Function that validates allocation status dropdown against the value entered in textbox of quantity.
 *
 * @param t - Translation function.
 * @param fieldName - Name of field
 * @param formvalues - Entered data.
 * * @param name - name of dropdown.
 */
export const validateAllocationStatusDropDown = (t, fieldName = null, formvalues, name) => {
  const keyArray = name.split("_");
  keyArray.shift();
  const uniqueId = keyArray.join("_");
  const textBoxValue = formvalues[`textBox_${uniqueId}`];
  const statusDropDownValue = HelperLodash.get(formvalues, name);
  return textBoxValue !== undefined && textBoxValue !== null && Number(textBoxValue) > 0 && statusDropDownValue === null
    ? `${t("common:VALIDATION_PLEASE_SELECT_A") + t(fieldName)}`
    : undefined;
};

export const getStatusDropDownVisibility = (data) => {
  return HelperLodash.get(data, "metaData.type") === ModuleName.allocation;
};

/**
 * @description - Helper function to get the status allocation status
 *
 * @param item - Transfer cart item list object.
 * @param allocationStatusList - Allocation status list.
 */
export const getStatusCode = (item, allocationStatusList) => {
  const { allocationStatus, qtyAllocationStatus, canConsume } = item;
  const value = HelperLodash.get(qtyAllocationStatus, "value");
  if (value && allocationStatusList !== null) {
    return allocationStatusList && allocationStatusList.find((obj) => obj.value === value);
  } else if (allocationStatus.code === "NA") {
    const code = canConsume ? QtyItemAllocationEnum.CONSUMED : QtyItemAllocationEnum.AVAILABLE;
    return allocationStatusList && allocationStatusList.find((obj) => obj.code === code);
  } else if (checkIntransitAllocationByCode(allocationStatus.code)) {
    return { code: QtyItemAllocationEnum.INTRAN, value: null };
  } else {
    return canConsume
      ? allocationStatusList && allocationStatusList.find((obj) => obj.code === QtyItemAllocationEnum.CONSUMED)
      : allocationStatus;
  }
};

/**
 * @description - Function that filled initial value of Allocation item and type checkbox
 *
 * @param List - Transfer cart item list.
 */
export const handleInitialValues = (list = [], allocationStatusList = []) => {
  const qtyItemAllocationKey = {};
  list.forEach((item) => {
    if (item.metaData && item.metaData.type === ModuleName.allocation) {
      const allocationItemKey = `textBox_${item.uniqueId}`;
      HelperLodash.set(qtyItemAllocationKey, allocationItemKey, item.qtyAllocationValue);

      const allocationStatusDdKey = `statusListId_${item.uniqueId}`;
      const allocationStatusLabelDdKey = `statusList_${item.uniqueId}`;
      const statusCodeObj = getStatusCode(item, allocationStatusList);
      const code = HelperLodash.get(statusCodeObj, "code");
      const value = HelperLodash.get(statusCodeObj, "value");
      HelperLodash.set(qtyItemAllocationKey, allocationStatusDdKey, code);
      HelperLodash.set(qtyItemAllocationKey, allocationStatusLabelDdKey, value);
    }
  });
  return qtyItemAllocationKey;
};

/**
 * @description - Function that check if any textBox value registerd in transfer redux form
 *
 * @param valueObj - redux form value obj
 */
export const getAllocationValueFields = (valueObj) =>
  Object.keys(valueObj).filter((key) => key.indexOf("textBox") > -1 && HelperLodash.get(valueObj, key) !== undefined)
    .length;

export const getTranslationKeyWithQty = (basekey) => {
  return isQtyItemModuleEnable() ? `${basekey}_WITH_QTYITEMS` : basekey;
};

export const getTranslationKeyWithoutQty = (basekey) => {
  return !isQtyItemModuleEnable() ? `${basekey}_WITHOUT_QTYITEMS` : basekey;
};

export const stockChangeErrorLabels = ["manufacturerName", "model", "allocationName", "responsibleEmployeeName"];

export const getAllocationTransferFormValue = (
  viewMode,
  allocationStatusList,
  transferCartList,
  allocationList = [],
) => {
  const mergedList = viewMode === ActionItem.transfer ? [...transferCartList, ...allocationList] : allocationList;
  return handleInitialValues(mergedList, allocationStatusList);
};

/**
 * @description This method is used to return the object of input and dropdown data if input is there
 * then only return the dropdown value
 * @param formValues Data of input box and dropdown of the transfer allocation
 *
 * @returns object
 */
export const prepareAllocationFormData = (formValues: object): object => {
  // This is returning the array of allocation ids if value is in the input box
  const allocationKeys = Object.keys(formValues)
    .filter((value) => {
      return value.indexOf("textBox") > -1 && !!HelperLodash.get(formValues, value);
    })
    .map((obj) => obj.split("_")[1]);

  // This is creating object of required fields
  const allocationFormData = Object.keys(formValues)
    .map((value) => (allocationKeys.includes(value.split("_")[1]) ? value : undefined))
    .reduce((obj, key) => {
      obj[`${key}`] = HelperLodash.get(formValues, key);
      return obj;
    }, {});

  // This condition is used to remove the undefined key from the object
  Object.keys(allocationFormData).forEach((key) => {
    if (HelperLodash.get(allocationFormData, key) === undefined) {
      delete allocationFormData[`${key}`];
    }
  });

  return allocationFormData;
};

/**
 * @description Clear Some field in case of Clone.
 * @param assetInfo asset data
 */
export const clearQtyItemField = (qtyItemInfo: QtyItems) => {
  qtyItemInfo.id = null;
  qtyItemInfo.scanCode = null;
  qtyItemInfo.inventoryNumber = null;
  qtyItemInfo.qtyAttachments = [];
  qtyItemInfo.purchaseCount = 0;
  if (qtyItemInfo.ownedAssetDetails) {
    qtyItemInfo.ownedAssetDetails.purchaseDate = null;
    qtyItemInfo.ownedAssetDetails.purchasePrice = null;
    qtyItemInfo.ownedAssetDetails.purchaseCurrencyCode = null;
    qtyItemInfo.ownedAssetDetails.purchaseUnitId = null;
  }
  return qtyItemInfo;
};
