import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-795.000000, -2630.000000)" fill="#524F53">
        <g transform="translate(793.000000, 2628.000000)">
          <path
            d="M5,2 L19,2 L22,2 L22,21 L22,22 L2,22 L2,21 L2,2 L5,2 Z M5,5 L5,10 L9,10 L9,5 L5,5 Z M12,5 L12,10 L19,10 L19,5 L12,5 Z M5,19 L19,19 L19,13 L5,13 L5,19 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
