import addAssetReducer from "./addAssetReducer";
import AddEditAssetSaga from "./addAssetSaga";
import { ISagaModule } from "redux-dynamic-modules-saga";

export const addEditAssetModule: ISagaModule<any> = {
  id: "addEditAssetModule",
  reducerMap: {
    AddAsset: addAssetReducer,
  },
  sagas: [AddEditAssetSaga],
};

export default addEditAssetModule;
