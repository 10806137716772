import React from "react";
import styled from "styled-components";
import ApplicationConstants from "../../../constant/applicationConstants";
import { getElementId, getFieldValue } from "../gridUtils";
import { IGridCell } from "./iGridCell";

export function ellipsis(width) {
  return `
    display: inline-block;
    width: ${width};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  `;
}
const TextCell = styled.div`
  width: 97%;
  ${ellipsis}
  display:block;
  min-height: 20px;
  margin: auto;
`;

/**
 * Grid cell for combinational field and column having multiple values
 */
export const GridTextCell = React.memo((props: IGridCell) => {
  const { column, rowIndex, columnIndex } = props;
  return (
    <div>
      {column.bindProperties.map((bindProperty, index) => {
        return renderText(bindProperty, rowIndex, columnIndex, index, props);
      })}
    </div>
  );
});

/**
 * @description renderCombinationColumn will render the values for single colum.
 * @param bindProperty { Array Object} contains the keys of the combination.
 * @index {number} contains the index of the data.
 */
export const renderText = (bindProperty, rowIndex, columnIndex, index, props: IGridCell) => {
  const combineData = [];
  bindProperty.properties.map((property) => {
    combineData.push(getFieldValue(props.data, property.name));
  });

  let value = combineData.join(" ");
  if (bindProperty.blankLiteral && !(value && value.trim())) {
    value = bindProperty.blankLiteral;
  }
  const id = getElementId(rowIndex, columnIndex, index, bindProperty);

  return (
    value && (
      <TextCell
        key={id}
        title={value}
        id={id}
        className={index % ApplicationConstants.NUMBER.NUM2 === 0 ? "cell-top-element" : "cell-bottom-element"}
      >
        {value}
      </TextCell>
    )
  );
};

export default GridTextCell;
