import React from "react";
const SVG = () => (
  <svg width="20px" height="15px" viewBox="0 0 20 15" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3532.000000, -989.000000)" fill="#524F53">
        <g transform="translate(3530.000000, 984.000000)">
          <path d="M2,9 L12,15 L22,9 L22,20 L2,20 L2,9 Z M2,5 L22,5 L22,7.5 L12,13.5 L2,7.5 L2,5 Z" id="🎨Color" />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
