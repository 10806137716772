import React from "react";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import { debounce } from "../../helpers/helperLodash";
import { getDebounceValue } from "../../helpers/sharedUtils";
// @ts-ignore
import { ISearchBoxProps, ISearchBoxState } from "./iSearchBox";

const transformNumerator = 16;
const transformDenominator = 24;
/**
 * Wrapper box for the component.
 */
export const StyledBox = styled("div")`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  padding: 10px 8px;
  background-color: ${(props) => props.theme.colors.base.white};
  border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete30};
  min-width: 300px;
`;

/**
 * Wrapper for removeCircle SVG.
 */
const RemoveIcon = styled("span")`
  display: inline-block;
  height: 24px;
  svg {
    cursor: pointer;
    transform: scale(${transformNumerator / transformDenominator});
  }
  svg:hover path {
    fill: #000000;
  }
`;

/**
 * Input element which will hold the search text entered by the user.
 */
export const StyledInput = styled("input")`
  color: ${(props) => props.theme.colors.base.steel};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: 300;
  text-align: left;
  margin-left: 8px;
  width: 97%;
  border: none;
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.placeholderColor};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.placeholderColor};
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.placeholderColor};
  }
  &::-ms-clear {
    display: none;
  }
  &:disabled {
    background: transparent;
  }
`;

/**
 * SearchBox component holds a simple input element with search icon on its left.
 */
class SearchBox extends React.PureComponent<ISearchBoxProps, ISearchBoxState> {
  /*
   getDerivedStateFromProps methid is new lifecycle method which is replacement
   of componentWillReceiveProps method in old lifecycle
 */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.oldValue !== nextProps.value) {
      return { oldValue: nextProps.value, searchValue: nextProps.value };
    }
    return null;
  }
  /*
    Cross button is made as optional props. If enableClear is true will render crossImg
    and currently using require module to load svg. Need to discuss with priyank for
    importing svg as moudle.
  */

  inputBoxRef = React.createRef<HTMLInputElement>();

  state = {
    oldValue: this.props.value,
    searchValue: this.props.value,
  };

  debounceStore;
  constructor(props) {
    super(props);
    this.debounceStore = null;
  }

  renderCrossBtn = () => {
    const { onChange, value } = this.props;
    if (value) {
      return (
        <RemoveIcon>
          <SquareIcon
            onClick={() => {
              this.setState({ searchValue: "" });
              onChange("");
            }}
            name={IconName.RemoveCircle}
            className="grey"
          />
        </RemoveIcon>
      );
    } else {
      return null;
    }
  };

  /*
  set the state to current value and fire callback onchange.
  */
  onChangeHandler = (event) => {
    if (
      (event.target.value && event.target.value.trim().length > 0) ||
      (this.props.value && this.props.value.length > 0)
    ) {
      this.setState({ searchValue: event.target.value });
      if (this.debounceStore) {
        this.debounceStore.cancel();
      }
      this.debounceStore = debounce(this.callOnChange, getDebounceValue());
      this.debounceStore();
    }
  };

  callOnChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.state.searchValue);
    }
  };

  componentDidUpdate() {
    if (this.inputBoxRef?.current && this.props.refFocus) {
      this.inputBoxRef.current?.focus();
    }
  }

  render() {
    const {
      id,
      disabled,
      placeholder,
      maxLength,
      searchIcon,
      setSearchBoxRef,
      style,
      onKeyPress,
      onMouseDown,
      onFocus,
      moduleName,
    } = this.props;

    return (
      <StyledBox style={style} className="searchbox-wrapper" ref={setSearchBoxRef ? setSearchBoxRef : null}>
        <SquareIcon name={searchIcon || IconName.Search} />
        <StyledInput
          id={id}
          type="text"
          autoComplete="off"
          value={this.state.searchValue}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder || "Search"}
          ref={this.inputBoxRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(event)}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => onKeyPress && onKeyPress(event)}
          onMouseDown={onMouseDown}
          onFocus={onFocus}
          className={`${moduleName}_search_box input`}
        />
        {this.renderCrossBtn()}
      </StyledBox>
    );
  }
}

export default SearchBox;
