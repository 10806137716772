// @ts-ignore
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IExpandChildWrapper } from "./iListProps";

export const Row = styled.div`
  display: flex;
  min-height: 72px;
  cursor: ${(props) => (props["data-notactionable"] ? "default" : "pointer")};
  background-color: ${(props) => props.theme.colors.base.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  &[data-disabled="true"] {
    pointer-events: none;
    cursor: not-allowed;
    input[type="checkbox"],
    input[type="radio"] + span {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &:hover {
    background-color: ${(props) =>
      props["data-no-hover"]
        ? props.theme.colors.base.white
        : `rgba(${props.theme.colors.base.heavyConcreteAlpha}, 0.06)`};
  }
  &.selected {
    background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
    position: relative;
    &:hover {
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.24);
    }
    &:after {
      display: ${(props) => (props["data-arrow"] ? "none" : "block")};
      position: absolute;
      right: 0;
      top: 50%;
      content: "";
      border-top: 7px solid transparent;
      border-right: 8px solid ${(props) => props.theme.colors.base.white};
      border-bottom: 7px solid transparent;
      margin-top: -9px;
      z-index: 100;
    }
  }
  &.selected-checked-multi {
    & + .show-more {
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
      min-height: 36px;
      &:hover {
        background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
      }
    }
    .cell-checkbox:after {
      background-color: rgba(136, 127, 110, 0.12);
    }
  }
  .selected-multi {
    & + div {
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
    }
  }
  .row-cell {
    display: flex;
    flex-direction: column;
    line-height: 2;
    height: 100%;
    .cell-bottom-element {
      margin-top: auto;
    }
    padding: 16px;
  }
`;
export const TemplateData = styled(Grid)`
  && {
    width: calc(100% - 72px);
    align-items: center;
    margin: 0;
    .custom-cell {
      padding: 8px 16px;
      color: ${(props) => props.theme.colors.base.whiteFaded};
      font-size: ${(props) => props.theme.fontSize.base};
      .radio-container {
        display: flex;
      }
    }
  }
`;
export const TemplateDataFullWidth = styled(Grid)`
  && {
    width: 100%;
    align-items: center;
    margin: 0;
    .custom-cell {
      padding-top: 8px;
      padding-bottom: 8px;
      color: ${(props) => props.theme.colors.base.whiteFaded};
      font-size: ${(props) => props.theme.fontSize.base};
      .radio-container {
        display: flex;
      }
    }
  }
`;
export const CellCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  width: 72px;
  &:hover {
    background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
    &:after {
      content: "";
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
    }
  }
  &.selected-multi {
    background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
    &:hover {
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
      &:after {
        background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
      }
    }
  }
`;

export const ExpandableRow = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  &.row-expanded,
  .row-expanded {
    position: relative;
    &:before,
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      content: "";
      z-index: 1;
    }
    &:before {
      border-left: 2px solid ${(props) => props.theme.colors.base.whiteFaded};
    }
    &:after {
      border-right: 2px solid ${(props) => props.theme.colors.base.whiteFaded};
    }
    &:before {
      content: "";
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
    }
  }
  /* Target area 4 */
  &:hover,
  &.row-has-childreen:hover {
    background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.06);
    .row {
      background-color: transparent;
    }
    .selected {
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
    }
  }
  &.row-has-childreen {
    background-color: ${(props) => props.theme.colors.base.white};
    .cell-checkbox {
      align-items: flex-start;
      padding-top: 26px;
    }
    .row:first-child {
      .cell-checkbox {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 72px;
          height: 36px;
          width: 72px;
          left: 0;
        }
      }
    }
    .selected {
      .cell-checkbox {
        &::after {
          background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
        }
        &:hover:after {
          background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.28);
        }
      }
      & + .show-more {
        background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
        &:hover {
          background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.24);
        }
      }
      &.selected-checked-multi {
        background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.06);
        .cell-checkbox {
          &::after {
            background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
          }
          &:hover:after {
            background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.24);
          }
        }
        & + .show-more {
          background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
          &:hover {
            background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.24);
          }
        }
      }
    }
  }
  &.additional-link {
    .additional-text {
      .cell-checkbox {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 72px;
          height: 72px !important;
          width: 72px;
          left: 0;
        }
      }
    }
  }
  /* Target area 4 ends */
  &.row-expanded {
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    .row-expanded {
      &:before {
        left: 4px;
      }
      &:after {
        right: 4px;
      }
    }
  }
  .row:first-child {
    border-bottom: 0;
  }
  .row {
    min-height: 72px;
  }
`;

const isMarginRequired = (disableCheckBoxColumn) => (disableCheckBoxColumn ? `0px` : `71px`);
const isBorderRequired = (disableCheckBoxColumn) => (disableCheckBoxColumn ? `0px` : `1px`);

export const ExpandChildWrapper = styled.div`
  display: flex;
  padding: 6px 0 6px 10px;
  margin-left: ${(props: IExpandChildWrapper) => isMarginRequired(props.disableCheckBoxColumn)};
  border-left: ${(props: IExpandChildWrapper) => isBorderRequired(props.disableCheckBoxColumn)} solid
    ${(props) => props.theme.colors.base.heavyConcrete20};
  cursor: pointer;
  &:hover {
    background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
  }
  &.toggle {
    svg {
      transform: rotate(90deg) scale(0.8);
    }
  }
  svg {
    transform: scale(0.8);
  }
`;
export const ExpandMore = styled.span`
  display: flex;
  align-items: center;

  .toggle {
    transform: rotate(180deg);
  }
`;

export const RowLinkWrapper = styled(Link)`
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
`;

export const ExpandableRowLink = styled(Link)`
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
`;

export const ShowMoreWrapper = styled.div`
  padding: 10px;
  width: 100%;
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  margin-top: -1px;
`;

export const ShowMoreButton = styled.span`
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  color: ${(props) => props.theme.colors.base.black};
  font-size: ${(props) => props.theme.fontSize.base};
  text-decoration: underline;
  cursor: pointer;
`;
export const CountWrapper = styled.span`
  font-size: ${(props) => props.theme.fontSize.small};
`;
export const Count = styled.span`
  margin-right: 2px;
`;

export const ChildWrapper = styled.div`
  display: flex;
  padding: 8px 0 9px 16px;
  margin-left: 71px;
  border-left: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  cursor: pointer;
  min-height: 36px;
`;
