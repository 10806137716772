import { GridView } from "am-web-ui-shared/components";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { getIconByAssetSubType } from "../../../../common/utils/assetTypeUtils";
import React from "react";
import {
  createCodeValueObject,
  getFullName,
  getListObjectValue,
  getPropertyLabel,
} from "../../../../common/utils/commonUtils";
import AssetStatus from "../../../modules/asset/components/list/presentational/assetStatus";
import { EntityInfoBoxWrapper } from "../../../utils/styledUtils";
import { AddAssetInfoGridSetting, EditAssetInfoGridSetting } from "./assetInfoGridSetting";
import { IAssetInfo } from "./iAssetInfo";

/**
 * @description - Function to return asset info box.
 * @param {IAssetInfo} props - Properties of the component.
 */
export const AssetInfo = (props: IAssetInfo) => {
  let columns = AddAssetInfoGridSetting.columns;
  let showTooltip = AddAssetInfoGridSetting.showTooltip;
  let rowsData = formatAssetData(props);
  if (props.isAssetDetails) {
    columns = EditAssetInfoGridSetting.columns;
    rowsData = props.assetFieldsData;
    showTooltip = EditAssetInfoGridSetting.showTooltip;
  }

  return (
    <EntityInfoBoxWrapper className="modal-header-grid">
      <GridView
        idKey="id"
        columns={columns}
        dependencies={{
          getAlternateDefaultImage: getIconByAssetSubType,
          getAssetsStatus: (column: any, rowData: any, rowIndex: number, columnIndex: number) =>
            getAssetsStatus(column, rowData, rowIndex, columnIndex, props.t),
          getPropertyLabel: (property: any) => getPropertyLabel(property, props.t),
        }}
        rowsData={[rowsData]}
        disableCheckBoxColumn={true}
        rowAttributes={{ style: { width: "992px", margin: "0 auto", borderBottom: "0" } }}
        showTooltip={showTooltip}
        lazyLoadList={false}
      />
    </EntityInfoBoxWrapper>
  );
};

/**
 * @description - Function to return AssetsStatus component.
 * @param column - Column settings.
 * @param rowData - API response Data.
 * @param {number} rowIndex - Row index.
 * @param {number} columnIndex - Column index.
 */
export const getAssetsStatus = (column: any, rowData: any, rowIndex: number, columnIndex: number, t: any) => {
  return (
    <AssetStatus
      column={column}
      data={rowData}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      showTooltip={true}
      tooltipValue={t("common:LABEL_ASSET_STATUS")}
    />
  );
};

/**
 * @description - Function to format asset form data for grid view.
 * @param {IAssetInfo} props - Properties of the component.
 * @param {string} property - Property name.
 */
export const formatAssetData = (props: IAssetInfo) => {
  const data = {};
  Object.entries(props.assetFieldsData).forEach(([key, keyValue]: any) => {
    let value = HelperLodash.get(props.assetFieldsData, key);
    switch (key) {
      case "statusCode":
      case "assetStatusResponse":
        value =
          getListObjectValue(props.assetStatusList, keyValue) &&
          createCodeValueObject(keyValue, getListObjectValue(props.assetStatusList, keyValue));
        break;
      case "responsibleEmployeeId":
        const firstName = getListObjectValue(props.responsibleWorkersList, parseInt(keyValue, 10), "id", "firstName");
        const lastName = getListObjectValue(props.responsibleWorkersList, parseInt(keyValue, 10), "id", "lastName");
        value = getFullName(firstName, lastName);
        break;
    }
    HelperLodash.set(data, key, value);
  });
  return data;
};
