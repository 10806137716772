/**
 * @description Number Helper is utility service to perform operations related to numbers and its formats.
 * We are using numeraljs for number formatting, parsing, conversion etc.
 * References: http://numeraljs.com/
 *
 * Format: Numbers can be formatted to look like currency, percentages, times,
 * or even plain old numbers with decimal places, thousands, and abbreviations.
 *
 * Example:
 *
 * let string = numeral(1000).format('0,0');
 * // '1,000'
 */

import numeral from "numeral";

/**
 * @description Function to get current date from moment
 * @memberof NumberHelpers
 */
export const defaultBaseFormat = "##";
export const defaultDecimalSeprator = ".";

export interface INumberFormat {
  baseFormat: string;
  decimalSeprator: string;
}

export interface ICurrency {
  symbol?: string;
  baseFormat: string;
  decimalSeprator: string;
}

export enum SymbolPosition {
  prefix,
  postfix,
}

export default class NumberHelpers {
  numberSettings: INumberFormat;
  currencySettings: ICurrency;

  constructor() {
    this.numberSettings = {
      baseFormat: defaultBaseFormat,
      decimalSeprator: defaultDecimalSeprator,
    };
    this.currencySettings = {
      baseFormat: defaultBaseFormat,
      decimalSeprator: defaultDecimalSeprator,
    };
  }

  registerLocale = (name, currencySymbol, decimalDelimiter) => {
    if (!(numeral as any).locales[name]) {
      // load a locale
      numeral.register("locale", name, {
        abbreviations: {
          billion: "b",
          million: "m",
          thousand: "k",
          trillion: "t",
        },
        currency: {
          symbol: currencySymbol,
        },
        delimiters: {
          decimal: decimalDelimiter,
          thousands: "",
        },
      } as any);
    }
  };

  init = (numberSettings, currencySettings) => {
    this.numberSettings = numberSettings;
    this.currencySettings = currencySettings;

    this.registerLocale("currencylocale", this.currencySettings.symbol, this.currencySettings.decimalSeprator);
    this.registerLocale("numberlocale", this.currencySettings.symbol, this.numberSettings.decimalSeprator);
  };

  /**
   * @description Function to set default number format
   * @memberof NumberHelpers
   * @param {String} - Default format for numeraljs library
   */
  setDefaultFormat = (defaultFormat?: string) => {
    defaultFormat ? numeral.defaultFormat(defaultFormat) : numeral.defaultFormat(this.numberSettings.baseFormat);
  };

  /**
   * @description Function to get formatted number based on default number format/custom number format
   * @memberof NumberHelpers
   * @param {Number} - Number to be formatted
   * @param {String} - Number format to be applied
   * @return {Number} - Formatted Number
   */
  getFormattedNumber = (num: string, format: string) => {
    const myNumeral = numeral(Number(num));
    return myNumeral.format(format);
  };

  formatCurrency = (
    val,
    currencySymbol,
    decimalCount = 0,
    position: SymbolPosition = SymbolPosition.prefix,
    truncateDecimalZeros = true,
  ) => {
    (numeral as any).locales.currencylocale.currency.symbol = currencySymbol || this.currencySettings.symbol;
    const format = `${position === SymbolPosition.prefix ? "$" : ""} ${this.getDecimalFormat(
      this.currencySettings.baseFormat,
      decimalCount,
      truncateDecimalZeros,
    )} ${position === SymbolPosition.postfix ? "$" : ""}`.trim();

    // switch between locales
    numeral.locale("currencyLocale");
    return this.getFormattedNumber(val, format);
  };

  formatDecimalNumber = (val, decimalCount, truncateDecimalZeros = true) => {
    const format = this.getDecimalFormat(this.numberSettings.baseFormat, decimalCount, truncateDecimalZeros);

    // switch between locales
    numeral.locale("numberLocale");
    return this.getFormattedNumber(val, format);
  };

  formatNumber = (val) => {
    return this.getFormattedNumber(val, this.numberSettings.baseFormat);
  };

  getDecimalFormat = (baseFormat, decimalCount, truncateDecimalZeros = true): string => {
    if (decimalCount > 0) {
      const format = `${baseFormat}.[${"#".repeat(decimalCount)}]`;
      return truncateDecimalZeros ? format : format.replace("[", "");
    } else {
      return baseFormat;
    }
  };
}
