import React, { memo } from "react";
import ApplicationConstants from "../../constant/applicationConstants";
import { LoaderWrapper } from "../../elements";
import GridView from "../grid/gridView";
import InfiniteScrollComponent from "../infiniteScroll/infiniteScrollComponent";
import { IistPropsTemplate } from "./iListProps";
import InfiniteScrollLoadEnd from "./loader/infiniteScrollLoadEnd";
import InfiniteScrollLoader from "./loader/infinitescrollLoader";

export const loadPaginatedList = (props) => {
  if (props.paginationInfo) {
    const nextOffset =
      (props.paginationInfo.offset || 0) + (props.paginationInfo.limit || ApplicationConstants.LIMIT.LIM100);
    if (nextOffset < props.totalRecords) {
      props.paginationInfo.getList({ offset: nextOffset });
    }
  }
};

export const LazyGrid = (props: IistPropsTemplate) => {
  return (
    <>
      {props.isLoading ? (
        <LoaderWrapper activity={props.isLoading} loadingText={props.t("common:LOADING")} />
      ) : (
        <InfiniteScrollComponent
          loader={() => <InfiniteScrollLoader loader={props.t("common:LOADING")} />}
          item={props.listScrollRef}
          hasMore={props.listLength < props.totalRecords}
          loadMore={() => loadPaginatedList(props)}
          pageStart={props.pageStart ? props.pageStart : 0}
          threshold={props.threshold ? props.threshold : ApplicationConstants.LIMIT.LIM100}
          resetPage={props.resetScrollPosition}
          loadEnd={() => <InfiniteScrollLoadEnd loadEnd={props.t("common:INFINITE_LOAD_END")} />}
          isWindowScroll={props.isMoreFilterClosed}
        >
          <GridView
            handleRowClick={props.handleRowClick}
            handleCheckboxClick={props.handleCheckboxClick}
            handleRowLinkClick={props.handleRowLinkClick}
            getQuickActionPopupPermission={props.getQuickActionPopupPermission}
            quickActionPopupPermissionList={props.quickActionPopupPermissionList}
            columns={props.columns}
            rowsData={props.rowsData}
            idKey={props.idKey}
            dependencies={props.dependencies}
            disableCheckBoxColumn={props.disableCheckBoxColumn}
            showTooltip={props.showTooltip}
            rowSettings={props.rowSettings}
            displayHeader={props.displayHeader}
            hideRowArrow={props.hideRowArrow}
            lazyLoadList={props.lazyLoadList}
            listScrollRef={props.listScrollRef}
            isWindowScroll={props.isMoreFilterClosed}
          />
        </InfiniteScrollComponent>
      )}
    </>
  );
};

export default memo(LazyGrid);
