/**
 * Operators to use in Filters
 */
enum FilterOperator {
  EQ = "eq",
  IN = "in",
  BW = "between",
  GE = "ge",
  LE = "le",
}

export default FilterOperator;
