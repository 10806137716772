import gatewayReportSettingsReducer from "./gatewayReportSettingsReducer";
import gatewayReportSettingsSaga from "./gatewayReportSettingsSaga";
import { ISagaModule } from "redux-dynamic-modules-saga";
import { IGatewayReportSettingModuleState } from "./gatewayReportSettingModuleState";

const gatewayReportSettingModule: ISagaModule<IGatewayReportSettingModuleState> = {
  id: "gatewayReportSettingModule",
  reducerMap: {
    GatewayReport: gatewayReportSettingsReducer,
  },
  sagas: [gatewayReportSettingsSaga],
};

export default gatewayReportSettingModule;
