const AllocationActionConstants = {
  ADD_ALLOCATION: "ADD_ALLOCATION",
  ADD_EDIT_ALLOCATION_SUCCESS: "ADD_EDIT_ALLOCATION_SUCCESS",
  ALLOCATION_SELECT_GRID_CHECKBOX: "ALLOCATION_SELECT_GRID_CHECKBOX",
  ALLOCATION_SELECT_ROW: "ALLOCATION_SELECT_ROW",
  ALLOCATION_STOCK_CHANGE: "ALLOCATION_STOCK_CHANGE",
  ALLOCATION_UNSELECT_GRID_CHECKBOX: "ALLOCATION_UNSELECT_GRID_CHECKBOX",
  ALLOCATION_UNSELECT_ROW: "ALLOCATION_UNSELECT_ROW",
  AM_GET_ALLOCATIONS: "AM_GET_ALLOCATIONS",
  AM_GET_ALL_ALLOCATIONS: "AM_GET_ALL_ALLOCATIONS",
  AM_GET_ALLOCATIONS_BY_QTYITEM_IDS: "AM_GET_ALLOCATIONS_BY_QTYITEM_IDS",
  AM_GET_ALL_PURCHASE_HISTORY: "AM_GET_ALL_PURCHASE_HISTORY",
  CANCEL_ALLOCATION_LAST_TRANSFER: "CANCEL_ALLOCATION_LAST_TRANSFER",
  CLEAR_ALL_ALLOCATIONS: "CLEAR_ALL_ALLOCATIONS",
  CLEAR_PARENT_QTY_ITEM: "CLEAR_PARENT_QTY_ITEM",
  CLOSE_ALLOCATION_MORE_FILTER: "CLOSE_ALLOCATION_MORE_FILTER",
  DELETE_ALLOCATION: "DELETE_ALLOCATION",
  EDIT_ALLOCATION: "EDIT_ALLOCATION",
  GET_ALLOCATIONS_BY_QTYITEM_IDS_SUCCESS: "GET_ALLOCATIONS_BY_QTYITEM_IDS_SUCCESS",
  GET_ALLOCATIONS_SUCCESS: "GET_ALLOCATIONS_SUCCESS",
  GET_ALLOCATION_ITEM_DETAILS: "GET_ALLOCATION_ITEM_DETAILS",
  GET_ALLOCATION_TRANSFER_DETAILS: "GET_ALLOCATION_TRANSFER_DETAILS",
  GET_STOCK_CHANGE_HISTORY: "GET_STOCK_CHANGE_HISTORY",
  GET_STOCK_CHANGE_HISTORY_LOADING: "GET_STOCK_CHANGE_HISTORY_LOADING",
  GET_STOCK_CHANGE_HISTORY_SUCCESS: "GET_STOCK_CHANGE_HISTORY_SUCCESS",
  OPEN_ALLOCATION_MORE_FILTER: "OPEN_ALLOCATION_MORE_FILTER",
  REFRESH_QTYITEMS: "REFRESH_QTYITEMS",
  REMOVE_ALL_ALLOCATIONS: "REMOVE_ALL_ALLOCATIONS",
  RESET_ADD_EDIT_ALLOCATION_DATA: "RESET_ADD_EDIT_ALLOCATION_DATA",
  RESET_ALLOCATION_REDUX_STATE: "RESET_ALLOCATION_REDUX_STATE",
  RESET_MULTIPLE_ALLOCATIONS: "RESET_MULTIPLE_ALLOCATIONS",
  SELECT_ALL_ALLOCATIONS: "SELECT_ALL_ALLOCATIONS",
  SET_ALLOCATIONS_FILTER_APPLIED_FIELD: "SET_ALLOCATIONS_FILTER_APPLIED_FIELD",
  SET_ALLOCATIONS_FILTER_QUERY: "SET_ALLOCATIONS_FILTER_QUERY",
  SET_ALLOCATION_ITEM_DETAIL: "SET_ALLOCATION_ITEM_DETAIL",
  SET_ALLOCATION_SORT_QUERY: "SET_ALLOCATION_SORT_QUERY",
  SET_ALL_ALLOCATIONS_SUCCESS: "SET_ALL_ALLOCATIONS_SUCCESS",
  SET_ALLOCATION_TRANSFER_DETAILS: "SET_ALLOCATION_TRANSFER_DETAILS",
  SET_MULTIPLE_ALLOCATIONS: "SET_MULTIPLE_ALLOCATIONS",
  SET_STOCK_CHANGE_SUCCESS: "SET_STOCK_CHANGE_SUCCESS",
  SET_ALLOCATIONS_PURCHASE_HISTORY: "SET_ALLOCATIONS_PURCHASE_HISTORY",
  CLEAR_CANCELLED_RECORDS: "CLEAR_CANCELLED_RECORDS",
  OPEN_OR_CLOSE_STOCK_HISTORY_DIALOG: "OPEN_OR_CLOSE_STOCK_HISTORY_DIALOG",
  SET_PARENT_QTY_ITEM: "SET_PARENT_QTY_ITEM",
  OPEN_OR_CLOSE_ATTACHMENTS_DIALOG: "OPEN_OR_CLOSE_ATTACHMENTS_DIALOG",
};

export default AllocationActionConstants;
