import { IconName } from "am-web-ui-shared/components";
import React from "react";
import { Trans } from "react-i18next";
import { SimpleExpansionPanel } from "../../../../../components/DetailsPanel/index";
import notes from "./notes";
import { INotesSectionProps } from "../iAssetDetails";
/**
 * @description - This is the notes section on the asset details panel.
 */

const NotesSection: React.FC<INotesSectionProps> = (props: INotesSectionProps) => {
  const { assetDetailsData } = props;
  return assetDetailsData.notes ? (
    <SimpleExpansionPanel
      panelId="pnlNotes"
      headingId="headNotes"
      iconUrl={IconName.DeliveryNotes}
      boldId="lblNotes"
      boldText={<Trans>{"common:LABEL_NOTES"}</Trans>}
      hasValue={!!assetDetailsData.notes}
    >
      {notes(assetDetailsData)}
    </SimpleExpansionPanel>
  ) : null;
};

/**
 * @exports notesSection - Notes section that renders both header and detail components.
 */
export default NotesSection;
