/**
 * @description Action constants to be used for company module and its feature
 *
 * format: {feature}_{action}
 */
const CompanyActionConstants = {
  CLEAR_TECHNICAL_USER: "CLEAR_TECHNICAL_USER",
  GET_LICENSE_AND_USAGE_DETAILS_SUCCESS: "GET_LICENSE_AND_USAGE_DETAILS_SUCCESS",
  GET_LICENSE_AND_USAGE_DETAILS_SUCCESS_COUNT: "GET_LICENSE_AND_USAGE_DETAILS_SUCCESS_COUNT",
  GET_TECHNICAL_USER: "GET_TECHNICAL_USER",
  GET_TECHNICAL_USERS: "GET_TECHNICAL_USERS",
  GET_TECHNICAL_USER_PASSWORD: "GET_TECHNICAL_USER_PASSWORD",
  GET_TECHNICAL_USER_PASSWORD_SUCCESS: "GET_TECHNICAL_USER_PASSWORD_SUCCESS",
  RESET_TECHNICAL_USER_PASSWORD: "RESET_TECHNICAL_USER_PASSWORD",
  RESET_TECHNICAL_USER_PASSWORD_SUCCESS: "RESET_TECHNICAL_USER_PASSWORD_SUCCESS",
  GET_TECHNICAL_USER_SUCCESS: "GET_TECHNICAL_USER_SUCCESS",
  GET_TECHNICAL_USERS_SUCCESS: "GET_TECHNICAL_USERS_SUCCESS",
  OM_EDIT_COMPANY_DETAILS: "OM_EDIT_COMPANY_DETAILS",
  OM_EDIT_COMPANY_DETAILS_SUCCESS: "OM_EDIT_COMPANY_DETAILS_SUCCESS",
  OM_GET_COMPANY_DETAILS: "OM_GET_COMPANY_DETAILS",
  OM_GET_COMPANY_DETAILS_SUCCESS: "OM_GET_COMPANY_DETAILS_SUCCESS",
  OM_GET_LICENSE_AND_USAGE_DETAILS: "OM_GET_LICENSE_AND_USAGE_DETAILS",
  AM_GET_LICENSE_AND_USAGE_DETAILS: "AM_GET_LICENSE_AND_USAGE_DETAILS",
  OM_GET_TRANSFER_CONFIRMATION_SETTINGS: "OM_GET_TRANSFER_CONFIRMATION_SETTINGS",
  OM_GET_TRANSFER_CONFIRMATION_SETTINGS_SUCCESS: "OM_GET_TRANSFER_CONFIRMATION_SETTINGS_SUCCESS",
  OM_PUT_COMPANY_DATA: "OM_PUT_COMPANY_DATA",
  OM_PUT_COMPANY_DATA_SUCCESS: "OM_PUT_COMPANY_DATA_SUCCESS",
  OM_UPDATE_TRANSFER_CONFIRMATION_SETTINGS: "OM_UPDATE_TRANSFER_CONFIRMATION_SETTINGS",
  OM_UPDATE_IS_EDITED: "OM_UPDATE_IS_EDITED",
  UPDATE_PRIVATE_MODE_SETTINGS: "UPDATE_PRIVATE_MODE_SETTINGS",
  UPDATE_PRIVATE_MODE_SETTINGS_SUCCESS: "UPDATE_PRIVATE_MODE_SETTINGS_SUCCESS",
  OM_GET_ADDITIONAL_SETTINGS: "OM_GET_ADDITIONAL_SETTINGS",
  OM_SET_ADDITIONAL_SETTINGS: "OM_SET_ADDITIONAL_SETTINGS",
  CLEAR_MULTIPLE_TECHNICAL_USER: "CLEAR_MULTIPLE_TECHNICAL_USER",
};

export default CompanyActionConstants;
