import React, { createContext, useContext, useReducer, ReactElement, PropsWithChildren } from "react";
import { IOneMapRequest } from "../model/cluster";
import { HelperLodash } from "am-web-ui-shared";
import { useAppDispatch } from "TARGET_BUILD/common/store/store";
import {
  getAssetForOneMap,
  getOneMapAssetsForSingleCluster,
  clearOneMapAssetsForSingleCluster,
  selectClusterAsset,
} from "../../assetTracking/actions/activeTrackingActionRoot";

import {
  State,
  initialState,
  oneMapReducer,
  UNSELECT_ASSET,
  SET_SELECTED_ASSET,
  SET_BOUNDING_BOX,
  SET_INVENTORY_ASSET_IDS,
  POPULATE_STATE,
  SET_CLUSTER_ASSET_IDS,
  IClusterAssetModel,
} from "./oneMapReducer";

const LIMIT = 5;

export type OneMapContext = {
  state: State;
  populateState?: (state) => void;
  setSelectedAssetId?: (assetId: number, asset?) => void;
  unSelectAsset?: () => void;
  setBoundingBox?: (request: IOneMapRequest) => void;
  setInventoryAssetIds?: (assetIds: number[]) => void;
  setClusterAssetIds?: (assetIds: number[]) => void;
  fetchAssetsForCluster?: (offset) => void;
  clearClusterAssets?: () => void;
};

export const OneMapStore = createContext<OneMapContext>({ state: { ...initialState } });

export const useOneMapStore = (): OneMapContext => useContext(OneMapStore);

const createIntialState = (initialState) => {
  return {
    ...initialState,
  };
};

export const OneMapProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  const [state, dispatch] = React.useReducer(oneMapReducer, initialState, createIntialState);
  const [shouldFetch, setShouldFetch] = React.useState(true);

  const appDispatch = useAppDispatch();

  const fetchClusteredAssets = React.useCallback(
    (params: any) => appDispatch(getAssetForOneMap(params)),
    [appDispatch],
  );

  const setSelectedAssetId = (assetId: number, asset) => {
    dispatch({ type: SET_SELECTED_ASSET, payload: { assetId } });
    appDispatch(selectClusterAsset(asset));
  };

  const unSelectAsset = React.useCallback(() => {
    setShouldFetch(true);
    dispatch({ type: UNSELECT_ASSET });
    const params = HelperLodash.cloneDeep(state?.currentState?.oneMapApiParams);
    fetchClusteredAssets({ ...params, assetIds: null });
  }, [setShouldFetch, dispatch, state?.currentState?.oneMapApiParams, fetchClusteredAssets]);

  const populateState = (data) => {
    setShouldFetch(true);
    dispatch({ type: POPULATE_STATE, payload: data });
    fetchClusteredAssets(HelperLodash.cloneDeep(data?.oneMapApiParams) || {});
  };

  const setBoundingBox = (request: IOneMapRequest) => {
    if (!shouldFetch) {
      return;
    }
    dispatch({ type: SET_BOUNDING_BOX, payload: request });
    const assetIds = state.currentState?.oneMapApiParams?.assetIds || null;
    fetchClusteredAssets({ ...request, assetIds: assetIds || [] });
  };

  const setInventoryAssetIds = (assetIds: number[]) => {
    dispatch({ type: SET_INVENTORY_ASSET_IDS, payload: assetIds });
    const params = HelperLodash.cloneDeep(state?.currentState?.oneMapApiParams);
    params.assetIds = assetIds;
    fetchClusteredAssets(params);
  };

  const setClusterAssetIds = (assetIds: number[] = []) => {
    setShouldFetch(assetIds?.length ? false : true);
    dispatch({ type: SET_CLUSTER_ASSET_IDS, payload: assetIds });
    const ids = assetIds.slice(0);
    fetchAssets({
      ids: ids.slice(0, LIMIT),
      offset: 0,
      limit: LIMIT,
      totalRecords: assetIds.length,
      response: null,
    });
  };

  const fetchAssets = React.useCallback(
    (params: IClusterAssetModel) => {
      if (params.ids.length === 0) {
        return;
      }
      appDispatch(getOneMapAssetsForSingleCluster(params));
    },
    [appDispatch],
  );

  const fetchAssetsForCluster = (info: { offset: number } = { offset: 0 }) => {
    const clusterAssetIds = state?.clusterAssetsIds || [];
    const ids = clusterAssetIds.slice(0);
    const eligibleIds = ids.slice(info.offset, info.offset + LIMIT);
    fetchAssets({
      ids: eligibleIds,
      offset: info.offset,
      limit: LIMIT,
      totalRecords: clusterAssetIds.length,
      response: null,
    });
  };

  const clearClusterAssets = () => {
    appDispatch(clearOneMapAssetsForSingleCluster());
  };

  return (
    <OneMapStore.Provider
      value={{
        state,
        setSelectedAssetId,
        setBoundingBox,
        setInventoryAssetIds,
        unSelectAsset,
        populateState,
        setClusterAssetIds,
        fetchAssetsForCluster,
        clearClusterAssets,
      }}
    >
      {children}
    </OneMapStore.Provider>
  );
};
