import { IGridSetting } from "am-web-ui-shared/components";
import { IErrorObject } from "../../../../../models/activeTracking/iGatewayAssets";
import { WithTranslation } from "react-i18next";
import { IAsset, IAssetsList } from "../../../../../models/assets.list";
import { ISmartInventoryFetchAssets, SmartInventoryStats } from "../../../../../models/activeTracking/SmartInventory";

export type GatewayAssetList = IAssetsList & { error: IErrorObject };

export interface ISmartInventoryAssetsProps extends WithTranslation, IGatewaySummaryProps {
  readonly toggleAssetListDialog: (showDialog: boolean) => void;
  readonly showAssetListDialog: boolean;
  readonly assetType: SmartInventoryStats;
  readonly id: number;
  readonly getFilterAttributes: (id: number, type: string, urlDetail: string, query?: any) => void;
  readonly setFilterQuery: (filter: any) => void;
  readonly getList: (options: ISmartInventoryFetchAssets) => void;
  readonly filterAttributes: any;
  readonly gatewayAssetsList: GatewayAssetList;
  readonly listLoader?: boolean;
  readonly onCheckedRow: (id: number, source?: string) => void;
  readonly onUncheckedRow: (id: number, source?: string) => void;
  readonly onClearRowSelection?: (id, source?) => void;
  readonly onSelectRow?: (id, source?) => void;
  readonly parentCount?: number;
  readonly resetQueryObjToDefault?: () => void;
  readonly isAllSelected?: boolean;
  readonly selectAllAssets: IAsset[];
  readonly header: string;
  readonly columnConfig: IGridSetting;
  readonly rowData: any;
  readonly appliedFilterQuery?: any;
  readonly filterFormValues?: any;
  readonly changeFilterFormValues?: (field: string, value: string) => void;
  readonly setFilterAppliedAttributes?: (filter: any) => void;
  readonly updateAssetType?: () => void;
}

interface IGatewaySummaryProps {
  readonly sortQuery?: any;
  readonly setSortQuery?: (query: any) => void;
  readonly onClearAllSelection?: (source?) => void;
  readonly onSelectAll?: (source?) => void;
  readonly transferCartList?: any[];
  readonly setTransferCartItemToStore: (items) => void;
  readonly showToast?: (type: any, message: any) => void;
}

export const AssetsType = {
  EXPECTED_ASSET_COUNT: "locations:EXPECTED",
  FOUND_ASSET_COUNT: "locations:FOUND",
  NOT_FOUND_ASSET_COUNT: "locations:NOT_FOUND",
  UNEXPECTED_ASSET_COUNT: "locations:UNEXPECTED",
};

export const StatsType = {
  EXPECTED_ASSET_COUNT: "EXPECTED",
  FOUND_ASSET_COUNT: "FOUND",
  NOT_FOUND_ASSET_COUNT: "NOT_FOUND",
  UNEXPECTED_ASSET_COUNT: "UNEXPECTED",
};
