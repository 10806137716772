/**
 * @description Action constants to be used across the project
 *
 * format: {feature}_{action}
 */

const UnitActionConstants = {
  ADD_EDIT_ERROR: "ADD_EDIT_ERROR",
  ADD_EDIT_SUCCESS: "ADD_EDIT_SUCCESS",
  AM_CREATE_UNIT: "AM_CREATE_UNIT",
  AM_DELETE_UNIT: "AM_DELETE_UNIT",
  AM_GET_UNIT_LIST: "AM_GET_UNIT_LIST",
  AM_UPDATE_UNIT: "AM_UPDATE_UNIT",
  GET_UNIT_LIST_SUCCESS: "GET_UNIT_LIST_SUCCESS",
  RESET_ADD_EDIT_ERROR: "RESET_ADD_EDIT_ERROR",
  UNIT_CLEAR_SELECTION: "UNIT_CLEAR_SELECTION",
  UNIT_SET_SELECTED_ROW: "UNIT_SET_SELECTED_ROW",
};

export default UnitActionConstants;
