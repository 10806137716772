import React from "react";
import styled from "styled-components";
import ILoaderProps from "./iLoader";
import LoaderElement from "./loaderElement";

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
`;

const LoaderText = styled.div`
  font-weight: 700;
  color: ${(props) => props.theme.colors.base.whiteFaded};
`;
const LoaderWrapper = (props: ILoaderProps) => {
  const { activity, loadingText } = props;
  return activity === true ? (
    <LoaderOverlay id="commonLoading">
      <LoaderContainer>
        <LoaderElement />
        <LoaderText>{loadingText}</LoaderText>
      </LoaderContainer>
    </LoaderOverlay>
  ) : null;
};
export default LoaderWrapper;
