import React from "react";
import IBoldNormalProps from "./iBoldNormal";

import { StyledBeforeIconContainer, StyledBoldText, StyledNormalText, StyledRowsWrapper } from "../../index";

/**
 * @description - Styled Component that consist of 2 things:
 * 1. Bold Text: This is the text with bold font-familiy and it is the bottom row.
 * 2. Normal Text: This is the text with normal font-familiy and it is the top row.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 * @const BoldNormal
 * @interface IBoldNormalProps
 */
const BoldNormal = (props: IBoldNormalProps) => {
  const { normalFirst, panelId, normalId, normalText, boldId, boldText } = props;

  const boldTextElement = <StyledBoldText id={boldId}>{boldText}</StyledBoldText>;
  const normalTextElement = <StyledNormalText id={normalId}>{normalText}</StyledNormalText>;
  return (
    <StyledBeforeIconContainer id={panelId}>
      {normalFirst ? (
        <StyledRowsWrapper>
          {normalTextElement}
          {boldTextElement}
        </StyledRowsWrapper>
      ) : (
        <StyledRowsWrapper>
          {boldTextElement}
          {normalTextElement}
        </StyledRowsWrapper>
      )}
    </StyledBeforeIconContainer>
  );
};

/**
 * @export  BoldNormal - Styled Component.
 */
export default BoldNormal;
