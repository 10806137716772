import { FilterPresentational } from "am-web-ui-shared/components";
import { LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import ContainerDimensions from "react-container-dimensions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { closeMoreFilter, openMoreFilter } from "../../actions/moreFilterAction";
import { getDatePickerLocale } from "../../utils/dateUtils";
import { IFilterContainerProps } from "./iFilterProps";

// 332 contains 300px for search box + 8 px margin right + 32px for the padding of the container
const calculateWidth = (props) => `calc(100% - ${props.contentWidth}px)`;
const FilterWrapper = styled.div`
  width: ${calculateWidth};
`;

/**
 * FilterContainer component is holding the search bar component and its related functionality.
 */
export class FilterContainer extends React.Component<IFilterContainerProps> {
  static defaultProps = {
    contentWidth: 332,
  };
  render() {
    return (
      <FilterWrapper className="enable-scroll" contentWidth={this.props.contentWidth}>
        <ContainerDimensions>
          {({ width }) => (
            <FilterPresentational
              displayLoader={this.props.displayLoader}
              clearAppliedFilter={this.props.clearAppliedFilter}
              contentWidth={this.props.contentWidth}
              dependencies={this.props.dependencies}
              filterAttributes={this.props.filterAttributes}
              filterAttributesCount={this.props.filterAttributesCount}
              filterAttributesSearchCount={this.props.filterAttributesSearchCount}
              filterSettings={this.props.filterSettings}
              filterQuery={this.props.filterQuery}
              appliedFilterQuery={this.props.appliedFilterQuery}
              filterFormName={this.props.filterFormName}
              reset={this.props.reset}
              refreshFilters={this.props.refreshFilters}
              showNoRecordFoundView={this.props.showNoRecordFoundView}
              selectedFilters={this.props.selectedFilters}
              location={this.props.location}
              history={this.props.history}
              width={width}
              closeMoreFilter={this.props.closeMoreFilter}
              openMoreFilter={this.props.openMoreFilter}
              parentMoreFilterHandler={this.props.parentMoreFilterHandler}
              searchContainerRef={this.props.searchContainerRef}
              isSearchComponentLoaded={this.props.isSearchComponentLoaded}
              updateFilterQuery={this.props.updateFilterQuery}
              isMoreFilterClosed={this.props.isMoreFilterClosed}
              t={this.props.t}
              tReady={this.props.tReady}
              i18n={this.props.i18n}
              getFilterAttributes={this.props.getFilterAttributes}
              changeFilterFormValues={this.props.changeFilterFormValues}
              filterFormValues={this.props.filterFormValues}
              datePickerLocale={getDatePickerLocale()}
              dateFormat={LocaleManager.dateHelpers.getLocalizedDatePickerFormat()}
            />
          )}
        </ContainerDimensions>
      </FilterWrapper>
    );
  }
}

export const mapStateToProps = (state) => ({
  displayLoader: state.Loader?.filter,
  isMoreFilterClosed: state.filterPanel?.isFilterPanelOpen,
});
export const mapDispatchToProps = (dispatch) => ({
  closeMoreFilter: () => dispatch(closeMoreFilter()),
  openMoreFilter: () => dispatch(openMoreFilter()),
  reset: (name: any) => dispatch(reset(name)),
});
const FilterConnect = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FilterContainer));
export default FilterConnect;
