import { LocaleManager } from "am-web-ui-shared/helpers";
import React from "react";
import { Trans } from "react-i18next";
import { FriendlyName, IconNormalBold, RedTextIcon } from "../../../../../common/components/DetailsPanel";
import { UpfrontExpansionPanel } from "../../../../components/DetailsPanel/index";
import { formatNotificationPeriod } from "../../../../utils/commonUtils";
import { ServiceStatus } from "../../serviceFormController/serviceFormEnum";
import { getClassName, ServiceStatusStyle } from "../serviceStatus";
import { IServiceDetail } from "./iServiceDetail";
import { getClassNameForResult } from "../serviceResult";
import { isServiceResultSettingEnabled } from "../../../../../common/utils/configUtil";
import { AssetServiceCategory } from "../../../../utils/enums/serviceEnum";

const ServiceNamePanel: React.FC<IServiceDetail> = (props: IServiceDetail) => {
  const { selectedRow, serviceStatus, distanceUnitValue } = props;

  /**
   * @description - Function to format scheduleAt.
   * @param value - data.
   */
  const formatScheduleAt = (data, _isPanelExpanded, t) => {
    if (!data.isLifeLong && data.basedOn === AssetServiceCategory.DISTANCE) {
      return t("services:SCHDEULE_AT_DISTANCE", {
        scheduleAt: data.scheduleAt,
        distanceUnitValue: distanceUnitValue?.value,
      });
    } else if (!data.isLifeLong && data.basedOn === AssetServiceCategory.OPERATING_TIME) {
      return t("services:SCHDEULE_AT_HOURS", {
        scheduleAt: data.scheduleAt,
      });
    } else if (data.isLifeLong) {
      return t("services:LIFELONG_SERVICE");
    }
  };
  /**
   * @description - Function to get scheduledDate.
   *
   * @param {object} data - Service detail object.
   */
  const scheduledDate = (data, _isPanelExpanded, t) => {
    const scheduleAtText = formatScheduleAt(data, _isPanelExpanded, t);
    return (
      <RedTextIcon
        RedNormalId="pnlLocationDetails"
        RedNormalText={LocaleManager.dateHelpers.getDateInTenantTimezone(data.scheduleDate) || scheduleAtText}
      />
    );
  };

  const scheduledDateORProvider = serviceStatus === ServiceStatus.HISTORY ? provider : scheduledDate;

  return (
    <UpfrontExpansionPanel
      panelId="serviceDetailsSection"
      detailsData={selectedRow}
      summaryComponents={[scheduledDateORProvider, getName, getStatus]}
      detailComponents={[description, cost, notificationPeriod, source]}
      associatedRowId={selectedRow.id}
    />
  );
};

/**
 * @description - Function to get scheduledDate.
 *
 * @param {object} data - Service detail object.
 */
export const provider = (data) => {
  return data && data.provider ? <RedTextIcon RedNormalId="pnlLocationDetails" RedNormalText={data.provider} /> : null;
};

/**
 * @description - Function to get Name of service.
 *
 * @param {object} data - Service detail object.
 */
export const getName = (data) => {
  return <FriendlyName boldId="pnlName" boldText={data.name} />;
};
/**
 * @description - Function to get status of service.
 *
 * @param {object} data - Service detail object.
 */
export const getStatus = (data) => {
  return (
    <div>
      <ServiceStatusStyle
        className={
          data.serviceResult && isServiceResultSettingEnabled()
            ? getClassNameForResult(data.serviceResult?.code)
            : data.serviceState && getClassName(data.serviceState?.code)
        }
      >
        {data.serviceResult && isServiceResultSettingEnabled() ? data.serviceResult?.value : data.serviceState?.value}
      </ServiceStatusStyle>
    </div>
  );
};

/**
 * @description - Function to get description of service.
 *
 * @param {object} data - Service detail object.
 */
export const description = (data) => {
  return (
    data.description && (
      <IconNormalBold
        id="pnlDescription"
        normalId="lblDescription"
        normalText={<Trans>{"common:LABEL_DESCRIPTION"}</Trans>}
        boldId="txtDescription"
        boldText={data.description}
      />
    )
  );
};

/**
 * @description - Function to get notification period of service.
 *
 * @param {object} data - Service detail object.
 */
export const notificationPeriod = (data, t) => {
  return (
    data.notificationNumber && (
      <IconNormalBold
        id="pnlNotificationPeriod"
        normalId="lblNotificationPeriod"
        normalText={<Trans>{"services:NOTIFICATION_DETAILS"}</Trans>}
        boldId="txtNotificationPeriod"
        boldText={formatNotificationPeriod(
          `${data.notificationNumber} ${data.notificationPeriod && data.notificationPeriod.value}`,
          t,
        )}
      />
    )
  );
};

/**
 * @description - Function to get cost of service.
 *
 * @param {object} data - Service detail object.
 */
export const cost = (data, t) => {
  return (
    data.cost && (
      <IconNormalBold
        id="pnlCost"
        normalId="lblCost"
        normalText={t("services:COST")}
        boldId="txtCost"
        boldText={`${data.cost} ${data.cost && data.costCurrency}`}
      />
    )
  );
};

/**
 * @description - Function to get source of service.
 *
 * @param {object} data - Service detail object.
 */
export const source = (data) => {
  return (
    data.source && (
      <IconNormalBold
        id="pnlSource"
        normalId="lblSource"
        normalText={<Trans>{"services:SOURCE"}</Trans>}
        boldId="txtSource"
        boldText={data.source}
      />
    )
  );
};
export default ServiceNamePanel;
