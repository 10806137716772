export enum allocationTargetEnum {
  LOCATION = "LOCATION",
  STORAGE_ASSET = "STORAGEASSET",
  WORKER = "WORKER",
  EMPLOYEE = "Employee",
}

export enum allocationStatusEnum {
  Main = "MAIN",
  Available = "AVAILABLE",
  NA = "NA",
  Disposed = "DISPOSED",
  Intransit = "INTRAN",
}

export const AllocationLimit = {
  MAX_CHILDREN_STORAGE_ASSET: 499,
  MAX_ALLOCATION_LIMIT: 500,
};
