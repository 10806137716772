import { HelperLodash } from "am-web-ui-shared/helpers";
import { ErrorType } from "./errorType";
import { IErrorHandler } from "./iErrorHandler";
export default class ErrorHandler {
  errorCollection: IErrorHandler[] = [];
  constructor(errors: IErrorHandler[] = null) {
    if (errors) {
      this.errorCollection = errors;
    }
  }
  /**
   * @description use to add error into collection
   * @param error error object
   */
  add(error: IErrorHandler) {
    this.errorCollection.push(error);
  }
  /**
   * @description use to clean the error message
   */
  clear() {
    this.errorCollection = [];
  }
  /**
   * @description get error on bases of ErrorType
   * @param types type of error
   */
  getErrorsByErrorType(types: ErrorType[]) {
    return HelperLodash.filter(this.errorCollection, (v) => HelperLodash.includes(types, v.errorType));
  }
  /**
   * @description get error on bases of ErrorType
   * @param types type of error
   */
  getErrors() {
    return this.errorCollection;
  }
  /**
   * @description normlize the error array into field object
   * @param types type of error
   */
  getNormalizedSubmitError(types: ErrorType[], registerField, t, propsData) {
    const error: any = {};
    this.getErrorsByErrorType(types).forEach((item: any) => {
      if (registerField[item.fieldName]) {
        HelperLodash.set(error, item.fieldName, t(`errorCodes:${item.code}`, { ...propsData }));
      } else {
        this.updateErrorType(item, ErrorType.UnhandledException);
      }
    });
    return error;
  }
  /**
   * @description use to update error type
   * @param item is error item
   * @param errorType type of error we need to update
   */
  updateErrorType(item: IErrorHandler, errorType: ErrorType): any {
    this.errorCollection = this.errorCollection.map((errorItem) => {
      if (errorItem.code === item.code) {
        errorItem.errorType = errorType;
      }
      return errorItem;
    });
  }
  /**
   * @description combine all error return coma seprated message
   * @param types type of error we need to update
   */
  getErrorMessage(types: ErrorType[], t, propsData = {}) {
    const filteredError = this.getErrorsByErrorType(types);
    return filteredError && filteredError.length > 0
      ? filteredError.map((item: any) => t(`errorCodes:${item.code}`, { ...propsData })).join(",")
      : "";
  }
}
