import { connect } from "react-redux";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { getDocumentById } from "../../../actions/documentAction";
import SquareImage from "./squareImage";

export const mapStateToProps = (state) => ({
  getDocument: (documentId) => HelperLodash.get(state.Document, ["document", documentId]),
});

export const mapDispatchToProps = (dispatch) => ({
  getDocumentById: (documentId, type, width?, height?) =>
    dispatch(getDocumentById(documentId, false, type, width, height)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SquareImage);
