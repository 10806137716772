import React from "react";
import { reduxForm } from "redux-form";
import ApplicationConstants from "../../constant/applicationConstants";
import IFormProps from "./iFormProps";

export const onSubmitFail = () => {
  return setTimeout(() => {
    const elements = document && document.getElementsByClassName("errorLabel");
    if (elements && elements.length) {
      elements[0].scrollIntoView();
    }
  }, ApplicationConstants.TIMEOUT.TOUT100);
};

const Form = (props: IFormProps) => {
  const { children, handleSubmit, change, reset, valid, dirty, onKeyPress, "data-testid": dataTestId } = props;
  const childrenWithProps = React.Children.map(children, (child) =>
    child
      ? React.cloneElement(child as React.ReactElement<any>, {
          change: change,
          reset: reset,
          valid: valid,
          dirty: dirty,
        })
      : "",
  );
  return (
    <form onSubmit={handleSubmit} onKeyPress={onKeyPress} autoComplete="off" data-testid={dataTestId}>
      {childrenWithProps}
    </form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  onSubmitFail,
})(Form);
