import { LocaleManager, HelperLodash } from "am-web-ui-shared/helpers";
import ApplicationConstants from "../../../applicationConstants";
import React from "react";
import { Trans } from "react-i18next";
import { IconNormalBold } from "../../DetailsPanel/index";
import { AssetCapType, CostCodeType, costTypeErrorCode } from "../costCodeTypeEnum";
import { CappedLabelMapping } from "../associateChargesPanel";
import { shouldShowCappingAndTimeBasedFields } from "../../../../common/utils/commonUtils";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import { IchargeDetails } from "TARGET_BUILD/common/modules/charges/redux/iChargesModuleState";
/**
 * @description - Asset Value.
 */
export const assetValue = (data) => {
  return (
    data.assetValue && (
      <IconNormalBold
        id="pnlAssetValue"
        normalId="lblAssetValue"
        normalText={<Trans>{"common:ASSET_VALUE"}</Trans>}
        boldId="txtAssetValue"
        boldText={LocaleManager.numberHelpers.formatCurrency(
          data.assetValue,
          data.currency,
          ApplicationConstants.COUNT.CNT2,
        )}
      />
    )
  );
};

/**
 * @description - Cost Type.
 */
export const costType = (data: IchargeDetails, t: (key: string) => string) => {
  const code = data.costTypeCode ? data.costTypeCode : data.error ? data.code : "";
  const value =
    code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_ASSET ||
    code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_TEMPLATE
      ? costTypeErrorCode.NONE_NEVERSET
      : code === CostCodeType.DAILY || code === CostCodeType.NONE
        ? CostCodeType[code.toUpperCase()]
        : "";

  return (
    code &&
    value && (
      <IconNormalBold
        id="pnlcostType"
        normalId="lblcostType"
        normalText={t("common:ASSET_COST_TYPE")}
        boldId="txtcostType"
        boldText={t(`common:${value.toUpperCase()}`)}
      />
    )
  );
};

/**
 * @description - Daily Asset Cost.
 */
export const dailyAssetCost = (data, t) => {
  const { assetCapSetting = {} } = data;
  const cappingRecalculationFlag = SingletonFeatureManager.isFeatureEnabled(FEATURES.CAPPING_RECALCULATION_FLAG);
  return (
    <>
      {data.costTypeCode === CostCodeType.DAILY && data.costTypeValue && (
        <IconNormalBold
          id="pnldailyAssetCost"
          normalId="lbldailyAssetCost"
          normalText={<Trans>{"common:DAILY_ASSET_COST"}</Trans>}
          boldId="txtdailyAssetCost"
          boldText={LocaleManager.numberHelpers.formatCurrency(
            data.costTypeValue,
            data.currency,
            ApplicationConstants.COUNT.CNT2,
          )}
        />
      )}
      <>
        {data.timeBasedDailyAssetCost && (
          <IconNormalBold
            id="pnldailyAssetCost"
            normalId="lbldailyAssetCost"
            normalText={<Trans>{"common:DAILY_ASSET_COST"}</Trans>}
            boldId="txtdailyAssetCost"
            boldText={LocaleManager.numberHelpers.formatCurrency(
              data.timeBasedDailyAssetCost,
              data.currency,
              ApplicationConstants.COUNT.CNT2,
            )}
          />
        )}
        {data.timeBasedWeeklyAssetCost && (
          <IconNormalBold
            id="pnldailyAssetCost"
            normalId="lbldailyAssetCost"
            normalText={<Trans>{"charges:WEEKLY_ASSET_COST"}</Trans>}
            boldId="txtdailyAssetCost"
            boldText={`${LocaleManager.numberHelpers.formatCurrency(
              data.timeBasedWeeklyAssetCost,
              data.currency,
              ApplicationConstants.COUNT.CNT2,
            )} (${t("charges:AFTER_WORKDAYS", { x: data.timeBasedWeeklyDays })})`}
          />
        )}
        {data.timeBasedMonthlyAssetCost && (
          <IconNormalBold
            id="pnldailyAssetCost"
            normalId="lbldailyAssetCost"
            normalText={<Trans>{"charges:MONTHLY_ASSET_COST"}</Trans>}
            boldId="txtdailyAssetCost"
            boldText={`${LocaleManager.numberHelpers.formatCurrency(
              data.timeBasedMonthlyAssetCost,
              data.currency,
              ApplicationConstants.COUNT.CNT2,
            )} (${t("charges:AFTER_WORKDAYS", { x: data.timeBasedMonthlyDays })})`}
          />
        )}
      </>
      {(shouldShowCappingAndTimeBasedFields(data.timeBasedEnabledTenantList, data.tenantId) ||
        cappingRecalculationFlag) && (
        <>
          <IconNormalBold
            id="pnlAssetCapSettings"
            normalId="lblAssetCapSettings"
            normalText={<Trans>{"charges:ASSET_CAP_SETTINGS"}</Trans>}
            boldId="txtAssetCapSettings"
            boldText={<Trans>{HelperLodash.get(CappedLabelMapping, assetCapSetting.cappingType)}</Trans>}
          />
          {assetCapSetting.cappingType !== AssetCapType.NOT_CAPPED && (
            <>
              <IconNormalBold
                id="pnlAssetChargeValueCappetAt"
                normalId="lblAssetChargeValueCappedAt"
                normalText={<Trans>{"charges:ASSET_CHARGE_VALUE_CAPPED_AT"}</Trans>}
                boldId="txtAssetChargeValueCappedAt"
                boldText={assetCapSetting.cappingPercentage}
              />
            </>
          )}
          <IconNormalBold
            id="pnlAlwaysFollowAssetCapSettings"
            normalId="lblAlwaysFollowAssetCapSettings"
            normalText={<Trans>{"charges:ALWAYS_FOLLOW_ASSET_CAP_SETTINGS"}</Trans>}
            boldId="txtAlwaysFollowAssetCapSettings"
            boldText={<Trans>{assetCapSetting.alwaysFollowAssetCapSetting ? "common:YES" : "common:NO"}</Trans>}
          />
        </>
      )}{" "}
    </>
  );
};

/**
 * @description - Exporting Array of the components that are used for detail part of the Cost Settings  section used on asset details.
 */
export default [costType, dailyAssetCost, assetValue];
