import { createReducer } from "../../utils/commonUtils";
import {
  AlertNotificationAction,
  FieldRequestConstants,
  ConfirmTransferActions,
} from "TARGET_BUILD/common/actionConstants";

const headerCountState = {
  activeAlertCount: null,
  confirmTransferCount: null,
  fieldRequestsCount: 0,
  showAlertListPopup: false,
  gatewayAssetsCount: null,
  suggestedTransfersCount: 0,
};

const setAlertsCount = (state, action) => ({
  ...state,
  activeAlertCount: action.payload,
});

const getRequestAlertCountSuccess = (state, action) => {
  if (action.payload) {
    return {
      ...state,
      fieldRequestsCount: action.payload.activeCount,
    };
  } else {
    return {
      ...state,
    };
  }
};

const saveConfirmTransferCount = (state, action) => {
  if (action.payload) {
    return {
      ...state,
      confirmTransferCount: action.payload.count,
    };
  } else {
    return { ...state };
  }
};

const setGatewayAssetsCount = (
  state: typeof headerCountState,
  action: { type: string; gatewayAssetsCount: number },
) => ({
  ...state,
  gatewayAssetsCount: action.gatewayAssetsCount,
});

const toggleAlertList = (state) => ({
  ...state,
  showAlertListPopup: !state.showAlertListPopup,
});

const setSuggestedTransfersCount = (state: typeof headerCountState, action: { suggestedTransfersCount: number }) => ({
  ...state,
  suggestedTransfersCount: action.suggestedTransfersCount,
});

const headerCountReducer = createReducer(headerCountState, {
  [AlertNotificationAction.AN_SET_ALERTS_COUNT]: setAlertsCount,
  [FieldRequestConstants.GET_FIELD_REQUEST_COUNT_SUCCESS]: getRequestAlertCountSuccess,
  [ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT_RESPONSE]: saveConfirmTransferCount,
  [AlertNotificationAction.AN_TOGGLE_ALERT_LIST]: toggleAlertList,
  [AlertNotificationAction.SET_GATEWAY_ASSETS_COUNT]: setGatewayAssetsCount,
  [AlertNotificationAction.GET_SUGGESTED_TRANSFER_COUNT_SUCCESS]: setSuggestedTransfersCount,
});

export default headerCountReducer;
