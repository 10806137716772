const ApplicationConstants = {
  NUMBER: {
    NUM2: 2,
  },
  LENGTH: {
    LEN2: 2,
    LEN3: 3,
    LEN64: 64,
  },
  WIDTH: {
    W10: 10,
    W32: 32,
    W40: 40,
    W64: 64,
    W85: 85,
    W128: 128,
  },
  LIMIT: {
    LIM100: 100,
  },
  KEYCODES: {
    ENTER: 13,
  },
  TIMEOUT: {
    TOUT100: 100,
    TOUT5000: 5000,
  },
  OPACITY: {
    O50: 0.5,
  },
  FIXEDACTIONBARCLASS: "thead-sticky",
};
export default ApplicationConstants;
