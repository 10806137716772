import { HelperLodash } from "am-web-ui-shared/helpers";
import ISeletionModel from "./iSeletionModel";
import { blankValueEnum, responseModelEnum } from "./responseModelEnum";

const setSelectedTemplateId = (responseRow, key, selectedTemplateId) =>
  responseRow[key].isSelected ? responseRow.templateId : selectedTemplateId;

const getSelectedModel = (fieldValue, selection) => {
  return { value: fieldValue, isSelected: selection };
};
const getDefaultGroup = (data = [], keyValue) => {
  const value = keyValue.value === "" ? blankValueEnum.NO_VALUE : keyValue.value;
  const returnValue = data.filter((row) => {
    return row[responseModelEnum.defaultGroupName] === value;
  });
  return returnValue && returnValue[0][responseModelEnum.defaultGroupId];
};

const shouldClearTemplateImageId = (key, keyValue) => {
  return key === responseModelEnum.templateImageId && keyValue.isSelected;
};

const isKeyAttachmentOrService = (key) =>
  key === responseModelEnum.templateAttachments || key === responseModelEnum.services;

const shouldSetProperty = (responseRow, key, valueToCompare) => key === valueToCompare && responseRow[key].isSelected;

const updateResponseData = (data, responseData) => {
  let templateImageId;
  let selectedTemplateId;
  const chargeSetting = { selectedChargeTemplateId: null };
  responseData.forEach((responseRow) => {
    Object.entries(responseRow).forEach(([key, keyValues]) => {
      const keyValue = keyValues as ISeletionModel;
      if (key === responseModelEnum.defaultGroupName) {
        const defaultGroupId = getDefaultGroup(data, keyValue);
        responseRow[responseModelEnum.defaultGroupId] = getSelectedModel(defaultGroupId, keyValue.isSelected);
      } else if (key === responseModelEnum.manufacturerNameAndModel) {
        selectedTemplateId = setSelectedTemplateId(responseRow, key, selectedTemplateId);
        responseRow = getManufacturerModelResponse(data, responseRow, keyValue.value, keyValue);
      } else if (shouldSetProperty(responseRow, key, responseModelEnum.templateImageId)) {
        templateImageId = responseRow[key].value;
        /*
          removed deletion of key. In case of error first time and change of the image selection later
          value field is not added.
          Note: api is ignoring templateImageId field inside templates array
          api only considers templateImageId in the root level.
        */
      } else if (isKeyAttachmentOrService(key)) {
        delete responseRow[key];
      } else if (shouldSetProperty(responseRow, key, responseModelEnum.costTypeValueDisplay)) {
        chargeSetting.selectedChargeTemplateId = responseRow.templateId;
        delete responseRow[key];
      }
      if (keyValue && keyValue.value === blankValueEnum.NO_VALUE) {
        responseRow[key] = { value: "", isSelected: false };
        templateImageId = shouldClearTemplateImageId(key, keyValue) ? null : templateImageId;
      }
    });
  });
  return {
    chargeSetting,
    selectedTemplateId,
    templateData: {
      templateServices: HelperLodash.getPathValue(
        responseData[responseModelEnum.services] && responseData[responseModelEnum.services].options,
        "id",
      ),
      templates: responseData,
      [responseModelEnum.templateImageId]: templateImageId,
      [responseModelEnum.templateAttachments]:
        responseData[responseModelEnum.templateAttachments] &&
        responseData[responseModelEnum.templateAttachments].options,
    },
  };
};

const getManufacturerModelResponse = (data = [], responseRow, manufacturerModel, keyValue: ISeletionModel) => {
  const manufacturerName = manufacturerModel.split(" / ");
  const returnValue = data.filter((row) => {
    return (
      row[responseModelEnum.manufacturerName] === manufacturerName[0] &&
      row[responseModelEnum.model] === manufacturerName[1]
    );
  });
  if (returnValue && returnValue.length > 0) {
    responseRow[responseModelEnum.manufacturerId] = getSelectedModel(
      returnValue[0][responseModelEnum.manufacturerId],
      keyValue.isSelected,
    );
    responseRow[responseModelEnum.model] = getSelectedModel(
      returnValue[0][responseModelEnum.model],
      keyValue.isSelected,
    );
  }
  return responseRow;
};
const prepareChargeSettingMergeData = (templateChargeSetting, chargeSettingFormData) => {
  const chargeSettingTemplateId = (chargeSettingFormData || {}).selectedChargeTemplateId;
  const baseChargeSettings = { costTypeCode: "NONE", costTypeValue: null, assetValue: null };
  if ((templateChargeSetting[chargeSettingTemplateId] || {}).error) {
    return { chargeSetting: baseChargeSettings };
  } else {
    return {
      chargeSetting: {
        ...templateChargeSetting[chargeSettingTemplateId],
      },
    };
  }
};
export { updateResponseData, prepareChargeSettingMergeData };
