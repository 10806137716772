const googleLanguage = {
  "be-BY": "be",
  "bg-BG": "bg",
  "zh-HK": "zh-HK",
  "zh-TW": "zh-TW",
  "cs-CZ": "cs",
  "da-DK": "da",
  "nl-NL": "nl",
  "en-AU": "en-AU",
  "en-CA": "en",
  "en-GB": "en-GB",
  "en-IE": "en",
  "en-ZA": "en",
  "en-US": "en",
  "et-EE": "et",
  "fil-PH": "fil",
  "fi-FI": "fi",
  "fr-CA": "fr-CA",
  "fr-FR": "fr",
  "fr-CH": "fr",
  "de-DE": "de",
  "de-CH": "de",
  "el-GR": "el",
  "hi-IN": "hi",
  "hu-HU": "hu",
  "id-ID": "id",
  "it-IT": "it",
  "it-CH": "it",
  "ja-JP": "ja",
  "kk-KZ": "kk",
  "ko-KR": "ko",
  "lv-LV": "lv",
  "lt-LT": "lt",
  "ms-MY": "ms",
  "pl-PL": "pl",
  "pt-BR": "pt-BR",
  "pt-PT": "pt-PT",
  "ro-RO": "ro",
  "ru-RU": "ru",
  "sk-SK": "sk",
  "sl-SI": "sl",
  "es-LATAM": "es-419",
  "es-ES": "es",
  "sv-SE": "sv",
  "th-TH": "th",
  "tr-TR": "tr",
  "uk-UA": "uk",
};

export const getGoogleLanguage = (language: string) => {
  if (language) {
    return googleLanguage[language] || language.split("-").shift();
  }
  return language;
};
