import React, { FC } from 'react';
import type { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import MuiTooltip from '@mui/material/Tooltip';

import type { HiltiTheme } from '../../theme';

export type TooltipProps = MuiTooltipProps & { type?: 'light' | 'dark' };

const defaultProps: Partial<TooltipProps> = {
  arrow: true,
  type: 'dark',
};

const Tooltip: FC<TooltipProps> = ({ title, children, type, ...rest }) => (
  <MuiTooltip
    title={title}
    {...rest}
    componentsProps={{
      tooltip: {
        sx: ({ colors, shadows }: HiltiTheme) => {
          const isDark = type === 'dark';
          const backgroundColor = isDark ? colors.steel[600] : colors.white[200];
          const color = isDark ? colors.white[200] : colors.steel[600];
          return {
            backgroundColor,
            color,
            fontSize: 14,
            maxWidth: 400,
            borderRadius: 0,
            padding: 1,
            boxShadow: shadows[3],
            '& .MuiTooltip-arrow': {
              '&:before': {
                boxShadow: shadows[1],
              },
              color: backgroundColor,
            },
          };
        },
      },
    }}
  >
    {children}
  </MuiTooltip>
);

Tooltip.defaultProps = defaultProps;

export default Tooltip;
