import { LicenseContext } from "./licenseContext";
import { isFleetTenant } from "TARGET_BUILD/common/utils/userUtils";

class SingletonLicenseContext {
  static licenseContext: LicenseContext;

  public static async initLicenseContext({ license, tenantCategory, moduleFeatures, licenseBundle }) {
    if (!SingletonLicenseContext.licenseContext) {
      if (isFleetTenant(license, licenseBundle)) {
        const { FleetLicenseStrategy } = await import("./fleet/fleetLicenseStrategy");
        const fmAppStrategy = new FleetLicenseStrategy();
        SingletonLicenseContext.licenseContext = new LicenseContext(fmAppStrategy);
      } else {
        const { OnTrackLicenseStrategy } = await import("./ontrack/onTrackLicenseStrategy");
        const otAppStrategy = new OnTrackLicenseStrategy({ tenantCategory, moduleFeatures });
        SingletonLicenseContext.licenseContext = new LicenseContext(otAppStrategy);
      }
    }
  }
  public static getAssetsContext(): LicenseContext {
    return SingletonLicenseContext.licenseContext;
  }
}

export default SingletonLicenseContext;
