import { SharedUtils } from "am-web-ui-shared/helpers";
import GTMConstants from "../gtmConstants";

// Enum that contains the entity name for GA Filter".
export enum entityName {
  ASSET = "asset",
  QTYITEMS = "quantity_item",
  QTYITEMSALLOCATION = "quantity_item_allocation",
  LOCATIONS = "locations",
}

// GTM Events for filters

const GTMFilterEventsName = {
  groupName: GTMConstants.EVENTS.ASSET_GROUP_FILTER,
  labelNames: GTMConstants.EVENTS.LABEL_FILTER,
  defaultLocationName: GTMConstants.EVENTS.DEFAULT_LOCATION_FILTER,
  defaultLocationType: GTMConstants.EVENTS.DEFAULT_LOCATION_TYPE_FILTER,
  assetStatus: GTMConstants.EVENTS.STATUS_FILTER,
  batteryStatus: GTMConstants.EVENTS.TAG_BATTERY_STATUS_FILTER,
  scanCodeType: GTMConstants.EVENTS.SCAN_CODE_TYPE_FILTER,
  assetType: GTMConstants.EVENTS.ASSET_TYPE_FILTER,
  responsibleEmployeeName: GTMConstants.EVENTS.RESPONSIBLE_EMPLOYEE_FILTER,
  ownerName: GTMConstants.EVENTS.OWNER_FILTER,
  currentLocationName: GTMConstants.EVENTS.CURRENT_LOCATION_FILTER,
  currentLocationType: GTMConstants.EVENTS.CURRENT_LOCATION_TYPE_FILTER,
  currentLocationManagerName: GTMConstants.EVENTS.CURRENT_LOCATION_MANAGER_FILTER,
  idleTimeDuration: GTMConstants.EVENTS.IDLE_TIME_FILTER,
  manufacturerName: GTMConstants.EVENTS.MANUFACTURER_FILTER,
  model: GTMConstants.EVENTS.MODEL_FILTER,
  costCode: GTMConstants.EVENTS.COST_CODE_FILTER,
  ownerShipType: GTMConstants.EVENTS.OWNERSHIP_TYPE_FILTER,
  hiltiAssetOwnerName: GTMConstants.EVENTS.HILTI_ASSET_OWNER_FILTER,
  connectedCharger: GTMConstants.EVENTS.CONNECTED_CHARGER_FILTER,
  monthlyFleetRate: GTMConstants.EVENTS.MONTHLY_FLEET_RATE_FILTER,
  loanToolClaim: GTMConstants.EVENTS.LOAN_TOOL_CLAIM_FILTER,
  theftInsurance: GTMConstants.EVENTS.THEFT_INSURANCE_FILTER,
  purchaseCurrencyCode: GTMConstants.EVENTS.PURCHASE_CURRENCY_FILTER,
  purchaseDate: GTMConstants.EVENTS.PURCHASE_DATE_FILTER,
  vendor: GTMConstants.EVENTS.VENDOR_FILTER,
  hiltiAssetStatus: GTMConstants.EVENTS.HILTI_ASSET_STATUS_FILTER,
  replacementCost: GTMConstants.EVENTS.REPLACEMENT_COST_FILTER,
  assetCategory: GTMConstants.EVENTS.CATEGORY_FILTER,
  assetState: GTMConstants.EVENTS.ASSET_STATE_FILTER,
  rentalReturnDate: GTMConstants.EVENTS.RENTAL_RETURN_FILTER,
  hasAttachments: GTMConstants.EVENTS.ATTACHMENTS_FILTER,
  hasImage: GTMConstants.EVENTS.IMAGES_FILTER,
  transferred: GTMConstants.EVENTS.TRANSFERRED_FILTER,
  registeredBy: GTMConstants.EVENTS.REGISTERED_BY_FILTER,
  tagStatus: GTMConstants.EVENTS.SMART_TAG_STATUS_FILTER,
  parentLocation: GTMConstants.EVENTS.LOCATIONS_FILTER,
  assetUsageConditionState: GTMConstants.EVENTS.AUC_STATE_FILTER,
  allocationStatus: GTMConstants.EVENTS.ALLOCATION_STATUS,
  allocationType: GTMConstants.EVENTS.ALLOCATION_TYPE_FILTER,
  stockQuantity: GTMConstants.EVENTS.STOCK_PER_ALLOCATION_FILTER,
  canConsume: GTMConstants.EVENTS.DEFAULT_TO_CONSUMED_TRANSFER_FILTER,
  mainAllocation: GTMConstants.EVENTS.MAIN_ALLOCATION_FILTER,
  type: GTMConstants.EVENTS.TYPE_FILTER,
  locationManagerFirstName: GTMConstants.EVENTS.LOCATION_MANAGER_FIRST_NAME_FILTER,
  locationManagerLastName: GTMConstants.EVENTS.LOCATION_MANAGER_LAST_NAME_FILTER,
  costCenter: GTMConstants.EVENTS.COST_CENTER_FILTER,
  locationState: GTMConstants.EVENTS.LOCATION_CENTER_FILTER,
};

const filterValuesNames = {
  parentLocation: "locations",
  labelNames: "labels",
  defaultLocationName: "default_location",
  assetStatus: "status",
  responsibleEmployeeName: "responsible_employee",
  ownerName: "owner",
  currentLocationName: "current_location",
  currentLocationManagerName: "current_location_manager",
  manufacturerName: "manufacturer",
  model: "model",
  costCode: "cost_code",
  vendor: "vendor",
  registeredBy: "registered_by",
  purchaseCurrencyCode: "purchase_currency",
  locationManagerFirstName: "location_manager_first_name",
  locationManagerLastName: "location_manager_last_name",
  costCenter: "cost_center",
};
export const GTMEventsForAppliedFilter = (filter, resultCount, entityName) => {
  SharedUtils.pushDataToGA(GTMFilterEventsName[filter?.filterName], null, {
    entity: entityName,
    count: filter?.filterValue?.length,
    total_result_count: resultCount,
  });
};

export const GTMEventsForFilterSearch = (filter, entityName) => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.FILTER_SEARCH_VALUE, null, {
    entity: entityName,
    field: filterValuesNames[filter.customAttribute] || filter.customAttribute,
  });
};

export const GTMEventsForShowMoreFilter = (filter, entityName) => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.FILTER_SHOW_MORE, null, {
    entity: entityName,
    field: filterValuesNames[filter.customAttribute] || filter.customAttribute,
  });
};
