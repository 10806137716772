import forEach from "lodash/forEach";

export const paramsSerializer = (params: Record<string, unknown[]> | Array<unknown>) => {
  if (isURLSearchParams(params)) {
    return params.toString();
  }

  const parts = [];

  forEach(params, (val, key) => {
    if (val === null || val === undefined) {
      return;
    }

    if (Array.isArray(val)) {
      key = `${key}[]`;
    } else {
      val = [val];
    }

    forEach(val, (v) => {
      if (isDate(v)) {
        v = v.toISOString();
      }

      if (isObject(v)) {
        v = JSON.stringify(v);
      }

      parts.push(`${encode(key)}=${encode(v as string | number)}`);
    });
  });

  return parts.join("&");
};

function isURLSearchParams(val: unknown) {
  return typeof URLSearchParams !== "undefined" && val instanceof URLSearchParams;
}

function isObject(val: unknown) {
  return val !== null && typeof val === "object";
}

function isDate(val: unknown): val is Date {
  return Object.prototype.toString.call(val) === "[object Date]";
}

function encode(val: string | number) {
  // .replace(/%2C/gi, ",") is Removed as per BUTSAM-78236
  // .replace(/%3A/gi, ":") is Removed as per BUTSAM-78236
  return encodeURIComponent(val).replace(/%40/gi, "@").replace(/%24/g, "$").replace(/%20/g, "+");
}
