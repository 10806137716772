import React from "react";
import styled from "styled-components";

const SearchLabelDiv = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
  margin-right: 16px;
  white-space: nowrap;
  @media (max-width: 540px) {
    white-space: normal;
  }
`;

export default (props: { searchLabel: string }) => <SearchLabelDiv>{props.searchLabel}</SearchLabelDiv>;
