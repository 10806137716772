import assetListReducer from "./assetsReducerRoot";
import assetListSaga from "./assetSaga";
import { ISagaModule } from "redux-dynamic-modules-saga";
import { IAssetModuleState } from "./iAssetModuleState";
import assetLabelsSaga from "../assetList/labelsSaga";
import hiltiCloudSaga from "./hiltiCloudSaga";
import AddEditAssetSaga from "../addEditAsset/addAssetSaga";
import addAssetReducer from "../addEditAsset/addAssetReducer";
export const _assetModule: ISagaModule<IAssetModuleState> = {
  id: "assetListModule",
  reducerMap: {
    Assets: assetListReducer,
    AddAsset: addAssetReducer,
  },
  sagas: [assetListSaga, assetLabelsSaga, hiltiCloudSaga, AddEditAssetSaga],
};

export default _assetModule;
