import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3532.000000, -612.000000)" fill="#524F53">
        <g transform="translate(3530.000000, 610.000000)">
          <path
            d="M13,8 L10,8 L6,7 L6,3 L10,2 L19,2 L21,4 L21,7 L20,8 L18,8 L20,17 L15,17 L13.6525405,10.9364322 L13,8 Z M12,18 L22,18 L22,22 L12,22 L12,18 Z M2,4 L5,4 L5,6 L2,6 L2,4 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
