// @ts-ignore
import React from "react";
import styled from "styled-components";
import IShowMoreLessProps, { IShowMoreLessState } from "./iShowMoreLess";
const ShowMoreWrap = styled.span`
  padding: 6px 12px;
  text-decoration: underline;
`;
const ContentWrapper = styled.div`
  width: 100%;
`;
/**
 * @class ShowMoreLess that extends the Rect.Component.
 * @extends React.Component<>
 * @interface <IShowMoreLessProps> - Interface that holds the list of properties of the component.
 * @interface <IShowMoreLessState> - Interface that holds the list of state properties of the component.
 *
 * @description Tooltip component.
 *
 */

class ShowMoreLess extends React.Component<IShowMoreLessProps, IShowMoreLessState> {
  state = { showAll: false };
  contentWrapperRef = null;

  /**
   * @function showMore - Function that handle click on showmore.
   */
  showMore = () => this.setState({ showAll: true });
  /**
   * @function showLess - Function that handle click on showLess.
   */
  showLess = () => {
    this.setState({ showAll: false });
    this.handleClick();
  };

  /**
   * @description Function to move the scroll to view show more button
   *
   * @memberof LocationGroupWrapper
   */

  handleClick = () => {
    const scrollDiv = this.contentWrapperRef;
    if (scrollDiv && scrollDiv.scrollBy) {
      scrollDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  /**
   * @function render - Function that renders and retunrs the showmoreless component.
   */
  render() {
    const { content, limit, showLessLbl, showMoreLbl } = this.props;
    const { showAll } = this.state;
    if (content.length <= limit) {
      return <ContentWrapper>{content}</ContentWrapper>;
    }
    if (showAll) {
      return (
        <ContentWrapper ref={(wrapper) => (this.contentWrapperRef = wrapper)}>
          {content}
          <a onClick={this.showLess}>
            <ShowMoreWrap>{showLessLbl}</ShowMoreWrap>
          </a>
        </ContentWrapper>
      );
    }
    const toShow = content.substring(0, limit);
    return (
      <div>
        {toShow}
        <span onClick={this.showMore}>
          <ShowMoreWrap>{showMoreLbl}</ShowMoreWrap>
        </span>
      </div>
    );
  }
}

export default ShowMoreLess;
