// https://hc-apigw-d.hilti.com/test/ontracktest/v1/asset-mgmt/unique-asset/template?tenantId=1

/**
 * @description This file is for api hitting using
 * axios for the logout web UI user.
 */
// @ts-ignore
import { getApiUrl } from "custom-utils";
import { APIMessages } from "../actionConstants";
import IAssignedServiceList from "../components/services/model/iAssignedServiceList";
import Gateway from "../gateway/gateway";
import { IModelList } from "../models/template/model";
import TemplateModel from "../models/template/templateModel";
import {
  IAssetTemplatesDetail,
  IAssetTemplatesList,
  IErrorResponse,
} from "../modules/administration/template/model/iAssetTemplatesList";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getAssetMgmtEndpoint } from "../utils/apiUtils";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";

export const getModelsList = async (params?): Promise<IModelList | IErrorResponse> => {
  const url = "models";
  return Gateway.get(getAssetMgmtEndpoint(`/${url}`), params, null, null, false) as unknown as IModelList;
};

export const getPDPModels = async (params?): Promise<any> => {
  const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, "/catalogs/1/products");
  return Gateway.get(url, params, null, null, false) as unknown as any;
};

/**
 * @description - This method is used fetch the asset tempaltes list.
 *
 * @param queryObj - Object that holds the query string and that would be passed to the API.
 */

export const getAssetTemplatesList = async (
  queryObj,
  loader = false,
): Promise<IAssetTemplatesList | IErrorResponse> => {
  const filter = queryObj.filter;
  delete queryObj.filter;
  let url = null;
  if (filter) {
    url = `unique-asset/template?${filter}`;
  } else {
    url = "unique-asset/template";
  }

  return Gateway.get(
    getAssetMgmtEndpoint(`/${url}`),
    { ...queryObj },
    null,
    null,
    loader,
  ) as unknown as IAssetTemplatesList;
};

/**
 * @description - This method is used fetch the asset tempaltes details based on the template Id.
 */
export const getTemplateDetails = async (templateId, loader): Promise<TemplateModel | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/unique-asset/template/${templateId}`),
    null,
    null,
    null,
    loader,
  ) as unknown as TemplateModel;
};

/**
 * @description - This method is used fetch the asset tempaltes details based on the template Id.
 */
export const getTemplateAssetList = async (templateId): Promise<TemplateModel | IErrorResponse> => {
  return Gateway.get(getAssetMgmtEndpoint(`/templates/${templateId}/assets`), null, null) as unknown as TemplateModel;
};

/**
 * @description - This method is used fetch the asset tempaltes details based on the template Id.
 */
export const getAssetTemplateDetails = async (id): Promise<IAssetTemplatesDetail | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/unique-asset/template/${id}`),
    null,
    null,
  ) as unknown as IAssetTemplatesDetail;
};
/**
 * @description - This method is used to create a new asset tempaltes.
 */
export const createTemplate = async (templateData): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/template");
    return Gateway.post(url, null, templateData, null);
  } catch (error) {
    return { error: APIMessages.UNKNOWN_ERROR };
  }
};

/**
 * @description - This method is used to update the existing asset tempaltes based on template Id.
 */
export const updateTemplate = async (templateId, templateData): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint(`/unique-asset/template/${templateId}`);
    return Gateway.put(url, null, templateData, null);
  } catch (error) {
    return { error: APIMessages.UNKNOWN_ERROR };
  }
};

/**
 * @description - This method is used fetch the asset tempaltes details based on the templateName bases.
 *
 * @param {string} templateName - Name of the template.
 */
export const getTemplatesOnSearch = async (templateName): Promise<TemplateModel | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint("/unique-asset/template/search"),
    { templateName },
    null,
  ) as unknown as TemplateModel;
};

/**
 * @description - This method is used to delete the existing asset tempalte based on template Id.
 *
 * @param {number} assetTemplateId - Asset template Id.
 */
export const deleteAssetTemplates = async (multiSelectPayload): Promise<any> => {
  return Gateway.delete(
    getAssetMgmtEndpoint("/unique-asset/template"),
    null,
    null,
    null,
    multiSelectPayload,
  ) as unknown;
};

/**
 * @description - This is used to merge two templateData.
 *
 * @param templateData - Merged templateData data.
 */
export const updateMergeAssetTemplates = async (templateData): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/template/merge");
    return Gateway.put(url, null, templateData, null);
  } catch (error) {
    return { error: APIMessages.UNKNOWN_ERROR };
  }
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in Asset Template list
 * @param filters : used to apply filters
 */
export const getAssetTemplateFilterAttributes = async (filter?: string): Promise<any> => {
  let url = null;
  if (filter) {
    url = getAssetMgmtEndpoint(`/unique-asset/template/attributes?${filter}`);
  } else {
    url = getAssetMgmtEndpoint("/unique-asset/template/attributes");
  }
  return Gateway.get(url, null, null, null, false);
};

/**
 * Method to Make API call for getAssetTemplateSerivceList
 */
export const getAssetTemplateSerivceList = async (queryObj, id, loader = true): Promise<any> => {
  const filter = queryObj.filter;
  delete queryObj.filter;
  let url = null;
  if (filter) {
    url = `service_templates?${filter}`;
  } else {
    url = "service_templates";
  }

  return Gateway.get(
    getAssetMgmtEndpoint(`/unique-asset/template/${id}/${url}`),
    { ...queryObj },
    null,
    null,
    loader,
  ) as unknown as IAssetTemplatesList;
};

/**
 * Method to Make API call to set asset ServiceTemplate
 */
export const assetServiceTemplateAssociation = async (serviceData, id): Promise<any> => {
  return Gateway.patch(
    getAssetMgmtEndpoint(`/unique-asset/template/${id}/service-association`),
    null,
    serviceData,
    null,
  );
};
/**
 * Method to Make API call to remove asset template service
 */
export const removeServiceTemplate = async (assetTemplateId, serviceId): Promise<any> => {
  return Gateway.delete(
    getAssetMgmtEndpoint(`/unique-asset/template/${assetTemplateId}/service?serviceId=${serviceId}`),
    null,
    null,
  ) as unknown;
};

export const getAssignedServiceApi = async (id: number, loader: true): Promise<IAssignedServiceList[]> => {
  const templateId = id && `assetTemplateId=${id}`;
  return Gateway.get(
    getAssetMgmtEndpoint(`/services?${templateId}&limit=250&offset=0`),
    null,
    null,
    null,
    loader,
  ) as unknown as IAssignedServiceList[];
};
export const getAssetTemplateServiceFilterAttributes = async (
  filter: string,
  templateId: number,
  loader = true,
): Promise<any> => {
  let url = null;
  if (filter) {
    url = getAssetMgmtEndpoint(`/services-template/attributes?${filter}`);
  } else {
    url = getAssetMgmtEndpoint("/services-template/attributes");
  }
  return Gateway.get(url, { templateId }, null, null, loader);
};
