/**
 * @description Utility file to get all the window variables declared in config
 * file, need to add all the other methods there
 */
import { MATERIAL_NUMBER } from "../../config";
import { IAssetManagementHiltiOnline } from "../models/iAssetManagementHiltiOnline";
declare global {
  interface Window {
    __AM_MAPS_JS_KEY__: string;
    __AM_MAPS_MAPID__: string;
    __AM_SENTRY_DSN_KEY__: string;
    ASSET_MANAGEMENT_HILTI_ONLINE_INFO: IAssetManagementHiltiOnline;
    ASSET_MANAGEMENT_HILTI_ONLINE_REPAIR_INFO: IAssetManagementHiltiOnline;
    ASSET_MANAGEMENT_HILTI_ONLINE_LOST_STOLEN_INFO: IAssetManagementHiltiOnline;
    ALERT_MANAGEMENT_HILTI_ONLINE_BATTERY_EXCHANGE_INFO: IAssetManagementHiltiOnline;
    ASSET_MANAGEMENT_HILTI_ONLINE_REPAIR_TRACKING_INFO: IAssetManagementHiltiOnline;
    ASSET_MANAGEMENT_HILTI_ONLINE_RENEW_RETURN_INFO: IAssetManagementHiltiOnline;
    ASSET_MANAGEMENT_HILTI_ONLINE_RETURN_LOAN_TOOLS: IAssetManagementHiltiOnline;
    ASSET_MANAGEMENT_HILTI_ONLINE_RETURN_RENT_TOOLS: IAssetManagementHiltiOnline;
    CHANGE_PASSWORD_LINK: string;
    __DEFAULT_MANUFACTURER: string[];
    DIDOMI_COOKIES_MGMT: string;
    TERMS_OF_USE_PRIVACY_POLICIES: string;
    TERMS_OF_USE_TERMS_AND_CONDITIONS: string;
    GATEWAY_MATERIAL_NUM: string;
    WINDOW_LANGUAGE_CODE: string;
    ENABLE_ACR_CAP_SETTINGS: boolean;
    ENABLE_SERVICE_RESULT_SETTINGS: boolean;
    ASSET_MANAGEMENT_GATEWAY_STATUS_OFFLINE_CODE: string;
    ASSET_MANAGEMENT_PRIVACY_POLICIES: string;
    PUBLIC_API: string;
    SENTRY_SAMPLE_RATE: number;
    ASSET_MANAGEMENT_COUNTRIES_CENTER: { [key: string]: { lat: number; lng: number; z: number } };
  }
}

/**
 * @description Function to get the google map key from config
 */
export const getMapKey = () => {
  return window.__AM_MAPS_JS_KEY__;
};

export const getMapId = () => {
  return window.__AM_MAPS_MAPID__;
};

/**
 * @description Function to get the Sentry dsn key from config
 */
export const getSentryDsnKey = () => {
  return window.__AM_SENTRY_DSN_KEY__;
};

export const getSentrySampleRate = () => {
  const sampleRate = window.SENTRY_SAMPLE_RATE;
  if (!isNaN(sampleRate) && Number(sampleRate) >= 0 && Number(sampleRate) <= 1) {
    return sampleRate;
  }
  return 0.2;
};

/**
 * @description Function to get change password link
 */
export const getChangePasswordLink = () => {
  return window.CHANGE_PASSWORD_LINK;
};

export const getDefaultManufacture = () => {
  return window.__DEFAULT_MANUFACTURER || [];
};

/**
 * @description Function to get dedomi api key
 */
export const getDidomiApiKey = () => {
  return window.DIDOMI_COOKIES_MGMT;
};

export const getPrivacyPolicyLink = () => {
  return window.TERMS_OF_USE_PRIVACY_POLICIES || "";
};
export const getTouLink = () => {
  return window.TERMS_OF_USE_TERMS_AND_CONDITIONS || "";
};
export const getGatewayMaterialNumber = () => {
  return window.GATEWAY_MATERIAL_NUM ? window.GATEWAY_MATERIAL_NUM : MATERIAL_NUMBER;
};
export const getAssetManagementHiltiOnlineInfo = () => {
  return window.ASSET_MANAGEMENT_HILTI_ONLINE_INFO || null;
};
export const getAssetManagementHiltiOnlineRepairInfo = () => {
  return window.ASSET_MANAGEMENT_HILTI_ONLINE_REPAIR_INFO || null;
};
export const getAssetManagementHiltiOnlineRepairTrackingInfo = () => {
  return window.ASSET_MANAGEMENT_HILTI_ONLINE_REPAIR_TRACKING_INFO || null;
};
export const getAssetManagementHiltiOnlineLostStolenInfo = () => {
  return window.ASSET_MANAGEMENT_HILTI_ONLINE_LOST_STOLEN_INFO || null;
};
export const getAssetManagementHiltiOnlineRenewReturnInfo = () => {
  return window.ASSET_MANAGEMENT_HILTI_ONLINE_RENEW_RETURN_INFO || null;
};
export const getAssetManagementHiltiOnlineReturnLoanToolsInfo = () => {
  return window.ASSET_MANAGEMENT_HILTI_ONLINE_RETURN_LOAN_TOOLS || null;
};
export const getAssetManagementHiltiOnlineReturnRentalToolsInfo = () => {
  return window.ASSET_MANAGEMENT_HILTI_ONLINE_RETURN_RENT_TOOLS || null;
};
export const getAlertManagementHiltiOnlineBatteryExchangeInfo = () => {
  return window.ALERT_MANAGEMENT_HILTI_ONLINE_BATTERY_EXCHANGE_INFO || null;
};
export const isServiceResultSettingEnabled = () => window.ENABLE_SERVICE_RESULT_SETTINGS || false;

export const getGatewayOfflineStatusCode = () => window.ASSET_MANAGEMENT_GATEWAY_STATUS_OFFLINE_CODE || "30213";

export const getPrivacyPolicies = () => window.ASSET_MANAGEMENT_PRIVACY_POLICIES || null;

export const getEnabledTenantIdsForUtilizationAndBenchmarking = () => window.TMP_UTILIZATION_TENANTS_FF || [];
export const getMuiLicense = () => window.MUI_LICENSE || "";
export const getPublicApi = () => window.PUBLIC_API || "";

export const getCountryCordinates = (countryCode: string): { lat: number; lng: number; z: number } => {
  const countries = window.ASSET_MANAGEMENT_COUNTRIES_CENTER || {};
  return countries[countryCode] || { lat: 51.165691, lng: 10.451526, z: 6 }; // DE default
};
