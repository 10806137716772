import { Dropdown, IconName, OutsideClickAlerter, Prompt, SquareIcon } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { Trans } from "react-i18next";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { redirect } from "../../../../common/utils/sessionUtils";
import { sessionStorageClearAll, storageClearAll, storageGetItem } from "../../../../common/utils/storageUtils";
import StyleAttrConstants from "../../../styleAttrConstants";
import { getFullName } from "../../../utils/commonUtils";
import IUserProfileProps from "./iUserProfile";
import SquareImage from "../../styled/squareImage/squareImageContainer";
// @ts-ignore
import ImageTypeEnum from "../../../../common/utils/enums/imageTypeEnum";
import { ImagePreviewEnum } from "../../../../common/utils/enums/imageThumbnailRatioEnum";
import withLicenseContext from "TARGET_BUILD/licenseStrategy/withLicenseContext";
import { CLICK_UPGRADE, trackUpgradeDialogEvents } from "TARGET_BUILD/analytics/events/upgradeOnTrackReady";
import UserGroup from "@hilti/components/core/icons/svgs/UserGroup";
import FeatureFlagManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { UpgradeOnTrackDialog } from "../upgradeOnTrackDialog/UpgradeOnTrackDialog";

export const Ellip = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledName = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 100px;
`;

const StyledCompany = styled(Ellip)``;
export const StyledAuthor = styled(Ellip)`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  margin-bottom: 2px;
`;

export const StyledUserProfile = styled("div").attrs({
  id: "userSetting",
})`
  display: flex;
  align-items: center;
  padding: 6px 0 6px 8px;
  border-left: 2px solid ${(props) => props.theme.colors.base.borderColor};
  border-bottom: ${(props) => (props.isUserSettingMenu ? `2px solid ${props.theme.colors.base.hiltiRed}` : "")};
  cursor: pointer;
  max-width: 275px;
  height: 46px;
  font-size: ${(props) => props.theme.fontSize.base};
`;

const UnorderedList = styled.ul.attrs({
  id: "userSettingDropDown",
})``;

export const ListStyle = styled.li`
  list-style: none;
  display: flex;
  #upgradeButton {
    border-top: ${(props) => `2px solid ${props.theme.colors.base.borderColor}`};
  }
`;

export const ListLink = styled.a`
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  line-height: 19px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.colors.base.customLightGrey};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    font-weight: ${(props) => props.theme.fontNormal.fontWeight};
  }
  &:focus {
    background: ${(props) => props.theme.colors.base.greyDark};
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    outline: none;
  }
`;
const rotate90 = "rotate(90deg)";
const rotateMinus90 = "rotate(-90deg)";
export const TabLinkMenuArrow = styled.span`
  display: inline-flex;
  transform: ${(props) => (props.isOpen ? rotateMinus90 : rotate90)};
`;

const Label = styled.span`
  margin-left: 8px;
  max-width: 250px;
  &.upgradeLabel {
    font-weight: bold;
    font-family: hilti-bold;
  }
`;
const imageWrapperAttr = {
  height: `${StyleAttrConstants.HEIGHT.H32}px`,
  width: `${StyleAttrConstants.WIDTH.W38}px`,
};

export class UserProfile extends React.PureComponent<IUserProfileProps> {
  path = this.props.location ? this.props.location.pathname : "";
  possiblePath = [appConstants.CONTACT, appConstants.COMPANY, appConstants.USER_SETTING];
  state = {
    isOpen: false,
    isUserSettingMenu: this.possiblePath.includes(this.path),
    showLogOutConfirm: false,
    showUpgradeDialog: false,
  };

  handleProfileClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.closeMoreFilter();
  };

  /**
   * @description Function to logout by calling user-mgmt
   */
  logoutIDM = () => {
    this.props.logout();
    storageClearAll();
    sessionStorageClearAll();
    this.handleLogOutPrompt();
  };

  redirectToCompany = (screen) => {
    this.setState({ isOpen: false, isUserSettingMenu: true });
    redirect(screen, this.props.history);
  };

  /**
   * @description Function to open and close logout prompt
   */

  handleLogOutPrompt = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      showLogOutConfirm: !this.state.showLogOutConfirm,
    });
  };

  /**
   * @description Function to open the upgrade popup
   */

  handleOpenUpgradePopup = () => {
    this.setState({ showUpgradeDialog: true });
    trackUpgradeDialogEvents(CLICK_UPGRADE);
  };

  handleCloseUpgradeDialog = () => {
    this.setState({ showUpgradeDialog: false });
  };

  renderSettingsMenu = () => {
    const { licenseContext } = this.props;
    const showUpgradeButton = licenseContext?.getUserProfileDetails().showUpgrade;
    const isUpgradeAvailable = FeatureFlagManager.isFeatureEnabled("web.upgrade.ontrack-ready");

    const switchCustomer = FeatureFlagManager.isFeatureEnabled("web.user-menu.switch-customer") ? (
      <ListStyle>
        <ListLink data-testid="switch-customer" href={"/login"}>
          <UserGroup />
          <Label>
            <Trans>{"common:SWITCH_CUSTOMER"}</Trans>
          </Label>
        </ListLink>
      </ListStyle>
    ) : null;

    const upgradeButton =
      showUpgradeButton && isUpgradeAvailable ? (
        <ListStyle>
          <ListLink data-testid="upgradeButton" id="upgradeButton" onClick={this.handleOpenUpgradePopup}>
            <SquareIcon id="logoutIcon" name={IconName.upgradeButton} imageSize={24} />
            <Label className="upgradeLabel">
              <Trans>{"common:USER_PROFILE_MENU_UPGRADE"}</Trans>
            </Label>
          </ListLink>
        </ListStyle>
      ) : null;

    return storageGetItem(appConstants.USER_TOKEN) ? (
      <UnorderedList>
        <ListStyle>
          <ListLink id="contactSupportAndInformation" onClick={this.redirectToCompany.bind(null, appConstants.CONTACT)}>
            <SquareIcon id="contactIcon" name={IconName.Contact} />
            <Label>
              <Trans>{"common:USER_PROFILE_MENU_CONTACT_SUPPORT_INFO"}</Trans>
            </Label>
          </ListLink>
        </ListStyle>
        <ListStyle>
          <ListLink id="companySettingLink" onClick={() => this.redirectToCompany(appConstants.COMPANY)}>
            <SquareIcon id="companySettingIcon" name={IconName.SettingCompany} />
            <Label>
              <Trans>{"common:USER_PROFILE_MENU_COMPANY_SETTINGS"}</Trans>
            </Label>
          </ListLink>
        </ListStyle>
        <ListStyle>
          <ListLink id="userSettingLink" onClick={() => this.redirectToCompany(appConstants.USER_SETTING)}>
            <SquareIcon id="userSettingIcon" name={IconName.SettingUser} />
            <Label>
              <Trans>{"common:USER_PROFILE_MENU_USER_SETTINGS"}</Trans>
            </Label>
          </ListLink>
        </ListStyle>
        {switchCustomer}
        <ListStyle>
          <ListLink data-testid="logoutButton" id="logoutButton" onClick={this.handleLogOutPrompt}>
            <SquareIcon id="logoutIcon" name={IconName.Logout} />
            <Label>
              <Trans>{"common:USER_PROFILE_MENU_LOGOUT"}</Trans>
            </Label>
          </ListLink>
        </ListStyle>
        {upgradeButton}
      </UnorderedList>
    ) : null;
  };

  render() {
    const { translate, userProfile } = this.props;
    const { showUpgradeDialog, isOpen, showLogOutConfirm, isUserSettingMenu } = this.state;
    const userFullName = getFullName(
      this.props.userProfile && this.props.userProfile.firstName,
      this.props.userProfile && this.props.userProfile.lastName,
    );
    return (
      <React.Fragment>
        <OutsideClickAlerter
          render={() => (
            <StyledUserProfile
              isUserSettingMenu={isUserSettingMenu}
              onClick={this.handleProfileClick}
              data-testid="userSetting"
              id="userSetting"
            >
              {userProfile && userProfile.workerImageId ? (
                <SquareImage
                  id="userProfileImage"
                  wrapperAttributes={imageWrapperAttr}
                  defaultSrc={IconName.User}
                  documentId={userProfile.workerImageId}
                  className="small"
                  imageSize={StyleAttrConstants.HEIGHT.H32}
                  type={ImageTypeEnum.thumbnail}
                  thumbnailWidth={ImagePreviewEnum.width}
                  thumbnailHeight={ImagePreviewEnum.height}
                />
              ) : (
                <SquareIcon id="userProfileImage" name={IconName.User} />
              )}
              {userFullName && (
                <StyledName id="userFullName">
                  <StyledAuthor title={userFullName}>{userFullName}</StyledAuthor>
                  <StyledCompany id="userCompanyName" title={userProfile && userProfile.tenantDisplayName}>
                    {userProfile && userProfile.tenantDisplayName}
                  </StyledCompany>
                </StyledName>
              )}
              <TabLinkMenuArrow isOpen={isOpen}>
                <SquareIcon id="userProfileArrow" name={IconName.Arrow} className="small" imageSize={16} />
              </TabLinkMenuArrow>
              {isOpen ? (
                <Dropdown
                  dropDownPosition={{ top: `${StyleAttrConstants.TOP.T48}px` }}
                  renderAsProps={this.renderSettingsMenu}
                />
              ) : null}
            </StyledUserProfile>
          )}
          onClickAway={() => this.setState({ isOpen: false })}
        />
        {showLogOutConfirm && (
          <Prompt
            icon={IconName.Info}
            handleClose={this.handleLogOutPrompt}
            handleConfirm={this.logoutIDM}
            header={translate("common:LOGOUT_CONFIRM_MESSAGE")}
            cancelLabel={translate("common:CANCEL")}
            okLabel={translate("common:OK")}
          />
        )}
        {showUpgradeDialog && <UpgradeOnTrackDialog handleClose={this.handleCloseUpgradeDialog} />}
      </React.Fragment>
    );
  }
}

export default withLicenseContext(UserProfile);
