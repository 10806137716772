import Gateway from "../gateway/gateway";
import IAssetFieldModel from "../models/asset/assetFieldModel";
import { ICustomViewListModel } from "../models/customView/iCustomViewListModel";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import { IErrorResponse } from "../models/iApiError";
import { ModuleName } from "../utils/enums/moduleNameEnum";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getAssetMgmtEndpoint, getOrgMgmtEndpoint, getUserMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description To get custom view dropdown data
 * @param entityName To get dropdown data for different modules like assets.
 */

export const getCustomViewFields = async (entityName: string): Promise<IAssetFieldModel | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/views/custom-fields?entityName=${entityName}`),
    null,
    null,
    null,
    false,
  ) as unknown as IAssetFieldModel;
};

/**
 * @description To get custom view dropdown data
 * @param entityName To get dropdown data for different modules like assets.
 */

export const getLocationViewFields = async (): Promise<IAssetFieldModel | IErrorResponse> => {
  return Gateway.get(
    getOrgMgmtEndpoint(`/views/custom-fields?entityName=location`),
    null,
    null,
    null,
    false,
  ) as unknown as IAssetFieldModel;
};

/**
 * @description To get custom view dropdown data
 */

export const getWorkerViewFields = async (): Promise<IAssetFieldModel | IErrorResponse> => {
  return Gateway.get(
    getUserMgmtEndpoint(`/views/custom-fields?entityName=worker`),
    null,
    null,
    null,
    false,
  ) as unknown as IAssetFieldModel;
};

/**
 * @description To create custom view.
 */
export const createCustomView = async (customViewData): Promise<any> => {
  return Gateway.post(
    getVersionedApiUrl(getMicroServiceName(customViewData.entityName), "/views"),
    null,
    customViewData,
    null,
  );
};

/**
 * @description To update custom view for asset filter and sort.
 */
export const updateCustomViewApi = async (customViewId, customViewData): Promise<any> => {
  return Gateway.put(
    getVersionedApiUrl(getMicroServiceName(customViewData.entityName), `/views/${customViewId}`),
    null,
    customViewData,
    null,
  );
};
/**
 * @description To get custom view.
 */

export const getCustomViewList = async (entityName): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(
    getVersionedApiUrl(getMicroServiceName(entityName.entityName), `/views`),
    entityName,
    null,
    null,
    false,
  ) as unknown as ICustomViewListModel;
};

/**
 * @description To update custom view.
 */
export const renameCustomView = async (customViewId, customViewData): Promise<any> => {
  return Gateway.patch(
    getVersionedApiUrl(getMicroServiceName(customViewData.entityName), `/views/${customViewId}/rename`),
    null,
    customViewData,
    null,
  );
};

/**
 * @description To Reorder custom view.
 * @param {customViewId , index , entityName} Id
 */

export const reorderCustomViewApi = async (queryObj: object, moduleName: string): Promise<any> => {
  const url = getVersionedApiUrl(getMicroServiceName(moduleName), `/views/update-index`);
  return Gateway.patch(url, null, queryObj, null);
};

/**
 * @description To set default custom view.
 * @param {customViewId} Id
 */
export const setDefaultCustomView = async (customViewId, isDefaultView): Promise<any> => {
  return Gateway.patch(getOrgMgmtEndpoint(`/views/${customViewId}/set-default`), null, isDefaultView, null);
};

/**
 * @description To set default custom view for worker.
 * @param {customViewId} Id
 */
export const setWorkerDefaultCustomView = async (customViewId, isDefaultView): Promise<any> => {
  return Gateway.patch(getUserMgmtEndpoint(`/views/${customViewId}/set-default`), null, isDefaultView, null);
};

/**
 * @description To delete custom view for asset.
 * @param {customViewId} Id
 */
export const deleteCustomView = async (customViewId, entityName): Promise<any> => {
  return Gateway.delete(
    getVersionedApiUrl(getMicroServiceName(entityName.entityName), `/views/${customViewId}`),
    entityName,
    null,
  );
};

/**
 * @description To update custom view for asset filter and sort.
 */
export const updateCustomViewGridFieldsApi = async (customViewId, gridFields): Promise<any> => {
  return Gateway.patch(
    getVersionedApiUrl(getMicroServiceName(gridFields.entityName), `/views/${customViewId}/update-fields`),
    null,
    gridFields,
    null,
  );
};

/**
 * @description To reset oringinal view for asset
 */
export const resetAssetOriginalViewApi = async (entityName): Promise<any> => {
  return Gateway.delete(getAssetMgmtEndpoint(`/views/reset?entityName=${entityName}`), null, null);
};

/**
 * @description To reset oringinal view for location and worker
 */
export const resetOriginalViewApi = async (entityName): Promise<any> => {
  return Gateway.delete(
    getVersionedApiUrl(getMicroServiceName(entityName), `/views/reset?entityName=${entityName}`),
    null,
    null,
  );
};

/**
 * @description To get asset system view
 */
export const getAssetSystemView = async (entityName): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/views/0?entityName=${entityName}`),
    null,
    null,
  ) as unknown as ICustomViewListModel;
};

/**
 * @description To get system view for location and worker
 */
export const getSystemView = async (entityName): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(
    getVersionedApiUrl(getMicroServiceName(entityName), `/views/0?entityName=${entityName}`),
    null,
    null,
    null,
    false,
  ) as unknown as ICustomViewListModel;
};

export const regenerateActivationEmail = async (employeeId): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.put(
    getUserMgmtEndpoint(`/employees/${employeeId}/re-generate-activation`),
    null,
    null,
    null,
  ) as unknown as ICustomViewListModel;
};

export const getMicroServiceName = (entityName: string) => {
  if (ModuleName.location === entityName) {
    return ServiceUrl.ORG_MGMT;
  } else if (ModuleName.asset === entityName || ModuleName.QtyItem === entityName) {
    return ServiceUrl.ASSET_MGMT;
  }
  return ServiceUrl.USER_MGMT;
};
