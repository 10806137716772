/**
 * @description Action constants to be used for location module and its feature
 *
 * format: {feature}_{action}
 */
const LocationActionConstants = {
  // action to get location list in location's page
  AM_GET_LOCATION_FILTER_FIELD: "AM_GET_LOCATION_FILTER_FIELD",
  AM_LOCATIONS_SELECT_ROW: "AM_LOCATIONS_SELECT_ROW",
  AM_LOCATIONS_UNSELECT_ROW: "AM_LOCATIONS_UNSELECT_ROW",
  AM_LOCATION_LIST_LOADER_END: "AM_LOCATION_LIST_LOADER_END", // loader for asset list
  AM_LOCATION_LIST_LOADER_START: "AM_LOCATION_LIST_LOADER_START", // loader for asset list
  AM_LOCATION_RESET_QUERY_PARAM: "AM_LOCATION_RESET_QUERY_PARAM", // Reset search and sort query to default
  AM_REORDER_LOCATION_CUSTOM_VIEW: "AM_REORDER_LOCATION_CUSTOM_VIEW",
  CLEAN_LOCATION_LABEL_DATA: "CLEAN_LOCATION_LABEL_DATA",
  CLEAR_ALL_LOCATION_DETAILS: "CLEAR_ALL_LOCATION_DETAILS",
  CLEAR_IMPORT_HISTORY_ERRORS: "CLEAR_IMPORT_HISTORY_ERRORS",
  CLEAR_LOCATION_DELETE_FLAG: "CLEAR_LOCATION_DELETE_FLAG", // Clears location delete flag
  CLEAR_LOCATION_GROUPLIST: "CLEAR_LOCATION_GROUPLIST",
  CLOSE_LOCATION_DETAILS: "CLOSE_LOCATION_DETAILS",
  DELETE_LOCATION: "DELETE_LOCATION",
  DELETE_LOCATION_FAIL: "DELETE_LOCATION_FAIL",
  DELETE_LOCATION_SUCCESS: "DELETE_LOCATION_SUCCESS",
  GET_IMPORT_LOCATION_HISTORY: "GET_IMPORT_LOCATION_HISTORY",
  GET_IMPORT_LOCATION_HISTORY_ERRORS: "GET_IMPORT_LOCATION_HISTORY_ERRORS",
  GET_IMPORT_LOCATION_HISTORY_ERRORS_RESPONSE: "GET_IMPORT_LOCATION_HISTORY_ERRORS_RESPONSE",
  GET_IMPORT_LOCATION_HISTORY_RESPONSE: "GET_IMPORT_LOCATION_HISTORY_RESPONSE",
  GET_LOCATIONS: "GET_LOCATIONS",
  GET_LOCATION_LIST: "GET_LOCATION_LIST",
  GET_LOCATION_LIST_FOR_SEARCH: "GET_LOCATION_LIST_FOR_SEARCH", // action to get locations with searched query.
  GET_LOCATION_MANAGER: "GET_LOCATION_MANAGER", // action to get location manager list
  GET_LOCATION_MANAGER_DETAILS: "GET_LOCATION_MANAGER_DETAILS", // action to get location manager details
  GET_LOCATION_MANAGER_DETAILS_RESPONSE: "GET_LOCATION_MANAGER_DETAILS_RESPONSE", // response action to get location manager details
  GET_LOCATION_MANAGER_RESPONSE: "GET_LOCATION_MANAGER_RESPONSE", // response action to get location manager details
  GET_LOCATION_NEXT_LEVEL_DETAILS: "GET_LOCATION_NEXT_LEVEL_DETAILS",
  GET_LOCATION_TYPES: "GET_LOCATION_TYPES", // action to get location types list
  GET_LOCATION_TYPES_RESPONSE: "GET_LOCATION_TYPES_RESPONSE", // response action to get location types list
  GET_STATES: "GET_STATES", // action to get states list
  GET_STATES_RESPONSE: "GET_STATES_RESPONSE", // response action to get states list
  GET_LOCATIONS_COUNTS: "GET_LOCATIONS_COUNTS",
  IMPORT_HISTORY_RESPONSE: "IMPORT_HISTORY_RESPONSE",
  LOCATION_ADD_LOCATION: "LOCATION_ADD_LOCATION", // action to post new location data
  LOCATION_ADD_LOCATION_RESPONSE: "LOCATION_ADD_LOCATION_RESPONSE", // response action to post new location data
  LOCATION_CLEAR_ADD_LOCATION_RESPONSE: "LOCATION_CLEAR_ADD_LOCATION_RESPONSE", // response action to post new location data
  LOCATION_CLEAR_ALL_SELECTION: "LOCATION_CLEAR_ALL_SELECTION",
  LOCATION_EDIT_LOCATION: "LOCATION_EDIT_LOCATION", // action to put edited location data
  LOCATION_EDIT_LOCATION_RESPONSE: "LOCATION_EDIT_LOCATION_RESPONSE", // response action to put edited location data
  LOCATION_EDIT_LOCATION_WITHOUT_LIST: "LOCATION_EDIT_LOCATION_WITHOUT_LIST",
  LOCATION_FILTER_FIELD_SUCCESS: "LOCATION_FILTER_FIELD_SUCCESS", // response action for location filter attributes
  LOCATION_MAP_SIDE_PANEL_CLOSE: "LOCATION_MAP_SIDE_PANEL_CLOSE",
  LOCATION_SELECT_ALL_ROWS: "LOCATION_SELECT_ALL_ROWS",
  LOCATION_SELECT_GRID_CHECKBOX: "LOCATION_SELECT_GRID_CHECKBOX",
  LOCATION_SET_VIEW_TYPE: "LOCATION_SET_VIEW_TYPE",
  LOCATION_UNSELECT_GRID_CHECKBOX: "LOCATION_UNSELECT_GRID_CHECKBOX",
  OM_ASSOCIATE_LOCATION_LABEL: "OM_ASSOCIATE_LOCATION_LABEL",
  OM_ASSOCIATE_LOCATION_LABEL_DETAILS: "OM_ASSOCIATE_LOCATION_LABEL_DETAILS",
  OM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA: "OM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA",
  OM_CLEAR_DEFAULT_GRID_FIELDS: "OM_CLEAR_DEFAULT_GRID_FIELDS",
  OM_CREATE_LOCATION_CUSTOM_VIEW: "OM_CREATE_LOCATION_CUSTOM_VIEW", // To create custom view
  OM_DELETE_CUSTOM_VIEW: "OM_DELETE_CUSTOM_VIEW", // Delete custom view
  OM_GET_ALL_LOCATIONS: "OM_GET_ALL_LOCATIONS",
  OM_GET_CUSTOM_VIEW_FAILURE: "OM_GET_CUSTOM_VIEW_FAILURE",
  OM_GET_LOCATION_BY_ID: "OM_GET_LOCATION_BY_ID",
  OM_GET_LOCATION_CUSTOM_VIEW: "OM_GET_LOCATION_CUSTOM_VIEW", // To get location custom view list
  OM_GET_LOCATION_CUSTOM_VIEW_SUCCESS: "OM_GET_LOCATION_CUSTOM_VIEW_SUCCESS", // To get location custom view list
  OM_GET_LOCATION_GROUPS_LIST: "OM_GET_LOCATION_GROUPS_LIST", // action to get location groups list
  OM_GET_LOCATION_GROUPS_LIST_FOR_ID: "OM_GET_LOCATION_GROUPS_LIST_FOR_ID", // action to update the group list after clicking on an item
  OM_GET_LOCATION_GROUPS_LIST_FOR_MOVE: "OM_GET_LOCATION_GROUPS_LIST_FOR_MOVE", // action to get the location groups list during move action.
  OM_GET_LOCATION_SYSTEM_VIEW: "OM_GET_LOCATION_SYSTEM_VIEW",
  OM_GET_LOCATION_SYSTEM_VIEW_SUCCESS: "OM_GET_LOCATION_SYSTEM_VIEW_SUCCESS",
  OM_LOCATION_ADD_RENAME_CUSTOM_VIEW_RESPONSE: "OM_LOCATION_ADD_RENAME_CUSTOM_VIEW_RESPONSE",
  OM_LOCATION_ASSOCIATE_LABELS_RESPONSE: "OM_LOCATION_ASSOCIATE_LABELS_RESPONSE",
  OM_LOCATION_CUSTOM_VIEW_UPDATE_GRID_FIELDS: "OM_LOCATION_CUSTOM_VIEW_UPDATE_GRID_FIELDS",
  OM_LOCATION_SET_SELECTED_ROW: "OM_LOCATION_SET_SELECTED_ROW", // Action to set selected row
  OM_PUT_LOCATION_BY_ID: "OM_PUT_LOCATION_BY_ID",
  OM_RENAME_CUSTOM_VIEW: "OM_RENAME_CUSTOM_VIEW", // To rename custom view
  OM_REORDER_LOCATION_CUSTOM_VIEW: "OM_REORDER_LOCATION_CUSTOM_VIEW",
  OM_REORDER_LOCATION_CUSTOM_VIEW_SUCCESS: "OM_REORDER_LOCATION_CUSTOM_VIEW_SUCCESS",
  OM_RESET_LOCATION_ORIGINAL_VIEW: "OM_RESET_LOCATION_ORIGINAL_VIEW",
  OM_SET_DEFAULT_CUSTOM_VIEW: "OM_SET_DEFAULT_CUSTOM_VIEW", // To set default custom view
  OM_SET_LABEL_UPDATED: "OM_SET_LABEL_UPDATED",
  OM_SET_LOCATION_GROUPS_LIST: "OM_SET_LOCATION_GROUPS_LIST", // update the group list
  OM_SET_LOCATION_GROUPS_SELECTED_ITEMS: "OM_SET_LOCATION_GROUPS_SELECTED_ITEMS", // update the selected items list when item is clicked.
  OM_SET_LOCATION_SORT_QUERY: "OM_SET_LOCATION_SORT_QUERY", // set sort query
  OM_SINGLE_LOCATION_LABEL_DETAILS: "OM_SINGLE_LOCATION_LABEL_DETAILS",
  OM_UPDATE_CUSTOM_VIEW_LOCATION: "OM_UPDATE_CUSTOM_VIEW_LOCATION",
  OM_GET_LOCATION_ASSET_COUNT_BY_ID: "OM_GET_LOCATION_ASSET_COUNT_BY_ID",
  PUT_ALL_LOCATIONS: "PUT_ALL_LOCATIONS",
  PUT_LOCATIONS: "PUT_LOCATIONS",
  PUT_LOCATION_GROUPS: "PUT_LOCATION_GROUPS",
  PUT_LOCATION_GROUPS_LIST: "PUT_LOCATION_GROUPS_LIST",
  PUT_LOCATION_GROUPS_MOVELIST: "PUT_LOCATION_GROUPS_MOVELIST", // use to create a list for move items
  PUT_LOCATIONS_COUNTS: "PUT_LOCATIONS_COUNTS",
  RESET_LOCATION_GROUPS_SELECTION_LIST: "RESET_LOCATION_GROUPS_SELECTION_LIST", // set location group list to empty array.
  RESET_SELECTED_LOCATION_GROUP_ID: "RESET_SELECTED_LOCATION_GROUP_ID", // reset selected location group id to null
  SET_CUSTOM_VIEW_MODIFIED_FLAG: "SET_CUSTOM_VIEW_MODIFIED_FLAG",
  SET_LOCATION_DEPENDENCY: "SET_LOCATION_DEPENDENCY",
  SET_LOCATION_DEPENDENCY_SUCCESS: "SET_LOCATION_DEPENDENCY_SUCCESS",
  SET_LOCATION_FILTER_APPLIED_FIELD: "SET_LOCATION_FILTER_APPLIED_FIELD",
  SET_LOCATION_FILTER_QUERY: "SET_LOCATION_FILTER_QUERY",
  SET_LOCATION_LIST_FIELDS_QUERY: "SET_LOCATION_LIST_FIELDS_QUERY",
  SET_LOCATION_SEARCH_QUERY: "SET_LOCATION_SEARCH_QUERY",
  SET_MARKER_SELECTION: "SET_MARKER_SELECTION",
  SET_SELECTED_LOCATION_GROUP_ID: "SET_SELECTED_LOCATION_GROUP_ID", // update the selected location group id
  SET_SELECTED_VIEW: "SET_SELECTED_VIEW_LOCATION",
  SET_STATES_RESPONSE_NULL: "SET_STATES_RESPONSE_NULL",
  SET_LOCATIONS_COUNTS_LOADER: "SET_LOCATIONS_COUNTS_LOADER",
  UPDATE_LOCATIONS_DETAILS: "UPDATE_LOCATIONS_DETAILS",
  UPDATE_LOCATION_STATUS: "UPDATE_LOCATION_STATUS",
  SET_LOCATIONS_COUNT_LOADER_STATE: "SET_LOCATIONS_COUNT_LOADER_STATE",
};

export default LocationActionConstants;
