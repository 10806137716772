import { ILastSeen, ILastSeenInfo } from "../models/activeTracking/iLastSeenInfo";
import { LastSeenStateEnum, ServiceProviderCode } from "./enums/activeTrackingEnum";
import { HelperLodash } from "am-web-ui-shared/helpers";
import SingletonFeatureManager from "../../featureFlagManager/featureFlagManager";
import { IlastSeenMap } from "../modules/assetTracking/redux/activeTracking/iActiveTrackingState";
import { FEATURES } from "../../featureFlagManager/featureConfig";
import {
  getRedirectionObjectForCharger,
  getRedirectionObjectForHeavyEquipment,
} from "../modules/assetTracking/components/locationDetails/helpers";
import { IGeoLocation, ITrackingPayload } from "../models/map/bleMap/trackingPayloalModel";
import SingletonLicenseContext from "TARGET_BUILD/licenseStrategy/singletonLicenseContext";
import { IGeoJSONFeature } from "../modules/oneMap/model/cluster";
import sum from "lodash/sum";

const SOURCE_GLOBAL = "GLOBAL";
const SOURCE_VAN = "VAN";
const SOURCE_HEAVY_EQUIPMENT = "HEAVY_EQUIPMENT";
const LABEL_HEAVY_EQUIPMENT_GLOBAL = "common:TELEMATICS_TITLE_GLOBAL";
const LABEL_VAN_GATEWAY = "common:TELEMATICS_VAN_GATEWAY";
const LABEL_TELEMATICS_GATEWAY = "common:LABEL_TELEMATICS_GATEWAY";
const LABEL_NURON = "common:LABEL_NURON";
const LABEL_ON_TRACK_APP = "common:LABEL_ON_TRACK_APP";
const LABEL_GEO_TAG = "common:LABEL_GEO_TAG";

export const getRedirectionDetails = (
  serviceProviderCode: string,
  assetId: number,
  trackingPayload: ITrackingPayload | IGeoLocation,
) => {
  if (serviceProviderCode === ServiceProviderCode.Charger) {
    return getRedirectionObjectForCharger(trackingPayload, assetId);
  } else if (
    (serviceProviderCode === ServiceProviderCode.HeavyEquipment || serviceProviderCode === ServiceProviderCode.Van) &&
    (isTrackedByHeavyEquipment(trackingPayload) || isTrackedByVAN(trackingPayload))
  ) {
    return getRedirectionObjectForHeavyEquipment(trackingPayload);
  }
  return { id: null };
};

export const getTrackedBySource = (trackingPayload: ITrackingPayload | IGeoLocation): string => {
  return trackingPayload?.trackedBy?.source;
};

export const isTrackedByGlobal = (trackingPayload: ITrackingPayload | IGeoLocation): boolean => {
  return getTrackedBySource(trackingPayload) === SOURCE_GLOBAL;
};

export const isTrackedByHeavyEquipment = (trackingPayload: ITrackingPayload | IGeoLocation): boolean => {
  return getTrackedBySource(trackingPayload) === SOURCE_HEAVY_EQUIPMENT;
};

export const isTrackedByVAN = (trackingPayload: ITrackingPayload | IGeoLocation): boolean => {
  return getTrackedBySource(trackingPayload) === SOURCE_VAN;
};

export const isVanAsset = (serviceProviderCode: string, trackingPayload: ITrackingPayload | IGeoLocation) => {
  return serviceProviderCode === ServiceProviderCode.Van && !getTrackedBySource(trackingPayload);
};

export const isHeavyAsset = (serviceProviderCode: string, trackingPayload: ITrackingPayload | IGeoLocation) => {
  return serviceProviderCode === ServiceProviderCode.HeavyEquipment && !getTrackedBySource(trackingPayload);
};

export const getSource = (serviceProviderCode: string, trackingPayload: ITrackingPayload | IGeoLocation) => {
  const gatewayLocationName = HelperLodash.get(trackingPayload, "gatewayLocationName", "");
  if (serviceProviderCode === ServiceProviderCode.HeavyEquipment || serviceProviderCode === ServiceProviderCode.Van) {
    if (isTrackedByGlobal(trackingPayload)) {
      return "-";
    } else if (isTrackedByHeavyEquipment(trackingPayload) || isTrackedByVAN(trackingPayload)) {
      return trackingPayload?.trackedBy?.name || "";
    }
  } else if (serviceProviderCode === ServiceProviderCode.Gateway && gatewayLocationName.length > 0) {
    return null;
  } else if (serviceProviderCode === ServiceProviderCode.GeoTag) {
    return LABEL_GEO_TAG;
  }
  return getLocationSource(serviceProviderCode);
};

export const getSourceTrackedByHeavyEquipment = (serviceProviderCode: string, locationDetails) => {
  if (isVanAsset(serviceProviderCode, locationDetails)) {
    return LABEL_VAN_GATEWAY;
  } else if (isHeavyAsset(serviceProviderCode, locationDetails)) {
    return LABEL_TELEMATICS_GATEWAY;
  }
  if (serviceProviderCode === ServiceProviderCode.HeavyEquipment || serviceProviderCode === ServiceProviderCode.Van) {
    if (isTrackedByHeavyEquipment(locationDetails)) {
      return LABEL_TELEMATICS_GATEWAY;
    } else if (isTrackedByVAN(locationDetails)) {
      return LABEL_VAN_GATEWAY;
    } else if (isTrackedByGlobal(locationDetails)) {
      return LABEL_HEAVY_EQUIPMENT_GLOBAL;
    }
  } else if (serviceProviderCode === ServiceProviderCode.Charger) {
    return LABEL_NURON;
  } else if (serviceProviderCode === ServiceProviderCode.Mobile) {
    return LABEL_ON_TRACK_APP;
  } else if (serviceProviderCode === ServiceProviderCode.GeoTag) {
    return LABEL_GEO_TAG;
  }
  return null;
};

/**
 * A function that returns a location source based on ServiceProviderCode enum
 * that we take from the AT GET /assets/{id}/tracking-payloads API
 */
export const getLocationSource = (serviceProviderCode: string) => {
  if (serviceProviderCode === ServiceProviderCode.HeavyEquipment) {
    return "common:LABEL_TELEMATICS_GATEWAY";
  }
  if (serviceProviderCode === ServiceProviderCode.Charger) {
    return "common:LABEL_NURON";
  }
  if (serviceProviderCode === ServiceProviderCode.Gateway) {
    return "common:LABEL_GATEWAY";
  }
  if (serviceProviderCode === ServiceProviderCode.Mobile) {
    return "common:LABEL_ON_TRACK_APP";
  }
  if (serviceProviderCode === ServiceProviderCode.Van) {
    return "common:TELEMATICS_VAN_GATEWAY";
  }
  if (serviceProviderCode === ServiceProviderCode.GeoTag) {
    return "common:LABEL_GEO_TAG";
  }
  return null;
};

/**
 * A function that returns a label for the location source based on ServiceProviderCode enum
 * that we take from the AT GET /assets/{id}/tracking-payloads API
 */
export const getLabelForLocationSource = () => {
  return "common:LABEL_LAST_SEEN_LOCATION_SOURCE";
};

export const appendLastSeenInfo = ({
  response,
  lastSeenMap,
  assetIds,
  responseState,
}: {
  response: any;
  lastSeenMap: IlastSeenMap;
  assetIds: number[];
  responseState: LastSeenStateEnum;
}) => {
  const value = "-";
  (assetIds || []).forEach((assetId) => {
    const item: ILastSeen = HelperLodash.get(lastSeenMap, assetId);

    item.metadata.state = responseState;

    if (responseState === LastSeenStateEnum.SUCCESS) {
      // update last seen info
      const lastSeen: ILastSeenInfo = HelperLodash.find(response || [], { assetId: +assetId }); // from api
      item.lastSeen.display = lastSeen ? lastSeen.display : value;
      item.lastSeen.lastSeen = lastSeen ? lastSeen.lastSeen : value;
      item.lastSeen.serviceProviderCode = lastSeen ? lastSeen.serviceProviderCode : value;
      item.lastSeen.lastSeenBy = lastSeen ? lastSeen.lastSeenBy : value;
    }
  });
  return lastSeenMap;
};

export const shouldFetchLastSeen = (field: string, assetIds: number[]) => {
  if (!SingletonFeatureManager.isFeatureEnabled(FEATURES.ASSETS_ASSET_LIST_LAST_SEEN)) {
    return false;
  }
  return assetIds?.length && (field?.includes("toolLastSeen") || field?.includes("lastSeenBy"));
};

export const isAssetTrackedByVanEnabled = () =>
  SingletonFeatureManager.isFeatureEnabled(FEATURES.ASSET_VAN_TRACKING) &&
  SingletonLicenseContext.licenseContext?.vanTracking()?.isSettingEnabled;

export const isLocationDetailsAddressEnabled = () =>
  SingletonFeatureManager.isFeatureEnabled(FEATURES.PAT_LOCATION_DETAILS_ADDRESS);

export const getClusterKey = (feature: IGeoJSONFeature) => {
  const box = feature?.properties?.clusterInfo?.boundingBox || [0];
  const assetIds = feature?.properties?.clusterInfo?.assetIds || [];
  return sum(box) + sum(assetIds) + feature?.properties?.clusterInfo?.count;
};
