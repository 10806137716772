export default {
  black: {
    50: '#FAFAFA',
    100: '#E5E5E5',
    200: '#CCCCCC',
    300: '#B2B2B2',
    400: '#333333',
    500: '#191919',
    600: '#000000',
  },
  concrete: {
    10: '#EEEBE4',
    50: '#F3F2F0',
    100: '#E7E5E2',
    200: '#D8D8D3',
    300: '#CFCCC5',
    400: '#D6CEBD',
    500: '#B7B3AA',
    600: '#A0998B',
    700: '#887F6E',
  },
  hilti: {
    50: '#D2051E',
    100: '#AB0115',
    200: '#C30000',
    300: '#D0021B',
    400: '#FF0000',
  },
  steel: {
    50: '#DDDCDD',
    100: '#CBCACB',
    200: '#BAB9BA',
    300: '#989598',
    400: '#757275',
    500: '#636064',
    600: '#524F53',
    700: '#2D2C2D',
  },
  green: {
    50: '#D8F4DF',
    100: '#A3DFAF',
    200: '#82BE00',
    300: '#19AF37',
    400: '#1FAC3A',
    500: '#15962F',
    600: '#00804A',
    700: '#117A26',
    800: '#00877D',
  },
  grey: {
    50: '#F8F8F8',
    100: '#F1F1F1',
    200: '#D8D8D8',
    300: '#D7CEBE',
    400: '#D8CFBF',
    500: '#BaB9B2',
    600: '#BFBDBF',
    700: '#AAA7AB',
    800: '#979598',
    900: '#534f53',
  },
  blue: {
    50: '#E0F0F9',
    100: '#4196CD',
    200: '#2F6D96',
    300: '#005F96',
    400: '#4385f4',
  },
  pink: {
    50: '#FFD7D8',
    100: '#61223F',
    200: '#671C3E',
  },
  orange: {
    50: '#D7CEBE',
    100: '#E97300',
  },
  yellow: {
    50: '#906300',
  },
  tarawera: {
    50: '#053B4E',
  },
  brown: {
    50: '#F7F5F2',
    100: '#EFEBE5',
    200: '#E7E2DB',
    300: '#DFD8CA',
    400: '#D7CEBD',
    500: '#FFF3D6',
  },
  white: {
    50: '#F3F2F0',
    100: '#F5F5F5',
    200: '#FFFFFF',
  },
  shadowBox: {
    50: 'rgba(0, 0, 0, 0.3)',
  },
};
