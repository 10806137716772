import React from "react";
const SVG = () => (
  <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3238.000000, -3349.000000)" fill="#524F53">
        <g transform="translate(3236.000000, 3345.000000)">
          <path
            fill="#524F53"
            fill-rule="evenodd"
            d="M21 4v16H3V4h18zm-11 8l-5 6h14l-4-5-2 2-3-3zm6.5-5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
