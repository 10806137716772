/**
 * @description - List of actions will be used for field request module.
 */

const FieldRequestConstants = {
  AM_GET_FIELD_REQUESTS_COUNT: "AM_GET_FIELD_REQUESTS_COUNT",
  GET_FIELD_REQUEST_COUNT_SUCCESS: "GET_FIELD_REQUEST_COUNT_SUCCESS",
  AM_GET_FIELD_REQUESTS_LIST: "AM_GET_FIELD_REQUESTS_LIST",
  GET_FIELD_REQUEST_LIST_SUCCESS: "GET_FIELD_REQUEST_LIST_SUCCESS",
  AM_GET_FIELD_REQUEST_DETAILS: "AM_GET_FIELD_REQUEST_DETAILS",
  GET_FIELD_REQUEST_DETAILS_SUCCESS: "GET_FIELD_REQUEST_DETAILS_SUCCESS",
  RESET_FIELD_REQUEST_DETAILS: "RESET_FIELD_REQUEST_DETAILS",
  UPDATE_FIELD_REQUEST: "UPDATE_FIELD_REQUEST",
  OM_GET_FIELD_REQUEST_SETTINGS: "OM_GET_FIELD_REQUEST_SETTINGS",
  GET_FIELD_REQUEST_SETTINGS_SUCCESS: "GET_FIELD_REQUEST_SETTINGS_SUCCESS",
  OM_UPDATE_FIELD_REQUEST_SETTINGS: "OM_UPDATE_FIELD_REQUEST_SETTINGS",
  UPDATE_FIELD_REQUEST_SETTINGS_SUCCESS: "UPDATE_FIELD_REQUEST_SETTINGS_SUCCESS",
  SET_FIELD_REQUEST_FILTER_QUERY: "SET_FIELD_REQUEST_FILTER_QUERY",
  SET_FIELD_REQUEST_FILTER_APPLIED_FIELD: "SET_FIELD_REQUEST_FILTER_APPLIED_FIELD",
  SET_FIELD_REQUEST_SEARCH_QUERY: "SET_FIELD_REQUEST_SEARCH_QUERY",
};

export default FieldRequestConstants;
