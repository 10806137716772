import { HelperLodash } from "am-web-ui-shared/helpers";
import { IGetQueryParams } from "../models/iGetQueryParams";
import { getPageSize } from "../utils/apiUtils";
import { filtersQuery, getFormattedFilter } from "../utils/commonUtils";
import ModuleName from "../utils/enums/moduleNameEnum";
const PAGE_SIZE = getPageSize();
/**
 * @description prepares query object for the GET of list calls
 */
export const getQueryObj = (
  state,
  offset,
  moduleName,
  limit = null,
  appliedFilterQueryFieldName: string = "appliedFilterQuery",
): IGetQueryParams => {
  const queryObj: IGetQueryParams = {
    limit: limit ? limit : PAGE_SIZE,
    offset,
  };
  const modelState = HelperLodash.get(state, moduleName);
  if (modelState) {
    const sortFields = modelState.sortQuery;
    if (sortFields && sortFields.sortType && sortFields.sortField) {
      queryObj.order_by = sortFields.sortType + sortFields.sortField;
    }
    getFilterQuery(modelState, queryObj, appliedFilterQueryFieldName);
    if (modelState.searchString) {
      if (moduleName === ModuleName.Assets || moduleName === ModuleName.QtyItems) {
        queryObj.q = modelState.searchString.length > 1 ? modelState.searchString : "";
      } else {
        queryObj.q = modelState.searchString;
      }
      if (moduleName === "IdleAssets") {
        queryObj.searchFields =
          "manufacturerName,model,currentLocationName,responsibleEmployeeName,scanCode,assetStatus,groupName,monitoringState";
      }
    }
    if (modelState.fields) {
      queryObj.fields = modelState.fields.join();
    }
  }
  return queryObj;
};

export const getQueryWithoutPagination = (state, moduleName) => {
  const queryObj: any = {};
  const modelState = HelperLodash.get(state, moduleName);
  if (modelState) {
    getFilterQuery(modelState, queryObj, "appliedFilterQuery");
    if (modelState.searchString) {
      queryObj.q = modelState.searchString;
    }
    if (modelState.fields) {
      queryObj.fields = modelState.fields.join();
    }
    queryObj.view = "map";
  }
  return queryObj;
};

export const getChildAssetQuery = (state, queryObj, moduleName) => {
  const moduleState = HelperLodash.get(state, moduleName);
  if (moduleState) {
    const sortQuery = moduleState.sortQuery;
    const fields = state.Assets.fields;
    return {
      ...queryObj,
      fields: fields.concat(["path"]).join(),
      order_by: `${sortQuery.sortType}${sortQuery.sortField}`,
    };
  }
  return queryObj;
};

/**
 * @description prepares query object for the GET of list calls
 */
export const getFilterQueryObj = (state, moduleName, payload?: any, useAppliedFilterQuery?: boolean): string => {
  const modelState = HelperLodash.get(state, moduleName);
  const { filterQuery, appliedFilterQuery, currentSelectedFilter, replaceFilterQuery } = modelState || {};
  const query = useAppliedFilterQuery ? appliedFilterQuery : filterQuery;
  return filtersQuery(query, currentSelectedFilter, replaceFilterQuery, payload, true);
};

export const getQueryObjForMultiPayload = (state, moduleName) => {
  const modelState = HelperLodash.get(state, moduleName);
  const defaultQuery = getQueryObj(state, 0, moduleName);
  const { filters, view } = getFormattedFilter(defaultQuery.filter && defaultQuery.filter.split("&"));
  return {
    filters,
    isSelectAll: modelState.isAllSelected,
    query: defaultQuery.q,
    view,
  };
};
const getFilterQuery = (modelState, queryObj, appliedFilterQueryFieldName) => {
  const modelStateFilterField = HelperLodash.get(modelState, appliedFilterQueryFieldName);

  if (modelStateFilterField) {
    queryObj.filter = filtersQuery(
      modelStateFilterField,
      modelState.currentSelectedFilter,
      modelState.replaceFilterQuery,
    );
  }
};
