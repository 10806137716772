import React from "react";
import sharedConstants from "../../config";
import DateRangePickerForm from "../../elements/datePicker/dateRangePickerForm";
import { get } from "../../helpers/helperLodash";
import CheckboxGroup from "./controls/checkboxGroup/checkboxGroup";
import DataRangeWithLabel from "./controls/dataRange/dataRangeContainer";
import IdleRangeWithLabel from "./controls/dataRange/idleRangeContainer";
import MultiSelectDropdownContainer from "./controls/multiSelectDropdown/multiSelectDropdownContainer";
import RadioGroup from "./controls/radioGroup/radioGroup";
import { ReadOnlyFilter } from "./controls/readOnlyFilter/readOnlyFilter";
import ReadOnlyMoreFilter from "./controls/readOnlyFilter/readOnlyMoreFilter";
import ControlType from "./controlType";
import { getFilterName, separateCheckedAndUncheckedOptions } from "./filterUtils";
import { IGridFilter, RenderLocation } from "./iGridFilter";
import { WithTranslation } from "react-i18next";

export interface IFilterRendererProps extends WithTranslation {
  readonly mode: RenderLocation;
  readonly onChange: (updatedOptions: any, filter: any) => void;
  readonly onSubmit?: (filters: any) => void;
  readonly filterFormValues?: any;
  readonly savedOptions: any;
  readonly getCount: (values: any, filter: IGridFilter, mode: any) => number;
  readonly getValues: (
    values: any,
    ctrlType: ControlType,
    nullTextDisplay: any,
    filterSetting: any,
    isAppliedValues?: boolean,
  ) => any;
  readonly filterSettings: IGridFilter[];
  readonly filterAttributes: any;
  readonly filterAttributesCount?: any;
  readonly filterAttributesSearchCount?: any;
  readonly dependencies?: any;
  readonly updateHeight?: () => void;
  readonly filterContainerRef: any;
  readonly displayLoader?: boolean;
  readonly appliedFilterQuery?: any;
  readonly handleShowMore?: any;
  readonly getFilterAttributes?: any;
  readonly changeFilterFormValues?: (field: string, value: string) => void;
  readonly datePickerLocale?: string;
  readonly dateFormat?: string;
}

interface IOption {
  label: string;
  value: string;
}

interface IOptionsAndAppliedOptions {
  appliedOptions: IOption[];
  options: IOption[];
}

const NO_VALUES_AVAILABLE = "filters:NO_VALUES_AVAILABLE";

export class FilterRenderer extends React.PureComponent<IFilterRendererProps> {
  private readonly noValueAvailable = NO_VALUES_AVAILABLE;
  private readonly componentMapping = {};

  constructor(props) {
    super(props);
    this.componentMapping[ControlType.MultiSelectDropdown] = this.getMultiSelectDropdown;
    this.componentMapping[ControlType.CheckboxGroup] = this.getCheckboxGroup;
    this.componentMapping[ControlType.HierarchicalDropdown] = this.getHierarchicalDropdown;
    this.componentMapping[ControlType.RadioGroup] = this.getRadioGroup;
    this.componentMapping[ControlType.RadioSelectDropdown] = this.getRadioSelectDropdown;
    this.componentMapping[ControlType.DataRange] = this.getDataRangeControl;
    this.componentMapping[ControlType.IdleRange] = this.getIdleRangeControl;
    this.componentMapping[ControlType.DataRangeDropdown] = this.getDataRangeDropdown;
    this.componentMapping[ControlType.DateRangeDropdown] = this.getDateRangeDropdown;
    this.componentMapping[ControlType.DateRange] = this.getDateRangeControl;
    this.componentMapping[ControlType.ReadOnlyFilter] = this.readOnlyFilter;
    this.componentMapping[ControlType.ReadOnlyMoreFilter] = this.ReadOnlyMoreFilter;
  }

  clearFilterLabel = () => this.props.t("filters:CLEAR");

  getMultiSelectDropdown = (filter, values, count, searchCount, appliedOptions) => {
    const {
      displayLoader,
      onChange,
      onSubmit,
      filterFormValues,
      savedOptions,
      t,
      handleShowMore,
      mode,
      tReady,
      i18n,
      getFilterAttributes,
      changeFilterFormValues,
    } = this.props;
    const updatedOptionsList: IOptionsAndAppliedOptions = separateCheckedAndUncheckedOptions(values, appliedOptions);
    const { options: calculatedOptions, appliedOptions: calculatedAppliedOptions } = updatedOptionsList;
    return (
      <MultiSelectDropdownContainer
        filter={filter}
        mode={mode}
        key={`${filter.filterName}Filter`}
        options={[...calculatedAppliedOptions, ...calculatedOptions]}
        optionsCount={count}
        searchCount={searchCount}
        appliedOptions={appliedOptions}
        id={`${filter.filterName}Filter`}
        name={getFilterName(filter.filterName, filter.operator)}
        valueId="value"
        valueKey="label"
        placeholder={t(filter.filterDisplayName)}
        isFormControl={true}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(opts) => onChange(opts, filter)}
        onSubmit={onSubmit}
        selectedOptions={savedOptions[filter.filterName] || []}
        type={ControlType.MultiSelectDropdown}
        noValuesLabel={t(this.noValueAvailable)}
        displayLoader={displayLoader}
        t={t}
        tReady={tReady}
        i18n={i18n}
        handleShowMore={handleShowMore}
        getFilterAttributes={getFilterAttributes}
        changeFilterFormValues={changeFilterFormValues}
        filterFormValues={filterFormValues}
      />
    );
  };

  getCheckboxGroup = (filter, values, count, searchCount, appliedOptions) => {
    const {
      onChange,
      savedOptions,
      t,
      handleShowMore,
      mode,
      getFilterAttributes,
      changeFilterFormValues,
      onSubmit,
      filterFormValues,
    } = this.props;
    return (
      <CheckboxGroup
        filter={filter}
        id={`${filter.filterName}Filter`}
        key={`${filter.filterName}Filter`}
        options={values}
        optionsCount={count}
        searchCount={searchCount}
        appliedOptions={appliedOptions}
        name={getFilterName(filter.filterName, filter.operator)}
        valueId="value"
        valueKey="label"
        header={t(filter.filterDisplayName)}
        isFormControl={true}
        onSelectionChange={(opts) => onChange(opts, filter)}
        selectedOptions={savedOptions[filter.filterName] || []}
        defaultDisplayCount={count ? sharedConstants.MAXIMUM_NUMBER_OF_DISPLAYED_VALUES : values && values.length}
        showMoreButtonText={t("filters:SHOW_MORE")}
        showLessButtonText={t("filters:SHOW_LESS")}
        clearButtonText={this.clearFilterLabel()}
        showSearch={count > sharedConstants.MAXIMUM_NUMBER_OF_DISPLAYED_VALUES}
        noValuesLabel={t(this.noValueAvailable)}
        t={this.props.t}
        tReady={this.props.tReady}
        i18n={this.props.i18n}
        handleShowMore={handleShowMore}
        getFilterAttributes={getFilterAttributes}
        changeFilterFormValues={changeFilterFormValues}
        mode={mode}
        onSubmit={onSubmit}
        filterFormValues={filterFormValues}
        shouldHaveApplyButton={true}
      />
    );
  };

  getHierarchicalDropdown = (filter) => {
    const { displayLoader, onChange, savedOptions, t, changeFilterFormValues, onSubmit, filterFormValues } = this.props;
    const optionsArr =
      this.props.dependencies[filter.filterName].isPaging && savedOptions[filter.filterName]
        ? this.props.dependencies[filter.filterName].entityGroupsList &&
          this.props.dependencies[filter.filterName].entityGroupsList.map((list) => {
            return list.listRes;
          })
        : this.props.dependencies[filter.filterName].entityGroupsList;
    return (
      <MultiSelectDropdownContainer
        filter={filter}
        key={`${filter.filterName}Filter`}
        options={optionsArr}
        id={`${filter.filterName}Filter`}
        name={getFilterName(filter.filterName, filter.operator)}
        valueId="name"
        valueKey="label"
        placeholder={t(filter.filterDisplayName)}
        isFormControl={true}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(opts) => onChange(opts, filter)}
        selectedOptions={savedOptions[filter.filterName]}
        hierarchyControlProps={this.props.dependencies[filter.filterName]}
        type={ControlType.HierarchicalDropdown}
        noValuesLabel={t(this.noValueAvailable)}
        displayProperty={filter.displayProperty}
        filterContainerRef={this.props.filterContainerRef}
        displayLoader={displayLoader}
        t={this.props.t}
        tReady={this.props.tReady}
        i18n={this.props.i18n}
        changeFilterFormValues={changeFilterFormValues}
        onSubmit={onSubmit}
        filterFormValues={filterFormValues}
      />
    );
  };

  getRadioGroup = (filter, values) => {
    const { onChange, savedOptions, t } = this.props;
    return (
      <RadioGroup
        id={`${filter.filterName}Filter`}
        key={`${filter.filterName}Filter`}
        options={values}
        name={getFilterName(filter.filterName, filter.operator)}
        valueId="value"
        valueKey="label"
        header={t(filter.filterDisplayName)}
        isFormControl={true}
        onSelectionChange={(opts) => onChange(opts, filter)}
        selectedOptions={savedOptions[filter.filterName]}
        clearButtonText={this.clearFilterLabel()}
        updateHeight={this.props.updateHeight}
        noValuesLabel={t(this.noValueAvailable)}
        showClearButton={!filter.isDefault && !filter.isUnclearable}
        t={this.props.t}
        tReady={this.props.tReady}
        i18n={this.props.i18n}
      />
    );
  };

  getRadioSelectDropdown = (filter, values, _count, _searchCount, appliedOptions) => {
    const { displayLoader, onChange, savedOptions, t, changeFilterFormValues, onSubmit, filterFormValues } = this.props;
    return (
      <MultiSelectDropdownContainer
        filter={filter}
        key={`${filter.filterName}Filter`}
        options={values}
        id={`${filter.filterName}Filter`}
        name={getFilterName(filter.filterName, filter.operator)}
        valueId="value"
        valueKey="label"
        placeholder={t(filter.filterDisplayName)}
        isFormControl={true}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(opts) => onChange(opts, filter)}
        selectedOptions={savedOptions[filter.filterName]}
        type={ControlType.RadioSelectDropdown}
        showClearButton={!filter.isDefault && !filter.isUnclearable}
        noValuesLabel={t(this.noValueAvailable)}
        displayLoader={displayLoader}
        t={this.props.t}
        tReady={this.props.tReady}
        i18n={this.props.i18n}
        changeFilterFormValues={changeFilterFormValues}
        onSubmit={onSubmit}
        filterFormValues={filterFormValues}
        appliedOptions={appliedOptions}
      />
    );
  };

  getIdleRangeControl = (filter, values) => {
    const { mode, onChange, savedOptions, t } = this.props;
    const defaultRangeControlProps = { renderCustomComponent: false };
    const rangeControlProps = filter.hasDependency
      ? this.props.dependencies[filter.filterName].rangeControlProps || defaultRangeControlProps
      : defaultRangeControlProps;
    return (
      <IdleRangeWithLabel
        filter={filter}
        key={`${filter.filterName}Filter`}
        id={`${filter.filterName}Filter`}
        name={getFilterName(filter.filterName, filter.operator)}
        placeholder={t(filter.filterDisplayName)}
        isFormControl={true}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(opts) => onChange(opts, filter)}
        rangeData={values && values.length > 0 ? values[0] : null}
        selectedOptions={savedOptions[filter.filterName]}
        minLabel={t("filters:MINIMUM")}
        maxLabel={t("filters:MAXIMUM")}
        count={this.props.getCount(values, filter, mode)}
        noValuesLabel={t(this.noValueAvailable)}
        rangeControlProps={rangeControlProps}
        showMaximumInput={true}
      />
    );
  };

  getDataRangeControl = (filter, values) => {
    const { mode, onChange, savedOptions, t } = this.props;
    const defaultRangeControlProps = { renderCustomComponent: false };
    const rangeControlProps = filter.hasDependency
      ? this.props.dependencies[filter.filterName].rangeControlProps || defaultRangeControlProps
      : defaultRangeControlProps;
    return (
      <DataRangeWithLabel
        filter={filter}
        key={`${filter.filterName}Filter`}
        id={`${filter.filterName}Filter`}
        name={getFilterName(filter.filterName, filter.operator)}
        placeholder={t(filter.filterDisplayName)}
        isFormControl={true}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(opts) => onChange(opts, filter)}
        rangeData={values && values.length > 0 ? values[0] : null}
        selectedOptions={savedOptions[filter.filterName]}
        minLabel={t("filters:MINIMUM")}
        maxLabel={t("filters:MAXIMUM")}
        count={this.props.getCount(values, filter, mode)}
        noValuesLabel={t(this.noValueAvailable)}
        rangeControlProps={rangeControlProps}
      />
    );
  };

  getDataRangeDropdown = (filter, values) => {
    const { displayLoader, mode, onChange, savedOptions, t, changeFilterFormValues, onSubmit, filterFormValues } =
      this.props;
    const count = this.props.getCount(values, filter, mode);
    const defaultRangeControlProps = { renderCustomComponent: false };
    const rangeControlProps = filter.hasDependency
      ? this.props.dependencies[filter.filterName].rangeControlProps || defaultRangeControlProps
      : defaultRangeControlProps;
    return (
      <MultiSelectDropdownContainer
        filter={filter}
        key={`${filter.filterName}DDFilter`}
        id={`${filter.filterName}DDFilter`}
        name={getFilterName(filter.filterName, filter.operator)}
        placeholder={t(filter.filterDisplayName)}
        isFormControl={true}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(opts) => onChange(opts, filter)}
        rangeData={values && values.length > 0 ? values[0] : null}
        selectedOptions={count > 0 ? savedOptions[filter.filterName] : null}
        options={[]}
        valueId="min"
        valueKey="max"
        type={ControlType.DataRangeDropdown}
        count={count > 0 ? 1 : count}
        noValuesLabel={t(this.noValueAvailable)}
        displayLoader={displayLoader}
        t={this.props.t}
        tReady={this.props.tReady}
        i18n={this.props.i18n}
        changeFilterFormValues={changeFilterFormValues}
        onSubmit={onSubmit}
        filterFormValues={filterFormValues}
        rangeControlProps={rangeControlProps}
      />
    );
  };

  getDateRangeDropdown = (filter, values) => {
    const { displayLoader, mode, onChange, savedOptions, t, changeFilterFormValues, onSubmit, filterFormValues } =
      this.props;
    const savedDateOptions = savedOptions[filter.filterName];
    const dateFilter = [
      {
        endDate: savedDateOptions && savedDateOptions.endDate,
        startDate: savedDateOptions && savedDateOptions.startDate,
      },
    ];
    return (
      <MultiSelectDropdownContainer
        filter={filter}
        key={`${filter.filterName}DateRangeFilter`}
        id={`${filter.filterName}DateRangeFilter`}
        name={getFilterName(filter.filterName, filter.operator)}
        placeholder={t(filter.filterDisplayName)}
        isFormControl={true}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(selectedValue, startDate, endDate) =>
          onChange(
            {
              endDate,
              selectedValue,
              startDate,
            },
            filter,
          )
        }
        selectedOptions={savedDateOptions && dateFilter}
        options={values}
        valueId="minDate"
        valueKey="maxDate"
        type={ControlType.DateRange}
        count={this.props.getCount(values, filter, mode)}
        noValuesLabel={t(this.noValueAvailable)}
        displayLoader={displayLoader}
        t={this.props.t}
        tReady={this.props.tReady}
        i18n={this.props.i18n}
        datePickerLocale={this.props.datePickerLocale}
        changeFilterFormValues={changeFilterFormValues}
        onSubmit={onSubmit}
        filterFormValues={filterFormValues}
        dateFormat={this.props.dateFormat}
      />
    );
  };

  getDateRangeControl = (filter, values) => {
    const { onChange, t } = this.props;
    return (
      <DateRangePickerForm
        name={getFilterName(filter.filterName, filter.operator)}
        minDate={values.length > 0 && values[0].minDate}
        maxDate={values.length > 0 && values[0].maxDate}
        label={t(filter.filterDisplayName)}
        clearButtonText={this.clearFilterLabel()}
        onSelectionChange={(selectedValue, startDate, endDate) =>
          onChange(
            selectedValue && {
              endDate,
              selectedValue,
              startDate,
            },
            filter,
          )
        }
        placeholder={this.props.t("filters:SELECT_DATE_PLACEHOLDER")}
        startDateLabel={this.props.t("filters:START_DATE")}
        endDateLabel={this.props.t("filters:END_DATE")}
        startDate={this.props.savedOptions[filter.filterName] && this.props.savedOptions[filter.filterName].startDate}
        endDate={this.props.savedOptions[filter.filterName] && this.props.savedOptions[filter.filterName].endDate}
        locale={this.props.datePickerLocale}
        dateFormat={this.props.dateFormat}
      />
    );
  };
  readOnlyFilter = (filter, _values) => {
    const { onChange, t, savedOptions, filterAttributes } = this.props;
    const { filterName, operator, filterDisplayName, toolTip } = filter;

    const savedFilterOption = get(savedOptions, filterName, []);

    if (savedFilterOption?.length > 0) {
      const selectedOptions = [];
      savedFilterOption.forEach((option) => {
        const initialFilterValues = get(filterAttributes, filterName, []);
        const filterOptionObject = initialFilterValues.find((obj) =>
          typeof obj === "string" ? obj === option : obj?.code === option,
        );
        if (filterOptionObject?.value) {
          selectedOptions.push(filterOptionObject.value);
        } else {
          selectedOptions.push(option);
        }
      });
      return (
        <ReadOnlyFilter
          key={`${filterName}readOnlyFilter`}
          id={`${filterName}readOnlyFilter`}
          name={getFilterName(filterName, operator)}
          header={t(filterDisplayName)}
          toolTip={t(toolTip)}
          onSelectionChange={(opts) => onChange(opts, filter)}
          selectedOptions={selectedOptions}
        />
      );
    }
    return null;
  };
  ReadOnlyMoreFilter = (filter, _value) => {
    const { onChange, t, savedOptions } = this.props;
    return savedOptions[filter.filterName] && savedOptions[filter.filterName].length > 0 ? (
      <ReadOnlyMoreFilter
        key={`${filter.filterName}readOnlyFilter`}
        id={`${filter.filterName}readOnlyFilter`}
        name={getFilterName(filter.filterName, filter.operator)}
        header={t(filter.filterDisplayName)}
        toolTip={t(filter.toolTip)}
        onSelectionChange={(opts) => onChange(opts, filter)}
        selectedOptions={savedOptions[filter.filterName]}
        clearButtonText={this.clearFilterLabel()}
      />
    ) : null;
  };

  getFilterCountObj = (filterAttributesCount: any[] = []) => {
    const filterCountObj = {};
    filterAttributesCount.forEach((item) => {
      const keys = Object.keys(item);
      filterCountObj[keys[0]] = item[keys[0]];
    });
    return filterCountObj;
  };

  render() {
    const {
      getValues,
      filterSettings,
      filterAttributes,
      filterAttributesCount,
      filterAttributesSearchCount,
      mode,
      appliedFilterQuery,
    } = this.props;
    const ctrls = filterSettings.map((filter) => {
      const filterCountObj = this.getFilterCountObj(filterAttributesCount);
      const count = filterCountObj && filterCountObj[filter.filterName];
      const searchCount =
        filterAttributesSearchCount &&
        filterAttributesSearchCount.length > 0 &&
        filterAttributesSearchCount[0][filter.filterName];
      let values = (filterAttributes && filterAttributes[filter.filterName]) || [];
      values = getValues(values, filter.type[mode], filter.nullTextDisplay, filter);

      // get all applied values
      const appliedFilterObj =
        (appliedFilterQuery &&
          appliedFilterQuery.find((appliedFilter) => appliedFilter.filterName === filter.filterName)) ||
        {};

      let foundObject = null;

      if (appliedFilterObj.filterValue && appliedFilterObj.filterValue.length === 1) {
        const codeToFind = appliedFilterObj.filterValue[0];
        const foundAttribute = (filterAttributes && filterAttributes[appliedFilterObj.filterName]) || [];
        foundObject = foundAttribute.find((item) => item && item.code === codeToFind);
      }

      let valuesToGet;
      if (foundObject) {
        valuesToGet = [foundObject];
      } else if (
        filterAttributes &&
        appliedFilterObj.supportShortName && // short values like OPR, should be picked from API e.g. Operational
        filterAttributes[appliedFilterObj.filterName] &&
        filterAttributes[appliedFilterObj.filterName].length === 1
      ) {
        valuesToGet = filterAttributes[appliedFilterObj.filterName];
      } else {
        valuesToGet = appliedFilterObj.filterValue;
      }
      const appliedValues = getValues(valuesToGet || [], filter.type[mode], filter.nullTextDisplay, filter, true);

      const filterType = get(filter.type, mode);
      return get(this.componentMapping, filterType)(filter, values, count, searchCount, appliedValues);
    });

    return ctrls.filter((ctrl) => !!ctrl);
  }
}
