import { SquareIcon } from "am-web-ui-shared/components";
import React from "react";
import styled from "styled-components";
import StyledBoldText from "../../Styled/BoldText/styledBoldText";
import StyledBeforeIconContainer from "../../Styled/Containers/BeforeIconContainer/styledBeforeIconContainer";
import StyledNormalText from "../../Styled/NormalText/styledNormalText";
import { StyledHeadingCount } from "../StyleHeadingPanel/styleHeadingPanel";
import IIconBoldNormalProps from "./iIconBoldNormal";

/**
 * @const IconBoldNormal is a Styled Component that consist of 3 things:
 * 1. Icon: An Icon that will be visible at the left side of the content (::before).
 * 2. Bold Text: This is the text with bold font-family and it is the bottom row.
 * 3. normal Text: This is the text with roman font-family and it is the top row.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 */
/**
 * @const StyledBoldText - Styling the Expansion Panel to fixed width of 380px
 */
const StyledBold = styled(StyledBoldText as any)`
  && {
    color: ${(props) => props.theme.colors.headingText.color};
  }
`;

const StyledContainer = styled(StyledBeforeIconContainer as any)`
  && {
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
    padding: 12px 16px;
  }
`;

const StyledBoldColor = styled(StyledBoldText)`
  && {
    color: ${(props) => props.theme.colors.headingText.color};
  }
`;

/**
 * This function is used to styled countText according to its value
 */
const renderStyledHeadingCount = (countText) => {
  return <StyledHeadingCount countText={countText}>{countText}</StyledHeadingCount>;
};

const IconBoldNormal = (props: IIconBoldNormalProps) => {
  const { id, iconUrl, boldId, boldText, countText, normalId, normalText, type } = props;
  return (
    <StyledContainer id={id}>
      <SquareIcon name={iconUrl} />
      {type === "innerText" ? (
        <StyledBoldColor id={boldId}>
          {boldText}
          {renderStyledHeadingCount(countText)}
        </StyledBoldColor>
      ) : (
        <StyledBold id={boldId}>
          {boldText}
          {renderStyledHeadingCount(countText)}
        </StyledBold>
      )}
      <StyledNormalText id={normalId}>{normalText}</StyledNormalText>
    </StyledContainer>
  );
};

/**
 * @export IconBoldNormal - Styled Component.
 */
export default IconBoldNormal;
