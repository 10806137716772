import React from "react";
import styled from "styled-components";

const SearchLabelDiv = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
  min-width: 51px;
  margin-right: 16px;
`;

export default (props: { searchLabel: string }) => <SearchLabelDiv>{props.searchLabel}</SearchLabelDiv>;
