import React, { lazy } from "react";

export const AddAssetContainerWrapper = lazy(
  () => import("../../../addAsset/addAssetWrapper/addAssetContainerWrapper"),
);
export const AssetMultiEditContainer = lazy(() => import("../../../multiEdit/assetMultiEditDialogContainer"));
export const AssetQuickTransferContainer = lazy(() => import("../../../quickTransfer/assetQuickTransferContainer"));
export const DeleteAction = lazy(() => import("../../../../components/actionItems/deleteAction"));
export const StatusAction = lazy(() => import("../../../../components/actionItems/statusAction"));
export const AssetLabels = lazy(() => import("../../../actionItems/labels/assetLabels"));
export const ImportAssets = lazy(() => import("../../../importAssets/importAssets"));
export const ImportCompleteServices = lazy(() => import("../../../importAssets/importCompleteServices"));
export const GridSettingModal = lazy(() => import("../../../../../../components/gridSetting/gridSettingModal"));
export const DiscardDialog = lazy(() => import("../../../../../../components/discardDialog/discardDialog"));
export const HiltiServicesWrapper = lazy(() => import(".././hiltiServicesWrapper"));
