import { AssetActions } from "TARGET_BUILD/common/actionConstants";
import { ToastrType } from "../../../actions/toastrAction";
import { ModuleName } from "../../../utils/enums/moduleNameEnum";
import { getPageSize } from "../../../utils/apiUtils";
import AlertNotificationConstant from "TARGET_BUILD/common/actionConstants/alertNotificationConstant";
import { ViewType } from "TARGET_BUILD/common/utils/enums/viewTypeEnum";

export const fetchAssetsList = (offsetVal?: number, id?: number, isSelectFirstRow = false) => ({
  id,
  isSelectFirstRow,
  offset: offsetVal || 0,
  type: AssetActions.GET_ASSET_LIST,
});

/**
 * @description This method is used to get the assets to transfer in transfer cart
 *
 * @param offset
 * @param limit
 */
export const fetchAllSelectedAssets = (offset: number, limit: number) => ({
  limit,
  offset,
  type: AssetActions.GET_SELECT_ALL_ASSETS,
});

/**
 * @description Function to get the asset list with searched value.
 */
export const assetsListForSearch = () => ({
  offset: 0,
  type: AssetActions.GET_ASSET_LIST_FOR_SEARCH,
});

export const refreshAssetsList = (assetId: any, isRepeat = false) => ({
  assetId,
  isRepeat,
  type: AssetActions.REFRESH_ASSETS,
});

export const fetchAssetDetails = (assetId, disableLoader = false) => ({
  assetId,
  disableLoader,
  type: AssetActions.AM_ASSET_DETAILS,
});

export const closeAssetDetails = () => ({
  type: "CLOSE_ASSET_DETAILS",
});
export const deleteAssets = (assetIds, assetsData, viewType: ViewType) => ({
  assetIds,
  assetsData,
  viewType,
  type: "DELETE_ASSETS",
});
export const setSelectedRowId = (rowId) => ({
  rowId,
  type: AssetActions.AM_ASSETS_SET_SELECTED_ROW,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param sortQuery
 */
export const setSortQuery = (sortQuery) => ({
  sortQuery,
  type: AssetActions.SET_ASSET_SORT_QUERY,
});

/**
 * @description Function to dispatch the method to set search value in the store
 * @param searchQuery
 */
export const setSearchQuery = (searchQuery) => ({
  searchQuery,
  type: AssetActions.SET_ASSET_SEARCH_QUERY,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param filterQuery
 */
export const setFilterQuery = (filterQuery) => ({
  currentSelectedFilter: filterQuery.currentSelectedFilter,
  filterQuery: filterQuery.filters,
  replaceFilterQuery: filterQuery.replaceQuery,
  type: AssetActions.SET_ASSET_FILTER_QUERY,
});

/**
 * @description Function to dispatch the method to reset query params to default
 */
export const resetQueryObjToDefault = () => ({
  type: AssetActions.AM_ASSET_RESET_QUERY_PARAM,
});
/**
 * updateUniqueAssetStatus is a Action used to update the status of asset
 * @param checkedListInfo
 * @param assetStatus status that need to update
 * @param toastrMessage use to show a message after asset status updated
 */
export const updateUniqueAssetStatus = (checkedListInfo, assetStatus, toastrMessage) => ({
  payload: {
    checkedListInfo,
    assetStatus,
    toastrMessage,
    toastrType: ToastrType.success,
  },
  type: AssetActions.AM_UPDATE_ASSET_STATUS,
});

export const getCommonAttributes = (checkedListInfo, t) => ({
  payload: {
    t,
    checkedListInfo,
  },
  type: AssetActions.AM_ASSET_COMMON_ATTRIBUTES,
});

export const getAssetFilterAttributes = (delay = true, customAttribute?: string, query?: string) => ({
  delay,
  payload: {
    customAttribute,
    query,
  },
  type: AssetActions.AM_GET_ASSET_FILTER_FIELD,
});

export const setAssetFilterAppliedAttributes = (filterQuery) => ({
  payload: {
    filterQuery,
  },
  type: AssetActions.AM_SET_ASSET_FILTER_APPLIED_FIELD,
});

/**
 * @description dispatcher to set the list fields value in the store.
 * @param {string} fields - Comma seperated string of fields.
 */
export const setListFieldsQuery = (fields: string[]) => ({
  fields,
  type: AssetActions.SET_ASSET_LIST_FIELDS_QUERY,
});

/*
 * getAssetTransferDetails is a to get the transfer details.
 * @param id assetId
 */
export const getAssetTransferDetails = (id: number, offset?: number, limit?: number) => ({
  id,
  offset: offset || 0,
  limit: limit || getPageSize(),
  type: AssetActions.AM_GET_ASSET_TRANSFER_DETAILS,
});

/**
 * @description- Function to dispatch action to get custom view list.
 */
export const getAssetCustomViewList = () => ({
  type: AssetActions.AM_GET_ASSET_CUSTOM_VIEW,
});

/**
 * @description - Function to dispatch action to create custom view.
 * @param customViewData - Custom view data to create.
 * @param successMsg - Success message to show.
 */
export const createCustomView = (customViewData, successMsg) => ({
  customViewData,
  successMsg,
  type: AssetActions.AM_CREATE_CUSTOM_VIEW,
});

/**
 * @description Function to dispatch the action to set selected custom view.
 * @param {object} item - selected view.
 */
export const setSelectedView = (item) => ({
  item,
  type: AssetActions.SET_SELECTED_VIEW,
});

/**
 * @description - Function to dispatch action to update custom view.
 * @param customView
 */

export const updateCustomAssetView = (customView) => ({
  customView,
  type: AssetActions.AM_UPDATE_ASSET_CUSTOM_VIEW,
});

export const reorderAssetCustomView = (item) => ({
  item,
  modName: ModuleName.asset,
  type: AssetActions.AM_REORDER_ASSET_CUSTOM_VIEW,
});

/**
 * @description - Function to dispatch action to delete custom view.
 * @param id - Custom view id.
 */
export const deleteCustomView = (id) => ({
  id,
  type: AssetActions.AM_DELETE_CUSTOM_VIEW,
});
/*
 * @description Function to dispatch the action to set flag if custom view is modified .
 */
export const setCustomViewModifiedFlag = () => ({
  type: AssetActions.SET_CUSTOM_VIEW_MODIFIED_FLAG,
});

/*
 * @description cancelAssetLastTransfer is used to cancle the last transfer
 *
 */

export const cancelAssetLastTransfer = (assetDetailsData, cancelNote) => ({
  formData: { cancellationNotes: cancelNote },
  payload: {
    assetId: assetDetailsData.assetId,
    transferId: assetDetailsData.transferId,
  },
  type: AssetActions.AM_CANCEL_TRANSFER,
});

/**
 * @description - Function to dispatch action to update custom grid fields.
 * @param customView - Custom view.
 */
export const updateCustomGridFields = (customView) => ({
  customView,
  type: AssetActions.UPDATE_CUSTOM_GRID_FIELDS,
});

export const resetMultiDeleteResponse = () => ({
  type: AssetActions.RESET_MULTI_DELETE_RESPONSE,
});

/**
 * clear custom view data
 */

export const clearCustomViewData = () => ({
  type: AssetActions.AM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA,
});

/**
 * @description - Function to dispatch action to update custom grid fields.
 * @param selectedCustomView
 * @param customViewName
 */
export const renameCustomView = (selectedCustomView, customViewName: string) => ({
  customViewName,
  selectedCustomView,
  type: AssetActions.AM_RENAME_CUSTOM_VIEW,
});

/**
 * @description - Function to dispatch action to update custom grid fields.
 * @param selectedCustomView
 */
export const setDefaultCustomView = (selectedCustomView) => ({
  selectedCustomView,
  type: AssetActions.AM_SET_DEFAULT_CUSTOM_VIEW,
});

/**
 * @description - Function to dispatch action to get system view
 */
export const getSystemView = () => ({
  type: AssetActions.AM_GET_SYSTEM_VIEW,
});

/*
 * @description Function to dispatch the action to reset original custom view
 */
export const resetOriginalView = () => ({
  type: AssetActions.AM_RESET_ORIGINAL_VIEW,
});

/**
 * @description - Function to dispatch action to clear system grid fields
 */
export const clearDefaultGridFields = () => ({
  type: AssetActions.AM_CLEAR_DEFAULT_GRID_FIELDS,
});

/**
 * @description - Function to dispatch action to get the child assets data
 */
export const getChildAssets = (query) => ({
  query,
  type: AssetActions.AM_GET_CHILD_ASSETS,
});

/**
 *
 * @param id
 */
export const getCheckedAssetTransferDetail = (id) => ({
  id,
  type: AssetActions.AM_GET_CHECKED_ASSET_TRANSFER_DETAILS,
});

/**
 * @description Get the Associate Asset Label Details for Asset
 */
export const associateAssetLabelDetails = (data) => ({
  data,
  type: AssetActions.AM_ASSOCIATE_ASEET_LABEL_DETAILS,
});

/**
 * @description Function to search labels
 */
export const createAssetLabel = (data, checkedListInfo) => ({
  checkedListInfo,
  data,
  type: AssetActions.AM_ASSOCIATE_ASEET_LABEL,
});

/**
 * @description Action dispatcher to update storage asset gateway status
 */
export const updateStorageAssetGatewayStatus = (assetId: number, gatewayStatus: { code: string; value: string }) => ({
  assetId,
  gatewayStatus,
  type: AssetActions.AM_UPDATE_STORAGE_ASSET_GATEWAY_STATUS,
});

/**
 * @description - Function to dispatch action to clear system grid fields
 */
export const clearSelectedAssetTransferHistory = () => ({
  type: AssetActions.CLEAR_SELECTED_ASSET_TRANSFER_HISTORY,
});

/**
 * @description - Function to dispatch action to clear system grid fields
 */
export const clearAssetDetails = () => ({
  type: AssetActions.CLEAR_ASSET_DETAILS,
});

/**
 * @description - Function to dispatch action to get asset utilization summary
 */
export const fetchAsseUtilizationSummary = (
  assetId,
  queryObj: { duration?: number; startDate?: string; endDate?: string },
) => ({
  assetId,
  queryObj,
  type: AssetActions.GET_ASSET_UTILIZATION_SUMMARY,
});

/**
 * @description - Function to dispatch action to get asset utilization Details
 */
export const fetchAsseUtilizationDetails = (
  assetId: number,
  queryObj: { duration?: number; startDate?: string; endDate?: string },
) => ({
  assetId,
  queryObj,
  type: AssetActions.GET_ASSET_UTILIZATION_DETAILS,
});

export const getGatewayAssetsCount = () => ({
  type: AlertNotificationConstant.GET_GATEWAY_ASSETS_COUNT,
});

/**
 * @description - Function to dispatch action to reset asset utilization summary
 */
export const resetAsseUtilizationSummary = () => ({
  type: AssetActions.RESET_ASSET_UTILIZATION_SUMMARY,
});
