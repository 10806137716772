import { HelperLodash } from "../index";
import { IApiVersion } from "./IApiVersion";

declare global {
  interface Window {
    ASSET_MANAGEMENT_URLS: any;
    ACTIVE_TRACKING: any;
    API_VERSIONS: IApiVersion;
    WalkMeAPI: any;
  }
}

const getApiUrl = (seriveUrl: string) => {
  return (
    window.ASSET_MANAGEMENT_URLS && (window.ASSET_MANAGEMENT_URLS[seriveUrl] || window.ASSET_MANAGEMENT_URLS.USER_MGMT)
  );
};

const interpolateParams = function (str, params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${str}\`;`)(...vals);
};
/**
 * @param serviceUrl - a string with the name of a microservice, like ALERT_NOTIFICATION_MGMT - that must match
 * with the API_VERSIONS object's keys from the config.js file
 * @param path - an endpoint URL with parameters, like "/alerts/{alertName}/{alertId}"
 * @param params - an object with the values for the parameters in the path, like { alertName: "test", alertId: 123 }
 *
 * The getApiPathVersion function makes the URLs with dynamic parameters pass through the config.js file
 * It identifies the dynamic parameters in the "path" parameter. In our case, {alertName} and {alertId}.
 *
 * Then it puts the values for corresponding parameters from the "params" object so that in our example the URL will be "/alerts/test/123"
 *
 * !!! IMPORTANT !!! Use the curly brackets in your API file in order to make this function work properly ===> /alerts/{alertName}/{alertId}
 */
const getApiPathVersion = (serviceUrl: string, path: string, params = {}) => {
  let urlPath = path;
  const versionInfo = window.API_VERSIONS && HelperLodash.get(window.API_VERSIONS, serviceUrl);
  const exception = versionInfo?.exception?.length && versionInfo.exception.find((value) => value.path === path);
  // determine if the URL has dynamic parameters
  if (path.indexOf("{") !== -1) {
    // this regex works in all browsers, but returns a string with "{"
    const realParamsArr = path.match(/(?:\{)[a-z0-9]+(?=})/gi);
    if (realParamsArr) {
      if (params === null) {
        params = {};
      }
      realParamsArr.forEach((element) => {
        // to convert strings like "{test" to "test"
        const parameter = element.substring(1);
        if (!HelperLodash.get(params, parameter)) {
          HelperLodash.set(params, parameter, "undefined");
        }
      });
      /*
       * replace the dynamic parameters in the way so that the URL will look like a template literal
       * "/alerts/{alertName}/{alertId}" ===> "/alerts/${alertName}/${alertId}"
       * */
      urlPath = path.split("/{").join("/${");
      /*
       * use the newly created template literal as an URL and replace the dynamic parameters with the real values
       * "/alerts/${alertName}/${alertId}" ===> "/alerts/test/123"
       * */
      urlPath = interpolateParams(urlPath, params);
    }
  }
  if (exception) {
    return exception.value + urlPath;
  }
  return versionInfo?.defaultValue + urlPath;
};

const getVersionedApiUrl = (serviceUrl: string, path: string, params = null) => {
  return `${getApiUrl(serviceUrl)}/${getApiPathVersion(serviceUrl, path, params)}`;
};

export default getVersionedApiUrl;
