import React from "react";
import Select from "react-select";
import styled from "styled-components";

import Field from "../../../elements/Field/field";
import { HelperLodash } from "../../../helpers";

const NoValuesLabel = styled.div`
  color: ${(props) => props.theme.colors.base.placeholderColor};
`;

export const StyledSelect = styled(Select)`
  -webkit-flex-basis: auto !important;
  flex-basis: auto !important;
  width: auto !important;
`;

const SelectDropdown = (props) => {
  const {
    className,
    id,
    name,
    options,
    optionsCount,
    maximumNumberOfOptions,
    handleChange,
    components,
    selectOnMenuClose,
    placeholder,
    selectedValue,
    inputFocus,
    isFocused,
    noValuesLabel,
  } = props;
  let constructedOptions = options
    ? options.map((item) => {
        const label = HelperLodash.get(item, props.valueKey, "");
        const value = HelperLodash.get(item, props.valueId, "");
        return {
          label,
          value,
        };
      })
    : [];
  constructedOptions =
    maximumNumberOfOptions && optionsCount ? constructedOptions.slice(0, maximumNumberOfOptions) : constructedOptions;
  return (
    <Field
      name={name}
      htmlFor={name}
      required={props.required}
      label={""}
      error={props.meta.error}
      touched={props.meta.touched}
      warning={props.meta.warning}
      hideValidation={true}
    >
      <StyledSelect
        {...props}
        isSearchable={true}
        isClearable={false}
        components={components}
        onChange={handleChange}
        onMenuClose={selectOnMenuClose}
        options={constructedOptions}
        className={`${className} multiSelectDropDown`}
        id={id}
        name={name}
        placeholder={placeholder}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        openMenuOnClick={true}
        closeMenuOnSelect={false}
        isMulti={true}
        onSelectResetsInput={false}
        onBlurResetsInput={false}
        value={selectedValue}
        onMenuInputFocus={inputFocus}
        isFocused={isFocused || undefined}
        noOptionMessage={() => <NoValuesLabel>{noValuesLabel}</NoValuesLabel>}
      />
    </Field>
  );
};

export default SelectDropdown;
