import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { memo } from "react";
import { components } from "react-select";
import { wrapMenuList } from "react-select-async-paginate/lib";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import Icons from "../../components/icons/svgs";
// @ts-ignore
import InfoIcon from "../../components/infoCard/assets/Info.svg";
import colorPalette from "../../materials/Colors";
// @ts-ignore
import iconArrowdown from "./arrow_down.svg";
import GridView from "../../components/grid/gridView";
import { HelperLodash } from "../../helpers";
import { AutoCompleteMenuType } from "./autoCompleteEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import Grid from "@mui/material/Grid";
import Tooltip from "@hilti/components/core/tooltip";

/**
 * StyledComponent wrapper on material ui TextField
 */
const TextFieldStyle = styled(TextField)`
  && {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    height: 48px;
  }
  + div {
    margin-top: 0px;
    margin-bottom: 0;
    border-radius: 0px;
    box-shadow: 0 2px 12px 0px ${(props) => props.theme.colors.base.greyrgba};
    .suggestion-wrapper {
      padding: 0;
    }
  }
`;

const AutoCompleteDropdown = styled.div`
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  height: 100%;
`;

export const NoOptions = styled.div`
  padding: 8px 16px;
`;

const Remove = styled.button`
  position: absolute;
  top: 50%;
  right: 48px;
  margin-top: -12px;
  border: 0;
  background-color: transparent;
  svg {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`;

export const ElementWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dropdownMenuItem {
    display: flex;
    align-items: center;
    white-space: inherit;
    word-break: break-all;
    height: auto;
    width: 100%;
    opacity: 1;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }
  & > div:hover {
    background-color: ${(props) => props.theme.colors.base.heavyConcrete10} !important;
    &.selected {
      background-color: ${(props: any) => (props.isSelected ? props.theme.colors.base.heavyConcrete10 : "")};
    }
  }
  .iconStyle {
    margin-right: 16px;
  }
  .cell-content-wrapper {
    align-items: center;
    min-width: 0;
    flex: 1;
    background-color: transparent !important;
  }
  .row {
    background-color: transparent;
    margin: -11px -16px;
    min-height: 0;
    flex: 1;
    &:hover {
      background-color: transparent;
    }
    .custom-cell {
      line-height: 1.5;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-right: 16px;
  cursor: pointer;
`;

export const OptionImageWrapper = styled(Grid)`
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};
  margin-right: 16px;
  display: flex;
`;

const IconWrapper = styled.div`
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
/**
 * Control to replace react-select NoOptionMessage
 * 1) This method is triggered by react-select only if user passes props noOptionsMessage.
 * 2) noOptionMessage is a method and it should return string. If it returns null value then
    below method is not triggered.
 *  3) When max limit is reached for multi-select. Then validateNewOptions() returns true.
    4) When there is no option left (can be for search) , then validateNewOptions() return false.
 */
export const NoOptionsMessage = (props) => {
  const {
    selectProps: { validateNewOptions },
  } = props;
  if (props.children && (props.selectProps.isCreatable || (validateNewOptions && validateNewOptions()))) {
    return (
      <Typography
        id={props.name}
        color="textSecondary"
        className={`optionMessage ${props.selectProps.classes.noOptionsMessage}`}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }
  return (
    <NoOptions
      className={
        props.selectProps.typeToSearchMsg || !props.selectProps.isCreatable
          ? `${colorPalette.greyrgba}`
          : `${colorPalette.hiltiRedError}`
      }
    >
      {props.selectProps.typeToSearchMsg ? props.selectProps.typeToSearchMsg : props.selectProps.t("common:NO_OPTIONS")}
    </NoOptions>
  );
};

/**
 * Control to replace react-select inputComponent
 */
const inputComponent = ({ inputRef, ...props }) => {
  return <div id={props.name} className="inputComponent" ref={inputRef} {...props} />;
};

/**
 * Control to replace react-select Control
 */
const Control = (props) => (
  <TextFieldStyle
    // @ts-ignore
    url={iconArrowdown}
    id={props.name}
    className={`typeselect`}
    variant={"standard"}
    InputProps={{
      inputComponent,
      inputProps: {
        children: props.children,
        className: props.selectProps.classes.input,
        ref: props.ref,
        ...props.innerProps,
      },
    }}
  />
);

export const RenderMenuItem = (props) => {
  if (props.selectProps.menuType === AutoCompleteMenuType.GRID) {
    const { selectProps } = props;
    const dataArray =
      selectProps.options.length > 0 ? HelperLodash.compact(selectProps.options.map((ele) => ele.item)) : [];
    const data = dataArray.filter((element) => element[selectProps.valueId]?.toString() === props.value);
    return (
      <GridView
        columns={selectProps.menuGridInfo.gridSetting}
        rowsData={data || []}
        idKey="id"
        disableCheckBoxColumn={true}
        showTooltip={false}
        dependencies={selectProps.menuGridInfo.dependencies}
        lazyLoadList={false}
      />
    );
  } else if (props.selectProps.menuType === AutoCompleteMenuType.IMAGE) {
    return <RenderImage {...props} />;
  } else {
    return props.children;
  }
};

export const RenderImage = (props) => {
  const imgObj = props.selectProps && props.selectProps.image;
  const wrapperAttributes = {
    height: "38px",
    imageSize: 38,
    width: "38px",
    style: { marginRight: "16px" },
  };
  const documentId = HelperLodash.get(props.data, "item.img");
  const defaultImg = imgObj.defaultImage;

  return (
    <>
      {imgObj.component && documentId ? (
        <imgObj.component wrapperAttributes={wrapperAttributes} documentId={documentId} type={"thumbnail"} />
      ) : (
        <OptionImageWrapper {...wrapperAttributes}>
          <SquareIcon name={defaultImg} imageSize={wrapperAttributes.imageSize} className="grid-thumb" />
        </OptionImageWrapper>
      )}
      <div className="cell-content-wrapper">{props.children}</div>
    </>
  );
};

const getNewOptionStyle = (props) => {
  return props.data.__isNew__
    ? {
        borderTop: `1px solid ${colorPalette.heavyConcrete20}`,
        textDecoration: "underline",
      }
    : {};
};

const getInfoOptionStyle = (props) => {
  return props.data.isInfo
    ? {
        color: colorPalette.heavyConcrete60,
        fontWeight: 400,
      }
    : {};
};
const getErrorOptionStyle = (props) =>
  props.data.isError ? { color: colorPalette.hiltiRedError, fontWeight: 400 } : {};

export const getSelectedClassForOption = (props) => {
  return props.isSelected
    ? "selected dropdownMenuItem"
    : `dropdownMenuItem op_${props.selectProps.name}${props.innerProps.id.split("-").pop()}`;
};
export const getSelectedStyle = (props) => {
  let selectedStyle: any = { fontWeight: 400 };

  // Change the style of selected option.
  if (props.isSelected) {
    selectedStyle = {
      background: colorPalette.heavyConcrete40,
      fontWeight: 700,
    };
  }
  // This is used to style the disabled option.
  if (props.isDisabled) {
    selectedStyle = {
      color: colorPalette.heavyConcrete60,
      fontWeight: 400,
    };
  }
  return selectedStyle;
};
export const GetImageWrapper = (props) => {
  const { item } = props.data;
  return props.isDisabled && item?.disabledReason ? (
    <Tooltip id="optionTooltip" title={item.disabledReason} placement="right">
      <ImageWrapper>
        {item && IconName[item?.icon] ? (
          <IconWrapper>
            <Icons name={IconName[item.icon]} />
          </IconWrapper>
        ) : (
          <img src={InfoIcon} data-for="optionTooltip" data-tip="" alt="" />
        )}
      </ImageWrapper>
    </Tooltip>
  ) : null;
};
/**
 * Control to replace react-select Option
 */
export const Option = (props) => {
  /**
   * Styling for the creatable option
   */
  const newOptionStyle = getNewOptionStyle(props);

  const infoOptionStyle = getInfoOptionStyle(props);

  const errorOptionStyle = getErrorOptionStyle(props);

  const selectedStyle = getSelectedStyle(props);

  return props.selectProps.shouldDisplayOption(props.data) ? (
    <ElementWithIcon>
      <MenuItem
        {...props.innerProps}
        id={`${props.selectProps.name}Op${props.innerProps.id.split("-").pop()}`}
        className={getSelectedClassForOption(props)}
        buttonRef={props.ref}
        disabled={!!props.data.isInfo || !!props.data.isDisabled || !!props.data.isError}
        selected={props.isFocused}
        component="div"
        style={{
          ...selectedStyle,
          ...newOptionStyle,
          ...infoOptionStyle,
          ...errorOptionStyle,
        }}
      >
        <RenderMenuItem {...props} />
        {props.data.item && props.data.item.inActive ? (
          <label className="filedstatus">{props.selectProps && props.selectProps.inactiveMessage}</label>
        ) : null}
      </MenuItem>
      <GetImageWrapper {...props} />
    </ElementWithIcon>
  ) : null;
};

/**
 * Control to replace react-select Placeholder
 */
export const Placeholder = (props) => {
  return (
    <Typography
      id={`${props.selectProps.name}-placeholder`}
      color="textSecondary"
      className={`dropDownPlaceholder ${props.selectProps.classes.placeholder}`}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

/**
 * Control to replace react-select SingleValue
 */
const SingleValue = (props) => {
  return (
    <Typography
      id={`${props.selectProps.name}Value`}
      className={`dropDownSingleValue ${props.selectProps.classes.singleValue}`}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

/**
 * Control to replace react-select ValueContainer
 */
const ValueContainer = (props) => {
  return (
    <AutoCompleteDropdown
      data-id="creatableDropDownParent"
      id={`${props.selectProps.name}Dd`}
      className={`dropDownSingleValue ${props.selectProps.classes.valueContainer}`}
    >
      {props.children}
    </AutoCompleteDropdown>
  );
};

/**
 * Control to replace react-select DropdownIndicator
 */
export const DropdownIndicator = (props) => {
  return components && components.DropdownIndicator ? (
    <components.DropdownIndicator {...props} className={`${props.selectProps.name}Img imgarrow`}>
      {props.selectProps.searchIcon ? (
        <SquareIcon name={IconName.Search} />
      ) : (
        <SquareIcon name={IconName.Arrow} className={props.selectProps.menuIsOpen ? "arrow up" : "arrow"} />
      )}
    </components.DropdownIndicator>
  ) : (
    <div {...props} className={`${props.selectProps.name}Img imgarrow`}>
      {props.selectProps.searchIcon ? (
        <SquareIcon name={IconName.Search} />
      ) : (
        <SquareIcon name={IconName.Arrow} className={props.selectProps.menuIsOpen ? "arrow up" : "arrow"} />
      )}
    </div>
  );
};

const ClearIndicator = memo((props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return props.selectProps.showClearIcon ? (
    <Remove {...restInnerProps}>
      <Icons name={IconName.RemoveCircle} ref={ref} />
    </Remove>
  ) : null;
});

export const CustomComponentPlaceholder = styled.div`
  padding: 8px 16px;
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  button {
    border: none;
    background: none;
    text-decoration: underline;
    font-size: ${(props) => props.theme.fontSize.base};
    color: ${(props) => props.theme.colors.base.steel};
    svg {
      height: 10px;
    }
  }
`;

const OptionsPlaceholder = styled.div`
  max-height: ${(props: any) => (props["data-footerHeight"] ? "250px" : "100%")};
  overflow-y: auto;
`;

export const CustomMenuList = (props: any) => {
  let showFooter;
  const { selectProps } = props;
  if (selectProps.footerControl) {
    showFooter = (
      <CustomComponentPlaceholder>
        <selectProps.footerControl name={selectProps.id} />
      </CustomComponentPlaceholder>
    );
  }

  return (
    <components.MenuList {...props}>
      <OptionsPlaceholder data-footerHeight={!!props.selectProps.footerControl}>{props.children}</OptionsPlaceholder>
      {showFooter}
    </components.MenuList>
  );
};

const MenuList = wrapMenuList(CustomMenuList);

/**
 * Component Object  to be used by react-select
 */
const autoCompleteComponents = {
  ClearIndicator,
  Control,
  DropdownIndicator,
  MenuList,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};
const getCustomComponents = (props) => {
  return {
    ...autoCompleteComponents,
    ...props.customComponents,
  };
};
const getAutoCompleteComponents = (props) => {
  return props.customComponents ? getCustomComponents(props) : autoCompleteComponents;
};
export default getAutoCompleteComponents;
