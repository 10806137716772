// @ts-ignore
import { LockedFeatureInfoActions } from "custom-actions";
import { LockedFeatures } from "../../api/lockedFeaturesInfoApi";

/**
 * @description - Switch cases for the dispatched locked feature info Actions.
 *
 * @param state - State of the store.
 * @param action { type: LockedFeatureInfoActions; payload?: object } - Dispatched action.
 */

const initialState = { lockedFeatureData: null };
const LockedFeatureInfoReducer = (
  state: any = initialState,
  action: { type: LockedFeatureInfoActions; payload?: LockedFeatures },
) => {
  switch (action.type) {
    case LockedFeatureInfoActions.PUT_LOCKED_FEATURE_INFO:
      return {
        ...state,
        lockedFeatureData: action.payload,
      };
    case LockedFeatureInfoActions.CLEAR_LOCKED_FEATURE_INFO:
      return initialState;
    default:
      return state;
  }
};

export default LockedFeatureInfoReducer;
