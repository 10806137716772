import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M4.5,8 L9,13 L6.08309386,13.0008069 C6.55950437,15.8381232 9.02728549,18 12,18 C13.6678999,18 15.1768473,17.319443 16.2643011,16.2208698 L18.3956099,18.332155 C16.7644922,19.9795156 14.5014196,21 12,21 C7.36768501,21 3.55276616,17.5003111 3.05500569,13.0006955 L0,13 L4.5,8 Z M12,3 C16.6326577,3 20.4477983,6.50020676 20.9451047,11.0003032 L24,11 L19.5,16 L15,11 L17.9170737,11.0001915 C17.4410745,8.16238395 14.9730632,6 12,6 C10.3090045,6 8.78139417,6.69953497 7.69076786,7.82500604 L5.59354728,5.67881449 C7.22547112,4.02501574 9.49303293,3 12,3 Z"></path>
    </g>
  </svg>
);

export default SVG;
