import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";

const Wrapper = styled(Grid)`
  font-size: 2rem;
  background: #efefef;
`;

const Heading = ({ children }) => (
  <Wrapper item={true} xs={12}>
    <Grid item={true} xs={12}>
      {children}
    </Grid>
    <Grid item={true} xs={12} />
  </Wrapper>
);

export default Heading;
