import React from "react";
const SVG = () => (
  <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-795.000000, -2067.000000)">
        <g transform="translate(792.000000, 2064.000000)">
          <g>
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M16.3787332,6.54855655 L15,3.59185367 L21.5778483,5.98599467 L19.1837073,12.563843 L17.6593292,9.29480351 C14.9239975,10.0597541 12.780493,12.2421007 12.0706587,15 L9,15 C9.73097157,11.0205246 12.6035717,7.79035437 16.3787332,6.54855655 Z M6,6 L6,18 L18,18 L18,14 L21,14 L21,21 L18,21 L6,21 L3,21 L3,3 L6,3 L10,3 L10,6 L6,6 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
