import React from "react";
import styled from "styled-components";
import { IconName } from "../../icons/iconEnum";
import { SquareIcon } from "../../icons/iconWrapper";
import StyleAttrConstants from "../../../constant/styleAttrConstants";
import { LoaderElement } from "../../../elements/loader/loaderElement";
import domHelper, { toggleClassOnBody } from "../../../helpers/domHelper";
import { fixedElement, removeBrowserScroll } from "../filterUtils";
import { IMoreFilterContainerProps } from "./iMoreFilterContainerProps";
import { Scroll, ScrollContext } from "../../../providers/scrollProvider";

interface IMoreFilterButtonProps {
  isMoreFilterClosed: boolean;
}

/**
 * MoreFilterWrapper styles for the dropdown wrapper
 */
const MoreFilterWrapper = styled.div`
  min-width: 145px;
  box-sizing: border-box;
  margin-right: 16px;
`;

const MenuChild = styled.div`
  .fields-wrapper {
    flex-direction: column;
    .seprator {
      display: none;
    }
    .react-datepicker-wrapper {
      display: flex;
      input[type="text"] {
        width: 100%;
      }
    }
  }
`;

/**
 * styles for more filter button
 */
export const MoreFilterButton = styled.div<IMoreFilterButtonProps>`
  width: 132px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete30};
  padding: 0 16px;
  color: ${(props) => props.theme.colors.base.steel};
  height: 40px;
  align-items: center;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.base};
  background-color: ${(props) =>
    props.isMoreFilterClosed ? props.theme.colors.base.white : props.theme.colors.base.transparent};
  .icon {
    margin-right: 8px;
  }
  &.selected {
    background-color: ${(props) => props.theme.colors.base.steel};
    border: 0;
    color: ${(props) => props.theme.colors.base.white};
    polygon {
      fill: ${(props) => props.theme.colors.base.white};
    }
  }
`;

interface IMoreFilterMenuWrapper {
  isActionBarRendered?: boolean;
  isSearchBarRendered?: boolean;
  isActionBarScrollRendered?: boolean;
}

/**
 * styles for more filter menu
 */
export const MoreFilterMenuWrapper = styled.section`
  right: 0;
  top: 291px;
  position: fixed;
  z-index: 96;
  background: ${(props) => props.theme.colors.base.white};
  border-left: 1px solid ${(props) => props.theme.colors.base.borderColor};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  width: 480px;
  min-height: calc(100vh - 231px);
  @media (max-width: 1280px) {
    width: 315px;
  }
  &.optionListContainer {
    position: absolute;
    top: ${(props: IMoreFilterMenuWrapper) =>
      props.isActionBarRendered || (props.isSearchBarRendered && props.isActionBarScrollRendered) ? "138px" : "65px"};
    right: 0;
    &.stickyTop {
      min-height: 100vh;
      position: fixed !important;
      top: 72px;
      right: 0;
    }
    &.sticky {
      position: fixed !important;
      bottom: 0;
      right: 0;
      overflow: clip auto;
      min-height: calc(100vh - 72px);
      top: 72px;
    }
    &.stickyOnScroll {
      position: fixed !important;
      bottom: 0;
      top: 138px;
      right: 0;
      overflow: clip auto;
      min-height: calc(100vh - 138px);
    }
  }
`;

/**
 * styles for close filter menu row
 */
export const CloseMoreFilter = styled.div`
  min-height: 56px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
  h4 {
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    font-size: ${(props) => props.theme.fontSize.medium};
    cursor: default;
  }
`;
/**
 * @description CountBadge to style the CountBadge
 */
export const CountBadge = styled.div`
  background-color: ${(props) => props.theme.colors.base.steel};
  border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete10};
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  position: absolute;
  top: -12px;
  right: -12px;
  color: ${(props) => props.theme.colors.base.white};
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 2px 6px;
`;

const LoaderWrapper = styled.div`
  padding: 32px 0;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${(props) => props.theme.colors.base.white};
`;

/**
 * @class MoreFilterContainer component
 */
export class MoreFilterContainer extends React.PureComponent<IMoreFilterContainerProps> {
  menuInstance = React.createRef<any>();
  calculateHeight = () => {
    const moreFilterHeight = this.menuInstance && this.menuInstance.current && this.menuInstance.current.clientHeight;
    const optionId = document.getElementById("optionListContainer");
    if (optionId && moreFilterHeight) {
      optionId.setAttribute("style", `height: ${moreFilterHeight}px`);
    }
  };

  state = {
    lastScrollY: 0,
  };

  static contextType?: React.Context<Scroll> = ScrollContext;

  setStickyClass = (filterSidebar, sticky) => {
    filterSidebar.classList.remove("sticky");
    filterSidebar.classList.remove("stickyScroll");
    const { isScrollBehaviourEnabled } = this.context;
    if (!isScrollBehaviourEnabled) {
      if (window.pageYOffset === 0 || window.pageYOffset < StyleAttrConstants.OFFSET.O200) {
        filterSidebar.classList.remove("stickyTop");
      } else if (filterSidebar.offsetHeight < window.innerHeight - StyleAttrConstants.HEIGHT.H71) {
        filterSidebar.classList.add("stickyTop");
      } else if (window.pageYOffset > sticky) {
        filterSidebar.classList.add("sticky");
      }
    } else {
      this.handleScroll();
    }
  };

  handleScroll = () => {
    const { lastScrollY } = this.state;
    const currentScrollY = window.scrollY;
    const filterSidebar = document.getElementById("optionListContainer");
    const targetElem = document.querySelector("#tableWrapper") as HTMLElement | null;
    const tablePos = targetElem?.offsetTop;
    if (currentScrollY < tablePos || currentScrollY === 0) {
      filterSidebar.classList.remove("stickyOnScroll");
      filterSidebar.classList.remove("sticky");
      filterSidebar.classList.add("stickyScrollTop");
    } else if (currentScrollY > lastScrollY && currentScrollY > tablePos) {
      filterSidebar.classList.remove("stickyScrollTop");
      filterSidebar.classList.remove("stickyOnScroll");
      filterSidebar.classList.add("sticky");
    } else if (currentScrollY < lastScrollY && currentScrollY > tablePos) {
      filterSidebar.classList.remove("stickyScrollTop");
      filterSidebar.classList.remove("sticky");
      filterSidebar.classList.add("stickyOnScroll");
    }
    this.setState({ lastScrollY: currentScrollY });
  };

  moreFilterFixed = () => {
    const filterOffsetVal = this.props.showNoRecordFoundView ? 0 : StyleAttrConstants.OFFSET.filterOffset;
    const filterSidebar = document.getElementById("optionListContainer");
    const sticky = filterSidebar && filterSidebar.offsetHeight - filterOffsetVal; // offset height from top
    if (filterSidebar && filterSidebar.classList.length > 0) {
      this.setStickyClass(filterSidebar, sticky);
    }
  };

  headerFixed() {
    fixedElement(".list-container", "thead-sticky");
  }

  configureScroll = (e) => {
    e.preventDefault();
    this.headerFixed();
    this.moreFilterFixed();
  };

  componentDidMount() {
    window.addEventListener("scroll", this.configureScroll);
  }

  componentWillUnmount() {
    const parentRef = this.checkParentRefrerance(this.props);
    window.removeEventListener("scroll", this.configureScroll);
    if (parentRef.isMoreFilterClosed) {
      domHelper.removeClassOnBody("browserscroll");
    }
  }

  componentDidUpdate(prevProps) {
    this.calculateHeight();
    const parentRef = this.checkParentRefrerance(this.props);
    const prevParentRef = this.checkParentRefrerance(prevProps);
    if (prevParentRef.isMoreFilterClosed !== parentRef.isMoreFilterClosed && !parentRef.isMoreFilterClosed) {
      domHelper.removeClassOnBody("browserscroll");
    }
  }
  /**
   * @description checkParentRefrerance will toggle the menu dropdown of current filter
   */
  checkParentRefrerance = (props) => {
    const { parentMoreFilterHandler } = props;
    if (parentMoreFilterHandler) {
      return parentMoreFilterHandler;
    }
    return {
      closeMoreFilter: props.closeMoreFilter,
      isMoreFilterClosed: props.isMoreFilterClosed,
      openMoreFilter: props.openMoreFilter,
    };
  };

  /**
   * @description openMoreFilterMenu will toggle the menu dropdown when more filter button clicked
   */
  openMoreFilterMenu = () => {
    const checkRef = this.checkParentRefrerance(this.props);
    if (!checkRef.isMoreFilterClosed) {
      checkRef.openMoreFilter();
    } else {
      checkRef.closeMoreFilter();
    }
    toggleClassOnBody("browserscroll");
  };

  /**
   * closeMoreFilterMenu will close the menu dropdown when clicked on cross icon
   */
  closeMoreFilterMenu = () => {
    const checkRef = this.checkParentRefrerance(this.props);
    if (checkRef.isMoreFilterClosed) {
      checkRef.closeMoreFilter();
    } else {
      this.props.closeMoreFilter();
    }
    removeBrowserScroll();
  };

  setMenuInstanceRef = (element) => {
    this.menuInstance = element;
  };

  getContainer = () => {
    const { children, t } = this.props;
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child as React.ReactElement<any>, {
        updateHeight: this.calculateHeight,
      }),
    );
    return (
      <MenuChild id="menuChild" className="menu-child" ref={this.setMenuInstanceRef}>
        {
          <CloseMoreFilter>
            <h4>{t("filters:MORE_FILTER")}</h4>
            <SquareIcon data-testid="close-filters" name={IconName.Cross} onClick={() => this.closeMoreFilterMenu()} />
          </CloseMoreFilter>
        }
        {childrenWithProps}
      </MenuChild>
    );
  };

  checkActionBarRenering = () => {
    const checkRootClass = document.querySelector("#root") as HTMLElement;
    return document.querySelector("#modalRoot #dialogPortal")
      ? !(!document.querySelector("#modalRoot #actionBar") && checkRootClass.style.display === "none")
      : !!document.querySelector("#root #actionBar");
  };

  checkSearchBarRendered = () => {
    return !!document.querySelector(".searchbar-scroll-add") || !!document.querySelector(".search-bar");
  };

  checkActionBarScrollRendered = () => {
    return !!document.querySelector("#actionBarScroll");
  };

  render() {
    const { displayLoader, id, placeholder, badgeCount } = this.props;
    const checkRef = this.checkParentRefrerance(this.props);
    return (
      <MoreFilterWrapper>
        <MoreFilterButton
          className={!!badgeCount && "selected"}
          id={id}
          onClick={() => this.openMoreFilterMenu()}
          isMoreFilterClosed={checkRef.isMoreFilterClosed}
        >
          <SquareIcon name={IconName.Filter} className="icon small" imageSize={16} />
          {placeholder}
          {!!badgeCount && <CountBadge className="badge">{badgeCount}</CountBadge>}
        </MoreFilterButton>
        {checkRef.isMoreFilterClosed && (
          <>
            <MoreFilterMenuWrapper
              id="optionListContainer"
              className="optionListContainer"
              isActionBarRendered={this.checkActionBarRenering()}
              isSearchBarRendered={this.checkSearchBarRendered()}
              isActionBarScrollRendered={this.checkActionBarScrollRendered()}
            >
              {displayLoader ? (
                <LoaderWrapper>
                  <LoaderElement />
                </LoaderWrapper>
              ) : null}
              {this.getContainer()}
            </MoreFilterMenuWrapper>
          </>
        )}
      </MoreFilterWrapper>
    );
  }
}
export default MoreFilterContainer;
