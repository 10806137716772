import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelperLodash } from "am-web-ui-shared/helpers";
import ILocationDetailsProps from "./iLocationDetailsProps";
import { UpfrontExpansionPanel } from "../../../../components/DetailsPanel";
import { clearAllSelection } from "../../../../actions/listActions";
import {
  LocationTitle,
  StorageLocation,
  AssetPosition,
  ConfirmedLocationName,
  LastSeen,
  TrackingGateways,
  OwnerShipType,
  FieldWithTooltipAndRedirect,
  TrackingByHeavyEquipment,
  Address,
} from "./additionalComponents";
import {
  getCurrentLocationName,
  getDefaultLocationName,
  getRedirectionForCurrentLocationStorageAsset,
  getRedirectionForDefaultLocationStorageAsset,
  getRedirectionObjectForCharger,
} from "./helpers";
import {
  getLocationSource,
  getRedirectionDetails,
  getSource,
  isAssetTrackedByVanEnabled,
  isTrackedByVAN,
} from "../../../../utils/activeTrackingUtils";
import { IAssetDetailsData } from "../../../../models/asset/assetModel";
import { trackLocationDetails } from "../../../../../analytics/events/assetDetails";
import useReverseGeocode from "./useReverseGeocode";
import { ServiceProviderCode } from "TARGET_BUILD/common/utils/enums/activeTrackingEnum";
import usePositionData from "./usePositionData";
import { getLocationInfo } from "./useLocationInfo";
import { AppDispatch, RootState } from "TARGET_BUILD/common/store/store";
import { LoaderWrapper } from "am-web-ui-shared";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// log event for asset side panel, for battery and alert, this will not be processed
const toggleLocationDetails = (data: IAssetDetailsData, isExpanded: boolean) => {
  if (isExpanded) {
    trackLocationDetails(data);
  }
};

const SidePanelContent = styled.div`
  position: relative;
  #commonLoading {
    top: 62px;
  }
`;

export const LocationDetails = (props: ILocationDetailsProps) => {
  const {
    item,
    onBeforeRedirect,
    forceFetch,
    showToggleIcon = false,
    isPanelExpanded = false,
    hasLocationMap = true,
  } = props;

  const { t } = useTranslation();

  const languageCode = useSelector(({ UserSetting }: RootState) => UserSetting?.userSettingData?.applyLanguageCode);

  const info = getLocationInfo(item, forceFetch);

  const { position: locationDetails, status } = usePositionData(item, info.isInvalidLocation || !hasLocationMap) || {
    position: undefined,
  };

  const address = useReverseGeocode(locationDetails, languageCode);

  const dispatch: AppDispatch = useDispatch();

  const clear = (source) => dispatch(clearAllSelection(source));

  const homeAndAssignedTo = [
    () => (
      <FieldWithTooltipAndRedirect
        isSummary={true}
        value={getDefaultLocationName(item)}
        label="common:LABEL_HOME"
        redirectionDetails={getRedirectionForDefaultLocationStorageAsset(item)}
        onBeforeRedirect={onBeforeRedirect}
      />
    ),
    () => (
      <FieldWithTooltipAndRedirect
        isSummary={true}
        value={getCurrentLocationName(item)}
        label="common:LABEL_ASSIGNED_TO"
        redirectionDetails={getRedirectionForCurrentLocationStorageAsset(item)}
        onBeforeRedirect={onBeforeRedirect}
      />
    ),
  ];

  let summaryComponentsList = [() => <LocationTitle />, ...homeAndAssignedTo];
  let detailComponentsList = [];

  // show only default location and current location
  if (info?.isInvalidLocation) {
    if ("storageLocation" in item && info?.isStorageLocation) {
      detailComponentsList = [() => <StorageLocation value={item.storageLocation} />];
    }
    return (
      <UpfrontExpansionPanel
        panelId="locationDetailsSection"
        detailsData={item}
        summaryComponents={summaryComponentsList}
        detailComponents={detailComponentsList}
        isEnable={detailComponentsList.length > 0}
        showToggleIcon={showToggleIcon}
        isPanelExpanded={isPanelExpanded}
        onPannelToggle={toggleLocationDetails}
      />
    );
  }

  const getSummaryAndDetailComponents = () => {
    const serviceProviderCode = HelperLodash.get(locationDetails, "serviceProviderCode", "");
    if (info?.isVanOrHeOrOstOrThingType || info?.isFoundAlert || info?.isGeoTaggedAsset) {
      detailComponentsList = [
        () => <StorageLocation value={"storageLocation" in item && item.storageLocation} />,
        () => <ConfirmedLocationName trackingPayload={locationDetails} />,
        () => (
          <FieldWithTooltipAndRedirect
            value={getSource(serviceProviderCode, locationDetails)}
            label="common:LABEL_LAST_SEEN_LOCATION_SOURCE"
            redirectionDetails={getRedirectionDetails(serviceProviderCode, info?.assetId, locationDetails)}
            onBeforeRedirect={onBeforeRedirect}
            clearAllSelection={clear}
          />
        ),
        () => <AssetPosition tool={item} trackingPayload={locationDetails} />,
        () => <Address address={address} />,
        () => <LastSeen trackingPayload={locationDetails} />,
        () => <TrackingGateways trackingPayload={locationDetails} />,
        () => <TrackingByHeavyEquipment trackingPayload={locationDetails} />,
      ];
    }
    if (info?.isPbeAlert) {
      summaryComponentsList = [
        () => <LocationTitle />,
        ...homeAndAssignedTo,
        () => (
          <FieldWithTooltipAndRedirect
            value={getLocationSource(serviceProviderCode)}
            label="common:LABEL_LAST_SEEN_LOCATION_SOURCE"
            redirectionDetails={getRedirectionObjectForCharger(locationDetails, item?.assetId)}
            onBeforeRedirect={onBeforeRedirect}
          />
        ),
        () => <AssetPosition tool={item} trackingPayload={locationDetails} />,
        () => <Address address={address} />,
        () => <LastSeen trackingPayload={locationDetails} />,
      ];
    }
    if (info?.isBattery) {
      const ownerShipType = HelperLodash.get(item, "ownerShipTypeResponse.value", null);
      summaryComponentsList = [
        () => <AssetPosition tool={item} trackingPayload={locationDetails} />,
        () => <Address address={address} />,
        ...homeAndAssignedTo,
        () => <OwnerShipType value={ownerShipType} />,
        () => (
          <FieldWithTooltipAndRedirect
            value={getLocationSource(serviceProviderCode)}
            label="common:LABEL_LAST_SEEN_LOCATION_SOURCE"
            redirectionDetails={getRedirectionObjectForCharger(locationDetails, item?.assetId)}
            onBeforeRedirect={onBeforeRedirect}
          />
        ),
        () => <LastSeen trackingPayload={locationDetails} />,
      ];
    }
    // OST asset is tracked by VAN OR van details will be off when feature flag is off
    if (
      ((isTrackedByVAN(locationDetails) || locationDetails?.serviceProviderCode === ServiceProviderCode.Van) &&
        !isAssetTrackedByVanEnabled()) ||
      !hasLocationMap
    ) {
      summaryComponentsList = [() => <LocationTitle />, ...homeAndAssignedTo];
      detailComponentsList = [];
    }
    return {
      summaryComponents: summaryComponentsList,
      detailComponents: detailComponentsList,
    };
  };

  const { summaryComponents, detailComponents } = getSummaryAndDetailComponents();

  return status === "loaded" && locationDetails ? (
    <UpfrontExpansionPanel
      panelId="locationDetailsSection"
      detailsData={item}
      summaryComponents={summaryComponents}
      detailComponents={detailComponents}
      isEnable={detailComponents.length > 0}
      showToggleIcon={showToggleIcon}
      isPanelExpanded={isPanelExpanded}
      onPannelToggle={toggleLocationDetails}
    />
  ) : (
    <SidePanelContent id="sidePanelContent">
      <LoaderWrapper loadingText={t("common:LOADING")} activity={true} />
    </SidePanelContent>
  );
};

export default React.memo(LocationDetails);
