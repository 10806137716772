import { compose } from "redux";
import { createStore, IExtension, IModule } from "redux-dynamic-modules";
import { getSagaExtension } from "redux-dynamic-modules-saga";
import rootModule from "./rootModule";
import masterDataModule from "../modules/masterData/masterDataModule";
import userSettingModule from "../modules/userSetting/redux/userSettingModule";
import { _companyModule } from "../modules/company/companyModule";
import { loaderModule } from "../modules/loader/loaderModule";
import documentModule from "../modules/document/documentModule";
import userLicenseModule from "../modules/licenseAndUsageInformation/redux/userLicenseModule";
import loginModule from "../modules/login/redux/loginModule";
import filterPanelModule from "../modules/filterPanel/redux/filterPanelModule";
import toastrModule from "../modules/toastr/redux/toastrModule";
import ActivityTracker from "../modules/activityTracker/redux/activityTrackerModule";
import userModule from "../modules/user/redux/userModule";
import lockedFeatureInfoModule from "../modules/lockedFeatureInfo/lockedFeatureInfoModule";
import { setTransferCartItem } from "../actions/transferCartActions";
import headerCountModule from "../modules/headerCount/headerCountModule";
import uniteModule from "../modules/apiAccess/redux/uniteModule";
import { useDispatch } from "react-redux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

export function reduxModuleExtension(): IExtension {
  return {
    onModuleAdded: (module: IModule<any>) => {
      if (module.id === "transferCartModule") {
        store?.dispatch(setTransferCartItem());
      }
    },
  };
}
/**
 * @description Configures the redux store.
 * Store is created with root reducer along with the root Saga for Redux-Saga.
 *
 * @returns the store object which is used by the provider.
 */
export function configureStore(initialState = {}, modules = []) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    {
      initialState,
      enhancers: [],
      extensions: [getSagaExtension({}), reduxModuleExtension()],
      advancedComposeEnhancers: composeEnhancers,
    },
    /*
     * default modules
     */
    rootModule,
    loaderModule,
    loginModule,
    ActivityTracker,
    toastrModule,
    filterPanelModule,
    masterDataModule,
    documentModule,
    userSettingModule,
    _companyModule,
    userLicenseModule,
    userModule,
    lockedFeatureInfoModule,
    headerCountModule,
    uniteModule,
    ...modules,
  );
}

const store = configureStore();

/*
*
  `any` to be replaced by union of each module states, once all modules are done
  IMasterDataModuleState
  ILoaderModuleState
  ....

  eg: RootState = ReturnType<typeof store.getState> & IMasterDataModuleState & ILoaderModuleState & ..

*/
export type RootState = any;
export type AppDispatch = ReturnType<typeof store.dispatch>["dispatch"];
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
