import { IGtmData } from "./models/iGTMModel";

declare global {
  interface Window {
    __DEBOUNCE: number;
    dataLayer: unknown[];
  }
}

export const getDebounceValue = () => {
  const DEFAULT_DEBOUNCE = 300;
  return window.__DEBOUNCE || DEFAULT_DEBOUNCE;
};

export const getParsedQueryParams = (queryString) => {
  const parsedQuery = {};
  if (queryString.indexOf("?") === 0) {
    queryString = queryString.substr(1);
  }
  queryString.split("&").forEach((q) => {
    const temp = q.split("=");
    parsedQuery[temp[0]] = temp[1];
  });
  return parsedQuery;
};

export const pushDataToGA = (event: string, error?: IGtmData, data?: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event, ...(error || {}), ...(data || {}) });
};

export const getGTMDataObject = (errorCode: number | string, errorMsg: string): IGtmData => ({ errorCode, errorMsg });

export const analyticsLicenseTypes = {
  OT: "ON!Track",
  FM: "ON!Track Ready",
};

export default { getDebounceValue, getParsedQueryParams, pushDataToGA, getGTMDataObject, analyticsLicenseTypes };
