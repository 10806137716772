export default class ChargeSetting {
  assetValue: number;
  code?: number | string;
  costTypeCode: string;
  costTypeName?: string;
  costTypeValue: number;
  costTypeValueDisplay: string;
  currency: string;
  error?: boolean;
  assetCapSetting: IAssetCapSetting;
  timeBasedDailyAssetCost: number;
  timeBasedMonthlyAssetCost: number;
  timeBasedMonthlyDays: number;
  timeBasedWeeklyAssetCost: number;
  timeBasedWeeklyDays: number;

  constructor(response: any) {
    this.assetValue = response.assetValue && response.assetValue.toString();
    this.code = response.code;
    this.costTypeCode = response.costTypeCode;
    this.costTypeName = response.costTypeName;
    this.costTypeValue = response.costTypeValue && response.costTypeValue.toString();
    this.costTypeValueDisplay = response.costTypeValueDisplay;
    this.currency = response.currency;
    this.error = response.error;
    this.code = response.code;
    this.assetCapSetting = response.assetCapSetting;
    this.timeBasedDailyAssetCost = response.timeBasedDailyAssetCost;
    this.timeBasedMonthlyAssetCost = response.timeBasedMonthlyAssetCost;
    this.timeBasedMonthlyDays = response.timeBasedMonthlyDays ? response.timeBasedMonthlyDays : undefined;
    this.timeBasedWeeklyAssetCost = response.timeBasedWeeklyAssetCost;
    this.timeBasedWeeklyDays = response.timeBasedWeeklyDays ? response.timeBasedWeeklyDays : undefined;
  }
}

export interface IChargeSettingError {
  code: number | string;
  error: boolean | string;
}

interface IAssetCapSetting {
  cappingType: string;
  alwaysFollowAssetCapSetting: string;
  cappingPercentage: string;
}
