import React from "react";
import styled from "styled-components";
import { IFieldWrapperProps } from "./iFieldWrapperProps";

export const FieldWrapper = styled.div`
  margin-bottom: 16px;
  &.no-margin {
    margin-bottom: 0;
  }
`;
export const LabelName = styled.label`
  width: 100%;
  color: ${(props) => props.theme.colors.base.steel};
  font-size: ${(props) => props.theme.fontSize.base};
`;
export const FieldName = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

export const FormFieldWrapper = (props: IFieldWrapperProps) => {
  return (
    <FieldWrapper className={props.className}>
      <LabelName> {props.labelName} </LabelName>
      {props.fieldName && <FieldName className={"fieldName"}> {props.fieldName}</FieldName>}
      {props.children}
    </FieldWrapper>
  );
};

export default FormFieldWrapper;

FormFieldWrapper.defaultProps = {
  cssSetting: {
    marginBottom: "16px",
  },
};
