// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import AccuracyCircleMap from "./accuracyCircleMap/container/accuracyCircleMapContainer";
import { IAssetBleMap } from "./model/iAssetBLEMap";
const MapWrapperDiv = styled.div`
  height: 150px;
  margin: 0 -16px 0;
  position: relative;
`;

/**
 * @description Asset BLE map, which renders map and its accuracy circle maps.
 */
const AssetBleMap: React.FC<IAssetBleMap> = (props: IAssetBleMap) => {
  const positionInfo = props.positionInfo || {
    position: { latitude: 0, longitude: 0, accuracy: 0, provider: "", altitude: 0 },
  };
  const {
    currentLocationName,
    inventoryNumber,
    lastUpdate,
    lastSeen,
    locationType,
    manufacturerModel,
    name,
    scanCode,
    statusCode,
    statusValue,
    scanCodeTypeDetails,
    assetSubType,
  } = props;
  const infoDetails = {
    currentLocationName,
    inventoryNumber,
    lastUpdate,
    lastSeen,
    locationType,
    manufacturerModel,
    name,
    scanCode,
    statusCode,
    statusValue,
    scanCodeTypeDetails,
  };
  return (
    <MapWrapperDiv id="bleMapWrapper">
      <AccuracyCircleMap
        infoDetails={infoDetails}
        mapPosition={positionInfo.position}
        assetSubType={assetSubType}
      ></AccuracyCircleMap>
    </MapWrapperDiv>
  );
};

export default AssetBleMap;
