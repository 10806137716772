import React from "react";
const SVG = () => (
  <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Web---Screen-5" transform="translate(-496.000000, -314.000000)">
        <g id="VAN" transform="translate(496.000000, 314.000000)">
          <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="54" height="54"></rect>
          <g id="van" transform="translate(7.714286, 15.428571)">
            <path
              d="M28.125,9.64285714 L32.9464286,16.875 L36.9642857,18.4821429 L36.9642857,27.3214286 L33.75,27.3214286 C33.75,29.98423 31.5913729,32.1428571 28.9285714,32.1428571 C26.26577,32.1428571 24.1071429,29.98423 24.1071429,27.3214286 L14.4642857,27.3214286 C14.4642857,29.98423 12.3056586,32.1428571 9.64285714,32.1428571 C6.98005567,32.1428571 4.82142857,29.98423 4.82142857,27.3214286 L1.60714286,27.3214286 L1.60714286,9.64285714 L28.125,9.64285714 Z M9.64285714,25.7142857 C8.75525665,25.7142857 8.03571429,26.4338281 8.03571429,27.3214286 C8.03571429,28.2090291 8.75525665,28.9285714 9.64285714,28.9285714 C10.5304576,28.9285714 11.25,28.2090291 11.25,27.3214286 C11.25,26.4338281 10.5304576,25.7142857 9.64285714,25.7142857 Z M28.9285714,25.7142857 C28.0409709,25.7142857 27.3214286,26.4338281 27.3214286,27.3214286 C27.3214286,28.2090291 28.0409709,28.9285714 28.9285714,28.9285714 C29.8161719,28.9285714 30.5357143,28.2090291 30.5357143,27.3214286 C30.5357143,26.4338281 29.8161719,25.7142857 28.9285714,25.7142857 Z M27.3214286,12.8571429 L24.1071429,12.8571429 L24.1071429,17.6785714 L30.5357143,17.6785714 L27.3214286,12.8571429 Z"
              id="🎨Color"
              fill="#757275"
            ></path>
            <rect id="Rectangle" opacity="0" x="0" y="0" width="38.5714286" height="38.5714286"></rect>
          </g>
          <g id="wifi_signal" transform="translate(15.428571, 0.000000)">
            <path
              d="M0.964285714,8.67857143 L2.89285714,10.6071429 C7.68535714,5.81464286 15.4575,5.81464286 20.25,10.6071429 L22.1785714,8.67857143 C16.3253571,2.82535714 6.82714286,2.82535714 0.964285714,8.67857143 Z M8.67857143,16.3928571 L11.5714286,19.2857143 L14.4642857,16.3928571 C12.8732143,14.7921429 10.2792857,14.7921429 8.67857143,16.3928571 Z M4.82142857,12.5357143 L6.75,14.4642857 C9.41142857,11.8028571 13.7314286,11.8028571 16.3928571,14.4642857 L18.3214286,12.5357143 C14.5992857,8.81357143 8.55321429,8.81357143 4.82142857,12.5357143 Z"
              id="🎨Color"
              fill="#757275"
            ></path>
            <rect id="Rectangle" opacity="0" x="0" y="0" width="23.1428571" height="23.1428571"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
