import { HelperLodash } from "am-web-ui-shared/helpers";

export const StorageKeys = {
  BANNER_PREFERENCES: "bannerPreferences",
  USER_PREFERENCES: "userPreferences",
};
export const storageGetItem = (name) => {
  return localStorage.getItem(name);
};

export const storageSetItem = (name, data) => {
  localStorage.setItem(name, data);
};

export const storageClearItem = (name) => {
  localStorage.removeItem(name);
};

export const storageClearAll = (clearUserPreferences?) => {
  let userPreferences;
  if (!clearUserPreferences) {
    userPreferences = storageGetObject(StorageKeys.USER_PREFERENCES);
  }
  localStorage.clear();
  storageSetObject(StorageKeys.USER_PREFERENCES, userPreferences);
};

export const setCurrentUserPreferences = (prefernce?, value?) => {
  const userPreferences = storageGetObject(StorageKeys.USER_PREFERENCES) || {};
  if (value) {
    HelperLodash.set(userPreferences, prefernce, value);
  } else {
    delete userPreferences[`${prefernce}`];
  }
  storageSetObject(StorageKeys.USER_PREFERENCES, userPreferences);
};

export const getCurrentUserPreferences = (prefernce?) => {
  const currentUserPreferences = storageGetObject(StorageKeys.USER_PREFERENCES);
  return prefernce && currentUserPreferences
    ? HelperLodash.get(currentUserPreferences, prefernce)
    : currentUserPreferences;
};

/**
 * @description To get column configuration of different modules
 * @param configType Type to handle column config
 */
export const storageGetObject = (configType: string) => {
  const obj = storageGetItem(configType);
  return obj && JSON.parse(obj);
};
/**
 * @description To set column configuration for different modules list
 * @param configType Configuration of column
 */
export const storageSetObject = (configType: string, data: object) => {
  storageSetItem(configType, JSON.stringify(data));
};

/*
  Session storage utils
*/

export const sessionStorageGetItem = (name) => {
  return sessionStorage.getItem(name);
};

export const sessionStorageSetItem = (name, data: string) => {
  sessionStorage.setItem(name, data);
};

export const sessionStorageClearItem = (name) => {
  sessionStorage.removeItem(name);
};

export const sessionStorageClearAll = () => {
  sessionStorage.clear();
};

/**
 * @description To get column configuration of different modules
 * @param configType Type to handle column config
 */
export const sessionStorageGetObject = (name: string) => {
  return JSON.parse(sessionStorageGetItem(name));
};
/**
 * @description To set column configuration for different modules list
 * @param configType Configuration of column
 */
export const sessionStorageSetObject = (name: string, data: object) => {
  sessionStorageSetItem(name, JSON.stringify(data));
};

export const setCurrentPreferencesByKey = (preferenceKey, prefernce?, value?) => {
  const bannerPreferences = storageGetObject(preferenceKey) || {};
  if (value) {
    HelperLodash.set(bannerPreferences, prefernce, value);
  } else {
    delete bannerPreferences[`${prefernce}`];
  }
  storageSetObject(StorageKeys.BANNER_PREFERENCES, bannerPreferences);
};

export const getCurrentPreferencesbyKey = (preferenceKey, prefernce?) => {
  const currentBannerPreferences = storageGetObject(preferenceKey);
  return prefernce && currentBannerPreferences
    ? HelperLodash.get(currentBannerPreferences, prefernce)
    : currentBannerPreferences;
};
