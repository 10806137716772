import React from "react";
const SVG = () => (
  <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3237.000000, -2755.000000)" fill="#524F53">
        <g transform="translate(3235.000000, 2752.000000)">
          <path
            d="M11.9798394,3 L21.9881577,13 L19,12.999 L19,21 L14,21 L14,16 L10,16 L10,21 L5,21 L5,12.999 L2,13 L11.9798394,3 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
