import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { colorPalette } from "../../materials";
import ITooltipNoArrowWhiteBGProps from "./iTooltipNoArrowWhiteBG";

const styles = () => ({
  bootstrapTooltip: {
    borderRadius: 0,
    fontFamily: "inherit",
    fontSize: "14px",
    lineHeight: "17px",
    padding: "10px",
    textAlign: "left" as "left",
    wordBreak: "break-all" as "break-all",
    backgroundColor: colorPalette.white,
    boxShadow: "2px 2px 7px 1px gray",
    color: colorPalette.black,
    maxWidth: "250px",
  },

  bootstrapPlacementBottom: {
    margin: "8px 20px 8px 0",
  },
  bootstrapPlacementLeft: {
    margin: "0 8px",
  },
  bootstrapPlacementRight: {
    margin: "0 8px",
  },
  bootstrapPlacementTop: {
    margin: "8px 20px 8px 0",
  },
});
export const TooltipNoArrowWhiteBG = (props: ITooltipNoArrowWhiteBGProps) => {
  const { classes, children, id, style, tooltipValue, showTooltip } = props;
  return showTooltip ? (
    <Tooltip
      id={`${id}Tooltip`}
      title={<React.Fragment>{tooltipValue}</React.Fragment>}
      classes={{
        tooltip: classes.bootstrapTooltip,
        tooltipPlacementBottom: classes.bootstrapPlacementBottom,
        tooltipPlacementLeft: classes.bootstrapPlacementLeft,
        tooltipPlacementRight: classes.bootstrapPlacementRight,
        tooltipPlacementTop: classes.bootstrapPlacementTop,
      }}
    >
      <span className="text" style={{ ...style }}>
        {children}
      </span>
    </Tooltip>
  ) : null;
};

export default withStyles(styles)(TooltipNoArrowWhiteBG);
