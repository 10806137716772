const ManufacturerConstants = {
  GET_MANUFACTURER_LIST_FOR_SEARCH: "GET_MANUFACTURER_LIST_FOR_SEARCH",
  MANUFACTURER_LIST_LOADER_END: "MANUFACTURER_LIST_LOADER_END",
  MANUFACTURER_LIST_LOADER_START: "MANUFACTURER_LIST_LOADER_START",
  MANUFACTURER_RESET_QUERY_PARAM: "MANUFACTURER_RESET_QUERY_PARAM",
  SELECT_ALL_ROWS: "SELECT_ALL_ROWS",
  SET_MANUFACTURER_SEARCH_QUERY: "SET_MANUFACTURER_SEARCH_QUERY", // action to set search query in redux state
  SET_MANUFACTURER_SORT_QUERY: "SET_MANUFACTURER_SORT_QUERY",
};

export default ManufacturerConstants;
