/**
 * @description Action constants to be used across the project
 *
 * format: {feature}_{action}
 */
const AssetActionConstants = {
  // action to get asset list in asset's page
  AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE: "AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE",
  AM_ASSETS_SET_SELECTED_ROW: "AM_ASSETS_SET_SELECTED_ROW",
  AM_ASSET_ADD_EDIT: "AM_ASSET_ADD_EDIT", //  use to call api for edit and add asset
  AM_ASSET_ASSOCIATE_LABELS_RESPONSE: "AM_ASSET_ASSOCIATE_LABELS_RESPONSE",
  AM_ASSET_COMMON_ATTRIBUTES: "AM_ASSET_COMMON_ATTRIBUTES",
  AM_ASSET_DETAILS: "AM_ASSET_DETAILS",
  AM_ASSET_LABELS_LIST_RESPONSE: "AM_ASSET_LABELS_LIST_RESPONSE",
  AM_ASSET_LIST_LOADER_END: "AM_ASSET_LIST_LOADER_END", // loader for asset list
  AM_ASSET_LIST_LOADER_START: "AM_ASSET_LIST_LOADER_START", // loader for asset list
  AM_ASSET_RESET_QUERY_PARAM: "AM_ASSET_RESET_QUERY_PARAM", // Reset search and sort query to default
  AM_ASSET_SELECT_GRID_CHECKBOX: "AM_ASSET_SELECT_GRID_CHECKBOX",
  AM_ASSET_SELECT_ROW: "AM_ASSET_SELECT_ROW",
  AM_ASSET_SET_COMMON_ATTRIBUTES: "AM_ASSET_SET_COMMON_ATTRIBUTES",
  AM_ASSET_UNSELECT_GRID_CHECKBOX: "AM_ASSET_UNSELECT_GRID_CHECKBOX",
  AM_ASSET_UNSELECT_ROW: "AM_ASSET_UNSELECT_ROW",
  AM_ASSOCIATE_ASEET_LABEL: "AM_ASSOCIATE_ASEET_LABEL",
  AM_ASSOCIATE_ASEET_LABEL_DETAILS: "AM_ASSOCIATE_ASEET_LABEL_DETAILS",
  AM_ASSOCIATE_LABELS_WITH_ASSET: "AM_ASSOCIATE_LABELS_WITH_ASSET",
  AM_ASSOCIATE_LABELS_WITH_ASSET_RESPONSE: "AM_ASSOCIATE_LABELS_WITH_ASSET_RESPONSE",
  AM_CANCEL_TRANSFER: "AM_CANCEL_TRANSFER",
  AM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA: "AM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA",
  AM_CLEAR_DEFAULT_GRID_FIELDS: "AM_CLEAR_DEFAULT_GRID_FIELDS",
  AM_CREATE_ASEET_LABEL: "AM_CREATE_ASEET_LABEL",
  AM_CREATE_ASEET_LABEL_RESPONSE: "AM_CREATE_ASEET_LABEL_RESPONSE",
  AM_CREATE_CUSTOM_VIEW: "AM_CREATE_CUSTOM_VIEW",
  AM_CREATE_CUSTOM_VIEW_RESPONSE: "AM_CREATE_CUSTOM_VIEW_RESPONSE",
  AM_DELETE_ASEET_LABEL: "AM_DELETE_ASEET_LABEL",
  AM_DELETE_ASEET_LABEL_RESPONSE: "AM_DELETE_ASEET_LABEL_RESPONSE",
  AM_DELETE_CUSTOM_VIEW: "AM_DELETE_CUSTOM_VIEW",
  AM_GET_ASSET_CUSTOM_VIEW: "AM_GET_ASSET_CUSTOM_VIEW",
  AM_GET_ASSET_CUSTOM_VIEW_FAILURE: "AM_GET_ASSET_CUSTOM_VIEW_FAILURE",
  AM_GET_ASSET_CUSTOM_VIEW_SUCCESS: "AM_GET_ASSET_CUSTOM_VIEW_SUCCESS",
  AM_GET_ASSET_FILTER_FIELD: "AM_GET_ASSET_FILTER_FIELD",
  AM_GET_ASSET_TRANSFER_DETAILS: "AM_GET_ASSET_TRANSFER_DETAILS",
  AM_GET_CHECKED_ASSET_TRANSFER_DETAILS: "AM_GET_CHECKED_ASSET_TRANSFER_DETAILS",
  AM_GET_CHILD_ASSETS: "AM_GET_CHILD_ASSETS",
  AM_GET_LABELS_LIST: "AM_GET_LABELS_LIST",
  AM_GET_SYSTEM_VIEW: "AM_GET_SYSTEM_VIEW",
  AM_GET_SYSTEM_VIEW_SUCCESS: "AM_GET_SYSTEM_VIEW_SUCCESS",
  AM_MULTI_EDIT_ASSET: "AM_MULTI_EDIT_ASSET",
  AM_RECENTLY_USED_TEMPLATES_RESPONSE: "AM_RECENTLY_USED_TEMPLATES_RESPONSE",
  AM_RENAME_CUSTOM_VIEW: "AM_RENAME_CUSTOM_VIEW",
  AM_REORDER_ASSET_CUSTOM_VIEW: "AM_REORDER_ASSET_CUSTOM_VIEW",
  AM_REORDER_ASSET_CUSTOM_VIEW_SUCCESS: "AM_REORDER_ASSET_CUSTOM_VIEW_SUCCESS",
  AM_RESET_ORIGINAL_VIEW: "AM_RESET_ORIGINAL_VIEW",
  AM_SELECT_ALL_ROWS: "AM_SELECT_ALL_ROWS",
  AM_SET_ASSET_FILTER_APPLIED_FIELD: "AM_SET_ASSET_FILTER_APPLIED_FIELD",
  AM_SET_DEFAULT_CUSTOM_VIEW: "AM_SET_DEFAULT_CUSTOM_VIEW",
  AM_SET_LABEL_UPDATED: "AM_SET_LABEL_UPDATED",
  AM_UPDATE_ASSET_CUSTOM_VIEW: "AM_UPDATE_ASSET_CUSTOM_VIEW",
  AM_UPDATE_ASSET_CUSTOM_VIEW_RESPONSE: "AM_UPDATE_ASSET_CUSTOM_VIEW_RESPONSE",
  AM_UPDATE_ASSET_STATUS: "AM_UPDATE_ASSET_STATUS",
  AM_UPDATE_STORAGE_ASSET_GATEWAY_STATUS: "AM_UPDATE_STORAGE_ASSET_GATEWAY_STATUS",
  ASSET_ADD_EDIT_RESPONSE: "ASSET_ADD_EDIT_RESPONSE", // use to capture a api response
  ASSET_FILTER_FIELD_SUCCESS: "ASSET_FILTER_FIELD_SUCCESS",
  ASSET_LOCATIONS_RESPONSE: "ASSET_LOCATIONS_RESPONSE",
  CLEAN_ASSET_DATA: "CLEAN_ASSET_DATA",
  CLEAN_LABEL_DATA: "CLEAN_LABEL_DATA",
  CLEAN_TEMPLATE_DATA: "CLEAN_TEMPLATE_DATA",
  CLEAR_ALL_ASSETS_SELECTION: "CLEAR_ALL_ASSETS_SELECTION",
  CLEAR_ASSET_DETAILS: "CLEAR_ASSET_DETAILS",
  CLEAR_SELECTED_ASSET_TRANSFER_HISTORY: "CLEAR_SELECTED_ASSET_TRANSFER_HISTORY",
  CREATE_ASSET_ERROR: "CREATE_ASSET_ERROR",
  CREATE_ASSET_RESPONSE: "CREATE_ASSET_RESPONSE",
  DELETE_LABELS: "DELETE_LABELS",
  GET_ACTIVE_TRACKING_PAYLOAD: "GET_ACTIVE_TRACKING_PAYLOAD",
  GET_ASSET_DEFAULT_LOCATION: "GET_ASSET_DEFAULT_LOCATION",
  GET_ASSET_LIST: "GET_ASSET_LIST",
  GET_ASSET_LIST_FOR_SEARCH: "GET_ASSET_LIST_FOR_SEARCH",
  GET_ASSET_LOCATION: "GET_ASSET_LOCATION",
  GET_CHILD_ASSETS_SUCCESS: "GET_CHILD_ASSETS_SUCCESS",
  GET_DEFAULT_LOCATION: "GET_DEFAULT_LOCATION",
  GET_SELECT_ALL_ASSETS: "GET_SELECT_ALL_ASSETS",
  MULTI_EDIT_RESPONSE: "MULTI_EDIT_RESPONSE",
  PUT_ASSET_LIST: "PUT_ASSET_LIST",
  REFRESH_ASSETS: "REFRESH_ASSETS",
  REFRESH_ASSETS_LIST: "REFRESH_ASSETS_LIST",
  RESET_ASSET_DEFAULT_LOCATIONS_LIST: "RESET_ASSET_DEFAULT_LOCATIONS_LIST",
  RESET_ASSET_LOCATIONS_LIST: "RESET_ASSET_LOCATIONS_LIST",
  RESET_INVENTORY_WORKERS_LIST: "RESET_INVENTORY_WORKERS_LIST",
  RESET_MULTI_DELETE_RESPONSE: "RESET_MULTI_DELETE_RESPONSE",
  RESET_MULTI_EDIT_RESPONSE: "RESET_MULTI_EDIT_RESPONSE",
  RESET_RESPONSIBLE_WORKERS_LIST: "RESET_RESPONSIBLE_WORKERS_LIST",
  SELECT_ALL_ASSETS_SUCCESS: "SELECT_ALL_ASSETS_SUCCESS",
  SET_ASSET_DELETE_RESPONSE: "SET_ASSET_DELETE_RESPONSE",
  SET_ASSET_DETAILS: "SET_ASSET_DETAILS",
  SET_ASSET_DETAILS_WITH_ID: "SET_ASSET_DETAILS_WITH_ID",
  SET_ASSET_FILTER_QUERY: "SET_ASSET_FILTER_QUERY",
  SET_ASSET_GROUPS_RESPONSE: "SET_ASSET_GROUPS_RESPONSE",
  SET_ASSET_LIST_FIELDS_QUERY: "SET_ASSET_LIST_FIELDS_QUERY",
  SET_ASSET_ROW_EXPAND_STATUS: "SET_ASSET_ROW_EXPAND_STATUS",
  SET_ASSET_SEARCH_QUERY: "SET_ASSET_SEARCH_QUERY", // action to set search query in redux state
  SET_ASSET_SORT_QUERY: "SET_ASSET_SORT_QUERY", // action to set sort query in redux state
  SET_ASSET_TRANSFER_DETAILS: "SET_ASSET_TRANSFER_DETAILS",
  SET_CHECKED_ASSET_DETAIL: "SET_CHECKED_ASSET_DETAIL",
  SET_CHECKED_ASSET_TRANSFER_DETAILS: "SET_CHECKED_ASSET_TRANSFER_DETAILS",
  SET_CUSTOM_VIEW_MODIFIED_FLAG: "SET_CUSTOM_VIEW_MODIFIED_FLAG",
  SET_INVENTORY_WORKERS_RESPONSE: "SET_INVENTORY_WORKERS_RESPONSE",
  SET_MANUFACTURER_AND_MODEL_RESPONSE: "SET_MANUFACTURER_AND_MODEL_RESPONSE",
  SET_MANUFACTURER_LIST_RESPONSE: "SET_MANUFACTURER_LIST_RESPONSE",
  SET_RESPONSIBLE_WORKERS_RESPONSE: "SET_RESPONSIBLE_WORKERS_RESPONSE",
  SET_SELECTED_VIEW: "SET_SELECTED_VIEW",
  SET_TEMPLATE_IMAGE_ID: "SET_TEMPLATE_IMAGE_ID",
  UPDATE_ASSET_DETAILS: "UPDATE_ASSET_DETAILS",
  UPDATE_CUSTOM_GRID_FIELDS: "UPDATE_CUSTOM_GRID_FIELDS",
  UPDATE_STATUS_ERROR: "UPDATE_STATUS_ERROR",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  GET_GPS_TAGS_SERIAL: "GET_GPS_TAGS_SERIAL",
  SET_GPS_TAGS_SERIAL: "SET_GPS_TAGS_SERIAL",
  UPDATE_ASSET_DETAILS_CHILD_GATEWAY_STATUS: "UPDATE_ASSET_DETAILS_CHILD_GATEWAY_STATUS",
  GET_ASSET_UTILIZATION_SUMMARY: "GET_ASSET_UTILIZATION_SUMMARY",
  SET_ASSET_UTILIZATION_SUMMARY: "SET_ASSET_UTILIZATION_SUMMARY",
  GET_ASSET_UTILIZATION_DETAILS: "GET_ASSET_UTILIZATION_DETAILS",
  SET_ASSET_UTILIZATION_DETAILS: "SET_ASSET_UTILIZATION_DETAILS",
  RESET_ASSET_UTILIZATION_SUMMARY: "RESET_ASSET_UTILIZATION_SUMMARY",
};

export default AssetActionConstants;
