import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2011.000000, -2291.000000)">
        <g transform="translate(2008.000000, 2287.000000)">
          <g>
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M17,11.5 L16.6572581,11.5 L15.7864071,13.6367027 L13.4157199,13.2789879 L11.6560373,16.1246835 L13.1020131,18.0193083 L11.5,19.2923005 L11.5,20 L11,20 L9,20 L9,15 L7,15 L7,20 L3,20 L3,4 L13,4 L13,8 L21,8 L21,11.5 L19,11.5 L19,10 L17,10 L17,11.5 Z M5,6 L5,8 L7,8 L7,6 L5,6 Z M9,6 L9,8 L11,8 L11,6 L9,6 Z M5,10 L5,12 L7,12 L7,10 L5,10 Z M9,10 L9,12 L11,12 L11,10 L9,10 Z M13,10 L13,12 L15,12 L15,10 L13,10 Z M19.5395139,14.4904068 C19.9311496,14.6362628 20.2919543,14.8454479 20.6094779,15.1055118 L22.0891485,14.6822358 L23.0302688,16.312304 L21.9347563,17.3715682 C21.9712513,17.5820062 21.9902732,17.7984359 21.9902732,18.0193083 C21.9902732,18.240955 21.9711177,18.4581276 21.934372,18.669261 L23.0330664,19.7316019 L22.0919461,21.3616701 L20.6054116,20.9364306 C20.2888752,21.1949525 19.9294787,21.4029762 19.5395139,21.5482099 L19.1669122,23.0386167 L17.2846716,23.0386167 L16.9120699,21.5482099 C16.5221051,21.4029762 16.1627087,21.1949525 15.8461722,20.9364306 L14.3596378,21.3616701 L13.4185175,19.7316019 L14.5172119,18.669261 C14.4804661,18.4581276 14.4613107,18.240955 14.4613107,18.0193083 C14.4613107,17.8000574 14.4800543,17.5851842 14.5160266,17.3762038 L13.4157199,16.312304 L14.3568402,14.6822358 L15.8406582,15.1066982 C16.1585326,14.846084 16.5198389,14.6364845 16.9120699,14.4904068 L17.2846716,13 L19.1669122,13 L19.5395139,14.4904068 Z M18.2257919,19.5878422 C19.0920693,19.5878422 19.7943258,18.8855857 19.7943258,18.0193083 C19.7943258,17.153031 19.0920693,16.4507745 18.2257919,16.4507745 C17.3595146,16.4507745 16.6572581,17.153031 16.6572581,18.0193083 C16.6572581,18.8855857 17.3595146,19.5878422 18.2257919,19.5878422 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
