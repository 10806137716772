import React from "react";
const SVG = () => (
  <svg width="21px" height="22px" viewBox="0 0 21 22" version="1.1">
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons-New-vs-Old" transform="translate(-3264.000000, -2260.000000)" fill="#524F53">
        <g id="cardMembership-Copy" transform="translate(3262.000000, 2258.000000)">
          <path
            d="M19,11 C21.209139,11 23,12.790861 23,15 C23,16.4801068 22.1961002,17.7724528 21.0011092,18.4642293 L21,24 L19,22 L17,24 L16.9998796,18.4648015 C15.8043432,17.7731656 15,16.480515 15,15 C15,12.790861 16.790861,11 19,11 Z M22,2 L22.000963,9.80325037 C21.1182253,9.29239547 20.093259,9 19,9 C15.6862915,9 13,11.6862915 13,15 C13,16.093259 13.2923955,17.1182253 13.8032504,18.000963 L2,18 L2,2 L22,2 Z M19,13 C17.8954305,13 17,13.8954305 17,15 C17,16.0962015 17.8819148,16.9864209 18.9749325,16.9998461 L19.025,16.999 L19.1492623,16.9945143 C20.1841222,16.9181651 21,16.0543618 21,15 C21,13.8954305 20.1045695,13 19,13 Z M12,9 L5,9 L5,11 L12,11 L12,9 Z M19,5 L5,5 L5,7 L19,7 L19,5 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
