import { createTheme } from '@mui/material';

import colors from './colors';
import fonts from './fonts';

declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary'];
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    default: PaletteOptions['primary'];
    tertiary: PaletteOptions['primary'];
  }

  interface Theme {
    colors: Partial<typeof colors>;
    fonts: typeof fonts;
    fontSizes: {
      xxs: number;
      xs: number;
      s: number;
      m: number;
      l: number;
      xl: number;
    };
  }

  interface ThemeOptions {
    colors: Partial<typeof colors>;
    fonts: typeof fonts;
    fontSizes: {
      xs: number;
      s: number;
      m: number;
      l: number;
      xl: number;
    };
  }
}

const theme = createTheme({
  fontSizes: {
    xs: 8,
    s: 12,
    m: 16,
    l: 20,
    xl: 24,
  },
  fonts,
  colors,
  typography: {
    fontFamily: fonts.normal.fontFamily,
    fontWeightRegular: fonts.normal.fontWeight,
    fontWeightBold: fonts.bold.fontWeight,
  },
  breakpoints: { values: { xs: 0, sm: 512, md: 900, lg: 1200, xl: 1536 } },
  palette: {
    default: {
      main: colors.steel[600],
      contrastText: colors.white[200],
    },
    primary: {
      main: colors.steel[600],
      contrastText: colors.white[200],
    },
    secondary: {
      main: colors.steel[600],
      contrastText: colors.white[200],
    },
    tertiary: {
      main: colors.black[50],
      contrastText: colors.white[200],
    },
    info: {
      main: colors.blue[50],
      contrastText: colors.blue[200],
    },
    warning: {
      main: colors.brown[500],
      contrastText: colors.yellow[50],
      dark: colors.orange[100],
    },
    error: {
      main: colors.hilti[400],
      contrastText: colors.hilti[200],
    },
    success: {
      main: colors.green[50],
      contrastText: colors.green[700],
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: colors.steel[600],
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiStep: {
      styleOverrides: {
        root: {
          flex: '1',
          justifyContent: 'flex-start',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  },
});

export default theme;

export type HiltiTheme = typeof theme;
