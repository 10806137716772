import { gridUtils } from "am-web-ui-shared/components";
import { AuthorizationCore } from "am-web-ui-shared/helpers";
import protectedRoutes from "./protectedRoutes";
import publicRoutes from "./publicRoutes";
import enterpriseRoutes from "./enterpriseRoutes";

interface IRoute {
  checkModuleActivation: (code: string) => boolean;
  dependencies: object;
  shouldDisplayRoute: (route: { component: any; path: string; dependencies?: any }) => boolean;
}
class Routes implements IRoute {
  checkModuleActivation = (code) => {
    return AuthorizationCore.hasActiveModule(code);
  };

  checkPermission = (permission) => {
    return AuthorizationCore.checkPermission(permission);
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  dependencies = {
    allowedPermission: this.checkPermission,
    moduleActivated: this.checkModuleActivation,
  };

  shouldDisplayRoute = (route) => {
    let isDisplay = true;
    if (route.dependencies) {
      for (const [key, value] of Object.entries(route.dependencies)) {
        isDisplay = this.resolveDependencies(key, value);
        if (!isDisplay) {
          return false;
        }
      }
    }
    return isDisplay;
  };

  resolveDependencies = (dependencyName, params) => {
    return gridUtils.dependencyResolver(this.dependencies, dependencyName)(params);
  };
}

const routes = new Routes();
export default routes;
export { enterpriseRoutes, publicRoutes, protectedRoutes };
