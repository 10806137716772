/**
 * @description - Interface for prompt component.
 *
 * @prop {any} icon - Prompt icon
 * @prop {any} handleClose - Close event handler.
 * @prop {any} header - Header of the prompt.
 * @prop {any} handleConfirm - Confirm event handler.
 * @prop {string} okLabel - Ok button text.
 * @prop {string} cancelLabel - Cancel button text.
 * @prop {boolean} showCancel - whether to show/ hide cancel button
 * @prop {any} headerComponent - custom header component
 * @prop {any} confirmBtnDisabled - disable confirm button
 * @prop {any} leftFooterComponentAction - a function to be triggered on leftFooterComponent click
 */
export default interface IPromptProps {
  readonly icon?: any;
  readonly className?: string;
  readonly dialogScrollClassName?: string;
  readonly crossIcon?: boolean;
  readonly imageUrl?: string;
  readonly salesLabel?: string;
  readonly learnMoreLabel?: string;
  readonly handleClose?: any;
  readonly contactSalesUrl?: string;
  readonly learnMoreUrl?: string;
  readonly showLockedFeatureDialog?: boolean;
  readonly header?: any;
  readonly handleConfirm?: any;
  readonly okLabel?: JSX.Element | string;
  readonly okIcon?: string;
  readonly cancelLabel?: JSX.Element | string;
  readonly showCancel?: boolean;
  readonly showConfirm?: boolean;
  readonly headerComponent?: any;
  readonly leftFooterComponentAction?: () => void;
  readonly leftFooterComponentLabel?: JSX.Element | string;
  readonly confirmBtnDisabled?: boolean;
  readonly style?: any;
  readonly isHeader?: boolean;
  readonly wrapperStyleAttributes?: any;
  readonly outerStyle?: any;
  readonly showToolTip?: boolean;
  readonly toolTipText?: string;
  readonly headerWithCrossIcon?: boolean;
  readonly handleCrossClick?: () => void;
  readonly showCheckbox?: boolean;
  readonly checkboxChange?: () => void;
  readonly checkboxError?: boolean;
  readonly checkboxRequired?: boolean;
  readonly slimFooter?: boolean;
  readonly checkboxLabel?: JSX.Element;
  readonly checkboxValidationLabel?: string;
}

export enum LockedFeatureLink {
  LearnMore = "learn_more",
  ContactSales = "contact_sales",
}
