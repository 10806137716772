import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M15,2 L15,4 L12.5,4 L12.5004345,4.55009729 C13.5892344,4.77131415 14.4209832,5.70015597 14.4946823,6.83562431 L14.5,7 C14.5,8.20932545 13.6413382,9.21809967 12.5004345,9.44990271 L12.5,13 L22,13 L22,23 L2,23 L2,13 L11.5,13 L11.500574,9.4501074 C10.4135206,9.22971378 9.58246551,8.30401491 9.50578238,7.17138429 L9.5,7 L10.5,7 C10.5,7.82842712 11.1715729,8.5 12,8.5 C12.8284271,8.5 13.5,7.82842712 13.5,7 C13.5,6.22030388 12.9051119,5.57955132 12.14446,5.50686658 L12,5.5 L11.5,5.5 L11.5,4 L9,4 L9,2 L15,2 Z M7,15 L5,15 L5,21 L7,21 L7,15 Z M11,15 L9,15 L9,21 L11,21 L11,15 Z M15,15 L13,15 L13,21 L15,21 L15,15 Z M19,15 L17,15 L17,21 L19,21 L19,15 Z"></path>
    </g>
  </svg>
);

export default SVG;
