import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";
import { Radio } from "../../elements";
import { default as TextField } from "../../elements/Field/field";
import ICommonProps from "../commonInterfaceProps/iCommonProps";
import IconComponent from "../icons/iconComponent/iconComponent";
import { IconName } from "../icons/iconEnum";
import IRadioOrInfoIconProps from "./iRadioOrInfoIconProps";

const TextIconStyle = styled.div`
  display: flex;
`;

const GridStyle = styled(Grid)`
  position: relative;
`;

const TextHeadingStyle = styled(TextField)`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  margin-bottom: 2px;
`;

const TextValueStyle = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 17px;
  text-align: left;
  margin-right: 4px;
`;

/**
 * common Interface implementaion
 */
type RadioOrInfoIconProps = ICommonProps & IRadioOrInfoIconProps;

/**
 * @description - A class for Radio or Info Icon Component
 *
 * @class RadioOrInfoIcon
 * @extends {React.PureComponent<RadioOrInfoIconProps, {}>}
 */
class RadioOrInfoIcon extends React.PureComponent<RadioOrInfoIconProps, {}> {
  render() {
    const { id, label, value, name, isFormControl, showToolTip, textHeading, textValue, place, getContent } =
      this.props;
    if (!showToolTip) {
      return (
        <Radio
          id={id}
          radioLabel={label}
          radioValue={value}
          name={name}
          isFormControl={isFormControl}
          onSelectionChange={this.props.onChange}
        />
      );
    } else {
      return (
        <GridStyle item={true} xs={12}>
          <TextHeadingStyle name={textHeading} htmlFor={textHeading} label={textHeading}>
            <TextIconStyle>
              <TextValueStyle>{textValue}</TextValueStyle>
              <IconComponent icon={IconName.Info} id={id} isIconDisabled={true} getContent={getContent} place={place} />
            </TextIconStyle>
          </TextHeadingStyle>
        </GridStyle>
      );
    }
  }
}

export default RadioOrInfoIcon;
