import React from "react";

const SVG = () => (
  <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3238.000000, -2358.000000)" fill="#524F53">
        <g transform="translate(3235.000000, 2356.000000)">
          <path
            d="M23 14v3l-1.5 1.5L23 20v3h-6v-3l1.5-1.5L17 17v-3h6zm-2-5v2.999L15 12v5.5l1.5 1-1.5 1-.001 1.5H3V9h18zm-8 7h-2v2h2v-2zm-5 0H6v2h2v-2zm0-4H6v2h2v-2zm5 0h-2v2h2v-2zM8 2v2h8V2h3v2h2v3H3V4h2V2h3z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
