import React from "react";
const SVG = () => (
  <svg width="4px" height="16px" viewBox="0 0 4 16" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3540.000000, -2524.000000)" fill="#524F53">
        <g transform="translate(3530.000000, 2520.000000)">
          <path
            d="M10,10 L14,10 L14,14 L10,14 L10,10 Z M10,16 L14,16 L14,20 L10,20 L10,16 Z M10,4 L14,4 L14,8 L10,8 L10,4 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
