import React from "react";
const SVG = () => (
  <svg width="22px" height="18px" viewBox="0 0 22 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2055.000000, -2622.000000)" fill="#524F53" fillRule="nonzero">
        <g transform="translate(2053.000000, 2619.000000)">
          <path
            d="M15.999867,17 C17.1043326,16.9999265 17.9997796,17.8951353 18,18.9996009 C18,19.5301397 17.7892443,20.038949 17.4140967,20.4140967 C17.038949,20.7892443 16.5301397,21 15.9996009,21 C14.8951353,20.9997796 13.9999265,20.1043326 14,18.999867 C14.0000735,17.8954014 14.8954014,17.0000735 15.999867,17 Z M6.99986696,17 C8.10433255,16.9999265 8.99977957,17.8951353 9,18.9996009 C9,20.1043908 8.1043908,21 6.99960086,21 C5.89513529,20.9997796 4.99992652,20.1043326 5,18.999867 C5.00007348,17.8954014 5.89540137,17.0000735 6.99986696,17 Z M24,3 L24,6.34457865 L21,8 L21,16 L2,16 L2,7.45797753 L4.96370482,6 L5,13.0786517 L18,13.0786517 L18,6.34457865 L24,3 Z M13,3 L13,6 L16,6 L16,9 L13,9 L13,12 L10,12 L10,9 L7,9 L7,6 L10,6 L10,3 L13,3 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
