import { IconName, IGridSetting } from "am-web-ui-shared/components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import { ImageSizeEnum } from "../../../../../../common/components/styled/squareImage/imageSizeUtil";
import squareImageContainer from "../../../../../../common/components/styled/squareImage/squareImageContainer";
import ImageTypeEnum from "../../../../../utils/enums/imageTypeEnum";
export const assetsGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          style: {
            color: `${theme.colors.base.black}`,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],

      cellSize: 4,
      image: {
        attributes: {
          image: { maxWidth: ImageSizeEnum.SMALL, maxHeight: ImageSizeEnum.SMALL },
          wrapper: {
            height: ImageSizeEnum.SMALL,
            imageSize: 42,
            style: { marginRight: "16px" },
            width: ImageSizeEnum.SMALL,
          },
        },
        component: squareImageContainer,
        defaultImage: IconName.Category,
        dependencies: {
          getImage: "getImageField",
          getAlternateDefaultImage: "getAlternateDefaultImage",
        },
        fieldName: "assetImageId",
        selfLoadImage: true,
        type: ImageTypeEnum.thumbnail,
      },
      isSortable: true,
      type: "imageFieldCombination",
    },
    {
      cellSize: 3,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      cellSize: 2,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      bindProperties: [
        {
          properties: [{ name: "assetStatusName" }],
        },
      ],
      cellSize: 3,
      component: "getAssetsStatus",
      isSortable: false,
      type: "custom",
    },
  ],
  defaultQueryFields: [
    "assetState",
    "assetStatus",
    "assetType",
    "assetSubType",
    "batteryStatus",
    "currentLocationName",
    "gatewayStatus",
    "inventoryNumber",
    "isCriticalFlag",
    "manufacturerName",
    "model",
    "name",
    "path",
    "quantity",
    "responsibleEmployeeName",
    "scanCode",
    "currentLocationId",
    "responsibleEmployeeId",
    "lastTransferDate",
    "serialNumber",
    "materialNumber",
    "hiltiAssetStatus",
    "isSapAsset",
    "ccmsNumber",
    "hiltiAssetOwnerName",
    "idleTimeDuration",
    "ownerShipType",
    "fleetExchangeStatus",
    "monitoringState",
    "originCustomerId",
  ],
  rowSettings: {
    customInfo: {
      additionalText: "getAddtionalText",
      chevronIconPosition: "last",
      childCountKey: "childCountWithQTYItem",
      expandRowHandler: "showStorageAssetChildren",
      fetchChildData: "getChildData",
      loadMoreRecordsText: "common:SHOW_MORE",
      rowLinkCount: "getRowLinkCount",
      rowLinkText: "getRowLinkText",
      templateDependency: "getRowTemplate",
      textLink: "assets:STORED_ASSETS",
    },
    isExpendClickable: true,
    key: "containsAnyAsset",
  },
  showTooltip: true,
  lazyLoadList: true,
};

export const ignoreSortProperties = ["labelNames", "toolLastSeen", "lastSeenBy"];
