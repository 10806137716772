const YEAR_2016 = 2016;

export const appConstants = {
  API_DEFAULT_TIMEOUT: 2000,
  ASSET_GROUP_HEADER_HEIGHT: 327, // header height for asset group
  COMPANY: "/company",
  CONTACT: "/contact",
  FAILED: "failed",
  FILE_ERROR_MSG: "completed with errors",
  FILE_SUCCESS: "Completed",
  FORM_WIDTH: 992,
  GROUPS_HEADER_HEIGHT: 365, // header height which includes header plus search bar.
  GROUPS_HEADER_HEIGHT_FOR_NON_ADMIN: 374, // header height which includes header plus search bar.
  GROUP_LIST_BOTTOM_EMPTY_SPACE: 36, // as per UX, the bottom empty in groups will be 36px to make clickable area
  HEADER_HEIGHT: 334, // header height which includes header only.
  HOUR_INTO_MILLISECONDS: 3600000, // 60 (minutes per hour) * 60 (seconds per minute) * 1000 (milliseconds per second)
  IS_DOMAIN_USER: "isDomainuser",
  IS_TNC_ACCEPTED: "isTncAccepted",
  LABEL_HEADER_HEIGHT: 188, // header height for label side panel
  LAST_REFRESH: "lastRefresh",
  LOGIN: "login",
  MARKETING_WEBSITE_URL: "http://www.hilti.com",
  MAX_ALERT_BADGE_COUNT: 99,
  MAX_ALERT_SELECTION_COUNT: 50,
  MAX_FILTER_OPTION_COUNT: 12,
  MAX_LABEL_COUNT_ALLOWED: 50,
  MODAL_FOOTER_HEIGHT: 64,
  MODAL_SMALL: 488,
  PLUG_COST_DATE_OCCURED_MIN_DATE: new Date(YEAR_2016, 0, 1), // Date occured of plug cost can not be earlier than January 1st 2016
  REDIRECT_TO_LOGIN: "login",
  STORE_KEY: "lastAction",
  SUCCESS: "success",
  TIMEOUT: "timeout",
  TIMER: "activeTimer",
  TIME_TO_REFRESH: "timeToRefresh",
  TODAY: "TODAY",
  TOKEN_REFRESH_TIME: "tokenRefreshTime",
  TOKEN_RESPONSE: "tokenResponse",
  TOOL_RETURN_ALERT_GROUP: "TOOL_RETURN_ALERT_GROUP",
  TRANSFER_ITEM: "TRANSFER_ITEM",
  TRANSFER_CART_ID: "TRANSFER_CART_ID",
  UNGROUPED: "ungrouped",
  USER_ROLE: "userRole",
  USER_SETTING: "/userSetting",
  USER_TOKEN: "userToken",
  LICENSE_TYPE: "licenseType",
  TENANT_CATEGORY: "tenantCategory",
  EXPIRES_IN: "expires_in",
  LICENSE_BUNDLE: "licenseBundle",
  MODULE_FEATURES: "moduleFeatures",
};

export enum RouteEnum {
  ASSETS = "assets",
  QTYITEMS = "qtyItems",
  TRANSFER_CART = "/transferCart",
  TRANSFER_HISTORY = "/administration/general/transferHistory",
}

/*
 * Modular level objects for ASSETS, WORKERS etc
 */

/*
*  @SortList : Object to provide sorting List
    @ASSETS : Provides Sorting list for assets with label field for displaying and name field to give as parameter for backend call
     @WORKERS : Provides Sorting list for workers with label field for displaying and name field to give as parameter for backend call
      @ASSET_TEMPLATE : Provides Sorting list for workers with label field for displaying and name field to give as parameter for backend call
*/

export const SortList = {
  ALLOCATIONS: {
    DEFAULT_SORT: { name: "relevance", label: "Relevance", sortField: "relevance", sortType: "-" },
    SORT_LIST: [
      { name: "relevance", label: "Relevance", sortField: "relevance", sortType: "-" },
      { name: "allocationName", label: "Allocation", sortField: "allocationName" },
      {
        label: "ResponsibleWorkerName",
        name: "responsibleEmployeeName",
        sortField: "responsibleEmployeeName",
      },
      { name: "stockQuantity", label: "StockQuantity", sortField: "stockQuantity", sortType: "-" },
    ],
  },
  ARCHIVED_LOCATION: {
    DEFAULT_SORT: { name: "locationName", sortKey: "name", sortType: "+" },
    SORT_LIST: [
      { name: "locationName", sortKey: "name" },
      { name: "locationType", sortKey: "type" },
      { name: "locationManagerFirstName", sortKey: "locationManagerFirstName" },
      { name: "locationManagerLastName", sortKey: "locationManagerLastName" },
    ],
  },
  ASSET_SERVICE: {
    DEFAULT_HISTORY_SORT: { name: "completionDate", sortType: "-" },
    DEFAULT_SORT: { name: "scheduleDate", sortType: "+" },
    HISTORY_SORT_LIST: [
      { name: "serviceName", label: "Name" },
      { name: "provider", label: "Provider" },
      { name: "scheduleDate", label: "Schedule Date" },
      { name: "completionDate", label: "Completion Date", sortType: "-" },
      { name: "cost", label: "Cost" },
      { name: "attachmentCount", label: "Attachment" },
    ],
    OPEN_SORT_LIST: [
      { name: "serviceName", label: "Name" },
      { name: "description", label: "Description" },
      { name: "frequency", label: "Frequency" },
      { name: "attachmentCount", label: "Attachment" },
      { name: "scheduleDate", label: "Schedule Date" },
    ],
  },
  ASSET_TEMPLATE: {
    DEFAULT_SORT: { name: "model", sortType: "+" },
    SORT_LIST: [
      { name: "model" },
      { name: "manufacturer" },
      { name: "costCode" },
      { name: "assetGroup" },
      { name: "assetCount" },
    ],
  },
  ASSET_TEMPLATE_SERVICE: {
    DEFAULT_SORT: { name: "serviceName", sortType: "+" },
    SORT_LIST: [
      { name: "serviceName", label: "Name" },
      { name: "frequency", label: "Frequency" },
      { name: "attachments", label: "Attachment" },
      { name: "notificationNumber", label: "Notifications" },
    ],
  },
  CHARGE_LOCATIONS: {
    DEFAULT_SORT: { name: "costLocationName", sortType: "+" },
    SORT_LIST: [
      { name: "costLocationManagerName", sortField: "costLocationManagerName" },
      { name: "costLocationName", sortField: "costLocationName" },
    ],
  },
  GATEWAY_ASSETS: {
    DEFAULT_SORT: { name: "scancode", label: "scancode", sortType: "+" },
    SORT_LIST: [{ name: "scancode", label: "scancode" }],
  },
  GENERATED_REPORTS: {
    DEFAULT_SORT: { name: "name", sortType: "+" },
    SORT_LIST: [
      { name: "type", sortField: "type" },
      { name: "reportType", sortField: "reportType" },
    ],
  },
  IDLE_ASSETS: {
    DEFAULT_SORT: { name: "idleTimeDuration", sortType: "-" },
    SORT_LIST: [
      { name: "manufacturerName", label: "manufacturerName" },
      { name: "groupName", label: "assetGroup" },
      { name: "idleTimeDuration", label: "idleTimeDuration" },
      { name: "currentLocationName", label: "currentLocationName" },
      { name: "responsibleEmployeeName", label: "responsibleEmployee" },
      { name: "scanCode", label: "scanCodeIdleAsset" },
    ],
  },
  LABEL: {
    DEFAULT_SORT: { name: "labelName", sortType: "+" },
    SORT_LIST: [{ name: "labelName" }, { name: "assetCount", label: "labelAssetsCount" }],
  },
  MANUFACTURERS: {
    DEFAULT_SORT: { name: "manufacturerName", label: "Name", sortType: "+" },
    SORT_LIST: [{ name: "manufacturerName", label: "Name" }],
  },
  PURCHASE: {
    DEFAULT_SORT: { name: "purchaseDate", label: "purchaseDate", sortField: "purchaseDate", sortType: "+" },
  },
  QTY_ITEM: {
    DEFAULT_SORT: { name: "manufacturerName", label: "manufacturer", sortType: "+" },
    SORT_LIST: [
      { sortField: "totalStockQuantity", name: "totalStockQuantity", label: "totalQuantity", sortType: "-" },
      { sortField: "manufacturerName", name: "manufacturerName", label: "manufacturer" },
      { sortField: "model", name: "model" },
      { sortField: "description", name: "description" },
      { sortField: "groupName", name: "groupName", label: "assetGroup" },
      { sortField: "allocationsCount", name: "allocationsCount", label: "allocationCount", sortType: "-" },
      { sortField: "unit", name: "unit", label: "stockingUnit" },
      { sortField: "lastTransferDate", name: "lastTransferDate", label: "lastTransfer" },
      { sortField: "purchaseDate", name: "purchaseDate" },
      { sortField: "purchasePrice", name: "purchasePrice" },
      { sortField: "vendor", name: "vendor" },
      { sortField: "inventoryNumber", name: "inventoryNumber" },
      { sortField: "scanCode", name: "scanCode" },
      { sortField: "name", name: "name" },
      { sortField: "notes", name: "notes" },
    ],
  },
  SERVICE_TEMPLATE: {
    DEFAULT_SORT: { name: "name", sortType: "+" },
    SORT_LIST: [
      { name: "name", sortField: "name" },
      { name: "attachmentCount", sortField: "attachmentCount" },
      { name: "frequency", sortField: "frequency" },
      { name: "notificationNumber", sortField: "notificationNumber" },
      { label: "assetCountLabel", name: "assetCount", sortField: "assetCount" },
      { name: "assetTemplateCount", sortField: "assetTemplateCount" },
      { name: "creationDate", sortField: "creationDate" },
    ],
  },
  UNIT_MANAGEMENT: {
    DEFAULT_SORT: { name: "name", sortField: "name", sortType: "+" },
    SORT_LIST: [],
  },
  WORKER_CERTIFICATE: {
    DEFAULT_SORT: { name: "status", sortField: "status", sortType: "-" },
    SORT_LIST: [
      { name: "certificateTemplateName", sortField: "certificateTemplateName" },
      { name: "certificateNo", sortField: "certificateNo" },
      { name: "issueDate", sortField: "issueDate" },
      { name: "expirationDate", sortField: "expirationDate" },
      { name: "presenceOfAttachment", sortField: "presenceOfAttachment" },
      { name: "status", sortField: "status", sortType: "-" },
    ],
  },
  WORKER_CERTIFICATE_TEMPLATE: {
    DEFAULT_SORT: { name: "name", sortField: "name", sortType: "+" },
    SORT_LIST: [
      { name: "name", sortField: "name" },
      { name: "workersCount", sortField: "workersCount" },
      { name: "notifications", sortField: "notifications" },
    ],
  },
};

export const LOCATION_DEFAULT_SORT = "type";
export const WORKER_DEFAULT_SORT = "firstName";

export enum SortFields {
  ASSETSCOUNT = "assetsCount",
  ASSETSASSIGNEDCOUNT = "assetsAssignedCount",
  QUANTITYITEMCOUNT = "quantityItemCount",
}

export const FileTypeList = {
  TYPE: {
    XLS: "xls",
    XLSX: "xlsx",
    XLSX_TYPE: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    XLS_TYPE: "application/vnd.ms-excel",
  },
};

export const FileErrors = {
  DEFAULT_SIZE: 15,
  INITIAL_RECORD_COUNT: 4,
};

export const languages = [
  { name: "English", code: "en-US" },
  { name: "German", code: "de-DE" },
  { name: "Italian", code: "it-IT" },
  { name: "Czech", code: "cs-CZ" },
  { name: "Spanish", code: "es-ES" },
  { name: "Slovakia", code: "sk-SK" },
  { name: "Polish", code: "pl-PL" },
  { name: "French", code: "fr-FR" },
  { name: "Dutch", code: "nl-NL" },
];

export const ASSET_COLUMN_ATTRIBUTES = [
  {
    code: "templateName",
    value: "Template Name",
  },
  {
    code: "manufacturer",
    value: "Manufacturer",
  },
  {
    code: "model",
    value: "Model",
  },
  {
    code: "description",
    value: "Description",
  },
  {
    code: "assetStatus",
    value: "Asset Status",
  },
  {
    code: "name",
    value: "Name",
  },
  {
    code: "defaultLocation",
    value: "Default Location",
  },
  {
    code: "inventoryWorker",
    value: "Inventory Worker",
  },
  {
    code: "assetGroup",
    value: "Asset Group",
  },
  {
    code: "storageLocation",
    value: "Storage Location",
  },
  {
    code: "notes",
    value: "Notes",
  },
  {
    code: "assetUsage",
    value: "Asset Usage Condition (AUC) State",
  },
  {
    code: "ownerShipType",
    value: "OwnerShip Type",
  },
  {
    code: "costCode",
    value: "Cost Code",
  },
  {
    code: "replacementCode",
    value: "Replacement Code",
  },
  {
    code: "purchaseDate",
    value: "Purchase Date",
  },
  {
    code: "purchasePrice",
    value: "Purchase Price",
  },
  {
    code: "purchaseCurrency",
    value: "Purchase Currency",
  },
  {
    code: "purchaseOrderNumber",
    value: "Purchase Order Number",
  },
  {
    code: "warranyExpirationDate",
    value: "Warranty Expiration Date",
  },
  {
    code: "vendor",
    value: "Vendor",
  },
];

export const LOCATION_TYPE = "LOCATION_GROUP";

export const ARCHIVED_LOCATION = "ARCHIVED_LOCATION";
export const ALERT_API_DELAY_TIME = 300000;
export const API_DELAY_TIME = 1000;
export const NO_OF_MINUTES_IN_A_HOUR = 60;
export const NO_OF_SECONDS_IN_A_MINUTE = 60;
export const API_TIMEOUT = NO_OF_SECONDS_IN_A_MINUTE * API_DELAY_TIME;
export const PDPMANUFACTURERS = ["Hilti"];
export const HINTMANUFACTURERS = ["Hilti"];
export enum MultiSelectValidation {
  MULTISELECTCOUNT = 500,
}
export const MAX_FILE_SIZE = 20971520;
export const MATERIAL_NUMBER = "2182498";
export const API_DATE_FORMAT = "YYYY-MM-DD";
export const MAX_FILE_COUNT = 20;
export const TRANSFER_LIMIT = {
  MAX_ALLOCATIONS: 50,
  MAX_ITEMS: 500,
};
export const IDLE_TIME_FILTER_DEFAULT_RANGE = { min: "1", max: "999" };
export const DEFAULT_MIN_IDLE_DAYS = "";
export const ASSET_DEFAULT_MIN_IDLE_DAYS = "";
export const DEFAULT_MAX_IDLE_DAYS = "";
export const MAX_QUERY_STRING_SIZE = 3000;
export const UNITE_DEVELOPER_PORTAL_SUPPORT_LINK = "https://unite.ontrack3.hilti.com/help-center/_support/";
export const UNITE_DEVELOPER_ONBOARDING_LINK = "https://unite.ontrack3.hilti.com/get-started/_onboardauth/";
export const UNITE_DEVELOPER_PORTAL_LINK = "https://unite.ontrack3.hilti.com/";
export const UPDATED_UNITE_DEVELOPER_PORTAL_LINK = "https://unite.ontrack3.hilti.com/developer/";
