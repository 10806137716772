import React from "react";
import IExternalIdProps from "./iExternalIdProps";
import { IconNormalBold } from "../DetailsPanel";
import { useTranslation } from "react-i18next";
import { LocationInnerText } from "../../modules/location/locationEnum";
import { AuthorizationCore } from "am-web-ui-shared/helpers";
import ModuleName from "../../utils/enums/moduleNameEnum";

export const ExternalId: React.FC<IExternalIdProps> = ({ extId }: IExternalIdProps) => {
  const { t } = useTranslation();
  if (extId && AuthorizationCore.hasActiveModule(ModuleName.UNITE)) {
    return (
      <IconNormalBold
        id="externalId"
        normalId="lblExternalId"
        normalText={t("common:LABEL_EXTERNAL_ID")}
        boldId="ExternalIdName"
        boldText={extId}
        type={LocationInnerText.INNER_TEXT}
      />
    );
  }
  return null;
};

export default ExternalId;
