import React from "react";
const SVG = () => (
  <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2010.000000, -494.000000)" fill="#524F53">
        <g transform="translate(2008.000000, 490.000000)">
          <path
            d="M21,19 L24,19 L24,21 L21,21 L21,24 L19,24 L19,21 L16,21 L16,19 L19,19 L19,16 L21,16 L21,19 Z M2,4 L9,4 L11,6 L21,6 L21,14 L17,14 L17,17 L14,17 L14,19 L2,19 L2,4 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
