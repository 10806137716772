// @ts-ignore
import styled from "custom-styled-components";

/**
 * @const StyledBoldText - Styled component that is having text with bold font-familiy.
 */
const StyledBoldText = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  color: ${(props) => props.theme.locationDetail.locationBadge.steel};
`;

/**
 * @export StyledBoldText -  Styled Component.
 */
export default StyledBoldText;
