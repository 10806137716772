import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M19,14 C21.209139,14 23,15.790861 23,18 C23,20.209139 21.209139,22 19,22 C16.790861,22 15,20.209139 15,18 C15,15.790861 16.790861,14 19,14 Z M16,2 L16,6 L22,6 L22.000963,12.8032504 C21.1182253,12.2923955 20.093259,12 19,12 C15.6862915,12 13,14.6862915 13,18 C13,18.7015272 13.1203964,19.3749342 13.3416539,20.0006859 L2,20 L2,6 L7,6 L7,2 L16,2 Z M19,16 L18,16 L18,19 L21,19 L21,18 L19,18 L19,16 Z M14,4 L9,4 L9,6 L14,6 L14,4 Z" />
    </g>
  </svg>
);

export default SVG;
