import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <title>template</title>
    <g stroke="none" fill="#524F53">
      <path
        d="M16,20 L16,22 L13,22 L13,20 L16,20 Z M22,19 L22,22 L20,22 L20,19 L22,19 Z M19,20 L19,22 L17,22 L17,20 L19,20 Z M5,12 L8,16 L6,16 L6,19 L11,19 L11,21 L4,21 L4,16 L2,16 L5,12 Z M15,17 L15,19 L13,19 L13,17 L15,17 Z M22,16 L22,18 L20,18 L20,16 L22,16 Z M15,13 L15,16 L13,16 L13,13 L15,13 Z M22,13 L22,15 L19,15 L19,13 L22,13 Z M18,13 L18,15 L16,15 L16,13 L18,13 Z M20,3 L20,8 L22,8 L19,12 L16,8 L18,8 L18,5 L13,5 L13,3 L20,3 Z M11,2 L11,11 L4,11 L4,9 L9,9 L9,4 L4,4 L4,11 L2,11 L2,2 L11,2 Z"
        id="Combined-Shape"
      />
    </g>
  </svg>
);

export default SVG;
