import React from "react";
import styled from "styled-components";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import IGroupsRowProps from "./iGroupsRowProps";
interface IRow {
  isSelected: boolean;
  currentItem: boolean;
  theme: any;
}

export const Row: any = styled.li`
  padding: 10px 16px 13px 16px;
  display: flex;
  align-items: center;
  background: ${(props: IRow) =>
    props.isSelected
      ? props.currentItem
        ? props.theme.colors.base.greyDark
        : props.theme.colors.base.customLightGrey
      : props.theme.colors.base.white};
  width: 100%;
  &:hover {
    background: ${(props: IRow) =>
      props.currentItem ? props.theme.colors.base.greyDark : props.theme.colors.base.customLightGrey};
    cursor: pointer;
  }
  &.disabledlist {
    color: ${(props) => props.theme.colors.base.steel20};
    opacity: 0.2;
    cursor: default;
    background-color: white;
    pointer-events: none;
    &:hover {
      background-color: transparent;
    }
  }
`;

export enum GroupType {
  LOCATION_GROUP = "LOCATION_GROUP",
  WAREHOUSE = "WAREHOUSE",
  JOBSITE = "JOBSITE",
  CUSTOM_LOCATION = "CUSTOM_LOCATION",
}
const getLocationTypeIcon = (iconType) => {
  switch (iconType.code) {
    case GroupType.LOCATION_GROUP:
      return IconName.Folder;
    case GroupType.WAREHOUSE:
      return IconName.WareHouse;
    case GroupType.JOBSITE:
      return IconName.LocationJobSite;
    default:
      return IconName.Folder;
  }
};

export const ListText = styled.span`
  width: calc(100% - 62px);
  white-space: normal;
  word-break: break-all;
  color: ${(props) => props.theme.colors.base.steel};
  word-wrap: break-word;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-weight: ${(props) => props.theme.fontRoman.fontWeight};
`;

export const FontBold = styled("span")`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;
const IconWrapper = styled.div`
  margin-right: 16px;
`;
export const ArrowIconContainer = styled.span`
  height: 24px;
  width: 24px;
  background: ${(props) => props.theme.colors.base.transparent};
  text-align: center;
  &:hover {
    background: ${(props) => props.theme.colors.base.steel};
    polygon {
      fill: ${(props) => props.theme.colors.base.white};
    }
  }
`;

/**
 * Render single row in group list
 */
const GroupsRow = (props: IGroupsRowProps) => {
  const { id, name, hasChildren, handleRowClick, isSelected, selectedId, showHover, type, disableClass } = props;
  const handleRowClickToggle = (event, isTrue = false) => {
    handleRowClick(isTrue);
    event.stopPropagation();
  };
  return (
    <Row
      id={`groupRow${id}`}
      currentItem={selectedId === id}
      isSelected={isSelected}
      className={disableClass}
      onClick={handleRowClickToggle}
    >
      <IconWrapper>
        <SquareIcon
          id="iconArrow"
          name={getLocationTypeIcon(type || { code: "LOCATION_GROUP" })}
          className="locationGroup"
        />
      </IconWrapper>
      <ListText id={`list${id}`}>{isSelected ? <FontBold id="listName">{name}</FontBold> : name}</ListText>
      {hasChildren ? (
        <ArrowIconContainer
          id={`groupArrow${id}`}
          onClick={(e) => {
            if (showHover) {
              handleRowClickToggle(e, true);
            }
          }}
        >
          <SquareIcon name={IconName.Arrow} />
        </ArrowIconContainer>
      ) : null}
    </Row>
  );
};

export default GroupsRow;
