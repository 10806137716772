import serviceReducer from "./serviceReducer";
import { ISagaModule } from "redux-dynamic-modules-saga";
import { IAssetServiceModuleState } from "./iAssetServiceModuleState";
import assetServiceSaga from "./assetServiceSaga";

const assetServiceModule: ISagaModule<IAssetServiceModuleState> = {
  id: "assetServiceModule",
  reducerMap: {
    AssetService: serviceReducer,
  },
  sagas: [assetServiceSaga],
};

export default assetServiceModule;
