import styled from "styled-components";
/**
 * @const StyledBeforeIconContainer - Styled Component that will act as a container for other component.
 * It is having the an icon at the left of the content rows (::before).
 * @interface IBeforeIconContainer
 */
const StyledBeforeIconContainer = styled("div")`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  align-items: center;
  padding: 12px 0;
  word-break: break-word;
  .mr-16 {
    margin-right: 16px;
  }
`;

/**
 * @export StyledBeforeIconContainer -  Styled Component.
 */
export default StyledBeforeIconContainer;
