import { ISagaModule } from "redux-dynamic-modules-saga";
import filterReducer from "./filterReducer";
import IFilterPanelModule from "./iFilterPanelModule";

const filterPanelModule: ISagaModule<IFilterPanelModule> = {
  id: "filterPanelModule",
  reducerMap: {
    filterPanel: filterReducer,
  },
  sagas: [],
};

export default filterPanelModule;
