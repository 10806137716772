import React, { useState } from "react";
import has from "lodash/has";
import { IconBold, IconNormalBold, UpfrontExpansionPanel } from "../../../../../components/DetailsPanel/index";
import { Trans } from "react-i18next";
import { IconName } from "am-web-ui-shared";
import UtilizationDetailsView from "./utilizationDetailsView";

// To hide the arrow icon in the case there is no data avilable in the details section.
const isEnabledDetailsSection = (data) => has(data, "totalEngineHours");

const secondsToHourMins = (d: number) => {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const hDisplay = h >= 0 ? `${h}h` : "0h";
  const mDisplay = m > 0 ? `${m}m` : "0m";
  return hDisplay === "" && mDisplay === "" ? `0h 0m` : `${hDisplay} ${mDisplay}`;
};

const TotalDistance = (props) => {
  const { data, distanceUnitValue } = props;
  const distance = has(data, "totalDistance") ? data.totalDistance : "- ";
  const totalDistance = distance + " " + (data.totalDistance ? distanceUnitValue?.value : "");
  return totalDistance ? (
    <IconNormalBold
      id="pnlTelematicTotalDistance"
      normalId="lblTelematicTotalDistance"
      normalText={<Trans>{"common:TOTAL_DISTANCE"}</Trans>}
      boldId="txtTelematicTotalDistance"
      boldText={totalDistance}
    />
  ) : null;
};

const TotalEngineHours = (props) => {
  const { data } = props;
  const totalTime = data?.utilizationSummary?.cumulativeOpTime;
  const engineHours = totalTime ? secondsToHourMins(totalTime) : "0h 0m";
  return data?.utilizationSummary ? (
    <IconNormalBold
      id="pnlTelematicTotalEngineHours"
      normalId="lblTelematicTotalEngineHours"
      normalText={<Trans>{"common:TOTAL_ENGINE_HOURS"}</Trans>}
      boldId="txtTelematicTotalEngineHours"
      boldText={engineHours}
    />
  ) : null;
};

export const HeavyEquipmentUitlization = (_data, _isPanelExpand, t) => {
  return (
    <IconBold
      id="pnlHeavyEquipment"
      iconUrl={IconName.DocumentChart}
      boldId="lblHeavyEquipment"
      boldText={t("common:UTILIZATION")}
    />
  );
};

/**
 * @description - This is the utilization section on the asset details panel.
 * @param - accepts assetDetailsData as argument for forming utilization section.
 */
export const UtilizationSection = (props) => {
  const {
    assetDetailsData,
    distanceUnitValue,
    utilizationSummary,
    utilizationDetails,
    getAssetUtiliationSummary,
    getAssetUtilizationDetails,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = (_detailsData, isPanelExpanded) => {
    setIsExpanded(isPanelExpanded);
  };
  const getSummaryComponents = () => {
    return [
      HeavyEquipmentUitlization,
      () => <TotalDistance data={assetDetailsData} distanceUnitValue={distanceUnitValue} />,
    ];
  };

  const getDetailsComponents = () => {
    return [
      () => <TotalEngineHours data={utilizationSummary} />,
      () => (
        <UtilizationDetailsView
          utilizationSummary={utilizationSummary}
          utilizationDetails={utilizationDetails}
          getAssetUtiliationSummary={getAssetUtiliationSummary}
          getAssetUtilizationDetails={getAssetUtilizationDetails}
          assetDetailsData={assetDetailsData}
          isExpanded={isExpanded}
        />
      ),
    ];
  };

  return (
    <UpfrontExpansionPanel
      panelId="utilizationSection"
      detailsData={assetDetailsData}
      summaryComponents={getSummaryComponents()}
      detailComponents={getDetailsComponents()}
      associatedRowId={assetDetailsData.id}
      isEnable={!!isEnabledDetailsSection(assetDetailsData)}
      onPannelToggle={handleToggle}
    />
  );
};

/**
 * @exports UtilizationSection - Utilization section that renders both detail and summary components.
 */
export default UtilizationSection;
