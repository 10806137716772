import { Ellipsis } from "am-web-ui-shared/components";
import React from "react";
import { HelperLodash } from "am-web-ui-shared/helpers";
import styled from "styled-components";
import { ServiceResultEnum } from "../serviceFormController/serviceFormEnum";

export const ServiceResultStyle = styled.span.attrs({
  id: "locationBadge",
})`
  height: 24px;
  text-align: center;
  &.a {
    background-color: ${(props) => props.theme.locationDetail.locationBadge.lightGrayishCyanLimeGreen};
    color: ${(props) => props.theme.locationDetail.locationBadge.darkLimeGreen};
  }
  &.e {
    background-color: ${(props) => props.theme.locationDetail.locationBadge.veryPaleRed};
    color: ${(props) => props.theme.locationDetail.locationBadge.strongRed};
  }
  border-radius: 12px;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  padding: 0 18px;
`;
export const getClassNameForResult = (status: any) => {
  switch (status) {
    case ServiceResultEnum.PASSED.toUpperCase():
      return "a";
    case ServiceResultEnum.FAILED.toUpperCase():
      return "e";
    default:
      return null;
  }
};
export const ServiceResult = (props) => {
  const { column, showTooltip, tooltipValue } = props;
  const propertyName = column.bindProperties[0].properties[0].name;
  const resultData = {
    result: props && HelperLodash.get(props.data, propertyName),
  };
  return resultData.result && resultData.result.code ? (
    <Ellipsis id={propertyName} showTooltip={showTooltip} tooltipValue={tooltipValue}>
      <ServiceResultStyle className={getClassNameForResult(resultData.result.code)}>
        {resultData.result.value}
      </ServiceResultStyle>
    </Ellipsis>
  ) : null;
};

export default ServiceResult;
