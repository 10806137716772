import React, { useContext, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { ISearchBarProps } from "../commonProps/iSearchBarProps";
import FilterContainer from "../filter/filterContainer";
import { ScrollContext } from "am-web-ui-shared/components";

/**
 * Styled component that is used as a search bar wrapper.
 */
const StyledSearchBar = styled("div")`
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
  position: relative;
  min-height: 64px;
  .field-wrapper {
    margin-bottom: 0;
  }
  .searchbox-wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    .input {
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }
  .multiSelectControl {
    .typeselect {
      height: 40px;
      & > div {
        font-size: ${(props) => props.theme.fontSize.base};
        &:last-child > .multiValueInput {
          height: 36px;
        }
      }
      .dropDownPlaceholder {
        padding: 8px 0;
      }
      &.selected .multivalue {
        line-height: 36px !important;
      }
      .menuClose,
      .menuOpen {
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: auto;
        }
      }
    }
  }
  .readonly-dropdown {
    height: 40px;
  }
`;

/**
 * SearchBar component is holding the search box and other filter components.
 */

const SearchBar = (props: ISearchBarProps) => {
  const searchRef = useRef<HTMLDivElement>(null);
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const { isScrollBehaviourEnabled } = useContext(ScrollContext);
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const targetElem = document.querySelector("#tableWrapper") as HTMLElement;
    const tablePos = targetElem?.offsetTop;
    const actionBarEle = document.querySelector("#actionBarScroll") as HTMLElement;
    if (isScrollBehaviourEnabled) {
      if (currentScrollY < tablePos || currentScrollY === 0) {
        searchRef.current.classList.remove("searchbar-scroll-add");
        actionBarEle.classList.remove("actionbar-scroll-add");
      } else if (currentScrollY > lastScrollY && currentScrollY > tablePos) {
        // User is scrolling down, hide the SearchBar
        actionBarEle.style.top = "0";
        actionBarEle.style.zIndex = "100";
        actionBarEle.classList.add("actionbar-scroll-add");
      } else if (currentScrollY < lastScrollY && currentScrollY > tablePos) {
        // User is scrolling up, show the SearchBar
        searchRef.current.classList.remove("searchbar-scroll-remove");
        searchRef.current.classList.add("searchbar-scroll-add");
        actionBarEle.style.top = searchRef.current.offsetHeight + "px";
        actionBarEle.style.zIndex = "97";
        actionBarEle.classList.add("actionbar-scroll-add");
      }
    }
    setLastScrollY(currentScrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, isScrollBehaviourEnabled]);

  return (
    <StyledSearchBar id="searchBarWrapper" className="search-bar" ref={searchRef}>
      {props.searchComponent}
      {props.customComponent}
      <FilterContainer
        {...props}
        refreshFilters={props.handleFilterChange}
        searchContainerRef={searchRef.current}
        isSearchComponentLoaded={!!props.searchComponent}
      />
    </StyledSearchBar>
  );
};

export default withTranslation()(React.memo(SearchBar));
