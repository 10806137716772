/**
 * @description Action constants for the service template module
 *
 */
const ServiceTemplateActionConstants = {
  ADD_SERVICE_TEMPLATE_RESPONSE: "ADD_SERVICE_TEMPLATE_RESPONSE",
  AM_ADD_SERVICE_TEMPLATE: "AM_ADD_SERVICE_TEMPLATE",
  AM_DELETE_SERVICE_TEMPLATE: "AM_DELETE_SERVICE_TEMPLATE",
  AM_EDIT_SERVICE_TEMPLATE: "AM_EDIT_SERVICE_TEMPLATE",
  AM_GET_SERVICE_TEMPLATE_CODES: "AM_GET_SERVICE_TEMPLATE_CODES",
  AM_GET_SERVICE_TEMPLATE_CODES_RESPONSE: "AM_GET_SERVICE_TEMPLATE_CODES_RESPONSE",
  AM_GET_SERVICE_TEMPLATE_DETAILS: "AM_GET_SERVICE_TEMPLATE_DETAILS",
  AM_SERVICE_TEMPLATE_CLEAR_SELECTION: "AM_SERVICE_TEMPLATE_CLEAR_SELECTION",
  AM_SERVICE_TEMPLATE_GET_LIST: "AM_SERVICE_TEMPLATE_GET_LIST",
  AM_SERVICE_TEMPLATE_RESET_QUERY_PARAM: "AM_SERVICE_TEMPLATE_RESET_QUERY_PARAM",
  AM_SERVICE_TEMPLATE_SET_LIST_RESPONSE: "AM_SERVICE_TEMPLATE_SET_LIST_RESPONSE",
  AM_SERVICE_TEMPLATE_SET_SELECTED_ROWS: "AM_SERVICE_TEMPLATE_SET_SELECTED_ROWS",
  AM_SET_SERVICE_TEMPLATE_SEARCH_QUERY: "AM_SET_SERVICE_TEMPLATE_SEARCH_QUERY",
  AM_SET_SERVICE_TEMPLATE_SORT_QUERY: "AM_SET_SERVICE_TEMPLATE_SORT_QUERY",
  AN_SET_SERVICE_TEMPLATE_FILTER_APPLIED_FIELD: "AN_SET_SERVICE_TEMPLATE_FILTER_APPLIED_FIELD",
  CLEAR_SERVICE_TEMPLATE_DETAILS: "CLEAR_SERVICE_TEMPLATE_DETAILS",
  EDIT_SERVICE_TEMPLATE_RESPONSE: "EDIT_SERVICE_TEMPLATE_RESPONSE",
  GET_SERVICE_TEMPLATE_FILTER_ATTRIBUTES: "GET_SERVICE_TEMPLATE_FILTER_ATTRIBUTES",
  SERVICE_TEMPLATE_CLEAR_ALL_ROWS_SELECTION: "SERVICE_TEMPLATE_CLEAR_ALL_ROWS_SELECTION",
  SERVICE_TEMPLATE_FILTER_ATTRIBUTES_SUCCESS: "SERVICE_TEMPLATE_FILTER_ATTRIBUTES_SUCCESS",
  SET_SERVICE_TEMPLATE_DETAILS: "SET_SERVICE_TEMPLATE_DETAILS",
  SET_SERVICE_TEMPLATE_FILTER_QUERY: "SET_SERVICE_TEMPLATE_FILTER_QUERY",
};

export default ServiceTemplateActionConstants;
