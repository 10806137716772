import MenuItem from "@mui/material/MenuItem";
import React from "react";
import styled from "styled-components";
import { includes } from "../../../../helpers/helperLodash";
import ControlType from "../../controlType";
import Checkbox from "../../../../elements/Checkbox/checkbox";
import { IMultiSelectCheckboxProps } from "../multiSelectDropdown/iMultiSelectDropDownProps";

/**
 * OptionWrapper styles for the menuItem in flyout
 */
export const OptionWrapper = styled(MenuItem)`
  background: ${(props) => props.theme.colors.base.white};
  padding: 0 16px;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
`;

export const DisabledLabels = styled.span`
  color: ${(props) => props.theme.colors.base.black30};
  font-weight: 300;
  line-height: 19px;
  text-align: left;
  margin-left: 2px;
`;

export const HiddenAssetInfo = (props) => {
  const { t } = props;
  return <DisabledLabels>({t("filters:HIDDEN_BY_DEFAULT")})</DisabledLabels>;
};

/**
 * @class MultiSelectCheckboxFilter component for multiselect dropdown
 */
export class MultiSelectCheckboxFilter extends React.PureComponent<IMultiSelectCheckboxProps> {
  renderLabel = () => {
    const { dropDownProps } = this.props;
    return (
      <>
        {dropDownProps.data && dropDownProps.data.label}
        {includes(
          [ControlType.retiredFilterCode, ControlType.disposedFilterCode, ControlType.Archived],
          dropDownProps.data && dropDownProps.data.value,
        ) && <HiddenAssetInfo {...this.props} />}
      </>
    );
  };

  render() {
    const { dropDownProps, handleInputChange } = this.props;
    const labelId =
      dropDownProps.data && dropDownProps.data.label && dropDownProps.data.label.toLowerCase().split(" ").join("_");
    return (
      <OptionWrapper
        {...dropDownProps.innerProps}
        id={`${dropDownProps.selectProps.name || labelId}`}
        className={`dropdownMenuItem op_${dropDownProps.selectProps.name}`}
        buttonRef={dropDownProps.ref}
        component="div"
        key={dropDownProps.data.label}
      >
        <Checkbox
          checked={dropDownProps.isSelected}
          name={dropDownProps.innerProps.id}
          onChange={(event) => {
            event.stopPropagation?.();
            event.preventDefault?.();
            return handleInputChange && handleInputChange(dropDownProps.data, event.target.checked);
          }}
          id={dropDownProps.innerProps.id}
          label={this.renderLabel()}
          truncateLabel
          wrapperStyles={{ pointerEvents: "none" }}
          isLabelBold={dropDownProps.isSelected}
        />
      </OptionWrapper>
    );
  }
}

export default MultiSelectCheckboxFilter;
