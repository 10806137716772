/**
 * @description Action constants to be used for worker module and its feature
 *
 * format: {feature}_{action}
 */
const WorkerActionConstants = {
  CLEAR_ALL_WORKERS_SELECTION: "CLEAR_ALL_WORKERS_SELECTION",
  CLEAR_CONTACT_CONFLICT: "CLEAR_CONTACT_CONFLICT",
  GET_CUSTOM_VIEW_FAILURE: "GET_CUSTOM_VIEW_FAILURE",
  GET_CUSTOM_VIEW_SUCCESS: "GET_CUSTOM_VIEW_SUCCESS",
  GET_PASSWORD: "GET_PASSWORD",
  GET_PASSWORD_FAIL: "GET_PASSWORD_FAIL",
  GET_PASSWORD_SUCCESS: "GET_PASSWORD_SUCCESS",
  OM_ADD_RENAME_CUSTOM_VIEW_RESPONSE: "OM_ADD_RENAME_CUSTOM_VIEW_RESPONSE",
  OM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA: "OM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA",
  OM_CLEAR_DEFAULT_GRID_FIELDS: "OM_CLEAR_DEFAULT_GRID_FIELDS",
  OM_CREATE_CUSTOM_VIEW: "OM_CREATE_CUSTOM_VIEW",
  OM_CUSTOM_VIEW_UPDATE_GRID_FIELDS: "OM_CUSTOM_VIEW_UPDATE_GRID_FIELDS",
  OM_DELETE_CUSTOM_VIEW_WORKER: "OM_DELETE_CUSTOM_VIEW_WORKER",
  OM_GET_CUSTOM_VIEW: "OM_GET_CUSTOM_VIEW",
  OM_GET_JOB_FUNCTIONS: "OM_GET_JOB_FUNCTIONS",
  OM_GET_JOB_FUNCTIONS_SUCCESS: "OM_GET_JOB_FUNCTIONS_SUCCESS",
  OM_GET_WORKER_SYSTEM_VIEW: "OM_GET_WORKER_SYSTEM_VIEW",
  OM_GET_WORKER_SYSTEM_VIEW_SUCCESS: "OM_GET_WORKER_SYSTEM_VIEW_SUCCESS",
  OM_RENAME_CUSTOM_VIEW_WORKER: "OM_RENAME_CUSTOM_VIEW_WORKER",
  OM_RESET_WORKER_ORIGINAL_VIEW: "OM_RESET_WORKER_ORIGINAL_VIEW",
  OM_SET_DEFAULT_CUSTOM_VIEW_WORKER: "OM_SET_DEFAULT_CUSTOM_VIEW_WORKER",
  OM_UPDATE_CUSTOM_VIEW_WORKER: "OM_UPDATE_CUSTOM_VIEW_WORKER",
  WORKER_RESET_MULTI_DELETE_RESPONSE: "WORKER_RESET_MULTI_DELETE_RESPONSE",
  SET_CUSTOM_VIEW_MODIFIED_FLAG: "SET_CUSTOM_VIEW_MODIFIED_FLAG",
  SET_SELECTED_VIEW: "SET_SELECTED_VIEW_WORKER",
  SET_WORKER_DELETE_RESPONSE: "SET_WORKER_DELETE_RESPONSE",
  SET_WORKER_FILTER_APPLIED_FIELD: "SET_WORKER_FILTER_APPLIED_FIELD",
  SET_WORKER_LIST_FIELDS_QUERY: "SET_WORKER_LIST_FIELDS_QUERY",
  UM_CREATE_WORKER_LABEL: "UM_CREATE_WORKER_LABEL",
  UM_GET_CONTACT_CONFLICT: "UM_GET_CONTACT_CONFLICT",
  UM_GET_CONTACT_CONFLICT_SUCCESS: "UM_GET_CONTACT_CONFLICT_SUCCESS",
  UM_REORDER_WORKER_CUSTOM_VIEW: "UM_REORDER_WORKER_CUSTOM_VIEW",
  UM_REORDER_WORKER_CUSTOM_VIEW_SUCCESS: "UM_REORDER_WORKER_CUSTOM_VIEW_SUCCESS",
  UM_RESEND_ACTIVATION_MAIL: "UM_RESEND_ACTIVATION_MAIL",
  UM_RESEND_ACTIVATION_MAIL_SUCCESS: "UM_RESEND_ACTIVATION_MAIL_SUCCESS",
  UM_SET_LABEL_UPDATED: "UM_SET_LABEL_UPDATED",
  UM_WORKER_ASSOCIATE_LABELS_RESPONSE: "UM_WORKER_ASSOCIATE_LABELS_RESPONSE",
  UM_WORKER_ASSOCIATE_LABEL_DETAILS: "UM_WORKER_ASSOCIATE_LABEL_DETAILS",
  UM_GET_WORKER_COUNTS: "UM_GET_WORKER_COUNTS",
  SET_WORKER_COUNTS_LOADER: "SET_WORKER_COUNTS_LOADER",
  UM_GET_WORKER_ASSET_COUNT_BY_ID: "UM_GET_WORKER_ASSET_COUNT_BY_ID",
  PUT_WORKER_COUNTS: "PUT_WORKER_COUNTS",
  SET_WORKER_COUNT_LOADER_STATE: "SET_WORKER_COUNT_LOADER_STATE",
};

export default WorkerActionConstants;
