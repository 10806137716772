// @ts-ignore
import { LockedFeatureInfoActions } from "../../common/actionConstants";

/**
 * @description Action method to get locked feature data
 * @export
 */
export const lockedFeature = (featureKey) => ({
  payload: featureKey,
  type: LockedFeatureInfoActions.GET_LOCKED_FEATURE_INFO,
});
export const clearLockedFeature = () => ({
  type: LockedFeatureInfoActions.CLEAR_LOCKED_FEATURE_INFO,
});
