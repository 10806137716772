import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M18,13 L20,13 L20,16 L23,16 L23,18 L19.999,18 L20,21 L18,21 L17.999,18 L15,18 L15,16 L18,16 L18,13 Z M21,8 L21,11 L16,11 L16,14 L13,14 L13,18 L3,18 C1.8954305,18 1,17.1045695 1,16 L1,8 L21,8 Z M19,3 C20.1045695,3 21,3.8954305 21,5 L21,6 L1,6 L1,5 C1,3.8954305 1.8954305,3 3,3 L19,3 Z"></path>
    </g>
  </svg>
);

export default SVG;
