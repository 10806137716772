import LoaderReducer from "./loaderReducer";
import { ISagaModule } from "redux-dynamic-modules-saga";
import { ILoaderModuleState } from "./iLoaderModuleState";

export const loaderModule: ISagaModule<ILoaderModuleState> = {
  id: "loaderModule",
  reducerMap: {
    Loader: LoaderReducer,
  },
  sagas: [],
};
