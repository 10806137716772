import Gateway from "../gateway/gateway";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description Returns the list of  countries and related locale information
 * @param {locale} : list the countries and related locale information
 */
export const getFormatFromLocale = async (locale) => {
  try {
    const url = getOrgMgmtEndpoint(`/locale-setting?locale=${locale}`);
    return Gateway.get(url);
  } catch (error) {
    return { error: "Unknown error" };
  }
};
