import { IconName } from "am-web-ui-shared/components";
import { LocaleManager } from "am-web-ui-shared/helpers";
import React from "react";
import { Trans } from "react-i18next";
import { IconNormalBold } from "../../../../../components/DetailsPanel/index";
import ExternalId from "../../../../../components/externalId/ExternalId";
import IExternalAssetIdProps from "./IExternalAssetIdProps";
import { IAssetDetailsData } from "TARGET_BUILD/common/models/asset/assetModel";

/**
 * @description - Category of the asset.
 */
export const category = (data) => {
  return data.assetCategoryResponse && data.assetCategoryResponse.value ? (
    <IconNormalBold
      id="pnlCategory"
      iconUrl={IconName.Category}
      normalId="lblCategory"
      normalText={<Trans>{"common:LABEL_CATEGORY"}</Trans>}
      boldId="txtCategory"
      boldText={data.assetCategoryResponse.value}
    />
  ) : null;
};

/**
 * @description - Scan code and Scan code type.
 */
export const scanCodeAndType = (data) => {
  const scanCode = data.scanCode || "";
  const scanCodeType =
    data.scanCodeTypeDetails && data.scanCodeTypeDetails.value ? ` / ${data.scanCodeTypeDetails.value}` : "";

  return scanCode || scanCodeType ? (
    <IconNormalBold
      id="pnlScanCodeAndType"
      normalId="lblScanCodeAndType"
      normalText={<Trans>{"common:LABEL_SCAN_CODE_AND_TYPE"}</Trans>}
      boldId="txtScanCodeAndType"
      boldText={`${scanCode}${scanCodeType}`}
    />
  ) : null;
};

export const geoTagSerialNumber = (data: IAssetDetailsData) => {
  const isTagAttached = data?.geoTagDetails?.isTagAttached;
  const geoTagSerialNumber = data?.geoTagDetails?.serialNumber || "";
  return isTagAttached && geoTagSerialNumber ? (
    <IconNormalBold
      id="pnlGeoTagSerialNumber"
      normalId="lblGeoTagSerialNumber"
      normalText={<Trans>{"common:TRACK_GEO_TAG_SERIAL_NUMBER"}</Trans>}
      boldId="txtScanCodeAndType"
      boldText={`${geoTagSerialNumber}`}
    />
  ) : null;
};

/**
 * @description - activation status of smart tags
 */
export const smartTagActivationStatus = (data) => {
  const assetTagStatus = data.assetTagStatus || {};
  return assetTagStatus && assetTagStatus.value ? (
    <IconNormalBold
      id="pnlSmartTagActivationStatus"
      normalId="lblSmartTagActivationStatus"
      normalText={<Trans>{"common:LABEL_SMART_TAG_ACTIVATION_STATUS"}</Trans>}
      boldId="txtSmartTagActivationStatus"
      boldText={assetTagStatus.value}
    />
  ) : null;
};

/**
 * @description - Inventory Code.
 */
export const inventoryCode = (data) => {
  return data.inventoryNumber ? (
    <IconNormalBold
      id="pnlInventoryCode"
      normalId="lblInventoryCode"
      normalText={<Trans>{"common:LABEL_INVENTORY_CODE"}</Trans>}
      boldId="txtInventoryCode"
      boldText={data.inventoryNumber || ""}
    />
  ) : null;
};

/**
 * @description - Asset status.
 */
export const assetStatus = (data) => {
  return data.assetStatusResponse && data.assetStatusResponse.value ? (
    <IconNormalBold
      id="pnlAssetStatus"
      normalId="lblAssetStatus"
      normalText={<Trans>{"common:LABEL_ASSET_STATUS"}</Trans>}
      boldId="txtAssetStatus"
      boldText={data.assetStatusResponse.value}
    />
  ) : null;
};

/**
 * @description - Asset group.
 */
export const assetTemplateName = (data) => {
  return data.assetTemplateName ? (
    <IconNormalBold
      id="pnlAssetTemplateName"
      normalId="lblTemplateName"
      normalText={<Trans>{"common:SORT_LIST_TEMPLATENAME"}</Trans>}
      boldId="txtLabelTemplateName"
      boldText={data.assetTemplateName}
    />
  ) : null;
};

/**
 * @description - Asset group.
 */
export const assetGroup = (data) => {
  return data.groupName ? (
    <IconNormalBold
      id="pnlAssetGroup"
      normalId="lblAssetGroup"
      normalText={<Trans>{"common:LABEL_ASSET_GROUP"}</Trans>}
      boldId="txtAssetGroup"
      boldText={data.groupName}
    />
  ) : null;
};

/**
 * @description - Serial number.
 */
export const serialNumber = (data) => {
  return data.serialNumber ? (
    <IconNormalBold
      id="pnlSerialNumber"
      normalId="lblSerialNumber"
      normalText={<Trans>{"common:LABEL_SERIAL_NUMBER"}</Trans>}
      boldId="txtSerialNumber"
      boldText={data.serialNumber || ""}
    />
  ) : null;
};

/**
 * @description - Previous serialNumber  number.
 */
export const previousSerialNumber = (data) => {
  return data.previousSerialNumber ? (
    <IconNormalBold
      id="pnlPrevSerialNumber"
      normalId="lblPrevSerialNumber"
      normalText={<Trans>{"common:LABEL_PREVIOUS_SERIAL_NUMBER"}</Trans>}
      boldId="txtPrevSerialNumber"
      boldText={data.previousSerialNumber || ""}
    />
  ) : null;
};

/**
 * @description - Display replacement serail number.
 */
export const replacementSerialNumber = (data) => {
  return data.replacementSerialNumber ? (
    <IconNormalBold
      id="pnlReplacementSerialNumber"
      normalId="lblReplacementSerialNumber"
      normalText={<Trans>{"common:LABEL_REPLACEMENT_SERIAL_NUMBER"}</Trans>}
      boldId="txtReplacementSerialNumber"
      boldText={data.replacementSerialNumber || ""}
    />
  ) : null;
};

/**
 * @description - external asset id.
 */
export const ExternalAssetId: React.FC<IExternalAssetIdProps> = ({ extAssetId }: IExternalAssetIdProps) =>
  extAssetId ? <ExternalId extId={extAssetId} /> : null;

/**
 * @description - Description.
 */
export const description = (data) => {
  return data.description ? (
    <IconNormalBold
      id="pnlDescription"
      normalId="lblDescription"
      normalText={<Trans>{"common:LABEL_DESCRIPTION"}</Trans>}
      boldId="txtDescription"
      boldText={data.description || ""}
    />
  ) : null;
};

/**
 * @description - show assetUsageConditionState from asset detail.
 */
export const assetUsageConditionState = (data) => {
  const aucState = data.assetUsageConditionState && data.assetUsageConditionState.value;
  return (
    aucState && (
      <IconNormalBold
        id="pnlassetUsageConditionState"
        normalId="lblassetUsageConditionState"
        normalText={<Trans>{"common:LABEL_AUC_STATUS"}</Trans>}
        boldId="txtDescription"
        boldText={aucState}
      />
    )
  );
};

/**
 * @description - show assetUsageConditionDate from asset detail.
 */
export const assetUsageConditionDate = (data) => {
  const aucDate = data.assetUsageConditionDate;
  return (
    aucDate && (
      <IconNormalBold
        id="pnlAssetUsageConditionDate"
        normalId="lblAssetUsageConditionDate"
        normalText={<Trans>{"common:LABEL_ASSET_USAGE_DATE"}</Trans>}
        boldId="txtAssetUsageConditionDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(aucDate)}
      />
    )
  );
};

/**
 * @description - show hiltiOwnerName from asset detail.
 */
export const hiltiOwnerName = (data) => {
  const hiltiAssetOwnerName = data.hiltiAssetOwnerName;
  return (
    hiltiAssetOwnerName && (
      <IconNormalBold
        id="pnlHiltiOwnerName"
        normalId="lblHiltiOwnerName"
        normalText={<Trans>{"assets:HILTI_ASSET_OWNER"}</Trans>}
        boldId="txtHiltiOwnerName"
        boldText={data.hiltiAssetOwnerName}
      />
    )
  );
};
