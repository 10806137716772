// @ts-ignore
import { CommonActions, UserSettingActions } from "custom-actions";
import { createReducer } from "../../../utils/commonUtils";
import { IUserSettingState } from "./iUserSettingModuleState";

export const masterDataInitialState: IUserSettingState = {
  locationList: null,
  userSettingData: null,
  languageList: null,
  userSettingDetailData: null,
  links: null,
  touLinks: null,
  activationUserData: null,
};

const setLocationList = (state, action) => {
  return {
    ...state,
    locationList: action.payload,
  };
};

const updateUserLanguage = (state, action) => {
  return {
    ...state,
    userSettingData: {
      ...state.userSettingData,
      applyLanguageCode: action.language,
    },
  };
};

const userSettingsReducer = createReducer(
  {},
  {
    [UserSettingActions.OM_USER_SETTING_LOCATIONS_RESPONSE]: setLocationList,
    [UserSettingActions.UPDATE_USER_LANGUAGE]: updateUserLanguage,
  },
);

const userSettingReducer = (state = {}, action) => {
  switch (action.type) {
    case UserSettingActions.UM_GET_USER_SETTING:
    case UserSettingActions.UM_GET_USER_SETTING_DETAIL:
      return {
        ...state,
      };
    case CommonActions.OM_LANGUAGE_RESPONSE:
      return {
        ...state,
        languageList: action.payload,
      };
    case UserSettingActions.UM_USER_SETTING_RESPONSE:
      return {
        ...state,
        userSettingData: action.payload,
      };
    case UserSettingActions.UM_USER_SETTING_DETAIL_RESPONSE:
      return {
        ...state,
        userSettingDetailData: action.payload,
      };
    case UserSettingActions.GET_APP_LINKS_RESPONSE:
      return {
        ...state,
        links: action.payload,
      };
    case UserSettingActions.GET_TOU_LINKS_RESPONSE:
      return {
        ...state,
        touLinks: action.payload,
      };
    case UserSettingActions.UM_GET_ACTIVATION_USER_INFO_SUCCESS:
      return {
        ...state,
        activationUserData: action.payload,
      };
    default:
      return userSettingsReducer(state, action);
  }
};

export default userSettingReducer;

export const getUserLanguageCode = (state: IUserSettingState) => state?.userSettingData?.applyLanguageCode;
