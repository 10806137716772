import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import { TransferCartConstants } from "custom-actions";
import { LocationState } from "../../../utils/enums/locationEnums";
import { TransferToListName } from "../../../utils/enums/transferAssetsEnum";
import ModuleName from "../../../utils/enums/moduleNameEnum";
import {
  appendSelectedPropertyToList,
  clearAllCheckedRows,
  clearAllSelection,
  setAllCheckedRows,
  setCheckboxSelection,
  setRowSelection,
} from "../../../utils/list/listUtils";
import { addTransferCartIdToSessionStore, removeItemFromSessionStore } from "../../../utils/transferCartUtils";
import { createReducer } from "../../../utils/commonUtils";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { ITransferCartState } from "./iTransferCartState";

const initialState: ITransferCartState = {
  disabledCheckBox: true,
  isSetReturnDate: false,
  isTransferCartLoaded: false,
  list: [],
  listName: TransferToListName.LIST,
  loader: {
    isStorageAssetListLoading: false,
  },
  locationGroupTransfer: {
    list: [],
    selectedList: [],
    selectedLocationGroupDetail: null,
    selectedLocationGroupId: null,
  },
  qtyItemSearchResponse: null,
  quickTransferResponse: null,
  storageAssetData: {},
  transferDate: "",
  transferDialogueList: [],
  isQtyItemPresent: false,
  isDuplicateTransferDetected: {
    showDuplicatePopUp: false,
    transferPayload: null,
    isQuickTransfer: true,
  },
  isConfirmDisabled: false,
  isDuplicateConfirmDisabled: false,
  searchString: "",
};

const putLocationGroupsDetails = (state, action) => {
  const query = action.payload.query;
  const { limit, offset, totalRecords } = query;
  const currentIndex = action.payload.currentIndex;
  const parsedLocationGroupList = action.payload.parsedLocationGroupList;
  const isPaginatedList = action.payload.isPaginatedList;
  const tempList = state.locationGroupTransfer.list.splice(0);
  if (!currentIndex && isPaginatedList) {
    tempList.push({
      limit,
      listRes: parsedLocationGroupList,
      offset,
      totalRecords,
    });
  } else {
    const tempListValue = HelperLodash.get(tempList, currentIndex);
    const updatedListResponse = {
      limit,
      listRes: [...tempListValue.listRes, ...parsedLocationGroupList],
      offset,
      totalRecords,
    };
    HelperLodash.set(tempList, currentIndex, updatedListResponse);
  }

  return {
    ...state,
    locationGroupTransfer: {
      ...state.locationGroupTransfer,
      list: tempList,
      selectedLocationGroupDetail: action.payload.locationGroupDetail,
    },
  };
};

const toggleTransferCartCheckbox = (state) => {
  const clearAllSelectionList = state[state.listName].slice(0);
  return {
    ...state,
    disabledCheckBox: !state.disabledCheckBox,
    [state.listName]: clearAllCheckedRows(clearAllSelectionList),
  };
};

const selectRow = (state, action) => {
  const selectRowList = state[state.listName] && state[state.listName].slice(0);
  return {
    ...state,
    [state.listName]: setCheckboxSelection(selectRowList, action.rowObj.uniqueId, true, "uniqueId"),
  };
};

const unselectRow = (state, action) => {
  const unSelectedList = state[state.listName].slice(0);

  return {
    ...state,
    [state.listName]: setCheckboxSelection(unSelectedList, action.rowObj.uniqueId, false, "uniqueId"),
  };
};

const clearAllRow = (state) => {
  const clearAllSelectionList = state[state.listName].slice(0);
  return {
    ...state,
    [state.listName]: clearAllCheckedRows(clearAllSelectionList),
  };
};

const setDuplicateTransferTrue = (state, action) => {
  return {
    ...state,
    isDuplicateTransferDetected: {
      showDuplicatePopUp: true,
      transferPayload: action.payload,
      isQuickTransfer: action.isQuickTransfer,
    },
  };
};

const editTransfercartInfo = (state) => {
  return {
    ...state,
    isDuplicateTransferDetected: {
      showDuplicatePopUp: false,
      transferPayload: null,
    },
  };
};

const setTransferCartId = (state, action) => {
  const uID = uuidv4();
  const timestamp = moment(new Date()).format("YYYY-MM-DDThh:mm:ss");
  let uniqueCartId = timestamp + "-web-am-" + action.tenantId + "-" + uID;
  try {
    uniqueCartId = window.btoa(uniqueCartId);
  } catch (error) {
    //sending UUID without encoding
  }
  addTransferCartIdToSessionStore(uniqueCartId);
  return {
    ...state,
    cartId: uniqueCartId,
  };
};

const setItemToTransferCart = (state, action) => {
  return {
    ...state,
    [state.listName]: action.payload,
  };
};

const setTransferCartVisiblity = (state, action) => {
  return {
    ...state,
    isTransferCartLoaded: action.payload,
  };
};

const updateCartItems = (state, action) => {
  return {
    ...state,
    list: action.payload,
    scanCodeReset: true,
  };
};

const errorMessage = (state, action) => {
  return {
    ...state,
    addAssetViaScanCodeFailed: action.payload[0],
    errorCaptured: action.payload[1],
  };
};

const errorCaptured = (state, action) => {
  return {
    ...state,
    errorCaptured: action.payload,
  };
};

const selectAllRow = (state) => {
  const selectAllList = state.list.slice(0);
  return {
    ...state,
    [state.listName]: setAllCheckedRows(selectAllList),
  };
};

const setTransferDate = (state) => {
  return {
    ...state,
    currentDate: LocaleManager.dateHelpers.getNativeDateInTenantTimezone(),
  };
};

// It is used to set the "Set return date" switch status that is used at the "Set dates" step of transfer asset.
const setReturnDateStatus = (state, action) => {
  return {
    ...state,
    isSetReturnDate: action.isSetReturnDate,
  };
};

// It is used to clear all the changed state at transfer asset steps.
const transferDialogClearAllState = (state) => {
  return {
    ...state,
    disabledCheckBox: true,
    isSetReturnDate: false,
    isTransferCartLoaded: false,
    transferCartResponse: null,
    listName: TransferToListName.LIST,
    transferDialogueList: [],
  };
};

const setTransferDateforAssetList = (state, action) => {
  return {
    ...state,
    transferDate: action.payload,
  };
};

const nullifyResponse = (state) => {
  return {
    ...state,
    addAssetViaScanCodeFailed: "",
    errorCaptured: "",
  };
};

const updateItemsInCart = (state, transferedAssets, uniqueId) => {
  // Remove the transfered assets from the session storage.
  removeItemFromSessionStore(transferedAssets);
  return state.list.filter((item) => {
    return !transferedAssets.some((ele) => HelperLodash.get(ele, uniqueId) === HelperLodash.get(item, uniqueId));
  });
};

// Clearing all the transfered assets from the cart.
const clearAllTransferedAssets = (state, action) => {
  return {
    ...state,
    list: updateItemsInCart(state, state.transferDialogueList, "uniqueId"),
    transferDialogueList: [],
    transferCartResponse: action.payload,
  };
};

const filterValidTransferedAssets = (list, validResponse) =>
  list.reduce((result, asset) => {
    const assetExistsInList = validResponse.findIndex((response) => asset.id === response.id);
    if (assetExistsInList !== -1) {
      return result.concat(asset);
    }
    return result;
  }, []);

// Clearing the transfered assets from store for the partially transfered success call.
const clearPartialTransferedAssets = (state, action) => {
  const { validResponse = [], invalidResponse = [] } = action.payload;
  return {
    ...state,
    transferDialogueList: filterValidTransferedAssets(state.transferDialogueList, invalidResponse),
    list: updateItemsInCart(state, filterValidTransferedAssets(state.list, validResponse), "uniqueId"),
    transferCartResponse: { ...action.payload, isPartiallySubmitted: true },
  };
};

const clearPartialTransferedAssetsResponse = (state) => ({
  ...state,
  transferCartResponse: null,
});

/**
 * @description Function updates the state value with updated storageAssetData response object
 * @param {object} state - TransferCart state
 */
const updateStorageAssetsList = (state, action) => {
  const oldResponse = action.payload.offset ? state.storageAssetData.list : [];
  action.payload.storageAssetData.list = [
    ...oldResponse,
    ...appendSelectedPropertyToList(action.payload.storageAssetData.response),
  ];
  delete action.payload.storageAssetData.response;
  return {
    ...state,
    storageAssetData: {
      ...action.payload.storageAssetData,
      list: action.payload.storageAssetData.list,
    },
  };
};

const selectStorageAssetRow = (state, action) => {
  const list = state.storageAssetData.list || [];
  setRowSelection(list, action.id, true, "assetId");
  return {
    ...state,
    storageAssetData: {
      ...state.assetsList,
      list,
    },
  };
};

const unselectStorageAssetRow = (state, action) => {
  const list = state.storageAssetData.list || [];
  setRowSelection(list, action.id, false, "assetId");
  return {
    ...state,
    storageAssetData: {
      ...state.assetsList,
      list,
    },
  };
};

const clearAllStorageAssetRow = (state) => {
  const list = state.storageAssetData.list || [];
  return {
    ...state,
    storageAssetData: {
      ...state.assetsList,
      list: clearAllSelection(list),
    },
  };
};

const storageAssetListLoader = (state, showLoader) => {
  return {
    ...state,
    loader: {
      isStorageAssetListLoading: showLoader,
    },
  };
};

const setSearchQueryState = (action, state) => {
  return {
    ...state,
    searchString: action.searchString,
  };
};
const updateQtyItemScanCodeSearchReponse = (state, action) => {
  return {
    ...state,
    qtyItemSearchResponse: action.payload,
  };
};

const getStateList = (mode, state) => {
  if (mode) {
    return state.locationGroupTransfer.filterList || [];
  } else {
    return state.locationGroupTransfer.list || [];
  }
};
/**
 * @description Function to set location groups and handle PUT_LOCATION_GROUPS action type
 */
const putLocationGroups = (state, action) => {
  const query = action.payload.query;
  const tempList: any = getStateList(action.payload.isFilterMode, state);
  let listInfo = [];
  const parsedLocationGroupList = action.payload.parsedLocationGroupList[0];
  if (action.payload.parsedLocationGroupList.length > 1) {
    // opening all selected location after back-track eralier its from isPaginatedList
    action.payload.parsedLocationGroupList.forEach((row, index) => {
      listInfo.push({
        limit: index === 0 ? query.limit : null,
        listRes: row,
        offset: index === 0 ? query.offset : null,
        totalRecords: index === 0 ? query.totalRecords : row.length,
      });
    });
  } else if (!query.offset) {
    listInfo = [
      {
        limit: query.limit,
        listRes: parsedLocationGroupList,
        offset: query.offset,
        totalRecords: query.totalRecords,
      },
    ];
  } else {
    tempList[0].limit = query.limit; // update limit
    tempList[0].offset = query.offset; // update offset
    tempList[0].listRes = [...tempList[0].listRes, ...parsedLocationGroupList]; // append list on pagination
    tempList[0].totalRecords = query.totalRecords; // update totalRecords
    listInfo = tempList;
  }
  const listName = action.payload.isFilterMode ? "filterList" : "list";
  const list = {
    [listName]: listInfo.slice(0),
  };
  return {
    ...state,
    locationGroupTransfer: {
      ...state.locationGroupTransfer,
      isListLoaded: true,
      selectedList:
        action.payload.selectedList.length > 0 ? action.payload.selectedList : state.locationGroupTransfer.selectedList,
      ...list,
    },
  };
};
const getSelectedLocationGroupDetail = (state, action) => {
  if (
    HelperLodash.isEqual(action.viewMode, ModuleName.transfer) &&
    action.payload &&
    action.payload.locationState &&
    action.payload.locationState.code !== LocationState.INACTIVE
  ) {
    return action.payload;
  }
  return state.locationGroupTransfer.selectedLocationGroupDetail;
};
/**
 * @description Function to put location details and handle OM_PUT_LOCATION_BY_ID action type
 */
const putLocationById = (state, action) => {
  const selectedLocationGroupDetail = getSelectedLocationGroupDetail(state, action);
  return {
    ...state,
    locationDetails: action.payload,
    locationGroupTransfer: {
      ...state.locationGroupTransfer,
      selectedLocationGroupDetail,
      selectedLocationGroupId: selectedLocationGroupDetail.id,
    },
  };
};

const setTransferListFromCart = (state, action) => {
  return {
    ...state,
    listName: action.payload.listName,
    transferDialogueList: action.payload.items.map((item) => {
      item.isSelected = false;
      item.metaData = { ...item.metaData, isChecked: false };
      return item;
    }),
  };
};

const selectAllFromTransferList = (state) => {
  const selectAllList = [...state.transferDialogueList];
  return {
    ...state,
    [state.listName]: setAllCheckedRows(selectAllList),
  };
};

// function to handle TransferCartConstants.SUBMIT_QUICK_TRANSFER_RESPONSE action type
const submitQuickTransferResponse = (state, action) => {
  return {
    ...state,
    quickTransferResponse: action.quickTransferResponse,
  };
};

// function to handle TransferCartConstants.SET_SELECTED_LOCATION_GROUP_ID_TRANSFER action type
const setSelectedLocationGroupTransfer = (state, action) => {
  return {
    ...state,
    locationGroupTransfer: {
      ...state.locationGroupTransfer,
      selectedLocationGroupDetail: action.payload ? state.selectedLocationGroupDetail : null,
      selectedLocationGroupId: action.payload,
    },
  };
};
// function to handle TransferCartConstants.SET_LOCATION_GROUPS_SELECTED_ITEMS action type
const setLocationGroupSelectedItems = (state, action) => {
  return {
    ...state,
    locationGroupTransfer: {
      ...state.locationGroupTransfer,
      selectedList: action.payload || [],
      selectedLocationGroupDetail:
        action.payload.length > 0 ? action.payload[action.payload.length - 1].locationDetails : null,
    },
  };
};

// function to handle TransferCartConstants.SET_LOCATION_GROUPS_LIST action type
const setLocationGroupsList = (state, action) => {
  return {
    ...state,
    locationGroupTransfer: {
      ...state.locationGroupTransfer,
      list: action.payload,
    },
  };
};
// function to handle TransferCartConstants.CLEAR_LOCATION_GROUP_TRANSFER action type
const clearLocationGroupTransfer = (state) => {
  return {
    ...state,
    locationGroupTransfer: initialState.locationGroupTransfer,
  };
};

const setQtyItemPresent = (state, action) => {
  return {
    ...state,
    isQtyItemPresent: action.payload,
  };
};
const toggleConfirmTransfer = (state, action) => {
  return {
    ...state,
    isConfirmDisabled: action.payload,
  };
};

const toggleDuplicateTransferConfirm = (state, action) => {
  return {
    ...state,
    isDuplicateConfirmDisabled: action.payload,
  };
};

const transferCartReducers = createReducer(initialState, {
  [TransferCartConstants.SUBMIT_QUICK_TRANSFER_RESPONSE]: submitQuickTransferResponse,
  [TransferCartConstants.SET_SELECTED_LOCATION_GROUP_ID_TRANSFER]: setSelectedLocationGroupTransfer,
  [TransferCartConstants.SET_LOCATION_GROUPS_SELECTED_ITEMS]: setLocationGroupSelectedItems,
  [TransferCartConstants.SET_LOCATION_GROUPS_LIST]: setLocationGroupsList,
  [TransferCartConstants.SET_LOCATION_GROUP_DETAILS]: putLocationGroupsDetails,
  [TransferCartConstants.PUT_TRANSFER_LOCATION_GROUPS]: putLocationGroups,
  [TransferCartConstants.CLEAR_LOCATION_GROUP_TRANSFER]: clearLocationGroupTransfer,
  [TransferCartConstants.PUT_LOCATION_BY_ID_TRANSFER]: putLocationById,
  [TransferCartConstants.SET_TRANSFER_LIST]: setTransferListFromCart,
  [TransferCartConstants.SET_QTY_ITEM_PRESENT]: setQtyItemPresent,
});

const transferCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case TransferCartConstants.AM_SET_TRASNFER_CART_ITEM_STORE:
      return setItemToTransferCart(state, action);
    case TransferCartConstants.AM_SET_TRASNFER_CART_ID:
      return setTransferCartId(state, action);
    case TransferCartConstants.AM_SET_TRANSFER_CART_LOADED:
      return setTransferCartVisiblity(state, action);
    case TransferCartConstants.AM_UPDATE_TRANSFER_CART_LIST:
      return updateCartItems(state, action);
    case TransferCartConstants.ADD_BY_SCANCODE_INVENTORY_NUMBER_FAILED:
      return errorMessage(state, action);
    case TransferCartConstants.TRANSFER_CHECKED_ROW:
      return selectRow(state, action);
    case TransferCartConstants.TRANSFER_UNCHECKED_ROW:
      return unselectRow(state, action);
    case TransferCartConstants.TRANSFER_CLEAR_ALL_ROWS_SELECTION:
      return clearAllRow(state);
    case TransferCartConstants.AM_ERROR_CAPTURED:
      return errorCaptured(state, action);
    default:
      return secondaryReducer(state, action);
  }
};
const secondaryReducer = (state, action) => {
  switch (action.type) {
    case TransferCartConstants.TRANSFER_SELECT_ALL:
      return selectAllRow(state);
    case TransferCartConstants.TRANSFER_SET_TRANSFER_DATE:
      return setTransferDate(state);
    case TransferCartConstants.TOGGLE_TRANSFER_CART_CHECKBOX:
      return toggleTransferCartCheckbox(state);
    case TransferCartConstants.TRANSFER_SET_RETURN_DATE_STATUS:
      return setReturnDateStatus(state, action);
    case TransferCartConstants.TRANSFER_DIALOG_CLEAR_ALL_STATE:
      return transferDialogClearAllState(state);
    case TransferCartConstants.TRANSFER_SUBMIT_CART_RESPONSE:
      return clearAllTransferedAssets(state, action);
    case TransferCartConstants.UPDATE_ADD_QTY_ITEM_BY_SCANCODE_RESPONSE:
      return updateQtyItemScanCodeSearchReponse(state, action);
    case TransferCartConstants.SELECT_ALL_IN_TRANSFER_LIST:
      return selectAllFromTransferList(state);
    default:
      return terinaryCaseReducer(state, action);
  }
};
const terinaryCaseReducer = (state, action) => {
  switch (action.type) {
    case TransferCartConstants.SET_TRANSFER_DATE:
      return setTransferDateforAssetList(state, action);
    case TransferCartConstants.NULLIFY_ERRORS:
      return nullifyResponse(state);
    case TransferCartConstants.AM_PARTIAL_TRANSFERED_ASSETS:
      return clearPartialTransferedAssets(state, action);
    case TransferCartConstants.AM_PARTIAL_TRANSFERED_ASSETS_CLEAR_RESPONSE:
      return clearPartialTransferedAssetsResponse(state);
    case TransferCartConstants.SET_TRANSFER_CART_SEARCH_QUERY:
      return setSearchQueryState(action, state);
    case TransferCartConstants.AM_GET_STORAGE_ASSET_LIST_SUCCESS:
      return updateStorageAssetsList(state, action);
    case TransferCartConstants.STORAGE_ASSET_SELECT_ROW:
      return selectStorageAssetRow(state, action);
    case TransferCartConstants.STORAGE_ASSET_UNSELECT_ROW:
      return unselectStorageAssetRow(state, action);
    default:
      return quaternary(state, action);
  }
};

const quaternary = (state, action) => {
  switch (action.type) {
    case TransferCartConstants.CLEAR_ALL_STORAGE_ASSETS_SELECTION:
      return clearAllStorageAssetRow(state);
    case TransferCartConstants.TRANSFER_STORAGE_ASSET_LOADER_START:
      return storageAssetListLoader(state, true);
    case TransferCartConstants.TRANSFER_STORAGE_ASSET_LOADER_END:
      return storageAssetListLoader(state, false);
    case TransferCartConstants.DUPLICATE_TRANSFER_DETECTED:
      return setDuplicateTransferTrue(state, action);
    case TransferCartConstants.EDIT_TRANSFERCART_INFO:
      return editTransfercartInfo(state);
    case TransferCartConstants.TOGGLE_CONFIRM_TRANSFER:
      return toggleConfirmTransfer(state, action);
    case TransferCartConstants.TOGGLE_DUPLICATE_TRANSFER_CONFIRM:
      return toggleDuplicateTransferConfirm(state, action);
    default:
      return transferCartReducers(state, action);
  }
};

export default transferCartReducer;
