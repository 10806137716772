import React from "react";
import { IMultiTagComponentProps } from "./iMultiTaginput";
import MultiTagFormInput from "./multiTagFormInput";
import MultiTagInput from "./multiTagInput";

const MultiTagInputComponent = (props: IMultiTagComponentProps) => {
  const { isFormControl, ...otherProps } = props;
  const { onTagChange } = props;
  return isFormControl ? (
    <MultiTagFormInput {...otherProps} />
  ) : (
    <MultiTagInput {...otherProps} onChange={onTagChange} />
  );
};

export default MultiTagInputComponent;
