import React from "react";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import { dependencyResolver } from "../grid/gridUtils";
import GridView from "../grid/gridView";
import {
  ChildWrapper,
  Count,
  CountWrapper,
  ExpandableRow,
  ExpandableRowLink,
  ExpandChildWrapper,
  ExpandMore,
  ShowMoreButton,
  ShowMoreWrapper,
} from "./gridStyle";
import { IExpandableRowTemplate, IExpandableRowTemplateState } from "./iListProps";

class ExpandableRowTemplate extends React.PureComponent<IExpandableRowTemplate, IExpandableRowTemplateState> {
  state: IExpandableRowTemplateState = {
    showChildren: false,
  };
  showChildData = (e) => {
    const { data, templateProps } = this.props;
    dependencyResolver(templateProps.dependencies, templateProps.rowSettings.customInfo.expandRowHandler)(e, data);
  };
  loadMoreRecords = () => {
    const { data, templateProps } = this.props;
    return dependencyResolver(templateProps.dependencies, templateProps.rowSettings.customInfo.fetchChildData)(data);
  };
  expandChildHander = (e) => {
    this.setState(
      (prevState) => {
        return { showChildren: !prevState.showChildren };
      },
      () => this.showChildData(e),
    );
  };
  hasMoreChildAssets = () => {
    const { data } = this.props;
    const { limit, offset, parentCount } = (data && data.childData) || { limit: 0, offset: 0, parentCount: 0 };
    return parentCount > limit + offset;
  };

  /*
    true -> current row is selected or any of its child selected
    false -> current row and none of its child is selected
    TBD: replace find with Array some
  */
  isChildSelected = (data) => {
    const childList = data && data.childData && data.childData.response;
    return !!(childList || []).find((childItem) => {
      return !!(childItem.metaData && childItem.metaData.isChecked) || this.isChildSelected(childItem);
    });
  };

  /*
    row selection in indeteminate(selection), if the row is not selected and
    any one of its child is selected.
  */
  isInderminateState = (data) => {
    return !data.metaData.isChecked && this.isChildSelected(data);
  };

  getLinkText = (dependencies, rowSettings, data) => {
    if (rowSettings.customInfo.rowLinkText) {
      return dependencyResolver(dependencies, rowSettings.customInfo.rowLinkText)(data);
    }
    return "";
  };

  getLinkCount = (dependencies, rowSettings, data) => {
    if (rowSettings.customInfo.rowLinkCount) {
      return dependencyResolver(dependencies, rowSettings.customInfo.rowLinkCount)(data);
    }
    return null;
  };

  renderUnclikableLinkText = (linkCount) => {
    const { data, templateProps } = this.props;
    const { dependencies, rowSettings } = templateProps;
    return !rowSettings.isExpendClickable && linkCount ? (
      <ChildWrapper>
        <CountWrapper>{this.getLinkText(dependencies, rowSettings, data)}</CountWrapper>
      </ChildWrapper>
    ) : null;
  };

  render() {
    const { data, disableCheckBoxColumn, rowIndex, renderRowTemplate, templateProps } = this.props;
    const { dependencies, rowSettings, hideRowArrow, handleRowLinkClick } = templateProps;
    const indeterminate = this.isInderminateState(data);
    const isRowExpandable = dependencyResolver(
      templateProps.dependencies,
      templateProps.rowSettings.customInfo.expandRowHandler,
    );
    const linkCount = this.getLinkCount(dependencies, rowSettings, data);
    return (
      <ExpandableRow className={this.state.showChildren ? "row-has-childreen row-expanded" : "row-has-childreen"}>
        {renderRowTemplate({ data, indeterminate, templateProps, rowIndex })}
        {rowSettings.isExpendClickable && (
          <ExpandChildWrapper
            className="show-more toggle"
            onClick={(e) => (isRowExpandable ? this.expandChildHander(e) : null)}
            disableCheckBoxColumn={disableCheckBoxColumn}
          >
            <ExpandMore>
              {isRowExpandable && (
                <SquareIcon name={IconName.Chevron} className={this.state.showChildren ? "toggle" : null} />
              )}
              <CountWrapper>
                <Count>{data[rowSettings.customInfo.childCountKey]}</Count>
                <span>{dependencies.t(rowSettings.customInfo.textLink)}</span>
              </CountWrapper>
            </ExpandMore>
          </ExpandChildWrapper>
        )}
        {this.state.showChildren && (
          <>
            {linkCount ? (
              <ExpandableRowLink to={handleRowLinkClick(data)}>
                <ExpandChildWrapper disableCheckBoxColumn={false}>
                  <ExpandMore>
                    <CountWrapper>
                      <Count>{linkCount}</Count>
                      <span>{this.getLinkText(dependencies, rowSettings, data)}</span>
                    </CountWrapper>
                    <SquareIcon name={IconName.Chevron} />
                  </ExpandMore>
                </ExpandChildWrapper>
              </ExpandableRowLink>
            ) : null}
            <GridView
              handleRowClick={(rowObj) => {
                templateProps.handleRowClick(rowObj);
              }}
              handleRowLinkClick={handleRowLinkClick}
              handleCheckboxClick={(rowObj) => {
                templateProps.handleCheckboxClick(rowObj);
              }}
              columns={templateProps.columns}
              rowsData={(data.childData && data.childData.response) || []}
              idKey={templateProps.idKey}
              dependencies={{ ...templateProps.dependencies }}
              disableCheckBoxColumn={templateProps.disableCheckBoxColumn}
              showTooltip={templateProps.showTooltip}
              rowSettings={templateProps.rowSettings}
              hideRowArrow={hideRowArrow}
            />
            {this.hasMoreChildAssets() && (
              <ShowMoreWrapper>
                <ShowMoreButton onClick={this.loadMoreRecords}>
                  {dependencies.t(rowSettings.customInfo.loadMoreRecordsText)}
                </ShowMoreButton>
              </ShowMoreWrapper>
            )}
          </>
        )}
        {this.renderUnclikableLinkText(linkCount)}
      </ExpandableRow>
    );
  }
}

export default ExpandableRowTemplate;
