import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M9,1 L14,4 L14,9 L22,17 L18,21 L13.1440646,16.1436684 C13.3736896,16.7176136 13.5,17.3440561 13.5,18 C13.5,20.7614237 11.2614237,23 8.5,23 C7.08092951,23 5.7999346,22.4088282 4.88985729,21.4593266 L4.72362133,21.2771527 L5.7875003,20.2120358 C6.42931431,20.9981133 7.40604619,21.5 8.5,21.5 C10.4329966,21.5 12,19.9329966 12,18 C12,16.0670034 10.4329966,14.5 8.5,14.5 C6.79972014,14.5 5.38261318,15.7124074 5.06604147,17.3198598 L5.03543731,17.5000497 L6.5,17.5 L4.25,20 L2,17.5 L3.52465783,17.5003002 C3.77538101,14.9734949 5.90722328,13 8.5,13 C9.15594388,13 9.78238637,13.1263104 10.3563316,13.3559354 L10,13 L5,13 L2,8 L4,6 L7,9 L10,6 L7,3 L9,1 Z M9,15.5 L9,17.792 L10.4748737,19.267767 L9.76776695,19.9748737 L8,18.2071068 L8,15.5 L9,15.5 Z" />
    </g>
  </svg>
);

export default SVG;
