/**
 * @description Action constants to be used for Asset Tracking
 *
 * format: {feature}_{action}
 */
const AssetTrackingActionConstants = {
  GET_ACTIVE_TRACKING_SETTINGS: "GET_ACTIVE_TRACKING_SETTINGS", // action to get active tracking settings
  RESET_ACTIVE_TRACKING_SETTINGS: "RESET_ACTIVE_TRACKING_SETTINGS", // action to reset asset tracking success and error response
  TRACKING_ACTIVE_SETTINGS_RESPONSE: "TRACKING_ACTIVE_SETTINGS_RESPONSE", // action to set active tracking settings
  UPDATE_ACTIVE_TRACKING_FAILURE: "UPDATE_ACTIVE_TRACKING_FAILURE", // action to set error response
  UPDATE_ACTIVE_TRACKING_SETTINGS: "UPDATE_ACTIVE_TRACKING_SETTINGS", // action to update asset tracking settings
  UPDATE_ACTIVE_TRACKING_SUCCESS: "UPDATE_ACTIVE_TRACKING_SUCCESS", // action to set success response
};

export default AssetTrackingActionConstants;
