import React from "react";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import { authKeyDependencyResolver, visibleDependencyResolver } from "./actionBarUtil";
import ActionDropDownValues from "./actionDropDownValues";
import { IActionDropDownProps, IActionDropDownState } from "./iactionDropDownProps";

const ActionDropDownWrapper = styled.div`
  width: 72px;
  height: 72px;
  border-left: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover svg polygon {
    fill: ${(props) => props.theme.colors.base.black};
  }
  .action-toggle {
    svg {
      transform: rotate(90deg);
    }
    &.active svg {
      transform: rotate(270deg);
      margin-top: -4px;
      polygon {
        fill: ${(props) => props.theme.colors.base.black};
      }
    }
  }
`;
const ActionViewMenuList = styled.div`
  overflow: auto;
  position: absolute;
  z-index: 999999;
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow:
    0 2px 12px 0 rgba(82, 79, 83, 0.5),
    inset 0 0 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
  width: 320px;
  right: 0;
  top: 72px;
  .sort-value {
    color: ${(props) => props.theme.colors.base.steel};
    cursor: pointer;
    padding: 8px 16px 8px 8px;
    line-height: 24px;
    min-height: 40px;
    display: flex;
    align-items: center;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    &[data-selected="true"] {
      font-family: ${(props) => props.theme.fontBold.fontFamily};
      font-weight: ${(props) => props.theme.fontBold.fontWeight};
      background-color: ${(props) => props.theme.sort.selected};
      color: ${(props) => props.theme.colors.base.black};
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.base.customLightGrey};
      color: ${(props) => props.theme.colors.base.black};
    }
    &:focus {
      background-color: ${(props) => props.theme.colors.base.greyDark};
      color: ${(props) => props.theme.colors.base.black};
      outline: none;
    }
    &:hover svg path {
      fill: ${(props) => props.theme.colors.base.black};
    }
    span {
      margin-right: 16px;
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export class ActionDropDown extends React.PureComponent<IActionDropDownProps, IActionDropDownState> {
  state = {
    toggleActionView: false,
  };

  onToggleList = () => {
    this.setState((prevState) => ({
      toggleActionView: !prevState.toggleActionView,
    }));
  };

  renderActions = (actionItem) => {
    if (actionItem.type === "TextWithIcon") {
      return this.renderTextWithIcon(actionItem, this.props);
    }
    return null;
  };

  /**
   * @description This method is use to check whether item will visible or not.
   */
  isVisible = (actionItem, props) => visibleDependencyResolver(actionItem, props.dependencies);

  renderTextWithIcon = (actionItem, props) => {
    const { dependencies, onActionClicked, t } = props;

    return this.isVisible(actionItem, props) ? (
      <ActionDropDownValues
        onActionClicked={onActionClicked}
        t={t}
        handleOnClickAway={this.handleOnClickAway}
        actionItem={actionItem}
        dependencies={dependencies}
      />
    ) : null;
  };

  handleOnClickAway = () => {
    this.setState({ toggleActionView: false });
  };

  menuList = (CheckAuthorization) => {
    return (
      <ActionViewMenuList id="actionViewMenuList">
        {this.props.actionDropdownItems.map((item) => {
          const authKey = authKeyDependencyResolver(item, this.props.dependencies);
          return authKey ? (
            <CheckAuthorization yes={() => this.renderActions(item)} permissionKey={authKey} />
          ) : (
            this.renderActions(item)
          );
        })}
      </ActionViewMenuList>
    );
  };

  render() {
    const CheckAuthorization = this.props.checkAuthorization;

    return (
      <ActionDropDownWrapper
        id="actionDropDownWrapper"
        className={this.state.toggleActionView ? "action-toggle active" : "action-toggle"}
        onClick={this.onToggleList}
      >
        <SquareIcon
          name={IconName.ArrowDown}
          className={this.state.toggleActionView ? "action-toggle active" : "action-toggle"}
          id="DropDownArrow"
        />
        {this.state.toggleActionView ? this.menuList(CheckAuthorization) : ""}
      </ActionDropDownWrapper>
    );
  }
}

export default ActionDropDown;
