export enum LicenseEnums {
  hideAssetModel = "hideAssetModel",
  hideAssetImage = "hideAssetImage",
  hideAssetManufacture = "hideAssetManufacture",
  hideAssetDescription = "hideAssetDescription",
  hideAdditionalFeatures = "hideAdditionalFeatures",
  hideAssetUsageCondition = "hideAssetUsageCondition",
  hideStorageAsset = "hideStorageAsset",
  hideAssetCosts = "hideAssetCosts",
  hideServices = "hideServices",
  hideEmployeeCertificates = "hideEmployeeCertificates",
  hideEmployeeEmail = "hideEmployeeEmail",
  hideQuickTransferAsset = "hideQuickTransferAsset",
  hideTransferCartAsset = "hideTransferCartAsset",
  hideAddAssetManuallyButton = "hideAddAssetManuallyButton",
  hideAssetViewTemplate = "hideAssetViewTemplate",
}

export enum ProModuleFeatures {
  SMART_INVENTORY = "smart_inventory",
  HE_UTILIZATION = "he_utilization",
  PRIVACY_MODE = "privacy_mode",
  SUGGESTED_TRANSFER = "suggested_transfer",
  ONE_MAP_VIEW = "one_map_view",
  GEO_TAG = "geo_tag",
  GATEWAY_REPORT = "gateway_report",
  AUTOMATED_TRANSFER = "automated_transfer",
}
