import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <polygon points="2.00952381 20.1428571 22 11.5714286 2.00952381 3 2 9.66666667 16.2857143 11.5714286 2 13.4761905" />
    </g>
  </svg>
);

export default SVG;
