import React from "react";
const SVG = () => (
  <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1">
    <defs>
      <polygon id="path-1" points="2 4 9 4 11 6 22 6 22 20 2 20" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-794.000000, -986.000000)">
        <g id="05-Icons-/-Folder" transform="translate(792.000000, 982.000000)">
          <mask id="mask-2" fill="white">
            <use href="#path-1" />
          </mask>
          <use id="🎨Color" fill="#524F53" fillRule="evenodd" href="#path-1" />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
