import { useContext, useEffect, useState } from "react";
import { IAssetDetailsData, ICodeValue } from "TARGET_BUILD/common/models/asset/assetModel";
import IBattery from "TARGET_BUILD/common/models/iBattery";
import { IAlertDetails } from "TARGET_BUILD/common/models/alert/alertDetails";
import ActiveTrackingApi from "TARGET_BUILD/common/api/activeTrackingApi";
import LocationApi from "TARGET_BUILD/common/api/locationApi";
import { ServiceProviderCode } from "TARGET_BUILD/common/utils/enums/activeTrackingEnum";
import { validateResponse } from "TARGET_BUILD/common/utils/errorUtils";
import { ITrackingPayload } from "TARGET_BUILD/common/models/map/bleMap/trackingPayloalModel";
import { AppDispatch } from "TARGET_BUILD/common/store/store";
import { useDispatch } from "react-redux";
import { ToastrType, showToastr } from "TARGET_BUILD/common/actions/toastrAction";
import { useLocationContext } from "./locationContext";

export type Response = { status: "loaded"; error: string; position: ITrackingPayload };

const getResponse = (status: "loaded", error: string, position: ITrackingPayload): Response => {
  return { status, error, position };
};

const getLocationByAssetId = async (assetId): Promise<Response> => {
  const location = await ActiveTrackingApi.findTrackingPayload(assetId);

  if ("error" in location) {
    const error = validateResponse(location);
    return getResponse("loaded", "", { position: { latitude: 0, longitude: 0 } });
  }

  const loc = location as ITrackingPayload;

  if (loc?.serviceProviderCode === ServiceProviderCode.Gateway && loc?.gatewayLocationId && !loc.position) {
    const gateway = await LocationApi.getLocationById(loc.gatewayLocationId);
    loc.position = { latitude: gateway?.latitude || 0, longitude: gateway?.longitude || 0 };
    loc.gatewayLocationName = gateway?.name || "";
  }

  return getResponse("loaded", "", loc as ITrackingPayload);
};

const getLocationByMatSerial = async (serialNumber: string, materialNumber: string): Promise<Response> => {
  const location = await ActiveTrackingApi.findGeolocationBySerialNumber(serialNumber, materialNumber);

  if ("error" in location || !("position" in location)) {
    const error = validateResponse(location);
    if (error) {
      return getResponse("loaded", `errorCodes:${error.code}`, { position: { latitude: 0, longitude: 0 } });
    }
  }
  return getResponse("loaded", "", location as ITrackingPayload);
};

export default function usePositionData(
  asset: IAssetDetailsData | IAlertDetails | IBattery,
  isInvalidLocation: boolean,
): Response {
  const [position, setPosition] = useState(null);
  const dispatch: AppDispatch = useDispatch();

  const context = useLocationContext();

  useEffect(() => {
    const fetch = async () => {
      const { assetId, serialNumber, materialNumber } = asset;
      let result: Response;
      if (!isInvalidLocation) {
        if (assetId) {
          result = await getLocationByAssetId(assetId);
        } else if (serialNumber && materialNumber) {
          result = await getLocationByMatSerial(serialNumber, materialNumber);
        }
        if (result?.status === "loaded" && result?.error) {
          dispatch(showToastr(ToastrType.success, result?.error));
        }
        if (context?.actions?.set) {
          context?.actions?.set(result);
        }
        setPosition(result);
      } else {
        setPosition(getResponse("loaded", "", { position: undefined }));
      }
    };
    fetch();
    return () => {
      setPosition(null);
    };
  }, [asset?.assetId, asset?.materialNumber, asset?.serialNumber]);

  return position;
}
