import { SharedUtils } from "am-web-ui-shared/helpers";
import LicenseTypes from "../../common/utils/enums/licenseTypesEnum";
import { LicenseBundleToLicenseTypeValue, LicenseBundleTypes } from "TARGET_BUILD/common/utils/enums/licenseBundleEnum";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import { isFleetTenant } from "TARGET_BUILD/common/utils/userUtils";

// Constants
export const EVENTS_HILTI_USER_PROPERTIES = "hilti_user_properties";
export const EVENTS_HILTI_LICENSE_TYPE = "hilti_license_type";
export const EVENTS_HILTI_LICENSE_BUNDLES = "hilti_license_bundles";

// Enums
export enum CustomerType {
  Solution = "Solution",
  OT = "ON!Track",
  Fleet = "Fleet Tenant",
  FM = "ON!Track Ready",
  OTR = "O!T Ready",
}

export enum TenantTypeValues {
  GPDH2 = "GPDH2",
  Demo = "Demo",
}

// Type
export type UserData = {
  isLoggedIn: boolean;
  userRole: string;
  customerId: number;
  displayName: string;
  countryCode: string;
  email: string;
  licenseType: LicenseTypes;
  isMasterContract: boolean | null;
  tenantType: string;
  licenseBundle?: LicenseBundleTypes;
};

const trackIsUserInternal = (email: string) => {
  const splitIndex = email.lastIndexOf("@");
  const domain = email.substring(splitIndex + 1).toLowerCase();
  const isHiltiUser = domain?.includes("hilti.com");
  return isHiltiUser;
};

const getCustomerType = (
  fleetContract: null | boolean,
  licenseType: LicenseTypes,
  licenseBundle: LicenseBundleTypes,
) => {
  if (fleetContract === null) {
    return null;
  }

  const licenseBundleFeatureEnabled = SingletonFeatureManager.isFeatureEnabled(FEATURES.LICENSE_BUNDLE);
  if (licenseBundleFeatureEnabled) {
    //not changing google analytics data from licenseType to licenseBundle
    if (licenseBundle !== LicenseBundleTypes.ONTRACK_READY && !fleetContract) {
      return LicenseBundleToLicenseTypeValue[licenseBundle];
    }
    if (licenseBundle !== LicenseBundleTypes.ONTRACK_READY && fleetContract) {
      return CustomerType.Solution;
    }
    if (licenseBundle === LicenseBundleTypes.ONTRACK_READY) {
      return CustomerType.Fleet;
    }
  } else {
    if (licenseType === LicenseTypes.OT && !fleetContract) {
      return CustomerType.OT;
    }
    if (licenseType === LicenseTypes.OT && fleetContract) {
      return CustomerType.Solution;
    }
    if (licenseType === LicenseTypes.FM) {
      return CustomerType.Fleet;
    }
  }

  return null;
};

export const trackUserData = (payload: UserData) => {
  const {
    email,
    licenseType,
    isLoggedIn,
    userRole,
    countryCode,
    displayName,
    customerId,
    tenantType,
    isMasterContract,
    licenseBundle,
  } = payload;
  const internal = trackIsUserInternal(email);
  const isFleet = isFleetTenant(licenseType, licenseBundle);
  const customerType = getCustomerType(isMasterContract, licenseType, licenseBundle);
  const tenantTypeValue = tenantType?.includes(TenantTypeValues.GPDH2) ? TenantTypeValues.GPDH2 : TenantTypeValues.Demo;

  const userData = {
    isLoggedIn,
    userRole,
    countryCode,
    displayName,
    customerId,
    internal,
    isFleet,
    customerType,
    tenantType: tenantTypeValue,
  };

  SharedUtils.pushDataToGA(EVENTS_HILTI_USER_PROPERTIES, null, { userData });
};

export const setUserLicenseForGA = (licenseType: string, licenseBundle?: string) => {
  const isLicenseBundleFeatureEnabled = SingletonFeatureManager.isFeatureEnabled(FEATURES.LICENSE_BUNDLE);
  let license = null;
  if (isLicenseBundleFeatureEnabled) {
    license = LicenseBundleToLicenseTypeValue[licenseBundle];
  } else {
    license = licenseType === LicenseTypes.OT ? CustomerType.OT : CustomerType.OTR;
  }

  SharedUtils.pushDataToGA(EVENTS_HILTI_LICENSE_TYPE, null, { license });
};
