import React from "react";
import styled from "styled-components";
import IMenuProps from "./iMenuProps";
import ListMenu from "./listMenu";
import { HelperLodash } from "../../helpers";
import ApplicationConstants from "../../constant/applicationConstants";

const IconContainer = styled.div`
  button {
    padding: 0;
    span {
      > div {
        padding: 0;
      }
      button {
        margin: 0;
      }
    }

    :hover {
      background: none;
    }
    && {
      :hover {
        background: none;
      }
    }
  }
`;

const styles = {
  menu_container: {
    display: "flex",
    margin: 0,
    paddingRight: "16px",
  },
};

class Menu extends React.PureComponent<IMenuProps> {
  state = {
    icons: [],
    menus: [],
  };
  getIcon = (item, i) => {
    if (item.seperator) {
      return undefined;
    } else if (item.menu) {
      return item.getSelectMenuItems();
    } else {
      return (
        <div key={i} className={"iconAction"} onClick={item.onClick}>
          {item.icon}
        </div>
      );
    }
  };
  totalListWidth = (breadcrumbElement) => {
    if (breadcrumbElement) {
      let totalWidth = 0;
      const liWidth = breadcrumbElement.getElementsByClassName("iconAction");
      const length = liWidth && liWidth.length;
      const iconSizes = [];

      for (let index = 0; index < length; index++) {
        const listWidth = HelperLodash.get(liWidth, index);
        iconSizes.push(listWidth.offsetWidth);
        totalWidth += listWidth.offsetWidth;
      }
      return {
        containerWidth: breadcrumbElement.offsetWidth,
        iconSizes,
        totalWidth,
      };
    }
    return null;
  };

  getMoreIconList = (width, menuList) => {
    const visibleItems = menuList.filter((item) => item.hidden !== true);
    let hiddenIcons = 0;
    const breadcrumbElement = document.getElementById("iconcontainer");
    const elementInfo = this.totalListWidth(breadcrumbElement);
    if (elementInfo) {
      let breackLimit = elementInfo.totalWidth + ApplicationConstants.WIDTH.W85;
      const iconLength = elementInfo.iconSizes.length;
      if (width < breackLimit) {
        for (let i = iconLength; i > 0; i--) {
          hiddenIcons = hiddenIcons + 1;
          breackLimit = breackLimit - elementInfo.iconSizes[iconLength - 1];
          if (width > breackLimit) {
            break;
          }
        }
      }
    }

    if (hiddenIcons > 0) {
      this.setState({
        icons: visibleItems.slice(0, hiddenIcons * -1),
        menus: visibleItems.slice(visibleItems.length - hiddenIcons, visibleItems.length),
      });
    } else {
      this.setState({
        icons: visibleItems,
        menus: [],
      });
    }
  };

  componentDidMount() {
    const { width, menuList } = this.props;
    this.getMoreIconList(width, menuList);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const { width, menuList } = this.props;
      this.getMoreIconList(width, menuList);
    }
  }

  render() {
    return (
      <IconContainer id="iconcontainer" style={styles.menu_container} ref={(container) => container}>
        {this.state.icons && this.state.icons.map((item, i) => this.getIcon(item, i))}

        {this.state.menus && this.state.menus.length !== 0 && (
          <ListMenu options={this.state.menus} textValue={this.props.textValue} />
        )}
      </IconContainer>
    );
  }
}

export default Menu;
