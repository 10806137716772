import React from "react";
import InfiniteScrollLoaderEnd from "../../../../components/gridList/loader/infiniteScrollLoadEnd";
import InfiniteScrollLoader from "../../../../components/gridList/loader/infinitescrollLoader";
import InfiniteScrollComponent from "../../../../components/infiniteScroll/infiniteScrollComponent";
import { HelperLodash } from "../../../../helpers";
import GroupList from "../../../groups/groupsList";
import { IHierarchyControlWrapperProps } from "./iDropdownMenu";

export const HierarchyControlWrapper = React.memo((props: IHierarchyControlWrapperProps) => {
  const {
    bindProperties,
    defaultId,
    hierarchyGroupScrollRef,
    groupsList,
    handleRowClick,
    isInitalLoad,
    isPaging,
    index,
    paginatedData,
    selectedId,
    selectedItems,
    t,
  } = props;
  const itemRef = HelperLodash.get(hierarchyGroupScrollRef, index);
  return isPaging ? (
    <InfiniteScrollComponent
      loader={() => <InfiniteScrollLoader />}
      item={itemRef}
      hasMore={groupsList.listRes.length < groupsList.totalRecords}
      loadMore={() => paginatedData(index)}
      pageStart={0}
      threshold={100}
      resetPage={null}
      loadEnd={() => <InfiniteScrollLoaderEnd loadEnd={t("common:INFINITE_LOAD_END")} />}
      class="loader-space"
    >
      <GroupList
        key={index}
        handleRowClick={handleRowClick}
        selectedItems={selectedItems}
        level={index}
        groupsList={groupsList.listRes}
        isInitalLoad={isInitalLoad}
        defaultId={defaultId}
        bindProperties={bindProperties}
        selectedId={selectedId}
        t={t}
      />
    </InfiniteScrollComponent>
  ) : (
    <GroupList
      key={index}
      handleRowClick={handleRowClick}
      selectedItems={selectedItems}
      level={index}
      groupsList={groupsList}
      isInitalLoad={isInitalLoad}
      defaultId={defaultId}
      bindProperties={bindProperties}
      selectedId={selectedId}
      t={t}
    />
  );
});

export default HierarchyControlWrapper;
