import React from "react";
const SVG = () => (
  <svg width="19px" height="16px" viewBox="0 0 19 16" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3239.000000, -2804.000000)" fill="#524F53">
        <g transform="translate(3235.000000, 2800.000000)">
          <path
            d="M12,4 C16.0796344,4 19.4460359,7.05371356 19.9381123,11.0000487 L23,11 L18.5,16 L14,11 L16.8999437,10.999812 C16.4366008,8.71766536 14.4188915,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 C13.3807119,17 14.6307119,16.4403559 15.5355339,15.5355339 L17.6568542,17.6568542 C16.209139,19.1045695 14.209139,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
