// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { Tab } from "am-web-ui-shared/elements";
import React, { useEffect } from "react";
import styled from "styled-components";
import { IAlertsTabProps } from "../../../components/header/Alert/iAlerts";
import { TransferConfirmationPolicyEnum } from "../../../modules/transferSettings/transferConfirmationPolicyEnum";
import { SharedUtils } from "am-web-ui-shared/helpers";
import GTMConstants from "../../../gtmConstants";
import { useSelector } from "react-redux";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import { RootState } from "TARGET_BUILD/common/store/store";
import { useTranslation } from "react-i18next";

const AlertTabWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  border-bottom: 2px solid ${(props) => props.theme.colors.base.borderColor};
  padding: 0 16px;
`;
const Container = styled.div`
  max-width: 996px;
  margin: 0 auto;
  .tab-link {
    position: relative;
  }
  .tab {
    border-bottom: 0;
    padding: 0;
    button {
      @media (min-width: 320px) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
`;

export const CountWrapper = styled.span`
  position: absolute;
  right: -7px;
  top: -13px;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: 12px;
  background-color: ${(props) => props.theme.colors.base.hiltiRed};
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.base.white};
  padding: 1px 5px;
  height: 16px;
`;

export const GenaratedReportTab = styled.div`
  display: flex;
`;

const AlertTab = React.memo((props: IAlertsTabProps) => {
  const hasGatewayAssets = useSelector((state: RootState) => state.HeaderCount.gatewayAssetsCount > 0);
  const suggestedTransfersCount = useSelector((state: RootState) => state.HeaderCount.suggestedTransfersCount || 0);
  const { t } = useTranslation();
  useEffect(() => {
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.OPEN_ALERT_LIST, null, { dataValue: props.activeAlertCount });
  }, []);

  const { selectedTabIndex, transferConfirmationPolicy, licenseContext } = props;
  const displayTab = () => {
    return transferConfirmationPolicy && transferConfirmationPolicy.code !== TransferConfirmationPolicyEnum.Disabled;
  };

  const isRequestTabDisplay = (): boolean => {
    return !!props.isFieldRequestEnabled;
  };

  const isSuggestedTransferTabDisplay = (): boolean => {
    const isSuggestedTransferTabEnabled =
      SingletonFeatureManager.isFeatureEnabled(FEATURES.PAT_SUGGESTED_TRANSFERS) && hasGatewayAssets;
    const proLicenseFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.PRO_LICENSING);
    return proLicenseFF
      ? licenseContext.getProLicenses()?.hasSuggestedTransfer && isSuggestedTransferTabEnabled
      : isSuggestedTransferTabEnabled;
  };

  const alertTabConfig = [
    {
      count: props.activeAlertCount,
      displayTab: true,
      name: t("common:MAIN_MENU_TAB_GENERAL"),
      id: "general_alerts",
    },
    {
      count: props.confirmTransferCount,
      displayTab: displayTab(),
      name: t("actionBar:IN_TRANSIT"),
      id: "transfer_alerts",
    },
    {
      count: props.fieldRequestsCount,
      displayTab: isRequestTabDisplay(),
      name: t("actionBar:FIELD_REQUESTS"),
      id: "field_request_alerts",
    },
    {
      count: suggestedTransfersCount,
      displayTab: isSuggestedTransferTabDisplay(),
      name: t("actionBar:SUGGESTED_TRANSFERS"),
      id: "suggested_transfer_alerts",
    },
  ];

  const renderTabTitle = (tabItem) => {
    return (
      <GenaratedReportTab>
        <span className="tab-link">
          {tabItem.name}
          <CountWrapper>
            {tabItem.count > appConstants.MAX_ALERT_BADGE_COUNT
              ? `${appConstants.MAX_ALERT_BADGE_COUNT}+`
              : tabItem.count}
          </CountWrapper>
        </span>
      </GenaratedReportTab>
    );
  };

  return (
    <AlertTabWrapper>
      <Container>
        <Tab
          selected={selectedTabIndex}
          showContent={false}
          onSelect={(nextIndex) => props.handleTabSelection(nextIndex)}
        >
          {alertTabConfig.map((tabItem, index) => {
            return tabItem.displayTab ? (
              <Tab.TabItem
                index={index}
                key={`tab_${index}`}
                label={renderTabTitle(tabItem)}
                type={"menuItem"}
                htmlId={tabItem.id}
              />
            ) : null;
          })}
        </Tab>
      </Container>
    </AlertTabWrapper>
  );
});

export default AlertTab;
