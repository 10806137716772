/**
 * @description This file is for api hitting using
 * axios for the logout web UI user.
 */
// @ts-ignore
import { APIMessages } from "custom-actions";
import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { getUserMgmtEndpoint, getOrgMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description Function to get user setting info of  webUI user
 */
export const userSetting = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint(`/users/me/setting`), null, null, null, false);
};

/**
 * @description - Get the browser's accept languages.
 */
export const getBrowserLanguage = async (): Promise<any | IErrorResponse> => {
  return Gateway.getUnAuthorize("/service/locale/getBrowserLanguage");
};

/**
 * @description Function to get user details of webUI user
 */
export const userSettingDetail = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint(`/users/my-context`));
};

/**
 * Returns list of locations
 */
export const getLocations = async (locationType): Promise<any> => {
  locationType = locationType ? locationType : "WAREHOUSE;JOBSITE";
  try {
    const params = {
      filter: `type:in=${locationType}`,
      order_by: "+name",
      view: "DropDown",
    };
    const url = getOrgMgmtEndpoint(`/locations`);
    return Gateway.get(url, params, null);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const updateUserSetting = async (userSettingData): Promise<any> => {
  const url = getUserMgmtEndpoint(`/users/me/setting`);
  return Gateway.put(url, null, userSettingData, null);
};

/**
 * Returns links
 */
export const getLinks = async (data): Promise<any> => {
  return Gateway.getUnAuthorize(`/links`, { ...data });
};

/**
 * Returns links for logged in user
 */
export const getTouLinks = async (): Promise<any> => {
  return Gateway.get(getOrgMgmtEndpoint(`/privacy-policies`));
};

export const getActivationUserInfo = async (nonce): Promise<any> => {
  return Gateway.getUnAuthorize(`/user-info-confirmations/${nonce}`);
};
