import React from "react";
import { withTheme } from "styled-components";
import ICommonProps from "../../commonInterfaceProps/iCommonProps";
import IconLabel from "../iconLabel";
import IIconComponentProps from "./iIconComponentProps";
import Tooltip, { TooltipProps } from "@hilti/components/core/tooltip";
type IconComponentProps = IIconComponentProps & ICommonProps;
/**
 * @description - A wrapper class for Icon Tooltip
 *
 * @class IconComponent
 * @extends {React.Component<IconComponentProps>}
 */
const renderIcon = (props) => {
  const { className, isIconDisabled, labelText, handleClick, icon, id, toggle, iconClass, imageSize } = props;
  return (
    <IconLabel
      toggle={toggle}
      className={className}
      handleClick={!isIconDisabled ? handleClick : null}
      iconName={icon}
      iconText={labelText}
      isIconDisabled={isIconDisabled}
      id={`${id}Action`}
      iconClass={iconClass}
      imageSize={imageSize}
    />
  );
};
export const IconComponent = (props: IconComponentProps) => {
  const { getContent = () => "", place, html } = props;
  /*
      Either icon is enabled or disabled. If icon text is truncated then need
      to show tooltip.
  */
  let content = getContent();
  if (html) {
    content = (
      <span>
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </span>
    );
  }
  return (
    <React.Fragment>
      {
        <Tooltip placement={place as TooltipProps["placement"]} title={content}>
          <span>{renderIcon(props)}</span>
        </Tooltip>
      }
    </React.Fragment>
  );
};
export default withTheme(IconComponent);
