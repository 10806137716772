import React from "react";
const SVG = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3531.000000, -1633.000000)">
        <g id="commodity" transform="translate(3530.000000, 1632.000000)">
          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
          <path
            d="M17.262099,10.2512716 L22.6394746,13.6102225 L22.6394746,19.734833 L17.2094075,23 L11.8198841,19.7738218 L6.4546564,23 L0.999999998,19.734833 L0.999999998,13.6102225 L6.37737553,10.2664135 L6.37737553,4.39186438 L11.8320319,0.999999998 L17.262099,4.39186438 L17.262099,10.2512716 Z M11.8417281,2.79245851 L8.42589952,4.87212961 L11.8417281,6.99618649 L15.4119691,4.87212961 L11.8417281,2.79245851 Z M15.8779789,5.55677423 L12.3442648,7.67700277 L12.3442648,11.8767278 L15.8779789,9.83160787 L15.8779789,5.55677423 Z M6.46435255,12.0108166 L3.04852401,14.0904877 L6.46435255,16.2145445 L10.0345935,14.0904877 L6.46435255,12.0108166 Z M10.5006034,14.7751323 L6.96688921,16.8953608 L6.96688921,21.0950858 L10.5006034,19.049966 L10.5006034,14.7751323 Z M17.2191036,12.0108166 L13.8032751,14.0904877 L17.2191036,16.2145445 L20.7893446,14.0904877 L17.2191036,12.0108166 Z M21.2553544,14.7751323 L17.7216403,16.8953608 L17.7216403,21.0950858 L21.2553544,19.049966 L21.2553544,14.7751323 Z"
            id="Combined-Shape"
            fill="#524F53"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
