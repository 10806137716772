import { ISagaModule } from "redux-dynamic-modules-saga";
import toastrReducer from "./toastrReducer";

const toastrModule: ISagaModule<{}> = {
  id: "toastrModule",
  reducerMap: {
    toastr: toastrReducer,
  },
  sagas: [],
};

export default toastrModule;
