// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import AttachmentsList from "../../../../../components/attachments/attachmentList";
import { ImageSizeEnum } from "../../../../../components/styled/squareImage/imageSizeUtil";
import SquareImage from "../../../../../components/styled/squareImage/squareImageContainer";
import { ITemplate } from "./iAssetTemplateDetailProps";

export const FluidContainer = styled.div`
  background: ${(props) => props.theme.colors.base.heavyConcrete10};
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  padding: 24px;
  background: ${(props) => props.theme.colors.base.white};
  width: 992px;
  border: 1px solid ${(props) => props.theme.colors.base.borderColor};
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
`;
export const TitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
  text-align: left;
  margin-bottom: ${(props) => (props.nobottom ? "0px" : "16px")};
`;

const FixedContainer = styled.div`
  height: 100%;
  width: 100%;
  line-height: 19px;
  overflow: hidden;
`;
export const DetailWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 8px;
`;
export const TemplateDetailWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const DetailsValueWrapper = styled.p`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 17px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  word-break: break-all;
  margin-right: ${(props) => (props.rightMargin ? "24px" : 0)};
`;

const LeftColumnSpace = styled.div`
  float: left;
  width: 50%;
  overflow-wrap: break-word;
`;

const RightColumnSpace = styled.div`
  float: left;
  width: 50%;
  overflow-wrap: break-word;
  .desc {
    margin-top: 16px;
  }
`;
const DetailContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;
class AssetTemplateDetailView extends React.Component<ITemplate> {
  render() {
    const { t, templateId } = this.props;
    return (
      <>
        <FluidContainer>
          <TitleWrapper>{t("assets:LABEL_TEMPLATE_DETAIL")}</TitleWrapper>
          <FixedContainer>
            <LeftColumnSpace>
              <DetailContainer>
                <DetailWrapper>{t("assets:MANUFACTURER")}</DetailWrapper>
                <DetailsValueWrapper>{templateId.manufacturerName}</DetailsValueWrapper>
              </DetailContainer>
              <DetailContainer>
                <DetailWrapper>{t("assets:MODEL")}</DetailWrapper>
                <DetailsValueWrapper rightMargin={true}>{templateId.model}</DetailsValueWrapper>
              </DetailContainer>
              <DetailContainer>
                <DetailWrapper>{t("assets:NO_OF_ASSETS")}</DetailWrapper>
                <DetailsValueWrapper>{templateId.assetCount}</DetailsValueWrapper>
              </DetailContainer>
              <DetailContainer>
                <DetailWrapper>{t("assets:ASSET_GROUP")}</DetailWrapper>
                <DetailsValueWrapper>{templateId.defaultGroupName}</DetailsValueWrapper>
              </DetailContainer>
              <DetailContainer>
                <DetailWrapper>{t("assets:COST_CODE")}</DetailWrapper>
                <DetailsValueWrapper>{templateId.costCode}</DetailsValueWrapper>
              </DetailContainer>
            </LeftColumnSpace>
            <RightColumnSpace>
              {templateId.templateImageId && (
                <>
                  <TemplateDetailWrapper>{t("assets:COST_CODE")}</TemplateDetailWrapper>
                  <SquareImage
                    id="imgAssetTemplate"
                    wrapperAttributes={{
                      height: ImageSizeEnum.MEDIUM,
                      style: { border: "0px", justifyContent: "flex-start" },
                      width: "auto",
                    }}
                    defaultSrc={""}
                    documentId={templateId.templateImageId}
                  />
                </>
              )}
              <DetailWrapper className="desc">{t("assets:DESCRIPTION")}</DetailWrapper>
              <DetailsValueWrapper>{templateId.description}</DetailsValueWrapper>
            </RightColumnSpace>
          </FixedContainer>
        </FluidContainer>
        <FluidContainer card={true}>
          <TitleWrapper nobottom={true}>{t("common:ATTACHMENTS")}</TitleWrapper>
          <AttachmentsList attachments={templateId.templateAttachments} />
        </FluidContainer>
      </>
    );
  }
}

export default withTranslation()(AssetTemplateDetailView);
