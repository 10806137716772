/**
 * @description Action constants to be used across the project
 *
 * format: {feature}_{action}
 */

const ActiveTrackingConstants = {
  ASSET_VIEW_FILTER_FIELD_SUCCESS: "ASSET_VIEW_FILTER_FIELD_SUCCESS",
  AT_GATEWAY_ASSET_SELECT_GRID_CHECKBOX: "AT_GATEWAY_ASSET_SELECT_GRID_CHECKBOX",
  AT_GATEWAY_ASSET_UNSELECT_GRID_CHECKBOX: "AT_GATEWAY_ASSET_UNSELECT_GRID_CHECKBOX",
  AT_GET_ASSET_FILTER_FIELD: "AT_GET_ASSET_FILTER_FIELD",
  AT_GET_ASSET_LIST: "AT_GET_ASSET_LIST",
  AT_GET_LOCATION_GATEWAY: "AT_GET_LOCATION_GATEWAY",
  AT_GET_STORAGE_ASSET_GATEWAY: "AT_GET_STORAGE_ASSET_GATEWAY",
  AT_GET_STORAGE_ASSET_GATEWAY_FAIL: "AT_GET_STORAGE_ASSET_GATEWAY_FAIL",
  AT_RESET_STORAGE_ASSET_GATEWAY: "AT_RESET_STORAGE_ASSET_GATEWAY",
  AT_GET_STORAGE_ASSET_GATEWAY_SUCCESS: "AT_GET_STORAGE_ASSET_GATEWAY_SUCCESS",
  AT_RESET_ASSET_VIEW_QUERY_PARAMS: "AT_RESET_ASSET_VIEW_QUERY_PARAMS",
  AT_SET_ASSET_FILTER_APPLIED_FIELD: "AT_SET_ASSET_FILTER_APPLIED_FIELD",
  AT_UPDATE_ASSET_TYPE: "AT_UPDATE_ASSET_TYPE",
  CLEAR_GATEWAY_ASSETS_CHECKED_ROWS: "CLEAR_GATEWAY_ASSETS_CHECKED_ROWS",
  GET_ASSET_LIST_SUCCESS: "GET_ASSET_LIST_SUCCESS",
  GET_LOCATION_GATEWAY_FAIL: "GET_LOCATION_GATEWAY_FAIL",
  GET_LOCATION_GATEWAY_SUCCESS: "GET_LOCATION_GATEWAY_SUCCESS",
  GET_SELECT_ALL_ASSETS_SUCCESS: "AT_GET_SELECT_ALL_ASSETS_SUCCESS",
  SELECT_ALL_GATEWAY_ASSETS_ROWS: "SELECT_ALL_GATEWAY_ASSETS_ROWS",
  SELECT_GATEWAY_ASSETS_ROW: "SELECT_GATEWAY_ASSETS_ROW",
  SET_ASSET_AS_GATEWAY: "SET_ASSET_AS_GATEWAY",
  SET_ASSET_VIEW_FILTER_QUERY: "SET_ASSET_VIEW_FILTER_QUERY",
  SET_GATEWAY_ASSET_SORT_QUERY: "SET_GATEWAY_ASSET_SORT_QUERY",
  SET_LOCATION_DETAILS: "SET_LOCATION_DETAILS",
  TOGGLE_ASSET_LIST_DIALOG_STATE: "TOGGLE_ASSET_LIST_DIALOG_STATE",
  AT_GET_ASSETS_LAST_SEEN: "AT_GET_ASSETS_LAST_SEEN",
  AT_PUT_ASSETS_LAST_SEEN: "AT_PUT_ASSETS_LAST_SEEN",
  INIT_ASSETS_LAST_SEEN: "INIT_ASSETS_LAST_SEEN", // to initiate loader
  CLEAR_ASSETS_LAST_SEEN: "CLEAR_ASSETS_LAST_SEEN", // to remove all entries
  GET_ASSET_ONE_MAP_DATA: "GET_ASSET_ONE_MAP_DATA",
  SET_ASSET_ONE_MAP_DATA: "SET_ASSET_ONE_MAP_DATA",
  AT_GET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER: "AT_GET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER",
  AT_SET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER: "AT_SET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER",
  AT_SELECT_ONE_MAP_ASSET_FOR_SINGLE_CLUSTER: "AT_SELECT_ONE_MAP_ASSET_FOR_SINGLE_CLUSTER",
  AT_CLEAR_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER: "AT_CLEAR_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER",
  AT_SET_ONE_MAP_ASSETS_FOR_VIEWPORT: "AT_SET_ONE_MAP_ASSETS_FOR_VIEWPORT",
};

export default ActiveTrackingConstants;
