import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { ITransferConfirmationSettingsResponse } from "../modules/transferSettings/iTransferSettingsProps";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";
/**
 * Returns transfer-confirmation-setting response
 */
export const getTransferConfirmationSettingsApi = async (): Promise<
  ITransferConfirmationSettingsResponse | IErrorResponse
> => {
  const url = getOrgMgmtEndpoint(`/company/transfer-confirmation-setting`);
  return Gateway.get(url, null, null, null, false) as unknown as ITransferConfirmationSettingsResponse;
};

/**
 * Update Transfer Confirmation Settings
 * @param value: Confirmation Settings Value
 */
export const updateTransferConfirmationSettingsApi = async (transferSettingObj) => {
  const url = getOrgMgmtEndpoint(`/company/transfer-confirmation-setting`);
  return Gateway.put(url, null, { ...transferSettingObj }, null) as unknown;
};
