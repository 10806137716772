import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M15.2670344,21.1779502 C16.0653718,20.3796128 17.359063,20.3796128 18.1525911,21.1779502 L18.1525911,21.1779502 L16.7098128,22.6207285 Z M13.34333,19.2542457 C15.204514,17.3978709 18.2199207,17.3978709 20.0762955,19.2542457 L20.0762955,19.2542457 L19.1144433,20.2160979 C17.7870872,18.8887419 15.6325383,18.8887419 14.3051822,20.2160979 L14.3051822,20.2160979 Z M11.4196255,17.3305413 C14.3436563,14.4113198 19.0807785,14.4113198 22,17.3305413 L22,17.3305413 L21.0381478,18.2923935 C18.647945,15.9021907 14.7716805,15.9021907 12.3814777,18.2923935 L12.3814777,18.2923935 Z M9.36273458,1 C10.6639589,1 11.7286646,2.01265671 11.8117591,3.29287758 L11.8169794,3.45424486 L16.7254692,3.45424486 L16.725301,13.2724475 C13.9978085,13.2262959 11.4734846,14.4861174 9.15232941,17.0519121 L8.89525936,17.3423748 L9.733,18.179 L2,18.179714 L2,3.45424486 L6.90848972,3.45424486 C6.90848972,2.09880285 8.00729257,1 9.36273458,1 Z M13.5609993,7.18577781 L8.354,12.391 L5.46240447,9.49966205 L4.30546235,10.6566042 L8.35475977,14.7059016 L14.7179414,8.34271993 L13.5609993,7.18577781 Z M9.36273458,2.63616324 C8.91092057,2.63616324 8.54465296,3.00243086 8.54465296,3.45424486 C8.54465296,3.90605886 8.91092057,4.27232648 9.36273458,4.27232648 C9.81454858,4.27232648 10.1808162,3.90605886 10.1808162,3.45424486 C10.1808162,3.00243086 9.81454858,2.63616324 9.36273458,2.63616324 Z"></path>
    </g>
  </svg>
);

export default SVG;
