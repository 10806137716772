const WorkerCertificateActionConstants = {
  ADD_WORKER_CERTIFICATE: "ADD_WORKER_CERTIFICATE",
  CERTIFICATE_FILTER_ATTRIBUTES: "CERTIFICATE_FILTER_ATTRIBUTES",
  CERTIFICATE_FILTER_ATTRIBUTES_SUCCESS: "CERTIFICATE_FILTER_ATTRIBUTES_SUCCESS",
  CLEAR_RENEW_CERTIFICATE_RESPONSE: "CLEAR_RENEW_CERTIFICATE_RESPONSE",
  CLOSE_CERTIFICATE_MORE_FILTER: "CLOSE_CERTIFICATE_MORE_FILTER",
  DELETE_WORKER_CERTIFICATE_FROM_LOCAL: "DELETE_WORKER_CERTIFICATE_FROM_LOCAL",
  OPEN_CERTIFICATE_MORE_FILTER: "OPEN_CERTIFICATE_MORE_FILTER",
  RENEW_WORKER_CERTIFICATE_RESPONSE: "RENEW_WORKER_CERTIFICATE_RESPONSE",
  RESET_CERTIFICATE_QUERY_PARAM: "RESET_CERTIFICATE_QUERY_PARAM",
  RESET_TO_DEFAULT_STATE: "RESET_TO_DEFAULT_STATE",
  RESET_WORKER_CERTIFICATE_RESPONSE: "RESET_WORKER_CERTIFICATE_RESPONSE",
  SET_CERTIFICATE_DETAILS: "SET_CERTIFICATE_DETAILS",
  SET_CERTIFICATE_FILTER_QUERY: "SET_CERTIFICATE_FILTER_QUERY",
  SET_CERTIFICATE_SEARCH_QUERY: "SET_CERTIFICATE_SEARCH_QUERY",
  SET_WORKER_CERTIFICATE_SORT_QUERY: "SET_WORKER_CERTIFICATE_SORT_QUERY",
  SHOW_MANAGE_CERTIFICATE_FORM: "SHOW_MANAGE_CERTIFICATE_FORM",
  UM_ASSIGN_CERTIFICATE_TO_WORKER: "UM_ASSIGN_CERTIFICATE_TO_WORKER",
  UM_CERTIFICATE_GET_LIST: "UM_CERTIFICATE_GET_LIST",
  UM_DELETE_WORKER_CERTIFICATE: "UM_DELETE_WORKER_CERTIFICATE",
  UM_GET_CERTIFICATE_DETAILS: "UM_GET_CERTIFICATE_DETAILS",
  UM_RENEW_WORKER_CERTIFICATE: "UM_RENEW_WORKER_CERTIFICATE",
  UM_SET_CERTIFICATE_FILTER_APPLIED_FIELD: "UM_SET_CERTIFICATE_FILTER_APPLIED_FIELD",
  UM_SET_CERTIFICATE_LIST: "UM_SET_CERTIFICATE_LIST",
  UM_UPDATE_CERTIFICATE: "UM_UPDATE_CERTIFICATE",
  UPDATE_WORKER_CERTIFICATE_RESPONSE: "UPDATE_WORKER_CERTIFICATE_RESPONSE",
  WORKER_CERTIFICATES_COUNT: "WORKER_CERTIFICATES_COUNT",
  WORKER_CERTIFICATE_CLEAR_LIST: "WORKER_CERTIFICATE_CLEAR_LIST",
  WORKER_CERTIFICATE_DATA_UPDATED: "WORKER_CERTIFICATE_DATA_UPDATED",
  WORKER_CERTIFICATE_GET_LIST: "WORKER_CERTIFICATE_GET_LIST",
  WORKER_CERTIFICATE_PUT_LIST: "WORKER_CERTIFICATE_PUT_LIST",
  WORKER_CERTIFICATE_SELECT_ROW: "WORKER_CERTIFICATE_SELECT_ROW",
  WORKER_CERTIFICATE_UNSELECT_ROW: "WORKER_CERTIFICATE_UNSELECT_ROW",
};
export default WorkerCertificateActionConstants;
