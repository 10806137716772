import React, { PropsWithChildren } from "react";
import { SquareIcon } from "am-web-ui-shared/components";
import styled from "../../../../../themeConfig/styledComponents";
import IStyledAfterIconContainer from "./iStyledAfterIconContainer";

/**
 * @const StyledAfterImageContainer - Styled Component that will act as a container for other component.
 * It is having the an icon at the right of the content rows.
 * @interface IAfterIconContainer
 */
const StyledAfterImageContainer = styled("div")`
  display: flex;
  align-items: center;
  padding: 16px 0px 16px 40px;
  .red-icon {
    cursor: pointer;
  }
  &.modal-sidepanel-icon-text {
    padding-left: 58px;
    padding-right: 17px;
  }
`;

const IconTextContainer = styled("div")`
  flex: 1;
  padding-right: 16px;
`;

/**
 * @description - Styled component which will add an image after the children of the container.
 * @param props - React container properties.
 */
type IStyledAfterIconContainerProps = PropsWithChildren<IStyledAfterIconContainer>;

const StyledAfterIconContainer = (props: IStyledAfterIconContainerProps) => {
  const { id, iconUrl, children, className, onIconClick } = props;
  return (
    <StyledAfterImageContainer id={id}>
      <IconTextContainer>{children}</IconTextContainer>
      <SquareIcon
        data-testid={`${id}Icon`}
        name={iconUrl}
        id={`${id}Icon`}
        onClick={onIconClick ? (event) => onIconClick(event) : null}
        className={className}
      />
    </StyledAfterImageContainer>
  );
};

/**
 * @export StyledAfterIconContainer - Styled Component.
 */
export default StyledAfterIconContainer;
