// @ts-ignore
import { AssetServiceActions } from "custom-actions";

/**
 * @description Function to fetch the open service list with given offset
 */

export const fetchAssetServiceList = (offset: number, id: number, status: string, filters?: string) => ({
  filters,
  id,
  offset: offset || 0,
  status,
  type: AssetServiceActions.AM_GET_ASSET_SERVICE_LIST,
});
/**
 * @description Function to add service
 */

export const addAssetService = (addAssetServiceData) => ({
  addAssetServiceData,
  type: AssetServiceActions.ADD_ASSET_SERVICE,
});

export const clearAssetServiceList = () => ({
  type: AssetServiceActions.ASSET_SERVICE_CLEAR_LIST,
});

export const showServiceList = (isOpen) => ({
  payload: { showServiceList: isOpen },
  type: AssetServiceActions.SHOW_MANAGE_SERVICES_LIST,
});
export const showServiceForm = (isOpen) => ({
  payload: { showServiceForm: isOpen },
  type: AssetServiceActions.SHOW_MANAGE_SERVICES_FORM,
});
export const resetServiceForm = () => ({
  payload: { showServiceList: false, showServiceForm: false },
  type: AssetServiceActions.SHOW_MANAGE_SERVICES_FORM,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param {string} searchString - takes entered search string.
 */
export const setSortQuery = (sortQuery) => ({
  sortQuery,
  type: AssetServiceActions.SET_SERVICE_SORT_QUERY,
});

/**
 * @description Function to assign service to existing asset
 */

export const assignHistoryService = (assetId, serviceData, serviceStatus) => ({
  assetId,
  serviceData,
  serviceStatus,
  type: AssetServiceActions.ASSIGN_HISTORY_SERVICE,
});

/**
 * @description Function to get list of assign service to existing asset
 */

export const getAssignServicesList = (assetId, actionType: string) => ({
  actionType,
  assetId,
  type: AssetServiceActions.GET_ASSIGN_SERVICES,
});

export const clearServiceData = () => ({
  type: AssetServiceActions.ASSET_SERVICE_DATA_CLEAR,
});

/**
 * @description Function to clear assigned servie details
 */
export const clearAssignedServiceData = () => ({
  type: AssetServiceActions.ASSET_ASSIGNED_SERVICE_DATA_CLEAR,
});

export const clearCompleteServiceData = () => ({
  type: AssetServiceActions.CLEAR_COMPLETE_SERVICE_DATA,
});

export const getAssignServiceDetail = (assetId, serviceId, serviceStatus) => ({
  assetId,
  serviceId,
  serviceStatus,
  type: AssetServiceActions.GET_ASSGIN_SERVICE_DETAIL,
});

export const appendServiceList = (info) => ({
  payload: info,
  type: AssetServiceActions.ASSET_SERVICE_PUT_LIST,
});

/**
 * @description dispatcher to get the attribute for the filters applided on open and history service.
 * @param {string} filters - filters fields.
 */
export const getServiceFilterAttributes = (assetId, filters, delay = true, serviceType) => ({
  delay,
  payload: { assetId, filters, serviceType },
  type: AssetServiceActions.AM_GET_SERVICE_FILTER_FIELD,
});

export const setAssetServiceAppliedAttributes = (filterQuery) => ({
  payload: {
    filterQuery,
  },
  type: AssetServiceActions.SET_ASSET_SERVICE_APPLIED_FILTER_ATTRIBUTES,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param {string} filterQuesry - takes entered search string.
 */
export const setServiceFilterQuery = (filterQuery) => ({
  filterQuery,
  type: AssetServiceActions.SET_SERVICE_FILTER_QUERY,
});

/**
 * @description Function to fetch the provider list
 */

export const providerList = (queryParams: any) => ({
  ...queryParams,
  type: AssetServiceActions.AM_GET_PROVIDER_LIST,
});

/**
 * @description Function to Edit Assigned service
 */

export const editAssignedService = (
  assetId,
  serviceData,
  serviceId,
  serviceStatus,
  isRecurrentDateChange,
  getServiceList = true,
  currentView?,
) => ({
  assetId,
  currentView,
  getServiceList,
  isRecurrentDateChange,
  serviceData,
  serviceId,
  serviceStatus,
  type: AssetServiceActions.EDIT_ASSIGNED_SERVICES,
});

/**
 * @description Function to Edit Assigned service
 */

export const completeAssignedService = (assetId, serviceData, getServiceList = true, currentView?) => ({
  assetId,
  currentView,
  getServiceList,
  serviceData,
  type: AssetServiceActions.COMPLETE_ASSIGNED_SERVICES,
});
/*
 * @description Function to remove service from asset.
 * @param number assetId - Asset id.
 * @param number serviceId - Service id.
 * @param string serviceStatus - Service status.
 */
export const removeAssetService = (assetId: number, serviceId: number, serviceStatus: string) => ({
  assetId,
  serviceId,
  serviceStatus,
  type: AssetServiceActions.AM_REMOVE_ASSET_SERVICE,
});

/**
 * @description Function to remove service from asset in case of add asset.
 * @param payload - Service object.
 */
export const removeAddAssetService = (payload, status) => ({
  payload,
  status,
  type: AssetServiceActions.ADD_ASSET_REMOVE_ASSET_SERVICE,
});
/**
 * @description Function to openServiceFilter.
 */
export const openServiceFilter = () => ({
  type: AssetServiceActions.OPEN_SERIVCE_MORE_FILTER,
});
/**
 * @description Function to closeServiceFilter.
 */
export const closeServiceFilter = () => ({
  type: AssetServiceActions.CLOSE_SERIVCE_MORE_FILTER,
});
/**
 * @description Function to dispatch the method to set search value in the store
 * @param {string} searchString - takes entered search string.
 */
export const setSearchQuery = (searchQuery) => ({
  searchQuery,
  type: AssetServiceActions.SET_SERVICE_SEARCH_QUERY,
});

export const clearAssignedServiceList = () => ({
  type: AssetServiceActions.CLEAR_ASSIGNED_SERVICE_LIST,
});

export const resetQueryObjToDefault = () => ({
  type: AssetServiceActions.RESET_SERVICE_QUERY_PARAM,
});
