import React, { useEffect, useState } from "react";
// @ts-ignore
import styled from "custom-styled-components";
import { useSelector } from "react-redux";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { getDocumentById } from "./../../actions/documentAction";
import ImageTypeEnum from "./../../utils/enums/imageTypeEnum";
import { useAppDispatch, RootState } from "./../../store/store";
import { LoaderWrapper } from "am-web-ui-shared/elements";
import { useTranslation } from "react-i18next";

const StyledImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
  backgroud-color: white;
`;

const DivWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
`;

export interface IOriginalImage {
  readonly id: string;
  readonly documentId: string;
}

export const OriginalImage = (props: IOriginalImage) => {
  const { id, documentId } = props;
  const storedDocuments = useSelector(({ Document }: RootState) => Document);
  const originalDocument = HelperLodash.get(storedDocuments, ["document", `${documentId}:${ImageTypeEnum.original}`]);
  const thumbnailDocument = HelperLodash.get(storedDocuments, ["document", `${documentId}:${ImageTypeEnum.thumbnail}`]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!thumbnailDocument) {
      return;
    }

    if (originalDocument?.url) {
      setLoading(false);
      return;
    }

    setLoading(true);
    dispatch(getDocumentById(documentId, false, ImageTypeEnum.original));
  }, [originalDocument]);

  if (loading) {
    return <LoaderWrapper loadingText={t("common:LOADING")} activity={loading} />;
  }

  return (
    <>
      {originalDocument?.url ? (
        <DivWrapper>
          <StyledImage id={id} src={originalDocument.url} />
        </DivWrapper>
      ) : null}
    </>
  );
};

export default OriginalImage;
