export enum QtyItemEnum {
  available = "available",
  consume = "consume",
  qtyItem = "qtyItem",
  QtyItems = "QtyItems",
  qtyItemQuickTransfer = "qtyItemQuickTransfer",
  qtyItemTransfer = "qtyItemTransfer",
  allocationTransfer = "allocationTransfer",
  allocationQuickTransfer = "allocationQuickTransfer",
  allocation = "Allocation",
  allocations = "Allocations",
  allocationTransferLimit = "500",
  purchase = "Purchase",
}

export enum QtyItemAllocationEnum {
  AVAILABLE = "AVAILABLE",
  CONSUMED = "CONSUMED",
  Consumed_S = "Consumed",
  DISPOSED = "DISPOSED",
  INREP = "INREP",
  LSTORSTOL = "LSTORSTOL",
  RESERVED = "RESERVED",
  INTRAN = "INTRAN",
}

export const QtyItemErrorCodes = ["5003", "1107", "1111", "20682", "20726"];

export enum QtyItemKeys {
  primaryRowIds = "quantityItemIds",
  excludedIds = "excludedQuantityItemIds",
}

export enum QtyItemMultiEditErrorCodes {
  ResctictStatusChange = "21152",
}
