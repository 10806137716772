import { default as TextField } from "../../elements/Field/field";
import React from "react";
import { Field } from "redux-form";
import MultiTagInput from "./multiTagInput";

export const MultiTagComponent = (props) => {
  const {
    input,
    required,
    meta,
    onTagChange,
    disabled,
    onValidationReject,
    onlyUnique,
    maxTags,
    validate,
    validationRegex,
    preventSubmit,
    removeKeys,
    addKeys,
    addOnBlur,
    tagDisplayProp,
    addOnPaste,
    placeholder,
    cssSetting,
    infoText,
    errorMessage,
    setError,
    showClearAll,
    wrapperClassName,
  } = props;
  return (
    <TextField
      name={input.name}
      htmlFor={input.name}
      required={required}
      label={props.label}
      error={meta.error}
      touched={meta.touched}
      warning={meta.warning}
      cssSetting={cssSetting}
    >
      <MultiTagInput
        {...props.input}
        onChange={(tags, additionalProp) => {
          input.onChange(tags);
          if (onTagChange) {
            onTagChange(tags, additionalProp);
          }
        }}
        inputProps={{}}
        disabled={disabled}
        value={input.value}
        onValidationReject={onValidationReject}
        onlyUnique={onlyUnique}
        maxTags={maxTags}
        validate={validate}
        validationRegex={validationRegex}
        preventSubmit={preventSubmit}
        removeKeys={removeKeys}
        addKeys={addKeys}
        addOnBlur={addOnBlur}
        tagDisplayProp={tagDisplayProp}
        addOnPaste={addOnPaste}
        required={required}
        placeholder={placeholder}
        infoText={infoText}
        errorMessage={errorMessage}
        setError={setError}
        showClearAll={showClearAll}
        wrapperClassName={wrapperClassName}
      />
    </TextField>
  );
};
const MultiTagFormInput = (props: any) => {
  return (
    <Field
      component={MultiTagComponent}
      label={props.label}
      htmlFor={true}
      name={props.name}
      onTagChange={props.onTagChange}
      onChange={props.onChange}
      disabled={props.disabled}
      onValidationReject={props.onValidationReject}
      onlyUnique={props.onlyUnique}
      maxTags={props.maxTags}
      validationRegex={props.validationRegex}
      preventSubmit={props.preventSubmit}
      removeKeys={props.removeKeys}
      addKeys={props.addKeys}
      inputProps={props.inputProps}
      addOnBlur={props.addOnBlur}
      tagDisplayProp={props.tagDisplayProp}
      addOnPaste={props.addOnPaste}
      required={props.required}
      placeholder={props.placeholder}
      validate={props.validation}
      infoText={props.infoText}
      errorMessage={props.errorMessage}
      setError={props.setError}
      showClearAll={props.showClearAll}
      wrapperClassName={props.wrapperClassName}
    />
  );
};

export default MultiTagFormInput;
