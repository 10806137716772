import { IconName, CellTypes, IGridSetting } from "am-web-ui-shared/components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import { ImageSizeEnum } from "../../../../../components/styled/squareImage/imageSizeUtil";
import squareImageContainer from "../../../../../components/styled/squareImage/squareImageContainer";
const styleRow = {
  color: `${theme.gridRow.rowTextColor}`,
  fontFamily: `${theme.fontNormal.fontFamily}`,
  fontSize: 14,
};
const imgSizeSmall = ImageSizeEnum.SMALL;
const fieldCombination = CellTypes.FieldCombination;
const scanCode = "scanCode";
const inventoryNumber = "inventoryNumber";

export const gatewayAssetsGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          key: "manufacturerName/model",
          properties: [{ name: "manufacturerName" }, { name: "model" }],
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          key: "name",
          properties: [{ name: "name" }],
          style: {
            color: `${theme.colors.base.black}`,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 4,
      image: {
        attributes: {
          image: { maxWidth: imgSizeSmall, maxHeight: imgSizeSmall },
          wrapper: {
            height: imgSizeSmall,
            imageSize: 42,
            style: { marginRight: "16px" },
            width: imgSizeSmall,
          },
        },
        component: squareImageContainer,
        defaultImage: IconName.Category,
        dependencies: {
          getAlternateDefaultImage: "getAlternateDefaultImage",
        },
        fieldName: "assetImageId",
      },
      isSortable: false,
      type: CellTypes.ImageFieldCombination,
    },
    {
      bindProperties: [
        {
          key: "responsibleEmployeeName",
          properties: [{ name: "responsibleEmployeeName" }],
          style: styleRow,
        },
        {
          key: "currentLocationName",
          properties: [{ name: "currentLocationName" }],
          style: styleRow,
        },
      ],
      cellSize: 3,
      isSortable: false,
      type: fieldCombination,
    },
    {
      bindProperties: [
        {
          key: scanCode,
          properties: [{ name: scanCode }],
          style: styleRow,
        },
        {
          key: inventoryNumber,
          properties: [{ name: inventoryNumber }],
          style: styleRow,
        },
      ],
      cellSize: 2,
      isSortable: true,
      type: fieldCombination,
    },
    {
      bindProperties: [
        {
          properties: [{ name: "assetStatusName" }],
        },
      ],
      cellSize: 3,
      component: "getAssetsStatus",
      isSortable: false,
      type: CellTypes.Custom,
    },
  ],
  defaultQueryFields: [scanCode],
  showTooltip: true,
};

export const ignoreSortProperties = [inventoryNumber];
