import React from "react";
import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { TParam } from "../../localization/types";
import styled from "../themeConfig/styledComponents";

const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const NoRecordsSecondaryText = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  margin: 16px 0;
`;
const ShowRecord = styled.a.attrs({
  id: "btnNoRecordsShowRecord",
})`
  color: ${(props) => props.theme.colors.base.linkColor};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
`;
export interface INoRecord extends WithTranslation {
  searchString: string;
  objectType: TParam;
  resetHandler: () => void;
}

const NoRecord = (props: INoRecord) => {
  const { objectType, resetHandler, searchString, t } = props;

  return (
    <FixedContainer className="wrapper">
      <SquareIcon name={IconName.NoSearchResult} imageSize={64} className="grey medium" />
      <NoRecordsSecondaryText>
        {searchString ? `${t("common:NO_RECORD")}"${searchString.trim()}"` : t("common:NO_RECORD_FILTER")}
      </NoRecordsSecondaryText>
      <ShowRecord title={t("common:GO_TO_LIST", { moduleType: t(objectType) })} onClick={resetHandler}>
        {t("common:GO_TO_LIST", { moduleType: t(objectType) })}
      </ShowRecord>
    </FixedContainer>
  );
};

export default withTranslation()(NoRecord);
