export enum ActionItem {
  add = "Add",
  addAllocation = "AddAllocation",
  addPurchase = "addPurchase",
  assign = "Assign",
  assignWorker = "AssignWorker",
  clone = "Clone",
  complete = "Complete",
  completeService = "CompleteService",
  delete = "Delete",
  deletePlugCost = "DeletePlugCost",
  dismiss = "Dismiss",
  edit = "Edit",
  export = "Export",
  import = "Import",
  label = "Label",
  multiEdit = "MultiEdit",
  remove = "Remove",
  rename = "Rename",
  renew = "Renew",
  setDefault = "SetDefault",
  setting = "Setting",
  sort = "Sort",
  status = "Status",
  transfer = "Transfer",
  update = "Update",
  quickTransfer = "QuickTransfer",
  repeat = "Repeat",
  addFolder = "AddFolder",
  move = "Move",
  listView = "ListView",
  mapIcon = "MapIcon",
  merge = "Merge",
  removeAll = "RemoveAll",
  editAssetsNotLinkedTemplate = "EditAssetsNotLinkedTemplate",
  editAssetsTemplate = "EditAssetsTemplate",
  editQuantityItemsCosts = "EditQuantityItemsCosts",
  alertSetting = "AlertSetting",
  stockChange = "StockChange",
  duplicateReport = "DuplicateReport",
  repair = "Repair",
  lostStolen = "LostStolen",
  hiltiServices = "HiltiServices",
  contact = "Contact",
  plugCost = "PlugCost",
  renewReturn = "RenewReturn",
  manageStock = "ManageStock",
  returnLoanRental = "ReturnLoanRental",
  returnLoan = "ReturnLoan",
  returnRental = "ReturnRental",
}
