import React from "react";
import styled from "styled-components";
import Field from "../Field/field";
import FormPhoneInput from "./formPhoneInput";
import IPhoneInputProps from "./iPhoneInputProps";

const Wrapper = styled.div`
  position: relative;
`;

const StyledPhoneNumberInput = styled.input`
  border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete60};
  width: 100%;
  height: 48px;
  padding-left: 86px;
  font-weight: 700;
`;

const StyledPhoneCodeInput = styled.input`
  border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete60};
  width: 80px;
  height: 48px;
  padding-left: 10px;
  position: absolute;
  top: 22px;
  font-weight: 700;
`;

/**
 * @description This class is the shared component for Phone Number Input field
 *
 * @class PhoneInput
 * @extends {React.PureComponent<IPhoneInputProps>}
 */
class PhoneInput extends React.PureComponent<IPhoneInputProps> {
  render() {
    const {
      codeValue,
      numberValue,
      codeName,
      numberName,
      disabled,
      codePlaceholder,
      numberPlaceholder,
      codeMaxLength,
      numberMaxLength,
      name,
      label,
      error,
    } = this.props;

    return !this.props.isFormControl ? (
      <Wrapper>
        <Field name={name} htmlFor={name} label={label} error={error}>
          <StyledPhoneCodeInput
            id={codeName}
            name={codeName}
            value={codeValue}
            disabled={disabled}
            maxLength={codeMaxLength}
            placeholder={codePlaceholder}
          />
          <StyledPhoneNumberInput
            id={numberName}
            name={numberName}
            value={numberValue}
            disabled={disabled}
            maxLength={numberMaxLength}
            placeholder={numberPlaceholder}
          />
        </Field>
      </Wrapper>
    ) : (
      <FormPhoneInput
        codeName={codeName}
        numberName={numberName}
        label={label}
        type={this.props.type}
        numberPlaceholder={numberPlaceholder}
        codePlaceholder={codePlaceholder}
        validation={this.props.validation}
        required={this.props.required}
        name={name}
        htmlFor={name}
        disabled={disabled}
        {...this.props}
      />
    );
  }
}

export default PhoneInput;
