import React from "react";
const SVG = () => (
  <svg width="21px" height="15px" viewBox="0 0 21 15" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="userGroup" transform="translate(-2.000000, -4.000000)" fill="#524F53">
        <path
          d="M5.43510056,12.9970128 L10.5555691,13 L14,14.7008417 L14,19 L2,18.9970128 L2,14.7008417 L5.43510056,12.9970128 Z M15.4351006,11.9970128 L20.5555691,12 L22.9705627,13.7008417 L22.9705627,18 L16,18 L16,13.7008417 L14.0335349,12.6921971 L15.4351006,11.9970128 Z M8,5 C9.65685425,5 11,6.34314575 11,8 C11,9.65685425 9.65685425,11 8,11 C6.34314575,11 5,9.65685425 5,8 C5,6.34314575 6.34314575,5 8,5 Z M17,4 C18.6568542,4 20,5.34314575 20,7 C20,8.65685425 18.6568542,10 17,10 C15.3431458,10 14,8.65685425 14,7 C14,5.34314575 15.3431458,4 17,4 Z"
          id="🎨Color"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
