import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";
import { IGridColumnSetting } from "..";
import { SquareIcon } from "../../icons/iconWrapper";
import GridCombinationalCell from "./gridCombinationalCell";
import { IGridCell } from "./iGridCell";

import { dependencyResolver } from "../gridUtils";

const ImgContent = styled.div`
  display: flex;
  .cell-content-wrapper {
    min-width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    line-height: 2;
  }
  .bottom {
    margin-top: auto;
  }
`;
export const ImageWrapper = styled(Grid)`
  width: ${(props: IGridColumnSetting) => props.width};
  height: ${(props: IGridColumnSetting) => props.height};
  margin-right: 16px;
  background-color: ${(props) => props.theme.colors.base.white};
  border: 1px solid ${(props) => props.theme.colors.base.heavyConcrete10};
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  margin: auto;
  max-width: 100%;
  max-height: 100%;
`;

const CombinationCell = styled.div``;

export const getImage = (data, ImgCell, dependencies) => {
  if (ImgCell.dependencies && ImgCell.dependencies.getImage) {
    return dependencyResolver(dependencies, ImgCell.dependencies.getImage)(data);
  } else if ((!data[ImgCell.fieldName] || data[ImgCell.fieldName] === "") && data.templateImageId) {
    return data.templateImageId;
  }
  return data[ImgCell.fieldName];
};

const getAlternateDefaultImage = (data, ImgCell, dependencies) => {
  if (ImgCell.dependencies && ImgCell.dependencies.getAlternateDefaultImage) {
    return dependencyResolver(dependencies, ImgCell.dependencies.getAlternateDefaultImage)(data);
  }
  return false;
};

const getImageFromImageCell = (data, ImgCell, dependencies) => {
  return ImgCell.externalLinkField && data[ImgCell.externalLinkField]
    ? data[ImgCell.externalLinkField]
    : ImgCell.property
      ? ImgCell.property
      : getAlternateDefaultImage(data, ImgCell, dependencies) || ImgCell.defaultImage;
};

/**
 * Grid cell for Image, & Multitext combination
 */

export const ImageFieldCombination = (props: IGridCell) => {
  const { column, rowIndex, columnIndex, data, dependencies } = props;
  const ImgCell = column.image;
  const isExternalLink = (ImgCell.externalLinkField && data[ImgCell.externalLinkField]) || ImgCell.property;
  const image = getImageFromImageCell(data, ImgCell, dependencies);
  const imageAttributes = column.image.attributes && column.image.attributes.image;
  const wrapperAttributes = column.image.attributes && column.image.attributes.wrapper;
  const size = wrapperAttributes.imageSize;
  const fieldImage = getImage(data, ImgCell, dependencies);
  const selfLoadImage = ImgCell.hasOwnProperty("selfLoadImage") ? ImgCell.selfLoadImage : true;
  const imageWrapperAttributes = Object.assign({}, wrapperAttributes);
  delete imageWrapperAttributes.imageSize;
  return (
    <ImgContent>
      {ImgCell.component && fieldImage ? (
        <ImgCell.component
          wrapperAttributes={wrapperAttributes}
          selfLoadImage={selfLoadImage}
          documentId={fieldImage}
          type={ImgCell.type}
        />
      ) : (
        <ImageWrapper width={wrapperAttributes.width} height={wrapperAttributes.height} {...imageWrapperAttributes}>
          {isExternalLink ? (
            <Img id={`image${rowIndex}`} src={image} {...imageAttributes} alt="" />
          ) : (
            <SquareIcon name={image} imageSize={size} className="grid-thumb" />
          )}
        </ImageWrapper>
      )}
      <CombinationCell className="cell-content-wrapper">
        <GridCombinationalCell
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          column={column}
          data={data}
          dependencies={dependencies}
          showTooltip={props.showTooltip}
          showValueTooltip={props.showValueTooltip}
        />
      </CombinationCell>
    </ImgContent>
  );
};

export default React.memo(ImageFieldCombination);
