import React from "react";
import styled from "styled-components";
import { IChargePanelField } from "./iAssociateChargesPanelprops";

const ReadOnlyFieldLabel = styled.div``;
const ReadOnlyFieldValue = styled("div")`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
  margin-bottom: 10px;
`;

export const ReadOnlyField = ({ label, value, currencyCode }) => {
  return (
    <>
      <ReadOnlyFieldLabel>{label}</ReadOnlyFieldLabel>
      <ReadOnlyFieldValue>{`${currencyCode ? currencyCode : ""} ${value}`}</ReadOnlyFieldValue>{" "}
    </>
  );
};

const RenderEachField = ({ editable, children, label, chargeSetting, currencyCode }: IChargePanelField) => {
  if (editable) {
    return children(label, currencyCode);
  } else {
    return chargeSetting ? <ReadOnlyField label={label} value={chargeSetting} currencyCode={currencyCode} /> : null;
  }
};

export default RenderEachField;
