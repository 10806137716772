import React from "react";
import { IconName, SquareIcon } from "TARGET_BUILD/../../shared";
import {
  StyledTableCell,
  HeaderFirstRow,
  HeaderSecondRow,
  StyledTableRow,
  CheckmarkContainer,
  Text,
  EmptyRow,
} from "./UpgradeOnTrackDialogStyles";
import { useTranslation } from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "TARGET_BUILD/../../component-lib/src/theme";
import { ITableInfoItem } from "./iTableInfo";

export const UpgradeOnTrackContent = () => {
  const { t } = useTranslation();
  const tableInfo: ITableInfoItem[] = [
    {
      description: "common:UPGRADE_EQUIPMENT_MANAGEMENT_HILTI_ASSETS",
      ontReady: true,
      ontLite: true,
      ontPro: true,
      limited: "common:LIMITED",
    },
    { description: "common:UPGRADE_EQUIPMENT_MANAGEMENT_ALL_ASSETS", ontReady: false, ontLite: true, ontPro: true },
    { description: "common:UPGRADE_QUICK_ACCESS", ontReady: true, ontLite: true, ontPro: true },
    {
      description: "common:UPGRADE_ACCESS_TO_NURON",
      ontReady: true,
      ontLite: true,
      ontPro: true,
    },
    {
      description: "common:UPGRADE_DASHBOARD_INSIGHTS_ACCESS",
      ontReady: true,
      ontLite: true,
      ontPro: true,
      limited: "common:LIMITED",
    },
    { description: "common:UPGRADE_ACTIVE_TRACKING", ontReady: false, ontLite: true, ontPro: true },
    { description: "common:UPGRADE_EMPLOYEE_CERTIFICATE_MANAGEMENT", ontReady: false, ontLite: true, ontPro: true },
    { description: "common:UPGRADE_ALERTS_NOTIFICATION_SETTINGS", ontReady: false, ontLite: true, ontPro: true },
    { description: "common:UPGRADE_CONSUMABLES_COMMODITIES_MANAGEMENT", ontReady: false, ontLite: false, ontPro: true },
    { description: "common:UPGRADE_ASSET_COSTS_REPORT", ontReady: false, ontLite: false, ontPro: true },
    { description: "common:UPGRADE_GATEWAYS", ontReady: false, ontLite: false, ontPro: true },
    { description: "common:UPGRADE_3RD_PARTY_INTEGRATION", ontReady: false, ontLite: false, ontPro: true },
  ];

  return (
    <TableContainer
      sx={{
        border: "2px solid ",
        borderColor: theme.colors.brown[200],
      }}
    >
      <TableHead>
        <TableRow>
          <StyledTableCell className="first-cell"></StyledTableCell>
          <StyledTableCell className="second-cell">
            <HeaderFirstRow>{t("common:LOGO_TEXT_FLEET")}</HeaderFirstRow>
            <HeaderSecondRow>{t("common:FREE")}</HeaderSecondRow>
            <br />
          </StyledTableCell>
          <StyledTableCell className="second-cell">
            <HeaderFirstRow>{t("common:UPGRADE_LITE")}</HeaderFirstRow>
            <HeaderSecondRow>
              {t("frequencySettings:MONTHLY")} <br />
              {t("common:SUBSCRIPTION")}
            </HeaderSecondRow>
          </StyledTableCell>
          <StyledTableCell className="second-cell">
            <HeaderFirstRow>{t("common:UPGRADE_PRO")}</HeaderFirstRow>
            <HeaderSecondRow>
              {t("frequencySettings:MONTHLY")} <br />
              {t("common:SUBSCRIPTION")}
            </HeaderSecondRow>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      {tableInfo.map((row) => (
        <StyledTableRow key={row.description}>
          <StyledTableCell className="first-cell">{t(row.description)}</StyledTableCell>
          <StyledTableCell className="second-cell">
            {row.ontReady ? (
              <CheckmarkContainer>
                <SquareIcon className="checkmark" name={IconName.Checkmark} />
                {row.limited ? <Text>{t(row.limited)}</Text> : null}
              </CheckmarkContainer>
            ) : (
              <EmptyRow />
            )}
          </StyledTableCell>
          <StyledTableCell className="second-cell">
            {row.ontLite ? (
              <CheckmarkContainer>
                <SquareIcon className="checkmark" name={IconName.Checkmark} />
                {row.full ? <Text>{t(row.full)}</Text> : null}
              </CheckmarkContainer>
            ) : (
              <EmptyRow />
            )}
          </StyledTableCell>
          <StyledTableCell className="second-cell">
            {row.ontPro ? (
              <CheckmarkContainer>
                <SquareIcon className="checkmark" name={IconName.Checkmark} />
                {row.full ? <Text>{t(row.full)}</Text> : null}
              </CheckmarkContainer>
            ) : null}
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableContainer>
  );
};
