import { takeLatest } from "redux-saga/effects";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { storageSetItem } from "../../../utils/storageUtils";
import { refreshTokenConstants } from "../../../actionConstants/refreshTokenConstants";
import { RefreshTokenWebUI } from "../../../api/refreshTokenApi";
import ApplicationConstants from "../../../applicationConstants";
import { call } from "../../../sagas/common/commonSaga";

export function* refreshTokenSaga() {
  yield takeLatest(refreshTokenConstants.REFRESH, refreshToken);
}

function* refreshToken() {
  const { NUMBER } = ApplicationConstants;
  const { NUM1000, NUM60 } = NUMBER;

  const response = yield call(() => {
    return RefreshTokenWebUI();
  });
  storageSetItem(appConstants.TOKEN_RESPONSE, JSON.stringify(response));
  if (response.access_token) {
    storageSetItem(appConstants.USER_TOKEN, response.access_token);
  }

  storageSetItem(
    appConstants.TIME_TO_REFRESH,
    (Date.now() + parseInt(response.expires_in, 10) * NUM1000 - NUM60 * NUM1000).toString(),
  );
}

export default refreshTokenSaga;
