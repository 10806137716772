import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M14.8284271,7.75735931 L12,10.5857864 L9.17157288,7.75735931 L7.75735931,9.17157288 L10.5850793,11.9992929 L7.75735931,14.8284271 L9.17157288,16.2426407 L11.9992929,13.4135065 L14.8284271,16.2426407 L16.2426407,14.8284271 L13.4142136,12 L16.2426407,9.17157288 L14.8284271,7.75735931 Z" />
    </g>
  </svg>
);

export default SVG;
