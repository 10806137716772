import React from "react";
const SVG = () => (
  <svg width="16px" height="18px" viewBox="0 0 16 18" version="1.1">
    <defs>
      <path
        d="M15,10 L15,20 L5,20 L5,10 L15,10 Z M13,2 L13,4 C17.418278,4 21,7.581722 21,12 C21,12.3389895 20.9789157,12.6730544 20.9379871,13.0009551 L18.9169061,13.0008069 C18.9715542,12.6753441 19,12.340994 19,12 C19,8.6862915 16.3137085,6 13,6 L13,8 L9,5 L13,2 Z"
        id="path-1"
      />
    </defs>
    <g id="Template" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="F6-03" transform="translate(-1330.000000, -781.000000)">
        <g id="screen" transform="translate(600.000000, 80.000000)">
          <g id="Group" transform="translate(0.000000, 32.000000)">
            <g id="side-panel" transform="translate(709.000000, 288.000000)">
              <g id="Group-5" transform="translate(0.000000, 359.000000)">
                <g id="05-Icons-/-Rotate_Left" transform="translate(16.000000, 20.000000)">
                  <mask id="mask-2" fill="white">
                    <use href="#path-1" />
                  </mask>
                  <use id="🎨Color" fill="#524F53" fillRule="evenodd" href="#path-1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
