/**
 * @description - List of action constants that will be used for for multiple actions associated with gateway report settings.
 */
const GatewayReportSettingsConstants = {
  RM_GET_GATEWAY_REPORT_SETTINGS_FREQUENCY: "RM_GET_GATEWAY_REPORT_SETTINGS_FREQUENCY",
  RM_GET_SCHEDULER_SETTINGS: "RM_GET_SCHEDULER_SETTINGS",
  RM_PUT_SCHEDULER_SETTINGS: "RM_PUT_SCHEDULER_SETTINGS",
  RM_RESET_DATA: "RM_RESET_DATA",
  RM_SEND_ASSET_REPORT: "RM_SEND_ASSET_REPORT",
  RM_SEND_LOCATION_REPORT: "RM_SEND_LOCATION_REPORT",
  RM_SET_FREQUENCY: "RM_SET_FREQUENCY",
  RM_SET_SCHEDULER_SETTINGS: "RM_SET_SCHEDULER_SETTINGS",
};

export default GatewayReportSettingsConstants;
