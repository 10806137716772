import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path
        d="M9,2 L14,5 L14,10 L22,18 L18,22 L10,14 L5,14 L2,9 L4,7 L7,10 L10,7 L7,4 L9,2 Z"
        transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
      ></path>
    </g>
  </svg>
);

export default SVG;
