import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";
import { IAccordion, IAccordionState } from "./iAccordion";

const ExpansionPanelDetailsStyle = styled(AccordionDetails)`
  && {
    margin-left: 44px;
    border-bottom: 1px solid ${(props) => props.theme.importComponents.detailsPanelBorder};
    padding: 12px 0;
    width: 822px;
    display: flex;
  }
`;
const WrapperDiv = styled("div")`
  && {
    background-color: ${(props) => props.theme.detailsPanel.detailBackground};
  }
`;
const TypographyInner = styled(Typography)`
  && {
    color: ${(props) => props.theme.colors.base.whiteFaded};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 300;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    padding-right: 16px;
  }
`;
const TypographyRowNoContent = styled(TypographyInner as any)`
  && {
    color: ${(props) => props.theme.boldBlackText.color};
    width: 336px;
    &:first-child {
      width: 150px;
    }
  }
`;

const DetailsContainer = styled.div`
  max-height: 320px;
  overflow-x: auto;
`;

class AccordionDetailsShared extends React.Component<IAccordion, IAccordionState> {
  static getDerivedStateFromProps(nextProps: IAccordion, prevState) {
    if (nextProps.accordionData) {
      return {
        accordionData: nextProps.accordionData,
      };
    }
    return {
      accordionData: prevState.accordionData,
    };
  }
  constructor(props: IAccordion) {
    super(props);
    this.state = {
      accordionData: null,
    };
  }
  componentDidMount() {
    this.state = { accordionData: null };
  }
  render() {
    const { accordionData, index } = this.props;
    return (
      <WrapperDiv id={`detailsWrapper${index}`}>
        <DetailsContainer id={`errorDetailsContainer${index}`}>
          <ExpansionPanelDetailsStyle id={`errorDetailDiv${index}`}>
            {accordionData && accordionData.length > 0
              ? accordionData.map((data, i) => {
                  return (
                    <TypographyRowNoContent key={i} id={`detailsData${i}`} title={data.title}>
                      {data.content}
                    </TypographyRowNoContent>
                  );
                })
              : null}
          </ExpansionPanelDetailsStyle>
        </DetailsContainer>
      </WrapperDiv>
    );
  }
}

export default AccordionDetailsShared;
