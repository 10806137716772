/**
 * @description Utility file to filter or update the grid list. Iterators on the grid list.
 */

import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore;
import { MultiSelectValidation } from "TARGET_BUILD/config";
import { ILocationsDataProps } from "../../components/DetailsPanel/QtyItemsLink/iQtyItemsLinkProps";
import { IAssetQtyCountList } from "../../modules/location/iLocationCountProps";
import { IWorkerDetailsProps } from "../../modules/worker/redux/list/iWorkerActionProps";
import { FieldType } from "../enums/locationEnums";
import ModuleName from "../enums/moduleNameEnum";
import { checkedListInfoEnum, ICheckedListInfo, ICheckedPayloadInfo, ListMetaEnum, rowActionType } from "./list";

const MAX_SLECTION_COUNT = 200;

const isChildDataExists = (row) => {
  return row.childData && row.childData.response && row.childData.response.length;
};

const isSelectionPersistToChild = (row) => {
  return (
    row.childData &&
    row.childData.response &&
    row.childData.response.length &&
    row.metaData &&
    !row.metaData.isExpandable
  );
};

const updateCheckedListInfo = (checkedListInfo, propertyName, PropertyValue) => {
  const checkedListPropertyName = HelperLodash.get(checkedListInfo, propertyName);

  if (Array.isArray(checkedListPropertyName)) {
    checkedListPropertyName.push(PropertyValue);
  }
};

const getIdKey = (meta) => {
  return meta && meta.id ? meta.id : "assetId";
};

const handleHierarchyRowWithoutChildData = (checkedListInfo, row, metaKeys?) => {
  checkedListInfo.hierarchyRowsInfo.push({
    excludedIds: [],
    hasChildData: false,
    id: row[getIdKey(metaKeys)],
    primaryRowIds: [],
    [ListMetaEnum.IsRowCheckedOnCollapsedMode]: true,
  });
  checkedListInfo.checkedRows.push(row);
  checkedListInfo.count += row.childCount + 1;
  updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.primaryRowIds);
  return checkedListInfo;
};

const handleHierarchyRowWithChildData = (checkedListInfo, row, metaKeys?) => {
  const idKey = getIdKey(metaKeys);
  const isRowCheckedOnCollapseMode = Boolean(row.metaData[ListMetaEnum.IsRowCheckedOnCollapsedMode]);
  // row may or may not be  selected and has child data. Iterate each child and append to selected list.
  updateCheckedListInfo(checkedListInfo, checkedListInfoEnum.hierarchyRowsInfo, {
    excludedIds: [],
    hasChildData: true,
    id: HelperLodash.get(row, idKey),
    primaryRowIds: [],
    [ListMetaEnum.IsRowCheckedOnCollapsedMode]: isRowCheckedOnCollapseMode,
  });
  if (isRowCheckedOnCollapseMode) {
    checkedListInfo.count += row.childCount - getExclusionCount(row.childData.response);
  }
  if (row.metaData && row.metaData.isChecked) {
    checkedListInfo.count += 1;
    updateCheckedListInfo(checkedListInfo, checkedListInfoEnum.checkedRows, row);
    updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.primaryRowIds);
  } else {
    updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.excludedIds);
  }
  checkedListInfo = getCheckedList(row.childData.response, checkedListInfo, metaKeys);
  return checkedListInfo;
};

const checkedListForHierarchyRow = (row, checkedListInfo, metaKeys?) => {
  if (!isChildDataExists(row)) {
    // row selected and never expanded
    checkedListInfo = handleHierarchyRowWithoutChildData(checkedListInfo, row, metaKeys);
  } else {
    // row may or may not selected but has childData. iterate each child and get excluded ids
    checkedListInfo = handleHierarchyRowWithChildData(checkedListInfo, row, metaKeys);
  }
  return checkedListInfo;
};

const handlePreviousSelections = (rowList, rowItem, isSelected, keyType) => {
  if (keyType === rowActionType.selected) {
    if (!!rowItem?.metaData && rowItem.metaData.isChecked) {
      clearAllSelection(rowList);
    } else {
      clearAllCheckedRows(rowList, true);
    }
  } else if (isSelected) {
    clearAllSelection(rowList);
  }
};

const updateIdsInthePath = (checkedListInfo, row, metaKeys, propertyName) => {
  const idKey = getIdKey(metaKeys);
  const hierarchialRows = checkedListInfo.hierarchyRowsInfo;
  const hierarchialRow = hierarchialRows.find((hRow) => hRow.id.toString() === getParentId(row));
  if (hierarchialRow) {
    // If a row is part of some hierarchial row then its information should be updated in
    // its parent object rather than at root level.
    HelperLodash.get(hierarchialRow, propertyName).push(HelperLodash.get(row, idKey));
  } else {
    /*
          case: When hierarchial row is expanded and then checked
          and it is at root level.
        */
    HelperLodash.get(checkedListInfo, propertyName).push(HelperLodash.get(row, getIdKey(metaKeys)));
  }
};

const updateForNonHierachialRowsInfo = (checkedListInfo, row, metaKeys) => {
  checkedListInfo.checkedRows.push(row);
  updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.primaryRowIds);
  checkedListInfo.count += 1;
  return checkedListInfo;
};

export const getListForPath = (list, path, key) => {
  if (!path) {
    return list;
  }
  let newList = list;
  const pathArr = path.toString().split(".");
  for (const ele of pathArr) {
    newList.forEach((item) => {
      const itemKey = HelperLodash.get(item, key);
      if (itemKey && itemKey.toString() === ele) {
        newList = item.childData && item.childData.response ? item.childData.response : newList;
      }
    });
  }
  return newList;
};

/**
 * @description Function toggles the row selection
 * need to update
 * @param {Array} - list - array of row objects
 * @param {number} - rowId for toggle selection
 */
export const setRowSelection = (
  list: any = [],
  rowId = -1,
  isSelected = false,
  key: string,
  maxSelectionCount?: number,
) => {
  let count = 0;
  let row;
  list.forEach((item) => {
    if (item.isSelected) {
      count = count + 1;
    }
    if (HelperLodash.get(item, key) === rowId) {
      row = item;
    }
  });

  if (row) {
    row.isSelected = count < (maxSelectionCount ? maxSelectionCount : MAX_SLECTION_COUNT) && isSelected;
  }
  return list;
};

export const updateChildListToAsset = ({ list, childList, id }) => {
  const rowObj = (list || []).find((row) => row.assetId === id);
  if (rowObj) {
    if (!rowObj.metaData) {
      rowObj.metaData = {};
    }
    rowObj.metaData.isExpandable = true;
    // if row is selected on collapsed mode then only show children selected afte expanded.
    if (rowObj.metaData && rowObj.metaData[ListMetaEnum.IsRowCheckedOnCollapsedMode]) {
      setAllCheckedRows(childList.response);
    }
    const oldChildData = rowObj.childData || { response: [] };
    const newResp = (oldChildData.response || []).concat(
      appendMetaPropertyToList(childList.response, { type: ModuleName.asset }) || [],
    );
    rowObj.childData = {
      ...childList,
      response: newResp,
    };
  }
};

/*
  When row is collapsed or expanded all its child should be collapsed.
*/
export const updateMetaPropertyToRow = (list, id, metaPropertyName, PropertyValue?, resetChildMeta?) => {
  const rowObj = (list || []).find((row) => row.assetId === id);
  if (rowObj && rowObj.metaData) {
    rowObj.metaData[`${metaPropertyName}`] =
      typeof PropertyValue !== "undefined" ? PropertyValue : !HelperLodash.get(rowObj, ["metaData", metaPropertyName]);
    if (isChildDataExists(rowObj) && resetChildMeta) {
      rowObj.childData.response.forEach((childRow) =>
        updateMetaPropertyToRow(rowObj.childData.response, childRow.assetId, metaPropertyName, false, resetChildMeta),
      );
    }
  }
  return list;
};

/**
 * @description Function toggles the row selection
 * @param {Array} - list - array of row objects
 * @param {number} - rowId for toggle selection
 */
export const subscribeSelection = (list: any = [], selectedRowObj) => {
  const rowSelected = selectedRowObj.selectedRow;
  const row = list.filter((item) => item[rowSelected.alertTypeKey] === rowSelected.alertType);
  const rowClicked = {
    row: row[0],
  };
  if (row.length) {
    row[0][rowSelected.parentArrKey].map((item) => {
      if (item[rowSelected.key] === rowSelected.value) {
        item.isSubscribed = selectedRowObj.isSubscribed;
      }
    });
    rowDisabled(list, rowClicked);
  }
  return list;
};

export const rowDisabled = (list: any = [], rowClicked) => {
  const rowSelected = rowClicked.row && rowClicked.row.id;
  if (list.length && rowSelected) {
    list.forEach((row) => {
      if (row.id === rowSelected) {
        checkAndDisableResponsibilityTypes(row);
      }
    });
  }

  return list;
};

export const checkAndDisableRows = (list: any = []) => {
  list.forEach((row) => {
    checkAndDisableResponsibilityTypes(row);
  });

  return list;
};

const checkAndDisableResponsibilityTypes = (row) => {
  const rowsSubscribed = row.responsibilityTypes.filter((responsibilityType) => responsibilityType.isSubscribed);
  if (rowsSubscribed && rowsSubscribed.length > 0) {
    row.responsibilityTypes.map((item) => {
      if (
        item.code === rowsSubscribed[0].code &&
        item.isSubscribed &&
        !item.isDisabled &&
        rowsSubscribed.length === 1
      ) {
        item.isDisabled = true;
      } else {
        item.isDisabled = false;
      }
    });
  }
};

export const getIdsFromList = (list, key) => list.map((item) => HelperLodash.get(item, key));

/**
 * @description Function toggles the row selection
 * @param {Array} - list - array of row objects
 * @param {number} - rowId for toggle selection
 */
export const clearAllSelection = (list: any = []) => {
  const newList = list.slice(0);
  newList.forEach((row) => {
    if (isChildDataExists(row)) {
      clearAllSelection(row.childData.response);
    }
    row.isSelected = false;
  });
  return newList;
};

/**
 * @description Function returns the updated list with isSelected Property added and remove response from the manufacturer
 * @param {Array} - list - array of row objects
 */
export const appendSelectedPropertyToList = (list = [], isAllSelected?) => {
  return (list || []).map((element) => {
    element.isSelected = false;
    if (!element.metaData) {
      element.metaData = {};
    }
    element.metaData.isChecked = isAllSelected ? true : false;
    return element;
  });
};

/**
 * @description Function returns the updated list with new meta property
 * @param {Array} - list - array of row objects
 */
export const appendMetaPropertyToList = (list: any[] = [], metaProperties) => {
  if (typeof metaProperties !== "object") {
    return list;
  }

  return (list || []).map((element) => {
    element.metaData = { ...(element.metaData || {}), ...metaProperties };

    return element;
  });
};

/**
 * @description Function returns the updated list with new meta property
 * @param {Array} - list - array of row objects
 */
export const appendMetaPropertiesInItem = (list, itemIds: any[] = [], metaProperties: object, key: string) => {
  if (typeof metaProperties !== "object") {
    return list;
  }

  return (list || []).map((item) => {
    if (itemIds.includes(HelperLodash.get(item, key))) {
      item.metaData = { ...(item.metaData || {}), ...metaProperties };
    }

    return item;
  });
};

/**
 * @description Function returns the updated list with isSelected Property added and remove response from the manufacturer
 * @param {selectedList} - list - array of row objects
 * @param {key} - key to retrieve from the object in the list
 */
export const getKeysFromList = (selectedList, key) => {
  return selectedList.map((list) => {
    return HelperLodash.get(list, key);
  });
};

/**
 * @description Function returns the updated list with isSelected Property added and remove response from the manufacturer
 * @param {Array} - list - array of row objects
 * @param {property} - key to retrieve from the object in the list
 */
export const getFlatMap = (list, property) => {
  return list.flatMap((item) => HelperLodash.get(item, property));
};

/**
 * @description Function returns the flat array
 * @param {Array} - list - array of row objects
 *
 * @returns {Array}
 */
export const getFlatArray = (list) => {
  return (list || []).flatMap((item) => item);
};

/**
 * @description Function returns the updated sort query
 * @param {property} - state of the sort query
 * @param {Array} - sortList - array of sort list
 */
export const applySort = (state, sortList) => {
  const isFoundRecord = sortList.SORT_LIST.find((item) => item.name === state.sortQuery.sortField);
  return !isFoundRecord
    ? state.previousSortQuery || {
        sortField: sortList.DEFAULT_SORT.name,
        sortType: sortList.DEFAULT_SORT.sortType,
      }
    : state.sortQuery;
};

export const getCheckedRowsExcludingChildren = (list: any[] = [], prevSelectedRows: any[] = []) => {
  let selectedRows = prevSelectedRows.concat((list || []).filter((row) => row.metaData && row.metaData.isChecked));
  const rowsWithChildData = (list || []).filter((row) => isChildDataExists(row));
  for (const item of rowsWithChildData) {
    if (!item.metaData.isChecked) {
      selectedRows = getCheckedRowsExcludingChildren(item.childData.response, selectedRows);
    }
  }
  return selectedRows;
};

/**
 * @description This function is used to return the selected row from the grid list view
 * @param list
 *
 * @returns object
 */
export const getSelectedRowDetail = (list: any[] = []) => {
  let selectedRow = (list || []).find((row) => row.isSelected);
  if (!selectedRow) {
    const rowsWithChildData = (list || []).filter((row) => isChildDataExists(row));
    for (const item of rowsWithChildData) {
      selectedRow = getSelectedRowDetail(item.childData.response);
      if (selectedRow) {
        break;
      }
    }
  }

  return selectedRow;
};

/**
 * @description This function is used to un-select/un-checked all the items in the grid view
 * and it will close detail panel of the previous selected item in grid view
 *
 * @param {Array} list This is an array of items
 * @param {boolean} clearSelection This flag is used to unselect the row selection as well.
 */
export const clearAllCheckedRows = (list, clearSelection = false) => {
  (list || []).forEach((row) => {
    if (isChildDataExists(row)) {
      clearAllCheckedRows(row.childData.response, clearSelection);
    }
    row.metaData = {
      ...row.metaData,
      [ListMetaEnum.IsRowCheckedOnCollapsedMode]: false,
      isChecked: false,
    };
    if (row.metaData.indeterminate) {
      row.metaData = { ...row.metaData, indeterminate: false };
    }

    if (clearSelection) {
      row.isSelected = false;
    }
  });
  return list;
};

/**
 * @description This function is used to select all the items in the grid view
 * and it will open detail panel of the first item in grid view if number of item is less than 500
 * and close the previous opened detail panel.
 *
 * @param list This is an array of items
 * @param {boolean} selectFirstItem This flag is used to check whether first item of row need to select or not.
 */
export const setAllCheckedRows = (list, selectFirstItem = false) => {
  (list || []).forEach((row, index) => {
    if (isChildDataExists(row)) {
      setAllCheckedRows(row.childData.response);
    }

    if (
      selectFirstItem &&
      index === 0 &&
      getCheckedRowsInfo(list).totalCheckedCount < MultiSelectValidation.MULTISELECTCOUNT
    ) {
      row.isSelected = true;
    }

    row.metaData = {
      ...row.metaData,
      // hierarchial row is checked in collapsed mode through select all
      [ListMetaEnum.IsRowCheckedOnCollapsedMode]: true,
      [ListMetaEnum.IsExpanded]: false,
      isChecked: true,
    };
  });

  return list;
};

export const setCheckboxSelection = (list: any = [], rowId = -1, isChecked = false, key: string) => {
  let count = 0;
  let row;
  (list || []).forEach((item) => {
    if (item.isChecked) {
      count = count + 1;
    }
    if (HelperLodash.get(item, key) === rowId) {
      row = item;
    }
  });

  if (row) {
    isChecked = count < MAX_SLECTION_COUNT && isChecked;
    row.metaData = { ...row.metaData, isChecked };
    if (row.metaData.indeterminate) {
      row.metaData = { ...row.metaData, indeterminate: false };
    }

    if (isSelectionPersistToChild(row)) {
      isChecked ? setAllCheckedRows(row.childData.response) : clearAllCheckedRows(row.childData.response);
    }
  }
  return list;
};

export const getExcludedIds = (list, key) => {
  return list.reduce((filter, row) => {
    if (row.metaData && !row.metaData.isChecked) {
      filter.push(HelperLodash.get(row, key));
    }
    return filter;
  }, []);
};

export const getExclusionCount = (list) => {
  let count = 0;
  list.forEach((item) => {
    if (item.metaData && !item.metaData.checked) {
      count += item.childCount + 1;
    }
  });
  return count;
};

export const getParentId = (item) => {
  if (!item.path) {
    return null;
  }
  const pathArr = item.path.split(".");
  return pathArr[pathArr.length - 1];
};

export const isHierarchialRow = (row) => {
  return row && row.childCount && row.metaData && !row.metaData.skipHierarchy;
};

export const getCheckedList = (list, prevSelectedListObj, metaKeys?) => {
  let checkedListInfo: ICheckedListInfo = {
    checkedRows: [...prevSelectedListObj.checkedRows],
    count: prevSelectedListObj.count,
    excludedIds: [...prevSelectedListObj.excludedIds],
    hierarchyRowsInfo: [...prevSelectedListObj.hierarchyRowsInfo],
    primaryRowIds: [...prevSelectedListObj.primaryRowIds],
  };
  (list || []).forEach((row) => {
    if ((row.metaData && row.metaData.isChecked) || isChildDataExists(row)) {
      if (isHierarchialRow(row)) {
        // row may not be checked but expanded then update its child info.
        checkedListInfo = checkedListForHierarchyRow(row, checkedListInfo, metaKeys);
      } else {
        checkedListInfo = updateForNonHierachialRowsInfo(checkedListInfo, row, metaKeys);
      }
    } else {
      updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.excludedIds);
    }
  });
  return checkedListInfo;
};

export const getMultiSelectPayloadForDelete = (checkedListInfo, isSelectAll, metaKeys) => {
  const multiSelectDeletePayload: ICheckedPayloadInfo = {
    Ids: [],
    excludedIds: [],
    hierarchyRowsInfo: [],
    selectAll: isSelectAll,
  };
  if (isSelectAll) {
    multiSelectDeletePayload.excludedIds = checkedListInfo.excludedIds;
  } else {
    multiSelectDeletePayload.Ids = checkedListInfo.primaryRowIds;
  }
  checkedListInfo.hierarchyRowsInfo.forEach((row) => {
    if (row.hasChildData && row.primaryRowIds.length) {
      multiSelectDeletePayload.hierarchyRowsInfo.push({
        [metaKeys.primaryRowIds]: row.primaryRowIds,
        id: row.id,
      });
    }
  });
  return multiSelectDeletePayload;
};

export const getMultiSelectPayload = (checkedListInfo, isSelectAll = false, metaKeys) => {
  const selectAllData: ICheckedPayloadInfo = {
    Ids: [],
    excludedIds: [],
    hierarchyRowsInfo: [],
    selectAll: isSelectAll,
  };
  checkedListInfo.hierarchyRowsInfo.forEach((row) => {
    if (row.hasChildData) {
      if (row.isRowCheckedOnCollapsedMode) {
        selectAllData.hierarchyRowsInfo.push({
          [metaKeys.primaryRowIds]: [],
          [metaKeys.excludedIds]: row.excludedIds,
          id: row.id,
          isSelectAll: row.isRowCheckedOnCollapsedMode,
        });
      } else {
        selectAllData.hierarchyRowsInfo.push({
          [metaKeys.excludedIds]: [],
          [metaKeys.primaryRowIds]: row.primaryRowIds,
          id: row.id,
          isSelectAll: row.isRowCheckedOnCollapsedMode,
        });
      }
    }
  });

  if (isSelectAll) {
    selectAllData.excludedIds = checkedListInfo.excludedIds;
  } else {
    selectAllData.Ids = checkedListInfo.primaryRowIds;
  }

  return selectAllData;
};

export const getMultiSelectPayloadAttributes = (checkedListInfo, isSelectAll, metaKeys) => {
  const payloadData = getMultiSelectPayload(checkedListInfo, isSelectAll, metaKeys);
  return mapMetaAttributes(payloadData, metaKeys);
};

export const getMultiSelectDeletePayloadAttributes = (checkedListInfo, isSelectAll, metaKeys) => {
  const payloadData = getMultiSelectPayloadForDelete(checkedListInfo, isSelectAll, metaKeys);
  return mapMetaAttributes(payloadData, metaKeys);
};

export const mapMetaAttributes = (payloadData, metaKeys) => {
  return {
    [metaKeys.primaryRowIds]: payloadData.Ids,
    [metaKeys.excludedIds]: payloadData.excludedIds,
    [metaKeys.hierarchyRows]: payloadData.hierarchyRowsInfo,
  };
};

export const getSelectAllCount = (totalCount = 0, checkedListInfo) => {
  if (checkedListInfo) {
    if (checkedListInfo.excludedIds) {
      totalCount = totalCount - checkedListInfo.excludedIds.length;
    }
    if (checkedListInfo.hierarchyRowsInfo) {
      checkedListInfo.hierarchyRowsInfo.forEach((row) => {
        totalCount = totalCount - row.excludedIds.length;
      });
    }
  }
  return totalCount;
};

/**
 * @description This is used to get total checked count with quantity items
 *
 * @param list array of row object
 * @param checkedListInfo array of selected row object
 */
export const getTotalCheckedCountWithQtyItems = (checkedRowsExcludingChildren, checkedListInfo) =>
  checkedListInfo.count + HelperLodash.sum(checkedRowsExcludingChildren, "totalQtyItemCount");

export const getCheckedRowsInfo = (list: any[] = [], metaKeys?, totalRecords?) => {
  const initialCheckedListInfo: ICheckedListInfo = {
    checkedRows: [],
    count: 0,
    excludedIds: [],
    hierarchyRowsInfo: [],
    primaryRowIds: [],
  };
  const checkedListInfo = getCheckedList(list, initialCheckedListInfo, metaKeys);
  const checkedRows = checkedListInfo.checkedRows;
  const checkedRowsExcludingChildren = getCheckedRowsExcludingChildren(list);

  return {
    checkedListInfo,
    checkedRows,
    checkedRowsExcludingChildren,
    checkedRowsIds: checkedRows.map((item) => item.id || item.assetId || item.allocationId),
    checkedRowscount: checkedListInfo.count,
    firstCheckedRow: checkedRows.length > 0 ? checkedRows[0] : null,
    isAnyRowChecked: checkedListInfo.count > 0,
    isMultipleRowChecked: checkedListInfo.count > 1,
    isOneRowChecked: checkedListInfo.count === 1,
    totalCheckedCount: getSelectAllCount(totalRecords, checkedListInfo),
    totalCheckedCountWithQtyItems: getTotalCheckedCountWithQtyItems(checkedRowsExcludingChildren, checkedListInfo),
    totalRecordCount: totalRecords,
  };
};

/**
 * @description This function is used to select/unslect and checked/unchecked item in the grid view
 *
 * @param rowList this is an arry of items in grid view
 * @param rowItem
 * @param isSelected A flag to determine whether item is checked/selected or not
 * @param key The primary key of an item
 * @param actionType
 */
export const setCheckboxWithRowSelection = (
  rowList: any = [],
  rowItem,
  isSelected = false,
  key: string,
  actionType = rowActionType.selected,
) => {
  handlePreviousSelections(rowList, rowItem, isSelected, actionType);
  const list = rowItem ? getListForPath(rowList, rowItem.path, key) : [];

  list.forEach((item) => {
    if (HelperLodash.get(item, key) === HelperLodash.get(rowItem, key)) {
      item.isSelected = isSelected;
      item.metaData = { ...item.metaData, isChecked: isSelected };

      if (isSelectionPersistToChild(item)) {
        isSelected ? setAllCheckedRows(item.childData.response) : clearAllCheckedRows(item.childData.response);
      }
    }
  });

  return list;
};

/**
 * @description This function is used to append asset/qty count
 *
 * @param locationDataList this is an array of locations
 * @param countList this is list of asset/qty count corresponding to location id
 */

export const appendAssetQtyCount = (
  locationDataList: ILocationsDataProps[] | IWorkerDetailsProps[],
  countList: IAssetQtyCountList[],
) => {
  return (locationDataList || []).map((element) => {
    const matchLocationCount = HelperLodash.find(countList, { id: element.id });
    element.assetsCount = matchLocationCount?.assetCount ?? 0;
    element.quantityItemCount = matchLocationCount?.qtyItemCount ?? 0;
    return element;
  });
};

export const getCountFields = (fields: string[]) => {
  const countFields = [];
  if (fields.includes("assetsCount") || fields.includes("assetsAssignedCount")) {
    countFields.push(FieldType.Asset);
  }
  if (fields.includes("quantityItemCount")) {
    countFields.push(FieldType.Qty);
  }
  return countFields;
};
