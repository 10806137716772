import React, { PropsWithChildren } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// @ts-ignore
import styled from "custom-styled-components";
// @ts-ignore
import ExpendIconUrl from "TARGET_BUILD/ui-assets/icons/expand.svg";
import IconBoldCount from "../../SingleRow/IconBoldCount/iconBoldCount";
import ICountExpansionPanelProps from "./iCountExpansionPanel";

/**
 * @const StyledExpansionPanel - Styling the Expansion Panel to fixed width of 380px
 */
const StyledExpansionPanel = styled(Accordion)`
  && {
    width: 100%;
    margin: 0;
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
    box-shadow: none;
    &:before {
      height: 0;
      margin: 0;
    }
  }
`;

/**
 * @const StyledExpansionPanelSummary - Styling the Expansion Panel Summary.
 */
const StyledExpansionPanelSummary = styled(AccordionSummary)`
  &&& {
    min-height: auto;
    padding: 0 16px;
    margin: 12px 0 12px 0;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 16px 0 0;
  }

  & .MuiAccordionSummary-content {
    margin: 0 16px 0 0;
    & > div {
      align-items: flex-start;
      padding: 0px;
    }
    & + .MuiIconButton-edgeEnd {
      align-self: flex-start;
      padding: 0;
      margin-right: 0px !important;
      width: 24px;
      height: 24px;
    }
    & > div > div:nth-child(2) {
      white-space: break-spaces;
      margin-right: 0;
      & > div {
        display: inline;
        padding-left: 4px;
      }
    }
  }
`;

/**
 * @const StyledExpansionPanelDetails - Styling the Expansion Panel Details.
 */
const StyledExpansionPanelDetails = styled(AccordionDetails)`
  && {
    display: flex;
    flex-direction: column;
    background: #fafafa;
    padding: 0;
  }
`;

/**
 * @description - This is a Count expansion panel that holds the summary and details panel.
 * Summary will hold IconBoldCount component in with the number of components rendered below will be listed in head.
 * By default the panel will be collapsed.
 *
 * @const CountExpansionPanel
 * @interface ICountExpansionPanelProps
 */
type ICountExpansionPanel = PropsWithChildren<ICountExpansionPanelProps>;

const CountExpansionPanel = (props: ICountExpansionPanel) => {
  const { panelId, children, showExpandIcon, defaultExpanded } = props;
  return (
    <StyledExpansionPanel id={panelId} defaultExpanded={defaultExpanded}>
      <StyledExpansionPanelSummary
        expandIcon={showExpandIcon && <img data-testid="expand-icon" src={ExpendIconUrl} alt="" />}
      >
        {renderSummary(props)}
      </StyledExpansionPanelSummary>
      <StyledExpansionPanelDetails>{children}</StyledExpansionPanelDetails>
    </StyledExpansionPanel>
  );
};

/**
 *
 * @param props - Properties for the IconBoldCount component.
 */
const renderSummary = (props: ICountExpansionPanelProps) => {
  const { headingId, iconUrl, boldId, boldText, countId, countText, isPanelOverlap } = props;
  return (
    <IconBoldCount
      panelId={headingId}
      iconUrl={iconUrl}
      boldId={boldId}
      boldText={boldText}
      countId={countId}
      countText={countText}
      displayCountInline={true}
      isPanelOverlap={isPanelOverlap}
    />
  );
};

/**
 * @export CountExpansionPanel - Count Expansion Panel.
 */
export default CountExpansionPanel;
