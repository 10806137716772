import { withStyles } from "@mui/styles";
import React from "react";
import Creatable from "react-select/lib/Creatable";
import { HelperLodash } from "../../helpers";
import { getDebounceValue } from "../../helpers/sharedUtils";
import { colorPalette } from "../../materials";
import { default as UITextField } from "../Field/field";
import LoaderElement from "../loader/loaderElement";
import getAutoCompleteComponents from "./autoCompleteComponents";
import AutoCompleteStyledComponent from "./autoCompleteStyledComponent";
import ICreatableAutoCompleteProps, { ICreatableAutoCompleteState } from "./iCreatableAutoCompleteProps";
import { AutoCompleteMenuType } from "./autoCompleteEnum";
import KeyCodes from "../../constant/keyCodes";
import ApplicationConstants from "../../constant/applicationConstants";

export enum CreatableAutoCompletePositionOptions {
  last = "last",
  first = "first",
}

const DEFAULTLIVESEARCHLIMIT = 20;

/**
 *
 * @param theme theme for the AutoComplete control
 */
const styles = (theme) => ({
  input: {
    display: "flex",
    height: 32,
    padding: 8,
  },
  noOptionsMessage: {
    color: `${colorPalette.hiltiRedError}`,
    fontSize: 16,
    padding: `${theme.spacing()}px ${theme.spacing(ApplicationConstants.NUMBER.NUM2)}px`,
  },
  placeholder: {},
  singleValue: {
    fontSize: 16,
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
  },
});

enum AutoCompleteActionEnum {
  "SELECT" = "select-option",
  "CREATE" = "create-option",
  "REMOVE" = "remove-value",
  "BACKSPACE" = "pop-value",
}

enum KeyBoardKeyEnum {
  "ENTER" = "Enter",
  "BACKSPACE" = "Backspace",
}

/***
 * CreatableAutoComplete provides a generic react autocomplete control that has functionality to add new options
 */
class CreatableAutoComplete extends React.PureComponent<ICreatableAutoCompleteProps, ICreatableAutoCompleteState> {
  static defaultProps = {
    createAbleOptions: true,
    defaultOptions: false,
    hasMore: (data, loadedOptions) => data.totalRecords > loadedOptions.length + data.response.length,
    hideInitialNoOption: false,
    isClearable: true,
    isCreatable: true,
    isSpaceAllowed: false,
    loadingMessage: () => <LoaderElement />,
    shouldDisplayOption: () => true,
    showClearIcon: true,
    disabledOptionWithTooltip: false,
    searchWithIncludes: false,
  };
  value;
  cretableAutoCompleteRef;
  state = {
    badgesMaxRange: this.props.badgesMaxRange || null,
    createTextRange: this.props.createTextRange
      ? { min: this.props.createTextRange[0], max: this.props.createTextRange[1] }
      : null,
    newOption: null,
  };

  getGridLabelField = (option) => {
    return this.props.menuGridInfo.menuGridDisplayProperties.map((element) => {
      if (element.includes("/")) {
        return HelperLodash.compact(element.split("/").map((ele) => HelperLodash.get(option.item, ele))).join(" ");
      } else {
        return HelperLodash.get(option.item, element);
      }
    });
  };
  getLabelField = (option) => {
    const { menuType, menuGridInfo } = this.props;

    if (menuType === AutoCompleteMenuType.GRID) {
      return menuGridInfo.getCustomLabel
        ? menuGridInfo.getCustomLabel(option.item)
        : HelperLodash.compact(this.getGridLabelField(option)).join("-");
    } else {
      return option.label;
    }
  };
  handleChange = (option: any): any => {
    let name = null;
    let labelField = null;
    let selectedValue = {
      id: undefined,
      value: undefined,
    };

    if (option) {
      name = option.__isNew__ ? "" : option.value;
      labelField = this.getLabelField(option);
      selectedValue = {
        ...option,
        id: option.value,
        value: labelField,
      };
    }

    this.props[this.props.name].input.onChange(name);
    this.props[this.props.labelField].input.onChange(labelField);

    if (this.props.onSelectChange) {
      this.props.onSelectChange(selectedValue, { isNew: option ? option.__isNew__ : false });
    }
  };

  formatOptions = (options, removedValue) => {
    return (options || []).reduce((resultArr, option) => {
      if (option !== removedValue) {
        resultArr.push({ label: option });
      }
      return resultArr;
    }, []);
  };

  getOptionArr = (option, metaInfo) => {
    const labelArr = this.props[this.props.labelField].input.value;
    return metaInfo.action === AutoCompleteActionEnum.REMOVE || metaInfo.action === AutoCompleteActionEnum.BACKSPACE
      ? this.formatOptions(labelArr, metaInfo.removedValue.label)
      : option;
  };
  getItemLabel = (item) => (item.label ? item.label.trim() : null);
  getItemValue = (item) => {
    if (item && item.value === 0) {
      item.value = "0";
    }
    return item.value ? String(item.value).trim() : null;
  };
  createTextRangeValidation = (createTextRange, itemTrim) => {
    if (
      (createTextRange && itemTrim.length >= createTextRange.min && itemTrim.length <= createTextRange.max) ||
      (!createTextRange && itemTrim.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleMultiChange = (option, metaInfo?, key?) => {
    const names = [];
    const labels = [];
    const { badgesMaxRange, createTextRange } = this.state;
    const { delimiter } = this.props;
    /*
      During backspace or remove of badge. Automatically option array is updated
      to new list. No need to handle explicitly for backspace and remove actions.
    */
    option.map((item) => {
      const itemLabel = this.getItemLabel(item);
      const itemValue = this.getItemValue(item);

      if (
        itemLabel.includes(delimiter) &&
        !(itemLabel.split(delimiter).filter((ele) => ele.length > createTextRange.max).length > 0)
      ) {
        const labelData = itemLabel.split(delimiter);
        const nameData = itemValue.split(delimiter);

        labelData.forEach((element) => {
          const itemTrim = element.trim();
          if (this.createTextRangeValidation(createTextRange, itemTrim)) {
            labels.push(itemTrim);
          }
        });

        nameData.forEach((element) => {
          const itemTrim = element.trim();
          if (this.createTextRangeValidation(createTextRange, itemTrim)) {
            names.push(itemTrim);
          }
        });
      } else if (!itemLabel.includes(delimiter)) {
        if ((createTextRange && itemLabel.length > 0 && itemLabel.length <= createTextRange.max) || !createTextRange) {
          names.push(itemValue ? itemValue : itemLabel);
          labels.push(itemLabel);
        }
      }
    });

    let uniqueLabel;
    let uniqueName;
    if (badgesMaxRange && labels.length > badgesMaxRange) {
      uniqueLabel = HelperLodash.uniqBy(labels, HelperLodash.toLower).slice(0, badgesMaxRange);
      uniqueName = HelperLodash.uniqBy(names, HelperLodash.toLower).slice(0, badgesMaxRange);
    } else {
      uniqueLabel = HelperLodash.uniqBy(labels, HelperLodash.toLower);
      uniqueName = HelperLodash.uniqBy(names, HelperLodash.toLower);
    }
    this.props[this.props.name].input.onChange(uniqueName);
    this.props[this.props.labelField].input.onChange(uniqueLabel);
    if (this.props.onSelectChange && (metaInfo.hasOwnProperty("action") || key !== KeyBoardKeyEnum.ENTER)) {
      this.props.onSelectChange(uniqueLabel, metaInfo);
    }
  };

  onBlur = () => {
    this.props[this.props.labelField].input.onBlur();
  };

  isValid = (e) => {
    const { delimiter } = this.props;
    let isValid = true;
    if (e.key === delimiter && e.target.value && e.target.value.trim().length > 0) {
      isValid = e.target.value.split(delimiter).filter((item) => item.length > 0).length > 0;
    } else if (e.key === delimiter) {
      isValid = false;
    }
    return isValid;
  };
  enterDelimiterPress = (e, delimiter) => e.key === KeyBoardKeyEnum.ENTER || e.key === delimiter;
  keyDown = (e, option) => {
    const { delimiter } = this.props;
    if (!this.enterDelimiterPress(e, delimiter)) {
      this.props.onSelectChange?.(option);
    }
    if (
      (option && option.length >= this.state.badgesMaxRange && e.key !== KeyBoardKeyEnum.BACKSPACE) ||
      !this.isValid(e)
    ) {
      e.preventDefault();
    } else if (e.target.value.trim().length > 0 && this.enterDelimiterPress(e, delimiter) && this.props.isCreatable) {
      this.handleObject(e, option);
    }
  };

  handleObject = (e, option) => {
    const requiredArray = option ? [...option] : [];
    const obj = { label: e.target.value, value: e.target.value, __isNew__: true };
    const differentValues = this.difference(obj.label, option);
    requiredArray.push(obj);
    if (
      requiredArray.filter((item) => item.label.toLowerCase() === e.target.value.toLowerCase()).length > 1 ||
      (differentValues && differentValues.length === 0)
    ) {
      e.target.value = "";
      e.preventDefault();
    }
  };

  difference = (inputValue, option) => {
    const { delimiter } = this.props;
    if (inputValue.includes(delimiter) && option && Array.isArray(option)) {
      const trimItemValueArray: any = HelperLodash.uniqBy(
        inputValue
          .split(delimiter)
          .map((item) => item.trim())
          .filter((item) => item.length > 0),
        HelperLodash.toLower,
      );

      return trimItemValueArray.filter(
        (ele) => !option.some((optionEle) => optionEle.label.toLowerCase() === ele.toLowerCase()),
      );
    }
    return null;
  };

  formatCreateLabel = (inputValue, option) => {
    const transKeyCreate = "common:CREATE";
    const differentValues = this.difference(inputValue, option);
    const { delimiter } = this.props;
    if (differentValues && differentValues.length === 1) {
      return `${this.props.t(transKeyCreate)} '${differentValues[0].trim()}'`;
    } else if (differentValues && differentValues.length > 1) {
      return `${this.props.t(transKeyCreate)} '${HelperLodash.uniqBy(differentValues, HelperLodash.toLower).join(
        delimiter,
      )}'`;
    } else {
      return `${this.props.t(transKeyCreate)} '${inputValue && inputValue.trim()}'`;
    }
  };

  fetchOptions = (input, additionalInfo) => {
    const searchMethod = this.props.liveSearch.searchMethod;
    return searchMethod(input, additionalInfo);
  };

  shouldTriggerSearching = (liveSearch, inputValue) =>
    liveSearch && (liveSearch.value || liveSearch.minCharacter <= inputValue.length);

  /**
   * @description - Function to create options.
   * @param {ISelectAutoCompleteProps} props - Props object.
   */
  createOptions = (data, infoText, inputValue) => {
    const options = data.map((item) => ({
      isDisabled: !!item.isDisabled,
      item,
      label: item[this.props.valueKey],
      value: item[this.props.valueId] && item[this.props.valueId].toString(),
    }));
    if (infoText && data.length > 0) {
      options.push({
        isInfo: true,
        label: infoText,
        value: -1,
      });
    }
    const errorMessage = this.props.inlineErrorMessage ? this.props.inlineErrorMessage(inputValue) : null;
    if (errorMessage && data.length > 0) {
      options.push({
        isError: true,
        label: errorMessage,
        value: -1,
      });
    }
    return options;
  };
  getOptions = (data, inputValue) => {
    if (data && data.response) {
      return this.props.createAbleOptions
        ? this.createOptions(data.response, data.infoText, inputValue)
        : data.response;
    }
    return [];
  };

  getDisabledOptionWithTooltip = (inputValue) => {
    const filteredOptions = inputValue
      ? this.props.data.filter((item) => {
          const value = item[this.props.valueKey];
          return value.toLowerCase().includes(inputValue.toLowerCase());
        })
      : this.props.data;
    return {
      options: this.createOptions(filteredOptions, false, inputValue),
    };
  };

  getDisableAndSearchValue = (inputValue) => {
    if (this.props.disabledOptionWithTooltip) {
      return this.getDisabledOptionWithTooltip(inputValue);
    } else {
      const searchBy = this.props.searchWithIncludes ? "includes" : "startsWith";
      const filteredOptions = inputValue
        ? this.props.options.filter((i) => i.label.toLowerCase()[searchBy](inputValue.toLowerCase()))
        : this.props.options;
      return {
        hasMore: false,
        options: filteredOptions,
      };
    }
  };

  /**
   * Function to filter the options which start with the input string
   */
  filterOptions = async (inputValue, loadedOptions) => {
    const { liveSearch } = this.props;
    const autoCompleteFieldName = this.props[this.props.name];
    let selectedValue = {};
    /*
      If autoComplete is not a redux form controk(isFormControl={false}) then
      input value is not created.
    */
    if (autoCompleteFieldName) {
      selectedValue = this.props[this.props.name].input.value;
    }
    if (this.shouldTriggerSearching(liveSearch, inputValue)) {
      const params: any = {};
      if (inputValue.length > 0 || liveSearch.value) {
        params.q = inputValue || liveSearch.value;
      }
      // page params
      if (liveSearch.isPaged) {
        params.offset = loadedOptions.length;
        params.limit = liveSearch.limit || DEFAULTLIVESEARCHLIMIT;
        params.loadedOptions = loadedOptions;
      }
      const data = await this.fetchOptions(params, { selectedValue, loadedOptions });
      return {
        additional: data && data.additional,
        hasMore: this.props.liveSearch.isPaged && this.props.hasMore(data, loadedOptions),
        options: this.getOptions(data, inputValue),
      };
    } else {
      return this.getDisableAndSearchValue(inputValue);
    }
  };
  hasDataError = (props) =>
    (props[props.name].meta.error && props[props.name].meta.touched) ||
    (props[props.labelField].meta.error && props[props.labelField].meta.touched);

  multiValueOption = (props) => {
    const option =
      props[props.labelField].input.value.length > 0 || props[props.name].input.value.length > 0
        ? props[props.labelField].input.value.map((item, index) => {
            return {
              label: item,
              value: props[props.name].input.value[index],
            };
          })
        : null;
    return option;
  };

  singleValueOption = (props) => {
    return props[props.labelField] &&
      (props[props.labelField].input.value.length > 0 || props[props.name].input.value.length > 0)
      ? {
          label: props[props.labelField].input.value,
          value: props[props.name].input.value,
        }
      : null;
  };
  noOptionValidation = (inputValue, selectedOption?) => {
    const { badgesMaxRange } = this.state;
    if (
      (inputValue && inputValue.inputValue.trim().length) ||
      (badgesMaxRange && selectedOption && selectedOption.length >= badgesMaxRange)
    ) {
      return this.props.noOptionsMessage(inputValue, selectedOption);
    }
    return this.props.hideInitialNoOption ? null : undefined;
  };

  getMultiValue = () => {
    return this.getValue(this.props) ? this.props[this.props.name].input.value.toString() : "";
  };
  getSingleValue = () => {
    return this.getValue(this.props)
      ? this.props[this.props.name].input.value.toString()
      : this.props[this.props.labelField].input.value;
  };

  handleKeyDown = (e) => {
    if (this.props.isSpaceAllowed && e.keyCode === KeyCodes.spacebar_key_code) {
      e.preventDefault();
      e.stopPropagation();
      this.cretableAutoCompleteRef.onInputChange(this.cretableAutoCompleteRef.state.inputValue + " ", "set-value");
    }
    return false;
  };

  isStyledComponent = () => {
    const props = this.props;
    let value = "";
    if (this.props.isFormControl) {
      value = props.isMulti ? this.getMultiValue() : this.getSingleValue();
    }

    let option;
    if (props.isMulti) {
      option = this.multiValueOption(props);
    } else {
      option = this.singleValueOption(props);
    }
    const key = props.key ? props.key : `${props.id}-${props.name}-${props.valueId}-${props.valueKey}`;
    return (
      <AutoCompleteStyledComponent
        key={JSON.stringify(key)}
        data-select="search-complete"
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        isLoading={false}
        selectRef={(ref) => {
          if (props.setRef) {
            props.setRef(ref);
          }
        }}
        ref={(ref) => {
          this.cretableAutoCompleteRef = ref;
        }}
        onChange={props.isMulti ? this.handleMultiChange : this.handleChange}
        value={option}
        autoComplete={props.autoComplete}
        className={props.className}
        id={props.id}
        inputId={props.name}
        name={props.name}
        error={props[props.name] && (props[props.name].meta.error || props[props.labelField].meta.error)}
        placeholder={props.placeholder}
        components={getAutoCompleteComponents(props)}
        data-searchable={props.autoComplete !== "off"}
        data-value={value.toString()}
        reduceOptions={props.reduceOptions}
        data-error={props[props.name] && this.hasDataError(props)}
        createOptionPosition={props.createOptionPosition || CreatableAutoCompletePositionOptions.last} // position of the creatable option in the  options list
        formatCreateLabel={(inputValue) => this.formatCreateLabel(inputValue, option)}
        isValidNewOption={props.isCreatable ? props.validateNewOptions : () => false}
        {...props}
        noOptionsMessage={(inputValue) => this.noOptionValidation(inputValue, option)}
        defaultOptions={props.defaultOptions}
        loadOptions={this.filterOptions}
        onBlur={props.required ? this.onBlur : null}
        SelectComponent={Creatable}
        debounceTimeout={getDebounceValue()}
        customComponents={props.customComponents}
        menuIsOpen={props.menuIsOpen}
        autoFocus={props.menuIsOpen}
        openMenuOnFocus={props.menuIsOpen}
        typeToSearchMsg={props.typeToSearchMsg}
        isMulti={props.isMulti || false}
        onKeyDown={props.isMulti ? (e) => this.keyDown(e, option) : (e) => this.handleKeyDown(e)}
        onFocus={props.onFocus}
        footerControl={props.footerControl}
      />
    );
  };
  render() {
    const props = this.props;
    return this.props.isFormControl ? (
      <UITextField
        name={props.name}
        htmlFor={props.name}
        required={props.required}
        label={props.label}
        error={props[props.name].meta.error || props[props.labelField].meta.error}
        touched={props[props.name].meta.touched || props[props.labelField].meta.touched}
        warning={props[props.name].meta.warning || props[props.labelField].meta.warning}
      >
        {this.isStyledComponent()}
      </UITextField>
    ) : (
      this.isStyledComponent()
    );
  }

  private getValue(props: Readonly<{ children?: React.ReactNode }> & Readonly<ICreatableAutoCompleteProps>) {
    return props[props.name].input.value >= 0
      ? props[props.name].input.value.toString()
      : props[props.name].input.value;
  }
}

export default withStyles(styles)(CreatableAutoComplete);
