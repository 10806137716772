/**
 * @description This file will contain all css style attribute
 */
const StyleAttrConstants = {
  HEIGHT: {
    H365: 365,
    H50: 50,
    H55: 55,
    H71: 71,
  },
  LEFT: {
    L350: 350,
  },
  OFFSET: {
    O200: 200,
    filterOffset: 312,
  },
  POSITION: {
    ABSOLUTE: "absolute",
    FIXED: "fixed",
  },
  WIDTH: {
    W10: 10,
    W40: 40,
    W16: 16,
    W2: 2,
    W3: 3,
    W300: 300,
    W332: 332,
    W992: 992,
  },
  ZINDEX: {
    Z999999: 999999,
  },
};

export default StyleAttrConstants;
