import { DropzoneProps } from "react-dropzone";
import { WithTranslation } from "react-i18next";

export enum DocumentType {
  Image = "Image",
  Attachment = "Attachment",
  templateImageId = "templateImageId",
}

export interface IResponseFormatMapper {
  readonly documentId?: string;
  readonly fileName?: string;
  readonly documentType?: string;
  readonly displayName?: string;
}

export interface IUploadComponentProps extends WithTranslation, DropzoneProps {
  readonly systemError?: string;
  allowDownloadAttachment?: boolean;
  readonly attachmentProperty?: any;
  readonly maxSizeTitle?: number;
  readonly name: string;
  readonly formName?: string;
  readonly uploadDocument?: any;
  readonly documentType: DocumentType;
  readonly label?: string;
  readonly change?: any;
  readonly onChange?: () => void;
  readonly clearData?: () => void;
  readonly allowedFileMessage: string;
  readonly uploadError?: any;
  readonly error?: any;
  readonly documentId?: string;
  readonly getDocument?: (documentId: string) => any;
  readonly documentKeys?: number[];
  readonly getDocumentById?: (documentId, showLoader?, type?: string, width?: string, height?: string) => void;
  readonly validations?: any[];
  readonly showToaster?: (type, msg) => any;
  readonly removeImage?: (formName, fieldName) => void;
  readonly resetUploadError?: (formName, fieldName) => void;
  readonly uploadImage?: any;
  readonly getAttachments?: (formName, fieldName) => any;
  readonly attachmentIdKey?: string;
  readonly isDisabled?: boolean;
  readonly disableActionOnAttachment?: boolean;
  readonly responseFormatMapper?: IResponseFormatMapper;
  readonly viewOnlyAttachment?: IViewAttachment[];
  readonly viewOnlyAttachmentLabelName?: string;
  readonly associatedEntity?: string;
  readonly thumbnailWidth?: string;
  readonly thumbnailHeight?: string;
  readonly isOnAssetsPage?: boolean;
}

export interface IViewAttachment {
  readonly fileName: number | string;
  readonly attachmentId: number | string;
  readonly displayName: number | string;
}
