import React from "react";
const SVG = () => (
  <svg width="16px" height="17px" viewBox="0 0 16 17" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2012.000000, -2700.000000)" fill="#524F53">
        <g transform="translate(2008.000000, 2697.000000)">
          <path
            d="M14,3.53512878 C15.1956027,4.22674762 16,5.51943529 16,7 L17,7 L17,8 L7,8 L7,7 L8,7 C8,5.51943529 8.80439726,4.22674762 10,3.53512878 L10,7 L11,7 L11,3.12601749 L11,3 L13,3 L13,3.12601749 L13,7 L14,7 L14,3.53512878 Z M8,9 L16,9 C16,11.209139 14.209139,13 12,13 C9.790861,13 8,11.209139 8,9 Z M8.9965099,14.0121996 L14.9899187,14.0121996 L20,15.9965149 L20,20 L4,20 L4,16 L8.9965099,14.0121996 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
