// @ts-ignore
import { DocumentActions } from "custom-actions";
import { arrayPush, change } from "redux-form";
import { all, put, takeEvery } from "redux-saga/effects";
import CommonApi from "../../../api/commonApi";
import { DocumentType } from "../../../components/upload/iUploadComponentProps";
import ImageTypeEnum from "../../../utils/enums/imageTypeEnum";
import { parseDocument } from "../../../utils/errorUtils";
import { call } from "../../../../common/sagas/common/commonSaga";
import { HelperLodash } from "am-web-ui-shared/helpers";

export function* uploadDocumentSaga() {
  yield takeEvery(DocumentActions.AM_UPLOAD_DOCUMENT, uploadDocument);
}

export function prepareDocResponse(action, result) {
  const { responseFormatMapper, documentAttachmentName } = action.payload;
  const response = HelperLodash.get(result, "response", {});
  if (responseFormatMapper) {
    const docResponse = { documentId: null, displayName: null, isNew: true, fileName: null };
    Object.entries(docResponse).forEach(([key, _value]) => {
      const valueInMapper = HelperLodash.get(responseFormatMapper, key);
      const responseValue = HelperLodash.get(response, key);
      if (valueInMapper) {
        HelperLodash.set(docResponse, valueInMapper, responseValue);
      } else {
        const docResponseKey = HelperLodash.get(docResponse, key);
        const newdocResponseKeyValue = responseValue ? responseValue : docResponseKey;
        HelperLodash.set(docResponse, key, newdocResponseKeyValue);
      }
    });
    return docResponse;
  }
  const docResponseObject = {
    displayName: "",
    fileName: response.fileName,
    isNew: true,
  };
  HelperLodash.set(docResponseObject, documentAttachmentName, response.documentId);
  return docResponseObject;
}

export function* uploadDocument(action) {
  yield put({
    type: DocumentActions.SET_DOCUMENT_ERROR_CLEAN,
  });
  const { formData, formName, config, documentType, showLoader, fieldName, callback, height, width, imageType } =
    action.payload;
  const response = yield call(() => {
    return CommonApi.uploadDocument(formData, config);
  });

  const result = yield parseDocument(response);

  if (result.success) {
    // Default value of showLoader is true(If showLoader is null or undefined).
    if (documentType === DocumentType.Image) {
      yield put({
        id: result.response.documentId,
        showLoader: showLoader === false ? showLoader : true,
        type: DocumentActions.AM_GET_DOCUMENT_URL,
        thumbnailHeight: height,
        thumbnailWidth: width,
        imageType,
      });
      yield put(change(formName, fieldName, result.response.documentId));
    } else {
      yield put({
        payload: result.response,
        type: DocumentActions.SET_DOCUMENT,
      });
      yield put(arrayPush(formName, fieldName, prepareDocResponse(action, result)));
    }

    if (callback) {
      yield callback(result.response.documentId);
    }
  } else {
    if (!result.isCancel && result.error && !result.isSystemError) {
      yield put({
        payload: { documentKey: documentType, error: `errorCodes:${result.error.code}` },
        type: DocumentActions.SET_DOCUMENT_ERROR,
      });
    }
  }
}

export function* getDocumentByIdSaga() {
  yield takeEvery(DocumentActions.AM_GET_DOCUMENT_URL, getDocumentById);
}

export function* getDocumentById(action) {
  yield put({
    type: DocumentActions.SET_DOCUMENT_ERROR_CLEAN,
  });

  const { id, thumbnailHeight, thumbnailWidth, showLoader, imageType } = action;
  const response = yield call(() => {
    return CommonApi.getDocumentById(id, showLoader, imageType, thumbnailWidth, thumbnailHeight);
  });

  if (response && !(response.error || response.errors)) {
    const result = yield parseDocument(response);

    if (result.success) {
      const urlCreator = window.URL || (window as any).webkitURL;
      const value = urlCreator.createObjectURL(result.response);
      yield put({
        payload: {
          documentId: action.id,
          imageType: action.imageType ? action.imageType : ImageTypeEnum.original,
          url: value,
        },
        type: DocumentActions.SET_DOCUMENT_URL,
      });
    } else {
      yield put({
        payload: {
          documentKey: DocumentType.Image,
          error: result.isSystemError ? null : `errorCodes:${result.error.code}`,
        },
        type: DocumentActions.SET_DOCUMENT_ERROR,
      });
    }
  }
}

function* composedDocumentSaga() {
  yield all([uploadDocumentSaga(), getDocumentByIdSaga()]);
}

export default composedDocumentSaga;
