export interface IAssignedServiceDetail {
  id: string;
  scheduleDate: string;
  completionDate: string;
  name: string;
  description: string;
  frequency: string;
  lastRecord?: boolean;
  notes: string;
  notificationNumber: number;
  notificationPeriod: INotificationInterface;
  cost: string;
  costCurrency: string;
  provider: string;
  serviceState: IServiceInterface;
  serviceType: IServiceInterface;
  serviceInterval: number;
  servicePeriod: IServiceInterface;
  serviceResult: IServiceInterface;
  isSystemService: boolean;
  serviceAttachments: IServiceAttachmentsInterface[];
}
export interface IServiceInterface {
  code: string;
  value: string;
}

export interface INotificationInterface {
  code: string;
  value: string;
}

export enum FILE_SOURCE {
  DM = "DM",
  CLOUD = "HC",
}
export interface IServiceAttachmentsInterface {
  serviceAttachmentId: string | number;
  displayName?: string | number;
  fileName: string | number;
  fileSource: FILE_SOURCE;
  path: string;
}
