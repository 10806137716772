import React from "react";
import styled from "styled-components";
import { HelperLodash } from "../../helpers";
import AccordionDetails from "./accordionDetails";

const HeadsDiv = styled.div`
  color: ${(props) => props.theme.colors.base.whiteFaded};
  border-bottom: 1px solid rgb(216, 216, 216);
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  padding-right: 16px;
  display: flex;
  padding: 10px 10px 12px 46px;
  background-color: ${(props) => props.theme.detailsPanel.detailBackground};
  p {
    width: 336px;
    &:first-child {
      width: 150px;
    }
  }
`;

const RowNo = styled.p``;
const Error = styled.p``;
const ErrorDetail = styled.p``;

const ViewMore = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  text-decoration: underline;
  line-height: 17px;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  padding-top: 10px;
  & span:hover {
    cursor: pointer;
  }
`;

export const getAccordionDetailsData = (item, errorDetail, t) => {
  return [
    {
      content: item.rowNo,
      style: {},
      type: "text",
    },
    {
      content: t(`errorCodes:${item.errorCode}`),
      style: {},
      title: t(`errorCodes:${item.errorCode}`),
      type: "text",
    },
    {
      content: errorDetail,
      style: {},
      type: "text",
    },
  ];
};

export const getFileErrorDetails = (fileErrorDetailObject) => {
  const { currObject, details, errorDetail, handleViewMore, initialRecordCount, nextShowMore, t, uid } =
    fileErrorDetailObject;
  const detailsUid = HelperLodash.get(details, uid);

  return details && detailsUid
    ? detailsUid.map((items, i) => (
        <React.Fragment key={i}>
          {i === 0 ? (
            <HeadsDiv>
              <RowNo>{t("common:ROW_NO")}</RowNo>
              <Error>{t("common:ERROR")}</Error>
              <ErrorDetail>{t("common:ERROR_DETAILS")}</ErrorDetail>
            </HeadsDiv>
          ) : null}
          {i <= initialRecordCount ? (
            <AccordionDetails key={i} index={i} accordionData={getAccordionDetailsData(items, errorDetail, t)} />
          ) : (
            <React.Fragment>
              {i > initialRecordCount && i === initialRecordCount + 1 ? (
                <ViewMore id={`viewMore${i}`}>
                  <span onClick={() => handleViewMore(nextShowMore, initialRecordCount, currObject, uid)}>
                    {`${t("common:VIEW_MORE")}(${nextShowMore})`}
                  </span>
                </ViewMore>
              ) : null}
            </React.Fragment>
          )}
        </React.Fragment>
      ))
    : null;
};
