import React from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import styled from "custom-styled-components";
import { Dialog, DialogContainer } from "am-web-ui-shared/components";
import { PieChartColor } from "TARGET_BUILD/common/utils/enums/styledEnum";
import { LocaleManager } from "am-web-ui-shared/helpers";
import CustomDaysFilter from "./customDaysFilter";
import { RootState } from "TARGET_BUILD/common/store/store";
import { useSelector } from "react-redux";
import { LoaderWrapper } from "am-web-ui-shared/elements";
import {
  IUtilizationDetailsChart,
  IUtilizationDetails,
  UtilizationBarChartData,
} from "./model/iUtilizationDetailsView";
import theme from "TARGET_BUILD/theme";
import { ValidationConstants } from "TARGET_BUILD/common/utils/enums/validationConstants";
import Chart, { BarSeriesOption, ComposeOption } from "@am/web-ui-components/extra/charts";
import { Footer } from "am-web-ui-shared/components";

/**
 * @description - This is the Utilization Details View section on the asset details panel.
 */

const BarChartFilterWrapper = styled.div`
  .multiSelectDropDown {
    margin-right: 0px;
  }
  width: 70%;
  form {
    display: flex;
  }
  #dateContainer {
    display: flex;
  }
  #DatePickerContainer {
    margin-left: 16px;
  }
`;
const BarWrapper = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  height: 70vh;
  background: ${(props) => props.theme.colors.base.white};
`;
const EmptyMessageWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UtilizationDetailsChart = (props: IUtilizationDetailsChart) => {
  const { t } = useTranslation();
  const dialogDetailLoader = useSelector(({ Loader }: RootState) => Loader?.dialogDetail);

  const {
    customDatefilterChange,
    customFilterValue,
    selectedStartDate,
    selectedEndDate,
    isBarChartVisible,
    onChangeStartDate,
    onChangeEndDate,
    closeBarChartDialog,
    barChartData,
  } = props;

  /**
   * Use to formatBarChartData and prvide data for stacked bar and axis of the charts
   * @param object contains barchartData
   */
  function formatBarChartData(barData: IUtilizationDetails) {
    const dailyUtilization = {
      date: [],
      workingTime: [],
      idleTime: [],
      engineOffTime: [],
    };
    barData?.utilization?.dailyUtilization?.forEach((singleBarData) => {
      dailyUtilization.date.push(LocaleManager.dateHelpers.getDateInTenantTimezone(singleBarData.date));
      dailyUtilization.workingTime.push(singleBarData.engineHours.workingTime / 3600);
      dailyUtilization.idleTime.push(singleBarData.engineHours.idleTime / 3600);
      dailyUtilization.engineOffTime.push(singleBarData.engineHours.engineOffTime / 3600);
    });
    return dailyUtilization;
  }
  const utilizationBarChartData: UtilizationBarChartData = formatBarChartData(barChartData);

  const formatHeader = () => {
    const currentDate = new Date();
    const headerStartDate =
      customFilterValue !== ValidationConstants.CUSTOM_DATES
        ? currentDate.setDate(currentDate.getDate() - Number(customFilterValue) + 1)
        : selectedStartDate;

    const headerEndDate = customFilterValue !== ValidationConstants.CUSTOM_DATES ? new Date() : selectedEndDate;

    return `${t("common:UTILIZATION")} ${"-"} ${LocaleManager.dateHelpers.getDateInTenantTimezone(headerStartDate)} ${t(
      "common:DATE_TO",
    )}  ${LocaleManager.dateHelpers.getDateInTenantTimezone(headerEndDate)}`;
  };

  const getSeries = () => {
    const series: BarSeriesOption[] = [
      {
        name: t("assets:WORKING_LABEL"),
        type: "bar",
        stack: "utilization",
        emphasis: {
          focus: "series",
        },
        data: utilizationBarChartData.workingTime,
        itemStyle: {
          color: PieChartColor.WORKING,
        },
      },
      {
        name: t("assets:ENGINE_LABEL"),
        type: "bar",
        stack: "utilization",
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: PieChartColor.ENGINE_HOUR,
        },
        data: utilizationBarChartData.engineOffTime,
      },
    ];

    if (!barChartData?.utilization?.hideIdle) {
      series.splice(1, 0, {
        name: t("assets:IDLE_LABEL"),
        type: "bar",
        stack: "utilization",
        emphasis: {
          focus: "series",
        },
        data: utilizationBarChartData.idleTime,
        itemStyle: {
          color: PieChartColor.IDLE,
        },
      });
    } else {
      const index = series.findIndex((item) => item.name === t("assets:IDLE_LABEL"));
      if (index > -1) {
        series.splice(index, 1);
      }
    }

    return series;
  };

  const options: ComposeOption<BarSeriesOption> = {
    tooltip: {
      backgroundColor: "#000",
      textStyle: {
        color: "white",
      },
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },

      formatter: (params) => {
        const values = params.map((param) => ({
          ...param,
          values: param.value,
        }));

        const getMinutes = (val) => {
          let minutes = (val - Math.trunc(val)) * 60;
          minutes = Math.round(minutes);
          return minutes;
        };

        const rows = values.map(
          (param) =>
            `${param?.seriesName}: ${Math.trunc(param.values)}${t("assets:AXIS_TICK_HOUR_FORMAT")} ${getMinutes(
              param.values,
            )}${t("assets:MIN")}`,
        );

        if (values.length) {
          return `${params[0].axisValue}<br /> ${rows.join("<br/>")}
          `;
        }
        return "";
      },
    },
    legend: {
      bottom: 10,
      icon: "square",
      itemHeight: 20,
      itemGap: 40,
      selected: {
        [t("assets:ENGINE_LABEL")]: false,
      },
      textStyle: {
        fontWeight: theme.fontNormal.fontWeight,
        fontFamily: theme.fontNormal.fontFamily,
        lineHeight: 15,
        fontSize: theme.fontSize.medium,
        padding: [0, 0, 0, 0],
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: utilizationBarChartData.date,
        axisTick: { alignWithLabel: true },
        showMaxLabel: true,
        showMinLabel: true,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: "{value} " + t("assets:AXIS_TICK_HOUR_FORMAT"),
        },
        max: 24,
        min: 0,
        interval: 8,
      },
    ],
    series: getSeries(),
  };
  return (
    <Dialog
      containerId="utilizationDetailContainer"
      headerId="headerUtilizationContainer"
      onClose={closeBarChartDialog}
      headerText={formatHeader()}
    >
      <DialogContainer id="barCharcontainerWrapper">
        <BarChartFilterWrapper>
          <CustomDaysFilter
            customDatefilterChange={customDatefilterChange}
            customFilterValue={customFilterValue}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            showLabels={isBarChartVisible}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
          />
        </BarChartFilterWrapper>

        <BarWrapper>
          {dialogDetailLoader && <LoaderWrapper loadingText={t("common:LOADING")} activity={true} />}
          {utilizationBarChartData.date.length === 0 ? (
            <EmptyMessageWrapper>{t("common:NO_DATA_UTILIZATION")}</EmptyMessageWrapper>
          ) : (
            !dialogDetailLoader && <Chart option={options} style={{ width: "100%", height: "100%" }} height={""} />
          )}
        </BarWrapper>
        <Footer buttonType="cta" buttonText={t("common:DONE")} onClick={closeBarChartDialog} />
      </DialogContainer>
    </Dialog>
  );
};

/**
 * @exports UtilizationDetailsChart - Utilization Details View  section that renders both header and detail components.
 */
export default UtilizationDetailsChart;
