import React from "react";
const SVG = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18,2 L18,8 L17.99,8 L18,8.01 L14,12 L18,16 L17.99,16.01 L18,16.01 L18,22 L6,22 L6,16.01 L6,16 L10,12 L6,8.01 L6.01,8 L6,2 L18,2 Z M12,13 L8,17 L8,20 L16,20 L16,17 L12,13 Z M12,15.5 L15,18 L15,19 L9,19 L9,18 L12,15.5 Z M16,4 L8,4 L8,7 L12,11 L16,7 L16,4 Z M14,8 L12,10 L10,8 L14,8 Z"
      fill="#524F53"
    />
  </svg>
);

export default SVG;
