import Grid from "@mui/material/Grid";
import { CurrencyInput, Input, Field, Radio } from "am-web-ui-shared/elements";
import React, { memo } from "react";
import { AssetCapType, CostCodeType } from "./costCodeTypeEnum";
import { maxLength, minValueValidator, rangeValidator, regexValidation, required } from "../../utils/errorUtils";
import { ValidationConstants } from "../../utils/enums/validationConstants";
import { GridLayout, SquareIcon, IconName } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import { FormSection } from "redux-form";
import { IAssetCapSettings, IRenderAssetCapValueProps, ICurrencyInputField } from "./iAssetCapSettings";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const INCORRECTVALTRANSCONST = "common:INCORRECT_VALUE_ENTERED";
const CHARGEASSETCAPSETTINGTRANS = "charges:ASSET_CAP_SETTINGS";
const MINASSETCHARGEVALUE = 0.01;
const MAXASSETCHARGEVALUE = 999.99;

const JobSiteChild = styled.div`
  margin-left: 32px;
  margin-top: 8px;
`;
const DailyCostWrapper = styled.div`
  margin-left: 32px;
  margin-top: 8px;
`;
const AlwaysFollowCapSettingContainer = styled.div`
  padding: 16px 0 10px 0;
`;
const AssetCapSettingContainer = styled.div`
  padding: 10px 0 16px 0;
`;
const WarningWrapperDiv = styled.div`
  display: flex;
`;
const TextWrapper = styled.div`
  vertical-align: super;
  display: inline-block;
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
`;

const ImageWrapper = styled.div`
  margin-right: 16px;
`;

export const AssetCapSettings = (props: IAssetCapSettings) => {
  const { companyOrLocation } = props;
  return (
    <FormSection name="assetCapSetting">
      {companyOrLocation ? <ShowDailyCostFieldForCompany {...props} /> : <ShowDailyCostFields {...props} />}
    </FormSection>
  );
};
export const ShowAlwaysFollowAssetCapSetting = (props) => {
  const { disabled, t, cappingType, change, isCapSettingNeverSet } = props;
  useEffect(() => {
    if (
      cappingType === AssetCapType.CAPPED_ASSET_VALUE ||
      (isCapSettingNeverSet && cappingType === AssetCapType.CAPPED_PER_LOCATION)
    ) {
      change("chargeSetting.assetCapSetting.alwaysFollowAssetCapSetting", true);
    }
  }, [cappingType]);

  return (
    <AlwaysFollowCapSettingContainer>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <Field
            htmlFor={t("charges:ALWAYS_FOLLOW_ASSET_CAP_SETTINGS")}
            label={t("charges:ALWAYS_FOLLOW_ASSET_CAP_SETTINGS")}
            required={true}
            name={"alwaysFollowAssetCapSetting"}
            getContent={() => t("charges:ALWAYS_FOLLOW_ASSET_CAP_SETTINGS_INFO")}
            place={"right"}
          >
            <Radio
              name={"alwaysFollowAssetCapSetting"}
              id={"dontFollowCapSettings"}
              radioLabel={t("common:NO")}
              radioValue={false}
              isFormControl={true}
              disabled={cappingType === AssetCapType.CAPPED_ASSET_VALUE ? true : disabled}
            />
            <Radio
              name={"alwaysFollowAssetCapSetting"}
              id={"followCapSettings"}
              radioLabel={t("common:YES")}
              radioValue={true}
              isFormControl={true}
              disabled={cappingType === AssetCapType.CAPPED_ASSET_VALUE ? true : disabled}
            />
          </Field>
        </Grid>
      </Grid>
    </AlwaysFollowCapSettingContainer>
  );
};

const showCappedAssetChargeValue = (props) => {
  const { cappingType, t } = props;
  return (
    cappingType &&
    cappingType !== AssetCapType.NOT_CAPPED && (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <Input
            name={"cappingPercentage"}
            label={t("charges:ASSET_CHARGE_VALUE_CAPPED_AT")}
            isFormControl={true}
            placeholder={"Ex. 60"}
            validation={[
              required(t, "charges:ASSET_CHARGE_VALUE_CAPPED_AT"),
              regexValidation(ValidationConstants.NUMBER_WITH_TWO_DECIMAL_PLACES, t(INCORRECTVALTRANSCONST)),
              rangeValidator(
                MINASSETCHARGEVALUE,
                MAXASSETCHARGEVALUE,
                t("common:RANGE_VALIDATION", { minValue: MINASSETCHARGEVALUE, maxValue: MAXASSETCHARGEVALUE }),
              ),
            ]}
            required={true}
          />
        </Grid>
      </Grid>
    )
  );
};
export const warningForCapType = (props) => {
  const { t, message } = props;
  return (
    <GridLayout mode={"col12"}>
      <WarningWrapperDiv>
        <ImageWrapper>
          <SquareIcon name={IconName.Warning} className="medium warning" imageSize={24} />
        </ImageWrapper>
        <TextWrapper>{t(`charges:${message}`)}</TextWrapper>
      </WarningWrapperDiv>
    </GridLayout>
  );
};
export const ShowDailyCostFieldForCompany = memo((props: IAssetCapSettings) => {
  const { cappingType, disabled, t } = props;
  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <Field
            htmlFor={t(CHARGEASSETCAPSETTINGTRANS)}
            label={t(CHARGEASSETCAPSETTINGTRANS)}
            required={true}
            name={"assetCapValue"}
          >
            <Radio
              name={"cappingType"}
              id={"assetNotCapped"}
              radioLabel={t("charges:NOT_CAPPED")}
              radioValue={AssetCapType.NOT_CAPPED}
              isFormControl={true}
              disabled={disabled}
            />
            <Radio
              name={"cappingType"}
              id={"cappedPerLocation"}
              radioLabel={t("charges:USE_ASSETS_CAP_SETTINGS")}
              radioValue={AssetCapType.CAPPED_ASSET_VALUE}
              isFormControl={true}
              disabled={disabled}
            />
            {cappingType && cappingType === AssetCapType.CAPPED_ASSET_VALUE && (
              <JobSiteChild>{warningForCapType({ t, message: "CAPPING_ASSET_VALUE_WARNING" })}</JobSiteChild>
            )}
            <Radio
              name={"cappingType"}
              id={"cappedAtAssetValue"}
              radioLabel={t("charges:CAPPED_PER_LOCATION_COMPANY")}
              radioValue={AssetCapType.CAPPED_PER_LOCATION}
              isFormControl={true}
              style={{ marginTop: "8px" }}
            />
            {cappingType && cappingType === AssetCapType.CAPPED_PER_LOCATION && (
              <JobSiteChild>
                {warningForCapType({ t, message: "CAPPING_ASSET_VALUE_WARNING" })}
                {showCappedAssetChargeValue(props)}
              </JobSiteChild>
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  );
});
export const ShowDailyCostFields = memo((props: IAssetCapSettings) => {
  const { cappingType, disabled, t } = props;
  return (
    <AssetCapSettingContainer>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Field
            htmlFor={t(CHARGEASSETCAPSETTINGTRANS)}
            label={t(CHARGEASSETCAPSETTINGTRANS)}
            required={true}
            name={"assetCapValue"}
            getContent={() => t("charges:ASSET_CAP_SETTINGS_INFO")}
            place={"right"}
          >
            <Radio
              name={"cappingType"}
              id={"assetNotCapped"}
              radioLabel={t("charges:NOT_CAPPED")}
              radioValue={AssetCapType.NOT_CAPPED}
              isFormControl={true}
              disabled={disabled}
            />
            <Radio
              name={"cappingType"}
              id={"cappedPerLocation"}
              radioLabel={t("charges:CAPPED_PER_LOCATION")}
              radioValue={AssetCapType.CAPPED_PER_LOCATION}
              isFormControl={true}
              disabled={disabled}
            />
            {cappingType && cappingType === AssetCapType.CAPPED_PER_LOCATION && (
              <JobSiteChild>{showCappedAssetChargeValue(props)}</JobSiteChild>
            )}
            <Radio
              name={"cappingType"}
              id={"cappedAtAssetValue"}
              radioLabel={t("charges:CAPPED_AT_ASSET_VALUE")}
              radioValue={AssetCapType.CAPPED_ASSET_VALUE}
              isFormControl={true}
              style={{ marginTop: "8px" }}
            />

            {cappingType && cappingType === AssetCapType.CAPPED_ASSET_VALUE && (
              <JobSiteChild>
                {warningForCapType({ t, message: "CAPPED_AT_PERCENT_VALUE_WARNING" })}
                {showCappedAssetChargeValue(props)}
              </JobSiteChild>
            )}
          </Field>
        </Grid>
      </Grid>
      {ShowAlwaysFollowAssetCapSetting(props)}
    </AssetCapSettingContainer>
  );
});

export const CurrencyInputField = (inputProps: ICurrencyInputField) => {
  const { name, label, currency, required: isFieldRequired } = inputProps;
  const { t } = useTranslation();

  const validations = [
    maxLength(t, ValidationConstants.MAX_LENGTH.NUM_12),
    regexValidation(ValidationConstants.NUMBER_WITH_TWO_DECIMAL_PLACES, t(INCORRECTVALTRANSCONST)),
    minValueValidator(ValidationConstants.MAX_LENGTH.NUM_0, t("common:VALIDATION_MIN_VALUE"), false),
  ];

  return (
    <DailyCostWrapper>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <CurrencyInput
            name={name}
            label={label}
            isFormControl={true}
            validation={!isFieldRequired ? validations : [...validations, required(t, "common:DAILY_ASSET_COST")]}
            currencyCode={currency?.code}
            required={isFieldRequired}
          />
        </Grid>
      </Grid>
    </DailyCostWrapper>
  );
};

export const RenderAssetCapValue = (props: IRenderAssetCapValueProps) => {
  const { t, cappingType, costTypeCode, currency, disabled } = props;
  const isRequired = costTypeCode === CostCodeType.DAILY && cappingType !== AssetCapType.NOT_CAPPED;
  const isRequiredValidate = isRequired ? [required(t, "common:ASSET_VALUE")] : ([] as any);
  const requiredArr = [
    regexValidation(ValidationConstants.NUMBER_WITH_TWO_DECIMAL_PLACES, t(INCORRECTVALTRANSCONST)),
    maxLength(t, ValidationConstants.MAX_LENGTH.NUM_12),
    minValueValidator(ValidationConstants.MAX_LENGTH.NUM_0, t("common:VALIDATION_MIN_VALUE"), false),
  ].concat(isRequiredValidate);
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={6}>
        <CurrencyInput
          name={"assetValue"}
          label={t("common:ASSET_VALUE")}
          isFormControl={true}
          required={isRequired}
          currencyCode={currency && currency.code}
          validation={requiredArr}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
