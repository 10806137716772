import { DidomiSDK } from "@didomi/react";
import { AuthorizationCore } from "am-web-ui-shared/helpers";
// @ts-ignore
import { ThemeProvider } from "custom-styled-components";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { SessionHandlerService } from "./common/api/sessionHandler";
import { refreshToken } from "./common/components/header/actions/refreshTokenActionRoot";
import { userData, userMeData } from "./common/actions/userProfileActionRoot";
import { getTransferConfirmationSettings, getCompanyDetails } from "./common/modules/company/actions/companyActionRoot";
import { getFieldRequestSettings } from "./common/modules/fieldRequest/settings/actions/fieldRequestSettingsActions";
import { getUserSetting } from "./common/modules/userSetting/actions/userSettingActionRoot";
import { getDidomiApiKey } from "./common/utils/configUtil";
import { storageGetItem } from "./common/utils/storageUtils";
import translate from "./hoc/translate";
import SessionProps from "./models/iSessionProps";
import ModulePermissions from "./modulePermissions";
import Root from "./root";
import theme from "./theme";
import SingletonFeatureManager from "./featureFlagManager/featureFlagManager";
import { getTimeBasedEnabledTenantList } from "./common/modules/masterData/masterDataActionCreator";
import { canUserViewChargeSettings } from "./common/models/charges/chargesOrchestration";
import { ThemeProvider as LegacyMUIThemeProvider } from "@mui/styles";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { LicenseContext } from "TARGET_BUILD/licenseStrategy/licenseContext";
import SingletonLicenseContext from "TARGET_BUILD/licenseStrategy/singletonLicenseContext";
import { FEATURES } from "./featureFlagManager/featureConfig";
import LicenseTypes from "TARGET_BUILD/common/utils/enums/licenseTypesEnum";
import TrackUserDataDetails from "./analytics/hooks/UserDataDetails";
import { GoogleMapProvider } from "./googleMapProvider";
import { LicenseBundleTypes } from "./common/utils/enums/licenseBundleEnum";

export class App extends React.Component<
  SessionProps,
  { isFeaturesInit: boolean; appContextInit: boolean; licenseContext: LicenseContext }
> {
  appLicense: LicenseTypes = storageGetItem(appConstants.LICENSE_TYPE) as LicenseTypes;
  appLicenseBundle: LicenseBundleTypes = storageGetItem(appConstants.LICENSE_BUNDLE) as LicenseBundleTypes;
  constructor(props) {
    super(props);
    this.state = { isFeaturesInit: false, appContextInit: false, licenseContext: null };
    if (process.env.NODE_ENV !== "development") {
      const sessionAPI = new SessionHandlerService();
      sessionAPI.initListener();
      sessionAPI.setInitialInterval(this.props.refreshToken);
    }
    const appTenantCategory = storageGetItem(appConstants.TENANT_CATEGORY);
    const moduleFeatures = storageGetItem(appConstants.MODULE_FEATURES);
    if (this.appLicense) {
      this.initializeLicense(this.appLicense, appTenantCategory, moduleFeatures, this.appLicenseBundle);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { employeeId, tenantCategory, getTransferConfirmationSettings, getFieldRequestSettings, moduleFeatures } =
      this.props;

    if (employeeId && !this.state.isFeaturesInit) {
      SingletonFeatureManager.initFeatures("ontrack", this.props.employeeId)
        .then(() => {
          this.setState({ isFeaturesInit: true }); // to trigger re-render
        })
        .catch((_error) => {
          //handle error
        });
    }

    const isEnterpriseTenantFeatureEnabled = SingletonFeatureManager.isFeatureEnabled(FEATURES.ENTERPRISE_TENANT);
    if (!isEnterpriseTenantFeatureEnabled) {
      return;
    }

    if (!prevProps.tenantCategory && tenantCategory) {
      this.initializeLicense(this.appLicense, tenantCategory, moduleFeatures, this.appLicenseBundle);
    }

    // the apis should be called only after license
    // context is initialised because FM context does
    // not have access to these
    if (!prevState.licenseContext?.hasRestrictedApiAccess() && this.state.licenseContext?.hasRestrictedApiAccess()) {
      getTransferConfirmationSettings();
      getFieldRequestSettings();
    }
  }

  componentDidMount() {
    const { getUserSetting, userData, userMeData, getCompanyDetails, getTimeBasedEnabledTenantList } = this.props;
    if (storageGetItem(appConstants.USER_TOKEN) || process.env.NODE_ENV === "development") {
      const userRole: string = storageGetItem(appConstants.USER_ROLE);
      getCompanyDetails();
      getUserSetting();
      userData();
      userMeData();

      AuthorizationCore.setup(storageGetItem("userPermissions"), userRole, ModulePermissions);
      if (canUserViewChargeSettings()) {
        getTimeBasedEnabledTenantList();
      }
    } else {
      getUserSetting("SET_DEFAULT");
    }
  }

  initializeLicense(license, tenantCategory, moduleFeatures, licenseBundle) {
    SingletonLicenseContext.licenseContext = null;
    SingletonLicenseContext.initLicenseContext({ license, tenantCategory, moduleFeatures, licenseBundle })
      .then(() => this.setState({ appContextInit: true, licenseContext: SingletonLicenseContext.getAssetsContext() }))
      .catch(() => null);
  }

  render() {
    const { languageCode } = this.props;
    const { appContextInit, licenseContext } = this.state;

    return (
      <>
        {storageGetItem(appConstants.USER_TOKEN) && <DidomiSDK apiKey={getDidomiApiKey()} gdprAppliesGlobally={true} />}
        <Router>
          <LegacyMUIThemeProvider theme={theme}>
            <MUIThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <GoogleMapProvider>
                  {languageCode ? <Root appContextInit={appContextInit} licenseContext={licenseContext} /> : null}
                </GoogleMapProvider>
                {this.state.isFeaturesInit ? <TrackUserDataDetails /> : null}
              </ThemeProvider>
            </MUIThemeProvider>
          </LegacyMUIThemeProvider>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageCode:
    state.UserSetting && state.UserSetting.userSettingData && state.UserSetting.userSettingData.applyLanguageCode,
  employeeId: state.UserReducer?.userMeData?.workerId,
  tenantCategory: state.Company?.companyDetails?.tenantCategory,
  userRole: state.UserReducer?.userMeData?.userRole?.value,
  email: state.UserReducer?.userMeData?.email,
  customerId: state.Company?.companyDetails?.originCustomerId,
  countryCode: state.Company?.companyDetails?.country?.code,
  displayName: state.Company?.companyDetails?.displayName,
  moduleFeatures: state.Company?.companyDetails?.moduleFeatures,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyDetails: () => dispatch(getCompanyDetails()),
  getTransferConfirmationSettings: () => dispatch(getTransferConfirmationSettings()),
  getUserSetting: (type) => dispatch(getUserSetting(type)),
  refreshToken: () => dispatch(refreshToken()),
  userData: () => dispatch(userData()),
  userMeData: () => dispatch(userMeData()),
  getFieldRequestSettings: () => dispatch(getFieldRequestSettings()),
  getTimeBasedEnabledTenantList: () => getTimeBasedEnabledTenantList(),
});
export default connect(mapStateToProps, mapDispatchToProps)(translate(App));
