import { HelperLodash } from "../../helpers";

/**
 * @description - Function to get object property value from list of objects.
 * @param list (Array) - List of objects.
 * @param value (string | number) - Value of object key to match.
 * @param key1 (string) - Object property to match with passed value. Default property is code.
 * @param key2 (string) - Object property to return value. Default property is value.
 */
export const getListObjectValue = (list, value: string | number, key1 = "code", key2 = "value") => {
  const obj = list && list.find((column: object) => HelperLodash.get(column, key1) === value);
  return obj && HelperLodash.get(obj, key2);
};

/**
 * @description To filter array with unique value
 * @param {array} array with nested object.
 */

export const uniqueArray = (array) => {
  return HelperLodash.uniqWith(array, HelperLodash.isEqual);
};
