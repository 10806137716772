import React from "react";
import { StyledTextType } from "../../../../utils/enums/styledEnum";
import INormalBoldIconProps from "./iNormalBoldIcon";

import {
  StyledAfterIconContainer,
  StyledBoldText,
  StyledInnerBoldText,
  StyledNormalText,
  StyledRowsWrapper,
} from "../../index";

/**
 * @description - Styled Component that consist of 3 things:
 * 1. Icon: An Icon that will be visible at the right side of the content (::After).
 * 2. Normal Text: This is the text with normal font-familiy and it is the top row.
 * 3. Bold Text: This is the text with bold font-familiy and it is the bottom row.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 *
 * @const NormalBoldIcon
 * @interface INormalBoldIconProps
 */
const NormalBoldIcon = (props: INormalBoldIconProps) => {
  const {
    id,
    iconUrl,
    normalId,
    normalText,
    boldId,
    boldText,
    className,
    type = StyledTextType.INNER_TEXT,
    onIconClick,
  } = props;
  return (
    <StyledAfterIconContainer id={id} iconUrl={iconUrl} onIconClick={onIconClick} className={className}>
      <StyledRowsWrapper>
        <StyledNormalText id={normalId}>{normalText}</StyledNormalText>
        {type === StyledTextType.INNER_TEXT ? (
          <StyledInnerBoldText data-testid="styledInnerBoldText" id={boldId}>
            {boldText}
          </StyledInnerBoldText>
        ) : (
          <StyledBoldText data-testid="styledBoldText" id={boldId}>
            {boldText}
          </StyledBoldText>
        )}
      </StyledRowsWrapper>
    </StyledAfterIconContainer>
  );
};

/**
 * @export NormalBoldIcon - Styled Component.
 */
export default NormalBoldIcon;
