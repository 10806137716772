import { fieldResolver } from "../../utils/commonUtils";
import { AssetSubType, AssetType } from "../../utils/enums/assetEnum";
import {
  IChildrenDetails,
  IFleetAssetDetails,
  ILoanedAssetDetails,
  ILocationParentDetails,
  ICodeValue,
  IOwnedAssetDetails,
  IParentDetails,
  IRentedAssetDetails,
  IResponsibleEmployeePhone,
  IGeoTagDetails,
} from "./assetModel";

export enum ScanCodeSource {
  SAP = "SAP",
  MANUAL = "MANUAL",
  MIGRATION = "MIGRATION",
  IMPORT = "IMPORT",
}

export default class AssetDetail {
  assetImageId: string;
  assetAttachments: any[];
  notes: string;
  manufacturerId: string;
  extAssetId: string;
  manufacturerName: string;
  groupId: number;
  storageLocation: string;
  ownerId: number;
  assetUsageConditionDate: string;
  inventoryNumber: string;
  responsibleEmployeePhone: IResponsibleEmployeePhone[];
  scanCodeTypeName: string;
  model: string;
  assetId: number;
  inventoryNumberTypeName: string;
  currentLocationState: string;
  serialNumber: string;
  previousSerialNumber: string;
  replacementSerialNumber: string;
  responsibleEmployeeId: number;
  assetTemplateId: number;
  currentLongitude: number;
  responsibleEmployeeDesignation: string;
  ownedAssetDetails: IOwnedAssetDetails;
  groupName: string;
  lastSeen: string;
  labelNames: string[];
  fleetAssetDetails: IFleetAssetDetails;
  name: string;
  usageStateName: string;
  loanedAssetDetails: ILoanedAssetDetails;
  currentLocationId: number;
  responsibleEmployeeEmail: string;
  statusCode: string;
  assetStateCode: string;
  assetTemplateName: string;
  inventoryNumberTypeCode: string;
  description: string;
  currentLocationName: string;
  categoryName: string;
  ownerShipType: string;
  ownerName: string;
  defaultLocationName: string;
  statusName: string;
  rentedAssetDetails: IRentedAssetDetails;
  defaultLocationId: number;
  ownershipName: string;
  responsibleEmployeeLanguage: string;
  usageStateCode: string;
  assetStateName: string;
  categoryCode: string;
  scanCode: string;
  costCode: string;
  tenantId: number;
  currentLatitude: number;
  responsibleEmployeeName: string;
  scanCodeType: string;
  replacementCost: string;
  assetTypeCode: AssetType;
  assetSubTypeCode: AssetSubType;
  childrenDetails: IChildrenDetails;
  hiltiIntegratedAsset: boolean;
  owner: string;
  hiltiAssetOwnerName: string;
  isSapAsset: boolean;
  hiltiAssetStatus: string | undefined;
  hiltiAssetStatusValue: string | undefined;
  defaultLocationTypeDetails: ICodeValue;
  defaultWorkerId: number;
  defaultWorkerName: string;
  defaultParentId: number;
  defaultParentName: string;
  currentLocationTypeDetails: ICodeValue;
  parentId: number;
  parentDetails: IParentDetails;
  lastTransferDate: string;
  defaultLocationParentDetails: ILocationParentDetails;
  currentLocationParentDetails: ILocationParentDetails;
  scanCodeSource?: ScanCodeSource;
  isTrackable: boolean;
  geoTagDetails: IGeoTagDetails;
  constructor(response: any) {
    this.notes = response.notes;
    this.manufacturerName = response.manufacturerName;
    this.manufacturerId = response.manufacturerId;
    this.extAssetId = response.extAssetId;
    this.groupId = response.groupId;
    this.groupName = response.groupName;
    this.storageLocation = response.storageLocation;
    this.ownerId = response.ownerId;
    this.assetUsageConditionDate = response.assetUsageConditionDate;
    this.inventoryNumber = response.inventoryNumber;
    this.scanCodeTypeName = response.scanCodeTypeName;
    this.model = response.model;
    this.assetId = response.assetId;
    this.inventoryNumberTypeName = response.inventoryNumberTypeName;
    this.currentLocationState = response.currentLocationState;
    this.serialNumber = response.serialNumber;
    this.previousSerialNumber = response.previousSerialNumber;
    this.replacementSerialNumber = response.replacementSerialNumber;
    this.responsibleEmployeeId = response.responsibleEmployeeId;
    this.assetTypeCode = response.assetTypeResponse && response.assetTypeResponse.code;
    this.assetSubTypeCode = response?.assetSubType?.code;

    this.childrenDetails = response.childrenDetails;
    /**
     * if we change the template or creating new one this props should be null.
     * Api use this props to decide template is new or edit asset with new template
     * if we give null it will handel both the cases
     */
    this.assetTemplateId = response.assetId ? null : response.assetTemplateId;
    this.currentLongitude = response.currentLongitude;
    this.responsibleEmployeeDesignation = response.responsibleEmployeeDesignation;
    this.lastSeen = response.lastSeen;
    this.name = response.name;
    this.usageStateName = response.usageStateName;
    this.currentLocationId = response.currentLocationId;
    this.responsibleEmployeeEmail = response.responsibleEmployeeEmail;
    this.statusCode = fieldResolver(response.assetStatusResponse, response.statusCode, "code");
    this.assetStateCode = fieldResolver(response.assetStateResponse, response.assetStateCode, "code");
    this.assetTemplateName = response.assetTemplateName;
    this.inventoryNumberTypeCode = response.inventoryNumber
      ? fieldResolver(response.scanCodeTypeDetails, response.scanCodeType, "code")
      : "";
    this.description = response.description;
    this.currentLocationName = response.currentLocationName;
    this.categoryName = response.categoryName;
    this.ownerShipType = fieldResolver(response.ownerShipTypeResponse, response.ownerShipType, "code");
    this.ownerName = response.ownerName;
    this.defaultLocationName = response.defaultLocationName;
    this.statusName = response.statusName;
    this.defaultLocationId = response.defaultLocationId;
    this.ownershipName = response.ownershipName;
    this.responsibleEmployeeLanguage = response.responsibleEmployeeLanguage;
    this.usageStateCode = fieldResolver(response.assetUsageConditionState, response.usageStateCode, "code");
    this.assetStateName = response.assetStateName;
    this.categoryCode = fieldResolver(response.assetCategoryResponse, response.categoryCode, "code");
    this.scanCode = response.scanCode;
    this.costCode = response.costCode;
    this.tenantId = response.tenantId;
    this.currentLatitude = response.currentLatitude;
    this.responsibleEmployeeName = response.responsibleEmployeeName;
    this.scanCodeType = response.scanCode
      ? fieldResolver(response.scanCodeTypeDetails, response.scanCodeType, "code")
      : "";
    this.responsibleEmployeePhone = [];
    this.ownedAssetDetails = response.ownedAssetDetails;
    this.fleetAssetDetails = response.fleetAssetDetails;
    this.loanedAssetDetails = response.loanedAssetDetails;
    this.rentedAssetDetails = response.rentedAssetDetails;
    this.labelNames = response.labelNames;
    this.replacementCost = response.replacementCost;
    this.assetImageId = response.assetImageId;
    this.assetAttachments = response.assetAttachments;
    this.hiltiIntegratedAsset = response.hiltiIntegratedAsset;
    this.owner = response.owner;
    this.hiltiAssetOwnerName = response.hiltiAssetOwnerName;
    this.isSapAsset = response.isSapAsset;
    this.hiltiAssetStatus = response.hiltiAssetStatus && response.hiltiAssetStatus.code;
    this.hiltiAssetStatusValue = response.hiltiAssetStatus && response.hiltiAssetStatus.value;
    this.defaultLocationTypeDetails = response.defaultLocationTypeDetails;
    this.defaultWorkerId = response.defaultWorkerId;
    this.defaultWorkerName = response.defaultWorkerName;
    this.defaultParentId = response.defaultParentId;
    this.defaultParentName = response.defaultParentName;
    this.currentLocationTypeDetails = response.currentLocationTypeDetails;
    this.parentId = response.parentId;
    this.parentDetails = response.parentDetails;
    this.lastTransferDate = response.lastTransferDate;
    this.defaultLocationParentDetails = response.defaultLocationParentDetails;
    this.currentLocationParentDetails = response.currentLocationParentDetails;
    this.scanCodeSource = response.scanCodeSource;
    this.isTrackable = response.isTrackable;
    this.geoTagDetails = response.geoTagDetails;
  }
}
