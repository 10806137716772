export enum LocationTypeEnum {
  WAREHOUSE_ACTIVE = "WAREHOUSE_ACTIVE",
  WAREHOUSE_INACTIVE = "WAREHOUSE_INACTIVE",
  JOBSITE_ACTIVE = "JOBSITE_ACTIVE",
  JOBSITE_INACTIVE = "JOBSITE_INACTIVE",
  LOCATION_GROUP = "LOCATION_GROUP",
  LOCATION_GROUP_INACTIVE = "LOCATION_GROUP",
  LOCATION_ACTIVE = "CUSTOM_LOCATION_ACTIVE",
  LOCATION_INACTIVE = "CUSTOM_LOCATION_INACTIVE",
}

export enum LocationState {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum LocationKeys {
  id = "id",
  primaryRowIds = "locationIds",
  excludedIds = "excludedIds",
}

export enum targetType {
  LOCATION = "LOCATION",
  STORAGE_ASSET = "STORAGEASSET",
  WORKER = "WORKER",
}

export enum LoactionsStateEnum {
  Success = "SUCCESS",
  Loading = "LOADING",
  Failure = "FAILURE",
}

export enum FieldType {
  Asset = "ASSET",
  Qty = "QTY",
}
