import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3532.000000, -612.000000)" fill="#524F53">
        <g transform="translate(3530.000000, 610.000000)">
          <path d="M11,5 L12.108,9.984 L19.5,6 L20.5,7.5 L22.421,14.483 L22.4360158,14.479825 L21.4557816,17.8160657 L19.5151901,18.2999095 L15.8785373,16.114788 L20.673,14.918 L19,9.5 L13,14 L13,14 L11,14 L11,15 L12.5,15 C13.3284271,15 14,15.6715729 14,16.5 C14,17.2796961 13.4051119,17.9204487 12.64446,17.9931334 L12.5,18 L3.5,18 L3.35553999,17.9931334 C2.59488808,17.9204487 2,17.2796961 2,16.5 C2,15.7203039 2.59488808,15.0795513 3.35553999,15.0068666 L3.5,15 L5,15 L5,14 L2,14 L2,9 L5,9 L5,5 L11,5 Z M3.5,16 C3.22385763,16 3,16.2238576 3,16.5 C3,16.7761424 3.22385763,17 3.5,17 C3.77614237,17 4,16.7761424 4,16.5 C4,16.2238576 3.77614237,16 3.5,16 Z M12.5,16 C12.2238576,16 12,16.2238576 12,16.5 C12,16.7761424 12.2238576,17 12.5,17 C12.7761424,17 13,16.7761424 13,16.5 C13,16.2238576 12.7761424,16 12.5,16 Z M9.5,7 L7,7 L7,9 L10,9 L9.5,7 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
