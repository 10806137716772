import TextField from "@mui/material/TextField";
import React from "react";
import styled from "styled-components";
import HelperLodash from "../../../../helpers/helperLodash";
import ControlType from "../../controlType";
import { getIntersectionOptions } from "../../filterUtils";
import { IMultiSelectInputValueProps } from "./iMultiSelectDropDownProps";

/**
 * styles for the TextField in react-select
 */
export const TextFieldStyled = styled(TextField)`
  &.typeselect {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete30};
    color: ${(props) => props.theme.colors.base.steel};
    height: 48px;
    cursor: pointer;
    &.menu-is-open {
      background-color: ${(props) => props.theme.colors.base.white};
    }
    &.selected {
      background-color: ${(props) => props.theme.colors.base.steel};
      border: 0;
      & .multivalue {
        color: ${(props) => props.theme.colors.base.white} !important;
      }
      .multiValueInput {
        height: 48px;
      }
      svg polygon {
        fill: #ffffff;
      }
    }
    &.disabled {
      opacity: 0.5;
    }

    > div:last-child {
      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
      &:hover {
        border: 0;
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }
      }
      > div {
        padding: 0;
        height: 44px;
        width: 100%;
        display: flex;
        cursor: pointer;
        padding: 0 36px 0 16px;
      }
      span {
        background-color: transparent;
      }
    }
    & .multiValueInput {
      > div:first-child {
        padding: 0px;
        width: 100%;
        overflow: visible;
        flex: 0 0 auto;
        font-family: ${(props) => props.theme.fontRoman.fontFamily};
        & > div:first-child {
          width: 100%;
          margin: 0;
          color: ${(props) => props.theme.colors.base.whiteFaded};
          text-wrap: nowrap;
        }
        & .multivalue {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 38px;
          & + div {
            /*  to make the input invisible */
            height: 0;
            line-height: 0;
            opacity: 0;
            width: 0;
            padding: 0;
            margin: 0;
          }
        }
        & > div:last-child {
          margin: 0;
          input {
            opacity: 0 !important;
            cursor: pointer;
            width: 0.01px !important;
            /* to remove hidden input from flex order */
            position: absolute;
          }
        }
      }
      > div:last-child {
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
      }
    }
    .dropDownPlaceholder {
      white-space: nowrap;
      padding: 12px 0;
    }
    & + div > div {
      margin: 0;
      position: absolute;
      z-index: 999999;
    }
  }
`;
/**
 * inputComponent return the div
 */
const inputComponent = React.forwardRef<HTMLDivElement, { name: string }>((props, inputRef) => {
  return <div id={props.name} className="inputComponent" ref={inputRef} {...props} />;
});

/**
 * MultiSelectInputValue compoenent to execute the input field in react-select
 */
export class MultiSelectInputValue extends React.PureComponent<IMultiSelectInputValueProps> {
  getClassNameForMultiSelectDropdown = (inputProps) => {
    const { selectProps } = inputProps;
    const { type } = selectProps;
    const appliedOptions = HelperLodash.get(selectProps, "appliedOptions", []);
    const selectedValue = HelperLodash.get(selectProps, "selectedValue", []);
    const allOptions = HelperLodash.get(selectProps, "options", []);
    const appliedIntersectionOptions = getIntersectionOptions(appliedOptions, allOptions) || [];
    const hasAppliedOptions = selectProps.searchValue
      ? appliedOptions.length > 0
      : (type === ControlType.RadioSelectDropdown && selectedValue.length > 0) || appliedIntersectionOptions.length > 0;
    switch (true) {
      case hasAppliedOptions:
        return "typeselect selected";
      case inputProps.isDisabled:
        return "typeselect disabled";
      case inputProps.menuIsOpen:
        return "typeselect menu-is-open";
      default:
        return "typeselect";
    }
  };
  getClassNameForDefault = (inputProps) => {
    switch (true) {
      case inputProps.hasValue:
        return "typeselect selected";
      case inputProps.isDisabled:
        return "typeselect disabled";
      case inputProps.menuIsOpen:
        return "typeselect menu-is-open";
      default:
        return "typeselect";
    }
  };
  getClassName = (inputProps) => {
    const { selectProps = {} } = inputProps;
    const { type } = selectProps;
    switch (type) {
      case ControlType.MultiSelectDropdown:
      case ControlType.RadioSelectDropdown:
        return this.getClassNameForMultiSelectDropdown(inputProps);
      default:
        return this.getClassNameForDefault(inputProps);
    }
  };
  render() {
    const { inputProps } = this.props;
    return (
      // @ts-ignore
      <TextFieldStyled
        id={inputProps.name}
        className={this.getClassName(inputProps)}
        InputProps={{
          inputComponent,
          inputProps: {
            children: inputProps.children,
            className: "multiValueInput",
            ref: inputProps.ref,
            ...inputProps.innerProps,
          },
        }}
        variant={"standard"}
      />
    );
  }
}

export default MultiSelectInputValue;
