import React from "react";
import styled from "styled-components";

export const StyledSearchBar = styled("div")`
  background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  min-height: 64px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  .field-wrapper {
    margin-bottom: 0;
  }
  .searchbox-wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    .input {
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }
  .multiSelectControl {
    .typeselect {
      height: 40px;
      & > div {
        font-size: ${(props) => props.theme.fontSize.base};
        &:last-child > .multiValueInput {
          height: 36px;
        }
      }
      .dropDownPlaceholder {
        padding: 8px 0;
      }
      &.selected .multivalue {
        line-height: 36px !important;
      }
      .menuClose,
      .menuOpen {
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: auto;
        }
      }
    }
  }
`;

const SearchFilterWrapper = (props) => {
  return <StyledSearchBar>{props.children}</StyledSearchBar>;
};

export default SearchFilterWrapper;
