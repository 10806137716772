import { StatusCode } from "../../utils/enums/assetEnum";

export const shouldShowAssetBatteryStatus = (data): boolean => {
  const batteryStatusData = data?.batteryStatus;
  if (batteryStatusData?.code === StatusCode.BateryLevel.toString()) {
    return true;
  }
  return false;
};

export const shouldShowAssetBatteryStatusInList = (data): boolean => {
  return shouldShowAssetBatteryStatus(data) && data?.assetStatusName?.code === StatusCode.Operational.toString();
};
