import React from "react";
import styled from "styled-components";

export interface ICardProps {
  readonly label: string;
  readonly count: number;
  readonly color?: string;
}

const CardWrapper = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  width: 100%;
`;

export const NumberWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize.largest};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  line-height: 24px;
  color: ${(props) => props.theme.colors.base[props.color]};
  margin-bottom: 8px;
`;

export const TextWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colors.base.steel};
`;

export const Card = (props: ICardProps) => {
  const { label, count, color } = props;
  return (
    <CardWrapper>
      <NumberWrapper color={color || "steel"}>{count}</NumberWrapper>
      <TextWrapper>{label}</TextWrapper>
    </CardWrapper>
  );
};

export default Card;
