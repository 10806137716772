import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="05-Icons-/-document_edit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <path
        d="M4,2 L4,21 L11.1396887,21 L12.3652283,17.9837972 L19,11.5 L19,8 L13,2 L4,2 Z M12,9 L12,3.5 L17.5,9 L12,9 Z M22.6282847,14.8304273 L16.8887192,20.5875557 L14.7219271,18.4289213 L20.5320906,12.7342333 L22.6282847,14.8304273 Z M13.4610228,19.8053011 L15.5271219,21.8714002 L12.3652283,22.9521473 L13.4610228,19.8053011 Z"
        id="Shape"
        fill="#524F53"
      />
    </g>
  </svg>
);

export default SVG;
