import { IconName, Prompt } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { IRemoveProps } from "./iRemove";
import { Trans } from "react-i18next";

const LabelName = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  word-break: normal;
  display: inline-block;
`;

/**
 * @class Remove
 * @extends {React.PureComponent<IRemoveProps>}
 */
export class Remove extends React.PureComponent<IRemoveProps> {
  /**
   * @description - Method to close remove confirmation popup.
   * @memberof - Remove
   */
  handleClose = () => {
    this.props.handleRemoveClose();
  };

  /**
   * @description - Method to remove asset service.
   * @memberof - Remove
   */
  handleConfirmRemove = () => {
    this.props.handleRemoveClose();
    this.props.handleRemoveConfirm(this.props.rowData && this.props.rowData.id);
  };

  render() {
    const { rowData, t } = this.props;

    return (
      <Prompt
        icon={IconName.Warning}
        handleClose={this.handleClose}
        handleConfirm={this.handleConfirmRemove}
        header={t("services:REMOVE_ASSET_SERVICE_TITLE")}
        cancelLabel={t("common:CANCEL")}
        okLabel={t("common:REMOVE")}
        className="warning"
      >
        <LabelName id="deleteConfirmationMessage">
          <Trans components={{ span: <span id="spanServiceName" className="bold-text" /> }}>
            {t("services:REMOVE_ASSET_SERVICE_MESSAGE", {
              serviceName: rowData && rowData.serviceName ? rowData.serviceName : rowData.name,
            })}
          </Trans>
        </LabelName>
      </Prompt>
    );
  }
}

export default Remove;
