// @ts-ignore
import Grid from "@mui/material/Grid";
// @ts-ignore
import {
  Dialog,
  Footer,
  GridView,
  InfiniteScrollComponent,
  ListContainer,
  InfiniteScrollLoadEnd,
} from "am-web-ui-shared/components";
import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import InfiniteScrollLoader from "../../../utils/loader/infinitescrollLoader";
import { AlignCenter, ellipsis, InlineFlex, primaryFontStyles } from "../../../utils/styledUtils";
import { CancelTransferView } from "../cancelTransfer/cancelTransferView";
import { ITransferHistoryView } from "../model/iTransferHistoryView";
import TransferCard from "../transferCard/transferCard";
import { transferHistoryGridSettings } from "../transferHistoryGridSetting";
import TransferHeader from "./transferHeader";
import { ActorTypeEnum } from "../../../utils/enums/actorTypeEnum";
import { TransferStatusEnum } from "../../../utils/enums/transferAssetsEnum";
import ServiceUrl from "../../../utils/enums/serviceUrlEnum";
import { AssetSubType } from "../../../utils/enums/assetEnum";

const StyledGridCard = styled(TransferCard).attrs({
  id: "gridCard",
})`
  margin-top: 1px;
`;
const ContentWrapper = styled.div``;
export const HeaderComponent = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.theme.colors.base.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
`;
export const FieldLabel = styled.div`
  line-height: 16px;
  min-height: 16px;
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.normalSteelText.color};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  ${() => ellipsis("97%")}
`;
export const FieldValue = styled.div`
  line-height: 20px;
  min-height: 20px;
  color: ${(props) => props.theme.boldSteelText.color};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  ${() => ellipsis("97%")}
`;
const FixedContainer = styled.div`
  margin: -4px auto 0;
  width: 992px;
  .custom-cell:first-child {
    padding-left: 0;
  }
`;
const BodyWrapper = styled.div`
  width: 992px;
  margin: 24px auto 0;
  #infinite {
    overflow-x: hidden;
  }
`;
export const TransferHistorySummary = styled(Grid)`
  ${InlineFlex};
  height: 68px;
  background-color: ${(props) => props.theme.colors.base.white};
  margin-bottom: 4px;
`;
export const SummaryDetails = styled(Grid).attrs({
  id: "summaryDetails",
})`
  && {
    ${InlineFlex}
    ${AlignCenter};
  }
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  ${primaryFontStyles};
  line-height: 24px;
  text-align: left;
`;
const ItemsLengthWrapper = styled.div.attrs({
  id: "itemsLength",
})`
  margin-left: 15px;
`;
export const ItemsSpan = styled.span`
  color: ${(props) => props.theme.colors.steel};
`;

export const ItemsSpanNormalFonts = styled.span`
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  color: ${(props) => props.theme.colors.steel};
  font-weight: 300;
  margin-left: 11px;
`;
export const CanceledLabel = styled.span`
  height: 24px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.base.pink};
  color: ${(props) => props.theme.colors.base.lightRedColor};
  border-radius: 12px;
  padding: 0px 12px;
`;

const TransferListBody = styled.div`
  overflow: auto;
  flex: 1;
  min-height: 0;
`;
export class TransferHistoryView extends React.Component<ITransferHistoryView, {}> {
  listScrollRef;
  state = {
    showCancelTransferForm: false,
  };
  componentDidMount() {
    const {
      selectedRow: { assetId, id },
      getAssetTransferDetails,
      getQTYTransferDetails,
      getAllocationDetails,
      allocationId,
    } = this.props;
    if (assetId) {
      getAssetTransferDetails(assetId, 0);
    } else if (id) {
      getQTYTransferDetails(id, 0);
    } else if (allocationId) {
      getAllocationDetails(allocationId, 0);
    }
  }
  setScrollRef = (ref) => {
    this.listScrollRef = ref;
  };
  toStorageAsset = (value) => {
    return value && value.toStorageAssetDetails && value.toStorageAssetDetails.assetName;
  };
  fromStorageAsset = (value) => {
    return value && value.fromStorageAssetDetails && value.fromStorageAssetDetails.assetName;
  };

  handelCancelTransfer = () => {
    this.setState({ showCancelTransferForm: true });
  };

  /**
   *
   * @description - Method to dispatch redux-form submit action.
   * @param {string} - Form model.
   */
  handleSave = (cancelNote, rowData) => {
    this.props.cancelLastTransfer(cancelNote, rowData.transferId);
  };

  /**
   *
   * @description - Method to add cancel label
   *
   */

  handleCancelTransferLabel = (props, value) => {
    return value ? <CanceledLabel>{props.t("common:CANCELLED")}</CanceledLabel> : null;
  };
  changeLabel = (props, label, value) => {
    const { t } = props;
    return (
      <>
        {label && <FieldLabel>{t(label)}</FieldLabel>}
        <FieldValue title={value || ""}> {value || "-"}</FieldValue>
      </>
    );
  };
  toLocation = (props, value, column) => {
    const { t, transferHistoryResponse } = props;
    const changedValue = this.toStorageAsset(transferHistoryResponse);
    let locationName = changedValue ? changedValue : value.locationName;
    locationName = transferHistoryResponse.allocationMerged
      ? `${locationName ? `${locationName} (${t("allocations:MERGED_INTO")})` : "-"} `
      : locationName;
    return this.changeLabel(props, column.label, locationName);
  };
  fromLocation = (props, value, column) => {
    const { transferHistoryResponse } = props;
    const changedValue = this.fromStorageAsset(transferHistoryResponse);
    const locationName = changedValue ? changedValue : value.locationName;
    return this.changeLabel(props, column.label, locationName);
  };

  quantityWithUnit = (props, value, column) => {
    const { transferHistoryResponse } = props;
    const decimalCount = 3;
    const quantityWithUnit = `${LocaleManager.numberHelpers.formatDecimalNumber(value, decimalCount)} ${
      transferHistoryResponse.unitName
    }`;
    return this.changeLabel(props, column.label, quantityWithUnit);
  };

  confirmedDate = (props, value) => {
    const formattedDate = LocaleManager.dateHelpers.getDateTimeInTenantTimezone(value);
    const entityTransferStatus = HelperLodash.get(props, "transferHistoryResponse.entityTransferStatus.code", "");
    const confirmDateLabel =
      entityTransferStatus === TransferStatusEnum.CONFIRMED ? "common:CONFIRMED" : "common:RECALLED";
    return this.changeLabel(props, confirmDateLabel, formattedDate);
  };
  confirmOrRecalledBy = (props, value) => {
    const confirmOrRecalledValue =
      value.code === ActorTypeEnum.SYS_USER ? value.code : props.transferHistoryResponse.entityActorDetails.name;
    const entityTransferStatus = HelperLodash.get(props, "transferHistoryResponse.entityTransferStatus.code", "");
    const confirmOrRecalledByLabel =
      entityTransferStatus === TransferStatusEnum.CONFIRMED ? "common:CONFIRMED_BY" : "common:RECALLED_BY";
    return this.changeLabel(props, confirmOrRecalledByLabel, confirmOrRecalledValue);
  };

  renderCard = (column, data) => {
    const { t, transferHistory } = this.props;
    const transformedData = {
      ...data,
      applicationType: data.applicationType === ServiceUrl.UNITE ? t("common:EXTERNAL_SYSTEM") : data.applicationType,
    };

    return (
      <StyledGridCard
        handleSave={this.handleSave}
        handelCancelTransfer={this.handelCancelTransfer}
        config={this.props.config}
        gridConfig={column}
        dependencies={{
          cancelTransferLabel: this.handleCancelTransferLabel,
          cancelTransferView: (props) => (
            <CancelTransferView
              {...props}
              fromAssetSubType={transferHistory?.list[0]?.fromAssetSubType}
              toAssetSubType={transferHistory?.list[0]?.toAssetSubType}
            />
          ),
          fromLocation: this.fromLocation,
          quantityWithUnit: this.quantityWithUnit,
          toLocation: this.toLocation,
          confirmedDate: this.confirmedDate,
          confirmOrRecalledBy: this.confirmOrRecalledBy,
        }}
        transferHistoryResponse={transformedData}
      />
    );
  };

  hasMoreData = (transferHistory, list) => {
    const { totalRecords, totalTransfersLength } = transferHistory;
    return totalTransfersLength ? totalTransfersLength < totalRecords : list.length < totalRecords;
  };

  renderListData = () => {
    const { headerGridSetting, selectedRow, t, transferHistory } = this.props;
    const transferHistoryCloaned = Object.assign({}, transferHistory);
    const list = transferHistoryCloaned.list || [];
    list[0].assetId = selectedRow.assetId;
    const hasMoreDataToLoad = this.hasMoreData(transferHistory, list);
    return (
      <ContentWrapper>
        <HeaderComponent id="Assetheader">
          <FixedContainer>
            <TransferHeader selectedRow={selectedRow} headerGridSetting={headerGridSetting} />
          </FixedContainer>
        </HeaderComponent>
        <BodyWrapper>
          <TransferHistorySummary container={true} xs={12}>
            <SummaryDetails item={true} xs={6}>
              <ItemsLengthWrapper id="itemsLength">
                <ItemsSpan>
                  {`${transferHistory.totalRecords} `}
                  {t("common:TRANSFERS")}
                </ItemsSpan>
                <ItemsSpanNormalFonts>({t("common:SORT_NEWEST_TO_OLDEST")})</ItemsSpanNormalFonts>
              </ItemsLengthWrapper>
            </SummaryDetails>
          </TransferHistorySummary>
          <ListContainer modalFooter={appConstants.MODAL_FOOTER_HEIGHT}>
            <TransferListBody id="transferListListBody" ref={this.setScrollRef}>
              <InfiniteScrollComponent
                loader={() => <InfiniteScrollLoader />}
                item={this.listScrollRef}
                hasMore={hasMoreDataToLoad}
                loadMore={this.props.loadPaginatedList}
                pageStart={0}
                threshold={100}
                loadEnd={() => <InfiniteScrollLoadEnd loadEnd={this.props.t("common:INFINITE_LOAD_END")} />}
              >
                <GridView
                  handleRowClick={null}
                  columns={transferHistoryGridSettings}
                  dependencies={{
                    customGridCard: this.renderCard,
                  }}
                  rowsData={list}
                  idKey="transferHistory"
                  disableCheckBoxColumn={true}
                  rowAttributes={{ style: { height: "auto", width: "100%", marginBottom: "4px" } }}
                  lazyLoadList={false}
                />
              </InfiniteScrollComponent>
            </TransferListBody>
          </ListContainer>
        </BodyWrapper>
      </ContentWrapper>
    );
  };
  render() {
    const { closeDialog, headerText, t } = this.props;
    return (
      <Dialog
        containerId="transferHistoryContainer"
        headerId="transferHistoryContainer"
        onClose={closeDialog}
        headerText={headerText}
      >
        {this.renderListData()}
        <Footer buttonType="cta" buttonText={t("common:DONE")} onClick={closeDialog} />
      </Dialog>
    );
  }
}

export default withTranslation()(TransferHistoryView);
