import { IconName } from "am-web-ui-shared/components";
import React from "react";
import { Trans } from "react-i18next";
import { IconBold, IconNormalBold } from "../../../../../components/DetailsPanel/index";
import { isResposibleWorker, isResposibleWorkerContacts } from "./reponsibleWorkerValidator";

/**
 * @description - Heading of the responsioble worker section.
 */
export const responsibleWorker = () => {
  return (
    <IconBold
      id="pnlResponsibleWorker"
      iconUrl={IconName.LocationManager}
      boldId="lblResponsibleWorker"
      boldText={<Trans>{"common:LABEL_RESPONSIBLE_WORKER"}</Trans>}
    />
  );
};

/**
 * @description - Worker name.
 */
export const workerName = (data) => {
  let responsibleEmployeeName = "";

  if (isResposibleWorker(data)) {
    responsibleEmployeeName = data.responsibleEmployeeName;
  }
  return responsibleEmployeeName ? (
    <IconNormalBold
      id="pnlWorkerName"
      normalId="lblWorkerName"
      normalText={<Trans>{"common:LABEL_WORKER_NAME"}</Trans>}
      boldId="txtWorkerName"
      boldText={responsibleEmployeeName}
    />
  ) : null;
};

/**
 * @description - Work phone.
 */
export const workPhone = (data) => {
  let countryCode = "";
  let phoneNumber = "";

  if (isResposibleWorkerContacts(data)) {
    countryCode = data.responsibleEmployeePhone[0].countryCode
      ? `+${data.responsibleEmployeePhone[0].countryCode}`
      : "";
    phoneNumber = data.responsibleEmployeePhone[0].phoneNumber;
  }

  return phoneNumber ? (
    <IconNormalBold
      id="pnlWorkPhone"
      normalId="lblWorkPhone"
      normalText={<Trans>{"common:LABEL_WORKER_PHONE"}</Trans>}
      boldId="txtWorkPhone"
      boldText={`${countryCode} ${phoneNumber}`}
    />
  ) : null;
};

/**
 * @description - Exporting Array of the components that are used at the summary part of the responsible worker section used on asset details.
 */
export default [responsibleWorker, workerName, workPhone];
