const ErrorCodes = {
  E200: 200,
  E201: 201,
  E204: 204,
  E401: 401,
  E403: 403,
  E405: 405,
  E500: 500,
  E502: 502,
  E503: 503,
  E504: 504,
};
export default ErrorCodes;
