import { Dialog, DialogPosition, OutsideClickAlerter, Sort } from "am-web-ui-shared/components";
import { SharedUtils, DownloadFileHandler, HelperLodash } from "am-web-ui-shared/helpers";
import React from "react";
import { SuspenseWrapper } from "../../../../../reactSuspenseWrapper";
import { withTranslation } from "react-i18next";
import { TParam } from "../../../../../../localization/types";
import { FileTypeList, MultiSelectValidation, TRANSFER_LIMIT } from "TARGET_BUILD/config";
import { ToastrType } from "../../../../../actions/toastrAction";
import { downloadAssetsList, getRenewalReturnAssetsCount } from "../../../../../api/assetApi";
import StyleAttrConstants from "../../../../../styleAttrConstants";
import { filtersQuery, getOffset } from "../../../../../utils/commonUtils";
import { isScanCodeInOffBoardingRange } from "../../../../../utils/scanCodeRangeUtil";
import { isDomainUser } from "../../../../../utils/holUtils";
import { ActionItem } from "../../../../../utils/enums/actionItemEnum";
import { StatusCode } from "../../../../../utils/enums/assetEnum";
import { ModuleName } from "../../../../../utils/enums/moduleNameEnum";
import { getCheckedRowsInfo } from "../../../../../utils/list/listUtils";
import { displayMessageFromErrorCode } from "../../../../../utils/errorUtils";
import { getTranslationKeyWithQty } from "../../../../../utils/qtyItemUtils";
import {
  getItemsToBeTransferred,
  getTransferCartListCount,
  transformItems,
} from "../../../../../utils/transferCartUtils";
import ActionBarWrapper from "../../../actionBarWrapper";
import ISummaryProps from "../model/iAssetsSummary";
import GTMConstants from "../../../../../gtmConstants";
import { withRouter } from "react-router-dom";
import {
  AddAssetContainerWrapper,
  AssetLabels,
  AssetMultiEditContainer,
  AssetQuickTransferContainer,
  DeleteAction,
  DiscardDialog,
  GridSettingModal,
  HiltiServicesWrapper,
  ImportAssets,
  ImportCompleteServices,
  StatusAction,
} from "./lazyLoads";

export const ASSET_TOOLTIP_INTRANSIT_ASSET = "assets:TOOLTIP_INTRANSIT_ASSET";
export class AssetsSummary extends React.Component<ISummaryProps> {
  state = {
    assetActionType: null,
    isAssetAdded: false,
    isFormChange: false,
    labelPanelOpen: false,
    labelsData: null,
    leftPosition: 0,
    rowId: null,
    selectedRow: -1,
    showDeleteModal: false,
    showDiscardDialog: false,
    showDiscardDialogForQuickTransfer: false,
    showImportAssetModal: false,
    showModal: false,
    showMultiEditModal: false,
    showSettingModal: false,
    showSortingModal: false,
    showStatusMenu: false,
    showCompleteServiceModal: false,
    showHiltiServicesMenu: false,
    source: {
      cancel: () => null,
      token: "",
    },
    topPosition: 0,
    expiringCount: 0,
    expiringCountError: null,
  };

  debounceStore;
  fileName = "Assets_Details.xlsx";
  multiSelectTransKey: TParam = "actionBar:MULTISELECT_VALIDATION";

  componentDidMount() {
    this.getCount();
  }

  componentDidUpdate(prevProps) {
    this.tranferAllSelectedAssetsToCart(prevProps);
  }

  getCount = async () => {
    try {
      const { expiringCount } = await getRenewalReturnAssetsCount();
      this.setState({ expiringCount });
    } catch (e) {
      /*nothing*/
    }
  };

  /**
   * @description This method is used to transfer the all selected assets to the cart
   *
   * @param prevProps
   */
  tranferAllSelectedAssetsToCart = (prevProps) => {
    const { selectAllAssets } = this.props;
    if (selectAllAssets && selectAllAssets !== prevProps.selectAllAssets) {
      const checkedAssetListInfo = getCheckedRowsInfo(selectAllAssets);
      this.tranferAssetsToCart(checkedAssetListInfo.checkedRowsExcludingChildren);
    }
  };

  getDownloadMessage = () => {
    const { t } = this.props;
    return t("common:EXPORTED_SUCCESSFULLY");
  };

  getQueryObj = () => {
    const { assetState } = this.props;
    const { filterQuery, currentSelectedFilter, replaceFilterQuery, searchString } = assetState;
    const queryObj: any = {};

    if (filterQuery) {
      queryObj.filter = filtersQuery(filterQuery, currentSelectedFilter, replaceFilterQuery);
    }

    if (searchString) {
      queryObj.q = searchString;
    }
    return queryObj;
  };

  /**
   * @description This method is used to transfer the selected assets to the cart and show the toast messages accordingly
   *
   * @param rows
   */
  tranferAssetsToCart = (rows) => {
    const { setTransferCartItemToStore, showToast, transferCartList, t, toggleAssetListDialog } = this.props;
    const assetsInfo = getItemsToBeTransferred(transformItems(rows), transferCartList);

    if (assetsInfo.existingAssetCount === 0 || (assetsInfo.existingAssetCount === rows.length && !assetsInfo.isAdded)) {
      showToast(ToastrType.success, t("common:TRANSFER_CART_ADDED_SUCESSFULLY"));
    } else if (assetsInfo.existingAssetCount === rows.length) {
      showToast(ToastrType.info, t("common:ASSET_EXIST_IN_CART"));
    } else {
      showToast(ToastrType.success, t("common:TRANSFER_CART_PARTIAL_SUCCESS"));
    }
    setTransferCartItemToStore(assetsInfo.resultantTransferCartList);
    if (toggleAssetListDialog) {
      toggleAssetListDialog(false);
    }
  };

  /**
   * @description This method is used to transfer the selected assets to the cart
   *
   * @param rows
   */
  transferToCart = (rows) => {
    SharedUtils.pushDataToGA(
      rows.length > 1
        ? GTMConstants.EVENTS.ADD_MULTIPLE_ASSET_TO_TRANSFER_CART
        : GTMConstants.EVENTS.ADD_ASSET_TO_TRANSFER_CART,
    );
    const { getSelectAllAssets, isAllSelected, assetState, gatewayAssetsList } = this.props;

    if (
      isAllSelected &&
      ((assetState &&
        assetState.assetsList.totalRecords > assetState.assetsList.offset + assetState.assetsList.limit) ||
        (gatewayAssetsList && gatewayAssetsList.totalRecords > gatewayAssetsList.offset + gatewayAssetsList.limit))
    ) {
      return getSelectAllAssets();
    } else {
      return this.tranferAssetsToCart(rows);
    }
  };

  /**
   * @description To download file
   */
  downloadFile = () => {
    DownloadFileHandler.downloadFile(
      downloadAssetsList,
      FileTypeList.TYPE,
      this.getQueryObj(),
      this.fileName,
      this.props.t,
      this.getDownloadMessage(),
      this.props.showToast,
      this.props.t("common:DOWNLOAD_ERROR"),
      displayMessageFromErrorCode,
    );
  };

  /**
   * @description To open dialog for adding asset
   */
  openAddAssetDialog = () => {
    this.setState({ showModal: true });
    this.props.closeMoreFilter();
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.ADD_UNIQUE_ASSET);
  };

  /**
   * @description To open dialog for import asset
   */
  openImportAssetDialog = () => {
    this.setState({ showImportAssetModal: true });
  };
  /**
   * @description To open settings dialog
   */
  openSettingDialog = () => {
    this.setState({ showSettingModal: true });
  };

  /**
   * @description To open dialog for import asset
   */
  openCompleteServiceDialog = () => {
    this.setState({ showCompleteServiceModal: true });
  };
  /**
   * @description To close dialog for import asset
   */
  closeImportAssetDialog = () => {
    const { source } = this.state;
    if (source && source.token) {
      source.cancel();
      source.token = null;
    }
    this.setState({ showImportAssetModal: false });
  };

  /**
   * @description To close dialog for adding asset
   *
   * @param trackDiscardWorkflow This param is used to make sure discard tracking
   */

  closeAddAssetDialog = (trackDiscardWorkflow = false) => {
    if (trackDiscardWorkflow) {
      SharedUtils.pushDataToGA(
        this.state.assetActionType === ActionItem.add
          ? GTMConstants.EVENTS.ADD_ASSET_WORKFLOW_DROP
          : GTMConstants.EVENTS.EDIT_ASSET_WORKFLOW_DROP,
      );
    }
    this.setState({
      isFormChange: false,
      showModal: false,
    });
  };

  /**
   * @description To close dialog for import asset
   */
  closeCompleteServiceDialog = () => {
    const { source } = this.state;
    if (source && source.token) {
      source.cancel();
      source.token = null;
    }
    this.setState({ showCompleteServiceModal: false });
  };

  /*
    After success, if user closes the dialog then refresh the assets list
  */
  closeAndRefreshList = () => {
    if (this.state.isAssetAdded) {
      this.props.getAssetsList(0);
    }
    this.closeAddAssetDialog(!this.state.isAssetAdded);
  };

  handleToken = (source) => {
    this.setState({
      source,
    });
  };

  openStatusDialog = () => {
    this.setState({
      showStatusMenu: !this.state.showStatusMenu,
    });
  };

  openHiltiServicesMenu = () => {
    this.setState({
      showHiltiServicesMenu: !this.state.showHiltiServicesMenu,
    });
  };

  closeHiltiServicesMenu = () => {
    this.setState({ showHiltiServicesMenu: false });
  };

  openSortingDialog = () => {
    this.setState({ showSortingModal: true });
  };

  closeStatusMenu = () => {
    this.setState({ showStatusMenu: false });
  };

  getStatusComponent = () => {
    return (
      <SuspenseWrapper>
        <DialogPosition left={this.state.leftPosition}>
          <StatusAction
            checkedAssetListInfo={this.props.checkedAssetListInfo}
            assetStatusList={this.props.assetStatusList}
            updateUniqueAssetStatus={this.props.updateUniqueAssetStatus}
            closeMenu={this.closeStatusMenu}
            showStatusMenu={this.state.showStatusMenu}
            transferConfirmationPolicy={this.props.transferConfirmationPolicy}
          />
        </DialogPosition>
      </SuspenseWrapper>
    );
  };

  /**
   * @description To close setting dialog
   */
  closeSettingDialog = () => {
    this.setState({ showSettingModal: false });
  };

  editClickHandler = () => {
    this.setState(
      this.props.checkedAssetListInfo.isMultipleRowChecked ? { showMultiEditModal: true } : { showModal: true },
    );
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.EDIT_ASSET_INITIATED);
  };

  handleDeleteOpen = () => {
    this.setState({
      showDeleteModal: true,
    });
  };

  handleDeleteClose = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  offBoardHiltiAssetsHandler = () => {
    const { checkedAssetListInfo, offBoardHiltiAssets } = this.props;
    const shouldOffboardAsset = checkedAssetListInfo.checkedRows.some((item) =>
      isScanCodeInOffBoardingRange(item.scanCode),
    );
    if (shouldOffboardAsset) {
      const scanCodes = checkedAssetListInfo.checkedRows
        .map((item) => (isScanCodeInOffBoardingRange(item.scanCode) ? item.scanCode : null))
        .filter((item) => item);
      offBoardHiltiAssets(scanCodes);
    }
  };

  deleteAssetHandler = () => {
    const { deleteAssets, list } = this.props;
    deleteAssets(this.props.checkedAssetListInfo.checkedListInfo, list);
    this.offBoardHiltiAssetsHandler();
    this.setState({ showDeleteModal: false });
  };

  getDeleteComponent = () => {
    const { isAllSelected, checkedAssetListInfo, transferConfirmationPolicy } = this.props;
    return (
      <SuspenseWrapper>
        <DeleteAction
          checkedAssetListInfo={checkedAssetListInfo}
          transferConfirmationPolicy={transferConfirmationPolicy}
          showToaster={this.props.showToast}
          assetDeleteResponse={this.props.assetState.assetDeleteResponse}
          deleteAssets={this.deleteAssetHandler}
          getAssetFilterAttributes={this.props.getAssetFilterAttributes}
          resetMultiDeleteResponse={this.props.resetMultiDeleteResponse}
          getAssetsList={this.props.getAssetsList}
          confirmDelete={this.handleDeleteOpen}
          closeDialog={this.handleDeleteClose}
          isAllSelected={isAllSelected}
        />
      </SuspenseWrapper>
    );
  };

  confirmFormClose = () => {
    this.setState({ showDiscardDialog: false });
    this.closeAddAssetDialog(true);
  };

  cancelFormClose = () => {
    this.setState({ showDiscardDialog: false });
  };

  setFormChange = (isFormChange) => {
    this.setState({ isFormChange });
  };

  setAssetAddStatus = (isAssetAdded = false) => {
    this.setState({ isAssetAdded });
  };

  openConfirmChange = () => {
    this.setState({ showDiscardDialog: true });
  };
  /**
   * @description returns toast message when file successfully downloads.
   * @params receives two arguments no of assets downloaded and translation.
   */
  getMessage = (noOfAssets, t) => {
    return `${t("templates:DOWNLOAD_ASSETS")} ${noOfAssets} ${t("templates:ITEMS")}`;
  };

  /**
   * @description Function to check asset state is In-Transit and only 1 row selected on assets list.
   * @params Receives checked rows details object
   * @return flag
   */
  isAssetStateIntransit = (checkedRowsInfo) => {
    const code = checkedRowsInfo?.firstCheckedRow?.assetStateName?.code;
    return checkedRowsInfo.isOneRowChecked && code === StatusCode.Intransit;
  };

  closeEditModal = (refreshAssetList: boolean) => {
    this.setState({ showMultiEditModal: false });
    if (refreshAssetList) {
      this.props.onClearAllSelection();
      this.props.fetchList();
    }
  };

  getAssetStatus = (asset) => {
    return [StatusCode.Retired, StatusCode.Disposed].includes(asset.assetStatusName.code);
  };

  moreCheckedRowsCount = () => {
    const {
      checkedAssetListInfo: { checkedRowscount },
    } = this.props;
    return checkedRowscount > MultiSelectValidation.MULTISELECTCOUNT;
  };

  labelTooltip = () => {
    const {
      checkedAssetListInfo: { isMultipleRowChecked, isAnyRowChecked, firstCheckedRow },
    } = this.props;
    if (isMultipleRowChecked || (isAnyRowChecked && this.getAssetStatus(firstCheckedRow))) {
      if (this.moreCheckedRowsCount()) {
        return this.props.t(this.multiSelectTransKey);
      } else {
        return this.props.t("assets:LABEL_TOOLTIP");
      }
    } else {
      return this.props.t("actionBar:LABEL");
    }
  };

  getDeleteTooltip = (): string => {
    const {
      checkedAssetListInfo: { isOneRowChecked },
      t,
    } = this.props;

    if (this.isAssetStateIntransit(this.props.checkedAssetListInfo)) {
      return t(ASSET_TOOLTIP_INTRANSIT_ASSET);
    }
    if (this.moreCheckedRowsCount()) {
      return t(this.multiSelectTransKey);
    }
    if (isOneRowChecked) {
      if (this.isTransferedAssetSelected()) {
        return t("assets:TOOLTIP_TRANSFERED_ASSET");
      }
      if (this.shouldStorageAssetDisabled()) {
        return t(getTranslationKeyWithQty("assets:STORAGE_ASSET_DELETE_DISABLE_TOOLTIP"));
      }
    }
    return t("actionBar:DELETE");
  };

  /**
   * @description - If Transfer cart is not empty
   */
  confirmQuickTransfer = () =>
    this.setState({ showDiscardDialogForQuickTransfer: false, assetActionType: ActionItem.quickTransfer });

  /**
   * @description - If Transfer cart is not empty
   */
  cancelQuickTransfer = () => this.setState({ showDiscardDialogForQuickTransfer: false });

  /**
   * @description - Transfer tooltip if asset not selected or selected more than 500
   */
  transferTooltip = () => {
    const { checkedAssetListInfo, t, transferCartList } = this.props;
    const transferItemCount = getTransferCartListCount(transferCartList);
    const selectedCount = HelperLodash.sum(checkedAssetListInfo.checkedRows, "assetCountWithChildData");

    if (this.isAssetStateIntransit(checkedAssetListInfo)) {
      return t(ASSET_TOOLTIP_INTRANSIT_ASSET);
    } else if (transferItemCount + selectedCount > TRANSFER_LIMIT.MAX_ITEMS) {
      return t("assets:TRANSFER_TOOLTIP_COUNT");
    }
    return t("actionBar:TRANSFER");
  };

  /**
   * @description - Quick Transfer tooltip if asset not selected or selected more than 50
   */
  quickTransferTooltip = () => {
    const { checkedAssetListInfo, t } = this.props;

    if (this.isAssetStateIntransit(checkedAssetListInfo)) {
      return t(ASSET_TOOLTIP_INTRANSIT_ASSET);
    } else if (checkedAssetListInfo.totalCheckedCountWithQtyItems > TRANSFER_LIMIT.MAX_ALLOCATIONS) {
      return t("assets:QUICK_TRANSFER_COUNT_TOOLTIP");
    } else {
      return t("actionBar:QUICKTRANSFER");
    }
  };

  /**
   * @description - Quick Transfer tooltip if asset not selected or selected more than 50
   */
  hiltiServicesTooltip = () => {
    const { userMeData, checkedAssetListInfo, t } = this.props;

    if (isDomainUser(userMeData)) {
      return t("actionBar:HILTI_SERVICE_DOMAIN_USER");
    }

    if (!checkedAssetListInfo.isAnyRowChecked) {
      return t("actionBar:HILTI_SERVICE_NO_ASSET_SELECTED");
    }

    return "";
  };

  otherActionItemsTooltip = (tooltipInfo: TParam) =>
    this.moreCheckedRowsCount() ? this.props.t(this.multiSelectTransKey) : this.props.t(tooltipInfo);

  getEditTooltip = () => {
    const { checkedAssetListInfo, t } = this.props;
    if (this.isAssetStateIntransit(checkedAssetListInfo)) {
      return t(ASSET_TOOLTIP_INTRANSIT_ASSET);
    } else {
      return t("actionBar:EDIT");
    }
  };

  getAssetStatusTooltip = () => {
    const { checkedAssetListInfo, t } = this.props;
    if (this.isAssetStateIntransit(checkedAssetListInfo)) {
      return t(ASSET_TOOLTIP_INTRANSIT_ASSET);
    } else {
      return this.otherActionItemsTooltip("actionBar:STATUS");
    }
  };

  handleCTATooltip = (CTAKey) => {
    const { t } = this.props;
    switch (CTAKey) {
      case "ADD":
        return () => t("actionBar:ADD");
      case "EDIT":
        return () => this.getEditTooltip();
      case "DELETE":
        return () => this.getDeleteTooltip();
      case "LABEL":
        return () => this.labelTooltip();
      case "STATUS":
        return () => this.getAssetStatusTooltip();
      case "TRANSFER":
        return () => this.transferTooltip();
      case "MULTIEDIT":
        return () => this.otherActionItemsTooltip("actionBar:MULTIEDIT");
      case ActionItem.quickTransfer:
        return () => this.quickTransferTooltip();
      case ActionItem.repair:
        return () => this.hiltiServicesTooltip();
      default:
        return "";
    }
  };

  toggleSort = () => {
    this.props.closeMoreFilter();
    this.setState({ showSortingModal: !this.state.showSortingModal });
  };

  closeMenu = () => {
    this.setState({ showSortingModal: false });
  };

  onActionClicked = (actionName, e) => {
    const positionObj = getOffset(e);

    this.setState({
      leftPosition: positionObj.leftPosition,
      topPosition: positionObj.topPosition,
    });

    switch (actionName) {
      case ActionItem.add:
        this.setState({
          assetActionType: ActionItem.add,
        });
        this.openAddAssetDialog();
        break;

      case ActionItem.edit:
        this.setState({
          assetActionType: ActionItem.edit,
        });
        this.editClickHandler();
        break;

      case ActionItem.delete:
        this.handleDeleteOpen();
        break;

      case ActionItem.setting:
        this.openSettingDialog();
        break;

      case ActionItem.import:
        this.openImportAssetDialog();
        break;

      case ActionItem.completeService:
        this.openCompleteServiceDialog();
        break;

      default:
        this.actionBarItemClick(actionName, e);
    }
  };

  actionBarItemClick = (actionName, e) => {
    const { checkedAssetListInfo } = this.props;

    switch (actionName) {
      case ActionItem.export:
        this.downloadFile();
        break;
      case ActionItem.status:
        this.openStatusDialog();
        break;
      case ActionItem.label:
        this.props.isLabelUpdated ? this.handleLabelClose() : this.handleLabelActivation();
        break;
      case ActionItem.transfer:
        this.transferToCart(checkedAssetListInfo.checkedRowsExcludingChildren);
        break;
      case ActionItem.multiEdit:
        this.editClickHandler();
        break;
      case ActionItem.quickTransfer:
        this.handleQuickTransferAction();
        break;
      case ActionItem.hiltiServices:
        this.openHiltiServicesMenu();
        break;
      case ActionItem.sort:
        this.toggleSort();
        const positionObj = getOffset(e, StyleAttrConstants.LEFT.LN176, StyleAttrConstants.TOP.T72);
        this.setState({
          leftPosition: positionObj.leftPosition, //  (width of the model (240) - width of action icon (64)
          topPosition: positionObj.topPosition, // width of model + width of actionBar(72)
        });
        break;
    }
  };

  handleQuickTransferAction = () => {
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.ASSETS_QUICK_TRANSFER_INITITATED);
    if (this.props.transferCartList.length) {
      this.setState({
        showDiscardDialogForQuickTransfer: true,
      });
    } else {
      this.setState({
        assetActionType: ActionItem.quickTransfer,
      });
    }
  };

  handleLabelClose = () => {
    this.props.cleanLabelData();
    this.setState({ labelPanelOpen: false });
    if (this.props.isLabelUpdated) {
      this.props.getAssetsList(0);
      this.props.onClearAllSelection();
    }
  };

  handleLabelActivation = () => {
    const { labelPanelOpen } = this.state;
    this.props.cleanAssetData();
    this.setState({ labelPanelOpen: !labelPanelOpen });
  };

  /** @description Function to ActionItem Edit visible
   */
  editVisible = () => !this.props.checkedAssetListInfo.isMultipleRowChecked;

  /** @description Function to enable and disable Label & Status Icon
   *
   */

  disableLabelStatus = () => {
    const {
      checkedAssetListInfo: { isMultipleRowChecked, checkedRowscount, isAnyRowChecked, firstCheckedRow },
    } = this.props;
    if (isMultipleRowChecked || (isAnyRowChecked && !this.getAssetStatus(firstCheckedRow))) {
      return checkedRowscount > MultiSelectValidation.MULTISELECTCOUNT;
    }

    return true;
  };

  disableStatus = () => {
    return (
      this.isAssetStateIntransit(this.props.checkedAssetListInfo) ||
      !(
        this.props.checkedAssetListInfo.isAnyRowChecked &&
        this.props.checkedAssetListInfo.checkedRowscount <= MultiSelectValidation.MULTISELECTCOUNT
      )
    );
  };

  /**
   * @description Function to ActionItem MultiEdit Enable and disable
   */
  multiEditVisible = () => this.props.checkedAssetListInfo.isMultipleRowChecked;

  /**
   * @description - disable  MultiEdit, Transfer
   */
  disableMultipleCTA = () => {
    const {
      checkedAssetListInfo: { isAnyRowChecked, checkedRowscount },
    } = this.props;
    return !isAnyRowChecked || (this.multiEditVisible() && checkedRowscount > MultiSelectValidation.MULTISELECTCOUNT);
  };

  /**
   * @description - disable  MultiEdit, Delete, Transfer
   */
  disableMultipleCtaAndDelete = () => {
    const {
      checkedAssetListInfo: { isAnyRowChecked, checkedRowscount },
    } = this.props;
    return !isAnyRowChecked || (this.multiEditVisible() && checkedRowscount > MultiSelectValidation.MULTISELECTCOUNT);
  };

  /**
   * @description - disable  Transfer if asset not selected or selected more than 500
   */
  disableTransfer = () => {
    const { checkedAssetListInfo, transferCartList } = this.props;
    const transferItemCount = getTransferCartListCount(transferCartList);

    return (
      this.isAssetStateIntransit(checkedAssetListInfo) ||
      !checkedAssetListInfo.isAnyRowChecked ||
      transferItemCount + checkedAssetListInfo.totalCheckedCountWithQtyItems > TRANSFER_LIMIT.MAX_ITEMS
    );
  };

  /**
   * @description - disable  Quick Transfer if asset not selected or selected more than 50
   */
  disableQuickTransfer = () => {
    const {
      checkedAssetListInfo: { isAnyRowChecked, totalCheckedCountWithQtyItems },
    } = this.props;
    // totalCheckedCountWithQtyItems count includes child assets also.
    return (
      this.isAssetStateIntransit(this.props.checkedAssetListInfo) ||
      !isAnyRowChecked ||
      totalCheckedCountWithQtyItems > TRANSFER_LIMIT.MAX_ALLOCATIONS
    );
  };

  shouldStorageAssetDisabled = () => {
    const {
      checkedAssetListInfo: { isOneRowChecked },
      selectedAssetDetail,
    } = this.props;
    /*
      storage asset is selected and never expanded then checkedRowsCount includes its children
    */
    return isOneRowChecked && selectedAssetDetail?.totalQtyItemCountExcludingNonOperable > 0;
  };

  /** @description Function to check if selected assets contains a transferred asset
   */
  isTransferedAssetSelected = () => {
    const { checkedAssetListInfo, isTransferedAssetDeletionEnabled } = this.props;
    const isAssetTransfered = checkedAssetListInfo.checkedRows.some((asset) => asset.lastTransferDate);

    return !isTransferedAssetDeletionEnabled && isAssetTransfered;
  };

  disableEdit = () => {
    const { checkedAssetListInfo, selectedAssetDetail } = this.props;
    return (
      !selectedAssetDetail ||
      (this.editVisible() &&
        (this.isAssetStateIntransit(checkedAssetListInfo) || !checkedAssetListInfo.isAnyRowChecked))
    );
  };

  hiltiServicesDisable = () => {
    const { userMeData, checkedAssetListInfo } = this.props;
    const { expiringCount } = this.state;
    if (expiringCount > 0) {
      return false;
    }
    return !(!isDomainUser(userMeData) && checkedAssetListInfo.isAnyRowChecked);
  };

  disableDelete = () => {
    return (
      this.isAssetStateIntransit(this.props.checkedAssetListInfo) ||
      this.disableMultipleCtaAndDelete() ||
      (this.props.checkedAssetListInfo.isOneRowChecked &&
        (this.isTransferedAssetSelected() || this.shouldStorageAssetDisabled()))
    );
  };

  /** @description Function to get authorization keys for delete and Label
   */
  handledeleteAuthKey = () =>
    this.props.checkedAssetListInfo.isMultipleRowChecked ? "asset.bulk.delete" : "asset.delete";

  handleLabelAuthKey = () =>
    this.props.checkedAssetListInfo.isMultipleRowChecked ? "asset.labels.bulk.add" : "asset.labels.add";

  handleStatusAuthKey = () =>
    this.props.checkedAssetListInfo.isMultipleRowChecked ? "asset.status.bulk.edit" : "asset.status.add";

  renderAddAssetContainer = () => {
    const {
      checkedAssetListInfo: { isAnyRowChecked, firstCheckedRow },
      t,
    } = this.props;

    return (
      <Dialog
        containerId="addAssetContainer"
        headerId="addAssetHeader"
        onClose={!this.state.isFormChange ? this.closeAndRefreshList : this.openConfirmChange}
        headerText={t(this.state.assetActionType === ActionItem.add ? "assets:ADD_ASSET_HEADER" : "assets:EDIT_ASSET")}
      >
        <SuspenseWrapper>
          <AddAssetContainerWrapper
            {...this.props}
            assetId={this.state.assetActionType === ActionItem.edit ? isAnyRowChecked && firstCheckedRow.assetId : null}
            setFormChange={this.setFormChange}
            setAssetAddStatus={this.setAssetAddStatus}
            closeAddAssetDialog={this.closeAddAssetDialog}
          />
        </SuspenseWrapper>
      </Dialog>
    );
  };
  renderSortingModelElement = () => {
    const {
      t,
      sortQuery,
      setSortQuery,
      setCustomViewModifiedFlag,
      isSortListUpdate,
      defaultSort,
      columns,
      ignoreSortProperties,
      assetFields,
    } = this.props;
    return (
      <DialogPosition left={this.state.leftPosition} top={this.state.topPosition}>
        <Sort
          isSortListUpdate={isSortListUpdate}
          columns={columns}
          defaultSort={defaultSort}
          ignoreSortProperties={ignoreSortProperties}
          columnFields={assetFields}
          fetchSortedList={this.props.fetchList}
          setCustomViewModifiedFlag={setCustomViewModifiedFlag}
          setSortQuery={setSortQuery}
          currentSelectedSort={sortQuery}
          showSortingModal={this.state.showSortingModal}
          closeMenu={this.closeMenu}
          t={t}
        />
      </DialogPosition>
    );
  };
  renderStatusComponent = () => this.state.showStatusMenu && this.getStatusComponent();
  renderDeleteComponent = () => this.state.showDeleteModal && this.getDeleteComponent();
  clearAssetActionType = () => this.setState({ assetActionType: null });
  renderSortingModel = () => this.state.showSortingModal && this.renderSortingModelElement();
  renderAddAssetContainerModel = () => this.state.showModal && this.renderAddAssetContainer();
  /**
   * @description This method is used to render Transfer qty item asset module
   */
  renderAssetQuickTransferContainer = () => {
    const { checkedAssetListInfo, t } = this.props;
    return (
      <SuspenseWrapper>
        <AssetQuickTransferContainer
          handleClose={this.clearAssetActionType}
          checkedAssetListInfo={checkedAssetListInfo}
          t={t}
        />
      </SuspenseWrapper>
    );
  };

  renderAssetQuickTransfer = () =>
    this.state.assetActionType === ActionItem.quickTransfer && this.renderAssetQuickTransferContainer();

  render() {
    const {
      actionBarSettings,
      assetFields,
      checkedAssetListInfo,
      clearDefaultGridFields,
      closeMoreFilter,
      columns,
      defaultGridColumns,
      getSystemView,
      isAllSelected,
      length,
      onClearAllSelection,
      onSelectAll,
      resetOriginalView,
      saveCustomGridView,
      selectedCustomView,
      t,
      createAssetLabel,
      associateAssetLabelDetails,
      companyDetails,
      history,
      assetAssociatedLabels,
      userMeData,
    } = this.props;

    const actionBarItemInfo = {
      multiSelectCount: MultiSelectValidation.MULTISELECTCOUNT,
      noOfSelectedRecords: isAllSelected
        ? checkedAssetListInfo.totalCheckedCount
        : checkedAssetListInfo.checkedRowscount,
      totalRecords: length,
    };

    return (
      <>
        <ActionBarWrapper
          actionBarItemInfo={actionBarItemInfo}
          checkedAssetListInfo={checkedAssetListInfo}
          onActionItemClicked={this.onActionClicked}
          shouldRenderCheckBox={true}
          dependencies={{
            addToolTip: this.handleCTATooltip("ADD"),
            deleteAuthKey: this.handledeleteAuthKey,
            deleteTooltip: this.handleCTATooltip("DELETE"),
            disableDelete: this.disableDelete,
            disableLabel: this.disableLabelStatus,
            disableEdit: this.disableEdit,
            disableMultiEdit: this.disableMultipleCTA,
            disableQuickTransfer: this.disableQuickTransfer,
            disableStatus: this.disableStatus,
            disableTransfer: this.disableTransfer,
            editTooltip: this.handleCTATooltip("EDIT"),
            editVisible: this.editVisible,
            labelAuthKey: this.handleLabelAuthKey,
            labelTooltip: this.handleCTATooltip("LABEL"),
            multiEditTooltip: this.handleCTATooltip("MULTIEDIT"),
            multiEditVisible: this.multiEditVisible,
            quickTransferTooltip: this.handleCTATooltip(ActionItem.quickTransfer),
            statusAuthKey: this.handleStatusAuthKey,
            statusToolTip: this.handleCTATooltip("STATUS"),
            transferTooltip: this.handleCTATooltip("TRANSFER"),
            hiltiServicesTooltip: this.handleCTATooltip(ActionItem.repair),
            hiltiServicesDisable: this.hiltiServicesDisable,
          }}
          onClearAllSelection={onClearAllSelection}
          onSelectAll={onSelectAll}
          actionBarSettings={actionBarSettings}
          getAssetCodeList={this.props.getAssetCodeList}
          closeMoreFilter={this.props.closeMoreFilter}
        />

        {this.renderDeleteComponent()}
        {this.renderStatusComponent()}
        {this.renderSortingModel()}
        {this.state.labelPanelOpen && (
          <OutsideClickAlerter
            onClickAway={this.handleLabelClose}
            ignoreClickedElement={["LabelAction"]}
            render={() => (
              <DialogPosition zindex={999} left={this.state.leftPosition}>
                <SuspenseWrapper>
                  <AssetLabels
                    selectedRow={checkedAssetListInfo}
                    clickedOutside={!this.state.labelPanelOpen}
                    labelPanelOpen={this.state.labelPanelOpen}
                    createAssetLabel={createAssetLabel}
                    associateAssetLabelDetails={associateAssetLabelDetails}
                    assetAssociatedLabels={assetAssociatedLabels}
                  />
                </SuspenseWrapper>
              </DialogPosition>
            )}
          />
        )}

        {this.renderAddAssetContainerModel()}
        {this.state.showImportAssetModal && (
          <Dialog onClose={this.closeImportAssetDialog} headerText={t("assets:IMPORT_ASSET")}>
            <SuspenseWrapper>
              <ImportAssets importToken={this.handleToken} closeModal={this.closeImportAssetDialog} />
            </SuspenseWrapper>
          </Dialog>
        )}
        {this.state.showCompleteServiceModal && (
          <Dialog onClose={this.closeCompleteServiceDialog} headerText={t("actionBar:COMPLETESERVICE")}>
            <SuspenseWrapper>
              <ImportCompleteServices importToken={this.handleToken} closeModal={this.closeCompleteServiceDialog} />
            </SuspenseWrapper>
          </Dialog>
        )}
        {this.state.showDiscardDialog && (
          <SuspenseWrapper>
            <DiscardDialog handleClose={this.cancelFormClose} handleConfirm={this.confirmFormClose} t={t} />
          </SuspenseWrapper>
        )}
        {this.state.showSettingModal && (
          <SuspenseWrapper>
            <GridSettingModal
              clearDefaultGridFields={clearDefaultGridFields}
              closeMoreFilter={closeMoreFilter}
              columns={columns}
              columnFields={assetFields}
              type={ModuleName.assets}
              onClose={this.closeSettingDialog}
              saveCustomGridView={saveCustomGridView}
              getSystemView={getSystemView}
              resetOriginalView={resetOriginalView}
              selectedCustomView={selectedCustomView}
              defaultGridColumns={defaultGridColumns}
            />
          </SuspenseWrapper>
        )}

        {this.state.showMultiEditModal && (
          <SuspenseWrapper>
            <AssetMultiEditContainer
              closeEdit={this.closeEditModal}
              checkedAssetListInfo={this.props.checkedAssetListInfo}
              shouldGetCommonAttributes={true}
            />
          </SuspenseWrapper>
        )}
        {this.renderAssetQuickTransfer()}
        {this.state.showDiscardDialogForQuickTransfer && (
          <SuspenseWrapper>
            <DiscardDialog
              handleClose={this.cancelQuickTransfer}
              handleConfirm={this.confirmQuickTransfer}
              t={this.props.t}
              confirmHeading={"qtyItems:QUICK_TRANSFER_WARNING_HEADER_TEXT"}
              confirmInfoText={"qtyItems:QUICK_TRANSFER_WARNING_BODY_TEXT"}
              okLabel={"common:CONTINUE"}
              crossIcon={true}
              isCustom={true}
            />
          </SuspenseWrapper>
        )}

        {this.state.showHiltiServicesMenu && (
          <SuspenseWrapper>
            <HiltiServicesWrapper
              history={history}
              assetFields={assetFields}
              selection={checkedAssetListInfo.checkedRows}
              checkedListInfo={checkedAssetListInfo.checkedListInfo}
              companyDetails={companyDetails}
              t={t}
              leftPosition={this.state.leftPosition}
              closeHiltiServicesMenu={this.closeHiltiServicesMenu}
              showHiltiServicesMenu={this.state.showHiltiServicesMenu}
              userMeData={userMeData}
            />
          </SuspenseWrapper>
        )}
      </>
    );
  }
}

export default withRouter(withTranslation()(AssetsSummary));
