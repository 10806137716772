import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <polygon points="17.7847884 6 15.6842105 5 4.10526316 5.01 2 7 2 17 4.10526316 18.99 15.6842105 19 17.7847884 18 22 12" />
    </g>
  </svg>
);

export default SVG;
