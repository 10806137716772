import { MonitorState } from "TARGET_BUILD/common/models/asset/monitoringState";
import React from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import styled from "custom-styled-components";
import { colorPalette } from "am-web-ui-shared";

// We can't use the component badge here, due to inconsistencies!
const Badge = styled.span<{ color: { background: string; color: string } }>`
  height: 24px;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  text-align: center;
  border-radius: 12px;
  padding: 4px 12px;
  background: ${(props) => props.color.background};
  color: ${(props) => props.color.color};
`;

const BADGE_COLORS = {
  TIMEOUT: { color: colorPalette.yellow, background: colorPalette.warningSecondaryYellow },
  FOUND_INTERNALLY: { color: colorPalette.statusgreen, background: colorPalette.statusLightGreen },
  FOUND_EXTERNALLY: { color: colorPalette.lightRedColor, background: colorPalette.pink },
};

type Props = { monitoringState: MonitorState };
const VALID_MONITORING_STATES = [MonitorState.TIMEOUT, MonitorState.FOUND_INTERNALLY, MonitorState.FOUND_EXTERNALLY];

export default function TrackingStatusBadges({ monitoringState }: Props) {
  const { t } = useTranslation();

  const monitoringBadge =
    monitoringState === MonitorState.ON ? (
      <Badge
        color={{ background: colorPalette.lightBlue, color: colorPalette.statusBlue }}
        data-status={MonitorState.ON}
      >
        {t("lostAndFound:MONITORING")}
      </Badge>
    ) : null;

  const statusBadge = VALID_MONITORING_STATES.includes(monitoringState) ? (
    <Badge color={BADGE_COLORS[monitoringState]} data-status={monitoringState}>
      {t(`lostAndFound:${monitoringState}`)}
    </Badge>
  ) : null;

  return (
    <>
      {monitoringBadge}
      {statusBadge}
    </>
  );
}
