import React from "react";
import styled from "styled-components";
import { SquareIcon } from "../icons/iconWrapper";
import Prompt from "../prompt/prompt";
import IpromptWrapperProps from "./iPromptWrapper";

export const HeaderContainer = styled.header`
  align-items: center;
  background-color: ${(props) => props.theme.colors.base.white};
  border: 2px solid ${(props) => props.theme.colors.base.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
`;
export const HeaderLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const HeaderLabel = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  padding-right: 10px;
`;
const Cross = styled.div`
  display: flex;
  .icon {
    cursor: pointer;
  }
`;

export const getHeaderComponent = () => {
  return (props) => {
    return (
      <HeaderContainer>
        <HeaderLabelContainer>
          <HeaderLabel>{props.header}</HeaderLabel>
        </HeaderLabelContainer>
        <Cross onClick={props.handleClose}>
          <SquareIcon name="Cross" className="icon" />
        </Cross>
      </HeaderContainer>
    );
  };
};

export const PromptWrapper = (props: IpromptWrapperProps) => {
  return (
    <Prompt
      {...props}
      handleClose={props.handleClose}
      icon={props.icon}
      handleConfirm={props.handleConfirm}
      header={props.header}
      cancelLabel={props.cancelLabel}
      okLabel={props.okLabel}
      wrapperStyleAttributes={{ width: "960px" }}
      className="hint-dialog hint-dialog-withList"
      headerComponent={getHeaderComponent()}
    />
  );
};

export default PromptWrapper;
