import { SharedUtils } from "am-web-ui-shared/helpers";
import { IAssetDetailsData } from "TARGET_BUILD/common/models/asset/assetModel";
import { AssetSubType } from "../../common/utils/enums/assetEnum";
import { storageGetItem } from "../../common/utils/storageUtils";

export const VIEW_LOCATION_DETAILS = "view_location_details";
export const VIEW_LOCATION_DETAILS_MAP = "view_location_detail_map";
export const VIEW_SMART_INVENTORY_ASSET = "view_smart_inventory_asset";

// Functions
export type LocationDetailsPayload = {
  asset_type: AssetSubType;
  scan_code_type: string;
  tenant_id: number;
  user_role: string;
};

export const trackLocationDetails = (asset: Partial<IAssetDetailsData>) => {
  if (!asset || !asset.assetSubType || !asset.scanCodeTypeDetails) {
    return;
  }
  const payload: LocationDetailsPayload = {
    asset_type: asset.assetSubType.code,
    scan_code_type: asset.scanCodeTypeDetails.code,
    tenant_id: asset.tenantId,
    user_role: storageGetItem("userRole"),
  };
  SharedUtils.pushDataToGA(VIEW_LOCATION_DETAILS, null, payload);
};

export const trackLocationDetailsMap = () => {
  SharedUtils.pushDataToGA(VIEW_LOCATION_DETAILS_MAP, null, null);
};

export const trackSmartInventoryAsset = (assetSubType: AssetSubType) => {
  const payload = {
    asset_type: assetSubType,
  };
  SharedUtils.pushDataToGA(VIEW_SMART_INVENTORY_ASSET, null, payload);
};
