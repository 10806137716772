import { ISagaModule } from "redux-dynamic-modules-saga";
import IUserSettingModuleState from "./iUserSettingModuleState";
import userSettingReducer from "./userSettingReducer";
import userSettingSaga from "./userSettingSaga";

export const userSettingModule: ISagaModule<IUserSettingModuleState> = {
  id: "userSettingModule",
  reducerMap: {
    UserSetting: userSettingReducer,
  },
  sagas: [userSettingSaga],
};

export default userSettingModule;
