import { Ellipsis } from "am-web-ui-shared/components";
import React from "react";
import { HelperLodash } from "am-web-ui-shared/helpers";
import styled from "styled-components";
import { ServiceStatusStates } from "../model/iserviceList";

export const ServiceStatusStyle = styled.span.attrs({
  id: "locationBadge",
})`
  height: 24px;
  text-align: center;
  &.a {
    background-color: ${(props) => props.theme.locationDetail.locationBadge.lightGrayishCyanLimeGreen};
    color: ${(props) => props.theme.locationDetail.locationBadge.darkLimeGreen};
  }
  &.d {
    background-color: ${(props) => props.theme.statusStyle.Retired.backgroundColor};
    color: ${(props) => props.theme.statusStyle.Retired.color};
  }
  &.e {
    background-color: ${(props) => props.theme.locationDetail.locationBadge.veryPaleRed};
    color: ${(props) => props.theme.locationDetail.locationBadge.strongRed};
  }
  border-radius: 12px;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  padding: 0 18px;
`;
export const getClassName = (status: any) => {
  switch (status) {
    case ServiceStatusStates.COMPLETED:
      return "a";
    case ServiceStatusStates.UPCOMING:
      return "d";
    case ServiceStatusStates.OVERDUE:
      return "e";
    default:
      return null;
  }
};
export const ServiceStatus = (props: any) => {
  const { column, showTooltip, tooltipValue } = props;
  const propertyName = column.bindProperties[0].properties[0].name;
  const statusData = {
    status: props && HelperLodash.get(props.data, propertyName),
  };
  return statusData.status && statusData.status.code ? (
    <Ellipsis id={propertyName} showTooltip={showTooltip} tooltipValue={tooltipValue}>
      <ServiceStatusStyle className={getClassName(statusData.status.code)}>
        {statusData.status.value}
      </ServiceStatusStyle>
    </Ellipsis>
  ) : null;
};

export default ServiceStatus;
