/**
 * Represents a Upfront Expansion Panel State with its details
 *
 * @prop isPanelExpanded : is panel expanded
 * @prop associatedRowId : associated row id
 */
export default class UpfrontExpansionPanelState {
  constructor(
    public isPanelExpanded: boolean,
    public associatedRowId: string | number,
  ) {}
}
