import React from "react";
import IlockedPanel from "TARGET_BUILD/common/modules/lockedPanel/ilockedPanel";
import LockedPanel from "TARGET_BUILD/common/modules/lockedPanel/lockedPanel";

export const LockedPanelList = ({ items }: { items: IlockedPanel[] }) => {
  return (
    <>
      {items.map((item) => (
        <LockedPanel {...item} key={item.id} />
      ))}
    </>
  );
};

export default LockedPanelList;
