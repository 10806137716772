import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M16.3137085,5 L19.1421356,7.82842712 L14.899,12.071 L19.1421356,16.3137085 L16.3137085,19.1421356 L12.071,14.899 L7.82842712,19.1421356 L5,16.3137085 L9.242,12.071 L5,7.82842712 L7.82842712,5 L12.071,9.242 L16.3137085,5 Z" />
    </g>
  </svg>
);

export default SVG;
