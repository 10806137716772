import { CellTypes, ShowMoreLess } from "am-web-ui-shared/components";
import { LocaleManager } from "am-web-ui-shared/helpers";

// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import { ValidationConstants } from "../../../utils/enums/validationConstants";
import { ellipsis } from "../../../utils/styledUtils";
import { ITransferCard } from "./iCustomGridCardProps";

const StyledRow = styled("div")`
  display: flex;
  margin: 16px 0;
  padding: 0 16px;
`;

const FieldLabel = styled.div`
  line-height: 16px;
  min-height: 16px;
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.normalSteelText.color};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  ${() => ellipsis("97%")}
`;
const FieldValue = styled.div`
  line-height: 20px;
  min-height: 20px;
  color: ${(props) => props.theme.boldSteelText.color};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  ${() => ellipsis("97%")}
`;

const ShowMoreFieldValue = styled.div`
  line-height: 20px;
  min-height: 20px;
  white-space: normal !important;
  word-break: break-word;
  word-wrap: break-word;
  text-align: justify;
  color: ${(props) => props.theme.boldSteelText.color};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  ${() => ellipsis("97%")}
`;

class TransferCard extends React.Component<ITransferCard> {
  getFieldValue = (value, column) => {
    if (!value) {
      return null;
    }
    let fieldValue;
    switch (column.type) {
      case CellTypes.Date:
        fieldValue = this.getDateTypeFieldValue(value);
        break;
      case CellTypes.Object:
        fieldValue = this.getObjectTypeFieldValue(value, column);
        break;
      case CellTypes.EmptyCell:
        fieldValue = "";
        break;
      default:
        fieldValue = column.appendHash ? `#${value}` : value || "";
    }
    return fieldValue;
  };

  getDateTypeFieldValue = (value) =>
    LocaleManager.dateHelpers.getDateInTenantTimezone(typeof value === "object" ? value.transferDate : value);

  getObjectTypeFieldValue = (value, column) =>
    column.appendHash ? `#${value[column.key]}` || "" : value[column.key] || "";

  calculateCellPercentage = (cellSize: number) => {
    if (cellSize) {
      const percentage = (
        (cellSize / ValidationConstants.MAX_LENGTH.NUM_12) *
        ValidationConstants.MAX_LENGTH.NUM_100
      ).toFixed(0);
      return percentage.toString().concat("%");
    }
    return null;
  };

  renderColumn = (column, value) => {
    const { cellSize, label, type, showMoreORLess } = column;
    const { dependencies, t } = this.props;
    if (type === "custom") {
      const dependencyComponent = dependencies[column.component];
      return dependencyComponent(this.props, value, column);
    } else if (type === "customLabel") {
      const dependencyComponent = dependencies[column.component];
      return (
        <div style={{ width: this.calculateCellPercentage(cellSize) }}>
          {dependencyComponent(this.props, value, column)}
        </div>
      );
    }
    return (
      <div style={{ width: this.calculateCellPercentage(cellSize) }}>
        {label && <FieldLabel>{t(label)}</FieldLabel>}
        {showMoreORLess ? (
          <ShowMoreFieldValue>
            <ShowMoreLess
              showMoreLbl={t("filters:SHOW_MORE")}
              showLessLbl={t("filters:SHOW_LESS")}
              content={value}
              limit={ValidationConstants.MAX_LENGTH.NUM_200}
            />
          </ShowMoreFieldValue>
        ) : (
          <FieldValue title={value || ""}> {value || ""}</FieldValue>
        )}
      </div>
    );
  };

  render() {
    const { config, transferHistoryResponse } = this.props;
    return config.map((row) => (
      <StyledRow>
        {(row.columns || []).map((column) => {
          const value = this.getFieldValue(transferHistoryResponse[column.bindProperty], column);
          return column.hideIfEmpty
            ? value
              ? this.renderColumn(column, value)
              : null
            : this.renderColumn(column, value);
        })}
      </StyledRow>
    ));
  }
}

export default withTranslation()(TransferCard);
