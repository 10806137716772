import React from "react";
import { FriendlyName } from "../../../../components/DetailsPanel";
import { Ellipsis, IconName, SquareIcon } from "am-web-ui-shared/components";
import styled from "styled-components";
import { IAlertLabelValueItemWithTooltip } from "./iNormalBoldWithTooltip";

export const RowWrapper = styled.div`
  padding-left: 40px;
  &.row-extra-space {
    padding-top: 16px;
  }
`;
export const SectionWrapper = styled.div`
  padding-left: 40px;
  display: flex;
  && {
    .normal-bold-tooltip {
      padding-left: 8px;
    }
    && {
      align-items: center;
    }
  }
`;

const NormalBoldWithTooltip = ({
  id,
  title,
  value,
  tooltipValue,
  tooltipId,
  isPadding = true,
}: IAlertLabelValueItemWithTooltip) => (
  <div>
    <RowWrapper className={isPadding ? "row-extra-space" : ""}>
      <FriendlyName boldId={id} boldText={title} isNormal={true} noBreak />
    </RowWrapper>
    <SectionWrapper>
      <FriendlyName boldId="txtName" boldText={value} isNormal={false} noBreak />
      {tooltipValue ? (
        <Ellipsis
          data-testid="tooltip"
          id={tooltipId}
          className="normal-bold-tooltip"
          showTooltip={true}
          tooltipValue={tooltipValue}
        >
          <SquareIcon name={IconName.Info} id={tooltipId} />
        </Ellipsis>
      ) : null}
    </SectionWrapper>
  </div>
);

export default NormalBoldWithTooltip;
