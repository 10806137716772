import React, { useEffect, useRef, useState } from "react";
import ErrorBoundary from "TARGET_BUILD/common/components/errorBoundary/errorBoundary";
import styled from "styled-components";
import SuggestedTransferList from "./list";
import { Footer } from "am-web-ui-shared/components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleAlertList } from "../../actions/alertNotificationAction";
import SuggestedTransfersFilters from "./filters";
import { ToastrType, showToastr } from "TARGET_BUILD/common/actions/toastrAction";
import Filter, { FilterAttributes } from "TARGET_BUILD/common/models/filter";
import { filtersQuery } from "TARGET_BUILD/common/utils/commonUtils";
import ActiveTrackingApi, { IErrorResponse } from "TARGET_BUILD/common/api/activeTrackingApi";
import { isErrorResponse, validateResponse as getErrorFromResponse } from "TARGET_BUILD/common/utils/errorUtils";
import {
  SuggestedTransferAttributes,
  SuggestedTransferListResponse,
} from "TARGET_BUILD/common/models/alert/suggestedTransfer";
import { requestFinished, requestInitiated } from "TARGET_BUILD/common/actions/activityAction";
import { PAGE_SIZE, REFRESH_LIST_WAIT_TIME } from "./configConstants";
import { AlertNotificationAction } from "TARGET_BUILD/common/actionConstants";

const Wrapper = styled.div`
  margin: 0 auto;
  width: ${(props) => props.theme.spacing(124.5)};
`;

const LOADER_KEY = "SUGGESTED_TRANSFER_BLOCKING_LOADER";

const SuggestedTransferTabContent = () => {
  const [filterAttrs, setFilterAttrs] = useState<FilterAttributes>({});
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);
  const [suggestedTransferResponse, setSuggestedTransferResponse] = useState<SuggestedTransferListResponse>(null);
  const [appliedFilterQuery, setAppliedFilterQuery] = useState<Filter[]>([]);
  const [searchstring, setSearchstring] = useState("");
  const timerRef = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeAlerts = () => dispatch(toggleAlertList());

  const showErrorToast = (errorResponse: IErrorResponse) => {
    // getErrorFromResponse utility is used when response status code is not 2xx
    // for other cases, get error directly from response
    const error = getErrorFromResponse(errorResponse) ?? errorResponse.errors?.[0];
    if (error) {
      dispatch(showToastr(ToastrType.error, `errorCodes:${error.code}`));
    }
  };

  const getFilterAttributes = async (appliedFilterQuery: Filter[]) => {
    const filterString = filtersQuery(appliedFilterQuery);
    const attrsResponse = await ActiveTrackingApi.getSuggestedTransfersAttributes(filterString);
    if (isErrorResponse(attrsResponse)) {
      showErrorToast(attrsResponse as IErrorResponse);
    } else {
      setFilterAttrs((attrsResponse as SuggestedTransferAttributes).response);
    }
  };
  const fetchSuggestedTransfers = async (offset: number, filters?: Filter[], searchQ?: string) => {
    const apiResponse = await ActiveTrackingApi.getSuggestedTransfers(
      offset,
      PAGE_SIZE,
      filtersQuery(filters ? filters : appliedFilterQuery),
      searchQ || searchQ === "" ? searchQ : searchstring,
    );
    if (isErrorResponse(apiResponse)) {
      showErrorToast(apiResponse as IErrorResponse);
    } else {
      setSuggestedTransferResponse(apiResponse as SuggestedTransferListResponse);
      if (!filters?.length && !searchQ?.length) {
        dispatch({
          type: AlertNotificationAction.GET_SUGGESTED_TRANSFER_COUNT_SUCCESS,
          suggestedTransfersCount: (apiResponse as SuggestedTransferListResponse).totalRecords,
        });
      }
    }
  };

  const loadList = (filterQuery?: Filter[], searchQ?: string) => {
    fetchSuggestedTransfers(0, filterQuery, searchQ);
    getFilterAttributes(filterQuery);
  };
  const handleSearchstringChange = (newValue: string) => {
    setSearchstring(newValue);
    fetchSuggestedTransfers(0, appliedFilterQuery, newValue.trim());
  };
  const closeMoreFilters = () => setIsMoreFiltersOpen(false);

  useEffect(() => {
    loadList([]);
  }, []);

  const handleConfirmTransfer = async (suggestedTransferIds: string[]) => {
    // show loader to block user
    requestInitiated(LOADER_KEY);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    const apiResponse = await ActiveTrackingApi.confirmSuggestedTransfers(suggestedTransferIds);
    if (isErrorResponse(apiResponse)) {
      requestFinished(LOADER_KEY);
      showErrorToast(apiResponse);
    } else {
      dispatch(
        showToastr(
          ToastrType.success,
          suggestedTransferIds.length === 1
            ? "common:TRANSFER_REQUEST_SUBMITTED"
            : "common:MULTIPLE_TRANSFER_REQUESTS_SUBMITTED",
        ),
      );
      // Wait 3 seconds before refreshing the list
      // because the confirm process is async and we
      // have no idea how long it might take.
      timerRef.current = setTimeout(() => {
        requestFinished(LOADER_KEY);
        loadList();
      }, REFRESH_LIST_WAIT_TIME);
    }
  };

  const handleNoSearchResultsLink = () => {
    handleSearchstringChange("");
  };

  return (
    <ErrorBoundary>
      <Wrapper>
        <SuggestedTransfersFilters
          isMoreFiltersOpen={isMoreFiltersOpen}
          getFilterAttributes={getFilterAttributes}
          filterAttributes={filterAttrs}
          setIsMoreFiltersOpen={setIsMoreFiltersOpen}
          handleListChange={loadList}
          appliedFilterQuery={appliedFilterQuery}
          setAppliedFilterQuery={setAppliedFilterQuery}
          searchstring={searchstring}
          handleSearchstringChange={handleSearchstringChange}
        />
        {suggestedTransferResponse ? (
          <SuggestedTransferList
            isMoreFiltersOpen={isMoreFiltersOpen}
            fetchSuggestedTransfers={fetchSuggestedTransfers}
            suggestedTransfersResponse={suggestedTransferResponse}
            closeMoreFilters={closeMoreFilters}
            onConfirmTransfer={handleConfirmTransfer}
            searchstring={searchstring}
            handleNoSearchResultsLink={handleNoSearchResultsLink}
          />
        ) : null}
      </Wrapper>
      <Footer buttonType="cta" buttonText={t("common:DONE")} onClick={closeAlerts} />
    </ErrorBoundary>
  );
};

export default SuggestedTransferTabContent;
