/**
 * @description - Enum that contains the assign "All" or "Individual" assets options used at transfer asset "Assign to worker" and "Set dates" steps.
 */
enum TransferAssignTo {
  ALL = "ALL",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum TransferToListName {
  LIST = "list",
  TRANSFER_DIALOGUE_LIST = "transferDialogueList",
  STORAGE_ASSETS = "storageAssetData",
}

export enum WhereToEnumMode {
  LOCATION = "location",
  STORAGE_ASSET = "storageAsset",
  WORKER = "worker",
}
export enum TransferStatusEnum {
  RECALLED = "RECALLED",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  TRANSFER = "TRANSFER",
}

export const DELIVERY_NOTES_SEND_TO_EMAIL_LIMIT = 10; // Limit of sending email to workers.

export default TransferAssignTo;
