import { IconComponent, IconName } from "am-web-ui-shared/components";
import React, { useEffect, useState } from "react";
import { SimpleExpansionPanel } from "../../../../../components/DetailsPanel/index";
import UtilizationDetailsExpanded from "./utilizationDetailsExpanded";
import { useTranslation } from "react-i18next";
import { IUtilizationDetailsView } from "./model/iUtilizationDetailsView";
// @ts-ignore
import styled from "custom-styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "TARGET_BUILD/common/store/store";
import { SharedUtils } from "am-web-ui-shared/helpers";
import { resetAsseUtilizationSummary } from "../../../actions/assetsActionRoot";
import { Box } from "@mui/material";
/**
 * @description - This is the Utilization Details View section on the asset details panel.
 */

const HE_UTILIZATION_EXPANDED = "HE_utilization_expanded";

const EmptyMessageWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
  text-align: left;
  margin-right: 4px;
`;

const UtilizationDetailsView = (props: IUtilizationDetailsView) => {
  const { isExpanded } = props;
  const [isBarChartVisible, setIsBarChartVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isExpanded && !utilizationSummary?.assetId) {
      const { getAssetUtiliationSummary } = props;
      getAssetUtiliationSummary(assetDetailsData?.assetId);
    }
  }, [isExpanded]);

  const closeBarChartDialog = () => {
    setIsBarChartVisible(false);
  };
  const onViewDetailClick = () => {
    setIsBarChartVisible(true);
  };
  const {
    utilizationSummary,
    utilizationDetails,
    getAssetUtiliationSummary,
    getAssetUtilizationDetails,
    assetDetailsData,
  } = props;
  const { t } = useTranslation();
  const userData = useSelector(({ UserReducer }: RootState) => UserReducer?.userMeData);
  useEffect(() => {
    if (isExpanded) {
      const payload = {
        tenant_id: assetDetailsData.tenantId,
        user_role: userData?.userRole?.code,
      };
      SharedUtils.pushDataToGA(HE_UTILIZATION_EXPANDED, null, payload);
    }
  }, [isExpanded]);

  useEffect(() => {
    return () => {
      dispatch(resetAsseUtilizationSummary()); //whenever the component removes it will executes
    };
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", ml: 5 }}>
        <TitleWrapper>{t("assets:UTILIZATION_SUMMARY")}</TitleWrapper>
        <IconComponent
          icon={IconName.Info}
          id={"infoTooltipStorage"}
          className="infoIconStorage"
          getContent={() => t("assets:UTILIZATION_SUMMARY_TOOLTIP")}
          place={"right"}
        />
      </Box>

      {utilizationSummary?.error ? (
        <EmptyMessageWrapper>{t("common:NO_DATA_AVAILABLE")}</EmptyMessageWrapper>
      ) : (
        <UtilizationDetailsExpanded
          barChartData={utilizationDetails}
          chartData={utilizationSummary}
          getAssetUtiliationSummary={getAssetUtiliationSummary}
          getAssetUtilizationDetails={getAssetUtilizationDetails}
          assetDetailsData={assetDetailsData}
          isBarChartVisible={isBarChartVisible}
          onViewDetailClick={onViewDetailClick}
          closeBarChartDialog={closeBarChartDialog}
          isExpanded={isExpanded}
        />
      )}
    </>
  );
};

/**
 * @exports UtilizationDetailsView - Utilization Details View  section that renders both header and detail components.
 */
export default UtilizationDetailsView;
