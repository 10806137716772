import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="#524F53"
      fillRule="evenodd"
      d="M22 16v6h-8v-6h8zM14 1l2 2-3 3 3 3 3-3 2 2-2.401 4H14l-.001.999L13 13l-8 8-4-4 8-8V4l5-3zm2.286 18.6h-1.143v1.2h1.143v-1.2zm2.285 0H17.43v1.2h1.142v-1.2zm2.286 0h-1.143v1.2h1.143v-1.2zm-4.571-2.4h-1.143v1.2h1.143v-1.2zm2.285 0H17.43v1.2h1.142v-1.2zm2.286 0h-1.143v1.2h1.143v-1.2zM17 13v1h2v-1h2v1h1v1h-8v-1h1v-1h2z"
    />
  </svg>
);

export default SVG;
