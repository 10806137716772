// @ts-ignore
import styled from "styled-components";

export const StyledNormalText = styled.div`
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  color: ${(props) => props.theme.colors.base.steel};
  word-break: break-all;
  width: 100%;
`;

export const StyledNormalTextForm = styled(StyledNormalText as any)`
  padding: 4px 0;
`;

export const StyledBoldText = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  color: ${(props) => props.theme.colors.base.steel};
  word-break: break-all;
  width: 100%;
  margin-top: 5px;
`;

export const styledNormalPopup = styled.div;

export const ReadOnlyCheckBoxContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const DefaultUnChecked = styled.span`
  border: 2px solid ${(props) => props.theme.colors.base.steel};
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.theme.colors.base.white};
`;

export const ReadOnlyCheckBoxLabel = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-size: ${(props) => props.theme.fontSize.base};
  margin-left: 16px;
  &.lbl {
    flex: 1;
  }
`;
export const DefaultChecked = styled.span`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  &:before {
    content: "";
    transform: rotate(45deg);
    border-width: 0 4px 4px 0;
    width: 9px;
    margin: 0px 6px 0px 4px;
    display: inline-block;
    border-color: ${(props) => props.theme.colors.base.steel};
    height: 15px;
    border-style: solid;
  }
`;
