import { TParam } from "../../localization/types";

/**
 * interface ITabMenu
 * path : to get path to redirect to
 * index : routed index
 * labelKey: key for localization
 * isClickable : whether given tab can be routed or not
 * subMenuItems ; whether tab has nested routing
 */
export interface ITabMenu {
  authKey?: string;
  htmlId: string;
  licenseCode?: ITabItemsLicenseCode;
  path?: string;
  index: number;
  labelKey: TParam;
  isClickable: boolean;
  isHidden?: boolean;
  subMenuItems?: ITabMenu[];
  matchOn?: RegExp;
  featureKey?: string;
  isDisabled?: boolean;
  isLocked?: boolean;
  isBeta?: boolean;
}

export enum ITabItemsLicenseCode {
  QUANTITY_ITEMS = "QTY_ITEMS",
  ASSET_COST = "ASSET_COST",
  DASHBOARD = "DASHBOARD",
  PROACTIVE_TRACKING = "PROACTIVE_TRACKING",
}
