import React from "react";
import { withTranslation } from "react-i18next";
import { ILazyModule } from "./iLazyModuleProps";
import { ILazyModuleState } from "./iLazyModuleState";

class LazyLoadModule extends React.Component<ILazyModule, ILazyModuleState> {
  // state initialization
  readonly state: ILazyModuleState = {
    error: null,
    isError: false,
  };

  componentDidCatch(error) {
    this.setState({ isError: true, error });
  }

  render() {
    const { view, t } = this.props;
    const { isError } = this.state;

    // error handling case, when error boundry raise exception
    if (isError) {
      return <div>{t("common:ERROR_LOADING_MODULE")}</div>;
    }

    if (view) {
      // passing location,history and match as second parimeter.
      return React.createElement(view, this.props);
    } else {
      return <div>{t("common:LOADING_MODULE")}</div>;
    }
  }
}

export default withTranslation()(LazyLoadModule);
