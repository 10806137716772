import { IconName } from "am-web-ui-shared/components";
import React from "react";
import { Trans } from "react-i18next";
import { IconBold } from "../../DetailsPanel/index";

export const chargeSetting = () => {
  return (
    <IconBold
      id="pnlChargeSetting"
      iconUrl={IconName.AssetCosts}
      boldId="lblChargeSetting"
      boldText={<Trans>{"common:ASSET_COSTS"}</Trans>}
    />
  );
};

/**
 * @description - Exporting Array of the components that are used at the summary part of the responsible worker section used on asset details.
 */
export default [chargeSetting];
