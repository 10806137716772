import { RootState } from "../../../store/store";

export const getCompanyDetails = ({ Company }: RootState) => Company?.companyDetails;
export const getCompanyModules = ({ Company }: RootState) => Company?.companyDetails?.modules;
export const getListOfActiveModules = ({ Company }: RootState) => Company?.companyDetails?.activeModules;
export const getListLoader = ({ Loader }: RootState) => Loader?.list;
export const getDetailLoader = ({ Loader }: RootState) => Loader?.detail;
export const getBatteriesAvailableForExchange = ({ Hint }: RootState) => Hint?.batteriesAvailableForExchange;
export const getTotalBatteries = ({ Hint }: RootState) => Hint?.soHBatteryOverview?.total;
export const getGoodBatteries = ({ Hint }: RootState) => Hint?.soHBatteryOverview?.good;
export const getBadBatteries = ({ Hint }: RootState) => Hint?.soHBatteryOverview?.bad;
export const getBadSoHBatteryList = ({ Hint }: RootState) => Hint?.badSoHBatteryList;
export const getBatteryDetails = ({ Hint }: RootState) => Hint?.batteryDetails;
export const getIdleAssetsList = ({ IdleAssets }: RootState) => IdleAssets?.idleAssetsList;
export const getTotalRecords = ({ IdleAssets }: RootState) => IdleAssets?.idleAssetsList?.totalRecords;
export const getIsAllSelected = ({ IdleAssets }: RootState) => IdleAssets?.isAllSelected;
export const getShowNoRecordFoundView = ({ IdleAssets }: RootState) => IdleAssets?.showNoRecordFoundView;
export const getTransferCartList = ({ TransferCart }: RootState) => TransferCart?.list;
export const getSortQuery = ({ IdleAssets }: RootState) => IdleAssets?.sortQuery;
export const getSearchstring = ({ IdleAssets }: RootState) => IdleAssets?.searchString;
export const getAppliedFilterQuery = ({ IdleAssets }: RootState) => IdleAssets?.appliedFilterQuery;
export const getFilterAttributes = ({ IdleAssets }: RootState) => IdleAssets?.filterAttributes;
export const getFilterQuery = ({ IdleAssets }: RootState) => IdleAssets?.filterQuery;
export const getFilterAttributesCount = ({ IdleAssets }: RootState) => IdleAssets?.filterAttributesCount;
export const getFilterAttributesSearchCount = ({ IdleAssets }: RootState) => IdleAssets?.filterAttributesSearchCount;
export const getAssetGroupsForFilter = ({ AssetGroup }: RootState) => AssetGroup?.assetGroupsForFilter;
export const getLocationTypeFilter = ({ IdleAssets }: RootState) => IdleAssets?.locationTypeFilter;
export const getReports = ({ IdleAssets }: RootState) => IdleAssets?.reports;
export const getIdleAssetsConnected = ({ IdleAssets }: RootState) => IdleAssets?.idleAssetsConnected;
export const getIdleAssetsListExist = ({ IdleAssets }: RootState) => IdleAssets?.idleAssetsListExist;
