import styled from "styled-components";
// @ts-ignore;
import SelectedIcon from "../../../../app-ui/src/ui-assets/icons/ic_Selected.svg";

export const StyledDiv = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    &:checked {
      & + label span.styledBox {
        border: none;
        width: 24px;
        height: 24px;
        &:after {
          content: "";
        }
      }
    }
  }
`;
export const StyledInput = styled.input.attrs({
  type: "radio",
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;
export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  span {
    &.styledBox {
      display: inline-flex;
      height: 20px;
      width: 20px;
      background-color: ${(props) => props.theme.colors.base.white};
      border-radius: 50%;
      border: 2px solid ${(props) => props.theme.colors.base.steel};
      align-items: center;
      text-align: center;

      ::after {
        width: 24px;
        height: 24px;
        background: url(${SelectedIcon}) 0 0 no-repeat;
        background-size: contain;
      }
    }
    &.styledText {
      font-family: ${(props) => props.theme.fontRoman.fontFamily};
      font-weight: ${(props) => props.theme.fontRoman.fontWeight};
      line-height: 17px;
      word-break: break-all;
      white-space: pre-wrap;
      width: calc(100% - 36px);
      color: ${(props) => props.theme.colors.base.steel};
      padding-left: 10px;
    }
  }
`;
