import React from "react";
const SVG = () => (
  <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2009.000000, -3074.000000)">
        <g id="05-Icons-/-Location_Group_Empty" transform="translate(2008.000000, 3072.000000)">
          <g id="location-group-empty">
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M20.9497475,19.5355339 L23.0710678,21.6568542 L21.6568542,23.0710678 L19.5355339,20.9497475 L17.4142136,23.0710678 L16,21.6568542 L18.1213203,19.5355339 L16,17.4142136 L17.4142136,16 L19.5355339,18.1213203 L21.6568542,16 L23.0710678,17.4142136 L20.9497475,19.5355339 Z M15.6895709,14.8962156 C15.495764,14.4582659 15.3312825,14.0183871 15.2116217,13.5884912 C16.2526163,11.9279576 17.2594601,10.0079498 17.7274318,8.21647246 C18.1270769,8.07605295 18.5549763,8 19,8 C21.2114286,8 23,9.878 23,12.2 C23,12.803075 22.8533839,13.461131 22.6162918,14.1310104 L21.6568542,13.1715729 L19.5355339,15.2928932 L17.4142136,13.1715729 L15.6895709,14.8962156 Z M6.27256818,8.21647246 C6.74053989,10.0079498 7.7473837,11.9279576 8.78837825,13.5884912 C7.96227984,16.5563497 5,20 5,20 C5,20 1,15.35 1,12.2 C1,9.878 2.78857143,8 5,8 C5.4450237,8 5.87292306,8.07605295 6.27256818,8.21647246 Z M5,13 C5.828,13 6.5,12.328 6.5,11.5 C6.5,10.672 5.828,10 5,10 C4.172,10 3.5,10.672 3.5,11.5 C3.5,12.328 4.172,13 5,13 Z M19,13 C19.828,13 20.5,12.328 20.5,11.5 C20.5,10.672 19.828,10 19,10 C18.172,10 17.5,10.672 17.5,11.5 C17.5,12.328 18.172,13 19,13 Z M12,2 C14.7642857,2 17,4.191 17,6.9 C17,10.575 12,16 12,16 C12,16 7,10.575 7,6.9 C7,4.191 9.23571429,2 12,2 Z M12,9 C13.104,9 14,8.104 14,7 C14,5.896 13.104,5 12,5 C10.896,5 10,5.896 10,7 C10,8.104 10.896,9 12,9 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
