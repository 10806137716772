import React from "react";
import ContainerDimensions from "react-container-dimensions";
import IResponsiveMenuProps from "./iResponsiveMenuProps";
import Menu from "./menu";

class ResponsiveAction extends React.PureComponent<IResponsiveMenuProps> {
  render() {
    return <ContainerDimensions>{({ width }) => <Menu width={width} {...this.props} />}</ContainerDimensions>;
  }
}

export default ResponsiveAction;
