import React from "react";
import styled from "styled-components";
import { IFieldsetProps } from "./iFieldsetProps";

const StyledFieldset = styled.fieldset``;

class Fieldset extends React.PureComponent<IFieldsetProps> {
  render() {
    const { children, legend, ...props } = this.props;
    return (
      <StyledFieldset {...props}>
        <legend>{legend}</legend>
        {children}
      </StyledFieldset>
    );
  }
}

export default Fieldset;
