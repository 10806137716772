/*
  Tab Class
  @example :
  <Tab selected = {selectedIndex}  onSelect = {(currenIndex, label) =>
      {onselect callback with index and label as parameters}} >
      <Tab.TabItem label='Tab One'>
          First Tab Content
      </Tab.TabItem>
      <Tab.TabItem label='Tab Two'>
          <SecondTabComponent/>
      </Tab.TabItem>
    </Tab>

    Tab Props :
      selected : Index of the current selected tab. Takes 0 by default
      onSelect : callback function when tab is selected with parameters current index and label
      children : Tab item elements which contains all the tab items

      Tab.TabItem
        Props :
          label : Label of the tab item

    Feature Parity :
      1) TextWrap : Wrap large text for label of TabItem
      2) HorizontalScroll for tab headers : If number of tabs increases the view port width
      3) Themeing tab header as Props :  Pass color, background .. as props for the tab and tab items

*/

import React from "react";
import styled from "styled-components";
import { OutsideClickAlerter } from "../../components/outSideClickAlerter/outSideClickComponet";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import colorPalette from "../../materials/Colors";
import { HelperLodash } from "../../helpers";
import ITabProps from "./iTabProps";
import TabMenu from "./tabMenu";
import { MenuTabIndex } from "./tabEnum";
import ApplicationConstants from "../../constant/applicationConstants";

interface ITabLinkProp {
  selected?: boolean;
  disabled?: boolean;
  showMenuList?: boolean;
  tabWidth?: string;
  activeTabColor?: string;
}

const tabTypes = {
  menuItem: "menuItem",
  menuList: "menuList",
  subMenuItem: "subMenuItem",
};

export const TabLink = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  margin-bottom: -1px;
  opacity: ${(props) => (props.disabled ? ApplicationConstants.OPACITY.O50 : 1)};
  cursor: default;
  width: ${(props: ITabLinkProp) => (props.tabWidth ? props.tabWidth : "auto")};
`;
export const TabLinkLabel = styled.button`
  cursor: ${(props: ITabLinkProp) => (props.selected ? "default" : "pointer")};
  border: 0;
  border-bottom: 2px solid
    ${(props: ITabLinkProp) =>
      props.selected
        ? props.activeTabColor === "steel"
          ? colorPalette.steel
          : colorPalette.hiltiRed
        : colorPalette.transparent};
  background-color: ${colorPalette.transparent};
  position: relative;
  color: ${(props: ITabLinkProp) => (props.selected ? colorPalette.black : colorPalette.steel)};
  display: inline-flex;
  align-items: center;
  min-height: 47px;
  .lockIcon {
    margin-left: 10px;
  }
  &:disabled {
    cursor: default;
  }
  .flyoutArrow {
    position: absolute;
    left: 42.5%;
    top: 45px;
    width: 0;
    height: 0;
    border: 10px solid ${colorPalette.transparent};
    border-bottom: 12px solid ${colorPalette.white};
    z-index: 9999999;
  }

  @media (max-width: 1365px) {
    padding: 9px 1vw;
    font-size: 14px;
  }
  @media (min-width: 1366px) {
    padding: 9px 1.5vw;
  }
`;
const rotate90 = "rotate(90deg)";
const rotateMinus90 = "rotate(-90deg)";
export const TabLinkMenuArrow = styled.span`
  display: inline-flex;
  transform: ${(props: ITabLinkProp) => (props.showMenuList ? rotateMinus90 : rotate90)};
  margin-left: 8px;
`;

const TabLinkGroup = styled.div`
  border-bottom: 2px solid #e7e5e2;
  padding-left: 16px;
  padding-right: 16px;
  white-space: nowrap;
  display: flex;
`;

const TabItem = styled.div`
  padding-top: 16px;
`;

export const StyledSup = styled.sup`
  color: ${(props) => props.theme.colors.base.steel40};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 0 3px;
  margin-top: -8px;
`;

// the "BETA" label will not be translatable
const Beta = () => <StyledSup>BETA</StyledSup>;

/* Used to keep selected tab on uncontrolled Tabs instances */

export class Tab extends React.Component<ITabProps> {
  static TabItem: any;

  /**
   * @description function to get tab selected whenever tab is changed
   * @param nextIndex: index selected
   * @param disabled
   * @param type
   */
  changeTab(nextIndex, disabled, type) {
    const currentIndex = this.props.selected;
    if (
      (currentIndex !== nextIndex && !disabled) ||
      (currentIndex === nextIndex && (type === tabTypes.menuList || type === tabTypes.subMenuItem))
    ) {
      this.props.onSelect(nextIndex, type);
    }
  }

  /**
   * @description getID function to remove space if any and convert to lowercase
   * @param id {string} id of the tab
   */
  getId(id: string) {
    return id && id.replace(/\s/g, "").toLowerCase();
  }

  /**
   * @description subMenu to render the sub menu and should close when clicked outside the menu.
   * @memberof Tab
   *
   * @param tabProps : Object , conatins the props of tab
   *
   */

  handleOnClickAway = (tabProps) => {
    return this.props.isAdminTabClicked ? tabProps.toggleMenuListHandler() : null;
  };

  getSubMenuList = (tab, selected, activeTabColor) =>
    tab && tab.props.menuList
      ? tab.props.menuList
          .map((item) => {
            const menuList =
              item.menuList && item.menuList.map((subTab) => this.getChildTab(subTab, selected, activeTabColor));
            if (item.menuList && item.menuList.length && menuList.filter((listItem) => !!listItem).length) {
              return {
                authKey: item.authKey,
                menuHead: item.menuHead,
                menuList: React.Children.toArray(menuList),
              };
            }
            return null;
          })
          .filter((listItem) => !!listItem)
      : null;

  /**
   * @description Function to get menuList or to get the tab
   * @memberof Tab
   * @param tab  - menuList display
   * @param selected
   * @param activeTabColor
   */
  getChildTab = (tab, selected, activeTabColor) => {
    const CheckAuthorization = this.props.authorizationComponent;

    const subMenuList = this.getSubMenuList(tab, selected, activeTabColor);

    const renderTab = () =>
      tab && tab.props.label ? (
        <TabLink
          disabled={tab.props.disabled}
          onClick={() => {
            !tab.props.locked
              ? this.changeTab(tab.props.index, tab.props.disabled, tab.props.type)
              : tab.props.handleLockedTabClick();
          }}
          key={tab.props.index}
          selected={this.isSelected(selected, tab)}
          id={tab.props.htmlId}
          showMenuList={tab.props.showMenuList}
          tabWidth={tab.props.tabWidth}
        >
          <TabLinkLabel
            selected={
              selected === tab.props.index || (selected > MenuTabIndex.ADMINSTRATION && tab.props.htmlId === "adminTab")
            } // 7 is index of tab links
            showMenuList={tab.props.showMenuList}
            id={`${tab.props.htmlId}Label`}
            activeTabColor={activeTabColor}
            style={this.props.tabLinkStyles}
          >
            {tab.props.label}
            {tab.props.isBeta ? <Beta /> : null}
            {tab.props.type === tabTypes.menuList ? (
              <TabLinkMenuArrow showMenuList={tab.props.showMenuList}>
                <SquareIcon id="tabArrow" name={IconName.Arrow} className="small" imageSize={16} />
              </TabLinkMenuArrow>
            ) : null}
            {tab.props.showMenuList ? <span className="flyoutArrow" /> : null}
            {tab.props.locked ? (
              <SquareIcon id="lockIcon" name={IconName.LockIcon} className="small lockIcon" imageSize={24} />
            ) : null}
          </TabLinkLabel>
          {tab.props.showMenuList ? (
            <OutsideClickAlerter
              onClickAway={() => this.handleOnClickAway(tab.props)}
              ignoreClickedElement={["adminTab"]}
              render={() => (
                <TabMenu
                  CheckAuthorization={CheckAuthorization}
                  list={subMenuList}
                  toggleMenuListHandler={tab.props.toggleMenuListHandler}
                />
              )}
            />
          ) : null}
        </TabLink>
      ) : null;

    return tab && tab.props.authKey && CheckAuthorization && !tab.props.disabled
      ? CheckAuthorization({ yes: renderTab, permissionKey: tab.props.authKey, no: () => null })
      : renderTab();
  };
  /**
   * @description Function for selection of particular tab and its child
   * @memberof Tab
   * @param selectedIndex {String} - get the index number of the tab
   * @param tab
   */
  isSelected = (selectedIndex, tab) => {
    if (tab.props.type === tabTypes.menuItem || tab.props.type === tabTypes.subMenuItem) {
      return selectedIndex === tab.props.index;
    }
    return tab.props.menuList ? this.searchChildItems(tab.props.menuList, selectedIndex) : null;
  };
  /**
   * @description Function to search menuList(child) and return child whose selectedIndex matches
   * @memberof Tab
   * @param items
   * @param selectedIndex {String} - index number of the tab selected
   */

  searchChildItems = (items, selectedIndex) => {
    return items.find((item) =>
      item && item.menuList
        ? this.searchChildItems(item.menuList, selectedIndex)
        : (item && item.props.index) === selectedIndex,
    );
  };

  render() {
    const { selected, activeTabColor = "", tabWrapperStyles } = this.props;

    const tabs = React.Children.toArray(this.props.children);
    const sortedTabs = HelperLodash.orderBy(tabs, "props.disabled", "desc");
    return (
      <>
        <TabLinkGroup style={tabWrapperStyles} className="tab">
          {sortedTabs.map((tab) => {
            return this.getChildTab(tab, selected, activeTabColor);
          })}
        </TabLinkGroup>
        {this.props.showContent && HelperLodash.get(sortedTabs, selected)}
      </>
    );
  }
}
Tab.TabItem = TabItem;
export default Tab;
