import React from "react";
import ReactDOM from "react-dom";
import Button from "../../elements/Button/button";
import { addClassOnBody, removeClassOnBody } from "../../helpers/domHelper";
import { SquareIcon } from "../icons/iconWrapper";
import IPromptProps, { LockedFeatureLink } from "./iPrompt";
import ButtonTypeEnum from "../../elements/Button/buttonTypes";
import { Checkbox } from "am-web-ui-shared/elements";
import {
  ButtonsHolder,
  LinksHolder,
  ContentBody,
  ContentFooter,
  ContentHeader,
  ContentWrapper,
  Cross,
  DialogBox,
  DialogBoxWrapper,
  DialogOuter,
  DialogScroll,
  IconWrapper,
  LabelName,
  LeftFooterComponent,
  Wrapper,
  IconLabel,
  ButtonIconWrapper,
  DialogImage,
  Error,
  CrossLockeItem,
} from "./promptStyle";
import { IconName } from "../icons/iconEnum";
import { Link } from "react-router-dom";
import Tooltip from "@hilti/components/core/tooltip";

const modalRoot = document.getElementById("modalRoot");

/**
 * @class ConfirmConfirmation
 * @extends {React.PureComponent<IPromptProps>}
 */
class Prompt extends React.PureComponent<IPromptProps> {
  static defaultProps = {
    confirmBtnDisabled: false,
    isHeader: true,
    outerStyle: {},
    showCancel: true,
    showConfirm: true,
    wrapperStyleAttributes: {},
    imageUrl: null,
    showLockedFeatureDialog: false,
  };

  el = document.createElement("div");

  /**
   * Show ConfirmConfirmation once this component is mounted
   *
   * @memberof ConfirmConfirmation
   */
  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
    addClassOnBody("modal-open");
  }

  /**
   * Hide ConfirmConfirmation once this component is unmounted
   *
   * @memberof ConfirmConfirmation
   */
  componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
      modalRoot.style.backgroundColor = "";
      if (modalRoot.childNodes.length === 0) {
        document.getElementById("root").style.visibility = "visible";
        document.getElementsByTagName("body")[0].style.overflow = "";
      }
    }
    removeClassOnBody("modal-open");
  }

  /**
   * @description - Render the content header of the prompt.
   */
  renderContentHeader = () => {
    const HeaderComponent = this.props.headerComponent;
    return HeaderComponent ? (
      <HeaderComponent handleClose={this.props.handleClose} header={this.props.header} />
    ) : (
      <ContentHeader className={this.props.headerWithCrossIcon ? "header-alignment" : null}>
        <LabelName>{this.props.header}</LabelName>
      </ContentHeader>
    );
  };

  render() {
    const {
      children,
      className,
      dialogScrollClassName,
      crossIcon,
      handleClose,
      contactSalesUrl,
      handleCrossClick,
      icon,
      isHeader,
      outerStyle,
      showCancel,
      imageUrl,
      learnMoreUrl,
      wrapperStyleAttributes,
      leftFooterComponentAction,
      leftFooterComponentLabel,
      showLockedFeatureDialog,
      showCheckbox,
      checkboxLabel,
      checkboxChange,
      checkboxError,
      checkboxRequired,
      checkboxValidationLabel,
      slimFooter,
      cancelLabel,
      showConfirm,
      confirmBtnDisabled,
      handleConfirm,
      okLabel,
      okIcon,
      learnMoreLabel,
      salesLabel,
    } = this.props;
    return ReactDOM.createPortal(
      <>
        <DialogOuter id="dialogOuter" style={outerStyle}>
          <DialogScroll className={`dialogScroll ${dialogScrollClassName}`}>
            <DialogBoxWrapper id="dialogBoxWrapper" style={wrapperStyleAttributes}>
              <DialogBox id="dialogBox" className={className}>
                {imageUrl && (
                  <DialogImage>
                    <CrossLockeItem onClick={handleCrossClick || handleClose} className="image-cross">
                      <SquareIcon name="Cross" className="icon" />
                    </CrossLockeItem>
                    <img src={imageUrl} alt="Modal Image" />
                  </DialogImage>
                )}
                {crossIcon && (
                  <Cross onClick={handleCrossClick || handleClose}>
                    <SquareIcon name="Cross" className="icon" />
                  </Cross>
                )}
                <Wrapper>
                  {icon && (
                    <IconWrapper className={className}>
                      <SquareIcon name={icon} className="icon medium" imageSize={64} />
                    </IconWrapper>
                  )}
                  <ContentWrapper>
                    {isHeader && this.renderContentHeader()}
                    {children && <ContentBody>{children}</ContentBody>}
                  </ContentWrapper>
                </Wrapper>
                <ContentFooter
                  id="buttonActionContainer"
                  slim={slimFooter}
                  className={leftFooterComponentAction && "space-between"}
                >
                  {leftFooterComponentAction && (
                    <LeftFooterComponent onClick={leftFooterComponentAction}>
                      {leftFooterComponentLabel}
                    </LeftFooterComponent>
                  )}
                  {!showLockedFeatureDialog && (
                    <ButtonsHolder showCheckbox={showCheckbox}>
                      {showCancel && (
                        <Button
                          id="cancelButton"
                          buttonType={ButtonTypeEnum.secondary}
                          onClick={handleClose}
                          ariaLabel={cancelLabel}
                        >
                          {cancelLabel}
                        </Button>
                      )}
                      {showCheckbox && (
                        <>
                          <Checkbox
                            label={checkboxLabel}
                            name="checkboxName"
                            onChange={checkboxChange}
                            error={checkboxError}
                            required={checkboxRequired}
                          />
                          {checkboxError && <Error>{checkboxValidationLabel}</Error>}
                        </>
                      )}
                      {showConfirm && (
                        <>
                          <Tooltip title={this.props.showToolTip ? this.props.toolTipText : ""}>
                            <Button
                              data-testid="confirm-button"
                              id="okButton"
                              buttonType={confirmBtnDisabled ? ButtonTypeEnum.disabled : ButtonTypeEnum.cta}
                              onClick={handleConfirm}
                              data-for="okButtonTooltip"
                              ariaLabel={okLabel}
                            >
                              {okIcon ? (
                                <IconLabel>
                                  <SquareIcon name={okIcon} />
                                  {okLabel}
                                </IconLabel>
                              ) : (
                                okLabel
                              )}
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </ButtonsHolder>
                  )}
                  {showLockedFeatureDialog && (
                    <LinksHolder>
                      <Link
                        onClick={() => handleClose(LockedFeatureLink.LearnMore)}
                        className="link"
                        id="secondary-link"
                        to={{ pathname: learnMoreUrl }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {learnMoreLabel}
                        <ButtonIconWrapper>
                          <SquareIcon name={IconName.OpenNew} className="icon small" imageSize={16} />
                        </ButtonIconWrapper>
                      </Link>
                      <>
                        <Link
                          onClick={() => handleClose(LockedFeatureLink.ContactSales)}
                          className="link"
                          id="cta-link"
                          to={{ pathname: contactSalesUrl }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {salesLabel}
                          <ButtonIconWrapper className={className}>
                            <SquareIcon name={IconName.OpenNew} className="icon small" imageSize={16} />
                          </ButtonIconWrapper>
                        </Link>
                      </>
                    </LinksHolder>
                  )}
                </ContentFooter>
              </DialogBox>
            </DialogBoxWrapper>
          </DialogScroll>
        </DialogOuter>
      </>,
      this.el,
    );
  }
}

export default Prompt;
