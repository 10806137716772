// @ts-ignore;
import { IconName } from "am-web-ui-shared/components";
// @ts-ignore;
import WarningIcon from "TARGET_BUILD/ui-assets/icons/WarningIcon.svg";
import { canUserEditChargeSettings } from "../../../../../models/charges/chargesOrchestration";
const placeHolder = "common:PLACEHOLDER_NO_SELECTION";

// @ts-ignore;
const getColumns = (chargeSettingError?) => {
  return canUserEditChargeSettings() && !chargeSettingError
    ? [...defaultColumns, ...chargeSettingColumns]
    : [...defaultColumns];
};

const defaultColumns = [
  {
    bindProperty: "manufacturerNameAndModel",
    label: "common:LABEL_MANUFACTURERANDMODEL",
    type: "radio",
    required: true,
    placeHolder: "common:PLACEHOLDER_SELECT_MANDATORY_FIELD",
  },
  {
    bindProperty: "templateImageId",
    defaultImage: IconName.Category,
    label: "assets:TEMPLATE_IMAGE",
    previewType: "image",
    defaultPreview: true,
    type: "radioWithImage",
    placeHolder,
  },
  {
    bindProperty: "description",
    label: "assets:DESCRIPTION",
    type: "radio",
    placeHolder,
  },
  {
    bindProperty: "costCode",
    label: "assets:COST_CODE",
    type: "radio",
    placeHolder,
    hintIcon: WarningIcon,
    hintText: "common:MERGE_COST_CODE_HINT",
  },
  {
    bindProperty: "defaultGroupName",
    label: "assets:ASSET_GROUP",
    type: "radio",
    placeHolder,
    hintIcon: WarningIcon,
    hintText: "common:MERGE_ASSET_GROUP_HINT",
  },
  {
    bindProperty: "services",
    hideClearSlection: true,
    label: "common:SERVICES",
    previewType: "itemList",
    type: "checkboxGroup",
    placeHolder,
    valueId: "id",
    valueKey: ["name"],
    maxCount: 20,
    maxDisplayOptions: 30,
    checkFormValid: "validateServices",
  },
  {
    bindProperty: "templateAttachments",
    hideClearSlection: true,
    label: "common:ATTACHMENTS",
    previewType: "itemList",
    type: "checkboxGroup",
    placeHolder,
    valueId: "attachmentId",
    valueKey: ["displayName", "fileName"],
    maxCount: 20,
    checkFormValid: "validateAttachments",
  },
];
const chargeSettingColumns = [
  {
    bindProperty: "costTypeValueDisplay",
    label: "charges:ASSET_COST_SETTINGS",
    type: "radio",
    placeHolder,
    hintIcon: WarningIcon,
  },
];
const falsyValues = ["common:NONE", "common:NO_VALUE", "common:NO_CHARGE"];
const assetTemplateMergeConfig = {
  title: "common:MERGE_TEMPLATE",
  enableSelectAll: true,
  falsyValues,
  responseKeyProperty: "templateId",
  mappedKeyProperty: "id",
  header: {
    textBindProperty: "manufacturerNameAndModelWithDash",
    countBindProperty: "assetCount",
    countPostFix: "common:LABEL_ASSETS_PLURAL",
  },
  previewHeader: {
    label: "common:PREVIEW",
    countBindProperty: "assetCount",
    countPostFix: "common:LABEL_ASSETS_PLURAL",
  },
  columns: (chargeSettingError?) => getColumns(chargeSettingError),
};

export default assetTemplateMergeConfig;
