import React from "react";
import { UpfrontExpansionPanel } from "../../DetailsPanel/index";
import DetailComponents from "./detailComponents";
import SummaryComponents from "./summaryComponents";

export class ChargeSettingSection extends React.Component<any, any> {
  static getDerivedStateFromProps(props, state) {
    if (state.id !== props.id) {
      return {
        id: props.id,
        showExpansionPanel: false,
      };
    }
    return null;
  }

  state = {
    id: this.props.id,
    showExpansionPanel: false,
  };

  allowAccordianControl = (panelId, isPanelExpanded) => {
    if (isPanelExpanded && panelId) {
      this.props.fetchChargeSetting();
    }
    this.setState((prevState) => {
      return { showExpansionPanel: !prevState.showExpansionPanel };
    });
  };

  render() {
    const { chargeSettingData, expansionPanelDetailsLoader } = this.props;
    return (
      <UpfrontExpansionPanel
        panelId="chargeSetting"
        detailsData={chargeSettingData}
        summaryComponents={SummaryComponents}
        detailComponents={chargeSettingData && DetailComponents}
        associatedRowId={chargeSettingData && chargeSettingData.id}
        isEnable={true}
        isPanelExpanded={this.state.showExpansionPanel}
        allowAccordianControl={this.allowAccordianControl}
        showToggleIcon={true}
        expansionPanelDetailsLoader={expansionPanelDetailsLoader}
      />
    );
  }
}

export default ChargeSettingSection;
