import React from "react";
import styled from "styled-components";
import { ISelectProps } from "./iSelectProps";

// @ts-ignore
import iconArrowdown from "./arrow_down.svg";

/**
 * Wrapper box for the component.
 */
const StyledBox = styled("div")`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  padding: 12px;
  background-color: #f3f2f0;
  border: 2px solid #b7b3aa;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
`;

/**
 * Down arrow icon and aligned to the right of the select control.
 */
const StyledIcon = styled("img")`
  height: 24px;
  width: 15px;
  text-align: center;
  float: right;
`;

/**
 * Button element which will hold the filter list.
 */
const StyledButton = styled("button")`
  margin-right: 8px;
  max-width: 180px;
  color: #524f53;
  border: none;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: 100%;
  background: #f3f2f0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledContainer = styled("div")`
  height: 24px;
  width: 24px;
`;

/**
 * Drilldown component holds a simple select element with down_arrow icon on its right.
 * The default text for the first option is configurable.
 * Also, the data to bind the options can be passed from the place you use this component.
 */
class Drilldown extends React.PureComponent<ISelectProps> {
  render() {
    const { id, value, name, disabled, style, ...props } = this.props;

    return (
      <StyledBox style={style} title={value}>
        <StyledButton id={id} name={name} disabled={disabled} {...props}>
          {value}
        </StyledButton>
        <StyledContainer>
          <StyledIcon src={iconArrowdown} />
        </StyledContainer>
      </StyledBox>
    );
  }
}

export default Drilldown;
