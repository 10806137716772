// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { Form, DatePicker } from "am-web-ui-shared/elements";
import { useTranslation } from "react-i18next";
import { LocaleManager } from "am-web-ui-shared/helpers";
import { ValidationConstants } from "TARGET_BUILD/common/utils/enums/validationConstants";
import { getDatePickerLocale } from "TARGET_BUILD/common/utils/dateUtils";
import { ICustomDaysFilter } from "./model/iUtilizationDetailsView";
import { default as TextLabel } from "am-web-ui-shared/elements/Field/field";
import PeriodSelectionMenu from "./periodSelectionMenu";

const DateContainer = styled.div`
  #DatePickerContainer {
    width: 100%;
  }
  #startDate {
    width: 100%;
  }
  #endDate {
    width: 100%;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  padding-bottom: 24px;
  padding-left: 8px;
`;
const DatepickerWrapper = styled.div`
  .fields-wrapper {
    flex-direction: column;
    .react-datepicker-wrapper {
      display: flex;
      width: 100%;
      input[type="text"] {
        width: 100%;
      }
    }
  }
  #dateRangeDropdown > div {
    padding: 25px 0px 0 40px;
    background: ${(props) => props.theme.detailsPanel.detailsSectionBackground};
  }
`;
const DatePickerSeperator = styled.div`
  padding: 16px 16px 0px 16px;
`;

const CustomDaysFilter = (props: ICustomDaysFilter) => {
  const { t } = useTranslation();
  const {
    customDatefilterChange,
    customFilterValue,
    selectedStartDate,
    selectedEndDate,
    showLabels,
    onChangeStartDate,
    onChangeEndDate,
  } = props;
  const customDateControl = () => {
    const startDate = new Date();
    const endEnd = new Date();
    const minDate = new Date();
    const maxDate: object = selectedEndDate ? new Date(selectedEndDate.toString()) : new Date();

    const customMinDate = minDate.setDate(startDate.getDate() - ValidationConstants.DAYS.NUM_364);

    return (
      <DateContainer id="dateContainer">
        <TextLabel
          name={"startDate"}
          htmlFor={"startDate"}
          label={showLabels ? "" : t("common:START_DATE")}
          required={true}
        >
          <DatePicker
            name="startDate"
            minDate={customMinDate}
            maxDate={maxDate}
            selected={selectedStartDate}
            required={true}
            label={showLabels ? "" : t("common:START_DATE")}
            format={LocaleManager.dateHelpers.getLocalizedDatePickerFormat()}
            id="startDate"
            locale={getDatePickerLocale()}
            onSelectChange={onChangeStartDate}
            isClearable={false}
            placeholder={LocaleManager.dateHelpers.getLocalizedDatePickerFormat().toUpperCase()}
            openToDate={LocaleManager.dateHelpers.getNativeDateInTenantTimezone(selectedStartDate)}
          />
        </TextLabel>
        {showLabels && <DatePickerSeperator>{t("common:DATE_TO")}</DatePickerSeperator>}
        <TextLabel name={"endDate"} htmlFor={"endDate"} label={showLabels ? "" : t("common:END_DATE")} required={true}>
          <DatePicker
            name="endDate"
            minDate={selectedStartDate}
            maxDate={new Date()}
            selected={selectedEndDate}
            required={true}
            label={showLabels ? "" : t("common:END_DATE")}
            format={LocaleManager.dateHelpers.getLocalizedDatePickerFormat()}
            id="endDate"
            locale={getDatePickerLocale()}
            isClearable={false}
            placeholder={LocaleManager.dateHelpers.getLocalizedDatePickerFormat().toUpperCase()}
            openToDate={LocaleManager.dateHelpers.getNativeDateInTenantTimezone(selectedEndDate)}
            onSelectChange={onChangeEndDate}
          />
        </TextLabel>
      </DateContainer>
    );
  };

  const options = [
    {
      label: `${ValidationConstants.DAYS.NUM_7} ${t("common:UTILIZATION_DAYS")}`,
      value: `${ValidationConstants.DAYS.NUM_7}`,
    },
    {
      label: `${ValidationConstants.DAYS.NUM_15} ${t("common:UTILIZATION_DAYS")}`,
      value: `${ValidationConstants.DAYS.NUM_15}`,
    },
    {
      label: `${ValidationConstants.DAYS.NUM_30} ${t("common:UTILIZATION_DAYS")}`,
      value: `${ValidationConstants.DAYS.NUM_30}`,
    },
    {
      label: `${ValidationConstants.DAYS.NUM_90} ${t("common:UTILIZATION_DAYS")}`,
      value: `${ValidationConstants.DAYS.NUM_90}`,
    },
    {
      label: `${ValidationConstants.DAYS.NUM_180} ${t("common:UTILIZATION_DAYS")}`,
      value: `${ValidationConstants.DAYS.NUM_180}`,
    },
    {
      label: t("common:CUSTOM_DATES"),
      value: "customDates",
    },
  ];

  return (
    <Form form="utilizationFilterform">
      <PeriodSelectionMenu
        options={options}
        onSelectionChange={customDatefilterChange}
        selectedOptions={options.filter((element) => element.value === customFilterValue)}
        fullWidth={props?.fullWidth}
      />
      <DatepickerWrapper>{customFilterValue === "customDates" ? customDateControl() : null}</DatepickerWrapper>
    </Form>
  );
};

export default CustomDaysFilter;
