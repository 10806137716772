const GeneratedReportsActionConstants = {
  GENERATED_REPORT_SELECT_ROW: "GENERATED_REPORT_SELECT_ROW",
  GENERATED_REPORT_UNSELECT_ROW: "GENERATED_REPORT_UNSELECT_ROW",
  REP_GET_GENERATED_REPORTS_LIST: "REP_GET_GENERATED_REPORTS_LIST",
  REP_SET_GENERATED_REPORTS: "REP_SAVE_GENERATED_REPORTS",
  RM_GET_UNREAD_REPORTS_COUNT: "RM_GET_UNREAD_REPORTS_COUNT",
  RM_SET_GENERATED_REPORT_STATUS: "RM_SET_GENERATED_REPORT_STATUS",
  RM_SET_READ_REPORT_AND_UNREAD_REPORTS_COUNT: "RM_SET_READ_REPORT_AND_UNREAD_REPORTS_COUNT",
  SET_UNREAD_REPORTS_COUNT: "SET_UNREAD_REPORTS_COUNT",
  RM_GENERATED_REPORT_CUSTOM_VIEW: "RM_GENERATED_REPORT_CUSTOM_VIEW",
  RM_GENERATED_REPORT_ATTRIBUTES: "RM_GENERATED_REPORT_ATTRIBUTES",
  RM_GENERATED_REPORT_SET_LIST_FIELDS_QUERY: "RM_GENERATED_REPORT_SET_LIST_FIELDS_QUERY",
  RM_GENERATED_REPORT_SET_SORT_QUERY: "RM_GENERATED_REPORT_SET_SORT_QUERY",
  RM_GENERATED_REPORT_CUSTOM_VIEW_SUCCESS: "RM_GENERATED_REPORT_CUSTOM_VIEW_SUCCESS",
  RM_GENERATED_REPORT_ATTRIBUTES_SUCCESS: "RM_GENERATED_REPORT_ATTRIBUTES_SUCCESS",
  RM_GENERATED_REPORT_DELETE: "RM_GENERATED_REPORT_DELETE",
};

export default GeneratedReportsActionConstants;
