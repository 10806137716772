import React, { useContext } from "react";
import Ellipsis from "../../../components/ellipsis/ellipsis";
import ApplicationConstants from "../../../constant/applicationConstants";
import { getElementId, getFieldValue, getPrefixComponent, getPropertyLabel } from "../gridUtils";
import GridCombinationalCustomCell from "./gridCombinationalCustomCell";
import { IGridCell } from "./iGridCell";
import Highlighter from "react-highlight-words";
import { SearchContext } from "../../../providers/searchProvider";

/**
 * Grid cell for combinational field and column having multiple values
 */

export const GridCombinationalCell = React.memo((props: IGridCell) => {
  const { column, rowIndex, columnIndex, dependencies } = props;
  const { searchString = "", isSearchHighlightEnabled } = useContext(SearchContext);
  return (
    <>
      {column.bindProperties.map((bindProperty, index) => {
        return bindProperty.component
          ? renderCombinationCustomCell(bindProperty, props)
          : renderCombinationColumn(
              bindProperty,
              rowIndex,
              columnIndex,
              index,
              dependencies,
              props,
              searchString,
              isSearchHighlightEnabled,
            );
      })}
    </>
  );
});

/**
 * @description renderCombinationColumn will render the values for single colum.
 * @param bindProperty { Array Object} contains the keys of the combination.
 * @index {number} contains the index of the data.
 */
export const renderCombinationColumn = (
  bindProperty,
  rowIndex: number,
  columnIndex: number,
  index: number,
  dependenciesFunction,
  props,
  searchString: string,
  isSearchHighlightEnabled: boolean,
) => {
  const combineData = [];
  let prefixComponent;
  bindProperty.properties.map((property) => {
    const val = getFieldValue(
      props && props.data,
      property.name,
      dependenciesFunction,
      property.dependencies && property.dependencies.format,
    );
    if (val !== undefined) {
      combineData.push(val);
    }
    prefixComponent = getPrefixComponent(props?.data, property?.name, dependenciesFunction, index, bindProperty);
  });

  let value = combineData.join(" ");
  if (bindProperty.blankLiteral && !(value && value.trim())) {
    value = bindProperty.blankLiteral;
  }

  const tooltipText = () => (props.showValueTooltip ? value : getPropertyLabel(dependenciesFunction, bindProperty.key));

  const id = getElementId(rowIndex, columnIndex, index, bindProperty);
  return (
    <React.Fragment key={id}>
      {prefixComponent
        ? prefixComponent
        : value && (
            <Ellipsis
              className={index % ApplicationConstants.NUMBER.NUM2 === 0 ? "cell-top-element" : "cell-bottom-element"}
              style={bindProperty.style ? bindProperty.style : null}
              key={id}
              id={id}
              value={value}
              tooltipValue={props.showTooltip && tooltipText()}
              showTooltip={props.showTooltip}
            >
              {isSearchHighlightEnabled ? (
                <Highlighter
                  searchWords={searchString?.split(" ")}
                  textToHighlight={value}
                  autoEscape={true}
                  highlightClassName="custom-highlight"
                />
              ) : (
                value
              )}
            </Ellipsis>
          )}
    </React.Fragment>
  );
};

/**
 * @description renderCombinationCustomCell will render the custom component.
 *
 * @param {object} bindProperty { Array Object} contains the keys of the combination.
 * @param {object} props given properties.
 *
 * @returns Component
 */
const renderCombinationCustomCell = (bindProperty, props) => {
  const { column, rowIndex, columnIndex, dependencies, data } = props;

  return (
    <GridCombinationalCustomCell
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      data={data}
      column={column}
      component={bindProperty.component}
      dependencies={dependencies}
    />
  );
};

export default GridCombinationalCell;
