// @ts-ignore
import { AssetActions } from "custom-actions";
import { put, select, takeLatest, delay, all } from "redux-saga/effects";
// @ts-ignore
import { API_DELAY_TIME } from "TARGET_BUILD/config";
import { showToastr, ToastrType } from "../../../../actions/toastrAction";
import { associateLabel, associateLabelDetail, getAssetDetails } from "../../../../api/assetApi";
import { createLabel, deleteLabels, getLabels } from "../../../../api/labelsApi";
import { AssetKeys } from "../../../../utils/enums/assetEnum";
import { validateResponse } from "../../../../utils/errorUtils";
import { getMultiSelectPayloadAttributes } from "../../../../utils/list/listUtils";
import { call } from "../../../../sagas/common/commonSaga";
import { getQueryObjForMultiPayload } from "../../../../sagas/stateSelector";
/**
 * Returns list of Assets by calling relevant API
 * @description : saga for getting the list of labels.
 */
export function* getLabelsDataSaga() {
  yield takeLatest(AssetActions.AM_GET_LABELS_LIST, getLabelsData);
}

/**
 * Returns list of Assets by calling relevant API
 * @description : getLabelsData generator for getting the list of labels.
 */
export function* getLabelsData(action) {
  let response = yield call(() => {
    return getLabels({ q: action.searchLabel });
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: AssetActions.AM_ASSET_LABELS_LIST_RESPONSE, payload: response });
}

/**
 * Returns list of Assets by calling relevant API
 * @description : saga for associating the labels from a specific asset.
 */
export function* associateLabels() {
  yield takeLatest(AssetActions.AM_ASSOCIATE_ASEET_LABEL, associate);
}

/**
 * Returns list of Assets by calling relevant API
 * @description : associate generator for associating the labels from a specific asset.
 */
export function* associate(action) {
  const multiSelectQueryFields = yield select(getQueryObjForMultiPayload, "Assets");
  const multiSelectPayloadFields = getMultiSelectPayloadAttributes(
    action.checkedListInfo,
    multiSelectQueryFields.isSelectAll,
    AssetKeys,
  );
  const multiRowPayload = {
    ...action.data,
    ...multiSelectQueryFields,
    ...multiSelectPayloadFields,
  };
  let response = yield call(() => {
    return associateLabel(multiRowPayload);
  });
  if (response.error) {
    response = validateResponse(response);
    yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`));
  } else {
    if (response.invalidResponse) {
      yield put(
        showToastr(ToastrType.error, `errorCodes:${response.invalidResponse && response.invalidResponse[0].errorCode}`),
      );
    } else {
      const successMessage = !action.data.isDissociation ? "common:ADDED_SUCCESSFULLY" : "common:REMOVED_SUCCESSFULLY";
      yield put(showToastr(ToastrType.success, successMessage));
      yield delay(API_DELAY_TIME);
      yield put({ type: AssetActions.AM_GET_ASSET_FILTER_FIELD });
    }
    yield put({ type: AssetActions.AM_SET_LABEL_UPDATED });
  }
}

/**
 * Returns list of Assets by calling relevant API
 * @description : saga for deassociating the labels from a specific asset.
 */
export function* deleteLabelsSaga() {
  yield takeLatest(AssetActions.AM_DELETE_ASEET_LABEL, deleteLabel);
}

/**
 * Returns list of Assets by calling relevant API
 * @description : deleteLabel generator to deassociate the labels from a specific asset.
 */
export function* deleteLabel(action) {
  let response = yield call(() => {
    return deleteLabels(action.assetId, action.labels);
  });
  if (response.error) {
    response = validateResponse(response);
    yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`));
  }
  let assetDetails = yield call(() => {
    return getAssetDetails(action.assetId);
  });
  yield put({
    payload: assetDetails,
    type: AssetActions.SET_CHECKED_ASSET_DETAIL,
  });
  if (assetDetails.error) {
    assetDetails = validateResponse(assetDetails);
    yield put(showToastr(ToastrType.error, `errorCodes:${assetDetails.code}`));
  }
}

/**
 * Returns list of Assets by calling relevant API
 * @description : saga for calling the create label Api
 */
export function* createLabelSaga() {
  yield takeLatest(AssetActions.AM_CREATE_ASEET_LABEL, labelCreation);
}

/**
 * Returns list of Assets by calling relevant API
 * @description : labelCreation generator to yield response of label creation and making label association+showing on asset details.
 */
export function* labelCreation(action) {
  let response = yield call(() => {
    return createLabel(action.data);
  });
  if (response.error) {
    response = validateResponse(response);
    yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`));
  } else {
    if (response.error) {
      response = validateResponse(response);
      yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`));
    } else {
      if (action.assetId && action.assetId.length === 1) {
        const assetDetails = yield call(() => {
          return getAssetDetails(action.assetId);
        });

        yield put({
          payload: assetDetails,
          type: AssetActions.SET_CHECKED_ASSET_DETAIL,
        });
      }
      yield put(showToastr(ToastrType.success, "common:ADDED_SUCCESSFULLY"));
    }
  }
}

/**
 * Returns list of Assets by calling relevant API
 * @description : saga for getting the list of labels.
 */
export function* getAssociateLabelsDataSaga() {
  yield takeLatest(AssetActions.AM_ASSOCIATE_ASEET_LABEL_DETAILS, getAssociateLabelsData);
}

/**
 * Returns list of Assets by calling relevant API
 * @description : getLabelsData generator for getting the list of labels.
 */
export function* getAssociateLabelsData(action) {
  const multiSelectQueryFields = yield select(getQueryObjForMultiPayload, "Assets");
  const multiSelectPayloadFields = getMultiSelectPayloadAttributes(
    action.data,
    multiSelectQueryFields.isSelectAll,
    AssetKeys,
  );
  const multiRowPayload = {
    ...multiSelectQueryFields,
    ...multiSelectPayloadFields,
  };
  let response = yield call(() => {
    return associateLabelDetail(multiRowPayload);
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: AssetActions.AM_ASSET_ASSOCIATE_LABELS_RESPONSE, payload: response });
}

function* assetLabelsSaga() {
  yield all([
    associateLabels(),
    createLabelSaga(),
    deleteLabelsSaga(),
    getAssociateLabelsDataSaga(),
    getLabelsDataSaga(),
  ]);
}

export default assetLabelsSaga;
