import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M14.1541607,18.9549909 L16.1478183,20.9486485 L13.0967869,21.9915024 L14.1541607,18.9549909 Z M20.9773027,12.1318489 L23,14.1545463 L17.4616753,19.7098181 L15.3708552,17.6268698 L20.9773027,12.1318489 Z M3.92987599,2 L3.929,8.754 L19.368,8.754 L19.3688839,2 L21.2987599,2 L21.298,10.684 L19.4658669,10.684442 L19.368,10.779 L19.368,10.684 L3.929,10.684 L3.929,16.474 L13.575,16.474 L11.64938,18.3671939 L11.789,18.403 L3.929,18.403 L3.92987599,19.3688839 L2,19.3688839 L2,2 L3.92987599,2 Z M8.75456597,11.64938 L8.75456597,15.5091319 L4.89481399,15.5091319 L4.89481399,11.64938 L8.75456597,11.64938 Z M13.5792559,11.64938 L13.5792559,15.5091319 L9.71950396,15.5091319 L9.71950396,11.64938 L13.5792559,11.64938 Z M18.4039459,11.64938 L18.403,11.728 L14.556,15.509 L14.5441939,15.5091319 L14.5441939,11.64938 L18.4039459,11.64938 Z M8.75456597,3.92987599 L8.75456597,7.78962797 L4.89481399,7.78962797 L4.89481399,3.92987599 L8.75456597,3.92987599 Z M13.5792559,3.92987599 L13.5792559,7.78962797 L9.71950396,7.78962797 L9.71950396,3.92987599 L13.5792559,3.92987599 Z" />
    </g>
  </svg>
);

export default SVG;
