import React from "react";
import { Field } from "redux-form";
import Image from "./attachmentImage";

export default class ImageComponent extends React.Component<any> {
  render() {
    const { currentImage, defaultImage, onChange, editImage, onRemove, removeText, changeText } = this.props;
    return this.props.isFormControl ? (
      <Image {...this.props} />
    ) : (
      <Field
        name={this.props.name}
        component={Image}
        defaultImage={defaultImage}
        currentImage={currentImage}
        onImageChange={onChange}
        editImage={editImage}
        onRemove={onRemove}
        changeText={changeText}
        removeText={removeText}
      />
    );
  }
}
