import React from "react";

const SVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" data-name="Ebene 1" viewBox="0 0 390.68 127.5">
      <defs>
        <style>{`.cls-1{font-size:45.28px;fill:#d2091e;font-family:Hilti-Roman, Hilti;letter-spacing:0.03em;}.cls-2{letter-spacing:0.03em;}.cls-3{letter-spacing:0.03em;}.cls-4{letter-spacing:0.04em;}.cls-5{letter-spacing:0.06em;}.cls-6{font-size:45.11px;font-family:Hilti-Bold, Hilti;font-weight:700;letter-spacing:0.05em;}.cls-14,.cls-6{fill:#525052;}.cls-7{letter-spacing:0.05em;}.cls-8{letter-spacing:0.06em;}.cls-9{letter-spacing:0.05em;}.cls-10{letter-spacing:0.07em;}.cls-11{letter-spacing:0.02em;}.cls-12{letter-spacing:0.05em;}.cls-13{letter-spacing:0.06em;}`}</style>
      </defs>
      <text className="cls-1" transform="translate(224.28 103.34)">
        U
        <tspan x="34.1" y="0" className="cls-2">
          N
        </tspan>
        <tspan x="68.11" y="0" className="cls-3">
          I
        </tspan>
        <tspan x="81.33" y="0" className="cls-4">
          T
        </tspan>
        <tspan x="108.95" y="0" className="cls-5">
          E
        </tspan>
      </text>
      <text className="cls-6" transform="translate(106.23 62.81)">
        O
        <tspan x="37.48" y="0" className="cls-7">
          N
        </tspan>
        <tspan x="73.18" y="0" className="cls-8">
          !
        </tspan>
        <tspan x="88.23" y="0" className="cls-9">
          T
        </tspan>
        <tspan x="118.12" y="0" className="cls-10">
          R
        </tspan>
        <tspan x="154.04" y="0" className="cls-11">
          A
        </tspan>
        <tspan x="185.93" y="0" className="cls-12">
          C
        </tspan>
        <tspan x="221.66" y="0" className="cls-13">
          K
        </tspan>
      </text>
      <path
        d="M38.47 58.26L30.36 53.62 30.36 44.25 38.48 39.52 46.6 44.16 46.59 53.53 38.47 58.26z"
        className="cls-14"
      ></path>
      <path
        d="M61.59 44.99L53.47 40.35 53.48 30.98 61.6 26.25 69.71 30.89 69.71 40.26 61.59 44.99z"
        className="cls-14"
      ></path>
      <path
        d="M61.59 71.53L53.47 66.89 53.48 57.52 61.6 52.8 69.71 57.44 69.71 66.81 61.59 71.53z"
        className="cls-14"
      ></path>
      <path
        d="M84.69 58.26L76.58 53.62 76.59 44.25 84.71 39.52 92.82 44.16 92.81 53.53 84.69 58.26z"
        className="cls-14"
      ></path>
      <path d="M58.78 36.87H64.4V60.959999999999994H58.78z" className="cls-14"></path>
      <path
        d="M47.44 43.63H53.059999999999995V67.72H47.44z"
        className="cls-14"
        transform="rotate(-60.22 50.24 55.68)"
      ></path>
      <path d="M70.57 30.36H76.19V54.45H70.57z" className="cls-14" transform="rotate(-60.22 73.373 42.412)"></path>
    </svg>
  );
};

export default SVG;
