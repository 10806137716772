import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { Button } from "am-web-ui-shared/elements";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { logout } from "../../components/header/actions/logoutActionRoot";
import { getPrivacyPolicyLink, getTouLink } from "../../utils/configUtil";
import { sessionStorageClearAll, storageClearAll, storageSetItem } from "../../utils/storageUtils";
import { acceptTncResponse, getTouLinks, userMeData } from "../../actions/userProfileActionRoot";
import Logo from "../header/Logo/logo";

const TermsDialogContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
`;
const DialogBoxWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 488px;
  padding: 24px;
`;

const ContentBody = styled.div`
  flex: 1;
  align-self: center;
  width: 100%;
`;
const ContentFooter = styled.footer`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;
const LabelName = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.base};
  min-height: 77px;
`;

const TncWrapper = styled.span`
  a {
    text-decoration: underline;
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
`;
const Wrapper = styled.div`
  display: flex;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid ${(props) => props.theme.colors.base.borderColor};
  padding: 0 12px;
  height: 65px;
  background-color: ${(props) => props.theme.colors.base.white};
`;

const LabelStaticText = styled.header`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 24px;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.medium};
  margin-bottom: 16px;
`;

const IconWrapper = styled.span`
  flex-basis: 64px;
  width: 64px;
  margin-right: 16px;
  &.warning path {
    fill: ${(props) => props.theme.colors.base.orange};
  }
`;
export class TermsDialog extends React.PureComponent<any> {
  state = {
    showLogoutModal: false,
    showTermsModal: true,
  };

  componentDidMount() {
    this.props.getTouLinks();
    this.props.userMeData();
  }

  /*function to redirect to a link or show toaster message if link not found*/
  getLink() {
    const { links } = this.props;
    return {
      privacyLink: links?.privatePolicy ? links.privatePolicy : getPrivacyPolicyLink(),
      tncLink: links?.termAndConditions ? links.termAndConditions : getTouLink(),
    };
  }

  showLogoutDialog = () => {
    this.setState({ showTermsModal: false });
    this.setState({ showLogoutModal: true });
  };

  tncText = () => {
    const { privacyLink, tncLink } = this.getLink();
    const { t } = this.props;
    return (
      <>
        <TncWrapper>
          {t("common:TERMSCONDITIONSREADACCEPT")}{" "}
          <Link to={{ pathname: tncLink }} target="_blank" rel="noopener noreferrer">
            {t("common:TERMSANDCONDITIONS")}{" "}
          </Link>
          {t("common:TERMSOFUSE")}{" "}
          <Link to={{ pathname: privacyLink }} target="_blank" rel="noopener noreferrer">
            {t("common:TERMSPRIVACYPOLICY")}
          </Link>
          {"."}
        </TncWrapper>
      </>
    );
  };

  /**
   * @description Function tologout by calling IDM API directly
   */
  logout = () => {
    this.props.logout();
    storageClearAll();
    sessionStorageClearAll();
  };
  decline = () => {
    if (this.state.showLogoutModal) {
      this.logout();
    } else {
      this.showLogoutDialog();
    }
  };

  accept = () => {
    const data = {
      isTncAccepted: true,
    };
    this.props.acceptTncResponse(data);
    storageSetItem(appConstants.IS_TNC_ACCEPTED, true);
  };
  render() {
    const { t } = this.props;
    return (
      <TermsDialogContainer>
        <HeaderWrapper>
          <Logo />
        </HeaderWrapper>
        <DialogBoxWrapper id="dialogBoxWrapper">
          <Wrapper id="wrapperId">
            {this.state.showLogoutModal ? (
              <>
                <IconWrapper className="warning">
                  <SquareIcon name={IconName.Warning} className="icon medium" imageSize={64} />
                </IconWrapper>
              </>
            ) : null}
            <ContentBody id="contentBody">
              {this.state.showLogoutModal ? (
                <LabelStaticText id="logoutHeading">{t("common:LOGOUT_HEADING")}</LabelStaticText>
              ) : null}
              <LabelName>{this.state.showLogoutModal ? t("common:LOGOUT_CONTENT") : this.tncText()}</LabelName>
            </ContentBody>
          </Wrapper>
          <ContentFooter id="contentFooter">
            <Button
              buttonType="secondary"
              onClick={this.decline}
              ariaLabel={this.state.showLogoutModal ? t("common:LOGOUT_TEXT") : t("common:DECLINE")}
            >
              {this.state.showLogoutModal ? t("common:LOGOUT_TEXT") : t("common:DECLINE")}
            </Button>
            <Button buttonType="cta" id="acceptBtn" onClick={this.accept} ariaLabel={t("common:ACCEPT")}>
              {t("common:ACCEPT")}
            </Button>
          </ContentFooter>
        </DialogBoxWrapper>
      </TermsDialogContainer>
    );
  }
}
export const mapStateToProps = (state) => ({
  links: state.UserSetting && state.UserSetting.touLinks,
  userMe: state.UserReducer && state.UserReducer.userMeData,
});

export const mapDispatchToProps = (dispatch) => ({
  acceptTncResponse: (data) => dispatch(acceptTncResponse(data)),
  getTouLinks: () => dispatch(getTouLinks()),
  logout: () => dispatch(logout()),
  userMeData: () => dispatch(userMeData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TermsDialog));
