import { HelperLodash } from "am-web-ui-shared/helpers";
import { IAsset } from "../models/assets.list";
import ICompanyModel from "../models/company/iCompanyModel";
import { IAssetManagementHiltiOnline } from "../models/iAssetManagementHiltiOnline";
import IUserMeModel from "../models/userMeModel";
import { OwnershipType } from "../modules/asset/components/hint/model/ownershipType";
import {
  getAssetManagementHiltiOnlineInfo,
  getAssetManagementHiltiOnlineRepairInfo,
  getAssetManagementHiltiOnlineLostStolenInfo,
  getAssetManagementHiltiOnlineRepairTrackingInfo,
  getAlertManagementHiltiOnlineBatteryExchangeInfo,
  getAssetManagementHiltiOnlineRenewReturnInfo,
  getAssetManagementHiltiOnlineReturnLoanToolsInfo,
  getAssetManagementHiltiOnlineReturnRentalToolsInfo,
} from "./configUtil";

import { ActionItem } from "./enums/actionItemEnum";
import { HiltiStatusCode, StatusCode } from "./enums/assetEnum";
import IBattery from "../models/iBattery";
import { getLanguageTermsAndCondition } from "../api/licenseAgreementApi";

export const MAX_LENGTH_SELECTION = 20;

export enum HolRepairConstant {
  TENANT_TYPE_BYD = "ByD Customer",
  STATE = "state",
  STATIC_VAL_TYPE = "static",
}

export const ineligibleHiltiAssetStatusesForLostStolen = [
  HiltiStatusCode.LOST,
  HiltiStatusCode.STOLEN,
  HiltiStatusCode.LOSTSTOLENNOTCOLLECT,
  HiltiStatusCode.COLLECTORRETURN,
];

export const eligibleHiltiAssetStatusesForRepair = [
  HiltiStatusCode.ACTIVE,
  HiltiStatusCode.FORMERFLEET,
  HiltiStatusCode.FLEETINEXTN,
];

export const getSortedAssetsWithAccountNumbers = (assets: Record<string, IAsset[]>) => {
  return Object.keys(assets)
    .map((key) => {
      const list = HelperLodash.get(assets, key);
      return {
        key,
        count: list.length,
        ownerName: list[0].hiltiAssetOwnerName || "",
      };
    })
    .sort((a, b) => {
      return b.count - a.count || a.ownerName.localeCompare(b.ownerName);
    })
    .map((entry) => {
      return {
        [entry.key]: HelperLodash.get(assets, entry.key),
      };
    });
};

export const getOrderedUniqueAssetsWithCCMSNumber = (assets: IAsset[]) => {
  const groupedAssets = HelperLodash.groupBy(assets, (a) => a.originCustomerId + a.moCountryCode);
  const assetsLinkedWithCCMS = getSortedAssetsWithAccountNumbers(groupedAssets);

  const uniqueCCMSNumbers = {};
  Object.keys(groupedAssets).forEach((key) => HelperLodash.set(uniqueCCMSNumbers, key, false));

  return { assetsLinkedWithCCMS, uniqueCCMSNumbers };
};

export const getToolIdForRepair = (assets: IAsset[], useTID = false) => {
  return assets
    .reduce((acc, asset) => {
      const { materialNumber, serialNumber } = asset;
      if (materialNumber && serialNumber) {
        let materialSerialCombination = `${materialNumber}-${serialNumber}`;
        if (useTID) {
          materialSerialCombination = `TID-${materialSerialCombination}`;
        }
        return `${acc},${materialSerialCombination}`;
      }
      return acc;
    }, "")
    .replace(",", "");
};

export const encodeHolUrlStateParam = async (
  assets: IAsset[],
  company: ICompanyModel,
  config: IAssetManagementHiltiOnline,
  useTID = false,
  repairOrderId,
) => {
  const country = company?.country?.code;
  const languageCode = localStorage.getItem("i18nextLng");
  const lang = languageCode?.substring(0, languageCode?.indexOf("-"));
  const fetchLanguages = async (lang: string) => {
    const res = await getLanguageTermsAndCondition(country);

    let value = res?.filter(({ code }) => code.includes(lang)).map(({ code }) => code);
    if (value?.length === 0) {
      value = [res?.[0].code];
    }
    const [codeLanguage] = value.toString().split("-");

    return codeLanguage;
  };

  const query = config.PARAMS.reduce((acc, val) => {
    if (val.type === HolRepairConstant.STATIC_VAL_TYPE) {
      return { ...acc, [val.key]: val.value };
    } else if (val.name === "tool-ids") {
      const toolIdForRepair = getToolIdForRepair(assets, useTID);
      return { ...acc, [val.key]: toolIdForRepair };
    }
    return acc;
  }, {});
  const countryCode = company?.country?.code || "";
  return fetchLanguages(lang).then((data) => {
    const relativeUrl = repairOrderId ? `/${data}${config.BASE_URL}${repairOrderId}` : `/${data}${config.BASE_URL}`;
    const stateObject = {
      targetUrl: {
        countryCode,
        relativeUrl,
        query,
      },
    };
    return encodeURIComponent(window.btoa(JSON.stringify(stateObject)));
  });
};

export const constructRedirectHolUrl = async (
  assets: IAsset[],
  company: ICompanyModel,
  config: IAssetManagementHiltiOnline,
  useTID = false,
  repairOrderId,
) => {
  const originCustomerId = assets[0]?.originCustomerId || company.originCustomerId;
  const countryCode = company?.country?.code || "";
  const assetManagementHiltiOnlineInfo = getAssetManagementHiltiOnlineInfo();
  const languageCode = localStorage.getItem("i18nextLng");
  const lang = languageCode?.substring(0, languageCode?.indexOf("-"));
  const stateUrl = await encodeHolUrlStateParam(assets, company, config, useTID, repairOrderId);
  const partialUri = assetManagementHiltiOnlineInfo.PARAMS.reduce((acc, val) => {
    if (val.type === HolRepairConstant.STATIC_VAL_TYPE) {
      return `${acc}&${val.key}=${val.value}`;
    }
    switch (val.name) {
      case "country":
        return `${acc}&${val.key}=${countryCode}`;
      case "lang":
        return `${acc}&${val.key}=${lang}`;
      case "origin_customer_id":
        return `${acc}&${val.key}=${originCustomerId}`;
      case HolRepairConstant.STATE:
        return `${acc}&${val.key}=${stateUrl}`;
      default:
        return acc;
    }
  }, "").replace("&", "");
  return `${company?.tenantHolLink}${assetManagementHiltiOnlineInfo.BASE_URL}${partialUri}`;
};

export const redirectToHOLPage = async (
  assets: IAsset | IAsset[] | IBattery,
  company: ICompanyModel,
  config: IAssetManagementHiltiOnline,
  useTID = false,
  repairOrderId?,
) => {
  const redirectionUrl = await constructRedirectHolUrl([].concat(assets), company, config, useTID, repairOrderId);
  window.open(redirectionUrl);
};

export const redirectToHOLPageForRepair = (assets: IAsset[], company: ICompanyModel) => {
  return redirectToHOLPage(assets, company, getAssetManagementHiltiOnlineRepairInfo(), true);
};

export const redirectToHOLPageForRepairTracking = (asset: IAsset, company: ICompanyModel, repairOrderId) => {
  return redirectToHOLPage(asset, company, getAssetManagementHiltiOnlineRepairTrackingInfo(), false, repairOrderId);
};

export const redirectToHOLPageForLostStolen = (assets: IAsset[], company: ICompanyModel) => {
  return redirectToHOLPage(assets, company, getAssetManagementHiltiOnlineLostStolenInfo(), true);
};

export const redirectToHOLPageForBatteryExchange = (alertObject: IAsset | IBattery, companyDetails: ICompanyModel) => {
  return redirectToHOLPage(alertObject, companyDetails, getAlertManagementHiltiOnlineBatteryExchangeInfo(), true);
};

export const redirectToHOLPageForRenewReturn = (company: ICompanyModel) => {
  return redirectToHOLPage(null, company, getAssetManagementHiltiOnlineRenewReturnInfo(), true);
};
export const redirectToHOLPageForReturnLoanTools = (assets: IAsset[], company: ICompanyModel) => {
  return redirectToHOLPage(assets, company, getAssetManagementHiltiOnlineReturnLoanToolsInfo(), true);
};
export const redirectToHOLPageForReturnRentTools = (assets: IAsset[], company: ICompanyModel) =>
  redirectToHOLPage(assets, company, getAssetManagementHiltiOnlineReturnRentalToolsInfo(), true);

export const redirectToHOLPageHandler = (action: string, assets: IAsset[], company: ICompanyModel) => {
  switch (action) {
    case ActionItem.repair:
      return redirectToHOLPageForRepair(assets, company);
    case ActionItem.lostStolen:
      return redirectToHOLPageForLostStolen(assets, company);
    case ActionItem.returnLoan:
      return redirectToHOLPageForReturnLoanTools(assets, company);
    case ActionItem.returnRental:
      return redirectToHOLPageForReturnRentTools(assets, company);
    default:
      return null;
  }
};

export const isDomainUser = (user: IUserMeModel) => {
  return user?.isDomainUser;
};

export const isTenantTypeByD = (companyDetails: ICompanyModel) => {
  return companyDetails?.tenantType === HolRepairConstant.TENANT_TYPE_BYD;
};

export const isSameSoldToAccountSelected = (assets: IAsset[]) => {
  return HelperLodash.uniqBy(assets, (a) => a.originCustomerId + a.moCountryCode).length === 1;
};

export const assetHasOriginCustomerNumber = (asset: IAsset) => asset.originCustomerId;

export const assetIsHiltiAsset = (asset: IAsset) => asset.isSapAsset;

export const assetIsFleetAsset = (asset: IAsset) => asset.ownerShipType?.code === OwnershipType.FLEET;

export const isAssetEligibleForRepair = (asset: IAsset) => {
  return eligibleHiltiAssetStatusesForRepair.includes(asset.hiltiAssetStatus.code);
};

export const isAssetEligibleForLostStolen = (asset: IAsset) => {
  return !ineligibleHiltiAssetStatusesForLostStolen.includes(asset.hiltiAssetStatus.code);
};

export const isSelectionThresholdReached = (assets: IAsset[]) => {
  return assets.length > MAX_LENGTH_SELECTION;
};

export const isAssetAllowedRepair = (asset: IAsset) => {
  return (
    asset.assetStatusName.code === StatusCode.Lost ||
    asset.assetStatusName.code === StatusCode.Disposed ||
    asset.assetStatusName.code === StatusCode.Retired ||
    asset.assetStatusName.code === StatusCode.Intransit ||
    asset.assetStatusName.code === StatusCode.Repair ||
    asset.assetStateName.code === StatusCode.Intransit
  );
};
