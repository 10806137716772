import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M14,22 L14,10.432 L18.5,14.8492424 L21.0918831,12.0208153 L12,3 L10.004,5.001 L10,5 L10,5.005 L3,12.0208153 L5.82842712,14.8492424 L10,10.678 L10,22 L14,22 Z" />
    </g>
  </svg>
);

export default SVG;
