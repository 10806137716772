// https://marvelapp.com/project/2935405/screen/49915272
const colorPalette = {
  black: "#000000",
  black10: "#E5E5E5",
  black2: "#FAFAFA",
  black20: "#CCCCCC",
  black30: "#B2B2B2",
  black80: "#333333",
  black90: "#191919",
  blackrgba: "rgba(0, 0, 0, 0.6)",
  blackrgba20: "rgba(0, 0, 0, 0.2)",
  blue: "#4196CD",
  breakRed: "#C30000",
  burgundy: "#671C3E",
  customDarkGrey: "#BaB9B2",
  customLightGrey: "#F8F8F8",
  customTarawera: "#053B4E",
  darkBlue: "#005F96",
  darkPink: "#61223F",
  darkTurqoise: "#00877D",
  dashboardGreen: "#00804A",
  dashboardRed: "#C30000",
  grayishOrange: "#D7CEBE",
  green: "#19AF37",
  green40: "#A3DFAF",
  grey: "#D7CEBE",
  greyDark: "#F1F1F1",
  greyrgba: "rgba(82, 79, 83, 0.5)",
  heavyConcrete: "#887F6E",
  heavyConcrete10: "#F3F2F0",
  heavyConcrete20: "#E7E5E2",
  heavyConcrete30: "#D8D8D3",
  heavyConcrete40: "#CFCCC5",
  heavyConcrete60: "#B7B3AA",
  heavyConcrete80: "#A0998B",
  heavyConcreteAlpha: "136,127,110",
  hiltiRed: "#D2051E",
  hiltiRed15: "#AB0115",
  hiltiRedError: "#FF0000",
  hiltiRedHover: "#AB0115",
  hiltiRedNotes: "#D0021B",
  lightBlackColor: "#D7CEDE",
  lightBlue: "#E0F0F9",
  lightGreen: "#82BE00",
  lightGrey: "#f9f8f8",
  lightGreyColor: "#D8CFBF",
  lightGreyFadeColor: "#D8D8D8",
  lightRedColor: "#C30000",
  lightTurqoise: "#A0D5CB",
  mapAccuracyBlue: "#4385f4",
  offWhite: "#F3F2F0",
  orange: "#E97300",
  pink: "#FFD7D8",
  placeholderColor: "#AAA7AB",
  statusBlue: "#2F6D96",
  statusLightGreen: "#D8F4DF",
  statusgreen: "#117A26",
  steel: "#524F53",
  steel10: "#2D2C2D",
  steel20: "#DDDCDD",
  steel30: "#CBCACB",
  steel40: "#BAB9BA",
  steel60: "#989598",
  steel80: "#757275",
  steel90: "#636064",
  titleColor: "#2E2C2E",
  transparent: "transparent",
  warmConcrete: "#D7CEBD",
  warmConcrete20: "#F7F5F2",
  warmConcrete40: "#EFEBE5",
  warmConcrete60: "#E7E2DB",
  warmConcrete80: "#DFD8CA",
  warmConcrete100: "#D6CEBD",
  warningSecondaryYellow: "#FFF3D6",
  white: "#FFFFFF",
  whiteFaded: "#534f53",
  whiteGrey: "#EFEFEF",
  whiteSmoke: "#F5F5F5",
  yellow: "#906300",
  highlightColor: "#ffaf0080",
};

export default colorPalette;
