import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GridCombinationalCell } from "../cells/gridCombinationalCell";
import { dependencyResolver } from "../gridUtils";
import { IGridCell } from "./iGridCell";

const LinkWrapper = styled.div`
  a {
    display: inline-flex;
  }
  .text {
    text-decoration: underline;
  }
`;

/**
 * @description renderColumn will render the values for single colum.
 * @param bindProperty { Array Object} contains the keys of the column.
 * @index {number} contains the index of the data.
 */
export const handleClick = (dependencyName, props) => {
  const { dependencies } = props;
  return dependencyResolver(dependencies, dependencyName)(props);
};

export const GridLinkCell = React.memo((props: IGridCell) => {
  const { column, data, dependencies, rowIndex, columnIndex, showTooltip } = props;
  const linkHandler = props.column.bindProperties.map((bindProperty) => {
    return bindProperty.linkHandler;
  });
  return (
    <LinkWrapper>
      <Link to={handleClick(linkHandler, props)} onClick={(e) => e.stopPropagation()}>
        <GridCombinationalCell
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          column={column}
          data={data}
          showTooltip={showTooltip}
          dependencies={dependencies}
        />
      </Link>
    </LinkWrapper>
  );
});

export default GridLinkCell;
