// @ts-ignore
import styled from "custom-styled-components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import IStyleHeadingPanelProps from "./iStyleHeadingPanelProps";

/**
 * @description - StyledHeadingCount is a Styled Component that consist of:
 * CountText: This is the style for main value of the content like of asset count etc.
 * @const StyledHeadingCount
 * @interface IStyleHeadingPanelProps
 */

export const StyledHeadingCount = styled("span")`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props: IStyleHeadingPanelProps) =>
    props.countText === "0" ? theme.locationDetail.locationBadge.steel : theme.header.color};
`;
