import React from "react";
const SVG = () => (
  <svg width="23px" height="20px" viewBox="0 0 23 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3532.000000, -2380.000000)">
        <g transform="translate(3530.000000, 2376.000000)">
          <g>
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M15.4351006,16.1506859 L15.4351006,14.2989152 L12.9655702,13.1715729 L15.4351006,11.9970128 L20.5555691,12 L22.4759402,13.3524869 L20.5355339,15.2928932 L18.4142136,13.1715729 L15.4351006,16.1506859 Z M17,10 C15.3431458,10 14,8.65685425 14,7 C14,5.34314575 15.3431458,4 17,4 C18.6568542,4 20,5.34314575 20,7 C20,8.65685425 18.6568542,10 17,10 Z M8,12 C6.34314575,12 5,10.6568542 5,9 C5,7.34314575 6.34314575,6 8,6 C9.65685425,6 11,7.34314575 11,9 C11,10.6568542 9.65685425,12 8,12 Z M5.43510056,13.9970128 L10.5555691,14 L14,15.7008417 L14,20 L2,19.9970128 L2,15.7008417 L5.43510056,13.9970128 Z M21.9497475,19.5355339 L24.0710678,21.6568542 L22.6568542,23.0710678 L20.5355339,20.9497475 L18.4142136,23.0710678 L17,21.6568542 L19.1213203,19.5355339 L17,17.4142136 L18.4142136,16 L20.5355339,18.1213203 L22.6568542,16 L24.0710678,17.4142136 L21.9497475,19.5355339 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
