import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="AM-3.0---Web-Icons-Copy" transform="translate(-218.000000, -578.000000)" fill="#524F53">
        <g id="05-Icons-/-Chat-/-Chat" transform="translate(216.000000, 576.000000)">
          <path
            d="M2,2 L22,2 L22,18 L22,22 L18,18 L2,18 L2,2 Z M6,6 L6,8 L18,8 L18,6 L6,6 Z M12,12 L12,14 L18,14 L18,12 L12,12 Z"
            id="🎨Color"
            transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
