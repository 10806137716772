import React from "react";
import AsyncSelect from "react-select/lib/Async";
import { Field } from "redux-form";
import styled from "styled-components";
import ApplicationConstants from "../../constant/applicationConstants";
import KeyCodes from "../../constant/keyCodes";
import { debounce } from "../../helpers/helperLodash";
import { getDebounceValue } from "../../helpers/sharedUtils";
import { colorPalette } from "../../materials";
import { default as TextField } from "../Field/field";
import IAsyncMultiSelect from "./iAsyncMultiSelect";

// @ts-ignore
import iconSearch from "./search.svg";

/**
 * @description - Styling the "AsyncSelect" component to match the look n feel as per out need.
 */
export const StyledMultiSelect = styled(AsyncSelect)`
  width: 100%;
  position: relative;
  > div.asyncAS__control {
    border: 2px solid
      ${(props) =>
        props["data-error"]
          ? colorPalette.hiltiRedError
          : props.disabled
            ? colorPalette.heavyConcrete20
            : colorPalette.heavyConcrete60};
    border-radius: 0;
    cursor: text;
    min-height: 44px;
    box-shadow: none;
    div div {
      border-radius: 12px;
      height: 23px;
      margin-bottom: 9px;
      margin-left: -1px;
      &:first-child {
        margin-left: 7px;
      }
    }
    div div div {
      font-family: ${(props) => props.theme.fontNormal.fontFamily};
      line-height: 17px;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: 300;
      text-align: center;
      color: ${colorPalette.steel};
      margin-left: 3px;
    }
  }
  > div.asyncAS__control:after {
    content: "";
    height: 43px;
    width: 43px;
    background: url(${(props: IAsyncMultiSelect) => props.searchIcon}) ${colorPalette.heavyConcrete20} no-repeat;
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    margin-top: 0.5px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  > div.asyncAS__control > div.asyncAS__value-container {
    cursor: pointer;
    padding-top: 7px;
    padding-right: 45px;
  }
  > div.asyncAS__control > div.asyncAS__value-container > div.asyncAS__placeholder {
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    color: ${colorPalette.steel40};
  }
  > div.asyncAS__control > div.asyncAS__value-container > div.asyncAS__single-value {
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    color: ${colorPalette.steel};
  }
  > div.asyncAS__control > div.asyncAS__value-container > div.asyncAS__input > input {
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    color: ${colorPalette.steel};
  }
  > div.asyncAS__control > div.asyncAS__indicators {
    display: none;
  }
  > div.asyncAS__menu {
    margin: 0;
    box-shadow:
      0 2px 12px 0 ${colorPalette.steel},
      inset 0 1px 0 0 ${colorPalette.heavyConcrete};
    border-radius: 0;
  }
  > div.asyncAS__menu > div.asyncAS__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option {
    line-height: 32px;
    padding: 8px 16px;
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option:hover {
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    background: ${colorPalette.offWhite};
    color: ${colorPalette.black};
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option--is-focused {
    background: ${colorPalette.offWhite};
    color: ${colorPalette.black};
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option--is-selected {
    color: ${colorPalette.black};
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    background: ${colorPalette.offWhite};
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option:empty {
    display: none;
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__menu-notice--no-options {
    display: none;
  }
`;
const maxLength = (max: number) => (value, errorMessage) => (value && value.length > max ? errorMessage : undefined);
/**
 * @description - This is the AsynchronousSelect component handleChange.
 * @param - event - event target of input
 * @param - clearInput - handle clear of input box
 * @param - input - input box of AsynchronousSelect
 */
export const handleChange = (event, clearInput, input) => {
  if (event.target.value) {
    const values = event.target.value && event.target.value.trim();
    const items = values.split(",");
    const isValid =
      items.filter((item) => {
        return (
          item === "" ||
          item.length < ApplicationConstants.LENGTH.LEN3 ||
          item.length > ApplicationConstants.LENGTH.LEN64
        );
      }).length === 0;
    if (
      isValid &&
      event.target.value.length >= ApplicationConstants.LENGTH.LEN3 &&
      (event.keyCode === KeyCodes.enter_key_code || event.type === "blur" || event.keyCode === KeyCodes.comma_key_code)
    ) {
      event.preventDefault();
      let inputCollection = input.value || [];
      items.map((item) => {
        if (!inputCollection.includes(item)) {
          inputCollection = [...inputCollection, item];
          input.onChange(inputCollection);
        }
      });
      clearInput();
    }
  }
};
/**
 * @description - This is the AsynchronousSelect component that get its existance with redux-form.
 */
export const AsynchronousSelect = ({
  input,
  inputValue,
  clearInput,
  onInputChange,
  isDisabled,
  isMulti,
  label,
  loadOptions,
  placeholder,
  searchPlaceholder,
  errorMessage,
  meta: { warning, error },
}) => (
  <TextField
    name={input.name}
    // @ts-ignore
    htmlFor={name}
    label={label}
    error={error || maxLength(ApplicationConstants.LENGTH.LEN64)(inputValue, errorMessage)}
    touched={true}
    warning={warning}
  >
    <StyledMultiSelect
      data-error={error || maxLength(ApplicationConstants.LENGTH.LEN64)(inputValue, errorMessage)}
      searchIcon={iconSearch}
      inputValue={inputValue}
      classNamePrefix="asyncAS"
      value={
        input.value
          ? input.value.map((item) => {
              return { value: item, label: item };
            })
          : null
      }
      loadOptions={loadOptions}
      label={label}
      className="asyncASContainer"
      onInputChange={onInputChange}
      onKeyDown={(event) => handleChange(event, clearInput, input)}
      onBlur={(event) => handleChange(event, clearInput, input)}
      onChange={(items) => {
        input.onChange(items.map((item) => item.value));
      }}
      isMulti={isMulti}
      placeholder={placeholder || searchPlaceholder}
      {...loadOptions}
      isDisabled={isDisabled}
    />
  </TextField>
);

/**
 * @description - This is the AsyncMultiSelect component that is using "AsyncSelect" of "react-select" library.
 * It is used to search the list of results using the promise API and show it as auto-suggestions.
 */
export class AsyncMultiSelect extends React.Component<IAsyncMultiSelect> {
  state = { inputValue: "" };

  debounceStore;
  constructor(props) {
    super(props);
    this.debounceStore = null;
  }
  /**
   *  @description onInputChange - captures the value of input being typed on asyncmultiselect.
   *  @param value - typed value to input.
   */
  onInputChange = (value) => {
    this.setState({ inputValue: value });
  };

  handleLoadOption = (val, callback) => {
    if (this.debounceStore) {
      this.debounceStore.cancel();
    }
    this.debounceStore = debounce(this.props.loadOptions, getDebounceValue());
    this.debounceStore(val, callback);
  };

  /**
   *  @description clearInput - resets the value of input being typed on asyncmultiselect.
   */
  clearInput = () => {
    this.setState({ inputValue: "" });
  };
  render() {
    {
      return (
        <Field
          name={this.props.name}
          id={this.props.id}
          component={AsynchronousSelect}
          isMulti={this.props.isMulti}
          label={this.props.label}
          placeholder={this.props.placeholder}
          loadOptions={(val, callback) => this.handleLoadOption(val, callback)}
          inputValue={this.state.inputValue}
          onInputChange={this.onInputChange}
          clearInput={this.clearInput}
          searchPlaceholder={this.props.searchPlaceholder}
          errorMessage={this.props.errorMessage}
          isDisabled={this.props.isDisabled}
        />
      );
    }
  }
}

export default AsyncMultiSelect;
