import React from "react";
import { DynamicModuleLoader } from "redux-dynamic-modules";
import gatewayReportSettingModule from "../../../administration/general/gatewayReportSetting/redux/gatewayReportSettingModule";
import assetTemplateModule from "../../../administration/template/assetTemplateModule";
import activeTrackingModule from "../../../assetTracking/redux/activeTracking/activeTrackingModule";
import chargesModule from "../../../charges/redux/chargesModule";
import addEditAssetModule from "../../reduxModule/addEditAsset/addEditAssetModule";
import assetModule from "../../reduxModule/assetList/assetModule";
import assetServiceModule from "../../reduxModule/assetService/assetServiceModule";
import AssetDetails from "./assetDetails";

const AssetDetailsWrapper = (props) => {
  return (
    <DynamicModuleLoader
      modules={[
        activeTrackingModule,
        assetModule,
        addEditAssetModule,
        assetTemplateModule,
        chargesModule,
        assetServiceModule,
        gatewayReportSettingModule,
      ]}
    >
      <AssetDetails {...props} />
    </DynamicModuleLoader>
  );
};

export default AssetDetailsWrapper;
