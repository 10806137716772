import React, { ReactNode } from "react";
import styled from "styled-components";
import IconComponent from "../../components/icons/iconComponent/iconComponent";
import { IconName } from "../../components/icons/iconEnum";
import { ICssSettingProps } from "./iCssSettingProps";
import MandatoryMark from "./mandatoryMark";
export interface IFieldProps extends ICssSettingProps {
  name: string;
  htmlFor?: string;
  label?: string | ReactNode;
  error?: string;
  children?: JSX.Element | JSX.Element[] | string;
  touched?: any;
  warning?: any;
  meta?: any;
  required?: boolean;
  hideValidation?: boolean;
  showSuccess?: boolean;
  successMessage?: string;
  getContent?: any;
  html?: any;
  place?: any;
  width?: any;
  validateForm?: any;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
  margin-top: ${(props: ICssSettingProps) => (props.cssSetting.marginTop ? props.cssSetting.marginTop : null)};
  margin-bottom: ${(props: ICssSettingProps) => props.cssSetting.marginBottom};
  .field-wrapper + .field-wrapper {
    margin-bottom: 0;
  }
  .radio-container {
    padding: 0;
  }
`;
const CommonSetting = styled.div`
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 300;
  line-height: 16px;
  padding-top: 2px;
  min-height: 14px;
`;

const ErrorLabel = styled(CommonSetting as any)`
  color: #ff0000;
`;

const SuccessLabel = styled.div`
  color: #19af37;
`;

const LabelStyle = styled.label`
  color: #524f53;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  padding-bottom: 5px;
`;

const Labelcontainer = styled.div`
  display: inline-flex;
  align-items: center;
  sup {
    align-self: baseline;
  }
  .icon {
    margin-left: 8px;
    margin-bottom: 3px;
  }
`;

class Field extends React.PureComponent<IFieldProps> {
  static defaultProps = {
    cssSetting: {
      marginBottom: "8px",
      marginTop: null,
    },
    hideValidation: false,
  };
  checkValidation = (
    hideValidation: boolean,
    touched: string,
    error: any,
    name: string,
    showSuccess: boolean,
    message: string,
  ) => {
    if ((error && hideValidation) || (!error && !showSuccess)) {
      return null;
    }
    if (touched && error) {
      return <ErrorLabel id={`${name}Err`}>{error}</ErrorLabel>;
    }
    if (touched && !error) {
      return <SuccessLabel id={`${name}Success`}>{message}</SuccessLabel>;
    } else {
      return "";
    }
  };

  render() {
    const {
      children,
      getContent,
      hideValidation,
      html,
      htmlFor,
      name,
      label,
      touched,
      error,
      place,
      required,
      showSuccess,
      successMessage,
      cssSetting,
      width,
    } = this.props;
    return (
      <Wrapper className="field-wrapper" cssSetting={cssSetting}>
        {label ? (
          <Labelcontainer className="label">
            <LabelStyle htmlFor={htmlFor} className={touched && error ? "errorLabel" : ""} id={`${name}Lbl`}>
              {label}
            </LabelStyle>
            {required ? <MandatoryMark /> : null}
            {getContent ? (
              <IconComponent
                icon={IconName.Info}
                id={`icon${name}`}
                getContent={getContent}
                isIconDisabled={true}
                html={html}
                place={place}
                width={width}
                className="icon"
              />
            ) : null}
          </Labelcontainer>
        ) : null}
        {children}
        {this.checkValidation(hideValidation, touched, error, name, showSuccess, successMessage)}
      </Wrapper>
    );
  }
}

export default Field;
