import React from "react";
import { components } from "react-select";
import styled from "styled-components";
import { IconName } from "../../../icons/iconEnum";
import { SquareIcon } from "../../../icons/iconWrapper";
import MultiSelectInputValue from "./multiSelectInputValue";

/**
 * @description Control {component} returns the customised input field.
 * @params props {object} contains the props of the dropdown
 */
const Control = (props) => {
  return <MultiSelectInputValue inputProps={props} />;
};

/**
 * @description ImgArrow to style the dropdown arrow indicator
 */
const Arrow = styled(SquareIcon)`
  transform: rotate(90deg);
  &.menuOpen {
    transform: rotate(-90deg);
  }
`;

const NoOptionsMessageWrapper = styled.span`
  margin: 20px;
  display: block;
`;

/**
 * @description Option {component} returns the customised dropdown indicator.
 * @params props {object} contains the props of the dropdown
 */
const DropdownIndicator = (props) => {
  return <Arrow className={props.selectProps.menuIsOpen ? "menuOpen" : "menuClose"} name={IconName.Arrow} />;
};

/**
 * @description Placeholder {component} returns the customised placeholder.
 * @params props {object} contains the props of the dropdown
 */
const Placeholder = (props) => {
  return (
    <div id={props.selectProps.name} color="textSecondary" className={"dropDownPlaceholder"} {...props.innerProps}>
      {props.children}
    </div>
  );
};

/** Custom value container   */
const CustomValueContainer = ({ children, ...props }) => {
  const { ValueContainer } = components;
  const commonProps = {
    clearValue: props.clearValue,
    cx: props.cx,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    selectProps: props.selectProps,
    setValue: props.setValue,
    theme: props.theme,
  };

  return (
    <ValueContainer {...props}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <components.SingleValue
            {...commonProps}
            isFocused={props.selectProps.isFocused}
            isDisabled={props.selectProps.isDisabled}
          >
            {props.selectProps.getOptionLabel(props.getValue()[0])}
          </components.SingleValue>
        ) : (
          <Placeholder {...commonProps}>{props.selectProps.placeholder}</Placeholder>
        );
      })}
    </ValueContainer>
  );
};

const NoOptionsMessage = (props) => {
  return <NoOptionsMessageWrapper>{props.selectProps.noOptionMessage()}</NoOptionsMessageWrapper>;
};

export { Control, CustomValueContainer, DropdownIndicator, NoOptionsMessage, Placeholder };
