import React from "react";
import AssetDetailsWrapper from "TARGET_BUILD/common/modules/asset/components/assetDetails/assetDetailsWrapper";
import SidePanelComponent from "TARGET_BUILD/common/components/sidePanel";
import { useTranslation } from "react-i18next";
import Title from "TARGET_BUILD/common/components/DetailsPanel/SingleRow/Title/title";
import useLicenseContext from "TARGET_BUILD/licenseStrategy/useLicenseContext";

const SidePanel = ({ assetId }: { assetId: number }) => {
  const { t } = useTranslation();
  const licenseConfig = useLicenseContext().assetDetails();
  return (
    <SidePanelComponent sidePanelWidth={315} headerHeight={136}>
      <Title id="suggested-transfer-assetdetails">{t("assets:ASSET_DETAILS")}</Title>
      <AssetDetailsWrapper t={t} withoutSidePanel selectedAsset={{ assetId }} config={licenseConfig} />;
    </SidePanelComponent>
  );
};

export default SidePanel;
