import styled from "styled-components";
// @ts-ignore
import WarningIcon from "TARGET_BUILD/ui-assets/icons/WarningIcon.svg";
import { LoaderElement, LoaderElementType } from "am-web-ui-shared/elements";
import { Ellipsis } from "am-web-ui-shared/components";
import { LastSeenStateEnum } from "../../../../../utils/enums/activeTrackingEnum";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { ILastSeen } from "../../../../../models/activeTracking/iLastSeenInfo";
import React from "react";
import { HelperLodash } from "am-web-ui-shared/helpers";
import ApplicationConstants from "../../../../../applicationConstants";

const getLastSeen = ({ ActiveTracking }: RootState) => ActiveTracking?.lastSeenMap;

const Wrapper = styled.div`
  position: relative;
`;
const WrapperLoader = styled.div`
  width: 20px;
`;

interface ILastSeenCell {
  assetId: number;
  tooltip: string;
  style: any;
  index: number;
}

const LastSeenSourceCell = (props: ILastSeenCell) => {
  const { assetId, tooltip, style, index } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const lastSeenMap = useSelector(getLastSeen) as Map<number, ILastSeen>;
  if (!lastSeenMap) {
    return null;
  }

  const item: ILastSeen = HelperLodash.get(lastSeenMap, assetId);
  const state = item?.metadata?.state || "";
  const display = item?.lastSeen?.lastSeenBy || "-";

  return (
    <Wrapper
      id="lastSeenSourceCell"
      className={index % ApplicationConstants.NUMBER.NUM2 === 0 ? "cell-top-element" : "cell-bottom-element"}
    >
      {state === LastSeenStateEnum.LOADING && (
        <WrapperLoader>
          <LoaderElement type={LoaderElementType.GRAY} />
        </WrapperLoader>
      )}
      {state === LastSeenStateEnum.FAILURE && <img src={WarningIcon} width="16" alt={tooltip} />}
      {state === LastSeenStateEnum.SUCCESS && (
        <Ellipsis id={tooltip} showTooltip={true} tooltipValue={tooltip} style={style}>
          {display}
        </Ellipsis>
      )}
    </Wrapper>
  );
};

export default LastSeenSourceCell;
