import React from "react";
import styled from "styled-components";
import { IconName } from "./iconEnum";
import { SquareIcon } from "./iconWrapper";
import { IIconLabelProps } from "./iIconLabelProps";
import OutsideClickAlerter from "../outSideClickAlerter/outSideClickComponet";

const Link = styled.a`
  color: ${(props) => props.theme.colors.base.steel};
  font-size: ${(props) => props.theme.fontSize.extraSmall};
  font-weight: 300;
  line-height: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
  &.action-gap {
    padding-left: 16px;
    padding-right: 16px;
  }
  &.action-wrapper {
    display: flex;
    flex-direction: column;
    width: 64px;
    height: 72px;
    justify-content: center;
    text-align: center;
    &:hover label {
      color: ${(props) => props.theme.colors.base.black};
    }
    &:hover svg path {
      fill: ${(props) => props.theme.colors.base.black};
    }
    &.disabled:hover label {
      color: ${(props) => props.theme.colors.base.steel};
    }
    &.disabled:hover svg path {
      fill: ${(props) => props.theme.colors.base.steel};
    }
  }
`;

const LinkText = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  height: 26px;
  padding-top: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

const ToggleIconWrapper = styled.span`
  display: inline-flex;
  width: 12px;
  height: 12px;
  position: absolute;
  right: -12px;
  bottom: 0px;
  align-items: center;
  svg {
    transform: ${(props: any) => (props.isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
  }
`;

class Icon extends React.PureComponent<IIconLabelProps, { isOpen }> {
  ref = null;
  state = {
    isOpen: false,
  };

  onClickHandler = (e) => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    if (this.props.handleClick) {
      this.props.handleClick(e, this.ref);
    }
  };

  onClickAway = () => {
    this.setState(() => ({ isOpen: false }));
  };

  getToggleComponent = () => (
    // @ts-ignore
    <ToggleIconWrapper isOpen={this.state.isOpen}>
      <SquareIcon id={this.props.id} className="toggle" name={IconName.Chevron} imageSize={12} />
    </ToggleIconWrapper>
  );

  render() {
    const { className, iconName, iconText, id, toggle, isIconDisabled, iconClass, imageSize } = this.props;

    return (
      <React.Fragment>
        <Link
          data-for={id.toString()}
          data-tip=""
          onClick={!isIconDisabled ? this.onClickHandler : null}
          className={className}
          id={id}
          ref={(elementRef) => (this.ref = elementRef)}
        >
          <IconWrapper>
            <SquareIcon id={id} name={iconName} className={iconClass} imageSize={imageSize} />

            {toggle ? (
              <OutsideClickAlerter
                ignoreClickedElement={[id]}
                render={this.getToggleComponent}
                onClickAway={this.onClickAway}
              />
            ) : null}
          </IconWrapper>
          {iconText && <LinkText className="icon-label">{iconText}</LinkText>}
        </Link>
      </React.Fragment>
    );
  }
}

export default Icon;
