const ReportTemplateActionConstants = {
  CLEAR_REPORT_TEMPLATES_COLUMNS: "CLEAR_REPORT_TEMPLATES_COLUMNS",
  CLEAR_REPORT_TEMPLATE_DETAIL: "CLEAR_REPORT_TEMPLATE_DETAIL",
  CLEAR_REPORT_TEMPLATES_FILTERS: "CLEAR_REPORT_TEMPLATES_FILTERS",
  CLEAR_WORKER_DETAILS_FOR_REPORT: "CLEAR_WORKER_DETAILS_FOR_REPORT",
  CREATE_REPORT_TEMPLATES_SUCCESS: "CREATE_REPORT_TEMPLATES_SUCCESS",
  REPORT_TEMPLATE_DESELECT_ROW: "REPORT_TEMPLATE_DESELECT_ROW",
  REPORT_TEMPLATE_SELECT_ROW: "REPORT_TEMPLATE_SELECT_ROW",
  REP_CREATE_REPORT_TEMPLATE: "REP_CREATE_REPORT_TEMPLATE",
  REP_EDIT_REPORT_TEMPLATE: "REP_EDIT_REPORT_TEMPLATE",
  REP_DELETE_REPORT_TEMPLATE: "REP_DELETE_REPORT_TEMPLATE",
  REP_GENERATE_REPORT: "REP_GENERATE_REPORT",
  REP_GET_REPORT_TEMPLATES_LIST: "REP_GET_REPORT_TEMPLATES_LIST",
  REP_GET_REPORT_TEMPLATES_LIST_SUCCESS: "REP_GET_REPORT_TEMPLATES_LIST_SUCCESS",
  REP_REPORT_TEMPLATE_CUSTOM_VIEW: "REP_REPORT_TEMPLATE_CUSTOM_VIEW",
  REP_REPORT_TEMPLATE_CUSTOM_VIEW_SUCCESS: "REP_REPORT_TEMPLATE_CUSTOM_VIEW_SUCCESS",
  REP_REPORT_TEMPLATE_ATTRIBUTES: "REP_REPORT_TEMPLATE_ATTRIBUTES",
  REP_REPORT_TEMPLATE_ATTRIBUTES_SUCCESS: "REP_REPORT_TEMPLATE_ATTRIBUTES_SUCCESS",
  REP_GET_REPORT_TEMPLATES_COLUMNS: "REP_GET_REPORT_TEMPLATES_COLUMNS",
  REP_GET_REPORT_TEMPLATES_COLUMNS_SUCCESS: "REP_GET_REPORT_TEMPLATES_COLUMNS_SUCCESS",
  REP_GET_REPORT_TEMPLATES_FILTERS: "REP_GET_REPORT_TEMPLATES_FILTERS",
  REP_GET_REPORT_TEMPLATE_BY_ID: "REP_GET_REPORT_TEMPLATE_BY_ID",
  GENERATE_REPORT_SUCCESS: "GENERATE_REPORT_SUCCESS",
  SET_REPORT_TEMPLATES_AVAILABLE_COLUMNS: "SET_REPORT_TEMPLATES_AVAILABLE_COLUMNS",
  SET_REPORT_TEMPLATE_DETAILS: "SET_REPORT_TEMPLATE_DETAILS",
  SET_REPORT_TEMPLATES_FILTERS: "SET_REPORT_TEMPLATES_FILTERS",
  SET_REPORT_TEMPLATES_SELECTED_COLUMNS: "SET_REPORT_TEMPLATES_SELECTED_COLUMNS",
  SET_REPORT_TEMPLATES_SELECTED_COLUMNS_IDS: "SET_REPORT_TEMPLATES_SELECTED_COLUMNS_IDS",
  GET_WORKER_FOR_REPORT: "GET_WORKER_FOR_REPORT",
  GET_WORKER_FOR_REPORT_SUCCESS: "GET_WORKER_FOR_REPORT_SUCCESS",
  SET_REPORT_CATEGORY_CODE: "SET_REPORT_CATEGORY_CODE",
  SET_IS_DATE_COL_SELECTED: "SET_IS_DATE_COL_SELECTED",
};

export default ReportTemplateActionConstants;
