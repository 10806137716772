import { SquareIcon } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import StyledBoldText from "../../Styled/BoldText/styledBoldText";
import StyledBeforeIconContainer from "../../Styled/Containers/BeforeIconContainer/styledBeforeIconContainer";
import { StyledHeadingCount } from "../StyleHeadingPanel/styleHeadingPanel";
import IIconBoldIconProps from "./iIconBoldIcon";

/**
 * @description - IconBold is a Styled Component that consist of 2 things:
 * 1. Icon: An Icon that will be visible at the left side of the content (::before).\
 * 2. Bold Text: This is the text with bold font-familiy and it is the bottom row.
 * 3. 1. Icon: An Icon that will be visible at the right side of the content (::after).\
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 *
 * @const IconBoldIcon
 * @interface IIconBoldIconProps
 */
enum Type {
  Inner = "innerText",
}
/**
 * Styled Container
 */
const StyledBeforeIconContainerWrapper = styled(StyledBeforeIconContainer)`
  && {
    padding: 0;
  }
`;

const StyledBoldColor = styled(StyledBoldText)`
  && {
    color: ${(props) => props.theme.colors.headingText.color};
  }
`;

const TextElement = styled.div`
  flex: 1;
  padding: 0 16px;
`;

/**
 * This function is used to styled countText according to its value
 */
const renderStyledHeadingCount = (countText) => {
  return <StyledHeadingCount countText={countText}>{countText}</StyledHeadingCount>;
};

const IconBoldIcon = (props: IIconBoldIconProps) => {
  const { id, boldId, boldText, iconUrl, postIconUrl, type, countText, iconClass, onClick } = props;
  return (
    <StyledBeforeIconContainerWrapper id={id} onClick={onClick}>
      <SquareIcon name={iconUrl} />
      <TextElement>
        {type === Type.Inner ? (
          <StyledBoldColor id={boldId}>
            {boldText}
            {renderStyledHeadingCount(countText)}
          </StyledBoldColor>
        ) : (
          <StyledBoldText id={boldId}>
            {boldText}
            {renderStyledHeadingCount(countText)}
          </StyledBoldText>
        )}
      </TextElement>
      <SquareIcon name={postIconUrl} className={iconClass} />
    </StyledBeforeIconContainerWrapper>
  );
};

/**
 * @export IconBoldIcon - Styled Component.
 */
export default IconBoldIcon;
