import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <title>ic_AccessLocations</title>
    <g stroke="none" fill="#524F53">
      <path d="M12,1 C16.4228571,1 20,3.8 20,9 C20,14.775 12,23 12,23 C12,23 4,14.775 4,9 C4,4 7.57714286,1 12,1 Z M15.3874833,4.0009348 L15.2274809,4.00177169 C13.9575947,4.05045377 12.941499,5.09623624 12.941499,6.37681374 L12.941499,6.37681374 L12.941499,6.85191384 L12.951001,7.80211404 L8.2,7.80342728 L8.2,13.9784153 L15.8016016,13.9784153 L15.81,7.802 L14.3763013,7.80211404 L14.3763013,6.56554054 L14.3805116,6.4410905 C14.4198124,5.86896355 14.7343078,5.4253003 15.3265015,5.4253003 L15.3265015,5.4253003 L15.4491506,5.4319762 C16.0016858,5.4937485 16.2767017,5.9766698 16.2767017,6.56554054 L16.2767017,6.56554054 L16.276,7.327 L17.6925,7.32701394 L17.6925,6.47658476 L17.6874015,6.31566886 C17.6129724,5.14218216 16.7313518,4.12442614 15.5497985,4.01081524 L15.5497985,4.01081524 L15.3874833,4.0009348 Z M12.4759009,9.70251444 L12.4759009,11.6029148 L11.5257007,11.6029148 L11.5257007,9.70251444 L12.4759009,9.70251444 Z"></path>
    </g>
  </svg>
);

export default SVG;
