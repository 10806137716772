import React from "react";
const SVG = () => (
  <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3238.000000, -2358.000000)" fill="#524F53">
        <g transform="translate(3235.000000, 2356.000000)">
          <path
            d="M21,9 L21,21 L3,21 L3,9 L21,9 Z M13,16 L11,16 L11,18 L13,18 L13,16 Z M8,16 L6,16 L6,18 L8,18 L8,16 Z M18,16 L16,16 L16,18 L18,18 L18,16 Z M8,12 L6,12 L6,14 L8,14 L8,12 Z M13,12 L11,12 L11,14 L13,14 L13,12 Z M18,12 L16,12 L16,14 L18,14 L18,12 Z M16,2 L19,2 L19,4 L21,4 L21,7 L3,7 L3,4 L5,4 L5,2 L8,2 L8,4 L16,4 L16,2 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
