import styled from "styled-components";

/**
 * @const StyledRowsWrapper - Styled component that is used to wrap the text rows.
 */
const StyledRowsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

/**
 * @export StyledRowsWrapper -  Styled component.
 */
export default StyledRowsWrapper;
