// @ts-ignore
import { AlertNotificationAction, AssetActions, AssetServiceActions } from "custom-actions";
import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../../../actions/toastrAction";
import {
  assignHistoryServiceApi,
  completeAssignedServiceOperation,
  editAssignedServiceOperation,
  getAssignServiceDetailOperation,
  getProviderList,
  getServiceFilterAttributes,
  getServicesAssignList,
  removeServiceApi,
} from "../../../../api/assetApi";
import { getAssetTemplateServiceFilterAttributes } from "../../../../api/assetTemplatesApi";
import { ServiceStatus } from "../../../../components/services/serviceFormController/serviceFormEnum";
import { validateResponse } from "../../../../utils/errorUtils";
import { call } from "../../../../sagas/common/commonSaga";
import { getQueryObj } from "../../../../sagas/stateSelector";
import { SharedUtils } from "am-web-ui-shared/helpers";
import GTMConstants from "../../../../gtmConstants";
import { FILE_SOURCE } from "TARGET_BUILD/common/components/services/model/iAssignedServiceDetail";

/**
 * @description - Saga to get custom views list.
 */
export function* assignHistoryServiceSaga() {
  yield takeLatest(AssetServiceActions.ASSIGN_HISTORY_SERVICE, assignHistoryService);
}

/**
 * @description - Function to get custom views list from api.
 */
function* assignHistoryService(action) {
  let serviceData = {};
  if (action.serviceStatus === ServiceStatus.HISTORY) {
    serviceData = {
      historyService: action.serviceData,
    };
  } else {
    serviceData = {
      openService: action.serviceData,
    };
  }

  const response = yield call(() => {
    return assignHistoryServiceApi(serviceData, action.assetId);
  });
  if (!response.error) {
    yield put({
      filterQuery: null,
      type: AssetServiceActions.SET_SERVICE_FILTER_QUERY,
    });
    yield put({
      payload: { serviceType: action.serviceStatus, filters: null, assetId: action.assetId },
      type: AssetServiceActions.AM_GET_SERVICE_FILTER_FIELD,
    });
    yield put({
      type: AssetServiceActions.ASSIGN_SERVICE_SUCCESS,
    });
    yield put({
      type: AlertNotificationAction.AN_GET_ALERTS_COUNT,
    });
    yield put(showToastr(ToastrType.success, `services:ASSIGNED_SUCCESSFULLY`));
  } else {
    yield put({
      payload: response.error,
      type: AssetServiceActions.SERVICE_OPERATION_ERROR,
    });
  }
}

/**
 * @description - Saga to get assign service list
 */
export function* getAssignServiceDetailSaga() {
  yield takeLatest(AssetServiceActions.GET_ASSGIN_SERVICE_DETAIL, getAssignServiceDetail);
}

/**
 * @description - Function to get custom views list from api.
 */
function* getAssignServiceDetail(action) {
  const serviceDetail = yield call(() => {
    return getAssignServiceDetailOperation(action.assetId, action.serviceId, action.serviceStatus, true);
  });
  if (!serviceDetail.error) {
    yield put({
      payload: serviceDetail,
      type: AssetServiceActions.GET_ASSGIN_SERVICE_DETAIL_SUCCESS,
    });
  }
}

export function* assetsServiceListSaga() {
  yield takeLatest(AssetServiceActions.AM_GET_ASSET_SERVICE_LIST, fetchServiceAssetsList);
}
function* fetchServiceAssetsList(action) {
  /*
    offset : 0 -> Load with initial state, show grid loader only for initial state.
    offset : (>0) -> Load list for pagination, paginated loader is shown by the infitescroll
    component at the bottom of the page
  */
  const queryObj = yield select(getQueryObj, action.offset, "AssetService", null, "assetServiceAppliedFilterQuery");

  let serviceData = yield call(() => {
    return getServicesAssignList(queryObj, action.id, action.status, action.offset === 0);
  });
  if (serviceData && serviceData.error) {
    serviceData = { response: [] };
  }
  // offset is required to decide whether to update the list or replace with new one.
  const payloadObj = {
    offset: action.offset,
    serviceList: serviceData,
    status: action.status,
  };
  yield put({ type: AssetServiceActions.AM_PUT_ASSET_SERVICE_LIST, payload: payloadObj });
}

/**
 * used make API call for retrieving service attributes
 */
export function* getServiceFilterAttributesSaga() {
  yield takeEvery(AssetServiceActions.AM_GET_SERVICE_FILTER_FIELD, fetchServiceFilterAttributes);
}

/**
 * used make API call for retrieving Service attributes
 *
 * @param fitlers : filters to apply while retreiving data
 */
export function* fetchServiceFilterAttributes(action) {
  const response = yield call(() => {
    return getServiceFilterAttributes(action.payload.serviceType, action.payload.filters, action.payload.assetId);
  });
  if (!response.error) {
    yield put({ type: AssetServiceActions.SERVICE_FILTER_FIELD_SUCCESS, payload: response });
  }
}

export function* assetsProviderListSaga() {
  yield takeLatest(AssetServiceActions.AM_GET_PROVIDER_LIST, fetchProviderList);
}
function* fetchProviderList(paction) {
  const queryObj = yield select(getQueryObj, paction.offset, "AssetService");
  const response = yield call(() => {
    return getProviderList(queryObj, paction.offset === 0);
  });
  if (!response.error) {
    yield put({ type: AssetServiceActions.AM_SET_PROVIDER, payload: response });
  }
}

/**
 * @description - Saga to edit assigned service
 */
export function* editAssignServiceSaga() {
  yield takeLatest(AssetServiceActions.EDIT_ASSIGNED_SERVICES, editAssignService);
}

/**
 * @description - Function to edit service data from api.
 */
function* editAssignService(action) {
  const attachmentList = action.serviceData?.serviceAttachments?.filter(
    (element) => element.fileSource !== FILE_SOURCE.CLOUD,
  );
  const editAssignedService = yield call(
    editAssignedServiceOperation,
    action.assetId,
    { ...action.serviceData, serviceAttachments: attachmentList },
    action.serviceId,
    action.serviceStatus,
    action.isRecurrentDateChange,
  );
  if (!editAssignedService.error) {
    if (action.getServiceList) {
      yield put({
        editAssignedServiceId: editAssignedService.id,
        id: action.assetId,
        offset: 0,
        status: action.serviceStatus,
        type: AssetServiceActions.AM_GET_ASSET_SERVICE_LIST,
      });
    } else {
      yield put({ currentView: action.currentView, offset: 0, type: AlertNotificationAction.AN_GET_ALERTS_LIST });
    }

    yield put({
      payload: editAssignedService,
      type: AssetServiceActions.EDIT_ASSIGNED_SERVICES_SUCCESS,
    });

    yield put({
      payload: { serviceType: action.serviceStatus, filters: null, assetId: action.assetId },
      type: AssetServiceActions.AM_GET_SERVICE_FILTER_FIELD,
    });
    yield put(showToastr(ToastrType.success, `common:SAVED_SUCCESSFULLY`));
  } else {
    yield put({
      payload: editAssignedService.error,
      type: AssetServiceActions.SERVICE_OPERATION_ERROR,
    });
  }
}
/*
 * Saga for remove asset assigned service.
 */
export function* removeServiceSaga() {
  yield takeLatest(AssetServiceActions.AM_REMOVE_ASSET_SERVICE, removeService);
}

/**
 * Function to remove asset assigned service.
 */
function* removeService(action) {
  const response = yield call(() => {
    return removeServiceApi(action.assetId, action.serviceId, action.serviceStatus);
  });
  if (!response.error) {
    yield put({
      id: action.assetId,
      offset: 0,
      status: action.serviceStatus,
      type: AssetServiceActions.AM_GET_ASSET_SERVICE_LIST,
    });
    yield put({
      assetId: action.assetId,
      disableLoader: true,
      type: AssetActions.AM_ASSET_DETAILS,
    });
    yield put({
      type: AssetServiceActions.ASSET_ASSIGNED_SERVICE_DATA_CLEAR,
    });
    yield put({
      payload: { serviceType: action.serviceStatus, filters: null, assetId: action.assetId },
      type: AssetServiceActions.AM_GET_SERVICE_FILTER_FIELD,
    });

    yield put(showToastr(ToastrType.success, "common:REMOVED_SUCCESSFULLY"));
  } else if (response.error && !response.isSystemError) {
    yield put(showToastr(ToastrType.error, `errorCodes:${validateResponse(response).code}`));
  }
}

/**
 * @description - Saga to complete assigned service
 */
export function* completeAssignServiceSaga() {
  yield takeLatest(AssetServiceActions.COMPLETE_ASSIGNED_SERVICES, completeAssignService);
}

/**
 * @description - Function to complete service data from api.
 */
function* completeAssignService(action) {
  const completeAssignedService = yield call(() => {
    return completeAssignedServiceOperation(action.assetId, action.serviceData);
  });
  if (!completeAssignedService.error) {
    if (action.getServiceList) {
      yield put({
        id: action.assetId,
        offset: 0,
        status: action.serviceData.serviceStatus,
        type: AssetServiceActions.AM_GET_ASSET_SERVICE_LIST,
      });
    } else {
      yield put({ currentView: action.currentView, offset: 0, type: AlertNotificationAction.AN_GET_ALERTS_LIST });
    }
    yield put({
      assetId: action.assetId,
      disableLoader: true,
      type: AssetActions.AM_ASSET_DETAILS,
    });
    yield put({
      payload: completeAssignedService,
      type: AssetServiceActions.COMPLETE_ASSIGNED_SERVICES_SUCCESS,
    });
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.MANUAL_SERVICE_COMPLETION);
  } else {
    yield put({
      payload: completeAssignedService.error,
      type: AssetServiceActions.SERVICE_OPERATION_ERROR,
    });
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.MANUAL_SERVICE_COMPLETION_ERROR);
  }
}
export function* getAssetTemplateServiceFilterAttributesSaga() {
  yield takeEvery(
    AssetServiceActions.GET_ASSET_TEMPLATE_SERVICE_FILTER_ATTRIBUTES,
    fetchAssetTemplateServiceFilterAttributes,
  );
}

/**
 * used make API call for retrieving asset attributes
 *
 * @param filters : filters to apply while retreiving data
 */
export function* fetchAssetTemplateServiceFilterAttributes(filter) {
  const response = yield call(() => {
    return getAssetTemplateServiceFilterAttributes(filter.filter, filter.templateId, filter.loader);
  });
  if (!response.error) {
    yield put({ type: AssetServiceActions.ASSET_TEMPLATE_SERVICE_FILTER_FIELD_SUCCESS, payload: response.response });
  }
}

function* assetServiceSaga() {
  yield all([
    assetsProviderListSaga(),
    assetsServiceListSaga(),
    assignHistoryServiceSaga(),
    completeAssignServiceSaga(),
    editAssignServiceSaga(),
    getAssetTemplateServiceFilterAttributesSaga(),
    getAssignServiceDetailSaga(),
    getServiceFilterAttributesSaga(),
    removeServiceSaga(),
  ]);
}

export default assetServiceSaga;
