import { IconName, IGridSetting } from "am-web-ui-shared/components";
import ImageTypeEnum from "../../../../../utils/enums/imageTypeEnum";
import theme from "TARGET_BUILD/theme";
import { ImageSizeEnum } from "../../../../../components/styled/squareImage/imageSizeUtil";
import squareImageContainer from "../../../../../components/styled/squareImage/squareImageContainer";

export const QtyItemPendingChargesGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          style: { color: `${theme.colors.base.black}`, fontFamily: `${theme.fontBold.fontFamily}`, fontSize: 16 },
        },
      ],
      cellSize: 4,
      image: {
        attributes: {
          image: { maxWidth: ImageSizeEnum.SMALL, maxHeight: ImageSizeEnum.SMALL },
          wrapper: {
            height: ImageSizeEnum.SMALL,
            imageSize: 42,
            style: { marginRight: "16px" },
            width: ImageSizeEnum.SMALL,
          },
        },
        dependencies: {
          getAlternateDefaultImage: "getAlternateDefaultImage",
        },
        component: squareImageContainer,
        defaultImage: IconName.Comodity,
        fieldName: "quantityItemImageId",
        type: ImageTypeEnum.thumbnail,
      },
      isSortable: true,
      type: "imageFieldCombination",
    },
    {
      cellSize: 3,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      cellSize: 2,
      isSortable: true,
      type: "fieldCombination",
    },
    {
      cellSize: 3,
      isSortable: true,
      type: "fieldCombination",
    },
  ],
  defaultQueryFields: [
    "scanCode",
    "stockQuantity",
    "chargeCenter",
    "dateArrived",
    "workdaysOnSite",
    "currentCosts",
    "manufacturerName",
    "model",
    "dateLeft",
  ],

  defaultSort: { label: "Date Arrived", name: "dateArrived", sortType: "+" },
  showTooltip: true,
};

export const ignoreSortProperties = ["workDaysOnSite"];
