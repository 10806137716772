import { PermissionConditions } from "am-web-ui-shared/helpers";

/**
 * @description - Object to save all the permissions in the system
 */
export const permissions = {
  ADD_ASSETS: "ADD_ASSETS",
  ADD_LOCATIONS: "ADD_LOCATIONS",
  ADD_QTY_ITEMS: "ADD_QTY_ITEMS",
  ADD_QTY_UNIT: "ADD_QTY_UNIT",
  ADD_TEMPLATES: "ADD_TEMPLATES",
  ADD_WORKERS: "ADD_WORKERS",
  ADVANCED_TRANSFER: "ADVANCED_TRANSFER",
  ADVANCE_NOTIFICATION_SETTING: "ADVANCE_NOTIFICATION_SETTING",
  BULK_ACTION: "BULK_ACTION",
  CREATE_SERVICES_TEMPLATE: "CREATE_SERVICES_TEMPLATE",
  DELETE_ASSETS: "DELETE_ASSETS",
  DELETE_LOCATIONS: "DELETE_LOCATIONS",
  DELETE_QTY_ITEMS: "DELETE_QTY_ITEMS",
  DELETE_QTY_UNIT: "DELETE_QTY_UNIT",
  DELETE_TEMPLATES: "DELETE_TEMPLATES",
  DELETE_WORKERS: "DELETE_WORKERS",
  EDIT_ASSETS: "EDIT_ASSETS",
  EDIT_ASSET_COST_SETTINGS: "EDIT_ASSET_COST_SETTINGS",
  EDIT_LOCATIONS: "EDIT_LOCATIONS",
  EDIT_QTY_ITEMS: "EDIT_QTY_ITEMS",
  EDIT_QTY_UNIT: "EDIT_QTY_UNIT",
  EDIT_TEMPLATES: "EDIT_TEMPLATES",
  EDIT_WORKERS: "EDIT_WORKERS",
  GENERAL_ACCESS_ASSET: "GENERAL_ACCESS_ASSET",
  GENERAL_ACCESS_ASSET_COST_SETTINGS: "GENERAL_ACCESS_ASSET_COST_SETTINGS",
  GENERAL_ACCESS_ASSET_COST_TAB: "GENERAL_ACCESS_ASSET_COST_TAB",
  GENERAL_ACCESS_SMART_MAP_TAB: "VIEW_SMART_MAP",
  GENERAL_ACCESS_ASSET_TEMPLATE: "GENERAL_ACCESS_ASSET_TEMPLATE",
  GENERAL_ACCESS_ASSET_TRANSFER: "GENERAL_ACCESS_ASSET_TRANSFER",
  GENERAL_ACCESS_QTY_ITEMS: "GENERAL_ACCESS_QTY_ITEMS",
  GENERAL_ACCESS_REPORT: "GENERAL_ACCESS_REPORT",
  GENERAL_ACCESS_SERVICES: "GENERAL_ACCESS_SERVICES",
  GENERAL_ACCESS_WORKER: "GENERAL_ACCESS_WORKER",
  GENERATE_REPORTS: "GENERATE_REPORTS",
  GENERATE_REPORT_BASIC: "GENERATE_REPORT_BASIC",
  LIMITED_EDIT: "LIMITED_EDIT",
  LIMITED_EDIT_QTY_ITEMS: "LIMITED_EDIT_QTY_ITEMS",
  MANAGE_COMPANY: "MANAGE_COMPANY",
  MANAGE_LABELS: "MANAGE_LABELS",
  MANAGE_SERVICES: "MANAGE_SERVICES",
  PERFORM_TRANSFER: "PERFORM_TRANSFER",
  PRICE_MANAGEMENT: "PRICE_MANAGEMENT",
  PRICING_QTY_ITEMS: "PRICING_QTY_ITEMS",
  QUICK_ACTIONS: "QUICK_ACTIONS",
  REMOVE_SERVICES: "REMOVE_SERVICES",
  ROLE_MANAGEMENT: "ROLE_MANAGEMENT",
  SET_STORAGE_ASSET: "SET_STORAGE_ASSET",
  SNOOZE_DISMISS: "SNOOZE_DISMISS",
  TRANSFER_CART: "TRANSFER_CART",
  TRANSFER_ADMIN: "TRANSFER_ADMIN",
  IDLE_TIME_INFORMATION: "IDLE_TIME_INFORMATION",
  BATTERY_STATE_HEALTH_INFORMATION: "BATTERY_STATE_HEALTH_INFORMATION",
};

const asset = {
  add: {
    allow: [permissions.ADD_ASSETS, permissions.ADD_TEMPLATES, permissions.EDIT_ASSETS],
  },
  attachments: {
    add: {
      allow: [permissions.EDIT_ASSETS],
    },
    delete: {
      allow: [permissions.EDIT_ASSETS],
    },
    edit: {
      allow: [permissions.EDIT_ASSETS],
    },
  },
  bulk: {
    delete: {
      allow: [permissions.BULK_ACTION, permissions.DELETE_ASSETS],
    },
    edit: {
      allow: [permissions.BULK_ACTION, permissions.EDIT_ASSETS],
    },
  },
  delete: {
    allow: [permissions.DELETE_ASSETS, permissions.EDIT_ASSETS],
  },
  edit: {
    allow: [permissions.EDIT_ASSETS, permissions.PRICE_MANAGEMENT, permissions.LIMITED_EDIT],
  },
  import: {
    allow: [permissions.ADD_ASSETS, permissions.ADD_TEMPLATES, permissions.EDIT_ASSETS],
  },
  labels: {
    add: {
      allow: [permissions.LIMITED_EDIT, permissions.EDIT_ASSETS],
    },
    bulk: {
      add: {
        allow: [permissions.BULK_ACTION, permissions.EDIT_ASSETS],
      },
      delete: {
        allow: [permissions.BULK_ACTION, permissions.EDIT_ASSETS],
      },
      view: {
        allow: [permissions.BULK_ACTION, permissions.EDIT_ASSETS],
      },
    },
    delete: {
      allow: [permissions.LIMITED_EDIT, permissions.EDIT_ASSETS],
    },
  },
  ownershipType: {
    add: {
      allow: [permissions.LIMITED_EDIT, permissions.EDIT_ASSETS],
    },
    view: {
      allow: [permissions.LIMITED_EDIT, permissions.EDIT_ASSETS],
    },
  },
  price: {
    add: {
      allow: [permissions.PRICE_MANAGEMENT],
    },
    view: {
      allow: [permissions.PRICE_MANAGEMENT],
    },
  },
  status: {
    add: {
      allow: [permissions.LIMITED_EDIT, permissions.EDIT_ASSETS],
      condition: PermissionConditions.ANY,
    },
    bulk: {
      edit: {
        allow: [permissions.BULK_ACTION, permissions.EDIT_ASSETS],
      },
      view: {
        allow: [permissions.BULK_ACTION],
      },
    },
    delete: {
      allow: [permissions.LIMITED_EDIT, permissions.EDIT_ASSETS],
      condition: PermissionConditions.ANY,
    },
    edit: {
      allow: [permissions.LIMITED_EDIT, permissions.EDIT_ASSETS],
      condition: PermissionConditions.ANY,
    },
  },
  transfer: {
    add: {
      allow: [permissions.PERFORM_TRANSFER],
    },
    backDate: { allow: [permissions.PERFORM_TRANSFER, permissions.ADVANCED_TRANSFER] },
    cancel: {
      allow: [permissions.PERFORM_TRANSFER, permissions.ADVANCED_TRANSFER],
    },
    export: {
      allow: [permissions.GENERATE_REPORTS],
    },
    history: {
      allow: [permissions.GENERAL_ACCESS_ASSET_TRANSFER],
    },
    setReturnDate: {
      allow: [permissions.PERFORM_TRANSFER],
    },
    view: {
      allow: [permissions.PERFORM_TRANSFER, permissions.ADVANCED_TRANSFER],
      condition: PermissionConditions.ANY,
    },
    perform: {
      allow: [permissions.PERFORM_TRANSFER],
    },
  },
  type: {
    any: {
      allow: [permissions.SET_STORAGE_ASSET],
      condition: PermissionConditions.ANY,
    },
    storageAsset: {
      allow: [permissions.SET_STORAGE_ASSET],
    },
  },
};

const assetTemplate = {
  add: {
    allow: [permissions.EDIT_TEMPLATES, permissions.ADD_TEMPLATES],
  },
  attachments: {
    add: {
      allow: [permissions.EDIT_TEMPLATES],
    },
    delete: {
      allow: [permissions.EDIT_TEMPLATES],
    },
    edit: {
      allow: [permissions.EDIT_TEMPLATES],
    },
  },
  bulk: {
    delete: {
      allow: [permissions.BULK_ACTION, permissions.EDIT_TEMPLATES, permissions.DELETE_TEMPLATES],
    },
  },
  delete: {
    allow: [permissions.EDIT_TEMPLATES, permissions.DELETE_TEMPLATES],
  },
  edit: {
    allow: [permissions.EDIT_TEMPLATES],
  },
  image: {
    add: {
      allow: [permissions.EDIT_TEMPLATES],
    },
    delete: {
      allow: [permissions.EDIT_TEMPLATES],
    },
    edit: {
      allow: [permissions.EDIT_TEMPLATES],
    },
  },
  merge: {
    allow: [permissions.DELETE_TEMPLATES],
  },
};

const manufacturer = {
  add: {
    allow: [permissions.EDIT_TEMPLATES, permissions.EDIT_QTY_UNIT],
    condition: PermissionConditions.ANY,
  },
  bulk: {
    delete: {
      allow: [permissions.BULK_ACTION, permissions.DELETE_TEMPLATES],
    },
  },
  delete: {
    allow: [permissions.DELETE_TEMPLATES, permissions.DELETE_QTY_UNIT],
    condition: PermissionConditions.ANY,
  },
  edit: {
    allow: [permissions.EDIT_TEMPLATES, permissions.EDIT_QTY_UNIT],
    condition: PermissionConditions.ANY,
  },
  export: {
    allow: [permissions.GENERATE_REPORTS],
  },
  import: {
    allow: [permissions.ADD_TEMPLATES],
  },
  merge: {
    allow: [permissions.DELETE_TEMPLATES],
  },
};

const assetGroup = {
  add: {
    allow: [permissions.EDIT_TEMPLATES, permissions.EDIT_QTY_UNIT, permissions.ADD_QTY_UNIT],
    condition: PermissionConditions.ANY,
  },
  delete: {
    allow: [permissions.DELETE_TEMPLATES, permissions.DELETE_QTY_UNIT],
    condition: PermissionConditions.ANY,
  },
  edit: {
    allow: [permissions.DELETE_TEMPLATES, permissions.EDIT_QTY_UNIT],
    condition: PermissionConditions.ANY,
  },
  move: {
    allow: [permissions.DELETE_TEMPLATES, permissions.EDIT_QTY_UNIT],
    condition: PermissionConditions.ANY,
  },
};

const location = {
  add: {
    allow: [permissions.ADD_LOCATIONS, permissions.EDIT_LOCATIONS],
  },
  archived: {
    view: {
      allow: [permissions.ADD_LOCATIONS],
    },
  },
  bulk: {
    delete: {
      allow: [permissions.BULK_ACTION, permissions.DELETE_LOCATIONS],
    },
  },
  delete: {
    allow: [permissions.DELETE_LOCATIONS, permissions.EDIT_LOCATIONS],
  },
  edit: {
    allow: [permissions.EDIT_LOCATIONS],
  },
  import: {
    allow: [permissions.ADD_LOCATIONS, permissions.EDIT_LOCATIONS],
  },
  labels: {
    add: {
      allow: [permissions.EDIT_LOCATIONS],
    },
    delete: {
      allow: [permissions.EDIT_LOCATIONS],
    },
    edit: {
      allow: [permissions.EDIT_LOCATIONS],
    },
  },
};

const locationGroup = {
  add: {
    allow: [permissions.ADD_LOCATIONS],
  },
  delete: {
    allow: [permissions.DELETE_LOCATIONS],
  },
  move: {
    allow: [permissions.ADD_LOCATIONS],
  },
  rename: {
    allow: [permissions.ADD_LOCATIONS],
  },
};

const worker = {
  add: {
    allow: [permissions.ADD_WORKERS, permissions.EDIT_WORKERS],
  },
  bulk: {
    delete: {
      allow: [permissions.BULK_ACTION, permissions.DELETE_WORKERS],
    },
  },
  delete: {
    allow: [permissions.DELETE_WORKERS, permissions.EDIT_WORKERS],
  },
  edit: {
    allow: [permissions.EDIT_WORKERS],
  },
  import: {
    allow: [permissions.ADD_WORKERS, permissions.EDIT_WORKERS],
  },
  labels: {
    add: {
      allow: [permissions.EDIT_WORKERS],
    },
    delete: {
      allow: [permissions.EDIT_WORKERS],
    },
    edit: {
      allow: [permissions.EDIT_WORKERS],
    },
  },
  privateInfo: {
    add: {
      allow: [permissions.EDIT_WORKERS],
    },
    delete: {
      allow: [permissions.EDIT_WORKERS],
    },
    edit: {
      allow: [permissions.EDIT_WORKERS],
    },
  },
};

const user = {
  add: {
    allow: [permissions.ADD_WORKERS, permissions.EDIT_WORKERS],
  },
  delete: {
    allow: [permissions.DELETE_WORKERS, permissions.EDIT_WORKERS],
  },
};

const companyInformation = {
  edit: {
    allow: [permissions.MANAGE_COMPANY],
  },
  view: {
    allow: [permissions.MANAGE_COMPANY],
  },
  transferSettings: {
    edit: {
      allow: [permissions.MANAGE_COMPANY],
    },
  },
};

const hiltiTools = {
  view: {
    allow: [permissions.MANAGE_COMPANY],
  },
};

const label = {
  add: {
    allow: [permissions.MANAGE_LABELS],
  },
  bulk: {
    delete: {
      allow: [permissions.BULK_ACTION, permissions.MANAGE_LABELS],
    },
  },
  delete: {
    allow: [permissions.MANAGE_LABELS],
  },
  edit: {
    allow: [permissions.MANAGE_LABELS],
  },
  view: {
    allow: [permissions.MANAGE_LABELS],
  },
};

const role = {
  add: {
    allow: [permissions.ROLE_MANAGEMENT],
  },
  delete: {
    allow: [permissions.ROLE_MANAGEMENT],
  },
  edit: {
    allow: [permissions.ROLE_MANAGEMENT],
  },
  view: {
    allow: [permissions.GENERAL_ACCESS_WORKER, permissions.ROLE_MANAGEMENT],
  },
};

const report = {
  export: {
    allow: [permissions.GENERATE_REPORTS],
  },
  view: {
    allow: [permissions.GENERATE_REPORT_BASIC],
  },
};

const alertSettings = {
  advanceNotificationSetting: {
    allow: [permissions.ADVANCE_NOTIFICATION_SETTING, permissions.CREATE_SERVICES_TEMPLATE, permissions.EDIT_WORKERS],
  },
  quickAction: {
    allow: [
      permissions.QUICK_ACTIONS,
      permissions.ADVANCED_TRANSFER,
      permissions.MANAGE_SERVICES,
      permissions.EDIT_WORKERS,
    ],
  },
  qtyItemQuickAction: {
    allow: [permissions.LIMITED_EDIT_QTY_ITEMS],
  },
  snoozeDismiss: {
    allow: [permissions.SNOOZE_DISMISS],
  },
  view: {
    allow: [permissions.MANAGE_COMPANY],
  },
};
const gatewayReportSettings = {
  view: {
    allow: [permissions.MANAGE_COMPANY],
  },
};
const service = {
  delete: {
    allow: [permissions.REMOVE_SERVICES],
  },
  manage: {
    allow: [permissions.MANAGE_SERVICES],
  },
  manageTemplate: {
    allow: [permissions.CREATE_SERVICES_TEMPLATE],
  },
  view: {
    allow: [permissions.GENERAL_ACCESS_SERVICES],
  },
};

const assetCost = {
  settings: {
    edit: {
      allow: [permissions.EDIT_ASSET_COST_SETTINGS],
    },
    view: {
      allow: [permissions.GENERAL_ACCESS_ASSET_COST_SETTINGS],
    },
  },
  view: {
    allow: [permissions.GENERAL_ACCESS_ASSET_COST_TAB],
  },
};

const smartMap = {
  view: {
    allow: [permissions.GENERAL_ACCESS_SMART_MAP_TAB],
  },
};

const qtyItem = {
  add: {
    allow: [permissions.ADD_QTY_ITEMS],
  },
  bulk: {
    multiEdit: {
      allow: [permissions.EDIT_QTY_ITEMS, permissions.BULK_ACTION],
    },
    stockChange: {
      allow: [permissions.BULK_ACTION, permissions.LIMITED_EDIT_QTY_ITEMS],
    },
  },
  delete: {
    allow: [permissions.DELETE_QTY_ITEMS],
  },
  edit: {
    allow: [permissions.EDIT_QTY_ITEMS, permissions.LIMITED_EDIT_QTY_ITEMS],
  },
  labels: {
    add: {
      allow: [permissions.LIMITED_EDIT_QTY_ITEMS, permissions.EDIT_QTY_ITEMS],
    },
    bulk: {
      add: {
        allow: [permissions.BULK_ACTION, permissions.EDIT_QTY_ITEMS],
      },
      delete: {
        allow: [permissions.BULK_ACTION, permissions.EDIT_QTY_ITEMS],
      },
      view: {
        allow: [permissions.BULK_ACTION, permissions.EDIT_QTY_ITEMS],
      },
    },
    delete: {
      allow: [permissions.LIMITED_EDIT_QTY_ITEMS, permissions.EDIT_QTY_ITEMS],
    },
  },
  ownershipType: {
    add: {
      allow: [permissions.LIMITED_EDIT_QTY_ITEMS],
    },
    view: {
      allow: [permissions.LIMITED_EDIT_QTY_ITEMS],
    },
  },
  status: {
    add: {
      allow: [permissions.LIMITED_EDIT_QTY_ITEMS],
    },
    delete: {
      allow: [permissions.LIMITED_EDIT_QTY_ITEMS],
    },
    edit: {
      allow: [permissions.LIMITED_EDIT_QTY_ITEMS],
    },
  },
  stockChange: {
    allow: [permissions.LIMITED_EDIT_QTY_ITEMS],
  },
  transfer: {
    add: {
      allow: [permissions.PERFORM_TRANSFER],
    },
    recallAndConfirm: {
      allow: [permissions.TRANSFER_ADMIN],
    },
    perform: {
      allow: [permissions.PERFORM_TRANSFER],
    },
  },
  unit: {
    add: {
      allow: [permissions.ADD_QTY_UNIT],
    },
    delete: {
      allow: [permissions.DELETE_QTY_UNIT],
    },
    edit: {
      allow: [permissions.EDIT_QTY_UNIT],
    },
  },
  view: {
    allow: [permissions.GENERAL_ACCESS_QTY_ITEMS],
  },
  purchase: {
    add: { allow: [permissions.PRICING_QTY_ITEMS] },
    edit: { allow: [permissions.PRICING_QTY_ITEMS] },
    delete: { allow: [permissions.PRICING_QTY_ITEMS] },
    view: { allow: [permissions.PRICING_QTY_ITEMS] },
  },
  import: {
    allow: [permissions.ADD_QTY_ITEMS],
  },
};

const dashboard = {
  view: {
    allow: [permissions.IDLE_TIME_INFORMATION, permissions.BATTERY_STATE_HEALTH_INFORMATION],
    condition: PermissionConditions.ANY,
  },
};

export default {
  alertSettings,
  asset,
  assetCost,
  assetGroup,
  assetTemplate,
  companyInformation,
  dashboard,
  gatewayReportSettings,
  label,
  location,
  locationGroup,
  manufacturer,
  qtyItem,
  report,
  role,
  service,
  user,
  worker,
  hiltiTools,
  smartMap,
};
