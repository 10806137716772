import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import Dialog from "@hilti/components/core/dialog";

export const Separator = styled.div`
  margin: 0 -24px 16px -24px;
  border-bottom: 2px solid ${(props) => props.theme.colors.base.borderColor};
`;

export const StyledLinkText = styled.span`
  text-decoration: underline;
  margin: 0 6px;
  display: inline-block;
`;

export const LinkWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;
export const StyledHeader = styled.div`
  padding-bottom: 24px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

export const StyledIcon = styled.div`
  position: absolute;
  top: 27px;
  padding: 0 12px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 100%;
  justify-content: space-between;
`;

export const Form = styled(FormControl)`
  position: relative;
  top: -11px;
  left: 20px;
  height: 74px;
  padding-left: 2px;
  padding-top: 4px;
  margin-left: 2px;
  min-width: 140px;
  border-left: 1px solid ${(props) => props.theme.colors.base.borderColor};
  [data-testid="select"] {
    padding-left: 12px;
  }
`;

export const StyledDialog = styled(Dialog)`
  [data-testid="close"] {
    display: none;
  }
  .MuiDialogActions-root {
    padding: 16px 24px;
  }
`;

export const StyledFooterText = styled.div`
  width: 700px;
`;

export const StyledCheckboxLabel = styled.span`
  position: relative;
  left: 4px;
  top: 1px;
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
`;

export const StyledAsterix = styled.span`
  margin: 0 0 4px 2px;
  color: ${(props) => props.theme.colors.base.hiltiRed};
`;

export const ValidationText = styled.div`
  color: ${(props) => props.theme.colors.base.hiltiRed};
  font-size: ${(props) => props.theme.fontSize.small};
  margin-left: 26px;
  position: fixed;
`;
