// @ts-ignore
import { UserLicenseActions } from "custom-actions";
import { all, put, takeLatest } from "redux-saga/effects";
import { fetchUserLicense } from "../../../api/userLicenseAPI";
import { call } from "../../../sagas/common/commonSaga";

export function* getUserLicenseSaga() {
  yield takeLatest(UserLicenseActions.ORG_GET_USER_LICENSE, getUserLicense);
}

export function* getUserLicense(action) {
  const userLicenseDetailData = yield call(() => {
    return fetchUserLicense(action.includeCount);
  });

  if (!userLicenseDetailData.error) {
    yield put({ type: UserLicenseActions.UI_UPDATE_USER_LICENSE_RESPONSE, payload: userLicenseDetailData });
  }
}

function* userLicenseSaga() {
  yield all([getUserLicenseSaga()]);
}

export default userLicenseSaga;
