import React from "react";
import styled from "styled-components";
import DialogPosition from "../../dialogPosition";
import { IconName } from "../../icons/iconEnum";
import { SquareIcon } from "../../icons/iconWrapper";
import MenuItem from "../../menuList/menuItems";
import OutsideClickAlerter from "../../outSideClickAlerter/outSideClickComponet";
import { dependencyResolver } from "../gridUtils";
import { IQuickActionProps, IQuickActionState } from "./iQuickActionProps";
import { HelperLodash } from "../../../helpers";

const QuickLink = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .quick-icon {
    &:hover {
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.12);
    }
    &.active {
      background-color: rgba(${(props) => props.theme.colors.base.heavyConcreteAlpha}, 0.18);
    }
  }
`;

const ActionWrapper = styled.div`
  text-align: center;
`;

const FixedTopValue = 48;
const FixedLeftValue = 212;
const FixedLeftValueShrinked = 239;
const TopAdjust = 26;

class QuickActionsCell extends React.PureComponent<IQuickActionProps, IQuickActionState> {
  state = {
    leftPosition: 0,
    toggleActions: false,
    topPosition: 0,
    leftToParent: 0,
    topToParent: 0,
    dropDownActionItems: [],
  };
  ref = null;
  scrollRef = null;
  menuListHeight = 0;

  handleScroll = () => {
    this.setState({ toggleActions: false });
  };

  componentDidMount() {
    const { dependencies } = this.props;
    this.getDataList();
    this.scrollRef = dependencyResolver(dependencies, "getScrollRef")();
  }

  getActionType = (props: IQuickActionProps) => {
    const { column, data } = props;
    const propertyName = column && column.key;
    return HelperLodash.get(data, propertyName);
  };

  shouldQuickActionHavePermission = (actionType = null) => {
    const { shouldQuickActionHavePermission } = this.props.dependencies;
    actionType = actionType || this.getActionType(this.props);
    return shouldQuickActionHavePermission && shouldQuickActionHavePermission(actionType);
  };

  getDataList = (newProps = null) => {
    const props = newProps || this.props;
    const { data, dependencies } = props;
    const actionType = this.getActionType(props);
    this.setState({ dropDownActionItems: dependencyResolver(dependencies, "getQuickActions")(actionType, data) || [] });
    this.menuListHeight = (this.state?.dropDownActionItems?.length ?? 0) * FixedTopValue;
  };

  componentWillReceiveProps(newProps) {
    this.getDataList(newProps);
  }

  componentDidUpdate() {
    const { getQuickActionPopupPermission, data, dependencies } = this.props;

    if (this.scrollRef && this.state.toggleActions) {
      if (this.scrollRef.current && this.scrollRef.current.addEventListener) {
        this.scrollRef.current.addEventListener("scroll", this.handleScroll);
      } else {
        this.scrollRef.addEventListener("scroll", this.handleScroll);
      }
    }

    if (this.state.toggleActions) {
      const shouldQuickActionHavePermission = this.shouldQuickActionHavePermission();
      if (shouldQuickActionHavePermission && !dependencies.isQuickActionPermissionSaved(data.id)) {
        const { materialNumber, serialNumber } = data.details;
        getQuickActionPopupPermission(materialNumber, serialNumber, data.id);
      }
    }
  }

  componentWillUnmount() {
    if (this.handleScroll && this.scrollRef) {
      if (this.scrollRef.current?.removeEventListener) {
        this.scrollRef.current.removeEventListener("scroll", this.handleScroll);
      } else if (this.scrollRef.removeEventListener) {
        this.scrollRef.removeEventListener("scroll", this.handleScroll);
      }
    }
  }

  getSelectedAlertId = () => {
    const { dependencies } = this.props;
    const getSelectedAlertId = dependencyResolver(dependencies, "getSelectedAlertId");
    if (getSelectedAlertId) {
      return getSelectedAlertId();
    }
    return;
  };

  getDialogTop = () => {
    const { topToParent, topPosition } = this.state;
    const { data } = this.props;
    const selectedAlertId = this.getSelectedAlertId();
    if (document.documentElement.clientHeight - topPosition < this.menuListHeight + FixedTopValue) {
      if (selectedAlertId && selectedAlertId === data.id) {
        return topToParent - (TopAdjust + this.menuListHeight);
      }
      return topPosition - (TopAdjust + this.menuListHeight);
    }
    if (selectedAlertId && selectedAlertId === data.id) {
      return topToParent + FixedTopValue;
    }
    return topPosition + FixedTopValue;
  };

  getDialogLeft = () => {
    const { dependencies } = this.props;
    const { leftToParent, leftPosition } = this.state;
    const isShrinkedview = dependencyResolver(dependencies, "isShrinkedView")();
    const selectedAlertId = this.getSelectedAlertId();
    if (isShrinkedview) {
      if (selectedAlertId) {
        return leftToParent - FixedLeftValueShrinked;
      }
      return leftPosition - FixedLeftValueShrinked;
    }
    if (selectedAlertId) {
      return leftToParent - FixedLeftValue;
    }
    return leftPosition - FixedLeftValue;
  };

  handleActionClick = (obj) => {
    const { data, dependencies } = this.props;
    return dependencyResolver(dependencies, "handleQuickActionClick")(obj, data);
  };

  getAllowedQuickAction = (rowIndex) => {
    const actionType = this.getActionType(this.props);
    const { dependencies, data } = this.props;
    this.setState({ dropDownActionItems: dependencyResolver(dependencies, "getQuickActions")(actionType, data) || [] });
    return this.renderActions(rowIndex);
  };

  prepareToRenderAction = (rowIndex) => {
    return this.renderActions(rowIndex);
  };

  renderActions = (rowIndex) => {
    const { dependencies } = this.props;
    return (
      <OutsideClickAlerter
        onClickAway={this.handleOnClickAway}
        ignoreClickedElement={[`actionDropDownWrapper${rowIndex}`]}
        render={() => (
          <DialogPosition top={this.getDialogTop()} left={this.getDialogLeft()}>
            <ActionWrapper>
              <MenuItem
                onSelectChange={(obj) => this.handleActionClick(obj)}
                dataList={this.state.dropDownActionItems.map((item) => {
                  return {
                    ...item,
                    image: item.image,
                    label: dependencies.t(item.value),
                    value: item.code,
                  };
                })}
              />
            </ActionWrapper>
          </DialogPosition>
        )}
      />
    );
  };

  handleOnClickAway = () => {
    this.setState({ toggleActions: false });
  };

  handleClick = (ele, event) => {
    event.stopPropagation();
    const viewPortOffset = ele.getBoundingClientRect();
    const leftToParent = ele.offsetLeft;
    const topToParent = ele.offsetTop;
    this.setState((prevState) => {
      return {
        leftPosition: viewPortOffset.left,
        topPosition: viewPortOffset.top,
        toggleActions: !prevState.toggleActions,
        leftToParent,
        topToParent,
      };
    });
  };

  renderQuickActionDots = () => {
    return dependencyResolver(this.props.dependencies, this.props.column.dependencies)(this.props.data);
  };

  render() {
    return this.renderQuickActionDots() ? (
      <QuickLink
        id={`actionDropDownWrapper${this.props.rowIndex}`}
        data-testid={`quick-actions-toggle-${this.props.rowIndex}`}
        onClick={(e) => this.handleClick(this.ref, e)}
        ref={(element) => (this.ref = element)}
      >
        <SquareIcon
          name={IconName.EllipsisVertical}
          id={`quickActionId${this.props.rowIndex}`}
          className={this.state.toggleActions ? "quick-icon active" : "quick-icon"}
        />
        {this.state.toggleActions && this.prepareToRenderAction(this.props.rowIndex)}
      </QuickLink>
    ) : null;
  }
}

export default QuickActionsCell;
