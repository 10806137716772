import MenuItem from "@mui/material/MenuItem";
import React, { memo, useMemo, useState } from "react";
import styled from "styled-components";
import { Checkbox } from "../../elements";
import LoaderElement from "../../elements/loader/loaderElement";
import SearchBox from "../../elements/SearchBox/searchBox";
import { cloneDeep, includes } from "../../helpers/helperLodash";
import Prompt from "../prompt/prompt";
import { getLabel, HiddenAssetInfo } from "./controls/checkboxGroup/checkboxGroup";
import ControlType from "./controlType";
import { getIntersectionOptions, separateCheckedAndUncheckedOptions } from "./filterUtils";

const List = styled(MenuItem)`
  && {
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    width: calc(33.3% - 32px);
    height: auto;
  }
`;

const OptionsWrapper = styled.div`
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Loader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.colors.base.white};
`;

export const SearchBoxWrapper = styled.div`
  width: 100%;
  padding: 16px 0 16px 16px;
  background-color: ${(props) => props.theme.colors.base.hiltiOffWhite};
`;

export const TitleText = styled.div`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 24px;
  padding-left: 16px;
  padding-top: 20px;
  width: 33.3%;
  cursor: default;
`;

export const TotalQuantityText = styled.div`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: ${(props) => props.theme.fontNormal.fontWeight};
  color: ${(props) => props.theme.colors.base.placeholderColor};
  line-height: 24px;
  padding-left: 16px;
  padding-top: 20px;
  width: 33.3%;
`;

export const NoValuesLabelWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.placeholderColor};
  padding-left: 16px;
`;

interface IOption {
  label: string;
  value: string;
}

interface IFilterGroupModalContentProps {
  readonly t: any;
  readonly filter: any;
  readonly selectedOptions: IOption[];
  readonly options: IOption[];
  readonly appliedOptions: IOption[];
  readonly handleSearch: any;
  readonly changeFilterFormValues: (field: string, value: string) => void;
  readonly filterFormValues: any;
  readonly updateSelectedOptions: (options: IOption[], filter: any) => void;
  readonly displayLoader: boolean;
  readonly initialSearchValue: string;
  readonly noValuesLabel: string;
  readonly totalCount?: number;
}

interface IFilterGroupModalProps {
  readonly t: any;
  readonly allOptions: any;
  readonly allSelectedOptions: any;
  readonly appliedOptions: IOption[];
  readonly shouldDisplay: boolean;
  readonly handleModalClose: (clearNotApplied: boolean) => void;
  readonly applyFilters: (filters: any) => void;
  readonly clearSelectedOptions: () => void;
  readonly filter: any;
  readonly filterAttributesSearchCount?: any;
  readonly handleSearch: (filter: any, quer: string) => void;
  readonly handleClear?: any;
  readonly getOptionsBasedOnFilter: (options: any[], mode: string, nullTextDisplay: string, filter: any) => IOption[];
  readonly mode: string;
  readonly displayLoader: boolean;
  readonly filterFormValues: any;
  readonly changeFilterFormValues: (field: string, value: string) => void;
  readonly updateSelectedOptions: (options: IOption[], filter: any) => void;
  readonly initialSearchValue: string;
  readonly noValuesLabel: string;
}

export const constructSelectedOptionsAndFieldValues = (
  item: IOption,
  isSelected: boolean,
  selectedOptions: IOption[],
  allOptions: IOption[],
  searchString: string,
) => {
  const filteredSelectedOptions = searchString ? selectedOptions : getIntersectionOptions(selectedOptions, allOptions);
  const newSelectedOptions = cloneDeep(filteredSelectedOptions);
  if (isSelected) {
    newSelectedOptions.push(item);
  } else {
    const removedElemIndex = newSelectedOptions.findIndex((option) => option.value === item.value);
    newSelectedOptions.splice(removedElemIndex, 1);
  }
  const newFieldValues = newSelectedOptions.map((option) => option.value).join(";");
  return {
    fieldValues: newFieldValues,
    newSelectedOptions,
  };
};

export const checkboxOnclick = (
  filter: any,
  item: IOption,
  filterFormValues,
  changeFilterFormValues,
  updateSelectedOptions,
  selectedOptions: IOption[],
  allOptions: IOption[],
  searchString: string,
  isSelected: boolean,
) => {
  if (filter && filterFormValues) {
    const { filterName, operator } = filter;
    const { value } = item;
    const formField = `${filterName}:${operator}`;
    let fieldValues = filterFormValues[formField];
    let newSelectedOptions;
    if (!fieldValues) {
      fieldValues = value;
      newSelectedOptions = [{ ...item }];
    } else {
      ({ fieldValues, newSelectedOptions } = constructSelectedOptionsAndFieldValues(
        item,
        isSelected,
        selectedOptions,
        allOptions,
        searchString,
      ));
    }
    changeFilterFormValues(formField, fieldValues);
    updateSelectedOptions(newSelectedOptions, filter);
  }
};

export const renderOptions = (props: IFilterGroupModalContentProps, optionsList: IOption[], searchString: string) => {
  const { filter, options, selectedOptions, filterFormValues, changeFilterFormValues, updateSelectedOptions, t } =
    props;
  const filterName = filter && filter.filterName;

  return optionsList.map((item, index) => {
    const opt = (selectedOptions && selectedOptions.filter((o) => o.value === item.value)) || [];
    const label = (
      <>
        {getLabel(item, "label")}
        {includes([ControlType.retiredFilterCode, ControlType.disposedFilterCode], item.value) && (
          <>
            {" "}
            <HiddenAssetInfo t={t} />
          </>
        )}
      </>
    );
    const identifier = `${filterName}_opt_${index}`;
    const checked = opt.length > 0;
    return (
      <List key={`${filterName}_wrapper_${index}`}>
        <Checkbox
          key={identifier}
          isFormControl={false}
          id={identifier}
          name={identifier}
          label={label}
          checked={checked}
          onChange={checkboxOnclick.bind(
            null,
            filter,
            item,
            filterFormValues,
            changeFilterFormValues,
            updateSelectedOptions,
            selectedOptions,
            options,
            searchString,
          )}
          wrapperStyles={{ width: "100%" }}
        />
      </List>
    );
  });
};

export const FilterGroupModalContent: React.FC<IFilterGroupModalContentProps> = memo((props) => {
  const {
    t,
    filter,
    handleSearch,
    options,
    appliedOptions,
    displayLoader,
    initialSearchValue,
    noValuesLabel,
    totalCount,
  } = props;

  const [searchString, setSearchString] = useState(initialSearchValue);

  const optionsList: { appliedOptions: IOption[]; options: IOption[] } = separateCheckedAndUncheckedOptions(
    options,
    appliedOptions,
  );

  const handleSearchOnChange = (searchValue: string) => {
    setSearchString(searchValue);
    handleSearch(filter, searchValue);
  };

  const { options: calculatedOptions, appliedOptions: calculatedAppliedOptions } = optionsList;

  return (
    <>
      <SearchBoxWrapper>
        <SearchBox
          id="searchBoxFilterGroupModal"
          placeholder={t("filters:SEARCH")}
          enableClear={true}
          value={searchString}
          onChange={handleSearchOnChange}
          style={{ width: "45%" }}
        />
      </SearchBoxWrapper>

      {displayLoader ? (
        <Loader>
          <LoaderElement />
        </Loader>
      ) : (
        <OptionsWrapper>
          {calculatedAppliedOptions.length === 0 && calculatedOptions.length === 0 && (
            <NoValuesLabelWrapper>{noValuesLabel}</NoValuesLabelWrapper>
          )}
          {calculatedAppliedOptions.length > 0 && (
            <>
              <TitleText>{t("filters:APPLIED_FILTERS")}</TitleText>
              {renderOptions(props, calculatedAppliedOptions, searchString)}
            </>
          )}
          {calculatedOptions.length > 0 && (
            <>
              <TitleText>{t("filters:AVAILABLE_FILTERS")}</TitleText>
              {renderOptions(props, calculatedOptions, searchString)}
              {totalCount > options.length ? (
                <TotalQuantityText>
                  {t("filters:SHOWING_WITH_COUNT", { count: options.length, totalCount })}
                </TotalQuantityText>
              ) : null}
            </>
          )}
        </OptionsWrapper>
      )}
    </>
  );
});

export const handleModalConfirm = (filters, applyFilters, handleModalClose) => {
  handleModalClose();
  applyFilters(filters);
};

const FilterGroupModal: React.FC<IFilterGroupModalProps> = (props) => {
  const {
    t,
    shouldDisplay,
    filter,
    applyFilters,
    handleModalClose,
    handleSearch,
    allOptions,
    allSelectedOptions,
    getOptionsBasedOnFilter,
    mode,
    displayLoader,
    filterFormValues,
    changeFilterFormValues,
    updateSelectedOptions,
    appliedOptions,
    initialSearchValue,
    clearSelectedOptions,
    noValuesLabel,
    filterAttributesSearchCount,
  } = props;
  if (!shouldDisplay || !filter || !filter.filterName) {
    return null;
  }

  const { filterName } = filter;
  const selectedOptions = allSelectedOptions[filterName];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const options = useMemo(() => {
    const filterOptions = allOptions && allOptions[filter.filterName];
    if (!filterOptions) {
      return [];
    }
    return getOptionsBasedOnFilter(filterOptions, filter.type[mode], filter.nullTextDisplay, filter);
  }, [allOptions]);

  const clearLabel = selectedOptions && selectedOptions.length ? `${t("filters:CLEAR")} ${selectedOptions.length}` : "";
  // get the total count of filter items
  const totalCount =
    filterAttributesSearchCount && filterAttributesSearchCount.length && filterAttributesSearchCount[0]
      ? filterAttributesSearchCount[0][filterName]
      : options.length;

  return (
    <Prompt
      okLabel={t("filters:APPLY_FILTERS")}
      cancelLabel={t("common:CANCEL")}
      isHeader={true}
      header={t(filter.filterDisplayName)}
      showCancel={true}
      handleConfirm={handleModalConfirm.bind(null, filterFormValues, applyFilters, handleModalClose)}
      handleClose={handleModalClose.bind(null, true)}
      crossIcon={true}
      wrapperStyleAttributes={{ width: "960px", height: "100%" }}
      outerStyle={{ zIndex: 99999999, alignItems: "normal" }}
      className="no-paddings"
      dialogScrollClassName="filter-high-dialog"
      leftFooterComponentAction={clearSelectedOptions}
      leftFooterComponentLabel={clearLabel}
    >
      <FilterGroupModalContent
        t={t}
        filter={filter}
        options={options}
        appliedOptions={appliedOptions}
        handleSearch={handleSearch}
        selectedOptions={selectedOptions}
        changeFilterFormValues={changeFilterFormValues}
        filterFormValues={filterFormValues}
        updateSelectedOptions={updateSelectedOptions}
        displayLoader={displayLoader}
        initialSearchValue={initialSearchValue}
        noValuesLabel={noValuesLabel}
        totalCount={totalCount}
      />
    </Prompt>
  );
};

export default FilterGroupModal;
