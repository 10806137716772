/** defined type of controls in system */
enum ControlType {
  Checkbox = "checkbox",
  CheckboxGroup = "CheckboxGroup",
  MultiSelectDropdown = "MultiSelectDropdown",
  ImageFieldCombination = "imageFieldCombination",
  Badge = "badge",
  FieldCombination = "fieldCombination",
  DropDown = "dropDown",
  InputText = "inputText",
  TextArea = "textArea",
  Custom = "custom",
  DateTime = "dateTime",
  HierarchicalDropdown = "hierarchicalDropdown",
  RadioGroup = "RadioGroup",
  RadioSelectDropdown = "RadioSelectDropdown",
  retiredFilterCode = "RETR",
  disposedFilterCode = "DISPOSED",
  DataRange = "dataRange",
  DataRangeDropdown = "dataRangeDropdown",
  PopoutInfo = "PopoutInfo",
  PopoutInfoIcon = "PopoutInfoIcon",
  DateRange = "dateRange",
  DateRangeDropdown = "dateRangeDropdown",
  Archived = "ARCHIVED",
  CookieSettings = "cookieSettings",
  ReadOnlyFilter = "readOnlyFilter",
  ReadOnlyMoreFilter = "ReadOnlyMoreFilter",
  CreatableAutoComplete = "creatableAutoComplete",
  IdleRange = "idleRange",
}

export default ControlType;
