import ApplicationConstants from "../../constant/applicationConstants";
import { addClassOnBody, removeClassOnBody } from "../../helpers/domHelper";
import HelperLodash, { cloneDeep } from "../../helpers/helperLodash";
import FilterOperator from "./filterOperator";
import { IGridFilter } from "./iGridFilter";
/**
 * @description - Function to format filters array in required format to apply.
 * @param data  - Filters array.
 */
export const formatFiltersToApply = (data) => {
  return data.map((item) => {
    const filter = {};
    filter[item.filterName] = item.filterValue;
    return filter;
  });
};

export const getSelectedFilterValue = (selectedFilter, filterName) => {
  if (Array.isArray(selectedFilter)) {
    return selectedFilter.map((item) => {
      return HelperLodash.get(item, filterName);
    });
  }
  return null;
};
export const Filters = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  DUE_TO_EXPIRE: "DUE_TO_EXPIRE",
  EXPIRED: "EXPIRED",
};

export const getDefaultFilters = (filterSettings: IGridFilter[]) => {
  return filterSettings.filter((setting) => setting.isDefault);
};

export const isFilterSupportShortName = (filterSettings: IGridFilter[], filterName) => {
  const filter = filterSettings.filter((setting) => setting.supportShortName && setting.filterName === filterName);
  return filter?.length > 0;
};

export const getUnclearableFilters = (filterSettings: IGridFilter[]) => {
  return filterSettings.filter((setting) => setting.isUnclearable);
};

/**
 *  get name of filter for current filter object
 * @param filterType : name of filter
 * @param operator: operator to apply for filter
 */
export const getFilterName = (filterType: string, operator: FilterOperator): string => {
  return `${filterType}:${operator}`;
};

export const getPaginatedData = (index: any, entityGroupsList, getEntityGroups, getChildrenForSelectedItem) => {
  const entityGroup = HelperLodash.get(entityGroupsList, index);
  const offset = entityGroup && entityGroup.offset;
  const limit = entityGroup && entityGroup.limit;
  const totalRecords = entityGroup && entityGroup.totalRecords;
  const nextOffset = (offset || 0) + (limit || ApplicationConstants.LIMIT.LIM100);
  if (nextOffset < totalRecords && index === 0) {
    return getEntityGroups(null, null, null, nextOffset);
  } else if (nextOffset < totalRecords && index !== 0) {
    const parentLocationId = HelperLodash.get(entityGroupsList, [index, "listRes", 0, "parentLocationId"]);
    return getChildrenForSelectedItem(parentLocationId, null, nextOffset, index);
  }
  return null;
};

/**
 * use to format filter query
 * @param object contains key operator and value
 */
export function formatFiltersObject(object: any, filterSettings: any) {
  const filterArray = [];
  Object.entries(object).forEach(([key]) => {
    const filterValue = HelperLodash.get(object, key);
    const splittedKey = key.split(":");
    if (filterValue && filterValue.split(";")[0] !== "") {
      const splitedValues = filterValue.split(";");
      const filterKey = splittedKey[0];
      let filterObject = {};
      if (isFilterSupportShortName(filterSettings, filterKey)) {
        filterObject = {
          filterName: filterKey,
          condition: splittedKey[1],
          filterValue: splitedValues,
          supportShortName: true,
        };
      } else {
        filterObject = { filterName: filterKey, condition: splittedKey[1], filterValue: splitedValues };
      }
      filterArray.push(filterObject);
    }
  });
  return filterArray;
}
/* @description - Function to remove browserScroll when more filter is closed
 *
 */

export const removeBrowserScroll = () => {
  window.scrollTo(0, 0);
};
/* @description - Function to fix element
 *
 */
export const fixedElement = (elem, className) => {
  const targetElem = document.querySelector(elem);
  const sticky = targetElem && targetElem.offsetTop;
  if (window.pageYOffset >= sticky && targetElem) {
    addClassOnBody(className);
  } else if (targetElem) {
    removeClassOnBody(className);
  }
};

const extractValuesFromOptions = (options) => {
  if (!options || !options.map) {
    return [];
  }
  return options.map((option) => option && option.value);
};

export const getIntersectionOptions = (optionsToBeFiltered, options) => {
  if (!Array.isArray(optionsToBeFiltered) || !Array.isArray(options)) {
    return optionsToBeFiltered;
  }
  const filteredOptions = [];
  optionsToBeFiltered.forEach((optionToBeFiltered) => {
    const { value } = optionToBeFiltered;
    const valueExistsInOptionsList = options.findIndex((option) => option && option.value === value);
    if (valueExistsInOptionsList !== -1) {
      filteredOptions.push({ ...optionToBeFiltered });
    }
  });
  return filteredOptions;
};

export const isApplyButtonActive = (appliedOptions, selectedOptions, options) =>
  (appliedOptions &&
    appliedOptions.length >= 0 &&
    selectedOptions &&
    selectedOptions.length >= 0 &&
    !HelperLodash.isEqual(
      extractValuesFromOptions(getIntersectionOptions(appliedOptions, options)).sort(),
      extractValuesFromOptions(getIntersectionOptions(selectedOptions, options)).sort(),
    )) ||
  (appliedOptions && appliedOptions.length === 0 && selectedOptions && selectedOptions.length) ||
  (appliedOptions && appliedOptions.length && !selectedOptions);

export const separateCheckedAndUncheckedOptions = (options = [], appliedOptions = []) => {
  const allOptions = cloneDeep(options);
  const appliedOptionsFromAllOptions = [];
  appliedOptions.forEach((appliedOption) => {
    const appliedOptionIndex = allOptions.findIndex((option) => option.value === appliedOption.value);
    if (appliedOptionIndex !== -1) {
      appliedOptionsFromAllOptions.push(HelperLodash.get(allOptions, appliedOptionIndex));
      allOptions.splice(appliedOptionIndex, 1);
    }
  });
  return {
    appliedOptions: HelperLodash.sortBy(
      appliedOptionsFromAllOptions,
      (appliedOption) => appliedOption && appliedOption.value && ("" + appliedOption.value).toLowerCase(),
    ),
    options: allOptions,
  };
};
