import React from "react";
import { StyledAfterIconContainer, StyledNormalText, StyledRowsWrapper } from "../../index";
import INormalIconProps from "./iNormalIcon";

/**
 * @const NormalIcon is a Styled Component that consist of 2 things:
 * 1. Icon: An Icon that will be visible at the right side of the content (::After).
 * 2. Normal Text: This is the text with roman font-family.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 */
const NormalIcon = (props: INormalIconProps) => {
  const { id, normalId, normalText, iconUrl, isBold, iconClassName, isSmall } = props;
  return (
    <StyledAfterIconContainer id={id} iconUrl={iconUrl} className={iconClassName}>
      <StyledRowsWrapper>
        <StyledNormalText id={normalId} isBold={isBold} isSmall={isSmall}>
          {normalText}
        </StyledNormalText>
      </StyledRowsWrapper>
    </StyledAfterIconContainer>
  );
};

/**
 * @export NormalIcon - Styled Component.
 */
export default NormalIcon;
