import React from "react";
import styled from "styled-components";
import SearchBox from "../../elements/SearchBox/searchBox";
import { ISearchBoxProps } from "./iSearchBarProps";
import SearchLabel from "./searchLabel";

/**
 * Styled component that is used as a search bar wrapper.
 */
export const StyledSearchBar = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

/**
 * Styling for the search box component, It is used to provide margin of 8px from the right side component.
 */
const searchStyle = {
  marginRight: "16px",
  minWidth: "320px",
};

/**
 * SearchBar component is holding the search box and other filter components.
 */
const SearchBoxWrapper = (props: ISearchBoxProps) => {
  const { handleSearchChange, value, placeholder, searchLabel, disabled, enableClear, moduleName } = props;
  return (
    <>
      {searchLabel && <SearchLabel searchLabel={searchLabel} />}
      <StyledSearchBar>
        <SearchBox
          id="searchBox"
          placeholder={placeholder}
          style={searchStyle}
          onChange={handleSearchChange}
          enableClear={enableClear}
          value={value}
          disabled={disabled}
          moduleName={moduleName}
        />
      </StyledSearchBar>
    </>
  );
};

SearchBoxWrapper.defaultProps = {
  disabled: false,
  enableClear: true,
};

export default SearchBoxWrapper;
