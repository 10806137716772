import { IStatusModel } from "../../modules/qtyItems/allocation/models/iAllocation";
import IDropDownModel from "../../models/iDropDownModel";
import { AssetSubType, AssetType } from "../../utils/enums/assetEnum";

export interface ITransferCartModel {
  id: number;
  allocationName: string;
  allocationStatus: IStatusModel;
  allocationType: IStatusModel;
  assetImageId: string;
  templateImageId: string;
  responsibleEmployeeId?: number;
  responsibleEmployeeName?: string;
  stockQuantity: number;
  unitName: string;
  currentLocationId?: number;
  allocationTypeId: number;
  uniqueId: string;
  manufacturerName: string;
  model: string;
  name: string;
  currentLocationName?: string;
  metaData: any;
  quantityItemImageId: string;
  qtyAllocationStatus?: IDropDownModel;
  qtyAllocationValue?: string;
  toReturnDate: string;
  toWorker: string;
  path?: string;
  containsAnyAsset?: boolean;
  childCount?: number;
  mainAllocation?: IStatusModel;
  lastTransferDate: string;
  scanCode: string;
  inventoryNumber: number;
  diameter?: string;
}

export default class TransferCartModel {
  id: number;
  allocationName: string;
  allocationStatus: IStatusModel;
  allocationType: IStatusModel;
  imageId: string;
  templateImageId: string;
  responsibleEmployeeId?: number;
  responsibleEmployeeName?: string;
  stockQuantity: number;
  unitName: string;
  currentLocationId?: number;
  allocationTypeId: number;
  uniqueId: string;
  manufacturerName: string;
  model: string;
  name: string;
  currentLocationName?: string;
  metaData: any;
  qtyAllocationStatus?: object;
  qtyAllocationValue?: string;
  canConsume?: boolean;
  path?: string;
  containsAnyAsset?: boolean;
  childCount?: number;
  qtyItemCount?: number;
  mainAllocation?: IStatusModel;
  lastTransferDate?: string;
  scanCode?: string;
  inventoryNumber?: number;
  assetType: { code: AssetType; value: string };
  assetSubType: { code: AssetSubType; value: string };
  diameter?: string;

  constructor(response: any) {
    this.id = response.id || response.assetId || response.allocationId;
    this.allocationName = response.allocationName;
    this.allocationStatus = response.allocationStatus;
    this.allocationType = response.allocationType;
    this.imageId = response.assetImageId || response.quantityItemImageId;
    this.templateImageId = response.templateImageId;
    this.responsibleEmployeeId = response.responsibleEmployeeId;
    this.responsibleEmployeeName = response.responsibleEmployeeName;
    this.currentLocationId = response.currentLocationId;
    this.allocationTypeId = response.allocationTypeId;
    this.manufacturerName = response.manufacturerName;
    this.model = response.model;
    this.name = response.name;
    this.currentLocationName = response.currentLocationName;
    this.stockQuantity = response.stockQuantity;
    this.unitName = response.unitName;
    this.allocationStatus = response.allocationStatus;
    this.uniqueId =
      response.metaData && `${response.id || response.assetId || response.allocationId}_${response.metaData.type}`;
    this.metaData = response.metaData;
    this.qtyAllocationStatus = response.qtyAllocationStatus;
    this.qtyAllocationValue = response.qtyAllocationValue;
    this.canConsume = response.canConsume;
    this.path = response.path;
    this.containsAnyAsset = response.containsAnyAsset;
    this.childCount = response.childCount;
    this.qtyItemCount = response.qtyItemCount;
    this.mainAllocation = response.mainAllocation;
    this.lastTransferDate = response.lastTransferDate;
    this.scanCode = response.scanCode;
    this.inventoryNumber = response.inventoryNumber;
    this.assetType = response.assetType;
    this.assetSubType = response.assetSubType;
  }
}
