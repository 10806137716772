/**
 * @description Action constants for the label module
 *
 */
const LabelActionConstants = {
  AM_CLEAR_LABEL_DELETE_FLAG: "AM_CLEAR_LABEL_DELETE_FLAG",
  AM_CREATE_LABEL: "AM_CREATE_LABEL",
  AM_DELETE_LABEL: "AM_DELETE_LABEL",
  AM_DELETE_LABEL_FAIL: "AM_DELETE_LABEL_FAIL",
  AM_DELETE_LABEL_SUCCESS: "AM_DELETE_LABEL_SUCCESS",
  AM_LABEL_CLEAR_SELECTION: "AM_LABEL_CLEAR_SELECTION",
  AM_LABEL_GET_LIST: "AM_LABEL_GET_LIST",
  AM_LABEL_LOADER_END: "AM_LABEL_LOADER_END",
  AM_LABEL_LOADER_START: "AM_LABEL_LOADER_START",
  AM_LABEL_RESET_QUERY_PARAM: "AM_LABEL_RESET_QUERY_PARAM",
  AM_LABEL_SET_LIST_RESPONSE: "AM_LABEL_SET_LIST_RESPONSE",
  AM_LABEL_SET_SELECTED_ROWS: "AM_LABEL_SET_SELECTED_ROWS",
  AM_PUT_LABEL: "AM_PUT_LABEL",
  AM_RESET_LIST_FROM_NO_RECORD: "AM_RESET_LIST_FROM_NO_RECORD",
  AM_SET_LABEL_SEARCH_QUERY: "AM_SET_LABEL_SEARCH_QUERY",
  AM_SET_LABEL_SORT_QUERY: "AM_SET_LABEL_SORT_QUERY",
  CLEAR_ALL_LABELS_SELECTION: "CLEAR_ALL_LABELS_SELECTION",
  CLEAR_LABEL_ASSET_LIST: "CLEAR_LABEL_ASSET_LIST",
  GET_LABEL_ASSET_LIST: "GET_LABEL_ASSET_LIST",
  LABEL_ADD_EDIT_RESPONSE: "LABEL_ADD_EDIT_RESPONSE",
  PUT_LABEL_ASSET_LIST: "PUT_LABEL_ASSET_LIST",
  RESET_MULTI_DELETE_LABEL_RESPONSE: "RESET_MULTI_DELETE_LABEL_RESPONSE",
  RESET_SELECTED_ROWS: "RESET_SELECTED_ROWS",
  RESET_TO_INITIAL_STATE: "RESET_TO_INITIAL_STATE",
  SET_LABEL_MODULE: "SET_LABEL_MODULE",
};

export default LabelActionConstants;
