import React from "react";
import styled from "styled-components";
import Radio from "../../elements/Radio/radio";
import { HelperLodash } from "../../helpers";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
const Container = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  display: flex;
  padding: 24px;
  width: 100%;
`;

const ImageWrapper = styled.div`
  width: 64px;
  margin-right: 24px;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const Heading3 = styled.h3`
  color: ${(props) => props.theme.colors.base.steel};
  font-size: ${(props) => props.theme.fontSize.medium};
  margin-bottom: 16px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

const TestPara = styled.p`
  line-height: 17px;
  margin: 0 0 24px;
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.colors.base.steel};
`;

const RadioWrapper = styled.div`
  h4 {
    margin: 16px 0 0;
    font-size: ${(props) => props.theme.fontSize.medium};
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
`;

const getRadioLabel = (item) => (item.jobFunctions ? `(${item.jobFunctions.value})` : "");

export const RadioControl = (props) => {
  const { name, valueKey, valueId, onRadioClicked, options } = props;
  return (
    <>
      {options &&
        options.ccmsContactDetails.length > 0 &&
        options.ccmsContactDetails.map((item, index) => {
          const itemValueKey = HelperLodash.get(item, valueKey);
          const itemValueId = HelperLodash.get(item, valueId);

          return (
            <RadioWrapper key={`${itemValueKey}${index}`}>
              <Radio
                name={name}
                onSelectionChange={onRadioClicked}
                id={itemValueId}
                radioLabel={`${itemValueKey} ${item.lastName} ${getRadioLabel(item)}`}
                radioValue={itemValueId ? itemValueId.toString() : "-1"}
                isFormControl={true}
              />
            </RadioWrapper>
          );
        })}
    </>
  );
};
/**
 * Class for Contact Conflict page
 *
 * @class CheckContactConflict
 */
export class CheckContactConflict extends React.Component<any> {
  componentWillUnmount() {
    this.props.clearContactConflict();
  }
  textMessage = (length, email, t) => {
    if (length > 1) {
      return t("common:CONTACT_MULTIPLE_USER_MESSAGE", {
        count: length,
        email,
      });
    } else {
      return t("common:CONTACT_SINGLE_USER_MESSAGE", {
        email,
      });
    }
  };
  render() {
    const { id, header, options, t } = this.props;
    const length = options && options.ccmsContactDetails && options.ccmsContactDetails.length;
    const email = options && options.userInput && options.userInput.email;
    return (
      <Container>
        <ImageWrapper>
          <SquareIcon name={IconName.Warning} className="medium warning" imageSize={64} />
        </ImageWrapper>
        <ContentWrapper>
          <Heading3 key={`${id}_header`}>{t(header)}</Heading3>
          <TestPara>{this.textMessage(length, email, t)}</TestPara>
          <Heading3>{t("common:SELECT_CONTACT_LABEL")}</Heading3>
          <RadioControl {...this.props} length={length} />
        </ContentWrapper>
      </Container>
    );
  }
}
