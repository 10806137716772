export const getAssetFields = (state) => state && state.assetView?.customFields;
export const getAssetList = (state) => state && state.assetsList;
export const getSelectedCustomView = (state) => state && state.selectedCustomView && state.selectedCustomView;
export const getTotalRecords = (state) => state && state.assetsList && state.assetsList.totalRecords;
export const getCustomViewsList = (state) => state && state.customViewsList;
export const defaultGridFields = (state) => state?.defaultGridFields;
export const getGridFields = (state) => state && state.gridFields;
export const getParentCount = (state) => state && state.assetsList && state.assetsList.parentCount;
export const ifListLoading = (state) => state?.loader?.isListLoading;
export const getAssetOwnershipType = (state) => state?.ownerShipTypeResponse;
export const getFilterQuery = (state) => state?.appliedFilterQuery;
export const getAssetStatusList = (state) => state?.assetStatusResponse;
export const ifMoreFilterClosed = (state) => state?.isFilterPanelOpen;
export const getSelectedAssetDetail = (state) => state?.selectedAssetDetail;
export const getListLoader = (state) => state?.list;
export const getShowNoRecordFoundView = (state) => state?.showNoRecordFoundView;
export const getSortQuery = (state) => state?.sortQuery;
export const getSearchString = (state) => state?.searchString;
export const getLabelData = (state) => state && state.labelsList && state.labelsList.response;
export const assetAssociatedLabels = (state) => state.associateLabels && state.associateLabels.list;
export const isLabelUpdated = (state) => state && state.associateLabels && state.associateLabels.isUpdated;
export const getSelectAllStatus = (state) => state && state.isAllSelected;
export const getList = (state) => state.assetsList.list;
export const getSelectAllAssets = (state) => state.selectAllAssets;
export const getFilterAttributes = (state) => state && state.filterAttributes;
export const getAddRenameCustomViewResponse = (state) => state && state.addRenameCustomViewResponse;
export const getEntityGroupList = (state) => state && state.assetGroupsForFilter;
export const getFilterAttributesCount = (state) => state && state.filterAttributesCount;
export const getFilterAttributesSearchCount = (state) => state && state.filterAttributesSearchCount;
export const getAppliedFilterQuery = (state) => state && state.appliedFilterQuery;
export const getCustomViewUpdateStatus = (state) => state && state.isCustomViewUpdated;
export const getEnableStatus = (state) =>
  state && state.assetsList && state.assetsList.list && state.assetsList.list.length === 0;
export const getNoRecordStatus = (state) => state && state.showNoRecordFoundView;
export const getLocationGroupsList = (state) => state && state.locationGroups && state.locationGroups.filterList;
export const getSelectedLocationGroupId = (state) =>
  state && state.locationGroups && state.locationGroups.selectedLocationGroupId;
export const getSelectedLocationGroups = (state) => state && state.locationGroups && state.locationGroups.selectedList;
export const getAddEditResponse = (state) => state && state.addEditResponse;
export const getAssetAttachment = (state) =>
  state && state.addAssets && state.addAssets.values && state.addAssets.values.assetAttachments;
export const getAssetDetails = (state) => state && state.selectedAssetDetail;
export const getAssetGroupsList = (state) =>
  state && state.assetGroupsList && state.assetGroupsList.assetGroupResponses;
export const getAssetImageId = (state) =>
  state && state.addAssets && state.addAssets.values && state.addAssets.values.assetImageId;
export const getScanCodeType = (state) =>
  state && state.addAssets && state.addAssets.values && state.addAssets.values.ScanCodeType;
export const getTemplateImageId = (state) =>
  state && state.addAssets && state.addAssets.values && state.addAssets.values.templateImageId;
export const getAssetTypeValue = (state) => state && state.addAssets && state.addAssets.values.assetTypeCode;
export const getAssetSubTypeValue = (state) => state?.addAssets?.values?.assetSubTypeCode;
export const getOwnerShipType = (state) =>
  state && state.addAssets && state.addAssets.values && state.addAssets.values.ownerShipType;
export const getAssetLocationsList = (state) => state && state.assetLocationsList;
export const getAssetsUsageConditionList = (state) => state && state.assetUsageConditionStateResponse;
export const getAssignServicesCount = (state) => state && state.assignedServicesCount;
export const getLocalServicesCount = (state) => state && state.localServicesCount;
export const getChargeSettingResponse = (state) => state && state.chargeSettingResponse;
export const getChargesDetail = (state) => state && state.chargesDetail;
export const getCreateAssetResponse = (state) => state && state.createAssetResponse;
export const getCurrencyList = (state) => state?.currenciesList;
export const getDefaultLocationList = (state) => state && state.defaultLocationList;
export const getInventoryWorkerList = (state) => state && state.inventoryWorkersList;
export const getLanguageCode = (state) => state && state.userSettingData && state.userSettingData.applyLanguageCode;
export const getLocationDetails = (state) => state?.selectedLocationDetail;
export const getManufacturerList = (state) => state && state.manufacturerList && state.manufacturerList.response;
export const getModelList = (state) => state && state.modelsList;
export const shouldTemplateCreated = (state) => state && state.isTemplateCreated;
export const shouldSuppressMessage = (state) => state && state.supressEditAssetSuccessMessage;
export const getOpenServices = (state) => state && state.service.open.list;
export const getHistoryServices = (state) => state && state.service.history.list;
export const getOwnershipTypeList = (state) => state && state.ownerShipTypeResponse;
export const getRecentlyUsedTemplatesList = (state) => state && state.recentlyUsedTemplatesList;
export const getResponsibleWorkerList = (state) => state && state.responsibleWorkersList;
export const getScanCodeList = (state) => state && state.scanCodeTypeList;
export const getshowNoRecordFoundViewState = (state) => state && state.showFailureMessageOnCreation;
export const shouldShowManageServices = (state) => state && state.showManageServices;
export const getTemplateInfo = (state) => state && state.templateInfo;
export const getTemplateSearchList = (state) => state && state.templateSearchList;
export const getTransferHistoryList = (state) => state && state.selectedAssetTransferDetail;
export const getAssetTrackingResponse = (state) => state && state.trackingResponse && state.trackingResponse;
export const getDuplicateScanCodeDetails = (state) => state && state.duplicateRecordDetails;
export const getHiltiIntegratedAsset = (state) =>
  state && state.addAssets && state.addAssets.values && state.addAssets.values.hiltiIntegratedAsset;
export const getIsSapAsset = (state) =>
  state && state.addAssets && state.addAssets.values && state.addAssets.values.isSapAsset;
export const getAddLocationResponse = (state) => state && state.addLocationResponse;
export const getAddedLocationDetails = (state) => state?.selectedLocationDetail;
export const getDefaultLocation = (state) => state && state.companyDetails && state.companyDetails.currency;
export const getTemplateChargeSetting = (state) => state && state.templateChargeSetting;
export const getUnregisteredHiltiAssetInfo = (state) => state && state.unregisteredHiltiAsset;
export const getHiltiAssetByScanCode = (state) => state && state.hiltiAssetByScanCode;
export const getWorkerDetails = (state) => state && state.workerDetails;
export const getTransferConfirmationPolicy = (state) => state?.transferConfirmationPolicy;
export const getGeoTagSerials = (state) => state?.geoTags;
export const getExternalAssetsFields = (state) => state?.managedFields?.asset;
export const getIsHiltiAssetOnboardingSucceded = (state) => state?.isHiltiAssetOnboardingSucceded;
export const getDetailLoader = (state) => state && state.detail;
export const getDialogDetailLoader = (state) => state?.dialogDetail;
export const getExpansionPanelDetailsLoader = (state) => state && state.expansionPanelDetail;
export const getTransferHistory = (state) => state && state.transferHistory;
export const getShowAlertListPopup = (state) => state?.showAlertListPopup;
export const getAssetDetailsData = (state) => state && state.selectedAssetDetail;
export const getGatwayAssetType = (state) => state && state.assetType;
export const showAssetViewDialog = (state) => state && state.showAssetListDialog;
export const storageSmartInventoryAssets = (state) => state && state.storageSmartInventoryAssets;
export const getUserMeData = (state) => state && state.userMeData;
export const getCompantDetails = (state) => state && state.companyDetails;
export const getShowManageServices = (state) => state && state.showManageServices;
export const getTemplateData = (state) => state && state.templateData;
export const getHintAssetsData = (state) => state && state.hintAssetsData;
export const showHintError = (state) => state && state.showError;
export const getShowNoRecordFoundViewHint = (state) => state && state.showNoRecordFoundView;
export const getShowNoRecordFoundViewSearchHint = (state) => state && state.showNoRecordFoundViewSearch;
export const getUnregisteredHiltiAssetDetails = (state) => state && state.unregisteredHiltiAssetDetails;
export const getHintSearchString = (state) => state && state.searchString;
export const getImportHistoryList = (state) => state && state.importHistory;
export const getAssetCommonAttributes = (state) => state && state.commonAttributes;
export const getMultiEditAssetResponse = (state) => state && state.multiAssetResponse;
export const getAssetQuickTransferFormValues = (state) =>
  state && state.assetQuickTransferForm && state.assetQuickTransferForm.values;
export const getQuickTransferResponse = (state) => state && state.transferCartResponse;
export const getTransferCartList = (state) => state && state.list;
export const getIsTransferedAssetDeletionEnabled = (state) => state.isDeletionSettingsEnabled;
export const getComapnyDetails = (state) => state.companyDetails;
export const getAssetState = (state) => state;
export const getServiceImportHistoryList = (state) => state && state.serviceImportHistory;
export const getTimeBasedEnabledTenantListFromState = (state) => state.MasterData?.timeBasedEnabledTenantList;
export const getTenantIdFromState = (state) => state.Company?.companyDetails?.tenantId;
export const getDuplicateTransferDetailsFromState = (state) => state?.isDuplicateTransferDetected?.showDuplicatePopUp;
export const getUtilizationSummary = (state) => state.utilizationSummary || [];
export const getUtilizationDetails = (state) => state.utilizationDetails;
