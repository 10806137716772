// @ts-ignore
import { GatewayReportSettingsConstants } from "custom-actions";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../../../../actions/toastrAction";
import {
  getGatewayReportSchedulerSettingApi,
  getGatewayReportSettingApi,
  putSchedulerSettingsApi,
  sendAssetReportApi,
  sendLocationReportApi,
} from "../../../../../api/gatewayReportSettingsApi";
import { HelperLodash } from "am-web-ui-shared/helpers";

function* getGatewayReportSettingFrequency() {
  const response = yield call(() => {
    return getGatewayReportSettingApi();
  });
  if (!response.error) {
    yield put({ type: GatewayReportSettingsConstants.RM_SET_FREQUENCY, payload: response.frequencies });
  }
}

function* putSchedulerSettings(action) {
  const upcomingSchedulerSettings = action.values;
  const state = yield select();
  const { initialValues } = state.GatewayReport;
  const { userMeData } = state.UserReducer;
  const tenantId = HelperLodash.get(userMeData, "tenantId");
  const initialSubscribedWorkerIds = initialValues?.subscribedWorkerDetails.map((item) => item.subscribedWorkerId);
  const initialSchedulerSettings = { ...initialValues, subscribedWorkerIds: initialSubscribedWorkerIds };

  if (!HelperLodash.isEqual(initialSchedulerSettings, upcomingSchedulerSettings)) {
    const response = yield call(() => {
      return putSchedulerSettingsApi(upcomingSchedulerSettings, action.schedulerSettingId);
    });
    if (response && response.error) {
      const errorCode = HelperLodash.get(response, "error.response.data.error", []);
      const code = response.code ? response.code : errorCode && errorCode[0] && errorCode[0].code;
      yield put(showToastr(ToastrType.error, `errorCodes:${code}`, true));
    } else {
      yield put(showToastr(ToastrType.success, "common:SAVED_SUCCESSFULLY", true));
      yield call(getSchedulerSettings, { tenantId });
    }
  } else {
    yield put(showToastr(ToastrType.success, "common:SAVED_SUCCESSFULLY", true));
  }
}

function* getSchedulerSettings(action) {
  const response = yield call(() => {
    return getGatewayReportSchedulerSettingApi(action.tenantId);
  });
  if (!response.error) {
    yield put({ type: GatewayReportSettingsConstants.RM_SET_SCHEDULER_SETTINGS, payload: response });
  }
}

function* sendAssetReport(action) {
  const response = yield call(() => {
    return sendAssetReportApi(action.assetId);
  });
  if (response && response.error) {
    yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`, true));
  } else {
    yield put(showToastr(ToastrType.success, `common:SENT_SUCCESSFULLY`, true));
  }
}

function* sendLocationsReport(action) {
  const response = yield call(() => {
    return sendLocationReportApi(action.locationId);
  });
  if (response && response.error) {
    yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`, true));
  } else {
    yield put(showToastr(ToastrType.success, `common:SENT_SUCCESSFULLY`, true));
  }
}

export function* gatewayReportSettingSaga() {
  yield all([
    takeLatest(
      GatewayReportSettingsConstants.RM_GET_GATEWAY_REPORT_SETTINGS_FREQUENCY,
      getGatewayReportSettingFrequency,
    ),
    takeLatest(GatewayReportSettingsConstants.RM_PUT_SCHEDULER_SETTINGS, putSchedulerSettings),
    takeLatest(GatewayReportSettingsConstants.RM_GET_SCHEDULER_SETTINGS, getSchedulerSettings),
    takeLatest(GatewayReportSettingsConstants.RM_SEND_ASSET_REPORT, sendAssetReport),
    takeLatest(GatewayReportSettingsConstants.RM_SEND_LOCATION_REPORT, sendLocationsReport),
  ]);
}

function* gatewayReportSettingsSaga() {
  yield all([gatewayReportSettingSaga()]);
}
export default gatewayReportSettingsSaga;
