export enum ModuleName {
  allocation = "allocation",
  allocations = "allocations",
  asset = "asset",
  assets = "assets",
  assetTemplate = "assetTemplate",
  assetCost = "asset cost",
  location = "location",
  locations = "locations",
  LockerAllocation = "LOCKERALLOCATION",
  LockerAllocations = "LOCKERALLOCATIONS",
  pendingCharges = "PendingCost",
  worker = "worker",
  workers = "workers",
  ArchivedLocations = "ArchivedLocations",
  services = "Services",
  storageAsset = "storageAsset",
  transfer = "transfer",
  qtyitems = "qtyitems",
  QTY_ITEMS_CODE = "QTY_ITEMS",
  reportTemplate = "reportTemplate",
  QtyItem = "QtyItem",
  QtyItems = "QtyItems",
  Assets = "Assets",
  Employee = "employee",
  QtyItemCharges = "QuantityItemCost",
  // the CIS feature was renamed 'UNITE' during feature definition (BUTSAM-101906)
  // However, apis were already developed by then which used 'CIS'. Hence "UNITE" enum has value "CIS".
  // Change to CIS if apis are corrected in future
  // Also update the translation key in "admin-ui/public/hilti/locales/messages_en-US.json" CIS and CIS_ABBREVIATION to use UNITE instead
  UNITE = "CIS",
  RENTAL_SERVICES = "RENTAL_SERVICES",
  PROACTIVE_TRACKING = "PROACTIVE_TRACKING",
  LOCKER = "LOCKER",
}

export default ModuleName;
