import { ITabMenu } from "../common/components/iTabMenu";
import ILicenseStrategy, {
  AssetStatus,
  AssetTracking,
  CompanyNavigation,
  IAlertsActionBar,
  FieldRequests,
  IAssetDetailsConfig,
  IHeaderDetails,
  ImportWorkflow,
  IReportDetails,
  Inventory,
  LocationDetails,
  Transfer,
  WorkerDetails,
  IWorkerAccessPanelDetails,
  UserProfile,
  VanTracking,
  IProLicenseDetails,
} from "./iLicenseStrategy";
import { IGridFilter } from "TARGET_BUILD/common/components/filter/iGridFilter";

/**
 * The Context defines the interface of interest to clients.
 */
export class LicenseContext {
  private strategy: ILicenseStrategy;

  constructor(strategy: ILicenseStrategy) {
    this.strategy = strategy;
  }

  public setStrategy(strategy: ILicenseStrategy) {
    this.strategy = strategy;
  }

  public getTabLinks(): ITabMenu[] {
    return this.strategy.getTabLinks() || [];
  }

  public isAssetFormFieldDisabled(itemId: string): boolean {
    const assetDetailsArray = this.strategy.getAddAssetDetails();
    const assetItem = assetDetailsArray.find((item) => item.id === itemId);
    return assetItem?.isDisabled;
  }

  public isEmployeeFormFieldDisabled(itemId: string): boolean {
    const employeeDetailsArray = this.strategy.getAddEmployeeDetails();
    const assetItem = employeeDetailsArray.find((item) => item.id === itemId);
    return assetItem?.isDisabled;
  }

  public isStorageAssetDisabled(itemId: string): boolean {
    const storageAssetDetailsArray = this.strategy.getStorageAsset();
    const assetItem = storageAssetDetailsArray.find((item) => item.id === itemId);
    return assetItem?.isDisabled;
  }

  public getImportWorkflow(): ImportWorkflow {
    return this.strategy.getImportWorkflow();
  }

  public assetDetails(): IAssetDetailsConfig {
    return this.strategy.assetDetails();
  }

  public getWorkerPageDetails(): WorkerDetails {
    return this.strategy.getWorkerDetails();
  }

  public getLocationPageDetails(): LocationDetails {
    return this.strategy.getLocationDetails();
  }

  public getAssetDetailsForCharges(): IAssetDetailsConfig {
    return this.strategy.getAssetDetailsForCharges();
  }

  public getAssetDetailsForOneMap(): IAssetDetailsConfig {
    return this.strategy.getAssetDetailsForOneMap();
  }

  public getCompanyPageSettingsNavigation(): CompanyNavigation[] {
    return this.strategy.getCompanySettingsNavigation();
  }

  public assetTracking(): AssetTracking {
    return this.strategy.getAssetTracking();
  }

  public inventory(): Inventory {
    return this.strategy.getInventory();
  }

  public vanTracking(): VanTracking {
    return this.strategy.getVanTracking();
  }

  public fieldRequests(): FieldRequests {
    return this.strategy.getFieldRequests();
  }

  public assetStatus(): AssetStatus {
    return this.strategy.getAssetStatus();
  }

  public additionalAssetFilters(): IGridFilter[] {
    return this.strategy.additionalAssetFilters();
  }

  public transfer(): Transfer {
    return this.strategy.getTransfer();
  }

  public getAlertsActionBar(): IAlertsActionBar {
    return this.strategy.getAlertsActionBar();
  }

  public getUserProfileDetails(): UserProfile {
    return this.strategy.getUserProfileDetails();
  }

  isEnterpriseTenant(): boolean {
    return this.strategy.isEnterpriseTenant();
  }

  isAssignServiceEnabled(): boolean {
    return this.strategy.isAssignServiceEnabled();
  }

  getHeaderDetails(): IHeaderDetails {
    return this.strategy.getHeaderDetails();
  }

  getReportDetails(): IReportDetails {
    return this.strategy.getReportDetails();
  }

  getRoutes(): any[] {
    return this.strategy.getRoutes();
  }

  getWorkerAccessPanelDetails(): IWorkerAccessPanelDetails {
    return this.strategy.getWorkerAccessPanelDetails();
  }
  hasDashboardTrial(): boolean {
    return this.strategy.hasDashboardTrial();
  }

  hasStorageAsset(): boolean {
    return this.strategy.hasStorageAsset();
  }

  hasRestrictedApiAccess(): boolean {
    return this.strategy.hasRestrictedApiAccess();
  }

  getProLicenses(): IProLicenseDetails {
    return this.strategy.getProLicenses();
  }
}
