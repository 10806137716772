import { gridUtils } from "am-web-ui-shared/components";
import { ChargesActions } from "../../../actionConstants";
import { PendingChargesGridSetting } from "../components/pendingCharges/list/pendingChargesGridSetting";
import { setFilterAttributesToReducer } from "../../../utils/commonUtils";
import { revertSortQueryObject } from "../../../utils/customViewUtils";
import { appendSelectedPropertyToList, clearAllSelection, setRowSelection } from "../../../utils/list/listUtils";
import { IChargesState } from "./iChargesModuleState";
import { QtyItemPendingChargesGridSetting } from "../components/pendingCharges/list/qtyItemPendingChargesGridSetting";

const initialState: IChargesState = {
  appliedFilterQuery: [],
  chargeSettingResponse: undefined,
  chargesDetail: null,
  filterQuery: null,
  importHistoryErrors: null,
  loader: {
    isListLoading: false,
  },
  pendingChargesList: {
    list: null,
    offset: null,
    totalRecords: 0,
  },
  resetScrollPosition: false,
  searchString: "",
  showFailureMessageOnCreation: false,
  showNoRecordFoundView: false,
  templateChargeSetting: {},
  pendingChargesQtyItemList: {
    list: null,
    offset: null,
    totalRecords: 0,
  },
};

const updateChargesListState = (state, action) => {
  const existingList = action.payload.offset ? state.pendingChargesList.list : [];

  // pagination appends new records
  const list = [...existingList, ...appendSelectedPropertyToList(action.payload.list.response)];

  delete action.payload.list.response;

  return {
    ...state,
    pendingChargesList: {
      ...action.payload.list,
      list,
    },
    resetScrollPosition: state.pendingChargesList.offset !== null && !action.payload.offset,
    showNoRecordFoundView:
      (state.searchString || (state.filterQuery && state.filterQuery.length)) && !action.payload.list.totalRecords,
  };
};
const updateQtyItemChargesListState = (state, action) => {
  const existingList = action.payload.offset ? state.pendingChargesQtyItemList.list : [];

  // pagination appends new records
  const list = [...existingList, ...appendSelectedPropertyToList(action.payload.list.response)];
  delete action.payload.list.response;

  return {
    ...state,
    pendingChargesQtyItemList: {
      ...action.payload.list,
      list,
    },
    resetScrollPosition: state.pendingChargesQtyItemList.offset !== null && !action.payload.offset,
    showNoRecordFoundView:
      (state.searchString || (state.filterQuery && state.filterQuery.length)) && !action.payload.list.totalRecords,
  };
};
const showNoRecordFoundViewState = (state) => {
  return {
    ...state,
    showFailureMessageOnCreation: true,
  };
};
/**
 * @description Function updates the state object with search string recieved.
 * @param {any} state state.
 * @param {any} action action object from dispatcher
 */
const setSearchQuery = (state, action) => {
  return {
    ...state,
    searchString: action.searchQuery,
  };
};

/**
 * @description - Function to update the state value with fields name for which we need value from api.
 * @param {object} action - Action object.
 * @param {object} state - State object.
 */
const setListFieldsState = (action, state) => {
  return {
    ...state,
    fields: gridUtils.createFieldsQuery(action.fields, PendingChargesGridSetting.defaultQueryFields),
    gridFields: gridUtils.getGridSettingsBindPropertyKey(action.fields),
  };
};

/**
 * @description - Function to update the state value with fields name for which we need value from api.
 * @param {object} action - Action object.
 * @param {object} state - State object.
 */
const setQtyItemListFieldsState = (action, state) => {
  return {
    ...state,
    fields: gridUtils.createFieldsQuery(action.fields, QtyItemPendingChargesGridSetting.defaultQueryFields),
    gridFields: gridUtils.getGridSettingsBindPropertyKey(action.fields),
  };
};

const viewChargesDetails = (state, action) => {
  return {
    ...state,
    chargesDetail: {
      [action.payload.assetId]: action.payload.value,
    },
  };
};

const updateTemplateChargeSetting = (state, action) => {
  return {
    ...state,
    templateChargeSetting: {
      ...state.templateChargeSetting,
      [action.templateId]: action.templateChargeSetting,
    },
  };
};

/**
 * @description Function updates the state value for loader
 * @param {object} state state
 * @param {object} value new value to update
 */
const updateLoaderState = (state, value) => {
  return {
    ...state,
    loader: {
      isListLoading: value,
    },
  };
};

/**
 * @description Function updates the state value with given sort query
 * @param {object} state state
 */
const resetSortQuery = (state, action) => {
  return {
    ...state,
    sortQuery: action.sortQuery,
  };
};

/**
 * @description resets all query params
 */
const resetQueryParam = (state) => {
  const selectedCustomView = state.customView && state.customView.selectedCustomViewFields;
  const sortQuery = revertSortQueryObject(selectedCustomView && selectedCustomView.sortField);
  return {
    ...state,
    filterQuery: null,
    pendingChargesList: {
      list: null,
      offset: null,
      totalRecords: 0,
    },
    pendingChargesQtyItemList: {
      list: null,
      offset: null,
      totalRecords: 0,
    },
    searchString: "",
    showNoRecordFoundView: false,
    sortQuery: {
      sortField: sortQuery && sortQuery.name,
      sortType: sortQuery && sortQuery.sortType,
    },
  };
};

/**
 * @description update custom view
 */
const updateCustomView = (state, action) => {
  const sort = revertSortQueryObject(action.payload.sortField);
  return {
    ...state,
    customView: {
      ...state.customView,
      selectedCustomViewFields: action.payload,
    },
    sortQuery: {
      sortField: sort.name,
      sortType: sort.sortType,
    },
  };
};

const setChargesFilterAppliedAttributes = (state, action) => ({
  ...state,
  appliedFilterQuery: action.payload && action.payload.filterQuery,
});

/**
 * @description Function updates the possible charges filters
 * @param action
 * @param {object} state - charges state
 */
const setChargesFilterAttributes = (action, state) => {
  const { filterAttributes, filterAttributesCount, filterAttributesSearchCount } = setFilterAttributesToReducer(
    state,
    action,
  );

  return {
    ...state,
    filterAttributes,
    filterAttributesCount,
    filterAttributesSearchCount,
  };
};

/**
 * @description Function updates the state value with given sort query
 * @param {object} action - filter query
 * @param {object} state - charges state
 */
const setFilterQuery = (action, state) => {
  return {
    ...state,
    filterQuery: action.filterQuery,
  };
};

/**
 * @description get all possible fields for custom view
 * @param {object} state - charges state
 * @param {object} action - contains payload attribute that consist of all field names
 */
const updateCustomViewFields = (state, action) => {
  return {
    ...state,
    customView: {
      ...state.customView,
      customFields: [...action.payload],
    },
  };
};

const checkSuccessResponse = (state, action) => {
  return {
    ...state,
    chargeSettingResponse: action.payload,
  };
};

/**
 * @description Function to clear default grid settings
 * @param {object} state - asset state
 */
const clearDefaultCustomGrid = (state) => {
  return {
    ...state,
    defaultGridFields: [],
  };
};

/**
 * @description Function to set default grid settings
 * @param {object} state - asset cost state
 */
const setDefaultCustomGrid = (action, state) => {
  return {
    ...state,
    defaultGridFields: action.data.listOfFields,
  };
};

/**
 * @description Function to set default grid settings of qty item charges
 * @param {object} state - asset cost state
 */
const setQtyItemDefaultCustomGrid = (action, state) => {
  return {
    ...state,
    defaultGridFields: action.data.listOfFields,
  };
};

const selectRow = (state, action) => {
  const selectRowList = state.pendingChargesList && state.pendingChargesList.list.slice(0);
  clearAllSelection(selectRowList);
  return {
    ...state,
    pendingChargesList: {
      ...state.pendingChargesList,
      list: setRowSelection(selectRowList, action.id, true, "pendingCostId"),
    },
  };
};

const unselectRow = (state, action) => {
  const unSelectedList = state.pendingChargesList && state.pendingChargesList.list.slice(0);
  return {
    ...state,
    pendingChargesList: {
      ...state.pendingChargesList,
      response: setRowSelection(unSelectedList, action.id, false, "pendingCostId"),
    },
  };
};

const selectQtyRow = (state, action) => {
  const selectRowList = state.pendingChargesQtyItemList?.list?.slice(0);
  clearAllSelection(selectRowList);
  return {
    ...state,
    pendingChargesQtyItemList: {
      ...state.pendingChargesQtyItemList,
      list: setRowSelection(selectRowList, action.item, true, "quantityItemCostId"),
    },
  };
};

const unselectQtyRow = (state, action) => {
  const unSelectedList = state.pendingChargesQtyItemList?.list?.slice(0);
  return {
    ...state,
    pendingChargesQtyItemList: {
      ...state.pendingChargesQtyItemList,
      response: setRowSelection(unSelectedList, action.id, false, "quantityItemCostId"),
    },
  };
};

const chargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ChargesActions.CH_CHARGES_RESET_QUERY_PARAM:
      return resetQueryParam(state);
    case ChargesActions.UI_SAVE_PENDING_CHARGES:
      return updateChargesListState(state, action);
    case ChargesActions.UI_SAVE_QTY_PENDING_CHARGES:
      return updateQtyItemChargesListState(state, action);
    case ChargesActions.CH_CHARGES_LIST_LOADER_START:
      return updateLoaderState(state, true);
    case ChargesActions.CH_CHARGES_LIST_LOADER_END:
      return updateLoaderState(state, false);
    case ChargesActions.SET_CHARGES_SORT_QUERY:
      return resetSortQuery(state, action);
    case ChargesActions.CH_GET_ASSET_CHARGE_SETTING:
      return updateLoaderState(state, false); // Need to make it true - TBD
    case ChargesActions.CH_CLEAR_DEFAULT_GRID_FIELDS:
      return clearDefaultCustomGrid(state);
    case ChargesActions.CH_SUCCESS_CHARGES_DETAILS:
      return viewChargesDetails(state, action);
    case ChargesActions.CH_FAILURE_CHARGES_DETAILS:
      return updateLoaderState(state, false);
    default:
      return firstLevelCases(state, action);
  }
};

const firstLevelCases = (state, action) => {
  switch (action.type) {
    case ChargesActions.CH_SUCCESS_TEMPLATE_CHARGES_SETTING:
      return updateTemplateChargeSetting(state, action);
    case ChargesActions.CH_SUBMIT_ADD_CHARGE_SETTING_RESPONSE:
      return checkSuccessResponse(state, action);
    case ChargesActions.SET_CHARGES_SEARCH_QUERY:
      return setSearchQuery(state, action);
    case ChargesActions.CH_GET_CUSTOM_VIEW_SUCCESS:
      return updateCustomView(state, action);
    case ChargesActions.CH_GET_CUSTOM_VIEW_FIELDS_SUCCESS:
      return updateCustomViewFields(state, action);
    case ChargesActions.CH_CHARGES_FILTER_FIELD_SUCCESS:
      return setChargesFilterAttributes(action, state);
    case ChargesActions.SET_CHARGES_FILTER_APPLIED_FIELD:
      return setChargesFilterAppliedAttributes(state, action);
    case ChargesActions.CH_GET_DEFAULT_VIEW_SUCCESS:
      return setDefaultCustomGrid(action, state);
    case ChargesActions.CH_GET_QTY_ITEM_DEFAULT_VIEW_SUCCESS:
      return setQtyItemDefaultCustomGrid(action, state);
    default:
      return secondLevelCases(state, action);
  }
};

const secondLevelCases = (state, action) => {
  switch (action.type) {
    case ChargesActions.SHOW_FAILURE_MESSAGE:
      return showNoRecordFoundViewState(state);
    case ChargesActions.RESET_SHOW_FAILURE_MESSAGE:
      return { ...state, showFailureMessageOnCreation: false };
    case ChargesActions.SET_CHARGES_FILTER_QUERY:
      return setFilterQuery(action, state);
    case ChargesActions.SET_CHARGES_LIST_FIELDS_QUERY:
      return setListFieldsState(action, state);
    case ChargesActions.SET_QTY_CHARGES_LIST_FIELDS_QUERY:
      return setQtyItemListFieldsState(action, state);
    case ChargesActions.CH_GET_COMPANY_CHARGE_SETTINGS_SUCCESS:
      return {
        ...state,
        companyChargeSettings: action.payload,
      };
    case ChargesActions.CH_GET_WORKDAYS_SUCCESS:
      return {
        ...state,
        companySettingsPreReqResponse: action.payload && action.payload.companySettingsPreReqResponse,
        settingsPreReqResponse: action.payload && action.payload.settingsPreReqResponse,
      };
    case ChargesActions.UPDATE_COMPANY_COST_SETTINGS_SUCCESS:
      return {
        ...state,
        isCompanyChargeSettingsUpdated: action.payload,
      };
    default:
      return ternaryCase(state, action);
  }
};

const ternaryCase = (state, action) => {
  switch (action.type) {
    case ChargesActions.CLEAR_IMPORT_HISTORY_ERRORS:
      return {
        ...state,
        importHistoryErrors: null,
      };
    case ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY_RESPONSE:
      return {
        ...state,
        importAssetChargesHistory: action.payload,
      };
    case ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS_RESPONSE:
      return {
        ...state,
        importHistoryErrors: { ...state.importHistoryErrors, ...action.payload },
      };
    case ChargesActions.CHARGES_SELECT_ROW:
      return selectRow(state, action);
    case ChargesActions.CHARGES_DESELECT_ROW:
      return unselectRow(state, action);
    case ChargesActions.QTY_CHARGES_SELECT_ROW:
      return selectQtyRow(state, action);
    case ChargesActions.QTY_CHARGES_DESELECT_ROW:
      return unselectQtyRow(state, action);
    default:
      return state;
  }
};

export default chargesReducer;
