import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import store from "./common/store/store";
import { getSentryDsnKey, getSentrySampleRate } from "./common/utils/configUtil";
import App from "./app";
import "./base.css";
import "./localization/initI18next";

function init() {
  const SENTRY_DSN = getSentryDsnKey();
  if (SENTRY_DSN) {
    Sentry.init({
      release: process.env.REACT_APP_APP_VERSION,
      dsn: SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.GlobalHandlers({ onunhandledrejection: false, onerror: false }),
      ],
      tracesSampleRate: getSentrySampleRate(),
    });
  }

  return ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root"),
  );
}

if (process.env.NODE_ENV === "development") {
  import("./dev").then((m) => m.setup()).then(init);
} else {
  init();
}
