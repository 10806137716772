import React from "react";
import styled from "styled-components";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import { ISortObject } from "./iSortProps";
import { SortTypes } from "./sort.enum";
const MenuList = styled.ul.attrs({
  id: "sortMenuList",
})`
  max-height: 248px;
  position: absolute;
  overflow: auto;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow:
    0 2px 12px 0 rgba(82, 79, 83, 0.5),
    inset 0 0 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
  width: 240px;
  .list-item {
    color: ${(props) => props.theme.colors.base.steel};
    cursor: pointer;
    padding: 10px 16px 10px 16px;
    line-height: 20px;
    min-height: 40px;
    display: flex;
    align-items: center;
    &:first-child {
      border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
    }
    &:first-child:hover .text {
      color: ${(props) => props.theme.colors.base.black};
      cursor: pointer;
    }
    &[data-selected="true"],
    &[data-selected="true"]:hover {
      font-family: ${(props) => props.theme.fontBold.fontFamily};
      font-weight: ${(props) => props.theme.fontBold.fontWeight};
      background-color: ${(props) => props.theme.sort.selected};
      color: ${(props) => props.theme.sort.text};
    }
    &:hover {
      background-color: ${(props) => props.theme.sort.hover};
      color: ${(props) => props.theme.colors.base.black};
    }
  }
`;

const SortList = styled.li`
  display: flex;
  justify-content: space-between;
  .text {
    color: ${(props) => props.theme.colors.base.steel};
  }
`;

const formatLabel = (isTranslation, data) =>
  isTranslation
    ? `${data && data.label}`
    : `common:SORT_LIST_${data.label ? data.label.toUpperCase() : data.name.toUpperCase()}`;
const SortViewComponent = (props) => {
  const { selectedField, t, showSortingModal, isTranslation, sortType, list, handleSelectOption, sortTypeToggle } =
    props;
  const menuItems = (list || []).map((data: ISortObject) => {
    const label = t(formatLabel(isTranslation, data));
    return React.createElement(
      "li",
      {
        className: "list-item",
        "data-selected":
          selectedField && selectedField.name
            ? selectedField.name === data.name
            : selectedField.sortField === data.sortKey || selectedField.sortField === data.name,
        id: data.name,
        key: data.id || data.name,
        onClick: () => handleSelectOption(data),
      },
      label,
    );
  });
  let menuList;
  if (showSortingModal) {
    menuList = (
      <MenuList>
        <SortList onClick={sortTypeToggle} className="list-item">
          <label className="text">
            {sortType === SortTypes.ASCENDING ? t(`actionBar:SORT_ASCENDING`) : t(`actionBar:SORT_DESCENDING`)}
          </label>
          <SquareIcon name={sortType === SortTypes.ASCENDING ? IconName.SortDescending : IconName.SortAscending} />
        </SortList>
        {menuItems}
      </MenuList>
    );
  } else {
    props.closeMenu();
  }
  return <>{menuList}</>;
};

export default SortViewComponent;
