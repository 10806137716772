import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { INotificationModalProps } from "./iNotificationModalProps";
import { NotificationModalToastr } from "./notificationModalToastr";

export const CloseImg = styled.span<INotificationModalProps>`
  width: 25px;
  background: url(${(props) => props.notificationCloseIconUrl}) no-repeat;
  cursor: pointer;
  margin-top: 5px;
`;
const MultipleTosterContainer = styled.div`
  #popperContainer {
    position: fixed;
    z-index: 9999999;
    background-color: ${(props) => props.theme.colors.base.black};
    box-shadow:
      0 2px 12px 0 ${(props) => props.theme.colors.base.greyrgba},
      inset 0 1px 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    bottom: 32px;
    transform: translate(-50%, 0);
    left: 50%;
    width: 540px;
    margin: 0 auto;
  }
`;

const toastPortal = document.getElementById("toastPortal");

class NotificationModal extends React.PureComponent<INotificationModalProps> {
  el = document.createElement("div");

  /**
   * Show NotificationModal once this component is mounted
   *
   * @memberof NotificationModal
   */
  componentDidMount = () => {
    if (toastPortal) {
      toastPortal.appendChild(this.el);
    }
  };

  /**
   * Hide NotificationModal once this component is unmounted
   *
   * @memberof NotificationModal
   */
  componentWillUnmount = () => {
    if (toastPortal) {
      toastPortal.removeChild(this.el);
    }
  };

  render() {
    return ReactDOM.createPortal(
      <MultipleTosterContainer>
        <NotificationModalToastr {...this.props} />
      </MultipleTosterContainer>,
      this.el,
    );
  }
}

export default NotificationModal;
