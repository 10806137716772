// @ts-ignore
import { ChargesActions } from "custom-actions";
import { IPlugCostData } from "../components/pendingCharges/list/model/iAddPlugCostProps";

/**
 * @description dispatcher to fetch charges list in the store
 * @param {number} offset - takes offset.
 */
export const fetchChargesList = (offsetVal?: number) => ({
  offset: offsetVal || 0,
  type: ChargesActions.CH_GET_PENDING_CHARGES,
});

/**
 * @description dispatcher to fetch qty item charges list in the store
 * @param {number} offset - takes offset.
 */
export const fetchQtyItemChargesList = (offsetVal?: number) => ({
  offset: offsetVal || 0,
  type: ChargesActions.CH_GET_QTY_ITEM_PENDING_CHARGES,
});
/**
 * @description dispatcher to get the charges setting
 */

export const getChargeSetting = (id: string) => ({
  id,
  type: ChargesActions.CH_GET_ASSET_CHARGE_SETTING,
});

/**
 * @description dispatcher to get the charges setting
 */
export const getTemplateChargeSetting = (templateId) => ({
  templateId,
  type: ChargesActions.CH_GET_TEMPLATE_CHARGE_SETTING,
});

/**
 * @description dispatcher to POST/PUT the template charges setting
 */
export const submitTemplatechargeSetting = (chargeSettingData) => ({
  chargeSettingData,
  source: chargeSettingData.source,
  type: ChargesActions.CH_SUBMIT_TEMPLATE_CHARGE_SETTING,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param {string} searchString - takes entered search string.
 */
export const setSortQuery = (sortQuery) => ({
  sortQuery,
  type: ChargesActions.SET_CHARGES_SORT_QUERY,
});

export const showFailureMessage = () => ({
  type: ChargesActions.SHOW_FAILURE_MESSAGE,
});
/**
 * @description Function to dispatch the method to reset query params to default
 */
export const resetQueryObjToDefault = () => ({
  type: ChargesActions.CH_CHARGES_RESET_QUERY_PARAM,
});

export const resetShowFailureMessage = () => ({
  type: ChargesActions.RESET_SHOW_FAILURE_MESSAGE,
});
/**
 * @description Function to dispatch the method to set search value in the store
 * @param {string} searchQuery - takes entered search string.
 */
export const setSearchQuery = (searchQuery) => ({
  searchQuery,
  type: ChargesActions.SET_CHARGES_SEARCH_QUERY,
});

/**
 * @description- Function to dispatch action to get custom view.
 */
export const getPendingChargesCustomView = () => ({
  type: ChargesActions.CH_GET_CUSTOM_VIEW,
});

/**
 * @description- Function to dispatch action to get custom view.
 */
export const getQtyItemPendingChargesCustomView = () => ({
  type: ChargesActions.CH_GET_QTY_CUSTOM_VIEW,
});

/**
 * @description- Function to dispatch action to get custom view list fields.
 */
export const getPendingChargesCustomViewListFields = () => ({
  type: ChargesActions.CH_GET_CUSTOM_VIEW_FIELDS,
});

/**
 * @description- Function to dispatch action to get qty item custom view list fields.
 */
export const getQtyItemPendingChargesCustomViewListFields = () => ({
  type: ChargesActions.CH_GET_QTY_ITEM_CUSTOM_VIEW_FIELDS,
});

/**
 * @description dispatcher to get the filter attributes for Worker
 * @action getWorkerFilterAttributes
 * @param delay
 * @param customAttribute
 * @param query
 */
export const getPendingChargesFilterAttributes = (delay = true, customAttribute?: string, query?: string) => ({
  delay,
  payload: {
    customAttribute,
    query,
  },
  type: ChargesActions.CH_GET_CHARGES_FILTER_FIELD,
});

export const getQtyItemFilterAttributes = (delay = true, customAttribute?: string, query?: string) => ({
  delay,
  payload: {
    customAttribute,
    query,
  },
  type: ChargesActions.CH_GET_QTY_ITEM_CHARGES_FILTER_FIELD,
});

export const getQtyItemPendingChargesFilterAttributes = (delay = true, customAttribute?: string, query?: string) => ({
  delay,
  payload: {
    customAttribute,
    query,
  },
  type: ChargesActions.CH_GET_QTY_ITEM_CHARGES_FILTER_FIELD,
});

export const setChargesFilterAppliedAttributes = (filterQuery) => ({
  payload: {
    filterQuery,
  },
  type: ChargesActions.SET_CHARGES_FILTER_APPLIED_FIELD,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param {string} searchString - takes entered search string.
 */
export const setFilterQuery = (filterQuery) => ({
  filterQuery,
  type: ChargesActions.SET_CHARGES_FILTER_QUERY,
});
/**
 * @description - Get the asset costs list
 * @param page - Page number.
 */
export const getPendingChargesListForSearch = (page?: number) => ({
  offset: page || 0,
  type: ChargesActions.CH_CHARGES_LIST_FOR_SEARCH,
});

export const getQtyPendingChargesListForSearch = (page?: number) => ({
  offset: page || 0,
  type: ChargesActions.CH_CHARGES_LIST_FOR_QTY_SEARCH,
});

/**
 * @description dispatcher to set the list fields value in the store.
 * @param {string} fields - Comma seperated string of fields.
 */
export const setListFieldsQuery = (fields: string[]) => ({
  fields,
  type: ChargesActions.SET_CHARGES_LIST_FIELDS_QUERY,
});

/**
 * @description dispatcher to set the list fields value in the store.
 * @param {string} fields - Comma seperated string of fields.
 */
export const setQtyItemListFieldsQuery = (fields: string[]) => ({
  fields,
  type: ChargesActions.SET_QTY_CHARGES_LIST_FIELDS_QUERY,
});

/**
 * @description dispatcher to POST/PUT the asset charges setting
 */
export const submitAssetCostSetting = (chargeSettingData) => ({
  chargeSettingData,
  type: ChargesActions.CH_SUBMIT_ASSET_CHARGE_SETTING,
});
export const updateCustomGridFields = (customView) => ({
  customView,
  type: ChargesActions.CH_UPDATE_CUSTOM_GRID_FIELDS,
});

export const updateQtyItemCustomGridFields = (customView) => ({
  customView,
  type: ChargesActions.CH_UPDATE_QTY_ITEM_CUSTOM_GRID_FIELDS,
});
/**
 * @description - Function to dispatch action to clear system grid fields
 */
export const clearDefaultGridFields = () => ({
  type: ChargesActions.CH_CLEAR_DEFAULT_GRID_FIELDS,
});

/**
 * @description - Function to dispatch action to reset to default a custom view
 */
export const resetToDefaultView = () => ({
  type: ChargesActions.CH_RESET_TO_DEFAULT_VIEW,
});

/**
 * @description - Function to dispatch action to reset to default a custom view
 */
export const resetToQtyItemDefaultView = () => ({
  type: ChargesActions.CH_RESET_TO_QTY_ITEM_DEFAULT_VIEW,
});

/**
 * @description - Function to dispatch action to reset to default a custom view
 */
export const getDefaultView = () => ({
  type: ChargesActions.CH_GET_DEFAULT_VIEW,
});

/**
 * @description - Function to dispatch action to reset to default qty item custom view
 */
export const getQtyItemDefaultView = () => ({
  type: ChargesActions.CH_GET_QTY_ITEM_DEFAULT_VIEW,
});

export const getCompanyChargeSettings = () => ({
  type: ChargesActions.CH_GET_COMPANY_CHARGE_SETTINGS,
});

export const getWorkDaysMetaData = () => ({
  type: ChargesActions.CH_GET_WORKDAYS,
});

export const submitCostSettings = (item, t) => ({
  item,
  t,
  type: ChargesActions.UPDATE_COMPANY_COST_SETTINGS,
});

/** action to clear the import file errors */
export const clearAssetChargesImportHistoryErrors = () => ({
  type: ChargesActions.CLEAR_IMPORT_HISTORY_ERRORS,
});

/** action to get import file history data */
export const getAssetChargesImportHistory = (type) => ({
  type: ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY,
  apiType: type,
});

export const getAssetChargesImportHistoryErrors = (historyId: number, accordinId: number) => ({
  accordinId,
  historyId,
  type: ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS,
});

/**
 * @description- dispatcher to POST add plug cost data
 */
export const addPlugCost = (plugCostData: IPlugCostData) => ({
  plugCostData,
  type: ChargesActions.ADD_PLUG_COST,
});

/** action to delete plug cost data */
export const deletePlugCost = (plugCostId) => ({
  plugCostId,
  type: ChargesActions.DELETE_PLUG_COST,
});
