enum ServiceUrl {
  AUDITING = "AUDITING",
  USER_MGMT = "USER_MGMT",
  ORG_MGMT = "ORG_MGMT",
  ASSET_MGMT = "ASSET_MGMT",
  ORCHESTRATION = "ORCHESTRATION",
  ACTIVE_TRACKING = "ACTIVE_TRACKING",
  DOC_MGMT = "DOC_MGMT",
  ALERT_NOTIFICATION_MGMT = "ALERT_NOTIFICATION_MGMT",
  CHARGES = "CHARGES",
  HILTI_CLOUD = "HILTI_CLOUD",
  HILTI_INTEGRATION = "HILTI_INTEGRATION",
  REPORTING = "REPORTING",
  UNITE = "UNITE",
  HILTI_CLOUD_REPAIR_ORDERS = "HILTI_CLOUD_REPAIR_ORDERS",
  HILTI_CLOUD_TECHNICAL_USERS = "HILTI_CLOUD_TECHNICAL_USERS",
  HILTI_CLOUD_WARRANTY_EXCHANGE = "HILTI_CLOUD_WARRANTY_EXCHANGE",
  HILTI_CLOUD_FLEET = "HILTI_CLOUD_FLEET",
  FEATURE_FLAGS = "FEATURE_FLAGS",
  TRACK_UNIT = "TRACK_UNIT",
}

export default ServiceUrl;
