import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M16.4571787,15.699118 L18.7265342,20.489608 L20.6534384,17.4139942 L22.2207224,19.9222 L24,19.9222 L24,21.5959816 L21.3176897,21.5959816 L20.6534384,20.5554573 L18.5452243,24 L16.4571787,19.5698888 L15.4620602,21.5959816 L12.2720779,21.5959816 L12.2720779,19.9222 L14.4253981,19.9222 L16.4571787,15.699118 Z M16.386,13 L14.5,17 L11,17 L11,13 L16.386,13 Z M19,13 L19,17 L18.423,17 L16.529,13 L19,13 Z M17.5,1 L19,2.5 L19,6 L18.125,7 L16.515,7 L17.5,12 L13.025974,12 L11.9,7 L8.5,7 L5,6 L5,2 L8.5,1 L17.5,1 Z M4,3 L4,5 L1,5 L1,3 L4,3 Z" />
    </g>
  </svg>
);

export default SVG;
