// @ts-ignore
import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";
import { dependencyResolver } from "../grid/gridUtils";
import IHeaderProps from "./iHeader";

const HeaderWrapper = styled(Grid)`
  && {
    width: 100%;
    margin: 0;
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    color: ${(props) => props.theme.colors.base.steel};
  }
`;

export const Header = (props: IHeaderProps) => {
  const { columns, dependencies } = props;
  const headers = columns.map((column, index) => {
    const dependency =
      column.headerInfo &&
      column.headerInfo.dependency &&
      dependencyResolver(dependencies, column.headerInfo.dependency);
    return (
      <Grid key={index} item={true} xs={column.cellSize}>
        {(dependency && dependency(column.key)) || column.key}
      </Grid>
    );
  });
  return (
    <HeaderWrapper container={true} spacing={4}>
      {headers}
    </HeaderWrapper>
  );
};

export default Header;
