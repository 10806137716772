import ControlType from "../filter/controlType";
import FilterOperator from "../filter/filterOperator";
import { IGridFilter } from "../filter/iGridFilter";

/**
 * defines all possible filters in the Service List
 */

const filterNo = "filters:NO";
const filterYes = "filters:YES";
export const openGridFiltersSettings: IGridFilter[] = [
  {
    filterDisplayName: "filters:FREQUENCY",
    filterName: "frequency",
    hasDependency: false,
    operator: FilterOperator.EQ,
    type: {
      moreFilter: ControlType.RadioGroup,
      searchBar: ControlType.RadioSelectDropdown,
    },
  },
  {
    filterDisplayName: "filters:ATTACHMENT",
    filterName: "hasAttachments",
    hasDependency: false,
    labels: {
      noLabel: filterNo,
      yesLabel: filterYes,
    },
    operator: FilterOperator.EQ,
    type: {
      moreFilter: ControlType.RadioGroup,
      searchBar: ControlType.RadioSelectDropdown,
    },
    useDefault: true,
  },
  {
    filterDisplayName: "filters:SCHEDULE_DATE",
    filterName: "scheduleDate",
    hasDependency: false,
    operator: FilterOperator.EQ,
    type: {
      moreFilter: ControlType.RadioGroup,
      searchBar: ControlType.RadioSelectDropdown,
    },
  },
];

export const historyGridFiltersSettings: IGridFilter[] = [
  {
    filterDisplayName: "filters:PROVIDER",
    filterName: "provider",
    hasDependency: true,
    operator: FilterOperator.IN,
    type: {
      moreFilter: ControlType.CheckboxGroup,
      searchBar: ControlType.MultiSelectDropdown,
    },
  },
  {
    filterDisplayName: "filters:SCHEDULE_DATE",
    filterName: "scheduleDate",
    hasDependency: false,
    operator: FilterOperator.EQ,
    type: {
      moreFilter: ControlType.RadioGroup,
      searchBar: ControlType.RadioSelectDropdown,
    },
  },
  {
    filterDisplayName: "filters:COMPLETION_DATE",
    filterName: "completionDate",
    hasDependency: false,
    operator: FilterOperator.EQ,
    type: {
      moreFilter: ControlType.RadioGroup,
      searchBar: ControlType.RadioSelectDropdown,
    },
  },
];

export default { openGridFiltersSettings, historyGridFiltersSettings };
