import { SquareIcon } from "am-web-ui-shared/components";
import React from "react";
import StyledBannerWrapper from "../Containers/styledBannerWrapper";
import IPlaceholderProps from "./iPlaceholder";

/**
 * @const BoldIcon is a Styled Component that consist of 2 things:
 * 1. Icon: An Icon that will be visible at the right side of the content (::After).
 * 2. Bold Text: This is the text with hilti-bold font-family.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 */
const Placeholder = (props: IPlaceholderProps) => {
  const { id, iconUrl, className, imageSize } = props;
  return (
    <StyledBannerWrapper id={id}>
      <SquareIcon name={iconUrl} className={className} imageSize={imageSize} />
    </StyledBannerWrapper>
  );
};

/**
 * @export Placeholder - Styled Component.
 */
export default Placeholder;
