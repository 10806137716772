import Grid from "@mui/material/Grid";
// @ts-ignore
import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { DownloadFileHandler } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import CommonApi from "../../api/commonApi";
import { IViewonlyAttachmentsProps } from "./iViewonlyAttachmentsProps";

export const DownloadWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.divBorder.backgroundLightGrey};
  padding: 16px;
  margin-top: 16px;
`;
const DownloadText = styled.div`
  display: flex;
`;
export const Textbold = styled.div`
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.boldSteelText.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;
export const Text = styled.div`
  padding-right: 16px;
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.boldSteelText.steel};
`;

const downloadDocument = (id, fileName) => {
  DownloadFileHandler.download(CommonApi, id, fileName);
};

export const renderViewAttachmentData = (data, labelName) => (
  <Grid item={true} sm={6}>
    <DownloadWrapper>
      <Textbold>{`${data.displayName !== null ? data.displayName : data.fileName} ${labelName}`}</Textbold>
      <DownloadText
        id={`viewOnlyAttachment${data.attachmentId}`}
        onClick={downloadDocument.bind(null, data.attachmentId, data.fileName)}
      >
        <Text>{data.fileName}</Text>
        <SquareIcon name={IconName.Download} />
      </DownloadText>
    </DownloadWrapper>
  </Grid>
);

export const ViewOnlyAttachments = (props: IViewonlyAttachmentsProps) => {
  const { viewOnlyAttachment, viewOnlyAttachmentLabelName } = props;
  return (
    <Grid container={true} sm={12} spacing={2}>
      {viewOnlyAttachment.map((attachments) => renderViewAttachmentData(attachments, viewOnlyAttachmentLabelName))}
    </Grid>
  );
};

export default withTranslation()(ViewOnlyAttachments);
