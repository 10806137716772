import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M20.5,12.5 L21.5,13.5 L20,15 L21.5,16.5 L23,15 L24,16 L22.5,18.5 L20,18.5 L16,22.5 L14,20.5 L18,16.5 L18,14 L20.5,12.5 Z M16.4969139,13.7033898 L16.4969139,15.8821343 L14.61,17.7711864 L10.1694915,17.7711864 L10.1694915,13.7033898 L16.4969139,13.7033898 Z M16.779661,1.5 L18.3050847,3.02542373 L18.3050847,6.58474576 L17.4152542,7.60169492 L15.777,7.601 L16.779661,12.6864407 L12.2298041,12.6864407 L11.084,7.601 L7.62711864,7.60169492 L4.06779661,6.58474576 L4.06779661,2.51694915 L7.62711864,1.5 L16.779661,1.5 Z M3.05084746,3.53389831 L3.05084746,5.56779661 L0,5.56779661 L0,3.53389831 L3.05084746,3.53389831 Z"></path>
    </g>
  </svg>
);

export default SVG;
