import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <polygon
        transform="translate(13.000000, 12.000000) rotate(-90.000000) translate(-13.000000, -12.000000) "
        points="13 16 7 8 19 8"
      />
    </g>
  </svg>
);

export default SVG;
