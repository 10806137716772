import { LocaleManager } from "am-web-ui-shared/helpers";
import React from "react";
import { Trans } from "react-i18next";
import { IAssetDetailsData } from "TARGET_BUILD/common/models/asset/assetModel";
import { IconNormalBold } from "../../../../../components/DetailsPanel/index";

const DEFAULT_DECIMALS = 2;

/**
 * @description - cost code of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const costCode = (data) => {
  return (
    data.costCode && (
      <IconNormalBold
        id="pnlCostCode"
        normalId="lblCostCode"
        normalText={<Trans>{"assets:COST_CODE"}</Trans>}
        boldId="txtCostCode"
        boldText={data.costCode}
      />
    )
  );
};

/**
 * @description - purchase currency of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const purchaseCurrency = (data) => {
  const purchaseCurrencyVal = data && data.ownedAssetDetails && data.ownedAssetDetails.purchaseCurrencyCode;
  return (
    purchaseCurrencyVal && (
      <IconNormalBold
        id="pnlPurchaseCurrency"
        normalId="lblPurchaseCurrency"
        normalText={<Trans>{"assets:PURCHASE_CURRENCY"}</Trans>}
        boldId="txtPurchaseCurrency"
        boldText={purchaseCurrencyVal}
      />
    )
  );
};

/**
 * @description - purchase date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const purchaseDate = (data) => {
  const purchaseDateVal = data && data.ownedAssetDetails && data.ownedAssetDetails.purchaseDate;
  return (
    purchaseDateVal && (
      <IconNormalBold
        id="pnlPurchaseDate"
        normalId="lblPurchaseDate"
        normalText={<Trans>{"assets:PURCHASE_DATE"}</Trans>}
        boldId="txtPurchaseDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(purchaseDateVal)}
      />
    )
  );
};

/**
 * @description - purchase order number of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const purchaseOrderNumber = (data) => {
  const purchaseOrderNumberVal = data && data.ownedAssetDetails && data.ownedAssetDetails.purchaseOrderNumber;
  return (
    purchaseOrderNumberVal && (
      <IconNormalBold
        id="pnlPurchaseOrderNumber"
        normalId="lblPurchaseOrderNumber"
        normalText={<Trans>{"assets:PURCHASE_ORDER_NUMBER"}</Trans>}
        boldId="txtPurchaseOrderNumber"
        boldText={purchaseOrderNumberVal}
      />
    )
  );
};

/**
 * @description - purchase price of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const purchasePrice = (data) => {
  const purchasePriceVal = data && data.ownedAssetDetails && data.ownedAssetDetails.purchasePrice;
  return (
    purchasePriceVal && (
      <IconNormalBold
        id="pnlPurchasePrice"
        normalId="lblPurchasePrice"
        normalText={<Trans>{"assets:PURCHASE_PRICE"}</Trans>}
        boldId="txtPurchasePrice"
        boldText={LocaleManager.numberHelpers.formatDecimalNumber(purchasePriceVal, DEFAULT_DECIMALS)}
      />
    )
  );
};

/**
 * @description - replacement cost of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const replacementCost = (data) => {
  return (
    data.replacementCost && (
      <IconNormalBold
        id="pnlReplacementCost"
        normalId="lblReplacementCost"
        normalText={<Trans>{"assets:REPLACEMENT_COST"}</Trans>}
        boldId="txtReplacementCost"
        boldText={LocaleManager.numberHelpers.formatDecimalNumber(data.replacementCost, DEFAULT_DECIMALS)}
      />
    )
  );
};

/**
 * @description - vendor of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const vendor = (data) => {
  const vendorVal = data && data.ownedAssetDetails && data.ownedAssetDetails.vendor;
  return (
    vendorVal && (
      <IconNormalBold
        id="pnlVendor"
        normalId="lblVendor"
        normalText={<Trans>{"assets:FIELD_NAME_VENDOR"}</Trans>}
        boldId="txtVendor"
        boldText={vendorVal}
      />
    )
  );
};

/**
 * @description - warranty expiration date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const warrantyExpDate = (data) => {
  const warrantyExpDateVal = data && data.ownedAssetDetails && data.ownedAssetDetails.warrantyExpirationDate;
  return (
    warrantyExpDateVal && (
      <IconNormalBold
        id="pnlWarrantyExpDate"
        normalId="lblWarrantyExpDate"
        normalText={<Trans>{"assets:WARRANTY_EXPIRATION_DATE"}</Trans>}
        boldId="txtWarrantyExpDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(warrantyExpDateVal)}
      />
    )
  );
};

/**
 * @description - fleet delivery date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const fleetDeliveryDate = (data) => {
  const fleetDeliveryDateVal = data && data.fleetAssetDetails && data.fleetAssetDetails.fleetDeliveryDate;
  return (
    fleetDeliveryDateVal && (
      <IconNormalBold
        id="pnlFleetDeliveryDate"
        normalId="lblFleetDeliveryDate"
        normalText={<Trans>{"assets:FLEET_DELIVERY_DATE"}</Trans>}
        boldId="txtFleetDeliveryDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(fleetDeliveryDateVal)}
      />
    )
  );
};

/**
 * @description - fleet exchange date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const fleetExchangeDate = (data) => {
  const fleetExchangeDateVal = data && data.fleetAssetDetails && data.fleetAssetDetails.fleetExchangeOrWarrantyDate;
  return (
    fleetExchangeDateVal && (
      <IconNormalBold
        id="pnlFleetExchangeDate"
        normalId="lblFleetExchangeDate"
        normalText={<Trans>{"assets:FLEET_EXCHANGE_DATE"}</Trans>}
        boldId="txtFleetExchangeDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(fleetExchangeDateVal)}
      />
    )
  );
};

/**
 * @description - fleet inventory number of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const fleetInventoryNumber = (data) => {
  const fleetInventoryNumberVal = data && data.fleetAssetDetails && data.fleetAssetDetails.fleetInventoryNumber;
  return (
    fleetInventoryNumberVal && (
      <IconNormalBold
        id="pnlFleetInventoryNumber"
        normalId="lblFleetInventoryNumber"
        normalText={<Trans>{"assets:FLEET_INVENTORY_NUMBER"}</Trans>}
        boldId="txtFleetInventoryNumber"
        boldText={fleetInventoryNumberVal}
      />
    )
  );
};

/**
 * @description - fleet ref number of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const fleetRefNumber = (data) => {
  const fleetRefNumberVal = data && data.fleetAssetDetails && data.fleetAssetDetails.fleetOrganizationReferenceNumber;
  return (
    fleetRefNumberVal && (
      <IconNormalBold
        id="pnlFleetRefNumber"
        normalId="lblFleetRefNumber"
        normalText={<Trans>{"assets:FLEET_REF_NUMBER"}</Trans>}
        boldId="txtFleetRefNumber"
        boldText={fleetRefNumberVal}
      />
    )
  );
};

/**
 * @description - fleet cost center of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const fleetCostCenter = (data) => {
  const fleetCostCenterVal = data && data.fleetAssetDetails && data.fleetAssetDetails.fleetCostCenter;
  return (
    fleetCostCenterVal && (
      <IconNormalBold
        id="pnlWarrantyExpDate"
        normalId="lblWarrantyExpDate"
        normalText={<Trans>{"assets:FLEET_COST_CENTER"}</Trans>}
        boldId="txtWarrantyExpDate"
        boldText={fleetCostCenterVal}
      />
    )
  );
};

/**
 * @description - fleet purchase order number of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const fleetPurchaseOrderNumber = (data) => {
  const fleetPurchaseOrderNumberVal = data && data.fleetAssetDetails && data.fleetAssetDetails.fleetPurchaseOrderNumber;
  return (
    fleetPurchaseOrderNumberVal && (
      <IconNormalBold
        id="pnlFleetPurchaseOrderNumber"
        normalId="lblFleetPurchaseOrderNumber"
        normalText={<Trans>{"assets:PURCHASE_ORDER_NUMBER"}</Trans>}
        boldId="txtFleetPurchaseOrderNumber"
        boldText={fleetPurchaseOrderNumberVal}
      />
    )
  );
};

/**
 * @description - fleet currency of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const fleetCurrency = (data) => {
  const fleetCurrencyVal = data && data.fleetAssetDetails && data.fleetAssetDetails.fleetCurrencyCode;
  return (
    fleetCurrencyVal && (
      <IconNormalBold
        id="pnlFleetCurrency"
        normalId="lblFleetCurrency"
        normalText={<Trans>{"assets:FLEET_CURRENCY"}</Trans>}
        boldId="txtFleetCurrency"
        boldText={fleetCurrencyVal}
      />
    )
  );
};

/**
 * @description - loan tool claim of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const loanToolClaim = (data: IAssetDetailsData, t: (key: string) => string) => {
  const loanToolClaimVal = data && data.fleetAssetDetails && data.fleetAssetDetails.loanToolClaim;
  return (
    <IconNormalBold
      id="pnlLoanToolClaim"
      normalId="lblLoanToolClaim"
      normalText={t("assets:LOAN_TOOL_CLAIM")}
      boldId="txtLoanToolClaim"
      boldText={loanToolClaimVal ? t("common:YES") : t("common:NO")}
    />
  );
};

/**
 * @description - theft insurance of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const theftInsurance = (data: IAssetDetailsData, t: (key: string) => string) => {
  const theftInsuranceVal = data && data.fleetAssetDetails && data.fleetAssetDetails.theftInsurance;
  return (
    <IconNormalBold
      id="pnlTheftInsurance"
      normalId="lblTheftInsurance"
      normalText={t("assets:THEFT_INSURANCE")}
      boldId="txtTheftInsurance"
      boldText={theftInsuranceVal ? t("common:YES") : t("common:NO")}
    />
  );
};

/**
 * @description - monthly fleet rate of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const monthlyFleetRate = (data) => {
  const monthlyFleetRateVal = data && data.fleetAssetDetails && data.fleetAssetDetails.monthlyFleetRate;
  return (
    monthlyFleetRateVal && (
      <IconNormalBold
        id="pnlMonthlyFleetRate"
        normalId="lblMonthlyFleetRate"
        normalText={<Trans>{"assets:MONTHLY_FLEET_RATE"}</Trans>}
        boldId="txtTMonthlyFleetRate"
        boldText={LocaleManager.numberHelpers.formatDecimalNumber(monthlyFleetRateVal, DEFAULT_DECIMALS)}
      />
    )
  );
};

/**
 * @description - loan start date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const loanStartDate = (data) => {
  const loanStartDateVal = data && data.loanedAssetDetails && data.loanedAssetDetails.loanStartDate;
  return (
    loanStartDateVal && (
      <IconNormalBold
        id="pnlLoanStartDate"
        normalId="lblLoanStartDate"
        normalText={<Trans>{"assets:LOAN_START_DATE"}</Trans>}
        boldId="txtTLoanStartDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(loanStartDateVal)}
      />
    )
  );
};

/**
 * @description - loan end date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const loanEndDate = (data) => {
  const loanEndDateVal = data && data.loanedAssetDetails && data.loanedAssetDetails.loanEndDate;
  return (
    loanEndDateVal && (
      <IconNormalBold
        id="pnlLoanEndDate"
        normalId="lblLoanEndDate"
        normalText={<Trans>{"assets:LOAN_END_DATE"}</Trans>}
        boldId="txtTLoanEndDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(loanEndDateVal)}
      />
    )
  );
};

/**
 * @description - rent start date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const rentStartDate = (data) => {
  const rentStartDateVal = data && data.rentedAssetDetails && data.rentedAssetDetails.rentalStartDate;
  return (
    rentStartDateVal && (
      <IconNormalBold
        id="pnlRentStartDate"
        normalId="lblRentStartDate"
        normalText={<Trans>{"assets:RENT_START_DATE"}</Trans>}
        boldId="txtRentStartDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(rentStartDateVal)}
      />
    )
  );
};

/**
 * @description - rent return date of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const rentReturnDate = (data) => {
  const rentReturnDateVal = data && data.rentedAssetDetails && data.rentedAssetDetails.rentalReturnDate;
  return (
    rentReturnDateVal && (
      <IconNormalBold
        id="pnlRentReturnDate"
        normalId="lblRentReturnDate"
        normalText={<Trans>{"assets:RENT_RETURN_DATE"}</Trans>}
        boldId="txtTRentReturnDate"
        boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(rentReturnDateVal)}
      />
    )
  );
};

/**
 * @description - rental tool claim of the asset.
 *
 * @param {object} data - Asset details object.
 */
export const rentToolClaim = (data) => {
  const rentToolClaimVal = data && data.rentedAssetDetails && data.rentedAssetDetails.rentalToolClaim;
  return (
    rentToolClaimVal && (
      <IconNormalBold
        id="pnlRentToolClaim"
        normalId="lblRentToolClaim"
        normalText={<Trans>{"assets:RENT_TOOL_CLAIM"}</Trans>}
        boldId="txtRentToolClaim"
        boldText={rentToolClaimVal}
      />
    )
  );
};

/**
 * @description - ownership hiltiAssetStatus.
 *
 * @param {object} data - Asset details object.
 */
export const ownershipHiltiAssetStatus = (data) => {
  const ownershipHiltiAssetStatusValue = data && data.hiltiAssetStatus && data.hiltiAssetStatus.value;
  return (
    ownershipHiltiAssetStatusValue && (
      <IconNormalBold
        id="pnlOwnershipHiltiAssetStatus"
        normalId="lblOwnershipHiltiAssetStatus"
        normalText={<Trans>{"assets:OWNERSHIP_HILTI_ASSET_STATUS"}</Trans>}
        boldId="txtOwnershipHiltiAssetStatus"
        boldText={`${ownershipHiltiAssetStatusValue}`}
      />
    )
  );
};

export const loanPurchaseOrderNumber = (data: IAssetDetailsData) =>
  data?.invoiceDetails?.purchaseOrderNumber && (
    <IconNormalBold
      id="pnlPurchaseOrderNumber"
      normalId="lblPurchaseOrderNumber"
      normalText={<Trans>{"assets:LOAN_PURCHASE_ORDER"}</Trans>}
      boldId="txtPurchaseOrderNumber"
      boldText={data?.invoiceDetails?.purchaseOrderNumber}
    />
  );

export const rentPurchaseOrderNumber = (data: IAssetDetailsData) =>
  data?.invoiceDetails?.purchaseOrderNumber && (
    <IconNormalBold
      id="pnlPurchaseOrderNumber"
      normalId="lblPurchaseOrderNumber"
      normalText={<Trans>{"assets:RENT_PURCHASE_ORDER"}</Trans>}
      boldId="txtPurchaseOrderNumber"
      boldText={data?.invoiceDetails?.purchaseOrderNumber}
    />
  );

export const loanRefNumber = (data: IAssetDetailsData) =>
  data?.fleetAssetDetails?.fleetOrganizationReferenceNumber && (
    <IconNormalBold
      id="pnlFleetRefNumber"
      normalId="lblFleetRefNumber"
      normalText={<Trans>{"assets:LOAN_REF_NUMBER"}</Trans>}
      boldId="txtFleetRefNumber"
      boldText={data?.fleetAssetDetails?.fleetOrganizationReferenceNumber}
    />
  );

export const rentRefNumber = (data: IAssetDetailsData) =>
  data?.fleetAssetDetails?.fleetOrganizationReferenceNumber && (
    <IconNormalBold
      id="pnlRentedRefNumber"
      normalId="lblRentedRefNumber"
      normalText={<Trans>{"assets:RENT_REF_NUMBER"}</Trans>}
      boldId="txtRentedRefNumber"
      boldText={data?.fleetAssetDetails?.fleetOrganizationReferenceNumber}
    />
  );

export const loanCostCenter = (data: IAssetDetailsData) =>
  data?.fleetAssetDetails?.fleetCostCenter && (
    <IconNormalBold
      id="pnlWarrantyExpDate"
      normalId="lblWarrantyExpDate"
      normalText={<Trans>{"assets:LOAN_COST_CENTER"}</Trans>}
      boldId="txtWarrantyExpDate"
      boldText={data?.fleetAssetDetails?.fleetCostCenter}
    />
  );

export const rentCostCenter = (data: IAssetDetailsData) =>
  data?.fleetAssetDetails?.fleetCostCenter && (
    <IconNormalBold
      id="pnlWarrantyExpDate"
      normalId="lblWarrantyExpDate"
      normalText={<Trans>{"assets:RENT_COST_CENTER"}</Trans>}
      boldId="txtWarrantyExpDate"
      boldText={data?.fleetAssetDetails?.fleetCostCenter}
    />
  );

export const loanInventoryNumber = (data: IAssetDetailsData) =>
  data?.fleetAssetDetails?.fleetInventoryNumber && (
    <IconNormalBold
      id="pnlLoanedInventoryNumber"
      normalId="lblLoanedInventoryNumber"
      normalText={<Trans>{"assets:LOAN_INVENTORY_NUMBER"}</Trans>}
      boldId="txtLoanedInventoryNumber"
      boldText={data?.fleetAssetDetails?.fleetInventoryNumber}
    />
  );

export const rentInventoryNumber = (data: IAssetDetailsData) =>
  data?.fleetAssetDetails?.fleetInventoryNumber && (
    <IconNormalBold
      id="pnlRentedInventoryNumber"
      normalId="lblRentedInventoryNumber"
      normalText={<Trans>{"assets:RENT_INVENTORY_NUMBER"}</Trans>}
      boldId="txtRentedInventoryNumber"
      boldText={data?.fleetAssetDetails?.fleetInventoryNumber}
    />
  );
