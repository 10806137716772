// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { storageGetItem, storageGetObject, storageSetItem } from "../utils/storageUtils";
declare global {
  interface Window {
    CHECK_INTERVAL: any;
    SESSION_INTERVAL: any;
  }
}
const DEFAULT_INTERVAL = 8;
const INTERVAL = window.CHECK_INTERVAL || DEFAULT_INTERVAL;
/**
 * @description AUtomatically logsout the user on validation the inactivity for 60 minutes of duration.
 */
export class SessionHandlerService {
  /**
   * @method initListener
   * @description Initializes document event listener for checking user interaction and maintaining the session.
   * @param refreshToken - Method passed through props to refresh the token.
   */
  initListener = () => {
    document.body.addEventListener("click", () => this.refreshTokenUserInteraction());
    document.body.addEventListener("onkeydown", () => this.refreshTokenUserInteraction());
    document.body.addEventListener("onkeyup", () => this.refreshTokenUserInteraction());
    document.body.addEventListener("onkeypress", () => this.refreshTokenUserInteraction());
  };

  /**
   * @method setInitialInterval
   * @description Initializes Interval to trigger refresh api every 59 minutes if user has not exceeded the configured maximum of idle time.
   * @param refreshToken - Method passed through props to refresh the token.
   */
  setInitialInterval = (refreshToken) => {
    const interval = parseInt(storageGetItem(appConstants.TIME_TO_REFRESH), 10) - Date.now();
    const intervalForRefresh =
      parseInt(storageGetObject(appConstants.TOKEN_RESPONSE)?.expires_in, 10) * 1000 - 60 * 1000;
    if (interval > 0) {
      setTimeout(() => {
        if (Date.now() < parseInt(storageGetItem(appConstants.TIMEOUT), 10)) {
          refreshToken();
        }
        window.SESSION_INTERVAL = setInterval(() => {
          if (Date.now() < parseInt(storageGetItem(appConstants.TIMEOUT), 10)) {
            refreshToken();
          }
        }, intervalForRefresh);
      }, interval);
    }
  };
  /**
   * @method refreshTokenUserInteraction
   * @description Listening to the document event, refreshTokenUserInteraction captures users activity on DOM and makes decision on user session.
   */
  refreshTokenUserInteraction = () => {
    const current = Date.now();
    if (Date.now() < parseInt(storageGetItem(appConstants.TIMEOUT), 10)) {
      storageSetItem(appConstants.TIMEOUT, (current + INTERVAL * appConstants.HOUR_INTO_MILLISECONDS).toString());
    }
  };
}
