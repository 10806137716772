import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M9.8,6.75615385 L11.1538462,8.11 L9.12307692,10.1407692 L11.1538462,12.1715385 L13.1846154,10.1407692 L14.5384615,11.4946154 L12.5076923,14.8792308 L9.12346154,14.8791538 L3.70769231,20.2946154 L1,17.5869231 L6.41546154,12.1711538 L6.41538462,8.78692308 L9.8,6.75615385 Z M18.9384615,3.71 L18.9384615,4.72538462 L17.9226923,4.725 L17.9226923,8.786 L21.9836923,8.786 L21.9846154,7.77153846 L23,7.77153846 L23,9.80230769 L16.9076923,9.80230769 L16.9076923,3.71 L18.9384615,3.71 Z M23,3.71 L23,6.75615385 L21.9846154,6.75615385 L21.9836923,5.439 L19.7265446,7.69645909 L18.9384615,6.90837607 L21.1216923,4.725 L19.9538462,4.72538462 L19.9538462,3.71 L23,3.71 Z" />
    </g>
  </svg>
);

export default SVG;
