import React from "react";
import styled from "styled-components";
import { CreatableAutoComplete, LoaderElement } from "../../../elements/";
import IGridDropdownCell from "./iGridDropdownCell";
import { isFunction, isEqual } from "../../../helpers/helperLodash";
import { HelperLodash } from "../../../helpers";

const DropdownContainer = styled.div`
  div[id^="gridDropdown"] > div {
    height: auto;
    width: 100%;
  }
  .dropDownSingleValue {
    input[type="text"] {
      max-width: 290px !important;
    }
    + div {
      background: ${(props) => props.theme.colors.base.heavyConcrete20};
      position: absolute;
      top: 0px;
      right: 0px;
      width: 44px;
      height: 44px !important;
      div {
        padding: 12px 14px 14px 0;
      }
      .imgarrow {
        padding-left: 14px;
      }
    }
  }
  .field-wrapper {
    margin-bottom: 0;
  }
`;

export class GridDropdownCell extends React.Component<IGridDropdownCell> {
  static defaultProps = {
    showDropDown: true,
  };

  shouldComponentUpdate(prevProps) {
    const {
      dependencies: { dropDownData = {} },
    } = this.props;
    if (prevProps.dependencies && !isEqual(prevProps.dependencies.dropDownData, dropDownData)) {
      return true;
    }
    return false;
  }

  // This is used to stop the event propagation of dropdown to stop the row click of gridView.
  onDropdownContainerClick = (event) => {
    event.stopPropagation();
  };

  handleOnSelectChange = (val: any, ddName) => {
    this.props.dependencies.handleOnSelectChange(val, ddName);
  };
  render() {
    const { data, idKey, dependencies } = this.props;
    const {
      creatableAutoCompleteProps,
      ComboBoxRequest,
      liveSearchProps,
      t,
      dropDownData,
      isDropDownDefaultOptions,
      showDisabledOptionWithTooltip,
      required,
    } = dependencies;
    const { attributes, validations } = this.props.column;
    const name = HelperLodash.get(data, idKey);
    const uniqueName = `${attributes.namePrefix}${name}`;
    const ddName = `${creatableAutoCompleteProps.id}_${name}`;
    let validationsArray;
    if (validations) {
      validationsArray = validations.map((validationName) => {
        return HelperLodash.get(dependencies, validationName);
      });
    }
    const getDropDownVisibility = dependencies[attributes.getDropDownVisibility];
    const showDropDown = isFunction(getDropDownVisibility) ? getDropDownVisibility(data) : this.props.showDropDown;

    return showDropDown ? (
      <DropdownContainer
        id={`${uniqueName}DdContainer`}
        onClick={this.onDropdownContainerClick}
        className="drop-down-container"
      >
        <CreatableAutoComplete
          autoComplete="on"
          className="form-control"
          id={`${creatableAutoCompleteProps.id}_${name}`}
          isFormControl={true}
          name={`${creatableAutoCompleteProps.name}_${name}`}
          labelField={`${creatableAutoCompleteProps.labelField}_${name}`}
          icon="search"
          loadingMessage={() => <LoaderElement />}
          placeholder={t(ComboBoxRequest.placeHolderText)}
          validation={validationsArray}
          valueKey={creatableAutoCompleteProps.valueKey}
          valueId={creatableAutoCompleteProps.valueId}
          liveSearch={liveSearchProps}
          t={t}
          isCreatable={false}
          noOptionsMessage={() => creatableAutoCompleteProps.noOptionsMessage}
          onSelectChange={(val) => this.handleOnSelectChange(val, ddName)}
          footerControl={attributes.control ? dependencies[attributes.control] : null}
          defaultOptions={isDropDownDefaultOptions}
          data={dropDownData}
          disabledOptionWithTooltip={showDisabledOptionWithTooltip}
          required={required}
        />
      </DropdownContainer>
    ) : null;
  }
}

export default GridDropdownCell;
