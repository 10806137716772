import React from "react";
import AsyncSelect from "react-select/lib/Async";
// @ts-ignore
import { Field } from "redux-form";
import styled from "styled-components";
import { debounce } from "../../helpers/helperLodash";
import { getDebounceValue } from "../../helpers/sharedUtils";
import { colorPalette } from "../../materials";
import { default as LabelField } from "../Field/field";
import IAutoSearchProps from "./iAutoSearch";
// @ts-ignore
import iconSearch from "./search.svg";

/**
 * @description - Styling the "AsyncSelect" component to match the look n feel as per out need.
 */
export const StyledAutoSearch = styled(AsyncSelect)`
  width: 100%;
  > div.asyncAS__control {
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => (props["data-error"] ? colorPalette.hiltiRedError : colorPalette.heavyConcrete60)};
    border-radius: 0;
    cursor: text;
    height: 48px;
    box-shadow: none;
  }
  > div.asyncAS__control:before {
    content: "";
    height: ${(props) => (props.height ? props.height : "48px")};
    width: ${(props) => (props.width ? props.width : "48px")};
    background: url(${(props: IAutoSearchProps) => props.searchIcon}) no-repeat;
    display: inline-block;
    vertical-align: middle;
    background-position: center;
  }
  > div.asyncAS__control > div.asyncAS__value-container {
    padding: 0;
  }
  > div.asyncAS__control > div.asyncAS__value-container > div.asyncAS__placeholder {
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    color: ${colorPalette.steel40};
  }
  > div.asyncAS__control > div.asyncAS__value-container > div.asyncAS__single-value {
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    color: ${colorPalette.steel};
  }
  > div.asyncAS__control > div.asyncAS__value-container > div.asyncAS__input > input {
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    color: ${colorPalette.steel};
  }
  > div.asyncAS__control > div.asyncAS__indicators {
    display: none;
  }
  > div.asyncAS__menu {
    margin: 0;
    box-shadow:
      0 2px 12px 0 ${colorPalette.steel},
      inset 0 1px 0 0 ${colorPalette.heavyConcrete};
    border-radius: 0;
  }
  > div.asyncAS__menu > div.asyncAS__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option {
    line-height: 32px;
    padding: 8px 16px;
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option:hover {
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    background: ${colorPalette.offWhite};
    color: ${colorPalette.black};
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option--is-focused {
    background: ${colorPalette.offWhite};
    color: ${colorPalette.black};
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option--is-selected {
    color: ${colorPalette.black};
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    background: ${colorPalette.offWhite};
  }
  > div.asyncAS__menu > div.asyncAS__menu-list > div.asyncAS__option:empty {
    display: none;
  }
`;
let debounceStore = null;
export function handleLoadOptions(val, callback, props) {
  if (debounceStore) {
    debounceStore.cancel();
  }
  debounceStore = debounce(props.loadOptions, getDebounceValue());
  debounceStore(val, callback);
}

const hasDataError = (props) => props.meta && props.meta.error && props.meta.touched;

const RenderAutoSearch = (props) => (
  <StyledAutoSearch
    {...props}
    searchIcon={iconSearch}
    id={props.id}
    classNamePrefix="asyncAS"
    loadOptions={(val, callback) => handleLoadOptions(val, callback, props)}
    defaultOptions={props.defaultOptions || [{}]}
    className="asyncASContainer"
    noOptionsMessage={() => props.noResultMessage}
    placeholder={props.placeholder}
    loadingMessage={() => props.loadinResultMessage}
    isClearable={props.isClearable}
    isMulti={props.isMulti}
    value={props.value}
    components={props.components}
    styles={props.styles}
    error={props.meta && props.meta.error}
    menuIsOpen={props.menuIsOpen}
    onBlur={props.onBlur}
    onChange={(val) => {
      changeHandler(props, val);
      if (props.input) {
        //if formControl={false} input is not available
        props.input.onChange(val);
      }
    }}
    data-error={hasDataError(props)}
  />
);

const FormAutoSearch = (props) => (
  <LabelField
    name={props.input.name}
    htmlFor={props.input.name}
    label={props.label}
    error={props.meta.error}
    touched={props.meta.touched}
    warning={props.meta.warning}
    required={props.required || false}
  >
    <RenderAutoSearch {...props} input={props.input} name={props.input.name} value={props.input.value} />
  </LabelField>
);
/**
 * @description - This is the auto search component that is using "AsyncSelect" of "react-select" library.
 * It is used to search the list of results using the promise API and show it as auto-suggestions.
 */
class AutoSearch extends React.Component<IAutoSearchProps> {
  render() {
    const { placeholder, name, label, validation, disabled, readOnly, selectedValue } = this.props;
    return !this.props.isFormControl ? (
      <RenderAutoSearch value={selectedValue} {...this.props} />
    ) : (
      <Field
        name={name}
        label={label}
        component={FormAutoSearch}
        validate={validation}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        {...this.props}
      />
    );
  }
}
export function changeHandler(props: any, val: any) {
  if (props.onSelectChange) {
    props.onSelectChange(val);
  } else {
    props.input.onChange(val.id);
  }
}

export default AutoSearch;
