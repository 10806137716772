import { ISagaModule } from "redux-dynamic-modules-saga";
import headerCountReducer from "./headerCountReducer";
import headerCountSaga from "./headerCountSaga";
import IHeaderCountModuleState from "./iHeaderCountModuleState";

const headerCountModule: ISagaModule<IHeaderCountModuleState> = {
  id: "headerCountModule",
  reducerMap: {
    HeaderCount: headerCountReducer,
  },
  sagas: [headerCountSaga],
};

export default headerCountModule;
