import {
  companyModule,
  contactModule,
  reportsModule,
  userSettingModule,
  workersModule,
} from "../common/loadableModule";

export default [
  {
    component: reportsModule,
    exact: true,
    path: "/",
  },
  {
    component: reportsModule,
    path: "/reports",
  },
  {
    component: workersModule,
    path: "/workers",
  },
  {
    component: contactModule,
    path: "/contact",
  },
  {
    component: companyModule,
    path: "/company",
  },
  {
    component: userSettingModule,
    path: "/userSetting",
  },
  {
    component: companyModule,
    path: "/assignedTenants",
  },
];
