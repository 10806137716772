import React from "react";
import "react-input-range/lib/css/index.css";
import { Field } from "redux-form";
import IdleRangeControl from "./idleRangeControl";
import { IDataRangeContainerProps } from "./iDataRange";

const IdleRangeContainer = (props: IDataRangeContainerProps) => {
  const onSelectionChange = (value) => {
    return props.onSelectionChange && props.onSelectionChange(value);
  };

  const handleRangeDisable = (): boolean => {
    let disableRangeContainer = false;
    const { rangeData } = props;
    const rangeMaxMinDiff = rangeData && Math.ceil(rangeData.max) - Math.ceil(rangeData.min);

    if (rangeMaxMinDiff > 1) {
      disableRangeContainer = true;
    }
    return disableRangeContainer;
  };
  const { clearButtonText, id, name } = props;

  return props.isFormControl ? (
    <Field
      id={id || name}
      name={name}
      component={IdleRangeControl}
      className="multiSelectControl"
      clearButtonText={clearButtonText}
      handleRangeDisable={handleRangeDisable()}
      {...props}
    />
  ) : (
    <IdleRangeControl {...props} onSelectionChange={onSelectionChange} />
  );
};

export default IdleRangeContainer;
