import Gateway from "../gateway/gateway";
import { getAssetMgmtEndpoint } from "../utils/apiUtils";

/**
 * Method to Make API call for submit cancletransfer
 * @param filters : used to apply filters
 */
export const cancelLastTransfer = (detail, transferAssetType): Promise<any> => {
  const url = getAssetMgmtEndpoint(
    `/assets/${detail.payload.id}/transfers/${detail.payload.transferId}/cancel?transferOrigin=WEB&transferAssetType=${transferAssetType}`,
  );
  return Gateway.put(url, null, detail.formData, null);
};

export const getTransferHistoryList = async (id, queryObj, transferAssetType): Promise<any> => {
  const url = getAssetMgmtEndpoint(`/assets/${id}/transfers?transferAssetType=${transferAssetType}`);
  return Gateway.get(url, { ...queryObj }, null, null, false);
};
