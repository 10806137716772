import { IconName } from "am-web-ui-shared/components";
import { AssetSubType, AssetType } from "../../../../../utils/enums/assetEnum";
import { FEATURES } from "../../../../../../featureFlagManager/featureConfig";
import SingletonFeatureManager from "../../../../../../featureFlagManager/featureFlagManager";
import { IAssetDetailsData, ICodeValue } from "../../../../../models/asset/assetModel";
import { ISmartInventory } from "../../../../../models/activeTracking/SmartInventory";
import { GatewayAssetList, StatsType } from "./iSmartInventoryAssetsProps";
import { IErrorObject } from "../../../../../models/activeTracking/iGatewayAssets";

import { GatewayStatus } from "../../../../../utils/enums/alertGroupEnum";
import { getGatewayOfflineStatusCode } from "../../../../../utils/configUtil";
import { HelperLodash } from "am-web-ui-shared/helpers";
import ApplicationConstants from "TARGET_BUILD/common/applicationConstants";
import IInventory from "../models/inventory";
import { inventoryCode } from "TARGET_BUILD/common/modules/asset/components/assetDetails/mainSection/detailComponents";

const childGatewayStatusMap = {
  ONLINE: "activeTracking:GATEWAY_ONLINE",
  OFFLINE: "activeTracking:GATEWAY_OFFLINE",
};

export const isVanGatewaysSmartInventoryEnabled = (): boolean =>
  SingletonFeatureManager.isFeatureEnabled(FEATURES.WEB_VAN_GATEWAYS_SMART_INVENTORY);

export const isHEGatewaysSmartInventoryEnabled = (): boolean =>
  SingletonFeatureManager.isFeatureEnabled(FEATURES.ASSETS_HEAVY_EQUIPMENT_SMART_INVENTORY);

export const isOfflineInventoryEnabled = (): boolean =>
  SingletonFeatureManager.isFeatureEnabled(FEATURES.PAT_OFFLINE_INVENTORY);

export const getHeaderText = () => "locations:SMART_INVENTORY";

export const getSmartInventoryIcon = () =>
  isVanGatewaysSmartInventoryEnabled() ? IconName.SmartInventory : IconName.ShelfBox;

export const getAssetSmartInventoryHeaderText = (assetSubType: AssetSubType) =>
  (isVanGatewaysSmartInventoryEnabled() && assetSubType === AssetSubType.Van) ||
  (isHEGatewaysSmartInventoryEnabled() && assetSubType === AssetSubType.HeavyEquipment)
    ? "locations:SMART_INVENTORY"
    : "activeTracking:STORAGE_ASSET_LIST_VIEW_HEADER_SMART";

export const isChildGatewayStatusVisible = (assetDetails: Partial<IAssetDetailsData>) => {
  const subTypeCode = assetDetails?.assetSubType?.code;
  return isChildGatewayStatusValid(assetDetails) && subTypeCode === AssetSubType.Container;
};

type Asset = { assetType: { code: AssetType }; assetSubType?: { code: AssetSubType } } & {
  assetTypeResponse: { code: AssetType };
  assetSubType?: { code: AssetSubType };
  gatewayStatus: ICodeValue;
};

// show gateway status for van and rigado (not for telematic gateway)
export const getGatewayStatus = (data: Partial<Asset> = {}) => {
  const type = data?.assetType?.code || data?.assetTypeResponse?.code; //list & details response are differnet
  const subType = data?.assetSubType?.code;
  if (type === AssetType.Gateway && subType === AssetSubType.Stationary) {
    return data.gatewayStatus;
  }
  return null;
};

export const stripFieldInFilter = (filter: string, field: string) => {
  if (filter) {
    const filters = HelperLodash.remove(filter.split("&"), (item: string) => !item.includes(field));
    return filters?.join("&");
  }
  return "";
};

export const isChildGatewayStatusValid = (assetDetails: Partial<IAssetDetailsData>) => {
  const childGatewayStatus = assetDetails?.childGatewayStatus?.code;
  return childGatewayStatus === GatewayStatus.ONLINE || childGatewayStatus === GatewayStatus.OFFLINE;
};

export const isSmartInventoryVisible = (assetDetails: Partial<IAssetDetailsData>): boolean => {
  const subTypeCode = assetDetails?.assetSubType?.code;
  return (
    isChildGatewayStatusValid(assetDetails) &&
    (subTypeCode === AssetSubType.Container ||
      (isVanGatewaysSmartInventoryEnabled() && subTypeCode === AssetSubType.Van) ||
      (isHEGatewaysSmartInventoryEnabled() && subTypeCode === AssetSubType.HeavyEquipment))
  );
};

export const isGatewayOffline = (smartInventory: ISmartInventory | GatewayAssetList | { error: IErrorObject }) =>
  smartInventory?.error?.code === getGatewayOfflineStatusCode();

export const getChildGatewayStatus = (data: Partial<IAssetDetailsData> = {}) =>
  childGatewayStatusMap[data?.childGatewayStatus?.code];

export const getSectionName = (section: string) => {
  const key = section?.substring(0, section.length - ApplicationConstants.LENGTH.LEN2);
  return StatsType[key && HelperLodash.constantCase(key)];
};

export const getSectionAssetIds = (section: string, inventory: IInventory) => {
  if (section && inventory) {
    const clone = { ...inventory };
    switch (section) {
      case "expectedAssetCountId":
        return [...(inventory.foundAssetIds || []), ...(inventory.notFoundAssetIds || [])];
      case "foundAssetCountId":
        return clone.foundAssetIds;
      case "notFoundAssetCountId":
        return clone.notFoundAssetIds;
      case "unexpectedAssetCountId":
        return clone.unexpectedAssetIds;
      default:
        return [];
    }
  }
  return [];
};
