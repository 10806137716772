import { GridFieldsEnum, CellTypes } from "./gridEnum";
import IBindProperties from "./iBindProperties";
import IGridColumnSetting from "./iGridSetting";
import { HelperLodash } from "../../helpers";
import ApplicationConstants from "../../constant/applicationConstants";

/**
 * @description Function to get element id of grid element.
 * @param {number} rowIndex
 * @param {number} columnIndex
 * @param {number} index
 * @param {IBindProperties} bindProperty
 */
export const getElementId = (rowIndex: number, columnIndex: number, index: number, bindProperty: IBindProperties) => {
  const splittedElements = bindProperty.properties[0].name && bindProperty.properties[0].name.split(".");
  const elementID = combineFirstandLastProperty(splittedElements);
  return `${elementID}${rowIndex + 1}${columnIndex + 1}${index + 1}`;
};

/**
 * @description Function to combine first and last propert in array.
 */
const combineFirstandLastProperty = (arr) => {
  return arr && (arr.length > 1 ? arr[0] + upperFirst(arr[arr.length - 1]) : arr[0]);
};

/**
 * @description Function to create array of fields to get data in list api.
 * @param {IGridColumnSetting[]} columns
 * @param {string[]} defaultQueryFields
 */
export const createFieldsQuery = (columns: IGridColumnSetting[], defaultQueryFields: string[]) => {
  const list = getGridSettingsBindProperties(columns);
  return uniqueArray(compact([...list, ...defaultQueryFields]));
};

/**
 * @description Function to get feld value.
 * @param {} data - data
 * @param {string} key - key of the data
 * @param {} dependencyFunctions - Many dependency functions.
 * @param {string} dependencyKey - key of the function.
 */
export const getFieldValue = (data: any, key: string, dependencyFunctions?: any, dependencyKey?: string) => {
  const gridEnumKey = HelperLodash.get(GridFieldsEnum, key);
  const property = gridEnumKey ? gridEnumKey : key;
  let fieldValue = get(data, property);
  if (fieldValue && typeof fieldValue === "object" && fieldValue.value) {
    fieldValue = fieldValue.value;
  }

  if (dependencyFunctions && dependencyKey) {
    fieldValue = dependencyResolver(dependencyFunctions, dependencyKey)(fieldValue, data);
  }
  if (HelperLodash.get(dependencyFunctions, ["propertyDependencies", key])) {
    fieldValue = dependencyResolver(dependencyFunctions.propertyDependencies, key)(fieldValue, data);
  }

  return fieldValue;
};

/**
 * @description Function to return dependency function among many functions.
 * @param {} dependencyFunctions - Many dependency functions.
 * @param {string} dependencyKey - key of the function.
 */
export const dependencyResolver = (dependencyFunctions?: any, dependencyKey?: string) => {
  return HelperLodash.get(dependencyFunctions, dependencyKey);
};

/**
 * @description Function to return dependency function among many functions.
 * @param {} dependencyFunctions - Many dependency functions.
 * @param {string} dependencyKey - key of the function.
 */
export const getPropertyLabel = (dependencyFunctions: any, propertyName: string) => {
  return dependencyResolver(dependencyFunctions, "getPropertyLabel")(propertyName);
};

export const createPropertyObjects = (properties) => {
  const gridProperties = [];
  if (properties) {
    properties.forEach((item) => {
      const name = { name: item };
      gridProperties.push(name);
    });
  }
  return gridProperties;
};

/**
 * @description - Function to get object property value from list of objects.
 * @param list (Array) - List of objects.
 * @param value (string) - Value of object key to match.
 * @param key1 (string) - Object property to match with passed value. Default property is code.
 * @param key2 (string) - Object property to return value. Default property is value.
 */
export const getListObjectValue = (list, value: string, key1 = "code", key2 = "value") => {
  const obj = list && list.find((column: object) => HelperLodash.get(column, key1) === value);
  return obj && HelperLodash.get(obj, key2);
};

/**
 * @description - Function to inject bindProperties in grid settings.
 * @param {IGridColumnSetting[]} columns - Grid settings columns.
 * @param {string[]} properties - Properties to inject in grid settings bindProperties.
 */
export const injectBindProperties = (columns: IGridColumnSetting[], properties: string[] = []) => {
  const columnsClone = deepCloneArray(columns);
  return columnsClone.map((column, index) => {
    const bindProperties = [];
    if (!column.hasOwnProperty("bindProperties")) {
      for (let i = index; i < index + ApplicationConstants.NUMBER.NUM2; i++) {
        const gridProperties = createPropertyObjects(getSplittedCombinedProperties(properties[index + i]));
        const bindProperty = {
          key: properties[index + i],
          properties: gridProperties,
        };
        bindProperties.push(bindProperty);
      }
    } else {
      column.bindProperties.forEach((bindProperty, propertyIndex) => {
        if (!bindProperty.hasOwnProperty("properties")) {
          const gridProperties = createPropertyObjects(
            getSplittedCombinedProperties(properties[index + propertyIndex]),
          );
          bindProperty.key = properties[index + propertyIndex];
          bindProperty.properties = gridProperties;
          bindProperties.push(bindProperty);
        } else {
          bindProperties.push(bindProperty);
        }
      });
    }

    return { ...column, bindProperties };
  });
};

/**
 * @description Function to create array of fields from grid columns complex object.
 * @param {IGridColumnSetting[]} columns - Grid setting columns.
 */
export const getGridSettingsBindProperties = (columns: IGridColumnSetting[]) => {
  const properties = [];
  columns.forEach((item) => {
    if (item.isSortable) {
      item.bindProperties.forEach((property) => {
        property.properties.forEach((name) => {
          properties.push(name.name);
        });
      });
    }
  });
  return properties;
};

/**
 * @description Function to create array of fields from grid columns complex object.
 * @param {IGridColumnSetting[]} columns - Grid setting columns.
 */
export const getGridSettingsBindPropertyKey = (columns: IGridColumnSetting[] = []) => {
  const properties = [];
  columns.forEach((item) => {
    if (item.isSortable) {
      item.bindProperties.forEach((property) => {
        properties.push(property.key);
      });
    }
  });
  return properties;
};

export const getSplittedCombinedProperties = (property: string) => {
  return property ? property.split("/") : [null];
};

/**
 * @description Creates an array with all falsey values removed.
 * @param data The array to compact.
 */
const compact = (data) => {
  return HelperLodash.compact(data);
};

/**
 * @description - Gets the value at path of object.
 * @param object (object) - The object to query.
 * @param path (Array|string) - The path of the property to get.
 */

const get = (object, path) => {
  return HelperLodash.get(object, path);
};

/**
 * @description Converts the first character of string to upper case.
 * @param label The string to convert.
 */
const upperFirst = (label: string) => {
  return HelperLodash.upperFirst(label);
};

/**
 * @description To deep cloning of array with objects
 * @param {array} array with nested object.
 */

const deepCloneArray = (array) => {
  return HelperLodash.cloneDeep(array);
};

/**
 * @description To filter array with unique value
 * @param {array} array with nested object.
 */
const uniqueArray = (array) => {
  return HelperLodash.uniqWith(array, HelperLodash.isEqual);
};

export const getHeaderLabel = (module: string, key: string, t: any) => {
  return key && t(`${module}:gridHeader:${key.toUpperCase()}`);
};

export const getColumnFirstProperty = (column) => {
  return column.bindProperties[0].properties[0];
};

export const getColumnSecondProperty = (column) => {
  return column.bindProperties[1].properties[0];
};

export const getPrefixComponent = (
  data: object,
  key: string,
  dependencyFunctions: { prefixComponentDependencies },
  index: number,
  bindProperty?,
) => {
  if (HelperLodash.get(dependencyFunctions, ["prefixComponentDependencies", key])) {
    return dependencyResolver(dependencyFunctions.prefixComponentDependencies, key)(data, key, index, bindProperty);
  }
  return null;
};

export const getCellClassName = (column) => {
  const classes = ["custom-cell"];
  switch (column.type) {
    case CellTypes.ImageFieldCombination:
    case CellTypes.FieldCombination:
    case CellTypes.Text:
      classes.push("row-cell");
      break;
    default:
      break;
  }

  return classes.join(" ");
};

export const gridUtils = {
  createFieldsQuery,
  createPropertyObjects,
  dependencyResolver,
  getCellClassName,
  getColumnFirstProperty,
  getColumnSecondProperty,
  getElementId,
  getFieldValue,
  getGridSettingsBindProperties,
  getGridSettingsBindPropertyKey,
  getHeaderLabel,
  getListObjectValue,
  getSplittedCombinedProperties,
  injectBindProperties,
};
export default gridUtils;
