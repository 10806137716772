import Gateway from "../gateway/gateway";
import {
  getHiltiCloudTechnicalUsersEndpoint,
  getHiltiCloudRepairOrdersEndpoint,
  getHiltiCloudWarrantyExchangeEndpoint,
} from "../utils/apiUtils";
import { IErrorResponse } from "../models/iApiError";

interface IWarrantyExchangeStatus {
  readonly status: string;
  readonly lock_command?: any;
}

interface ITechnicaluserPassword {
  password: string;
}

export default class HiltiCloudApi {
  static getWarrantyExchangeStatus = async (materialNumber, serialNumber): Promise<IWarrantyExchangeStatus> => {
    const url = getHiltiCloudWarrantyExchangeEndpoint(`/status/TID-${materialNumber}-${serialNumber}`);
    return Gateway.get(url, null, null, null, false) as unknown as IWarrantyExchangeStatus;
  };

  static searchRepairOrder = async (body): Promise<any> => {
    const url = getHiltiCloudRepairOrdersEndpoint(`/repair-orders/search`);
    return Gateway.post(url, null, body, null, {}, true, false) as unknown as any;
  };
}
