import { ITransfer } from "./iTransfer";

export default class TransferModel {
  cancelledTransferId: number;
  transferId: number;
  transferDate: string;
  applicationType: string;
  applicationTypeResponse: {
    code: string;
    value: string;
  };
  transferType: string;
  toWorkerDetails: {
    workerId: number;
    workerName: string;
  };
  fromWorkerDetails: {
    workerId: number;
    workerName: string;
  };
  toLocationDetails: {
    locationId: number;
    locationName: string;
  };
  fromLocationDetails: {
    locationId: number;
    locationName: string;
  };
  fromStorageAssetDetails: {
    assetId: number;
    assetName: string;
  };
  toStorageAssetDetails: {
    assetId: number;
    assetName: string;
  };
  notes: string;
  quantity: number;
  transferByDetails: {
    employeeId: number;
    userName: string;
  };
  taskCode: string;
  sourceAllocation: boolean;
  allocationMerged: boolean;
  unitName: string;
  entityActionActorType: {
    code: string;
    value: string;
  };
  entityTransferStatus: {
    code: string;
    value: string;
  };
  entityActorDetails: {
    name: string;
  };
  entityActionDateTime: string;
  toAssetSubType?: string;
  fromAssetSubType?: string;

  constructor(response: ITransfer) {
    this.cancelledTransferId = response.cancelledTransferId;
    this.transferId = response.transferId;
    this.transferDate = response.transferDate;
    this.applicationType = response.applicationType;
    this.applicationTypeResponse = response.applicationTypeResponse;
    this.transferType = response.transferType;
    this.toWorkerDetails = {
      workerId: response.toWorkerDetails.workerId,
      workerName: response.toWorkerDetails.workerName,
    };
    this.fromWorkerDetails = {
      workerId: response.fromWorkerDetails.workerId,
      workerName: response.fromWorkerDetails.workerName,
    };
    this.fromLocationDetails = {
      locationId: response.fromLocationDetails.locationId,
      locationName: response.fromLocationDetails.locationName,
    };
    this.toLocationDetails = {
      locationId: response.toLocationDetails.locationId,
      locationName: response.toLocationDetails.locationName,
    };
    this.fromStorageAssetDetails = {
      assetId: response.fromStorageAssetDetails && response.fromStorageAssetDetails.assetId,
      assetName: response.fromStorageAssetDetails && response.fromStorageAssetDetails.assetName,
    };
    this.toStorageAssetDetails = {
      assetId: response.toStorageAssetDetails && response.toStorageAssetDetails.assetId,
      assetName: response.toStorageAssetDetails && response.toStorageAssetDetails.assetName,
    };
    this.transferByDetails = {
      employeeId: response.transferByDetails.employeeId,
      userName: response.transferByDetails.userName,
    };
    this.notes = response.notes;
    this.quantity = response.quantity;
    this.taskCode = response.taskCode;
    this.sourceAllocation = response.sourceAllocation;
    this.allocationMerged = response.allocationMerged;
    this.unitName = response.unitName;
    this.entityActionActorType = response.entityActionActorType;
    this.entityTransferStatus = response.entityTransferStatus;
    this.entityActorDetails = response.entityActorDetails;
    this.entityActionDateTime = response.entityActionDateTime;
    this.toAssetSubType = response.toAssetSubType;
    this.fromAssetSubType = response.fromAssetSubType;
  }
}
