import styled from "styled-components";

import React from "react";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import Prompt from "../prompt/prompt";
import IPartialeDeletePopupProps from "./iPartialDeletePopupProps";

const SuccessContainer = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  padding: 16px 0;
  display: flex;
`;

const FailureContainer = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  margin: 16px 0 8px 0;
  display: flex;
  align-items: center;
`;

const Message = styled.span`
  margin-left: 8px;
`;

const FailureAssetsInfoList = styled.ul`
  color: ${(props) => props.theme.colors.base.whiteFaded};
  font-size: ${(props) => props.theme.fontSize.base};
  overflow: auto;
  padding-left: 32px;
  max-height: 200px;
  li {
    line-height: 25px;
  }
`;

const handleCloseButton = (props) => {
  props.clearMultiDeleteResponse();
  props.refreshList();
};

const constructPopup = (response, handlePopupConfirm, dependencies, t) => {
  const failedItems = response.invalidResponse || {};
  const { successMessage, failureMessage, failureList, header } = dependencies;
  return (
    <Prompt
      okLabel={t("common:OK")}
      isHeader={true}
      header={header()}
      showCancel={false}
      handleConfirm={handlePopupConfirm}
      handleClose={handlePopupConfirm}
      crossIcon={true}
    >
      {successMessage() && (
        <SuccessContainer>
          <SquareIcon name={IconName.Feedback} className="success" />
          <Message>{dependencies.successMessage()}</Message>
        </SuccessContainer>
      )}
      {failedItems && failedItems.length > 0 && (
        <>
          <FailureContainer>
            <SquareIcon name={IconName.Alert} className="red-icon" />
            <Message>{failureMessage()}</Message>
          </FailureContainer>
          <FailureAssetsInfoList>{failureList()}</FailureAssetsInfoList>
        </>
      )}
    </Prompt>
  );
};

const PartialDeletePopup = (props: IPartialeDeletePopupProps) => {
  const { response, dependencies, t } = props;
  if (!response || !response.invalidResponse) {
    return <></>;
  }
  return constructPopup(response, () => handleCloseButton(props), dependencies, t);
};

export default PartialDeletePopup;
