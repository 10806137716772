import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M14.9899187,14.0121996 L20,15.9965149 L20,20 L4,20 L4,16 L8.9965099,14.0121996 L14.9899187,14.0121996 Z M12,4 C14.209139,4 16,5.790861 16,8 C16,10.209139 14.209139,12 12,12 C9.790861,12 8,10.209139 8,8 C8,5.790861 9.790861,4 12,4 Z" />
    </g>
  </svg>
);

export default SVG;
