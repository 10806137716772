import React from "react";
const SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            ".cls-1{fill:#d0021b;}.cls-2{fill:#ee929b;}.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7{fill-rule:evenodd;}.cls-3{fill:#f3aeb4;}.cls-4{fill:#a92822;}.cls-5{fill:#bf2325;}.cls-6{fill:#dd2535;}.cls-7{fill:#ea717d;}.cls-8{fill:#fff;}",
        }}
      />
    </defs>
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <rect className="cls-1" x={32} y="31.74" width="192.25" height="192.25" />
        <polygon
          className="cls-2"
          points="13.71 173.71 0 182.86 0 205.71 22.86 205.71 22.86 50.29 0 50.29 0 73.14 13.71 82.29 13.71 173.71"
        />
        <polygon
          className="cls-3"
          points="187.43 0.13 173.71 13.84 82.29 13.84 68.57 0 18.29 9.14 0 50.29 22.86 50.29 32 32.13 224 32 233.14 50.29 256 50.29 237.71 9.14 187.43 0.13"
        />
        <polygon
          className="cls-4"
          points="187.43 255.87 173.71 242.16 82.29 242.16 68.57 256 18.29 246.86 0 205.71 22.86 205.71 32 223.87 224 224 233.14 205.71 256 205.71 237.71 246.86 187.43 255.87"
        />
        <polygon className="cls-5" points="32 224 22.86 205.71 22.86 50.16 32 32 32 224" />
        <polygon
          className="cls-6"
          points="242.29 82.29 256 73.14 256 50.29 233.14 50.29 233.14 205.71 256 205.71 256 182.86 242.29 173.71 242.29 82.29"
        />
        <polygon className="cls-7" points="224 32 233.14 50.29 233.14 205.84 224 224 224 32" />
        <polygon
          className="cls-8"
          points="66.8 152.81 45.31 140.53 45.33 115.71 66.83 103.19 88.31 115.48 88.3 140.29 66.8 152.81"
        />
        <polygon
          className="cls-8"
          points="128 117.66 106.51 105.37 106.53 80.56 128.03 68.04 149.52 80.33 149.5 105.14 128 117.66"
        />
        <polygon
          className="cls-8"
          points="128 187.96 106.51 175.67 106.53 150.86 128.03 138.34 149.52 150.63 149.5 175.44 128 187.96"
        />
        <polygon
          className="cls-8"
          points="189.2 152.81 167.71 140.53 167.73 115.71 189.23 103.19 210.72 115.48 210.7 140.29 189.2 152.81"
        />
        <rect className="cls-8" x="120.56" y="96.17" width="14.89" height="63.8" />{" "}
        <rect
          className="cls-8"
          x="90.54"
          y="114.08"
          width="14.89"
          height="63.8"
          transform="matrix(0.5, -0.87, 0.87, 0.5, -77.38, 158.5)"
        />
        <rect
          className="cls-8"
          x="151.8"
          y="78.94"
          width="14.89"
          height="63.8"
          transform="translate(-16.06 193.99) rotate(-60.22)"
        />
      </g>
    </g>
  </svg>
);
export default SVG;
