import axios, { AxiosRequestConfig } from "axios";
import JSONbig from "json-bigint";
import { captureException } from "@sentry/react";
import { API_TIMEOUT, appConstants } from "TARGET_BUILD/config";
import { requestFinished, requestInitiated } from "../actions/activityAction";
import { IErrorResponse } from "../models/iApiError";
import { IAPIResponse } from "../models/iApiResponse";
import { storageClearAll, storageGetItem } from "../utils/storageUtils";
import { paramsSerializer } from "./paramsSerializer";
import { getSentryDsnKey } from "../utils/configUtil";

const apiState = {
  STATE_200: 200,
  STATE_201: 201,
  STATE_204: 204,
  STATE_206: 206,
  STATE_207: 207,
  STATE_401: 401,
};

export type CallParams = Parameters<typeof Gateway.call>;

export type GetParams = Parameters<typeof Gateway.get>;

export type PostParams = Parameters<typeof Gateway.post>;

export type PutParams = Parameters<typeof Gateway.put>;

export type PatchParams = Parameters<typeof Gateway.patch>;

export type DeleteParams = Parameters<typeof Gateway.delete>;

export type GetUnauthorizeParams = Parameters<typeof Gateway.getUnAuthorize>;

export type AsyncResponse<T = IAPIResponse> = Promise<T | IErrorResponse>;

const SENTRY_DSN = getSentryDsnKey();

class Gateway {
  static getAxiosConfig = (): AxiosRequestConfig => ({
    transformResponse: [(data) => JSONbig.parse(data)],
  });

  static async call<TResponse = IAPIResponse>(
    method,
    url,
    params,
    body,
    additionalHeaders,
    blob,
    config = {},
    loader = true,
    timeout?,
    shouldAuthenticated = true,
    signOutOnReject = true,
  ): Promise<TResponse | IErrorResponse> {
    const requestBody = body;
    const token = storageGetItem(appConstants.USER_TOKEN);

    if (!Gateway.validateRequest(shouldAuthenticated, token)) {
      storageClearAll();
      window.location.assign("/login");
      return { error: "NO_TOKEN", response: "" };
    }

    const headers = {
      ...additionalHeaders,
    };

    if (shouldAuthenticated) {
      headers.Authorization = `Bearer ${token}`;
    }

    if (loader) {
      requestInitiated(
        Gateway.genAPIKey({
          loader,
          method,
          url,
        }),
      );
    }

    return axios({
      data: requestBody,
      headers,
      method,
      ...config,
      params,
      paramsSerializer,
      responseType: blob,
      timeout: timeout || API_TIMEOUT,
      url,
    })
      .then((response) => Gateway.handlePromiseResolve(response, { method, url, loader }))
      .catch((error) => {
        error.isCancel = axios.isCancel(error);
        return Gateway.handlePromiseReject(error, { method, url, loader, signOutOnReject });
      });
  }

  static validateRequest = (shouldAuthenticated: boolean, token: string): boolean => {
    if (process.env.NODE_ENV === "development") {
      return true;
    }
    if (shouldAuthenticated && token) {
      return true;
    }
    return !shouldAuthenticated;
  };

  static getToken = () => axios.CancelToken;

  static validateTheResponseWithStatus = (response) => {
    return (
      response &&
      [apiState.STATE_200, apiState.STATE_201, apiState.STATE_204, apiState.STATE_206, apiState.STATE_207].includes(
        response.status,
      )
    );
  };

  static handlePromiseResolve = (response, request) => {
    if (request.loader) {
      requestFinished(Gateway.genAPIKey(request));
    }
    if (Gateway.validateTheResponseWithStatus(response)) {
      // TODO: check the structure of such response error
      // catch responses with code 20x and an error
      if (SENTRY_DSN && response && response.error) {
        captureException(response.error, {
          extra: response.data,
          contexts: { apiUrl: response?.request?.responseURL },
        });
      }
      return response.data;
    }
    return false;
  };

  static handlePromiseReject = (error, request) => {
    if (request.loader) {
      requestFinished(Gateway.genAPIKey(request));
    }
    const response = error && error.response;

    /*
    Its a temporary solution for redirection for unauthorized calls.
    */
    if (
      response &&
      response.status === apiState.STATE_401 &&
      storageGetItem(appConstants.USER_TOKEN) &&
      request.signOutOnReject
    ) {
      storageClearAll();
      window.location.assign("/login");
    }
    if (SENTRY_DSN) {
      // we do not want to catch 401 errors
      if (response?.status !== apiState.STATE_401) {
        captureException(error, { extra: response?.data, contexts: { apiUrl: response?.request?.responseURL } });
      }
    }
    return { error };
  };

  static get = <TResponse = IAPIResponse>(
    url: string,
    params = null,
    header = null,
    responseType = null,
    loader = true,
    timeout?,
  ): AsyncResponse<TResponse> => {
    return Gateway.call("GET", url, params, null, header, responseType, {}, loader, timeout);
  };

  static getUnAuthorize = <TResponse = IAPIResponse>(
    url: string,
    params = null,
    header = null,
    responseType = null,
    loader = true,
    timeout?,
  ): AsyncResponse<TResponse> => {
    return Gateway.call("GET", url, params, null, header, responseType, {}, loader, timeout, false);
  };

  static getWithBigInt = <TResponse = IAPIResponse>(
    url,
    params = null,
    header = null,
    responseType = null,
    loader = true,
    timeout?,
  ): AsyncResponse<TResponse> => {
    return Gateway.call("GET", url, params, null, header, responseType, Gateway.getAxiosConfig(), loader, timeout);
  };

  static post = <TResponse = IAPIResponse>(
    url,
    params,
    body,
    header,
    config = {},
    loader = true,
    signOutOnReject = true,
  ): AsyncResponse<TResponse> => {
    return Gateway.call("POST", url, params, body, header, null, config, loader, API_TIMEOUT, true, signOutOnReject);
  };

  static postUnAuthorize = <TResponse = IAPIResponse>(
    url,
    params,
    body,
    header,
    config = {},
    loader = true,
    timeout?,
  ): AsyncResponse<TResponse> => {
    return Gateway.call("POST", url, params, body, header, null, config, loader, timeout, false);
  };

  static put = <TResponse = IAPIResponse>(url, params, body, header, loader = true): AsyncResponse<TResponse> => {
    return Gateway.call("PUT", url, params, body, header, null, {}, loader);
  };

  static delete = <TResponse = IAPIResponse>(
    url,
    params = null,
    header = null,
    loader = true,
    body = null,
  ): AsyncResponse<TResponse> => {
    return Gateway.call("DELETE", url, params, body, header, null, {}, loader);
  };

  static patch = <TResponse = IAPIResponse>(url, params, body, header, loader = true): AsyncResponse<TResponse> => {
    return Gateway.call("PATCH", url, params, body, header, null, {}, loader);
  };

  static getUrl(config) {
    if (config.baseURL) {
      return config.url.replace(`${config.baseURL}`, "");
    }
    return config.url;
  }

  static genAPIKey = (request) => `${request.method}_${Gateway.getUrl(request)}`;
}

export default Gateway;
