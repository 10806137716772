import React from "react";
const SVG = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-796.000000, -1119.000000)">
        <g transform="translate(793.000000, 1116.000000)">
          <g>
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M6,6 L6,18 L18,18 L18,14 L21,14 L21,21 L18,21 L6,21 L3,21 L3,3 L6,3 L10,3 L10,6 L6,6 Z M21,6.10606436 L21,9.15283973 C20.5285663,9.05269209 20.0395892,9 19.5383052,9 C16.9539102,9 14.6966288,10.4005401 13.4840407,12.4840407 L16,15 L9,15 L9,8 L11.3122634,10.3122634 C13.1173015,7.7066091 16.1285034,6 19.5383052,6 C20.0348196,6 20.5228823,6.03618591 21,6.10606436 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
