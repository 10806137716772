import React from "react";
import styled from "styled-components";
import Form from "../../elements/Form/form";
import { HelperLodash } from "../../helpers";
import { isEqual, isObjectValueEmpty } from "../../helpers/helperLodash";
import ControlType from "./controlType";
import FilterGroupModal from "./filterGroupModal";
import { FilterRenderer } from "./filterRenderer";
import { formatFiltersObject, getDefaultFilters, getUnclearableFilters, getFilterName } from "./filterUtils";
import { IFilterProps, IFilterState } from "./iFilterProps";
import { IGridFilter, RenderLocation } from "./iGridFilter";
import { IDropDownModel } from "./modules/iDropDrownModel";
import MoreFilterContainer from "./moreFilterComponents/moreFilterContainer";
import StyleAttrConstants from "../../constant/styleAttrConstants";
import { Scroll, ScrollContext } from "../../providers/scrollProvider";
/**
 * @description FilterWrapper styled for the wrapper
 */
interface IFilterWrapper {
  filterContainerWidth: number;
}
export const calculateWidth = (props: IFilterWrapper) => `${props.filterContainerWidth}px`;

const FilterWrapper = styled.div`
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: nowrap;
  display: flex;
  flex-grow: 0;
  flex-basis: ${calculateWidth};
  & > div {
    display: inline-block;
  }
`;
interface IClearAll {
  showClearAll: boolean;
}
export const ClearAll = styled.div`
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  visibility: ${(props: IClearAll) => (props.showClearAll ? "visible" : "hidden")};
  padding-top: 12px;
  &:hover {
    color: ${(props) => props.theme.colors.base.steel10};
  }
`;

/**
 * define entity for filters
 */

export class FilterPresentational extends React.Component<IFilterProps, IFilterState> {
  myFilterContainerRef: any;
  private readonly noValueAvailable = "filters:NO_VALUES_AVAILABLE";
  // Create an element for calculating the filter row length.
  static canvas = document.createElement("canvas");
  static contextType?: React.Context<Scroll> = ScrollContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      badgeCount: 0,
      currentSelectedFilter: null,
      filterContainerWidth: this.props.width,
      filterGroupModalData: {
        appliedOptions: [],
        clearCurrentFilterSelection: null,
        filter: null,
        initialSearchValue: "",
        mode: null,
      },
      filtersHandled: {},
      initialValues: this.getInitialSelectedValues(props.selectedFilters),
      moreFilterSettings: [],
      savedOptions: this.getSavedOptions(props.selectedFilters),
      searchBarFilterSettings: [],
      shouldDisplayFilterGroupModal: false,
    };
    const { initialValues } = this.state;
    if (initialValues && Object.keys(initialValues).length > 0) {
      if (props.updateFilterQuery) {
        props.updateFilterQuery(
          formatFiltersObject(initialValues, props.filterSettings),
          this.state.currentSelectedFilter,
          initialValues,
        );
      }
      this.handleFormSubmit(initialValues);
    }
  }

  componentDidMount() {
    this.updateFilterSettingState();
    this.setState({
      filterContainerWidth: this.getFilterContainerWidth(),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.shouldResetToDefault(prevProps)) {
      this.resetToDefault();
    }
    if (this.shouldUpdateFilterSettings(prevProps)) {
      this.updateFilterSettings();
    }
    if (this.props.selectedFilters !== prevProps.selectedFilters) {
      const { selectedFilters, updateFilterQuery } = this.props;
      const initialValues = this.getInitialSelectedValues(selectedFilters);
      this.setState({
        initialValues,
      });
      // we need to check if selectedFilters is undefined
      if (selectedFilters || (selectedFilters === undefined && prevProps.selectedFilters !== null)) {
        if (updateFilterQuery) {
          const filtersObject = formatFiltersObject(initialValues, this.props.filterSettings);
          updateFilterQuery(
            filtersObject,
            filtersObject && filtersObject.length && filtersObject[filtersObject.length],
            initialValues,
          );
        }

        this.handleFormSubmit(initialValues);
      }
      if (!selectedFilters) {
        this.setState({
          currentSelectedFilter: null,
          savedOptions: [],
        });
      }

      if (Array.isArray(selectedFilters)) {
        const options = this.getSavedOptions(selectedFilters);
        this.setState({
          badgeCount: this.getBadgeCount(options),
          currentSelectedFilter: this.getCurrentSelectedFilter(options),
          savedOptions: options,
        });
      }
    }
  }

  /**
   * @description getFilterContainerWidth makes the calculation of the filter container's width consistent
   * */
  getFilterContainerWidth = () => {
    let filterWidth = this.myFilterContainerRef?.offsetWidth;
    const widthOfParent = this.props.searchContainerRef?.offsetWidth;
    const filterOffsetLeft = this.myFilterContainerRef?.offsetLeft;
    if (widthOfParent && filterOffsetLeft) {
      filterWidth = widthOfParent - filterOffsetLeft;
    }
    return filterWidth;
  };

  /**
   * @description howManyFiltersWillNotFit will return the amount of filters
   * that must go under the "More filters" section (can be even all of them)
   */
  howManyFiltersWillNotFit = (filterArr) => {
    const { filterContainerWidth } = this.state;

    const widthOfRef = filterContainerWidth ? filterContainerWidth : this.myFilterContainerRef?.offsetWidth;
    const widthOfContainer = this.props.isSearchComponentLoaded
      ? widthOfRef - StyleAttrConstants.WIDTH.W300
      : widthOfRef; // space for clear all and more filter scroll padding margin
    const context = FilterPresentational.canvas.getContext("2d");

    let totalWidth = 0;
    let count = 0;
    if (filterArr) {
      filterArr.forEach((ele: any) => {
        const metrics = context?.measureText(this.props.t(ele.filterDisplayName));
        const PADDING_SPACE = 90;
        const filterPlaceholderWidth =
          this.displayedFilter(ele, this.state.savedOptions) && metrics ? metrics.width + PADDING_SPACE : 0; // padding + margin right
        totalWidth += filterPlaceholderWidth;
        /*
         *
         * Within every iteration we increase the totalWidth with the approximate
         * width of a particular filter selector component based on the length of the filter's label
         *
         * Once the totalWidth becomes more than widthOfContainer - we start counting
         * how many filters will not fit into the container
         *
         * */
        if (widthOfContainer < totalWidth) {
          count++;
        }
      });
    }
    return count;
  };

  /**
   * @description updateFilterSettingState will check if needs to render the more filter dropdown
   */
  updateFilterSettingState = () => {
    const { filterSettings } = this.props;
    const filterSettingsWithoutExclude = filterSettings.filter((setting) => !setting.exclude);

    // how many filters we can show before the "More filters" button
    const filtersAmountBeforeMoreButton =
      filterSettingsWithoutExclude.length - this.howManyFiltersWillNotFit(filterSettingsWithoutExclude);

    /*
     *
     * if filtersAmountBeforeMoreButton === 0 and filterSettings.length > 0
     * that means all filters must go under the "More filters" section
     *
     * */
    if (filterSettingsWithoutExclude.length > 0) {
      this.setState(
        {
          moreFilterSettings: filterSettingsWithoutExclude.slice(
            filtersAmountBeforeMoreButton,
            filterSettingsWithoutExclude.length,
          ),
          searchBarFilterSettings: filterSettingsWithoutExclude.slice(0, filtersAmountBeforeMoreButton),
        },
        () => {
          this.setState({ badgeCount: this.getBadgeCount() });
        },
      );
    } else {
      this.setState({
        badgeCount: 0,
        moreFilterSettings: [],
        searchBarFilterSettings: filterSettings,
      });
    }
  };

  shouldResetToDefault = (prevProps) => {
    const { appliedFilterQuery, clearAppliedFilter } = this.props;
    return (
      (prevProps.appliedFilterQuery &&
        prevProps.appliedFilterQuery.length > 0 &&
        appliedFilterQuery &&
        appliedFilterQuery.length === 0) ||
      (clearAppliedFilter && prevProps.clearAppliedFilter !== clearAppliedFilter) ||
      (appliedFilterQuery === null &&
        appliedFilterQuery !== prevProps.appliedFilterQuery &&
        Object.keys(this.state.savedOptions).length > 0)
    );
  };

  shouldUpdateFilterSettings = (prevProps) => {
    const { filterAttributes, filterSettings, width } = this.props;
    return (
      (filterAttributes && (width !== prevProps.width || filterAttributes !== prevProps.filterAttributes)) ||
      (filterSettings && filterSettings !== prevProps.filterSettings)
    );
  };

  updateFilterSettings = () => {
    const { width } = this.props;
    this.setState(
      {
        filterContainerWidth: width ? width : this.getFilterContainerWidth(),
      },
      () => this.updateFilterSettingState(),
    );
  };

  checkReadOnlyFilter = (type: any) => {
    return type === ControlType.ReadOnlyFilter;
  };
  displayedFilter = (row: any, savedOptions: any) => {
    const isReadOnly: boolean = this.checkReadOnlyFilter(row.type.searchBar);
    return !isReadOnly || (isReadOnly && HelperLodash.get(savedOptions, row.filterName));
  };

  /**
   * method to convert API values to bind dropdown
   * @param values : values to convert in dropdown format
   * Just a working solution fix this
   * @param nullTextDisplay
   */
  getDropDownValues = (values, nullTextDisplay): IDropDownModel => {
    const { t } = this.props;
    return (
      values &&
      values.map((value) => {
        if (!value) {
          return {
            label: nullTextDisplay ? `${t(nullTextDisplay)}` : `${t("filters:NO_VALUE_ASSIGNED")}`,
            value: "null",
          };
        } else if (typeof value === "string") {
          return {
            label: value,
            value,
          };
        } else {
          return {
            label: t(value.value),
            value: value.code,
          };
        }
      })
    );
  };

  /**
   * Method to retrieve value for filter
   *
   * @param ctrlType : Type of control
   * @param values : values to transform
   * @param nullTextDisplay
   * @param filterSetting
   * @param isAppliedValues
   */
  getValues = (values, ctrlType: ControlType, nullTextDisplay = null, filterSetting, isAppliedValues?: boolean) => {
    switch (ctrlType) {
      case ControlType.DataRange:
      case ControlType.DataRangeDropdown:
      case ControlType.ReadOnlyFilter:
        return values;
      case ControlType.RadioGroup:
      case ControlType.RadioSelectDropdown:
        return this.getRadioDropdownValues(filterSetting, values, nullTextDisplay, isAppliedValues);
      case ControlType.DateRange:
      case ControlType.DateRangeDropdown:
        return this.getDateRangeValues(values);
      default:
        return this.getDropDownValues(values, nullTextDisplay);
    }
  };

  /**
   * Method to retrieve initial selected value for filter
   *
   * @param ctrlType : Type of control
   * @param values : values to transform
   * @param nullTextDisplay
   * @param filterSetting
   * @param isAppliedValues
   */
  getInitialValues = (
    values,
    ctrlType: ControlType,
    nullTextDisplay = null,
    filterSetting,
    isAppliedValues?: boolean,
  ) => {
    switch (ctrlType) {
      case ControlType.DateRange:
      case ControlType.DateRangeDropdown:
        return this.getInitialDateRangeValues(values);
      default:
        return this.getValues(values, ctrlType, nullTextDisplay, filterSetting, isAppliedValues);
    }
  };

  getInitialDateRangeValues = (values) => {
    if (values && values.length > 1) {
      return {
        endDate: new Date(values[1]),
        startDate: new Date(values[0]),
      };
    }
    return null;
  };

  getRadioDropdownValues = (filterSetting, values = [], nullTextDisplay, isAppliedValues) => {
    if (filterSetting.hasDependency) {
      return this.props.dependencies[filterSetting.filterName].options;
    } else if (filterSetting.useDefault && !isAppliedValues) {
      return [
        {
          label: filterSetting.labels ? this.props.t(filterSetting.labels.yesLabel) : this.props.t("filters:YES"),
          value: "true",
        },
        {
          label: filterSetting.labels ? this.props.t(filterSetting.labels.noLabel) : this.props.t("filters:NO"),
          value: "false",
        },
      ];
    } else if (!filterSetting.useDefault) {
      // This check was required to fix the bug  BUTSAM-165506 as filterSetting.useDefault is avilable for the all the redioselectdropdown and isAppliedValues becomes true in multiple render
      // to fix this edge case this Check is required further we can optimize  this TBD
      return this.getDropDownValues(values, nullTextDisplay);
    }
  };

  getDateRangeValues = (values) => {
    const value = values && values.length > 0 ? values[0] : null;
    return value
      ? [
          {
            maxDate: new Date(value.max),
            minDate: new Date(value.min),
          },
        ]
      : [];
  };

  /**
   * method to update selected filter options in state
   *
   * @param updatedOptions : options to set in state
   * @param filter : filter for which options to update
   */
  onChange = (updatedOptions, filter) => {
    const updateSelectedOptions = (prevSavedOptions) => {
      const savedOptions = { ...prevSavedOptions };
      let currentFilter = filter.filterName;
      savedOptions[filter.filterName] = updatedOptions;
      if (
        (updatedOptions && updatedOptions.length === 0) ||
        !updatedOptions ||
        (updatedOptions.selectedValue && !updatedOptions.selectedValue[0])
      ) {
        this.clearSelectedFilter();
        currentFilter = null;
        delete savedOptions[filter.filterName]; // removing filterName from array in case all the options of particular filter is removed.
      }
      return { savedOptions, currentSelectedFilter: currentFilter };
    };

    this.setState((prevState) => {
      return updateSelectedOptions(prevState.savedOptions);
    });
  };
  /*
   *clear selected filters when user click on clear
   */
  clearSelectedFilter = () => {
    if (this.props.selectedFilters && this.props.location && this.props.location.state) {
      const resetState = { ...this.props.location.state };
      delete resetState.initialFilterValue;
      this.props.history.replace({ state: resetState });
    }
  };

  /**
   * get applied filter count for Badge Calculation
   */
  getBadgeCount = (savedOptionsList = null) => {
    let count = 0;
    const savedOptions = savedOptionsList || this.state.savedOptions;
    const moreFilterSettings = this.state.moreFilterSettings;
    const { filterAttributes } = this.props;

    if (savedOptions && Object.keys(savedOptions).length > 0) {
      moreFilterSettings.forEach((row: any) => {
        const filterSettings = this.props.filterSettings.filter((s) => s.filterName === row.filterName)[0];
        let values = filterAttributes && filterAttributes[row.filterName];
        values = this.getValues(values, row.type[RenderLocation.moreFilter], null, filterSettings);
        if (savedOptions.hasOwnProperty(row.filterName)) {
          count = count + this.getCount(values, row, RenderLocation.moreFilter);
        }
      });
    }
    return count;
  };

  renderFilterControls = (filterSettings, mode) => {
    const {
      appliedFilterQuery,
      filterAttributes,
      filterAttributesCount,
      filterAttributesSearchCount,
      filterFormValues,
      dependencies,
      searchContainerRef,
      displayLoader,
      t,
      tReady,
      i18n,
      getFilterAttributes,
      changeFilterFormValues,
    } = this.props;
    const { savedOptions } = this.state;
    return (
      <FilterRenderer
        appliedFilterQuery={appliedFilterQuery}
        savedOptions={savedOptions}
        onChange={this.onChange}
        onSubmit={(filters) => this.handleFormSubmit(filters, mode)}
        mode={mode}
        filterAttributes={filterAttributes}
        filterAttributesCount={filterAttributesCount}
        filterAttributesSearchCount={filterAttributesSearchCount}
        filterSettings={filterSettings}
        filterFormValues={filterFormValues}
        getValues={this.getValues}
        getCount={this.getCount}
        t={t}
        dependencies={dependencies}
        filterContainerRef={searchContainerRef}
        displayLoader={displayLoader}
        tReady={tReady}
        i18n={i18n}
        handleShowMore={this.handleShowMore}
        getFilterAttributes={getFilterAttributes}
        changeFilterFormValues={changeFilterFormValues}
        datePickerLocale={this.props.datePickerLocale}
        dateFormat={this.props.dateFormat}
      />
    );
  };

  /**
   * render filters
   */
  renderFilters = () => {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.renderFilterControls(this.state.searchBarFilterSettings, RenderLocation.searchBar)}
        {this.state.moreFilterSettings && this.state.moreFilterSettings.length !== 0 && (
          <MoreFilterContainer
            id={"moreFilters"}
            placeholder={t("filters:MORE_FILTER")}
            name={"moreFilters"}
            badgeCount={this.state.badgeCount}
            showNoRecordFoundView={this.props.showNoRecordFoundView}
            parentMoreFilterHandler={this.props.parentMoreFilterHandler}
            displayLoader={this.props.displayLoader}
            t={this.props.t}
            tReady={this.props.tReady}
            i18n={this.props.i18n}
            openMoreFilter={this.props.openMoreFilter}
            closeMoreFilter={this.props.closeMoreFilter}
            isMoreFilterClosed={this.props.isMoreFilterClosed}
          >
            {this.renderFilterControls(this.state.moreFilterSettings, RenderLocation.moreFilter)}
          </MoreFilterContainer>
        )}
      </React.Fragment>
    );
  };

  /**
   * handler GetSavedOptions and make it selected by default
   *
   */
  getSavedOptions = (selectedFilters) => {
    const savedOptions = [];
    const settings = this.props.filterSettings;
    if (selectedFilters) {
      selectedFilters.forEach((initVal) => {
        const filterKey = Object.keys(initVal).toString();
        const setting = settings.filter((s) => s.filterName === filterKey)[0];
        if (initVal) {
          savedOptions[filterKey] = this.getInitialValues(
            initVal[filterKey],
            setting && setting.type.searchBar,
            null,
            settings,
          );
        }
      });
    }
    return savedOptions;
  };

  /**
   * handler form initialValue setting InitialValues of the forms
   *
   */
  getInitialSelectedValues = (selectedFiltersVal) => {
    const { filterSettings } = this.props;
    if (Array.isArray(selectedFiltersVal)) {
      const initialValues = {};
      selectedFiltersVal.forEach((initVal) => {
        const filterKey = Object.keys(initVal);

        filterKey.forEach((filterVal) => {
          const filterValue = initVal[filterVal];
          const currentLocationFilterSettingObj = filterSettings.filter((filter) => {
            return filter.filterName === filterVal;
          });
          if (filterValue && currentLocationFilterSettingObj.length > 0) {
            const filterNameOptCombination = getFilterName(
              currentLocationFilterSettingObj[0].filterName,
              currentLocationFilterSettingObj[0].operator,
            );
            initialValues[filterNameOptCombination] = filterValue.join(";");
          }
        });
      });
      return initialValues;
    }
    return selectedFiltersVal ? selectedFiltersVal : {};
  };

  /**
   * handler to invoke when form data changes
   * @param originalFilters
   */
  handleFormOnChange = (originalFilters) => {
    let filterKeyToFind = "";
    const filters = {};
    for (const [key, value] of Object.entries(originalFilters)) {
      if (value !== undefined) {
        filters[key] = value;
      }
    }
    if (this.props.updateFilterQuery && !isEqual(filters, this.props.selectedFilters)) {
      this.props.updateFilterQuery(
        formatFiltersObject(filters, this.props.filterSettings),
        this.state.currentSelectedFilter,
        filters,
      );
    }
    this.setState((prevState) => {
      // this array is used two times, let's separate it to a constant
      const filtersObjectKeys = Object.keys(filters);

      // let's handle the "Clear all" button clicked
      const wasClearAllButtonClicked =
        prevState.currentSelectedFilter && filters && filtersObjectKeys && filtersObjectKeys.length === 0;

      // the array of previously applied filter keys
      const previouslySelectedFilterNames = Object.keys(prevState.filtersHandled);

      // the array of currently applied filter keys
      const currentlySelectedFilterNames = filtersObjectKeys;

      if (previouslySelectedFilterNames.length > currentlySelectedFilterNames.length) {
        /*
         *
         * In this case user clears a recently applied specific filter.
         * We detect the filter that was cleared here
         *
         * */
        const foundKey = previouslySelectedFilterNames.find((item) => !currentlySelectedFilterNames.includes(item));
        filterKeyToFind = foundKey && foundKey.split && foundKey.split(":")[0];
      } else if (previouslySelectedFilterNames.length < currentlySelectedFilterNames.length) {
        /*
         *
         * In this case user interacts with a new filter that was not applied previously.
         * We detect that filter here
         *
         * */
        const foundKey = currentlySelectedFilterNames.find((item) => !previouslySelectedFilterNames.includes(item));
        filterKeyToFind = foundKey && foundKey.split && foundKey.split(":")[0];
      } else {
        /*
         *
         * The "else" case when user does not change the amount of applied filters
         * (but not filter options in those filters)
         *
         * */
        const keys = Object.keys(filters) || [];
        if (prevState && prevState.currentSelectedFilter) {
          filterKeyToFind = prevState.currentSelectedFilter;
        } else {
          filterKeyToFind = filters && keys.length && keys[keys.length - 1].split(":")[0];
        }
      }

      // we detect whether the applied filter was in the "More filters" section
      const filterSettingsObjectForMoreFilters = this.state.moreFilterSettings.find(
        (item) => item.filterName === filterKeyToFind,
      );

      // we detect whether the applied filter was in the "Search bar" section
      const filterSettingsObject = this.props.filterSettings.find((item) => item.filterName === filterKeyToFind);

      // let's use the default value for the interacted filter
      let filterType = ControlType.MultiSelectDropdown;

      if (filterSettingsObjectForMoreFilters) {
        // we detect a filter type from the "More filters" section
        filterType =
          filterSettingsObjectForMoreFilters.type && filterSettingsObjectForMoreFilters.type[RenderLocation.moreFilter];
      } else if (filterSettingsObject) {
        // we detect a filter type from the "Search bar" section
        filterType = filterSettingsObject.type && filterSettingsObject.type[RenderLocation.searchBar];
      }

      /*
       *
       * If the filter type is NOT a checkbox/checkbox group/multiselect dropdown
       * AND the action is NOT performed by clicking the "Clear all" button
       * THEN we must perform an immediate API call to apply the interacted filter
       *
       * */
      if (
        !wasClearAllButtonClicked &&
        filterType !== ControlType.Checkbox &&
        filterType !== ControlType.CheckboxGroup &&
        filterType !== ControlType.MultiSelectDropdown &&
        this.shouldSubmitFormForReadOnlyFilter(filterType, prevState.savedOptions)
      ) {
        this.handleFormSubmit(filters);
        this.scrollToTopOnFilter(
          filterSettingsObjectForMoreFilters ? RenderLocation.moreFilter : RenderLocation.searchBar,
        );
      }
      return {
        filtersHandled: filters,
      };
    });
  };

  shouldSubmitFormForReadOnlyFilter = (filterType, savedOptions) =>
    filterType !== ControlType.ReadOnlyFilter ||
    (filterType === ControlType.ReadOnlyFilter &&
      savedOptions &&
      !Array.isArray(savedOptions) &&
      Object.keys(savedOptions) &&
      Object.keys(savedOptions).length === 0);

  scrollToTopOnFilter = (mode = RenderLocation.moreFilter) => {
    const { isScrollBehaviourEnabled = false } = this.context;
    if (mode === RenderLocation.searchBar && isScrollBehaviourEnabled) {
      window.scrollTo(0, 0);
    }
  };

  /**
   * handler to invoke when form is submitted
   * @param filters: current form values
   */
  handleFormSubmit = (filters, mode = RenderLocation.moreFilter) => {
    this.scrollToTopOnFilter(mode);
    this.props.refreshFilters(
      formatFiltersObject(filters, this.props.filterSettings),
      this.state.currentSelectedFilter,
      filters,
    );
  };

  handleModalClose = (clearNotApplied: boolean) => {
    const { getFilterAttributes } = this.props;
    const { filterGroupModalData } = this.state;
    const { filter } = filterGroupModalData || {};
    this.setState({ shouldDisplayFilterGroupModal: false });

    if (clearNotApplied && filterGroupModalData.clearCurrentFilterSelection) {
      filterGroupModalData.clearCurrentFilterSelection();
      getFilterAttributes(filter.filterName, "");
    }
  };

  handleShowMore = (filter, appliedOptions, mode, query = "", clearCurrentFilterSelection) => {
    const { getFilterAttributes } = this.props;
    getFilterAttributes(filter.filterName, query);
    this.setState((prevState) => ({
      filterGroupModalData: {
        ...prevState.filterGroupModalData,
        appliedOptions,
        clearCurrentFilterSelection,
        filter,
        initialSearchValue: query,
        mode,
      },
      shouldDisplayFilterGroupModal: true,
    }));
  };

  handleFilterGroupModalSearch = (filter, query: string) => {
    const { getFilterAttributes } = this.props;
    getFilterAttributes(filter.filterName, query);
  };

  closeMoreFilter = () => {
    const { parentMoreFilterHandler, closeMoreFilter } = this.props;
    return parentMoreFilterHandler ? parentMoreFilterHandler.closeMoreFilter() : closeMoreFilter();
  };
  /**
   * clears form data
   *
   * @param event
   */
  clearForm = (event?: any) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    this.closeMoreFilter();
    this.resetToDefault();

    const unclearableFilters = getUnclearableFilters(this.props.filterSettings);

    if (unclearableFilters.length) {
      this.handleFormSubmit(this.state.initialValues);
    } else {
      this.props.refreshFilters([]);
      this.scrollToTopOnFilter(RenderLocation.searchBar);
    }
  };

  /**
   * rest to default state
   */
  resetToDefault = () => {
    const { location, selectedFilters, filterSettings, filterFormName } = this.props;
    if (selectedFilters && location?.state) {
      const { initialFilterValue, additionalData, ...rest } = location.state;
      this.props.history.replace({ state: rest });
    }

    this.props.reset(filterFormName ? filterFormName : "filters");
    this.forceUpdate();

    const defaultFilters = getDefaultFilters(filterSettings);
    const unclearableFilters = getUnclearableFilters(filterSettings);
    const filtersSelected = unclearableFilters.length ? selectedFilters : defaultFilters;
    const savedOptions = this.getSavedOptions(filtersSelected);

    this.setState({
      currentSelectedFilter: this.getCurrentSelectedFilter(savedOptions),
      initialValues: this.getInitialSelectedValues(filtersSelected),
      savedOptions,
    });
  };

  getCurrentSelectedFilter = (savedOptions) => {
    const savedOptionKeys = savedOptions && Object.keys(savedOptions);
    return savedOptionKeys.length && savedOptionKeys[savedOptionKeys.length];
  };

  /**
   * @description getCountDetail nested function to check if we have selected value need to add those in count number
   * @param selectedValue the value selected in the filter
   * @param rangeData the default values
   */
  getDataRangeCount = (selectedValue, rangeData) => {
    let selectedCount = 0;
    if (selectedValue.min !== rangeData.min || selectedValue.max !== rangeData.max) {
      selectedCount = selectedCount + 1;
    }
    return selectedCount;
  };

  getDateRangeCount = (selectedDates) => {
    if (selectedDates !== null) {
      let count = 0;
      if (selectedDates.startDate) {
        count = count + 1;
      }
      if (selectedDates.endDate) {
        count = count + 1;
      }
      return count;
    }
    return 0;
  };

  getNonIdleRangeCount = (ctrlType, selectedValue) => {
    let count = 0;
    const selectedValueLen = selectedValue && selectedValue.length;
    if (ctrlType === ControlType.DateRange || ctrlType === ControlType.DateRangeDropdown) {
      count = this.getDateRangeCount(selectedValue);
    } else if (ctrlType === ControlType.ReadOnlyFilter || ctrlType === ControlType.ReadOnlyMoreFilter) {
      count = selectedValueLen;
    } else {
      count = selectedValueLen;
    }
    return count;
  };
  getSelectedFilterCount = (ctrlType, values, selectedValue, filter) => {
    if (ctrlType === ControlType.IdleRange || ctrlType === ControlType.DataRangeDropdown) {
      const rangeData = values && values.length > 0 ? values[0] : null;
      if (Array.isArray(selectedValue)) {
        return selectedValue.length;
      }

      if (selectedValue.min || selectedValue.max) {
        return filter.filterName === "idleTimeDuration" ? 1 : this.getDataRangeCount(selectedValue, rangeData);
      }
    }

    return this.getNonIdleRangeCount(ctrlType, selectedValue);
  };

  /**
   * @description getCount to get the count to show in badge only in case there is dataRange filter
   * @param values default values in attribute
   * @param filter the filter setting of filters
   * @param mode the control type to render
   */
  getCount = (values: any, filter: IGridFilter, mode) => {
    const selectedValue = this.state.savedOptions[filter.filterName];
    const ctrlType: ControlType = filter.type[mode];
    return selectedValue ? this.getSelectedFilterCount(ctrlType, values, selectedValue, filter) : 0;
  };

  showClearAll = () => {
    const { savedOptions } = this.state;
    const defaultFilters = getDefaultFilters(this.props.filterSettings);
    const unclearableFilters = getUnclearableFilters(this.props.filterSettings);

    return (
      !isObjectValueEmpty(savedOptions) &&
      Object.keys(savedOptions).some(
        (option) =>
          !defaultFilters.find((filter) => filter.filterName === option) &&
          !unclearableFilters.find((filter) => filter.filterName === option),
      )
    );
  };

  clearSelectedOptionsInModal = () => {
    this.setState(
      (prevState) => ({
        filterGroupModalData: {
          ...prevState.filterGroupModalData,
          appliedOptions: [],
        },
        savedOptions: {
          ...prevState.savedOptions,
          [prevState.currentSelectedFilter]: [],
        },
      }),
      () => {
        const filtersHandledKeys = this.state.filtersHandled ? Object.keys(this.state.filtersHandled) : [];
        const filtersHandled = Object.assign({}, this.state.filtersHandled);
        const currentFilterIndex = filtersHandledKeys.findIndex((element) =>
          element.includes(this.state.currentSelectedFilter),
        );
        if (currentFilterIndex >= 0) {
          delete filtersHandled[filtersHandledKeys[currentFilterIndex]];
        }
        this.props.changeFilterFormValues(filtersHandledKeys[currentFilterIndex], "");
      },
    );
  };

  render() {
    const {
      t,
      filterAttributes,
      displayLoader,
      filterFormName,
      filterFormValues,
      changeFilterFormValues,
      filterAttributesSearchCount,
    } = this.props;
    const { shouldDisplayFilterGroupModal, savedOptions, filterGroupModalData, initialValues, filterContainerWidth } =
      this.state;
    return (
      <>
        <Form
          form={filterFormName ? filterFormName : "filters"}
          onChange={this.handleFormOnChange}
          onSubmit={this.handleFormSubmit}
          initialValues={initialValues}
        >
          <FilterWrapper
            filterContainerWidth={filterContainerWidth}
            ref={(elementRef) => (this.myFilterContainerRef = elementRef)}
          >
            {this.renderFilters()}
            <ClearAll onClick={this.clearForm} showClearAll={this.showClearAll()}>
              {t("filters:CLEAR_ALL")}
            </ClearAll>
          </FilterWrapper>
        </Form>

        <FilterGroupModal
          shouldDisplay={shouldDisplayFilterGroupModal}
          filter={filterGroupModalData.filter}
          filterAttributesSearchCount={filterAttributesSearchCount}
          allSelectedOptions={savedOptions}
          allOptions={filterAttributes}
          appliedOptions={filterGroupModalData.appliedOptions}
          handleModalClose={this.handleModalClose}
          applyFilters={this.handleFormSubmit}
          handleSearch={this.handleFilterGroupModalSearch}
          getOptionsBasedOnFilter={this.getValues}
          mode={filterGroupModalData.mode}
          t={t}
          clearSelectedOptions={this.clearSelectedOptionsInModal}
          displayLoader={displayLoader}
          filterFormValues={filterFormValues}
          changeFilterFormValues={changeFilterFormValues}
          updateSelectedOptions={this.onChange}
          initialSearchValue={filterGroupModalData.initialSearchValue}
          noValuesLabel={t(this.noValueAvailable)}
        />
      </>
    );
  }
}

export default FilterPresentational;
