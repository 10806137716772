// @ts-ignore
import { FieldRequestConstants } from "custom-actions";

export const getFieldRequestsCount = () => ({
  type: FieldRequestConstants.AM_GET_FIELD_REQUESTS_COUNT,
});

export const getFieldRequestsList = (offset = 0, currentFilter?) => ({
  offset,
  currentFilter,
  type: FieldRequestConstants.AM_GET_FIELD_REQUESTS_LIST,
});

export const getFieldRequestDetails = (id) => ({
  id,
  type: FieldRequestConstants.AM_GET_FIELD_REQUEST_DETAILS,
});

export const updateFieldRequest = (fieldRequestInfo) => ({
  fieldRequestInfo,
  type: FieldRequestConstants.UPDATE_FIELD_REQUEST,
});

export const resetFieldRequestDetails = () => ({
  type: FieldRequestConstants.RESET_FIELD_REQUEST_DETAILS,
});

export const setFilterQuery = (filterQuery) => ({
  filterQuery: filterQuery.filters,
  type: FieldRequestConstants.SET_FIELD_REQUEST_FILTER_QUERY,
});

export const setFieldRequestFilterAppliedAttributes = (filterQuery) => ({
  payload: filterQuery,
  type: FieldRequestConstants.SET_FIELD_REQUEST_FILTER_APPLIED_FIELD,
});

export const setSearchQuery = (searchQuery) => ({
  searchQuery,
  type: FieldRequestConstants.SET_FIELD_REQUEST_SEARCH_QUERY,
});
