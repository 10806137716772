// @ts-ignore
import { IconName } from "am-web-ui-shared/components";
import { colorPalette } from "am-web-ui-shared/materials";

const AlertType = {
  FLEET_OVERDUE: "FLEET_OVERDUE",
  FLEET_UPCOMING: "FLEET_UPCOMING",
  LOAN_OVERDUE: "LOAN_OVERDUE",
  LOAN_UPCOMING: "LOAN_UPCOMING",
  OWNED_OVERDUE: "OWNED_OVERDUE",
  OWNED_UPCOMING: "OWNED_UPCOMING",
  RENT_OVERDUE: "RENT_OVERDUE",
  RENT_UPCOMING: "RENT_UPCOMING",
  SERVICE_OVERDUE_ALERT: "SERVICE_OVERDUE_ALERT",
  SERVICE_UPCOMING_ALERT: "SERVICE_UPCOMING_ALERT",
  TRANSFER_OVERDUE_ALERT: "TRANSFER_OVERDUE_ALERT",
  TRANSFER_UPCOMING_ALERT: "TRANSFER_UPCOMING_ALERT",
  WORKER_CERTIFICATE_OVERDUE_ALERT: "WORKER_CERTIFICATE_OVERDUE_ALERT",
  WORKER_CERTIFICATE_UPCOMING_ALERT: "WORKER_CERTIFICATE_UPCOMING_ALERT",
};

const BadgeBackground = {
  OFFLINE: colorPalette.pink,
  OVERDUE: colorPalette.pink,
  REPLACEMENT_COMPLETE: colorPalette.statusLightGreen,
  REPLACEMENT_FAILED: colorPalette.pink,
  UNEXPECTED_REMOVAL: colorPalette.pink,
  UPCOMING: colorPalette.warningSecondaryYellow,
  MIN: colorPalette.warningSecondaryYellow,
  MAX: colorPalette.warningSecondaryYellow,
  EMPTY: colorPalette.pink,
  BATTERY_STATUS_CRITICAL: colorPalette.pink,

  NOT_FOUND: colorPalette.warningSecondaryYellow,
  MONITORING: colorPalette.lightBlue,
  FOUND_INTERNALLY: colorPalette.statusLightGreen,
  FOUND_EXTERNALLY: colorPalette.pink,
};

const BadgeColor = {
  OFFLINE: colorPalette.lightRedColor,
  OVERDUE: colorPalette.lightRedColor,
  REPLACEMENT_COMPLETE: colorPalette.statusgreen,
  REPLACEMENT_FAILED: colorPalette.lightRedColor,
  UNEXPECTED_REMOVAL: colorPalette.lightRedColor,
  UPCOMING: colorPalette.yellow,
  MIN: colorPalette.yellow,
  MAX: colorPalette.yellow,
  EMPTY: colorPalette.lightRedColor,
  BATTERY_STATUS_CRITICAL: colorPalette.lightRedColor,

  NOT_FOUND: colorPalette.yellow,
  MONITORING: colorPalette.blue,
  FOUND_INTERNALLY: colorPalette.statusgreen,
  FOUND_EXTERNALLY: colorPalette.lightRedColor,
};

const addToTransferAction = {
  code: "ADD_TO_TRANSFER_CART",
  image: IconName.TransferAdd,
  value: "quickActions:ADD_TO_TRANSFER_CART",
};

const DropDownValues = {
  QTY_ITEM_STOCK: [
    {
      code: "STOCK_CHANGE",
      image: IconName.Location,
      value: "actionBar:MANAGESTOCK",
    },
    {
      code: "MANAGE_ALLOCATIONS",
      image: IconName.Edit,
      value: "quickActions:MANAGE_ALLOCATIONS",
    },
    {
      code: "ADD_TO_TRANSFER_CART",
      image: IconName.TransferAdd,
      value: "quickActions:ADD_ALLOCATION_TO_TRANSFER_CART",
    },
    {
      code: "QUICK_TRANSFER",
      image: IconName.LastTransfer,
      value: "quickActions:QUICK_TRANSFER",
    },
  ],
  ZERO_QTY_ITEM_STOCK: [
    {
      code: "STOCK_CHANGE",
      image: IconName.Location,
      value: "actionBar:MANAGESTOCK",
    },
  ],
  SERVICE: [
    {
      code: "COMPLETE_SERVICE",
      image: IconName.CompleteService,
      value: "quickActions:COMPLETE_SERVICE",
    },
    {
      code: "RESCHEDULE_SERVICE",
      image: IconName.RescheduleService,
      value: "quickActions:RESCHEDULE_SERVICE",
    },
  ],
  TRANSFER_RETURN: [addToTransferAction],
  UNEXPECTED_ASSET_REMOVAL: [addToTransferAction],
  WORKER_CERTIFICATE: [
    {
      code: "RENEW_CERTIFICATE",
      image: IconName.Certificate,
      value: "quickActions:RENEW_CERTIFICATE",
    },
  ],
  PROACTIVE_BATTERY: [
    {
      code: "PROACTIVE_BATTERY",
      image: IconName.ExchangeBattery,
      value: "quickActions:EXCHANGE_BATTERY",
    },
  ],
  LOST_FOUND: [
    {
      code: "INVOKE_THEFT_CLAIM",
      image: IconName.HiltiServices,
      suffixImage: IconName.OpenNew,
      value: "quickActions:INVOKE_THEFT_CLAIM",
    },
    {
      code: "CHANGE_STATUS_TO_OPERATIONAL",
      image: IconName.Status,
      value: "quickActions:LOST_FOUND",
    },
  ],
};

const TransferAlertQuickActions = {
  RECALL_CONFIRM_TRANSFER: [
    {
      code: "RECALL_TRANSFER",
      image: IconName.RecallTransfer,
      value: "quickActions:RECALL_TRANSFER",
    },
    {
      code: "CONFIRM_TRANSFER",
      image: IconName.ConfirmTransfer,
      value: "quickActions:CONFIRM_TRANSFER",
    },
  ],
  RECALL_CONFIRM_TRANSFER_WITH_DELIVERY_NOTE: [
    {
      code: "RECALL_TRANSFER",
      image: IconName.RecallTransfer,
      value: "quickActions:RECALL_TRANSFER",
    },
    {
      code: "CONFIRM_TRANSFER",
      image: IconName.ConfirmTransfer,
      value: "quickActions:CONFIRM_TRANSFER",
    },
    {
      code: "DELIVERY_NOTE",
      image: IconName.Download,
      value: "quickActions:DELIVERY_NOTE_INTRANSIT",
    },
  ],
  RECALL_TRANSFER: [
    {
      code: "RECALL_TRANSFER",
      image: IconName.ConfirmTransfer,
      value: "quickActions:RECALL_TRANSFER",
    },
  ],
  CONFIRM_TRANSFER: [
    {
      code: "CONFIRM_TRANSFER",
      image: IconName.ConfirmTransfer,
      value: "quickActions:CONFIRM_TRANSFER",
    },
  ],
};

enum AlertFields {
  ALERT_NAME_FIELD = "alertName",
  ALERT_TYPE_WARRANTY = "WARRANTY",
  ALERT_TYPE_FLEET = "FLEET",
  ALERT_TYPE_RENTAL = "RENTAL",
  ALERT_TYPE_TRANSFER_RETURN = "TRANSFER_RETURN",
  ALERT_TYPE_SERVICE = "SERVICE",
  ALERT_TYPE_LOST_AND_FOUND = "LOST_FOUND",
  ALERT_TYPE_WORKER_CERTIFICATE = "WORKER_CERTIFICATE",
  ALERT_TYPE_REPLACEMENT_HILTI_ASSET = "REPLACEMENT_HILTI_ASSET",
  ALERT_TYPE_GATEWAY_OFFLINE = "GATEWAY_OFFLINE",
  ALERT_TYPE_UNEXPECTED_ASSET_REMOVAL = "UNEXPECTED_ASSET_REMOVAL",
  ALERT_TYPE_QTY_ITEM_STOCK = "QTY_ITEM_STOCK",
  ALERT_TYPE_PROACTIVE_BATTERY = "PROACTIVE_BATTERY",
  ALERT_FIELD_MANUFACTURER_MODEL = "manufacturerName",
  ALERT_FIELD_ALLOCATION_NAME = "allocationName",
  ALERT_FIELD_CURRENT_LOCATION_NAME = "currentLocationName",
  ALERT_TYPE_ZERO_QTY_ITEM_STOCK = "ZERO_QTY_ITEM_STOCK",
}

enum TransferAlertPersonas {
  ADMIN = "RECALL_CONFIRM_TRANSFER",
  RECEIVER = "CONFIRM_TRANSFER",
  SENDER = "RECALL_TRANSFER",
  ADMIN_DELIVERY_NOTE = "RECALL_CONFIRM_TRANSFER_WITH_DELIVERY_NOTE",
}
enum TransferQuickActionType {
  CONFIRM = "Confirm",
  RECALL = "Recall",
  DELIVERY_NOTE = "DELIVERY_NOTE",
}

export {
  AlertFields,
  AlertType,
  BadgeBackground,
  BadgeColor,
  DropDownValues,
  TransferAlertQuickActions,
  TransferAlertPersonas,
  TransferQuickActionType,
};
