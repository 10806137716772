import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@hilti/components/core/dialog";
import { UpgradeOnTrackContent } from "./UpgradeOnTrackContent";
import { UpgradeOnTrackActionButtons } from "./UpgradeOnTrackActionButtons";
import { ClOSE_UPGRADE_DIALOG, trackUpgradeDialogEvents } from "TARGET_BUILD/analytics/events/upgradeOnTrackReady";

export default interface IUpgradeOntrackReady {
  readonly handleClose: () => void;
}

export const UpgradeOnTrackDialog = ({ handleClose }: IUpgradeOntrackReady) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation();

  const handlePromptClose = () => {
    setIsOpen(!isOpen);
    trackUpgradeDialogEvents(ClOSE_UPGRADE_DIALOG);
    handleClose();
  };

  return (
    <Dialog
      header={t("common:HILTI_UPGRADE_DIALOG_HEADER")}
      open={isOpen}
      maxWidth="xl"
      children={<UpgradeOnTrackContent />}
      actions={<UpgradeOnTrackActionButtons handleClose={handlePromptClose} />}
      handleClose={handlePromptClose}
      fullScreen={true}
      onClose={handlePromptClose}
    />
  );
};
