export const keySeparator = ":";

export type KeySeparator = typeof keySeparator;

type Prefix<T extends string> = T extends "" ? "" : `${KeySeparator}${T}`;

type Keys<T> = (
  T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${Prefix<Keys<T[K]>>}`;
      }[Exclude<keyof T, symbol>]
    : ""
) extends infer D
  ? Extract<D, string>
  : never;

export type Locale = typeof import("../../public/hilti/locales/messages_en-US.json");

export type LocaleModule<T extends keyof Locale> = keyof Locale[`${T}`];

export type TParam = Keys<Locale>;
