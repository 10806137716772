export enum LicenseBundleTypes {
  ONTRACK_LITE = "ONTRACK_LITE",
  ONTRACK_PRO = "ONTRACK_PRO",
  ONTRACK_ENTERPRISE = "ONTRACK_ENTERPRISE",
  ONTRACK_READY = "ONTRACK_READY",
}

export enum LicenseBundleValues {
  ONTRACK_LITE = "ON!Track LITE",
  ONTRACK_PRO = "ON!Track PRO",
  ONTRACK_ENTERPRISE = "ON!Track Enterprise",
  ONTRACK_READY = "ON!Track Ready",
}

export enum LicenseBundleToLicenseType {
  ONTRACK_LITE = "OT",
  ONTRACK_PRO = "OT",
  ONTRACK_ENTERPRISE = "OT",
  ONTRACK_READY = "FM",
}

export enum LicenseBundleToLicenseTypeValue {
  ONTRACK_LITE = "O!T Lite",
  ONTRACK_PRO = "O!T Pro",
  ONTRACK_ENTERPRISE = "O!T Enterprise",
  ONTRACK_READY = "O!T Ready",
}
