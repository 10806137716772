import React from "react";
import styled from "styled-components";
import { IRenderLabelProps } from "./iRenderLabelProps";
// @ts-ignore;
import theme from "TARGET_BUILD/theme";

export const LabelBadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${() => theme.labels.assetTypeBG};
  border-radius: 12px;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 8px;
  min-height: 24px;
  word-break: break-all;
  padding: 1px 8px;
  font-size: ${() => theme.fontSize.base};
  color: ${() => theme.colors.base.steel};
  line-height: 23px;
  &&&& {
    &:last-child {
      padding-right: 8px;
    }
  }
`;
export const LabelBadge = styled.span`
  text-align: center;
  color: ${() => theme.labels.badgeBG};
  font-family: ${() => theme.fontRoman.fontFamily};
  font-size: ${() => theme.fontSize.base};
  font-weight: 500;
  line-height: 23px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: auto;
  justify-content: space-between;
  max-width: 66px;
`;
/**
 * @description - Show less label according to limit.
 * @param - index of the labels
 * @param - labelLength is size of labelNames array
 */
export const lessLabels = (index, labelsLength, addedLabelsOverflowIndex) => {
  return index === addedLabelsOverflowIndex ? (
    <LabelBadgeWrapper key={`labelBadge_${index}`}>
      {" "}
      <LabelBadge>+{labelsLength - index}</LabelBadge>{" "}
    </LabelBadgeWrapper>
  ) : null;
};
/**
 * @description - label handle to display the label badge.
 * @param - labelName is display the name of label
 */
export const displayLabel = (labelName) => {
  return (
    <LabelBadgeWrapper key={`labelBadge_${labelName}`}>
      <LabelBadge id={`txtLabel${labelName}`} />
      {labelName}
    </LabelBadgeWrapper>
  );
};
/**
 * @description - labels handle to display all the label.
 * @param - labelNames is row labels array
 * @param - isExpanded is boolean value to know panel is expanded or not
 */
const RenderLabels = (props: IRenderLabelProps) => {
  const { labelNames, isExpanded, addedLabelsOverflowIndex } = props;
  const labelsLength = labelNames && labelNames.length;
  return (
    <React.Fragment>
      {labelNames &&
        labelNames.map((item, index) => {
          return index < addedLabelsOverflowIndex || isExpanded
            ? displayLabel(item)
            : lessLabels(index, labelsLength, addedLabelsOverflowIndex);
        })}
    </React.Fragment>
  );
};
export default RenderLabels;
