import React from 'react';
import type { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import type { ModalProps } from '@mui/material/Modal';
import { IconButton } from '@mui/material';

import StyledDialog, { DialogContent, DialogTitle, DialogActions } from './Dialog.styled';
import CrossIcon from '../icons/svgs/CrossIcon';

export type DialogProps = MuiDialogProps & {
  header: React.ReactNode;
  actions: React.ReactNode;
  handleClose: ModalProps['onClose'];
  fullScreen?: boolean;
};

export default function Dialog({
  header,
  actions,
  children,
  handleClose,
  fullScreen,
  ...rest
}: DialogProps) {
  return (
    <StyledDialog maxWidth='sm' scroll='body' {...rest} fullScreen={fullScreen}>
      <DialogTitle>
        {header}
        <IconButton
          data-testid='close'
          sx={{
            position: 'relative',
            marginRight: -1,
            top: -7,
          }}
          disableRipple={true}
          onClick={(e) => handleClose(e, 'escapeKeyDown')}
        >
          <CrossIcon className='icon' color='primary' />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions fullScreen={fullScreen}>{actions}</DialogActions>
    </StyledDialog>
  );
}
