const QTYPurchaseActionConstants = {
  ADD_QTY_PURCHASE: "ADD_QTY_PURCHASE",
  EDIT_QTY_PURCHASE: "EDIT_QTY_PURCHASE",
  ADD_EDIT_QTY_PURCHASE_SUCCESS: "ADD_EDIT_QTY_PURCHASE_SUCCESS",
  AM_GET_PURCHASES: "AM_GET_PURCHASES",
  GET_PURCHASES_SUCCESS: "GET_PURCHASES_SUCCESS",
  PURCHASE_UNSELECT_ROW: "PURCHASE_UNSELECT_ROW",
  PURCHASE_SELECT_ROW: "PURCHASE_SELECT_ROW",
  RESET_PURCHASE_STATE: "RESET_PURCHASE_STATE",
  DELETE_PURCHASE: "DELETE_PURCHASE",
};

export default QTYPurchaseActionConstants;
