import Axios from "axios";

import { call } from "../../common/sagas/common/commonSaga";

function getImageData(IURL) {
  return Axios.get(`${IURL}?timestamp=${new Date().getTime()}`, { responseType: "blob" })
    .then((response) => response.data)
    .catch((_error) => {
      return false;
    });
}

function imageDataToFile(imageData) {
  /*
    No need of atob/encode/decode conversions(not supported in IE), get the data in blob
    and send the same to formData.
  */
  return imageData || false;
}
function filenameFromURL(fileURL = "") {
  return fileURL.substring(fileURL.lastIndexOf("/") + 1);
}
function* dataURLtoFile(imageURL) {
  const imageData = yield getImageData(imageURL);
  return yield imageDataToFile(imageData);
}

export function* getFileFromURL(imageURL) {
  const fileObj = yield call(() => dataURLtoFile(imageURL));
  const fileName = filenameFromURL(imageURL);
  if (fileObj) {
    const formData = new FormData();
    formData.append("file", fileObj, fileName);
    formData.append(
      "jsonparams",
      new Blob(
        [
          JSON.stringify({
            documentDescription: "Doc Description.",
            documentTitle: "",
            documentType: "ifft",
            privateDocument: false,
          }),
        ],
        { type: "application/json" },
      ),
    );
    return formData;
  }

  return null;
}
