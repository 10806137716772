import { AssetSubType } from "./enums/assetEnum";
import { IconName } from "am-web-ui-shared/components";

type Asset = { assetSubTypeResponse?: { code: AssetSubType } } & { assetSubTypeCode?: AssetSubType } & {
  assetSubType?: { code: AssetSubType };
};

export const getIconByAssetSubType = (asset: Asset) => {
  const code = asset?.assetSubTypeResponse?.code || asset?.assetSubType?.code || asset?.assetSubTypeCode;
  switch (code) {
    case AssetSubType.Container:
      return IconName.Container;
    case AssetSubType.Van:
      return IconName.Truck;
    case AssetSubType.HeavyEquipment:
      return IconName.HeavyEquipment;
    default:
      return IconName.Category;
  }
};
