import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M19.2511924,13 L19.6246304,14.4907184 C20.015966,14.6365691 20.3765022,14.8456572 20.6938131,15.1055569 L22.1734286,14.6822358 L23.114549,16.312304 L22.0190917,17.3718868 C22.0555506,17.5822247 22.0745533,17.7985474 22.0745533,18.0193083 C22.0745533,18.2409115 22.0554054,18.4580423 22.0186737,18.6691367 L23.1173465,19.7316019 L22.1762262,21.3616701 L20.6898708,20.9362844 C20.3732441,21.1949122 20.0137264,21.4030102 19.6236215,21.5482742 L19.2511924,23.0386167 L17.3689518,23.0386167 L16.9965226,21.5482742 C16.6064178,21.4030102 16.2469,21.1949122 15.9302734,20.9362844 L14.4439179,21.3616701 L13.5027976,19.7316019 L14.6014704,18.6691367 C14.5647388,18.4580423 14.5455908,18.2409115 14.5455908,18.0193083 C14.5455908,17.8002939 14.564294,17.5856478 14.6001904,17.3768801 L13.5,16.312304 L14.4411203,14.6822358 L15.9253461,15.1063639 C16.2428958,14.84609 16.6037732,14.63672 16.9955137,14.4907184 L17.3689518,13 L19.2511924,13 Z M11.814,19.993 L12.3677916,20.878383 C11.9772887,21.5196005 11.1574188,21.9842908 10.0606602,21.999633 C8.56502751,22.0205548 7.5843067,21.1440115 7.50517655,20.1419649 L7.5,20.0106149 L11.814,19.993 Z M18.3100721,16.4507745 C17.4437947,16.4507745 16.7415382,17.153031 16.7415382,18.0193083 C16.7415382,18.8855857 17.4437947,19.5878422 18.3100721,19.5878422 C19.1763494,19.5878422 19.8786059,18.8855857 19.8786059,18.0193083 C19.8786059,17.153031 19.1763494,16.4507745 18.3100721,16.4507745 Z M11.5,1 L11.5,2.99901313 C14.5594872,3.63476082 16.8784615,6.22373858 16.9953718,9.32952514 L17,9.57576634 L17,11.494 L16,11.494819 L15.5,13.4938321 L13.5,12.9940788 L11.5,16.4923518 L13,17.9916116 L1,17.9916116 L1,14.9930919 L3,14.9930919 L3,9.57576634 C3,6.44377407 5.24052597,3.79079004 8.26002664,3.05323552 L8.5,2.99901313 L8.5,1 L11.5,1 Z" />
    </g>
  </svg>
);

export default SVG;
