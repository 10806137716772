import { Ellipsis } from "am-web-ui-shared/components";
import React from "react";
// @ts-ignore
import styled from "custom-styled-components";
import { GridFieldsEnum } from "../../../../../utils/enums/gridFieldsEnum";
import { statusColor } from "../../../../../utils/styledUtils";
import IAssetsStatusProps from "./iAssetsStatusProps";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { shouldShowAssetBatteryStatusInList } from "../../../assetTagBatteryStatusRules";
import { getGatewayStatus } from "../../../../../modules/assetTracking/components/smartInventory/presentational/smartInventoryUtils";
import { StatusCode } from "../../../../../utils/enums/assetEnum";
import { useTranslation } from "react-i18next";
import TrackingStatusBadges from "TARGET_BUILD/common/modules/asset/components/lostAndFound/trackingStatusBadges";

// We can't use the component badge here, due to inconsistencies!
const AssetStatusWrapper = styled.span`
  height: 24px;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  padding: 4px 12px;
  ${(props: IAssetsStatusProps) => statusColor(props.statusCode)}
`;

const Spacer = styled.span`
  *:nth-child(n + 2) {
    margin-left: 8px;
  }
`;

const AssetStatus = (props: IAssetsStatusProps) => {
  const { column, showTooltip, tooltipValue, data } = props;
  const { t } = useTranslation();
  const key = column.bindProperties[0].properties[0].name;
  const propertyName = HelperLodash.get(GridFieldsEnum, key, key);
  const statusData = {
    status: HelperLodash.get(props, ["data", propertyName]),
  };
  const statusDataCheck = () => {
    return statusData.status && statusData.status.code && statusData.status.value;
  };

  const gatewayStatus = getGatewayStatus(data);

  const getStatusCodeValue = (dataKey: string) => {
    if (HelperLodash.get(gatewayStatus, "code")) {
      return HelperLodash.get(gatewayStatus, dataKey);
    } else if (shouldShowAssetBatteryStatusInList(data)) {
      return HelperLodash.get(data, ["batteryStatus", dataKey]);
    }
    return HelperLodash.get(statusData, ["status", dataKey]);
  };

  const getAssetStatus = (
    <AssetStatusWrapper
      data-status={statusData.status && statusData.status.code}
      statusCode={getStatusCodeValue("code")}
    >
      {getStatusCodeValue("value")}
    </AssetStatusWrapper>
  );

  const newAssetStatus =
    data?.viewLabel === StatusCode.New ? (
      <AssetStatusWrapper data-status={StatusCode.New} statusCode={StatusCode.New}>
        {t("assets:NEW")}
      </AssetStatusWrapper>
    ) : null;

  const trackingStatus = <TrackingStatusBadges monitoringState={data?.monitoringState} />;

  return statusDataCheck() ? (
    <Ellipsis id={key} showTooltip={showTooltip} tooltipValue={tooltipValue}>
      <Spacer>
        {getAssetStatus}
        {newAssetStatus}
        {trackingStatus}
      </Spacer>
    </Ellipsis>
  ) : null;
};

export default AssetStatus;
