import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import ApplicationConstants from "../../constant/applicationConstants";
import colorPalette from "../../materials/Colors";
import { default as TextField } from "../Field/field";
import IInputProps from "./iInputProps";
// @ts-ignore
import SearchIcon from "./searchIcon.svg";
// @ts-ignore
import SearchIconHover from "./searchIconHover.svg";

// this constant is made to remove the sonar code-smell
// however, this font family should be removed as this component is shared and will be used by trimble as well
const fontFamily = "Hilti-Roman";

const InputStyle = styled.input`
  height: 48px;
  padding-left: 10px;
  padding-right: 52px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  border: 2px solid
    ${(props) =>
      props["data-error"]
        ? colorPalette.hiltiRedError
        : props.disabled
          ? colorPalette.heavyConcrete20
          : colorPalette.heavyConcrete60};
  background-color: ${colorPalette.white};
  color: ${(props) => (props.disabled ? colorPalette.steel20 : colorPalette.steel)};
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${colorPalette.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily || fontFamily};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colorPalette.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily || fontFamily};
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${colorPalette.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily || fontFamily};
  }
  width: 100%;
`;

const InputStyleWrapper = styled.div`
  position: relative;
`;

const WrapperImg = styled.span`
  position: absolute;
  background-image: url(${SearchIcon});
  height: 44px;
  width: 46px;
  text-align: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-color: ${colorPalette.heavyConcrete20};
  top: 2px;
  right: 2px;
  background-position: 16px;
  :hover {
    background-image: url(${SearchIconHover});
  }
`;

const DisabledWrapperImg = styled.span`
  position: absolute;
  background-image: url(${SearchIcon});
  height: 44px;
  width: 46px;
  text-align: center;
  background-repeat: no-repeat;
  background-color: ${colorPalette.heavyConcrete20};
  top: 2px;
  right: 2px;
  background-position: 16px;
  opacity: 0.5;
  pointer-events: none;
`;

const Wrapper = styled.div`
  position: relative;
`;

export const TextComponent = ({
  input,
  label,
  placeholder,
  required,
  selectedValue,
  disabled,
  readOnly,
  onBlur,
  onSearch,
  meta: { touched, error, warning },
}) => {
  /**
   * @description: Method executed while pressing enter button
   * @param {e} : Key press event .
   */
  const onPressEnter = (e) => {
    if (e.charCode === ApplicationConstants.KEYCODES.ENTER) {
      onSearchHandler();
    }
  };

  /**
   * invoke search handler provided if there is no error
   * else invoke onBlur to display error on click
   */
  const onSearchHandler = () => {
    if (!error) {
      onSearch();
    } else {
      input.onBlur();
    }
  };

  return (
    <TextField
      name={input.name}
      htmlFor={input.name}
      label={label}
      error={error}
      touched={touched}
      warning={warning}
      required={required}
    >
      <InputStyleWrapper>
        <InputStyle
          {...input}
          id={input.name}
          data-error={error && touched ? error : ""}
          placeholder={placeholder}
          type="text"
          value={selectedValue ? selectedValue : input.value}
          disabled={disabled}
          readonly={readOnly}
          onChange={(val) => {
            input.onChange(val);
          }}
          onBlur={(val) => {
            const trimVal = val.target.value.trim();
            input.onBlur(trimVal);
            if (onBlur) {
              onBlur(trimVal);
            }
          }}
          onKeyPress={(e) => onPressEnter(e)}
        />
        {disabled ? <DisabledWrapperImg /> : <WrapperImg onClick={() => onSearchHandler()} />}
      </InputStyleWrapper>
    </TextField>
  );
};

export class InputSearchField extends React.PureComponent<IInputProps> {
  render() {
    return (
      <Wrapper>
        <Field
          id={this.props.id || this.props.name}
          name={this.props.name}
          label={this.props.label}
          type={this.props.type}
          placeholder={this.props.placeholder}
          required={this.props.required}
          component={TextComponent}
          validate={this.props.validation}
          {...this.props}
        />
      </Wrapper>
    );
  }
}

export default InputSearchField;
