import React from "react";
import FormAutoComplete from "./formAutoComplete";
import ISelectAutoCompleteProps from "./iSelectAutoCompleteProps";
import AutoCompleteComponent from "./selectAutoComplete";

class AutoComplete extends React.Component<ISelectAutoCompleteProps> {
  render() {
    return !this.props.isFormControl ? <AutoCompleteComponent {...this.props} /> : <FormAutoComplete {...this.props} />;
  }
}

export default AutoComplete;
