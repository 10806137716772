import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path
        d="M14,2 C19.5228475,2 24,6.4771525 24,12 C24,17.5228475 19.5228475,22 14,22 C10.9842284,22 8.28025654,20.6650264 6.44679242,18.553787 L6.44679242,18.553787 L8.57570428,16.4249333 C9.85934249,17.9965933 11.8124922,19 14,19 C17.8659932,19 21,15.8659932 21,12 C21,8.13400675 17.8659932,5 14,5 C10.4735309,5 7.55612324,7.60770164 7.07089004,10.9999918 L7.07089004,10.9999918 L10,11 L5.5,16 L1,11 L4.04938526,10.9998991 C4.55115644,5.94662476 8.81468642,2 14,2 Z M14.9497475,7 L14.9497475,12.4142136 L11.4142136,15.9497475 L10,14.5355339 L12.9497475,11.585 L12.9497475,7 L14.9497475,7 Z"
        transform="translate(12.500000, 12.000000) scale(-1, 1) translate(-12.500000, -12.000000) "
      />
    </g>
  </svg>
);

export default SVG;
