import React from "react";
import shortid from "shortid";
import styled from "styled-components";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import IGroupsBreadcrumbProps from "./iGroupsBreadcrumbProps";

export const FontBold = styled("span")`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;
const BreadcrumbUl = styled.ul`
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  .ellipse {
    span {
      cursor: initial;
    }
  }
  li {
    display: inline-flex;
    font-size: ${(props) => props.theme.fontSize.base};
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    &:first-child .icon {
      display: inline-flex;
    }
    &#highlightedText {
      cursor: initial;
      .text:hover {
        text-decoration: none;
      }
    }
    .icon {
      margin-right: 16px;
    }
    .text {
      padding-right: 22px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  li:nth-child(2) {
    display: none;
  }
  .hide {
    display: none !important;
    width: 0;
  }
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  .home {
    cursor: ${(props: any) => (props?.children[1]?.props?.children?.length > 0 ? "pointer" : "default")};
    margin: 0 16px;
  }
`;

/**
 * @description get width of breadcrumbContainer
 */

/**
 * @description totalListWidth function
 *
 * Get the width of all li's
 */
const totalListWidth = () => {
  let totalWidth = 0;
  const breadcrumbElement = document.getElementById("breadcrumb");
  const liWidth = breadcrumbElement && breadcrumbElement.getElementsByTagName("li");
  const length = liWidth && liWidth.length;

  for (let index = 0; index < length; index++) {
    totalWidth += liWidth[index].clientWidth;
  }
  return totalWidth;
};

const totalListWidthLargeThanBreadcrumb = (element: HTMLElement, breadcrumbElement, breadcrumbElementCollection) => {
  for (let i = 2; i < breadcrumbElementCollection.length; i++) {
    breadcrumbElementCollection[i].classList.add("hide");
    const updatedWidth = totalListWidth();

    if (updatedWidth < breadcrumbElement.offsetWidth) {
      if (element) {
        element.style.display = "inline";
        element.classList.add("ellipse");
      }
      break;
    }
  }
};

/**
 * @description truncateBreadcrumb function
 *
 * truncate the 2 to nth breadcrumb and show ... in that place
 */
const truncateBreadcrumb = () => {
  const breadcrumbElement = document.getElementById("breadcrumb");
  const breadcrumbElementCollection = breadcrumbElement && breadcrumbElement.getElementsByTagName("li");
  const element: HTMLElement = document.querySelector("ul#breadcrumb li:nth-child(2)");

  if (breadcrumbElement && totalListWidth() > breadcrumbElement.offsetWidth) {
    totalListWidthLargeThanBreadcrumb(element, breadcrumbElement, breadcrumbElementCollection);
  } else {
    if (element) {
      element.style.display = "none";
      for (let i = 2; i < breadcrumbElementCollection.length; i++) {
        breadcrumbElementCollection[i].classList.remove("hide");
      }
      if (totalListWidth() > breadcrumbElement.offsetWidth) {
        truncateBreadcrumb();
      }
    }
  }
};

/**
 * @description GroupsBreadcrumb class
 *
 * @class GroupsBreadcrumb
 * @extends
 */
class GroupsBreadcrumb extends React.PureComponent<IGroupsBreadcrumbProps, any> {
  state = {
    breadcrumbWidth: 0,
  };
  /**
   * @description Create Breadcrumb
   *
   * @memberof GroupBreadcrumb
   */

  breadcrumbref = React.createRef<any>();
  componentDidMount() {
    this.breadcrumbContainer();
    truncateBreadcrumb();
  }

  componentDidUpdate(prevProps) {
    truncateBreadcrumb();
    if (this.props.selectedItems !== prevProps.selectedItems) {
      this.breadcrumbContainer();
      if (this.props.selectedItems.length === 0) {
        this.breadcrumbContainer();
      }
    }
  }

  handleHomeClick = () => {
    if (this.props.selectedItems.length > 0) {
      this.props.handleBlankSpace(0);
    }
  };
  breadcrumbContainer = (value = 0) => {
    const homeIconOffsetWidth = 60;
    const element = this.breadcrumbref.current && this.breadcrumbref.current.clientWidth;
    const width = value ? value : element != null && element - homeIconOffsetWidth;
    if (this.state.breadcrumbWidth !== width) {
      this.setState({ breadcrumbWidth: width });
    }
  };

  setBreadcrumbsRef = (element) => {
    this.breadcrumbref = element;
  };

  render() {
    const selectedItems = this.props.selectedItems || [];
    const collection: any = [...selectedItems];

    if (collection.length > 1) {
      collection.splice(1, 0, { isBlank: true });
    }
    const length = collection && collection.length - 1;

    return (
      <BreadcrumbContainer ref={this.setBreadcrumbsRef} id="breadcrumbContainer">
        <SquareIcon name={IconName.HomeIcon} onClick={this.handleHomeClick} className="home" />
        <BreadcrumbUl id="breadcrumb" style={{ width: this.state.breadcrumbWidth }}>
          {collection.map((item, index) =>
            item?.id ? (
              length === index ? (
                <li key={shortid.generate()} id="highlightedText">
                  <SquareIcon name={IconName.Chevron} className="icon" />
                  <FontBold className="text">{this.renderItem(item)}</FontBold>
                </li>
              ) : (
                <li
                  key={shortid.generate()}
                  onClick={() => {
                    if (!item.isBlank) {
                      this.props.handleRowClick(item.id, this.props.selectedItems[item.level].level, item.name, {
                        parentLocationId: item.parentLocationId,
                        type: item.type,
                      });
                    }
                  }}
                >
                  <SquareIcon name={IconName.Chevron} className="icon" />
                  <span className="text">{this.renderItem(item)}</span>
                </li>
              )
            ) : (
              ""
            ),
          )}
        </BreadcrumbUl>
      </BreadcrumbContainer>
    );
  }

  private renderItem(item: any) {
    return item.isBlank ? "..." : item.name;
  }
}

export default GroupsBreadcrumb;
