import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path
        d="M7,16 L7,20 L3,20 L3,16 L7,16 Z M21,16 L21,20 L9,20 L9,16 L21,16 Z M7,10 L7,14 L3,14 L3,10 L7,10 Z M21,10 L21,14 L9,14 L9,10 L21,10 Z M7,4 L7,8 L3,8 L3,4 L7,4 Z M21,4 L21,8 L9,8 L9,4 L21,4 Z"
        transform="translate(12.000000, 12.000000) scale(-1, -1) translate(-12.000000, -12.000000) "
      />
    </g>
  </svg>
);

export default SVG;
