import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import sharedConstants from "../../../../config";
import ApplicationConstants from "../../../../constant/applicationConstants";
import { IMultiSelectDropDownContainerProps } from "./iMultiSelectDropDownProps";
import MultiSelectDropDown from "./multiSelectDropdown";

/**
 * MultiSelectStyledDropDown styles for the multiSelectdropdown
 */
export const StyledMultiSelectDropDown = styled(MultiSelectDropDown)`
  margin-right: 16px;
  flex-shrink: 0;
  flex-grow: 0;
`;

/**
 * Container class for MultiSelectdropdown component
 */
export class MultiSelectDropdownContainer extends React.PureComponent<IMultiSelectDropDownContainerProps> {
  static defaultProps = {
    isDefault: false,
  };

  getSelectedText = () => {
    const { placeholder, selectedOptions } = this.props;
    if (placeholder && selectedOptions && selectedOptions.length === 1) {
      return selectedOptions[0].value;
    }
    return placeholder;
  };

  /**
   * @description setControlWidth will set the width on the basis of the name of the category
   */
  setControlWidth = () => {
    const { id } = this.props;
    const placeHolderText = this.getSelectedText();
    if (placeHolderText) {
      const element = document.getElementById(id);
      if (!element) {
        return null;
      }
      const widthOfEle = placeHolderText.length * ApplicationConstants.WIDTH.W10 + ApplicationConstants.WIDTH.W40; // 48 is for adding the padding and icons
      element.setAttribute(
        "style",
        `flex-basis:${widthOfEle}px; -webkit-flex-basis:${widthOfEle}px; width:${widthOfEle}px;`,
      );
    }
  };
  componentDidMount() {
    this.setControlWidth();
  }

  render() {
    const {
      clearButtonText,
      dateFormat,
      datePickerLocale,
      displayLoader,
      optionsCount,
      id,
      name,
      valueId,
      valueKey,
      placeholder,
    } = this.props;
    return this.props.isFormControl ? (
      <Field
        id={id || name}
        component={StyledMultiSelectDropDown}
        className="multiSelectControl"
        showSearchInput={optionsCount && optionsCount > sharedConstants.MAXIMUM_NUMBER_OF_DISPLAYED_VALUES}
        maximumNumberOfOptions={sharedConstants.MAXIMUM_NUMBER_OF_DISPLAYED_VALUES}
        {...this.props}
      />
    ) : (
      <StyledMultiSelectDropDown
        className="multiSelectControl"
        id={id}
        name={name}
        valueId={valueId}
        valueKey={valueKey}
        placeholder={placeholder}
        showSearchInput={optionsCount && optionsCount > sharedConstants.MAXIMUM_NUMBER_OF_DISPLAYED_VALUES}
        clearButtonText={clearButtonText}
        type={this.props.type}
        showClearButton={this.props.showClearButton}
        displayLoader={displayLoader}
        datePickerLocale={datePickerLocale}
        dateFormat={dateFormat}
        {...this.props}
      />
    );
  }
}

export default MultiSelectDropdownContainer;
