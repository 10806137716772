import { createReducer } from "../../../../utils/commonUtils";

const initialState = {
  templateInfo: {},
  multiAssetResponse: undefined,
  importHistory: null,
  geoTags: null,
  serviceImportHistory: null,
  importHistoryErrors: null,
} as any;

const recentlyUsedTemplatesResponse = (state, action) => {
  return {
    ...state,
    recentlyUsedTemplatesList: action.payload,
  };
};

const createAssetResponse = (state, action) => {
  return {
    ...state,
    createAssetResponse: action.payload,
  };
};

const createAssetError = (state, action) => {
  return {
    ...state,
    createAssetError: action.payload,
  };
};

const importHistoryResponse = (state, action) => {
  return {
    ...state,
    importHistory: action.payload,
  };
};

const serviceImportHistoryResponse = (state, action) => {
  return {
    ...state,
    serviceImportHistory: action.payload,
  };
};

const getImportHistoryErrors = (state, _action) => {
  return state;
};

const getLabelsList = (state, _action) => {
  return state;
};

const associateLabelsWithAsset = (state, _action) => {
  return state;
};

const getImportHistoryErrorsResponse = (state, action) => {
  return {
    ...state,
    importHistoryErrors: { ...state.importHistoryErrors, ...action.payload },
  };
};

const getServiceImportHistoryErrorsResponse = (state, action) => {
  return {
    ...state,
    importHistoryErrors: { ...state.importHistoryErrors, ...action.payload },
  };
};

const clearImportHistoryErrors = (state, _action) => {
  return {
    ...state,
    importHistoryErrors: null,
    importHistory: null,
  };
};

const clearServiceImportHistoryErrors = (state, _action) => {
  return {
    ...state,
    importHistoryErrors: null,
    serviceImportHistory: null,
  };
};

const setAssetGroupsResponse = (state, action) => {
  return {
    ...state,
    assetGroupsList: action.payload,
  };
};

const assetLocationsResponse = (state, action) => {
  return {
    ...state,
    assetLocationsList: action.payload,
  };
};

const getDefaultLocation = (state, action) => {
  return {
    ...state,
    defaultLocationList: action.payload,
  };
};

const setInventoryWorkersResponse = (state, action) => {
  return {
    ...state,
    inventoryWorkersList: action.payload,
    responsibleWorkersList: action.payload,
  };
};

const setResponsibleWorkersResponse = (state, action) => {
  return {
    ...state,
    responsibleWorkersList: action.payload,
  };
};

const setManufacturAndModelResponse = (state, action) => {
  return {
    ...state,
    templateInfo: action.payload,
  };
};

const setTemplateImageId = (state, action) => {
  return {
    ...state,
    templateInfo: {
      ...state.templateInfo,
      templateImageId: action.payload,
    },
  };
};

const assetAddEditResponse = (state, action) => {
  return {
    ...state,
    addEditResponse: action.addEditResponse,
    isTemplateCreated: action.addEditResponse.isTemplateCreated,
    supressEditAssetSuccessMessage: action.supressEditAssetSuccessMessage,
  };
};

const assetLabelsListResponse = (state, action) => {
  return {
    ...state,
    labelsList: action.payload,
  };
};

const setAssociateLabels = (state, action) => {
  return {
    ...state,
    associatedLabels: action.payload,
  };
};

const createAseetLabelResponse = (state, action) => {
  return {
    ...state,
    createdLabel: action.payload,
  };
};

const cleanAssetData = (state, _action) => {
  return {
    ...state,
    addEditResponse: null,
    labelsList: [],
  };
};

const cleanTemplateData = (state, _action) => {
  return {
    ...state,
    templateInfo: {},
  };
};

const cleanLabelData = (state, _action) => {
  return {
    ...state,
    labelsList: [],
  };
};

const resetAssetDefaultLocationsList = (state, _action) => {
  return {
    ...state,
    defaultLocationList: undefined,
  };
};

const resetAssetLocationsList = (state, _action) => {
  return {
    ...state,
    assetLocationsList: undefined,
  };
};

const resetInventoryWorkersList = (state, _action) => {
  return {
    ...state,
    inventoryWorkersList: undefined,
  };
};

const resetResponsibleWorkersList = (state, _action) => {
  return {
    ...state,
    responsibleWorkersList: undefined,
  };
};

const setManufacturerListResponse = (state, action) => {
  return {
    ...state,
    manufacturerList: action.payload,
  };
};

const multiEditResponse = (state, action) => {
  return {
    ...state,
    multiAssetResponse: action.payload,
  };
};

const resetMultiEditResponse = (state, _action) => {
  return {
    ...state,
    multiAssetResponse: undefined,
  };
};

const setGeoTags = (state, action) => {
  return {
    ...state,
    geoTags: action.payload,
  };
};

const addAssetReducer = createReducer(initialState, {
  AM_ASSET_LABELS_LIST_RESPONSE: assetLabelsListResponse,
  AM_ASSOCIATE_LABELS_WITH_ASSET: associateLabelsWithAsset,
  AM_ASSOCIATE_LABELS_WITH_ASSET_RESPONSE: setAssociateLabels,
  AM_CREATE_ASEET_LABEL: createAseetLabelResponse,
  AM_CREATE_ASEET_LABEL_RESPONSE: createAseetLabelResponse,
  AM_DELETE_ASEET_LABEL: setAssociateLabels,
  AM_GET_LABELS_LIST: getLabelsList,
  AM_RECENTLY_USED_TEMPLATES_RESPONSE: recentlyUsedTemplatesResponse,
  ASSET_ADD_EDIT_RESPONSE: assetAddEditResponse,
  ASSET_LOCATIONS_RESPONSE: assetLocationsResponse,
  CLEAN_ASSET_DATA: cleanAssetData,
  CLEAN_LABEL_DATA: cleanLabelData,
  CLEAN_TEMPLATE_DATA: cleanTemplateData,
  CLEAR_IMPORT_HISTORY_ERRORS: clearImportHistoryErrors,
  CREATE_ASSET_ERROR: createAssetError,
  CREATE_ASSET_RESPONSE: createAssetResponse,
  GET_DEFAULT_LOCATION: getDefaultLocation,
  GET_IMPORT_HISTORY_ERRORS: getImportHistoryErrors,
  GET_IMPORT_HISTORY_ERRORS_RESPONSE: getImportHistoryErrorsResponse,
  IMPORT_HISTORY_RESPONSE: importHistoryResponse,
  MULTI_EDIT_RESPONSE: multiEditResponse,
  RESET_ASSET_DEFAULT_LOCATIONS_LIST: resetAssetDefaultLocationsList,
  RESET_ASSET_LOCATIONS_LIST: resetAssetLocationsList,
  RESET_INVENTORY_WORKERS_LIST: resetInventoryWorkersList,
  RESET_MULTI_EDIT_RESPONSE: resetMultiEditResponse,
  RESET_RESPONSIBLE_WORKERS_LIST: resetResponsibleWorkersList,
  SET_ASSET_GROUPS_RESPONSE: setAssetGroupsResponse,
  SET_INVENTORY_WORKERS_RESPONSE: setInventoryWorkersResponse,
  SET_MANUFACTURER_AND_MODEL_RESPONSE: setManufacturAndModelResponse,
  SET_MANUFACTURER_LIST_RESPONSE: setManufacturerListResponse,
  SET_RESPONSIBLE_WORKERS_RESPONSE: setResponsibleWorkersResponse,
  SET_TEMPLATE_IMAGE_ID: setTemplateImageId,
  SET_GPS_TAGS_SERIAL: setGeoTags,
  GET_IMPORT_SERVICE_HISTORY_RESPONSE: serviceImportHistoryResponse,
  CLEAR_SERVICE_IMPORT_HISTORY_ERRORS: clearServiceImportHistoryErrors,
  GET_SERVICE_IMPORT_HISTORY_ERRORS_RESPONSE: getServiceImportHistoryErrorsResponse,
});

export default addAssetReducer;
