import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <polygon points="14 12.0920485 14 19 9.94974747 22 10 12.0920485 4 4 20 4" />
    </g>
  </svg>
);

export default SVG;
