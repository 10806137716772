const AssetServiceActionConstants = {
  ADD_ASSET_REMOVE_ASSET_SERVICE: "ADD_ASSET_REMOVE_ASSET_SERVICE",
  ADD_ASSET_SERVICE: "ADD_ASSET_SERVICE",
  AM_GET_ASSET_SERVICE_LIST: "AM_GET_ASSET_SERVICE_LIST",
  AM_GET_PROVIDER_LIST: "AM_GET_PROVIDER_LIST",
  AM_GET_SERVICE_FILTER_FIELD: "AM_GET_SERVICE_FILTER_FIELD",
  AM_PUT_ASSET_SERVICE_LIST: "AM_PUT_ASSET_SERVICE_LIST",
  AM_REMOVE_ASSET_SERVICE: "AM_REMOVE_ASSET_SERVICE",
  AM_SET_PROVIDER: "AM_SET_PROVIDER",
  ASSET_ASSIGNED_SERVICE_DATA_CLEAR: "ASSET_ASSIGNED_SERVICE_DATA_CLEAR",
  ASSET_HISTORY_SERVICE_SELECT_ROW: "ASSET_HISTORY_SERVICE_SELECT_ROW",
  ASSET_OPEN_SERVICE_SELECT_ROW: "ASSET_OPEN_SERVICE_SELECT_ROW",
  ASSET_SERVICES_COUNT: "ASSET_SERVICES_COUNT",
  ASSET_SERVICE_CLEAR_LIST: "ASSET_SERVICE_CLEAR_LIST",
  ASSET_SERVICE_DATA_CLEAR: "ASSET_SERVICE_DATA_CLEAR",
  ASSET_SERVICE_GET_LIST: "ASSET_SERVICE_GET_LIST",
  ASSET_SERVICE_PUT_LIST: "ASSET_SERVICE_PUT_LIST",
  ASSET_SERVICE_UNSELECT_ROW: "ASSET_SERVICE_UNSELECT_ROW",
  ASSET_TEMPLATE_SERVICE_FILTER_FIELD_SUCCESS: "ASSET_TEMPLATE_SERVICE_FILTER_FIELD_SUCCESS",
  ASSIGN_HISTORY_SERVICE: "ASSIGN_HISTORY_SERVICE",
  ASSIGN_HISTORY_SERVICE_SUCCESS: "ASSIGN_HISTORY_SERVICE_SUCCESS",
  ASSIGN_SERVICE_SUCCESS: "ASSIGN_SERVICE_SUCCESS",
  CLEAR_ASSIGNED_SERVICE_LIST: "CLEAR_ASSIGNED_SERVICE_LIST",
  CLEAR_COMPLETE_SERVICE_DATA: "CLEAR_COMPLETE_SERVICE_DATA",
  CLOSE_SERIVCE_MORE_FILTER: "CLOSE_SERIVCE_MORE_FILTER",
  COMPLETE_ASSIGNED_SERVICES: "COMPLETE_ASSIGNED_SERVICES",
  COMPLETE_ASSIGNED_SERVICES_SUCCESS: "COMPLETE_ASSIGNED_SERVICES_SUCCESS",
  EDIT_ASSIGNED_SERVICES: "EDIT_ASSIGNED_SERVICES",
  EDIT_ASSIGNED_SERVICES_SUCCESS: "EDIT_ASSIGNED_SERVICES_SUCCESS",
  GET_ASSET_TEMPLATE_SERVICE_FILTER_ATTRIBUTES: "GET_ASSET_TEMPLATE_SERVICE_FILTER_ATTRIBUTES",
  GET_ASSGIN_SERVICE_DETAIL: "GET_ASSGIN_SERVICE_DETAIL",
  GET_ASSGIN_SERVICE_DETAIL_SUCCESS: "GET_ASSGIN_SERVICE_DETAIL_SUCCESS",
  GET_ASSIGN_SERVICES: "GET_ASSIGN_SERVICES",
  GET_ASSIGN_SERVICES_SUCCESS: "GET_ASSIGN_SERVICES_SUCCESS",
  OPEN_SERIVCE_MORE_FILTER: "OPEN_SERIVCE_MORE_FILTER",
  RESET_SERVICE_QUERY_PARAM: "RESET_SERVICE_QUERY_PARAM",
  SERVICE_FILTER_FIELD_SUCCESS: "SERVICE_FILTER_FIELD_SUCCESS",
  SERVICE_OPERATION_ERROR: "SERVICE_OPERATION_ERROR",
  SET_ASSET_SERVICE_APPLIED_FILTER_ATTRIBUTES: "SET_ASSET_SERVICE_APPLIED_FILTER_ATTRIBUTES",
  SET_ASSET_TEMPLATE_SERVICE_APPLIED_FILTER_ATTRIBUTES: "SET_ASSET_TEMPLATE_SERVICE_APPLIED_FILTER_ATTRIBUTES",
  SET_ASSET_TEMPLATE_SERVICE_FILTER_QUERY: "SET_ASSET_TEMPLATE_SERVICE_FILTER_QUERY",
  SET_SERVICE_FILTER_QUERY: "SET_SERVICE_FILTER_QUERY",
  SET_SERVICE_SEARCH_QUERY: "SET_SERVICE_SEARCH_QUERY",
  SET_SERVICE_SORT_QUERY: "SET_SERVICE_SORT_QUERY",
  SHOW_MANAGE_SERVICES_FORM: "SHOW_MANAGE_SERVICES_FORM",
  SHOW_MANAGE_SERVICES_LIST: "SHOW_MANAGE_SERVICES_LIST",
};
export default AssetServiceActionConstants;
