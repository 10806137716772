import React from "react";
import shortid from "shortid";
import styled from "styled-components";
import { IMenuListProps } from "./iMenuListProps";
import { IMenuListState } from "./iMenuListState";

// @ts-ignore
import iconCross from "./cross_icon.svg";

const MenuFlyOut = styled.div`
  position: absolute;
  background: ${(props) => props.theme.colors.base.white};
  top: 65px;
  z-index: 999999;
  box-shadow:
    0 2px 12px 0 ${(props) => props.theme.colors.base.greyrgba},
    inset 0 1px 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
  left: 50%;
  transform: translateX(-50%);
`;

const CloseIcon = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: url(${(props: IMenuLayerProps) => props.crossIcon}) 0 0 no-repeat;
`;

const FadeLayer = styled.div`
  top: 94px;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: ${(props) => props.theme.colors.base.blackrgba};
  z-index: 999999;
`;

const MenuListWrapper = styled.div`
  padding: 56px 24px 20px;
  .sub-menu {
    button {
      padding: 10px 16px;
      width: 100%;
      text-align: left;
      font-family: ${(props) => props.theme.fontNormal.fontFamily};
      font-weight: ${(props) => props.theme.fontNormal.fontWeight};
      &:hover {
        background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
      }
    }
  }
`;

const MenuHeading = styled.h2`
  color: ${(props) => props.theme.colors.base.black};
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: 19px;
  border-bottom: 2px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  padding: 0 0 12px 16px;
`;

const FlyoutListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IMenuLayerProps {
  readonly crossIcon?: string;
}

export class TabMenu extends React.Component<IMenuListProps, IMenuListState> {
  /**
   * @description Function to close menu list
   * @param e : event with parameter target.id = closeIcon to close menuList
   * @memberof TabMenu
   */
  closeMenuList = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.id === "closeIcon") {
      this.props.toggleMenuListHandler();
    }
  };
  renderTabs = (item) => (
    <MenuListWrapper key={shortid.generate()}>
      <MenuHeading id={item.menuHead}>{item.menuHead}</MenuHeading>
      <ul className="sub-menu">
        {item.menuList.map((component, index) => (
          <li id={`${item.menuHead}${index}`} key={shortid.generate()}>
            {component}
          </li>
        ))}
      </ul>
    </MenuListWrapper>
  );

  render() {
    const { CheckAuthorization } = this.props;

    const list = this.props.list.map((item) => {
      return CheckAuthorization && item.authKey ? (
        <CheckAuthorization yes={() => this.renderTabs(item)} permissionKey={item.authKey} key={shortid.generate()} />
      ) : (
        this.renderTabs(item)
      );
    });
    return (
      <React.Fragment>
        <FadeLayer title={""} />
        <MenuFlyOut title={""} id="flyout" onClick={this.closeMenuList}>
          <CloseIcon id="closeIcon" crossIcon={iconCross} onClick={this.closeMenuList} />
          <FlyoutListWrapper>{list}</FlyoutListWrapper>
        </MenuFlyOut>
      </React.Fragment>
    );
  }
}

export default TabMenu;
