import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3182.000000, -3662.000000)">
        <g id="Deactivate" transform="translate(3180.000000, 3660.000000)">
          <rect id="Rectangle" x="0" y="0" width="24" height="24" />
          <path
            d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M18.1052611,8.57316318 L8.57316318,18.1052611 C9.58606664,18.6750178 10.7550815,19 12,19 C15.8659932,19 19,15.8659932 19,12 C19,10.7550815 18.6750178,9.58606664 18.1052611,8.57316318 Z M12,5 C8.13400675,5 5,8.13400675 5,12 C5,13.1658386 5.28500639,14.2651104 5.78914598,15.2319422 L15.2309402,5.78862364 C14.2643509,5.28480953 13.1654359,5 12,5 Z"
            id="Oval-2"
            fill="#524F53"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
