// @ts-ignore
import { UserLicenseActions } from "custom-actions";

const INITIAL_STATE = {
  licenseDetails: {
    activeModules: ["ASSET_COST"],
  },
  usageStatictics: {},
};

const userLicenseReducer = (state = INITIAL_STATE, action) => {
  if (action.type === UserLicenseActions.UI_UPDATE_USER_LICENSE_RESPONSE) {
    return {
      ...state,
      licenseDetails: action.payload.licenseDetails,
      usageStatictics: action.payload.usageStatictics,
    };
  } else {
    return state;
  }
};

export default userLicenseReducer;
