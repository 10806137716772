import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { IUtilizationDropdownProps } from "./iUtilizationDropdownProps";
import { Radio } from "@mui/material";
import styled from "styled-components";

/**
 * styles for the Select in react-select
 */
const StyledSelect = styled(Select)`
  width: 100%;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0;
  word-wrap: normal;
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: normal;
  ${(props) =>
    props["data-error"]
      ? props.theme.colors.base.hiltiRedError
      : props.disabled
        ? props.theme.colors.base.heavyConcrete20
        : props.theme.colors.base.heavyConcrete60};

  color: ${(props) => (props.disabled ? props.theme.colors.base.steel20 : props.theme.colors.base.steel)};
  background-color: ${(props) => (props.disabled ? props.theme.colors.base.steel40 : "#524f53")};
  border-radius: 0;
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    opacity: 1; /* Firefox */
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }
  .MuiSelect-select {
    color: ${(props) => props.theme.colors.base.white};
  }
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.colors.base.white};
  }
  .MuiOutlinedInput-root.MuiSelect-outlinedInput {
    border-radius: 0;
  }
  .MuiMenu-root > .MuiPaper-root {
    background-color: red;
  }
`;

const PeriodSelectionMenu = (props: IUtilizationDropdownProps) => {
  const { fullWidth, selectedOptions, options, onSelectionChange } = props;
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (event) => {
    const selectedElement = options.find((element) => event.target.value === element.value);

    if (selectedElement) {
      onSelectionChange([selectedElement]);
      setSelectedValue(selectedElement.label);
    }
  };

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      setSelectedValue(selectedOptions[0].label);
    }
  }, [selectedOptions]);

  return (
    <FormControl fullWidth={fullWidth} sx={{ mb: 1 }}>
      <StyledSelect
        defaultValue="30"
        id="utilizationDateFilter"
        value={selectedValue}
        onChange={handleChange}
        renderValue={(selected) => selected}
        MenuProps={{
          PaperProps: { style: { borderRadius: 0, width: !fullWidth ? 290 : "inherit" } },
          disableScrollLock: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ px: 1 }}>
            <Radio checked={selectedValue === option.label} disableRipple />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default PeriodSelectionMenu;
