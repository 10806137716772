import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import Gateway from "../gateway/gateway";

const getEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.UNITE, path, params);
};

export default class UniteApi {
  static getManagedFields = async (): Promise<any> => {
    const url = getEndpoint("/managed-fields");
    return Gateway.get(url, null, null, null, false) as unknown;
  };
}
