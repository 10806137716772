import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M16,2 L22,8 L22,16 L16,22 L8,22 L2,16 L2,8 L8,2 L16,2 Z M13,16 L11,16 L11,18 L13,18 L13,16 Z M13,7 L11,7 L11,14 L13,14 L13,7 Z" />
    </g>
  </svg>
);

export default SVG;
