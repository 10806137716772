import { HelperLodash } from "am-web-ui-shared/helpers";
import { IAssetsList } from "../assets.list";
import { AssetSubType, AssetType } from "../../utils/enums/assetEnum";
import { MonitorState } from "TARGET_BUILD/common/models/asset/monitoringState";

export default class AssetList {
  assetId: number;
  assetStateName: string;
  assetStatusName: any;
  assetType: { code: AssetType; value: string };
  assetSubType: { code: AssetSubType; value: string };
  batteryStatus: { code: string; value: string };
  category: string;
  currentLocationName: string;
  defaultLocationName: string;
  currentLocationTypeDetails: { code: string; value: string };
  defaultLocationTypeDetails: { code: string; value: string };
  description: string;
  gatewayStatus: { code: string; value: string };
  groupName: string;
  isCriticalFlag: boolean;
  inventoryNumber: number;
  labelNames: string;
  manufacturerName: string;
  assetStorage: string;
  model: string;
  name: string;
  ownershipTypeName: string;
  origin?: string;
  responsibleEmployeeName: string;
  scanCode: string;
  scanCodeType: string;
  serialNumber: string;
  previousSerialNumber: string;
  replacementSerialNumber: string;
  lastUpdate: string;
  inventoryNumberType: string;
  assetTemplateName: string;
  assetCategory: string;
  storageLocation: string;
  ownerName: string;
  ownerShipType: string;
  notes: string;
  assetUsageConditionState: string;
  assetUsageConditionDate: string;
  lastSeen: string;
  purchaseDate: string;
  purchasePrice: string;
  replacementCost: string;
  purchaseCurrencyCode: string;
  purchaseOrderNumber: string;
  warrantyExpirationDate: string;
  vendor: string;
  fleetDeliveryDate: string;
  fleetExchangeOrWarrantyDate: string;
  fleetExchangeStatus?: string;
  fleetInventoryNumber: string;
  fleetOrganizationReferenceNumber: string;
  fleetCostCenter: string;
  fleetPurchaseOrderNumber: string;
  monthlyFleetRate: string;
  fleetCurrencyCode: string;
  loanToolClaim: string;
  theftInsurance: string;
  rentalToolClaim: string;
  rentalStartDate: string;
  rentalReturnDate: string;
  loanStartDate: string;
  loanEndDate: string;
  costCode: string;
  responsibleEmployeeEmail: string;
  responsibleEmployeeDesignation: string;
  responsibleEmployeeLanguage: string;
  currentLocationManagerName: string;
  defaultLocationManagerName: string;
  defaultLocationCostCenter: string;
  currentLocationCostCenter: string;
  registeredBy: string;
  lastTransferDate: string;
  currentLocationId: number;
  responsibleEmployeeId: number;
  assetImageId: number;
  templateImageId: number;
  metaData: any;
  containsAnyAsset: boolean;
  childData: IAssetsList;
  path: string;
  totalChildCount: number;
  childCount: number;
  qtyItemCount: number;
  childCountWithQTYItem: number;
  assetCountWithChildData: number;
  hiltiAssetOwnerName: string;
  isSapAsset: boolean;
  totalQtyItemCount: number;
  ccmsNumber: string;
  materialNumber: number;
  hiltiAssetStatus: { code: string; value: string };
  nextServiceDate: string;
  lastServiceDate: string;
  idleTimeDuration: string;
  viewLabel: string;
  diameter?: string;
  isTrackable: boolean;
  monitoringState: MonitorState;
  originCustomerId: string;
  moCountryCode: string;

  constructor(response: any, skipHierarchy?) {
    this.assetId = AssetList.getFieldValue(response, "assetId");
    this.assetStateName = AssetList.getFieldValue(response, "assetStateResponse");
    this.assetStatusName = AssetList.getFieldValue(response, "assetStatusResponse");
    this.assetType = AssetList.getFieldValue(response, "assetType");
    this.assetSubType = AssetList.getFieldValue(response, "assetSubType");
    this.category = AssetList.getFieldValue(response, "category");
    this.currentLocationName = AssetList.getFieldValue(response, "currentLocationName");
    this.defaultLocationName = AssetList.getFieldValue(response, "defaultLocationName");
    this.isCriticalFlag = AssetList.getFieldValue(response, "isCriticalFlag");
    this.batteryStatus = AssetList.getFieldValue(response, "batteryStatus");
    this.description = AssetList.getFieldValue(response, "description");
    this.groupName = AssetList.getFieldValue(response, "groupName");
    this.gatewayStatus = AssetList.getFieldValue(response, "gatewayStatus");
    this.inventoryNumber = AssetList.getFieldValue(response, "inventoryNumber");
    this.labelNames = AssetList.getFieldValue(response, "labelNames");
    this.manufacturerName =
      !!AssetList.getFieldValue(response, "diameter") && !!AssetList.getFieldValue(response, "diameterUnit")
        ? `(${AssetList.getFieldValue(response, "diameter")} ${AssetList.getFieldValue(response, "diameterUnit")}) ` +
          AssetList.getFieldValue(response, "manufacturerName")
        : AssetList.getFieldValue(response, "manufacturerName");
    this.assetStorage = AssetList.getFieldValue(response, "assetStorage");
    this.model = AssetList.getFieldValue(response, "model");
    this.name = AssetList.getFieldValue(response, "name");
    this.ownershipTypeName = AssetList.getFieldValue(response, "ownershipTypeName");
    this.origin = AssetList.getFieldValue(response, "origin");
    this.responsibleEmployeeName = AssetList.getFieldValue(response, "responsibleEmployeeName");
    this.scanCode = AssetList.getFieldValue(response, "scanCode");
    this.serialNumber = AssetList.getFieldValue(response, "serialNumber");
    this.previousSerialNumber = response.previousSerialNumber;
    this.replacementSerialNumber = response.replacementSerialNumber;
    this.lastUpdate = AssetList.getFieldValue(response, "lastUpdate");
    this.scanCodeType = AssetList.getFieldValue(response, "scanCodeTypeDetails");
    this.inventoryNumberType = AssetList.getFieldValue(response, "inventoryNumberTypeDetails");
    this.assetTemplateName = AssetList.getFieldValue(response, "assetTemplateName");
    this.assetCategory = AssetList.getFieldValue(response, "assetCategoryResponse");
    this.storageLocation = AssetList.getFieldValue(response, "storageLocation");
    this.ownerName = AssetList.getFieldValue(response, "ownerName");
    this.ownerShipType = AssetList.getFieldValue(response, "ownerShipTypeResponse");
    this.notes = AssetList.getFieldValue(response, "notes");
    this.assetUsageConditionState = AssetList.getFieldValue(response, "assetUsageConditionState");
    this.assetUsageConditionDate = AssetList.getFieldValue(response, "assetUsageConditionDate");
    this.lastSeen = AssetList.getFieldValue(response, "lastSeen");
    this.purchaseDate = AssetList.getFieldValue(response.ownedAssetDetails, "purchaseDate");
    this.purchasePrice = AssetList.getFieldValue(response.ownedAssetDetails, "purchasePrice");
    this.replacementCost = AssetList.getFieldValue(response, "replacementCost");
    this.purchaseCurrencyCode = AssetList.getFieldValue(response.ownedAssetDetails, "purchaseCurrencyCode");
    this.purchaseOrderNumber = AssetList.getFieldValue(response.ownedAssetDetails, "purchaseOrderNumber");
    this.warrantyExpirationDate = AssetList.getFieldValue(response.ownedAssetDetails, "warrantyExpirationDate");
    this.vendor = AssetList.getFieldValue(response.ownedAssetDetails, "vendor");
    this.fleetDeliveryDate = AssetList.getFieldValue(response.fleetAssetDetails, "fleetDeliveryDate");
    this.fleetExchangeOrWarrantyDate = AssetList.getFieldValue(
      response.fleetAssetDetails,
      "fleetExchangeOrWarrantyDate",
    );
    this.fleetInventoryNumber = AssetList.getFieldValue(response.fleetAssetDetails, "fleetInventoryNumber");
    this.fleetOrganizationReferenceNumber = AssetList.getFieldValue(
      response.fleetAssetDetails,
      "fleetOrganizationReferenceNumber",
    );
    this.fleetExchangeStatus = AssetList.getFieldValue(response.fleetAssetDetails, "fleetExchangeStatus");
    this.fleetCostCenter = AssetList.getFieldValue(response.fleetAssetDetails, "fleetCostCenter");
    this.fleetPurchaseOrderNumber = AssetList.getFieldValue(response.fleetAssetDetails, "fleetPurchaseOrderNumber");
    this.monthlyFleetRate = AssetList.getFieldValue(response.fleetAssetDetails, "monthlyFleetRate");
    this.fleetCurrencyCode = AssetList.getFieldValue(response.fleetAssetDetails, "fleetCurrencyCode");
    this.loanToolClaim = AssetList.getFieldValue(response.fleetAssetDetails, "loanToolClaim");
    this.theftInsurance = AssetList.getFieldValue(response.fleetAssetDetails, "theftInsurance");
    this.rentalToolClaim = AssetList.getFieldValue(response.rentedAssetDetails, "rentalToolClaim");
    this.rentalStartDate = AssetList.getFieldValue(response.rentedAssetDetails, "rentalStartDate");
    this.rentalReturnDate = AssetList.getFieldValue(response.rentedAssetDetails, "rentalReturnDate");
    this.loanStartDate = AssetList.getFieldValue(response.loanedAssetDetails, "loanStartDate");
    this.loanEndDate = AssetList.getFieldValue(response.loanedAssetDetails, "loanEndDate");
    this.costCode = AssetList.getFieldValue(response, "costCode");
    this.responsibleEmployeeEmail = AssetList.getFieldValue(response, "responsibleEmployeeEmail");
    this.responsibleEmployeeDesignation = AssetList.getFieldValue(response, "responsibleEmployeeDesignation");
    this.responsibleEmployeeLanguage = AssetList.getFieldValue(response, "responsibleEmployeeLanguage");
    this.currentLocationManagerName = AssetList.getFieldValue(response, "currentLocationManagerName");
    this.defaultLocationManagerName = AssetList.getFieldValue(response, "defaultLocationManagerName");
    this.defaultLocationCostCenter = AssetList.getFieldValue(response, "defaultLocationCostCenter");
    this.currentLocationCostCenter = AssetList.getFieldValue(response, "currentLocationCostCenter");
    this.registeredBy = AssetList.getFieldValue(response, "registeredBy");
    this.lastTransferDate = AssetList.getFieldValue(response, "lastTransferDate");
    this.currentLocationId = AssetList.getFieldValue(response, "currentLocationId");
    this.responsibleEmployeeId = AssetList.getFieldValue(response, "responsibleEmployeeId");
    this.assetImageId = AssetList.getFieldValue(response, "assetImageId");
    this.templateImageId = AssetList.getFieldValue(response, "templateImageId");
    this.metaData = response.metaData || {};
    this.childData = response.childData;
    this.path = response.path;
    this.totalChildCount = response.totalChildCount || 0;
    this.childCount = response.childCount || 0;
    this.childCountWithQTYItem = AssetList.getTotalChildCount(response.childCount, response.totalQtyItemCount);
    this.assetCountWithChildData = AssetList.getTotalChildWithAllocation(
      response.childCount,
      response.totalAllocationsCount,
    );
    this.qtyItemCount = response.immediateQtyItemCount || 0;
    this.metaData.skipHierarchy = skipHierarchy;
    this.hiltiAssetOwnerName = response.hiltiAssetOwnerName;
    this.isSapAsset = response.isSapAsset;
    this.containsAnyAsset = response.containsAnyAsset;
    this.totalQtyItemCount = response.totalQtyItemCount || 0;
    this.currentLocationTypeDetails = response.currentLocationTypeDetails;
    this.defaultLocationTypeDetails = response.defaultLocationTypeDetails;
    this.materialNumber = AssetList.getFieldValue(response, "materialNumber");
    this.ccmsNumber = AssetList.getFieldValue(response, "ccmsNumber");
    this.hiltiAssetStatus = AssetList.getFieldValue(response, "hiltiAssetStatus");
    this.nextServiceDate = AssetList.getFieldValue(response, "nextServiceDate");
    this.lastServiceDate = AssetList.getFieldValue(response, "lastServiceDate");
    this.idleTimeDuration = AssetList.getFieldValue(response, "idleTimeDuration");
    this.viewLabel = AssetList.getFieldValue(response, "viewLabel") || "";
    this.isTrackable = response.isTrackable || false;
    this.monitoringState = response.monitoringState || MonitorState.OFF;
    this.originCustomerId = response.originCustomerId;
    this.moCountryCode = response.moCountryCode;
  }
  /*
   * @description: returns the value of the field in the asset object or empty string.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static getFieldValue = (asset, field: string) => HelperLodash.get(asset, field, "");

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static getTotalChildCount = (childCount = 0, totalQtyItemCount = 0) => {
    return childCount + totalQtyItemCount;
  };

  // eslint-disable-next-line
  static getTotalChildWithAllocation = (childCount = 0, totalQtyAllocationCount = 0) => {
    return childCount + totalQtyAllocationCount + 1; // 1 added for selected asset
  };

  // eslint-disable-next-line
  static getTotalChildWithQtyItem = (childCount = 0, totalQtyItemCount = 0) => {
    return childCount + totalQtyItemCount + 1; // 1 added for selected asset
  };
}
