import { IconName, SquareIcon } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React, { useEffect } from "react";
import { StyledBeforeIconContainer, StyledNormalText, StyledRowsWrapper } from "../../index";
import IInventoryProps from "./iInventory";
import { useOneMapStore } from "TARGET_BUILD/common/modules/oneMap/store/oneMapStore";

const getFont = (props) => (props.disabled ? "" : props.theme.fontBold.fontFamily);
const getColor = (props) => (props.disabled ? props.theme.boldSteelText.color : props.theme.colors.headingText.color);
/**
 * @const StyledWrapper - styled wrapper
 */
const BoldWrapper = styled.div`
  font-family: ${getFont};
  color: ${getColor};
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const IconStyle = styled.div`
  cursor: ${(props) => !props.disabled && "pointer"};
`;

const ExStyledBeforeIconContainer = styled(StyledBeforeIconContainer)`
  cursor: ${(props) => !props.disabled && "pointer"};
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.base.heavyConcrete40 : props.theme.detailsPanel.detailBackground}};
`;

const ExStyledRowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 16px;
`;

const Inventory = (props: IInventoryProps) => {
  const {
    id,
    normalId,
    normalText,
    disabled,
    valueId,
    valueText,
    onItemClick,
    "data-testid": dataTestId,
    hasSmartInventoryInDialog = true,
    sectionName = null,
  } = props;
  return (
    <ExStyledBeforeIconContainer
      id={id}
      className="inventory-wrapper"
      isSelected={id === sectionName}
      onClick={disabled ? null : () => onItemClick(id)}
    >
      <ExStyledRowsWrapper>
        <StyledNormalText>
          <StyledNormalText id={normalId} className="label-text">
            {normalText}
          </StyledNormalText>
        </StyledNormalText>
        <TextWrapper>
          <BoldWrapper disabled={disabled} id={valueId}>
            {valueText}
          </BoldWrapper>
          <IconStyle disabled={disabled}>
            {hasSmartInventoryInDialog ? (
              <SquareIcon
                data-testid={dataTestId}
                name={IconName.Chevron}
                className={disabled ? "disabled path" : "path"}
              />
            ) : (
              <SquareIcon
                data-testid={dataTestId}
                name={IconName.Target}
                className={disabled ? "disabled path" : "path"}
              />
            )}
          </IconStyle>
        </TextWrapper>
      </ExStyledRowsWrapper>
    </ExStyledBeforeIconContainer>
  );
};

/**
 * @export Inventory - Styled Component.
 */
export default Inventory;
