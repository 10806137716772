import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M21,21 L15,21 L15,18 L18,18 L18,15 L21,15 L21,21 Z M6,15 L6,18 L9,18 L9,21 L3,21 L3,15 L6,15 Z M9,3 L9,6 L6,6 L6,9 L3,9 L3,3 L9,3 Z M21,3 L21,9 L18,9 L18,6 L15,6 L15,3 L21,3 Z" />
    </g>
  </svg>
);

export default SVG;
