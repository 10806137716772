import React from "react";
import styled from "styled-components";
import HierarchyControl from "./hierarchyControl";
import { IDropDownMenuProps, IDropDownMenuState } from "./iDropdownMenu";
/**
 * @description MenuListContainer to style the MenuListContainer
 */
export const MenuListContainer = styled("div").attrs(() => ({
  id: "menuListContainer",
}))`
  color: ${(props) => props.theme.colors.base.steel};
  box-shadow: 0 2px 12px 0px ${(props) => props.theme.colors.base.greyrgba};
  max-width: calc(100vw - 357px);
  background: ${(props) => props.theme.colors.base.white};
  z-index: 999999 !important;
  & .optionWrapper {
    box-shadow: inset 0px 2px 3px 0 ${(props) => props.theme.colors.base.greyrgba};
    overflow: hidden;
  }
`;
/**
 * DropDownMenu component for the dropdown of a category filter
 */
class DropDownMenu extends React.PureComponent<IDropDownMenuProps, IDropDownMenuState> {
  containerRef: any;
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.state = {
      isInitalLoad: false,
      selectedId: this.props.selectedEntityGroupId,
    };
  }

  /**
   * method to identify if it is clicked inside the component
   */
  isClickedOnComponent = (target) => {
    const eleArr = this.containerRef.current && Array.from(this.containerRef.current.children);
    const arr = eleArr.filter((child: any) => child.contains(target));
    return arr.length > 0;
  };

  /**
   * onDocumentClick to disable focus while click outside
   */
  onDocumentClick = (e) => {
    if (this.isClickedOnComponent(e.target)) {
      this.props.inputFocus(); // open
    } else {
      this.props.disableFocus(); // close
    }
  };

  /**
   * handle on mouse down event on search input box
   *
   * @param e current event
   */
  onMouseDown = (e: any) => {
    e.stopPropagation();
    e.target.focus();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentClick);
  }

  /**
   * code to remove selection
   */
  handleRemoveSelection = () => {
    let lastId = 0;
    let level = 0;
    const tempLastSelectedItems = this.props.selectedItems && this.props.selectedItems.slice(0, -1);

    this.props.setSelectedItems(tempLastSelectedItems);

    if (tempLastSelectedItems && tempLastSelectedItems.length > 0) {
      const tempLastSelectedItem = tempLastSelectedItems[tempLastSelectedItems.length - 1];
      lastId = tempLastSelectedItem.id;
      level = tempLastSelectedItem.level;
    }
    const entityGroupsList = this.props.entityGroupsList;
    const tempItems = entityGroupsList.slice(0, level + 1);
    if (lastId && this.props.updateGroupListAndGetChildren) {
      this.props.updateGroupListAndGetChildren(tempItems, lastId);
    } else {
      this.props.getEntityGroups(lastId, true);
      this.props.disableFocus();
    }
    this.setState({ isInitalLoad: false, selectedId: lastId });
  };

  /**
   * handle click on row in the control
   */
  handleRowClick = (id: number, level: number, name: string, entityInfo: any) => {
    let isSelected = false;
    const tempSelectedItems = this.props.selectedItems.slice(0);
    if (tempSelectedItems && tempSelectedItems.length > 0) {
      isSelected = level === 0 && id === tempSelectedItems[tempSelectedItems.length - 1].id;
    }
    if (!isSelected) {
      tempSelectedItems.length = level + 1;
      tempSelectedItems[tempSelectedItems.length - 1] = {
        id,
        level,
        locationDetails: entityInfo,
        name,
        parentLocationId: entityInfo.parentLocationId,
      };
      if (tempSelectedItems.length > 0 && tempSelectedItems[0]) {
        const tempItems = this.props.entityGroupsList;
        tempItems.length = level + 1;
        this.props.setSelectedItems(tempSelectedItems);
        this.setState({ isInitalLoad: false, selectedId: id });
        if (this.props.updateGroupListAndGetChildren) {
          this.props.updateGroupListAndGetChildren(tempItems, id);
        } else {
          this.props.disableFocus();
        }
      }
    } else {
      this.handleRemoveSelection();
    }
  };

  render() {
    return (
      <MenuListContainer ref={this.containerRef}>
        <div className="optionWrapper">
          <HierarchyControl
            getEntityGroups={this.props.getEntityGroups}
            setSelectedItems={this.props.setSelectedItems}
            defaultId={this.props.defaultId}
            selectedEntityGroupId={this.state.selectedId}
            isAdmin={this.props.isAdmin}
            entityGroupsList={this.props.entityGroupsList}
            selectedItems={this.props.selectedItems}
            handleRowClick={this.handleRowClick}
            isInitalLoad={this.state.isInitalLoad}
            bindProperties={this.props.bindProperties}
            filterContainerRef={this.props.filterContainerRef}
            suggestionSearch={this.props.suggestionSearch}
            inputFocus={this.props.inputFocus}
            disableFocus={this.props.disableFocus}
            isPaging={this.props.isPaging}
            getPaginatedData={this.props.getPaginatedData}
            t={this.props.t}
            tReady={this.props.tReady}
            i18n={this.props.i18n}
            maxHeight={this.props.maxHeight}
          />
        </div>
      </MenuListContainer>
    );
  }
}
export default DropDownMenu;
