import React from "react";
import styled from "styled-components";
import IRedTextIconProps from "./iRedTextIcon";

/**
 * @const RedTextIcon is a Styled Component that consist of 2 things:
 * 1. Bold Red color text which is either simple or concatinated.
 * 2. Icon after the text.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 */
const RedText = styled("div")`
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  color: ${(props) => props.theme.colors.base.hiltiRed};
  width: calc(100% - 32px);
  align-items: unset;
  word-break: break-all;
  max-width: 400px;
  font-size: ${(props) => props.theme.fontSize.base};
  padding-bottom: 8px;
  @media (max-width: 1280px) {
    max-width: 250px;
  }
`;

const RedTextIcon = (props: IRedTextIconProps) => <RedText id={props.RedNormalId}>{props.RedNormalText}</RedText>;

/**
 * @export RedTextIcon - Styled Component.
 */
export default RedTextIcon;
