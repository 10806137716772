import { Dialog, Footer, ListContainer } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
import AssetsList from "../../../../asset/components/list/presentational/assetsList";
import AssetSummary from "../../../../asset/components/list/presentational/assetsSummary";
import { AssetKeys } from "../../../../../utils/enums/assetEnum";
import { getCheckedRowsInfo, getSelectedRowDetail } from "../../../../../utils/list/listUtils";
import ErrorBoundary from "../../../../../components/errorBoundary/errorBoundary";
import { ActionBarSettings } from "../models/actionBarSetting";
import { gatewayAssetsGridSetting, ignoreSortProperties } from "../models/gridSettings";
import { ISmartInventoryAssetsProps } from "./iSmartInventoryAssetsProps";
import { LocationInfo } from "./locationInfo";
import GatewayAssetsFilter from "./smartInventoryAssetsFilter";
import { InfiniteScrollLoadEnd } from "am-web-ui-shared/components";
import { isGatewayOffline, isOfflineInventoryEnabled } from "./smartInventoryUtils";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import AssetList from "TARGET_BUILD/common/models/asset/assetList";
import SmartInventoryAssetDetails from "./smartInventoryAssetDetails";

const Wrapper = styled.div`
  margin: 0 auto;
  width: 996px;
`;

const OfflineWrapper = styled.div`
  margin-top: 40px;
`;

const AssetListBody = styled.div`
  overflow: auto;
  flex: 1;
  min-height: 0;
  position: relative;
  height: calc(100vh - 360px);
`;

const SummaryContainer = styled.div.attrs({
  id: "gatewayAssetsSummary",
})``;

const ListWithSidebar = styled.div`
  display: flex;
  width: 100%;
  &.open-sidebar {
    width: calc(100% - 315px);
  }
`;

export const SidePanelContainer = styled.div`
  min-height: 40vh;
  overflow: auto;
  & #sidePanelWrapper {
    height: calc(100% - 65px);
  }
`;

export class SmartInventoryAssetsView extends React.PureComponent<ISmartInventoryAssetsProps> {
  listScrollRef;

  assetFields = [
    {
      code: "scanCode",
      value: this.props.t("fieldLabels:SCANCODE"),
    },
    {
      code: "currentLocationName",
      value: this.props.t("fieldLabels:CURRENTLOCATIONNAME"),
    },
    {
      code: "manufacturerName",
      value: this.props.t("fieldLabels:MANUFACTURERNAME"),
    },
    {
      code: "manufacturerName/model",
      value: this.props.t("fieldLabels:MANUFACTURERNAMEMODEL"),
    },
    {
      code: "inventoryNumber",
      value: this.props.t("fieldLabels:INVENTORYNUMBER"),
    },
    {
      code: "name",
      value: this.props.t("fieldLabels:NAME"),
    },
    {
      code: "model",
      value: this.props.t("fieldLabels:MODEL"),
    },
    {
      code: "responsibleEmployeeName",
      value: this.props.t("fieldLabels:RESPONSIBLEEMPLOYEEID"),
    },
  ];

  closeDialog = () => {
    this.props.toggleAssetListDialog(false);
    this.props.resetQueryObjToDefault();
  };

  getHeaderText = (type, t, header) => {
    return `${header}: ${t("locations:" + type)} ${t("assets:ASSETS")}`;
  };

  /*
    using callback ref to set the DOM reference
  */
  setScrollRef = (ele) => {
    this.listScrollRef = ele;
  };

  getPaginationList = (info) => {
    const { assetType, getList, id } = this.props;
    const options = {
      id: id,
      statsType: assetType,
      offset: info.offset,
    };
    getList(options);
  };

  fetchList = () => {
    this.getPaginationList({ offset: 0 });
  };

  getSelectAllAssets = () => {
    const { assetType, gatewayAssetsList, getList, id } = this.props;
    // We are getting offset = 0 when first time page is loaded so we need to add limit in offset to make offset 100 for the next call
    // same logic repeat for upcomming requests
    const offset = gatewayAssetsList.offset + gatewayAssetsList.limit;
    const options = {
      id: id,
      statsType: assetType,
      offset: offset,
      limit: gatewayAssetsList.totalRecords - offset,
      isTransfer: true,
    };
    getList(options);
  };

  doNothing = () => {
    return null;
  };

  onRowSelection = (asset: AssetList) => {
    if (this.props.onSelectRow) {
      this.props.onSelectRow(asset);
    }
  };

  render() {
    const {
      assetType,
      columnConfig,
      filterAttributes,
      gatewayAssetsList,
      getFilterAttributes,
      getList,
      header,
      onCheckedRow,
      onUncheckedRow,
      setFilterQuery,
      id,
      t,
      listLoader,
      parentCount,
      rowData,
      sortQuery,
      setSortQuery,
      selectAllAssets,
      onClearAllSelection,
      onSelectAll,
      transferCartList,
      setTransferCartItemToStore,
      showToast,
      isAllSelected,
      appliedFilterQuery,
      filterFormValues,
      changeFilterFormValues,
      setFilterAppliedAttributes,
      toggleAssetListDialog,
    } = this.props;

    const selectedAsset = SingletonFeatureManager.isFeatureEnabled(FEATURES.PAT_SMART_INVENTORY_LOCATION_DETAILS)
      ? getSelectedRowDetail(gatewayAssetsList?.list)
      : undefined;

    const filterProps = {
      appliedFilterQuery,
      assetType,
      changeFilterFormValues,
      filterAttributes,
      filterFormValues,
      getFilterAttributes,
      getList,
      id,
      setFilterAppliedAttributes,
      setFilterQuery,
    };
    const listProps = {
      assetFields: this.assetFields,
      assetStatusList: true,
      assetsList: gatewayAssetsList && gatewayAssetsList.list,
      closeMoreFilter: this.doNothing,
      columnConfig: gatewayAssetsGridSetting.columns,
      listLoader,
      listScrollRef: this.listScrollRef,
      onRowSelection: this.onRowSelection,
      onCheckedRow,
      onUncheckedRow,
      paginationInfo: {
        getList: this.getPaginationList,
        limit: gatewayAssetsList.limit,
        offset: gatewayAssetsList.offset,
      },
      parentCount,
      showNoRecordFoundView: false,
      showTooltip: gatewayAssetsGridSetting.showTooltip,
      isCustomRefScroll: true,
      lazyLoadList: false,
    };

    const summaryProps = {
      actionBarSettings: ActionBarSettings,
      assetFields: this.assetFields,
      assetStatusList: null,
      checkedAssetListInfo: getCheckedRowsInfo(
        gatewayAssetsList && gatewayAssetsList.list,
        AssetKeys,
        gatewayAssetsList.totalRecords,
      ),
      cleanAssetData: null,
      cleanLabelData: null,
      closeMoreFilter: this.doNothing,
      columns: gatewayAssetsGridSetting.columns,
      createdLabel: null,
      defaultSort: sortQuery,
      deleteAssets: null,
      deleteLabels: null,
      fetchList: this.fetchList,
      gatewayAssetsList,
      getAssetFilterAttributes: null,
      getAssociatedLabels: null,
      getLabelsData: null,
      getSelectAllAssets: this.getSelectAllAssets,
      ignoreSortProperties,
      isAllSelected,
      isTransferedAssetDeletionEnabled: null,
      length: parentCount,
      list: gatewayAssetsList && gatewayAssetsList.list,
      onClearAllSelection,
      onSelectAll,
      resetMultiDeleteResponse: null,
      saveCustomGridView: null,
      selectAllAssets,
      setCustomViewModifiedFlag: this.doNothing,
      setSortQuery,
      setTransferCartItemToStore,
      toggleAssetListDialog,
      showToast,
      transferCartList,
      updateUniqueAssetStatus: null,
      userMeData: null,
      companyDetails: null,
    };

    return (
      <Dialog
        containerId="assetViewContainer"
        headerId="assetViewHeader"
        onClose={this.closeDialog}
        headerText={this.getHeaderText(assetType, t, header)}
      >
        <ErrorBoundary>
          <LocationInfo rowData={rowData} t={t} columnConfig={columnConfig} />
        </ErrorBoundary>
        <Wrapper>
          {!isOfflineInventoryEnabled() && isGatewayOffline(gatewayAssetsList) ? (
            <OfflineWrapper>
              <InfiniteScrollLoadEnd
                loadEnd={t("locations:GATEWAY_INVENTORY_AVAILABLE_ONLINE")}
              ></InfiniteScrollLoadEnd>
            </OfflineWrapper>
          ) : (
            <>
              <ErrorBoundary>
                <GatewayAssetsFilter {...filterProps} />
              </ErrorBoundary>
              {gatewayAssetsList?.totalRecords === 0 || gatewayAssetsList?.list?.length === 0 ? (
                <OfflineWrapper>
                  <InfiniteScrollLoadEnd
                    loadEnd={t("locations:GATEWAY_INVENTORY_NOT_AVAILABLE")}
                  ></InfiniteScrollLoadEnd>
                </OfflineWrapper>
              ) : (
                <>
                  <ErrorBoundary>
                    <SummaryContainer>
                      <AssetSummary {...summaryProps} />
                    </SummaryContainer>
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <ListContainer>
                      <ListWithSidebar>
                        <AssetListBody
                          id="assetsListBody"
                          ref={this.setScrollRef}
                          className={selectedAsset ? "open-sidebar" : ""}
                        >
                          <AssetsList {...listProps} />
                        </AssetListBody>
                        {selectedAsset && (
                          <SmartInventoryAssetDetails
                            assetId={selectedAsset.assetId}
                            onBeforeRedirect={this.closeDialog}
                          />
                        )}
                      </ListWithSidebar>
                    </ListContainer>
                  </ErrorBoundary>
                </>
              )}
            </>
          )}
        </Wrapper>
        <Footer buttonType="cta" buttonText={t("common:DONE")} onClick={this.closeDialog} />
      </Dialog>
    );
  }
}

export default withTranslation()(SmartInventoryAssetsView);
