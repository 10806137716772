import ControlType from "TARGET_BUILD/common/components/filter/controlType";
import FilterOperator from "TARGET_BUILD/common/components/filter/filterOperator";

const multiSelectType = {
  moreFilter: ControlType.CheckboxGroup,
  searchBar: ControlType.MultiSelectDropdown,
};

export default [
  {
    filterDisplayName: "filters:ASSIGNED_LOCATION",
    filterName: "assignedLocationName",
    operator: FilterOperator.IN,
    type: multiSelectType,
  },
  {
    filterDisplayName: "filters:TRACKED_LOCATION",
    filterName: "trackedLocationName",
    operator: FilterOperator.IN,
    type: multiSelectType,
  },
  {
    filterDisplayName: "filters:RESPONSIBLE_WORKER",
    filterName: "responsibleEmployeeName",
    operator: FilterOperator.IN,
    type: multiSelectType,
  },
  {
    filterDisplayName: "filters:MANUFACTURER_NAME",
    filterName: "manufacturerName",
    operator: FilterOperator.IN,
    type: multiSelectType,
  },
  {
    filterDisplayName: "filters:MODEL",
    filterName: "model",
    operator: FilterOperator.IN,
    type: multiSelectType,
  },
  {
    filterDisplayName: "filters:DEFAULT_LOCATION",
    filterName: "defaultLocationName",
    operator: FilterOperator.IN,
    type: multiSelectType,
  },
];
