import React, { Component } from "react";
import styled from "styled-components";
import { HelperLodash, LocaleManager } from "../../../../helpers";
import DateRangePicker from "../../../../elements/datePicker/dateRangePicker";
import LoaderElement from "../../../../elements/loader/loaderElement";
import ControlType from "../../controlType";
import { getIntersectionOptions } from "../../filterUtils";
import DataRangeDropdownControl from "../dataRangeDropdown/dataRangeDropdownControl";
import MultiSelectCheckboxFilter from "../filterMenuComponents/multiSelectChecboxFilter";
import RadioSelectFilter from "../filterMenuComponents/radioSelectionFilter";
import HierarchicalDropDownMenu from "../hierarchicalDropdown/dropdownMenu";
import SelectDropdown from "../selectDropdown";
import DropDownMenu from "./dropdownMenu";
import { IMultiSelectDropDownMenuState, IMultiSelectDropDownProps } from "./iMultiSelectDropDownProps";
import {
  Control,
  CustomValueContainer,
  DropdownIndicator,
  NoOptionsMessage,
  Placeholder,
} from "./multiSelectDropdownComponents";

/**
 * @description MultiSelectValueContainer to style the multiselect container
 */
const MultiSelectValueContainer = styled.div`
  display: none;
  &:first-child {
    display: inline-block;
  }
`;

/**
 * @description CountBadge to style the CountBadge
 */
export const CountBadge = styled.div`
  background-color: ${(props) => props.theme.colors.base.steel};
  border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete10};
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  line-height: 18px;
  position: absolute;
  top: -13px;
  right: -48px;
  color: ${(props) => props.theme.colors.base.white};
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 0 6px;
`;

/**
 * @description PlaceholderValue to style the PlaceholderValue
 */
export const PlaceholderValue = styled.div`
  &.selected {
    color: ${(props) => props.theme.colors.base.white};
    padding-top: 9px;
    padding-bottom: 9px;
  }
`;

const DateRangePickerWrapper = styled.div`
  box-shadow: 0 2px 12px 0px rgba(82, 79, 83, 0.5);
  max-width: calc(100vw - 332px);
  z-index: 999999 !important;
`;

export const Loader = styled.div`
  max-height: calc(100vh - 200px);
  overflow: hidden;
  padding: 32px 0;
  position: relative;
  box-sizing: border-box;
  width: 400px;
  box-shadow: 0 2px 12px 0px rgba(82, 79, 83, 0.5);
  background: ${(props) => props.theme.colors.base.white};
  z-index: 1000;
`;
const MenuListWrapper = styled.div`
  display: ${(props: { displayLoader?: boolean }) => (props.displayLoader ? "none" : "block")};
`;

/**
 * @class MultiSelectDropDown component for multiselect
 */
class MultiSelectDropDown extends Component<IMultiSelectDropDownProps, IMultiSelectDropDownMenuState> {
  static defaultProps = {
    showClearButton: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      count: this.props.count || this.getCount(this.props.selectedOptions) || 0,
      isFocused: false,
      label: null,
      searchValue: "",
      selectedValue: this.getSelectedValue(),
    };
  }

  /**
   * method to get slected filter
   */
  getSelectedValue() {
    const { searchValue } = this.state || {};
    const { selectedOptions: allSelectedOptions, type, options, displayProperty } = this.props;
    if (allSelectedOptions) {
      if (type === ControlType.MultiSelectDropdown) {
        if (searchValue) {
          // don't filter options in search mode
          return allSelectedOptions;
        }
        return this.checkEmptyList(getIntersectionOptions(allSelectedOptions, options));
      } else if (type === ControlType.RadioSelectDropdown) {
        // the radio select filter take initialFilterValue/selectedFilter in format ["VALUE"]
        // this results in allSelectedOptions={ label: "VALUE", value: "VALUE"}
        // however, unlike other multiselect filter where only value is important, the radio
        // filter has significance of label and value, hence making it { label: "Actual label", value: "VALUE"}
        return this.getRadioSelectDropdownOptions();
      } else if (options && options.length > 0 && displayProperty) {
        return this.getHierarchyControlOptions();
      }
    }

    return this.checkEmptyList(allSelectedOptions);
  }

  checkEmptyList = (list) => {
    return list || [];
  };
  /**
   * Method to get hierarchical control options
   */
  getHierarchyControlOptions = () => {
    const { selectedOptions: allSelectedOptions, hierarchyControlProps, options } = this.props;
    const selectedOptions = [];
    const hierarchyControlId = hierarchyControlProps.bindProperties ? hierarchyControlProps.bindProperties.id : "id";
    const hierarchyControlName = hierarchyControlProps.bindProperties
      ? hierarchyControlProps.bindProperties.name
      : "name";
    allSelectedOptions.forEach((element: any) => {
      let selectedValue;
      options.forEach((optionsArray = []) => {
        selectedValue = optionsArray.find(
          (row: any) =>
            HelperLodash.get(row, hierarchyControlId).toString() === element.value ||
            HelperLodash.get(row, hierarchyControlName) === element.label,
        );
        if (selectedValue) {
          selectedValue.label = HelperLodash.get(selectedValue, hierarchyControlName);
          selectedValue.value = HelperLodash.get(selectedValue, hierarchyControlId);
          selectedOptions.push(selectedValue);
        }
      });
    });
    return selectedOptions;
  };

  /**
   * Method to get options for radio dropdown
   */
  getRadioSelectDropdownOptions = () => {
    const { appliedOptions, selectedOptions: allSelectedOptions } = this.props;
    if (appliedOptions?.length) {
      return appliedOptions;
    } else {
      return allSelectedOptions;
    }
  };
  /**
   * method to clear filter selection
   */
  clearFilterSelection = (preventClearAppliedFilters = false) => {
    const { searchValue } = this.state;
    const appliedOptions = searchValue
      ? this.props.appliedOptions
      : getIntersectionOptions(this.props.appliedOptions, this.props.options);
    const valuesAfterReset = preventClearAppliedFilters ? appliedOptions : [];
    this.handleChange(valuesAfterReset);
    this.setState({ selectedValue: valuesAfterReset, count: this.getCount(valuesAfterReset), isFocused: true });
    if (this.props.hierarchyControlProps) {
      this.props.hierarchyControlProps.setSelectedItems([]);
      this.props.hierarchyControlProps.getEntityGroups(null);
    }
  };

  clearSearchValue = () => {
    this.setState({ searchValue: "" });
  };

  /**
   * @description Option {component} returns the customised option list.
   * @params props {object} contains the props of the dropdown
   */
  Option = (props) => {
    const isSelected = this.state.selectedValue.filter((opt: any) => opt.value === props.data.value);
    const dropDownProps = {
      ...props,
      isSelected: isSelected.length > 0,
    };
    return (
      <MultiSelectCheckboxFilter
        dropDownProps={dropDownProps}
        t={this.props.t}
        tReady={this.props.tReady}
        i18n={this.props.i18n}
      />
    );
  };

  /**
   * items selected
   */
  setSelectedItems = (opts) => {
    const filteredOption = opts.length > 0 ? opts[opts.length - 1] : null;
    const handleChangeOptions = filteredOption
      ? [
          {
            label: filteredOption.name,
            value:
              this.props.hierarchyControlProps && this.props.hierarchyControlProps.isParentLocation
                ? filteredOption.id
                : filteredOption.name,
          },
        ]
      : [];

    this.handleChange(handleChangeOptions);

    return this.props.hierarchyControlProps && this.props.hierarchyControlProps.setSelectedItems(opts);
  };

  handleShowMore = () => {
    const { handleShowMore, appliedOptions, filter, mode } = this.props;
    const { searchValue } = this.state;
    this.disableFocus();
    handleShowMore(filter, appliedOptions, mode, searchValue, this.clearFilterSelection.bind(this, true));
    this.setState({ searchValue: "" });
  };

  handleSearchChange = (query) => {
    const { filter, getFilterAttributes } = this.props;
    getFilterAttributes(filter.filterName, query);
    this.setState({ searchValue: query });
  };

  getMenuListControl = (props) => {
    const { onSubmit, filterFormValues, displayLoader } = this.props;
    const showClearButton = this.props.showClearButton && this.state.count >= 1;
    const { searchValue } = this.state;
    switch (props.selectProps.type) {
      case ControlType.MultiSelectDropdown:
        return (
          <DropDownMenu
            showClearButton={showClearButton}
            dropDownMenuProps={props}
            showSearchInput={this.props.showSearchInput}
            selectProps={props.selectProps}
            handleSearchChange={this.handleSearchChange}
            clearSearchValue={this.clearSearchValue}
            clearFilterSelection={this.clearFilterSelection}
            inputFocus={this.inputFocus}
            disableFocus={this.disableFocus}
            t={this.props.t}
            tReady={this.props.tReady}
            i18n={this.props.i18n}
            optionsCount={this.props.optionsCount}
            searchCount={this.props.searchCount}
            handleShowMore={this.handleShowMore}
            searchValue={searchValue}
            onSubmit={onSubmit}
            filterFormValues={filterFormValues}
            displayLoader={displayLoader}
          />
        );
      case ControlType.RadioSelectDropdown: {
        return (
          <RadioSelectFilter
            showClearButton={showClearButton}
            dropDownProps={props}
            clearFilterSelection={this.clearFilterSelection}
            handleInputChange={this.handleChange}
            inputFocus={this.inputFocus}
            disableFocus={this.disableFocus}
            t={this.props.t}
            tReady={this.props.tReady}
            i18n={this.props.i18n}
          />
        );
      }
      case ControlType.DataRangeDropdown:
        return (
          <DataRangeDropdownControl
            {...props.rangeControlProps}
            {...props.selectProps}
            onSelectionChange={(selectedValue, count, label) => {
              this.setState({ count, label });
              if (count === 0) {
                props.clearValue();
              }
              props.selectProps.onSelectionChange(selectedValue);
            }}
            inputFocus={this.inputFocus}
            disableFocus={this.disableFocus}
          />
        );
      case ControlType.DateRange:
        return this.getDateRangeControl(props);
      default:
        return (
          <HierarchicalDropDownMenu
            {...this.props.hierarchyControlProps}
            showClearButton={showClearButton}
            dropDownMenuProps={props}
            showSearchInput={this.props.showSearchInput}
            selectProps={props.selectProps}
            handleSearchChange={this.props.handleSearchChange}
            clearFilterSelection={this.clearFilterSelection}
            inputFocus={this.inputFocus}
            disableFocus={this.disableFocus}
            setSelectedItems={this.setSelectedItems}
            filterContainerRef={this.props.filterContainerRef}
            t={this.props.t}
            tReady={this.props.tReady}
            i18n={this.props.i18n}
            maxHeight={this.props.filter.maxHeight}
          />
        );
    }
  };

  getDateRangeControl = (props) => {
    const { clearButtonText, dateFormat, input, options, selectedOptions, t } = this.props;
    const { minDate, maxDate } = options.length > 0 && options[0];
    const selectedOption = selectedOptions && selectedOptions.length > 0 ? selectedOptions[0] : null;
    let startDate;
    let endDate;
    if (selectedOption) {
      startDate = selectedOption.startDate && new Date(selectedOption.startDate);
      if (startDate === minDate) {
        startDate = null;
      }
      endDate = selectedOption.endDate && new Date(selectedOption.endDate);
      if (endDate === maxDate) {
        endDate = null;
      }
    }

    return (
      <DateRangePickerWrapper>
        <DateRangePicker
          name={input.name}
          minDate={minDate}
          maxDate={maxDate}
          startDate={startDate}
          endDate={endDate}
          dateFormat={dateFormat}
          clearButtonText={clearButtonText}
          placeholder={t("filters:SELECT_DATE_PLACEHOLDER")}
          inputFocus={this.inputFocus}
          disableFocus={this.disableFocus}
          startDateLabel={t("filters:START_DATE")}
          endDateLabel={t("filters:END_DATE")}
          onSelectionChange={(selectedValue: any, newStartDate, newEndDate, count, label) => {
            this.setState({ count, label, selectedValue });
            if (count === 0) {
              props.clearValue();
            } else {
              props.setValue([selectedValue], "select-option", selectedValue);
            }
            this.props.input.onChange(selectedValue);
            props.selectProps.onSelectionChange([selectedValue], newStartDate, newEndDate);
          }}
          locale={this.props.datePickerLocale}
        />
      </DateRangePickerWrapper>
    );
  };

  filterAppliedOptions = () => {
    const { searchValue } = this.state;
    const { appliedOptions, options } = this.props;
    if (!searchValue) {
      return getIntersectionOptions(appliedOptions, options) || [];
    }
    return appliedOptions;
  };

  /**
   * @description Menu The custom flyout component which will render the options
   * @param props {Object} contains the props of the dropdown
   */
  MenuList = (props) => {
    const { displayLoader } = this.props;
    return (
      <>
        {displayLoader && props.selectProps.type !== ControlType.MultiSelectDropdown && (
          <div>
            <Loader>
              <LoaderElement />
            </Loader>
          </div>
        )}
        <MenuListWrapper
          displayLoader={displayLoader && props.selectProps.type !== ControlType.MultiSelectDropdown}
          id="menu-list-wrapper"
        >
          {this.getMenuListControl(props)}
        </MenuListWrapper>
      </>
    );
  };

  getPlaceholder = (props) => {
    const filteredAppliedOptions = this.filterAppliedOptions();
    if (filteredAppliedOptions.length > 0) {
      return this.MultiValueList(props);
    }
    return <Placeholder {...props} />;
  };

  /**
   * @description MultiValue {component} returns the customised input value when user selects more than one will show the badge of count.
   * @param props {Object} contains the props of the dropdown
   */
  MultiValueList = (props) => {
    switch (props.selectProps.type) {
      case ControlType.MultiSelectDropdown:
        return this.MultiValueForMultiSelectDropdown();
      default:
        return this.MultiValue(props);
    }
  };
  MultiValueForMultiSelectDropdown = () => {
    const { placeholder, t } = this.props;
    const filteredAppliedOptions = this.filterAppliedOptions();
    const appliedCount = (filteredAppliedOptions && filteredAppliedOptions.length) || 0;
    const label = filteredAppliedOptions && filteredAppliedOptions[0] && filteredAppliedOptions[0].label;
    const displayLabel = label === "null" ? t("filters:NO_VALUE_ASSIGNED") : label;
    return appliedCount === 1 ? (
      <div className="multivalue">{displayLabel}</div>
    ) : (
      <MultiSelectValueContainer>
        <PlaceholderValue className={appliedCount > 1 ? "selected" : null}>{placeholder}</PlaceholderValue>
        {appliedCount > 1 && <CountBadge className="badge">{appliedCount}</CountBadge>}
      </MultiSelectValueContainer>
    );
  };
  isSelected = (count, props, label) =>
    count > 1 && props.hasValue && (props.data.label || label) ? "selected" : null;
  MultiValue = (props) => {
    const { count, label } = this.state;
    const { placeholder, type } = this.props;
    let multiValueLabel = props.data && props.data.label ? props.data.label : label;

    if (type === ControlType.DataRangeDropdown) {
      multiValueLabel = placeholder;
    }
    if (type === ControlType.DateRange) {
      multiValueLabel = LocaleManager.dateHelpers.getLocaleDateByFormat(multiValueLabel, this.props.dateFormat);
    }
    return count === 1 ? (
      <div className="multivalue">{multiValueLabel}</div>
    ) : (
      <MultiSelectValueContainer>
        <PlaceholderValue className={this.isSelected(count, props, label)}>
          {props.selectProps.placeholder}
        </PlaceholderValue>
        {count > 1 && <CountBadge className="badge">{count}</CountBadge>}
      </MultiSelectValueContainer>
    );
  };
  /** Handle focus on input control inside the MenuList component */
  disableFocus = () => {
    return this.setState({ isFocused: undefined });
  };

  /**
   * method to update changes in redux store for form
   * @param options: selected options array
   * @param action
   */
  handleChange = (options: any, action?: any): any => {
    const { option: touchedOption } = action || {};
    const { selectedValue: currentSelectedValue } = this.state;
    const { type, onSelectionChange, filter = {}, changeFilterFormValues } = this.props;
    const { filterName, operator } = filter;
    const newSelectedValue =
      touchedOption && currentSelectedValue && currentSelectedValue.length > 1
        ? HelperLodash.cloneDeep(currentSelectedValue)
        : options;

    // selectedValue is based on appliedOptions
    // need to check whether the touchedOption is insert or remove
    if (touchedOption && currentSelectedValue && currentSelectedValue.length > 1) {
      const touchedOptionIndex = newSelectedValue.findIndex((option) => touchedOption.value === option.value);
      if (touchedOptionIndex !== -1) {
        newSelectedValue.splice(touchedOptionIndex, 1);
      } else {
        newSelectedValue.push(touchedOption);
      }
    }

    if (type !== ControlType.DateRange) {
      this.setState({
        count: this.getCount(newSelectedValue),
        selectedValue: newSelectedValue,
      });
    } else {
      this.setState({
        selectedValue: newSelectedValue,
      });
    }

    const formField = `${filterName}:${operator}`;
    const newFilterFormFieldValues = newSelectedValue
      .reduce((accumlator, option) => accumlator.concat(option.value), [])
      .join(";");

    if (changeFilterFormValues) {
      changeFilterFormValues(formField, newFilterFormFieldValues);
    }

    if (onSelectionChange && type !== ControlType.DateRange) {
      onSelectionChange(newSelectedValue);
    }
  };

  shouldResetSelection = (props) => {
    return props.meta && !props.meta.dirty && !props.meta.touched && !props.selectedOptions && this.state.count > 0;
  };

  componentDidUpdate(prevProps) {
    // logic to clear all form control values on form reset also check for initial value provided by redirection
    if (this.shouldResetSelection(this.props)) {
      this.setState({
        count: 0,
        selectedValue: [],
      });
      if (this.props.hierarchyControlProps) {
        this.props.hierarchyControlProps.setSelectedItems([]);
        this.props.hierarchyControlProps.getEntityGroups(null);
      }
    }
    if (
      this.props.options &&
      this.props.selectedOptions &&
      (prevProps.selectedOptions !== this.props.selectedOptions || prevProps.options !== this.props.options)
    ) {
      this.setState({
        count: this.props.count || this.getCount(this.props.selectedOptions) || 0,
        selectedValue: this.getSelectedValue(),
      });
    }
  }

  /** Handle focus on input control inside the MenuList component */
  inputFocus = () => {
    return this.setState({ isFocused: true });
  };

  getCount = (selectedOptions) => {
    const countOptions = HelperLodash.intersectionBy(selectedOptions, this.props.options, this.props.valueId);
    return countOptions && countOptions.length;
  };

  render() {
    return (
      <SelectDropdown
        {...this.props}
        components={{
          Control,
          DropdownIndicator,
          Menu: this.MenuList,
          MultiValue: this.MultiValueList,
          NoOptionsMessage,
          Option: this.Option,
          Placeholder: this.getPlaceholder,
          ValueContainer: CustomValueContainer,
        }}
        selectedValue={this.state.selectedValue}
        inputFocus={this.inputFocus}
        disableFocus={this.disableFocus}
        isFocused={this.state.isFocused || undefined}
        menuIsOpen={this.state.isFocused || undefined}
        searchValue={this.state.searchValue}
        handleChange={this.handleChange}
      />
    );
  }
}

export default MultiSelectDropDown;
