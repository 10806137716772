import { IconName } from "am-web-ui-shared/components";
import ICompanyModel from "TARGET_BUILD/common/models/company/iCompanyModel";
import React, { memo } from "react";
import styled from "styled-components";
import IconBoldIcon from "../../../../../components/DetailsPanel/SingleRow/IconBoldIcon/iconBoldIcon";
import { withTranslation, WithTranslation } from "react-i18next";
import { trackRepairStatusTracking } from "../../../../../../analytics/events/hiltiServices";

export const StyledWrapper = styled("div")`
  padding: 12px 16px 12px 16px;
  cursor: pointer;
  border-top: 1px solid ${(props) => props.theme.locationDetail.borderColor.color};
`;

type RepairTrackingProps = WithTranslation & {
  assetDetailsData?: any;
  companyDetails: ICompanyModel;
  searchRepairOrder?: (asset, company) => void;
};

/**
 * Repair status tracking link on side panel
 */
const RepairTracking = memo((props: RepairTrackingProps) => {
  const { assetDetailsData, t, companyDetails } = props;

  const initiateRepairTracking = () => {
    trackRepairStatusTracking();
    props.searchRepairOrder(assetDetailsData, companyDetails);
  };

  return (
    <StyledWrapper>
      <IconBoldIcon
        id="repairStatusTracking"
        boldId="repairStatusTrackingBoldId"
        boldText={t("assets:REPAIR_STATUS_TRACKING")}
        iconUrl={IconName.RepairStatus}
        postIconUrl={IconName.OpenNew}
        onClick={initiateRepairTracking}
      />
    </StyledWrapper>
  );
});

export default withTranslation()(RepairTracking);
