import { IconName } from "am-web-ui-shared/components";
import { transferPermissionKey } from "./configConstants";

export const CONFIRM_TRANSFER = "CONFIRMTRANSFER";

export default {
  actionItems: [
    {
      authKey: transferPermissionKey,
      iconName: IconName.Checkmark,
      isDisable: false,
      isVisible: true,
      name: CONFIRM_TRANSFER,
      showTooltipOnDisable: false,
      showTooltipOnEnable: false,
      tooltipPosition: "bottom",
      type: "TextWithIcon",
      dependencies: {
        toolTip: "getActionItemTooltip",
        disable: "disableConfirm",
      },
    },
  ],
  moduleType: {
    moduleName: "SUGGESTED_TRANSFERS",
  },
  showAlertSettingDialog: false,
};
