import { HelperLodash } from "am-web-ui-shared/helpers";
import IFilterFieldModel from "TARGET_BUILD/common/models/customView/iFilterFieldModel";

export const getGTMFiltersFields = (filters: IFilterFieldModel[], filtersMapping: Record<string, string | number>) => {
  let field = ",";
  filters.forEach((filter: IFilterFieldModel) => {
    const fName = HelperLodash.get(filtersMapping, filter.filterName);
    field = field.concat(fName, ",");
  });
  return field;
};
