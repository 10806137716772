import { ISagaModule } from "redux-dynamic-modules-saga";
import IUserModuleState from "./iUserModuleState";
import userReducer from "./userReducer";
import userSaga from "./userSaga";

export const userModule: ISagaModule<IUserModuleState> = {
  id: "userModule",
  reducerMap: {
    UserReducer: userReducer,
  },
  sagas: [userSaga],
};

export default userModule;
