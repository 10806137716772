/**
 * @description Action constants to be used for archived location module and its feature
 *
 * format: {feature}_{action}
 */
const ArchivedLocationActionConstants = {
  CLEAR_ALL_ARCHIVED_LOCATIONS_SELECTION: "CLEAR_ALL_ARCHIVED_LOCATIONS_SELECTION",
  GET_ARCHIVED_LOCATIONS: "GET_ARCHIVED_LOCATIONS",
  GET_ARCHIVED_LOCATIONS_SUCCESS: "GET_ARCHIVED_LOCATIONS_SUCCESS",
  OM_ARCHIVED_LOCATION_SELECT_ROW: "OM_ARCHIVED_LOCATION_SELECT_ROW",
  OM_ARCHIVED_LOCATION_UNSELECT_ROW: "OM_ARCHIVED_LOCATION_UNSELECT_ROW",
  OM_SET_ARCHIVED_LOCATION_SORT_QUERY: "OM_SET_ARCHIVED_LOCATION_SORT_QUERY",
  RESTORE_ARCHIVED_LOCATIONS: "RESTORE_ARCHIVED_LOCATIONS",
  SET_ARCHIVE_LOCATION_SEARCH_QUERY: "SET_ARCHIVE_LOCATION_SEARCH_QUERY",
};

export default ArchivedLocationActionConstants;
