import React from "react";
import styled from "styled-components";
import sharedConstants from "../../../../config";
import SearchBox from "../../../../elements/SearchBox/searchBox";
import { isApplyButtonActive } from "../../filterUtils";
import ApplyButton from "../applyButton/applyButton";
import { IDropDownMenuProps } from "./iMultiSelectDropDownProps";
import { LoaderElement } from "../../../../elements";
/**
 * @description MenuListContainer to style the MenuListContainer
 */

export const Loader = styled.div`
  max-height: calc(100vh - 200px);
  overflow: hidden;
  padding: 32px 0;
  position: relative;
  background: ${(props) => props.theme.colors.base.white};
  z-index: 1000;
`;

export const MenuListContainer = styled("div").attrs(() => ({
  id: "menuListContainer",
}))`
  color: ${(props) => props.theme.colors.base.steel};
  box-shadow: 0 2px 12px 0px ${(props) => props.theme.colors.base.greyrgba};
  background: ${(props) => props.theme.colors.base.white};
  &.drodownMenuMultiSelect {
    max-height: calc(100vh - 200px);
    width: 400px;
  }
  & .optionWrapper {
    max-height: calc(100vh - 200px);
    overflow: hidden;
    & .dropdownMenuItem {
      display: inline-block;
      height: auto;
      width: calc(100% - 32px);
      padding: 6px 16px;
      font-family: ${(props) => props.theme.fontRoman.fontFamily};
      &:hover {
        background-color: ${(props) => props.theme.colors.base.customLightGrey};
      }
      &:focus {
        background-color: ${(props) => props.theme.colors.base.greyDark};
      }
    }
  }
`;

/**
 * @description Footer wrapper to style the Clear and Apply button of the option list
 */
export const Footer = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  text-decoration: underline;
  font-size: ${(props) => props.theme.fontSize.base};
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & span {
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.colors.base.steel10};
    }
  }
`;

/**
 * @description ShowMoreWrapper wrapper to style show more text
 */
export const ShowMoreWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  width: 100%;
  text-decoration: underline;
  padding: 12px 16px;
  font-size: ${(props) => props.theme.fontSize.base};
  & span {
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.colors.base.steel10};
    }
  }
`;

/**
 * SearchBar styles for the search bar
 */
const searchStyle = {
  margin: "16px",
};

/**
 * DropDownMenu component for the dropdown of a category filter
 */
class DropDownMenu extends React.PureComponent<IDropDownMenuProps, {}> {
  containerRef: any;
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  clearFilterSelection = () => {
    this.props.clearFilterSelection();
  };

  isClickedOnComponent = (target) => {
    const eleArr = this.containerRef.current && Array.from(this.containerRef.current.children);
    const arr = (eleArr || []).filter((child: any) => child.contains(target));
    return arr.length > 0;
  };

  /**
   * onDocumentClick to disable focus while click outside
   */
  onDocumentClick = (e) => {
    const { inputFocus, disableFocus, clearFilterSelection } = this.props;
    if (this.isClickedOnComponent(e.target)) {
      inputFocus(); // open
    } else {
      clearFilterSelection(true);
      disableFocus(); // close
    }
  };
  /**
   * handle on change value in search input box
   *
   * @param e current event
   */
  onChange = (e) => {
    this.props.selectProps.onInputChange(e, {
      action: "input-change",
    });
    if (this.props.handleSearchChange) {
      this.props.handleSearchChange(e);
    }
  };

  /**
   * handle on mouse down event on search input box
   *
   * @param e current event
   */
  onMouseDown = (e: any) => {
    e.stopPropagation();
    e.target.focus();
  };
  onMouseEvent = (e: any) => {
    e.stopPropagation();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentClick);

    /**
     * Clear search value of the component
     */
    this.props.selectProps.onInputChange("", {
      action: "input-change",
    });
  }

  isApplyButtonActive = () => {
    const { selectProps } = this.props;
    return isApplyButtonActive(selectProps.appliedOptions, selectProps.selectedOptions, selectProps.options);
  };

  handleApplyOnClick = () => {
    const { clearSearchValue, onSubmit, filterFormValues } = this.props;
    clearSearchValue();
    if (onSubmit) {
      onSubmit(filterFormValues);
    }
  };

  render() {
    const {
      dropDownMenuProps,
      showClearButton,
      showSearchInput,
      selectProps,
      optionsCount,
      searchCount,
      t,
      searchValue,
      displayLoader,
    } = this.props;

    const showMoreCount = searchValue && searchCount !== undefined ? searchCount : optionsCount;
    return (
      <MenuListContainer ref={this.containerRef} className="drodownMenuMultiSelect" onKeyDown={this.onMouseEvent}>
        {showSearchInput ? (
          <SearchBox
            id="searchBox"
            placeholder={`${t("filters:SEARCH")} ${selectProps.placeholder.toLowerCase()}`}
            enableClear={true}
            autoCorrect="off"
            autoComplete="off"
            spellCheck={false}
            value={searchValue}
            onChange={this.onChange}
            onMouseDown={this.onMouseDown}
            onFocus={selectProps.onMenuInputFocus}
            style={searchStyle}
          />
        ) : null}
        {displayLoader ? (
          <Loader>
            <LoaderElement />
          </Loader>
        ) : (
          <div className="optionWrapper">
            {dropDownMenuProps.children}
            {showMoreCount && showMoreCount > sharedConstants.MAXIMUM_NUMBER_OF_DISPLAYED_VALUES ? (
              <ShowMoreWrapper>
                <span onClick={this.props.handleShowMore}>
                  {t("filters:SHOW_MORE_WITH_COUNT", {
                    count: showMoreCount - sharedConstants.MAXIMUM_NUMBER_OF_DISPLAYED_VALUES,
                  })}
                </span>
              </ShowMoreWrapper>
            ) : null}
          </div>
        )}
        <Footer>
          {showClearButton ? (
            <span onClick={this.clearFilterSelection}>{`${selectProps.clearButtonText}${
              selectProps.selectedValue && selectProps.selectedValue.length
                ? ` ${selectProps.selectedValue.length}`
                : ""
            }`}</span>
          ) : (
            <span />
          )}
          <ApplyButton isActive={this.isApplyButtonActive()} onClick={this.handleApplyOnClick} />
        </Footer>
      </MenuListContainer>
    );
  }
}
export default DropDownMenu;
