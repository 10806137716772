import { SuspenseWrapper } from "TARGET_BUILD/common/reactSuspenseWrapper";
import React, { useEffect, useState } from "react";
import { SidePanelContainer } from "./smartInventoryAssetsListView";
import SidePanel from "TARGET_BUILD/common/components/sidePanel";
import LocationDetailsWrapper from "../../locationDetails/locationDetailsWrapper";
import { getAssetDetails } from "TARGET_BUILD/common/api/assetApi";

const SmartInventoryAssetDetails = (props: { assetId: number; onBeforeRedirect: () => void }) => {
  const { assetId } = props;
  const [asset, setAsset] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let result: Response;
      if (assetId) {
        result = await getAssetDetails(assetId);
        setAsset(result);
      }
    };
    fetch();
    return () => {
      setAsset(null);
    };
  }, [assetId]);

  return asset ? (
    <>
      <SuspenseWrapper>
        <SidePanelContainer>
          <SidePanel sidePanelWidth={315}>
            <LocationDetailsWrapper
              item={asset}
              forceFetch={true}
              isPanelExpanded={true}
              showToggleIcon={true}
              onBeforeRedirect={props.onBeforeRedirect}
            />
          </SidePanel>
        </SidePanelContainer>
      </SuspenseWrapper>
    </>
  ) : null;
};

export default SmartInventoryAssetDetails;
