import { HelperLodash } from "am-web-ui-shared/helpers";
import { ServiceStatus } from "../../../../components/services/serviceFormController/serviceFormEnum";
import ServiceDetail from "../../../../models/asset/serviceDetail";
import { createReducer, deepCloneArray } from "../../../../utils/commonUtils";
import { ActionItem } from "../../../../utils/enums/actionItemEnum";
import { clearAllSelection, setRowSelection } from "../../../../utils/list/listUtils";

const defaultState = {
  addService: null,
  assetServiceAppliedFilterQuery: [],
  assetTemplateServiceAppliedFilterQuery: [],
  assetTemplateServiceFilterAttributes: null,
  assignedServiceDetail: null,
  clearFilterResponse: false,
  completeServiceRespnse: null,
  filterQuery: null,
  isFilterPanelOpen: false,
  localServicesCount: 0,
  provider: [],
  searchString: null,
  service: {
    history: {
      list: [],
      totalRecords: 0,
    },
    offset: null,
    open: {
      list: [],
      totalRecords: 0,
    },
  },
  showManageServices: { showServiceList: false, showServiceForm: false },
  showNoRecordFoundView: false,
};

const updateManageServices = (prev, next) => {
  return { ...prev, ...next };
};

/**
 * Function to format service template response.
 * @param state - Redux state.
 * @param data - Data to format.
 */
const formatServiceTemplateResponse = (state, data) => {
  const openServices = state.service.open.list;
  return data.map((item) => {
    openServices.map((service) => {
      if (item.id === parseInt(service.serviceId, 10)) {
        item.associatedFlag = true;
      }
    });
    return item;
  });
};

/**
 * @description Function to determine NoRecordFoundView exists or not.
 *
 */
const setShowNoRecordFoundView = (state, action) => {
  return state.searchString || (state.filterQuery && state.filterQuery.length && !action.payload.serviceList.length);
};

/**
 * @description Function to handle ASSET_SERVICE_PUT_LIST action type
 */
const assetServicePutList = (state, action) => {
  const currentServiceStatus = action.payload.serviceStatus;
  const currentServiceStatusValue = HelperLodash.get(state.service, currentServiceStatus);
  const rowInfo = {
    ...action.payload,
    id: currentServiceStatusValue.list.length + 1,
  };
  const newServiceList = [...state.service[action.payload.serviceStatus].list, ...[rowInfo]];

  return {
    ...state,
    localServicesCount: state.localServicesCount + 1,
    service: {
      ...state.service,
      [currentServiceStatus]: {
        list: newServiceList,
        totalRecords: newServiceList.length,
      },
      status: action.payload.serviceStatus,
    },
  };
};

/**
 * @description Function to handle ADD_ASSET_SERVICE action type
 */
const addAssetService = (state, action) => {
  return {
    ...state,
    addService: action.addAssetServiceData,
  };
};

/**
 * @description Function to handle ASSET_SERVICE_CLEAR_LIST action type
 */
const assetServiceClearList = (state, _action) => {
  return {
    ...state,
    assetTemplateServiceFilterAttributes: null,
    filterQuery: null,
    localServicesCount: 0,
    openServices: [],
    searchString: "",
    service: {
      history: {
        list: [],
        totalRecords: 0,
      },
      offset: null,
      open: {
        list: [],
        totalRecords: 0,
      },
    },
    sortQuery: null,
  };
};
/**
 * @description Function to handle ASSET_SERVICES_COUNT action type
 */
const assetServicesCount = (state, action) => {
  return {
    ...state,
    assignSerivesCount: action.payload,
  };
};
/**
 * @description Function to show manage services and handle SHOW_MANAGE_SERVICES_FORM and SHOW_MANAGE_SERVICES_LIST action type
 */
const showManageServices = (state, action) => {
  return {
    ...state,
    showManageServices: updateManageServices(state.showManageServices, action.payload),
  };
};
/**
 * @description Function to get assigned services and handle GET_ASSIGN_SERVICES_SUCCESS action type
 */
const getAssignServicesSuccess = (state, action) => {
  return {
    ...state,
    assignedServicesList:
      action.actionType === ActionItem.add
        ? formatServiceTemplateResponse(state, action.payload.response)
        : action.payload.response,
  };
};
/**
 * @description Function to clear service list and handle CLEAR_ASSIGNED_SERVICE_LIST action type
 */
const clearAssignedServiceList = (state, _action) => {
  return {
    ...state,
    assignedServicesList: [],
  };
};

/**
 * @description Function to clear complete service data and handle CLEAR_COMPLETE_SERVICE_DATAT action type
 */
const clearCompleteServiceData = (state, _action) => {
  return { ...state, completeServiceResponse: null };
};
/**
 * @description Function to set sevice error and handle SERVICE_OPERATION_ERROR action type
 */
const serviceOperationError = (state, action) => {
  return {
    ...state,
    serviceOperationError: action.payload,
  };
};
/**
 * @description Function to assign service and handle ASSIGN_SERVICE_SUCCESS action type
 */
const assignServiceSuccess = (state, _action) => {
  return {
    ...state,
    assignServiceSuccess: true,
    assignedServiceDetail: null,
  };
};
/**
 * @description Function to clear the service data and handle ASSET_SERVICE_DATA_CLEAR action type
 */
const assetServiceDataClear = (state, _action) => {
  return {
    ...state,
    assignServiceSuccess: false,
    serviceOperationError: null,
  };
};
/**
 * @description Function to get the assigned service detail and handle GET_ASSGIN_SERVICE_DETAIL_SUCCESS action type
 */
const getAssignServiceDetailSuccess = (state, action) => {
  return {
    ...state,
    assignedServiceDetail: new ServiceDetail(action.payload),
  };
};
/**
 * @description Function to set search query and handle  SET_SERVICE_SEARCH_QUERY action type
 */
const setServiceSearchQuery = (state, action) => {
  return {
    ...state,
    searchString: action.searchQuery,
  };
};
/**
 * @description Function to edit assigned services and handle EDIT_ASSIGNED_SERVICES_SUCCESS action type
 */
const editAssignedServicesSuccess = (state, _action) => {
  return {
    ...state,
    assignedServiceDetail: null,
    showManageServices: {
      showServiceForm: false,
      showServiceList: true,
    },
  };
};
/**
 * @description Function to handle COMPLETE_ASSIGNED_SERVICES_SUCCESS action type
 */
const completeAssignedServicesSuccess = (state, action) => {
  return {
    ...state,
    assignedServiceDetail: null,
    completeServiceResponse: action.payload,
    showManageServices: {
      showServiceForm: false,
      showServiceList: true,
    },
  };
};
/**
 * @description Function to clear the assigned service data
 */
const assetAssignedServiceDataClear = (state, _action) => {
  return {
    ...state,
    assignedServiceDetail: null,
  };
};

/**
 * @description Function to reset service query parameters and  handle RESET_SERVICE_QUERY_PARAM action type
 */
const resetServiceQueryParam = (state, _action) => {
  return {
    ...state,
    assetServiceAppliedFilterQuery: [],
    filterQuery: null,
    searchString: "",
    assetTemplateServiceAppliedFilterQuery: [],
  };
};
/**
 * @description Function to handle ASSET_TEMPLATE_SERVICE_FILTER_FIELD_SUCCESS action type
 */
const assetTemplateServiceFilterFieldSuccess = (state, action) => {
  return {
    ...state,
    assetTemplateServiceFilterAttributes: action.payload,
  };
};

const setAssetServiceAppliedFilterAttributes = (state, action) => ({
  ...state,
  assetServiceAppliedFilterQuery: action.payload && action.payload.filterQuery,
});

const setAssetTemplateServiceAppliedFilterAttributes = (state, action) => ({
  ...state,
  assetTemplateServiceAppliedFilterQuery: action.payload && action.payload.filterQuery,
});
/**
 * @description Function to update service filter and handle SERVICE_FILTER_UPDATE action type
 */
const serviceFilterUpdate = (state, action) => {
  return {
    ...state,
    clearFilterResponse: action.clearFilterStatus,
  };
};
/**
 * @description Function to open service's more filter and handle OPEN_SERIVCE_MORE_FILTER action type
 */
const openServiceMoreFilter = (state, _action) => {
  return {
    ...state,
    isFilterPanelOpen: true,
  };
};
/**
 * @description Function to close service's more filter and handle CLOSE_SERIVCE_MORE_FILTER action type
 */
const closeServiceMoreFilter = (state, _action) => {
  return {
    ...state,
    isFilterPanelOpen: false,
  };
};

/**
 * @description Function to remove the service and  handle ADD_ASSET_REMOVE_ASSET_SERVICE action type
 */
const removeAssetService = (state, action) => {
  const filteredServicesList = state.service[action.status].list.filter(
    (item) => item && item.id !== action.payload.id,
  );

  return {
    ...state,
    localServicesCount: state.localServicesCount - 1,
    service: {
      ...state.service,
      [action.status]: {
        list: filteredServicesList,
        totalRecords: filteredServicesList.length,
      },
    },
  };
};

/**
 * @description Function to select the open service and handle ASSET_OPEN_SERVICE_SELECT_ROW action type
 */
const assetOpenServiceSelectRow = (state, action) => {
  let selectRowList = deepCloneArray(state.service.open.list);
  if (!action.isMultiSelect) {
    selectRowList = clearAllSelection(selectRowList);
  }
  const selectedList = setRowSelection(selectRowList, action.id, true, "id");
  return {
    ...state,
    service: {
      ...state.service,
      open: {
        list: selectedList,
        totalRecords: state.service.open.totalRecords,
      },
    },
  };
};

/**
 * @description Function to select the  service history and handle ASSET_HISTORY_SERVICE_SELECT_ROW action type
 */
const assetHistoryServiceSelectRow = (state, action) => {
  let selectRowList = deepCloneArray(state.service.history.list);
  if (!action.isMultiSelect) {
    selectRowList = clearAllSelection(selectRowList);
  }
  const selectedList = setRowSelection(selectRowList, action.id, true, "id");
  return {
    ...state,
    service: {
      ...state.service,
      history: {
        list: selectedList,
        totalRecords: state.service.history.totalRecords,
      },
    },
  };
};
/**
 * @description Function to unselect the service row and handle ASSET_SERVICE_UNSELECT_ROW action type
 */
const assetServiceUnselectRow = (state, action) => {
  const unSelectedOpenList = deepCloneArray(state.service.open.list);
  const requiredOpenList = setRowSelection(unSelectedOpenList, action.id, false, "id");
  const unSelectedHistoryList = deepCloneArray(state.service.history.list);
  const requiredHistoryList = setRowSelection(unSelectedHistoryList, action.id, false, "id");
  return {
    ...state,
    assignedServiceDetail: null,
    service: {
      ...state.service,
      history: {
        list: requiredHistoryList,
        totalRecords: state.service.history.totalRecords,
      },
      open: {
        list: requiredOpenList,
        totalRecords: state.service.open.totalRecords,
      },
    },
  };
};

/**
 * @description Function to put the serviceList in state and handle AM_PUT_ASSET_SERVICE_LIST action type
 */
const putAssetServiceList = (state, action) => {
  // AM_PUT_ASSET_SERVICE_LIST:
  return {
    ...state,
    resetScrollPosition: state.service.offset !== null && !action.payload.offset,
    service: {
      ...action.payload.serviceList,
      history: {
        list:
          action.payload.status === ServiceStatus.HISTORY
            ? action.payload.status === state.service.status && action.payload.offset
              ? [...state.service.history.list, ...action.payload.serviceList.response]
              : action.payload.serviceList.response
            : [],
        totalRecords: action.payload.serviceList.totalRecords,
      },
      open: {
        list:
          action.payload.status !== ServiceStatus.HISTORY
            ? action.payload.status === state.service.status && action.payload.offset
              ? [...state.service.open.list, ...action.payload.serviceList.response]
              : action.payload.serviceList.response
            : [],
        totalRecords: action.payload.serviceList.totalRecords,
      },
      status: action.payload.status,
    },
    showNoRecordFoundView: setShowNoRecordFoundView(state, action),
  };
};

/**
 * @description Function to set the filter field handle SERVICE_FILTER_FIELD_SUCCESS action type
 */
const serviceFilterFieldSuccess = (state, action) => {
  return {
    ...state,
    serviceFilterAttributes: action.payload && action.payload.response,
  };
};

/**
 * @description Function to set the provider filter data and handle AM_SET_PROVIDER action type
 */
const setProviderFilterData = (state, action) => {
  const providerOptions = action.payload.response.map((value) => ({ label: value, value }));
  return {
    ...state,
    provider: providerOptions,
  };
};

/**
 * @description Function to set the filter query and  handle SET_ASSET_TEMPLATE_SERVICE_FILTER_QUERY action type
 */
const setServiceFilterQuery = (state, action) => {
  return {
    ...state,
    filterQuery: action.filterQuery,
  };
};

/**
 * @description Function to set the service sort query
 */
const setServiceSortQuery = (state, action) => {
  return {
    ...state,
    assignedServiceDetail: null,
    sortQuery: action.sortQuery,
  };
};

const serviceReducer = createReducer(defaultState, {
  ADD_ASSET_REMOVE_ASSET_SERVICE: removeAssetService,
  ADD_ASSET_SERVICE: addAssetService,
  AM_PUT_ASSET_SERVICE_LIST: putAssetServiceList,
  AM_SET_PROVIDER: setProviderFilterData,
  ASSET_ASSIGNED_SERVICE_DATA_CLEAR: assetAssignedServiceDataClear,
  ASSET_HISTORY_SERVICE_SELECT_ROW: assetHistoryServiceSelectRow,
  ASSET_OPEN_SERVICE_SELECT_ROW: assetOpenServiceSelectRow,
  ASSET_SERVICES_COUNT: assetServicesCount,
  ASSET_SERVICE_CLEAR_LIST: assetServiceClearList,
  ASSET_SERVICE_DATA_CLEAR: assetServiceDataClear,
  ASSET_SERVICE_PUT_LIST: assetServicePutList,
  ASSET_SERVICE_UNSELECT_ROW: assetServiceUnselectRow,
  ASSET_TEMPLATE_SERVICE_FILTER_FIELD_SUCCESS: assetTemplateServiceFilterFieldSuccess,
  ASSIGN_SERVICE_SUCCESS: assignServiceSuccess,
  CLEAR_ASSIGNED_SERVICE_LIST: clearAssignedServiceList,
  CLEAR_COMPLETE_SERVICE_DATA: clearCompleteServiceData,
  CLOSE_SERIVCE_MORE_FILTER: closeServiceMoreFilter,
  COMPLETE_ASSIGNED_SERVICES_SUCCESS: completeAssignedServicesSuccess,
  EDIT_ASSIGNED_SERVICES_SUCCESS: editAssignedServicesSuccess,
  GET_ASSGIN_SERVICE_DETAIL_SUCCESS: getAssignServiceDetailSuccess,
  GET_ASSIGN_SERVICES_SUCCESS: getAssignServicesSuccess,
  OPEN_SERIVCE_MORE_FILTER: openServiceMoreFilter,
  RESET_SERVICE_QUERY_PARAM: resetServiceQueryParam,
  SERVICE_FILTER_FIELD_SUCCESS: serviceFilterFieldSuccess,
  SERVICE_FILTER_UPDATE: serviceFilterUpdate,
  SERVICE_OPERATION_ERROR: serviceOperationError,
  SET_ASSET_SERVICE_APPLIED_FILTER_ATTRIBUTES: setAssetServiceAppliedFilterAttributes,
  SET_ASSET_TEMPLATE_SERVICE_APPLIED_FILTER_ATTRIBUTES: setAssetTemplateServiceAppliedFilterAttributes,
  SET_ASSET_TEMPLATE_SERVICE_FILTER_QUERY: setServiceFilterQuery,
  SET_SERVICE_FILTER_QUERY: setServiceFilterQuery,
  SET_SERVICE_SEARCH_QUERY: setServiceSearchQuery,
  SET_SERVICE_SORT_QUERY: setServiceSortQuery,
  SHOW_MANAGE_SERVICES_FORM: showManageServices,
  SHOW_MANAGE_SERVICES_LIST: showManageServices,
});
export default serviceReducer;
