import React from "react";
import styled from "styled-components";
import IFriendlyName from "./iFriendlyName";

interface IHeaderTextProps {
  isNormal?: boolean;
  small?: boolean;
  noBreak?: boolean;
  theme?: any;
}

/**
 * @const FriendlyName is a Styled Component that consist of top level heading
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 */
const StyledBoldHeaderText = styled("div").attrs((props) => ({
  isNormal: (props as IHeaderTextProps).isNormal,
  small: (props as IHeaderTextProps).small,
  noBreak: (props as IHeaderTextProps).noBreak,
}))`
  font-family: ${(props) => (props.isNormal ? props.theme.fontRoman.fontFamily : props.theme.fontBold.fontFamily)};
  font-size: ${(props) => (props.small ? "12px" : props.isNormal ? "14px" : "16px")};
  line-height: ${(props) => (props.small ? "24px" : "normal")};
  align-items: unset;
  word-break: ${(props) => (props.noBreak ? "break-word" : "break-all")};
  color: ${(props: IHeaderTextProps) => props.theme.colors.base.steel};
  padding-bottom: ${(props: IHeaderTextProps) => (props.isNormal ? "0px" : "8px")};
`;

const FriendlyName: React.FC<IFriendlyName> = (props: IFriendlyName) => {
  const { boldId, boldText, isNormal = false, noBreak, small = false } = props;
  return boldText ? (
    <StyledBoldHeaderText id={boldId} title={boldText} isNormal={isNormal} small={small} noBreak={noBreak}>
      {boldText}
    </StyledBoldHeaderText>
  ) : null;
};

/**
 * @export FriendlyName - Styled Component.
 */
export default FriendlyName;
