import { HelperLodash, SharedUtils } from "am-web-ui-shared/helpers";
import IFilterFieldModel from "TARGET_BUILD/common/models/customView/iFilterFieldModel";
import { getGTMFiltersFields } from "./utils/analyticsUtils";

export const ADD_TEMPLATE_INITIATED = "add_template_initiated";
export const EDIT_TEMPLATE_INITIATED = "edit_template_initiated";
export const MERGE_TEMPLATE_INITIATED = "merge_template_initiated";
export const DELETE_TEMPLATE_INITIATED = "delete_template_initiated";
export const ADD_TEMPLATE_WORKFLOW_CLOSED = "add_template_workflow_closed";
export const MERGE_TEMPLATE_WORKFLOW_CLOSED = "merge_template_workflow_closed";
export const EDIT_TEMPLATE_WORKFLOW_CLOSED = "edit_template_workflow_closed";
export const DELETE_TEMPLATE_WORKFLOW_CLOSED = "delete_template_workflow_closed";
export const ADD_TEMPLATE_WORKFLOW_SUCCESS = "add_template_workflow_success";
export const ADD_TEMPLATE_WORKFLOW_FAILURE = "add_template_workflow_failure";
export const EDIT_TEMPLATE_WORKFLOW_SUCCESS = "edit_template_workflow_success";
export const EDIT_TEMPLATE_WORKFLOW_FAILURE = "edit_template_workflow_failure";
export const MERGE_TEMPLATE_WORKFLOW_SUCCESS = "merge_template_workflow_success";
export const MERGE_TEMPLATE_WORKFLOW_FAILURE = "merge_template_workflow_failure";
export const DELETE_TEMPLATE_WORKFLOW_SUCCESS = "delete_template_workflow_success";
export const DELETE_TEMPLATE_WORKFLOW_FAILURE = "delete_template_workflow_failure";
export const CLICK_ASSET_TEMPLATE_SELECT_ALL = "click_asset_template_select_all";

export const FILTER_SEARCH_VALUE = "filter_search_value";
export const FILTER_SHOW_MORE = "filter_show_more";

export const COMBINED_FILTER = "combined_filter";

export enum EntityTitle {
  ASSETTEMPLATES = "asset_templates",
  ASSETTEMPLATE = "asset_template",
}

export enum FiltersMapping {
  assetGroup = 1,
  manufacturer,
  model,
  costCode,
  assetCount,
  attachmentFlag,
  imageFlag,
}

export const eventNames = {
  assetGroup: "asset_group_filter",
  manufacturer: "manufacturer_filter",
  model: "model_filter",
  costCode: "cost_code_filter",
  assetCount: "asset_count_filter",
  attachmentFlag: "attachments_filter",
  imageFlag: "images_filter",
};

type FilterType = {
  customAttribute: string;
  query?: string;
};

export const filterSearchValuesNames = {
  model: "model",
  costCode: "cost_code",
  manufacturer: "manufacturer",
};

export const trackAssetTemplateEventsForAppliedFilter = (appliedFilter: IFilterFieldModel[], resultCount: number) => {
  const filter = appliedFilter.slice(-1)[0];
  const filterEventName = HelperLodash.get(eventNames, filter.filterName);
  SharedUtils.pushDataToGA(filterEventName, null, {
    entity: EntityTitle.ASSETTEMPLATES,
    count: filter.filterValue.length,
    total_result_count: resultCount,
  });
};

export const trackEventsForFilterSearch = (filter: FilterType, eventName: string) => {
  const fieldName = HelperLodash.get(filterSearchValuesNames, filter.customAttribute);
  SharedUtils.pushDataToGA(eventName, null, {
    entity: EntityTitle.ASSETTEMPLATES,
    field: fieldName,
  });
};

export const trackAssetTemplatesCombinedFilters = (filters: IFilterFieldModel[]) => {
  const field = getGTMFiltersFields(filters, FiltersMapping);
  SharedUtils.pushDataToGA(COMBINED_FILTER, null, {
    entity: EntityTitle.ASSETTEMPLATE,
    count: filters.length,
    field,
  });
};

export const trackAddAssetTemplateInitiated = () => {
  SharedUtils.pushDataToGA(ADD_TEMPLATE_INITIATED, null, null);
};

export const trackEditAssetTemplateInitiated = () => {
  SharedUtils.pushDataToGA(EDIT_TEMPLATE_INITIATED, null, null);
};

export const trackMergeAssetTemplateInitiated = () => {
  SharedUtils.pushDataToGA(MERGE_TEMPLATE_INITIATED, null, null);
};

export const trackDeleteAssetTemplateInitiated = () => {
  SharedUtils.pushDataToGA(DELETE_TEMPLATE_INITIATED, null, null);
};

export const trackAddTemplateWorkflowClosed = () => {
  SharedUtils.pushDataToGA(ADD_TEMPLATE_WORKFLOW_CLOSED, null, null);
};

export const trackMergeTemplateWorkflowFailure = (errorCode: number) => {
  SharedUtils.pushDataToGA(MERGE_TEMPLATE_WORKFLOW_FAILURE, null, {
    errorCode,
  });
};

export const trackDeleteTemplateWorkflowSuccess = () => {
  SharedUtils.pushDataToGA(DELETE_TEMPLATE_WORKFLOW_SUCCESS, null, null);
};

export const trackDeleteTemplateWorkflowFailure = (errorCode: number) => {
  SharedUtils.pushDataToGA(DELETE_TEMPLATE_WORKFLOW_FAILURE, null, {
    errorCode,
  });
};

export const trackAssetTemplateSelectAll = (selectedCount: number) => {
  SharedUtils.pushDataToGA(CLICK_ASSET_TEMPLATE_SELECT_ALL, null, {
    asset_template_count: selectedCount,
  });
};

export const trackMergeTemplateWorkflowSuccess = () => {
  SharedUtils.pushDataToGA(MERGE_TEMPLATE_WORKFLOW_SUCCESS, null, null);
};

export const trackMergeTemplateWorkflowClosed = () => {
  SharedUtils.pushDataToGA(MERGE_TEMPLATE_WORKFLOW_CLOSED, null, null);
};

export const trackEditTemplateWorkflowClosed = () => {
  SharedUtils.pushDataToGA(EDIT_TEMPLATE_WORKFLOW_CLOSED, null, null);
};

export const trackDeleteWorkflowClosed = () => {
  SharedUtils.pushDataToGA(DELETE_TEMPLATE_WORKFLOW_CLOSED, null, null);
};

export const trackAddTemplateWorkflowSuccess = () => {
  SharedUtils.pushDataToGA(ADD_TEMPLATE_WORKFLOW_SUCCESS, null, null);
};

export const trackAddTemplateWorkflowFailure = (errorCode: number) => {
  SharedUtils.pushDataToGA(ADD_TEMPLATE_WORKFLOW_FAILURE, null, {
    errorCode,
  });
};

export const trackEditTemplateWorkflowFailures = (errorCode: number) => {
  SharedUtils.pushDataToGA(EDIT_TEMPLATE_WORKFLOW_FAILURE, null, {
    errorCode,
  });
};

export const trackEditTemplateWorkflowSuccess = () => {
  SharedUtils.pushDataToGA(EDIT_TEMPLATE_WORKFLOW_SUCCESS, null, null);
};
