import React from "react";
const SVG = () => (
  <svg width="21px" height="22px" viewBox="0 0 21 22" version="1.1">
    <defs>
      <path
        d="M18.5625,11.0625 C20.6335678,11.0625 22.3125,12.7414322 22.3125,14.8125 C22.3125,16.2004495 21.5584644,17.4122845 20.4376937,18.0607047 L20.4375,23.25 L18.5625,21.375 L16.6875,23.25 L16.6873063,18.0607047 C15.5665356,17.4122845 14.8125,16.2004495 14.8125,14.8125 C14.8125,12.7414322 16.4914322,11.0625 18.5625,11.0625 Z M21.375,2.625 L21.3758509,9.94051718 C20.5482961,9.46160929 19.5874089,9.1875 18.5625,9.1875 C15.4558983,9.1875 12.9375,11.7058983 12.9375,14.8125 C12.9375,15.8374089 13.2116093,16.7982961 13.6905172,17.6258509 L2.625,17.625 L2.625,2.625 L21.375,2.625 Z M18.5625,12.9375 C17.5269661,12.9375 16.6875,13.7769661 16.6875,14.8125 C16.6875,15.8480339 17.5269661,16.6875 18.5625,16.6875 C19.5980339,16.6875 20.4375,15.8480339 20.4375,14.8125 C20.4375,13.7769661 19.5980339,12.9375 18.5625,12.9375 Z M19.35,13.5 L19.875,14.025 L19.087,14.813 L19.875,15.6 L19.35,16.125 L18.562,15.338 L17.775,16.125 L17.25,15.6 L18.037,14.813 L17.25,14.025 L17.775,13.5 L18.562,14.288 L19.35,13.5 Z M12,9.1875 L5.4375,9.1875 L5.4375,11.0625 L12,11.0625 L12,9.1875 Z M18.5625,5.4375 L5.4375,5.4375 L5.4375,7.3125 L18.5625,7.3125 L18.5625,5.4375 Z"
        id="path-1"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, -2.000000)">
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <rect
          id="Rectangle"
          fillOpacity="0"
          fill="#FFFFFF"
          fillRule="evenodd"
          x="0.75"
          y="0.75"
          width="22.5"
          height="22.5"
        />
        <mask id="mask-2" fill="white">
          <use href="#path-1" />
        </mask>
        <use id="Combined-Shape" fill="#524F53" fillRule="evenodd" href="#path-1" />
      </g>
    </g>
  </svg>
);

export default SVG;
