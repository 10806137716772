// @ts-ignore
import { LockedFeatureInfoActions } from "../../../common/actionConstants";
import { all, put, takeLatest, call } from "redux-saga/effects";
import { getLockedFeatureInfo } from "../../api/lockedFeaturesInfoApi";

/**
 * @description the saga function to get the locked feature info
 *
 * @export
 */
export function* getLockedFeatureInfoSaga() {
  yield takeLatest(LockedFeatureInfoActions.GET_LOCKED_FEATURE_INFO, lockedFeatureInfo);
}

export function* lockedFeatureInfo(action) {
  const response = yield call(getLockedFeatureInfo, action.payload);
  if (response && !response.error) {
    yield put({
      type: LockedFeatureInfoActions.PUT_LOCKED_FEATURE_INFO,
      payload: { ...response, featureKey: action.payload },
    });
  }
}

function* lockedFeatureInfoSaga() {
  yield all([getLockedFeatureInfoSaga()]);
}

export default lockedFeatureInfoSaga;
