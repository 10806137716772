import React, { Fragment } from "react";
// @ts-ignore
import styled from "styled-components";
import ApplicationConstants from "../../constant/applicationConstants";
import IconComponent from "../icons/iconComponent/iconComponent";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import OutsideClickAlerter from "../outSideClickAlerter/outSideClickComponet";
import {
  actionBarLabelText,
  authKeyDependencyResolver,
  disableDependencyResolver,
  tooltipDependencyResolver,
  visibleDependencyResolver,
} from "./actionBarUtil";
import ActionDropDownValues from "./actionDropDownValues";
import { useTranslation } from "react-i18next";
import IActionItemSetting, { IDependenciesSetting } from "./iActionBarSettings";

const ActionItemsWrapper = styled.div`
  display: flex;
  margin-right: auto;
  .place-bottom {
    margin-top: 0;
  }
  .action-wrapper {
    padding: 8px;
  }
  .__react_component_tooltip.action-wrapper {
    padding: 8px;
  }
  .__react_component_tooltip.place-right {
    margin-left: -8px;
  }
  .show {
    text-align: left;
    max-width: 512px;
  }
`;

const MoreLink = styled.a`
  cursor: pointer;
  padding: 8px;
  width: 64px;
  height: 100%;
  display: block;
  span {
    height: 24px;
    margin-bottom: 7px;
    width: 100%;
  }
  svg {
    transform: rotate(90deg);
  }
`;
const LinkText = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  height: 26px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSize.extraSmall};
  font-weight: 300;
`;

const MoreContainer = styled.div`
  position: relative;
`;

const MoreLinkValues = styled.div`
  overflow: auto;
  position: absolute;
  overflow: auto;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow:
    0 2px 12px 0 rgba(82, 79, 83, 0.5),
    inset 0 0 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
  width: 240px;
  right: 0;

  .sort-value.disabled {
    opacity: 0.5;
  }

  .sort-value {
    color: ${(props) => props.theme.colors.base.steel};
    cursor: pointer;
    padding: 8px 16px 8px 8px;
    line-height: 24px;
    min-height: 40px;
    display: flex;
    align-items: center;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    &:hover {
      background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
      color: ${(props) => props.theme.colors.base.black};
    }
    &:hover svg path {
      fill: ${(props) => props.theme.colors.base.black};
    }
    span {
      margin-right: 16px;
    }
  }
`;

const actionItemNameConversion = (actionItemName: string) => `actionBar:${actionItemName.toUpperCase()}`;

const TextWithIcon = ({
  actionItem,
  maxLabelLength,
  dependencies,
  onActionClicked,
}: {
  actionItem: IActionItemSetting;
  maxLabelLength: number;
  dependencies: IDependenciesSetting;
  onActionClicked: (actionName: string, linkElement: HTMLAnchorElement) => void;
}) => {
  const { t } = useTranslation();
  const disabled = disableDependencyResolver(actionItem, dependencies);
  const isVisible = visibleDependencyResolver(actionItem, dependencies);
  return isVisible ? (
    <IconComponent
      toggle={actionItem.toggle}
      icon={actionItem.iconName ? actionItem.iconName : actionItem.name}
      labelText={actionBarLabelText(`${t(actionItemNameConversion(actionItem.name))}`, maxLabelLength)}
      id={actionItem.name}
      key={actionItem.name}
      className={`action-wrapper ${disabled ? "disabled" : ""}`}
      handleClick={(_e, element) => onActionClicked(actionItem.name, element)}
      isIconDisabled={disabled}
      getContent={() => tooltipDependencyResolver(actionItem, dependencies)}
      place={actionItem.tooltipPosition}
      forceToolTipOnEnable={actionItem.forceToolTipOnEnable}
    />
  ) : null;
};

export class ActionItems extends React.Component<any, any> {
  state = {
    showMoreDialog: false,
  };
  availableWidth = 0;

  moreDialogValues = () => {
    this.setState((prevState) => ({
      showMoreDialog: !prevState.showMoreDialog,
    }));
  };

  getActionItems = () => {
    const { actionBarSettings } = this.props;
    if (actionBarSettings && actionBarSettings.actionItems) {
      return actionBarSettings.actionItems;
    }
    return [];
  };

  getTotalIconWidth = () => {
    const singleIconWidth = 64;
    const itemsCount = this.getActionItems().length - 1;
    return itemsCount * singleIconWidth;
  };

  shouldShowMoreIcon = () => {
    const { availableWidth } = this.props;
    return this.getTotalIconWidth() > availableWidth;
  };

  closeMoreMenu = () => {
    this.setState({ showMoreDialog: false });
  };

  renderActions = (actionItem) => {
    const { dependencies, maxLabelLength, onActionClicked } = this.props;
    if (actionItem.type === "TextWithIcon") {
      return (
        <TextWithIcon
          actionItem={actionItem}
          dependencies={dependencies}
          maxLabelLength={maxLabelLength}
          onActionClicked={onActionClicked}
        />
      );
    }
    return null;
  };

  moreRenderActions = (actionItem) => {
    const { onActionClicked, dependencies, t } = this.props;
    const isVisible = visibleDependencyResolver(actionItem, dependencies);
    const isDisabled = disableDependencyResolver(actionItem, dependencies);

    return (
      isVisible && (
        <ActionDropDownValues
          onActionClicked={isDisabled ? null : (id, ref) => onActionClicked(actionItem.name, ref, id)}
          t={t}
          actionItem={actionItem}
          dependencies={dependencies}
        />
      )
    );
  };

  menuList = (items, CheckAuthorization) => {
    return (
      <OutsideClickAlerter
        onClickAway={this.closeMoreMenu}
        ignoreClickedElement={["moreLink"]}
        render={() => (
          <MoreLinkValues id="moreLinkValues">
            {items.map((item, index) => {
              const authKey = authKeyDependencyResolver(item, this.props.dependencies);
              return authKey ? (
                <CheckAuthorization
                  key={`${authKey}_${index}`}
                  yes={() => this.moreRenderActions(item)}
                  permissionKey={authKey}
                />
              ) : (
                this.moreRenderActions(item)
              );
            })}
          </MoreLinkValues>
        )}
      />
    );
  };

  hasWidthToFit = (index) => {
    const { availableWidth } = this.props;
    const totalIconWidth = index * ApplicationConstants.WIDTH.W64;
    return totalIconWidth < availableWidth;
  };

  getMoreListIcons = () => {
    const items = this.getActionItems();
    const displayedItems = [];
    const moreItems = [];
    items.forEach((item, index) => {
      if (this.hasWidthToFit(index + 1)) {
        displayedItems.push(item);
      } else {
        moreItems.push(item);
      }
    });
    return { displayedItems, moreItems };
  };

  renderActionItemsWrapper = (items) => {
    const CheckAuthorization = this.props.checkAuthorization;

    return (
      <ActionItemsWrapper data-testid="actionItemsWrapper">
        {items.map((item, index) => {
          const authKey = authKeyDependencyResolver(item, this.props.dependencies);
          return authKey ? (
            <CheckAuthorization
              key={`${authKey}_${index}`}
              yes={() => this.renderActions(item)}
              permissionKey={authKey}
            />
          ) : (
            <Fragment key={`${authKey}_${index}`}> {this.renderActions(item)} </Fragment>
          );
        })}
      </ActionItemsWrapper>
    );
  };

  render() {
    const displayMoreButton = this.shouldShowMoreIcon();
    const moreIcons = displayMoreButton ? this.getMoreListIcons() : null;
    const CheckAuthorization = this.props.checkAuthorization;

    return (
      <>
        {displayMoreButton ? (
          <>
            {this.renderActionItemsWrapper(moreIcons.displayedItems)}
            <MoreContainer onClick={this.moreDialogValues}>
              <MoreLink id="moreLink">
                <SquareIcon id={"more"} name={IconName.More} />
                <LinkText>{"More"}</LinkText>
              </MoreLink>
              {this.state.showMoreDialog ? this.menuList(moreIcons.moreItems, CheckAuthorization) : ""}
            </MoreContainer>
          </>
        ) : (
          this.renderActionItemsWrapper(this.getActionItems())
        )}
      </>
    );
  }
}

export default ActionItems;
