import React from "react";
const SVG = () => (
  <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3237.000000, -3187.000000)" fill="#524F53">
        <g transform="translate(3235.000000, 3184.000000)">
          <path
            d="M16,3 L16,7 L22,7 L22,21 L2,21 L2,7 L7,7 L7,3 L16,3 Z M14,5 L9,5 L9,7 L14,7 L14,5 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
