import Select from "@mui/material/Select";
import styled from "styled-components";

const StyledSelect = styled(Select)(({theme}) => {
  return {
    color: theme.colors.steel[600],
   ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
     "&.MuiOutlinedInput-root": { marginLeft: 16, marginTop: 4 },
  };
});

export { StyledSelect };
