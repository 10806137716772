import { MultiSelectDropdownContainer } from "am-web-ui-shared/components";
import { Form, SearchBox } from "am-web-ui-shared/elements";
import React from "react";
import { connect } from "react-redux";
import { change, getFormValues } from "redux-form";
// @ts-ignore
import { SortList } from "TARGET_BUILD/config";
import SearchBar from "../../components/searchBox/searchBar";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  closeServiceFilter,
  fetchAssetServiceList,
  getServiceFilterAttributes,
  openServiceFilter,
  providerList,
  setAssetServiceAppliedAttributes,
  setSearchQuery,
} from "../../modules/asset/actions/assetServiceAction";
import { filtersQuery } from "../../utils/commonUtils";
import { ActionItem } from "../../utils/enums/actionItemEnum";
import IMoreFilterCloseHandler from "../commonProps/iSearchBarProps";
import ControlType from "../filter/controlType";
import { ServiceFormEnum, ServiceStatus, StatusTypeFilter } from "./serviceFormController/serviceFormEnum";
import { historyGridFiltersSettings, openGridFiltersSettings } from "./servicesGridFilters";
interface IServiceFilterProps extends WithTranslation {
  readonly closeMoreFilter?: () => void;
  readonly setServiceFilterQuery?: (query: any) => void;
  readonly getServiceFilterAttributes?: (
    assetId: number,
    filterQuery: string,
    delay: boolean,
    serviceStatusType: string,
  ) => void;
  readonly fetchProviderList?: (query) => void;
  readonly fetchAssetServiceList?: (offset: number, assetId: number, status: string, filters: string) => void;
  readonly serviceFilterAttributes?: any;
  readonly provider?: any;
  readonly serviceStatusType: string;
  readonly assetId: number;
  readonly actionType: ActionItem;
  readonly setServiceStatusFilterState: any;
  readonly searchString?: string;
  readonly setSearchQuery?: any;
  readonly showNoRecordFoundView?: boolean;
  readonly openMoreFilter?: () => void;
  readonly isMoreFilterOpen?: boolean;
  readonly filterQuery?: any;
  readonly setSortQuery?: (sort: any) => void;
  readonly setFilterAppliedAttributes?: (filter: any) => void;
  readonly appliedFilterQuery?: any;
  readonly filterFormValues?: any;
  readonly changeFilterFormValues?: any;
}

const searchStyle = {
  marginRight: "16px",
};

export class ServiceFilterContainer extends React.PureComponent<IServiceFilterProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {},
      selectedFilters: this.getDefaultSelectedFilters(),
      serviceStatus: props.serviceStatusType,
    };
  }

  getStatus = () =>
    this.props.serviceStatusType === ServiceStatus.OPEN
      ? StatusTypeFilter.OPEN_SERVICE
      : StatusTypeFilter.HISTORY_SERVICE;

  getDefaultSelectedFilters = () => {
    return [
      {
        serviceStatusResponse: [this.getStatus()],
      },
    ];
  };
  componentDidMount() {
    if (this.props.actionType === ActionItem.edit) {
      this.props.getServiceFilterAttributes(this.props.assetId, null, false, this.props.serviceStatusType);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.actionType === ActionItem.edit && this.props.serviceStatusType !== prevProps.serviceStatusType) {
      this.props.setServiceFilterQuery(null);
      this.setSortQuery();
      this.props.fetchAssetServiceList(0, this.props.assetId, this.props.serviceStatusType, null);
      this.props.getServiceFilterAttributes(this.props.assetId, null, false, this.props.serviceStatusType);
      this.props.closeMoreFilter();
    }
  }
  componentWillUnmount() {
    this.props.setFilterAppliedAttributes([]);
    this.props.closeMoreFilter();
  }
  setSortQuery = () => {
    if (this.props.serviceStatusType === ServiceStatus.OPEN) {
      this.props.setSortQuery({
        sortField: SortList.ASSET_SERVICE.DEFAULT_SORT.name,
        sortType: SortList.ASSET_SERVICE.DEFAULT_SORT.sortType,
      });
    } else if (this.props.serviceStatusType === ServiceStatus.HISTORY) {
      this.props.setSortQuery({
        sortField: SortList.ASSET_SERVICE.DEFAULT_HISTORY_SORT.name,
        sortType: SortList.ASSET_SERVICE.DEFAULT_HISTORY_SORT.sortType,
      });
    }
  };

  /**
   * method to invoke when any filter change
   * this will refresh other filters & grid data
   *
   * @param filterQuery: query to filter grid
   */

  handleFilterChange = (filter: any) => {
    if (this.props.actionType === ActionItem.edit) {
      this.handleFilterEditMode(filter);
    }
  };

  /**
   * method to invoke when any filter change
   * this will refresh other filters & grid data
   *
   * @param filterQuery: query to filter grid
   */
  handleFilterEditMode = (filter: any) => {
    if (filter && filter.length > 0 && this.props.actionType === ActionItem.edit) {
      // open & history is mapped with open_service and history_service
      const serviceTypeFilterMapper =
        this.props.serviceStatusType === ServiceStatus.OPEN
          ? StatusTypeFilter.OPEN_SERVICE
          : StatusTypeFilter.HISTORY_SERVICE;

      //   check when user Change the Service Type filter clear the filter and set the new service type in state.
      if (serviceTypeFilterMapper === StatusTypeFilter.OPEN_SERVICE) {
        this.loadDataWithAppliedFilters(ServiceStatus.OPEN, filter);
        this.props.setServiceStatusFilterState([{ value: ServiceStatus.OPEN }]);
      } else {
        this.props.fetchProviderList({ offset: 0 });
        this.loadDataWithAppliedFilters(ServiceStatus.HISTORY, filter);
        this.props.setServiceStatusFilterState([{ value: ServiceStatus.HISTORY }]);
      }
    } else {
      this.setState({ selectedFilter: null });
      this.props.setServiceFilterQuery(null);
      this.loadDataWithAppliedFilters(this.props.serviceStatusType, null);
    }
  };
  loadDataWithAppliedFilters = (serviceStatusSelected, appliedFilters) => {
    this.props.setFilterAppliedAttributes(appliedFilters);
    this.props.getServiceFilterAttributes(
      this.props.assetId,
      filtersQuery(appliedFilters),
      false,
      serviceStatusSelected,
    );
    this.setSortQuery();
    this.props.setServiceFilterQuery(appliedFilters);
    this.props.fetchAssetServiceList(0, this.props.assetId, serviceStatusSelected, filtersQuery(appliedFilters));
  };
  /**
   * @description Function to set the search value in the store
   * @param {string} searchString - takes entered search string.
   */
  setSearchValue = (searchString = "") => {
    this.props.setSearchQuery(searchString);
    this.props.fetchAssetServiceList(0, this.props.assetId, this.props.serviceStatusType, null);
    this.props.closeMoreFilter();
  };

  /**
   * search component for searching the list
   */
  searchComponent = () => {
    return this.props.actionType !== ActionItem.add ? (
      <SearchBox
        id="searchBox"
        placeholder={this.props.t("filters:SEARCH")}
        style={searchStyle}
        onChange={this.setSearchValue}
        enableClear={true}
        value={this.props.searchString}
        disabled={true}
      />
    ) : null;
  };

  getRadioSelectDropdown = () => {
    const options = [
      {
        label: this.props.t("services:UPCOMING_SERVICES"),
        value: ServiceStatus.OPEN,
      },
      {
        label: this.props.t("services:HISTORIC_SERVICES"),
        value: ServiceStatus.HISTORY,
      },
    ];
    return (
      <Form form="serviceFilterContainer">
        <MultiSelectDropdownContainer
          key={""}
          options={options}
          id={"assetServicesFilter"}
          name={""}
          valueId="value"
          valueKey="label"
          placeholder={this.props.t("common:SERVICE")}
          isFormControl={true}
          clearButtonText={null}
          onSelectionChange={(option) => this.props.setServiceStatusFilterState(option)}
          selectedOptions={options.filter((element) => element.value === this.props.serviceStatusType)}
          type={ControlType.RadioSelectDropdown}
          showClearButton={false}
          t={this.props.t}
          tReady={this.props.tReady}
          i18n={this.props.i18n}
          appliedOptions={options.filter((element) => element.value === this.props.serviceStatusType)}
        />
      </Form>
    );
  };
  render() {
    const provider = {
      options: this.props.provider,
    };
    const filterSettingConfig =
      this.props.serviceStatusType === ServiceStatus.OPEN ? openGridFiltersSettings : historyGridFiltersSettings;
    const {
      searchString,
      serviceFilterAttributes,
      showNoRecordFoundView,
      appliedFilterQuery,
      filterFormValues,
      changeFilterFormValues,
    } = this.props;
    const moreFilterHandler: IMoreFilterCloseHandler = {
      closeMoreFilter: this.props.closeMoreFilter,
      isMoreFilterClosed: this.props.isMoreFilterOpen,
      openMoreFilter: this.props.openMoreFilter,
    };

    return (
      <>
        <SearchBar
          searchComponent={null}
          filterFormName={ServiceFormEnum.FILTER_FORM}
          customComponent={this.getRadioSelectDropdown()}
          closeMoreFilter={this.props.closeMoreFilter}
          filterAttributes={serviceFilterAttributes}
          handleFilterChange={this.handleFilterChange}
          filterSettings={this.props.actionType === ActionItem.edit ? filterSettingConfig : []}
          dependencies={{ provider }}
          getFilterAttributes={null}
          getList={null}
          searchString={searchString}
          setFilterQuery={null}
          setSearchQuery={this.props.setSearchQuery}
          showNoRecordFoundView={showNoRecordFoundView}
          parentMoreFilterHandler={moreFilterHandler}
          filterQuery={this.props.filterQuery}
          appliedFilterQuery={appliedFilterQuery}
          filterFormValues={filterFormValues}
          changeFilterFormValues={changeFilterFormValues}
        />
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  appliedFilterQuery: state.AssetService && state.AssetService.assetServiceAppliedFilterQuery,
  clearAppliedFilter: state.AssetService && state.AssetService.clearFilterResponse,
  filterFormValues: getFormValues(ServiceFormEnum.FILTER_FORM)(state),
  filterQuery: state.AssetService && state.AssetService.filterQuery,
  isMoreFilterOpen: state.AssetService && state.AssetService.isFilterPanelOpen,
  provider: state.AssetService && state.AssetService.provider,
  searchString: state.AssetService && state.AssetService.searchString,
  serviceFilterAttributes: state.AssetService && state.AssetService.serviceFilterAttributes,
  showNoRecordFoundView: state.AssetService && state.AssetService.showNoRecordFoundView,
});

export const mapDispatchToProps = (dispatch) => ({
  changeFilterFormValues: (field: string, value: string) => dispatch(change(ServiceFormEnum.FILTER_FORM, field, value)),
  closeMoreFilter: () => dispatch(closeServiceFilter()),
  fetchAssetServiceList: (offset: number, assetId: number, status: string, filters: string) =>
    dispatch(fetchAssetServiceList(offset, assetId, status, filters)),
  fetchProviderList: (query) => dispatch(providerList(query)),
  getServiceFilterAttributes: (assetId: number, filterQuery: string, delay: boolean, serviceStatusType: string) =>
    dispatch(getServiceFilterAttributes(assetId, filterQuery, delay, serviceStatusType)),
  openMoreFilter: () => dispatch(openServiceFilter()),
  setFilterAppliedAttributes: (filters) => dispatch(setAssetServiceAppliedAttributes(filters)),
  setSearchQuery: (searchString: string) => dispatch(setSearchQuery(searchString)),
});

export default withTranslation()(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(ServiceFilterContainer),
);
