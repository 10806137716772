import React from "react";
import { Ref } from "react";
import styled from "styled-components";
import { SquareIcon } from "../../components/icons/iconWrapper";
import IButtonProps from "./iButtonProps";

const setButtonStyle = (props) => {
  switch (props.buttonType) {
    case "primary":
      return `background-color: ${props.theme.buttonStyle.primary.backgroundColor};
      border: 2px solid ${props.theme.buttonStyle.primary.borderColor};
      color: ${props.theme.buttonStyle.primary.color};
    `;
    case "secondary":
      return `background-color: ${props.theme.buttonStyle.secondary.backgroundColor};
    border: 2px solid ${props.theme.buttonStyle.secondary.borderColor};
    color: ${props.theme.buttonStyle.secondary.color};
  `;

    case "cta":
      return `background-color: ${props.theme.buttonStyle.cta.backgroundColor};
      border: 2px solid ${props.theme.buttonStyle.cta.borderColor};
      color: ${props.theme.buttonStyle.cta.color};
      cursor: pointer;
  `;
    case "disabled":
      return `background-color: ${props.theme.buttonStyle.disabled.backgroundColor};
      border: 2px solid transparent;
      color: ${props.theme.buttonStyle.disabled.color};
      cursor: not-allowed;
      pointer-events: none;
  `;
    default:
      return `background-color: ${props.theme.buttonStyle.secondary.backgroundColor};
      border: 2px solid ${props.theme.buttonStyle.secondary.borderColor};
      color: ${props.theme.buttonStyle.secondary.color};
  `;
  }
};

const setHoverButtonStyle = (props) => {
  switch (props.buttonType) {
    case "primary":
      return `background-color: ${props.theme.buttonStyle.primary.backgroundHoverColor};
      border: 2px solid ${props.theme.buttonStyle.primary.backgroundHoverColor};
      transition: background-color .3s ease;
      cursor: pointer`;
    case "secondary":
      return `background-color: ${props.theme.buttonStyle.secondary.backgroundHoverColor};
      border: 2px solid ${props.theme.buttonStyle.secondary.borderColor};
      transition: background-color .3s ease;
      cursor: pointer`;
    case "cta":
      return `background-color: ${props.theme.buttonStyle.cta.backgroundHoverColor};
      border: 2px solid ${props.theme.buttonStyle.cta.backgroundHoverColor};
      transition: background-color .3s ease;
      cursor: pointer`;
    default:
      return `background-color: ${props.theme.buttonStyle.secondary.backgroundHoverColor};
      transition: background-color .3s ease;
      cursor: pointer`;
  }
};

export const StyledButton = styled("button")<IButtonProps>`
  padding: 12px 24px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  text-align: center;
  ${(props) => setButtonStyle(props)};
  display: flex;
  align-items: center;
  justify-content: center;
  & + button {
    margin-left: 16px;
  }
  & svg path {
    fill: white;
  }
  .icon {
    margin-right: 10px;
  }
  &:hover {
    ${(props) => setHoverButtonStyle(props)};
  }
`;
class Button extends React.PureComponent<IButtonProps> {
  /**
   * method to handle on click of button
   */
  onClick = (event) => {
    // added to handle IE browser, IE browser fires click even if disabled
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const textId = `${this.props.id}text`;
    const { buttonType, children, iconName, ...props } = this.props;
    return (
      <StyledButton
        ref={this.props.innerRef}
        type={this.props.type || "button"}
        id={this.props.id || "footerButton"}
        data-testid={this.props.id || "generic-button"}
        onClick={this.onClick}
        buttonType={buttonType}
        disabled={this.props.disabled}
        aria-label={this.props.ariaLabel}
        {...props}
      >
        {iconName && <SquareIcon className="icon" name={iconName} />}
        <span id={this.props.id ? textId : "buttonText"}>{children}</span>
      </StyledButton>
    );
  }
}
export default React.forwardRef((props: Omit<IButtonProps, "innerRef">, ref: Ref<HTMLButtonElement>) => (
  <Button innerRef={ref} {...props} />
));
