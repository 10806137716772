import { Field } from "am-web-ui-shared/elements";
// @ts-ignore
import styled from "custom-styled-components";
import React, { lazy } from "react";
import Gateway from "../../../common/gateway/gateway";
import ApplicationConstants from "../../applicationConstants";
import ImageTypeEnum from "../../utils/enums/imageTypeEnum";
import AttachmentsPanel from "../attachmentsPanel/attachmentsPanel";
import { DocumentType, IUploadComponentProps } from "./iUploadComponentProps";
import UploadComponentView from "./uploadpanelView";
import UploadProgress from "./uploadProgress";
import ViewOnlyAttachments from "./viewOnlyAttachments";
import { SuspenseWrapper } from "../../reactSuspenseWrapper";
const Dropzone = lazy(() => import("react-dropzone"));

/**
 * Class to show Dialog on the top of app pages
 *
 * @class Dialog
 * @extends {React.PureComponent<>}
 */

export const UploadPanel = styled.div`
  background: ${(props) => props.theme.colors.divBorder.backgroundLightGrey};
  text-align: center;
  color: ${(props) => props.theme.gridRow.rowTextColor};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 20px;
  padding-top: ${(props) => (props["data-padding"] ? "24px" : "0")};
`;

export const UploadPanelError = styled.div`
  position: relative;
  min-height: 160px;
  background: ${(props) => props.theme.colors.divBorder.backgroundLightGrey};
  border: 2px dashed
    ${(props) => (props["data-error"] ? props.theme.colors.base.hiltiRedError : props.theme.colors.base.borderColor)};
  text-align: center;
  color: ${(props) => props.theme.gridRow.rowTextColor};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 20px;
  #uploadCompError {
    border: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 24px;
  }

  p {
    display: flex;
    position: relative;
    justify-content: center;
  }
`;
export const UploadPanelEnter = styled.div`
  min-height: 180px;
  background: ${(props) => props.theme.colors.divBorder.backgroundLightGrey};
  border: 2px dashed #61223f;
  text-align: center;
  color: ${(props) => props.theme.gridRow.rowTextColor};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 20px;
  #uploadComponentEnter {
    border: 0;
  }

  p {
    margin: 24px 0 16px 0;
  }
`;

const dataAttachmentDisabled = "data-attachment-disabled";
export const UploadContainer = styled.div`
  cursor: ${(props) => (props["data-show-pointer"] && !props[dataAttachmentDisabled] ? "pointer" : "default")};
  .dropzone {
    outline: 0;
  }
  pointer-events: ${(props) => props[dataAttachmentDisabled] && "none"};
  opacity: ${(props) => props[dataAttachmentDisabled] && "0.5"};
`;

const ErrorMessageWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.colors.base.hiltiRedError};
`;
export const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-size: 28px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 34px;
  vertical-align: middle;
  position: relative;
`;

/**
 * @class Upload
 * @extends {React.PureComponent <IDropzoneCustomProps, IUploadState>}
 * @description Upload component resuable based on a file dropped to react dropzone.
 */

export class UploadComponent extends React.Component<IUploadComponentProps, any> {
  /**
   * Function which executes on upload the files.
   * @param formData
   * @param uploadApi
   * @param statusApi
   */
  CancelToken = Gateway.getToken();
  source: any;
  uploadInstance = React.createRef<UploadProgress>();
  constructor(props) {
    super(props);
    this.state = {
      dropEnter: false,
      fileName: "",
      fileSizeLoaded: 0,
      filesPreview: [],
      filesToBeSent: [],
      id: null,
      isCancelProgress: false,
      messageKey: "",
      notificationModel: false,
      printcount: 10,
      progressCount: 0,
      showProgress: false,
      totalFileSize: 0,
    };
  }

  /**
   * drop the uploaded files.
   * @param acceptedFiles
   */
  onDrop = (acceptedFiles) => {
    if (this.props.onChange) {
      this.props.onChange();
    }
    this.setState({ messageKey: "" });
    const attachments = this.props.getAttachments(this.props.formName, this.props.name);
    const errorResult = this.validate(acceptedFiles, attachments && attachments.length);
    if (acceptedFiles.length === 0 || errorResult) {
      this.setState({ messageKey: errorResult, dropEnter: false });
    } else {
      const file = acceptedFiles[0];
      const isFileValid = file.size <= acceptedFiles.maxSize; // check file size against maxSize

      this.setState(
        {
          dropEnter: false,
          filesToBeSent: acceptedFiles.map((item) => {
            item.documentKey = Date.now() + (Math.random() * ApplicationConstants.NUMBER.NUM100000).toFixed();
            return item;
          }),
          isFileValid,
          showProgress: true,
        },
        () => {
          this.uploadInstance.current.uploadApi(this.props.name, this.props.formName);
        },
      );
      if (acceptedFiles.length) {
        this.setState({
          showProgress: true,
        });
      } else {
        this.setState({ messageKey: "assets:FILE_SIZE_ERROR" });
      }
    }
  };
  /**
   * Executes when file enter in the dropbox.
   */
  onDragEnter = (event) => {
    if (event) {
      this.setState({ dropEnter: true, messageKey: null });
    }
  };
  /**
   * Executes when file leave from the dropbox.
   */
  onDragLeave = () => {
    this.setState({ dropEnter: false, messageKey: null });
  };

  /**
   * close notification modal message
   */

  handleProgressBar = () => {
    this.setState({
      messageKey: "",
      showProgress: false,
    });
  };

  closeProgressBar = () => {
    this.setState({ showProgress: false });
  };

  uploadDocument = (info, config) => {
    const { uploadDocument, responseFormatMapper, thumbnailHeight, thumbnailWidth } = this.props;
    if (thumbnailHeight && thumbnailWidth) {
      uploadDocument(info, config, responseFormatMapper, thumbnailWidth, thumbnailHeight, ImageTypeEnum.thumbnail);
    } else {
      uploadDocument(info, config, responseFormatMapper);
    }
  };

  /**
   * render upload panel.
   * @params {getInputProps}: Props for input.
   */
  renderUploadPanel = (open, isDragActive) => {
    const { t, allowedFileMessage, associatedEntity, thumbnailHeight, thumbnailWidth, isOnAssetsPage } = this.props;

    const docType =
      this.props.documentType === DocumentType.Image && !thumbnailHeight && !thumbnailWidth
        ? `${this.props.documentId}:${ImageTypeEnum.original}`
        : thumbnailHeight && thumbnailWidth
          ? `${this.props.documentId}:${ImageTypeEnum.thumbnail}`
          : this.props.documentId;
    const document = this.props.getDocument(docType);
    const documentURL = document && document.url;
    return (
      <UploadPanel
        id="uploadComponentPanel"
        data-image={this.props.documentType === DocumentType.Image ? documentURL : false}
        data-padding={!documentURL}
      >
        <UploadComponentView
          allowedFileMessage={allowedFileMessage}
          name={this.props.name}
          uploadImage={this.props.uploadImage}
          imageChangeHandler={this.imageChangeHandler}
          imageData={documentURL}
          maxSizeTitle={this.props.maxSizeTitle}
          documentType={this.props.documentType}
          t={t}
          showProgress={this.state.showProgress}
          editImage={open}
          isDragActive={isDragActive}
          onRemove={this.props.removeImage.bind(null, this.props.formName, this.props.name)}
          isOnAssetsPage={isOnAssetsPage}
        />
        {this.state.showProgress ? (
          <UploadProgress
            handleProgressBar={this.handleProgressBar}
            ref={this.uploadInstance}
            showToaster={this.props.showToaster}
            file={this.state.filesToBeSent}
            uploadDocument={this.uploadDocument}
            associatedEntity={associatedEntity}
            imageData={documentURL}
            documentType={this.props.documentType}
            attachmentIdKey={this.props.attachmentIdKey}
            t={t}
            closeProgressBar={this.closeProgressBar}
          />
        ) : null}
      </UploadPanel>
    );
  };

  /**
   * open notification modal for success
   */
  imageChangeHandler = (documentId) => {
    const { thumbnailHeight, thumbnailWidth, getDocumentById } = this.props;
    if (thumbnailHeight && thumbnailWidth) {
      getDocumentById(documentId, true, ImageTypeEnum.thumbnail, thumbnailWidth, thumbnailHeight);
    } else {
      getDocumentById(documentId, true);
    }
  };
  render() {
    const {
      allowDownloadAttachment,
      attachmentProperty,
      t,
      documentType,
      label,
      name,
      isDisabled,
      disableActionOnAttachment,
      viewOnlyAttachment,
      viewOnlyAttachmentLabelName,
    } = this.props;
    return (
      <UploadContainer data-show-pointer={!this.props.documentId} data-attachment-disabled={disableActionOnAttachment}>
        <Field label={label} htmlFor={`${name}Lbl`} name={name}>
          <SuspenseWrapper>
            <Dropzone
              noClick={!!this.props.documentId}
              onDrop={this.onDrop}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
            >
              {({ getRootProps, getInputProps, fileRejections, isDragActive, open }) => {
                return (
                  <div {...(getRootProps() as any)} className={"dropzone"}>
                    <div>
                      <UploadPanelError
                        id="uploadCompError"
                        data-error={
                          this.props.uploadError[this.props.name] || this.state.messageKey || fileRejections.length > 0
                        }
                      >
                        <div>
                          <input
                            data-testid="uploadComponnet-input"
                            {...(getInputProps() as any)}
                            disabled={isDisabled}
                          />
                        </div>
                        {isDragActive && !this.props.documentId ? (
                          <UploadPanelEnter class="uploadComponentEnter">
                            <Wrapper>{t("common:DROP_FILE_MESSAGE")}</Wrapper>
                          </UploadPanelEnter>
                        ) : (
                          this.renderUploadPanel(open, isDragActive)
                        )}
                      </UploadPanelError>
                      <ErrorMessageWrapper>
                        {this.props.uploadError[this.props.name] || t(this.state.messageKey)}
                      </ErrorMessageWrapper>
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          </SuspenseWrapper>
          {documentType === DocumentType.Attachment && (
            <AttachmentsPanel
              attachmentProperty={attachmentProperty}
              name={this.props.name}
              responseFormatMapper={this.props.responseFormatMapper}
              allowDownloadAttachment={allowDownloadAttachment}
            />
          )}
          {viewOnlyAttachment && viewOnlyAttachment.length > 0 && (
            <ViewOnlyAttachments
              viewOnlyAttachment={viewOnlyAttachment}
              viewOnlyAttachmentLabelName={viewOnlyAttachmentLabelName}
            />
          )}
        </Field>
      </UploadContainer>
    );
  }

  clearState(documentKeys: any[]) {
    const currentStateFilter = this.state.filesToBeSent.filter(
      (item) => !documentKeys.includes(item.documentKey.toString()),
    );
    this.setState({
      filesToBeSent: currentStateFilter,
      messageKey: "",
    });
    if (currentStateFilter.length === 0) {
      this.setState({
        showProgress: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.errorHandling(prevProps, this.props);
    if (prevProps.documentKeys?.join("") !== this.props.documentKeys?.join("")) {
      this.clearState(this.props.documentKeys);
    }
  }

  validate(acceptedFiles, attachmentCount) {
    if (this.props.validations && acceptedFiles.length > 0) {
      for (const file of acceptedFiles) {
        for (const action of this.props.validations) {
          const result = action(acceptedFiles, file, this.props.t, attachmentCount);
          if (result) {
            return result;
          }
        }
      }
    }

    return "";
  }

  errorHandling(prevProps, props) {
    if (prevProps.error?.[this.props.documentType] !== props.error?.[this.props.documentType]) {
      this.setState({ messageKey: this.props.error[this.props.documentType], dropEnter: null });
    }
  }
  componentWillUnmount() {
    this.props.clearData();
  }
}

export default UploadComponent;
