import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-794.000000, -3829.000000)" fill="#524F53">
        <g transform="translate(792.000000, 3827.000000)">
          <path
            d="M10.7426407,13.9852814 L7.91421356,11.1568542 L6.5,12.5710678 L9.32842712,15.3994949 L10.7426407,16.8137085 L17.8137085,9.74264069 L16.3994949,8.32842712 L10.7426407,13.9852814 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
