import React from "react";
import { HelperLodash } from "../../helpers";
import { ISortedListProps, ISortedListState } from "./iSortComponentProps";
import SortComponent from "./sortComponent";
import { getListObjectValue, uniqueArray } from "./sortUtils";

export default class Sort extends React.Component<ISortedListProps, ISortedListState> {
  state: ISortedListState = {
    selectedSortField: {
      label: this.props.defaultSort.sortField,
      name: this.props.defaultSort.sortField,
      sortType: this.props.defaultSort.sortType,
    },
    sortList: [],
  };

  componentDidMount() {
    this.getSortList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.columns !== prevProps.columns || this.props.columnFields !== prevProps.columnFields) {
      this.getSortList();
    }
    if (!HelperLodash.isEqual(this.props.defaultSort, prevProps.defaultSort) && this.props.defaultSort) {
      this.setState({
        selectedSortField: {
          label: this.props.defaultSort.sortField,
          name: this.props.defaultSort.sortField,
          sortType: this.props.defaultSort.sortType,
        },
      });
    }
  }

  /**
   * @description- To get list of sorting fields and default sorting field according to custom grid
   *
   */

  getSortList = () => {
    const sortList = [];
    this.props.columns.forEach((item) => {
      if (item.isSortable) {
        item.bindProperties.forEach((property) => {
          if (
            this.props.columnFields &&
            getListObjectValue(this.props.columnFields, property.properties[0].name) !== undefined &&
            (!this.props.ignoreSortProperties ||
              (this.props.ignoreSortProperties &&
                this.props.ignoreSortProperties.indexOf(property.properties[0].name) === -1))
          ) {
            sortList.push({
              label: getListObjectValue(this.props.columnFields, property.properties[0].name),
              name: property.properties[0].name,
            });
          }
        });
      }
    });
    this.setState({ sortList: uniqueArray(sortList) }, () => {
      if (this.props.isSortListUpdate && this.state.selectedSortField) {
        this.props.setSortQuery({
          sortField: this.state.selectedSortField.name,
          sortType: this.state.selectedSortField.sortType,
        });
      }
    });
  };

  /**
   * @description - Function build query param based on selection and sort type.
   * @param sortObj - Its the selected sort object
   * @param sortType - Its enum - ASCENDING | DESCENDING.
   */
  getSortedField = (sortObj, sortType: string) => {
    const sortQuery = { sortField: sortObj.name, sortType };
    this.props.setSortQuery(sortQuery);
    this.props.fetchSortedList();
    this.props.setCustomViewModifiedFlag();
  };
  /**
   * @description - To get display name for dropdown
   * @param-{value} value selected in dropdown
   */
  render() {
    return (
      <SortComponent
        list={this.state.sortList || []}
        selectedSortField={this.state.selectedSortField}
        onFieldSelected={this.getSortedField}
        isTranslation={true}
        showSortingModal={this.props.showSortingModal}
        closeMenu={this.props.closeMenu}
        t={this.props.t}
      />
    );
  }
}
