import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import UserLicenseModel from "../models/userLicenseModel";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";
/**
 * @description Function to get User Subcribed License
 */
export const fetchUserLicense = async (includeCount): Promise<UserLicenseModel | IErrorResponse> => {
  return Gateway.get(
    getOrgMgmtEndpoint(`/license-usage-info?includeCount=${includeCount}`),
    null,
    null,
  ) as unknown as UserLicenseModel;
};
