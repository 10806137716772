// @ts-ignore
import { ActiveTrackingActions } from "custom-actions";
import { connect } from "react-redux";
import { change, getFormValues } from "redux-form";
import {
  resetQueryObjToDefault,
  setAppliedAttributes,
  setFilterQuery,
  setSortingQuery,
  toggleAssetListDialog,
} from "../../../actions/activeTrackingActionRoot";
import {
  clearAllSelection,
  handleGridCheckboxSelection,
  handleRowSelection,
  selectAll,
} from "../../../../../actions/listActions";
import { showToastr, ToastrType } from "../../../../../actions/toastrAction";
import { setTransferCartItem } from "../../../../../actions/transferCartActions";
import SmartInventoryAssetsView from "../presentational/smartInventoryAssetsListView";

const showAssetListDialog = (state) => state.ActiveTracking && state.ActiveTracking.showAssetListDialog;
const getFilterAttributes = (state) => state.ActiveTracking && state.ActiveTracking.assetFilterAttributes;
const getAssetList = (state) => state.ActiveTracking && state.ActiveTracking.gatewayAssetsList;
const getListLoader = (state) => state.Loader && state.Loader.list;
const getParentCount = (state) =>
  state.ActiveTracking && state.ActiveTracking.gatewayAssetsList && state.ActiveTracking.gatewayAssetsList.totalRecords;
const getSortQuery = (state) => state.ActiveTracking && state.ActiveTracking.sortQuery;
const getTransferList = (state) => state.TransferCart && state.TransferCart.list;
const getSelectAllStatus = (state) => state.ActiveTracking && state.ActiveTracking.isAllSelected;
export const mapStateToProps = (state) => ({
  appliedFilterQuery: state.ActiveTracking && state.ActiveTracking.appliedFilterQuery,
  filterAttributes: getFilterAttributes(state),
  filterFormValues: getFormValues("gatewayFilter")(state),
  gatewayAssetsList: getAssetList(state),
  isAllSelected: getSelectAllStatus(state),
  listLoader: getListLoader(state),
  parentCount: getParentCount(state),
  selectAllAssets: state.ActiveTracking.selectAllAssets,
  showAssetListDialog: showAssetListDialog(state),
  sortQuery: getSortQuery(state),
  transferCartList: getTransferList(state),
});

export const mapDispatchToProps = (dispatch) => ({
  changeFilterFormValues: (field: string, value: string) => dispatch(change("gatewayFilter", field, value)),
  onCheckedRow: (item) =>
    dispatch(handleGridCheckboxSelection(item, ActiveTrackingActions.AT_GATEWAY_ASSET_SELECT_GRID_CHECKBOX, true)),
  onClearAllSelection: () => dispatch(clearAllSelection(ActiveTrackingActions.CLEAR_GATEWAY_ASSETS_CHECKED_ROWS)),
  onSelectAll: () => dispatch(selectAll(ActiveTrackingActions.SELECT_ALL_GATEWAY_ASSETS_ROWS)),
  onUncheckedRow: (item) =>
    dispatch(handleGridCheckboxSelection(item, ActiveTrackingActions.AT_GATEWAY_ASSET_UNSELECT_GRID_CHECKBOX, false)),
  onSelectRow: (item) => dispatch(handleRowSelection(item, ActiveTrackingActions.SELECT_GATEWAY_ASSETS_ROW)),
  resetQueryObjToDefault: () => dispatch(resetQueryObjToDefault()),
  setFilterAppliedAttributes: (filters) => dispatch(setAppliedAttributes(filters)),
  setFilterQuery: (filterQuery) => dispatch(setFilterQuery(filterQuery)),
  setSortQuery: (sortQuery: any) => dispatch(setSortingQuery(sortQuery)),
  setTransferCartItemToStore: (items) => dispatch(setTransferCartItem(items)),
  showToast: (type: ToastrType, message: string) => dispatch(showToastr(type, message)),
  toggleAssetListDialog: (showDialog: boolean) => dispatch(toggleAssetListDialog(showDialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartInventoryAssetsView);
