import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M14,16 L14,20 L10,20 L10,16 L14,16 Z M14,10 L14,14 L10,14 L10,10 L14,10 Z M14,4 L14,8 L10,8 L10,4 L14,4 Z" />
    </g>
  </svg>
);

export default SVG;
