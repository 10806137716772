// @ts-ignore
import { APIMessages } from "custom-actions";
import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import {
  IFieldRequestSettingsData,
  IFieldRequestSettingsResponse,
} from "../modules/fieldRequest/settings/iFieldRequestSettingsProps";
import {
  IFieldRequestActionUpdateData,
  IFieldRequestActionUpdateResponse,
  IFieldRequestCountResponse,
  IFieldRequestDetailsData,
  IFieldRequestList,
} from "../modules/fieldRequest/receiver/components/iFieldRequest";
import { getAssetMgmtEndpoint, getOrgMgmtEndpoint } from "../utils/apiUtils";
import { UserPersona } from "../utils/enums/fieldRquestEnum";

export const getFieldRequestsCountApi = async (): Promise<IFieldRequestCountResponse | IErrorResponse> => {
  try {
    const url = getAssetMgmtEndpoint("/field-request/count");
    return Gateway.get(url, {}, null, null, false) as unknown as IFieldRequestCountResponse;
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG } as unknown as IErrorResponse;
  }
};

export const getFieldRequestsListApi = async (
  queryObj,
  loader = true,
  view = UserPersona.Receiver,
): Promise<IFieldRequestList | IErrorResponse> => {
  try {
    const filter = queryObj.filter;
    delete queryObj.filter;
    let url = null;
    if (filter) {
      url = getAssetMgmtEndpoint(`/field-request?view=${view}&${filter}`);
    } else {
      url = getAssetMgmtEndpoint(`/field-request?view=${view}`);
    }
    return Gateway.get(url, { ...queryObj }, null, null, loader, false) as unknown as IFieldRequestList;
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG } as unknown as IErrorResponse;
  }
};

export const getFieldRequestById = async (id): Promise<IFieldRequestDetailsData | IErrorResponse> => {
  try {
    const url = getAssetMgmtEndpoint(`/field-request/${id}`);
    return Gateway.get(url, {}, null, null, true) as unknown as IFieldRequestDetailsData;
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG } as unknown as IErrorResponse;
  }
};

export const updateFieldRequestInfo = async (
  fieldRequestDetails: IFieldRequestActionUpdateData,
): Promise<IFieldRequestActionUpdateResponse | IErrorResponse> => {
  try {
    const { requestId, action, notes } = { ...fieldRequestDetails };
    const requestData = {
      requestId,
      notes,
      action,
    };
    const url = getAssetMgmtEndpoint(`/field-request`);
    return Gateway.patch(url, null, requestData, null) as unknown as IFieldRequestActionUpdateResponse;
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG } as unknown as IErrorResponse;
  }
};
/**
 * Returns Field Request settings response
 */
export const getFieldRequestSettingsApi = async (): Promise<IFieldRequestSettingsResponse | IErrorResponse> => {
  const url = getOrgMgmtEndpoint(`/company/field-request-setting`);
  return Gateway.get(url, null, null) as unknown as IFieldRequestSettingsResponse;
};

/**
 * Update Field request Settings and return response
 * @param newFieldRequestSetting: Flag to enable or disable the field request settings
 */
export const updateFieldRequestSettingsApi = async (
  newFieldRequestSetting: IFieldRequestSettingsData,
): Promise<IFieldRequestSettingsResponse | IErrorResponse> => {
  const url = getOrgMgmtEndpoint(`/company/field-request-setting`);
  return Gateway.put(url, null, newFieldRequestSetting, null) as unknown as IFieldRequestSettingsResponse;
};
