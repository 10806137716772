import lodashValues from "lodash-es/values";
import lodashGet from "lodash-es/get";
import lodashIsEmpty from "lodash-es/isEmpty";
import lodashSnakeCase from "lodash-es/snakeCase";
import lodashUpperCase from "lodash-es/upperCase";
import lodashToUpper from "lodash-es/toUpper";
import lodashCompact from "lodash-es/compact";
import lodashUpperFirst from "lodash-es/upperFirst";
import lodashFirst from "lodash-es/first";
import lodashIsEqual from "lodash-es/isEqual";
import lodashIntersectionBy from "lodash-es/intersectionBy";
import lodashIntersection from "lodash-es/intersection";
import lodashGroupBy from "lodash-es/groupBy";
import lodashFilter from "lodash-es/filter";
import lodashIncludes from "lodash-es/includes";
import lodashSet from "lodash-es/set";
import lodashOrderBy from "lodash-es/orderBy";
import lodashClone from "lodash-es/clone";
import lodashSortBy from "lodash-es/sortBy";
import lodashUniqWith from "lodash-es/uniqWith";
import lodashUniqBy from "lodash-es/uniqBy";
import lodashToLower from "lodash-es/toLower";
import lodashUniq from "lodash-es/uniq";
import lodashCloneDeep from "lodash-es/cloneDeep";
import lodashFind from "lodash-es/find";
import lodashIndexOf from "lodash-es/indexOf";
import lodashFindIndex from "lodash-es/findIndex";
import lodashDifferenceBy from "lodash-es/differenceBy";
import lodashConcat from "lodash-es/concat";
import lodashDebounce from "lodash-es/debounce";
import lodashReverse from "lodash-es/reverse";
import lodashCamelCase from "lodash-es/camelCase";
import lodashCapitalize from "lodash-es/capitalize";
import lodashIsNil from "lodash-es/isNil";
import lodashIsFunction from "lodash-es/isFunction";
import lodashKeys from "lodash-es/keys";
import lodashMap from "lodash-es/map";
import lodashRange from "lodash-es/range";
import lodashPartition from "lodash-es/partition";
import lodashOmit from "lodash-es/omit";
import lodashSumBy from "lodash-es/sumBy";
import lodashRemove from "lodash-es/remove";
import lodashJoin from "lodash-es/join";
import lodashSize from "lodash-es/size";
import lodashEscape from "lodash-es/escape";
import lodashUnescape from "lodash-es/unescape";
import lodashEvery from "lodash-es/every";
import lodashForEach from "lodash-es/forEach";
import lodashPick from "lodash-es/pick";
/**
 * @description - Check if object is empty OR object values are empty.
 *
 * @param object - Object to be checked.
 */
export const isObjectValueEmpty = (object: any) => {
  return !lodashValues(object).some((x) => !isEmpty(x));
};

/**
 * @description - Gets the value at path of object.
 * @param object (object) - The object to query.
 * @param path (Array|string) - The path of the property to get.
 */

export const get = (object, path, defaultValue?) => {
  return lodashGet(object, path, defaultValue);
};

/**
 * @description Converts string to snakeCase.
 * @param label The string to convert.
 */
export const snakeCase = (label) => {
  return lodashSnakeCase(label);
};

export const constantCase = (label) => {
  const labelInUpper = lodashUpperCase(label);
  return labelInUpper.replace(/ /g, "_");
};

/**
 * @description Converts string to upper case.
 * @param label The string to convert.
 */
export const toUpper = (label) => {
  return lodashToUpper(label);
};

/**
 * @description Creates an array with all falsey values removed.
 * @param data The array to compact.
 */
export const compact = (data) => {
  return lodashCompact(data);
};

/**
 * @description Converts the first character of string to upper case.
 * @param label The string to convert.
 */
export const upperFirst = (label: string) => {
  return lodashUpperFirst(label);
};

export const first = (value) => {
  return lodashFirst(value);
};

/**
 * @description - Performs a deep comparison between two values to determine if they are equivalent.
 * @param val1 - The value to compare.
 * @param val2 - The other value to compare.
 */
export function isEqual(val1, val2) {
  return lodashIsEqual(val1, val2);
}

export function intersection(val1, val2) {
  return lodashIntersection(val1, val2);
}

/**
 * @description - Check if array or object is empty or not
 * @param obj object or array
 */

export const isEmpty = (obj) => {
  return lodashIsEmpty(obj);
};
/**
 * @description -Returns the new array of intersecting values.
 * @param selectedOptions
 * @param options
 * @param valueId
 */
export const intersectionBy = (selectedOptions: any, options: any, valueId: any) => {
  return lodashIntersectionBy(selectedOptions, options, valueId);
};

/**
 * @description Returns the composed aggregate object
 * @param collection
 * @param identity
 */
export const groupBy = (collection, identity) => {
  return lodashGroupBy(collection, identity);
};
/**
 * Returns the new filtered array
 * @param collection
 * @param identity
 */
export const filter = (collection, identity) => {
  return lodashFilter(collection, identity);
};
/**
 * Returns true if value is found, else false
 * @param collection
 * @param value
 */
export const includes = (collection, value) => {
  return lodashIncludes(collection, value);
};
/**
 * Returns object
 * @param object
 * @param path
 * @param value
 */
export const set = (object, path, value) => {
  return lodashSet(object, path, value);
};
/**
 * Returns the new sorted array
 * @param list
 * @param comparator
 * @param direction
 */
export const orderBy = (list, comparator, direction) => {
  return lodashOrderBy(list, [comparator], [direction]);
};

/**
 * Returns the cloned value
 * @param value
 */

export const clone = (value) => {
  return lodashClone(value);
};

/**
 * Returns the sorted value on field
 * @param list
 * @param sortByField
 */

export const sortBy = (list, callBack) => {
  return lodashSortBy(list, callBack);
};
/**
 * Returns the new duplicate free array
 * @param array
 * @param Comparator
 */
export const uniqWith = (array, Comparator) => {
  return lodashUniqWith(array, Comparator);
};
/**
 * Returns the new duplicate free array
 * @param array
 * @param callback
 */
export const uniqBy = (array, callBack) => {
  return lodashUniqBy(array, callBack);
};
/**
 * Returns the string in lowercase
 */
export const toLower = (val) => {
  return lodashToLower(val);
};
/**
 * Returns the new duplicate free array
 * @param array
 */
export const uniq = (array) => {
  return lodashUniq(array);
};
/**
 * Returns the deep cloned value
 * @param value
 */
export const cloneDeep = (value) => {
  return lodashCloneDeep(value);
};
/**
 * Returns the matched element, else undefined
 * @param array
 * @param condition
 */
export const find = (array, condition) => {
  return lodashFind(array, condition);
};
/**
 * Returns the index of the matched value, else -1
 * @param array
 * @param match
 */
export const indexOf = (array, match) => {
  return lodashIndexOf(array, match);
};

/**
 * Returns the index of the matched value, else -1
 * @param array
 * @param match
 */
export const findIndex = (array, condition) => {
  return lodashFindIndex(array, condition);
};
/**
 * Returns the new array of filtered values
 * @param array
 * @param value
 * @param key
 */
export const differenceBy = (array, value, key) => {
  return lodashDifferenceBy(array, value, key);
};
/**
 * Returns the new concatenated array
 * @param array
 * @param values
 */
export const concat = (array, values) => {
  return lodashConcat(array, values);
};
/**
 * Returns the new debounced function
 * @param func
 * @param wait
 */
export const debounce = (func, wait) => {
  return lodashDebounce(func, wait);
};

/**
 * Returns the reversed Array
 * @param array
 */
export const reverse = (array) => {
  return lodashReverse(array);
};
/**
 * Return the string in camelcase
 * @param data
 */
export const camelCase = (label: string) => {
  return lodashCamelCase(label);
};

/**
 * ReturnConverts the first character of string to upper case and the remaining to lower case.
 * @param data
 */
export const capitalize = (label: string) => {
  return lodashCapitalize(label);
};

/**
 * @description - Check if value is undefined/null or not
 * @param obj object or array
 */
export const isNil = (obj) => {
  return lodashIsNil(obj);
};

export const isFunction = (fn) => {
  return lodashIsFunction(fn);
};

export const getObjectKeys = (obj) => {
  return lodashKeys(obj);
};

export const getPathValue = (collection, path) => lodashMap(collection, path);

/**
 * @description - Check if value is undefined/null or not
 *
 * @param data array of object
 * @param key {string} primary key use to delete particular object
 * @param value value to delete which object select this value
 */
export const removeObjectByKey = (data, key: string, value) => filter(data, (obj) => value !== get(obj, key));

/**
 * @description - return the array with secific range
 *
 * @param start
 * @param end
 */
export const range = (start, end) => lodashRange(start, end);

/**
 * @description - Creates an array of elements split into two groups, First contains elements predicate returns truthy for, Second for falsy
 * @param collection
 * @param identity
 * @returns - array of grouped elements
 */
export const partition = (collection, identity) => lodashPartition(collection, identity);

export const omit = (collection, paths) => lodashOmit(collection, paths);

/**
 * Returns the Sum be array object key
 * @param data -  array
 * @param key
 */
export const sum = (data, key: string) => lodashSumBy(data, key);

/**
 * @description - Remove the item from the array using key
 * @param - collection of items
 * @param - element which need to be removed
 *
 */
export const remove = (collection, element) => lodashRemove(collection, element);
/**
 * @description - join the elements
 * @param - array of items
 * @param - seprator join with
 *
 */
export const join = (array, seprator) => lodashJoin(array, seprator);

/**
 * @description - Returns the collection size.
 * @param collection
 */
export const size = (collection) => lodashSize(collection);

export const escape = (string) => lodashEscape(string);
export const unescape = (label) => lodashUnescape(label);

/**
 * @description - Iterate through entire array and find if a key has same value for all object
 * @param array - Array of object.
 * @param value - key value pair to identify
 */
export const isSameValue = (array, value) => {
  return lodashEvery(array, value);
};
export const pick = (object, paths) => lodashPick(object, paths);

export default {
  camelCase,
  capitalize,
  clone,
  cloneDeep,
  compact,
  concat,
  constantCase,
  debounce,
  differenceBy,
  escape,
  filter,
  find,
  findIndex,
  first,
  get,
  getObjectKeys,
  getPathValue,
  groupBy,
  includes,
  indexOf,
  intersection,
  intersectionBy,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isObjectValueEmpty,
  join,
  omit,
  orderBy,
  partition,
  range,
  remove,
  removeObjectByKey,
  reverse,
  set,
  size,
  snakeCase,
  sortBy,
  sum,
  toLower,
  toUpper,
  unescape,
  uniq,
  uniqBy,
  uniqWith,
  upperFirst,
  isSameValue,
  pick,
};

export const forEach = (collection, iteratee) => lodashForEach(collection, iteratee);
