// @ts-ignore
import { APIMessages } from "custom-actions";
import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import {
  getAssetMgmtEndpoint,
  getAuditingEndpoint,
  getOrgMgmtEndpoint,
  getUserMgmtEndpoint,
  getChargesEndpoint,
} from "../utils/apiUtils";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import IActivityType from "../models/report/activityTypes";
import { IChargesMasterDataApi } from "../models/charges/iChargeSetting";

export const getAssetCodes = async (): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/codes");
    return Gateway.get(url, {}, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getAssetUsageCondition = async (): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/usage-condition-state");
    return Gateway.get(url, {}, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getCurrencies = async (): Promise<any> => {
  try {
    const url = getOrgMgmtEndpoint("/currencies");
    return Gateway.get(url, {}, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getScanCodeType = async (): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/identification-mechanisms");
    return Gateway.get(url, {}, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

/**
 * Returns list of Roles of worker
 */
export const getRoles = async (): Promise<any> => {
  try {
    const url = getUserMgmtEndpoint("/roles");
    return Gateway.get(url, {}, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

/**
 * Returns list of countries by calling relevant API
 */
export const getCountries = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint("/countries"), null, null, null, false);
};

/**
 * @description Returns the list of Location States from API
 */
export const getLocationStatesList = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint("/locations/states"), null, null, null, false);
};

/**
 * Returns list of Language
 */
export const getLanguage = async (countryCode?): Promise<any> => {
  return Gateway.getUnAuthorize("/languages", { countryCode });
};

/**
 * Returns list of Allocation list
 */
export const allocationStatusListApi = async () => {
  const url = getAssetMgmtEndpoint("/quantity-items/allocation-status");
  return Gateway.get(url, null, null, null, false);
};

export const getReportTemplateCategories = async (): Promise<any> => {
  const url = getVersionedApiUrl(ServiceUrl.REPORTING, "/report-categories");
  return Gateway.get(url, null, null, null, false) as unknown as any;
};

/**
 * Returns list of Allocation stock level
 */
export const allocationStockLevelApi = async () => {
  const url = getAssetMgmtEndpoint("/quantity-items/allocation/stock-levels");
  return Gateway.get(url, null, null, null, false);
};

/**
 * Returns list of service status
 */
export const getServiceStatus = async () => {
  const url = getAssetMgmtEndpoint("/services/service-statuses");
  return Gateway.get(url, null, null, null, false);
};

/**
 * Returns list of service status
 */
export const getServiceResults = async () => {
  const url = getAssetMgmtEndpoint("/services/service-results");
  return Gateway.get(url, null, null, null, false);
};

/**
 * Returns list of certificate status
 */
export const getCertificateStatus = async () => {
  const url = getUserMgmtEndpoint("/workers/worker-certificate-status");
  return Gateway.get(url, null, null, null, false);
};

/**
 * Returns list of certificate status
 */
export const getHiltiAssetStatus = async () => {
  const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, "/tool/hilti-asset-status");
  return Gateway.get(url, null, null, null, false);
};

/**
 * Returns list of activity types for which quantity items history report can be generated
 */
export const getQtyItemActivityTypes = async () => {
  const url = getAssetMgmtEndpoint("/quantity-items/activity-types");
  return Gateway.get<IActivityType[]>(url, null, null, null, false);
};

export const getTimeBasedEnabledTenants = async () => {
  const url = getVersionedApiUrl(ServiceUrl.CHARGES, "/toggle-on/tenants");
  return Gateway.get(url, null, null, false);
};

export const getCostCodes = async (): Promise<IChargesMasterDataApi | IErrorResponse> => {
  return Gateway.get<IChargesMasterDataApi>(getChargesEndpoint("/assets/unique-assets/codes"), {}, null, null, false);
};
