import React from "react";
import { IconName } from "am-web-ui-shared/components";
import { LocaleManager } from "am-web-ui-shared/helpers";
import { IconNormalBold } from "../../../DetailsPanel";
import { IServiceDetail } from "./iServiceDetail";
import { CountExpansionPanel } from "../../../../components/DetailsPanel/index";
import { useTranslation } from "react-i18next";
import { AssetServiceCategory } from "../../../../utils/enums/serviceEnum";

// @ts-ignore
import styled from "custom-styled-components";

const DetailWrapper = styled.div`
  padding: 0 16px;
`;

export const BasedOnPanel = (props: IServiceDetail) => {
  const { selectedRow, distanceUnitValue } = props;

  const { t } = useTranslation();

  const unitsOfMeasurement = () => {
    switch (selectedRow?.basedOn) {
      case AssetServiceCategory.DISTANCE:
        return distanceUnitValue?.value;
      case AssetServiceCategory.OPERATING_TIME:
        return t("services:ENGINE_HOURS");
      case AssetServiceCategory.PERIOD:
        return t("services:DATE");
      default:
        return t("services:DATE");
    }
  };

  /**
   * @description Return asset Service basedon Category Label
   *
   * @param basedOn - basedOn Service category
   * @param t - translation function
   * @Return - Returns translated Basedon category List
   */
  const assetServiceCategoryLabel = (basedOn, t) => {
    switch (basedOn) {
      case AssetServiceCategory.DISTANCE:
        return t("services:SERVICES_LABEL_DISTANCE");
      case AssetServiceCategory.OPERATING_TIME:
        return t("services:SERVICES_LABEL_OPERATING_TIME");
      case AssetServiceCategory.PERIOD:
        return t("services:SERVICES_LABEL_PERIOD");
      default:
        return t("services:SERVICES_LABEL_PERIOD");
    }
  };

  const scheduleAtValue = selectedRow?.scheduleAt ? selectedRow.scheduleAt : "-";
  return (
    <CountExpansionPanel
      panelId="ServicesSectionHE"
      headingId="headOpenServicesHE"
      boldId="lblOpenServicesHE"
      boldText={t("services:BASED_ON")}
      countId="lblOpenServicesCount"
      countText={assetServiceCategoryLabel(selectedRow?.basedOn, t)}
      iconUrl={IconName.CompleteService}
      showExpandIcon={true}
      defaultExpanded={false}
    >
      <DetailWrapper>
        {selectedRow && (
          <>
            <IconNormalBold
              id="pnlScheduled"
              normalId="lblScheduled"
              normalText={t("services:SCHEDULED_HE", {
                unitName: unitsOfMeasurement(),
              })}
              boldId="txtScheduled"
              boldText={
                selectedRow?.basedOn === AssetServiceCategory.PERIOD
                  ? LocaleManager.dateHelpers.getDateInTenantTimezone(selectedRow?.scheduleDate)
                  : scheduleAtValue
              }
            />
            <IconNormalBold
              id="pnlCompleated"
              normalId="lblCompleated"
              normalText={t("services:COMPLETED_HE", {
                unitName: unitsOfMeasurement(),
              })}
              boldId="txtCompleated"
              boldText={
                selectedRow?.basedOn === AssetServiceCategory.PERIOD
                  ? LocaleManager.dateHelpers.getDateInTenantTimezone(selectedRow?.completionDate)
                  : selectedRow?.completionAt
              }
            />
          </>
        )}
      </DetailWrapper>
    </CountExpansionPanel>
  );
};
export default BasedOnPanel;
