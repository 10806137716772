import React from "react";
import styled from "styled-components";
import { Input } from "../../elements";
import { default as TextField } from "../../elements/Field/field";
import ICommonProps from "../commonInterfaceProps/iCommonProps";
import IconComponent from "../icons/iconComponent/iconComponent";
import { IconName } from "../icons/iconEnum";
import iInputOrInfoIconProps from "./iInputOrInfoIconProps";
const TextIconStyle = styled.div`
  display: flex;
`;

const InputInfoStyle = styled.div`
  left: 100px;
  position: absolute;
  top: -3px;
`;

const GridStyle = styled.div`
  position: relative;
`;

const TextHeadingStyle = styled(TextField)`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
`;

const TextValueStyle = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 17px;
  text-align: left;
  margin-right: 4px;
`;

/**
 * common Interface implementaion
 */
type InputOrInfoIconProps = ICommonProps & iInputOrInfoIconProps;

/**
 * @description - A class for input or Info Icon Component
 *
 * @class InputOrInfoIcon
 * @extends {React.PureComponent<InputOrInfoIconProps, {}>}
 */
class InputOrInfoIcon extends React.PureComponent<InputOrInfoIconProps, {}> {
  static defaultProps = {
    renderInfoIcon: false,
    renderInfoIconReadOnly: true,
  };
  render() {
    const {
      id,
      label,
      name,
      placeholder,
      isFormControl,
      value,
      required,
      showToolTip,
      textHeading,
      textValue,
      getContent,
      place,
      html,
      width,
    } = this.props;
    if (!showToolTip) {
      return (
        <GridStyle>
          <Input
            id={id}
            name={name}
            label={label}
            placeholder={placeholder}
            isFormControl={isFormControl}
            value={value}
            onChange={this.props.onChange}
            required={required}
            validation={this.props.validation}
            disabled={this.props.disabled}
            onBlur={this.props.onBlur}
          />
          {this.props.renderInfoIcon ? (
            <InputInfoStyle>
              <IconComponent
                icon={IconName.Info}
                id={id}
                getContent={getContent}
                isIconDisabled={true}
                html={html}
                place={place}
                width={width}
              />
            </InputInfoStyle>
          ) : null}
        </GridStyle>
      );
    } else {
      return (
        <GridStyle>
          <TextHeadingStyle name={textHeading} htmlFor={textHeading} label={textHeading}>
            <TextIconStyle>
              <TextValueStyle>{textValue}</TextValueStyle>
              {this.props.renderInfoIconReadOnly && (
                <IconComponent
                  icon={IconName.Info}
                  id={id}
                  getContent={getContent}
                  isIconDisabled={true}
                  className="action-gap"
                  place={place}
                  width={width}
                />
              )}
            </TextIconStyle>
          </TextHeadingStyle>
        </GridStyle>
      );
    }
  }
}

export default InputOrInfoIcon;
