import { SquareIcon } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import IPanelHeaderProps from "./iPanelHeder";

export const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  color: ${(props) => props.theme.colors.base.steel};
  display: flex;
`;

const TitleText = styled.span`
  margin-left: 16px;
  word-break: break-all;
`;
/**
 * @const NormalList is a Styled Component that consist of list of texts with roman font-family.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 */
export const PanelHeader = (props: IPanelHeaderProps) => {
  const { iconName, id, text } = props;
  return iconName || text ? (
    <Wrapper id={id}>
      <SquareIcon name={iconName} className="icon" />
      <TitleText>{text}</TitleText>
    </Wrapper>
  ) : null;
};
export default PanelHeader;
