import { IconName } from "am-web-ui-shared/components";
import LicenseTypes from "../../../../common/utils/enums/licenseTypesEnum";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import { storageGetItem } from "../../../../common/utils/storageUtils";
import Alerts from "../Alert/alerts";
import IHeaderProps from "../iHeaderProps";
import Logo from "../Logo/logo";
import TransferCart from "../TransferCart/transferCart";
import UserProfile from "../UserProfile/userProfile";
import { LicenseBundleTypes } from "TARGET_BUILD/common/utils/enums/licenseBundleEnum";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.theme.colors.base.borderColor};
  padding: 0 16px;
  height: 46px;
`;

const RightComp = styled.div`
  display: inline-flex;
  cursor: pointer;
  border-left: ${(props) => (props.hasBorder ? `2px solid ${props.theme.colors.base.borderColor}` : "none")};
`;

class Header extends React.Component<IHeaderProps> {
  getLogoText() {
    const { licenseContext, t } = this.props;
    const licenseType = storageGetItem(appConstants.LICENSE_TYPE);
    const licenseBundle = storageGetItem(appConstants.LICENSE_BUNDLE);
    const isEnterpriseTenant = licenseContext?.isEnterpriseTenant();

    let text = t("common:LOGO_TEXT");
    if (licenseType === LicenseTypes.FM || licenseBundle === LicenseBundleTypes.ONTRACK_READY) {
      text = t("common:LOGO_TEXT_FLEET");
    } else if (isEnterpriseTenant) {
      text = t("common:LOGO_ENTERPRISE_TEXT");
    }
    return text;
  }

  render() {
    const {
      activeAlertCount,
      confirmTransferCount,
      getAlertCount,
      getConfirmTransferCount,
      t,
      transferCartItems,
      toggleAlertList,
      showAlertListPopup,
      fieldRequestsCount,
      getFieldRequestsCount,
      isFieldRequestEnabled,
      licenseContext,
      getGatewayAssetsCount,
      getSuggestedTransfersCount,
      suggestedTransfersCount,
    } = this.props;
    // Getting the header flag values from license context.
    const headerDetails = licenseContext?.getHeaderDetails();
    const walkmeId = headerDetails?.hasWalkme ? "walk-me-binding" : "";
    const hasBorder = headerDetails?.hasBorder;

    return (
      <HeaderWrapper>
        <Logo logoText={this.getLogoText()} />
        {storageGetItem(appConstants.USER_TOKEN) ? (
          <RightComp id={walkmeId} hasBorder={hasBorder}>
            {headerDetails?.hasAlert && (
              <Alerts
                closeAllMoreFilter={this.props.closeMoreFilter}
                activeAlertCount={activeAlertCount}
                confirmTransferCount={confirmTransferCount}
                fieldRequestsCount={fieldRequestsCount}
                getAlertCount={getAlertCount}
                getConfirmTransferCount={getConfirmTransferCount}
                name={IconName.Bell}
                t={t}
                transferConfirmationPolicy={this.props.transferConfirmationPolicy}
                toggleAlertList={toggleAlertList}
                showAlertListPopup={showAlertListPopup}
                getFieldRequestsCount={getFieldRequestsCount}
                isFieldRequestEnabled={isFieldRequestEnabled}
                getGatewayAssetsCount={getGatewayAssetsCount}
                getSuggestedTransfersCount={getSuggestedTransfersCount}
                suggestedTransfersCount={suggestedTransfersCount}
              />
            )}
            {headerDetails?.hasTransferCart && <TransferCart translate={t} transferCartItems={transferCartItems} />}

            <UserProfile
              {...this.props}
              logout={this.props.logout}
              name="user"
              translate={t}
              languageCode={this.props.languageCode}
              closeMoreFilter={this.props.closeMoreFilter}
            />
          </RightComp>
        ) : null}
      </HeaderWrapper>
    );
  }
}

export default withTranslation()(Header);
