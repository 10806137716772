import Gateway from "../gateway/gateway";
import CompanyAdditionalSettings from "../models/company/CompanyAdditionalSettings";
import ITechnicalUsersResponseModel from "../models/company/ITechnicalUsersModel";
import ICompanyModel, { ILicenseAndUsageDetailCountModel } from "../models/company/iCompanyModel";
import ITechnicaluserModel from "../models/company/iTechnicaluserModel";
import { IErrorResponse } from "../models/iApiError";
import { ITechnicaluserPassword } from "../models/iApiResponse";
import { getAssetMgmtEndpoint, getOrgMgmtEndpoint, getUserMgmtEndpoint } from "../utils/apiUtils";

export default class CompanyApi {
  /**
   * @description Returns the tenant detail from API
   *
   * @static
   * @memberof editCompanyApi
   * @header editCompanyDetails : user added data in the form
   * @param item
   */
  static editCompanyDetails = async (item: ICompanyModel) => {
    const url = getOrgMgmtEndpoint(`/company`);
    return Gateway.put(url, null, item, null) as unknown;
  };

  /**
   * Returns object of Company by calling relevant API
   */
  static getCompanyDetail = async (): Promise<ICompanyModel | IErrorResponse> => {
    return Gateway.get(getOrgMgmtEndpoint(`/company`), null, null, null, false) as unknown as ICompanyModel;
  };

  /**
   * Returns object of Company License and Usage Detail by calling this API
   */
  static getLicenseAndUsageDetail = async (includeCount): Promise<ICompanyModel | IErrorResponse> => {
    return Gateway.get(
      getOrgMgmtEndpoint(`/license-usage-info?includeCount=${includeCount}`),
    ) as unknown as ICompanyModel;
  };

  /**
   * Returns object of Company License and Usage Detail by calling this API
   */
  static getLicenseAndUsageDetailsCount = async (): Promise<
    Array<ILicenseAndUsageDetailCountModel> | IErrorResponse
  > => {
    return Gateway.get(
      getAssetMgmtEndpoint(`/count/tenants/license-usage-info`),
    ) as unknown as ILicenseAndUsageDetailCountModel[];
  };

  /**
   * Returns object whith technicalUser data by calling this API
   */

  static getTechnicalUser = async (): Promise<ITechnicaluserModel | IErrorResponse> => {
    return Gateway.get(
      getUserMgmtEndpoint("/technical-user"),
      null,
      null,
      null,
      false,
    ) as unknown as ITechnicaluserModel;
  };

  static getTechnicalUsers = async (): Promise<ITechnicalUsersResponseModel | IErrorResponse> => {
    return Gateway.get(
      getUserMgmtEndpoint("/technical-users"),
      null,
      null,
      null,
      false,
    ) as unknown as ITechnicalUsersResponseModel;
  };

  static getTechnicalUserPassword = async (employeeId: string): Promise<ITechnicaluserPassword | IErrorResponse> => {
    return Gateway.post(getUserMgmtEndpoint("/technical-users/reset-password"), null, { employeeId }, null);
  };

  static getAdditionalSettings = async (): Promise<CompanyAdditionalSettings | IErrorResponse> => {
    return Gateway.get(getOrgMgmtEndpoint(`/company/additional-settings`));
  };

  static updateAdditionalSettings = async (
    updatedSettings: { name: string; value: string }[],
  ): Promise<void | IErrorResponse> => {
    return Gateway.put(getOrgMgmtEndpoint(`/company/additional-settings`), null, updatedSettings, null);
  };
  static enableTrial = async (
    listOfModules: { name: string; trial: boolean }[],
  ): Promise<any | void | IErrorResponse> => {
    return Gateway.post(getOrgMgmtEndpoint(`/company/modules/trial`), null, { listOfModules }, null);
  };

  static resetTechnicalUserPassword = async (): Promise<ITechnicaluserPassword | IErrorResponse> => {
    const url = getUserMgmtEndpoint("/technical-user/reset-password");
    return Gateway.post<ITechnicaluserPassword>(url, null, null, null);
  };
}
