import Gateway, { GetParams, PutParams } from "../gateway/gateway";
import { IAlertList } from "../models/alert/activeAlerts";
import { IAlertDetails } from "../models/alert/alertDetails";
import {
  IAlertListQuery,
  IAlertsActiveCount,
  IGetAlertsParams,
  IAlertGroups,
  ISubscriptions,
} from "../models/iAlertSettingsModels";
import { IUserSettings } from "../modules/alert/components/list/iSummaryProps";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import { AlertView } from "../modules/alert/components/list/container/alertGridFiltersConfig";

const getEndpoint = (path: string, params: IGetAlertsParams = null): string =>
  getVersionedApiUrl(ServiceUrl.ALERT_NOTIFICATION_MGMT, path, params);

export const getActiveAlertCount = async () => {
  const params: GetParams = [getEndpoint("/alerts/active/count"), null, null, null, false];
  return Gateway.get<IAlertsActiveCount>(...params);
};

export const getAlertDetailsApi = async (alertId: number, alertView: AlertView) => {
  const params: GetParams = [getEndpoint(`/alerts/${alertView}/${alertId}`)];
  return Gateway.get<IAlertDetails>(...params);
};

export const getAlertGroupApi = async () => {
  const params: GetParams = [getEndpoint("/tenant/alerts/groups")];
  return Gateway.get<IAlertGroups>(...params);
};

export const getAlertSettingListApi = async (groupId: number) => {
  const params: GetParams = [getEndpoint(`/tenant/alerts/settings/${groupId}`)];
  return Gateway.get<ISubscriptions>(...params);
};

export const setAlertsSubscriptionSettingsApi = async (action) => {
  const { groupId, payload } = action;

  const params: PutParams = [getEndpoint(`/tenant/alerts/settings/${groupId}`), {}, payload, null];

  return Gateway.put<ISubscriptions>(...params);
};

export const getAlertFilterAttributesApi = async (alertView: AlertView, filters: string) => {
  const url = `${getEndpoint(`/alerts/${alertView}/attributes`)}${filters ? `?${filters}` : ""}`;
  return Gateway.get(url);
};

export const getAlertUserSettingListApi = async () => {
  const params: GetParams = [getEndpoint("/user/alerts/settings")];
  return Gateway.get<IUserSettings>(...params);
};

export const updateAlertUserSettingsApi = async (action) => {
  const params: PutParams = [getEndpoint("/user/alerts/settings"), {}, action, null];
  return Gateway.put<IUserSettings>(...params);
};

export const getAlertListApi = async (queryObj: IAlertListQuery, loader: boolean = true, currentView: AlertView) => {
  const { filter, ...pagination } = queryObj;

  const url = `${getEndpoint(`/alerts/${currentView}`)}${filter ? `?${filter}` : ""}`;

  return Gateway.get<IAlertList>(url, { ...pagination }, null, null, loader, false);
};

export const setDismissAlertsApi = async (queryObj, action) => {
  const data = {
    alertIds: action.selectedAll ? [] : action.alertIds,
    excludeIds: action.selectedAll ? action.excludeIds : [],
    filters: queryObj.filter,
    q: queryObj.q,
    selectAll: action.selectedAll,
  };

  const params: PutParams = [getEndpoint("/alerts/action/dismiss"), null, data, null];

  return Gateway.put<IAlertDetails>(...params);
};
