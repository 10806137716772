/**
 * @description Action constants to be used for Check Inventory
 *
 * format: {feature}_{action}
 */

const CheckInventoryActionConstants = {
  GET_CHECK_INVENTORY_SETTINGS: "GET_CHECK_INVENTORY_SETTINGS", // action to get check inventory settings
  GET_CHECK_INVENTORY_SETTINGS_SUCCESS: "GET_CHECK_INVENTORY_SETTINGS_SUCCESS",
  UPDATE_CHECK_INVENTORY_SETTINGS: "UPDATE_CHECK_INVENTORY_SETTINGS", // action to reset check inventory success and error response
  UPDATE_CHECK_INVENTORY_SUCCESS: "UPDATE_CHECK_INVENTORY_SUCCESS",
};

export default CheckInventoryActionConstants;
