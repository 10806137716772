// @ts-ignore
import { UserProfileActions } from "custom-actions";
import { all, put, takeLatest, call as sagaCall } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../../actions/toastrAction";
import {
  acceptTncResponse,
  resetPassword,
  userMeDetail,
  userProfileDetail,
  patchUserAcceptanceFlag,
} from "../../../api/userProfileApi";
import { call } from "../../../sagas/common/commonSaga";
import { HelperLodash } from "am-web-ui-shared/helpers";

export function* userProfileSaga() {
  yield takeLatest(UserProfileActions.USER_PROFILE, userProfile);
}

export function* userProfile() {
  const userProfileData = yield call(userProfileDetail);
  yield put({ type: UserProfileActions.USER_PROFILE_RESPONSE, payload: userProfileData });
}

/**
 * @description the saga function to get User Me details
 *
 * @export
 */
export function* userMeSaga() {
  yield takeLatest(UserProfileActions.USER_ME_DATA, userMe);
}

/**
 * @description the function to get User Me details from API
 *
 * @export
 */
export function* userMe() {
  const userMeData = yield call(userMeDetail);
  yield put({ type: UserProfileActions.USER_ME_RESPONSE, payload: userMeData });
}

export function* resetPasswordSaga() {
  yield takeLatest(UserProfileActions.RESET_PASSWORD, passwordReset);
}

function* passwordReset(action) {
  const response = yield call(() => {
    return resetPassword(action.data);
  });
  if (response && response.error) {
    yield put(
      showToastr(
        ToastrType.error,
        `errorCodes:${
          response.error.response && response.error.response.data.errors && response.error.response.data.errors[0].code
        }`,
      ),
    );
    yield put({ type: UserProfileActions.RESET_PASSWORD_FAILURE, response });
  } else {
    yield put({ type: UserProfileActions.RESET_PASSWORD_SUCCESS, response });
  }
}

export function* updateUserAcceptanceFlagSaga() {
  yield takeLatest(UserProfileActions.UPDATE_USER_ACCEPTANCE_FLAG, updateUserAcceptanceFlag);
}

function* updateUserAcceptanceFlag(action) {
  const extWarningFlag = HelperLodash.get(action, "payload.extWarningFlag");
  yield sagaCall(() => patchUserAcceptanceFlag(extWarningFlag));
}

export function* acceptTnCResponseSaga() {
  yield takeLatest(UserProfileActions.TNC_ACCEPTED, acceptTnCResponses);
}
function* acceptTnCResponses(action) {
  const response = yield call(() => {
    return acceptTncResponse(action.data);
  });
  if (!(response && response.error)) {
    yield put({ type: UserProfileActions.TNC_ACCEPTED_SUCCESS, response });
    window.location.assign("/");
  }
}

function* userSaga() {
  yield all([
    acceptTnCResponseSaga(),
    resetPasswordSaga(),
    userMeSaga(),
    userProfileSaga(),
    updateUserAcceptanceFlagSaga(),
  ]);
}

export default userSaga;
