const ManufactureConstants = {
  ADD_MANUFACTURER_ERROR: "ADD_MANUFACTURER_ERROR",
  ADD_MANUFACTURE_SUCCESS: "ADD_MANUFACTURE_SUCCESS",
  AM_ADD_EDIT_MANUFACTURER: "AM_ADD_EDIT_MANUFACTURER", // use to call api (add/edit)
  AM_GET_ASSETS_BY_MANUFACTURER_ID_REQUEST: "AM_GET_ASSETS_BY_MANUFACTURER_ID_REQUEST",
  AM_GET_ASSETS_BY_MANUFACTURER_ID_RESPONSE: "AM_GET_ASSETS_BY_MANUFACTURER_ID_RESPONSE",
  AM_GET_TEMPLATES_BY_MANUFACTURER_ID_REQUEST: "AM_GET_TEMPLATES_BY_MANUFACTURER_ID_REQUEST",
  AM_GET_TEMPLATES_BY_MANUFACTURER_ID_RESPONSE: "AM_GET_TEMPLATES_BY_MANUFACTURER_ID_RESPONSE",
  AM_MERGE_MANUFACTURERS_REQUEST: "AM_MERGE_MANUFACTURERS_REQUEST",
  AM_MERGE_MANUFACTURERS_RESPONSE: "AM_MERGE_MANUFACTURERS_RESPONSE",
  CLEAR_MANUFACTURER_BY_ID_DETAILS: "CLEAR_MANUFACTURER_BY_ID_DETAILS",
  CLEAR_MANUFACTUERER_IMPORT_HISTORY: "CLEAR_MANUFACTUERER_IMPORT_HISTORY",
  CREATE_MANUFACTURE: "CREATE_MANUFACTURE",
  DELETE_MANUFACTURER: "DELETE_MANUFACTURER",
  DELETE_MANUFACTURER_FAIL: "DELETE_MANUFACTURER_FAIL",
  DELETE_MANUFACTURER_SUCCESS: "DELETE_MANUFACTURER_SUCCESS",
  GET_IMPORT_HISTORY_ERRORS: "GET_MANUFACTUERER_IMPORT_HISTORY_ERRORS",
  GET_IMPORT_HISTORY_ERRORS_RESPONSE: "GET_MANUFACTUERER_IMPORT_HISTORY_ERRORS_RESPONSE",
  GET_MANUFACTUERER_IMPORT_HISTORY: "GET_MANUFACTUERER_IMPORT_HISTORY",
  GET_MANUFACTURERS_INFO: "GET_MANUFACTURERS_INFO",
  GET_MANUFACTURERS_LIST: "GET_MANUFACTURERS_LIST",
  GET_MANUFACTURER_DETAILS: "GET_MANUFACTURER_DETAILS",
  GET_MANUFACTURER_INFO_DETAILS: "GET_MANUFACTURER_INFO_DETAILS",
  IMPORT_HISTORY_RESPONSE: "IMPORT_HISTORY_RESPONSE",
  MANUFACTURERS_CLEAR_ALL_ROWS_SELECTION: "MANUFACTURERS_CLEAR_ALL_ROWS_SELECTION",
  MANUFACTURERS_SELECTALL_ROWS: "MANUFACTURERS_SELECTALL_ROWS",
  MANUFACTURERS_SELECT_ROW: "MANUFACTURERS_SELECT_ROW",
  MANUFACTURERS_SET_SELECTED_ROW: "MANUFACTURERS_SET_SELECTED_ROW",
  MANUFACTURERS_UNSELECT_ROW: "MANUFACTURERS_UNSELECT_ROW",
  MANUFACTURER_ADD_EDIT_RESPONSE: "MANUFACTURER_ADD_EDIT_RESPONSE", // use to capture api response (add/edit)
  MANUFACTURER_SELECT_GRID_CHECKBOX: "MANUFACTURER_SELECT_GRID_CHECKBOX",
  MANUFACTURER_UNSELECT_GRID_CHECKBOX: "MANUFACTURER_UNSELECT_GRID_CHECKBOX",
  PUT_MANUFACTURERS: "PUT_MANUFACTURERS",
  REFRESH_ACTIONS: "REFRESH_ACTIONS",
  RESET_MANUFACTURE_DELETE_RESPONSE: "RESET_MANUFACTURE_DELETE_RESPONSE",
  RESET_MANUFACTURE_TO_INITIAL_STATE: "RESET_MANUFACTURE_TO_INITIAL_STATE",
  SET_MANUFACTURER_DEPENDENCY: "SET_MANUFACTURER_DEPENDENCY",
  SET_MANUFACTURER_DEPENDENCY_SUCCESS: "SET_MANUFACTURER_DEPENDENCY_SUCCESS",
  SET_MANUFACTURE_DELETE_RESPONSE: "SET_MANUFACTURE_DELETE_RESPONSE",
  SET_SELECTED_MANUFACTURERS_INFO: "SET_SELECTED_MANUFACTURERS_INFO",
};

export default ManufactureConstants;
