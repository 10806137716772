/**
 * @description This file is for all the api hitting using
 * axios for the hilti asset status.
 */

import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import IHiltiAssetStatusSettingsResponse from "../modules/hiltiAssetStatusSettings/iHiltiAssetStatusSettingsResponse";
import { getCompanyAssetStatusEndpoint } from "../utils/apiUtils";

/**
 * Returns hilti asset status response
 */
export const getHiltiAssetStatus = async (): Promise<IHiltiAssetStatusSettingsResponse | IErrorResponse> => {
  const url = getCompanyAssetStatusEndpoint(`/company/asset-status-setting`);
  return Gateway.get(url, null, null) as unknown as IHiltiAssetStatusSettingsResponse;
};

/**
 * Update hilti asset status and return response
 *
 */
export const updateHiltiAssetStatus = async (updatedData): Promise<string | IErrorResponse> => {
  const udpdated = { isEnabled: updatedData };
  const url = getCompanyAssetStatusEndpoint(`/company/asset-status-setting`);
  return Gateway.put(url, null, udpdated, null) as unknown as string;
};
