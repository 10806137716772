import { THistory } from "TARGET_BUILD/types";

/**
 * @description Function to redirect user
 * @param {String} - url - take redirect url as string
 * @param {Array} - history - take history object of browser
 *
 */
export const redirect = (url: string, history: THistory) => {
  history.push(url);
  return true;
};
