import { AuthorizationCore, DomHelper, HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
// @ts-ignore
import { appConstants, FileErrors, LOCATION_DEFAULT_SORT, SortFields, WORKER_DEFAULT_SORT } from "TARGET_BUILD/config";
import StyleAttrConstants from "../styleAttrConstants";
import ModuleName from "./enums/moduleNameEnum";
import IReplaceFilterQuery from "./iReplaceFilterQuery";
import Delimiter from "./enums/delimiterEnum";
import ApplicationConstants from "../applicationConstants";
import { TenantCategories } from "./enums/assetEnum";
import { ValidationConstants } from "./enums/validationConstants";
import { AssetServiceCategory } from "./enums/serviceEnum";
import ISortQuery from "../models/sort/iSortQuery";
/**
 * @description - Concatinate the firstName and lastName of the user.
 *
 * @param firstName - First name of the user.
 * @param lastName - Last name of the user.
 */
export const getFullName = (firstName: string, lastName: string) => {
  return `${firstName || ""} ${lastName || ""}`.trim();
};

export const getWorkerLabel = (data, keys, optionalKey?) => {
  return (
    keys &&
    keys.map((key) =>
      optionalKey && data[optionalKey] === data[key] && data[key] ? `(${data[key]})` : data[key] && `${data[key]} `,
    )
  );
};

/**
 * @description - getPromisForAutoSearch is used when AutoSearch is used to populate the searched item
 *
 * @param searchApi - api to get the Searched result
 * @param fields - params for the search api q , fields ,limit , order_by, offset.
 * @param mapResponse - key mapped to label and value
 * @param loader - default pass false in loader to the Promise list in auto Search
 * @param callback
 */
export const getPromisForAutoSearch = (searchApi, fields, mapResponse, loader, callback) => {
  searchApi(fields, loader).then((result: any) => {
    if (result.response && result.response.length > 0) {
      const options = result.response.map((item) => {
        return {
          label: getWorkerLabel(item, mapResponse.label, mapResponse.optionalKey),
          value: item[mapResponse.value[0]],
          ...item,
        };
      });
      callback(options);
    } else {
      callback(null);
    }
  });
};

/**
 * @description Function add a value or modify existing if condition match
 * @param arr
 * @param condition
 * @param newval
 */
export const updateArray = (arr, condition, newval) => {
  const match = HelperLodash.find(arr, condition);
  if (match) {
    const index = HelperLodash.indexOf(arr, match);
    arr.splice(index, 1, newval);
  } else {
    arr.unshift(newval);
  }
  return arr;
};

/**
 * @description - This method is used to compare two json objects.
 * @param oldObject - Initial object.
 * @param newObject - New Object.
 */
export function isEqual(oldObject, newObject) {
  const oldValue = JSON.stringify(oldObject, (k, v) => (k && v === undefined ? null : v));
  const newValue = JSON.stringify(newObject, (k, v) => (k && v === undefined ? null : v));
  return HelperLodash.isEqual(oldValue, newValue);
}
/**
 * use to set scroll position where user receive error
 */
export function setScrollOnField() {
  setTimeout(() => {
    const elements = document.getElementsByClassName("errorLabel");
    if (elements.length) {
      elements[0].scrollIntoView();
    }
  }, ApplicationConstants.TIMEOUT.TOUT100);
}

export const handleViewMore = (nextShowMore, initialRecordCount, currObject, uploadHistoryId) => {
  if (currObject.props.importHistoryErrorsList) {
    const length = currObject.props.importHistoryErrorsList[uploadHistoryId].length;
    const index = initialRecordCount + nextShowMore;
    const currentNextShowMore =
      length - index > FileErrors.DEFAULT_SIZE ? FileErrors.DEFAULT_SIZE : length - (index + 1);
    currObject.setState({
      initialRecordCount: index,
      nextShowMore: currentNextShowMore,
    });
  }
};

/**
 * @description Function returns true or false according to the x
 * @param x
 */
export const isEmptyStringOrValue = (x: string) => {
  return !!(x || x === "");
};

/**
 * @description Function to resolve the an specific value of from an object.
 * @param {object} fieldObject - Object that contains the values.
 * @param {string} fieldName - Field name that needs to be resolved from the object.
 * @param {string} resolveProps - Property that needs to be resolved for the case of { code, value } combination.
 */
export const fieldResolver = (fieldObject, fieldName, resolveProps) => {
  return fieldObject instanceof Object ? fieldObject[resolveProps] : fieldName;
};

/**
 * @description - Function to concatinate the state and postal code of the address details.
 *
 * @param {string} state - State from the address details.
 * @param {string} postalCode - Postal code from the address details.
 */
export const concatStatePostalCode = (state, postalCode) => {
  let concatedString = "";

  // If the state name is avilable, then assign it to the concated string.
  if (state && state.value) {
    concatedString = state.value;
  }

  // If both state and postal code is avilable then put comma "," between then else return only postal code.
  if (postalCode) {
    concatedString = concatedString ? `${concatedString}, ${postalCode}` : postalCode;
  }

  return concatedString;
};

/**
 * @description Default Manufacturer Names.
 */
export const defaultManufacturerFields = ["hilti"];

/**
 * @description To deep cloning of array with objects
 * @param {array} array with nested object.
 */

export const deepCloneArray = (array) => {
  return HelperLodash.cloneDeep(array);
};

/**
 * @description To filter array with unique value
 * @param {array} array with nested object.
 */

export const uniqueArray = (array) => {
  return HelperLodash.uniqWith(array, HelperLodash.isEqual);
};

export const clone = (obj) => {
  return HelperLodash.clone(obj);
};
/**
 * @description - Function to concatenate the two fields with space as separator and hash(#) with second field
 *
 * @param val1 - val1
 * @param val2 - val2
 */
export const concatFieldsWithHash = (val1, val2) => {
  const value1 = val1 ? `${val1} ` : "";
  const value2 = val2 ? `#${val2}` : "";
  return `${value1}${value2}`;
};

/**
 * @description - Function that returns the top and left position of particular ref
 * @param1 - event of ref
 * @param2 - value to be changed in intial ref's LeftOffset
 * @param3 -  value to be changed in intial ref's TopOffset
 */
export const getOffset = (e, leftValue = 0, topValue = 0) => {
  const leftPosition = e && e.offsetLeft ? e.offsetLeft + leftValue : 0;
  const topPosition = e && e.offsetTop ? e.offsetTop + topValue : 0;
  return { leftPosition, topPosition };
};

/**
 * @description - Function to get object property value from list of objects.
 * @param list (Array) - List of objects.
 * @param value (string | number) - Value of object key to match.
 * @param key1 (string) - Object property to match with passed value. Default property is code.
 * @param key2 (string) - Object property to return value. Default property is value.
 */
export const getListObjectValue = (list, value: string | number, key1 = "code", key2 = "value") => {
  const obj = list && list.find((column: object) => column[key1] === value);
  return obj && obj[key2];
};

/**
 * @description - Function to merge attributes to common attributes.
 * @param attributes - common asset field attributes.
 */
export const mergeAssetUsageStateAndDate = (attributes = [], t) => {
  /*
    Get the assetUsage and assetConditionState from the attributes.
  */
  function getUsageArr(attr) {
    return attr.code === "usageStateCode" || attr.code === "assetUsageConditionDate";
  }
  function removeElemFromAttributes(index: number) {
    if (index !== -1) {
      attributes.splice(index, 1);
    }
  }
  const usageArr = attributes.filter(getUsageArr);
  if (usageArr.length) {
    const usageStateCodeIndex = attributes.findIndex((attr) => attr.code === "usageStateCode");
    removeElemFromAttributes(usageStateCodeIndex);

    const assetUsageConditionDateIndex = attributes.findIndex((attr) => attr.code === "assetUsageConditionDate");
    const aucTranslatedValue = t("assets:ASSET_USAGE_CONDITION_AUC");
    removeElemFromAttributes(assetUsageConditionDateIndex);
    attributes.push({
      children: usageArr,
      code: "assetUage",
      value: aucTranslatedValue,
    });
  }

  /*
    Get the purchase price and purchsecurrency from the attributes.
  */
  function getPurchaseArr(attr) {
    return attr.code === "purchaseCurrency" || attr.code === "purchasePrice";
  }
  const purchaseArr = attributes.filter(getPurchaseArr);
  if (purchaseArr.length) {
    const purchaseCurrencyIndex = attributes.findIndex((attr) => attr.code === "purchaseCurrency");
    removeElemFromAttributes(purchaseCurrencyIndex);

    const purchasePriceIndex = attributes.findIndex((attr) => attr.code === "purchasePrice");
    const purchaseTranslatedValue = t("filters:PURCHASE_PRICE_CURRENCY");
    removeElemFromAttributes(purchasePriceIndex);
    attributes.push({
      children: purchaseArr,
      code: "purchase",
      value: purchaseTranslatedValue,
    });
  }

  /*
    Get the fleet currency and monthly rate from the attributes.
  */
  function getFleetArr(attr) {
    return attr.code === "fleetCurrencyCode" || attr.code === "monthlyFleetRate";
  }
  const fleetArr = attributes.filter(getFleetArr);
  if (fleetArr.length) {
    const fleetCurrencyIndex = attributes.findIndex((attr) => attr.code === "fleetCurrencyCode");
    attributes.splice(fleetCurrencyIndex, 1);

    const monthlyFleetRateIndex = attributes.findIndex((attr) => attr.code === "monthlyFleetRate");
    attributes.splice(monthlyFleetRateIndex, 1, {
      children: fleetArr,
      code: "fleetCurrencyRate",
      value: <Trans>{"filters:MONTHLY_FLEET_CURRENCY"}</Trans>,
    });
  }

  return attributes;
};

/**
 * @description - Function to get array of object sorted .
 * @param list  - List of array of object objects.
 * @param comparator - object key on which the list will be sorted.
 * @param direction - asc or desc order of sorting
 *
 */

export const sort = (list, comparator: string, direction?: "asc") => {
  return list && Array.isArray(list) ? HelperLodash.orderBy(list, comparator, direction) : [];
};

/* @description - Function to remove browserScroll when more filter is closed
 *
 */
export const removeBrowserScroll = () => {
  window.scrollTo(0, 0);
};

export const removeCustomView = () => {
  DomHelper.removeClassOnBody("custom-view");
};

/**
 * use to format filter query
 * @param object contains key operator and value
 */
export function formatFiltersObject(object: any) {
  const filterArray = [];
  Object.entries(object).forEach(([key]) => {
    const filterValue = object[key];
    const splittedKey = key.split(":");
    if (filterValue && filterValue.split(";")[0] !== "") {
      const splitedValues = filterValue.split(";");
      const filterObject = {
        filterName: splittedKey[0],
        condition: splittedKey[1],
        filterValue: splitedValues,
      };
      filterArray.push(filterObject);
    }
  });
  return filterArray;
}

export function modifiedFilterObject(filters, filterSettings) {
  const finalFilterObj = { include: [], exclude: [] };
  filters.forEach((filterVal) => {
    const currentFilterSettingObj = filterSettings.filter((filter) => {
      return filter.filterName === filterVal.filterName;
    });
    if (
      currentFilterSettingObj[0] &&
      currentFilterSettingObj[0].exclude &&
      currentFilterSettingObj[0].exclude.filter === true
    ) {
      finalFilterObj.exclude.push(filterVal);
    } else {
      finalFilterObj.include.push(filterVal);
    }
  });
  return finalFilterObj;
}

export const getFilterArrary = (filter) => {
  return (filter || []).map((row) => {
    const filterValue = Array.isArray(row.filterValue) ? row.filterValue.join(";") : row.filterValue;
    return `${row.filterName}:${row.condition}=${encodeURIComponent(filterValue)}`;
  });
};
export const getFilterArraryWithoutEncode = (filter, replaceFilterQuery = []) => {
  return (filter || []).map((row) => {
    const filterValue = Array.isArray(row.filterValue) ? row.filterValue.join(";") : row.filterValue;
    let filterString = `${row.filterName}${encodeURIComponent(":")}${row.condition}=${encodeURIComponent(filterValue)}`;
    if (replaceFilterQuery) {
      replaceFilterQuery.forEach((rq) => {
        filterString = filterString.replace(rq.source, rq.with);
      });
    }
    return decodeURIComponent(filterString);
  });
};

export const getFormattedFilter = (filter) => {
  const filters = [];
  let view = null;
  (filter || []).forEach((row) => {
    if (row.includes("filter=")) {
      const filterVal = row.replace("filter=", "");
      filters.push(decodeURIComponent(filterVal));
    }
    if (row.includes("view=")) {
      view = "child";
    }
  });
  return { filters, view };
};

/**
 * format filter array into query to get details
 * @param filter contains key operator and value
 * @param currentFilter
 * @param replaceQuery
 * @param payload
 * @param enableMax
 */
const FILTER_MAX_AMOUNT_TO_LOAD = 1000; // or 3000 ?

const getMax = (filter) =>
  (Array.isArray(filter) &&
    filter.reduce((result, filterObj) => {
      const { filterValue } = filterObj;
      return result < (filterValue && filterValue.length) ? filterValue.length : result;
    }, ApplicationConstants.NUMBER.NUM5)) ||
  ApplicationConstants.NUMBER.NUM5;

export function filtersQuery(
  filter,
  currentFilter = null,
  replaceQuery: IReplaceFilterQuery[] | undefined = null,
  payload: any = {},
  enableMax = false,
) {
  let filterString = "";
  let customAttributeFilterString = "";
  const max = getMax(filter);
  if (payload.customAttribute) {
    const { customAttribute, query = "" } = payload;
    const encodedQuery = encodeURIComponent(query);
    customAttributeFilterString = `customAttribute=${customAttribute}&limit=${FILTER_MAX_AMOUNT_TO_LOAD}&offset=0&q=${encodedQuery}`;
  } else if (enableMax) {
    filterString = `max=${max}`;
  }
  if (filter && filter.length > 0) {
    const currentSelectedFilter = currentFilter ? currentFilter : filter[filter.length - 1].filterName;
    filterString = filterString ? `${filterString}&` : filterString;
    filterString += `${filterRequestQuery(filter)}&currentSelectedFilter=${currentSelectedFilter}`;
  }
  if (replaceQuery) {
    replaceQuery.forEach((rq) => {
      filterString = filterString.replace(rq.source, rq.with);
    });
  }
  return filterString ? `${filterString}&${customAttributeFilterString}` : customAttributeFilterString;
}
/**
 * format filter array to query string
 * @param filter
 */
export const filterRequestQuery = (filter: any) => {
  return filter
    .map((row) => {
      const filterValue = Array.isArray(row.filterValue) ? row.filterValue.join(";") : row.filterValue;
      const rowView = `&view=${row.view}`;
      return `filter=${row.filterName}${encodeURIComponent(":")}${row.condition}=${encodeURIComponent(filterValue)}${
        row.view ? rowView : ""
      }`;
    })
    .join("&");
};
/* @description - Function to fix element
 *
 */
export const fixedElement = (elem, className) => {
  const pageHeight = window.innerHeight;
  const targetElem = document.querySelector(elem);
  const sticky = targetElem && targetElem.offsetTop;
  if (window.pageYOffset !== 0 && window.pageYOffset >= sticky && targetElem) {
    DomHelper.addClassOnBody(className);
    targetElem.style.minHeight = pageHeight + StyleAttrConstants.HEIGHT.H72 + "px";
  } else if (targetElem) {
    DomHelper.removeClassOnBody(className);
    targetElem.style.minHeight = 0 + "px";
  }
};

export type FormatCountMessages = {
  noValueText: string;
  singulerText: string;
  pluralText: string;
};

/**
 * method to format count.
 * @param value Count of the provided value.
 * @param messages
 */
export const formatCount = (value: string, messages: FormatCountMessages, isHiltiService?: boolean): string => {
  const count = parseInt(value, 10);
  const displayCount = LocaleManager.numberHelpers.formatNumber(count);

  switch (true) {
    case isHiltiService:
      return "";
    case count < 1:
    case isNaN(count):
      return `${messages.noValueText}`;
    case count > 1:
      return `${displayCount} ${messages.pluralText}`;
    default:
      return `${displayCount} ${messages.singulerText}`;
  }
};

/**
 * method to get soritng, filtering and search if applicable
 * @param data
 * @param isExport
 */
export const getQueryObj = (data, isExport = false) => {
  if (data) {
    const queryObj: any = {};
    const sortFields = data.sortQuery;
    if (sortFields && !isExport) {
      queryObj.order_by = sortFields.sortType + sortFields.sortField;
    }

    const { appliedFilterQuery, filterQuery } = data;
    const filterQueryArray = appliedFilterQuery ? appliedFilterQuery : filterQuery;

    if (filterQueryArray) {
      queryObj.filter = filtersQuery(filterQueryArray);
    }

    if (data.searchString) {
      queryObj.q = data.searchString;
    }
    return queryObj;
  } else {
    return null;
  }
};

export const querySelector = (selector) => {
  return document.querySelector(selector);
};

const enhanceWithPreview = (files) =>
  files.map((file) =>
    Object.assign({}, file, {
      preview: URL.createObjectURL(file),
    }),
  );

export const withPreviews = (dropHandler) => (accepted, rejected) =>
  dropHandler(enhanceWithPreview(accepted), rejected);

export const clearPreviews = (files) => files.forEach((file) => URL.revokeObjectURL(file.preview));

export const removeExtraSpace = (value) => {
  return value && value.replace(/  +/g, " ");
};

/**
 * Function to return formatted contact number.
 * @param contactDetails
 */
export const getDisplayContact = (contactDetails: { countryCode: string; phoneNumber: string }) => {
  const countryCode = getCountryCode(contactDetails);
  const phoneNumber = getPhoneNumber(contactDetails);
  return countryCode ? `+${countryCode} ${phoneNumber}` : `${phoneNumber}`;
};

export const getCountryCode = (contactDetails: { countryCode: string; phoneNumber: string }) => {
  return contactDetails && contactDetails.countryCode ? contactDetails.countryCode : "";
};

export const getPhoneNumber = (contactDetails: { countryCode: string; phoneNumber: string }) => {
  return contactDetails && contactDetails.phoneNumber ? contactDetails.phoneNumber : "";
};

/**
 * Function to return formatted contact number.
 * @param contactNumber
 */
export const getWorkerDisplayContact = (contactNumber: { countryCode: string; phoneNumber: string }) => {
  let workerContactNumber = "";
  if (contactNumber) {
    workerContactNumber = contactNumber.countryCode
      ? `+${contactNumber.countryCode} ${contactNumber.phoneNumber}`
      : `${contactNumber.phoneNumber}`;
  }
  return workerContactNumber;
};

export const getPropertyLabel = (property: string, t: any) => {
  return property && t(`fieldLabels:${property.toUpperCase()}`);
};

export const getPropertyLabelValue = (property, customFields, t) => {
  let propertyLabelValue = "";
  customFields.forEach((field) => {
    if (field.code === property) {
      propertyLabelValue = field.value;
    }
  });
  if (propertyLabelValue) {
    return propertyLabelValue;
  } else {
    return getPropertyLabel(property, t);
  }
};

/**
 * @description - Function to create code value object.
 * @param {string} value1 string value to be used as a code.
 * @param {string} value2 string value to be used as a value.
 */
export const createCodeValueObject = (value1: string, value2: string) => {
  return {
    code: value1,
    value: value2,
  };
};

/**
 * @description - Function to construct filter query.
 * @param filter
 */
export const constructFilterQueryForExport = (filter) =>
  filter.endsWith("&") ? filter.substring(0, filter.length - 1) : filter;

export const popElemntFromArray = (arr, key) => {
  if (arr.length <= 0) {
    return arr;
  }
  const position = arr.indexOf(key);
  if (position >= 0) {
    arr.splice(position, 1);
  }
  return arr;
};

export const pushElementInArray = (arr, key) => {
  if (!arr.includes(key)) {
    arr.push(key);
  }
  return arr;
};
/**
 * @description - Function to format Service Frequency.
 * @param value - Service frequency value.
 * @param t
 */
export const formatFrequency = (value: string, t: any) =>
  value && value !== "0" ? value : t("common:ONE_TIME_SERVICE");

/**
 * @description - Function to format Notification Period.
 * @param value - Notification period value.
 * @param t
 */
export const formatNotificationPeriod = (value: string, t) => {
  return `${value} ${t("common:IN_ADVANCE")}`;
};

/**
 * @description - function to divide given array in n near equal parts
 * @param arr
 * @param n
 */
export const splitUp = (arr, n) => {
  if (!arr) {
    return null;
  }
  let restUsed = arr.length % n;
  const partLength = Math.floor(arr.length / n);
  const result = [];
  let add;
  for (let i = 0; i < arr.length; i += partLength + (add && 1)) {
    let end = partLength + i;
    add = false;
    if (restUsed > 0) {
      end++;
      restUsed--;
      add = true;
    }
    result.push(arr.slice(i, end));
  }
  return result;
};

/**
 * @description - Function to format Attachment Text.
 * @param value - document value.
 * @param t - object.
 */
export const formatDocument = (value, t) => {
  const presenceOfAttachment = value ? "HAS_ATTACHMENT" : "NO_ATTACHMENT";

  return t(`common:${presenceOfAttachment}`);
};

export const getUsername = (email) => {
  return email && email.split("@")[0];
};
export const specicalCharValidator = (value) => {
  return value.match(/[+!@#$%^&*(),.?":{}|<>]/g);
};
// https://github.com/JedWatson/react-select/issues/3037

export enum AutoCompleteActionEnum {
  "SELECT" = "select-option",
  "CREATE" = "create-option",
  "REMOVE" = "remove-value",
  "BACKSPACE" = "pop-value",
}

export const getMultiSelectOptionInfo = (selectionOption, metaInfo) => {
  const isOptionRemoved =
    metaInfo.action === AutoCompleteActionEnum.REMOVE || metaInfo.action === AutoCompleteActionEnum.BACKSPACE;
  const optionValues =
    metaInfo && metaInfo.removedValue && isOptionRemoved ? [metaInfo.removedValue.label] : selectionOption;
  return { isOptionRemoved, optionValues };
};

export const labelNoOptionMessage = (obj) => {
  const {
    input,
    max,
    maxLabelsValidationMsg,
    maxLengthValidationMsg,
    selectedOption,
    delimiter = Delimiter.COMMA,
  } = obj;
  let inputConditon = false;
  const trimValue = input.inputValue.trim();
  if (input && trimValue.includes(delimiter)) {
    inputConditon = trimValue.split(delimiter).filter((item) => item.length > max).length > 0;
  } else if (trimValue.length > max) {
    inputConditon = trimValue.length > max;
  }

  if (input && selectedOption && selectedOption.length >= appConstants.MAX_LABEL_COUNT_ALLOWED) {
    return maxLabelsValidationMsg;
  } else if (inputConditon) {
    return maxLengthValidationMsg;
  }
  return null;
};

export const handleLabelExclusionFilter = (data) => {
  const requiredArray = [];
  HelperLodash.uniq(data).forEach((item) => {
    if (item) {
      requiredArray.push(`filter=labelName${encodeURIComponent(":")}ne=${item}`);
    }
  });
  return requiredArray.join("&");
};

/**
 * @description - Function to find array values not exist in another array.
 * @param firstArray
 * @param secondArray
 */
export const getArrayValuesNotInAnotherArray = (firstArray, secondArray) => {
  return firstArray.filter((ele) => !secondArray.some((yEle) => yEle.toLowerCase() === ele.toLowerCase()));
};
/*
 * Function to get the validation Message
 */
export const getValidationMessage = (input, maxLength, errorMessage) => {
  return input.trim().length > maxLength ? errorMessage : null;
};

/*
 * Function to get the value or -
 */
export const getValueOrHyphen = (data, key) => {
  return HelperLodash.get(data, key) ? HelperLodash.get(data, key) : "-";
};

export const isQtyItemModuleEnable = () => {
  return AuthorizationCore.hasActiveModule(ModuleName.QTY_ITEMS_CODE);
};
/**
 * @description - Function to create the reducer function
 * @param initialState-- state passed to the reducer function
 * @param handlers-- function to execute on specific action type
 */
export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) => {
    return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
  };

export const setFilterAttributesToReducer = (state, action) => {
  const { response, filterPayload } = action.payload;
  const filterAttributes = { ...response };
  const filterAttributesCount = deepCloneArray(filterAttributes.totalCount);
  delete filterAttributes.totalCount;

  if (filterPayload && filterPayload.customAttribute) {
    const { customAttribute } = filterPayload;
    return {
      filterAttributes: {
        ...state.filterAttributes,
        [customAttribute]: filterAttributes[customAttribute],
      },
      filterAttributesCount: state.filterAttributesCount,
      filterAttributesSearchCount: filterAttributesCount,
    };
  }
  return {
    filterAttributes,
    filterAttributesCount,
  };
};

/**
 * @description returns toast message when file successfully downloads.
 * @param - t: translation property
 */
export const getSuccessMessage = (t) => {
  return `${t("common:EXPORTED_SUCCESSFULLY")}`;
};

/**
 * @description returns toast message when file downloads failed.
 * @param - t: translation property
 */
export const getFailureMessage = (t) => {
  return `${t("common:EXPORT_ERROR")}`;
};

export const getImportDownloadFileName = (templateName, extension) => {
  const date = LocaleManager.dateHelpers.getNativeDateInTenantTimezone();
  return `${templateName}_${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}.${extension}`;
};

export const getFileName = (fileName) => {
  return fileName.replace(/\/|<|>|:|"|\||\?|\*|\[|\]/gi, "_");
};

export const getFormattedName = (parentDetails) => {
  if (parentDetails) {
    const manufacturerDetail = [parentDetails.manufacturerName, parentDetails.model];
    const scanCodeDetails = [
      parentDetails.name,
      parentDetails.scanCode ? `(${parentDetails.scanCode})` : `(${parentDetails.inventoryNumber})`,
    ];
    const filterManufacturerDetail = HelperLodash.compact(manufacturerDetail).join(" ");
    const filterScanCodeDetail = HelperLodash.compact(scanCodeDetails).join(" ");

    return !!filterManufacturerDetail.length
      ? `${filterManufacturerDetail} - ${filterScanCodeDetail}`
      : filterScanCodeDetail;
  }
  return null;
};

export const getFormatNameScanCode = (data) => {
  let formatedData = data.name;

  if (data.name && data.scanCode) {
    formatedData = `${formatedData} (${data.scanCode})`;
  } else if (!data.name && data.scanCode) {
    formatedData = `${data.scanCode}`;
  } else if (data.name && !data.scanCode) {
    formatedData = `${formatedData} (${data.inventoryNumber})`;
  } else if (!data.name && !data.scanCode) {
    formatedData = `${data.inventoryNumber}`;
  }
  return formatedData;
};

/**
 * @description returns the array data in chunk
 * @param - arr: Complete array data
 * @param - chunk: size of chunk
 */

export const splitDataIntoChunk = (arr, chunk) => {
  const tempArray = [];
  while (arr.length > 0) {
    tempArray.push(arr.splice(0, chunk));
  }
  return tempArray;
};

/**
 * @description Checks if the array of the field names includes a specific
 * field name and returns an additional label "(from external system)"
 *
 * @param canUpdateOriginalLabel - a flag that allows us to modify the original label
 * @param originalLabel - original label
 * @param t - translation function
 * @param fieldNamesList - array of the field names
 * @param fieldName - field name that we're going to search
 */

export const addExternalLabel = (
  canUpdateOriginalLabel: boolean = false,
  originalLabel: string,
  t: (a: string) => string,
  fieldNamesList: string[] = [],
  fieldName: string = "",
) => {
  if (canUpdateOriginalLabel && fieldNamesList.includes(fieldName)) {
    return `${originalLabel} (${t("common:FROM_EXTERNAL_SYSTEM")})`;
  }
  return originalLabel;
};

/**
 * @description opens e-mailing options for emailing clicked email id
 * @param - event: event parameter
 * @param - email: email-id of user
 */

export const onMailIconClickHandler = (event: any, email: string) => {
  window.location.href = `mailto:${email}`;
  event.stopPropagation();
};

/**
 * @description to check whether time based fields should be displayed. If
 * tenant is in list of timeBasedEnabledTenantList then
 * user will see time based cost setting fields
 * @param - timeBasedEnabledTenantList: []
 * @param - tenantId: current tenantId
 */

export const shouldShowCappingAndTimeBasedFields = (
  timeBasedEnabledTenantList: number[] = [],
  tenantId: number,
): boolean => {
  return timeBasedEnabledTenantList.includes(tenantId);
};

/**
 * @description basedOn  static list to categorize Service Template
 *
 * @param t - translation function
 * @Return - Returns translated Basedon category List
 */
export const basedOnList = (t) => [
  {
    code: "PERIOD",
    value: t("services:LABEL_PERIOD"),
  },
  {
    code: "DISTANCE",
    value: t("services:LABEL_DISTANCE"),
  },
  {
    code: "OPERATING_TIME",
    value: t("services:LABEL_OPERATING_TIME"),
  },
];

export const isEnterpriseTenant = (tenantCategory) => tenantCategory === TenantCategories.ENTERPRISE;

/**
 * @description A function for converting URLs in a given string of text into clicking link tags.
 *
 * @param text - input text string
 */
const StyleNormalTextAreaLink = styled.a`
  text-decoration: underline;
  color: blue;
  &:after {
    content: " ";
  }
`;
export const autoLinkText = (text) => {
  if (!text) {
    return "";
  }
  return text.split(/\r?\n/).map((line) => {
    return (
      <div>
        {line?.split(" ").map((word) => {
          if (word.match(ValidationConstants.URL_REGEX)) {
            const linkWord = word.startsWith("http://") || word.startsWith("https://") ? word : `https://${word}`;
            return (
              <StyleNormalTextAreaLink target="_blank" href={linkWord}>
                {word}
              </StyleNormalTextAreaLink>
            );
          } else {
            return word + " ";
          }
        })}
      </div>
    );
  });
};

export const unitsOfMeasurement = (basedOn, t, distanceUnitValue) => {
  switch (basedOn) {
    case AssetServiceCategory.DISTANCE:
      return distanceUnitValue?.value;
    case AssetServiceCategory.OPERATING_TIME:
      return t("services:ENGINE_HOURS");
    case AssetServiceCategory.PERIOD:
      return t("services:DATE");
    default:
      return t("services:DATE");
  }
};

export const setSortFields = (sortQuery: ISortQuery, setSortQuery: (query: ISortQuery) => void, moduleName: string) => {
  if (
    moduleName === ModuleName.location &&
    (sortQuery.sortField === SortFields.ASSETSCOUNT || sortQuery.sortField === SortFields.QUANTITYITEMCOUNT)
  ) {
    setSortQuery({
      sortField: LOCATION_DEFAULT_SORT,
      sortType: sortQuery.sortType,
    });
  } else if (
    moduleName === ModuleName.Employee &&
    (sortQuery.sortField === SortFields.ASSETSASSIGNEDCOUNT || sortQuery.sortField === SortFields.QUANTITYITEMCOUNT)
  ) {
    setSortQuery({
      sortField: WORKER_DEFAULT_SORT,
      sortType: sortQuery.sortType,
    });
  }
};

export const getPercentage = (value: number, total: number) => ((value / total) * 100).toFixed(1).replace(/\.0+$/, "");
export const secondsToHourMins = (d: number) => {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const hDisplay = h >= 0 ? `${h}h` : "";
  const mDisplay = m >= 0 ? `${m}m` : "";
  return hDisplay === "" && mDisplay === "" ? ` (0)` : ` (${hDisplay} ${mDisplay})`;
};

export const getHours = (d: number) => {
  const hours = Math.floor(d / 3600);
  return hours;
};

/**
 * use to format chart data
 * @param object contains utilization summary data
 */
export const getUtilizationTime = (utilizationSummary) => {
  const engineOffTime = utilizationSummary?.engineHours?.engineOffTime || 0;
  const idleTime = utilizationSummary?.engineHours?.idleTime || 0;
  const workingTime = utilizationSummary?.engineHours?.workingTime || 0;
  const totalTime = engineOffTime + idleTime + workingTime;
  return { engineOffTime, idleTime, workingTime, totalTime };
};

type ScrollEvent = {
  currentTarget: {
    scrollHeight: number;
    scrollTop: number;
    clientHeight: number;
  };
};

export const isScrolledToBottom = (scrollEvent: ScrollEvent): boolean => {
  const scrollEventTarget = scrollEvent.currentTarget;
  return (
    Math.floor(scrollEventTarget.scrollHeight - scrollEventTarget.scrollTop) ===
    Math.floor(scrollEventTarget.clientHeight)
  );
};

export const includeLockerSubType = (queryObj) => {
  if (queryObj?.filter) {
    return { ...queryObj, filter: `${queryObj.filter}&includeLocker=true` };
  } else {
    return { ...queryObj, filter: `&includeLocker=true` };
  }
};
