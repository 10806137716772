// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import removeIcon from "TARGET_BUILD/ui-assets/icons/remove.svg";
import { ToastrType } from "../../actions/toastrAction";
import Gateway from "../../gateway/gateway";
import ApplicationConstants from "../../applicationConstants";

export const ProgressContainer = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 24px;
  display: flex;
  margin: 0px 24px 24px 24px;
`;

const ProgressStyle = styled.progress`
  width: 100%;
  height: 4px;
  background: red;
`;

const ProgressContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  word-break: break-all;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CancelProgressImg = styled.img`
  margin: 4px 0 4px 16px;
  cursor: pointer;
  display: flex;
  align-self: flex-end;
`;

export class UploadProgress extends React.Component<any, any> {
  CancelToken = Gateway.getToken();
  source: {
    cancel: any;
    token: any;
  };
  constructor(props) {
    super(props);
    this.state = {
      index: {
        fileName: "",
        fileSizeLoaded: 0,
        progressCount: 0,
        totalFileSize: 0,
      },
    };
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const d = 2;
    const dm = decimals <= 0 ? 0 : decimals || d;
    const sizes = ["Bytes", "KB", "MB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${HelperLodash.get(sizes, i)}`;
  }

  cancelFileProgress = (e) => {
    if (this.source && this.source.cancel) {
      e.stopPropagation();
      this.source.cancel();
      this.source.token = null;
      this.props.showToaster(ToastrType.error, `${this.props.t("common:FILE_UPLOAD_CANCEL")}`);
      this.props.handleProgressBar();
    }
  };

  uploadApi(fieldName, formName) {
    const { associatedEntity, closeProgressBar, uploadDocument, attachmentIdKey, documentType } = this.props;
    this.props.file.map((file) => {
      const fileName = file && file.name;
      const formData = new FormData();

      formData.append("file", file, fileName);
      const jsonParamsObject = {
        documentDescription: "Doc Description.",
        documentTitle: file.documentKey,
        documentType: "ifft",
        privateDocument: false,
      };
      if (associatedEntity) {
        HelperLodash.set(jsonParamsObject, "associatedEntity", associatedEntity);
      }
      formData.append("jsonparams", new Blob([JSON.stringify(jsonParamsObject)], { type: "application/json" }));
      this.source = this.CancelToken.source();
      const uploadProgressConfig = {
        cancelToken: this.source.token,
        onUploadProgress: (progressEvent) => {
          const percent = (progressEvent.loaded / progressEvent.total) * ApplicationConstants.PERCENTAGE_BASE;
          const updatedState = {};
          const updatedStateObject = {
            documentKey: file.documentKey,
            fileName,
            fileSizeLoaded: this.formatBytes(Math.round(progressEvent.loaded), null),
            progressCount: Math.round(percent),
            totalFileSize: this.formatBytes(Math.round(progressEvent.total), null),
          };
          HelperLodash.set(updatedState, file.documentKey, updatedStateObject);
          this.setState(updatedState);
          if (percent >= ApplicationConstants.PERCENTAGE_BASE) {
            closeProgressBar();
            this.setState({
              progressCount: 0,
            });
          }
        },
      };
      uploadDocument(
        {
          attachmentIdKey: attachmentIdKey ? attachmentIdKey : "attachmentId",
          documentType,
          fieldName,
          fileData: formData,
          formName,
        },
        uploadProgressConfig,
      );
    });
  }

  render() {
    return (
      this.props.file.length &&
      this.props.file.map((file, index) => {
        const fileDocumentKey = HelperLodash.get(this.state, file.documentKey);
        return fileDocumentKey ? (
          <ProgressContainer key={file && index} id={`progressContainer${index}`}>
            <ProgressWrapper id={`progressWrapper${index}`}>
              <ProgressContent id={`progressContent${index}`}>
                <span>{fileDocumentKey.fileName}</span>
                <span>
                  {fileDocumentKey.fileSizeLoaded === fileDocumentKey.totalFileSize
                    ? `${this.props.t("common:UPLOAD_COMPLETE")} `
                    : `${this.props.t("common:UPLOADING")} `}
                  {fileDocumentKey.fileSizeLoaded} / {fileDocumentKey.totalFileSize}
                </span>
              </ProgressContent>
              <ProgressStyle id={`progressStyle${index}`} value={fileDocumentKey.progressCount} max="100" />
            </ProgressWrapper>
            <CancelProgressImg src={removeIcon} id={`cancelProgressImg${index}`} onClick={this.cancelFileProgress} />
          </ProgressContainer>
        ) : null;
      })
    );
  }
}

export default UploadProgress;
