import { Dialog, SquareIcon } from "am-web-ui-shared/components";
import { SharedUtils } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { IAlertsProps } from "./iAlerts";
import AlertTab from "../../../modules/alert/components/alertTabs";

import { TransferConfirmationPolicyEnum } from "../../../modules/transferSettings/transferConfirmationPolicyEnum";
import GTMConstants from "../../../gtmConstants";
import { SuspenseWrapper } from "../../../reactSuspenseWrapper";
import { trackConfirmDeliveryTransferList } from "../../../../analytics/events/confirmTransfer";
import {
  AlertAndNotificationContainerWrapper,
  ConfirmTransferContainerWrapper,
  FieldRequestsListContainerWrapper,
} from "./lazyLoads";
import SuggestedTransferTabContent from "TARGET_BUILD/common/modules/alert/components/suggestedTransfer/tabContent";
import featureFlagManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import withLicenseContext from "TARGET_BUILD/licenseStrategy/withLicenseContext";

export const StyledAlertText = styled.span`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 16px;
  color: ${(props) => props.theme.colors.base.steel};
  margin-left: 16px;
`;

export const StyledAlerts = styled("div")`
  position: relative;
  padding: 12px 16px 12px 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-right: 2px solid ${(props) => props.theme.colors.base.borderColor};
`;

export const CountWrapper = styled.span`
  position: absolute;
  left: 24px;
  top: 4px;
  padding: 4px;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: 8px;
  background-color: ${(props) => props.theme.colors.base.hiltiRed};
  border-radius: 50px;
  color: ${(props) => props.theme.colors.base.white};
`;

const AlertTabIndex = {
  GENERAL: 0,
  TRANSFER: 1,
  FIELDREQUEST: 2,
  SUGGESTEDTRANSFER: 3,
};

export class Alerts extends React.Component<IAlertsProps> {
  state = {
    selectedTabIndex: 0,
  };

  componentDidMount() {
    const {
      confirmTransferCount,
      activeAlertCount,
      getAlertCount,
      getConfirmTransferCount,
      transferConfirmationPolicy,
      getFieldRequestsCount,
      isFieldRequestEnabled,
      getGatewayAssetsCount,
      getSuggestedTransfersCount,
      licenseContext,
    } = this.props;
    if (!activeAlertCount && activeAlertCount !== 0) {
      getAlertCount();
    }
    if (
      !confirmTransferCount &&
      confirmTransferCount !== 0 &&
      transferConfirmationPolicy &&
      transferConfirmationPolicy.code !== TransferConfirmationPolicyEnum.Disabled
    ) {
      getConfirmTransferCount();
    }
    if (isFieldRequestEnabled) {
      getFieldRequestsCount();
    }
    getGatewayAssetsCount();
    if (
      featureFlagManager.isFeatureEnabled(FEATURES.PAT_SUGGESTED_TRANSFERS) &&
      licenseContext.hasRestrictedApiAccess() &&
      licenseContext.getProLicenses().hasSuggestedTransfer
    ) {
      getSuggestedTransfersCount();
    }
  }

  showAlertsList = () => {
    const { toggleAlertList, closeAllMoreFilter, transferConfirmationPolicy, getConfirmTransferCount } = this.props;
    toggleAlertList();
    closeAllMoreFilter();
    if (transferConfirmationPolicy && transferConfirmationPolicy.code !== TransferConfirmationPolicyEnum.Disabled) {
      getConfirmTransferCount();
    }
    if (this.props.isFieldRequestEnabled) {
      this.props.getFieldRequestsCount();
    }
  };

  closeAlertListDialog = () => {
    this.props.toggleAlertList();
  };

  handleTabSelection = (index) => {
    this.setState({ selectedTabIndex: index });
  };

  getAlertView = () => {
    const {
      name,
      t,
      activeAlertCount,
      confirmTransferCount,
      fieldRequestsCount,
      suggestedTransfersCount = 0,
    } = this.props;
    return (
      <StyledAlerts onClick={this.showAlertsList}>
        <SquareIcon name={name} id="alertImageId" />
        {activeAlertCount + confirmTransferCount + fieldRequestsCount + suggestedTransfersCount > 0 && <CountWrapper />}
        <StyledAlertText id="alertImageText">{t("common:ALERTS")}</StyledAlertText>
      </StyledAlerts>
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.isFieldRequestEnabled && state.selectedTabIndex === AlertTabIndex.FIELDREQUEST) {
      return {
        selectedTabIndex: AlertTabIndex.GENERAL,
      };
    }
    return null;
  }

  renderTabContainer = (tabIndex) => {
    const { confirmTransferCount, t, fieldRequestsCount } = this.props;
    let componentToRender;
    switch (tabIndex) {
      case AlertTabIndex.GENERAL:
        componentToRender = (
          <SuspenseWrapper>
            <AlertAndNotificationContainerWrapper />
          </SuspenseWrapper>
        );
        break;
      case AlertTabIndex.TRANSFER:
        componentToRender = (
          <SuspenseWrapper>
            <ConfirmTransferContainerWrapper t={t} confirmTransferCount={confirmTransferCount} />
          </SuspenseWrapper>
        );
        trackConfirmDeliveryTransferList(confirmTransferCount);
        break;
      case AlertTabIndex.FIELDREQUEST:
        componentToRender = (
          <SuspenseWrapper>
            <FieldRequestsListContainerWrapper t={t} fieldRequestsCount={fieldRequestsCount} />
          </SuspenseWrapper>
        );
        SharedUtils.pushDataToGA(GTMConstants.EVENTS.SELECT_FIELD_REQUEST_TAB, null, {
          dataValue: fieldRequestsCount,
        });
        break;
      case AlertTabIndex.SUGGESTEDTRANSFER:
        componentToRender = (
          <SuspenseWrapper>
            <SuggestedTransferTabContent />
          </SuspenseWrapper>
        );
        break;
    }
    return componentToRender;
  };

  getALertListView = () => {
    const { t, licenseContext } = this.props;
    return (
      <Dialog onClose={this.closeAlertListDialog} headerText={t("alertAndNotification:NOTIFICATION_CENTER")}>
        <AlertTab
          activeAlertCount={this.props.activeAlertCount}
          confirmTransferCount={this.props.confirmTransferCount}
          fieldRequestsCount={this.props.fieldRequestsCount}
          handleTabSelection={this.handleTabSelection}
          selectedTabIndex={this.state.selectedTabIndex}
          transferConfirmationPolicy={this.props.transferConfirmationPolicy}
          isFieldRequestEnabled={this.props.isFieldRequestEnabled}
          licenseContext={licenseContext}
        />
        {this.renderTabContainer(this.state.selectedTabIndex)}
      </Dialog>
    );
  };

  render() {
    return this.props.showAlertListPopup ? this.getALertListView() : this.getAlertView();
  }
}

export default withLicenseContext(Alerts);
