import React from "react";
const SVG = () => (
  <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3532.000000, -612.000000)" fill="#524F53">
        <g transform="translate(3530.000000, 610.000000)">
          <path d="M17,4 L17,7 L20,7 L23,12 L23,17 L21,17 C21,18.6568542 19.6568542,20 18,20 C16.3431458,20 15,18.6568542 15,17 L15,17 L9,17 C9,18.6568542 7.65685425,20 6,20 C4.34314575,20 3,18.6568542 3,17 L3,17 L1,17 L1,4 L17,4 Z M6,16 C5.44771525,16 5,16.4477153 5,17 C5,17.5522847 5.44771525,18 6,18 C6.55228475,18 7,17.5522847 7,17 C7,16.4477153 6.55228475,16 6,16 Z M18,16 C17.4477153,16 17,16.4477153 17,17 C17,17.5522847 17.4477153,18 18,18 C18.5522847,18 19,17.5522847 19,17 C19,16.4477153 18.5522847,16 18,16 Z M19,9 L17,9 L17,12 L21,12 L19,9 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
