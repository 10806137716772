import { AuthorizationCore, HelperLodash } from "am-web-ui-shared/helpers";
import { ToastrType } from "../../actions/toastrAction";
import { AssetCapType, CostCodeType, costTypeErrorCode } from "../../components/charges/costCodeTypeEnum";
import { validateChargeSettingResponse } from "../../utils/errorUtils";
import ChargeSetting, { IChargeSettingError } from "./chargesModel";

const canUserEditChargeSettings = () => {
  return AuthorizationCore.hasActiveModule("ASSET_COST") && AuthorizationCore.hasPermission("EDIT_ASSET_COST_SETTINGS");
};

/*
  Its the case if charges details are not recieved from backend. Then charges section is not
  initialized. So during edit, need to supress the post/put call.
*/
export const isChargeSettingLoaded = (chargeDetails, formChargeSetting) => {
  return chargeDetails && formChargeSetting;
};

const canUserViewChargeSettings = () => {
  return (
    AuthorizationCore.hasActiveModule("ASSET_COST") &&
    AuthorizationCore.hasPermission("GENERAL_ACCESS_ASSET_COST_SETTINGS")
  );
};

const shouldProcessChargeSetting = (response, isNewTemplate) => {
  if (
    response &&
    !response.error &&
    response.assetId &&
    canUserEditChargeSettings() &&
    (isNewTemplate || !response.assetTemplateId)
  ) {
    return true;
  }
  return false;
};

const getToastMessages = (chargeSettingResponse, source, t, isNewTemplate, asset) => {
  const messages = [];

  const errorCode = validateChargeSettingResponse(chargeSettingResponse);

  // charges has error
  if (errorCode) {
    messages.push(
      {
        toastrMessage: t("common:SAVED_WITH_ERRORS"),
        type: ToastrType.warning,
      },
      {
        toastrMessage: t("assets:UNABLE_TO_SAVE_ASSET_COST_DATA_PLEASE_EDIT_AND_TRY_AGAIN_LATER"),
        type: ToastrType.error,
      },
    );
  } else {
    messages.push({
      toastrMessage: t("common:SAVED_SUCCESSFULLY"),
      type: ToastrType.success,
    });
  }

  if (source === "asset" && asset && isNewTemplate) {
    messages.push({
      toastrMessage: t("templates:ADD_TEMPLATE_SUCCESS_MESSAGE", {
        templateName: `${asset.model}-${asset.manufacturerName}`,
      }),
      type: ToastrType.info,
    });
  }
  return messages;
};

const isTemplateCreated = (response, isEditing: boolean): boolean => {
  if (!response) {
    return false;
  }
  if (isEditing) {
    // on editing template, API gives 200 without respoonse object
    return !Boolean(response.error);
  } else {
    return Boolean(response.templateId);
  }
};

const getMethod = (chargeSetting: ChargeSetting | IChargeSettingError): string => {
  if (!chargeSetting) {
    return "POST";
  }
  if (
    chargeSetting.error &&
    (chargeSetting.code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_ASSET ||
      chargeSetting.code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_TEMPLATE)
  ) {
    return "POST";
  } else {
    return "PUT";
  }
};

const assignChargeSetting = (setting) =>
  !setting || setting.error || (setting && !setting.costTypeCode)
    ? {
        costTypeCode: "NONE",
        assetCapSetting:
          setting && setting.assetCapSetting
            ? setting.assetCapSetting
            : {
                cappingType: AssetCapType.NOT_CAPPED,
                alwaysFollowAssetCapSetting: false,
              },
      }
    : setting;

const hasError = (setting) =>
  setting &&
  setting.error &&
  setting.code !== costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_ASSET &&
  setting.code !== costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_TEMPLATE;

const process = (setting, disable) => {
  return {
    chargeSetting: disable ? setting : assignChargeSetting(setting),
    disable,
    isDailyCost: setting && setting.costTypeCode === CostCodeType.DAILY,
    isError: hasError(setting),
    isNeverSet:
      !setting ||
      (setting && !setting.costTypeCode) ||
      (setting &&
        setting.error &&
        (setting.code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_ASSET ||
          setting.code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_TEMPLATE)),
    isNone: setting && setting.costTypeCode === "NONE",
  };
};

const isNewTemplateCreated = (asset, template) => {
  if ((!template || (template && !template.id)) && asset) {
    return Boolean(!asset.assetTemplateId && asset.model && (asset.manufacturerId || asset.manufacturerName));
  }
  return false;
};

const isValidTemplate = (template) => {
  return Boolean(template && template.id);
};

const getTemplateSettings = (params) => {
  const { templateChargeSetting, entityId } = params;
  return process(getTemplateSetting(templateChargeSetting, entityId), false);
};

const isDelink = (asset, template, modelName, manufacturerName) => {
  return Boolean(asset && asset.assetTemplateId && (!modelName || !manufacturerName) && !isValidTemplate(template));
};

const getChargeSetting = (params) => {
  const {
    assetDetails,
    templateChargeSetting,
    templateInfo,
    assetSetting,
    entityId,
    modelName,
    manufacturerName,
    manufacturerId,
  } = params;

  if (isValidTemplate(templateInfo) && getTemplateSetting(templateChargeSetting, templateInfo.id)) {
    // template case
    return process(getTemplateSetting(templateChargeSetting, templateInfo.id), true);
  } else if (isDelink(assetDetails, templateInfo, modelName, manufacturerName) && entityId) {
    // delink case
    return process(getTemplateSetting(templateChargeSetting, assetDetails.assetTemplateId), false);
  } else if (!isValidTemplate(templateInfo) && modelName && (manufacturerName || manufacturerId)) {
    // create new template case
    return process({}, false);
  } else {
    // asset case
    return process(assetSetting || {}, false);
  }
};

const getTemplateSetting = (templates, templateId) => HelperLodash.get(templates, templateId);

const isTemplateAssociated = (asset): boolean => Boolean(HelperLodash.get(asset, "assetTemplateId"));

const generateRequestData = (chargeSetting) => {
  chargeSetting = chargeSetting || {};
  if (!chargeSetting.costTypeCode) {
    chargeSetting.costTypeCode = "NONE";
  }
  if (chargeSetting.costTypeCode === "NONE") {
    delete chargeSetting.costTypeValue;
  }
  delete chargeSetting.costTypeName;
  delete chargeSetting.costTypeValueDisplay;
  delete chargeSetting.currency;

  return chargeSetting;
};

export {
  canUserEditChargeSettings,
  canUserViewChargeSettings,
  isDelink,
  isNewTemplateCreated,
  isTemplateCreated,
  isValidTemplate,
  generateRequestData,
  getMethod,
  getTemplateSettings,
  isTemplateAssociated,
  getChargeSetting,
  getToastMessages,
  shouldProcessChargeSetting,
};
