import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
/**
 * Header styles for the checkbox list
 */
export const HeaderText = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;
export const ClearButton = styled.button`
  text-decoration: underline;
  background: none;
  border: none;
  font-size: ${(props) => props.theme.fontSize.base};
  &:hover {
    color: ${(props) => props.theme.colors.base.steel10};
  }
`;
const FilterWrapper = styled.div`
  padding: 8px 0 10px;
`;
export const List = styled(MenuItem)`
  && {
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
export const StyledText = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: 300;
  line-height: 17px;
  font-size: ${(props) => props.theme.fontSize.base};
  margin-left: 16px;
  &.lbl {
    flex: 1;
  }
  &:before {
    content: "";
    transform: rotate(45deg);
    border-width: 0 4px 4px 0;
    width: 9px;
    margin-right: 16px;
    display: inline-block;
    border-color: ${(props) => props.theme.colors.base.steel};
    height: 15px;
    border-style: solid;
  }
`;
export const HeaderWrapper = styled.h4`
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  margin: 10px 0 0;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;
export const ReadOnlyControl = (props) => {
  /**
   * clear seelcted records
   */
  const clearSelection = () => {
    props.input.onChange(null);
    return props.onSelectionChange && props.onSelectionChange([]);
  };

  return (
    <FilterWrapper id={props.id}>
      <HeaderWrapper>
        <HeaderText key={`${props.id}_header`}> {props.header}</HeaderText>
        <ClearButton onClick={clearSelection}>{props.clearButtonText}</ClearButton>
      </HeaderWrapper>
      {props.selectedOptions &&
        props.selectedOptions.map((row, index) => {
          return (
            <List key={index}>
              <StyledText className={"styledText"} id={`${name}Lbl`}>
                {row}
              </StyledText>
            </List>
          );
        })}
    </FilterWrapper>
  );
};
export class ReadOnlyMoreFilter extends React.PureComponent<any> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={ReadOnlyControl}
        header={this.props.header}
        toolTip={this.props.toolTip}
        selectedOptions={this.props.selectedOptions}
        {...this.props}
      />
    );
  }
}

export default ReadOnlyMoreFilter;
