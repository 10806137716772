import React from "react";
import styled from "styled-components";
import Button from "../../../../elements/Button/button";

export const ApplyButtonWrapper = styled(Button)`
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.base.steel20 : theme.colors.base.hiltiRed)};
  position: relative;
  height: 32px;
  width: 32px;
  color: ${(props) => props.theme.colors.base.white};
  padding: 0;
  border: none;
  &:disabled {
    cursor: default;
  }
  &:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
    width: 8px;
    height: 12px;
    left: 12px;
    top: 8px;
    content: "";
    position: absolute;s
    margin: 0;
    border-color: ${(props) => props.theme.colors.base.white};
    border-style: solid;
  }
`;

export const ApplyButton = (props) => (
  <ApplyButtonWrapper type={props.type || "button"} disabled={!props.isActive} onClick={props.onClick} />
);

export default ApplyButton;
