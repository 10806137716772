import React from "react";
import styled from "styled-components";
import Field from "../Field/field";
import { ISelectProps } from "./iSelectProps";

const StyledSelect = styled.select``;

class Select extends React.PureComponent<ISelectProps> {
  render() {
    const { value, name, label, options, onChange, required, error, ref, ...props } = this.props;

    return (
      <Field htmlFor={name} name={name} label={label} error={error}>
        <StyledSelect
          {...props}
          name={name}
          value={value}
          ref={ref}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange(event)}
          required={required}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </Field>
    );
  }
}

export default Select;
