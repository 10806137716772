import { HelperLodash } from "am-web-ui-shared";
import { IGeoJSONFeature, IOneMapRequest } from "../model/cluster";
import { IAssetDetailsData } from "TARGET_BUILD/common/models/asset/assetModel";
import AssetDetail from "TARGET_BUILD/common/models/asset/assetDetail";
import { cloneDeep } from "am-web-ui-shared/helpers/helperLodash";
import { setCheckboxWithRowSelection } from "TARGET_BUILD/common/utils/list/listUtils";

export const POPULATE_STATE = "POPULATE_STATE";
export const UNSELECT_ASSET = "UNSELECT_ASSET";
export const SET_SELECTED_ASSET = "SET_SELECTED_ASSET";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_BOUNDING_BOX = "SET_BOUNDING_BOX";
export const SET_INVENTORY_ASSET_IDS = "SET_INVENTORY_ASSET_IDS";
export const SET_CLUSTER_ASSET_IDS = "SET_CLUSTER_ASSET_IDS";

export interface IClusterAssetModel {
  ids?: number[];
  totalRecords: number;
  limit: number;
  offset: number;
  response: AssetDetail[];
}

export type Actions =
  | { type: typeof POPULATE_STATE; payload: OneMapState }
  | { type: typeof UNSELECT_ASSET }
  | { type: typeof SET_SELECTED_ASSET; payload: { assetId: number } }
  | { type: typeof SET_SELECTED_LOCATION; payload: IGeoJSONFeature }
  | { type: typeof SET_INVENTORY_ASSET_IDS; payload: number[] }
  | { type: typeof SET_BOUNDING_BOX; payload: IOneMapRequest }
  | { type: typeof SET_CLUSTER_ASSET_IDS; payload: number[] };

type OneMapApiParams = {
  minLng: number;
  minLat: number;
  maxLng: number;
  maxLat: number;
  zoom: number;
  assetIds: number[];
};

export type OneMapState = {
  selectedAssetId: number;
  oneMapApiParams: OneMapApiParams;
  display: string;
};

export type State = {
  states: OneMapState[];
  features: IGeoJSONFeature[];
  currentState: OneMapState;
  clusterAssetsIds: number[];
};

export const initialState: State = {
  states: [],
  features: [],
  currentState: {
    selectedAssetId: null,
    oneMapApiParams: {
      minLng: 0,
      minLat: 0,
      maxLng: 0,
      maxLat: 0,
      zoom: 0,
      assetIds: [],
    },
    display: "",
  },
  clusterAssetsIds: [],
};

export const populateSelection = (state: State, newState: OneMapState): OneMapState[] => {
  if (state?.states) {
    const updatedStates: OneMapState[] = [];
    for (const value of state.states) {
      if (HelperLodash.isEqual(value, newState)) {
        updatedStates.push({ ...value });
        break;
      } else {
        updatedStates.push(value);
      }
    }
    return updatedStates;
  }
  return state?.states || [];
};

export const oneMapReducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case POPULATE_STATE:
      return {
        ...state,
        states: [...populateSelection(state, action.payload)],
        currentState: action.payload,
      };
    case UNSELECT_ASSET:
      return {
        ...state,
        states: [],
        currentState: {
          ...state.currentState,
          oneMapApiParams: {
            ...state.currentState?.oneMapApiParams,
            assetIds: null,
          },
          selectedAssetId: null,
        },
      };
    case SET_SELECTED_ASSET:
      return {
        ...state,
        states: [
          {
            ...state.currentState,
            selectedAssetId: action.payload?.assetId,
          },
        ],
        currentState: {
          ...state.currentState,
          selectedAssetId: action.payload?.assetId,
        },
      };
    case SET_INVENTORY_ASSET_IDS:
      return {
        ...state,
        currentState: {
          ...state.currentState,
          oneMapApiParams: {
            ...state.currentState?.oneMapApiParams,
            assetIds: action.payload,
          },
        },
      };
    case SET_BOUNDING_BOX:
      return {
        ...state,
        currentState: {
          ...state.currentState,
          oneMapApiParams: {
            ...state.currentState?.oneMapApiParams,
            assetIds: state.currentState?.oneMapApiParams?.assetIds || null,
            minLng: action.payload?.minLng,
            minLat: action.payload?.minLat,
            maxLng: action.payload?.maxLng,
            maxLat: action.payload?.maxLat,
            zoom: action.payload?.zoom,
          },
        },
      };
    case SET_CLUSTER_ASSET_IDS:
      return {
        ...state,
        clusterAssetsIds: action.payload,
      };
    default:
      return state;
  }
};
