// @ts-ignore
import { PDPMANUFACTURERS } from "TARGET_BUILD/config";
/*
  For user defined templates isAMTemplate field doesnot exists
  For PDP field isAMTemplate can be true or false.
*/
export const isAMTemplate = (templateInfo) => {
  return isTemplateExists(templateInfo) && (templateInfo.rangeId ? templateInfo.isAmTemplate !== false : true);
};

/*
  For Template if id exist or not
*/
export const isTemplateExists = (templateInfo) => {
  return templateInfo && (templateInfo.id || templateInfo.rangeId);
};

/**
 * @description Function to check if template provided data control should render readonly
 * @param {templateInfo} - Template information details
 * @param {fieldName} - name of the input controls
 * @return {boolean} -
 */
export const shouldRenderAsReadOnly = (templateInfo, fieldName) => {
  return isTemplateExists(templateInfo) && isAMTemplate(templateInfo) && TEMPLATE_FIELDS.indexOf(fieldName) > -1;
};

/**
 * @description Function to check rangeExists and it pdp template
 * @param {templateInfo} - Template info
 * @return {boolean} -
 */
export const rangeExists = (templateInfo) => {
  return templateInfo && templateInfo.rangeId;
};

/**
 * @description Function to check if the manufacuture selected have pdp support
 * @param {value} - Manufacture name
 * @param {PDPMANUFACTURERS} - Array of list of manufacture having pdp support
 * @return {boolean} -
 */
export const isPDPManufacturerSelected = (value) => {
  return value && PDPMANUFACTURERS && PDPMANUFACTURERS.includes(value);
};

export const isManufactureModelExist = (action) => action.manufacturerId && action.model;

/**
 * @description -> checks if the reponse of the get template API call is valid/has error or not
 * @param response -> reponse of the get template API call
 */
export const hasError = (response) => !response || response.error;

/**
 * @description checks if the template is fetched by rangeId, not templateId
 */
export const isTemplateFetchedByRangeId = (action) =>
  action.model && !action.model.templateId && action.model.isPDPModel;

/**
 * @description functions decides which API to call to get template
 * "getPDPTemplate" if rangeId is present
 * else "getManufacturerAndModel"
 * @param getPDPTemplate function to make "getPDPTemplate" call
 * @param getManufacturerAndModel function to make "getManufacturerAndModel" call
 * @param action
 */
export const getTemplateCall = (getPDPTemplate, getManufacturerAndModel, action) => {
  const { rangeId, isPDPModel, name } = action.model;
  return isPDPModel ? getPDPTemplate(rangeId) : getManufacturerAndModel(action.manufacturerId, name);
};

export const isHintEnabled = () => {
  return true;
};

const TEMPLATE_FIELDS = ["groupId", "description", "costCode", "templateImageId"];

export const TemplateConfirm = {
  MANUFACTURER: "MANUFACTURER",
  MODEL: "MODEL",
};

export const HILTIMANUFACTURERID = "1";
export const HILTIMANUFACTURERNAME = "Hilti";
