export default {
  normal: {
    fontFamily: '"Hilti-Roman", Arial,Verdana,sans-serif',
    fontWeight: 400,
  },
  bold: {
    fontFamily: '"Hilti-Bold", Arial,Verdana,sans-serif',
    fontWeight: 700,
  },
};
