import Grid from "@mui/material/Grid";
import { IconName, Prompt } from "am-web-ui-shared/components";
import { LocaleManager, HelperLodash } from "am-web-ui-shared/helpers";
import React from "react";
import styled from "styled-components";
import { required } from "../../../../utils/errorUtils";
import { ServiceResultEnum } from "../serviceFormEnum";
import { DateField } from "./servicePanelDateField";
import { SelectAutoComplete } from "am-web-ui-shared/elements";
import { isServiceResultSettingEnabled } from "../../../../../common/utils/configUtil";
import SingletonFeatureManager from "../../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../../featureFlagManager/featureConfig";
export const LabelContent = styled.h4`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  padding-bottom: 24px;
`;

export const LabelText = styled.p`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  display: block;
  cursor: pointer;
  margin-bottom: 5px;
`;
const Notes = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;
export enum dateFieldName {
  scheduleDate = "scheduleDate",
  completionDate = "completionDate",
}

const getDateInTenantTimezone = LocaleManager.dateHelpers.getDateInTenantTimezone;
const getNativeDateInTenantTimezone = LocaleManager.dateHelpers.getNativeDateInTenantTimezone;
const getDateFromNativeDateObject = LocaleManager.dateHelpers.getDateFromNativeDateObject;

export class DateSection extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    const { selectedService } = this.props;
    const initDates = {
      scheduleDate: HelperLodash.get(selectedService, dateFieldName.scheduleDate),
      completionDate: HelperLodash.get(selectedService, dateFieldName.completionDate),
    };
    this.state = {
      currentDate: { ...initDates }, // tracks changes in dates
      lastDate: { ...initDates }, // saves previously saved dates
      currentDateField: "",
      serviceResult: ServiceResultEnum.PASSED,
    };
  }
  heavyEquipmentTemplateFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE);
  isLastRecord = () => {
    return this.props.selectedService.lastRecord && this.props.selectedService.serviceType.code === "RECURRENT";
  };

  areDatesDifferent = (date1, date2) => {
    return getDateInTenantTimezone(new Date(date1)) !== getDateInTenantTimezone(new Date(date2));
  };

  handleDateChange = (fieldName, date) => {
    if (
      this.isLastRecord() &&
      this.areDatesDifferent(HelperLodash.get(this.props, `selectedService.${fieldName}`), date)
    ) {
      this.setState((prevState) => {
        return {
          currentDate: {
            ...prevState.currentDate,
            [fieldName]: getDateFromNativeDateObject(getNativeDateInTenantTimezone(date)),
          },
          lastDate: {
            ...prevState.lastDate,
            [fieldName]: HelperLodash.get(prevState, `currentDate.${fieldName}`),
          },
          currentDateField: fieldName,
        };
      });
    } else {
      this.setState({ currentDateField: "" });
    }
  };

  /**
   * Handle Confirmation Pop up close
   */
  handleClose = () => {
    const { currentDateField } = this.state;
    const lastSavedDate = HelperLodash.get(this.state, `lastDate.${currentDateField}`);
    this.setState((prevState) => ({
      currentDate: {
        ...prevState.currentDate,
        [currentDateField]: lastSavedDate,
      },
      lastDate: {
        ...prevState.lastDate,
        [currentDateField]: lastSavedDate,
      },
      currentDateField: "",
    }));
  };

  /**
   * Handle Confirmation Pop up confirm
   */
  handleConfirm = () => {
    this.props.handleCompletionDateChange();
    this.setState({ currentDateField: "" });
  };

  showConfirmationDialog = () => {
    const { t } = this.props;
    const { currentDateField } = this.state;

    this.heavyEquipmentTemplateFF && this.showCompleationConfirmationDialog();
    const date = HelperLodash.get(this.state, `currentDate.${currentDateField}`);
    // get header and message based on the name of date field being changed
    const header =
      currentDateField === dateFieldName.completionDate
        ? t("services:CHANGE_COMPLETION_DATE_CONFIRMATION")
        : t("services:CHANGE_SCHEDULE_DATE_CONFIRMATION");
    const message =
      currentDateField === dateFieldName.completionDate
        ? t("services:CHANGE_COMPLETION_DATE_MESSAGE", { date })
        : t("services:CHANGE_SCHEDULE_DATE_MESSAGE", { date });
    return (
      <Prompt
        icon={IconName.Warning}
        className="warning"
        handleClose={this.handleClose}
        handleConfirm={this.handleConfirm}
        header={header}
        cancelLabel={t("common:CANCEL")}
        okLabel={t("services:CHANGE_DATE")}
      >
        {message}
      </Prompt>
    );
  };
  showCompleationConfirmationDialog = () => {
    const { t, selectedService, distanceUnitValue } = this.props;
    const unitName = t("services:PERIOD_UNIT");
    const completionDate = getDateInTenantTimezone(this.state.currentDate?.completionDate);
    const header = t("services:CHANGE_COMPLETION_DATA_CONFIRMATION", {
      unitName,
    });

    const message = t("services:CHANGE_COMPLETION_DATA_MESSAGE", {
      unitName,
      compleationValue: completionDate,
    });
    const notes = t("services:CHANGE_COMPLETION_DATA_NOTES", {
      unitName,
    });

    return (
      <Prompt
        icon={IconName.Warning}
        className="warning"
        handleClose={this.handleClose}
        handleConfirm={this.handleConfirm}
        header={header}
        cancelLabel={t("services:FOR_THIS_SERVICE")}
        okLabel={t("services:FOR_UPCOMING_SERVICE")}
        crossIcon={true}
      >
        <Notes>{notes}</Notes>
        {message}
      </Prompt>
    );
  };

  showServiceResult = (t) => {
    const { handleServiceResult } = this.props;
    const serviceResultOptions = [
      {
        code: ServiceResultEnum.PASSED.toUpperCase(),
        value: t("services:PASS"),
      },
      {
        code: ServiceResultEnum.FAILED.toUpperCase(),
        value: t("services:FAIL"),
      },
    ];

    return (
      <Grid item={true} xs={6} direction="row">
        <SelectAutoComplete
          autoComplete="off"
          className="form-control"
          id="serviceResult"
          isFormControl={true}
          label={t("services:LABEL_SERVICE_RESULT")}
          name="serviceResult"
          valueKey="value"
          valueId="code"
          t={t}
          required={true}
          data={serviceResultOptions}
          onSelectChange={handleServiceResult}
        />
      </Grid>
    );
  };

  render() {
    const { disabled, selectedService, showDateLabel, showScheduleDate, showCompletionDate, t } = this.props;
    return (
      <>
        {showScheduleDate && selectedService && (
          <Grid item={true} xs={6}>
            {showDateLabel ? (
              <>
                <LabelText>{t("services:FIELD_NAME_SCHEDULEDATE")}</LabelText>
                <LabelContent className="label-of-co-info">
                  {getDateInTenantTimezone(selectedService.scheduleDate) || "-"}
                </LabelContent>
              </>
            ) : (
              <DateField
                t={t}
                name={dateFieldName.scheduleDate}
                validation={disabled ? [] : [required(t, t("common:VALIDATION_DATE"))]}
                disabled={disabled}
                onSelectChange={this.handleDateChange}
              />
            )}
          </Grid>
        )}
        {showCompletionDate && (
          <DateField
            t={t}
            viewSize={6}
            name={dateFieldName.completionDate}
            maxDate={getNativeDateInTenantTimezone()}
            isClearable={false}
            validation={[required(t, t("common:VALIDATION_DATE"))]}
            onSelectChange={this.handleDateChange}
          />
        )}
        {!!this.state.currentDateField && this.showCompleationConfirmationDialog()}
        {isServiceResultSettingEnabled() && showCompletionDate && this.showServiceResult(t)}
      </>
    );
  }
}
