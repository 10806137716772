import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import { ActiveTrackingActions } from "custom-actions";
// @ts-ignore
import { SortList } from "TARGET_BUILD/config";
import { createReducer, deepCloneArray } from "../../../../utils/commonUtils";
import ModuleName from "../../../../utils/enums/moduleNameEnum";
import {
  appendMetaPropertyToList,
  appendSelectedPropertyToList,
  clearAllCheckedRows,
  setAllCheckedRows,
  setCheckboxWithRowSelection,
} from "../../../../utils/list/listUtils";
import { IActiveTrackingState, IlastSeenMap } from "./iActiveTrackingState";
import { LastSeenStateEnum } from "../../../../utils/enums/activeTrackingEnum";
import { appendLastSeenInfo, getClusterKey } from "../../../../utils/activeTrackingUtils";
import { IErrorObject, IGatewayInventory } from "../../../../models/activeTracking/iGatewayAssets";
import { rowActionType } from "TARGET_BUILD/common/utils/list/list";
import { IGeoJSONFeature, IOneMapData } from "TARGET_BUILD/common/modules/oneMap/model/cluster";
import { cloneDeep } from "am-web-ui-shared/helpers/helperLodash";
import AssetDetail from "TARGET_BUILD/common/models/asset/assetDetail";
import { IClusterAssetModel } from "TARGET_BUILD/common/modules/oneMap/store/oneMapReducer";

const DEFAULT_SORT = SortList.GATEWAY_ASSETS.DEFAULT_SORT;

const createLastSeenItems = (ids: []): IlastSeenMap => {
  const lastSeenMap: IlastSeenMap = {};
  if (!ids) {
    return lastSeenMap;
  }
  ids.forEach((assetId: number) => {
    HelperLodash.set(lastSeenMap, assetId, {
      lastSeen: { assetId },
      metadata: { state: LastSeenStateEnum.LOADING },
    });
  });
  return lastSeenMap;
};

const oneMapSingleClusterAssetsInitialState: IClusterAssetModel = {
  ids: [],
  totalRecords: 0,
  limit: 0,
  offset: 0,
  response: [],
};

const activeTrackingInitialState: IActiveTrackingState = {
  oneMapData: null,
  oneMapSingleClusterAssets: oneMapSingleClusterAssetsInitialState,
  appliedFilterQuery: [],
  assetType: null,
  filterQuery: null,
  gatewayAssetsList: {
    error: null,
    list: [],
    offset: null,
    totalRecords: 0,
  },
  isAllSelected: false,
  locationSmartInventoryAssets: null,
  selectAllAssets: [],
  showAssetListDialog: false,
  sortQuery: {
    sortField: DEFAULT_SORT.name,
    sortType: DEFAULT_SORT.sortType,
  },
  storageSmartInventoryAssets: null,
  lastSeenMap: createLastSeenItems([]),
};

/**
 * @description Function updates the state value with given filter query
 * @param action
 * @param {object} state - active tracking state
 */
const resetFilterQuery = (action, state) => {
  return {
    ...state,
    currentSelectedFilter: action.currentSelectedFilter,
    filterQuery: action.filterQuery,
  };
};

/**
 * @description Function updates the state value checked property
 * @param {object} state - active tracking state
 * @param action
 * @param {boolean} selected - true/false for check/uncheck
 */
const handleGridCheckboxSelection = (state, action, selected: boolean) => {
  const gatewayAssetsList = state?.gatewayAssetsList?.list?.slice(0) || [];
  return {
    ...state,
    gatewayAssetsList: {
      ...state.gatewayAssetsList,
      list: setCheckboxWithRowSelection(gatewayAssetsList, action.item, selected, "assetId", rowActionType.checked),
    },
  };
};

/**
 * @description Function updates the state value with updated assets response object
 * @param action
 * @param {object} state - takes entered search string.
 */
const updateAssetListState = (action, state) => {
  const isAllSelected = !!(state.isAllSelected && action.payload.offset);
  const oldResponse = action.payload.offset ? state.gatewayAssetsList.list : [];
  action.payload.assetsData.list = [
    ...oldResponse,
    ...appendSelectedPropertyToList(
      appendMetaPropertyToList(action.payload.assetsData.response, { type: ModuleName.asset }),
      isAllSelected,
    ),
  ];
  delete action.payload.assetsData.response;
  return {
    ...state,
    gatewayAssetsList: {
      ...action.payload.assetsData,
      list: action.payload.assetsData.list,
      error: action.payload.error,
    },
  };
};

/**
 * @description Function update selectAllAssets field with assets response object
 * @param action
 * @param {object} state - takes entered search string.
 */
const updateSelectAllAssetsField = (action, state) => {
  const oldResponse = action.payload.offset ? state.gatewayAssetsList.list : [];
  action.payload.assetsData.list = [
    ...oldResponse,
    ...appendSelectedPropertyToList(action.payload.assetsData.response, true),
  ];
  delete action.payload.assetsData.response;
  return {
    ...state,
    isAllSelected: false,
    selectAllAssets: action.payload.assetsData.list,
  };
};

/**
 * @description Function updates the state value with default query params
 * @param {object} state
 */
const resetQueryParam = (state: IActiveTrackingState) => {
  return {
    ...state,
    appliedFilterQuery: [],
    assetFilterAttributes: null,
    currentSelectedFilter: null,
    filterQuery: null,
    gatewayAssetsList: {
      error: null,
      list: [],
      offset: null,
      totalRecords: 0,
    },
    isAllSelected: false,
    selectAllAssets: [],
    sortQuery: {
      sortField: DEFAULT_SORT.name,
      sortType: DEFAULT_SORT.sortType,
    },
  };
};

/**
 * @description Function updates the state value with sort query params
 * @param action
 * @param {object} state
 */
const resetSortQuery = (action, state) => {
  return {
    ...state,
    sortQuery: action.sortQuery,
  };
};

/**
 * @description Function updates the checked property for all assets
 * @param {object} state
 */
const clearAllAssetsSelection = (state) => {
  const assetsList = deepCloneArray(state.gatewayAssetsList && state.gatewayAssetsList.list);
  return {
    ...state,
    gatewayAssetsList: {
      ...state.gatewayAssetsList,
      list: clearAllCheckedRows(assetsList, true),
    },
    isAllSelected: false,
  };
};

const selectAllAssets = (state) => {
  return {
    ...state,
    gatewayAssetsList: {
      ...state.gatewayAssetsList,
      list: setAllCheckedRows(state.gatewayAssetsList.list),
    },
    isAllSelected: true,
  };
};

/**
 * @description Function updates the update asset type and handles ActiveTrackingActions.AT_UPDATE_ASSET_TYPE
 * @param {object} state
 * @param action
 */
const updateAssetType = (state, action) => {
  return {
    ...state,
    assetType: action.payload,
  };
};

const populateExpectedInventory = (inventory: IGatewayInventory) => {
  return {
    expectedAssetCount: inventory.foundAssetCount + inventory.notFoundAssetCount,
    ...inventory,
  };
};

// function to handle ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY_SUCCESS action type
const getStorageAssetGatewaySuccess = (state, action) => {
  return {
    ...state,
    storageSmartInventoryAssets: {
      inventory: populateExpectedInventory(action.payload),
      error: null,
    },
  };
};

// function to handle ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY_FAIL action type
const getStorageAssetGatewayFail = (state: IActiveTrackingState, action: { payload: IErrorObject }) => {
  return {
    ...state,
    storageSmartInventoryAssets: {
      inventory: null,
      error: action.payload,
    },
  };
};

const clearStorageAssetGateway = (state) => {
  return {
    ...state,
    storageSmartInventoryAssets: null,
  };
};

// function to handle ActiveTrackingActions.AT_SET_ASSET_FILTER_APPLIED_FIELD action type
const setAssetFilterAppliedField = (state, action) => {
  return {
    ...state,
    appliedFilterQuery: HelperLodash.get(action, "payload.filterQuery"),
  };
};

const initLastSeenMap = (action, state: IActiveTrackingState) => {
  const map = createLastSeenItems(action?.payload);
  const lastSeenMap = { ...state.lastSeenMap, ...map };
  return {
    ...state,
    lastSeenMap,
  };
};

const clearLastSeenMap = (state) => {
  return {
    ...state,
    lastSeenMap: createLastSeenItems([]),
  };
};

const putAssetListLastSeenInfo = (action, state: IActiveTrackingState) => {
  const { response, responseState, assetIds } = action.payload;
  const payload = {
    assetIds,
    response,
    responseState,
    lastSeenMap: state.lastSeenMap,
  };
  const updatedMap = appendLastSeenInfo(payload);
  return {
    ...state,
    lastSeenMap: { ...updatedMap },
  };
};

const setAssetOneMap = (state, action) => {
  const oneMap = action.payload;
  if (Array.isArray(oneMap)) {
    oneMap.map((item: IGeoJSONFeature) => {
      if (item?.properties?.clusterInfo?.count > 1) {
        item.properties.clusterInfo.clusterId = getClusterKey(item);
      }
    });
    return {
      ...state,
      oneMapData: {
        oneMap,
      },
    };
  } else {
    return state;
  }
};

const setAssetOneMapWithAssetInfo = (state, action) => {
  const assets = action.payload || [];
  const oneMap = state?.oneMapData?.oneMap;
  const updatedAssets = oneMap.map((feature: IGeoJSONFeature) => {
    if (feature?.properties?.assetId && feature?.properties?.clusterInfo?.count === 1) {
      const asset = assets.find((asset) => feature?.properties?.assetId === asset.assetId);
      if (asset) {
        feature.assetInfo = asset;
      }
    }
    return feature;
  });
  return {
    ...state,
    oneMapData: {
      oneMap: updatedAssets,
    },
  };
};

const setOneMapAssetsForSingleCluster = (state, action: { payload: IClusterAssetModel }) => {
  const ids = action.payload.ids || [];
  const records = cloneDeep(action.payload.response);
  ids.map((id, index) => {
    const asset = action.payload.response.find((asset) => asset.assetId === id);
    if (!asset) {
      records.splice(index, 0, new AssetDetail({ assetId: id }));
    }
  });
  const existingRecords = state?.oneMapSingleClusterAssets?.response || [];
  const data = action.payload.offset === 0 ? records : [...existingRecords, ...(records || [])];
  return {
    ...state,
    oneMapSingleClusterAssets: {
      totalRecords: action.payload.totalRecords,
      limit: action.payload.limit,
      offset: action.payload.offset,
      response: data,
    },
  };
};

const clearOneMapAssetsForSingleCluster = (state) => {
  return {
    ...state,
    oneMapSingleClusterAssets: oneMapSingleClusterAssetsInitialState,
  };
};

const selectOneMapAssetForSingleCluster = (state, action) => {
  return {
    ...state,
    oneMapSingleClusterAssets: {
      ...state.oneMapSingleClusterAssets,
      response: [
        ...setCheckboxWithRowSelection(state.oneMapSingleClusterAssets.response, action.payload, true, "assetId"),
      ],
    },
  };
};

const activeTrackingReducers = createReducer(activeTrackingInitialState, {
  [ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY_SUCCESS]: getStorageAssetGatewaySuccess,
  [ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY_FAIL]: getStorageAssetGatewayFail,
  [ActiveTrackingActions.AT_RESET_STORAGE_ASSET_GATEWAY]: clearStorageAssetGateway,
  [ActiveTrackingActions.AT_SET_ASSET_FILTER_APPLIED_FIELD]: setAssetFilterAppliedField,
  [ActiveTrackingActions.AT_UPDATE_ASSET_TYPE]: updateAssetType,
});

const activeTrackingReducer = (state = activeTrackingInitialState, action) => {
  switch (action.type) {
    case ActiveTrackingActions.GET_LOCATION_GATEWAY_SUCCESS:
      return {
        ...state,
        locationSmartInventoryAssets: {
          inventory: populateExpectedInventory(action.payload),
          error: null,
        },
      };
    case ActiveTrackingActions.GET_LOCATION_GATEWAY_FAIL:
      return {
        ...state,
        locationSmartInventoryAssets: {
          inventory: null,
          error: action.payload,
        },
      };
    case ActiveTrackingActions.TOGGLE_ASSET_LIST_DIALOG_STATE:
      return {
        ...state,
        showAssetListDialog: action.payload,
      };
    case ActiveTrackingActions.ASSET_VIEW_FILTER_FIELD_SUCCESS:
      return {
        ...state,
        assetFilterAttributes: action.payload,
      };
    case ActiveTrackingActions.SET_ASSET_VIEW_FILTER_QUERY:
      return resetFilterQuery(action, state);
    case ActiveTrackingActions.AT_GATEWAY_ASSET_SELECT_GRID_CHECKBOX:
      return handleGridCheckboxSelection(state, action, true);
    case ActiveTrackingActions.AT_GATEWAY_ASSET_UNSELECT_GRID_CHECKBOX:
      return handleGridCheckboxSelection(state, action, false);
    case ActiveTrackingActions.GET_ASSET_LIST_SUCCESS:
      return updateAssetListState(action, state);
    default:
      return activeTrackSecondaryCases(action, state);
  }
};

const activeTrackSecondaryCases = (action, state) => {
  switch (action.type) {
    case ActiveTrackingActions.SET_GATEWAY_ASSET_SORT_QUERY:
      return resetSortQuery(action, state);
    case ActiveTrackingActions.CLEAR_GATEWAY_ASSETS_CHECKED_ROWS:
      return clearAllAssetsSelection(state);
    case ActiveTrackingActions.SELECT_ALL_GATEWAY_ASSETS_ROWS:
      return selectAllAssets(state);
    case ActiveTrackingActions.SELECT_GATEWAY_ASSETS_ROW:
      return handleGridCheckboxSelection(state, action, true);
    case ActiveTrackingActions.AT_RESET_ASSET_VIEW_QUERY_PARAMS:
      return resetQueryParam(state);
    case ActiveTrackingActions.GET_SELECT_ALL_ASSETS_SUCCESS:
      return updateSelectAllAssetsField(action, state);
    case ActiveTrackingActions.AT_PUT_ASSETS_LAST_SEEN:
      return putAssetListLastSeenInfo(action, state);
    case ActiveTrackingActions.INIT_ASSETS_LAST_SEEN:
      return initLastSeenMap(action, state);
    case ActiveTrackingActions.CLEAR_ASSETS_LAST_SEEN:
      return clearLastSeenMap(state);
    case ActiveTrackingActions.SET_ASSET_ONE_MAP_DATA:
      return setAssetOneMap(state, action);
    case ActiveTrackingActions.AT_SET_ONE_MAP_ASSETS_FOR_VIEWPORT:
      return setAssetOneMapWithAssetInfo(state, action);
    case ActiveTrackingActions.AT_SET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER:
      return setOneMapAssetsForSingleCluster(state, action);
    case ActiveTrackingActions.AT_CLEAR_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER:
      return clearOneMapAssetsForSingleCluster(state);
    case ActiveTrackingActions.AT_SELECT_ONE_MAP_ASSET_FOR_SINGLE_CLUSTER:
      return selectOneMapAssetForSingleCluster(state, action);
    default:
      return activeTrackingReducers(state, action);
  }
};

export default activeTrackingReducer;
