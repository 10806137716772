// @ts-ignore
import React from "react";
import styled from "styled-components";
import IContactInfoCardProps from "./iInfoCardProps";

// @ts-ignore
import InfoIcon from "./assets/Info.svg";
import Tooltip from "@hilti/components/core/tooltip";

const InfoCardWrapper = styled.div.attrs(() => ({
  id: "infoCardWrapper",
}))`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.base.white};
  border: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  &:hover {
    background: ${(props) => props.theme.colors.base.heavyConcrete10};
  }
  cursor: pointer;
`;

const ImageSpan = styled.span.attrs((props) => ({
  id: "imgWrapper",
  icon: (props as any).icon,
}))`
  width: 20px;
  height: 18px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: url(${(props) => (props as any).backgroundIcon});
`;

const InfoIconSpan = styled.span.attrs({
  id: "infoIconSpan",
})`
  width: 20px;
  height: 20px;
  background-image: url(${InfoIcon});
  margin-left: 10px;
  display: inline-block;
`;

const HeaderWrapper = styled.span.attrs({
  id: "headerWrapper",
})`
  display: flex;
`;

class InfoCard extends React.PureComponent<IContactInfoCardProps> {
  render() {
    return (
      <InfoCardWrapper onClick={this.props.handleClick}>
        <HeaderWrapper>
          {this.props.infoHeader}
          {this.props.showInfoIcon && (
            <>
              <Tooltip title={this.props.infoIconTooltipMsg} placement="right">
                <InfoIconSpan />
              </Tooltip>
            </>
          )}
        </HeaderWrapper>
        {this.props.icon && <ImageSpan icon={this.props.icon} />}
      </InfoCardWrapper>
    );
  }
}

export default InfoCard;
