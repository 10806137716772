import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-794.000000, -4130.000000)" fill="#524F53">
        <g transform="translate(792.000000, 4128.000000)">
          <path
            d="M17.02,12 L17.02,8 L9.02,8 L9.02,20 L12.02,20 L12.02,22 L2.02,22 L2.02,20 L5.02,20 L5.02,8 L2,8 L2,6 L5.02,2.26461614 L5.02,2 L9.02,2 L9.02,2.89796758 L17.542764,6 L22,6 L22,8 L18.02,8 L18.02,12 L20.02,12 L20.02,17 L15.02,17 L15.02,12 L17.02,12 Z M3.18294681,6 L5.02,6 L5.02,3.81068526 L3.18294681,6 Z M14.6189596,6 L9.02,3.96214535 L9.02,6 L14.6189596,6 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
