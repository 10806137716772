/**
 * @description Action constants to be used across the project
 *
 * format: {feature}_{action}
 */
const AlertNotificationConstant = {
  ADDITIONAL_WORKER_CLICKED: "ADDITIONAL_WORKER_CLICKED",
  ALERT_ROW_DESELECTED: "ALERTLIST_ROW_DESELECTED",
  ALERT_ROW_SELECTED: "ALERTLIST_ROW_SELECTED",
  ALERT_SELECT_ALL_ROWS: "ALERT_SELECT_ALL_ROWS",
  AN_ALERT_LIST_LOADER_END: "AN_ALERT_LIST_LOADER_END",
  AN_ALERT_RESET_QUERY_PARAM: "AN_ALERT_RESET_QUERY_PARAM",
  AN_ALERT_SELECT_GRID_CHECKBOX: "AN_ALERT_SELECT_GRID_CHECKBOX",
  AN_ALERT_UNSELECT_GRID_CHECKBOX: "AN_ALERT_UNSELECT_GRID_CHECKBOX",
  AN_CLOSE_ALERT_MORE_FILTER: "AN_CLOSE_ALERT_MORE_FILTER",
  AN_DISMISS_ALERT: "AN_DISMISS_ALERT",
  AN_GET_ALERTS_COUNT: "AN_GET_ALERTS_COUNT",
  AN_GET_ALERTS_LIST: "AN_GET_ALERTS_LIST",
  AN_GET_ALERTS_SETTINGS_LIST: "AN_GET_ALERTS_SETTINGS_LIST",
  AN_GET_QUICK_ACTION_POPUP_ACCESS: "AN_GET_QUICK_ACTION_POPUP_ACCESS",
  AN_ADD_TO_QUICK_ACTION_POPUP_LIST: "AN_ADD_TO_QUICK_ACTION_POPUP_LIST",
  AN_GET_ALERT_DETAILS: "AN_GET_ALERT_DETAILS",
  AN_GET_ALERT_FILTER_FIELD: "AN_GET_ALERT_FILTER_FIELD",
  AN_GET_ALERT_GROUPS: "AN_GET_ALERT_GROUPS",
  AN_GET_ALERT_USER_SETTINGS: "AN_GET_ALERT_USER_SETTINGS",
  AN_GET_NOTIFICATION_ALERT_SETTINGS: "AN_GET_NOTIFICATION_ALERT_SETTINGS",
  AN_GET_TOOL_ALERTS_LIST: "AN_GET_TOOL_ALERTS_LIST",
  AN_MANAGE_ALLOCATIONS_ALERT: "AN_MANAGE_ALLOCATIONS_ALERT",
  AN_OPEN_ALERT_MORE_FILTER: "AN_OPEN_ALERT_MORE_FILTER",
  AN_PUT_ALERT_USER_SETTINGS: "AN_PUT_ALERT_USER_SETTINGS",
  AN_PUT_IN_APP_SETTINGS: "AN_PUT_IN_APP_SETTINGS",
  AN_SET_ALERTS: "AN_SET_ALERTS",
  AN_SET_ALERTS_COUNT: "AN_SET_ALERTS_COUNT",
  AN_SET_ALERTS_SETTINGS_LIST: "AN_SET_ALERTS_SETTINGS_LIST",
  AN_SET_ALERTS_SUBSCRIPTION: "AN_SET_ALERTS_SUBSCRIPTION",
  AN_SET_ALERT_DETAILS: "AN_SET_ALERT_DETAILS",
  AN_SET_ALERT_FILTER_APPLIED_FIELD: "AN_SET_ALERT_FILTER_APPLIED_FIELD",
  AN_SET_ALERT_FILTER_FIELD: "AN_SET_ALERT_FILTER_FIELD",
  AN_SET_ALERT_GROUPS: "AN_SET_ALERT_GROUPS",
  AN_SET_ALERT_VIEW: "AN_SET_ALERT_VIEW",
  AN_TOGGLE_ALERT_LIST: "AN_TOGGLE_ALERT_LIST",
  AN_QUICK_TRANSFER_ALERT: "AN_QUICK_TRANSFER_ALERT",
  AN_UPDATE_ALERT_USER_SETTINGS: "AN_UPDATE_ALERT_USER_SETTINGS",
  CHECK_UNCHECK_USER_ALERT_SETTING: "CHECK_UNCHECK_USER_ALERT_SETTING",
  CLEAR_ALERT_DATA: "CLEAR_ALERT_DATA",
  CLEAR_ALERT_DETAIL_DATA: "CLEAR_ALERT_DETAIL_DATA",
  CLEAR_ALERT_NOTIFICATION: "CLEAR_ALERT_NOTIFICATION",
  CLEAR_ALERT_SETTINGS: "CLEAR_ALERT_SETTINGS",
  DELETE_QUICK_ACTION_POPUP_PERMISSION: "DELETE_QUICK_ACTION_POPUP_PERMISSION",
  CLEAR_QUICK_ACTION_POPUP_PERMISSION_LIST: "CLEAR_QUICK_ACTION_POPUP_PERMISSION_LIST",
  CLEAR_ALL_ALERTS_SELECTION: "CLEAR_ALL_ALERTS_SELECTION",
  GET_ALERT_LIST_FOR_SEARCH: "GET_ALERT_LIST_FOR_SEARCH",
  GET_GATEWAY_ASSETS_COUNT: "GET_GATEWAY_ASSETS_COUNT",
  GET_SUGGESTED_TRANSFER_COUNT: "GET_SUGGESTED_TRANSFER_COUNT",
  GET_SUGGESTED_TRANSFER_COUNT_SUCCESS: "GET_SUGGESTED_TRANSFER_COUNT_SUCCESS",
  RECEIVE_NOTIFICATIONS_ALERT_SETTINGS: "RECEIVE_NOTIFICATIONS_ALERT_SETTINGS",
  RESPONSIBILITY_DESELECTED: "RESPONSIBILITY_DESELECTED",
  RESPONSIBILITY_SELECTED: "RESPONSIBILITY_SELECTED",
  ROW_DISABLED: "ROW_DISABLED",
  SELECT_EMAIL_SETTING: "SELECT_EMAIL_SETTING",
  SET_ALERT_FILTER_QUERY: "SET_ALERT_FILTER_QUERY",
  SET_ALERT_SEARCH_QUERY: "SET_ALERT_SEARCH_QUERY",
  SET_GATEWAY_ASSETS_COUNT: "SET_GATEWAY_ASSETS_COUNT",
  SET_ASSET_TO_OPERATIONAL_AND_DISMISS_ALERT: "SET_ASSET_TO_OPERATIONAL_AND_DISMISS_ALERT",
  REQUEST_HOL_REDIRECT: "REQUEST_HOL_REDIRECT",
};

export default AlertNotificationConstant;
