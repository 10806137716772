import React from "react";
import styled from "styled-components";
import FooterButton from "./footerButton/footerButton";
import IFooterProps from "./iFooter";

/**
 * FooterGrid css for the footer dimension.
 */
const FooterWrapper = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.base.white};
  border-top: 4px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  display: flex;
  z-index: 1;
`;

/**
 * Grid for the footer.
 */

const StyledFooter = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 992px;
  margin: 0 auto;
  padding: 6px 0;
  position: relative;
  & button:only-child {
    margin-left: auto;
  }
`;
const CompleteButtonTooltip = styled.div`
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.black};
  position: absolute;
  min-width: 275px;
  margin-top: -50px;
  right: -77px;
  padding: 16px;
  font-size: ${(props) => props.theme.fontSize.small};
  top: -52px;
  :after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border: 6px solid transparent;
    border-top: 6px solid ${(props) => props.theme.colors.base.black};
    z-index: 9;
    margin-right: 206px;
  }
`;

const Footermsg = styled.span`
  color: ${(props) => props.theme.colors.base.hiltiRedError};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.small};
  margin-right: 16px;
`;
/**
 * Footer component is holding the footer button component.
 */
class Footer extends React.PureComponent<IFooterProps> {
  static defaultProps = {
    shouldRenderChildren: false,
  };
  render() {
    const {
      buttonText,
      buttonType,
      buttonPrevText,
      buttonPrevType,
      children,
      message,
      shouldRenderChildren,
      tooltipMessage,
      id,
    } = this.props;
    return (
      <FooterWrapper>
        <StyledFooter className="no-fixed" id="footerDiv">
          <>
            {buttonPrevText && (
              <FooterButton
                {...this.props}
                onClick={this.props.clickToPrev}
                buttonType={buttonPrevType ? buttonPrevType : buttonType}
                buttonText={buttonPrevText}
                id={"previousButton"}
              />
            )}

            {message ? <Footermsg>{message}</Footermsg> : null}
            {shouldRenderChildren ? (
              children
            ) : (
              <FooterButton
                onClick={this.props.onClick}
                buttonType={buttonType}
                buttonText={buttonText}
                id={id || "forwardButton"}
                {...this.props}
              />
            )}
            {tooltipMessage ? (
              <CompleteButtonTooltip id="mergeCompleteTooltip">{tooltipMessage}</CompleteButtonTooltip>
            ) : null}
          </>
        </StyledFooter>
      </FooterWrapper>
    );
  }
}

export default Footer;
