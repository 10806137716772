import React from "react";
import { I18nextProvider } from "react-i18next";
import initI18Next from "../localization/initI18next";
import { ITranslate } from "./iTranslate";

const translate = (ComposedComponent) =>
  class extends React.Component<ITranslate> {
    i18n: any;
    constructor(props) {
      super(props);
      this.i18n = initI18Next;
    }
    /**
     * @description - Method componentDidUpdate is invoked immediately after updating occurs.
     * @param - prevProps is previous value of language code
     */
    componentDidUpdate(prevProps) {
      const { languageCode } = this.props;
      if (languageCode !== prevProps.languageCode) {
        this.i18n.changeLanguage(languageCode);
      }
    }

    render() {
      return (
        <I18nextProvider i18n={this.i18n}>
          <ComposedComponent {...this.props} t={(val) => this.i18n.t(val)} />
        </I18nextProvider>
      );
    }
  };

export default translate;
