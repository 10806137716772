/**
 * @description This file is for api hitting using
 * axios for the logout web UI user.
 */
import Gateway, { PutParams } from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import UserMeModel from "../models/userMeModel";
import UserProfileModel from "../models/userProfileModel";
import { getFleetMgmtEndpoint, getUserMgmtEndpoint } from "../utils/apiUtils";

export type MasterContract = {
  isFleetContract: boolean;
};

/**
 * @description Function to get User Profile
 * @memberof HeaderModule
 */
export const userProfileDetail = async (): Promise<UserProfileModel | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint("/users/me/profile"), null, null, null, false) as unknown as UserProfileModel;
};

/**
 * @description Function to get User Me data
 * @memberof HeaderModule
 */
export const userMeDetail = async (): Promise<UserMeModel | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint("/users/me"), null, null, null, false) as unknown as UserMeModel;
};

/**
 * @description Function to get User Me data
 * @memberof HeaderModule
 */
export const resetPassword = async (passwordData): Promise<any> => {
  return Gateway.postUnAuthorize("/users/changePassword", null, passwordData, null);
};

export const acceptTncResponse = async (data): Promise<any | IErrorResponse> => {
  return Gateway.patch(getUserMgmtEndpoint("/employees/tncs"), null, data, null, false) as unknown as UserMeModel;
};

export const patchUserAcceptanceFlag = async (extWarningFlag: boolean): Promise<any | IErrorResponse> => {
  return Gateway.patch(getUserMgmtEndpoint("/users/external-entity-warning"), null, { extWarningFlag }, null, false);
};

export const acceptLiceseAgreement = async (): Promise<unknown | IErrorResponse> => {
  return Gateway.patch(getUserMgmtEndpoint("/users/legal-agreement/confirm"), null, {}, null, false);
};

export const hasFleetContract = async (
  originCustomerId: number,
  moCountry: string,
): Promise<MasterContract | IErrorResponse> => {
  const url = getFleetMgmtEndpoint(`/master-contracts?origin_sold_to=${originCustomerId}&country=${moCountry}`);
  const params: PutParams = [url, null, null, null];
  return Gateway.get<{ _links: { fleetmanagement_master_contracts: [] } }>(...params).then((data) => {
    if ("_links" in data) {
      return {
        isFleetContract: !!data._links?.fleetmanagement_master_contracts?.length,
      };
    }
    return data;
  });
};
