/**
 * @description Action constants to be used for common module and its feature
 *
 * format: {feature}_{action}
 */
const TransferCartContants = {
  ADD_BY_SCANCODE_INVENTORY_NUMBER_FAILED: "ADD_BY_SCANCODE_INVENTORY_NUMBER_FAILED",
  AM_ERROR_CAPTURED: "AM_ERROR_CAPTURED",
  AM_GET_STORAGE_ASSET_LIST: "AM_GET_STORAGE_ASSET_LIST",
  AM_GET_STORAGE_ASSET_LIST_SUCCESS: "AM_GET_STORAGE_ASSET_LIST_SUCCESS",
  AM_PARTIAL_TRANSFERED_ASSETS: "AM_PARTIAL_TRANSFERED_ASSETS",
  AM_PARTIAL_TRANSFERED_ASSETS_CLEAR_RESPONSE: "AM_PARTIAL_TRANSFERED_ASSETS_CLEAR_RESPONSE",
  AM_REMOVE_ALL_TRANSFER_CART_ITEMS: "AM_REMOVE_ALL_TRANSFER_CART_ITEMS",
  AM_REMOVE_TRANSFER_CART_ITEM: "AM_REMOVE_TRANSFER_CART_ITEM",
  AM_SET_TRANSFER_CART_LOADED: "AM_SET_TRANSFER_CART_LOADED",
  AM_SET_TRASNFER_CART_ITEMS: "AM_SET_TRASNFER_CART_ITEMS", // set transfer cart items in store
  AM_SET_TRASNFER_CART_ITEM_STORE: "AM_SET_TRASNFER_CART_ITEM_STORE",
  AM_SET_TRASNFER_CART_ID: "AM_SET_TRASNFER_CART_ID",
  AM_SUBMIT_QUICK_TRANSFER: "AM_SUBMIT_QUICK_TRANSFER",
  AM_SUBMIT_TRANSFER: "AM_SUBMIT_TRANSFER",
  AM_UPDATE_TRANSFER_CART_LIST: "AM_UPDATE_TRANSFER_CART_LIST",
  CHANGE_TRANSFER_CART_LIST_NAME: "CHANGE_TRANSFER_CART_LIST_NAME",
  CLEAR_ALL_STORAGE_ASSETS_SELECTION: "CLEAR_ALL_STORAGE_ASSETS_SELECTION",
  CLEAR_LOCATION_GROUP_TRANSFER: "CLEAR_LOCATION_GROUP_TRANSFER",
  FETCH_ASSET_VIA_SCANCODE_OR_INVENTORYCODE: "FETCH_ASSET_VIA_SCANCODE_OR_INVENTORYCODE",
  GET_TRANSFER_CART_ASSET_LIST_FOR_SEARCH: "GET_TRANSFER_CART_ASSET_LIST_FOR_SEARCH",
  GET_TRANSFER_DATE: "GET_TRANSFER_DATE",
  NULLIFY_ERRORS: "NULLIFY_ERRORS",
  PUT_LOCATION_BY_ID_TRANSFER: "PUT_LOCATION_BY_ID_TRANSFER",
  PUT_TRANSFER_LOCATION_GROUPS: "PUT_TRANSFER_LOCATION_GROUPS",
  SEARCH_STRING_REDUX_RESET: "SEARCH_STRING_REDUX_RESET",
  SELECT_ALL_IN_TRANSFER_LIST: "SELECT_ALL_IN_TRANSFER_LIST",
  SET_LOCATION_GROUPS_LIST: "SET_LOCATION_GROUPS_LIST",
  SET_LOCATION_GROUPS_SELECTED_ITEMS: "SET_LOCATION_GROUPS_SELECTED_ITEMS",
  SET_LOCATION_GROUP_DETAILS: "SET_LOCATION_GROUP_DETAILS",
  SET_SELECTED_LOCATION_GROUP_ID_TRANSFER: "SET_SELECTED_LOCATION_GROUP_ID_TRANSFER",
  SET_TRANSFER_CART_SEARCH_QUERY: "SET_TRANSFER_CART_SEARCH_QUERY",
  SET_TRANSFER_DATE: "SET_TRANSFER_DATE",
  SET_TRANSFER_LIST: "SET_TRANSFER_LIST",
  STORAGE_ASSET_SELECT_ROW: "STORAGE_ASSET_SELECT_ROW",
  STORAGE_ASSET_UNSELECT_ROW: "STORAGE_ASSET_UNSELECT_ROW",
  SUBMIT_QUICK_TRANSFER_RESPONSE: "SUBMIT_QUICK_TRANSFER_RESPONSE",
  TOGGLE_TRANSFER_CART_CHECKBOX: "TOGGLE_TRANSFER_CART_CHECKBOX",
  TRANSFER_CHECKED_ROW: "TRANSFER_CHECKED_ROW",
  TRANSFER_CLEAR_ALL_ROWS_SELECTION: "TRANSFER_CLEAR_ALL_ROWS_SELECTION",
  TRANSFER_DIALOG_CLEAR_ALL_STATE: "TRANSFER_DIALOG_CLEAR_ALL_STATE",
  TRANSFER_SELECT_ALL: "TRANSFER_SELECT_ALL",
  TRANSFER_SET_RETURN_DATE_STATUS: "TRANSFER_SET_RETURN_DATE_STATUS",
  TRANSFER_SET_TRANSFER_DATE: "TRANSFER_SET_TRANSFER_DATE",
  TRANSFER_STORAGE_ASSET_LOADER_END: "TRANSFER_STORAGE_ASSET_LOADER_END",
  TRANSFER_STORAGE_ASSET_LOADER_START: "TRANSFER_STORAGE_ASSET_LOADER_START",
  TRANSFER_SUBMIT_CART_RESPONSE: "TRANSFER_SUBMIT_CART_RESPONSE",
  TRANSFER_UNCHECKED_ROW: "TRANSFER_UNCHECKED_ROW",
  UPDATE_ADD_QTY_ITEM_BY_SCANCODE_RESPONSE: "UPDATE_ADD_QTY_ITEM_BY_SCANCODE_RESPONSE",
  SET_QTY_ITEM_PRESENT: "SET_QTY_ITEM-PRESENT",
  ITEM_ADDED_TO_TRANSFERCART: "Item added into TransferCart to Transfer.",
  QTY_QUICK_TRANSFER_STARTED: "QTY Item Quick Transfer started.",
  QTY_TRANSFER_STEP1_TO_RESPONSIBLE_WORKER: "Step1: Assign To responsibleWorker",
  QTY_TRANSFER_STEP1_TO_STORAGE_ASSET: "Step1: Assign To Storage asset",
  QTY_TRANSFER_STEP1_TO_LOCATION: "Step1: Assign To Location",
  QTY_ITEM_REMOVED: "Transfer cart Item has been removed",
  TRANSFERCART_TRANSFER_CLICKED: "TransferCart Transfer Button clicked",
  QTY_TRANSFER_STEP4_CONFIRM: "Step4: Confirm Transfer",
  QTY_TRANSFER_STEP2_ASSIGN_TO_EMPLOYEE: "Step2: Assign to Employee",
  QTY_TRANSFER_STEP3_SET_DATES: "Step3: Set Dates",
  QTY_TRANSFER_SUCCESS: "All items have been transferred successfully with status code 201",
  QTY_TRANSFER_PARTIAL_SUCCESS: "Not all items have been transfered with status code 207",
  QTY_QUICK_TRANSFER_SUCCESS: "Quick transfer success.",
  DUPLICATE_TRANSFER_DETECTED: "Duplicate transfer detected",
  EDIT_TRANSFERCART_INFO: "Edit transfercart info",
  TOGGLE_DUPLICATE_TRANSFER_CONFIRM: "TOGGLE_DUPLICATE_TRANSFER_CONFIRM",
  TOGGLE_CONFIRM_TRANSFER: "TOGGLE_CONFIRM_TRANSFER",
};

export default TransferCartContants;
