import { CellTypes, IGridColumnSetting } from "am-web-ui-shared/components";
export const transferHistoryGridSettings: IGridColumnSetting[] = [
  {
    bindProperties: [],
    cellSize: 12,
    component: "customGridCard",
    isSortable: false,
    type: CellTypes.Custom,
  },
];
