import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M20.5,14.5 L22.5,16.5 L20.5,18.5 L22.5,20.5 L20.5,22.5 L18.5,20.5 L16.5,22.5 L14.5,20.5 L16.5,18.5 L14.5,16.5 L16.5,14.5 L18.5,16.5 L20.5,14.5 Z M12.6213203,19.9906248 C12.6213203,21.0461037 11.6213203,21.9778014 10.0606602,21.999633 C8.56502751,22.0205548 7.5843067,21.1440115 7.50517655,20.1419649 L7.5,20.0106149 L12.6213203,19.9906248 Z M11.5,1 L11.5,2.99901313 C14.5594872,3.63476082 16.8784615,6.22373858 16.9953718,9.32952514 L17,9.57576634 L17,11.383 L11.8964463,16.4849961 L13.404,17.991 L1,17.9916116 L1,14.9930919 L3,14.9930919 L3,9.57576634 C3,6.44377407 5.24052597,3.79079004 8.26002664,3.05323552 L8.5,2.99901313 L8.5,1 L11.5,1 Z" />
    </g>
  </svg>
);

export default SVG;
