// @ts-ignore
import { SortList } from "TARGET_BUILD/config";
import TemplateModel from "../../../models/template/templateModel";
import { createReducer, deepCloneArray, setFilterAttributesToReducer, updateArray } from "../../../utils/commonUtils";
import {
  appendSelectedPropertyToList,
  clearAllCheckedRows,
  setAllCheckedRows,
  setCheckboxSelection,
  setRowSelection,
  setCheckboxWithRowSelection,
} from "../../../utils/list/listUtils";
import { rowActionType } from "../../../utils/list/list";

const DEFAULT_SORT = SortList.ASSET_TEMPLATE.DEFAULT_SORT;

/**
 * @description - Setting and passing the initial state of the reducer so that it will not throw the object reference "undefined" for the first time it loads the component.
 */
const assetTemplatesInitialState = {
  appliedFilterQuery: [],
  assetTemplatesList: {
    list: null,
    totalRecords: null,
  },
  filterQuery: null,
  isAllSelected: false,
  loader: {
    isDetailsLoading: false,
    isListLoading: false,
  },
  modelsList: [],
  searchString: "",
  selectedAssetTemplateDetail: null,
  showNoRecordFoundView: false,
  sortQuery: {
    sortField: "templateName",
    sortType: "+",
  },
  templateData: {},
  templateDeleteResponse: null,
};
function getList(templateInfo: TemplateModel, assetTemplatesList: any) {
  const list = assetTemplatesList.slice();
  return updateArray(list, { id: templateInfo.id }, templateInfo);
}

/**
 * @description Function returns whether result set is empty for searched query.
 * @param {any} state - assetTemplate state.
 * @param {any} action - action object from dispatcher
 */
const isSearchedResultsEmpty = (state, action) => {
  return (state.searchString || state.filterQuery) && !action.payload.assetTemplatessData.totalRecords;
};

const getAddTemplateId = (action) => {
  const { assetTemplatessData } = action.payload;
  return assetTemplatessData?.list?.length > 0 && assetTemplatessData.list[0].id;
};

const getSelectedId = (action) => {
  return action.payload && action.payload.selectFirstRow && getAddTemplateId(action);
};

/**
 * @description - Get the list of templates and send it back to the container.
 * This function that is used on the switch  of getting the asset templates list.
 */
const putAssetTemplates = (state, action) => {
  const isAllSelected = !!(state.isAllSelected && action.payload.offset);
  if (action.payload.assetTemplatessData) {
    const oldResponse = action.payload.offset && action.payload.offset > 0 ? state.assetTemplatesList.list : [];
    action.payload.assetTemplatessData.list = [
      ...oldResponse,
      ...appendSelectedPropertyToList(action.payload.assetTemplatessData.response, isAllSelected),
    ];
    delete action.payload.assetTemplatessData.response;
    const selectRowList =
      action.payload.assetTemplatessData &&
      action.payload.assetTemplatessData.list &&
      action.payload.assetTemplatessData.list.slice(0);
    const selectedId = getSelectedId(action);
    return {
      ...state,
      assetTemplatesList: {
        ...action.payload.assetTemplatessData,
        offset: action.payload.offset,
        list: selectedId
          ? setCheckboxSelection(setRowSelection(selectRowList, selectedId, true, "id"), selectedId, true, "id")
          : action.payload.assetTemplatessData.list,
      },
      isAllSelected,
      showNoRecordFoundView: isSearchedResultsEmpty(state, action),
      totalRecords: action.payload.assetTemplatessData.totalRecords,
    };
  } else {
    return {
      ...state,
    };
  }
};

/**
 * @description - Get the detail of the templates and send it back to the container.
 * This function that is used on the switch  of getting the asset templates list.
 */
const getTemplateInfoDetails = (state, action) => {
  return {
    ...state,
    resetScrollPosition: false,
    selectedAssetTemplateDetail: action.payload,
  };
};

/**
 * Function to put the template's assetList and handle PUT_TEMPLATE_ASSET_LIST action type
 */
const putTemplateAssetList = (state, action) => {
  return {
    ...state,
    assetList: action.payload,
  };
};

/**
 * @description clear asset list of template in store and handle CLEAR_TEMPLATE_ASSET_LIST action type
 * @param state : current store state
 */
const clearTemplateAssetList = (state) => {
  return {
    ...state,
    assetList: null,
  };
};

/**
 * @description - remove detail of the templates from store and handle REMOVE_TEMPLATE_DETAILS action type
 */
const removeTemplateDetails = (state) => {
  return {
    ...state,
    deleteAssetTemplateStatus: null,
    resetScrollPosition: false,
    selectedAssetTemplateDetail: null,
  };
};

/**
 * @description - Get the selected template row and and handle ASSET_TEMPLATES_SET_SELECTED_ROW action type
 *
 */
const setAssetTemplateSelectedRow = (state, action) => {
  return {
    ...state,
    selectedRowId: action.rowId,
  };
};
/**
 * @description - Refresh the templates list and handle REFRESH_TEMPLATES_LIST action type
 */
const refreshTemplatesList = (state, action) => {
  return {
    ...state,
    assetTemplatesList: {
      ...state.assetTemplatesList,
      response: getList(action.payload, state.assetTemplatesList.response),
      totalRecords: state.assetTemplatesList.totalRecords + 1,
    },
  };
};
/**
 * @description - Search the templates list and handle SEARCH_TEMPLATES_LIST action type
 */
const getTemplateSearchList = (state, action) => {
  return {
    ...state,
    templateSearchList: action.payload.response,
  };
};
/**
 * @description - To clear asset template delete flag and handle CLEAR_ASSET_TEMPLATE_DELETE_FLAG action type
 */
const clearAssetTemplateDeleteFlag = (state) => {
  return {
    ...state,
    deleteAssetTemplateStatus: null,
  };
};

/**
 * @description - To clear asset template list and handle CLEAR_TEMPLATES_LIST action type
 */
const clearAssetTemplatesList = (state) => {
  return {
    ...state,
    assetTemplatesList: {
      list: [],
      totalRecords: 0,
    },
    filterQuery: null,
    searchString: "",
  };
};

/**
 * @description - To delete asset template and handle DELETE_ASSET_TEMPLATE_SUCCESS action type
 */
const deleteAssetTemplateSuccess = (state, action) => {
  return {
    ...state,
    templateDeleteResponse: action.payload,
  };
};

/**
 * @description Function to start the loader and handle AM_ASSETTEMPLATE_LIST_LOADER_START action type
 */
const getLoaderStart = (state) => {
  return {
    ...state,
    loader: {
      isDetailsLoading: false,
      isListLoading: true,
    },
  };
};

/**
 * @description Function to end the loader and handle AM_ASSETTEMPLATE_LIST_LOADER_END action type
 */
const getLoaderEnd = (state) => {
  return {
    ...state,
    loader: {
      isDetailsLoading: false,
      isListLoading: false,
    },
  };
};

/**
 * @description Function to reset the asset templates query parameters and handle AM_ASSET_TEMPLATES_RESET_QUERY_PARAM action type
 */
const resetAssetTemplatesQueryParam = (state) => {
  return {
    ...state,
    filterQuery: null,
    searchString: "",
    sortQuery: {
      sortField: DEFAULT_SORT.name,
      sortType: DEFAULT_SORT.sortType,
    },
  };
};

/**
 * @description Function to select the asset templates row and handle AM_ASSET_TEMPLATES_SELECT_ROW action type
 */
const selectAssetTemplatesRow = (state, action) => {
  const selectRowList =
    state.assetTemplatesList && state.assetTemplatesList.list && state.assetTemplatesList.list.slice(0);

  return {
    ...state,
    assetTemplatesList: {
      ...state.assetTemplatesList,
      list: setCheckboxWithRowSelection(selectRowList, action.item, true, "id"),
    },
  };
};

/**
 * @description Function to unselect the asset templates row and handle AM_ASSET_TEMPLATES_UNSELECT_ROW action type
 */
const unselectAssetTemplatesRow = (state, action) => {
  const unSelectedList = state.assetTemplatesList.list.slice(0);
  return {
    ...state,
    assetTemplatesList: {
      ...state.assetTemplatesList,
      list: setCheckboxWithRowSelection(unSelectedList, action.item, false, "id"),
    },
    selectedAssetTemplateDetail: null,
  };
};

/**
 * @description Function to select and unselect asset template and handle TEMPLATE_SELECT_GRID_CHECKBOX,TEMPLATE_UNSELECT_GRID_CHECKBOX action type
 */
const handleGridCheckboxSelection = (state, action) => {
  const selectRowList = deepCloneArray(state.assetTemplatesList && state.assetTemplatesList.list);
  return {
    ...state,
    assetTemplatesList: {
      ...state.assetTemplatesList,
      list: setCheckboxWithRowSelection(selectRowList, action.item, action.value, "id", rowActionType.checked),
    },
  };
};

/**
 * @description - To clear the selection of asset templates and handle ASSET_TEMPLATES_UNSELECT_ALL_ROWS
 */
const clearAllSelectedRows = (state) => {
  return {
    ...state,
    isAllSelected: false,
    workersList: {
      ...state.assetTemplatesList,
      list: clearAllCheckedRows(state.assetTemplatesList.list, true),
    },
  };
};

/**
 * @description - To select all asset templates and handle ASSET_TEMPLATES_SELECT_ALL_ROWS action type
 */
const selectAllAssetTemplates = (state) => {
  return {
    ...state,
    assetTemplatesList: {
      ...state.assetTemplatesList,
      list: setAllCheckedRows(state.assetTemplatesList.list, true),
    },
    isAllSelected: true,
  };
};

/**
 * @description Function updates the state object to the sort query passed in the action.
 * @param {any} state - assetTemplate state.
 * @param {any} action - action object from dispatcher
 */
const setSortQuery = (state, action) => {
  return {
    ...state,
    sortQuery: action.sortQuery,
  };
};

/**
 * @description Function updates the state object with search string recieved.
 * @param {any} state - assetTemplate state.
 * @param {any} action - action object from dispatcher
 */
const setSearchQuery = (state, action) => {
  return {
    ...state,
    searchString: action.searchQuery,
  };
};
/**
 * @description Function updates the possible asset filters and handle ASSET_TEMPLATE_FILTER_FIELD_SUCCESS action type
 * @param {object} state - asset state
 * @param action
 */
const setFilterAttributes = (state, action) => {
  const { filterAttributes, filterAttributesCount, filterAttributesSearchCount } = setFilterAttributesToReducer(
    state,
    action,
  );

  return {
    ...state,
    filterAttributes,
    filterAttributesCount,
    filterAttributesSearchCount,
  };
};
/**
 * @description Function updates the filter query and handles SET_TEMPLATE_FILTER_QUERY action type
 */
const setTemplateFilterQuery = (state, action) => {
  return {
    ...state,
    filterQuery: action.filterQuery,
  };
};

/**
 * @description Function updates the template's addEditResponse and handle TEMPLATE_ADD_EDIT_RESPONSE action type
 */
const setTemplateAddEditResponse = (state, action) => {
  return { ...state, addEditResponse: action.addEditResponse };
};

/**
 * @description Function updates the template's addEditResponse and handle TEMPLATE_ADD_EDIT_RESPONSE action type
 */

const cleanTemplateData = (state) => {
  // CLEAN_TEMPLATE_DATA
  return { ...state, addEditResponse: null };
};

/**
 * @description Function to set the template details and handle PUT_TEMPLATE_DETAILS action type
 */
const putTemplateDetails = (state, action) => {
  return {
    ...state,
    templateData: action.payload,
  };
};

/**
 * @description Function to clear the template details and handle CLEAR_ASSET_TEMPLATE_DETAILS action type
 */

const clearAssetTemplateDetails = () => {
  return {
    ...assetTemplatesInitialState,
  };
};

/**
 * @description Function to set the selected template's info and handle AM_SET_SELECTED_ASSETTEMPLATES_INFO action type
 */
const setSelectedAssetTemplatesInfo = (state, action) => {
  return {
    ...state,
    assetTemplatesInfo: action.payload,
  };
};

/**
 * @description Function to merge the asset templates and handle AM_MERGE_ASSETTEMPLATES_RESPONSE action type
 */
const mergeAssetTemplatesResponse = (state, action) => {
  return {
    ...state,
    mergedAssetTemplates: action.payload,
  };
};
/**
 * @description Function to set the models list and handle SET_MODELS_LIST action type
 */
const setModelsList = (state, action) => {
  return {
    ...state,
    modelsList: action.payload,
  };
};

/**
 * @description Function to reset templateDeleteResponse and handle RESET_MULTI_DELETE_ASSET_TEMPLATE_RESPONSE action type
 */
const resetMultiDeleteAssetTemplateResponse = (state) => {
  return {
    ...state,
    templateDeleteResponse: undefined,
  };
};

const setAssetFilterAppliedAttributes = (state, action) => ({
  ...state,
  appliedFilterQuery: action.payload && action.payload.filterQuery,
});

const assetTemplatesReducer = createReducer(assetTemplatesInitialState, {
  AM_ASSETTEMPLATE_LIST_LOADER_END: getLoaderEnd,
  AM_ASSETTEMPLATE_LIST_LOADER_START: getLoaderStart,
  AM_ASSET_TEMPLATES_RESET_QUERY_PARAM: resetAssetTemplatesQueryParam,
  AM_ASSET_TEMPLATES_SELECT_ROW: selectAssetTemplatesRow,
  AM_ASSET_TEMPLATES_UNSELECT_ROW: unselectAssetTemplatesRow,
  AM_MERGE_ASSETTEMPLATES_RESPONSE: mergeAssetTemplatesResponse,
  AM_PUT_ASSET_TEMPLATES: putAssetTemplates,
  AM_SET_ASSET_TEMPLATES_FILTER_APPLIED_FIELD: setAssetFilterAppliedAttributes,
  AM_SET_ASSET_TEMPLATES_SEARCH_QUERY: setSearchQuery,
  AM_SET_ASSET_TEMPLATES_SORT_QUERY: setSortQuery,
  AM_SET_SELECTED_ASSETTEMPLATES_INFO: setSelectedAssetTemplatesInfo,
  ASSET_TEMPLATES_SELECT_ALL_ROWS: selectAllAssetTemplates,
  ASSET_TEMPLATES_SET_SELECTED_ROW: setAssetTemplateSelectedRow,
  ASSET_TEMPLATES_UNSELECT_ALL_ROWS: clearAllSelectedRows,
  ASSET_TEMPLATE_FILTER_FIELD_SUCCESS: setFilterAttributes,
  ASSET_TEMPLATE_CLEAN_TEMPLATE_DATA: cleanTemplateData,
  CLEAR_ASSET_TEMPLATE_DELETE_FLAG: clearAssetTemplateDeleteFlag,
  CLEAR_ASSET_TEMPLATE_DETAILS: clearAssetTemplateDetails,
  CLEAR_TEMPLATES_LIST: clearAssetTemplatesList,
  CLEAR_TEMPLATE_ASSET_LIST: clearTemplateAssetList,
  DELETE_ASSET_TEMPLATE_SUCCESS: deleteAssetTemplateSuccess,
  GET_TEMPLATE_INFO_DETAILS: getTemplateInfoDetails,
  PUT_TEMPLATE_ASSET_LIST: putTemplateAssetList,
  PUT_TEMPLATE_DETAILS: putTemplateDetails,
  REFRESH_TEMPLATES_LIST: refreshTemplatesList,
  REMOVE_TEMPLATE_DETAILS: removeTemplateDetails,
  RESET_MULTI_DELETE_ASSET_TEMPLATE_RESPONSE: resetMultiDeleteAssetTemplateResponse,
  SEARCH_TEMPLATES_LIST: getTemplateSearchList,
  SET_MODELS_LIST: setModelsList,
  SET_TEMPLATE_FILTER_QUERY: setTemplateFilterQuery,
  TEMPLATE_ADD_EDIT_RESPONSE: setTemplateAddEditResponse,
  TEMPLATE_SELECT_GRID_CHECKBOX: handleGridCheckboxSelection,
  TEMPLATE_UNSELECT_GRID_CHECKBOX: handleGridCheckboxSelection,
});
/**
 * @exports assetTemplatesListReducer - Exporting the reducer associated with the asset templates list.
 */
export default assetTemplatesReducer;
