import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 24px;
  position: relative;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.base.heavyConcrete20};
  height: 3px;
`;

const HeaderValue = styled.span.attrs({
  id: "headingName",
})`
  position: absolute;
  color: #979598;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 17px;
  background: #fff;
  padding-right: 8px;
  display: inline;
  top: 17px;
`;

interface IHeaderLine {
  readonly value?: string;
}

class HeaderWithLine extends React.PureComponent<IHeaderLine> {
  render() {
    const { value } = this.props;
    return (
      <Wrapper>
        <Line />
        <HeaderValue>{value}</HeaderValue>
      </Wrapper>
    );
  }
}

export default HeaderWithLine;
