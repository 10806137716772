import React, { useState } from "react";
import styled from "styled-components";
import { SquareIcon } from "../icons/iconWrapper";
import IDataListItemProps from "./iDataListItemProps";
import IMenuItemProps from "./iMenuItemProps";
import Tooltip from "@hilti/components/core/tooltip";

const menuListLeft = (props: any) => `calc(${props.left}px - 32px)`;
export const MenuList = styled.ul.attrs((props) => ({
  id: "sortMenuList",
  left: (props as any).left,
}))`
  position: absolute;
  z-index: 99;
  left: ${menuListLeft};
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow:
    0 2px 12px 0 ${(props) => props.theme.colors.base.greyrgba},
    inset 0 0 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
  min-width: 270px;
  color: ${(props) => props.theme.colors.base.black};
  &.show-updated li:first-child:before {
    content: " ";
    position: absolute;
    left: 30px;
    top: 7px;
    width: 5px;
    height: 5px;
    background-color: ${(props) => props.theme.colors.base.hiltiRed};
    border-radius: 5px;
  }
  & > li {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 11px;
    padding-top: 11px;
    font-size: ${(props) => props.theme.fontSize.medium};
    font-weight: 300;
    line-height: 19px;
    min-height: 48px;
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    &[data-selected="true"] {
      background-color: ${(props) => props.theme.sort.selected};
      font-family: ${(props) => props.theme.fontBold.fontFamily};
      font-weight: ${(props) => props.theme.fontBold.fontWeight};
    }
    &.cursor-pointer {
      cursor: pointer;
    }
    &.cursor-default {
      cursor: default;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
    }
    &.disabled {
      color: ${(props) => props.theme.colors.base.steel20};
      background-color: ${(props) => props.theme.colors.base.transparent};
    }
    &.disabled svg path,
    &.disabled svg polygon {
      fill: ${(props) => props.theme.colors.base.steel20};
    }
    &.sub-menu-parent {
      position: relative;
    }
    .menu-list-content {
      word-break: break-all;
      flex: 1;
      white-space: normal;
    }
  }
  & li:last-child {
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
  }
  &.subMenu {
    left: 360px;
    width: 360px;
    top: 0;
    & li:last-child {
      border-top: none;
    }
  }
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ListImg = styled.span`
  display: inline-flex;
  flex-basis: 24px;
  margin-right: 16px;
  img {
    width: 16px;
  }
`;
const ListLabel = styled.span`
  word-break: break-word;
  flex: 1;
  white-space: normal;
`;
const SuffixImg = styled.span`
  align-self: flex-end;
`;

/**
 * @description this component is used to show the menu items
 *
 */
export const MenuItem = ({
  onSelectChange,
  selectedField,
  className,
  isCustomViewUpdated,
  left,
  dataList,
}: IMenuItemProps) => {
  const handleSelectOption = (data) => onSelectChange && onSelectChange(data);

  const [index, setIndex] = useState(null);

  const RenderItemWithIcon = ({ data, hasTooltip = false }: { data: IDataListItemProps; hasTooltip?: boolean }) => {
    return (
      <Tooltip title={hasTooltip ? data.message : ""} placement="bottom">
        <ListWrapper>
          {data.image ? (
            <ListImg>
              <SquareIcon name={data.image} />
            </ListImg>
          ) : null}
          <ListLabel dangerouslySetInnerHTML={{ __html: data.label }} />
          {data.suffixImage ? (
            <SuffixImg>
              <SquareIcon name={data.suffixImage} />
            </SuffixImg>
          ) : null}
        </ListWrapper>
      </Tooltip>
    );
  };

  const RenderWithTooltip = ({ data }: { data: IDataListItemProps }) => {
    return (
      <li
        className={`disabled ${data.subMenu ? "sub-menu-parent" : ""}`}
        id={data.value}
        data-selected={selectedField === data.value}
      >
        {data.image || data.suffixImage ? (
          <RenderItemWithIcon data={data} hasTooltip={true} />
        ) : (
          <Tooltip title={data.message} placement="bottom">
            <div>{data.label}</div>
          </Tooltip>
        )}
      </li>
    );
  };

  const RenderWithoutTooltip = ({
    data,
    children,
    itemIndex,
  }: {
    data: IDataListItemProps;
    children?: React.JSX.Element;
    itemIndex?: number;
  }) => {
    return (
      <li
        className={`${data.notActionable ? "cursor-default" : "cursor-pointer"}  ${
          data.subMenu ? "sub-menu-parent" : ""
        }`}
        id={data.value}
        onClick={data.notActionable ? null : () => handleSelectOption(data)}
        data-selected={selectedField === data.value}
        onMouseEnter={() => data.subMenu && setIndex(itemIndex)}
        onMouseLeave={() => data.subMenu && setIndex(null)}
      >
        {data.image || data.suffixImage ? <RenderItemWithIcon data={data} /> : data.label}
        {data.subMenu && children}
      </li>
    );
  };

  return (
    <MenuList
      className={`sub-menu ${className ?? ""} ${isCustomViewUpdated ? "show-updated" : ""}`}
      left={left || null}
    >
      {dataList.map((data: IDataListItemProps, itemIndex: number) => {
        return data.disabled && data.message ? (
          <RenderWithTooltip data={data} key={data.value || data.label} />
        ) : (
          <RenderWithoutTooltip data={data} itemIndex={itemIndex} key={data.value || data.label}>
            {itemIndex === index && (
              <MenuList className="subMenu" onMouseLeave={() => setIndex(null)} key={data.value || data.label}>
                {data.subMenu?.map((data: IDataListItemProps) =>
                  data.disabled && data.message ? (
                    <RenderWithTooltip data={data} key={data.value || data.label} />
                  ) : (
                    <RenderWithoutTooltip data={data} key={data.value || data.label} />
                  ),
                )}
              </MenuList>
            )}
          </RenderWithoutTooltip>
        );
      })}
    </MenuList>
  );
};

export default MenuItem;
