import { HelperLodash } from "am-web-ui-shared/helpers";
import { IAssetDetailsData } from "../../../../models/asset/assetModel";
import { IAlertDetails } from "../../../../models/alert/alertDetails";
import { targetType } from "../../../../utils/enums/locationEnums";
import IBattery from "../../../../models/iBattery";
import { IGeoLocation, ITrackingPayload } from "../../../../models/map/bleMap/trackingPayloalModel";
import { AssetSubType, AssetType } from "../../../../utils/enums/assetEnum";

export enum AssetRedirectionType {
  CHARGER = "charger",
  ASSET = "asset",
}

export const getDefaultLocationType = (item: IAssetDetailsData | IAlertDetails | IBattery) =>
  "defaultLocationTypeDetails" in item && item.defaultLocationTypeDetails?.code;
export const getCurrentLocationType = (item: IAssetDetailsData | IAlertDetails | IBattery) =>
  "currentLocationTypeDetails" in item && item.currentLocationTypeDetails?.code;

export const getCurrentLocationParentDetailsId = (item: IAssetDetailsData | IAlertDetails | IBattery) =>
  "currentLocationParentDetails" in item && item.currentLocationParentDetails.id;
export const getDefaultLocationParentDetailsId = (item: IAssetDetailsData | IAlertDetails | IBattery) =>
  "defaultLocationParentDetails" in item && item.defaultLocationParentDetails.id;
export const getCurrentLocationParentDetailsName = (item: IAssetDetailsData | IAlertDetails | IBattery) =>
  "currentLocationParentDetails" in item && item.currentLocationParentDetails.name;
export const getDefaultLocationParentDetailsName = (item: IAssetDetailsData | IAlertDetails | IBattery) =>
  "defaultLocationParentDetails" in item && item.defaultLocationParentDetails.name;
export const getCurrentLocationParentDetailsScanCode = (item: IAssetDetailsData | IAlertDetails | IBattery) => {
  if ("currentLocationParentDetails" in item && item.currentLocationParentDetails.scanCode) {
    return `(${item.currentLocationParentDetails.scanCode})`;
  }
  return "";
};
export const getDefaultLocationParentDetailsScanCode = (item: IAssetDetailsData | IAlertDetails | IBattery) => {
  if ("defaultLocationParentDetails" in item && item.defaultLocationParentDetails.scanCode) {
    return `(${item.defaultLocationParentDetails.scanCode})`;
  }
  return "";
};

export const getRedirectionForCurrentLocationStorageAsset = (item: IAssetDetailsData | IAlertDetails | IBattery) => ({
  id: getCurrentLocationParentDetailsId(item),
  name: getCurrentLocationParentDetailsName(item),
  type: AssetRedirectionType.ASSET,
});

export const getRedirectionForDefaultLocationStorageAsset = (item: IAssetDetailsData | IAlertDetails | IBattery) => ({
  id: getDefaultLocationParentDetailsId(item),
  name: getDefaultLocationParentDetailsName(item),
  type: AssetRedirectionType.ASSET,
});

export const getRedirectionObjectForHeavyEquipment = (locationDetails: ITrackingPayload | IGeoLocation) => {
  const id = HelperLodash.get(locationDetails, "trackedBy.assetId");
  const name = HelperLodash.get(locationDetails, "trackedBy.name");
  return {
    id,
    name,
    type: AssetRedirectionType.ASSET,
  };
};

export const getRedirectionObjectForCharger = (locationDetails: ITrackingPayload | IGeoLocation, assetId?: number) => {
  const chargerAssetId = HelperLodash.get(locationDetails, "chargerAssetId");
  const name = HelperLodash.get(locationDetails, "chargerDeviceId");
  return {
    id: chargerAssetId === assetId ? null : chargerAssetId,
    name,
    type: AssetRedirectionType.CHARGER,
  };
};

const formatDefaultLocationNameForStorage = (item) => {
  return item && "defaultLocationParentDetails" in item
    ? `${item.defaultLocationParentDetails?.name || ""} ${getDefaultLocationParentDetailsScanCode(item)}`
    : null;
};

export const getDefaultLocationName = (item: IAssetDetailsData | IAlertDetails | IBattery) => {
  const defaultLocationIsWorker = getDefaultLocationType(item) === targetType.WORKER;
  const defaultLocationIsStorage = getDefaultLocationType(item) === targetType.STORAGE_ASSET;

  let defaultLocationName = null;
  if ("defaultLocationName" in item && item.defaultLocationName) {
    defaultLocationName = item.defaultLocationName;
  } else if ("defaultLocation" in item && item.defaultLocation) {
    defaultLocationName = item.defaultLocation; // alert details => "defaultLocation"
  }

  if (defaultLocationIsWorker && "defaultWorkerName" in item && item.defaultWorkerName) {
    defaultLocationName = item.defaultWorkerName;
  } else if (defaultLocationIsStorage) {
    defaultLocationName = formatDefaultLocationNameForStorage(item);
  }
  return defaultLocationName;
};
export const getCurrentLocationName = (item: IAssetDetailsData | IAlertDetails | IBattery) => {
  const currentLocationIsWorker = getCurrentLocationType(item) === targetType.WORKER;
  const currentLocationIsStorage = getCurrentLocationType(item) === targetType.STORAGE_ASSET;

  let currentLocationName = null;
  if ("currentLocationName" in item && item.currentLocationName) {
    currentLocationName = item.currentLocationName;
  } else if ("currentLocation" in item && item.currentLocation) {
    currentLocationName = item.currentLocation; // alert details => "currentLocation"
  }

  if (currentLocationIsWorker && "responsibleEmployeeName" in item && item.responsibleEmployeeName) {
    currentLocationName = item.responsibleEmployeeName;
  } else if (currentLocationIsStorage) {
    currentLocationName =
      "currentLocationParentDetails" in item
        ? `${item.currentLocationParentDetails?.name || ""} ${getCurrentLocationParentDetailsScanCode(item)}`
        : null;
  }
  return currentLocationName;
};

export const getAssetSubType = (item: IAssetDetailsData | IAlertDetails | IBattery): AssetSubType =>
  ("assetSubType" in item && item?.assetSubType?.code) || AssetSubType.Unique;

export const isHeavyEquipment = (item: IAssetDetailsData | IAlertDetails | IBattery) => {
  const type: AssetType = "assetTypeResponse" in item && item?.assetTypeResponse?.code;
  const subType: AssetSubType = "assetSubType" in item && item?.assetSubType?.code;
  return type === AssetType.HeavyEquipment || (type === AssetType.Storage && subType === AssetSubType.HeavyEquipment);
};

export const isVan = (item: IAssetDetailsData | IAlertDetails | IBattery) => {
  const type = "assetTypeResponse" in item && item?.assetTypeResponse?.code;
  const subType = "assetSubType" in item && item?.assetSubType?.code;
  return type === AssetType.Storage && subType === AssetSubType.Van;
};
