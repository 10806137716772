import { IconName } from "am-web-ui-shared/components";

export const ActionBarSettings = {
  actionItems: [
    {
      authKey: "asset.transfer.add",
      dependencies: { toolTip: "transferTooltip", disable: "disableTransfer" },
      iconName: IconName.CartAdd,
      isDisable: true,
      isVisible: true,
      name: IconName.Transfer,
      showTooltipOnDisable: false,
      showTooltipOnEnable: false,
      tooltipPosition: "bottom",
      type: "TextWithIcon",
    },
  ],
  actionViews: [
    {
      isDisable: false,
      isVisible: true,
      name: IconName.Sort,
      showTooltipOnDisable: true,
      showTooltipOnEnable: true,
      tooltipPosition: "bottom",
      type: "TextWithIcon",
    },
  ],
  moduleType: {
    moduleName: "ASSETS",
  },
};
