import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import IRadioProps from "./iRadioProps";
import { RadioElement } from "./radioForm";

const RadioWrapper = styled.label`
  position: relative;
  font-size: ${(props) => props.theme.fontSize.large};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  .rd-checked {
    display: none;
  }
  input {
    &:checked {
      ~ .rd-checked {
        display: inline-flex;
      }
      ~ .rd-unchecked {
        display: none;
      }
    }
    &.styledText {
      font-family: ${(props) => props.theme.fontRoman.fontFamily};
      font-weight: 300;
      line-height: 17px;
      word-break: break-all;
      white-space: pre-wrap;
      width: calc(100% - 36px);
      color: ${(props) => props.theme.colors.base.steel};
      padding-left: 10px;
      font-size: ${(props) => props.theme.fontSize.base};
    }
    &.error {
      svg path {
        fill: ${(props) => props.theme.colors.base.hiltiRedError};
      }
    }
  }
`;

const LabelContainer = styled.span`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.base.steel};
  padding-left: ${(props) => (props["data-readonly"] ? "0" : "8px")};
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  display: none;
  &:disabled ~ span {
    opacity: 0.5;
    cursor: default;
  }
`;

const Label = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: 300;
  line-height: 17px;
  font-size: ${(props) => props.theme.fontSize.base};
  cursor: pointer;
`;

const LabelDesc = styled.label`
  font-size: ${(props) => props.theme.fontSize.small};
`;

/**
 * @description - Redux field binding the Radio Button component with props and returning the element.
 */
class Radio extends React.PureComponent<IRadioProps> {
  normalizeBoolean = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };

  render() {
    const {
      style,
      id,
      isFormControl,
      checked = false,
      name,
      radioLabel,
      labelDescription,
      labelOnly,
      validation,
      showInlineError,
      allSelectIndex,
      disabled,
      onSelectionChange,
      radioValue,
    } = this.props;
    return !isFormControl ? (
      <RadioWrapper style={style} className="radio-label">
        <Input
          type="radio"
          checked={checked}
          onChange={(event) => {
            return onSelectionChange(event.target.value);
          }}
          id={id}
          name={name}
          value={radioValue}
          disabled={disabled}
        />
        <SquareIcon name={IconName.Radio} className="rd-unchecked" />
        <SquareIcon name={IconName.RadioChecked} className="rd-checked" />
        <LabelContainer data-readonly={labelOnly}>
          {radioLabel && <Label className="label">{radioLabel}</Label>}
          {labelDescription && <LabelDesc> {labelDescription}</LabelDesc>}
        </LabelContainer>
      </RadioWrapper>
    ) : (
      <Field
        name={name}
        component={RadioElement}
        label={radioLabel}
        validate={validation}
        showInlineError={showInlineError}
        parse={this.normalizeBoolean}
        checked={checked}
        allSelectIndex={allSelectIndex}
        disabled={disabled}
        labelOnly={labelOnly}
        {...this.props}
      />
    );
  }
}

export default Radio;
