import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M22,2 L2,2 L2,22 L22,22 L22,2 Z M16.3137085,5 L19.1421356,7.82842712 L14.899,12.071 L19.1421356,16.3137085 L16.3137085,19.1421356 L12.071,14.899 L7.82842712,19.1421356 L5,16.3137085 L9.242,12.071 L5,7.82842712 L7.82842712,5 L12.071,9.242 L16.3137085,5 Z"
        id="Combined-Shape"
        fill="#524F53"
      />
    </g>
  </svg>
);

export default SVG;
