// @ts-ignore
import { CommonActions } from "custom-actions";

const commonReducers = (state = {}, action: { type: any; payload?: any }) => {
  switch (action.type) {
    case CommonActions.OM_GET_STATES_SUCCESS:
      return {
        ...state,
        stateList: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducers;
