import React from "react";
import { dependencyResolver } from "../gridUtils";
import { IGridCell } from "./iGridCell";

/**
 * Grid cell for custom component.
 */
export const GridCombinationalCustomCell = (props: IGridCell) => {
  const { column, data, dependencies, rowIndex, columnIndex, component } = props;
  const Component = dependencyResolver(dependencies, component);
  return <>{Component(column, data, rowIndex, columnIndex)}</>;
};

export default GridCombinationalCustomCell;
