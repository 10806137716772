import { IconName } from "am-web-ui-shared/components";
import { DownloadFileHandler } from "am-web-ui-shared/helpers";
import React from "react";
import CommonApi from "../../api/commonApi";
import { NormalBoldIcon } from "../DetailsPanel";
import { FILE_SOURCE } from "../services/model/iAssignedServiceDetail";
import { downloadHCDocuments } from "am-web-ui-shared/helpers/downloadFileHandler";

/**
 * @description - attachment.
 */
export const DetailComponents = (files, property) => {
  const downloadDocument = async (id, fileName, fileSource, path) => {
    if (fileSource === FILE_SOURCE.CLOUD) {
      downloadHCDocuments(CommonApi, fileName, path);
    } else {
      DownloadFileHandler.download(CommonApi, id, fileName);
    }
  };

  const renderNormalBoldIcon = (data, idx?: number) => (
    <NormalBoldIcon
      key={data[property.id] || idx}
      id={`pnlAttachment${data[property.id]}`}
      normalId="lblAttachment"
      normalText={null}
      boldId="txtAttachment"
      boldText={data[property.displayName] || data[property.fileName]}
      iconUrl={IconName.Download}
      onIconClick={downloadDocument.bind(
        null,
        data[property.fileId],
        data[property.fileName],
        data[property.fileSource],
        data[property.path],
      )}
      className={"modal-sidepanel-icon-text red-icon"}
    />
  );

  const attachmentList = Array.isArray(files)
    ? files.map((data, idx) => renderNormalBoldIcon(data, idx))
    : renderNormalBoldIcon(files);

  return <>{attachmentList}</>;
};
