/* eslint-disable no-console */
import { gridUtils } from "am-web-ui-shared/components";
import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import { ChargesLocationActions } from "custom-actions";
import { chargeLocationsGridSetting } from "../../administration/location/charges/chargeLocations/components/list/presentational/chargeLocationsGridSetting";
import { createReducer } from "../../../utils/commonUtils";
import { revertSortQueryObject } from "../../../utils/customViewUtils";
import { appendSelectedPropertyToList, clearAllSelection, setRowSelection } from "../../../utils/list/listUtils";
import { IChargeLoationState } from "./iChargesModuleState";
import { SortList } from "TARGET_BUILD/config";

const DEFAULT_SORT = SortList.CHARGE_LOCATIONS.DEFAULT_SORT;

const chargesLocationInitialState: IChargeLoationState = {
  appliedFilterQuery: [],
  chargeLocationDetails: {},
  chargeLocationsList: {
    list: null,
    offset: null,
    totalRecords: 0,
  },
  filterQuery: null,
  isEditCostLocation: false,
  isEditParent: false,
  isReset: false,
  resetScrollPosition: false,
  rootChargeLocationDetails: {},
  searchString: "",
  showNoRecordFoundView: false,
  chargelocationsFilterAllAttributes: null,
};

const setChargeLocationsListResponse = (state: IChargeLoationState, action) => {
  const oldResponse = action.payload.offset ? state.chargeLocationsList.list : [];
  action.payload.chargeLocations.list = [
    ...oldResponse,
    ...appendSelectedPropertyToList(action.payload.chargeLocations.response),
  ];
  delete action.payload.chargeLocations.response;

  return {
    ...state,
    chargeLocationsList: {
      ...action.payload.chargeLocations,
      list: action.payload.chargeLocations.list,
    },
    resetScrollPosition: state.chargeLocationsList.offset !== null && !action.payload.offset,
    showNoRecordFoundView: state.searchString && action.payload && !action.payload.chargeLocations.totalRecords,
    totalRecords: action.payload.chargeLocations.totalRecords,
  };
};

/**
 * @description Function updates the possible charge locations filters
 * @param action
 * @param {object} state - charge location state
 */
const setChargeLocationsFilterAttributes = (action, state: IChargeLoationState) => {
  const { response, filterPayload = {} } = action.payload;
  const { customAttribute, query } = filterPayload;
  const getAttributesCount = (filters) => {
    if (!filters) {
      return [];
    }
    return Object.keys(filters).map((filterName) => {
      const selectedFilters = HelperLodash.get(filters, filterName);
      return {
        [filterName]: selectedFilters && selectedFilters.length,
      };
    });
  };

  if (customAttribute && query) {
    const filtersWithQuery = HelperLodash.cloneDeep(state.chargelocationsFilterAllAttributes);
    const filteredCommonAttributes = HelperLodash.get(filtersWithQuery, customAttribute).filter((val) =>
      ("" + val).toLowerCase().includes(query.toLowerCase()),
    );
    HelperLodash.set(filtersWithQuery, customAttribute, filteredCommonAttributes);
    const selectedCustomAttribute = HelperLodash.get(filtersWithQuery, customAttribute);

    return {
      ...state,
      chargelocationsFilterAttributes: filtersWithQuery,
      filterAttributesSearchCount: [{ [customAttribute]: selectedCustomAttribute && selectedCustomAttribute.length }],
    };
  }

  return {
    ...state,
    chargelocationsFilterAllAttributes: response,
    chargelocationsFilterAttributes: response,
    filterAttributesCount: getAttributesCount(response),
    filterAttributesSearchCount: [],
  };
};

const setFilterQuery = (action, state) => {
  return {
    ...state,
    filterQuery: action.filterQuery,
  };
};

const selectRow = (state, action) => {
  const selectRowList = state.chargeLocationsList && state.chargeLocationsList.list.slice(0);
  clearAllSelection(selectRowList);
  return {
    ...state,
    chargeLocationsList: {
      ...state.chargeLocationsList,
      list: setRowSelection(selectRowList, action.id, true, "costLocationId"),
    },
  };
};

const unselectRow = (state, action) => {
  const unSelectedList = state.chargeLocationsList && state.chargeLocationsList.list.slice(0);
  return {
    ...state,
    chargeLocationsList: {
      ...state.chargeLocationsList,
      list: setRowSelection(unSelectedList, action.id, false, "costLocationId"),
    },
  };
};

const setCostLocationDetails = (state, action) => {
  return {
    ...state,
    chargeLocationDetails: action.payload.chargeLocationDetails,
    isEdit: true,
  };
};

const clearCostLocationDetails = (state) => {
  return {
    ...state,
    chargeLocationDetails: {},
    isEdit: false,
  };
};

const setCostLocationCustomViews = (state, action) => {
  const sort = revertSortQueryObject(action.payload.sortField);
  return {
    ...state,
    customView: {
      ...state.customView,
      selectedCustomViewFields: action.payload,
    },
    sortQuery: {
      sortField: sort.name,
      sortType: sort.sortType,
    },
  };
};

const setCostLocationAttributes = (state, action) => {
  return {
    ...state,
    customView: {
      ...state.customView,
      customFields: [...action.payload],
    },
  };
};

/**
 * @description - Function to update the state value with fields name for which we need value from api.
 * @param {object} action - Action object.
 * @param {object} state - State object.
 */
const setListFieldsState = (state, action) => {
  return {
    ...state,
    fields: gridUtils.createFieldsQuery(action.fields, chargeLocationsGridSetting.defaultQueryFields),
    gridFields: gridUtils.getGridSettingsBindPropertyKey(action.fields),
  };
};

/**
 * @description Function updates the state value with given sort query
 * @param {object} state state
 * @param action
 */
const setCostLocationsSortQuery = (state, action) => {
  return {
    ...state,
    sortQuery: action.sortQuery,
  };
};

const clearCostLocationsData = (state) => {
  return {
    ...state,
    chargeLocationsList: {
      list: null,
      offset: null,
      totalRecords: 0,
    },
    filterQuery: null,
  };
};

const setRootCostLocationDetails = (state, action) => {
  return {
    ...state,
    isEditParent: true,
    isReset: false,
    rootChargeLocationDetails: action.payload.rootChargeLocationDetails,
  };
};

const updateCostLocationSettingsSuccess = (state) => {
  return {
    ...state,
    isEditCostLocation: false,
    isEditParent: false,
    isReset: false,
  };
};

const setDisplayEditDialog = (state, action) => {
  return {
    ...state,
    isEditCostLocation: action.bool,
    isEditParent: false,
    isReset: false,
  };
};

const resetDefaultCompanySettings = (state) => {
  return {
    ...state,
    isReset: true,
  };
};

const setFilterAppliedField = (state, action) => ({
  ...state,
  appliedFilterQuery: action.payload && action.payload.filterQuery,
});

const setCostLocationSearchString = (
  state: IChargeLoationState,
  action: { type: ChargesLocationActions.SET_COST_LOCATIONS_SEARCH_STRING; searchString: string },
) => ({
  ...state,
  searchString: action.searchString,
});

const resetCostLocationQueryParam = (state: IChargeLoationState) => ({
  ...state,
  searchString: "",
  appliedFilterQuery: [],
  showNoRecordFoundView: false,
  filterQuery: null,
  sortQuery: {
    sortField: DEFAULT_SORT.name,
    sortType: DEFAULT_SORT.sortType,
  },
});

const chargeLocationsReducer = createReducer(chargesLocationInitialState, {
  [ChargesLocationActions.CH_CHARGE_LOCATIONS_CUSTOM_VIEW_SUCCESS]: setCostLocationCustomViews,
  [ChargesLocationActions.CH_CHARGE_LOCATIONS_ATTRIBUTES_SUCCESS]: setCostLocationAttributes,
  [ChargesLocationActions.SET_COST_LOCATIONS_LIST_FIELDS_QUERY]: setListFieldsState,
  [ChargesLocationActions.SET_CHARGE_LOCATIONS_SORT_QUERY]: setCostLocationsSortQuery,
  [ChargesLocationActions.CH_CHARGE_LOCATIONS_CLEAR_RESPONSE]: clearCostLocationsData,
  [ChargesLocationActions.CH_ROOT_CHARGE_LOCATION_DETAILS_SET_RESPONSE]: setRootCostLocationDetails,
  [ChargesLocationActions.CH_UPDATE_COST_LOCATION_SETTINGS_SUCCESS]: updateCostLocationSettingsSuccess,
  [ChargesLocationActions.CH_DISPLAY_EDIT_DIALOG]: setDisplayEditDialog,
  [ChargesLocationActions.CH_RESET_COMPANY_SETTINGS]: resetDefaultCompanySettings,
  [ChargesLocationActions.CH_SET_FILTER_APPLIED_FIELD]: setFilterAppliedField,
  [ChargesLocationActions.SET_COST_LOCATIONS_SEARCH_STRING]: setCostLocationSearchString,
  [ChargesLocationActions.RESET_COST_LOCATIONS_QUERY_PARAM]: resetCostLocationQueryParam,
});

const ChargeLocationsListReducer = (state = chargesLocationInitialState, action) => {
  switch (action.type) {
    case ChargesLocationActions.CH_CHARGE_LOCATIONS_SET_LIST_RESPONSE:
      return setChargeLocationsListResponse(state, action);
    case ChargesLocationActions.CH_CHARGE_LOCATIONS_FILTER_FIELD_SUCCESS:
      return setChargeLocationsFilterAttributes(action, state);
    case ChargesLocationActions.SET_CHARGE_LOCATIONS_FILTER_QUERY:
      return setFilterQuery(action, state);
    case ChargesLocationActions.CH_CHARGE_LOCATIONS_SELECT_ROW:
      return selectRow(state, action);
    case ChargesLocationActions.CH_CHARGE_LOCATIONS_UNSELECT_ROW:
      return unselectRow(state, action);
    case ChargesLocationActions.CH_CHARGE_LOCATION_DETAILS_SET_RESPONSE:
      return setCostLocationDetails(state, action);
    case ChargesLocationActions.CH_CHARGE_LOCATION_DETAILS_CLEAR_RESPONSE:
      return clearCostLocationDetails(state);
    default:
      return chargeLocationsReducer(state, action);
  }
};

export default ChargeLocationsListReducer;
