import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import IOutSideClickProps from "./iOutSideClickProps";

/**
 * @description Component that alerts if you click outside of it
 *
 * Usage example :
 * <OutsideClickAlerter render={
 *     (clickedOuside: boolean) => <MyComponent clickedOuside={clickedOuside}/>
 *   }/>
 */

export const OutsideClickAlerter = (props: IOutSideClickProps) => {
  return (
    <OutsideClickHandler onOutsideClick={(event) => handleClickOutside(event, props)}>
      {props.render()}
    </OutsideClickHandler>
  );
};

/**
 * @description Component that exclude the id from outside click
 * Usful in case when action click and dialog are in different container
 * Right now it will pick the 0 index from array only
 */

export const shouldExecuteOutsideClick = (e, props) => {
  const validataions =
    props.ignoreClickedElement &&
    props.ignoreClickedElement.map((ignoreElement) => {
      let targetElmTree = null;

      if (ignoreElement[0] !== "." && ignoreElement && document && document.querySelector(`#${ignoreElement}`)) {
        targetElmTree = document.querySelector(`#${ignoreElement}`);
      }

      if (ignoreElement[0] === ".") {
        targetElmTree = document.querySelector(ignoreElement);
      }

      return targetElmTree && targetElmTree.contains(e.target);
    });

  return !(validataions || []).includes(true);
};

export const handleClickOutside = (event: MouseEvent, props) => {
  if (props.onClickAway && shouldExecuteOutsideClick(event, props)) {
    props.onClickAway(event);
  }
};

export default OutsideClickAlerter;
