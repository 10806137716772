import React from "react";
import GroupsRow from "./groupsRow";
import IGroupsListProps from "./iGroupsListProps";
import IGroupsSelectedItemModel from "./iGroupsSelectedItemModel";
import { HelperLodash } from "../../helpers";
import Tooltip from "@hilti/components/core/tooltip";
/**
 * @description GroupList class.
 *
 * @class GroupList
 * @extends {React.PureComponent<IGroupsListProps>}
 */
class GroupsList extends React.PureComponent<IGroupsListProps> {
  handleRowClick = (id: number, level: number, name: string, row = { level: 0 }, isIconClick = false) => {
    this.props.handleRowClick(id, level, name, row, isIconClick);
  };

  /**
   * @description Function return true if row is selected
   *
   * @param id - Id of group
   * @param level - Level of group in hierarchy
   * @param selectedItems - Array of selected items
   * @param isInitalLoad - Flag to indicate is top level group loaded
   *
   * @memberof GroupsList
   */
  isRowSelected = (id: number, level: number, selectedItems: IGroupsSelectedItemModel[]) => {
    const selectedItem =
      selectedItems &&
      selectedItems.find((item: IGroupsSelectedItemModel) => item && item.id === id && item.level === level);
    if (selectedItem) {
      return true;
    }
    return false;
  };

  renderGroupsRow = (row, index) => {
    const { bindProperties, disableItemList, level, selectedItems, selectedId, showHover } = this.props;
    const name = bindProperties && bindProperties.name;
    const id = bindProperties && bindProperties.id;
    const rowId = HelperLodash.get(row, id);
    const rowName = HelperLodash.get(row, name);
    return (
      <GroupsRow
        {...this.props}
        isSelected={this.isRowSelected(rowId, level, selectedItems)}
        handleRowClick={(isIconClick) => {
          this.handleRowClick(rowId, level, rowName, row, isIconClick);
        }}
        key={index}
        id={rowId}
        name={rowName}
        hasChildren={row.hasChildren}
        level={level}
        selectedId={selectedId}
        type={row.type}
        showHover={showHover}
        disableClass={
          rowId === this.props.disableItemId || (disableItemList && disableItemList.includes(rowId))
            ? "disabledlist"
            : ""
        }
      />
    );
  };

  render() {
    const { disableItemList, groupsList, t } = this.props;
    return (
      <ul id="groupList">
        {groupsList
          ? groupsList.map((row: any, index) =>
              disableItemList && disableItemList.includes(row.id) ? (
                <>
                  <Tooltip title={t("locations:NO_MOVE_TO_INACTIVE_LOCATION")} placement="left">
                    <div data-for={row.id.toString()} data-tip="">
                      {this.renderGroupsRow(row, index)}
                    </div>
                  </Tooltip>
                </>
              ) : (
                this.renderGroupsRow(row, index)
              ),
            )
          : null}
      </ul>
    );
  }
}

export default GroupsList;
