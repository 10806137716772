import Gateway from "../gateway/gateway";
import { IErrorModelSearchByScanCode, ScanCodeModel } from "../models/iSearchByScanCodeModel";
import ImageTypeEnum from "../utils/enums/imageTypeEnum";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import {
  getAssetMgmtEndpoint,
  getOrgMgmtEndpoint,
  getUserMgmtEndpoint,
  getDocMgmtEndpoint,
  getHiltiCloudEndpoint,
} from "../utils/apiUtils";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import { FILE_SOURCE } from "../components/services/model/iAssignedServiceDetail";

export default class CommonApi {
  /**
   * Returns list of states by calling relevant API
   * @param countryCode
   */
  static getStates = async (countryCode): Promise<any> => {
    const url = getOrgMgmtEndpoint(`/states?countryCode=${countryCode}`);
    return Gateway.get(url, null, null) as unknown as any;
  };

  /**
   * Returns list of Assets by calling relevant API
   * @param fileType : file type of the template.
   */
  static downloadImportExcelTemplate = async (fileType): Promise<any> => {
    const url = getAssetMgmtEndpoint(`/excel-template/assets/download/${fileType}`);
    return Gateway.get(url, null, null, "blob");
  };

  /**
   Download excel sheet for bulk service closure
   */
  static downloadServiceClosureTemplate = async (): Promise<any> => {
    const url = getAssetMgmtEndpoint("/excel-template/serviceClosure/download");
    return Gateway.get(url, null, null, "blob");
  };

  /**
   * Returns list of Assets by calling Hint API
   * @param fileType : file type of the template.
   */
  static downloadHiltiAssetTemplate = async (fileType): Promise<any> => {
    const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/download-template/${fileType}`);
    return Gateway.get(url, null, null, "blob");
  };

  /**
   * Returns list of workers by calling relevant API
   */
  static downloadWorkersImportExcelTemplate = async (): Promise<any> => {
    const url = getUserMgmtEndpoint("/workers/download-template");
    return Gateway.get(url, null, null, "blob");
  };

  /**
   * Returns list of asset cost settings linked with template by calling relevant API
   */
  static downloadAssetCostSettingsWithTemplate = async (): Promise<any> => {
    const url = getVersionedApiUrl(ServiceUrl.CHARGES, "/charge-type/export/asset-templates");
    return Gateway.get(url, null, null, "blob");
  };

  /**
   * Returns list of asset cost settings linked with template by calling relevant API
   */
  static downloadAssetCostSettingsWithoutTemplate = async (): Promise<any> => {
    const url = getVersionedApiUrl(ServiceUrl.CHARGES, "/charge-type/export/unique-assets");
    return Gateway.get(url, null, null, "blob");
  };

  /**
   * Returns list of quantity item cost settings by calling relevant API
   */
  static downloadQtyItemSettings = async () => {
    const url = getVersionedApiUrl(ServiceUrl.CHARGES, "/charge-type/export/quantity-items");
    return Gateway.get(url, null, null, "blob");
  };

  /**
   * Returns asset on entering any scan code or inventory number.
   * @param searchString : keyword of either scan code or inventory number category
   */
  static seekAnAssetWithScanCodeOrInventoryNumber = async (
    searchString,
  ): Promise<ScanCodeModel | IErrorModelSearchByScanCode> => {
    const params = {
      scanCodeOrInventoryNumber: searchString,
    };
    return Gateway.get(getAssetMgmtEndpoint(`/asset/search`), params, null) as unknown as ScanCodeModel;
  };

  /**
   * Returns document in blob format
   * @param id
   * @param showLoader
   * @param imageType
   */
  static getDocumentById = async (id, showLoader = false, imageType = ImageTypeEnum.original, width?, height?) => {
    const params = {
      type: imageType,
      thumbnailWidth: width,
      thumbnailHeight: height,
    };
    const url = getDocMgmtEndpoint(`/documents/${id}`, { id });

    return Gateway.get(url, params, null, "blob", showLoader).then((response) => {
      return response;
    });
  };

  /**
   * Returns document in blob format
   * @param url
   */
  static getDocument = async (url: string) => {
    return Gateway.get<Blob>(url, null, null, "blob");
  };

  /**
   * Returns document metadata
   * @param id
   */
  static getDocumentMetadataById = async (id) => {
    const url = getDocMgmtEndpoint(`/documents/${id}/metadata`, { id });
    return Gateway.get(url).then((response) => {
      return response;
    });
  };

  /**
   * Returns document in blob format
   * @param data
   * @param config
   */
  static uploadDocument = async (data, config) => {
    const url = getDocMgmtEndpoint("/documents");
    return Gateway.post(url, null, data, null, config, false).then((response) => {
      return response;
    });
  };
}
