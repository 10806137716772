import React from "react";
const SVG = () => (
  <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3531.000000, -2066.000000)" fill="#524F53">
        <g transform="translate(3530.000000, 2064.000000)">
          <path
            d="M12,2 L23,22 L1,22 L12,2 Z M11,17 L11,19 L13,19 L13,17 L11,17 Z M11,15 L13,15 L13,10 L11,10 L11,15 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
