import { defaultManufacturerFields } from "../../../../../../../common/utils/commonUtils";
import { ValidationConstants } from "../../../../../../../common/utils/enums/validationConstants";
import ISeletionModel from "../../../../template/components/merge/iSeletionModel";
/**
 * @description Utility file for manufacturer merge container.
 */

/**
 * @description hasColumnInConfigmethod check column found in the config
 *
 * @param - columns - input array of config columns in merge box
 * @param - bindProperty- input data index from data array
 */
// this method is not required
const hasColumnInConfig = (columns, bindProperty) => {
  const returnValue = columns.filter((column) => {
    return column.bindProperty === bindProperty;
  });
  return returnValue && returnValue.length > 0;
};
/**
 * @description addResponseRow method handle the row for preview response
 *
 * @param - config - input array of config columns in merge box
 * @param - row - input data single row from data array
 * @param - responseRow - response row
 */
const addResponseRow = (config, row) => {
  const responseRow = {};
  const parentKey = config.mappedKeyProperty;

  const manufacturerNotesKey = "manufacturerNotes";
  if (!row[manufacturerNotesKey]) {
    row[manufacturerNotesKey] = "";
  }
  const { columns, responseKeyProperty } = config;
  Object.entries(row).forEach(([key, keyValue]) => {
    const newKey = key === parentKey ? responseKeyProperty : key;
    if (hasColumnInConfig(columns(), key) || key === parentKey) {
      responseRow[newKey] = key === parentKey ? keyValue : { value: keyValue, isSelected: false };
    }
  });
  return responseRow;
};
/**
 * @description generateResponse method handle to create preview response
 * & create subheader Total count based on sub header eq: asset counts
 * @param - props - input props in merge box
 */
const generateResponse = (data, config) => {
  const response = [];
  data.forEach((row) => {
    response.push(addResponseRow(config, row));
  });
  return response;
};
/**
 * @description getTotalCount method handle to calculate total
 * & create subheader Total count based on sub header eq: asset counts
 * @param - data - input props in merge box
 * @param - field - field to calculate total count
 */
const getTotalCount = (data, field) => {
  let totalCount = 0;
  data.forEach((row) => {
    totalCount = totalCount + row[field];
  });
  return totalCount;
};
/**
 * @description getSelectedField method handle selected field
 *
 * @param - responseData - input props in merge box
 * @param - field - selected field
 * @param - isSelected - check selected field
 */
const getSelectedField = (responseData, field, isSelected) => {
  let name = "";
  if (responseData && responseData.length > 0) {
    responseData.forEach((responseRow) => {
      name = responseRow[field].isSelected === isSelected ? responseRow[field].value : name;
    });
  }
  return name;
};
/**
 * @description isDefultManufacturerFound method to check if default manufacturer found
 *
 * @param - data - updated response
 */
const isDefultManufacturerFound = (data) => {
  let isDefaultManufacturer = false;
  data.forEach((row) => {
    if (!isDefaultManufacturer) {
      const manufacturerName = row.manufacturerName;
      if (manufacturerName) {
        isDefaultManufacturer = defaultManufacturerFields.includes(manufacturerName.toLowerCase());
      }
    }
  });
  return isDefaultManufacturer;
};
/**
 * @description onDefaultManufacturerUpdate method handle if default manufacturer found
 *
 * @param - data - updated response
 */
const onDefaultManufacturerUpdate = (data, config) => {
  const isDefaultManufacturer = isDefultManufacturerFound(data);
  if (data.length === ValidationConstants.MAX_LENGTH.NUM_2 && isDefaultManufacturer && config.columns()[1]) {
    config.columns()[1].hintText = "common:DEFAULT_MANUFACTURER_HAS_NO_NOTES_MERGED_IGNORED";
  }
  data.forEach((row) => {
    if (isDefaultManufacturer) {
      row.manufacturerNotes = "";
    } else {
      row.manufacturerNotes = row.manufacturerNotes ? row.manufacturerNotes : "common:NO_VALUE";
    }
  });
};
/**
 * @description updateResponse method handle to update response to sent it to API
 *
 * @param - data - response data
 */
const updateResponse = (data) => {
  data.forEach((responseRow) => {
    Object.entries(responseRow).forEach(([key, keyValues]) => {
      const keyValue = keyValues as ISeletionModel;
      if (key === "manufacturerNotes" && keyValue.value === "common:NO_VALUE") {
        responseRow.manufacturerNotes = { value: "", isSelected: false };
      }
    });
  });
  return data;
};
export { generateResponse, getTotalCount, getSelectedField, onDefaultManufacturerUpdate, updateResponse };
