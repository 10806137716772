import React from "react";
import { IconBold } from "../../components/DetailsPanel";
import { UpfrontExpansionPanel } from "../../components/DetailsPanel";
import { IconName, SquareIcon } from "am-web-ui-shared/components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IlockedPanel from "./ilockedPanel";
import { HelperLodash } from "am-web-ui-shared/helpers";

const DisabledPanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
`;

const StyledUpfrontExpansionPanel = styled.div`
  pointer-events: none;
`;

export const LockedPanel = ({ id, label, icon, isLocked }: IlockedPanel) => {
  const { t } = useTranslation();
  const boldId = `lbl${id}`;
  const iconUrl = HelperLodash.get(IconName, icon);
  const lockedPanel = () => (
    <DisabledPanelWrapper>
      <IconBold id={id} iconUrl={iconUrl} boldId={boldId} boldText={t(label)} />
      {isLocked && <SquareIcon id="lockIcon" name={IconName.LockIcon} className="fleetDisabled" />}
    </DisabledPanelWrapper>
  );
  return (
    <StyledUpfrontExpansionPanel>
      <UpfrontExpansionPanel panelId={id} summaryComponents={[lockedPanel]} isEnable={false} isPanelExpanded={false} />
    </StyledUpfrontExpansionPanel>
  );
};

export default LockedPanel;
