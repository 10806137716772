import React from "react";
// @ts-ignore
import styled from "styled-components";
import ApplicationConstants from "../../constant/applicationConstants";
import { Checkbox } from "../../elements/Checkbox/checkbox";
import { handleActionItemDisable } from "./actionBarUtil";
import ActionDropDown from "./actionDropdown";
import ActionItems from "./actionItems";
import ActionView from "./actionView";
import { IActionBarProps, IActionBarState } from "./iActionBarSettings";
import ItemsCount from "./itemsCount";
import { withTranslation } from "react-i18next";
import Tooltip from "@hilti/components/core/tooltip";
import { Scroll, ScrollContext } from "../../providers/scrollProvider";

const ActionBarWrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.base.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  min-height: 72px;
  justify-content: space-between;
  .icon-label {
    word-break: break-word;
    -ms-word-break: break-all;
    width: 100%;
    justify-content: center;
  }
`;
const CheckboxWrapper = styled.div`
  height: 72px;
  border-right: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  display: flex;
  justify-content: center;
  flex-basis: 72px;
`;

const TooltipWrap = styled.div`
  display: flex;
  align-self: center;
`;

const GridActions = styled.div`
  display: flex;
  margin-left: auto;
  & > div:last-child {
    display: flex;
  }
`;
class ActionBar extends React.Component<IActionBarProps, IActionBarState> {
  actionBarRef: any = {};
  actionBarCheckboxRef: any = {};
  countRef: any = {};
  gridActionsRef: any = {};
  state = {
    activeIndex: null,
    availableWidth: 0,
    isCompMounted: false,
  };
  static contextType?: React.Context<Scroll> = ScrollContext;

  componentDidMount = () => {
    this.setState({ availableWidth: this.calculateAvailableWidth() });
  };
  componentDidUpdate(prevProps) {
    const currentAvailableWidth = this.calculateAvailableWidth();
    if (this.state.availableWidth !== currentAvailableWidth && currentAvailableWidth > 0) {
      this.setState({ availableWidth: currentAvailableWidth });
    }
    if (
      this.props.selectedItemListCount &&
      prevProps.selectedItemListCount &&
      prevProps.selectedItemListCount === 1 &&
      this.props.selectedItemListCount === 0 &&
      this.props.handleSelectAll
    ) {
      this.props.handleSelectAll(false);
    }
  }

  onClickActionView = (name: any, element: any, index: number) => {
    this.props.onActionItemClicked(name, element);
    this.setState({
      activeIndex: index,
    });
  };

  calculateAvailableWidth = () => {
    return (
      (this.actionBarRef.offsetWidth || 0) -
      (((this.actionBarCheckboxRef && this.actionBarCheckboxRef.offsetWidth) || 0) +
        ((this.gridActionsRef && this.gridActionsRef.offsetWidth) || 0) +
        ((this.countRef && this.countRef.offsetWidth) || 0) +
        ApplicationConstants.WIDTH.W32)
    );
  };

  isSelectAllInfoDisabled = () => {
    const { actionBarItemInfo } = this.props;
    return actionBarItemInfo && actionBarItemInfo.selectAllInfo && actionBarItemInfo.selectAllInfo.isDisabled;
  };

  isTotalRecordsLessMultiSelectCount = () => {
    const { actionBarItemInfo } = this.props;
    return actionBarItemInfo && actionBarItemInfo.totalRecords > actionBarItemInfo.multiSelectCount;
  };

  getSelectAllInfo = () => {
    let isDisabled: boolean;
    let tooltip = null;
    const { actionBarItemInfo, selectedItemListCount, t } = this.props;
    if (this.isSelectAllInfoDisabled()) {
      const isHeaderSelected = selectedItemListCount && selectedItemListCount > 0;
      isDisabled = !this.getIndeterminate(isHeaderSelected);
      tooltip = isDisabled && actionBarItemInfo.selectAllInfo.selectAllTooltip;
    } else if (this.isTotalRecordsLessMultiSelectCount() && selectedItemListCount === 0) {
      isDisabled = true;
      tooltip =
        actionBarItemInfo.selectAllInfo && actionBarItemInfo.selectAllInfo.selectAllTooltip
          ? actionBarItemInfo.selectAllInfo.selectAllTooltip
          : t("common:SELECT_ALL_DISABLE_TOOLTIP");
    } else {
      isDisabled = false;
    }

    return {
      isDisabled,
      tooltip,
    };
  };

  getIndeterminate = (isHeaderSelected) =>
    isHeaderSelected &&
    this.props.actionBarItemInfo &&
    this.props.actionBarItemInfo.noOfSelectedRecords < this.props.actionBarItemInfo.totalRecords;

  render() {
    const {
      actionBarSettings,
      dependencies,
      onActionItemClicked,
      actionBarItemInfo,
      t,
      selectedItemListCount,
      shouldRenderCheckBox,
      checkAuthorization,
      maxLabelLength,
    } = this.props;

    const selectAllInfo = this.getSelectAllInfo();
    const isHeaderSelected = selectedItemListCount && selectedItemListCount > 0;
    const indeterminate = this.getIndeterminate(isHeaderSelected);
    const { isScrollBehaviourEnabled } = this.context;

    return (
      <ActionBarWrapper
        id={`${!isScrollBehaviourEnabled ? `actionBar` : "actionBarScroll"}`}
        ref={(element) => (this.actionBarRef = element)}
      >
        {shouldRenderCheckBox ? (
          <CheckboxWrapper id="actionBarCheckbox" ref={(element) => (this.actionBarCheckboxRef = element)}>
            <Tooltip
              title={selectAllInfo.isDisabled && selectAllInfo.tooltip ? selectAllInfo.tooltip : ""}
              placement="right"
            >
              <TooltipWrap>
                <Checkbox
                  checked={isHeaderSelected}
                  disabled={selectAllInfo.isDisabled}
                  id="selectAllCheckBox"
                  indeterminate={indeterminate}
                  onChange={this.props.handleSelectAll}
                  name="selectAllCheckBox"
                  data-testid="selectAllCheckBox"
                />
              </TooltipWrap>
            </Tooltip>
          </CheckboxWrapper>
        ) : null}
        {actionBarItemInfo && (
          <ItemsCount
            setCountRef={(ref) => (this.countRef = ref)}
            actionBarSettings={actionBarSettings}
            t={t}
            actionBarItemInfo={actionBarItemInfo}
          />
        )}
        <ActionItems
          actionBarSettings={actionBarSettings}
          onActionClicked={onActionItemClicked}
          t={t}
          dependencies={dependencies}
          availableWidth={this.state.availableWidth}
          checkAuthorization={checkAuthorization}
          maxLabelLength={maxLabelLength}
        />

        {actionBarSettings.actionViews || actionBarSettings.actionDropdownItems ? (
          <GridActions id="gridActions" ref={(element) => (this.gridActionsRef = element)}>
            {actionBarSettings.actionViews && (
              <ActionView
                actionViews={actionBarSettings.actionViews}
                onActionClicked={this.onClickActionView}
                t={t}
                dependencies={dependencies}
                activeIndex={this.state.activeIndex}
                checkAuthorization={checkAuthorization}
              />
            )}
            {actionBarSettings.actionDropdownItems ? (
              <ActionDropDown
                actionDropdownItems={actionBarSettings.actionDropdownItems}
                onActionClicked={onActionItemClicked}
                t={t}
                dependencies={dependencies}
                checkAuthorization={checkAuthorization}
              />
            ) : null}
          </GridActions>
        ) : null}
      </ActionBarWrapper>
    );
  }
}

export default withTranslation()(ActionBar);
export { handleActionItemDisable };
