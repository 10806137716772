import { LocaleManager, SharedUtils } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import ExpandRight from "@hilti/components/core/icons/svgs/ExpandRight";
import { ValidationConstants } from "TARGET_BUILD/common/utils/enums/validationConstants";
import { useTranslation } from "react-i18next";
import { getUtilizationTime } from "TARGET_BUILD/common/utils/commonUtils";
import UtilizationDetailsChart from "./utilizationDetailsChart";
import UtilizationSummaryChart from "./utilizationSummaryChart";
import CustomDaysFilter from "./customDaysFilter";
import { IUtilizationDetailsExpanded } from "./model/iUtilizationDetailsView";
import { useSelector } from "react-redux";
import { RootState } from "TARGET_BUILD/common/store/store";

const ViewDetails = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
  margin-left: 58px;
  margin-bottom: 16px;
  margin-top: 16px;
  word-break: break-all;
  padding-right: 16px;
`;

const SectionWrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -16px;
  margin-right: -16px;
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
`;
const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`;
const Img = styled.img`
  top: 28px;
  right: 15px;
  height: 16px;
  width: 10px;
`;

const ChartWrapper = styled.div`
  padding-left: 40px;
  height: auto;
  margin-bottom: 8px;
`;

const DonutWrapper = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  height: 430px;
`;

const FilterWrapper = styled.div`
  .multiSelectDropDown {
    margin-right: 0px;
  }
  padding-left: 40px;
  margin-top: 0px;
`;

export const LabelName = styled.label`
  color: ${(props) => props.theme.boldSteelText.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
`;

const EmptyMessageWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HE_UTILIZATION_VIEW_DETAILS = "utilization_viewdetails_clicked";
const HE_UTILIZATION_SUMMARY_DURATION = "utilization_summary_duration_selected";
const HE_UTILIZATION_DETAILS_DURATION = "utilization_viewdetails_duration_selected";
const CUSTOM = "custom";

/**
 * @description - Utilization Details Expanded containing charts and custom dates options.
 */
const UtilizationDetailsExpanded = (props: IUtilizationDetailsExpanded) => {
  const {
    chartData,
    getAssetUtiliationSummary,
    getAssetUtilizationDetails,
    assetDetailsData,
    isBarChartVisible,
    closeBarChartDialog,
    onViewDetailClick,
    barChartData,
    isExpanded,
  } = props;
  const { t } = useTranslation();
  const userData = useSelector(({ UserReducer }: RootState) => UserReducer?.userMeData);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [customFilterValue, setcustomFilterValue] = useState("30");

  useEffect(() => {
    if (!isExpanded) {
      return;
    }
    const payload = {
      tenant_id: assetDetailsData?.tenantId,
      user_role: userData?.userRole?.code,
      utilization_duration_selected: null,
      utilization_viewdetails_duration_selected: null,
    };
    if (
      customFilterValue === ValidationConstants.CUSTOM_DATES &&
      LocaleManager.dateHelpers.getDateOnly(selectedEndDate) >=
        LocaleManager.dateHelpers.getDateOnly(selectedStartDate) &&
      !isBarChartVisible
    ) {
      getAssetUtiliationSummary(assetDetailsData?.assetId, {
        startDate: LocaleManager.dateHelpers.getDateInAPIFormat(selectedStartDate),
        endDate: LocaleManager.dateHelpers.getDateInAPIFormat(selectedEndDate),
      });
      payload.utilization_duration_selected = CUSTOM;
      SharedUtils.pushDataToGA(HE_UTILIZATION_SUMMARY_DURATION, null, payload);
    } else if (customFilterValue !== ValidationConstants.CUSTOM_DATES && !isBarChartVisible) {
      getAssetUtiliationSummary(assetDetailsData?.assetId, {
        duration: Number(customFilterValue),
      });
      payload.utilization_duration_selected = customFilterValue;
      SharedUtils.pushDataToGA(HE_UTILIZATION_SUMMARY_DURATION, null, payload);
    }
    if (customFilterValue === "customDates" && isBarChartVisible) {
      getAssetUtilizationDetails(assetDetailsData?.assetId, {
        startDate: LocaleManager.dateHelpers.getDateInAPIFormat(selectedStartDate),
        endDate: LocaleManager.dateHelpers.getDateInAPIFormat(selectedEndDate),
      });
      payload.utilization_viewdetails_duration_selected = CUSTOM;
      SharedUtils.pushDataToGA(HE_UTILIZATION_DETAILS_DURATION, null, payload);
    } else if (customFilterValue !== "customDates" && isBarChartVisible) {
      getAssetUtilizationDetails(assetDetailsData?.assetId, {
        duration: Number(customFilterValue),
      });
      payload.utilization_viewdetails_duration_selected = customFilterValue;
      SharedUtils.pushDataToGA(HE_UTILIZATION_DETAILS_DURATION, null, payload);
    }
  }, [selectedStartDate, selectedEndDate, customFilterValue, isBarChartVisible]);

  const totalTime = getUtilizationTime(chartData?.utilizationSummary)?.totalTime;

  const customDatefilterChange = (options) => {
    let params = null;
    const value = options[0].value;
    if (value === ValidationConstants.CUSTOM_DATES) {
      setDateRange(customFilterValue);
    } else {
      params = { duration: value };
      setSelectedStartDate(new Date());
      setSelectedEndDate(new Date());
    }
    setcustomFilterValue(value);
  };

  const setDateRange = (preCustomFilterValue) => {
    if (!isNaN(preCustomFilterValue)) {
      setSelectedStartDate(
        moment(selectedEndDate)
          .subtract(Number(preCustomFilterValue) - 1, "day")
          .toDate(),
      );
    }
  };

  const onChangeStartDate = (date) => {
    setSelectedStartDate(date);
    setDateRange(customFilterValue);
  };

  const onChangeEndDate = (date) => {
    setSelectedEndDate(date);
    setDateRange(customFilterValue);
  };
  const viewDetails = () => {
    setDateRange(customFilterValue);
    onViewDetailClick();
    const payload = {
      tenant_id: assetDetailsData?.tenantId,
      user_role: userData?.userRole?.code,
    };
    SharedUtils.pushDataToGA(HE_UTILIZATION_VIEW_DETAILS, null, payload);
  };
  return (
    <>
      <FilterWrapper>
        <CustomDaysFilter
          customDatefilterChange={customDatefilterChange}
          customFilterValue={customFilterValue}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          showLabels={isBarChartVisible}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          fullWidth
        />
      </FilterWrapper>

      <ChartWrapper>
        {totalTime > 0 ? (
          <DonutWrapper>
            <UtilizationSummaryChart chartData={chartData} />
          </DonutWrapper>
        ) : (
          <EmptyMessageWrapper>{t("assets:UTILIZATION_SUMMARY_EMPTY_MESSAGE")}</EmptyMessageWrapper>
        )}
      </ChartWrapper>
      {totalTime > 0 && (
        <SectionWrapperDiv onClick={viewDetails}>
          <ViewDetails>{t("common:VIEW_DETAILS")}</ViewDetails>
          <ImgWrapper>
            <ExpandRight width="24" height="24" />
          </ImgWrapper>
        </SectionWrapperDiv>
      )}
      {isBarChartVisible ? (
        <UtilizationDetailsChart
          customDatefilterChange={customDatefilterChange}
          customFilterValue={customFilterValue}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          isBarChartVisible={isBarChartVisible}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          closeBarChartDialog={closeBarChartDialog}
          barChartData={barChartData}
        />
      ) : null}
    </>
  );
};

/**
 * @description - Exporting the components
 */
export default UtilizationDetailsExpanded;
