import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M14,4 L14,10 L20,10 L20,14 L13.999,14 L14,20 L10,20 L9.999,14 L4,14 L4,10 L10,10 L10,4 L14,4 Z" />
    </g>
  </svg>
);

export default SVG;
