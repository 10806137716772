import React from "react";
import { Fields } from "redux-form";
import CreatableAutoComplete from "./creatableAutoComplete";
import ICreatableAutoCompleteProps from "./iCreatableAutoCompleteProps";
import ICreatableAutoCompleteState from "./iCreatableAutoCompleteState";

/***
 * CreatableAutoComplete provides a generic react autocomplete control that has functionality to add new options
 */
class CreatableAutoComponent extends React.Component<ICreatableAutoCompleteProps, ICreatableAutoCompleteState> {
  render() {
    return this.props.isFormControl ? (
      <Fields
        names={[this.props.name, this.props.labelField]}
        name={this.props.name}
        label={this.props.label}
        component={CreatableAutoComplete}
        validate={this.props.validation}
        options={
          this.props.data
            ? this.props.data.map((item) => ({
                label: item[this.props.valueKey],
                value: item[this.props.valueId] && item[this.props.valueId].toString(),
              }))
            : []
        }
        {...this.props}
      />
    ) : (
      <CreatableAutoComplete {...this.props} />
    );
  }
}

export default CreatableAutoComponent;
