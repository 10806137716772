import React from "react";
const SVG = () => (
  <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2010.000000, -330.000000)">
        <g transform="translate(2008.000000, 326.000000)">
          <g>
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M2,4 L9,4 L11,6 L22,6 L22,16 L17,12.5 L17,17 L13,17 L13,20 L2,20 L2,4 Z M19,21 L15,21 L15,19 L19,19 L19,16 L24,20 L19,24 L19,21 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
