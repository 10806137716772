import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { default as TextField } from "../Field/field";
import { ITextAreaProps } from "./iTextAreaProps";

export const StyledInput = styled.textarea`
  background-color: ${(props) => props.theme.colors.base.white};
  border: 2px solid ${(props) =>
    props.disabled ? props.theme.colors.base.heavyConcrete20 : props.theme.colors.base.heavyConcrete60};
  color: ${(props) => (props.disabled ? props.theme.colors.base.steel20 : props.theme.colors.base.steel)};
  width: ${(props) => (props.style && props.style.width ? props.style.width : "100%")};
  height: ${(props) => (props.style && props.style.height ? props.style.height : "48px")};
  padding: 10px 16px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  resize: none;
  border-color: ${(props) =>
    props["data-error"]
      ? props.theme.colors.base.hiltiRedError
      : props.disabled
        ? props.theme.colors.base.heavyConcrete20
        : props.theme.colors.base.heavyConcrete60};
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    font-weight: ${(props) => props.theme.fontNormal.fontWeight};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.steel40};
    font-family: font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.steel40};
    font-family: font-family: ${(props) => props.theme.fontRoman.fontFamily};
  }
`;

export const TextAreaComponent = (props) => {
  const {
    input,
    label,
    placeholder,
    disabled,
    readOnly,
    meta: { touched, error, warning },
    onBlur,
    onTextChange,
    style,
    required,
    maxLength,
  } = props;
  return (
    <TextField
      required={required}
      name={input.name}
      // @ts-ignore
      htmlFor={name}
      label={label}
      error={error}
      touched={touched}
      warning={warning}
    >
      <StyledInput
        {...input}
        data-error={error && touched}
        id={input.name}
        type="textarea"
        placeholder={placeholder}
        disabled={disabled}
        style={style}
        readonly={readOnly}
        maxLength={maxLength}
        onBlur={(val) => {
          const trimVal = val.target.value.trim();
          input.onBlur(trimVal);
          if (onBlur) {
            onBlur(trimVal);
          }
        }}
        onChange={(val) => {
          input.onChange(val);
          if (onTextChange) {
            onTextChange(val);
          }
        }}
      />
    </TextField>
  );
};

TextAreaComponent.defaultProps = {
  required: false,
};

class FormTextArea extends React.PureComponent<ITextAreaProps> {
  render() {
    return (
      <Field
        name={this.props.name}
        label={this.props.label}
        component={TextAreaComponent}
        validate={this.props.validation}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        style={this.props.style}
        onTextChange={this.props.onTextChange}
        required={this.props.required}
        maxLength={this.props.maxLength}
      />
    );
  }
}

export default FormTextArea;
