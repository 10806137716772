/**
 * @description This file is for all the api hitting using
 * axios for the asset tracking module.
 */
import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import ICheckInventoryResponse from "../modules/checkInventory/iCheckInventoryResponse";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";

/**
 * Returns Check Inventory settings response
 */
export const getCheckInventorySettings = async (): Promise<ICheckInventoryResponse | IErrorResponse> => {
  const url = getOrgMgmtEndpoint(`/company/check-inventory`);
  return Gateway.get(url, null, null) as unknown as ICheckInventoryResponse;
};

/**
 * Update Check inventory Settings and return response
 * @param newCheckInventorySetting: Tracking flag to enable or disabled the tracking settings
 */
export const updateCheckInventorySettings = async (updatedData): Promise<string | IErrorResponse> => {
  const udpdated = { isEnabled: updatedData };
  const url = getOrgMgmtEndpoint(`/company/check-inventory`);
  return Gateway.put(url, null, udpdated, null) as unknown as string;
};
