import styled from "styled-components";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import { allocationStatusEnum } from "./enums/allocationTargetEnum";
import { StatusCode } from "./enums/assetEnum";
import { QtyItemAllocationEnum } from "./enums/qtyItemEnum";
import { getColorPrppertiesString } from "./styledUtilsCore";
import { GeoTagBatteryStatusEnum } from "./enums/activeTrackingEnum";

export function truncate(width) {
  return `
      width: ${width};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
}

export function AlignCenter() {
  return `
      align-self: center;
    `;
}

export function InlineFlex() {
  return `
      display: inline-flex;
    `;
}

export function FlexSpaceAround() {
  return `
    display: flex;
    justify-content: space-around;
  `;
}

export function FlexEnd() {
  return `
    justifyContent: flex-end;
  `;
}

export function HiltiBold() {
  return `
    font-family: "Hilti-Bold", Arial,Verdana,sans-serif;
  `;
}

export function noPadding() {
  return `
    padding: 0 !important;
  `;
}

export function noMargin() {
  return `
    margin: 0 !important;
  `;
}

/**
 *  Primary font styles
 */
export function primaryFontStyles() {
  return `
        font-size: 16px;
        font-weight: 700;
    `;
}

/**
 *  Secondary font styles
 */
export function secondaryFontStyles() {
  return `
        font-size: 16px;
        font-weight: 300;
    `;
}

export function setFontSizeAndWeight(fontSize, fontWeight) {
  return `
        font-size: ${fontSize};
        font-weight: ${fontWeight};
    `;
}

export function wordwrap(wrap) {
  const wordBreak = wrap === "break-word" ? "break-all" : wrap;
  return `
    overflow-wrap: ${wrap};
    word-wrap: ${wrap};
    ${wordBreak};
  `;
}

export function ellipsis(width) {
  return `
    display: inline-block;
    width: ${width};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  `;
}

export function ellipsisFunc() {
  return `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  `;
}

export function statusColor(currentStatusCode) {
  let fontColor = "";
  let bgColor = "";

  switch (currentStatusCode) {
    case StatusCode.Lost: {
      bgColor = theme.statusStyle.Lost.backgroundColor;
      fontColor = theme.statusStyle.Lost.color;
      break;
    }
    case StatusCode.Operational: {
      bgColor = theme.statusStyle.Operational.backgroundColor;
      fontColor = theme.statusStyle.Operational.color;
      break;
    }
    case StatusCode.Repair: {
      bgColor = theme.statusStyle.Repair.backgroundColor;
      fontColor = theme.statusStyle.Repair.color;
      break;
    }
    case StatusCode.Retired: {
      bgColor = theme.statusStyle.Retired.backgroundColor;
      fontColor = theme.statusStyle.Retired.color;
      break;
    }
    case allocationStatusEnum.Available: {
      bgColor = theme.allocationStatus.Available.backgroundColor;
      fontColor = theme.allocationStatus.Available.color;
      break;
    }
    case allocationStatusEnum.Main:
    case allocationStatusEnum.Intransit: {
      bgColor = theme.allocationStatus.Main.backgroundColor;
      fontColor = theme.allocationStatus.Main.color;
      break;
    }
    case allocationStatusEnum.Disposed: {
      bgColor = theme.allocationStatus.Disposed.backgroundColor;
      fontColor = theme.allocationStatus.Disposed.color;
      break;
    }
    case StatusCode.GatewayOnline: {
      bgColor = theme.statusStyle.gateway.online.backgroundColor;
      fontColor = theme.statusStyle.gateway.online.color;
      break;
    }
    case StatusCode.GatewayOffline: {
      bgColor = theme.statusStyle.gateway.offline.backgroundColor;
      fontColor = theme.statusStyle.gateway.offline.color;
      break;
    }
    case QtyItemAllocationEnum.CONSUMED: {
      bgColor = theme.qtyItemStatus.Consumed.backgroundColor;
      fontColor = theme.qtyItemStatus.Consumed.color;
      break;
    }
    case QtyItemAllocationEnum.RESERVED: {
      bgColor = theme.qtyItemStatus.Reserved.backgroundColor;
      fontColor = theme.qtyItemStatus.Reserved.color;
      break;
    }
    case StatusCode.New: {
      bgColor = theme.statusStyle.New.backgroundColor;
      fontColor = theme.statusStyle.New.color;
      break;
    }
    case GeoTagBatteryStatusEnum.LOW:
    case StatusCode.BateryLevel: {
      bgColor = theme.statusStyle.Warning.backgroundColor;
      fontColor = theme.statusStyle.Warning.color;
      break;
    }
    case StatusCode.Broken:
    default: {
      bgColor = theme.statusStyle.Broken.backgroundColor;
      fontColor = theme.statusStyle.Broken.color;
      break;
    }
  }

  return getColorPrppertiesString(bgColor, fontColor);
}

export function clearFix() {
  return `
    &:after {
      display: table;
      content: "''";
      clear: both;
    }
  `;
}

export function size(width, height) {
  return `
    width: ${width};
    height: ${height};
  `;
}

/**
 *  Footer position and style for the app
 */
export function footerStyle() {
  return `
    position: fixed;
    bottom: 0;
    width: 992px;
    padding-top :6px;
    padding-bottom :6px;
  `;
}

/**
 * Shorthand that accepts any number of background values as parameters for creating a single background statement.
 * @example
 * // styled-components usage
 * const div = styled.div`
 *   ${backgrounds('url("/image/background.jpg")', 'linear-gradient(red, green)', 'center no-repeat')}
 * `
 */
export function backgrounds(...propArray) {
  const properties = propArray.join(", ");
  return `
    background: ${properties};
  `;
}

export const CollapsiblePanel = styled.div`
  &.detailView {
    @media (max-width: 1280px) {
      width: calc(100vw - 315px);
    }
    @media (min-width: 1281px) {
      width: calc(100vw - 480px);
    }
  }
`;
export const LoaderWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;

export function getShrinkedViewWidth() {
  return `
  @media (max-width: 1280px) {
    width: calc(100vw - 315px);
  }
  @media (min-width: 1281px) {
      width: calc(100vw - 480px);
  }
  `;
}

export function getViewPortWidth() {
  return `
    width: calc(100vw);
  `;
}

export function getSidePanelWidth() {
  return `

  `;
}

export function CommonHeading() {
  return `
    padding: 24px 16px;
    font-size: 16px;
    font-weight: 700;
  `;
}

export const fieldSpacingSetting = {
  marginBottom: "16px",
  marginBottomNone: "0px",
  marginTop: "16px",
  marginTop8: "8px",
};

export const StatusWrapper = styled.div`
  display: inline-flex;
  row-gap: 8px;
  flex-wrap: wrap;
`;

export const EntityInfoBoxWrapper = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  background: ${(props) => props.theme.colors.base.white};
  margin-top: -4px;
  padding-bottom: 2px;
  .row {
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const FixedContainer = styled.div`
  width: ${appConstants.FORM_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const StyledFixedContainer = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.base.steel};
  box-shadow: 0 2px 0 0 ${(props) => props.theme.colors.base.heavyConcrete20};
  border: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  margin-bottom: 24px;
`;
