import { ImageSizeEnum } from "TARGET_BUILD/common/components/styled/squareImage/imageSizeUtil";
import { CellTypes, IconName, IGridColumnSetting } from "am-web-ui-shared/components";
import SquareImageContainer from "TARGET_BUILD/common/components/styled/squareImage/squareImageContainer";
import ImageTypeEnum from "TARGET_BUILD/common/utils/enums/imageTypeEnum";
import theme from "TARGET_BUILD/theme";

export const columnConfig: IGridColumnSetting[] = [
  {
    bindProperties: [
      {
        key: "manufacturermodel",
        properties: [{ name: "manufacturerName" }, { name: "modelName" }],
        style: { color: theme.colors.base.hiltiRed },
      },
      {
        key: "assetName",
        properties: [{ name: "assetName" }],
      },
    ],
    cellSize: 4,
    image: {
      attributes: {
        image: { maxWidth: ImageSizeEnum.SMALL, maxHeight: ImageSizeEnum.SMALL },
        wrapper: {
          height: ImageSizeEnum.SMALL,
          imageSize: 56,
          style: { marginRight: "16px" },
          width: ImageSizeEnum.SMALL,
        },
      },
      component: SquareImageContainer,
      defaultImage: IconName.Category,
      fieldName: "assetImageId",
      selfLoadImage: true,
      type: ImageTypeEnum.thumbnail,
    },
    type: CellTypes.ImageFieldCombination,
  },
  {
    bindProperties: [
      {
        key: "scanCode",
        properties: [{ name: "scanCode" }],
      },
      {
        key: "trackedSince",
        properties: [{ name: "trackedSince" }],
      },
    ],
    cellSize: 3,
    type: CellTypes.FieldCombination,
  },
  {
    bindProperties: [
      {
        key: "assignedLocationName",
        properties: [{ name: "assignedLocationName" }],
      },
      {
        key: "trackedLocationName",
        properties: [{ name: "trackedLocationName" }],
      },
    ],
    cellSize: 4,
    type: CellTypes.FieldCombination,
  },
  {
    cellSize: 1,
    isSortable: false,
    key: "suggestedTransferQuickAction",
    type: CellTypes.QuickActions,
    dependencies: "alertTypeHasQuickAction",
  },
];

export const ACCEPT_SUGGESTED_TRANSFER = "ACCEPT_SUGGESTED_TRANSFER";

export const quickActionsConfig = [
  {
    code: ACCEPT_SUGGESTED_TRANSFER,
    image: IconName.Checkmark,
    value: "quickActions:ACCEPT_SUGGESTED_TRANSFER",
  },
];
