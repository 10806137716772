import React from "react";
const SVG = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g id="05-Icons-/-cart-add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="32" height="32" />
      <path d="" id="Path-2" fill="#524F53" />
      <path
        d="M21.3333333,8.4594382 L29.3333333,4 L29.3333333,8.4594382 L25.3333333,10.6666667 L25.3333333,21.3333333 L2.66666667,21.3333333 L2.66666667,9.94397004 L6.61827309,8 L6.66666667,17.4382022 L21.3333333,17.4382022 L21.3333333,8.4594382 Z M22.6666667,25.3328011 C22.6666667,26.0401863 22.385659,26.7185987 21.8854622,27.2187955 C21.3852654,27.7189924 20.7068529,28 19.9994678,28 C18.526847,27.9997061 17.3332354,26.8057767 17.3333333,25.333156 C17.3334313,23.8605352 18.5272018,22.6667646 19.9998226,22.6666667 C21.4724434,22.6665687 22.6663728,23.8601804 22.6666667,25.3328011 Z M10.6666667,25.3328011 C10.6666667,26.8058544 9.47252106,28 7.99946781,28 C6.52684705,27.9997061 5.33323536,26.8057767 5.33333333,25.333156 C5.3334313,23.8605352 6.52720183,22.6667646 7.99982262,22.6666667 C9.4724434,22.6665687 10.6663728,23.8601804 10.6666667,25.3328011 Z"
        id="Path-2"
        fill="#524F53"
      />
      <rect id="Rectangle" fill="#524F53" x="8" y="8" width="12" height="4" />
      <rect id="Rectangle" fill="#524F53" x="12" y="4" width="4" height="12" />
    </g>
  </svg>
);

export default SVG;
