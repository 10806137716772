import React from "react";
const SVG = () => (
  <svg width="16px" height="17px" viewBox="0 0 16 17" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2012.000000, -2931.000000)" fillRule="nonzero">
        <g transform="translate(2008.000000, 2928.000000)">
          <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
          <path
            d="M12.6006276,5.05343147 C12.3437275,5.52556806 12.197774,6.06680104 12.197774,6.64212328 C12.197774,6.91398759 12.2303649,7.17823989 12.2918529,7.43118632 C11.815136,7.22329808 11.2887711,7.10799519 10.7354758,7.10799519 C8.58312692,7.10799519 6.83830342,8.85281869 6.83830342,11.0051676 C6.83830342,13.1575164 8.58312692,14.9023399 10.7354758,14.9023399 C12.8878246,14.9023399 14.6326481,13.1575164 14.6326481,11.0051676 C14.6326481,10.5805848 14.5647511,10.1718602 14.4392224,9.78925933 C14.7799664,9.90696428 15.1457884,9.97089041 15.5265411,9.97089041 C15.9910468,9.97089041 16.4333308,9.87574794 16.8349685,9.70388749 C16.9240719,10.1235941 16.9709515,10.5589062 16.9709515,11.0051676 C16.9709515,12.3045284 16.5735169,13.5110633 15.8935793,14.5098409 L19.6875,18.3037616 L18.0340698,19.9571918 L14.2401491,16.1632711 C13.2413716,16.8432087 12.0348366,17.2406433 10.7354758,17.2406433 C7.29171759,17.2406433 4.5,14.4489257 4.5,11.0051676 C4.5,7.56140938 7.29171759,4.76969179 10.7354758,4.76969179 C11.3851915,4.76969179 12.0116982,4.86906124 12.6006271,5.05343134 L12.6006276,5.05343147 Z M15.6305651,9.13869863 C14.1942937,9.13869863 13.0299658,7.97437065 13.0299658,6.53809931 C13.0299658,5.10182798 14.1942937,3.9375 15.6305651,3.9375 C17.0668364,3.9375 18.2311644,5.10182798 18.2311644,6.53809931 C18.2311644,7.97437065 17.0668364,9.13869863 15.6305651,9.13869863 Z M15.6305651,8.51455479 C16.7221313,8.51455479 17.6070206,7.62966553 17.6070206,6.53809931 C17.6070206,5.44653309 16.7221313,4.56164383 15.6305651,4.56164383 C14.5389988,4.56164383 13.6541096,5.44653309 13.6541096,6.53809931 C13.6541096,7.62966553 14.5389988,8.51455479 15.6305651,8.51455479 Z M15.6422703,7.01565954 L14.9434877,7.71444208 L14.4776327,7.24858704 L15.1764152,6.54980451 L14.4776327,5.85102197 L14.9434877,5.38516695 L15.6422703,6.08394949 L16.3410528,5.38516695 L16.8069078,5.85102197 L16.1081253,6.54980451 L16.8069078,7.24858704 L16.3410528,7.71444208 L15.6422703,7.01565954 Z"
            id="Combined-Shape"
            fill="#524F53"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
