import { ValidationConstants } from "../../utils/enums/validationConstants";
export default class ComboBoxRequest {
  static readonly defaultLimit = ValidationConstants.MAX_LENGTH.NUM_50;
  static readonly defaultOffset = ValidationConstants.MAX_LENGTH.NUM_0;
  static readonly placeHolderText = "common:PLACEHOLDER_TYPE_OR_SELECT";
  static readonly defaultLiveSearchProps = (
    searchMethod,
    isPaged = false,
  ): {
    isPaged: boolean;
    minCharacter: number;
    searchMethod: (inputParams) => Promise<any>;
  } => {
    return {
      isPaged,
      minCharacter: 0,
      searchMethod,
    };
  };

  readonly filterQuery: string;
  // fields expected to be recieved in response, eg.  "firstName,lastName"
  fields: string;
  // any filters that need to be applied
  // eg. format `responsibilityType:in=${ResponsibilityTypeEnum.LOCATION_MANAGER}&order_by=%2BfirstName`
  filters: string[];
  // fields to be searched for search string provided in variable q
  searchFields: string;
  orderBy: string;
  // search query
  q?: string;
  // number of records to be fetched
  limit: number;
  // offset for paging
  offset: number;
  view?: string;
  constructor(requestPayload) {
    this.fields = requestPayload.fields;
    this.filters = requestPayload.filters;
    this.searchFields = requestPayload.searchFields;
    this.orderBy = requestPayload.orderBy;
    this.q = requestPayload.q;
    this.limit = requestPayload.limit || ComboBoxRequest.defaultLimit;
    this.offset = requestPayload.offset || ComboBoxRequest.defaultOffset;
    this.view = requestPayload.view;
    this.filterQuery = (() => {
      let filter = "";
      this.filters.forEach((f) => (filter = filter ? `${filter}&filter=${encodeURI(f)}` : `filter=${encodeURI(f)}`));
      return filter;
    })();
  }
}
