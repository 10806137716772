// @ts-ignore
import { CheckInventoryActions, CompanyActions, TransferedAssetSettingsActions } from "custom-actions";
import { HiltiAssetStatusActions, FieldRequestConstants } from "../../../actionConstants";
import { createReducer } from "../../../utils/commonUtils";
import { ICompanyState } from "./iCompanyModuleState";
import { ILicenseAndUsageDetailCountModel } from "TARGET_BUILD/common/models/company/iCompanyModel";
const initialState: ICompanyState = {
  isDeletionSettingsEnabled: false,
  transferConfirmationPolicy: null,
  isDeliveryNoteEmailEnabled: false,
  isHiltiAssetStatusEnabled: true,
  technicalUser: null,
  technicalUsers: null,
  password: null,
  employeeId: null,
  isFieldRequestEnabled: false,
};

const getCompanyDetailsSuccess = (state, action) => {
  return {
    ...state,
    companyDetails: action.payload,
  };
};

const editCompanyDetailsSuccess = (state, action) => {
  return {
    ...state,
    isEditedSuccessfully: action.payload,
    isRequestInitiated: action.isRequestInitiated,
  };
};

const getLicenseAndUsageDetailsSuccess = (state, action) => {
  return {
    ...state,
    licenseAndUsageDetails: action.payload,
  };
};

const getLicenseAndUsageDetailsSuccessCount = (
  state: ICompanyState,
  action: { payload: Array<ILicenseAndUsageDetailCountModel> },
) => {
  return {
    ...state,
    licenseAndUsageDetailsCount: action.payload,
  };
};

const getCheckInventorySettingsSuccess = (state, action) => {
  return {
    ...state,
    isCheckInventoryEnabled: action.payload,
  };
};

const getHiltiAssetStatusSettingsSuccess = (state, action) => {
  return {
    ...state,
    isHiltiAssetStatusEnabled: action.payload,
  };
};

const getTransferSettingsSuccess = (state, action) => {
  return {
    ...state,
    isDeletionSettingsEnabled: action.payload,
  };
};

const getTransferConfirmationSettingsSuccess = (state, action) => {
  return {
    ...state,
    transferConfirmationPolicy: action.payload.transferConfirmationPolicy,
    isDeliveryNoteEmailEnabled: action.payload.isDeliveryNoteEmailEnabled,
  };
};
const getTechnicalUser = (state, action) => {
  return {
    ...state,
    technicalUser: action.payload,
  };
};

const getTechnicalUsers = (state, action) => {
  return {
    ...state,
    technicalUsers: action.payload,
  };
};

const getTechnicalUserPassword = (state, action) => {
  return {
    ...state,
    password: action.payload?.password,
    employeeId: action.payload?.employeeId,
  };
};

const clearTechnicalUser = (state) => {
  return {
    ...state,
    technicalUser: null,
  };
};

const clearTechnicalUserandPassword = (state) => {
  return {
    ...state,
    technicalUsers: null,
    password: null,
    employeeId: null,
  };
};
const resetTechnicalUserPassword = (state, action) => {
  return {
    ...state,
    technicalUser: { ...state.technicalUser, password: action.payload.password },
  };
};

const getFieldRequestSettingsSuccess = (state, action) => {
  return {
    ...state,
    isFieldRequestEnabled: action.payload.isEnabled,
  };
};

const setAdditionalSettings = (state, action) => {
  return {
    ...state,
    additionalSettings: action.payload,
  };
};

const companyReducers = createReducer(initialState, {
  [CompanyActions.CLEAR_TECHNICAL_USER]: clearTechnicalUser,
  [CompanyActions.CLEAR_MULTIPLE_TECHNICAL_USER]: clearTechnicalUserandPassword,
  [CompanyActions.GET_TECHNICAL_USER_SUCCESS]: getTechnicalUser,
  [CompanyActions.GET_TECHNICAL_USERS_SUCCESS]: getTechnicalUsers,
  [CompanyActions.GET_TECHNICAL_USER_PASSWORD_SUCCESS]: getTechnicalUserPassword,
  [CompanyActions.OM_GET_COMPANY_DETAILS_SUCCESS]: getCompanyDetailsSuccess,
  [CompanyActions.OM_EDIT_COMPANY_DETAILS_SUCCESS]: editCompanyDetailsSuccess,
  [CompanyActions.GET_LICENSE_AND_USAGE_DETAILS_SUCCESS]: getLicenseAndUsageDetailsSuccess,
  [CompanyActions.GET_LICENSE_AND_USAGE_DETAILS_SUCCESS_COUNT]: getLicenseAndUsageDetailsSuccessCount,
  [CompanyActions.RESET_TECHNICAL_USER_PASSWORD_SUCCESS]: resetTechnicalUserPassword,
  [CheckInventoryActions.GET_CHECK_INVENTORY_SETTINGS_SUCCESS]: getCheckInventorySettingsSuccess,
  [CheckInventoryActions.UPDATE_CHECK_INVENTORY_SUCCESS]: getCheckInventorySettingsSuccess,
  [TransferedAssetSettingsActions.GET_TRANSFER_SETTINGS_SUCCESS]: getTransferSettingsSuccess,
  [TransferedAssetSettingsActions.UPDATE_TRANSFER_SETTINGS_SUCCESS]: getTransferSettingsSuccess,
  [CompanyActions.OM_GET_TRANSFER_CONFIRMATION_SETTINGS_SUCCESS]: getTransferConfirmationSettingsSuccess,
  [HiltiAssetStatusActions.GET_HILTI_ASSET_STATUS_SETTINGS_SUCCESS]: getHiltiAssetStatusSettingsSuccess,
  [HiltiAssetStatusActions.UPDATE_HILTI_ASSET_STATUS_SUCCESS]: getHiltiAssetStatusSettingsSuccess,
  [FieldRequestConstants.GET_FIELD_REQUEST_SETTINGS_SUCCESS]: getFieldRequestSettingsSuccess,
  [FieldRequestConstants.UPDATE_FIELD_REQUEST_SETTINGS_SUCCESS]: getFieldRequestSettingsSuccess,
  [CompanyActions.OM_SET_ADDITIONAL_SETTINGS]: setAdditionalSettings,
});
export default companyReducers;
