import { SortComponent } from "am-web-ui-shared/components";
import React from "react";
// @ts-ignore
import { SortList } from "TARGET_BUILD/config";
import { getPageSize } from "../../../../utils/apiUtils";
import { ServiceStatus } from "../../serviceFormController/serviceFormEnum";
import { IServiceSortProps } from "./iServicesSummarySortProps";

const PAGE_SIZE = getPageSize();

export class ServicesSummarySortComponent extends React.Component<IServiceSortProps, {}> {
  /**
   * @description - Function build query param based on selection and sort type.
   * @param sortObj - Its the selected sort object
   * @param sortType - Its enum - ASCENDING | DESCENDING.
   */
  getSortedField = (sortObj, sortType: string) => {
    const orderQuery = `${sortType}${sortObj.name}`;
    this.setState({ selectedSortField: sortObj });
    const sortQuery = { sortField: sortObj.name || sortObj.sortField, sortType };
    const queryObj = {
      limit: PAGE_SIZE,
      offset: 0,
      order_by: orderQuery,
    };
    this.props.setSortQuery(sortQuery);
    this.props.getSortedServiceList(queryObj);
  };
  getList = () => {
    return this.props.serviceStatus === ServiceStatus.OPEN
      ? SortList.ASSET_SERVICE.OPEN_SORT_LIST
      : SortList.ASSET_SERVICE.HISTORY_SORT_LIST;
  };
  render() {
    const { closeMenu, showSortingModal, t } = this.props;
    const selectedSortFiled = this.props.sortQuery ? this.props.sortQuery : SortList.ASSET_SERVICE.DEFAULT_SORT;
    return (
      <SortComponent
        list={this.getList()}
        onFieldSelected={this.getSortedField}
        closeMenu={closeMenu}
        showSortingModal={showSortingModal}
        selectedSortField={selectedSortFiled}
        t={t}
      />
    );
  }
}

/**
 * @exports ManufacturersList - Exporting component "ManufacturersList" using the connect with the store.
 */

export default ServicesSummarySortComponent;
