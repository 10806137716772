import React from "react";
import styled from "styled-components";
import { IMoreFiltersProps } from "./iIMoreFiltersProps";

// @ts-ignore
import iconFilter from "./filter.svg";

/**
 * Wrapper box for the component.
 */
const StyledBox = styled("div")`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  padding: 12px;
  background-color: #f3f2f0;
  border: 2px solid #b7b3aa;
  white-space: nowrap;
  cursor: pointer;
`;

/**
 * Down arrow icon and aligned to the right of the select control.
 */
const StyledIcon = styled("img")`
  height: 24px;
  width: 18px;
  text-align: center;
`;

/**
 * Button element which will hold the list of more filters.
 */
export const StyledButton = styled("button")`
  margin-left: 8px;
  max-width: 180px;
  color: #524f53;
  border: none;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: 100%;
  background-color: #f3f2f0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

/**
 * MoreFilters component holds a simple input element of type button, It has filter icon on its left.
 */
class MoreFilters extends React.PureComponent<IMoreFiltersProps> {
  render() {
    const { id, value, name, disabled, style, ...props } = this.props;

    return (
      <StyledBox style={style} title={value}>
        <StyledIcon src={iconFilter} />
        <StyledButton id={id} name={name} disabled={disabled} {...props}>
          {value}
        </StyledButton>
      </StyledBox>
    );
  }
}

export default MoreFilters;
