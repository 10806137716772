import React from "react";
import styled from "styled-components";
import Field from "../Field/field";
import FormInput from "./formInput";
import IInputProps from "./iInputProps";

const StyledInput = styled.input`
  border: 2px solid
    ${(props) =>
      props["data-error"] ? props.theme.colors.base.hiltiRedError : props.theme.colors.base.heavyConcrete60};
  width: 100%;
  height: 48px;
  padding-left: 16px;
`;

class Input extends React.PureComponent<IInputProps> {
  onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    const { onKeyPress } = this.props;
    if (onKeyPress) {
      onKeyPress(event);
    }
  }

  render() {
    const { value, name, label, disabled, onChange, placeholder, maxLength, ref, error } = this.props;

    return !this.props.isFormControl ? (
      <Field name={name} htmlFor={name} label={label} error={error}>
        <StyledInput
          id={name}
          name={name}
          value={value}
          disabled={disabled ? true : false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event)}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => this.onKeyPress(event)}
          maxLength={maxLength}
          placeholder={placeholder}
          ref={ref}
          data-error={error}
        />
      </Field>
    ) : (
      <FormInput
        id={this.props.id || this.props.name}
        name={this.props.name}
        label={this.props.label}
        type={this.props.type}
        placeholder={this.props.placeholder}
        validation={this.props.validation}
        required={this.props.required}
        selectedValue={this.props.value}
        disabled={this.props.disabled}
        onSelectChange={this.props.onChange}
        onBlur={this.props.onBlur}
        ref={this.props.ref}
        hasButton={this.props.hasButton}
        onButtonClick={this.props.onButtonClick}
        buttonImage={this.props.buttonImage}
        showSuccess={this.props.showSuccess}
        successMessage={this.props.successMessage}
      />
    );
  }
}

export default Input;
