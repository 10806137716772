import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M14.7334034,15 L16.9205016,19.6168491 L18.7775614,16.6527171 L20.288036,19.070008 L22.0028201,19.070008 L22.0028201,20.68312 L19.4177355,20.68312 L18.7775614,19.6803117 L16.7457636,23 L14.7334034,18.730467 L13.774355,20.68312 L10.7,20.68312 L10.7,19.070008 L12.7752687,19.070008 L14.7334034,15 Z M14,1 L16,3 L13,6 L16,9 L19,6 L21,8 L18,13 L13,13 L5,21 L1,17 L9,9 L9,4 L14,1 Z"></path>
    </g>
  </svg>
);

export default SVG;
