import React from "react";
import { UpfrontExpansionPanel } from "../../../../../components/DetailsPanel/index";
import {
  assetGroup,
  assetStatus,
  assetTemplateName,
  assetUsageConditionDate,
  assetUsageConditionState,
  category,
  description,
  ExternalAssetId,
  hiltiOwnerName,
  inventoryCode,
  previousSerialNumber,
  replacementSerialNumber,
  scanCodeAndType,
  serialNumber,
  smartTagActivationStatus,
  geoTagSerialNumber,
} from "./detailComponents";
import {
  assetState,
  friendlyNameComponent,
  hiltiAsset,
  manufacturerAndModel,
  normalList,
  statusComponent,
  idleTimeDuration,
  geoTagSerialNumberSummary,
} from "./summaryComponents";

/**
 * @description - This is the main section present below the asset image on the asset details panel.
 */
export const mainSection = (assetDetailsData, config = { hasAssetUsageConditionState: true }) => {
  assetDetailsData = assetDetailsData || {};
  const { hasAssetUsageConditionState } = config;

  let detailComponentsList = [
    category,
    scanCodeAndType,
    geoTagSerialNumber,
    smartTagActivationStatus,
    inventoryCode,
    ExternalAssetId,
    hiltiOwnerName,
    assetTemplateName,
    assetStatus,
    assetGroup,
    serialNumber,
    previousSerialNumber,
    replacementSerialNumber,
    description,
  ];
  if (hasAssetUsageConditionState) {
    detailComponentsList = [...detailComponentsList, assetUsageConditionState, assetUsageConditionDate];
  }
  return (
    <UpfrontExpansionPanel
      panelId="mainSection"
      detailsData={assetDetailsData}
      summaryComponents={[
        manufacturerAndModel,
        friendlyNameComponent,
        statusComponent,
        assetState,
        normalList,
        geoTagSerialNumberSummary,
        hiltiAsset,
        idleTimeDuration,
      ]}
      detailComponents={detailComponentsList}
      associatedRowId={assetDetailsData?.id}
    />
  );
};
