import React from "react";

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="72"
    height="72"
    viewBox="0 0 72 72"
  >
    <defs>
      <path
        id="path-1"
        d="M72 0v72H0V0h72zM3 50.41V69l18.593.001a36.04 36.04 0 01-9.966-6.506l-.022.022-2.122-2.122.022-.022A36.04 36.04 0 013 50.41zM36 57a20.919 20.919 0 01-13.752-5.128L13.75 60.37A32.88 32.88 0 0036 69a32.88 32.88 0 0022.25-8.63l-8.498-8.498A20.919 20.919 0 0136 57zm26.495 3.373l.022.022-2.122 2.122-.022-.022A36.04 36.04 0 0150.407 69L69 69l.001-18.593a36.04 36.04 0 01-6.506 9.966zM3 36a32.88 32.88 0 008.63 22.25l8.498-8.498A20.919 20.919 0 0115 36c0-5.26 1.933-10.067 5.128-13.752L11.63 13.75A32.88 32.88 0 003 36zm57.37-22.25l-8.498 8.498A20.919 20.919 0 0157 36c0 5.26-1.933 10.067-5.128 13.752l8.499 8.498A32.88 32.88 0 0069 36a32.88 32.88 0 00-8.63-22.25zM36 38.12L24.377 49.744A17.928 17.928 0 0036 54c4.43 0 8.487-1.6 11.623-4.256L36 38.121zM18 36c0 4.43 1.6 8.487 4.256 11.623L33.879 36 22.256 24.377A17.928 17.928 0 0018 36zm31.744-11.623L38.121 36l11.623 11.623A17.928 17.928 0 0054 36c0-4.43-1.6-8.487-4.256-11.623zM36 18c-4.43 0-8.487 1.6-11.623 4.256L36 33.879l11.623-11.623A17.928 17.928 0 0036 18zM69 3H50.41a36.04 36.04 0 019.963 6.505l.022-.022 2.122 2.122-.022.022A36.04 36.04 0 0169 21.593L69 3zM21.59 3H3v18.59a36.04 36.04 0 016.505-9.963l-.022-.022 2.122-2.122.022.022A36.04 36.04 0 0121.59 3zM36 3a32.88 32.88 0 00-22.25 8.63l8.498 8.498A20.919 20.919 0 0136 15c5.26 0 10.067 1.933 13.752 5.128l8.498-8.499A32.88 32.88 0 0036 3z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-474 -120)">
        <g transform="translate(474 120)">
          <mask id="mask-2" fill="#fff">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use fill="#B7B3AA" xlinkHref="#path-1"></use>
          <path d="M0 0H72V72H0z" mask="url(#mask-2)"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
