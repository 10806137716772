/**
 * @description Action constants to be used for HILTI ASSET STATUS
 *
 * format: {feature}_{action}
 */

const HiltiAssetStatusActionConstants = {
  GET_HILTI_ASSET_STATUS_SETTINGS: "GET_HILTI_ASSET_STATUS_SETTINGS",
  GET_HILTI_ASSET_STATUS_SETTINGS_SUCCESS: "GET_HILTI_ASSET_STATUS_SETTINGS_SUCCESS",
  UPDATE_HILTI_ASSET_STATUS_SETTINGS: "UPDATE_HILTI_ASSET_STATUS_SETTINGS",
  UPDATE_HILTI_ASSET_STATUS_SUCCESS: "UPDATE_HILTI_ASSET_STATUS_SUCCESS",
};

export default HiltiAssetStatusActionConstants;
