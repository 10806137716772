/**
 * @description Action constants to be used for deletion settings
 *
 * format: {feature}_{action}
 */

const TransferedAssetSettingsConstants = {
  GET_TRANSFER_SETTINGS: "GET_TRANSFER_SETTINGS", // action to get deletion settings
  GET_TRANSFER_SETTINGS_SUCCESS: "GET_TRANSFER_SETTINGS_SUCCESS",
  UPDATE_TRANSFER_SETTINGS: "UPDATE_TRANSFER_SETTINGS",
  UPDATE_TRANSFER_SETTINGS_SUCCESS: "UPDATE_TRANSFER_SETTINGS_SUCCESS",
};

export default TransferedAssetSettingsConstants;
