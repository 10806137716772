const ConfirmTransferActionConstants = {
  GET_CONFIRM_TRANSFER_LIST: "GET_CONFIRM_TRANSFER_LIST",
  GET_CONFIRM_RECALL_LIST: "GET_CONFIRM_RECALL_LIST",
  GET_CONFIRM_TRANSFER_LIST_RESPONSE: "GET_CONFIRM_TRANSFER_LIST_RESPONSE",
  GET_CONFIRM_RECALL_LIST_RESPONSE: "GET_CONFIRM_RECALL_LIST_RESPONSE",
  GET_CONFIRM_TRANSFER_COUNT: "GET_CONFIRM_TRANSFER_COUNT",
  GET_CONFIRM_TRANSFER_COUNT_RESPONSE: "GET_CONFIRM_TRANSFER_COUNT_RESPONSE",
  SET_CONFIRM_RECALL_LIST: "SET_CONFIRM_RECALL_LIST",
  SET_CONFIRM_RECALL_CHECKBOX: "SET_CONFIRM_RECALL_CHECKBOX",
  RESET_CONFIRM_RECALL_LIST_RESPONSE: "RESET_CONFIRM_RECALL_LIST_RESPONSE",
};

export default ConfirmTransferActionConstants;
