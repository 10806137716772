import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import styled from "styled-components";
import ISliderProps from "./iSlider";

/**
 * @const StyledUpfrontExpansionPanel - Styling the Expansion Panel container.
 */
const StyledUpfrontExpansionPanel = styled(Accordion)`
  && {
    width: 98%;
    margin: 0;
    box-shadow: none;
    /* This is the container for the summary components */
    div[data-exp-pnl="upfront"] > div:first-child {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    /* Fixing the expansion icon to a apecific position on the summary section */
    div[data-exp-pnl="upfront"] > div:nth-child(2) {
      top: 28px;
      right: 15px;
      margin: 0;
      padding-right: 17px;
      height: 24px;
      width: 24px;
    }
    ::before {
      background-color: transparent;
    }
  }
`;

/**
 * @const StyledUpfrontExpansionPanelSummary - Styling the Expansion Panel Summary.
 */
export const StyledUpfrontExpansionPanelSummary = styled(AccordionSummary)`
  && {
    padding: 16px 16px 16px 0px;
    display: flex;
    flex-direction: column;
    align-items: unset;
    div:last-child {
      padding-bottom: 0;
      padding-right: 0;
    }
  }
  &&& {
    min-height: unset;
    height: auto;
  }
`;

/**
 * @const StyledUpfrontExpansionPanelDetails - Styling the Expansion Panel Details.
 */
export const StyledUpfrontExpansionPanelDetails = styled(AccordionDetails)`
  && {
    display: flex;
    flex-direction: column;
    background: ${(prosp) => prosp.theme.detailsPanel.detailBackground};
    border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
    padding-left: 0px;
  }
`;

/**
 * @description - This is a upfront expansion panel that holds the summary and details panel.
 * User can render "n" number of components into the summary and details sections by passing the components thru props.
 * By default the panel will be collapsed.
 *
 * @const UpfrontExpansionPanel
 * @interface ISliderProps
 */

const Slider = (props: ISliderProps) => {
  const { panelId, detailComponents, summaryComponents, detailsData } = props;
  return (
    <StyledUpfrontExpansionPanel data-testid="styledUpfrontExpansionPanel" id={panelId}>
      <StyledUpfrontExpansionPanelSummary data-exp-pnl="upfront">
        {summaryComponents.map((item, index) => (
          <React.Fragment key={`keyUEPSummary_${index}`}>{item(detailsData)}</React.Fragment>
        ))}
      </StyledUpfrontExpansionPanelSummary>
      <StyledUpfrontExpansionPanelDetails>
        {detailComponents.map((item, index) => (
          <React.Fragment key={`keyUEPDetails_${index}`}>{item(detailsData)}</React.Fragment>
        ))}
      </StyledUpfrontExpansionPanelDetails>
    </StyledUpfrontExpansionPanel>
  );
};

/**
 * @export Slider - Upfront Expansion Panel.
 */
export default Slider;
