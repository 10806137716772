import Typography from "@mui/material/Typography";
import React from "react";

export const CustomTypography = (props) => {
  const { type, title, id, content, style } = props;
  const typoProps: any = {};
  typoProps.id = id;
  typoProps.style = style;
  if (type === "image") {
    typoProps.children = <img title={title} src={content} alt="" />;
  } else if (type === "text") {
    typoProps.children = content;
    typoProps.title = title;
  }
  return <Typography {...typoProps} />;
};
