import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2057.000000, -1442.000000)" fill="#524F53">
        <g transform="translate(2053.000000, 1438.000000)">
          <path
            d="M21,16 L21,19 L24,19 L24,21 L21,21 L21,24 L19,24 L19,21 L16,21 L16,19 L19,19 L19,16 L21,16 Z M14.9899187,14.0121996 L17,14.808 L17,17 L14,17 L14,19.999 L4,20 L4,16 L8.9965099,14.0121996 L14.9899187,14.0121996 Z M12,4 C14.209139,4 16,5.790861 16,8 C16,10.209139 14.209139,12 12,12 C9.790861,12 8,10.209139 8,8 C8,5.790861 9.790861,4 12,4 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
