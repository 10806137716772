const workflowDropEvents = {
  ADD_ASSET_WORKFLOW_DROP: "add_asset_workflow_closed",
  EDIT_ASSET_WORKFLOW_DROP: "edit_asset_workflow_closed",
  ADD_LOCATION_WORKFLOW_DROP: "add_location_workflow_closed",
  EDIT_LOCATION_WORKFLOW_DROP: "edit_location_workflow_closed",
  ADD_WORKER_WORKFLOW_DROP: "add_worker_workflow_closed",
  EDIT_WORKER_WORKFLOW_DROP: "edit_worker_workflow_closed",
  ADD_QTY_ITEMS_WORKFLOW_DROP: "add_qty_items_workflow_closed",
  EDIT_QTY_ITEMS_WORKFLOW_DROP: "edit_qty_items_workflow_closed",
  QTY_ITEMS_ADD_ALLOCATION_WORKFLOW_DROP: "qty_itmes_add_alloc_workflow_closed",
  QTY_ITEMS_EDIT_ALLOCATION_WORKFLOW_DROP: "qty_itmes_edit_alloc_workflow_closed",
  QTY_ITEMS_BULK_EDIT_WORKFLOW_CLOSED: "qty_items_bulk_edit_workflow_closed",
  QUICK_TRANSFER_WORKFLOW_CLOSED: "quick_transfer_workflow_closed",
  TRANSFER_WORKFLOW_CLOSED: "transfer_workflow_closed",
  CONFIRM_TRANSFER_WORKFLOW_CLOSED: "confirm_transfer_workflow_closed",
  COMPLETE_REQUEST_FULLY: "fully_complete_field_request",
  COMPLETE_REQUEST_PARTIALLY: "partially_complete_field_request",
  DECLINE_REQUEST: "decline_field_request",
  SELECT_FIELD_REQUEST_TAB: "select_field_request_tab",
  CLOSE_FIELD_REQUEST: "close_field_request",
};

const featureUsageEvents = {
  ADD_ASSET_SUCCESS: "add_asset_success",
  DELETE_ASSET_SUCCESS: "delete_asset_success",
  DELETE_ASSET_FAILED: "delete_asset_failed",
  TRANSFER_SUBMIT_SUCCESS: "transfer_submit_success",
  TRANSFER_SUCCESS_OPEN_ASSET_LIST: "transfer_success_open_asset_list",
  QUICK_TRANSFER_SUCCESS: "quick_transfer_success",
  EDIT_ASSET_SUCCESS: "edit_asset_success",
  ADD_QTY_ITEMS_SUBMIT_SUCCESS: "add_qty_items_submit_success",
  QTY_ITEMS_ADD_ALLOC_SUBMIT_SUCCESS: "qty_items_add_alloc_submit_success",
  QTY_ITEMS_QUICK_TRANSFER_SUCCESS: "qty_items_quick_transfer_success",
  QTY_ITEMS_QUICK_TRANSFER_PARTIAL_SUCCESS: "qty_items_quick_transfer_partial_success",
  QTY_ITEMS_QUICK_STOCK_CHANGE_SUCCESS: "qty_items_quick_stock_change_success",
  EDIT_QTY_ITEMS_SUBMIT_SUCCESS: "edit_qty_items_submit_success",
  QTY_ITEMS_BULK_EDIT_SUCCESS: "qty_items_bulk_edit_success",
  ADD_LOCATION_SUBMIT_SUCCESS: "add_location_submit_success",
  EDIT_LOCATION_SUBMIT_SUCCESS: "edit_location_submit_success",
  DELETE_LOCATION_SUCCESS: "delete_locations_success",
  DELETE_LOCATION_FAILED: "delete_location_failed",
  ADD_WORKER_SUBMIT_SUCCESS: "add_worker_submit_success",
  ADD_WORKER_SUBMIT_FAILED: "add_worker_submit_failed",
  EDIT_WORKER_SUBMIT_SUCCESS: "edit_worker_submit_success",
  EDIT_WORKER_SUBMIT_FAILED: "edit_worker_submit_failed",
  DELETE_WORKER_SUCCESS: "delete_worker_success",
  DELETE_WORKER_FAILED: "delete_worker_failed",
  ADD_ASSET_FAILED: "add_asset_failed",
  SUBMIT_TRANSFER_FAILED: "submit_transfer_failed",
  QUICK_TRANSFER_FAILED: "quick_transfer_failed",
  ADD_QTY_ITEMS_SUBMIT_FAILED: "add_qty_items_submit_failed",
  QTY_ITEMS_ADD_ALLOC_SUBMIT_FAILED: "qty_items_add_alloc_submit_failed",
  QTY_ITEMS_QUICK_TRANSFER_FAILED: "qty_items_quick_transfer_failed",
  QTY_ITEMS_QUICK_STOCK_CHANGE_FAILED: "qty_items_quick_stock_change_failed",
  EDIT_QTY_ITEMS_SUBMIT_FAILED: "edit_qty_items_submit_failed",
  QTY_ITEMS_BULK_EDIT_FAILED: "qty_items_bulk_edit_failed",
  ADD_LOCATION_SUBMIT_FAILED: "add_location_submit_failed",
  EDIT_LOCATION_SUBMIT_FAILED: "edit_location_submit_failed",
  EDIT_ASSET_FAILED: "edit_asset_failed",
  CONFIRM_TRANSFER_SUBMIT_SUCCESS: "confirm_transfer_submit_success",
  CONFIRM_TRANSFER_SUBMIT_FAILED: "confirm_transfer_submit_failed",
  ADD_WORKER_MANAGE_PASSWORD_SUBMIT_SUCCESS: "add_worker_manage_password_submit_success",
  ADD_WORKER_MANAGE_PASSWORD_SUBMIT_FAILED: "add_worker_manage_password_submit_failed",
  ADD_WORKER_SET_APPLICATION_ACCESS: "add_worker_set_application_access",
  ADD_WORKER_SELECT_EMAIL_BUTTON: "add_worker_select_email_button",
  ADD_WORKER_SELECT_USERNAME_BUTTON: "add_worker_select_username_button",
  ADD_WORKER_MANAGE_PASSWORD: "add_worker_manage_password",
  EDIT_WORKER_CHANGE_PASSWORD: "edit_worker_change_password",
  WORKERS_SEARCH_STARTED: "workers_search_started",
  WORKERS_SEARCH_RESULT_LOADED: "workers_search_result_loaded",
  BULK_SERVICE_COMPLETION_UPLOAD: "bulk_service_completion_upload",
  BULK_SERVICE_COMPLETION_UPLOAD_ERROR: "bulk_service_completion_upload_error",
  MANUAL_SERVICE_COMPLETION: "manual_service_completion",
  MANUAL_SERVICE_COMPLETION_ERROR: "manual_service_completion_error",
};

const otherEvents = {
  ADD_QTY_ITEMS_INITIATED: "add_qty_items_initiated",
  EDIT_QTY_ITEMS_INITIATED: "edit_qty_items_initiated",
  ADD_ALLOCATION_FROM_MANAGE_ALLOCATIONS: "add_alloc_from_manage_allocs",
  QTY_ITEMS_MANAGE_ALLOC_ADD_TO_TRANSFER_CART: "qty_items_manage_alloc_add_to_transfer_cart",
  QTY_ITEMS_ADD_TO_TRANSFER_CART: "qty_items_add_to_transfer_cart",
  QTY_ITEMS_MANAGE_ALLOC_QUICK_TRANSFER: "qty_items_manage_alloc_quick_transfer",
  QTY_ITEMS_QUICK_TRANSFER_INITITATED: "qty_items_quick_transfer_inititated",
  QTY_ITEMS_QUICK_STOCK_CHANGE_INITIATED: "qty_items_quick_stock_change_initiated",
  QTY_ITEMS_QUICK_STOCK_CHANGE_ITEM_STATUS: "qty_items_quick_stock_change_item_status",
  QTY_ITEMS_DELETE: "qty_items_delete",
  QTY_ITEMS_BULK_EDIT: "qty_items_bulk_edit",
  AAD_LOCATION_INITIATED: "add_location_initiated",
  EDIT_LOCATION_FORM_OPEN: "edit_location_form_open",
  AAD_WORKER_INITIATED: "add_worker_initiated",
  EDIT_WORKER_INITIATED: "edit_worker_initiated",
  MANAGE_SERVICES_VIA_ADD_ASSET: "manage_services_via_add_asset",
  SELECT_MANUFACTURER_ADD_ASSET: "select_manufacturer_add_asset",
  DELETE_ASSET_INITIATED: "delet_asset_initiated",
  ADD_ASSET_SEARCH_RESULT_LOADED: "add_asset_search_result_loaded",
  ADD_ASSET_TO_TRANSFER_CART: "add_asset_to_transfer_cart",
  ADD_MULTIPLE_ASSET_TO_TRANSFER_CART: "add_multiple_asset_to_transfer_cart",
  VIEW_ITEMS_TRANSFER_CART: "view_items_transfer_cart",
  ASSETS_QUICK_TRANSFER_INITITATED: "assets_quick_transfer_inititated",
  EDIT_ASSET_INITIATED: "edit_asset_initiated",
  ADD_UNIQUE_ASSET: "add_unique_asset",
  ADD_LABELS: "add_labels",
  SEE_ALERT_DETAILS: "see_alert_details",
  DISMISS_ALERTS: "dismiss_alerts",
  OPEN_ALERT_SETTING: "open_alert_settings",
  OPEN_ALERT_LIST: "open_alert_list",
  SEE_TRANSFER_ALERT_LIST: "see_transfer_alert_list",
  QTY_ITEMS_SEARCH_LOADED: "qty_items_search_loaded",
  UNABLE_PERFORM_ACTION: "unable_perform_action",
  ADD_QTY_ITEMS_SELECT_MANUFACTURER: "add_qty_items_select_manufacturer",
  ADD_QTY_ITEMS_SELECT_STOCK_UNIT: "add_qty_items_select_stock_unit",
  IDEL_TOOLS_ADD_TO_TRANSFER_CART: "idle_tools_add_to_transfer_cart",
  IDEL_TRACKABLE_ASSETS_PAGE_LOAD: "idle_trackable_assets_page_load",
  EDIT_WORKER_CHANGE_ROLE: "edit_worker_change_role",
};

const filterFeatureUsageEvents = {
  ASSET_GROUP_FILTER: "asset_group_filter",
  LABEL_FILTER: "label_filter",
  DEFAULT_LOCATION_FILTER: "default_location_filter",
  DEFAULT_LOCATION_TYPE_FILTER: "default_location_type_filter",
  STATUS_FILTER: "status_filter",
  TAG_BATTERY_STATUS_FILTER: "tag_battery_status_filter",
  SCAN_CODE_TYPE_FILTER: "scan_code_type_filter",
  ASSET_TYPE_FILTER: "asset_type_filter",
  RESPONSIBLE_EMPLOYEE_FILTER: "responsible_employee_filter",
  OWNER_FILTER: "owner_filter",
  CURRENT_LOCATION_FILTER: "current_location_filter",
  CURRENT_LOCATION_TYPE_FILTER: "current_location_type_filter",
  CURRENT_LOCATION_MANAGER_FILTER: "current_location_manager_filter",
  IDLE_TIME_FILTER: "idle_time_filter",
  MANUFACTURER_FILTER: "manufacturer_filter",
  MODEL_FILTER: "model_filter",
  COST_CODE_FILTER: "cost_code_filter",
  OWNERSHIP_TYPE_FILTER: "ownership_type_filter",
  HILTI_ASSET_OWNER_FILTER: "hilti_asset_owner_filter",
  CONNECTED_CHARGER_FILTER: "connected_charger_filter",
  MONTHLY_FLEET_RATE_FILTER: "monthly_fleet_rate_filter",
  LOAN_TOOL_CLAIM_FILTER: "loan_tool_claim_filter",
  THEFT_INSURANCE_FILTER: "theft_insurance_filter",
  PURCHASE_CURRENCY_FILTER: "purchase_currency_filter",
  PURCHASE_DATE_FILTER: "purchase_date_filter",
  VENDOR_FILTER: "vendor_filter",
  HILTI_ASSET_STATUS_FILTER: "hilti_asset_status_filter",
  REPLACEMENT_COST_FILTER: "replacement_cost_filter",
  CATEGORY_FILTER: "category_filter",
  ASSET_STATE_FILTER: "asset_state_filter",
  RENTAL_RETURN_FILTER: "rental_return_filter",
  ATTACHMENTS_FILTER: "attachments_filter",
  IMAGES_FILTER: "images_filter",
  TRANSFERRED_FILTER: "transferred_filter",
  REGISTERED_BY_FILTER: "registered_by_filter",
  SMART_TAG_STATUS_FILTER: "smart_tag_status_filter",
  LOCATIONS_FILTER: "locations_filter",
  AUC_STATE_FILTER: "auc_state_filter",
  FILTER_SEARCH_VALUE: "filter_search_value",
  FILTER_SHOW_MORE: "filter_show_more",
  MULTIPLE_FILTERS_APPLED: "multiple_filters_applied",
  ALLOCATION_TYPE_FILTER: "allocation_type_filter",
  STOCK_PER_ALLOCATION_FILTER: "stock_per_allocation_filter",
  DEFAULT_TO_CONSUMED_TRANSFER_FILTER: "default_to_consumed_transfer_filter",
  MAIN_ALLOCATION_FILTER: "main_allocation_filter",
  ALLOCATION_STATUS: "status_filter",
  TYPE_FILTER: "location_type_filter",
  LOCATION_MANAGER_FIRST_NAME_FILTER: "location_manager_first_name_filter",
  LOCATION_MANAGER_LAST_NAME_FILTER: "location_manager_last_name_filter",
  COST_CENTER_FILTER: "cost_center_filter",
  LOCATION_CENTER_FILTER: "location_state_filter",
};

const chargesFeatureUsageEvents = {
  EXPORT_ASSET_COST: "export_asset_cost",
  DELETE_PLUG_COST: "delete_plug_cost",
  UPLOAD_ASSET_COSTS_TEMPLATE: "upload_asset_costs_template",
  UPLOAD_ASSET_COSTS_NOT_TEMPLATE: "upload_asset_costs_not_template",
  UPLOAD_QUANTITY_ITEM_COSTS: "upload_quantity_item_costs",
  VIEW_COST_LOCATIONS: "view_cost_locations",
  EDIT_COST_LOCATION: "edit_cost_location",
  ADD_HOLIDAYS_TO_COST_LOCATION: "add_holidays_to_cost_location",
  EDITING_ASSET_COSTS_COMPANY_SETTINGS: "editing_asset_costs_company_settings",
  GENERATE_ASSET_COSTS_REPORT: "generate_asset_costs_reprt",
  ADD_DAILY_COSTS_QTY_ITEM: "add_daily_costs_qty_item",
  EDIT_DAILY_COSTS_QTY_ITEM: "editing_daily_costs_qty_item",
  OPEN_QTY_ITEM_COSTS_LIST: "open_quantity_item_cost_list",
  DOWNLOAD_IMPORT_TEMPLATE_QTY_ITEM_COSTS: "download_import_template_qty_item_costs",
  UPLOAD_TEMPLATE_QTY_ITEM_COSTS: "upload_template_qty item_costs",
  EXPORT_QTY_ITEM_COSTS: "export_qty_item_costs",
};

const GTMConstants = {
  EVENTS: {
    ...workflowDropEvents,
    ...featureUsageEvents,
    ...otherEvents,
    ...filterFeatureUsageEvents,
    ...chargesFeatureUsageEvents,
  },
};
export default GTMConstants;
