import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
import { Dispatch } from "redux";
import { closeMoreFilter } from "../../../actions/moreFilterAction";
import {
  getAlertCount,
  toggleAlertList,
  getSuggestedTransfersCount,
} from "../../../modules/alert/actions/alertNotificationAction";
import { getConfirmTransferCount } from "../../../modules/alert/actions/confirmTransfer";
import { storageClearAll } from "../../../utils/storageUtils";
import { logout } from "../actions/logoutActionRoot";
import { userData, userMeData } from "../../../actions/userProfileActionRoot";
import IHeaderProps from "../iHeaderProps";
import HeaderComponent from "../presentational/header";
import { getFieldRequestsCount } from "../../../modules/fieldRequest/receiver/actions/fieldRequestReceiverActions";
import { getGatewayAssetsCount } from "TARGET_BUILD/common/modules/asset/actions/assetsActionRoot";
import WarningIcon from "TARGET_BUILD/ui-assets/icons/WarningIcon.svg";
import { IconComponent, IconName, SquareIcon } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import ModuleName from "TARGET_BUILD/common/utils/enums/moduleNameEnum";

const BannerWrapper = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  color: #603e10;
  font-size: 14px;
  font-weight: 700;
  background: #fdf5e7;
`;

const BannerText = styled.div`
  padding-top: 2px;
  margin-left: 8px;
`;

class Header extends React.Component<IHeaderProps> {
  constructor(props) {
    super(props);
  }

  logout = () => {
    this.props.logout();
    storageClearAll();
  };

  renderBanner = () => {
    const { companyDetails, translate } = this.props;
    const gracePeriodModules = companyDetails?.listOfModuleInGrace || [];
    const proActiveModule = gracePeriodModules.find((module) => module.name === ModuleName.PROACTIVE_TRACKING);

    return (
      proActiveModule && (
        <BannerWrapper>
          <SquareIcon name={IconName.Warning} id="warningIcon" className="warning small" imageSize={16} />
          <BannerText>
            {translate("common:LICENSE_ALERT_TEXT", {
              date: LocaleManager.dateHelpers.getDateInTenantTimezone(proActiveModule?.endAt),
            })}
          </BannerText>
        </BannerWrapper>
      )
    );
  };
  render() {
    return (
      <>
        {this.renderBanner()}
        <HeaderComponent {...this.props} logout={this.logout} closeMoreFilter={this.props.closeMoreFilter} />
      </>
    );
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleAlertList: () => dispatch(toggleAlertList()),
  closeMoreFilter: () => dispatch(closeMoreFilter()),
  getAlertCount: () => dispatch(getAlertCount()),
  getConfirmTransferCount: () => dispatch(getConfirmTransferCount()),
  logout: () => dispatch(logout()),
  userData: () => dispatch(userData()),
  userMeData: () => dispatch(userMeData()),
  getFieldRequestsCount: () => dispatch(getFieldRequestsCount()),
  getGatewayAssetsCount: () => dispatch(getGatewayAssetsCount()),
  getSuggestedTransfersCount: () => dispatch(getSuggestedTransfersCount()),
});

export const mapStateToProps = (state: any) => ({
  activeAlertCount: state.HeaderCount && state.HeaderCount.activeAlertCount,
  showAlertListPopup: state.HeaderCount && state.HeaderCount.showAlertListPopup,
  confirmTransferCount: state.HeaderCount && state.HeaderCount.confirmTransferCount,
  transferConfirmationPolicy: HelperLodash.get(state.Company, "transferConfirmationPolicy"),
  userMe: state.UserReducer && state.UserReducer.userMeData,
  userProfile: state.UserReducer && state.UserReducer.userProfileData,
  fieldRequestsCount: state.HeaderCount && state.HeaderCount.fieldRequestsCount,
  isFieldRequestEnabled: HelperLodash.get(state.Company, "isFieldRequestEnabled"),
  suggestedTransfersCount: state.HeaderCount?.suggestedTransfersCount,
  companyDetails: state.Company?.companyDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
