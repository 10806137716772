/**
 * @description This file is for api hitting using
 * axios for the logout web UI user.
 */

import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";

/**
 * @description Function to logout webUI user
 * @memberof HeaderModule
 * @param {String} - Token
 */
export const logoutWebUI = async (): Promise<any | IErrorResponse> => {
  return Gateway.post(`/auth/web/logout`, null, null, null) as unknown as any;
};
