import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  ContentBody,
  ContentFooter,
  ContentWrapper,
  DialogBox,
  DialogOuter,
  DialogScroll,
  LabelName,
  Wrapper,
} from "../prompt/promptStyle";
import Button from "../../elements/Button/button";
import ButtonTypeEnum from "../../elements/Button/buttonTypes";
import { addClassOnBody, removeClassOnBody } from "../../helpers/domHelper";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import { IFormPromptProps, IFormPromptState } from "./iFormPrompt";
interface IWrapper {
  modalSize: number;
}
const wrapperElementWidth = (props: IWrapper) => `${props.modalSize}px`;
export const DialogBoxWrapper = styled.div`
  width: ${wrapperElementWidth};
  border: 12px solid transparent;
  box-sizing: content-box;
`;
const PanelHeaderTitle = styled.h4`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: 19px;
  margin-bottom: 16px;
`;

const SubTitle = styled.span`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

/**
 * Add button tooltip
 */
const SaveButtonTooltip = styled.div`
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.black};
  position: absolute;
  right: 0px;
  top: 50px;
  padding: 16px;
  font-size: ${(props) => props.theme.fontSize.small};
  :before {
    position: absolute;
    top: -12px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom: 6px solid ${(props) => props.theme.colors.base.black};
    z-index: 9999;
    content: "";
    right: 48px;
  }
`;

const modalRoot = document.getElementById("modalRoot");

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

/**
 * @class ConfirmConfirmation
 * @extends {React.PureComponent<IPromptProps>}
 */
class Prompt extends React.PureComponent<IFormPromptProps, IFormPromptState> {
  static defaultProps = {
    modalSize: 620,
    showCrossIcon: false,
    showFooter: true,
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.saveButtonDisabled !== nextProps.saveButtonDisabled) {
      return {
        saveButtonDisabled: nextProps.saveButtonDisabled,
      };
    }
    return null;
  }
  state = {
    saveButtonDisabled: true,
  };
  el = document.createElement("div");

  /**
   * Show ConfirmConfirmation once this component is mounted
   *
   * @memberof ConfirmConfirmation
   */
  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
    addClassOnBody("modal-open");
  }

  /**
   * Hide ConfirmConfirmation once this component is unmounted
   *
   * @memberof ConfirmConfirmation
   */
  componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
      modalRoot.style.backgroundColor = "";
    }
    removeClassOnBody("modal-open");
  }

  /**
   * @description - Render the header of the prompt.
   */
  renderHeader = () => {
    const { id, header, showCrossIcon } = this.props;
    return (
      <HeaderWrapper>
        {header ? <PanelHeaderTitle id={`${id}Header`}>{header}</PanelHeaderTitle> : null}
        {showCrossIcon ? this.renderCrossIcon() : null}
      </HeaderWrapper>
    );
  };

  /**
   * @description - Render the crossIcon of the prompt.
   */
  renderCrossIcon = () => {
    const { id, panelCloseHandler } = this.props;
    return <SquareIcon id={id} onClick={panelCloseHandler} name={IconName.Cross} className="close" />;
  };

  /**
   * @description - Render the title of the prompt.
   */
  renderTitle = () => {
    const { id, subTitle, title } = this.props;
    return title ? (
      <LabelName id={`${id}Title`}>
        {title}
        {subTitle && <SubTitle id={`${id}SubTitle`}>{subTitle}</SubTitle>}
      </LabelName>
    ) : null;
  };

  /**
   * @description - Render the tooltip of the prompt.
   */
  renderTooltip = () => {
    const { id, saveButtonTooltip } = this.props;
    return saveButtonTooltip ? <SaveButtonTooltip id={`${id}Tooltip`}>{saveButtonTooltip}</SaveButtonTooltip> : null;
  };

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(
      <>
        <DialogOuter id="dialogOuter">
          <DialogScroll>
            <DialogBoxWrapper modalSize={this.props.modalSize}>
              <DialogBox>
                <Wrapper>
                  <ContentWrapper>
                    {this.renderHeader()}
                    {this.renderTitle()}
                    {children ? <ContentBody>{children}</ContentBody> : null}
                  </ContentWrapper>
                </Wrapper>
                {this.props.showFooter && (
                  <ContentFooter>
                    <Button
                      buttonType={ButtonTypeEnum.secondary}
                      onClick={this.props.handleClose}
                      ariaLabel={this.props.cancelButtonText}
                    >
                      {this.props.cancelButtonText}
                    </Button>

                    <Button
                      buttonType={this.state.saveButtonDisabled ? ButtonTypeEnum.disabled : ButtonTypeEnum.cta}
                      onClick={this.props.handleSave}
                      ariaLabel={this.props.saveButtonText}
                    >
                      {this.props.saveButtonText}
                    </Button>
                    {this.renderTooltip()}
                  </ContentFooter>
                )}
              </DialogBox>
            </DialogBoxWrapper>
          </DialogScroll>
        </DialogOuter>
      </>,
      this.el,
    );
  }
}
export default Prompt;
