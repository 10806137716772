import React from "react";
import styled from "styled-components";
import { IconName } from "../icons/iconEnum";
import { SquareIcon } from "../icons/iconWrapper";
import { IBannerProps } from "./iBannerProps";

const BannerWrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.base.steel};
  min-height: 56px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
`;
const Message = styled.div`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: left;
  margin: 16px 16px 16px 0;
`;

const IconContainer = styled.div`
  margin: 16px;
`;

const Cross = styled.div`
  .icon {
    cursor: pointer;
  }
  margin: 16px;
`;

const IconTextWrapper = styled.div`
  display: flex;
`;

const Banner = React.memo((props: IBannerProps) => {
  const { handleClose, message, icon, className } = props;
  return (
    <BannerWrapper>
      <IconTextWrapper>
        <IconContainer>
          <SquareIcon name={icon ? icon : IconName.Info} className={className ? className : "grey"} />
        </IconContainer>
        <Message>{message}</Message>
      </IconTextWrapper>
      {handleClose && (
        <Cross onClick={handleClose}>
          <SquareIcon name={IconName.Cross} className="icon grey" />
        </Cross>
      )}
    </BannerWrapper>
  );
});

export default Banner;
