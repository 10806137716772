import type { CSSObject, Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Button, { buttonClasses } from '@mui/material/Button';
import type { ButtonProps } from '@mui/material/Button';

export const getColorVariant = (
  theme: Theme,
): Record<ButtonProps['color'] & 'disabled', CSSObject> => ({
  primary: {
    backgroundColor: theme.colors.hilti[50],
    backgroundHoverColor: theme.colors.hilti[100],
    borderColor: theme.colors.hilti[50],
    borderHoverColor: theme.colors.hilti[100],
    color: theme.colors.white[200],
  },
  secondary: {
    backgroundColor: theme.colors.steel[600],
    backgroundHoverColor: theme.colors.black[600],
    borderColor: theme.colors.steel[600],
    borderHoverColor: theme.colors.black[600],
    color: theme.colors.white[200],
  },
  tertiary: {
    backgroundColor: theme.colors.concrete[100],
    backgroundHoverColor: theme.colors.concrete[300],
    borderHoverColor: theme.colors.concrete[400],
    borderColor: theme.colors.concrete[300],
    color: theme.colors.steel[600],
  },
  disabled: {
    backgroundColor: theme.colors.steel[50],
    backgroundHoverColor: 'inherit',
    borderColor: 'inherit',
    color: theme.colors.white[200],
  },
});

export default styled(Button)(({ theme, color: _color, disabled }) => {
  const color = disabled ? 'disabled' : _color;
  const colorVariant = getColorVariant(theme)[color];

  return {
    fontFamily: theme.fonts.bold.fontFamily,
    backgroundColor: colorVariant.backgroundColor,
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
      backgroundColor: colorVariant.backgroundHoverColor,
      borderColor: colorVariant.borderHoverColor,
    },
    borderColor: colorVariant.borderColor,
    color: colorVariant.color,
    paddingLeft: 16,
    paddingRight: 16,
    height: 48,
    lineHeight: 1.5,
    fontSize: 16,
    borderRadius: 0,
    textTransform: 'inherit',
    [`.${buttonClasses.startIcon} > svg`]: {
      color: theme.colors.white[200],
    },
    [`&.${buttonClasses.disabled}`]: {
      color: colorVariant.color,
      borderColor: colorVariant.backgroundColor,
    },
  };
});
