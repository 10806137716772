import React from "react";
const SVG = () => (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="AM-3.0---Web-Icons" transform="translate(-3481.000000, -531.000000)">
        <g id="ic_Commodity" transform="translate(3480.000000, 530.000000)">
          <polygon id="Shape" points="0 0 32 0 32 32 0 32" />
          <path
            d="M23.016132,13.6683621 L30.1859661,18.1469633 L30.1859661,26.3131107 L22.9458766,30.6666667 L15.7598455,26.3650957 L8.60620853,30.6666667 L1.33333333,26.3131107 L1.33333333,18.1469633 L8.50316738,13.6885514 L8.50316738,5.85581918 L15.7760426,1.33333333 L23.016132,5.85581918 L23.016132,13.6683621 Z M15.7889708,3.72327801 L11.2345327,6.49617282 L15.7889708,9.32824865 L20.5492921,6.49617282 L15.7889708,3.72327801 Z M21.1706386,7.40903231 L16.4590197,10.2360037 L16.4590197,15.8356371 L21.1706386,13.1088105 L21.1706386,7.40903231 Z M8.61913673,16.0144221 L4.06469868,18.7873169 L8.61913673,21.6193927 L13.379458,18.7873169 L8.61913673,16.0144221 Z M14.0008045,19.7001764 L9.28918562,22.5271477 L9.28918562,28.1267811 L14.0008045,25.3999546 L14.0008045,19.7001764 Z M22.9588048,16.0144221 L18.4043668,18.7873169 L22.9588048,21.6193927 L27.7191261,18.7873169 L22.9588048,16.0144221 Z M28.3404726,19.7001764 L23.6288537,22.5271477 L23.6288537,28.1267811 L28.3404726,25.3999546 L28.3404726,19.7001764 Z"
            id="Combined-Shape"
            fill="#E7E5E2"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
