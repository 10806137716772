import React from "react";
import ReactDOM from "react-dom";
import shortid from "shortid";
import styled from "styled-components";
import { IMultipleNotificationProps } from "./iMultipleNotificationProps";
import { NotificationModalToastr } from "./notificationModalToastr";

const MultipleTosterContainer = styled.div`
  min-height: 56px;
  position: fixed;
  z-index: 999999;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 16px;
  #popperContainer {
    background-color: ${(props) => props.theme.colors.base.black};
    box-shadow:
      0 2px 12px 0 ${(props) => props.theme.colors.base.greyrgba},
      inset 0 1px 0 0 ${(props) => props.theme.colors.base.heavyConcrete};
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    margin-top: 16px;
    width: 540px;
  }
`;

const toastPortal = document.getElementById("toastPortal");

class MultipleNotificationModal extends React.PureComponent<IMultipleNotificationProps> {
  el = document.createElement("div");

  /**
   * Show NotificationModal once this component is mounted
   *
   * @memberof NotificationModal
   */
  componentDidMount = () => {
    if (toastPortal) {
      toastPortal.appendChild(this.el);
    }
  };

  /**
   * Hide NotificationModal once this component is unmounted
   *
   * @memberof NotificationModal
   */
  componentWillUnmount = () => {
    if (toastPortal) {
      toastPortal.removeChild(this.el);
    }
  };

  getNotifications = () => {
    const { data, icons, t } = this.props;
    return data.map((toastrData, index) => {
      return (
        <NotificationModalToastr
          key={shortid.generate()}
          notificationIcon={icons[toastrData.type]}
          message={this.props.translate ? t(toastrData.toastrMessage) : toastrData.toastrMessage}
          handleClose={this.props.handleClose}
          className={`styleNotify${index}`}
          gtmEvent={toastrData.gtmEvent}
        />
      );
    });
  };

  render() {
    const Notifications = this.getNotifications();
    return ReactDOM.createPortal(<MultipleTosterContainer>{Notifications}</MultipleTosterContainer>, this.el);
  }
}

export default MultipleNotificationModal;
