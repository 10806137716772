import { ToastrType } from "../../../actions/toastrAction";
import { WithTranslation } from "react-i18next";
import { ActionItem } from "../../../utils/enums/actionItemEnum";
import ICurrency from "./iServiceBase";
import { ICodeValue } from "../../../../common/models/asset/assetModel";
import { IServiceAttachmentsInterface } from "./iAssignedServiceDetail";

/**
 * @interface IProps - Interface for the service list component.
 *
 * @prop {interface} IServicesList - Reusing the interface.
 */
export interface IServiceListContainerProps extends WithTranslation {
  readonly distanceUnitValue?: ICodeValue;
  readonly appendServiceList?: (row: any) => void;
  readonly assetId: number;
  readonly clearAssetServiceList?: () => void;
  readonly closeMoreFilter?: () => void;
  readonly service?: any;
  readonly showManageServices?: { showServiceList: boolean; showServiceForm: boolean };
  readonly showServiceForm?: (isOpen: boolean) => void;
  readonly showServiceList?: (isOpen: boolean) => void;
  readonly fetchAssetServiceList?: (offset: number, id: number, status: string, filter?: string) => void;
  readonly isAssetDetails?: boolean;
  readonly isSortListUpdate?: boolean;
  readonly getServiceTemplateList?: any;
  readonly actionType?: ActionItem;
  readonly showToaster?: any;
  readonly assetFieldsData?: any;
  readonly assetLocationsList?: any;
  readonly currenciesList?: ICurrenciesList[];
  readonly assetStatusList?: any;
  readonly manufacturerList?: any;
  readonly responsibleWorkersList?: any;
  readonly getAssignServiceDetail?: (assetId, serviceId, serviceStatus) => void;
  readonly assignHistoryService?: (assetId: number, historyServiceData, serviceStatus?: string) => void;
  readonly scheduleDate?: string;
  readonly completionDate?: string;
  readonly submitForm?: (formName) => void;
  readonly onFormchange?: (serviceFormName: string, field: string, value: any) => any;
  readonly costCurrency?: string;
  readonly serviceOperationError?: any;
  readonly assignServiceSuccess?: boolean;
  readonly clearServiceData?: () => void;
  readonly clearAssignedServiceData: () => void;
  readonly serviceValue?: string;
  readonly filterSettings?: any;
  readonly searchComponent?: any;
  readonly clearAppliedFilter?: any;
  readonly contentWidth?: any;
  readonly dependencies?: any;
  readonly selectedFilters?: any;
  readonly serviceStatusType?: any;
  readonly assignedServicesList?: any;
  readonly getAssignedServicesList?: (assetId: number, actionType?: string) => void;
  readonly assignedServiceDetail?: any;
  readonly selectedAssetDetail?: any;
  readonly getAssetDetails?: (id?: number) => void;
  readonly columns?: any;
  readonly sortQuery?: any;
  readonly ignoreSortProperties?: any;
  readonly setSortQuery?: any;
  readonly onSelectRow?: (id, source, isMultiSelect) => void;
  readonly onClearRowSelection?: (id, source) => void;
  readonly editAssignedService?: (
    assetId: number,
    historyServiceData,
    serviceId: string,
    serviceStatus: string,
    isRecurrentDateChange?: boolean,
  ) => void;
  readonly completeAssignedService?: (assetId: number, serviceData: any) => void;
  readonly removeAssetService: (assetId: number, serviceId: number, serviceType: string) => void;
  readonly removeAddAssetService: (id, serviceStatus) => void;
  readonly closeServices?: () => void;
  readonly getServiceTemplateDetails?: any;
  readonly totalRecords: number;
  readonly resetQueryObjToDefault?: () => void;
  readonly clearAssignedServiceList?: () => void;
  readonly showNoRecordFoundView?: boolean;
  readonly isMoreFilterOpen?: boolean;
  readonly getCurrenciesList?: () => void;
  readonly setServiceFilterQuery?: (filterQuery) => void;
  readonly defaultCurrency?: ICurrency;
  readonly serviceTemplateDetails: any;
  readonly clearServiceTemplateDetails: () => void;
  readonly completeServiceResponse: any;
  readonly clearCompleteAssignedServiceData: () => void;
}

export interface ICurrenciesList {
  readonly name: string;
  readonly code: string;
}
export interface IServiceListContainerState {
  readonly serviceStatus?: any;
  readonly selectedFilters?: any;
  readonly rowData?: any;
  readonly provider?: [];
  readonly clearAppliedFilter?: boolean;
  readonly selectedServiceInfo: IserviceListModel;
}
export interface IServiceListViewProps extends WithTranslation {
  readonly distanceUnitValue?: ICodeValue;
  readonly serviceList: IList;
  readonly showManageServices?: { showServiceList: boolean; showServiceForm: boolean };
  readonly showServiceForm?: (isOpen: boolean) => void;
  readonly showServiceList?: (isOpen: boolean) => void;
  readonly handleServiceList?: (item: any) => void;
  readonly getServiceTemplateList?: any;
  readonly showToaster?: (type: ToastrType, message: string) => void;
  readonly currenciesList?: ICurrenciesList[];
  readonly assetId?: number;
  readonly scheduleDate?: string;
  readonly completionDate?: string;
  readonly serviceStatus?: string;
  readonly submitForm?: (formName) => void;
  readonly costCurrency?: string;
  readonly getServicesList?: (status: string) => void;
  readonly clearServiceData?: () => void;
  readonly serviceOperationError?: any;
  readonly assignServiceSuccess?: boolean;
  readonly handleRowClick?: (data) => void;
  readonly assignHistoryService?: (assetId: number, historyServiceData, serviceStatus: string) => void;
  readonly serviceValue?: string;
  readonly onFormchange?: (serviceFormName: string, field: string, value: any) => any;
  readonly actionType?: ActionItem;
  readonly loadPaginatedData?: (offset?: number) => void;
  readonly isServiceStatus?: boolean;
  readonly assetFieldsData?: any;
  readonly handleCloseClick?: (id) => void;
  readonly assignedServiceDetail?: any;
  readonly moduleName?: string;
  readonly getAssignServicesList?: (id?: number) => void;
  readonly assignedServicesList?: any;
  readonly showNoRecordFoundView?: boolean;
  readonly searchString?: string;
  readonly resetHandler?: () => void;
  readonly getCurrenciesList?: () => void;
  readonly defaultCurrency?: ICurrency;
  readonly clearAssignedServiceList?: () => void;
  readonly getServiceTemplateDetails?: (id: number, module?: any) => any;
  readonly serviceTemplateDetails?: any;
  readonly clearServiceTemplateDetails: () => void;
  readonly allAssignedService?: any;
  readonly isMoreFilterOpen?: boolean;
  readonly selectedServiceInfo?: IserviceListModel;
}

export const ServiceStatusStates = {
  COMPLETED: "COMPLETED",
  OVERDUE: "OVERDUE",
  UPCOMING: "UPCOMING",
};

export interface IList {
  list?: IserviceListModel[];
  limit?: number;
  offset?: number;
  totalRecords?: number;
}
export default interface IserviceListModel {
  readonly attachmentCount: string | number;
  readonly description: string;
  readonly frequency: string;
  readonly id: number;
  readonly scheduleDate: string;
  readonly serviceName: string;
  readonly serviceState: string;
  readonly name?: string;
  readonly serviceStatus?: string;
  readonly notificationNumber?: number;
  readonly serviceAttachmentsCount?: number;
  readonly assetTemplateCount?: number;
  readonly source?: string;
  readonly serviceResult?: string;
  readonly isLifeLong?: boolean;
  readonly isSystemService: boolean;
  readonly serviceAttachments?: IServiceAttachmentsInterface[];
}
