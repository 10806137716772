import { SharedUtils } from "am-web-ui-shared/helpers";
import IFilterFieldModel from "TARGET_BUILD/common/models/customView/iFilterFieldModel";
import { getGTMFiltersFields } from "./utils/analyticsUtils";

export enum EntityTitle {
  QUANTITY_ITEM = "quantity_item",
}

export const COMBINED_FILTER = "combined_filter";
export const CLICK_QTY_ITEMS_MODULE = "click_qty_items_module";

export enum FiltersMapping {
  currentLocationName = 1,
  responsibleEmployeeName,
  allocationStatus,
  allocationType,
  groupName,
  labelNames,
  manufacturerName,
  model,
  canConsume,
  mainAllocation,
  stockQuantity,
}

export const trackQtyCombinedFilters = (filters: IFilterFieldModel[]) => {
  const field = getGTMFiltersFields(filters, FiltersMapping);
  SharedUtils.pushDataToGA(COMBINED_FILTER, null, {
    entity: EntityTitle.QUANTITY_ITEM,
    count: filters.length,
    field,
  });
};

export const trackQTYModuleClicked = () => {
  SharedUtils.pushDataToGA(CLICK_QTY_ITEMS_MODULE);
};
