import React from "react";
import { UpfrontExpansionPanel } from "../../../../../components/DetailsPanel/index";
import DetailComponents from "./detailComponents";
import SummaryComponents from "./summaryComponents";

// To hide the arrow icon in the case there is no data avilable in the details section.
const isEnabledDetailsSection = (data) => {
  if (!data) {
    return false;
  }
  return data.responsibleEmployeeDesignation || data.responsibleEmployeeEmail || data.responsibleEmployeeLanguage;
};

/**
 * @description - Returns responsible worker section.
 * @param - accepts assetDetailsData.
 */
export const responsibleWorker = (assetDetailsData) => {
  return (
    <UpfrontExpansionPanel
      panelId="responsibleWorkerSection"
      detailsData={assetDetailsData}
      summaryComponents={SummaryComponents}
      detailComponents={DetailComponents}
      associatedRowId={assetDetailsData.id}
      isEnable={!!isEnabledDetailsSection(assetDetailsData)}
    />
  );
};

/**
 * @description - This is the responsible worker section on the asset details panel.
 * @param - accepts assetDetailsData as argument for forming responsible worker section.
 */
export const responsibleWorkerSection = (assetDetailsData) => {
  return assetDetailsData ? responsibleWorker(assetDetailsData) : responsibleWorker({ id: null });
};

/**
 * @exports responsibleWorkerSection - Responsible worker section that renders both detail and summary components.
 */
export default responsibleWorkerSection;
