// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { Trans } from "react-i18next";
import { FriendlyName, RedTextIcon, Status } from "../../../../../components/DetailsPanel/index";
import RenderLabels from "../../../../../components/labels/renderLabels";
import { StatusWrapper } from "../../../../../utils/styledUtils";
import { addedLabelsOverflowIndex } from "../../actionItems/labels/labelsConfig";
import { shouldShowAssetBatteryStatus } from "../../../assetTagBatteryStatusRules";
import { getGatewayStatus } from "../../../../../modules/assetTracking/components/smartInventory/presentational/smartInventoryUtils";
import { IAssetDetailsData } from "../../../../../models/asset/assetModel";
import { AssetSubType } from "../../../../../utils/enums/assetEnum";
import { ChildGatewayStatus } from "./childGatewayStatus";
import TrackingStatusBadges from "TARGET_BUILD/common/modules/asset/components/lostAndFound/trackingStatusBadges";
/**
 * @description - Manufacture and Model of the asset.
 */
export const manufacturerAndModel = (data) => {
  const diameterInfo = data.diameter && data.diameterUnit ? `(${data.diameter} ${data.diameterUnit})` : "";
  return data && (data.manufacturerName || data.model) ? (
    <RedTextIcon
      RedNormalId="txtManufacturer"
      RedNormalText={`${diameterInfo} ${data.manufacturerName || ""} ${data.model || ""}`}
    />
  ) : null;
};

/**
 * @description - Friendly name of the asset.
 */
export const friendlyNameComponent = (data) => {
  return <FriendlyName boldId="txtName" boldText={data.name || ""} />;
};

/**
 * @description - Status of the asset.
 * @param - data is row object
 * @param - isExpanded is boolean value to know panel is expanded or not
 */
export const statusComponent = (data: Partial<IAssetDetailsData>, isExpanded: boolean) => {
  return (
    <StatusWrapper>
      <>
        {data.assetStatusResponse && assetStatus(data.assetStatusResponse)}
        {getGatewayStatus(data) && assetStatus(data.gatewayStatus)}
        {<ChildGatewayStatus asset={data} />}
        {data && data.labelNames && data.labelNames.length > 0 && (
          <RenderLabels
            labelNames={data.labelNames}
            isExpanded={isExpanded}
            addedLabelsOverflowIndex={addedLabelsOverflowIndex}
          />
        )}
        {shouldShowAssetBatteryStatus(data) ? assetBatteryStatus(data.batteryStatus) : null}
        <TrackingStatusBadges monitoringState={data?.monitoringState} />
      </>
    </StatusWrapper>
  );
};

/**
 * @description - status handle to display the label badge.
 * @param - status is display the type of staus
 */
const assetStatus = (statusdata) => {
  return <Status id={`txtAssetStatus${statusdata.value}`} statusCode={statusdata.code} statusText={statusdata.value} />;
};

/**
 * @description - Battery level to display the label badge.
 * @param - status is display the level of battery
 */
const assetBatteryStatus = (batteryStatusData) => {
  return batteryStatusData ? (
    <Status
      id={`txtAssetBatteryStatus${batteryStatusData.code}`}
      statusCode={batteryStatusData.code}
      statusText={batteryStatusData.value}
    />
  ) : null;
};

/**
 * @description - Wrapper for the "Scan code / Type" and "Inventory number"
 */
export const StyledNormalListText = styled("div")`
  width: auto;
  word-break: break-all;
  margin: 2px;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.colors.base.steel};
  line-height: 20px;
`;

const AssetState = styled("div")`
  width: auto;
  word-break: break-all;
  margin: 2px;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.colors.base.steel};
  line-height: 20px;
`;

/**
 * @description - List of additional details.
 */
export const normalList = (data) => {
  const scanCode = data.scanCode || "";
  const scanCodeType =
    data.scanCodeTypeDetails && data.scanCodeTypeDetails.value ? ` / ${data.scanCodeTypeDetails.value}` : "";
  const inventoryNumber = data.inventoryNumber || "";

  return (
    <div>
      <StyledNormalListText>{`${scanCode}${scanCodeType}`}</StyledNormalListText>
      <StyledNormalListText>{inventoryNumber}</StyledNormalListText>
    </div>
  );
};

export const geoTagSerialNumberSummary = (data: IAssetDetailsData) => {
  const isTagAttached = data?.geoTagDetails?.isTagAttached;
  const geoTagSerialNumber = data?.geoTagDetails?.serialNumber || "";
  return isTagAttached && geoTagSerialNumber ? (
    <StyledNormalListText>
      {`${geoTagSerialNumber} / `}
      <Trans>{"common:LABEL_GEO_TAG"}</Trans>
    </StyledNormalListText>
  ) : null;
};

/**
 * @description - show asset state.
 */
export const assetState = (data) => {
  const assetStateVal = data && data.assetStateResponse && data.assetStateResponse.value;
  return assetStateVal && <AssetState>{assetStateVal}</AssetState>;
};

/**
 * @description - show hilti asset.
 */
export const hiltiAsset = (data) => {
  const hiltiAssetVal =
    data && (data.hiltiIntegratedAsset || data.isSapAsset) ? <Trans>{"assets:HILTI_ASSET"}</Trans> : "";
  return hiltiAssetVal && <StyledNormalListText>{hiltiAssetVal}</StyledNormalListText>;
};

/**
 * @description - show idle asset time duration.
 */
export const idleTimeDuration = (data: Partial<IAssetDetailsData>, _isPanelExpanded, t) => {
  const subType = data?.assetSubType?.code;
  if (subType === AssetSubType.Van || subType === AssetSubType.HeavyEquipment) {
    return null;
  }
  const timeDuration =
    data && (data.idleTimeDuration || data.idleTimeDuration === 0)
      ? data.idleTimeDuration > 1
        ? `${data.idleTimeDuration} ${t("common:DAYS_IDLE")}`
        : `${data.idleTimeDuration} ${t("common:DAY_IDLE")}`
      : "";
  return timeDuration && <StyledNormalListText>{timeDuration}</StyledNormalListText>;
};
