// @ts-ignore
import { Dialog, Footer, IconName } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { withTranslation } from "react-i18next";
// @ts-ignore
import ExpandRight from "TARGET_BUILD/ui-assets/icons/expand_right.svg";
import { SimpleExpansionPanel } from "../../../../../components/DetailsPanel/index";
import AssetTemplateDialogContainer from "./assetTemplateDialogContainer";
import IAssetTemplateDetailProps from "./iAssetTemplateDetailProps";
/**
 * @description - This is the asset template  detailsection on the asset details panel.
 */

const SectionWrapperDiv = styled.div`
  position: relative;
`;
const ImgWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 24px;
`;
const Img = styled.img`
  margin: 0 -2px;
`;

const DivWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 128px);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconDiv = styled.div`
  display: flex;
  align-self: center;
`;

class AssetTemplateSection extends React.Component<IAssetTemplateDetailProps, { showModal: boolean }> {
  state = {
    showModal: false,
  };

  /** Open template detail dialog. */
  openTemplateDetails = () => {
    this.setState({ showModal: true });
  };

  /** close the template detail dialogue */
  closeTemplateDetails = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { assetDetailsData, t } = this.props;
    if (assetDetailsData.assetTemplateId) {
      return (
        <React.Fragment>
          <SectionWrapperDiv onClick={this.openTemplateDetails}>
            <ContentWrapper>
              <SimpleExpansionPanel
                panelId="pnlAssetTemplate"
                headingId="headTemplate"
                iconUrl={IconName.Template}
                boldId="lblTempalate"
                boldText={t("assets:LABEL_TEMPLATE_DETAIL")}
              />
              <IconDiv>
                <ImgWrapper>
                  <Img src={ExpandRight} />
                </ImgWrapper>
              </IconDiv>
            </ContentWrapper>
          </SectionWrapperDiv>
          {this.state.showModal ? (
            <Dialog
              containerId="assetTemplateDetailContainer"
              headerId="headerAssetTemplateContainer"
              onClose={this.closeTemplateDetails}
              headerText={t("assets:ASSET_TEMPLATE_DETAILS")}
            >
              <DivWrapper>
                <AssetTemplateDialogContainer
                  assetTemplateId={assetDetailsData.assetTemplateId}
                  onClose={this.closeTemplateDetails}
                />
              </DivWrapper>
              <Footer
                buttonType="cta"
                buttonText={t("common:DONE")}
                onClick={() => {
                  this.closeTemplateDetails();
                }}
              />
            </Dialog>
          ) : null}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
/**
 * @exports templateSection - Notes section that renders both header and detail components.
 */

export default withTranslation()(AssetTemplateSection);
