// eslint-disable-next-line unicorn/filename-case
import { AttachmentImageComponent } from "am-web-ui-shared/elements";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { DocumentType } from "./iUploadComponentProps";
import { IUploadPanelView } from "./iUploadPanelView";
import { LicenseEnums } from "../../utils/enums/licenseEnum";
import withLicenseContext from "TARGET_BUILD/licenseStrategy/withLicenseContext";

export const Note = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: 11px;
  font-weight: 300;
  line-height: 13px;
`;
export const AllowedFileMessage = styled.div`
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  color: ${(props) => props.theme.colors.base.steel};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 300;
  line-height: 14px;
  text-align: center;
  padding: 8px 0 0;
`;

const Title = styled.div`
  margin-top: 16px;
`;

const ReplaceImage = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-size: 28px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 34px;
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 65px;
  z-index: 1;
  background: rgba(248, 248, 248, 0.9);
`;
export class UploadComponentView extends React.Component<IUploadPanelView, any> {
  render() {
    const {
      name,
      uploadImage,
      allowedFileMessage,
      documentType,
      imageData,
      isDragActive,
      imageChangeHandler,
      t,
      maxSizeTitle,
      showProgress,
      isOnAssetsPage,
    } = this.props;
    return (
      <>
        {documentType === DocumentType.Image && imageData ? (
          <>
            <AttachmentImageComponent
              changeText={t("assets:CHANGE_TEXT")}
              removeText={t("common:REMOVE")}
              isFormComponent={true}
              name={name}
              currentImage={imageData}
              editImage={this.props.editImage}
              onRemove={this.props.onRemove}
            />
            {isDragActive && <ReplaceImage>{t("assets:REPLACE_TEXT")}</ReplaceImage>}
          </>
        ) : (
          <React.Fragment>
            <AttachmentImageComponent
              isFormComponent={true}
              name={name}
              onChange={documentType === DocumentType.Image ? imageChangeHandler : null}
              defaultImage={uploadImage}
            />
            {!showProgress ? (
              <>
                {this.props.licenseContext?.isAssetFormFieldDisabled(LicenseEnums.hideAssetImage) && isOnAssetsPage ? (
                  <Title>{t("assets:UPLOAD_FILE_FLEET")}</Title>
                ) : (
                  <Title>{t("assets:UPLOAD_FILE")}</Title>
                )}
                <AllowedFileMessage>{allowedFileMessage}</AllowedFileMessage>
                <Note>{t(maxSizeTitle)}</Note>
              </>
            ) : null}
          </React.Fragment>
        )}
      </>
    );
  }
}

export default withLicenseContext(UploadComponentView);
