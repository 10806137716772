// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import CrossBar from "TARGET_BUILD/ui-assets/icons/cross.svg";
import { IFullViewMapProps, IFullViewMapState } from "./model/iFullViewMap";

const FullViewWrapper = styled.div.attrs({
  id: "mapFullViewWrapper",
})`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
`;
const HeaderText = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 22px;
  text-align: left;
  display: inline-block;
`;
const CloseImg = styled.img.attrs({
  id: "fullViewImg",
})`
  width: 12px;
  height: 12px;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`;
const MapHeader = styled.div`
  height: 68px;
  width: 100%;
  position: relative;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow: 0 4px 0 0 ${(props) => props.theme.colors.base.heavyConcrete20};
  line-height: 4;
`;

const mapPortal = document.getElementById("fullMapPortal");

/**
 * @description Full view component to hold map in the browsers full view
 * @memberof FullViewMap
 */
class FullViewMap extends React.Component<IFullViewMapProps, IFullViewMapState> {
  fullMapDiv = document.createElement("div");

  componentDidMount = () => {
    if (mapPortal) {
      mapPortal.appendChild(this.fullMapDiv);
    }
  };

  componentWillUnmount = () => {
    if (mapPortal) {
      mapPortal.removeChild(this.fullMapDiv);
    }
  };

  state: IFullViewMapState = {
    isFullViewClosed: false,
  };

  /*
    Toggles full view mode when clicked on cross icon
  */
  closeFullViewMap = () => {
    this.setState({
      isFullViewClosed: true,
    });
  };

  render() {
    return ReactDOM.createPortal(this.renderFullViewMap(), this.fullMapDiv);
  }

  renderFullViewMap() {
    const { headerText } = this.props;
    return (
      <FullViewWrapper>
        <MapHeader>
          <div>
            <CloseImg onClick={this.closeFullViewMap} src={CrossBar} />
            <HeaderText> {headerText} </HeaderText>
          </div>
        </MapHeader>
        {this.props.children(this.state.isFullViewClosed)}
      </FullViewWrapper>
    );
  }
}

export default FullViewMap;
