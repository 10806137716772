import React from "react";
const SVG = () => (
  <svg width="21px" height="18px" viewBox="0 0 21 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-795.000000, -4180.000000)" fill="#524F53">
        <g transform="translate(792.000000, 4177.000000)">
          <path
            d="M17.4705627,13.5 L9,13.5 L9,10.5 L17.3284271,10.5 L14.6568542,7.82842712 L16.7781746,5.70710678 L23.1421356,12.0710678 L21.0208153,14.1923882 L16.7781746,18.4350288 L14.6568542,16.3137085 L17.4705627,13.5 Z M6,6 L6,18 L13,18 L13,21 L6,21 L3,21 L3,3 L6,3 L13,3 L13,6 L6,6 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
