import { Attachment } from "am-web-ui-shared/elements";
import { DownloadFileHandler } from "am-web-ui-shared/helpers";
import React from "react";
import { withTranslation } from "react-i18next";
import CommonApi from "../../api/commonApi";
import { ValidationConstants } from "../../utils/enums/validationConstants";
import { locales } from "../../utils/errorUtils";
import IAttachmentsPanelProps from "./iAttachmentsPanel";
import { FILE_SOURCE } from "../services/model/iAssignedServiceDetail";

export const fieldMaxLength = (t, max) => (values: any) => {
  const errors = [];
  if (values) {
    values.forEach((item) => {
      let error = null;
      if (item && item.displayName && item.displayName.length > max) {
        error = { displayName: `${t(locales.maxOf)} ${max} ${t(locales.charAllowed)}` };
      }
      errors.push(error);
    });
  }
  return errors.find((x) => x != null) ? errors : null;
};
export class AttachmentsPanel extends React.Component<IAttachmentsPanelProps> {
  downloadAttachement = (attachmentData) => {
    const { attachmentProperty } = this.props;
    const fileId = attachmentProperty ? attachmentProperty.fileId : "attachmentId";
    const fileName = attachmentProperty ? attachmentProperty.fileName : "fileName";

    if (attachmentData.fileSource === FILE_SOURCE.CLOUD) {
      DownloadFileHandler.downloadHCDocuments(CommonApi, attachmentData[`${fileName}`], attachmentData.path);
    } else {
      DownloadFileHandler.download(CommonApi, attachmentData[`${fileId}`], attachmentData[`${fileName}`]);
    }
  };

  render() {
    const { allowDownloadAttachment, t } = this.props;
    return (
      <Attachment
        isFormControl={true}
        name={this.props.name}
        placeholder={t("common:ENTER_DISPLAY_NAME")}
        validation={[fieldMaxLength(t, ValidationConstants.MAX_LENGTH.NUM_64)]}
        responseFormatMapper={this.props.responseFormatMapper}
        downloadAttachement={this.downloadAttachement}
        allowDownloadAttachment={allowDownloadAttachment}
      />
    );
  }
}

export default withTranslation()(AttachmentsPanel);
