import React, { useEffect, useState } from "react";
import { ButtonIconWrapper, CloseButtonWrapper } from "./UpgradeOnTrackDialogStyles";
import Button from "TARGET_BUILD/../../component-lib/src/core//button";
import { SquareIcon, IconName } from "TARGET_BUILD/../../shared";
import { useTranslation } from "react-i18next";
import IUpgradeOntrackReady from "./UpgradeOnTrackDialog";
import { getLockedFeatureInfo, LockedFeatures } from "TARGET_BUILD/common/api/lockedFeaturesInfoApi";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToastr, ToastrType } from "TARGET_BUILD/common/actions/toastrAction";
import {
  CLICK_UPGRADE_CALL_BACK,
  CLICK_UPGRADE_LEARN_MORE,
  trackUpgradeDialogEvents,
} from "TARGET_BUILD/analytics/events/upgradeOnTrackReady";

export const UpgradeOnTrackActionButtons = (props: IUpgradeOntrackReady) => {
  const [data, setData] = useState<Pick<LockedFeatures, "contactSalesUrl" | "learnMoreUrl">>({
    learnMoreUrl: "",
    contactSalesUrl: "",
  });
  const { t } = useTranslation();
  const key = "QTY_ITEMS";
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const response = await getLockedFeatureInfo(key);
      setData(response);
    } catch (error) {
      dispatch(showToastr(ToastrType.error, t(error.message)));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <CloseButtonWrapper>
        <Button id="closeBtn" color="tertiary" onClick={props.handleClose}>
          {t("common:CLOSE")}
        </Button>
      </CloseButtonWrapper>
      <Link
        className="link"
        id="secondary-link"
        to={{ pathname: data.learnMoreUrl }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          id="learnMore"
          sx={{ marginRight: 1 }}
          color="tertiary"
          onClick={() => trackUpgradeDialogEvents(CLICK_UPGRADE_LEARN_MORE)}
        >
          <ButtonIconWrapper>
            <SquareIcon name={IconName.OpenNew} className="icon small" imageSize={16} />
          </ButtonIconWrapper>
          {t("common:LEARN_MORE")}
        </Button>
      </Link>
      <Link
        className="link"
        id="secondary-link"
        to={{ pathname: data.contactSalesUrl }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button id="arrangeCallBack" onClick={() => trackUpgradeDialogEvents(CLICK_UPGRADE_CALL_BACK)}>
          <ButtonIconWrapper className="white-icon">
            <SquareIcon name={IconName.OpenNew} className="icon small" imageSize={16} />
          </ButtonIconWrapper>
          {t("common:ARRANGE_CALL_BACK")}
        </Button>
      </Link>
    </>
  );
};
