import React from "react";
import styled, { keyframes } from "styled-components";

export enum LoaderElementType {
  RED,
  GRAY,
}

interface IloaderElement {
  type?: LoaderElementType;
}

const rotateLoader = keyframes`
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
`;

export const LoaderEle = styled.div.attrs<IloaderElement>(() => ({
  id: "loaderEle",
}))<IloaderElement>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => {
    return props.type === LoaderElementType.GRAY
      ? props.theme.colors.base.lightGreyColor
      : props.theme.colors.base.hiltiRed;
  }};
  margin: 10px auto;
  animation: ${rotateLoader} 1.2s infinite ease-in-out;
  position: relative;
`;

export const LoaderElement = (props: IloaderElement) => {
  return <LoaderEle type={props.type} data-testid="loader" />;
};
export default LoaderElement;
