import React from "react";
const SVG = () => (
  <svg width="11px" height="18px" viewBox="0 0 11 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3242.000000, -2525.000000)" fill="#524F53">
        <g transform="translate(3235.000000, 2522.000000)">
          <polygon
            id="🎨Color"
            points="7.15055804 5 7.15055804 19 9 21 16 21 18 19 18 7 16 7 16 18 15 19 10 19 9 18 9 6 10.1695876 5 12 5 13 6 13 15 12 15 12 7 10 7 10 16 11 17 14 17 15 16 15 5 13 3 9 3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
