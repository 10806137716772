const ValidationConstants = {
  // To Verify alpha characters (A-Za-z), digits and "-" require for Postal Code(Add Worker)
  ALPHA_NUMERIC_HYPHEN: /^[a-z0-9-\s]+$/i,
  // To Verify alpha characters (A-Za-z) and digits without special characters
  ALPHA_NUMERIC_WITHOUT_SPECIAL_CHAR: /^[a-zA-Z0-9]+$/,
  // To Verify alpha characters (A-Za-z), digits and -, ., $, /, +, %,  and space require for Scan Code(Add Worker)
  ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR: /^[a-z0-9-.$/+% \s]+$/i,
  // To Verify alpha characters (A-Za-z), digits and -, ., _ , /, ,  require for Cost Code(Add Template)
  ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR_COSTCODE: /^[a-z0-9-./,_]+$/i,
  // To verify numeric character of total lenght and decimal upto 3 digit
  // DECIMAL_VALIDATION: /^\s*(?=.*[1-9])\d*(?:\.\d{1,3})?\s*$/,
  // To Verify Email(Add Worker)
  EMAIL_REGEX: /^([a-zA-Z0-9_\-'.]+)@((?!_)(?!-)(?!www.)[a-zA-Z0-9_\-\.]+)\.(\d*[a-zA-Z][a-zA-Z\d]*)$/,
  FILE_TYPE: ["png", "jpg", "jpeg", "jfif", "bmp", "gif"],
  ALLOWED_FILE_TYPES: ["pdf", "docx", "xlsx", "png", "jpg", "jpeg", "txt", "bmp", "rtf", "odt"],
  // to disallow comma in renaming label
  LABEL_WITHOUT_COMMA: /^[^,]+$/,
  MAX_LENGTH: {
    NUM_0: 0,
    NUM_1: 1,
    NUM_8: 8,
    NUM_5: 5,
    NUM_10: 10,
    NUM_12: 12,
    NUM_13: 13,
    NUM_18: 18,
    NUM_100: 100,
    NUM_1000: 1000,
    NUM_1024: 1024,
    NUM_128: 128,
    NUM_129: 129,
    NUM_132: 132,
    NUM_15: 15,
    NUM_16: 16,
    NUM_2: 2,
    NUM_20: 20,
    NUM_200: 200,
    NUM_2048: 2048,
    NUM_25: 25,
    NUM_255: 255,
    NUM_256: 256,
    NUM_360: 360,
    NUM_3: 3,
    NUM_30: 30,
    NUM_32: 32,
    NUM_36: 36,
    NUM_50: 50,
    NUM_60: 60,
    NUM_64: 64,
    NUM_99: 99,
    NUM_225: 225,
    NUM_188: 188,
    NUM_999: 999,
    NUM_400: 400,
  },
  DAYS: {
    NUM_7: 7,
    NUM_10: 10,
    NUM_15: 15,
    NUM_30: 30,
    NUM_90: 90,
    NUM_180: 180,
    NUM_365: 365,
    NUM_364: 364,
  },
  MIN_LENGTH: {
    NUM_1: 1,
  },
  MIN_VALUE: {
    NUM_0: 0,
  },
  MAX_VALUE: {
    NUM_999992D: 99999.99,
    NUM_200000: 200000,
  },
  MAX_CHAR_64: /^.{1,64}$/,
  MAX_CHAR_129: /^.{1,129}$/,
  NUMBER_REGEX: /^\d+$/,
  NUMBER_REGEX_MIN_ONE: /^0*[1-9]\d{0,2}$/,
  NUMBER_REGEX_PHONE_NUMBER: /^$|^([0-9() +-]*)$/,
  NUMBER_WITH_MAX_11_DIGITS_THREE_DECIMAL_PLACES: /^(?:\d{0,11}(\.\d{1,3})$)|^\d{0,11}$/,
  NUMBER_WITH_MAX_15_DIGITS_TWO_DECIMAL_PLACES: /^(?:\d{0,15}(\.\d{1,2})$)|^\d{0,15}$/,
  NUMBER_WITH_MAX_20_DIGITS_TWO_DECIMAL_PLACES: /^(?:\d{0,20}(\.\d{1,2})$)|^\d{0,20}$/,
  NUMBER_WITH_RANGE_1_TO_999: /^([1-9][0-9]{0,2}|999)$/,
  NUMBER_WITH_RANGE_1_TO_200000: /^([1-9]|[1-9][0-9]{1,4}|1[0-9]{5}|200000)$/,
  NUMBER_WITH_THREE_DECIMAL_PRECISION: /^(?:\d+|\d*\.\d{1,3})$/,
  NUMBER_WITH_TWO_DECIMAL_PLACES: /^(?:\d+|\d*\.\d{1,2})$/,
  // PERCENTAGE_BETWEEN_0_TO_100: /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/,
  URL_REGEX:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-z0-9.]+\.[a-z]{2,5}((?:(?:\:[0-9])?[0-9]?[0-9]?[0-9]?[0-9]?)|(?:(?:\:[0-9])?[0-9]?[0-9]?[0-9]?[0-9]?)(?:\/|\/[\s\S]*?))$/,
  // USERNAME_REGEX: /^([a-zA-Z0-9!@#$&()'^\-_`.+,/\".\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]+)$/,
  USERNAME_REGEX: /^([a-zA-Z0-9_\-'\.]+)$/,
  // only space allowed
  ALPHA_NUMERIC_WITHOUT_SPECIAL_CHAR_SPACE: /^[a-zA-Z0-9 ]+$/,
  NUMBER_WITH_TWO_DECIMAL_PLACES_WITH_PLUS_AND_MINUS_SIGN: /^[+-]?(?:\d+|\d*\.\d{1,2})$/,
  DECIMAL_COUNT: 3,
  CUSTOM_DATES: "customDates",
  HTML_TAGS: /<(.|\n)*?>/,
  LOCALE_REGEX: /^[A-Za-z]{2,4}([_-][A-Za-z]{4})?([_-]([A-Za-z]{2}|[0-9]{3}))?$/,
};

export { ValidationConstants };
