import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Prompt from "../prompt/prompt";
import { DownloadFileHandler, HelperLodash } from "../../helpers";
import { IconName } from "../icons/iconEnum";
import { IAsyncExportDialogProps, IAsyncExportDialogState } from "./iAsyncExportDialog";

const LabelName = styled.label`
  font-size: ${(props) => props.theme.fontSize.base};
  display: inline-block;
`;

const asyncExportDialog = document.getElementById("asyncExportDialog");

const FileTypeList = {
  TYPE: {
    XLS: "xls",
    XLSX: "xlsx",
    XLSX_TYPE: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    XLS_TYPE: "application/vnd.ms-excel",
  },
};

enum ExportStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  DOWNLOADED = "DOWNLOADED",
  FAILED = "FAILED",
}
class AsyncExportDialog extends React.Component<IAsyncExportDialogProps, IAsyncExportDialogState> {
  state: IAsyncExportDialogState = {
    showDialog: false,
  };
  el = document.createElement("div");
  startTimerInterval;

  constructor(props) {
    super(props);

    // This event listener will fire handleExportDialog when any change detected in local storage
    window.addEventListener("storage", this.handleExportDialog);

    // This event listener will fire when we close the tab, browser and refresh the tab, and remove
    // the "exportInfo" from the local storage
    window.addEventListener("beforeunload", this.clearExportInfoFromStorage);
  }

  componentDidMount() {
    this.clearExportInfoFromStorage();
    if (asyncExportDialog) {
      asyncExportDialog.appendChild(this.el);
    }

    this.startTimer();
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleExportDialog);
    window.removeEventListener("beforeunload", this.clearExportInfoFromStorage);
    if (this.startTimerInterval) {
      clearInterval(this.startTimerInterval);
    }
  }

  clearExportInfoFromStorage = () => localStorage.removeItem("exportInfo");

  /**
   * @description This method is used to handle the show/hide export dialog functionality
   * and start the polling for active module which is in the pipeline to download excel
   *
   * @returns undefined
   */
  startTimer = () => {
    this.startTimerInterval = setInterval(() => {
      const exportInfo = this.getExportInfo();
      const status = HelperLodash.get(this.exportPollingInfo(), "exportStatus");
      if (status && status !== ExportStatus.IN_PROGRESS) {
        this.setState({ showDialog: true });
      }

      Object.keys(exportInfo).forEach((moduleName) => {
        if (HelperLodash.get(exportInfo, [moduleName, "exportStatus"]) === ExportStatus.IN_PROGRESS) {
          this.props.startPolling(moduleName);
        }
      });
    }, this.props.delayTime);
  };

  /**
   * @description Returing object of export information
   *
   * @returns object
   */
  getExportInfo = () => JSON.parse(localStorage.getItem("exportInfo")) || {};

  /**
   * @description Returing export information of completed status
   *
   * @returns object
   */
  exportPollingInfo = () => {
    // We have single download at a time popup design for now that is why we are returning 0 index
    // Later we will change this logic according to multiple download and will return array of object
    const pollingInfo: any = Object.values(this.getExportInfo());

    return pollingInfo && pollingInfo[0];
  };

  /**
   * @description This method is used to handle the show/hide export dialog functionality in duplicate tabs
   *
   * @param storageEvent
   *
   * @returns undefined
   */
  handleExportDialog = (storageEvent) => {
    if (storageEvent.key === "exportInfo") {
      const exportPollingInfo = this.exportPollingInfo();

      if (HelperLodash.get(exportPollingInfo, "exportStatus") !== ExportStatus.IN_PROGRESS || !exportPollingInfo) {
        this.setState({ showDialog: !!storageEvent.newValue });
      }
    }
  };

  /**
   * @description This method is used to call download handler to download the document
   *
   * @returns undefined
   */
  downloadFile = () => {
    const { downloadDocument, t, showToaster } = this.props;
    const { fileName, documentId } = this.exportPollingInfo();
    DownloadFileHandler.downloadFile(
      downloadDocument,
      FileTypeList.TYPE,
      documentId,
      fileName,
      t,
      t("common:DOWNLOAD_STARTED"),
      showToaster,
      t("common:DOWNLOAD_ERROR"),
    );
  };

  /**
   * @description This method is used to remove "exportInfo" form the local storage and hide the export dialog.
   *
   * @returns undefined
   */
  handleClose = () => {
    this.clearExportInfoFromStorage();
    this.setState({ showDialog: false });
  };

  /**
   * @description Handle confirm
   *
   * @returns undefined
   */
  handleConfirm = () => {
    this.downloadFile();
    this.handleClose();
  };

  /**
   * @description Check if export status is complete
   *
   * @returns boolean
   */
  isStatusCompleted = (): boolean => {
    return HelperLodash.get(this.exportPollingInfo(), "exportStatus") === ExportStatus.COMPLETED;
  };

  /**
   * @description This method is used to render dialog with faild information
   *
   * @returns component
   */
  renderFailedPrompt = () => {
    const { t } = this.props;
    return (
      <Prompt
        crossIcon={true}
        headerWithCrossIcon={true}
        showCancel={false}
        icon={IconName.Alert}
        handleClose={this.handleClose}
        handleConfirm={this.handleClose}
        header={t("common:EXPORT_DIALOG_HEADER")}
        cancelLabel={t("common:CANCEL")}
        okLabel={t("common:OK")}
        className="error"
      >
        <LabelName>{this.props.t("common:EXPORT_FAILED")}</LabelName>
      </Prompt>
    );
  };

  /**
   * @description This method is used to render dialog to download the excel
   *
   * @returns component
   */
  renderSuccessPrompt = () => {
    const { t } = this.props;
    const exportInfo = this.exportPollingInfo();

    return (
      <Prompt
        icon={IconName.Download}
        handleClose={this.handleClose}
        handleConfirm={this.handleConfirm}
        header={t("common:EXPORT_DIALOG_HEADER")}
        cancelLabel={t("common:DISCARD")}
        okLabel={t("common:DOWNLOAD")}
      >
        <LabelName>
          <span className="bold-text">'{exportInfo.fileName}'</span>
          {t("common:EXPORT_DIALOG_CONFIRMATION")}
        </LabelName>
      </Prompt>
    );
  };

  /**
   * @description This method is used to render dialog
   *
   * @returns component
   */
  renderPrompt = () => {
    return this.isStatusCompleted() ? this.renderSuccessPrompt() : this.renderFailedPrompt();
  };

  render() {
    return ReactDOM.createPortal(
      this.state.showDialog && this.exportPollingInfo() ? this.renderPrompt() : null,
      this.el,
    );
  }
}

export default AsyncExportDialog;
