// @ts-ignore
import styled from "custom-styled-components";

/**
 * @const StyledNormalText - Styled component that is having text with roman font-familiy.
 * @interface INormalText
 */
const StyledNormalText = styled.div`
  font-family: ${(props) => (props.isBold ? props.theme.fontBold.fontFamily : props.theme.fontRoman.fontFamily)};
  font-size: ${(props) => (props.isSmall ? props.theme.fontSize.small : props.theme.fontSize.base)};
  font-weight: 300;
  color: ${(props) => props.theme.colors.base.steel};
  word-break: break-word;
  max-width: 370px;
  cursor: default;
  @media (max-width: 1280px) {
    max-width: 230px;
  }
`;

/**
 * @export StyledNormalText -  Styled component.
 */
export default StyledNormalText;
