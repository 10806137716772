import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import TransferCartModel from "../models/transferCart/transferCartModel";
import { deepCloneArray } from "../utils/commonUtils";
import {
  sessionStorageGetItem,
  sessionStorageGetObject,
  sessionStorageSetItem,
  sessionStorageSetObject,
} from "../utils/storageUtils";
import ModuleName from "./enums/moduleNameEnum";
import { TransferConfirmationPolicyEnum } from "../modules/transferSettings/transferConfirmationPolicyEnum";

/**
 * @description - check is item available from the items in the cart.
 * @param cartItem - items in the cart
 * @param item - item for check.
 * @param key - key for comparision.
 */
export const isItemAvailable = (cartItems = [], item, key) => {
  return cartItems.find((cartItem) => HelperLodash.get(cartItem, key) === HelperLodash.get(item, key));
};

/**
 * @description - check is item available from the items in the cart.
 * @param cartItem - items in the cart
 * @param item - item for check.
 * @param key - key for comparision.
 */
export const isItemsAvailable = (cartItems = [], items, key) => {
  return items.filter((item) => {
    return cartItems.find((cartItem) => HelperLodash.get(cartItem, key) === HelperLodash.get(item, key));
  });
};

/**
 * @description - get items that are not in cart.
 * @param cartItem - items in the cart
 * @param item - item for check.
 * @param key - key for comparision.
 */
export const getItemsListNotInCart = (cartItems = [], items, key) => {
  return HelperLodash.differenceBy(items, cartItems, key);
};

/**
 * @description - Get the transfer cart from session storage
 */
export const getTransferCartFromStorage = () => {
  return sessionStorageGetObject(appConstants.TRANSFER_ITEM) || [];
};

export const getTransferCartIdFromStorage = () => {
  return sessionStorageGetItem(appConstants.TRANSFER_CART_ID);
};
/**
 * @description - add item to the session storage
 * @param item - item to be added
 */
export const addItemtoSessionStore = (item) => {
  const cartItems = getTransferCartFromStorage();
  item.isSelected = false;
  item.metaData = { isChecked: false };
  cartItems.push(item);
  sessionStorageSetObject(appConstants.TRANSFER_ITEM, deepCloneArray(cartItems));
};

/**
 * @description - add item to the session storage
 * @param items - items to be added
 */
export const addItemstoSessionStore = (items) => {
  let cartItems = getTransferCartFromStorage();
  cartItems = [...items, ...cartItems];
  sessionStorageSetObject(appConstants.TRANSFER_ITEM, deepCloneArray(cartItems));
};

export const updateItemsToSesionStore = (items) => {
  sessionStorageSetObject(appConstants.TRANSFER_ITEM, items);
};

export const addTransferCartIdToSessionStore = (cartId) => {
  sessionStorageSetItem(appConstants.TRANSFER_CART_ID, cartId);
};

export const removeTransferCartIdFromSessionStore = () => {
  sessionStorageSetItem(appConstants.TRANSFER_CART_ID, "");
};
/**
 * @description - remove given item from the session store.
 * @param item - item to be removed.
 * @param key - key for comparision.
 */
export const removeItemFromSessionStore = (items) => {
  const groupedItemsByType = HelperLodash.groupBy(items, "metaData.type");
  Object.keys(groupedItemsByType).forEach((item) => {
    const cartItems = getTransferCartFromStorage();
    const tempItems = HelperLodash.differenceBy(cartItems, HelperLodash.get(groupedItemsByType, item), `uniqueId`);
    sessionStorageSetObject(appConstants.TRANSFER_ITEM, deepCloneArray(tempItems));
  });
  if (getTransferCartFromStorage().length === 0) {
    removeTransferCartIdFromSessionStore();
  }
};

/**
 * @description - remove all items in the cart
 */
export const removeAllCartItems = () => {
  sessionStorageSetObject(appConstants.TRANSFER_ITEM, []);
  removeTransferCartIdFromSessionStore();
};

export const getTransferCartListCount = (list) => {
  let count = 0;
  (list || []).forEach((row) => {
    const childCount = HelperLodash.get(row, "childCount", 0);
    const qtyItemCount = HelperLodash.get(row, "qtyItemCount", 0);
    count += childCount + qtyItemCount + 1;
  });
  return count;
};

export const checkAssetValidity = (resultantTransferCartList, pathArr, combinedResult, id) => {
  return !(
    resultantTransferCartList.some((row) => id === row.id) ||
    combinedResult.some((row) => pathArr.indexOf(row.id.toString()) > -1)
  );
};

const checkAllocationValidity = (resultantTransferCartList, uniqueId) => {
  return resultantTransferCartList.some((row) => row.uniqueId === uniqueId);
};

const getFilteredAllocations = (item, itemsExcludingAssets) => {
  const arr = [];
  itemsExcludingAssets.filter((transferCartItem) => {
    const pathArr = transferCartItem.path ? transferCartItem.path.split(".") : [];
    if (!pathArr.includes(item.id.toString())) {
      arr.push(transferCartItem);
    }
  });

  return arr;
};

export const getItemsToBeTransferred = (rows, transferCartList) => {
  let existingAssetCount = 0;
  let isAdded = false;
  let filteredAllocations = [];
  const resultantTransferCartList = [];
  const itemsExcludingAssets = transferCartList.filter((data) => data.metaData.type !== ModuleName.asset);
  const combinedResult = rows.concat(transferCartList.filter((data) => data.metaData.type === ModuleName.asset));

  combinedResult.forEach((item) => {
    const pathArr = item.path ? item.path.split(".") : [];
    filteredAllocations = getFilteredAllocations(item, itemsExcludingAssets);
    const isAssetNotInCart = checkAssetValidity(resultantTransferCartList, pathArr, combinedResult, item.id);
    if (isAssetNotInCart) {
      if (item.metaData?.isChecked) {
        isAdded = true;
      }
      item.isSelected = false;
      resultantTransferCartList.push(item);
    } else {
      existingAssetCount++;
    }
  });

  return {
    existingAssetCount,
    isAdded,
    resultantTransferCartList: resultantTransferCartList.concat(filteredAllocations),
  };
};

const isItemExistInCart = (item, itemsExcludingAllocations) => {
  const pathArr = item.path ? item.path.split(".") : [];
  return itemsExcludingAllocations.some((row) => pathArr.indexOf(row.id.toString()) > -1);
};

export const getAllocationsToBeTransferred = (rows, transferCartList) => {
  let existingAllocationsCount = 0;
  const resultantTransferCartList = [];
  const itemsExcludingAllocations = transferCartList.filter((data) => data.metaData.type !== ModuleName.allocation);
  const combinedResult = transferCartList.filter((data) => data.metaData.type === ModuleName.allocation).concat(rows);

  combinedResult.forEach((item) => {
    if (isItemExistInCart(item, itemsExcludingAllocations)) {
      existingAllocationsCount++;
      return;
    }
    const doesItemExistsInCart = checkAllocationValidity(resultantTransferCartList, item.uniqueId);
    if (!doesItemExistsInCart) {
      resultantTransferCartList.push(item);
    } else {
      HelperLodash.remove(resultantTransferCartList, { uniqueId: item.uniqueId });
      resultantTransferCartList.push(item);
    }
  });

  return {
    existingAllocationsCount,
    resultantTransferCartList: resultantTransferCartList.concat(itemsExcludingAllocations),
  };
};

export const getInitialValueForIndividualWorker = (selectedWorkder, transferCartItems) => {
  const assignToWorkerkey = {};
  transferCartItems.forEach((item) => {
    const valuekey = "assignToWorker_" + item.uniqueId;
    const idkey = "idAssignToWorker_" + item.uniqueId;
    HelperLodash.set(assignToWorkerkey, valuekey, selectedWorkder.name);
    HelperLodash.set(assignToWorkerkey, idkey, selectedWorkder.toWorkerId);
  });

  return assignToWorkerkey;
};

export const getInitialValueForWorkers = (transferCartItems = [], transferCartInfo) => {
  const assignToWorkerkey = {};
  transferCartItems?.forEach((item) => {
    const valueKey = `assignToWorker_${item.uniqueId}`;
    const idKey = `idAssignToWorker_${item.uniqueId}`;
    const workerInfo = HelperLodash.get(transferCartInfo, valueKey);
    const idInfo = HelperLodash.get(transferCartInfo, idKey);
    HelperLodash.set(assignToWorkerkey, valueKey, workerInfo);
    HelperLodash.set(assignToWorkerkey, idKey, idInfo);
  });
  return assignToWorkerkey;
};

export const getInitialValueForIndividualReturnDate = (transferCartItems = [], transferCartInfo) => {
  const individualReturnDate = {};
  transferCartItems &&
    transferCartItems.forEach((item) => {
      const returnDate = `returnDate${item.uniqueId}`;
      const cartInfo = HelperLodash.get(transferCartInfo, returnDate);
      HelperLodash.set(individualReturnDate, returnDate, cartInfo);
    });

  return individualReturnDate;
};

export const getSelectedValuesFromTheTransferForm = (formValues, allocations) => {
  let filteredItems = [];

  if (formValues) {
    filteredItems = allocations.filter((data) => formValues[`textBox_${data.uniqueId}`] !== undefined);

    filteredItems.forEach((item, index) => {
      const { uniqueId } = item;
      filteredItems[`${index}`].qtyAllocationValue = formValues[`textBox_${uniqueId}`];
      filteredItems[`${index}`].qtyAllocationStatus = {
        code: formValues[`statusListId_` + uniqueId],
        value: formValues[`statusList_` + uniqueId],
      };
      formValues[`statusList_${uniqueId}`] = formValues[`statusList_${uniqueId}`];
    });
  }

  return filteredItems;
};

export const transformItems = (items) => {
  const transferCartItems = [];

  items.map((item) => {
    transferCartItems.push(new TransferCartModel(item));
  });

  return transferCartItems;
};

/**
 * @description - This method will check if all item have are asset
 *
 */
export const isAssetItemExist = (list) => {
  return list && list.some((item) => item.metaData.type === ModuleName.asset);
};

/**
 * @description - This method will check if confirm transfer is optinal
 *
 */
export const isConfirmTransferOptional = (transferConfirmationPolicy) => {
  return transferConfirmationPolicy?.code === TransferConfirmationPolicyEnum.Optional;
};

/**
 * @description - This method will return transfer item based on id
 *
 */
export const getTransferItem = (itemsToTransfer, transferId) => {
  return itemsToTransfer.find((transferItem) => transferItem.id === transferId);
};

/**
 * @description - This method will return transfer item based on selected item
 *
 */
export const getItemsToTransfer = (transferItems = []) => {
  if (transferItems.every((item) => !item.metaData.isChecked)) {
    return transferItems;
  } else {
    return transferItems.filter((item) => item.metaData.isChecked);
  }
};

export const checkIfQtyItemPresent = (transferCartList) =>
  transferCartList.some((item) => item.transferAssetType === ModuleName.allocation.toUpperCase());
