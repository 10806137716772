import { CellTypes, IGridSetting } from "am-web-ui-shared/components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";

export const chargeLocationsGridSetting: IGridSetting = {
  columns: [
    {
      bindProperties: [
        {
          style: { color: `${theme.colors.base.hiltiRed}` },
        },
        {
          style: {
            color: `${theme.colors.base.black}`,
            fontFamily: `${theme.fontBold.fontFamily}`,
            fontSize: 16,
          },
        },
      ],
      cellSize: 3,
      isSortable: false,
      type: CellTypes.FieldCombination,
    },
    {
      cellSize: 3,
      isSortable: false,
      type: CellTypes.FieldCombination,
    },
    {
      cellSize: 3,
      isSortable: false,
      type: CellTypes.FieldCombination,
    },
    {
      cellSize: 3,
      isSortable: false,
      type: CellTypes.FieldCombination,
    },
  ],
  defaultQueryFields: [
    "costLocationType",
    "costLocationName",
    "costLocationCustomId",
    "noOfWorkdays",
    "startTime",
    "endTime",
    "costLocationManagerName",
    "noOfHolidays",
    "costSettingType",
    "noOfSubLocations",
  ],
  defaultSort: { label: "Cost location manager", name: "costLocationManagerName", sortType: "+" },
  showTooltip: true,
};
