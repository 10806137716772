// @ts-ignore
import Gateway from "../gateway/gateway";
import { IFrequencyList, IPrefilledData } from "../models/gatewayReportSettings/iResponse";
import ServiceUrl from "../utils/enums/serviceUrlEnum";

import { getVersionedApiUrl } from "am-web-ui-shared/helpers";

const getEndpoint = (path) => {
  return getVersionedApiUrl(ServiceUrl.REPORTING, path);
};

export const getGatewayReportSettingApi = async (): Promise<any> => {
  return Gateway.get(getEndpoint("/scheduler/frequency"), null, null, null, true) as unknown as IFrequencyList;
};

export const putSchedulerSettingsApi = async (values, schedulerSettingId): Promise<any> => {
  let url = null;
  if (schedulerSettingId) {
    url = getEndpoint(`/scheduler/setting/${schedulerSettingId}`);
  } else {
    url = getEndpoint("/scheduler/setting/");
  }
  return Gateway.put(url, null, values, null, true) as unknown;
};

export const getGatewayReportSchedulerSettingApi = async (tenantId): Promise<any> => {
  return Gateway.get(
    getEndpoint(`/scheduler/setting/${tenantId}`),
    null,
    null,
    null,
    true,
  ) as unknown as IPrefilledData;
};

export const sendAssetReportApi = async (assetId): Promise<any> => {
  return Gateway.post(
    getEndpoint(`/unique-asset/${assetId}/smart-inventory-email-report`),
    null,
    null,
    null,
    false,
  ) as unknown;
};

export const sendLocationReportApi = async (locationId): Promise<any> => {
  return Gateway.post(
    getEndpoint(`/location/${locationId}/smart-inventory-email-report`),
    null,
    null,
    null,
    false,
  ) as unknown;
};
