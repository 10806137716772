const HintActionConstants = {
  AM_GET_ASSET_WITH_SCANCODE: "AM_GET_ASSET_WITH_SCANCODE",
  CHECK_BATTERY_EXCHANGE_AVAILABILITY: "CHECK_BATTERY_EXCHANGE_AVAILABILITY",
  CLEAR_BATTERY_DETAILS: "CLEAR_BATTERY_DETAILS",
  CLEAR_HINT_LIST_FIELDS: "CLEAR_HINT_LIST_FIELDS",
  CLEAR_SCAN_CODE_RESPONSE: "CLEAR_SCAN_CODE_RESPONSE",
  GET_ASSET_WITH_SCANCODE: "GET_ASSET_WITH_SCANCODE",
  GET_HINT_LIST: "GET_HINT_LIST",
  HINT_GET_ASSETS_BY_SCAN_CODE: "HINT_GET_ASSETS_BY_SCAN_CODE",
  HINT_GET_BAD_SOH_BATTERY_LIST: "HINT_GET_BAD_SOH_BATTERY_LIST",
  HINT_GET_BAD_SOH_BATTERY_OVERVIEW: "HINT_GET_BAD_SOH_BATTERY_OVERVIEW",
  HINT_GET_TOOL_BY_ID: "HINT_GET_TOOL_BY_ID",
  HINT_PUT_BAD_SOH_BATTERY_LIST: "HINT_PUT_BAD_SOH_BATTERY_LIST",
  HINT_PUT_BAD_SOH_BATTERY_OVERVIEW: "HINT_PUT_BAD_SOH_BATTERY_OVERVIEW",
  HINT_SELECT_ROW: "HINT_SELECT_ROW",
  HINT_UNSELECT_ROW: "HINT_UNSELECT_ROW",
  IS_HILTI_ASSET_ONBOARDING_SUCCEDED: "IS_HILTI_ASSET_ONBOARDING_SUCCEDED",
  ONBOARD_HILTI_ASSET_WITH_SCAN_CODE: "ONBOARD_HILTI_ASSET_WITH_SCAN_CODE",
  OFFBOARD_HILTI_ASSETS_WITH_SCAN_CODES: "OFFBOARD_HILTI_ASSETS_WITH_SCAN_CODES",
  SET_ASSET_WITH_SCANCODE: "SET_ASSET_WITH_SCANCODE",
  SET_BATTERY_DETAILS: "SET_BATTERY_DETAILS",
  SET_BATTERY_ID_FOR_EXCHANGE: "SET_BATTERY_ID_FOR_EXCHANGE",
  SET_DUPLICATE_SCANCODE_DETAILS: "SET_DUPLICATE_SCANCODE_DETAILS",
  SET_HINT_LIST_FIELDS: "SET_HINT_LIST_FIELDS",
  SET_UNREGISTERED_TOOL_SEARCH_QUERY: "SET_UNREGISTERED_TOOL_SEARCH_QUERY",
  REMOVE_BATTERY_ID_FOR_EXCHANGE: "REMOVE_BATTERY_ID_FOR_EXCHANGE",
  UI_SAVE_SCAN_CODE_RESPONSE: "UI_SAVE_SCAN_CODE_RESPONSE",
  UI_SAVE_TOOL_ID_RESPONSE: "UI_SAVE_TOOL_ID_RESPONSE",
  UPDATE_BATTERY_DETAILS: "UPDATE_BATTERY_DETAILS",
};
export default HintActionConstants;
