import React, { lazy } from "react";

export const AlertAndNotificationContainerWrapper = lazy(
  () => import("../../../modules/alert/components/list/container/alertAndNotificationContainerWrapper"),
);
export const ConfirmTransferContainerWrapper = lazy(
  () => import("../../../modules/alert/confirmTransfer/confirmTransferContainerWrapper"),
);
export const FieldRequestsListContainerWrapper = lazy(
  () => import("../../../modules/fieldRequest/receiver/components/fieldRequestsListContainerWrapper"),
);
