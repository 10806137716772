// @ts-ignore
import { LoaderAction } from "custom-actions";
import { createReducer } from "../../utils/commonUtils";
import { ILoaderInitialState } from "./iLoaderModuleState";
/**
 * Loader Reducer
 * @param {object} state current state
 * @param {object} action current action
 */

export const loaderInitialState: ILoaderInitialState = {
  detail: false,
  dialogDetail: false,
  expansionPanelDetail: false,
  filter: false,
  list: false,
};
const setDialogDetailLoader = (state) => {
  return { ...state, dialogDetail: true };
};
const unsetDialogDetailLoader = (state) => {
  return { ...state, dialogDetail: false };
};
const loaderReducer = createReducer(loaderInitialState, {
  [LoaderAction.SET_DIALOG_DETAIL_LOADER]: setDialogDetailLoader,
  [LoaderAction.UNSET_DIALOG_DETAIL_LOADER]: unsetDialogDetailLoader,
});
const LoaderReducer = (state = loaderInitialState, action) => {
  switch (action.type) {
    case LoaderAction.SET_LIST_LOADER: {
      return { ...state, list: true };
    }
    case LoaderAction.UNSET_LIST_LOADER: {
      return { ...state, list: false };
    }
    case LoaderAction.SET_DETAIL_LOADER: {
      return { ...state, detail: true };
    }
    case LoaderAction.UNSET_DETAIL_LOADER: {
      return { ...state, detail: false };
    }
    case LoaderAction.SET_FILTER_LOADER: {
      return { ...state, filter: true };
    }
    case LoaderAction.UNSET_FILTER_LOADER: {
      return { ...state, filter: false };
    }
    case LoaderAction.SET_EXPANTION_PANEL_DETAIL_LOADER: {
      return { ...state, expansionPanelDetail: true };
    }
    case LoaderAction.UNSET_EXPANTION_PANEL_DETAIL_LOADER: {
      return { ...state, expansionPanelDetail: false };
    }
    default:
      return loaderReducer(state, action);
  }
};

export default LoaderReducer;
