import { LocaleManager, HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
// @ts-ignore
import ExpandRight from "TARGET_BUILD/ui-assets/icons/expand_right.svg";
import { IconNormalBold } from "../../../../../components/DetailsPanel/index";
import { AssetTransferGridSettings } from "../../../../../components/transferHistory/config/transferHeaderConfig";
import Config from "../../../../../components/transferHistory/config/transferHistoriesConfig";
import TransferHistoryView from "TARGET_BUILD/common/components/transferHistory/presentational/transferHistoryView";
import { ITransferDetailsExpanded, ITransferDetailsExpandedState } from "./model/iTransferDetailsExpanded";
import { ValidationConstants } from "../../../../../utils/enums/validationConstants";

const ViewHistory = styled.div`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
  margin-left: 58px;
  margin-bottom: 16px;
  margin-top: 16px;
  word-break: break-all;
  padding-right: 16px;
`;
const StyledWrapper = styled("div")`
  padding: 0px 0px;
`;
const SectionWrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -16px;
  margin-right: -16px;
  border-top: 1px solid ${(props) => props.theme.colors.base.borderColor};
`;
const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`;
const Img = styled.img`
  top: 28px;
  right: 15px;
  height: 16px;
  width: 10px;
`;
/**
 * @description - Transfer history of the asset.
 */
class TransferDetailsExpanded extends React.Component<ITransferDetailsExpanded, ITransferDetailsExpandedState> {
  state: ITransferDetailsExpandedState = {
    showHistoryDialog: false,
  };
  openHistoryDialog = () => {
    this.setState({ showHistoryDialog: true });
  };
  closeAssetTransferHistory = () => {
    this.setState({ showHistoryDialog: false });
  };
  /**
   * @description - Invokes the assets list with given page number. Invokes the loader for infinite scroll.
   * `offset` and `limit` are coming from the API.
   */
  loadPaginatedList = () => {
    const { transferHistory, fetchList } = this.props;
    const nextOffset =
      (transferHistory.offset || 0) + (transferHistory.limit || ValidationConstants.MAX_LENGTH.NUM_100);
    if (nextOffset < transferHistory.totalRecords) {
      fetchList(nextOffset);
    }
  };

  getTransferFirstRowData = (transferHistory) => {
    const list = HelperLodash.get(transferHistory, "list", []);
    return list.find((transferData) => {
      return !transferData.canceledTransferDetails;
    });
  };
  isStorageAsset = (value) => {
    return value && value.assetName;
  };

  render() {
    const { selectedRow, t, transferHistory, handleCancelTransfer, getAssetTransferDetails } = this.props;
    const transferData = this.getTransferFirstRowData(transferHistory);
    return (
      <>
        {transferData && (
          <StyledWrapper id="transferHistorySection">
            <IconNormalBold
              id="pnlTransferDate"
              normalId="lblTransferDate"
              normalText={t("common:LABEL_TRANSFER_DATE")}
              boldId="txtTransferDate"
              boldText={LocaleManager.dateHelpers.getDateInTenantTimezone(transferData.transferDate)}
            />
            <IconNormalBold
              id="pnlToLocationName"
              normalId="lblToLocationName"
              normalText={t("common:LABEL_TO_STORAGE_ASSET_LOCATION")}
              boldId="txtToLocationName"
              boldText={
                this.isStorageAsset(transferData.toStorageAssetDetails)
                  ? transferData.toStorageAssetDetails.assetName
                  : (transferData.toLocationDetails && transferData.toLocationDetails.locationName) || "-"
              }
            />
            <IconNormalBold
              id="pnlFromLocationName"
              normalId="lblFromLocaionName"
              normalText={t("common:LABEL_FROM_STORAGE_ASSET_LOCATION")}
              boldId="txtFromLocationName"
              boldText={
                this.isStorageAsset(transferData.fromStorageAssetDetails)
                  ? transferData.fromStorageAssetDetails.assetName
                  : (transferData.fromLocationDetails && transferData.fromLocationDetails.locationName) || "-"
              }
            />
            <IconNormalBold
              id="pnlToWorkerName"
              normalId="lblToWorkerName"
              normalText={t("common:LABEL_TO_WORKER")}
              boldId="txtToWorkerName"
              boldText={transferData.toWorkerDetails.workerName}
            />
            <IconNormalBold
              id="pnlFromWorkerName"
              normalId="lblFromWorkerName"
              normalText={t("common:LABEL_FROM_WORKER")}
              boldId="txtFromWorkerName"
              boldText={transferData.fromWorkerDetails.workerName}
            />
          </StyledWrapper>
        )}
        <SectionWrapperDiv onClick={this.openHistoryDialog}>
          <ViewHistory>{t("common:VIEW_TRANSFER_HISTORY")}</ViewHistory>
          <ImgWrapper>
            <Img src={ExpandRight} alt="" />
          </ImgWrapper>
        </SectionWrapperDiv>
        {this.state.showHistoryDialog && (
          <TransferHistoryView
            cancelLastTransfer={handleCancelTransfer}
            closeDialog={this.closeAssetTransferHistory}
            loadPaginatedList={this.loadPaginatedList}
            transferHistory={transferHistory}
            selectedRow={selectedRow}
            headerText={t("assets:ASSET_TRANSFER_HISTORY")}
            config={Config}
            headerGridSetting={AssetTransferGridSettings}
            getAssetTransferDetails={getAssetTransferDetails}
          />
        )}
      </>
    );
  }
}

/**
 * @description - Exporting the components that are used for transfer history section used on asset details.
 */
export default TransferDetailsExpanded;
