/**
 * @description This file is for api hitting using
 * axios for refreshing token
 */
import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { getUserMgmtEndpoint } from "../utils/apiUtils";
/**
 * @description Function to refresh the token
 * @param {String} - Token
 */

export const RefreshTokenWebUI = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint(`/auth/web/refresh-token`), null, null, null, false);
};
