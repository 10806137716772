import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UpfrontExpansionPanel } from "../../../../../components/DetailsPanel";
import ISmartInventoryDetailsViewProps, { ISmartInventoryDynamicProps } from "./iSmartInventoryDetailsViewProps";
import {
  Footer,
  Header,
  InventoryList,
  SendReport,
  Offline,
  ExtendedStyledWrapperExpandCollpase,
} from "./smartInventoryDetailsComponents";
import {
  getHeaderText,
  getSectionAssetIds,
  getSectionName,
  isGatewayOffline,
  isOfflineInventoryEnabled,
} from "./smartInventoryUtils";
import { toggleAssetListDialog, updateAssetType } from "../../../actions/activeTrackingActionRoot";
import { AppDispatch } from "TARGET_BUILD/common/store/store";
import { useDispatch } from "react-redux";
import { trackSmartInventoryAsset } from "TARGET_BUILD/analytics/events/assetDetails";
import { useOneMapStore } from "TARGET_BUILD/common/modules/oneMap/store/oneMapStore";

type SmartInventoryProps = ISmartInventoryDetailsViewProps & ISmartInventoryDynamicProps;

export const SmartInventoryDetailsView: React.FC<SmartInventoryProps> = (props) => {
  const {
    gatewayAssets,
    id,
    assetSubType,
    getGatewaySmartInventory,
    expansionPanelDetailsLoader,
    hasSmartInventoryInDialog = true,
  } = props;
  const { t } = useTranslation();
  const [sectionName, setSectionName] = useState<string>("");
  const [panelExpanded, setPanelExpanded] = React.useState(false);

  const dispatch: AppDispatch = useDispatch();
  const { setInventoryAssetIds } = useOneMapStore();

  const handleInventoryListClick = useCallback(
    (section: string) => {
      if (gatewayAssets && !hasSmartInventoryInDialog) {
        setSectionName(section);
        setInventoryAssetIds([...getSectionAssetIds(section, gatewayAssets?.inventory)]);
        return;
      }
      dispatch(updateAssetType(getSectionName(section)));
      dispatch(toggleAssetListDialog(true));
      setPanelExpanded(false);
    },
    [id, gatewayAssets],
  );

  // fetching data
  const allowAccordianControl = (panelId, isPanelExpanded) => {
    if (isPanelExpanded && panelId) {
      getGatewaySmartInventory(id);
      trackSmartInventoryAsset(assetSubType);
    }
    setPanelExpanded(isPanelExpanded);
  };

  // headers
  const summary = [() => <Header title={t(getHeaderText())} />];

  // components when accordion is expanded
  const details = [
    () => (
      <InventoryList
        assetData={gatewayAssets?.inventory}
        handleInventoryListClick={handleInventoryListClick}
        hasSmartInventoryInDialog={hasSmartInventoryInDialog}
        sectionName={sectionName}
      />
    ),
    () => <Footer assetSubType={assetSubType} />,
    () => <SendReport sendReport={props.sendReport} assetData={gatewayAssets?.inventory} />,
  ];

  return (
    <ExtendedStyledWrapperExpandCollpase>
      <UpfrontExpansionPanel
        isPadding={false}
        panelId="assetSmartInventory"
        isEnable={true}
        allowAccordianControl={allowAccordianControl}
        isPanelExpanded={panelExpanded}
        showToggleIcon={true}
        expansionPanelDetailsLoader={expansionPanelDetailsLoader}
        summaryComponents={summary}
        detailComponents={
          !isOfflineInventoryEnabled() && isGatewayOffline(gatewayAssets) ? [() => <Offline />] : details
        }
      />
    </ExtendedStyledWrapperExpandCollpase>
  );
};

export default memo(SmartInventoryDetailsView);
