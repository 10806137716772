// @ts-ignore
import { UniteActions } from "custom-actions";
import { call, put, takeLatest, all } from "redux-saga/effects";
import UniteApi from "../../../api/uniteApi";
import { showToastr, ToastrType } from "../../../actions/toastrAction";

export function* getManagedFieldsSaga() {
  yield takeLatest(UniteActions.GET_MANAGED_FIELDS, getManagedFields);
}

export function* getManagedFields() {
  const response = yield call(() => UniteApi.getManagedFields());
  if (!response.error) {
    yield put({ type: UniteActions.SET_MANAGED_FIELDS, payload: response });
  } else {
    yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`, true));
  }
}

function* uniteSaga() {
  yield all([getManagedFieldsSaga()]);
}

export default uniteSaga;
