import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M13,11 L11,11 L11,17 L13,17 L13,11 Z M13,7 L11,7 L11,9 L13,9 L13,7 Z" />
    </g>
  </svg>
);

export default SVG;
