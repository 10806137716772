import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { IRadioGroupProps } from "./iRadioGroup";
import Radio from "./radio";
import { HelperLodash } from "../../../../helpers";

const FilterWrapper = styled.div`
  margin-bottom: 8px;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
`;
export const List = styled(MenuItem)`
  && {
    font-family: ${(props) => props.theme.fontRoman.fontFamily};
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
export const ClearButton = styled.button`
  text-decoration: underline;
  background: none;
  border: none;
  font-size: ${(props) => props.theme.fontSize.base};
  &:hover {
    color: ${(props) => props.theme.colors.base.steel10};
  }
`;

/**
 * Header styles for the checkbox list
 */
const HeaderText = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  line-height: 32px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

export const NoValuesWrapper = styled.p`
  padding-left: 16px;
`;

export const RadioControl = (props) => {
  const {
    clearButtonText,
    clearSelection,
    id,
    header,
    showClearButton,
    name,
    valueKey,
    valueId,
    t,
    input,
    onRadioClicked,
    selectedOptions,
    options,
    noValuesLabel,
  } = props;
  return (
    <FilterWrapper id={id}>
      <HeaderWrapper>
        <HeaderText key={`${id}_header`}>{t(header)}</HeaderText>
        {selectedOptions && selectedOptions.length > 0 && showClearButton ? (
          <ClearButton onClick={() => clearSelection(input.onChange)}>{clearButtonText}</ClearButton>
        ) : null}
      </HeaderWrapper>
      <ul>
        {options.map((item, index) => {
          const opt =
            (selectedOptions &&
              selectedOptions.filter((o) => HelperLodash.get(o, valueId) === HelperLodash.get(item, valueId))) ||
            [];
          return (
            <List key={opt.label}>
              <Radio
                key={opt.label}
                isFormControl={false}
                id={`${id}${index}`}
                name={name}
                label={HelperLodash.get(item, valueKey)}
                onChange={() => {
                  return onRadioClicked && onRadioClicked(item, input.onChange);
                }}
                checked={opt.length > 0}
              />
            </List>
          );
        })}
        {!options || options.length === 0 ? <NoValuesWrapper>{noValuesLabel}</NoValuesWrapper> : null}
      </ul>
    </FilterWrapper>
  );
};

/**
 * @class MenuWrapper component for checkbox component // To DO : as discussed, the component array should be used as a prop.
 */
export class RadioGroup extends React.PureComponent<IRadioGroupProps> {
  componentDidMount() {
    this.props.updateHeight();
  }

  /**
   * handle on change value in search input box
   *
   * @param selectedValue
   */
  onChange = (selectedValue) => {
    return (
      this.props.options &&
      this.props.options.filter((option: any) => option.label.toLowerCase().includes(selectedValue.toLowerCase()))
    );
  };

  /**
   * @description checkboxClicked on change of checkbox this needs to execute
   *
   * @param currentOption
   * @param onChange
   */
  radioClicked = (currentOption, onChange) => {
    onChange(currentOption?.value);
    return this.props.onSelectionChange && this.props.onSelectionChange([currentOption]);
  };

  /**
   * clear seelcted records
   */
  clearSelection = (onChange) => {
    const options = [];
    onChange("");
    return this.props.onSelectionChange && this.props.onSelectionChange(options);
  };

  render() {
    const { isFormControl, showClearButton, name } = this.props;
    return isFormControl ? (
      <Field
        name={name}
        component={RadioControl}
        onRadioClicked={this.radioClicked}
        clearSelection={this.clearSelection}
        showClearButton={showClearButton}
        {...this.props}
      />
    ) : (
      <RadioControl
        onRadioClicked={this.radioClicked}
        clearSelection={this.clearSelection}
        showClearButton={showClearButton}
        {...this.props}
      />
    );
  }
}

export default RadioGroup;
