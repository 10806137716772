import Grid from "@mui/material/Grid";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
const panelWidth = (props) => `${props.sidepanelwidth}px`;

export const SidePanelWrapper = styled(Grid).attrs({
  id: "sidePanelWrapper",
})`
  background-color: ${(props) => props.theme.colors.base.white};
  border-left: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
  overflow-x: clip;
  overflow-y: auto;
  overscroll-behavior: contain;
  transition: width 1ms;
  .scrollOver > div:first-child {
    display: flex;
  }
  width: ${(props) => (props.sidepanelwidth ? panelWidth : "480px")};
  & > div[id*="container"],
  & > div[id*="Container"] {
    > div:last-child {
      border-bottom: 1px solid ${(props) => props.theme.colors.base.heavyConcrete20};
    }
  }
  @media (max-width: 1280px) {
    width: 315px;
  }
`;

interface IProps {
  headerHeight?: number;
  sidePanelWidth?: number;
}
class SidePanel extends React.Component<IProps> {
  render() {
    return <SidePanelWrapper sidepanelwidth={this.props.sidePanelWidth}>{this.props.children}</SidePanelWrapper>;
  }
}

export default SidePanel;
