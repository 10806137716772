// @ts-ignore
import { ActiveTrackingActions, LoaderAction } from "custom-actions";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { validateResponse } from "../../../../utils/errorUtils";
import ActiveTrackingApi from "../../../../api/activeTrackingApi";
import { getAssetModelList } from "../../../asset/reduxModule/assetList/assetSaga";
import { call } from "../../../../sagas/common/commonSaga";
import { getQueryObj } from "../../../../sagas/stateSelector";
import { LastSeenStateEnum, ServiceProviderCode } from "../../../../utils/enums/activeTrackingEnum";
import LocationApi from "../../../../api/locationApi";
import { showToastr, ToastrType } from "../../../../actions/toastrAction";
import { updateChildGatewayStatus, updateLastSeen } from "../../actions/activeTrackingActionRoot";
import { isGatewayOffline } from "../../components/smartInventory/presentational/smartInventoryUtils";
import { GatewayStatus } from "../../../../utils/enums/alertGroupEnum";
import { IGeoJSONFeature, IOneMapRequest } from "TARGET_BUILD/common/modules/oneMap/model/cluster";
import { IClusterAssetModel } from "TARGET_BUILD/common/modules/oneMap/store/oneMapReducer";

export function* getGatewayAssets(action) {
  const response = yield call(ActiveTrackingApi.getLocationGatewayAssets, action.payload);
  if (!(response.errors || response.error)) {
    yield put({
      payload: response,
      type: ActiveTrackingActions.GET_LOCATION_GATEWAY_SUCCESS,
    });
  } else {
    yield put({
      payload: validateResponse(response),
      type: ActiveTrackingActions.GET_LOCATION_GATEWAY_FAIL,
    });
  }
}

export function* getStorageAssetGatewayAssets(action) {
  yield put({ type: LoaderAction.SET_EXPANTION_PANEL_DETAIL_LOADER }); // loader start

  const response = yield call(ActiveTrackingApi.getStorageAssetGatewayAssets, action.payload);

  let gatewayStatus: { code: GatewayStatus.ONLINE | GatewayStatus.OFFLINE; value: string };

  if (!(response.errors || response.error)) {
    if (response?.lastScanned) {
      gatewayStatus = { code: GatewayStatus.OFFLINE, value: "Offline" };
    }
    yield put({
      payload: response,
      type: ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY_SUCCESS,
    });
  } else {
    const error = validateResponse(response);
    yield put({
      payload: error,
      type: ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY_FAIL,
    });
  }

  // update the status of asset details
  if (gatewayStatus) {
    yield put(updateChildGatewayStatus(gatewayStatus));
  }
  // loader end
  yield put({ type: LoaderAction.UNSET_EXPANTION_PANEL_DETAIL_LOADER });
}

function* fetchAssetFilterAttributes(action) {
  const response = yield call(() => {
    return ActiveTrackingApi.getAssetViewFilterAttributes(
      action.urlDetail,
      action.id,
      action.statsType,
      action.filters,
    );
  });
  if (!response.error) {
    yield put({ type: ActiveTrackingActions.ASSET_VIEW_FILTER_FIELD_SUCCESS, payload: response.response });
  }
}

function* fetchAssetsList(action) {
  /*
    offset : 0 -> Load with initial state, show grid loader only for initial state.
    offset : (>0) -> Load list for pagination, paginated loader is shown by the infitescroll
    component at the bottom of the page
  */
  const queryObj = yield select(getQueryObj, action.offset, "ActiveTracking", action.limit);
  let error = null;
  let assetsData = yield call(() => {
    return ActiveTrackingApi.getAssetsList(action.urlDetail, action.id, action.statsType, queryObj);
  });
  if (assetsData && assetsData.error) {
    error = validateResponse(assetsData);
    assetsData = { response: [] };
    if (isGatewayOffline({ error })) {
      yield put(updateChildGatewayStatus({ code: "OFFLINE", value: "Offline" }));
    }
  }
  const payloadObj = {
    error,
    assetsData: getAssetModelList(assetsData),
    limit: action.limit,
    offset: action.offset,
  };
  if (!action.transferAllAssetsToCart) {
    yield put({ type: ActiveTrackingActions.GET_ASSET_LIST_SUCCESS, payload: payloadObj });
  } else {
    yield put({ type: ActiveTrackingActions.GET_SELECT_ALL_ASSETS_SUCCESS, payload: payloadObj });
  }
}

function* getAssetsLastSeen(action) {
  const assetIds = action?.payload?.assetIds || [];
  const response = yield call(() => {
    return ActiveTrackingApi.getLastSeenForAssets(assetIds);
  });
  if (response?.error) {
    yield put(updateLastSeen({ response, assetIds, responseState: LastSeenStateEnum.FAILURE }));
    yield put(
      showToastr(ToastrType.warning, "common:LAST_SEEN_FAILURE", true, "", {
        name: "common:RELOAD",
        clickHandler: () => window.location.reload(),
      }),
    );
  } else if (response) {
    yield put(updateLastSeen({ response, assetIds, responseState: LastSeenStateEnum.SUCCESS }));
  }
}

function* getOneMapData(action: { payload: IOneMapRequest }) {
  const oneMapData = yield call(ActiveTrackingApi.getClusteredAssets, action.payload);
  if (!oneMapData.error) {
    const features: IGeoJSONFeature[] = oneMapData?.features;
    yield put({
      payload: features,
      type: ActiveTrackingActions.SET_ASSET_ONE_MAP_DATA,
    });
    const assetIds = [];
    features.forEach((feature) => {
      if (feature?.properties?.clusterInfo?.count === 1) {
        assetIds.push(feature?.properties?.assetId);
      }
    });
    if (assetIds.length > 0) {
      const assets = yield call(ActiveTrackingApi.getAssetsForSingleCluster, { assetIds: assetIds });
      if (assets && !assets.error) {
        yield put({
          payload: assets,
          type: ActiveTrackingActions.AT_SET_ONE_MAP_ASSETS_FOR_VIEWPORT,
        });
      }
    }
  }
}

export function* getOneMapAssetForSingleCluster(action: { payload: IClusterAssetModel }) {
  const assets = yield call(ActiveTrackingApi.getAssetsForSingleCluster, { assetIds: action.payload.ids });
  if (!assets.error) {
    yield put({
      payload: {
        ids: action.payload.ids,
        offset: action.payload.offset,
        limit: action.payload.limit,
        totalRecords: action.payload.totalRecords,
        response: assets,
      },
      type: ActiveTrackingActions.AT_SET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER,
    });
  }
}

export function* activeTrackingSaga() {
  yield all([
    takeLatest(ActiveTrackingActions.AT_GET_ASSET_LIST, fetchAssetsList),
    takeEvery(ActiveTrackingActions.AT_GET_ASSET_FILTER_FIELD, fetchAssetFilterAttributes),
    takeLatest(ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY, getStorageAssetGatewayAssets),
    takeLatest(ActiveTrackingActions.AT_GET_LOCATION_GATEWAY, getGatewayAssets),
    takeLatest(ActiveTrackingActions.AT_GET_ASSETS_LAST_SEEN, getAssetsLastSeen),
    takeLatest(ActiveTrackingActions.GET_ASSET_ONE_MAP_DATA, getOneMapData),
    takeLatest(ActiveTrackingActions.AT_GET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER, getOneMapAssetForSingleCluster),
  ]);
}

export default activeTrackingSaga;
