import { LoaderWrapper } from "am-web-ui-shared/elements";
import React from "react";
import { Trans } from "react-i18next";
import Loadable from "react-loadable";
import LazyLoadModule from "./lazyLoadModule";

export function Loading(props, pastDelay) {
  if (props && props.error) {
    return (
      <div>
        <Trans>{"common:ERROR_LOADING_MODULE"}</Trans>:{props.error}
      </div>
    );
  } else if (pastDelay) {
    return (
      <Trans>
        <LoaderWrapper activity={true} loadingText={<Trans>{"common:LOADING"}</Trans>} />
      </Trans>
    );
  } else {
    return null;
  }
}

export const loaderWrapper = (loader) =>
  Loadable({
    loader,
    loading: Loading,
    render(loaded, props) {
      const module = loaded.default;
      // passing location,history and match as second parimeter.
      return <LazyLoadModule view={module.view} name={module.name} {...props} />;
    },
  });

export const loginModule = loaderWrapper(() => import(/*webpackChunkName: "login"*/ "../common/modules/login"));
export const dashboardModule = loaderWrapper(
  () => import(/*webpackChunkName: "dashboard"*/ "../common/modules/dashboard"),
);
export const assetsModule = loaderWrapper(() => import(/*webpackChunkName: "asset"*/ "../common/modules/asset"));
export const qtyItemsModule = loaderWrapper(
  () => import(/*webpackChunkName: "qtyItems"*/ "../common/modules/qtyItems"),
);
export const chargesModule = loaderWrapper(
  () => import(/*webpackChunkName: "assetCosts"*/ "../common/modules/charges"),
);
export const locationsModule = loaderWrapper(
  () => import(/*webpackChunkName: "location"*/ "../common/modules/location"),
);
export const manufactureModule = loaderWrapper(
  () => import(/*webpackChunkName: "manufacture"*/ "../common/modules/administration/asset/manufacturer"),
);
export const reportsModule = loaderWrapper(() => import(/*webpackChunkName: "report"*/ "../common/modules/report"));
export const templatesModule = loaderWrapper(
  () => import(/*webpackChunkName: "template"*/ "../common/modules/administration/template"),
);
export const workersModule = loaderWrapper(() => import(/*webpackChunkName: "worker"*/ "../common/modules/worker"));
export const assetTrackingModule = loaderWrapper(
  () => import(/*webpackChunkName: "assetTracking"*/ "../common/modules/assetTracking"),
);
export const companyModule = loaderWrapper(() => import(/*webpackChunkName: "company"*/ "../common/modules/company"));
export const labelModule = loaderWrapper(
  () => import(/*webpackChunkName: "label"*/ "../common/modules/administration/general/label"),
);
export const licenseModule = loaderWrapper(
  () => import(/*webpackChunkName: "license"*/ "../common/modules/licenseAndUsageInformation"),
);
export const checkInventoryModule = loaderWrapper(
  () => import(/*webpackChunkName: "checkInventory"*/ "./modules/checkInventory"),
);
export const privateModeSettingsModule = loaderWrapper(
  () => import(/*webpackChunkName: "privateModeSettings"*/ "./modules/privateModeSettings"),
);
export const deletionSettingsModule = loaderWrapper(
  () => import(/*webpackChunkName: "deletionSettings"*/ "./modules/deletionSettings"),
);
export const userSettingModule = loaderWrapper(
  () => import(/*webpackChunkName: "userSetting"*/ "../common/modules/userSetting"),
);
export const assetGroupModule = loaderWrapper(
  () => import(/*webpackChunkName: "administrationAssetGroup"*/ "../common/modules/administration/asset/group"),
);
export const hiltiToolsModule = loaderWrapper(() => import("./modules/administration/asset/hiltiTools"));
export const locationGroupModule = loaderWrapper(
  () => import(/*webpackChunkName: "administrationLocationGroup"*/ "../common/modules/administration/location/group"),
);
export const archivedLocationModule = loaderWrapper(
  () =>
    import(
      /*webpackChunkName: "administrationArchivedLocations"*/ "../common/modules/administration/location/archived"
    ),
);
export const transferCartModule = loaderWrapper(
  () => import(/*webpackChunkName: "transferCart"*/ "../common/modules/transferCart"),
);
export const transferHistoryModule = loaderWrapper(
  () => import(/*webpackChunkName: "transferHistory"*/ "./modules/administration/asset/transferHistory"),
);
export const errorModule = loaderWrapper(
  () => import(/*webpackChunkName: "errorModule"*/ "../common/modules/errorModule"),
);
export const contactModule = loaderWrapper(() => import(/*webpackChunkName: "contact"*/ "../common/modules/contact"));
export const roleModule = loaderWrapper(() => import("../common/modules/administration/worker/role"));
export const certificateTemplatesModule = loaderWrapper(
  () => import("../common/modules/administration/worker/certificateTemplates"),
);
export const serviceModule = loaderWrapper(() => import("../common/modules/administration/asset/service"));
export const info = loaderWrapper(() => import("../common/modules/info"));

export const accountActivation = loaderWrapper(() => import("../common/modules/account/activation"));
export const accountActivated = loaderWrapper(() => import("../common/modules/account/activation/indexActivated"));
export const accountActivationLinkExpired = loaderWrapper(
  () => import("../common/modules/account/activation/indexLinkExpired"),
);
export const accountAlreadyActivated = loaderWrapper(
  () => import("../common/modules/account/activation/indexAlreadyActivated"),
);

export const alertSettingsModule = loaderWrapper(
  () => import("../common/modules/administration/general/alertSettings"),
);

export const unitManagementModule = loaderWrapper(
  () => import("../common/modules/administration/general/unitManagement"),
);
export const gatewayReportSetting = loaderWrapper(
  () =>
    import(
      /*webpackChunkName: "gatewayReportSettings"*/ "../common/modules/administration/general/gatewayReportSetting"
    ),
);
export const companyChargeSettingsModule = loaderWrapper(
  () => import(/*webpackChunkName: "assetCostCompanySettings"*/ "../common/modules/companyChargeSettings"),
);
export const chargeLocationsModule = loaderWrapper(
  () => import(/*webpackChunkName: "chargeLocations"*/ "./modules/administration/location/charges/chargeLocations"),
);

export const downloadModule = loaderWrapper(() => import("./modules/download"));

export const oneMapModule = loaderWrapper(() => import(/*webpackChunkName: "oneMap"*/ "../common/modules/oneMap"));
