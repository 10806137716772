import { IconName } from "am-web-ui-shared/components";
import { onMailIconClickHandler } from "TARGET_BUILD/common/utils/commonUtils";
import React from "react";
import { Trans } from "react-i18next";
import { IconNormalBold, NormalBoldIcon } from "../../../../../components/DetailsPanel/index";
import { isResposibleWorker } from "./reponsibleWorkerValidator";

/**
 * @description - Work email.
 */
export const workEmail = (data) => {
  let email = "";

  if (isResposibleWorker(data)) {
    email = data.responsibleEmployeeEmail;
  }
  return (
    email && (
      <NormalBoldIcon
        id="pnlWorkEmail"
        normalId="lblWorkEmail"
        normalText={<Trans>{"common:LABEL_WORK_EMAIL"}</Trans>}
        boldId="txtWorkEmail"
        boldText={email}
        iconUrl={IconName.Email}
        className="red-icon"
        onIconClick={(event: any) => onMailIconClickHandler(event, email)}
      />
    )
  );
};

/**
 * @description - Designation.
 */
export const designation = (data) => {
  let workerDesignation = "";

  if (isResposibleWorker(data)) {
    workerDesignation = data.responsibleEmployeeDesignation;
  }
  return (
    workerDesignation && (
      <IconNormalBold
        id="pnlDesignation"
        normalId="lblDesignation"
        normalText={<Trans>{"common:LABEL_DESIGNATION"}</Trans>}
        boldId="txtDesignation"
        boldText={workerDesignation}
      />
    )
  );
};

/**
 * @description - Language.
 */
export const workerLanguage = (data) => {
  let language = "";

  if (isResposibleWorker(data)) {
    language = data.responsibleEmployeeLanguage;
  }
  return (
    language && (
      <IconNormalBold
        id="pnlLanguage"
        normalId="lblLanguage"
        normalText={<Trans>{"common:LABEL_LANGUAGE"}</Trans>}
        boldId="txtLanguage"
        boldText={language}
      />
    )
  );
};

/**
 * @description - Inventory worker and Ownership type.
 *
 * @param {object} data - Asset details object that contains current owner name and ownership type details.
 */
export const inventoryWorkerOwnershipType = (data) => {
  const ownerName = data.ownerName || "";
  const ownershipType =
    data.ownerShipTypeResponse && data.ownerShipTypeResponse.value ? ` / ${data.ownerShipTypeResponse.value}` : "";

  return ownerName || ownershipType ? (
    <IconNormalBold
      id="pnlInventoryWorkerOwnershipType"
      normalId="lblInventoryWorkerOwnershipType"
      normalText={<Trans>{"common:LABEL_INVENTORY_WORKER_OWNERSHIP_TYPE"}</Trans>}
      boldId="txtInventoryWorkerOwnershipType"
      boldText={`${ownerName}${ownershipType}`}
    />
  ) : null;
};

/**
 * @description - Exporting Array of the components that are used for detail part of the responsible worker section used on asset details.
 */
export default [workEmail, designation, workerLanguage, inventoryWorkerOwnershipType];
