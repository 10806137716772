import { ISagaModule } from "redux-dynamic-modules-saga";
import activeTrackingSaga from "./activeTrackingSaga";
import activeTrackingReducer from "./activeTrackingReducer";
import IActiveTrackingModuleState from "./iActiveTrackingState";

const activeTrackingModule: ISagaModule<IActiveTrackingModuleState> = {
  id: "activeTrackingModule",
  reducerMap: {
    ActiveTracking: activeTrackingReducer,
  },
  sagas: [activeTrackingSaga],
};

export default activeTrackingModule;
