export enum FEATURES {
  ASSETS_ASSET_LIST_LAST_SEEN = "assets.asset-list.lastSeen",
  WEB_SENTRY_DEBUGGING = "web.sentry.debugging",
  HEAVY_EQUIPMENT_TEMPLATE = "assets.heavyEquipment-serviceTemplate",
  HEAVY_EQUIPMENT_UTILIZATION = "assets.heavyEquipment-utilization",
  ENTERPRISE_TENANT = "tenent.enterprise-tenant",
  COMPANY_SETTING_CONFIRM_TRANSFER = "company.setting.confirm-transfer",
  LOCATION_COUNT = "web.location-list.asset-qty.count",
  EMPLOYEE_COUNT = "count.employee",
  DOCUMENT_PRESIGNED_S3_URL = "document.presigned.s3-url",
  WEB_VAN_GATEWAYS_SMART_INVENTORY = "web.van-gateways.smart-inventory",
  ASSETS_HEAVY_EQUIPMENT_SMART_INVENTORY = "assets.heavy-equipment.smart-inventory",
  PAT_OFFLINE_INVENTORY = "pat.offline-inventory",
  ENTERPRISE_TENANT_PDF_REPORT = "tenent.enterprise-tenant.pdf-report",
  TIME_BASED_FEATURE_FLAG = "charges.timebased-feature-flag",
  ASSET_QTY_ITEMS_HISTORY_ATTACHMENT = "asset.qty-items-history-attachment",
  ASSET_VAN_TRACKING = "assets.van-gw-tracking",
  PAT_SUGGESTED_TRANSFERS = "pat.suggested-transfers",
  CAPPING_RECALCULATION_FLAG = "charges.capping-recalculation-flag",
  PAT_SMART_INVENTORY_LOCATION_DETAILS = "pat.smart-inventory.location-details",
  LOST_AND_FOUND = "lost-and-found",
  ASSET_EXTENDED_RETURN_DATE = "asset.extended-return-date",
  HEAVY_EQUIPMENT_SERVICE_FILTER = "assets.heavyEquipment-service-filter",
  PAT_LOCATION_DETAILS_ADDRESS = "pat.location-details.address",
  RENTAL_SERVICES_MODULE = "tenant.rental-services-module",
  DASHBOARD_UTILIZATION_AND_BENCHMARKING = "dashboard.utilization-and-benchmarking",
  QTY_ITEM_COSTS_FLAG = "charges.qty-item-flag",
  CUSTOMIZE_DELIVERY_NOTE = "company-settings.customize-delivery-note",
  GEO_TAG = "web.geo-tag",
  SEARCH_HIGHLIGHT = "search-highlight",
  ASSET_SERVICES = "asset-services.sap-documents",
  ONE_MAP = "web.one-map",
  ONE_MAP_LOCATIONS = "web.one-map-locations",
  LICENSE_BUNDLE = "tenant.license-bundle",
  PRO_LICENSING = "web.pro-licensing",
  LOCKER_ENABLED_FLAG = "charges.locker-enabled-flag",
  MULTIPLE_TECHNICAL_USER_ENABLED = "web.multiple-technical-user-enabled",
  NEW_IDLE_API = "idle-api.new",
  DISABLED_HISTORY_ALERT_FILTER = "alerts.history-alert.filter.disabled",
  HILTI_ASSETS_IMPORT_MIGRATED = "assets.import.hilti-assets.migrate",
}

// map between feature and it's key,
// one of the use case is custom view layout where toolLastSeen is the key, not expected to be shown when feature is disabled
export const featureFlagMapping = {
  toolLastSeen: FEATURES.ASSETS_ASSET_LIST_LAST_SEEN,
  heavyEquipment: FEATURES.HEAVY_EQUIPMENT_TEMPLATE,
  heavyEquipmentUtilization: FEATURES.HEAVY_EQUIPMENT_UTILIZATION,
};
