import MoreFilterActions from "../../../actionConstants/moreFilterConstants";

export const filterInitialState = {
  isFilterPanelOpen: false,
};

export const setMoreFilter = (state) => {
  return {
    ...state,
    isFilterPanelOpen: true,
  };
};

export const closeMoreFilter = (state) => {
  return {
    ...state,
    isFilterPanelOpen: false,
  };
};

const filterReducer = (state = filterInitialState, action) => {
  switch (action.type) {
    case MoreFilterActions.OPEN_MORE_FILTER:
      return setMoreFilter(state);
    case MoreFilterActions.CLOSE_MORE_FILTER:
      return closeMoreFilter(state);
    default:
      return state;
  }
};

export default filterReducer;
