import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";
import { Field } from "redux-form";
import styled from "styled-components";
import LocaleManager from "../../helpers/localeManager";
import { default as TextLabel } from "../Field/field";
import IDatePickerProps from "./iDatePickerProps";

const DatePickerStyle = styled(DatePicker)`
  && {
    border: 2px solid
      ${(props) =>
        props["data-error"] ? props.theme.colors.base.hiltiRedError : props.theme.colors.base.heavyConcrete60};
    width: 100%;
    p {
      display: none;
    }
  }
  div {
    margin-left: 0;
    height: auto;
    max-height: 44px;
    align-self: flex-start;
    ::before {
      border: 0;
    }
    ::after {
      border: 0;
    }

    :hover {
      ::before {
        border-bottom: 0 !important;
      }
    }
    input {
      height: 44px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      font-family: ${(props) => props.theme.fontBold.fontFamily};
      font-weight: ${(props) => props.theme.fontBold.fontWeight};
      width: 408px;
    }
    button {
      background: ${(props) => props.theme.colors.base.heavyConcrete20};
      border-radius: 0;
      height: 44px;
    }
  }
`;

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendarPortal");

  return <Portal container={el}>{children}</Portal>;
};

/**
 * disable user manual input in datepicker
 * @param event - event object of current target
 */
const disableManualInput = (event) => {
  event.preventDefault();
};

const TextComponent = ({
  id,
  input,
  label,
  required,
  onSelectChange,
  disabled,
  selected,
  dateFormat,
  placeholder,
  getRef,
  meta: { touched, error, warning },
  minDate,
  maxDate,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeFormat,
  isDisableInputBox,
  isClearable,
  selectsStart,
  startDate,
  selectsEnd,
  endDate,
  openToDate,
  timeCaption,
}) => (
  <div id="DatePickerContainer">
    <TextLabel
      name={input.name}
      htmlFor={input.name}
      label={label}
      error={error}
      touched={touched}
      warning={warning}
      required={required}
    >
      <DatePickerStyle
        id={id}
        data-error={error && touched ? error : ""}
        dateFormat={dateFormat}
        placeholderText={placeholder ? placeholder : dateFormat}
        selected={input.value ? new Date(input.value) : selected ? new Date(selected) : null}
        name={input.name}
        ref={getRef}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        showMonthDropdown={true}
        showYearDropdown={true}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeFormat={timeFormat}
        dropdownMode="select"
        popperContainer={CalendarContainer}
        onChangeRaw={isDisableInputBox ? (event) => disableManualInput(event) : null}
        isClearable={isClearable}
        onChange={(date) => {
          const dateValue = isClearable && !date && selected ? new Date(selected) : date;
          input.onChange(dateValue);
          if (onSelectChange) {
            onSelectChange(dateValue);
          }
        }}
        selectsStart={selectsStart}
        startDate={startDate}
        selectsEnd={selectsEnd}
        endDate={endDate}
        className={showTimeSelectOnly && "time-only"}
        openToDate={openToDate}
        timeCaption={timeCaption}
        autoComplete="off"
      />
    </TextLabel>
  </div>
);

class DatePickerForm extends React.PureComponent<IDatePickerProps> {
  render() {
    return (
      <Field
        name={this.props.name}
        label={this.props.label}
        type={this.props.type}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        required={this.props.required}
        format={(value) => {
          return LocaleManager.dateHelpers.isTimeZonePositive(value) ? value + "T00:00:00" : value;
        }}
        component={TextComponent}
        validate={this.props.validation}
        onSelectChange={this.props.onSelectChange}
        {...this.props}
      />
    );
  }
}
export default DatePickerForm;
