import { HelperLodash, SharedUtils } from "am-web-ui-shared/helpers";

export const TRANSFER_EMAIL = "transfer_email";
export const CONFIRM_TRANSFER_POLICY = "confirm_transfer_policy";
export const TRANSFER_SETTINGS = "transfer_settings";
export const VAN_PRIVATE_MODE_SETTINGS = "van_private_mode_settings";
export const TRANSFER_SETTINGS_DELIVERY_NOTE_OPTIONS = "transfer_settings_delivery_note_options";

enum EmailEnabled {
  ON = "on",
  OFF = "off",
}

interface IOptions {
  name: string;
  value: string[] | string;
}

interface IDeliveryNoteOptions {
  asset_identification_details: string;
  asset_item_details: string;
  disclaimer_text: string;
  qty_item_identification_details: string;
  qty_item_item_details: string;
}

export const getDeliveryNoteSelectedOptions = (options: IOptions[]) => {
  const result = {} as IDeliveryNoteOptions;
  options.forEach((item) => {
    if (Array.isArray(item.value)) {
      result[item.name] = item.value.join(", ");
    } else {
      result[item.name] = item.value;
    }
  });
  return result;
};

export const trackCompanySettingsPolicy = (transferConfirmationVal: string, isDeliveryNoteEmailEnabled: boolean) => {
  SharedUtils.pushDataToGA(CONFIRM_TRANSFER_POLICY, null, {
    status: transferConfirmationVal,
  });
  const settingsStatus = isDeliveryNoteEmailEnabled ? EmailEnabled.ON : EmailEnabled.OFF;
  SharedUtils.pushDataToGA(TRANSFER_EMAIL, null, {
    status: settingsStatus,
  });
};

export const trackVanPrivateModeSetting = (values = []) => {
  const obj = {};
  values.forEach((item) => {
    obj[item?.name] = item?.value;
  });
  SharedUtils.pushDataToGA(VAN_PRIVATE_MODE_SETTINGS, null, obj);
};

export const trackTransferSettingsClicked = () => {
  SharedUtils.pushDataToGA(TRANSFER_SETTINGS, null, null);
};

export const trackSavedOptions = (options: IOptions[]) => {
  const {
    asset_identification_details,
    asset_item_details,
    disclaimer_text,
    qty_item_identification_details,
    qty_item_item_details,
  } = getDeliveryNoteSelectedOptions(options);
  SharedUtils.pushDataToGA(TRANSFER_SETTINGS_DELIVERY_NOTE_OPTIONS, null, {
    asset_identification_details,
    asset_item_details,
    disclaimer_text,
    qty_item_identification_details,
    qty_item_item_details,
  });
};
