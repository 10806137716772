// @ts-ignore
import { ChargesLocationActions, LoaderAction, ToastrActions } from "custom-actions";
import { put, select, takeEvery, takeLatest, delay, all } from "redux-saga/effects";
// @ts-ignore
import { API_DELAY_TIME } from "TARGET_BUILD/config";
import { ChargesActions } from "../../../actionConstants";
import {
  createPlugCost,
  getAssetChargeSetting,
  getAssetChargesImportHistory,
  getChargeLocationDetails,
  getChargeLocations,
  getChargeLocationsAttributes,
  getChargeLocationsCustomViews,
  getChargeLocationsFilterAttributes,
  getCompanyChargeSettings,
  getCustomViewFields,
  getCustomViews,
  getDefaultViewApi,
  getImportAssetChargesHistoryErrors,
  getPendingCharges,
  getPendingChargesFilterAttributes,
  getTemplateChargeSetting,
  getWorkDaysMetaData,
  resetToDefaultViewApi,
  submitAssetChargeSetting,
  submitTemplateChargeSetting,
  updateCompanyCharge,
  updateCostLocation,
  updateCustomGridFieldsApi,
  deletePlugCost,
  getQtyPendingCharges,
  getQtyItemDefaultViewApi,
  getQtyCustomViewFields,
  getQtyItemCustomViews,
  resetToQtyItemDefaultViewApi,
  updateQtyItemCustomGridFieldsApi,
  getQtyItemPendingChargesFilterAttributes,
} from "../../../api/chargesApi";
import Charge from "../../../models/charges/chargesModel";
import CompanyChargeSettings from "../../../models/charges/companyChargeModel";
import PendingCharge from "../../../models/charges/pendingCharge";
import {
  validateChargeSettingErrors,
  validateChargeSettingResponse,
  validateResponse,
} from "../../../utils/errorUtils";
import { call } from "../../../sagas/common/commonSaga";
import { getFilterQueryObj, getQueryObj } from "../../../sagas/stateSelector";

import { showToastr, ToastrType } from "../../../actions/toastrAction";
import { getToastMessages } from "../../../models/charges/chargesOrchestration";
import PendingChargesListModel from "../../../models/charges/pendingChargesListModel";
import {
  FILTER_SEARCH_VALUE,
  FILTER_SHOW_MORE,
  trackEventsForFilterSearch,
  trackEventsForAppliedFilter,
  trackChargesCombinedFilters,
  trackDeletePlugCostEvent,
} from "../../../../analytics/events/charges";
import QtyItemPendingCharge from "TARGET_BUILD/common/models/charges/qtyItemPendingCharge";
import { fetchQtyItemChargesList } from "../actions/chargesActionRoot";

const parseList = ({
  isRecalculationInProgress,
  error,
  totalRecords,
  offset,
  limit,
  response = [],
}): PendingChargesListModel<PendingCharge> => {
  const records: PendingCharge[] = [];
  response.forEach((val) => {
    records.push(new PendingCharge(val));
  });
  return new PendingChargesListModel<PendingCharge>(
    isRecalculationInProgress,
    totalRecords,
    limit,
    offset,
    records,
    error,
  );
};
const parseQtyList = ({
  isRecalculationInProgress,
  error,
  totalRecords,
  offset,
  limit,
  response = [],
}): PendingChargesListModel<QtyItemPendingCharge> => {
  const records: QtyItemPendingCharge[] = [];
  response.forEach((val) => {
    records.push(new QtyItemPendingCharge(val));
  });
  return new PendingChargesListModel<QtyItemPendingCharge>(
    isRecalculationInProgress,
    totalRecords,
    limit,
    offset,
    records,
    error,
  );
};
const savedSuccessfullyTransKey = "common:SAVED_SUCCESSFULLY";
export function* getPendingChargesSaga() {
  yield takeLatest(ChargesActions.CH_GET_PENDING_CHARGES, getCharges);
}

export function* getCharges(action) {
  const isLoader = action.offset === 0;
  if (isLoader) {
    yield put({ type: LoaderAction.SET_LIST_LOADER });
  }
  const queryObj = yield select(getQueryObj, action.offset, "Charges");

  const data = yield getPendingCharges(queryObj, false);

  const payload: { list: PendingChargesListModel<PendingCharge>; offset: number } = {
    list: parseList(data),
    offset: action.offset,
  };

  yield put({ type: ChargesActions.UI_SAVE_PENDING_CHARGES, payload });

  if (isLoader) {
    yield put({ type: LoaderAction.UNSET_LIST_LOADER });
  }

  if (!action.offset) {
    yield put({ type: ChargesActions.CH_CHARGES_LIST_LOADER_END });
  }
  const appliedFilter = yield select((state) => state?.Charges?.appliedFilterQuery);
  if (appliedFilter?.length) {
    yield trackEventsForAppliedFilter(appliedFilter, data?.totalRecords);
  }
  if (appliedFilter?.length > 1) {
    yield trackChargesCombinedFilters(appliedFilter);
  }
}

export function* getQtyPendingChargesSaga() {
  yield takeLatest(ChargesActions.CH_GET_QTY_ITEM_PENDING_CHARGES, getQtyCharges);
}
export function* getQtyCharges(action) {
  const isLoader = action.offset === 0;
  if (isLoader) {
    yield put({ type: LoaderAction.SET_LIST_LOADER });
  }
  const queryObj = yield select(getQueryObj, action.offset, "Charges");

  const data = yield getQtyPendingCharges(queryObj);

  const payload: { list: PendingChargesListModel<QtyItemPendingCharge>; offset: number } = {
    list: parseQtyList(data),
    offset: action.offset,
  };

  yield put({ type: ChargesActions.UI_SAVE_QTY_PENDING_CHARGES, payload });

  if (isLoader) {
    yield put({ type: LoaderAction.UNSET_LIST_LOADER });
  }

  if (!action.offset) {
    yield put({ type: ChargesActions.CH_CHARGES_LIST_LOADER_END });
  }
  const appliedFilter = yield select((state) => state?.Charges?.appliedFilterQuery);
  if (appliedFilter?.length) {
    yield trackEventsForAppliedFilter(appliedFilter, data?.totalRecords);
  }
  if (appliedFilter?.length > 1) {
    yield trackChargesCombinedFilters(appliedFilter);
  }
}
/**
 * @description - Saga to get charges details.
 */
export function* fetchChargesDetailsSaga() {
  yield takeLatest(ChargesActions.CH_GET_ASSET_CHARGE_SETTING, fetchChargesDetails);
}

/**
 * @description - Saga to get charges details.
 */
export function* fetchTemplateChargesSettingSaga() {
  yield takeLatest(ChargesActions.CH_GET_TEMPLATE_CHARGE_SETTING, fetchTemplateChargeSetting);
}

/**
 * @description - add/edit template cost setting on template add/edit
 */
export function* submitTemplateChargeSettingSaga() {
  yield takeLatest(ChargesActions.CH_SUBMIT_TEMPLATE_CHARGE_SETTING, saveTemplateChargeSetting);
}

export function* saveTemplateChargeSetting(action) {
  let messages = [];
  const response = yield call(() => submitTemplateChargeSetting(action.chargeSettingData));

  const errorCode = validateChargeSettingResponse(response);
  if (action.isCreatingNewTemplate === true && errorCode && action.source === "asset") {
    yield put({ type: ChargesActions.SHOW_FAILURE_MESSAGE, showFailureMessageOnCreation: true });
  }

  if (action.source && !(action.source === "asset" && !action.isEdit)) {
    messages = getToastMessages(
      response,
      action.source,
      action.chargeSettingData.t,
      action.isCreatingNewTemplate,
      action.asset,
    );
    if (messages && messages.length) {
      yield put({ type: ToastrActions.SHOW_MULTIPLE_TOASTR, payload: messages });
    }
  }
}

/**
 * @description - Function to get charges views list from api.
 */
export function* fetchChargesDetails(action) {
  yield put({ type: LoaderAction.SET_EXPANTION_PANEL_DETAIL_LOADER });
  const chargesSetting = yield call(() => {
    return getAssetChargeSetting(action && action.id, false);
  });
  if (chargesSetting) {
    const value = chargesSetting.costTypeCode
      ? new Charge(chargesSetting)
      : { error: true, code: validateChargeSettingResponse(chargesSetting) };
    yield put({
      payload: {
        assetId: action.id,
        value,
      },
      type: ChargesActions.CH_SUCCESS_CHARGES_DETAILS,
    });
  }
  yield put({ type: LoaderAction.UNSET_EXPANTION_PANEL_DETAIL_LOADER });
}

/**
 * @description - Function to get charges views list from api.
 */
export function* fetchTemplateChargeSetting(action) {
  yield put({ type: LoaderAction.SET_EXPANTION_PANEL_DETAIL_LOADER });
  const templateChargeSetting = yield call(() => {
    return getTemplateChargeSetting(action && action.templateId);
  });

  if (templateChargeSetting) {
    const value = templateChargeSetting.costTypeCode
      ? new Charge(templateChargeSetting)
      : { error: true, code: validateChargeSettingResponse(templateChargeSetting) };
    yield put({
      templateChargeSetting: value,
      templateId: action && action.templateId,
      type: ChargesActions.CH_SUCCESS_TEMPLATE_CHARGES_SETTING,
    });
  }
  yield put({ type: LoaderAction.UNSET_EXPANTION_PANEL_DETAIL_LOADER });
}

/**
 * @description - Saga to get custom views list fields.
 */
export function* getPendingChargesCustomViewSaga() {
  yield takeLatest(ChargesActions.CH_GET_CUSTOM_VIEW, getPendingChargesCustomView);
}

/**
 * @description - Function to get custom views list fields from api.
 */
export function* getPendingChargesCustomView() {
  const response = yield call(() => {
    return getCustomViews();
  });
  if (response) {
    yield put({
      payload: response,
      type: ChargesActions.CH_GET_CUSTOM_VIEW_SUCCESS,
    });
  }
}

/**
 * @description - Saga to get custom views list fields.
 */
export function* getQtyItemPendingChargesCustomViewSaga() {
  yield takeLatest(ChargesActions.CH_GET_QTY_CUSTOM_VIEW, getQtyItemPendingChargesCustomView);
}

/**
 * @description - Function to get custom views list fields from api.
 */
export function* getQtyItemPendingChargesCustomView() {
  const response = yield call(() => {
    return getQtyItemCustomViews();
  });
  if (response) {
    yield put({
      payload: response,
      type: ChargesActions.CH_GET_CUSTOM_VIEW_SUCCESS,
    });
  }
}

/**
 * @description - Saga to get custom views list fields.
 */
export function* getPendingChargesCustomViewFieldsSaga() {
  yield takeLatest(ChargesActions.CH_GET_CUSTOM_VIEW_FIELDS, getPendingChargesCustomViewFields);
}

/**
 * @description - Function to get custom views list fields from api.
 */
export function* getPendingChargesCustomViewFields() {
  const response = yield call(() => {
    return getCustomViewFields();
  });
  if (response) {
    yield put({
      payload: response.fields,
      type: ChargesActions.CH_GET_CUSTOM_VIEW_FIELDS_SUCCESS,
    });
  }
}

/**
 * @description - Saga to get qtycustom views list fields.
 */
export function* getQtyPendingChargesCustomViewFieldsSaga() {
  yield takeLatest(ChargesActions.CH_GET_QTY_ITEM_CUSTOM_VIEW_FIELDS, getQtyPendingChargesCustomViewFields);
}

/**
 * @description - Function to get custom views list fields from api.
 */
export function* getQtyPendingChargesCustomViewFields() {
  const response = yield call(() => {
    return getQtyCustomViewFields();
  });
  if (response) {
    yield put({
      payload: response.fields,
      type: ChargesActions.CH_GET_CUSTOM_VIEW_FIELDS_SUCCESS,
    });
  }
}

function* hideFilterLoader() {
  yield put({ type: LoaderAction.UNSET_FILTER_LOADER });
}
function* showFilterLoader() {
  yield put({ type: LoaderAction.SET_FILTER_LOADER });
}

/**
 * @description To make API call for retrieving filter attributes
 */
export function* getPendingChargesFilterAttributesSaga() {
  yield takeLatest(ChargesActions.CH_GET_CHARGES_FILTER_FIELD, fetchPendingChargesFilterAttributes);
}

/**
 * @description To make API call for retrieving worker attributes
 * @param filters : filters to apply while retreiving data
 */
export function* fetchPendingChargesFilterAttributes(filters) {
  if (filters.delay) {
    yield delay(API_DELAY_TIME);
  }
  yield showFilterLoader();
  const filterQuery = yield select(getFilterQueryObj, "Charges", filters.payload, true);
  const response = yield call(() => {
    return getPendingChargesFilterAttributes(filterQuery);
  });
  if (!response.error) {
    yield put({
      payload: {
        filterPayload: filters.payload,
        response: response.response,
      },
      type: ChargesActions.CH_CHARGES_FILTER_FIELD_SUCCESS,
    });

    if (filters.payload?.query) {
      yield trackEventsForFilterSearch(filters.payload, FILTER_SEARCH_VALUE);
    }
    if (filters.payload?.query === "") {
      yield trackEventsForFilterSearch(filters.payload, FILTER_SHOW_MORE);
    }
  }
  yield hideFilterLoader();
}

export function* getQtyItemPendingChargesFilterAttributesSaga() {
  yield takeLatest(ChargesActions.CH_GET_QTY_ITEM_CHARGES_FILTER_FIELD, fetchQtyItemPendingChargesFilterAttributes);
}

/**
 * @description To make API call for retrieving worker attributes
 * @param filters : filters to apply while retreiving data
 */
export function* fetchQtyItemPendingChargesFilterAttributes(filters) {
  if (filters.delay) {
    yield delay(API_DELAY_TIME);
  }
  yield showFilterLoader();
  const filterQuery = yield select(getFilterQueryObj, "Charges", filters.payload, true);
  const response = yield call(() => {
    return getQtyItemPendingChargesFilterAttributes(filterQuery);
  });
  if (!response.error) {
    yield put({
      payload: {
        filterPayload: filters.payload,
        response: response.response,
      },
      type: ChargesActions.CH_CHARGES_FILTER_FIELD_SUCCESS,
    });

    if (filters.payload?.query) {
      yield trackEventsForFilterSearch(filters.payload, FILTER_SEARCH_VALUE);
    }
    if (filters.payload?.query === "") {
      yield trackEventsForFilterSearch(filters.payload, FILTER_SHOW_MORE);
    }
  }
  yield hideFilterLoader();
}

/**
 * @description method takes latest action and callback of fetch dealyed locations list.
 *  Blocks the fetchChargesList till delay promise is resolved. If user types the search quickly
 * it will not allow to many calls.
 */
export function* searchChargesListSaga() {
  yield takeLatest(ChargesActions.CH_CHARGES_LIST_FOR_SEARCH, fetchDelayedChargesList);
}

/**
 * @description method delay call and call to fetch locations list after delay promise is resolved
 */
export function* fetchDelayedChargesList(action) {
  yield getCharges(action);
}

export function* searchQtyChargesListSaga() {
  yield takeLatest(ChargesActions.CH_CHARGES_LIST_FOR_QTY_SEARCH, fetchQtyDelayedChargesList);
}

export function* fetchQtyDelayedChargesList(action: ReturnType<typeof fetchQtyItemChargesList>) {
  yield getQtyCharges(action);
}
/**
 * @description - add/edit asset cost setting on asset add/edit
 */
export function* submitAssetCostSettingSaga() {
  yield takeLatest(ChargesActions.CH_SUBMIT_ASSET_CHARGE_SETTING, saveAssetCostSetting);
}

export function* saveAssetCostSetting(action) {
  const response = yield call(() => submitAssetChargeSetting(action.chargeSettingData));

  const errorCode = validateChargeSettingResponse(response);

  if (action.chargeSettingData.method === "POST" && errorCode) {
    yield put({ type: ChargesActions.SHOW_FAILURE_MESSAGE, showFailureMessageOnCreation: true });
  }
  if (action.chargeSettingData.isEdit) {
    const messages = getToastMessages(response, undefined, action.chargeSettingData.t, false, undefined);
    if (messages && messages.length) {
      yield put({ type: ToastrActions.SHOW_MULTIPLE_TOASTR, payload: messages });
    }
  }

  // to be put inside the condition
  yield put({
    payload: response,
    type: ChargesActions.CH_SUBMIT_ADD_CHARGE_SETTING_RESPONSE,
  });
}

/**
 *
 * @description - Saga to update custom grid fields
 */
export function* updateCustomGridFieldsChargesSaga() {
  yield takeLatest(ChargesActions.CH_UPDATE_CUSTOM_GRID_FIELDS, updateCustomGridFields);
}

/**
 * @description - Function to update custom grid fields
 */
export function* updateCustomGridFields() {
  const state = yield select();

  const data = {
    listOfFields: state && state.Charges && state.Charges.gridFields,
  };
  const response = yield call(() => {
    return updateCustomGridFieldsApi(data);
  });
  if (!response.error) {
    yield put({ type: ChargesActions.CH_CLEAR_DEFAULT_GRID_FIELDS });
    yield put({ type: ChargesActions.CH_GET_CUSTOM_VIEW, selectedView: response });
    yield put(showToastr(ToastrType.success, savedSuccessfullyTransKey));
  }
}

/**
 *
 * @description - Saga to update custom grid fields
 */
export function* updateQtyItemCustomGridFieldsChargesSaga() {
  yield takeLatest(ChargesActions.CH_UPDATE_QTY_ITEM_CUSTOM_GRID_FIELDS, updateQtyItemCustomGridFields);
}

/**
 * @description - Function to update custom grid fields
 */
export function* updateQtyItemCustomGridFields() {
  const state = yield select();

  const data = {
    listOfFields: state?.Charges?.gridFields,
  };
  const response = yield call(() => {
    return updateQtyItemCustomGridFieldsApi(data);
  });
  if (!response.error) {
    yield put({ type: ChargesActions.CH_CLEAR_DEFAULT_GRID_FIELDS });
    yield put({ type: ChargesActions.CH_GET_QTY_CUSTOM_VIEW, selectedView: response });
    yield put(showToastr(ToastrType.success, savedSuccessfullyTransKey));
  }
}

/**
 *
 * @description - Saga to reset a view to default
 */
export function* resetToDefaultViewSaga() {
  yield takeLatest(ChargesActions.CH_RESET_TO_DEFAULT_VIEW, resetToDefaultView);
}

/**
 * @description - Function to reset a view to default
 *
 */
export function* resetToDefaultView() {
  const response = yield call(() => {
    return resetToDefaultViewApi();
  });
  if (!response.error) {
    yield put({ type: ChargesActions.CH_GET_CUSTOM_VIEW });
  }
}

/**
 *
 * @description - Saga to reset a view to default
 */
export function* resetToQtyItemDefaultViewSaga() {
  yield takeLatest(ChargesActions.CH_RESET_TO_QTY_ITEM_DEFAULT_VIEW, resetToQtyItemDefaultView);
}

/**
 * @description - Function to reset a view to default
 *
 */
export function* resetToQtyItemDefaultView() {
  const response = yield call(() => {
    return resetToQtyItemDefaultViewApi();
  });
  if (!response.error) {
    yield put({ type: ChargesActions.CH_GET_QTY_CUSTOM_VIEW });
  }
}

/**
 *
 * @description - Saga to reset a view to default
 */
export function* getDefaultViewSaga() {
  yield takeLatest(ChargesActions.CH_GET_DEFAULT_VIEW, getDefaultView);
}

/**
 * @description - Function to get default view fields
 *
 */
export function* getDefaultView() {
  const response = yield call(() => {
    return getDefaultViewApi();
  });
  if (!response.error) {
    yield put({ type: ChargesActions.CH_GET_DEFAULT_VIEW_SUCCESS, data: response });
  }
}

/**
 *
 * @description - Saga to reset a qty item view to default
 */
export function* getQtyItemDefaultViewSaga() {
  yield takeLatest(ChargesActions.CH_GET_QTY_ITEM_DEFAULT_VIEW, getQtyItemDefaultView);
}

/**
 * @description - Function to get Qty Item default view fields
 *
 */
export function* getQtyItemDefaultView() {
  const response = yield call(() => {
    return getQtyItemDefaultViewApi();
  });
  if (!response.error) {
    yield put({ type: ChargesActions.CH_GET_QTY_ITEM_DEFAULT_VIEW_SUCCESS, data: response });
  }
}

export function* fetchCompanyChargeSettingsSaga() {
  yield takeLatest(ChargesActions.CH_GET_COMPANY_CHARGE_SETTINGS, getCompanyChargeSetting);
}
/**
 * get License And Usage detail.
 */
function* getCompanyChargeSetting() {
  const response = yield call(() => {
    return getCompanyChargeSettings();
  });
  if (!response.error) {
    yield put({
      payload: chargesListForCompany(response),
      type: ChargesActions.CH_GET_COMPANY_CHARGE_SETTINGS_SUCCESS,
    });
  }
}
const chargesListForCompany = (response) => {
  const records: CompanyChargeSettings = Object.assign({}, response);
  return records;
};

export function* fetchWorkdaysSaga() {
  yield takeLatest(ChargesActions.CH_GET_WORKDAYS, getWorkdays);
}
/**
 * get License And Usage detail.
 */
function* getWorkdays() {
  const response = yield call(() => {
    return getWorkDaysMetaData();
  });
  if (!response.error) {
    yield put({
      payload: response,
      type: ChargesActions.CH_GET_WORKDAYS_SUCCESS,
    });
  }
}

/**
 * Returns list of Charges Location by calling relevant API
 * @description : saga for getting the list of charges location.
 */
export function* fetchChargeLocationsSaga() {
  yield takeLatest(ChargesLocationActions.CH_CHARGE_LOCATIONS_GET_LIST, getChargeLocationsData);
}

/**
 * Returns list of service templates by calling relevant API
 * @description : getChargeLocationsData generator for getting the list of services.
 */
export function* getChargeLocationsData(action) {
  const isLoader = action.offset === 0;
  if (isLoader) {
    yield put({ type: LoaderAction.SET_LIST_LOADER });
  }
  const queryObj = yield select(getQueryObj, action.offset, "ChargeLocations", action.limit);
  let chargeLocations = yield call(() => {
    return getChargeLocations(queryObj, false);
  });

  if (chargeLocations.error) {
    chargeLocations = { response: [] };
  }

  const payloadObj = {
    chargeLocations,
    offset: action.offset,
  };
  yield put({ type: ChargesLocationActions.CH_CHARGE_LOCATIONS_SET_LIST_RESPONSE, payload: payloadObj });
  if (isLoader) {
    yield put({ type: LoaderAction.UNSET_LIST_LOADER });
  }
}

/**
 * used make API call for retrieving asset attributes
 */
export function* getChargeLocationsFilterAttributesSaga() {
  yield takeEvery(ChargesLocationActions.CH_CHARGE_LOCATIONS_GET_FILTER_FIELD, fetchChargeLocationsFilterAttributes);
}
/**
 * used make API call for retrieving asset attributes
 *
 * @param action
 */
function* fetchChargeLocationsFilterAttributes(action) {
  if (action.delay) {
    yield delay(API_DELAY_TIME);
  }
  yield showFilterLoader();
  const filterPayload = { ...action.payload };
  delete filterPayload.customAttribute;
  delete filterPayload.query;
  const filterQuery = yield select(getFilterQueryObj, "ChargeLocations", filterPayload, true);
  const response = yield call(() => {
    return getChargeLocationsFilterAttributes(filterQuery);
  });
  if (!response.error) {
    yield put({
      payload: {
        filterPayload: action.payload,
        response: response ? response.response : {},
      },
      type: ChargesLocationActions.CH_CHARGE_LOCATIONS_FILTER_FIELD_SUCCESS,
    });
  }
  yield hideFilterLoader();
}

/**
 * Returns Charge Location details by calling relevant API
 * @description : saga for getting the charge location details.
 */
export function* fetchChargeLocationDetailsSaga() {
  yield takeLatest(ChargesLocationActions.CH_CHARGE_LOCATION_GET_DETAILS, getChargeLocationDetailsData);
}

/**
 * Returns list of service templates by calling relevant API
 * @description : getChargeLocationDetailsData generator for getting the list of services.
 */
function* getChargeLocationDetailsData(action) {
  yield put({ type: LoaderAction.SET_DETAIL_LOADER });
  const chargeLocationDetails = yield call(() => {
    return getChargeLocationDetails(action.costLocationId);
  });
  yield put({
    payload: { chargeLocationDetails },
    type: ChargesLocationActions.CH_CHARGE_LOCATION_DETAILS_SET_RESPONSE,
  });
  yield put({ type: LoaderAction.UNSET_DETAIL_LOADER });
}

/**
 *
 * @description - Saga to get charge locations custom views
 */
export function* getChargeLocationCustomViewsSaga() {
  yield takeLatest(ChargesLocationActions.CH_CHARGE_LOCATIONS_CUSTOM_VIEW, getChargeLocationCustomViews);
}

/**
 * @description - Function to get charge locations custom views
 *
 */
export function* getChargeLocationCustomViews() {
  const response = yield call(() => {
    return getChargeLocationsCustomViews();
  });
  if (!response.error) {
    yield put({ type: ChargesLocationActions.CH_CHARGE_LOCATIONS_CUSTOM_VIEW_SUCCESS, payload: response });
  }
}

/**
 *
 * @description - Saga to get charge locations attributes
 */
export function* getChargeLocationAttributesSaga() {
  yield takeLatest(ChargesLocationActions.CH_CHARGE_LOCATIONS_ATTRIBUTES, getChargeLocationAttributes);
}

/**
 * @description - Function to get charge locations attributes
 *
 */
export function* getChargeLocationAttributes() {
  const response = yield call(() => {
    return getChargeLocationsAttributes();
  });
  if (!response.error) {
    yield put({ type: ChargesLocationActions.CH_CHARGE_LOCATIONS_ATTRIBUTES_SUCCESS, payload: response.fields });
  }
}
export function* updateCompanyChargeSettingsSaga() {
  yield takeLatest(ChargesActions.UPDATE_COMPANY_COST_SETTINGS, updateCompanyChargeSettings);
}
/**
 * get License And Usage detail.
 */
function* updateCompanyChargeSettings(payload) {
  // resetting the value to false before making update call
  yield put({
    payload: false,
    type: ChargesActions.UPDATE_COMPANY_COST_SETTINGS_SUCCESS,
  });

  let response = yield call(() => {
    return updateCompanyCharge(payload.item);
  });
  if (response.error) {
    response = validateChargeSettingErrors(response, payload.t);
    yield put({ type: ToastrActions.SHOW_MULTIPLE_TOASTR, payload: response });
  } else {
    yield put({
      payload: response,
      type: ChargesActions.UPDATE_COMPANY_COST_SETTINGS_SUCCESS,
    });
    yield put(showToastr(ToastrType.success, savedSuccessfullyTransKey));
  }
}
export function* updateCostLocationSettingsSaga() {
  yield takeLatest(ChargesLocationActions.CH_UPDATE_COST_LOCATION_SETTINGS, updateCostLocationSettings);
}
/**
 * Update Cost Location Settings
 */
function* updateCostLocationSettings(payload) {
  let response = yield call(() => {
    return updateCostLocation(payload.data);
  });
  if (response) {
    if (response.error) {
      response = validateChargeSettingErrors(response, payload.t);
      yield put({ type: ToastrActions.SHOW_MULTIPLE_TOASTR, payload: response });
    } else {
      yield put(showToastr(ToastrType.success, "common:SAVED_SUCCESSFULLY"));
      yield put({
        payload: false,
        type: ChargesLocationActions.CH_UPDATE_COST_LOCATION_SETTINGS_SUCCESS,
      });
      yield put({ limit: 100, offset: 0, type: ChargesLocationActions.CH_CHARGE_LOCATIONS_GET_LIST });
    }
  }
}

/**
 * Returns Root Charge Location details by calling relevant API
 * @description : saga for getting the root charge location details.
 */
export function* fetchRootChargeLocationDetailsSaga() {
  yield takeLatest(ChargesLocationActions.CH_ROOT_CHARGE_LOCATION_GET_DETAILS, getRootChargeLocationDetailsData);
}

/**
 * Returns Root Charge Location details by calling relevant API
 * @description : getRootChargeLocationDetailsData generator for getting the list of services.
 */
function* getRootChargeLocationDetailsData(action) {
  const rootChargeLocationDetails = yield call(() => {
    return getChargeLocationDetails(action.rootCostLocationId);
  });
  yield put({
    payload: { rootChargeLocationDetails },
    type: ChargesLocationActions.CH_ROOT_CHARGE_LOCATION_DETAILS_SET_RESPONSE,
  });
}

export function* getImportAssetChargesHistoryDataSaga() {
  yield takeLatest(ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY, getImportAssetChargesHistoryData);
}

/**
 * calling an API to get import file history data
 */

export function* getImportAssetChargesHistoryData(action) {
  let response = yield call(() => {
    return getAssetChargesImportHistory(action.apiType);
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY_RESPONSE, payload: response });
}

export function* getImportHistoryAssetChargesErrorsDataSaga() {
  yield takeLatest(ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS, getImportHistoryDataErrors);
}

export function* getImportHistoryDataErrors(action) {
  let newHistoryErrorPayload = null;
  const response = yield call(() => {
    return getImportAssetChargesHistoryErrors(action.historyId);
  });

  if (response.error) {
    newHistoryErrorPayload = {
      id: action.accordinId,
      response: [],
    };
  } else {
    const historyId = action.historyId;
    const detailObj = {
      [historyId]: response.uploadHistoryErrorDetailResponses,
    };
    newHistoryErrorPayload = detailObj;
  }
  yield put({
    type: ChargesActions.GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS_RESPONSE,
    payload: newHistoryErrorPayload,
  });
}

/**
 * use to add plug cost form data
 */
export function* addPlugCostSaga() {
  yield takeEvery(ChargesActions.ADD_PLUG_COST, addPlugCost);
}

export function* addPlugCost(action) {
  const response = yield call(() => {
    return createPlugCost(action.plugCostData);
  });
  if (!response.error) {
    yield put({ type: ChargesActions.CH_CHARGES_RESET_QUERY_PARAM });
    yield put({
      sortQuery: { sortField: "createdAt", sortType: "-" },
      type: ChargesActions.SET_CHARGES_SORT_QUERY,
    });
    yield put({
      offset: 0,
      type: ChargesActions.CH_GET_PENDING_CHARGES,
    });
    yield put({
      type: ChargesActions.CH_GET_CHARGES_FILTER_FIELD,
    });
    yield put(showToastr(ToastrType.success, savedSuccessfullyTransKey));
  } else if (response.error) {
    yield put(showToastr(ToastrType.error, `errorCodes:${validateResponse(response).code}`));
  }
}

export function* deletePlugCostSaga() {
  yield takeLatest(ChargesActions.DELETE_PLUG_COST, deletePlugCostData);
}

function* deletePlugCostData(action) {
  const response = yield call(() => {
    return deletePlugCost(action.plugCostId);
  });
  if (!response.error) {
    yield trackDeletePlugCostEvent();
    yield put(showToastr(ToastrType.success, "common:DELETED_SUCCESSFULLY", true));
    yield put({ type: ChargesActions.CH_GET_PENDING_CHARGES, offset: 0 });
    yield put({
      type: ChargesActions.CH_GET_CHARGES_FILTER_FIELD,
    });
  } else {
    const errorCode = validateResponse(response);
    if (errorCode) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorCode.code}`, true));
    }
  }
}

function* chargesSaga() {
  yield all([
    addPlugCostSaga(),
    deletePlugCostSaga(),
    fetchChargeLocationDetailsSaga(),
    fetchChargeLocationsSaga(),
    fetchChargesDetailsSaga(),
    fetchCompanyChargeSettingsSaga(),
    fetchRootChargeLocationDetailsSaga(),
    fetchTemplateChargesSettingSaga(),
    fetchWorkdaysSaga(),
    getChargeLocationAttributesSaga(),
    getChargeLocationCustomViewsSaga(),
    getChargeLocationsFilterAttributesSaga(),
    getDefaultViewSaga(),
    getQtyItemDefaultViewSaga(),
    getImportAssetChargesHistoryDataSaga(),
    getImportHistoryAssetChargesErrorsDataSaga(),
    getPendingChargesCustomViewFieldsSaga(),
    getPendingChargesCustomViewSaga(),
    getQtyItemPendingChargesCustomViewSaga(),
    getPendingChargesFilterAttributesSaga(),
    getQtyItemPendingChargesFilterAttributesSaga(),
    getPendingChargesSaga(),
    resetToDefaultViewSaga(),
    searchChargesListSaga(),
    submitAssetCostSettingSaga(),
    submitTemplateChargeSettingSaga(),
    updateCompanyChargeSettingsSaga(),
    updateCostLocationSettingsSaga(),
    updateCustomGridFieldsChargesSaga(),
    updateQtyItemCustomGridFieldsChargesSaga(),
    getQtyPendingChargesSaga(),
    getQtyPendingChargesCustomViewFieldsSaga(),
    searchQtyChargesListSaga(),
    resetToQtyItemDefaultViewSaga(),
  ]);
}

export default chargesSaga;
