import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3534.000000, -2332.000000)">
        <g transform="translate(3530.000000, 2328.000000)">
          <g>
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M20.9497475,19.5355339 L23.0710678,21.6568542 L21.6568542,23.0710678 L19.5355339,20.9497475 L17.4142136,23.0710678 L16,21.6568542 L18.1213203,19.5355339 L16,17.4142136 L17.4142136,16 L19.5355339,18.1213203 L21.6568542,16 L23.0710678,17.4142136 L20.9497475,19.5355339 Z M16.124299,14.4614874 L13.1715729,17.4142136 L15.2928932,19.5355339 L14.8284271,20 L4,20 L4,16 L8.9965099,14.0121996 L14.9899187,14.0121996 L16.124299,14.4614874 Z M12,12 C9.790861,12 8,10.209139 8,8 C8,5.790861 9.790861,4 12,4 C14.209139,4 16,5.790861 16,8 C16,10.209139 14.209139,12 12,12 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
