import { ISagaModule } from "redux-dynamic-modules-saga";
import { reducer as reduxFormReducer } from "redux-form";
import commonReducers from "./commonReducers";
import commonSaga from "../sagas/common/commonSaga";

export const rootModule: ISagaModule<any> = {
  id: "root-module",
  reducerMap: {
    Common: commonReducers,
    form: reduxFormReducer,
  },
  sagas: [commonSaga],
};

export default rootModule;
