/**
 * @description - Check if reponsible worker details are available.
 * @param data - Asset details data object.
 */
export const isResposibleWorker = (data): boolean => {
  return !!(data && data.responsibleEmployeeName);
};

/**
 * @description - Check if reponsible worker contact details are available.
 * @param data - Asset details data object.
 */
export const isResposibleWorkerContacts = (data): boolean => {
  return !!(data && data.responsibleEmployeePhone && data.responsibleEmployeePhone.length);
};
