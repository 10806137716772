export const CHARGE_SETTING_CHARGES_SCHEDULER = "chargeSetting";

export const WORKDAYS_FOR_WEEKLY_ASSET_COST = "timeBasedWeeklyDays";

export const WEEKLYASSETCOST = "timeBasedWeeklyAssetCost";

export const WORKDAYS_FOR_MONTHLY_ASSET_COST = "timeBasedMonthlyDays";

export const WORKDAYS_FOR_MONTHLY_PLACEHOLDER = "Ex. 24";

export const WORKDAYS_FOR_WEEKLY_PLACEHOLDER = "Ex. 4";

export const MONTHLYASSETCOST = "timeBasedMonthlyAssetCost";

export const TIME_BASED_DAILY_ASSET_COST = "timeBasedDailyAssetCost";

export const WEEKLY_ASSET_COST = "WEEKLY_ASSET_COST";

export const MONTHLY_ASSET_COST = "MONTHLY_ASSET_COST";

export const AFTER_X_WORKDAYS = "AFTER_X_WORKDAYS";

export const MIN_DAYS_WEEKLY = 2;

export const MAX_DAYS_WEEKLY = 6;

export const MIN_DAYS_MONTHLY = 3;

export const MAX_DAYS_MONTHLY = 26;

export const INCORRECTVALTRANSCONST = "common:INCORRECT_VALUE_ENTERED";

export const TIMEBASED = "Time based";

export const ASSET_TEMPLATES = "asset-templates";

export const MONTH_FILTER = "chargeCalculationByCalenderMonth";

export const CHARGE_CENTER = "chargeCenter";

export const QTY_ITEM_COST = "quantityitemcost";

export const ASSET_COST = "pendingcost";

export const CostTabConstants = {
  ASSET_COSTS_PATH: "/assetCosts",
  QTY_COSTS_PATH: "/assetCosts/quantity",
};
