import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" style={{ background: "#FFFFFF" }}>
    <g stroke="none" fill="#524F53">
      <path d="M15,10 L11.75,4.71806519 L8.5,10 L15,10 Z M10.8306572,2.5 L12.6221825,2.5 L12.622,2.50006519 L12.6357195,2.49206519 L17.5,10 L23,10 L20,21.5 L3.98,21.5 L1,10 L6,10 L10.8306572,2.5 Z" />
    </g>
  </svg>
);

export default SVG;
