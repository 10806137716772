import { IconName } from "am-web-ui-shared/components";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { UpfrontExpansionPanel } from "../../../../../components/DetailsPanel/index";
import IconBoldCount from "../../../../../components/DetailsPanel/SingleRow/IconBoldCount/iconBoldCount";
import { connect } from "react-redux";
import { toggleAlertList } from "../../../../../modules/alert/actions/alertNotificationAction";
import { IStorageAssetProps } from "./iStorageAssetProps";
import { getShowAlertListPopup } from "../../../reduxModule/assetSelectors";

const redirectObject = (data) => {
  return {
    label: (
      <ViewListWrapper id="pnlAssetsStored">
        <span>{<Trans>{"templates:VIEW_IN_ASSETS_LIST"}</Trans>}</span>
      </ViewListWrapper>
    ),
    link: {
      pathname: "/",
      state: {
        additionalData: {
          assetId: data.assetId,
          view: "child",
        },
        initialFilterValue: [
          {
            assetName: [data.name || " "],
          },
        ],
      },
    },
  };
};

export const ViewListWrapper = styled.div`
  margin: 0 0 0 40px;
  span {
    text-decoration: underline;
    display: inline-block;
    padding: 2px 0;
    font-size: ${(props) => props.theme.fontSize.base};
    word-break: break-word;
  }
`;

const StorageAssets = (data) => {
  const storageAssetCount = data.childrenDetails.childCount + data.totalQtyItemCount;
  return (
    <IconBoldCount
      panelId="lblStoragedAssets"
      iconUrl={IconName.StorageAsset}
      boldId="lblStoragedAssets"
      boldText={<Trans>{"assets:STORED_ASSETS"}</Trans>}
      countId="count"
      countText={storageAssetCount}
    />
  );
};

const StorageAsset = (props: IStorageAssetProps) => {
  const { assetDetailsData, showAlertListPopup } = props;
  const handleLinkClick = () => {
    showAlertListPopup && props.toggleAlertList();
  };

  return (assetDetailsData.childrenDetails && assetDetailsData.childrenDetails.childCount) ||
    assetDetailsData.totalQtyItemCount ? (
    <UpfrontExpansionPanel
      panelId="assetsStored"
      detailsData={assetDetailsData}
      summaryComponents={[StorageAssets]}
      associatedRowId={assetDetailsData.assetId}
      isEnable={true}
      isPanelExpanded={true}
      redirect={redirectObject(assetDetailsData)}
      onClickLink={handleLinkClick}
      isPadding={false}
    />
  ) : null;
};
export const mapStateToProps = ({ HeaderCount }) => ({
  showAlertListPopup: getShowAlertListPopup(HeaderCount),
});

export default connect(mapStateToProps, { toggleAlertList })(StorageAsset);
