import Gateway from "../gateway/gateway";
import { IHintList } from "../models/hiltiIntegration.list";
import { IErrorResponse } from "../models/iApiError";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import FeatureFlagManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";

/**
 * get api for listing hilti unregisterised
 */
export const getHintSapList = async (queryObj, loader = true): Promise<IHintList | IErrorResponse> => {
  const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, "/unregistered-assets");
  return Gateway.get(url, { ...queryObj }, null, null, loader) as unknown as IHintList;
};

type HiltiAssetOwnerResponse = {
  response: {
    hiltiAssetOwnerName: string;
    hiltiAssetOwnerId: string;
    originCustomerId: string;
    moCountry: string;
  }[];
};

export const getHiltiAssetOwners = async (queryObj, loader = true) => {
  const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, "/tool/hilti-asset-owners");

  return Gateway.get<HiltiAssetOwnerResponse>(url, { ...queryObj }, null, null, loader).then((response) => {
    if ("error" in response) {
      return response;
    }

    return {
      ...response,
      response: response.response.map((r) => {
        return {
          ...r,
          hiltiAssetOwnerId: r.originCustomerId,
        };
      }),
    };
  });
};

/*
  API to get SAP data by toolId
*/

export const getUnregisteredHiltiAssetByToolId = async (toolId): Promise<any> =>
  Gateway.get(
    getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/unregistered-assets/${toolId}`),
    null,
    null,
    null,
    false,
  );

export const getUnregisteredHiltiAssetByScanCode = async (scanCode): Promise<any> =>
  Gateway.get(getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/tool/${scanCode}`), null, null, null, false);

export const fetchBadSoHBatteryList = async (offset: number = 0): Promise<any> =>
  Gateway.get(
    getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/battery/bad?limit=100&offset=${offset}`),
    null,
    null,
    null,
    false,
  );

export const fetchBadSoHBatteryOverview = async (): Promise<any> =>
  Gateway.get(getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, "/battery/health/count"), null, null, null, false);

export const getGeoTags = async (): Promise<any> =>
  Gateway.get(
    getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/unregistered-assets/geotags`),
    null,
    null,
    null,
    false,
  );
