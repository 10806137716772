import React from "react";
import styled from "styled-components";

const Inner = styled.div`
  padding-top: 24px;
  overflow-y: auto;
  .search-bar {
    padding-top: 0;
  }
`;
const InnerWrapper = styled.div`
  width: 992px;
  margin: 0 auto;
`;
const DialogContainer = (props: any) => {
  return (
    <Inner className="dialog-container">
      <InnerWrapper>{props.children}</InnerWrapper>
    </Inner>
  );
};

export default DialogContainer;
