import { ALERT_API_DELAY_TIME } from "TARGET_BUILD/config";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  AlertNotificationAction,
  FieldRequestConstants,
  ConfirmTransferActions,
} from "TARGET_BUILD/common/actionConstants";
import { getActiveAlertCount } from "../../api/alertNotificationApi";
import { getFieldRequestsCountApi } from "../../api/fieldRequestApi";
import { isErrorResponse, validateResponse } from "../../utils/errorUtils";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { getConfirmTransferCountApi } from "../../api/confirmTransfer";
import { AssetType } from "TARGET_BUILD/common/utils/enums/assetEnum";
import { RootState } from "TARGET_BUILD/common/store/store";
import { getAssetsList } from "TARGET_BUILD/common/api/assetApi";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import ActiveTrackingApi, { IErrorResponse } from "TARGET_BUILD/common/api/activeTrackingApi";
import { SuggestedTransferListResponse } from "TARGET_BUILD/common/models/alert/suggestedTransfer";

export const getFieldRequestCompanySettings = (state) => {
  return HelperLodash.get(state, "Company.isFieldRequestEnabled");
};

/**
 * Saga to get alerts count
 */
export function* getAlertsCount() {
  yield takeLatest(AlertNotificationAction.AN_GET_ALERTS_COUNT, getAlertCount);
}

export function* getAlertCount() {
  const response = yield call(() => {
    return getActiveAlertCount();
  });
  if (!response.error) {
    yield put({ type: AlertNotificationAction.AN_SET_ALERTS_COUNT, payload: response.count });
  }
  yield delay(ALERT_API_DELAY_TIME);
  yield put({ type: AlertNotificationAction.AN_GET_ALERTS_COUNT });
}

export function* getFieldRequestsCountSaga() {
  yield takeLatest(FieldRequestConstants.AM_GET_FIELD_REQUESTS_COUNT, getFieldRequestsCount);
}

function* getFieldRequestsCount() {
  const response = yield call(() => {
    return getFieldRequestsCountApi();
  });

  const errorResponse = validateResponse(response);
  if (errorResponse) {
    yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`));
  } else {
    yield put({
      payload: response,
      type: FieldRequestConstants.GET_FIELD_REQUEST_COUNT_SUCCESS,
    });
  }
  const isFieldRequestEnabled = yield select(getFieldRequestCompanySettings);
  if (isFieldRequestEnabled) {
    yield delay(ALERT_API_DELAY_TIME);
    yield put({ type: FieldRequestConstants.AM_GET_FIELD_REQUESTS_COUNT });
  }
}

export function* getConfirmTransferCountSaga() {
  yield takeLatest(ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT, getConfirmTransferCount);
}

export function* getConfirmTransferCount() {
  const response = yield call(() => {
    return getConfirmTransferCountApi();
  });
  if (!response.error) {
    yield put({ type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT_RESPONSE, payload: response });
  }
  yield delay(ALERT_API_DELAY_TIME);
  yield put({ type: ConfirmTransferActions.GET_CONFIRM_TRANSFER_COUNT });
}

export function* getGatewayAssetsCountSaga() {
  yield takeLatest(AlertNotificationAction.GET_GATEWAY_ASSETS_COUNT, fetchGatewayAssetsCount);
}

function* fetchGatewayAssetsCount() {
  const SINGLE_GATEWAY_QUERY = {
    filter: "filter=assetType%3Ain=" + AssetType.Gateway,
    limit: 1,
    offset: 0,
  };
  const gatewayAssetsCount = yield select((state: RootState) => state.HeaderCount?.gatewayAssetsCount);
  if (SingletonFeatureManager.isFeatureEnabled(FEATURES.PAT_SUGGESTED_TRANSFERS) && gatewayAssetsCount === null) {
    const response = yield call(getAssetsList, SINGLE_GATEWAY_QUERY, false);
    if (response.error || response.errors) {
      yield put(showToastr(ToastrType.error, "common:ERROR_SOMETHING_WENT_WRONG"));
    } else {
      yield put({
        gatewayAssetsCount: response.totalRecords,
        type: AlertNotificationAction.SET_GATEWAY_ASSETS_COUNT,
      });
    }
  }
}

export function* getSuggestedTransfersCountSaga() {
  yield takeLatest(AlertNotificationAction.GET_SUGGESTED_TRANSFER_COUNT, getSuggestedTransfersCount);
}

export function* getSuggestedTransfersCount() {
  const response: IErrorResponse | SuggestedTransferListResponse = yield call(
    ActiveTrackingApi.getSuggestedTransfers,
    0,
    1,
  );
  if (!isErrorResponse(response)) {
    yield put({
      type: AlertNotificationAction.GET_SUGGESTED_TRANSFER_COUNT_SUCCESS,
      suggestedTransfersCount: (response as SuggestedTransferListResponse).totalRecords,
    });
  }
}

function* headerCountSaga() {
  yield all([
    getAlertsCount(),
    getFieldRequestsCountSaga(),
    getConfirmTransferCountSaga(),
    getGatewayAssetsCountSaga(),
    getSuggestedTransfersCountSaga(),
  ]);
}
export default headerCountSaga;
