// @ts-ignore
import { appConstants } from "TARGET_BUILD/config";
import Gateway from "../gateway/gateway";
import { IChargesLocationListModel } from "../models/charges/chargesLocationModel";
import { generateRequestData } from "../models/charges/chargesOrchestration";
import { IChargeSetting } from "../models/charges/iChargeSetting";
import { ICustomViewListModel } from "../models/customView/iCustomViewListModel";
import { IErrorResponse } from "../models/iApiError";
import { constructFilterQueryForExport } from "../utils/commonUtils";
import { HttpMethodEnum } from "../utils/enums/httpMethodEnum";
import { ModuleName } from "../utils/enums/moduleNameEnum";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { validateResponse } from "../utils/errorUtils";

import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import { FilterAttributes } from "../models/filter";
import { ICustomViewModel } from "../models/customView/iCustomViewModel";

const getEndpoint = (path) => {
  return getVersionedApiUrl(ServiceUrl.CHARGES, path);
};

export const getAssetChargeSetting = (id, loader = false): any => {
  return Gateway.getWithBigInt(
    getEndpoint(`/assets/unique-assets/${id}/charge-types`),
    null,
    null,
    null,
    loader,
  ) as IChargeSetting;
};

export const getTemplateChargeSetting = (id, loader = false, timeout = appConstants.API_DEFAULT_TIMEOUT): any => {
  return Gateway.getWithBigInt(
    getEndpoint(`/asset-templates/${id}/charge-types`),
    null,
    null,
    null,
    loader,
    timeout,
  ) as any;
};

export const submitTemplateChargeSetting = (payload): any => {
  const chargeSetting = generateRequestData(payload.chargeSetting);
  const url = getEndpoint(`/asset-templates/${payload.templateId}/charge-types`);
  return Gateway.call(payload.method, url, null, chargeSetting, null, false, {}, payload.loader);
};

/**
 * @description To get custom view for charges filter and sort.
 */
export const getCustomViews = async (): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(
    getEndpoint("/views?entityName=PendingCost"),
    null,
    null,
    null,
    false,
  ) as unknown as ICustomViewListModel;
};

/**
 * @description To get custom view for charges filter and sort.
 */
export const getQtyItemCustomViews = async (): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(
    getEndpoint(`/views?entityName=${ModuleName.QtyItemCharges}`),
    null,
    null,
    null,
    false,
  ) as unknown as ICustomViewListModel;
};

/**
 * @description to get pending charges
 * @memberof Charges
 */
export const getPendingCharges = async (queryObj, loader = true) => {
  const filter = queryObj.filter;
  delete queryObj.filter;
  let url = null;
  if (filter) {
    url = `assets/-/pending?${filter}`;
  } else {
    url = "assets/-/pending";
  }
  return Gateway.get(getEndpoint(`/${url}`), { ...queryObj }, null, null, loader) as unknown as any;
};

/**
 * @description to get qty item pending charges
 * @memberof Charges
 */
export const getQtyPendingCharges = async (queryObj, loader = false) => {
  const filter = queryObj.filter;
  delete queryObj.filter;
  let url = getEndpoint("/quantity-item/costs");
  if (filter) {
    url = getEndpoint(`/quantity-item/costs?${filter}`);
  }
  return Gateway.get(url, { ...queryObj }, null, null, loader) as unknown as any;
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in charges grid
 * @param filters : used to apply filters
 */
export const getPendingChargesFilterAttributes = async (filter?: string): Promise<any> => {
  let url = null;
  if (filter) {
    url = getEndpoint(`/assets/-/pending/attributes?${filter}`);
  } else {
    url = getEndpoint("/assets/-/pending/attributes");
  }
  return Gateway.get(`${url}`, null, null, null, false);
};

export const getQtyItemPendingChargesFilterAttributes = async (
  filter?: string,
): Promise<FilterAttributes | IErrorResponse> => {
  let url = null;
  if (filter) {
    url = getEndpoint(`/quantity-item/costs/attributes?${filter}`);
  } else {
    url = getEndpoint("/quantity-item/costs/attributes");
  }
  return Gateway.get(`${url}`, null, null, null, false);
};

/**
 * @description To get custom view for charges filter and sort.
 */
export const getCustomViewFields = async (): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(
    getEndpoint("/charges/attributes?entityName=PendingCost"),
    null,
    null,
    null,
    false,
  ) as unknown as any;
};

/**
 * @description To get custom view for charges filter and sort.
 */
export const getQtyCustomViewFields = async (): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get<ICustomViewListModel>(
    getEndpoint("/charges/attributes?entityName=Quantity-Item-Cost"),
    null,
    null,
    null,
    false,
  );
};

export const submitAssetChargeSetting = (payload): any => {
  const chargeSetting = generateRequestData(payload.chargeSetting);
  const url = getEndpoint(`/assets/unique-assets/${payload.assetId}/charge-types`);
  return Gateway.call(payload.method, url, null, chargeSetting, null, null, null, false);
};
/**
 * Returns all charges list in byte array format
 */
export const downloadChargesList = async (queryObj): Promise<any> => {
  if (queryObj) {
    const filter = queryObj.filter;
    delete queryObj.filter;
    let url = null;
    if (filter) {
      const filterQuery = constructFilterQueryForExport(filter);
      url = `assets/-/pending/export?${filterQuery}`;
    } else {
      url = "assets/-/pending/export";
    }
    return Gateway.get(getEndpoint(`/${url}`), { ...queryObj }, null, "blob");
  } else {
    return null;
  }
};

/**
 * Returns all qty item charges list in byte array format
 */
export const downloadQtyItemChargesList = async (queryObj): Promise<Blob | IErrorResponse> => {
  const filter = queryObj?.filter;
  delete queryObj.filter;
  let url = null;
  if (filter) {
    const filterQuery = constructFilterQueryForExport(filter);
    url = `quantity-item/costs/export?${filterQuery}`;
  } else {
    url = "quantity-item/costs/export";
  }
  return Gateway.get<Blob | IErrorResponse>(getEndpoint(`/${url}`), { ...queryObj }, null, "blob");
};
/**
 * @description To update custom grid fields
 */
export const updateCustomGridFieldsApi = async (payload): Promise<any> => {
  const formData = { listOfFields: payload.listOfFields, entityName: ModuleName.pendingCharges };
  const url = getEndpoint(`/views?entityName=${ModuleName.pendingCharges}`);
  return Gateway.call(HttpMethodEnum.put, url, null, formData, null, false);
};

/**
 * @description To update qty item custom grid fields
 */
export const updateQtyItemCustomGridFieldsApi = async (payload): Promise<ICustomViewModel | IErrorResponse> => {
  const formData = { listOfFields: payload.listOfFields, entityName: ModuleName.QtyItemCharges };
  const url = getEndpoint(`/views?entityName=${ModuleName.QtyItemCharges}`);
  return Gateway.call(HttpMethodEnum.put, url, null, formData, null, false);
};

/**
 * @description To reset a view to default
 */
export const resetToDefaultViewApi = async (): Promise<any> => {
  const url = getEndpoint(`/views?entityName=${ModuleName.pendingCharges}`);
  return Gateway.call(HttpMethodEnum.delete, url, null, null, null, false);
};

/**
 * @description To reset qty item view to default
 */
export const resetToQtyItemDefaultViewApi = async (): Promise<ICustomViewModel | IErrorResponse> => {
  const url = getEndpoint(`/views?entityName=${ModuleName.QtyItemCharges}`);
  return Gateway.call(HttpMethodEnum.delete, url, null, null, null, false);
};

/**
 * @description To reset a view to default
 */
export const getDefaultViewApi = async (): Promise<any> => {
  const url = getEndpoint(`/views/default?entityName=${ModuleName.pendingCharges}`);
  return Gateway.call(HttpMethodEnum.get, url, null, null, null, false);
};

/**
 * @description To reset a qty item charges view to default
 */
export const getQtyItemDefaultViewApi = async (loader = true): Promise<ICustomViewModel | IErrorResponse> => {
  const url = getEndpoint(`/views/default?entityName=${ModuleName.QtyItemCharges}`);
  return Gateway.get(url, null, null, null, loader) as unknown as any;
};

/**
 * @description to get company charge settings
 */
export const getCompanyChargeSettings = async () => {
  return Gateway.get(getEndpoint("/tenants"), null, null) as unknown as any;
};
/**
 * @description to get workdays metadata
 */
export const getWorkDaysMetaData = async () => {
  return Gateway.get(getEndpoint("/settings/pre-requisites"), null, null) as unknown as any;
};

/**
 * @description : draws the list of cost locations.
 * @queryObj : its use to provide query related to sorting, searching and ordering
 */
export const getChargeLocations = async (
  queryObj,
  loader = true,
): Promise<IChargesLocationListModel | IErrorResponse> => {
  const filter = queryObj.filter;
  delete queryObj.filter;
  let url = null;
  if (filter) {
    url = getEndpoint(`/cost-locations?${filter}`);
  } else {
    url = getEndpoint("/cost-locations");
  }
  return Gateway.get(
    url,
    {
      ...queryObj,
    },
    null,
    null,
    loader,
  ) as unknown as IChargesLocationListModel;
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in Charge Locations grid
 * @param filters : used to apply filters
 */
export const getChargeLocationsFilterAttributes = async (filter?: string): Promise<any> => {
  let url = null;
  if (filter) {
    url = getEndpoint(`/cost-locations/filters?${filter}`);
  } else {
    url = getEndpoint("/cost-locations/filters");
  }
  return Gateway.get(url, null, null, null, false);
};

/**
 * Method to Make API call for retreiving values for Charge Location Details
 * @param costLocationId
 */
export const getChargeLocationDetails = async (costLocationId: string): Promise<any> => {
  let url = null;
  url = getEndpoint(`/cost-locations/${costLocationId}`);
  return Gateway.get(url, null, null, null, false);
};

/**
 * @description To get custom view for charge location filter and sort.
 */
export const getChargeLocationsCustomViews = async (): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(getEndpoint("/cost-locations/views"), null, null, null, false) as unknown as ICustomViewListModel;
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in charges grid
 * @param filters : used to apply filters
 */
export const getChargeLocationsAttributes = async (filter?: string): Promise<any> => {
  let url = null;
  if (filter) {
    url = getEndpoint(`/cost-locations/attributes?${filter}`);
  } else {
    url = getEndpoint("/cost-locations/attributes");
  }
  return Gateway.get(`${url}`, null, null, null, false);
};
/* Method to Make API call for updating company charge settings
 * @param item : request object
 */
export const updateCompanyCharge = async (item?: any): Promise<any> => {
  const url = getEndpoint("/tenants");
  return Gateway.put(url, null, item, null) as unknown;
};
/* Method to Make API call for updating cost location settings
 * @param item : request object
 */
export const updateCostLocation = async (payload?: any): Promise<any> => {
  const costLocationId = payload.id;
  const url = getEndpoint(`/cost-locations/${costLocationId}`);
  return Gateway.put(url, null, payload, null) as unknown;
};

export const getAssetChargesImportHistory = async (type: string): Promise<any> => {
  return Gateway.get(getEndpoint(`/charge-types/import/${type}/history`), null, null) as unknown as unknown;
};

export const getImportAssetChargesHistoryErrors = async (historyId: number): Promise<any> => {
  return Gateway.get(getEndpoint(`/charge-types/import/${historyId}/error-details`), null, null) as unknown as unknown;
};

export const uploadFile = async (file, config, type: string) => {
  return Gateway.post(getEndpoint(`/charge-types/import/${type}`), null, file, null, config, false).then((response) => {
    const error = validateResponse(response);
    if (error) {
      return error;
    }
    return {
      importSuccess: true,
      response,
    };
  });
};

export const getStatusById = async (historyId: number): Promise<any> => {
  return Gateway.get(
    getEndpoint(`/charge-types/import/${historyId}/status`),
    null,
    null,
    null,
    false,
  ) as unknown as unknown;
};

export const createPlugCost = async (plugCostData): Promise<any> => {
  try {
    return Gateway.post(getEndpoint(`/plug-cost`), null, plugCostData, null);
  } catch (error) {
    return error;
  }
};

export const deletePlugCost = async (plugCostId, loader = true): Promise<any> => {
  const url = getEndpoint(`/plug-cost/${plugCostId}`);
  return Gateway.delete(url, null, null, null, loader) as unknown as any;
};
