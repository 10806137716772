import React from "react";
import { Fields } from "redux-form";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import { HelperLodash } from "../../helpers";
import { get } from "../../helpers/helperLodash";
import { default as TextField } from "../Field/field";
import IPhoneInputProps from "./iPhoneInputProps";

const dataError = "data-error";

const Wrapper = styled.div`
  position: relative;
`;

const StyledPhoneInput = styled.div`
  height: 48px;
  width: 100%;
  border-color: ${(props) =>
    HelperLodash.get(props, dataError)
      ? props.theme.colors.base.hiltiRedError
      : props.theme.colors.base.heavyConcrete60};
  display: flex;
  align-items: center;
  .prefix-symbol {
    position: relative;
    right: -2px;
    svg {
      transform: scale(0.6);
      opacity: 0.5;
    }
  }
`;

const StyledPhoneNumberInput = styled.input`
  width: 100%;
  height: 48px;
  padding-left: 16px;
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: ${(props) => props.theme.fontNormal.fontWeight};
  border: 2px solid
    ${(props) =>
      HelperLodash.get(props, dataError)
        ? props.theme.colors.base.hiltiRedError
        : props.theme.colors.base.heavyConcrete60};
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
  }
`;

const StyledPhoneCodeInput = styled.input`
  width: 80px;
  height: 48px;
  padding-left: 24px;
  margin-left: -24px;
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: ${(props) => props.theme.fontNormal.fontWeight};
  border: 2px solid
    ${(props) =>
      HelperLodash.get(props, dataError)
        ? props.theme.colors.base.hiltiRedError
        : props.theme.colors.base.heavyConcrete60};
  border-right: 0;
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
  }

  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.base.steel40};
    font-weight: 300;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const hasCodeName = (fields) => fields && fields.codeName;
const hasNumberName = (fields) => fields && fields.numberName;
const hasDataError = (codeName, numberName) =>
  (codeName.meta.error && codeName.meta.touched ? codeName.meta.error : "") ||
  (numberName.meta.error && numberName.meta.touched ? numberName.meta.error : "");
/**
 * @description This class is the shared component for Phone Number Form Input field
 *
 * @class TextComponent
 * @param fields : fields object of the phoneInput
 */
export const TextComponent = (fields) => {
  let codeName;
  let numberName;
  if (hasCodeName(fields)) {
    codeName = get(fields, fields.codeName);
  }
  if (hasNumberName(fields)) {
    numberName = get(fields, fields.numberName);
  }

  const hasError = hasDataError(codeName, numberName);

  return (
    <TextField
      name={fields.name}
      htmlFor={fields.name}
      label={fields.label}
      error={codeName.meta.error || numberName.meta.error}
      touched={codeName.meta.touched || numberName.meta.touched}
      warning={codeName.meta.warning || numberName.meta.warning}
      required={fields.required}
    >
      <StyledPhoneInput data-error={hasError}>
        <SquareIcon name={IconName.Add} className="prefix-symbol" />
        <StyledPhoneCodeInput
          {...codeName.input}
          id={fields.codeName}
          placeholder={fields.codePlaceholder}
          type="tel"
          disabled={fields.disabled}
          value={codeName.input.value || fields.codeValue}
          onBlur={(val) => {
            codeName.input.onChange(val);
            if (codeName.onSelectChange) {
              codeName.onSelectChange(val);
            }
          }}
          data-error={hasError}
        />
        <StyledPhoneNumberInput
          {...numberName.input}
          id={fields.numberName}
          placeholder={fields.numberPlaceholder}
          type="tel"
          disabled={fields.disabled}
          value={numberName.input.value || fields.numberValue}
          onBlur={(val) => {
            numberName.input.onChange(val);
            if (numberName.onSelectChange) {
              numberName.onSelectChange(val);
            }
          }}
          data-error={hasError}
        />
      </StyledPhoneInput>
    </TextField>
  );
};

/**
 * @description This class is the shared component for Phone Number Form Input field
 *
 * @class FormPhoneInput
 * @extends {React.PureComponent<IPhoneInputProps>}
 */
class FormPhoneInput extends React.PureComponent<IPhoneInputProps> {
  render() {
    return (
      <Wrapper>
        <Fields
          names={[this.props.codeName, this.props.numberName]}
          label={this.props.label}
          type={this.props.type}
          required={this.props.required}
          component={TextComponent}
          validate={this.props.validation}
          disabled={this.props.disabled}
          {...this.props}
        />
      </Wrapper>
    );
  }
}

export default FormPhoneInput;
