import React from "react";
import styled from "styled-components";
import ApplicationConstants from "../../constant/applicationConstants";
import { colorPalette } from "../../materials";
import ListContainer from "../gridList/listContainer";
import IStepperLinkProps from "./iStepperLinkProps";
import IStepperProps from "./iStepperProps";
import IStepperState from "./iStepperState";

const stepperConstants = {
  MODAL_FOOTER_HEIGHT: 64,
};

const StepperLink = styled.div`
  flex-grow: 1;
  margin-bottom: -1px;
  &:hover {
    color: ${(props) => (!props.selected ? colorPalette.customTarawera : colorPalette.steel)};
  }
  justify-content: center;
  display: inline-flex;
  opacity: ${(props: IStepperLinkProps) => (props.disabled ? ApplicationConstants.OPACITY.O50 : 1)};
`;

const StepperLinkHeader = styled.div`
  line-height: 19px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  cursor: ${(props: IStepperLinkProps) => (props.isActive === "complete" ? "pointer" : "default")};
  padding: 0px 16px 21px 0px;
  width: 100%;
  text-overflow: ellipsis;
  border-bottom: 3px solid ${colorPalette.steel60};
`;

const OverflowContainer = styled("div")`
  padding-top: 12px;
  overflow: auto;
`;

const InnerWrapper = styled.div`
  width: 992px;
  margin: 0 auto;
`;

const StepperLinkNumber = styled.label`
  border: 3px solid;
  margin-right: 8px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  background: ${colorPalette.white};
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props: IStepperLinkProps) => (props.isActive === "complete" ? "pointer" : "default")};
`;

const StepperLinkLabel = styled.label`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 20px;
  cursor: ${(props: IStepperLinkProps) => (props.isActive === "complete" ? "pointer" : "default")};
`;

const FluidContainer = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colorPalette.heavyConcrete20};
  background: ${colorPalette.white};
  margin-top: -4px;
`;

const FixedContainer = styled.div`
  width: 992px;
  margin: 0 auto;
`;

const StepperLinkGroup = styled.div`
  ${StepperLink}:last-child {
    margin-right: 0;
  }
  white-space: nowrap;
  width: 992px;
  display: flex;
  position: relative;
  top: 2px;
  .disable {
    label {
      border-color: ${colorPalette.steel60};
      color: ${colorPalette.steel60};
    }
  }
  .active {
    div {
      border-color: ${colorPalette.hiltiRed};
    }
    label {
      border-color: ${colorPalette.hiltiRed};
      color: ${colorPalette.hiltiRed};
    }
  }
  .complete {
    div {
      border-color: ${colorPalette.hiltiRed};
    }
    label {
      border-color: ${colorPalette.steel};
      color: ${colorPalette.steel};
    }
  }
`;

/**
 * Class Stepper is the shared class for tabs in a stepper
 *
 * @class Stepper
 * @extends {React.Component<IStepperProps,IStepperState>}
 */
class Stepper extends React.Component<IStepperProps, IStepperState> {
  subscribeIndexes = [];
  overFlowRef: any = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      dirtyIndexes: [0],
      selectedIndex: 0,
    };
    this.changeSelection = this.changeSelection.bind(this);
  }

  subscribeTab(name, index, readOnly = false) {
    const selectedIndex = this.subscribeIndexes.filter(
      (x) => x.name === name && x.index === index && x.readOnly === readOnly,
    );
    if (selectedIndex.length === 0) {
      this.subscribeIndexes.push({ name, index, readOnly });
    }
    return this.unSubscribeTabSelection.bind(this, name);
  }

  unSubscribeTabSelection = (name, unSubscribeAnyWay = false) => {
    this.subscribeIndexes = this.subscribeIndexes.filter((item) =>
      item.name !== name || !unSubscribeAnyWay ? item.readOnly : false,
    );
  };

  next(unSubscribeAnyWay = false) {
    if (this.subscribeIndexes.length > 0) {
      const navigationData = this.subscribeIndexes[this.subscribeIndexes.length - 1];
      if (!navigationData.readOnly) {
        this.unSubscribeTabSelection(navigationData.name, unSubscribeAnyWay);
      }
      if (this.state.selectedIndex === navigationData.index) {
        return false;
      }
      this.changeSelection(navigationData.index);
    } else {
      if (this.state.selectedIndex + 1 !== this.props.components.length) {
        this.changeSelection(this.state.selectedIndex + 1);
      } else {
        return false;
      }
    }
    return true;
  }

  prev() {
    this.changeSelection(this.state.selectedIndex - 1);
  }

  changeSelection(index: number) {
    if (this.state.selectedIndex === index) {
      return;
    }
    const dirtyIndex = [];
    for (let i = index; i > 0; i--) {
      dirtyIndex.push(i);
    }
    this.setState({
      dirtyIndexes: [...this.state.dirtyIndexes, ...dirtyIndex],
      selectedIndex: index >= 0 ? index : 0,
    });
    if (this.overFlowRef && this.overFlowRef.current) {
      this.overFlowRef.current.scrollTo?.(0, 0);
    }
  }

  changeTab(index) {
    if (this.props.onChange && this.state.dirtyIndexes.includes(index)) {
      this.props.onChange(index);
    } else if (this.state.dirtyIndexes.includes(index)) {
      this.setState({
        dirtyIndexes: [...this.state.dirtyIndexes, index],
        selectedIndex: index >= 0 ? index : 0,
      });
    }
  }

  classCalc(selected, index) {
    if (selected === index) {
      return "active";
    } else if (selected > index || this.state.dirtyIndexes.includes(index)) {
      return "complete";
    } else {
      return "disable";
    }
  }

  render() {
    const { components, componentsLabel } = this.props;
    const SelectedComponent = components[this.state.selectedIndex];
    return (
      <React.Fragment>
        <FluidContainer>
          <FixedContainer>
            <StepperLinkGroup>
              {componentsLabel.map((label, index) => (
                <StepperLink
                  onClick={() =>
                    !this.props.disableNavigation && this.state.selectedIndex !== index ? this.changeTab(index) : null
                  }
                  key={`stepper${index}`}
                  selected={this.state.selectedIndex === index}
                  className={this.classCalc(this.state.selectedIndex, index)}
                >
                  <StepperLinkHeader
                    selected={this.state.selectedIndex === index}
                    id={`stepper${index}`}
                    isActive={this.classCalc(this.state.selectedIndex, index)}
                  >
                    <StepperLinkNumber
                      isActive={this.classCalc(this.state.selectedIndex, index)}
                      selected={this.state.selectedIndex === index}
                    >
                      {index + 1}
                    </StepperLinkNumber>
                    <StepperLinkLabel
                      isActive={this.classCalc(this.state.selectedIndex, index)}
                      selected={this.state.selectedIndex === index}
                    >
                      {label}
                    </StepperLinkLabel>
                  </StepperLinkHeader>
                </StepperLink>
              ))}
            </StepperLinkGroup>
          </FixedContainer>
        </FluidContainer>
        <OverflowContainer ref={this.overFlowRef}>
          <InnerWrapper>
            <ListContainer modalFooter={stepperConstants.MODAL_FOOTER_HEIGHT} direction="column">
              <SelectedComponent {...this.props} />
            </ListContainer>
          </InnerWrapper>
        </OverflowContainer>
      </React.Fragment>
    );
  }
}

export default Stepper;
