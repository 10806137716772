import { trackViewCostLocationsModule } from "./charges";
import { trackQTYModuleClicked } from "./qtyItems";
import { trackTransferHistoryModuleClicked } from "./transferHistory";

enum RouteUrls {
  QTY_ITEMS_TAB = "/qtyItems",
  COST_LOCATIONS_TAB = "/administration/location/costLocationSettings",
  TRANSFER_HISTORY = "/administration/general/transferHistory",
}

export const pushRoutesDataToGA = (selectedPath: string) => {
  switch (selectedPath) {
    case RouteUrls.QTY_ITEMS_TAB:
      trackQTYModuleClicked();
      break;
    case RouteUrls.COST_LOCATIONS_TAB:
      trackViewCostLocationsModule();
      break;
    case RouteUrls.TRANSFER_HISTORY:
      trackTransferHistoryModuleClicked();
      break;
  }
};
