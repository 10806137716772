// @ts-ignore
import { ActivityAction } from "custom-actions";
import Store from "../store/store";

/**
 * To set activity(an api/script chunk call) is in progress.
 * @param {string} key - The unique key to track that API status.
 */
export const requestInitiated = (key) => {
  const action = { type: ActivityAction.ACTIVITY_REQUEST_INITIATED, key };
  Store.dispatch(action);
};
/**
 * To set activity(an api/script chunk call) is finished.
 * @param {string} key - The unique key to track that API status.
 */
export const requestFinished = (key) => {
  const action = { type: ActivityAction.ACTIVITY_REQUEST_FINISHED, key };
  Store.dispatch(action);
};
