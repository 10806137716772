import React from "react";
import styled from "styled-components";
import IItemTypeProps from "./iItemType";

/**
 * @const StyledWrapper - Styled wrapper.
 */
const StyledWrapper = styled("div")`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  display: flex;
  font-size: 11px;
  align-items: center;
  color: rgba(82, 79, 83, 0.800000011920929);
  /* Forcing the last-child for padding because upfront-expansion-panel is forcing the last-child to remove the bottom padding to make is equally spacing from the container. */
  &&& div:last-child {
    padding-bottom: 2px;
    padding-right: 4px;
  }
`;

/**
 * @const ItemType is a Styled Component that consist types of item.
 * IDs and Texts for the rows are configurable from the component using this component.
 */
const StyledItemTypeText = styled("div")`
  background-color: rgba(0, 0, 0, 0.05999999865889549);
  width: auto;
  max-width: 150px;
  margin: 2px;
  padding: 2px 4px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ItemType = (props: IItemTypeProps) => {
  const { uniqueKey, boldTexts } = props;
  return (
    <StyledWrapper>
      {boldTexts.map((item, index) => {
        return (
          <StyledItemTypeText key={`keyItemType_${uniqueKey}${index}`} id={`lblItemType_${uniqueKey}${index}`}>
            {item}
          </StyledItemTypeText>
        );
      })}
    </StyledWrapper>
  );
};

/**
 * @export ItemType - Styled Component.
 */
export default ItemType;
