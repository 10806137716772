import React from "react";
import styled from "styled-components";
import ApplicationConstants from "../../constant/applicationConstants";
import { SquareIcon } from "../icons/iconWrapper";
import { IEmptyListProps } from "./iEmptyListProps";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;
/**
 * @const EmptyWrapper - Styled component for the title text.
 */
const EmptyWrapper = styled.div`
  margin: auto;
  padding-top: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  & button {
    margin: 0;
    & + button {
      margin-top: 16px;
      margin-left: 0;
    }
  }
`;

const IconWrapper = styled.div`
  margin: 0 auto;
  path {
    fill: ${(props) => props.theme.colors.base.steel40};
  }
`;

/**
 * @const EmptyWrapperSecondaryText - Styled component for the title text.
 */
const EmptyWrapperSecondaryText = styled.p`
  color: ${(props) => props.theme.colors.innerHeadingText.color};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  margin-top: 24px;
  &.noSubTitle {
    margin-bottom: 24px;
  }
  &.subTitle {
    margin-bottom: 10px;
  }
`;

const EmptyWrapperSubTitle = styled.p`
  color: ${(props) => props.theme.colors.innerHeadingText.color};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-weight: ${(props) => props.theme.fontNormal.fontWeight};
  margin-bottom: 24px;
`;

/**
 * @description - A class for Dropdown or Info Icon Component
 *
 * @class EmptyList
 */

const EmptyList = (props: IEmptyListProps) => {
  const {
    emptyList,
    emptyListTitle,
    name,
    subTitle,
    iconClassName,
    imageSize = ApplicationConstants.WIDTH.W128,
    style,
  } = props;
  return (
    <Wrapper style={style}>
      <EmptyWrapper>
        {name && (
          <IconWrapper>
            <SquareIcon name={name} imageSize={imageSize} className={iconClassName} />
          </IconWrapper>
        )}
        <EmptyWrapperSecondaryText className={subTitle ? "subTitle" : "noSubTitle"}>
          {emptyListTitle}
        </EmptyWrapperSecondaryText>
        {subTitle && <EmptyWrapperSubTitle>{subTitle}</EmptyWrapperSubTitle>}
        {emptyList ? emptyList.map((item, index) => <item.component key={`${index}`} {...item.props} />) : null}
      </EmptyWrapper>
    </Wrapper>
  );
};

EmptyList.defaultProps = {
  iconClassName: "large",
};

export default EmptyList;
