import React from "react";
const SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      fill="#524F53"
      fill-rule="nonzero"
      d="M26.667 12.004v17.33H5.333v-17.33h4V9.337a6.678 6.678 0 0 1 7.32-6.64c3.467.333 6.014 3.44 6.014 6.92v2.387h4zm-12 5.33v5.333h2.666v-5.334h-2.666zM13.333 12h5.334V9.867c0-1.771-.886-3.2-2.667-3.2-1.78 0-2.667 1.429-2.667 3.2V12z"
    />
  </svg>
);

export default SVG;
