import React from "react";
import styled from "styled-components";
import Field from "../Field/field";
import FormTextArea from "./formTextArea";
import { ITextAreaProps } from "./iTextAreaProps";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled.textarea`
  background-color: ${(props) => props.theme.colors.base.white};
  border: 2px solid ${(props) => props.theme.colors.base.heavyConcrete60};
  width: ${(props) => (props.style && props.style.width ? props.style.width : "100%")};
  height: ${(props) => (props.style && props.style.height ? props.style.height : "48px")};
  padding: 10px 16px;
  font: inherit;
  resize: none;
`;

class TextArea extends React.PureComponent<ITextAreaProps> {
  onKeyPress(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    const { onKeyPress } = this.props;
    if (onKeyPress) {
      onKeyPress(event);
    }
  }
  render() {
    const {
      label,
      error,
      name,
      value,
      //   onChange,
      maxLength,
      placeholder,
      ref,
      disabled,
      readOnly,
      style,
    } = this.props;
    return !this.props.isFormControl ? (
      <Wrapper>
        <Field name={name} htmlFor={name} label={label} error={error}>
          <StyledInput
            className="textArea"
            name={name}
            id={name}
            value={value}
            onKeyPress={(event: React.KeyboardEvent<HTMLTextAreaElement>) => this.onKeyPress(event)}
            maxLength={maxLength}
            placeholder={placeholder}
            ref={ref as any}
            disabled={disabled}
            readOnly={readOnly}
            style={style}
          />
        </Field>
      </Wrapper>
    ) : (
      <FormTextArea
        htmlFor={name}
        name={this.props.name}
        label={this.props.label}
        validation={this.props.validation}
        placeholder={placeholder}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        style={style}
        onTextChange={this.props.onChange}
        required={this.props.required}
        maxLength={this.props.maxLength}
      />
    );
  }
}

export default TextArea;
