import React from "react";
import styled from "styled-components";
import LocaleManager from "../../helpers/localeManager";
import DatePicker from "./datePicker";

interface IDateRangePickerProps {
  readonly name: string;
  readonly minDate?: Date;
  readonly maxDate?: Date;
  readonly startDate?: Date;
  readonly endDate?: Date;
  readonly onSelectionChange?: (
    selectedValue: string,
    startDate: Date,
    endDate: Date,
    count: number,
    label: string,
  ) => void;
  readonly input?: any;
  readonly placeholder?: string;
  readonly dateFormat: string;
  readonly label?: string;
  readonly clearButtonText: string;
  readonly inputFocus?: () => void;
  readonly disableFocus?: () => void;
  readonly endDateLabel: string;
  readonly startDateLabel: string;
  readonly locale: string;
  readonly meta?: any;
}

interface IDateRangePickerState {
  readonly startDate?: any;
  readonly endDate?: any;
}

const WrapperDateRange = styled.div`
  padding: 25px 16px 0 16px;
  background-color: ${(props) => props.theme.colors.base.white};
  min-width: 300px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const PickerControlWrapper = styled.div`
  padding: 0 0 16px;
`;
const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize.base};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  color: ${(props) => props.theme.colors.base.steel};
  display: block;
  margin-bottom: 4px;
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .label {
    margin-bottom: 0;
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    font-size: ${(props) => props.theme.fontSize.medium};
  }
`;
const ClearButton = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  text-align: right;
  margin-top: ${(props) => (props["data-showInDropdown"] ? "20px" : "")};
  font-size: ${(props) => props.theme.fontSize.base};
  & span {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: ${(props) => props.theme.colors.base.steel10};
    }
  }
`;

const Seprator = styled.div`
  width: 20px;
  height: 2px;
  background-color: ${(props) => props.theme.colors.base.heavyConcrete60};
  align-self: center;
  margin: 0px 5px;
`;

class DateRangePicker extends React.PureComponent<IDateRangePickerProps, IDateRangePickerState> {
  containerRef = React.createRef<any>();
  constructor(props) {
    super(props);
    this.state = {
      endDate: props.endDate,
      startDate: props.startDate,
    };
  }

  isClickedOnComponent = (target) => {
    const eleArr = this.containerRef.current && Array.from(this.containerRef.current.children);
    const arr = eleArr.filter((child: any) => child.contains(target));

    const ele = document.getElementById("calendarPortal");
    const eleArr1 = ele ? Array.from(ele.children) : [];
    const arr1 = eleArr1.filter((child: any) => child.contains(target));
    return arr.length > 0 || arr1.length > 0;
  };

  /**
   * onDocumentClick to disable focus while click outside
   */
  onDocumentClick = (e) => {
    if (this.isClickedOnComponent(e.target)) {
      return this.props.inputFocus && this.props.inputFocus(); // open
    } else {
      return this.props.disableFocus && this.props.disableFocus(); // close
    }
  };

  /**
   * handle on mouse down event on search input box
   *
   * @param e current event
   */
  onMouseDown = (e: any) => {
    e.stopPropagation();
    e.target.focus();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentClick);
  }

  onSelectStartChange = (date) => {
    this.setState({ startDate: date }, this.handleInputChange);
  };
  onSelectEndChange = (date) => {
    this.setState({ endDate: date }, this.handleInputChange);
  };

  handleInputChange = () => {
    let count = 0;
    let label;
    let startDate;
    let endDate;
    if (this.state.startDate) {
      count = count + 1;
      label = LocaleManager.dateHelpers.getLocalDate(this.state.startDate);
    }
    if (this.state.endDate) {
      count = count + 1;
      label = LocaleManager.dateHelpers.getLocalDate(this.state.endDate);
    }

    let selectedValue = "";
    if (count !== 0) {
      startDate = this.state.startDate || this.props.minDate;
      endDate = this.state.endDate || this.props.maxDate;
      // eslint-disable-next-line
      this.props.input && this.props.input.onChange(selectedValue);
      selectedValue = `${LocaleManager.dateHelpers.getDateInAPIFormat(
        startDate,
      )};${LocaleManager.dateHelpers.getDateInAPIFormat(endDate)}`;
    }

    // eslint-disable-next-line
    this.props.input && this.props.input.onChange(selectedValue);
    // eslint-disable-next-line
    this.props.onSelectionChange &&
      this.props.onSelectionChange(selectedValue, this.state.startDate, this.state.endDate, count, label);
  };

  clearFilterSelection = () => {
    this.setState({ startDate: "", endDate: "" }, this.handleInputChange);
  };

  getIdForRender = (name = "") => (name.includes(":") ? name.split(":")[0] : name);

  setContainerRef = (element) => {
    this.containerRef = element;
  };

  render() {
    const { startDate, endDate } = this.state;
    const {
      name,
      minDate,
      maxDate,
      placeholder,
      dateFormat,
      label,
      clearButtonText,
      endDateLabel,
      startDateLabel,
      input,
    } = this.props;
    const showClearButton = startDate || endDate;
    const inputName = input && input.name;
    const id = inputName ? this.getIdForRender(inputName) : this.getIdForRender(name);
    return (
      <div ref={this.containerRef} id={id}>
        <WrapperDateRange>
          {label ? (
            <LabelWrapper>
              <Label className="label">{label}</Label>
              {showClearButton && (
                <ClearButton>
                  <span onClick={this.clearFilterSelection}>{clearButtonText}</span>
                </ClearButton>
              )}
            </LabelWrapper>
          ) : null}
          <FieldsWrapper className="fields-wrapper">
            <PickerControlWrapper>
              <Label>{startDateLabel}</Label>
              <DatePicker
                id={`${name || inputName}minDate`}
                name={`${name || inputName}minDate`}
                selected={startDate}
                onSelectChange={this.onSelectStartChange}
                selectsStart={true}
                selectsEnd={false}
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate || maxDate}
                minDate={minDate}
                isFormControl={false}
                placeholder={placeholder}
                format={dateFormat}
                disabled={!minDate || !maxDate}
                locale={this.props.locale}
              />
            </PickerControlWrapper>
            <Seprator className="seprator" />
            <PickerControlWrapper>
              <Label>{endDateLabel}</Label>
              <DatePicker
                id={`${name || inputName}maxDate`}
                name={`${name || inputName}maxDate`}
                selected={endDate}
                onSelectChange={this.onSelectEndChange}
                selectsStart={false}
                selectsEnd={true}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate || minDate}
                maxDate={maxDate}
                isFormControl={false}
                placeholder={placeholder}
                format={dateFormat}
                disabled={!minDate || !maxDate}
                locale={this.props.locale}
              />
            </PickerControlWrapper>
          </FieldsWrapper>
          {!label && showClearButton && (
            <ClearButton>
              <span onClick={this.clearFilterSelection}>{clearButtonText}</span>
            </ClearButton>
          )}
        </WrapperDateRange>
      </div>
    );
  }
}

export default DateRangePicker;
