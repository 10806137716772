import React from "react";
const SVG = () => (
  <svg width="14px" height="18px" viewBox="0 0 14 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1161.000000, -234.000000)">
        <g transform="translate(1156.000000, 231.000000)">
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <path
            d="M17,20.41 L18.41,19 L15,15.59 L13.59,17 L17,20.41 Z M7.5,8 L11,8 L11,13.59 L5.59,19 L7,20.41 L13,14.41 L13,8 L16.5,8 L12,3.5 L7.5,8 Z"
            id="Shape"
            fill="#524F53"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
