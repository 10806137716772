import React from "react";
import { IconNormalBold } from "../../../../../components/DetailsPanel/index";
import { LocaleManager } from "am-web-ui-shared/helpers";
import { AssetServiceCategory } from "../../../../../utils/enums/serviceEnum";
import SingletonFeatureManager from "../../../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../../../featureFlagManager/featureConfig";
const heavyEquipmentFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE);
export const unitsOfMeasurement = (basedOn, t, distanceUnitValue) => {
  switch (basedOn) {
    case AssetServiceCategory.DISTANCE:
      return distanceUnitValue?.value;
    case AssetServiceCategory.OPERATING_TIME:
      return t("services:ENGINE_HOURS");
    case AssetServiceCategory.PERIOD:
      return t("services:DATE");
    default:
      return t("services:DATE");
  }
};
export const nextServiceDateDetails = (data, t, distanceUnitValue) => {
  const nextServiceDate = data.nextServiceDate
    ? LocaleManager.dateHelpers.getDateInTenantTimezone(data.nextServiceDate)
    : null;

  const isServiceOfHeavyEquipment =
    [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(data?.nextServiceBasedOn) &&
    heavyEquipmentFF;
  const nextServiceData = isServiceOfHeavyEquipment
    ? data?.nextServiceAt
      ? `${data.nextServiceAt} ${unitsOfMeasurement(data?.nextServiceBasedOn, t, distanceUnitValue)}`
      : "-"
    : nextServiceDate;

  return nextServiceData ? (
    <IconNormalBold
      id="pnlNextServiceData"
      normalId="lblNextServiceData"
      normalText={
        isServiceOfHeavyEquipment ? t("services:NEXT_SERVICE_SECHEDULE_AT") : t("services:FIELD_NAME_NEXTSERVICEDATE")
      }
      boldId="txtNextServiceData"
      boldText={nextServiceData}
    />
  ) : data.openServicesCount ? (
    <IconNormalBold
      id="pnlNextServiceDate"
      normalId="lblNextServiceDate"
      normalText={
        isServiceOfHeavyEquipment ? t("services:NEXT_SERVICE_SECHEDULE_AT") : t("services:FIELD_NAME_NEXTSERVICEDATE")
      }
      boldId="txtNextServiceDate"
      boldText={nextServiceDate || "-"}
    />
  ) : null;
};

export const nextServiceNameDetails = (data, t) => {
  const nextServiceName = data.nextServiceName ? data.nextServiceName : null;
  return nextServiceName ? (
    <IconNormalBold
      id="pnlNextServiceName"
      normalId="lblNextServiceName"
      normalText={t("common:LABEL_NEXT_SERVICE_NAME")}
      boldId="txtNextServiceName"
      boldText={nextServiceName}
    />
  ) : null;
};

export const lastServiceDateDetails = (data, t, distanceUnitValue) => {
  const lastServiceDate = data.lastServiceDate
    ? LocaleManager.dateHelpers.getDateInTenantTimezone(data.lastServiceDate)
    : null;
  const isServiceOfHeavyEquipment =
    [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(data?.lastServiceBasedOn) &&
    heavyEquipmentFF;
  const lastServiceData = isServiceOfHeavyEquipment
    ? (data.lastServiceAt || "-") + " " + unitsOfMeasurement(data?.lastServiceBasedOn, t, distanceUnitValue)
    : lastServiceDate;

  return lastServiceData ? (
    <IconNormalBold
      id="pnlLastServiceDate"
      normalId="lblLastServiceDate"
      normalText={isServiceOfHeavyEquipment ? t("services:LAST_SERVICE_DONE") : t("services:LAST_SERVICE_DATE")}
      boldId="txtLastServiceDate"
      boldText={lastServiceData}
    />
  ) : null;
};

export const lastServiceNameDetails = (data, t) => {
  const lastServiceName = data.lastServiceName ? data.lastServiceName : null;
  return lastServiceName ? (
    <IconNormalBold
      id="pnlLastServiceName"
      normalId="lblLastServiceName"
      normalText={t("services:LAST_SERVICE_NAME")}
      boldId="txtLastServiceName"
      boldText={lastServiceName}
    />
  ) : null;
};
