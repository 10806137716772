import { ISagaModule } from "redux-dynamic-modules-saga";
import ITransferCartModuleState from "./iTransferCartState";
import transferCartReducer from "./transferCartReducer";
import composeTransferCartSaga from "./transferCartSaga";

export const transferCartModule: ISagaModule<ITransferCartModuleState> = {
  id: "transferCartModule",
  reducerMap: {
    TransferCart: transferCartReducer,
  },
  sagas: [composeTransferCartSaga],
};

export default transferCartModule;
