import Grid from "@mui/material/Grid";
import { DetailsPanel, FieldWrapper, IconName, SquareIcon, GridLayout, Prompt } from "am-web-ui-shared/components";
import Tooltip from "@hilti/components/core/tooltip";
import { Checkbox, CreatableAutoComplete, Field, Radio } from "am-web-ui-shared/elements";
// @ts-ignore
import styled from "custom-styled-components";
import SingletonFeatureManager from "../../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../../featureFlagManager/featureConfig";
import React from "react";
import { withTranslation } from "react-i18next";
import { AssignServiceApi, getAssignServiceApi } from "../../../../../common/api/assetApi";
import ComboBoxRequest from "../../../../../common/models/comboBox/comboBoxRequest";
import ComboBoxUtils from "../../../../../common/models/comboBox/comboBoxUtils";
import { deepCloneArray, unitsOfMeasurement } from "../../../../utils/commonUtils";
import { ActionItem } from "../../../../utils/enums/actionItemEnum";
import { ModuleName } from "../../../../utils/enums/moduleNameEnum";
import { fieldSpacingSetting } from "../../../../utils/styledUtils";
import { ServiceFormEnum, ServiceResultEnum, ServiceStatus } from "../serviceFormEnum";
import { IServiceDetailPanelProps } from "./iServiceDetailPanel";
import { DateSection } from "./serviceDateSection";
import { DateField } from "./servicePanelDateField";
import { AssetServiceCategory, FREQUENCY_TYPE } from "../../../../utils/enums/serviceEnum";
import { required, minValueValidator, regexValidation } from "../../../../../common/utils/errorUtils";
import { ValidationConstants } from "../../../../utils/enums/validationConstants";
import { LocaleManager } from "am-web-ui-shared/helpers";
import Input from "am-web-ui-shared/elements/Input/input";
import { SelectAutoComplete } from "am-web-ui-shared/elements";

const CheckboxWrap = styled.div`
  margin-top: 10px;
  display: flex;
`;
const TooltipSpan = styled.span`
  margin-left: 10px;
`;
export const InputWraper = styled.span`
  display: block;
  width: 100%;
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
export const LabelText = styled.p`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  display: block;
  margin-bottom: 5px;
`;
export const LabelContent = styled.h4`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  padding-bottom: 24px;
`;
export const TextboxWithLabel = styled.div`
  display: flex;
  padding-right: 16px;
  position: relative;
  &:before {
    content: "";
    border: 2px solid
      ${(props) =>
        props.children[0].props.disabled
          ? props.theme.colors.base.heavyConcrete20
          : props.theme.colors.base.heavyConcrete60};
    background-color: ${(props) => props.theme.colors.base.white};
    height: 48px;
    position: absolute;
    top: 22px;
    right: 8px;
    left: 0;
  }
  input {
    border: 0;
    height: 42px;
    position: relative;

    background-color: transparent;
    top: 4px;
    margin-bottom: 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .scheduleAtLabel {
    width: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${(props) => props.theme.colors.base.heavyConcrete60};
    font-size: ${(props) => props.theme.fontSize.small};
    text-align: right;
    margin-top: 40px;
    position: relative;
  }
  .field-wrapper {
    margin-bottom: 0;
    width: calc(100% - 50px);
  }
`;
const Notes = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;
class ServiceDetailsPanel extends React.PureComponent<IServiceDetailPanelProps, any> {
  constructor(props) {
    super(props);
    const { selectedService } = this.props;
    this.state = {
      completionAt: selectedService.completionAt,
      isCompleationChange: false,
    };
  }
  cssSetting = {
    marginBottom: fieldSpacingSetting.marginBottom,
  };
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.selectedService !== this.props.selectedService ||
      nextProps.serviceStatus !== this.props.serviceStatus ||
      nextProps.isLifeLong !== this.props.isLifeLong ||
      this.state.isCompleationChange !== nextState.isCompleationChange
    );
  }
  renderDescriptionSection = () => {
    const { selectedService, t } = this.props;
    return (
      <FieldWrapper
        labelName={t("common:LABEL_DESCRIPTION_FROM_TEMPLATE")}
        fieldName={selectedService && selectedService.description}
      />
    );
  };
  isAssetModule = () => {
    return this.props.moduleName === ModuleName.asset;
  };
  renderServiceName = () => {
    const { selectedService, t } = this.props;
    return <FieldWrapper labelName={t("services:SERVICE_NAME")} fieldName={selectedService && selectedService.name} />;
  };

  getToolTipInfo = (info, isServiceOfHeavyEquipment = false) => {
    const disableInfoMessageTransKey = isServiceOfHeavyEquipment
      ? "services:DISABLE_HEAVE_EQUIPMENT_OPTION_INFO_MESSAGE"
      : "services:DISABLE_OPTION_INFO_MESSAGE";
    const { moduleName, t } = this.props;
    info.disabledReason = this.isAssetModule()
      ? t(disableInfoMessageTransKey, { moduleName })
      : t(disableInfoMessageTransKey, { moduleName: "asset template" });
    info.icon = isServiceOfHeavyEquipment ? "InfoIcon" : "Checkmark";
    info.isDisabled = true;
    return info;
  };

  /**
   * return list
   */
  getServiceList = () => {
    return this.isAssetModule()
      ? this.props.allAssignedService && this.props.allAssignedService[ServiceStatus.OPEN].list
      : this.props.serviceList;
  };
  /**
   * Disbale drop down options in case of edit asset
   */

  disableAddEditDropDownOptions = (assignedList) => {
    const list = this.getServiceList();
    if (this.props.actionType === ActionItem.edit) {
      return assignedList.map((item) => {
        if (item.associatedFlag && this.props.serviceStatus !== ServiceStatus.HISTORY) {
          item = this.getToolTipInfo(item);
        }
        if (!item?.disabled) {
          item = this.disableHeavyEquipmentDropDownOptions(item);
        }
        return item;
      });
    } else if (list && this.props.serviceStatus === ServiceStatus.OPEN) {
      return assignedList.map((item) => {
        list.map((service) => {
          if (item.id.toString() === service.serviceId) {
            item = this.getToolTipInfo(item);
          }
          if (!item?.disabled) {
            item = this.disableHeavyEquipmentDropDownOptions(item);
          }
        });
        return item;
      });
    } else if (this.props.actionType === ActionItem.add && !list && assignedList?.length > 0) {
      return assignedList.map((item) => {
        item = this.disableHeavyEquipmentDropDownOptions(item);
        return item;
      });
    }
    return assignedList;
  };

  /**
   * Disbale drop down options in case of Heavy Equipment Distance / Operating Time service
   */

  disableHeavyEquipmentDropDownOptions = (item) => {
    if (
      !this.isAssetModule() &&
      (item?.basedOn?.code === AssetServiceCategory.DISTANCE ||
        item?.basedOn?.code === AssetServiceCategory.OPERATING_TIME)
    ) {
      item = this.getToolTipInfo(item, true);
    }
    return item;
  };

  /**
   * function to disable dropdown option
   */
  disableDropDownOption = (assignedServicesList) => {
    const assignedList = deepCloneArray(assignedServicesList);
    const isAllAssigned = assignedList && assignedList.filter((row) => row.associatedFlag === false).length === 0;
    this.props.isAllAssignedFlag(isAllAssigned);
    if (assignedServicesList && assignedServicesList.length > 0) {
      return this.disableAddEditDropDownOptions(assignedList);
    }
    if (assignedServicesList && assignedServicesList.length === 0) {
      return [
        {
          disabledReason: this.props.t("services:DISABLE_OPTION_FOR_EMPTY_LIST"),
          icon: "InfoIcon",
          id: "NOSERVICE",
          isDisabled: true,
          name: this.props.t("common:NO_OPTIONS"),
        },
      ];
    }
    return assignedList;
  };
  searchServiceTemplates = async (inputParams) => {
    const customPayload = new ComboBoxRequest({
      fields: "name",
      filters: [],
      limit: inputParams.limit,
      offset: inputParams.offset,
      orderBy: "name",
      q: inputParams.q,
      searchFields: "name",
    });
    const data = await getAssignServiceApi(
      this.props.assetId,
      customPayload,
      this.isAssetModule() ? "assetId" : "assetTemplateId",
    );
    if ("error" in data) {
      const fallback = {
        limit: 0,
        offset: 0,
        response: [],
        totalRecords: 0,
      };
      return {
        infoText: "",
        ...this.formatResponse(fallback),
      };
    }

    return {
      infoText: ComboBoxUtils.getInfoMessage((data.response || []).length, data.totalRecords, this.props.t),
      ...this.formatResponse(data),
    };
  };
  formatResponse = (data: AssignServiceApi) => {
    return {
      limit: data.limit || 0,
      offset: data.offset || 0,
      response: this.disableDropDownOption(data.response),
      totalRecords: data.totalRecords || 0,
    };
  };

  serviceDropDown = () => {
    const { t, serviceDropDownHandler } = this.props;
    const LiveSearchProps = ComboBoxRequest.defaultLiveSearchProps(this.searchServiceTemplates);
    return (
      <CreatableAutoComplete
        autoComplete="on"
        className="form-control"
        id="service"
        isFormControl={true}
        label={t("services:SERVICE")}
        name={this.isAssetModule() ? "service" : "serviceTemplateId"}
        labelField={this.isAssetModule() ? "service" : "serviceTemplateId"}
        icon="search"
        placeholder={t("common:PLACEHOLDER_TYPE_OR_SELECT")}
        required={true}
        valueKey="name"
        valueId="id"
        onSelectChange={serviceDropDownHandler}
        liveSearch={LiveSearchProps}
        t={t}
        isCreatable={false}
        noOptionsMessage={() => t("common:NO_OPTIONS")}
        cssSetting={this.cssSetting}
      />
    );
  };

  isUpcomingServiceButtonDisabled = () => {
    const { selectedService } = this.props;
    const list = this.getServiceList();
    if (this.props.actionType === ActionItem.edit) {
      return selectedService && selectedService.associatedFlag;
    } else {
      if (list && selectedService && selectedService.id) {
        return list.filter((row) => row.serviceId.toString() === selectedService.id.toString()).length > 0;
      }
    }
    return false;
  };

  getUpcomingButtonTooltip = () => {
    const moduleName = ModuleName.asset;
    const { selectedService, t } = this.props;
    return selectedService && selectedService.associatedFlag
      ? t("services:DISABLE_OPTION_INFO_MESSAGE", { moduleName })
      : t("services:UPCOMING_SERVICES_ALREADY_ASSIGNED");
  };

  isServiceStatusOpen = () => {
    const { serviceStatus, serviceValue } = this.props;
    return this.isAssetModule() && !!serviceValue && serviceStatus === ServiceStatus.OPEN;
  };

  isServiceStatusHistory = () => {
    const { serviceStatus, serviceValue } = this.props;
    return (this.isAssetModule() && !!serviceValue) || serviceStatus === ServiceStatus.HISTORY;
  };

  showServiceResult = () => {
    const { handleServiceResult, t } = this.props;
    const serviceResultOptions = [
      {
        code: ServiceResultEnum.PASSED.toUpperCase(),
        value: t("services:PASS"),
      },
      {
        code: ServiceResultEnum.FAILED.toUpperCase(),
        value: t("services:FAIL"),
      },
    ];

    return (
      <Grid item xs={6} direction="row">
        <SelectAutoComplete
          autoComplete="off"
          className="form-control"
          id="serviceResult"
          isFormControl={true}
          label={t("services:LABEL_SERVICE_RESULT")}
          name="serviceResult"
          valueKey="value"
          valueId="code"
          t={t}
          required={true}
          data={serviceResultOptions}
          onSelectChange={handleServiceResult}
        />
      </Grid>
    );
  };
  showUpcomingDetailsHE = (unitsOfMeasurement) => {
    const { t, handleScheduledAtChange, isLifeLong, serviceActionType, selectedService } = this.props;
    return (
      <>
        {serviceActionType === ActionItem.complete ? (
          <Grid container={true} spacing={2}>
            <Grid item xs={6}>
              <LabelText>
                {t("services:SCHEDULED_HE", {
                  unitName: unitsOfMeasurement,
                })}
              </LabelText>
              <LabelContent className="label-of-co-info">{selectedService?.scheduleAt || "-"}</LabelContent>
            </Grid>

            <Grid item xs={6}>
              <TextboxWithLabel>
                <Input
                  name={"completionAt"}
                  id={"completionAt"}
                  label={t("services:COMPLETION_HE", {
                    unitName: unitsOfMeasurement,
                  })}
                  placeholder={t("services:PLACEHOLDER_SERVICE_SECHEDULE_AT")}
                  isFormControl={true}
                  value={""}
                  required={true}
                  type={"number"}
                  validation={[
                    required(
                      t,
                      t("services:COMPLETION_HE", {
                        unitName: unitsOfMeasurement,
                      }),
                    ),
                    regexValidation(ValidationConstants.ALPHA_NUMERIC_HYPHEN, t("common:VALIDATION_SPECIAL_CHARACTER")),
                    minValueValidator(ValidationConstants.MIN_VALUE.NUM_0, t("common:VALIDATION_MIN_VALUE"), false),
                  ]}
                />
                <label className="scheduleAtLabel">{unitsOfMeasurement}</label>
              </TextboxWithLabel>
            </Grid>
          </Grid>
        ) : (
          <GridLayout mode={"col6"}>
            <TextboxWithLabel>
              <Input
                disabled={isLifeLong}
                name={"scheduleAt"}
                id={"scheduleAt"}
                label={t("services:NEXT_SERVICE_SECHEDULE_AT")}
                placeholder={t("services:PLACEHOLDER_SERVICE_SECHEDULE_AT")}
                isFormControl={true}
                value={""}
                required={true}
                type={"number"}
                onChange={handleScheduledAtChange}
                validation={
                  isLifeLong
                    ? []
                    : [
                        regexValidation(
                          ValidationConstants.ALPHA_NUMERIC_HYPHEN,
                          t("common:VALIDATION_SPECIAL_CHARACTER"),
                        ),
                        required(t, t("common:VALIDATION_SCHEDULEAT")),
                        minValueValidator(ValidationConstants.MIN_VALUE.NUM_0, t("common:VALIDATION_MIN_VALUE"), false),
                      ]
                }
              />
              <label className="scheduleAtLabel">{unitsOfMeasurement}</label>
            </TextboxWithLabel>
          </GridLayout>
        )}
      </>
    );
  };

  handleCompleationOnChange = (event) => {
    const target = event.target;
    this.setState({
      completionAt: target.value,
      isCompleationChange: false,
    });
  };
  handleCompleationOnBlur = () => {
    if (this.state.completionAt !== this.props.selectedService.completionAt) {
      this.setState({
        isCompleationChange: true,
      });
    }
  };
  /**
   * Handle Confirmation Pop up confirm
   */
  handleConfirm = () => {
    this.props.handleCompletionDataChange(true);
    this.setState({ isCompleationChange: false });
  };
  /**
   * Handle Confirmation Pop up close
   */
  handleClose = () => {
    this.props.handleCompletionDataChange(false);
    this.setState({ isCompleationChange: false });
  };

  showConfirmationDialog = () => {
    const { t, selectedService, distanceUnitValue } = this.props;
    const { basedOn } = selectedService;

    const unitName = unitsOfMeasurement(basedOn, t, distanceUnitValue);

    const header = t("services:CHANGE_COMPLETION_DATA_CONFIRMATION", {
      unitName,
    });

    const message = t("services:CHANGE_COMPLETION_DATA_MESSAGE", {
      unitName,
      compleationValue: this.state.completionAt,
    });
    const notes = t("services:CHANGE_COMPLETION_DATA_NOTES", {
      unitName,
    });

    return (
      <Prompt
        icon={IconName.Warning}
        className="warning"
        handleClose={this.handleClose}
        handleConfirm={this.handleConfirm}
        header={header}
        cancelLabel={t("services:FOR_THIS_SERVICE")}
        okLabel={t("services:FOR_UPCOMING_SERVICE")}
        crossIcon={true}
      >
        <Notes>{notes}</Notes>
        {message}
      </Prompt>
    );
  };

  showHistoricDetailsHE = (unitsOfMeasurement) => {
    const { t, serviceActionType, selectedService } = this.props;
    return (
      <Grid container={true} spacing={2}>
        <Grid item xs={6}>
          {serviceActionType === ActionItem.edit ? (
            <>
              <LabelText>
                {t("services:SCHEDULED_HE", {
                  unitName: unitsOfMeasurement,
                })}
              </LabelText>
              <LabelContent className="label-of-co-info">{selectedService?.scheduleAt || "-"}</LabelContent>
            </>
          ) : (
            <InputWraper>
              <Input
                name={"scheduleAt"}
                id={"scheduleAt"}
                label={t("services:SCHEDULED_HE", {
                  unitName: unitsOfMeasurement,
                })}
                placeholder={t("services:PLACEHOLDER_SERVICE_SECHEDULE_AT")}
                isFormControl={true}
                value={""}
                required={true}
                type={"number"}
                validation={[
                  required(
                    t,
                    t("services:SCHEDULED_HE", {
                      unitName: unitsOfMeasurement,
                    }),
                  ),
                  regexValidation(ValidationConstants.ALPHA_NUMERIC_HYPHEN, t("common:VALIDATION_SPECIAL_CHARACTER")),
                  minValueValidator(ValidationConstants.MIN_VALUE.NUM_0, t("common:VALIDATION_MIN_VALUE"), false),
                ]}
                onChange={this.props.handleScheduledAtChange}
              />
            </InputWraper>
          )}
        </Grid>
        <Grid item xs={6}>
          <InputWraper>
            <Input
              name={"completionAt"}
              id={"completionAt"}
              label={t("services:COMPLETION_HE", {
                unitName: unitsOfMeasurement,
              })}
              onBlur={this.handleCompleationOnBlur}
              onChange={this.handleCompleationOnChange}
              placeholder={t("services:PLACEHOLDER_SERVICE_SECHEDULE_AT")}
              isFormControl={true}
              value={""}
              required={true}
              type={"number"}
              validation={[
                required(
                  t,
                  t("services:COMPLETION_HE", {
                    unitName: unitsOfMeasurement,
                  }),
                ),
                regexValidation(ValidationConstants.ALPHA_NUMERIC_HYPHEN, t("common:VALIDATION_SPECIAL_CHARACTER")),
                minValueValidator(ValidationConstants.MIN_VALUE.NUM_0, t("common:VALIDATION_MIN_VALUE"), false),
              ]}
            />
          </InputWraper>
        </Grid>
        {this.showServiceResult()}
      </Grid>
    );
  };

  showStatusTypeRadio = () => {
    const { t, serviceStatus, handleStatusRadioChange } = this.props;
    return (
      <Field
        htmlFor={t("common:LABEL_STATUS")}
        label={t("common:LABEL_STATUS")}
        required={true}
        name="serviceStatusValue"
        cssSetting={{
          marginBottom: fieldSpacingSetting.marginBottom,
          marginTop: fieldSpacingSetting.marginTop8,
        }}
      >
        <Radio
          name="statusType"
          id="statusType"
          radioLabel={t("services:UPCOMING_SERVICES")}
          radioValue={ServiceStatus.OPEN}
          isFormControl={true}
          disabled={this.isUpcomingServiceButtonDisabled()}
          allSelectIndex={1}
          checked={serviceStatus === ServiceStatus.OPEN}
          onSelectionChange={handleStatusRadioChange}
          toolTip={{
            place: "right",
            showToolTip: this.isUpcomingServiceButtonDisabled(),
            value: this.getUpcomingButtonTooltip(),
          }}
        />
        <Radio
          name="statusType"
          id="statusType"
          radioLabel={t("services:HISTORIC_SERVICES")}
          radioValue={ServiceStatus.HISTORY}
          isFormControl={true}
          checked={serviceStatus === ServiceStatus.HISTORY}
          onSelectionChange={handleStatusRadioChange}
        />
      </Field>
    );
  };
  /**
   * To Check if HeavyEquipment Feature flag is enabled
   */
  heavyEquipmentTemplateFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE);

  /**
   * --Flag to show Completion popuup
   * FF active , SearviceAction Type Edit
   * Selected searvice is last updated record
   * Service Type is Recurrent
   *
   */
  showCompletionPopup =
    this.heavyEquipmentTemplateFF &&
    this.props.serviceActionType === ActionItem.edit &&
    this.props.selectedService.lastRecord &&
    this.props.selectedService.basedOn !== AssetServiceCategory.PERIOD &&
    this.props.selectedService.serviceType.code === FREQUENCY_TYPE.RECURRENT;

  render() {
    const {
      handleCheckboxChange,
      handleCompletionDateChange,
      isLifeLong,
      onFormchange,
      selectedService,
      serviceStatus,
      showCompletionDate,
      showScheduleDate,
      showServiceDropDown,
      showServiceRadioButton,
      t,
      serviceActionType,
      handleServiceResult,
      distanceUnitValue,
    } = this.props;
    const unitsOfMeasurement =
      selectedService?.basedOn?.code === AssetServiceCategory.DISTANCE ||
      selectedService?.basedOn === AssetServiceCategory.DISTANCE
        ? distanceUnitValue?.value
        : t("services:ENGINE_HOURS");
    const isServiceOfHeavyEquipment =
      [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(
        this.props.selectedService?.basedOn?.code,
      ) ||
      [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(
        this.props.selectedService?.basedOn,
      );
    return (
      <DetailsPanel
        data-testid="pnlTemplateDetails"
        panelTitleId="pnlTemplateDetails"
        title={t("services:SERVICE_DETAILS")}
      >
        {this.showCompletionPopup && this.state.isCompleationChange && this.showConfirmationDialog()}
        {showServiceDropDown ? (
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              {this.serviceDropDown()}
            </Grid>
          </Grid>
        ) : (
          this.renderServiceName()
        )}
        {showServiceRadioButton && this.isServiceStatusHistory() && this.showStatusTypeRadio()}
        {this.props.showDescription && this.renderDescriptionSection()}
        {serviceStatus === ServiceStatus.OPEN && isServiceOfHeavyEquipment && this.heavyEquipmentTemplateFF
          ? this.showUpcomingDetailsHE(unitsOfMeasurement)
          : this.isServiceStatusOpen() && (
              <GridLayout mode={"col6"}>
                <DateField
                  t={t}
                  name="nextServiceDate"
                  validation={[required(t, t("common:VALIDATION_DATE"))]}
                  minDate={LocaleManager.dateHelpers.getNativeDateInTenantTimezone()}
                />
              </GridLayout>
            )}

        {serviceActionType === ActionItem.edit &&
        selectedService.serviceType.code === ServiceFormEnum.ONE_TIME_SERVICE &&
        serviceStatus === ServiceStatus.OPEN &&
        isServiceOfHeavyEquipment ? (
          <Grid item={true} xs={12}>
            <CheckboxWrap>
              <Checkbox
                name="isLifeLong"
                isFormControl={true}
                label={t("services:IS_LIFELONG_SERVICE")}
                disabled={false}
                onChange={handleCheckboxChange}
              />
              <Tooltip id="LifelongToolTip" title={t("services:LIFELONG_TOOLTIP")}>
                <TooltipSpan>
                  <SquareIcon name={IconName.Info} />
                </TooltipSpan>
              </Tooltip>
            </CheckboxWrap>
          </Grid>
        ) : null}

        {showScheduleDate && !isServiceOfHeavyEquipment ? (
          <Grid container={true} spacing={2}>
            <DateSection
              selectedService={selectedService}
              showScheduleDate={showScheduleDate}
              showCompletionDate={showCompletionDate}
              onFormchange={onFormchange}
              handleCompletionDateChange={handleCompletionDateChange}
              t={t}
              disabled={isLifeLong}
              showDateLabel={
                serviceActionType === ActionItem.complete ||
                (serviceActionType === ActionItem.edit && serviceStatus === ServiceStatus.HISTORY)
              }
              handleServiceResult={handleServiceResult}
              handleCompleationOnBlur={this.handleCompleationOnBlur}
            />
            <Grid item={true} xs={12}>
              {serviceActionType === ActionItem.edit &&
              selectedService.serviceType.code === ServiceFormEnum.ONE_TIME_SERVICE &&
              serviceStatus !== ServiceStatus.HISTORY ? (
                <CheckboxWrap>
                  <Checkbox
                    name="isLifeLong"
                    isFormControl={true}
                    label={t("services:IS_LIFELONG_SERVICE")}
                    disabled={false}
                    onChange={handleCheckboxChange}
                  />
                  <Tooltip id="LifelongToolTip" title={t("services:LIFELONG_TOOLTIP")} placement="bottom">
                    <TooltipSpan>
                      <SquareIcon name={IconName.Info} />
                    </TooltipSpan>
                  </Tooltip>
                </CheckboxWrap>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          showScheduleDate && serviceStatus === ServiceStatus.HISTORY && this.showHistoricDetailsHE(unitsOfMeasurement)
        )}
        {serviceActionType === ActionItem.complete && serviceStatus === ServiceStatus.OPEN ? (
          <Grid container={true} spacing={1}>
            {isServiceOfHeavyEquipment && this.showServiceResult()}
          </Grid>
        ) : null}
      </DetailsPanel>
    );
  }
}

export default withTranslation()(ServiceDetailsPanel);
