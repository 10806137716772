/**
 * @description - Loader to execute when more data present during scoll down of the data.
 */

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "../../themeConfig/styledComponents";

const LoaderWrapper = styled.div`
  text-align: center;
`;
const LoaderText = styled.span``;
const LoaderDiv = styled.div.attrs({
  key: "loader",
})`
  width: 20px;
  height: 20px;
  background-color: #d2051e;
  margin: 10px auto;
  -webkit-animation: rotateLoader 1.2s infinite ease-in-out;
  animation: rotateLoader 1.2s infinite ease-in-out;
  position: relative;
  &:after {
    content: "";
  }
  ${LoaderAnimation()};
`;
/**
 * @description - Animation of the loader using keyframes.
 */
function LoaderAnimation() {
  return `
      @keyframes rotateLoader {
        0% {
          transform: perspective(120px) rotateX(0deg) rotateY(0deg);
          -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
        }
        50% {
          transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
          -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
        }
        100% {
          transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
          -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        }
      }`;
}

const InfiniteScrollLoader = (props: WithTranslation) => (
  <LoaderWrapper id="infiniteLoading" key="infiniteLoader">
    <LoaderDiv />
    <LoaderText>{props.t("common:LOADING")}</LoaderText>
  </LoaderWrapper>
);

export default withTranslation()(InfiniteScrollLoader);
