import { HelperLodash } from "am-web-ui-shared/helpers";
/**
 * @description - Function to get default custom view.
 * @param data - Custom view list.
 */
export const getDefaultView = (data) => {
  return (
    !HelperLodash.isEmpty(data) &&
    data.find((item) => {
      return item.defaultView;
    })
  );
};

/**
 * @description - Function to prepare object to send API to create custom view.
 * @param formValues - Form input values.
 * @param {string[]} filterQuery - Filter query object.
 * @param sortQuery - Sort query object.
 * @param {string[]} gridFields - List of grid fields.
 * @param module - Module name.
 */
export const prepareAddCustomViewData = (
  formValues,
  filterQuery: [],
  sortQuery,
  gridFields: string[],
  module: string,
) => {
  const obj: any = {
    customViewName: formValues && formValues.customViewName,
    defaultView: (formValues && formValues.defaultView) || false,
    filters: !isOriginalView(formValues) && filterQuery && filterQuery.length ? filterQuery : null,
    listOfFields: gridFields,
    sortField: formatSortQueryObject(sortQuery),
  };
  obj.entityName = module;
  return obj;
};

/**
 * This function should be removed as original view flag should be derived from API.
 * This is temporary solution to fix bug BUTSAM-29728.
 * @description - Function to get original view.
 * @param data - Custom view list.
 */
export const getOriginalCustomView = (data) => {
  return (
    data &&
    data.find((item) => {
      return item.originalView;
    })
  );
};

// This function is used to map sortQuery object as we are
// getting different property from customView api and needs to send
// back in different format with GET all asset api.
export const formatSortQueryObject = (data) => {
  return data && { sortColumn: data.sortField, sortOrder: data.sortType === "+" ? "asc" : "dsc" };
};

// This function is used to map sortQuery object as we are
// getting different property from customView api and needs to send
// back in different format with GET all asset api.
export const revertSortQueryObject = (data) => {
  return data && { name: data.sortColumn, sortType: data.sortOrder === "asc" ? "+" : "-", label: "" };
};

export const isOriginalView = (customView) => {
  return customView && customView.originalView;
};

/**
 * @description - Function to return TRUE/FALSE to decide weather
 * custom view is modified or not.
 * @param state - Redux state.
 */
export const isCustomViewUpdated = (state) => {
  const filters = state.appliedFilterQuery || [];
  const sortrQuery = formatSortQueryObject(state.sortQuery);
  const selectedCustomView = state.selectedCustomView;
  const selectedViewFilters = (selectedCustomView && selectedCustomView.filters) || [];
  const selectedViewsortField = selectedCustomView && selectedCustomView.sortField;
  return selectedCustomView && selectedCustomView.originalView
    ? !HelperLodash.isEqual(sortrQuery, selectedViewsortField)
    : !HelperLodash.isEqual(filters, selectedViewFilters) || !HelperLodash.isEqual(sortrQuery, selectedViewsortField);
};

export const setSelectedCustomView = (state, action) => {
  const sortQuery = revertSortQueryObject(action.item?.sortField);
  return {
    ...state,
    appliedFilterQuery: action.item?.filters || [],
    filterQuery: null,
    isCustomViewUpdated: false,
    selectedCustomView: action.item,
    showNoRecordFoundView: action.item?.originalView,
    sortQuery: {
      sortField: sortQuery?.name,
      sortType: sortQuery?.sortType,
    },
  };
};

export const getCustomViewSuccess = (state, action) => {
  const sortQueryObject = action.selectedView
    ? revertSortQueryObject(action.selectedView.sortField)
    : revertSortQueryObject(getDefaultView(action.payload).sortField);
  return {
    ...state,
    customViewsList: action.payload,
    filterQuery: null,
    isCustomViewUpdated: false,
    selectedCustomView: action.selectedView ? action.selectedView : getDefaultView(action.payload),
    sortQuery: {
      sortField: sortQueryObject.name,
      sortType: sortQueryObject.sortType,
    },
  };
};
export const getCustomViewFailure = (state, action) => {
  return {
    ...state,
    customViewsList: action.payload,
    selectedCustomView: action.selectedView,
  };
};
