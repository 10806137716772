/**
 * @description Action constants for Transfer history.
 *
 * format: {feature}_{action}
 */
const TransferHistoryActionConstants = {
  AM_FETCH_TRANSFER_HISTORIES: "AM_FETCH_TRANSFER_HISTORIES",
  AM_FETCH_TRANSFER_HISTORIES_SEARCH: "AM_FETCH_TRANSFER_HISTORIES_SEARCH",
  AM_FETCH_TRANSFER_HISTORY_DETAILS_REQUEST: "AM_FETCH_TRANSFER_HISTORY_DETAILS_REQUEST",
  AM_FETCH_TRANSFER_HISTORY_DETAILS_RESPONSE: "AM_FETCH_TRANSFER_HISTORY_DETAILS_RESPONSE",
  AM_SET_TRANSFER_HISTORIES_RESPONSE: "AM_SET_TRANSFER_HISTORIES_RESPONSE",
  SET_TRANSFER_HISTORY_SEARCH_QUERY: "SET_TRANSFER_HISTORY_SEARCH_QUERY",
  TRANSFER_HISTORY_RESET_QUERY_PARAM: "TRANSFER_HISTORY_RESET_QUERY_PARAM",
};

export default TransferHistoryActionConstants;
