import React from "react";
const SVG = () => (
  <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3533.000000, -2426.000000)">
        <g transform="translate(3530.000000, 2424.000000)">
          <g id="callback">
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M15,7.58578644 L19.8284271,2.75735931 L21.2426407,4.17157288 L16.4142136,9 L19,9 L19,11 L15,11 L13,11 L13,5 L15,5 L15,7.58578644 Z M20.9984377,15.46 L20.9984377,20.97 C10.8184377,21.55 2.44843768,13.18 3.02843768,3 L8.53843768,3 L9.14843768,8.25 L7.5,10 C8.94,12.84 11.17,15.06 14,16.5 L15.7284377,14.85 L20.9984377,15.46 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
