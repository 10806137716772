// @ts-ignore
import { FieldRequestConstants } from "custom-actions";
import { IFieldRequestSettingsData } from "../iFieldRequestSettingsProps";

export const getFieldRequestSettings = () => ({
  type: FieldRequestConstants.OM_GET_FIELD_REQUEST_SETTINGS,
});
export const updateFieldRequestSetting = (updatedData: IFieldRequestSettingsData) => ({
  type: FieldRequestConstants.OM_UPDATE_FIELD_REQUEST_SETTINGS,
  updatedData,
});
