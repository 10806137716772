const UserProfileConstants = {
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  TNC_ACCEPTED: "TNC_ACCEPTED",
  TNC_ACCEPTED_SUCCESS: "TNC_ACCEPTED_SUCCESS",
  UPDATE_USER_ACCEPTANCE_FLAG: "UPDATE_USER_ACCEPTANCE_FLAG",
  USER_ME_DATA: "USER_ME_DATA",
  USER_ME_RESPONSE: "USER_ME_RESPONSE",
  USER_PROFILE: "USER_PROFILE",
  USER_PROFILE_RESPONSE: "USER_PROFILE_RESPONSE",
};

export default UserProfileConstants;
