const ApplicationConstants = {
  COLUMN: {
    COL0: 0,
    COL1: 1,
    COL2: 2,
    COL3: 3,
    COL4: 4,
    COL6: 6,
  },
  COUNT: {
    CNT1: 1,
    CNT2: 2,
    CNT3: 3,
    CNT4: 4,
  },
  LATITUDE: {
    L90: 90,
  },
  LENGTH: {
    LEN0: 0,
    LEN1: 1,
    LEN10: 10,
    LEN2: 2,
  },
  LIMIT: {
    LIM1: 1,
  },
  LONGITUDE: {
    L180: 180,
  },
  OFFSET: {
    OFF0: 0,
  },
  TIMEOUT: {
    TOUT100: 100,
    TOUT200: 200,
  },
  PERCENTAGE_BASE: 100,
  NUMBER: {
    NUM2: 2,
    NUM3: 3,
    NUM4: 4,
    NUM5: 5,
    NUM6: 6,
    NUM10: 10,
    NUM60: 60,
    NUM100: 100,
    NUM266: 266,
    NUM1000: 1000,
    NUM100000: 100000,
  },
  KEYCODES: {
    ENTER: 13,
  },
};

export default ApplicationConstants;
