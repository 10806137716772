const ChargesActionConstants = {
  ADD_PLUG_COST: "ADD_PLUG_COST",
  CHARGES_DESELECT_ROW: "CHARGES_DESELECT_ROW",
  CHARGES_SELECT_ROW: "CHARGES_SELECT_ROW",
  QTY_CHARGES_SELECT_ROW: "QTY_CHARGES_SELECT_ROW",
  QTY_CHARGES_DESELECT_ROW: "QTY_CHARGES_DESELECT_ROW",
  CH_CHARGES_FILTER_FIELD_SUCCESS: "CH_CHARGES_FILTER_FIELD_SUCCESS",
  CH_CHARGES_LIST_FOR_SEARCH: "CH_CHARGES_LIST_FOR_SEARCH",
  CH_CHARGES_LIST_LOADER_END: "CH_CHARGES_LIST_LOADER_END",
  CH_CHARGES_LIST_LOADER_START: "CH_CHARGES_LIST_LOADER_START",
  CH_CHARGES_RESET_QUERY_PARAM: "CH_CHARGES_RESET_QUERY_PARAM",
  CH_CLEAR_DEFAULT_GRID_FIELDS: "CH_CLEAR_DEFAULT_GRID_FIELDS",
  CH_FAILURE_CHARGES_DETAILS: "CH_FAILURE_CHARGES_DETAILS",
  CH_GET_ASSET_CHARGE_SETTING: "CH_GET_ASSET_CHARGE_SETTING",
  CH_GET_CHARGES_FILTER_FIELD: "CH_GET_CHARGES_FILTER_FIELD",
  CH_GET_QTY_ITEM_CHARGES_FILTER_FIELD: "CH_GET_QTY_ITEM_CHARGES_FILTER_FIELD",
  CH_GET_COMPANY_CHARGE_SETTINGS: "CH_GET_COMPANY_CHARGE_SETTINGS",
  CH_GET_COMPANY_CHARGE_SETTINGS_SUCCESS: "CH_GET_COMPANY_CHARGE_SETTINGS_SUCCESS",
  CH_GET_CUSTOM_VIEW: "CH_GET_CUSTOM_VIEW",
  CH_GET_QTY_CUSTOM_VIEW: "CH_GET_QTY_CUSTOM_VIEW",
  CH_GET_CUSTOM_VIEW_FIELDS: "CH_GET_CUSTOM_VIEW_FIELDS",
  CH_GET_QTY_ITEM_CUSTOM_VIEW_FIELDS: "CH_GET_QTY_ITEM_CUSTOM_VIEW_FIELDS",
  CH_GET_CUSTOM_VIEW_FIELDS_SUCCESS: "CH_GET_CUSTOM_VIEW_FIELDS_SUCCESS",
  CH_GET_CUSTOM_VIEW_SUCCESS: "CH_GET_CUSTOM_VIEW_SUCCESS",
  CH_CHARGES_LIST_FOR_QTY_SEARCH: "CH_CHARGES_LIST_FOR_QTY_SEARCH",
  CH_GET_DEFAULT_VIEW: "CH_GET_DEFAULT_VIEW",
  CH_GET_QTY_ITEM_DEFAULT_VIEW: "CH_GET_QTY_ITEM_DEFAULT_VIEW",
  CH_GET_DEFAULT_VIEW_SUCCESS: "CH_GET_DEFAULT_VIEW_SUCCESS",
  CH_GET_QTY_ITEM_DEFAULT_VIEW_SUCCESS: "CH_GET_QTY_ITEM_DEFAULT_VIEW_SUCCESS",
  CH_GET_PENDING_CHARGES: "CH_GET_PENDING_CHARGES",
  CH_GET_QTY_ITEM_PENDING_CHARGES: "CH_GET_QTY_ITEM_PENDING_CHARGES",
  CH_GET_SUCESS_RESPONSE: "CH_GET_SUCESS_RESPONSE",
  CH_GET_TEMPLATE_CHARGE_SETTING: "CH_GET_TEMPLATE_CHARGE_SETTING",
  CH_GET_WORKDAYS: "CH_GET_WORKDAYS",
  CH_GET_WORKDAYS_SUCCESS: "CH_GET_WORKDAYS_SUCCESS",
  CH_RESET_TO_DEFAULT_VIEW: "CH_RESET_TO_DEFAULT_VIEW",
  CH_RESET_TO_QTY_ITEM_DEFAULT_VIEW: "CH_RESET_TO_QTY_ITEM_DEFAULT_VIEW",
  CH_RESET_TO_DEFAULT_VIEW_SUCCESS: "CH_RESET_TO_DEFAULT_VIEW_SUCCESS",
  CH_SUBMIT_ADD_CHARGE_SETTING_RESPONSE: "CH_SUBMIT_ADD_CHARGE_SETTING_RESPONSE",
  CH_SUBMIT_ASSET_CHARGE_SETTING: "CH_SUBMIT_ASSET_CHARGE_SETTING",
  CH_SUBMIT_ASSET_CHARGE_SETTING_RESPONSE: "CH_SUBMIT_ASSET_CHARGE_SETTING_RESPONSE",
  CH_SUBMIT_TEMPLATE_CHARGE_SETTING: "CH_SUBMIT_TEMPLATE_CHARGE_SETTING",
  CH_SUBMIT_TEMPLATE_CHARGE_SETTING_RESPONSE: "CH_SUBMIT_TEMPLATE_CHARGE_SETTING_RESPONSE",
  CH_SUCCESS_CHARGES_DETAILS: "CH_SUCCESS_CHARGES_DETAILS",
  CH_SUCCESS_TEMPLATE_CHARGES_SETTING: "CH_SUCCESS_TEMPLATE_CHARGES_SETTING",
  CH_UPDATE_CUSTOM_GRID_FIELDS: "CH_UPDATE_CUSTOM_GRID_FIELDS",
  CH_UPDATE_QTY_ITEM_CUSTOM_GRID_FIELDS: "CH_UPDATE_QTY_ITEM_CUSTOM_GRID_FIELDS",
  CLEAR_IMPORT_HISTORY_ERRORS: "CLEAR_IMPORT_HISTORY_ERRORS",
  DELETE_PLUG_COST: "DELETE_PLUG_COST",
  GET_IMPORT_ASSET_CHARGES_HISTORY: "GET_IMPORT_ASSET_CHARGES_HISTORY",
  GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS: "GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS",
  GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS_RESPONSE: "GET_IMPORT_ASSET_CHARGES_HISTORY_ERRORS_RESPONSE",
  GET_IMPORT_ASSET_CHARGES_HISTORY_RESPONSE: "GET_IMPORT_ASSET_CHARGES_HISTORY_RESPONSE",
  RESET_SHOW_FAILURE_MESSAGE: "RESET_SHOW_FAILURE_MESSAGE",
  SET_CHARGES_FILTER_APPLIED_FIELD: "SET_CHARGES_FILTER_APPLIED_FIELD",
  SET_CHARGES_FILTER_QUERY: "SET_CHARGES_FILTER_QUERY",
  SET_CHARGES_LIST_FIELDS_QUERY: "SET_CHARGES_LIST_FIELDS_QUERY",
  SET_QTY_CHARGES_LIST_FIELDS_QUERY: "SET_QTY_CHARGES_LIST_FIELDS_QUERY",
  SET_CHARGES_SEARCH_QUERY: "SET_CHARGES_SEARCH_QUERY",
  SET_CHARGES_SORT_QUERY: "SET_CHARGES_SORT_QUERY",
  SHOW_FAILURE_MESSAGE: "SHOW_FAILURE_MESSAGE",
  UI_SAVE_PENDING_CHARGES: "UI_SAVE_PENDING_CHARGES",
  UI_SAVE_QTY_PENDING_CHARGES: "UI_SAVE_QTY_PENDING_CHARGES",
  UPDATE_COMPANY_COST_SETTINGS: "UPDATE_COMPANY_COST_SETTINGS",
  UPDATE_COMPANY_COST_SETTINGS_SUCCESS: "UPDATE_COMPANY_COST_SETTINGS_SUCCESS",
};

export default ChargesActionConstants;
