import { HelperLodash } from "am-web-ui-shared/helpers";
import TransferModel from "../models/transfer/transferModel";
import { deepCloneArray } from "../utils/commonUtils";
import { TransferStatusEnum } from "../utils/enums/transferAssetsEnum";

/**
 * @description - Merge the canceled data inside transfer object
 * @param listArg - contains all transferd and canceled object
 *
 */
export const mergedCancelTransferList = (listArg) => {
  const list = deepCloneArray(listArg);
  const TransferdListPartition = HelperLodash.partition(list, (o) => o.transferType !== TransferStatusEnum.TRANSFER);
  const cancelledTransferredList = TransferdListPartition[0];
  const transferdList = TransferdListPartition[1];
  let totalRecords = 0;

  transferdList.map((item, index) => {
    cancelledTransferredList.filter((canceledItem) => {
      if (canceledItem.cancelledTransferId === item.transferId) {
        item.canceledTransferDetails = {
          notes: canceledItem.notes,
          transferDate: canceledItem.transferDate,
          transferId: canceledItem.transferId,
        };
        item.showTransferButton = false;
        item.showTransferLabel = true;
      }
    });

    if (index === 0 && !item.sourceAllocation) {
      if (item.canceledTransferDetails) {
        item.showTransferButton = false;
        item.showTransferLabel = true;
      } else {
        item.showTransferButton = true;
        item.showTransferLabel = false;
      }
    }
  });

  const filteredList = transferdList.filter((item) => !!item.canceledTransferDetails);
  totalRecords = transferdList.length + filteredList.length;
  return { transferdList, totalRecords };
};

export const getTransferModelList = (dataObj) => {
  const list = [];
  const dataList = dataObj.response;
  (dataList || []).forEach((data) => {
    list.push(new TransferModel(data));
  });
  return {
    ...dataObj,
    response: list,
  };
};
