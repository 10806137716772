import React from "react";
import styled from "styled-components";
import Button from "../../../elements/Button/button";
import IFooterButtonProps from "./iFooterButton";

const StyledButton = styled(Button)``;

/**
 * FooterButton component is holding the footer button only.
 */

class FooterButton extends React.PureComponent<IFooterButtonProps> {
  render() {
    const { buttonText, buttonType, id } = this.props;
    return (
      <StyledButton onClick={this.props.onClick} buttonType={buttonType} id={id} {...this.props}>
        {buttonText}
      </StyledButton>
    );
  }
}

export default FooterButton;
