import React from "react";
import styled from "styled-components";
import StyleAttrConstants from "../../constant/styleAttrConstants";
import ApplicationConstants from "../../constant/applicationConstants";

interface IActionProps {
  children: any;
  left?: any;
  top?: any;
  zindex?: number;
  position?: string;
  isActionBarItem?: boolean;
}

const dialogContainerDivLeft = (props: any) => `${props["data-left"]}px`;
const dialogContainerDivTop = (props: any) => `${props["data-top"]}px`;
const zindex = (props: string) => `${props["data-zindex"]}`;
const dialogContainerDivPosition = (props: any) => `${props["data-position"]}`;

const DialogContainer = styled.div`
  position: ${dialogContainerDivPosition};
  left: ${dialogContainerDivLeft};
  top: ${dialogContainerDivTop};
  z-index: ${zindex};
`;
class DialogPosition extends React.Component<IActionProps> {
  static defaultProps = {
    isActionBarItem: true,
    position: StyleAttrConstants.POSITION.FIXED,
    zindex: StyleAttrConstants.ZINDEX.Z999999,
  };

  state = {
    dialogOffsetTop: this.props.top,
    dialogPosition: this.props.position,
    showComponent: true,
  };

  hideComponent = () => {
    this.setState({ showComponent: false });
  };

  setDialogPosition = () => {
    if (document.body.classList.contains(ApplicationConstants.FIXEDACTIONBARCLASS)) {
      this.setState({
        dialogOffsetTop: StyleAttrConstants.HEIGHT.H71,
        dialogPosition: StyleAttrConstants.POSITION.FIXED,
      });
    } else {
      this.setState({ dialogPosition: StyleAttrConstants.POSITION.ABSOLUTE, dialogOffsetTop: this.props.top });
    }
  };

  componentDidMount() {
    if (this.props.isActionBarItem) {
      this.setDialogPosition();
      window.addEventListener("scroll", this.setDialogPosition);
    }
    window.addEventListener("resize", this.hideComponent);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.hideComponent);
    if (this.props.isActionBarItem) {
      window.removeEventListener("scroll", this.setDialogPosition);
    }
  }

  render() {
    return (
      this.state.showComponent && (
        <DialogContainer
          id="dialogContainer"
          data-left={this.props.left ? this.props.left : null}
          data-top={this.state.dialogOffsetTop ? this.state.dialogOffsetTop : null}
          data-zindex={this.props.zindex}
          data-position={this.state.dialogPosition}
        >
          {this.props.children}
        </DialogContainer>
      )
    );
  }
}

export default DialogPosition;
