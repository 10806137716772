import { Dialog } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
// @ts-ignore
import { CountExpansionPanel } from "../../../../../components/DetailsPanel/index";
import DetailsPanelLink from "../../../../../components/detailsPanelLink/DetailsPanelLink";
import ServiceViewContainer from "../../../../../components/services/servicesViewContainer";
import { ActionItem } from "../../../../../utils/enums/actionItemEnum";
import IServicesProps from "./iServicesProps";

/**
 * @description - This is the services detail section on the asset details panel.
 */

const DetailWrapper = styled.div`
  padding: 0 16px;
`;

const WrappedDetailLink = styled(DetailsPanelLink)`
  padding: 12px 16px 10px 56px;
`;

class Services extends React.PureComponent<IServicesProps, { showService: boolean }> {
  state = {
    showService: false,
  };
  /**
   * @description open services when user click
   */
  showService = () => {
    this.setState({ showService: true });
    this.props.showServiceList(true);
  };
  /**
   * @description close services when user click
   */
  closeServices = () => {
    this.setState({ showService: false });
    this.props.clearAssetServiceList();
    this.props.showServiceList(false);
    this.props.closeMoreFilter();
  };

  getDetailsComponents = () => {
    const { detailComponents, assetDetailsData, t, distanceUnitValue } = this.props;
    return (
      detailComponents &&
      detailComponents.map((item, index) => (
        <React.Fragment key={`keyUEPDetails_${index}`}>{item(assetDetailsData, t, distanceUnitValue)}</React.Fragment>
      ))
    );
  };

  render() {
    const { servicesCount, t, icon, text, status, assetId, showManageServices, showMoreText, defaultExpanded } =
      this.props;

    return (
      <>
        <CountExpansionPanel
          panelId="ServicesSection"
          headingId="headOpenServices"
          boldId="lblOpenServices"
          boldText={t(text)}
          countId="lblOpenServicesCount"
          countText={servicesCount ? servicesCount : 0}
          iconUrl={icon}
          showExpandIcon={true}
          defaultExpanded={defaultExpanded}
        >
          <DetailWrapper>{this.getDetailsComponents()}</DetailWrapper>
          <WrappedDetailLink onClick={this.showService} id={`${status}-services-link`} text={t(showMoreText)} />
        </CountExpansionPanel>
        {this.state.showService && showManageServices && showManageServices.showServiceList && (
          <Dialog
            containerId="addAssetContainer"
            headerId="addAssetHeader"
            onClose={this.closeServices}
            headerText={t("services:MANAGE_SERVICES")}
          >
            <ServiceViewContainer
              assetLocationsList={null}
              assetStatusList={null}
              manufacturerList={null}
              responsibleWorkersList={null}
              actionType={ActionItem.edit}
              assetId={assetId}
              serviceStatusType={status}
              isAssetDetails={true}
              closeServices={this.closeServices}
            />
          </Dialog>
        )}
      </>
    );
  }
}

export default Services;
