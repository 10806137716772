import React from "react";
import styled from "styled-components";
import Icons from "./svgs/index";
/**
 * Element interfaces
 */
interface IElementProps {
  className?: string;
  name?: string;
  id?: string;
  onClick?: any;
  imageSize?: number;
  dataFor?: string;
  dataTip?: string;
  "data-testid"?: string;
}

interface IWrapper {
  imageSize: number;
}

const Element = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  path {
    fill: ${(props) => props.theme.colors.base.steel};
  }
  &.large-dark path {
    fill: ${(props) => props.theme.colors.base.steel};
  }
  &.disabled path,
  &.grey path {
    fill: ${(props) => props.theme.colors.base.heavyConcrete40};
  }
  &.red-icon path,
  &.red-icon polygon,
  &.polygon polygon {
    fill: ${(props) => props.theme.colors.base.hiltiRed};
  }
  &.warning path {
    fill: ${(props) => props.theme.colors.base.orange};
  }
  &.success path {
    fill: ${(props) => props.theme.colors.base.green};
  }
`;

const wrapperElementWidth = (props: IWrapper) => `${props.imageSize}px`;
const wrapperElementHeight = (props: IWrapper) => `${props.imageSize}px`;

const Wrapper = styled(Element as any)`
  width: ${wrapperElementWidth};
  height: ${wrapperElementHeight};
  &.small svg {
    width: 16px;
    height: auto;
  }
  &.medium svg {
    width: 58px;
    height: auto;
  }
  &.large svg {
    width: 128px;
    height: auto;
    opacity: 0.2;
  }
  &.large-dark svg {
    width: 128px;
    height: auto;
  }
  &.grid-thumb svg {
    width: 42px;
    height: auto;
    opacity: 0.2;
  }
`;

export const SquareIcon = (props: IElementProps) => {
  const { "data-testid": dataTestId, name } = props;
  return (
    <Wrapper
      data-testid={dataTestId || `${name}-icon`}
      className={props.className}
      onClick={props.onClick}
      imageSize={props.imageSize}
      data-for={props.dataFor}
      data-tip={props.dataTip}
    >
      <Icons name={props.name} />
    </Wrapper>
  );
};

SquareIcon.defaultProps = {
  imageSize: 24,
};
