import { ConfirmHeading, ConfirmInfoText, IconName, LabelText, Prompt, SquareIcon } from "am-web-ui-shared/components";
import React from "react";
import styled from "styled-components";
import IDiscardDialog from "./iDiscardDialog";

export const ContentHeader = styled.header`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

export const LabelName = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

export const Cross = styled.div`
  .icon {
    cursor: pointer;
  }
`;

export const PromptHeader = (props) => {
  return (
    <ContentHeader>
      <LabelName>{props.header}</LabelName>
      <Cross onClick={props.handleClose}>
        <SquareIcon name="Cross" className="icon" />
      </Cross>
    </ContentHeader>
  );
};

/**
 * @description DiscardDialog
 */
export const DiscardDialog = (props: IDiscardDialog) => {
  const { t, handleClose, handleConfirm, crossIcon, confirmHeading, confirmInfoText, okLabel, isCustom, showCancel } =
    props;

  const renderBody = () => {
    return (
      <>
        {isCustom ? (
          <LabelText>{t(confirmInfoText)}</LabelText>
        ) : (
          <ConfirmInfoText>{t(confirmInfoText)}</ConfirmInfoText>
        )}
      </>
    );
  };

  return (
    <Prompt
      icon={IconName.Warning}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      cancelLabel={t("common:CANCEL")}
      okLabel={t(okLabel)}
      className="warning"
      header={t(confirmHeading)}
      headerComponent={PromptHeader}
      isHeader={crossIcon}
      showCancel={showCancel}
    >
      {!crossIcon && <ConfirmHeading>{t(confirmHeading)}</ConfirmHeading>}
      {renderBody()}
    </Prompt>
  );
};

DiscardDialog.defaultProps = {
  confirmHeading: "common:DISCARD_CHANGES_HEADING",
  confirmInfoText: "common:DISCARD_DIALOG_MESSAGE",
  crossIcon: false,
  okLabel: "common:DISCARD_CHANGES",
};

export default DiscardDialog;
