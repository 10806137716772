import { LoaderWrapper } from "am-web-ui-shared/elements";
import { AuthorizationCore, HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { connect } from "react-redux";

import {
  fetchAssetsList,
  getAssetFilterAttributes,
  getStorageAssetGatewayAssets,
  resetStorageAssetGatewayAssets,
  toggleAssetListDialog,
  updateAssetType,
} from "../../../assetTracking/actions/activeTrackingActionRoot";
import { sendAssetReport } from "../../../../actions/gatewayReportActionRoot";

import Title from "../../../../components/DetailsPanel/SingleRow/Title/title";

import { isTemplateAssociated } from "../../../../models/charges/chargesOrchestration";
import SidePanel from "../../../../components/sidePanel";
import { SmartInventoryDetailPanel } from "../../../../models/activeTracking/smartINventoryEnum";
import { canUserViewChargeSettings } from "../../../../models/charges/chargesOrchestration";
import { getChargeSetting, getTemplateChargeSetting } from "../../../charges/actions/chargesActionRoot";
import {
  fetchAssetDetails,
  cancelAssetLastTransfer,
  clearSelectedAssetTransferHistory,
  getAssetTransferDetails,
  updateStorageAssetGatewayStatus,
  clearAssetDetails,
  fetchAsseUtilizationSummary,
  fetchAsseUtilizationDetails,
} from "../../actions/assetsActionRoot";
import { clearAllSelection } from "../../../../actions/listActions";
import { searchRepairOrder } from "../../actions/hiltiCloudActionRoot";
import { clearAssetServiceList, closeServiceFilter, showServiceList } from "../../actions/assetServiceAction";
import AssetDetailPanel from "./assetDetailPanel";
import IAssetDetailsProps from "./iAssetDetails";
import { ValidationConstants } from "../../../../utils/enums/validationConstants";
import ICompanyModel from "../../../../models/company/iCompanyModel";
import { toggleAlertList } from "../../../alert/actions/alertNotificationAction";
import {
  getAddEditResponse,
  getAssetDetailsData,
  getAssetList,
  getAssetLocationsList,
  getAssetTrackingResponse,
  getChargesDetail,
  getCompantDetails,
  getDetailLoader,
  getDialogDetailLoader,
  getExpansionPanelDetailsLoader,
  getGatwayAssetType,
  getShowAlertListPopup,
  getShowManageServices,
  getTransferHistory,
  getUserMeData,
  ifMoreFilterClosed,
  showAssetViewDialog,
  storageSmartInventoryAssets,
  getTimeBasedEnabledTenantListFromState,
  getTenantIdFromState,
  getUtilizationSummary,
  getUtilizationDetails,
} from "../../reduxModule/assetSelectors";
import { ISmartInventoryFetchAssets } from "../../../../models/activeTracking/SmartInventory";

export const AssetDetailWrapper = styled.div.attrs({
  id: "asset-detail-container",
})`
  width: 100%;
  position: relative;
  height: 100%;
`;

const SidePanelContent = styled.div`
  #commonLoading {
    top: 62px;
  }
`;

/**
 * @description - This is the asset details component that will hold all its components.
 *
 * @class AssetDetails
 *
 * @interface IAssetDetailsProps
 */

export class AssetDetails extends React.PureComponent<IAssetDetailsProps> {
  componentDidMount = () => {
    const {
      assetTrackingResponse,
      getAssetDetails,
      getStorageAssetGatewayAssets: atgetStorageAssetGatewayAssets,
      resetStorageAssetGatewayAssets: atResetStorageAssetGatewayAssets,
      selectedAsset: { assetId },
    } = this.props;

    getAssetDetails(assetId);
    atResetStorageAssetGatewayAssets();
  };

  /**
   * @description - Invokes the assets list with given page number. Invokes the loader for infinite scroll.
   * `offset` and `limit` are coming from the API.
   */
  getPaginatedAssetTransferDetails = (offset?: number) => {
    const { transferHistory } = this.props;
    const nextOffset =
      (transferHistory.offset || 0) + (transferHistory.limit || ValidationConstants.MAX_LENGTH.NUM_100);
    if (nextOffset < transferHistory.totalRecords) {
      this.props.getAssetTransferDetails(this.props.selectedAsset.assetId, offset);
    }
  };

  selectedAssetCheck = (prevProps) => {
    const { selectedAsset } = this.props;
    return selectedAsset && selectedAsset.assetId !== prevProps.selectedAsset.assetId;
  };

  componentWillUnmount() {
    this.props.clearAssetDetails();
  }

  isAssetDetailsDataCheck = (prevProps) => {
    const { assetDetailsData } = this.props;
    return (
      !HelperLodash.isEqual(assetDetailsData, prevProps.assetDetailsData) &&
      HelperLodash.get(assetDetailsData, "gatewayStatus.code")
    );
  };

  isGatewayStatusNotMatched = (assetFromList) =>
    assetFromList.gatewayStatus &&
    !HelperLodash.isEqual(assetFromList.gatewayStatus.code, this.props.assetDetailsData.gatewayStatus.code);

  shouldUpdateStorageAssetGatewayStatus = () => {
    const {
      assetDetailsData,
      assetsList: { list },
    } = this.props;
    const assetFromList = list && list.find((asset) => asset.assetId === assetDetailsData.assetId);
    return assetFromList ? this.isGatewayStatusNotMatched(assetFromList) : false;
  };
  componentDidUpdate(prevProps) {
    const {
      assetDetailsData,
      updateStorageAssetGatewayStatus: amUpdateStorageAssetGatewayStatus,
      selectedAsset,
      resetStorageAssetGatewayAssets: atResetStorageAssetGatewayAssets,
    } = this.props;

    if (!assetDetailsData && prevProps.assetDetailsData) {
      this.props.getAssetDetails(selectedAsset.assetId);
    }

    if (this.isAssetDetailsDataCheck(prevProps)) {
      const { assetId, gatewayStatus } = assetDetailsData;
      if (this.shouldUpdateStorageAssetGatewayStatus()) {
        amUpdateStorageAssetGatewayStatus(assetId, gatewayStatus);
      }
    }

    if (this.selectedAssetCheck(prevProps)) {
      this.props.getAssetDetails(selectedAsset.assetId);
      this.props.clearSelectedAssetTransferHistory();
      atResetStorageAssetGatewayAssets();
    }
  }

  fetchChargeSetting = () => {
    if (AuthorizationCore.hasActiveModule("ASSET_COST") && canUserViewChargeSettings() && this.props.assetDetailsData) {
      if (isTemplateAssociated(this.props.assetDetailsData)) {
        this.props.getTemplateChargeSetting(this.props.assetDetailsData.assetTemplateId);
      } else {
        this.props.getChargeSetting(this.props.assetDetailsData.assetId);
      }
    }
  };

  /*
   * @description - pass the transfer list inside props
   */
  cancelLastTransfer = (cancelNote) =>
    this.props.cancelAssetLastTransfer(this.props.transferHistory.list[0], cancelNote);

  getSmartInventoryAssetsList = (options: ISmartInventoryFetchAssets) =>
    this.props.getList(SmartInventoryDetailPanel.STORAGE_ASSETS, options);

  getSmartInventoryAttributes = (id: number, statsType: string, filterQuery?: string) =>
    this.props.getSmartInventoryFilterAttributes(id, statsType, SmartInventoryDetailPanel.STORAGE_ASSETS, filterQuery);
  sendAssetReport = () => this.props.sendAssetReport(this.props.selectedAsset.assetId);

  panelCloseHandler = () => {
    if (this.props.notifyClose) {
      this.props.notifyClose();
    }
    this.props.clearAssetDetails();
  };

  contentOfDetailPanel = () => {
    const {
      assetDetailsData,
      selectedAsset,
      transferHistory,
      chargesDetail,
      t,
      showManageServices,
      expansionPanelDetailsLoader,
      toggleAssetListDialog: atToggleAssetListDialog,
      gatewayAssets: atGatewayAssets,
      updateAssetType: atUpdateAssetType,
      showAssetListDialog,
      assetType,
      userMeData,
      companyDetails,
      searchRepairOrderInfo,
      clearAllRowsSelection,
      showAlertListPopup,
      timeBasedEnabledTenantList,
      tenantId,
      config,
      distanceUnitValue,
      utilizationSummary,
      utilizationDetails,
      getAssetUtiliationSummary,
      getAssetUtilizationDetails,
    } = this.props;

    return (
      <AssetDetailPanel
        assetDetailsData={assetDetailsData}
        getStorageAssetGatewayAssets={this.props.getStorageAssetGatewayAssets}
        cancelLastTransfer={this.cancelLastTransfer}
        selectedAsset={selectedAsset}
        transferHistory={transferHistory}
        chargesDetail={chargesDetail && chargesDetail[selectedAsset.assetId]}
        closeMoreFilter={this.props.closeMoreFilter}
        t={t}
        showManageServices={showManageServices}
        clearAssetServiceList={this.props.clearAssetServiceList}
        showServiceList={this.props.showServiceList}
        getPaginatedAssetTransferDetails={this.getPaginatedAssetTransferDetails}
        templateChargeSetting={this.props.templateChargeSetting}
        detailLoader={this.props.detailLoader}
        fetchChargeSetting={this.fetchChargeSetting}
        expansionPanelDetailsLoader={expansionPanelDetailsLoader}
        getList={this.getSmartInventoryAssetsList}
        gatewayAssets={atGatewayAssets}
        toggleAssetListDialog={atToggleAssetListDialog}
        updateAssetType={atUpdateAssetType}
        showAssetListDialog={showAssetListDialog}
        assetType={assetType}
        getFilterAttributes={this.getSmartInventoryAttributes}
        sendAssetReport={this.sendAssetReport}
        getAssetTransferDetails={this.props.getAssetTransferDetails}
        userMeData={userMeData}
        companyDetails={companyDetails}
        searchRepairOrderInfo={searchRepairOrderInfo}
        clearAllRowsSelection={clearAllRowsSelection}
        showAlertListPopup={showAlertListPopup}
        toggleAlertList={this.props.toggleAlertList}
        timeBasedEnabledTenantList={timeBasedEnabledTenantList}
        tenantId={tenantId}
        config={config}
        distanceUnitValue={distanceUnitValue}
        utilizationSummary={utilizationSummary}
        utilizationDetails={utilizationDetails}
        getAssetUtiliationSummary={getAssetUtiliationSummary}
        getAssetUtilizationDetails={getAssetUtilizationDetails}
      />
    );
  };

  render() {
    const { assetDetailsData, detailLoader, t, config } = this.props;

    if (assetDetailsData || detailLoader) {
      return (
        <>
          {this.props.withoutSidePanel ? (
            <>
              {detailLoader ? (
                <LoaderWrapper loadingText={t("common:LOADING")} activity={detailLoader} />
              ) : (
                this.contentOfDetailPanel()
              )}
            </>
          ) : !this.props.isMoreFilterClosed ? (
            <SidePanel>
              <AssetDetailWrapper>
                <Title
                  id="lblAssetDetails"
                  showCloseIcon={config.showCloseButton}
                  panelCloseHandler={this.panelCloseHandler}
                >
                  {t("assets:ASSET_DETAILS")}
                </Title>
                {detailLoader ? (
                  <SidePanelContent id="sidePanelContent">
                    <LoaderWrapper loadingText={t("common:LOADING")} activity={detailLoader} />
                  </SidePanelContent>
                ) : (
                  this.contentOfDetailPanel()
                )}
              </AssetDetailWrapper>
            </SidePanel>
          ) : null}
        </>
      );
    } else {
      return null;
    }
  }
}
const getDistanceUnitValue = (state) => state.Company?.companyDetails?.distanceUnitValue;

/**
 * @description - It is redux method that is used for the map the redux state of the component's props.
 * @param state - State of the component.
 */
export const mapStateToProps = (state) => ({
  distanceUnitValue: getDistanceUnitValue(state),
  addEditResponse: getAddEditResponse(state.AddAsset),
  assetDetailsData: getAssetDetailsData(state.Assets),
  assetLocationsList: getAssetLocationsList(state.AddAsset),
  assetTrackingResponse: getAssetTrackingResponse(state.AssetTracking),
  assetType: getGatwayAssetType(state.ActiveTracking),
  assetsList: getAssetList(state.Assets),
  chargesDetail: getChargesDetail(state.Charges),
  detailLoader: getDetailLoader(state.Loader),
  dialogDetailLoader: getDialogDetailLoader(state.Loader),
  expansionPanelDetailsLoader: getExpansionPanelDetailsLoader(state.Loader),
  gatewayAssets: storageSmartInventoryAssets(state.ActiveTracking),
  isMoreFilterClosed: ifMoreFilterClosed(state.filterPanel),
  showAssetListDialog: showAssetViewDialog(state.ActiveTracking),
  showManageServices: getShowManageServices(state.AssetService),
  templateChargeSetting: (templateId) => HelperLodash.get(state, ["Charges", "templateChargeSetting", templateId]),
  transferHistory: getTransferHistory(state.Assets),
  userMeData: getUserMeData(state.UserReducer),
  companyDetails: getCompantDetails(state.Company),
  showAlertListPopup: getShowAlertListPopup(state.HeaderCount),
  timeBasedEnabledTenantList: getTimeBasedEnabledTenantListFromState(state),
  tenantId: getTenantIdFromState(state),
  utilizationSummary: getUtilizationSummary(state.Assets),
  utilizationDetails: getUtilizationDetails(state.Assets),
});

/**
 * @description - It is redux method that is used to dispatch the action.
 * @param dispatch - It is a function which is responsable to call a reducer with the help of action to update a state.
 */
export const mapDispatchToProps = (dispatch) => ({
  cancelAssetLastTransfer: (assetDetailsData, formData) =>
    dispatch(cancelAssetLastTransfer(assetDetailsData, formData)),
  clearAssetServiceList: () => dispatch(clearAssetServiceList()),
  clearSelectedAssetTransferHistory: () => dispatch(clearSelectedAssetTransferHistory()),
  closeMoreFilter: () => dispatch(closeServiceFilter()),
  clearAllRowsSelection: (data) => dispatch(clearAllSelection(data)),
  clearAssetDetails: () => dispatch(clearAssetDetails()),
  getAssetDetails: (paramAssetId) => dispatch(fetchAssetDetails(paramAssetId)),
  getAssetTransferDetails: (assetId: number, offset?: number, limit?: number) =>
    dispatch(getAssetTransferDetails(assetId, offset, limit)),
  getChargeSetting: (assetId) => dispatch(getChargeSetting(assetId)),

  getList: (page: SmartInventoryDetailPanel, options: ISmartInventoryFetchAssets) =>
    dispatch(fetchAssetsList(page, options)),

  getSmartInventoryFilterAttributes: (id: number, statsType: string, urlDetail: string, filterQuery?: string) =>
    dispatch(getAssetFilterAttributes(id, statsType, filterQuery, urlDetail)),
  getStorageAssetGatewayAssets: (assetId: number) => dispatch(getStorageAssetGatewayAssets(assetId)),
  getTemplateChargeSetting: (templateId) => dispatch(getTemplateChargeSetting(templateId)),
  resetStorageAssetGatewayAssets: () => dispatch(resetStorageAssetGatewayAssets()),
  sendAssetReport: (assetId) => dispatch(sendAssetReport(assetId)),
  showServiceList: (type) => dispatch(showServiceList(type)),
  toggleAssetListDialog: (showDialog: boolean) => dispatch(toggleAssetListDialog(showDialog)),
  updateAssetType: (assetType: string) => dispatch(updateAssetType(assetType)),
  updateStorageAssetGatewayStatus: (assetId: number, gatewayStatus: { code: string; value: string }) =>
    dispatch(updateStorageAssetGatewayStatus(assetId, gatewayStatus)),
  searchRepairOrderInfo: (assetDetails: any, companyDetails: ICompanyModel) =>
    dispatch(searchRepairOrder(assetDetails, companyDetails)),
  toggleAlertList: () => dispatch(toggleAlertList()),
  getAssetUtiliationSummary: (paramAssetId: number, queryObj: any) =>
    dispatch(fetchAsseUtilizationSummary(paramAssetId, queryObj)),
  getAssetUtilizationDetails: (assetId: number, queryObj: any) =>
    dispatch(fetchAsseUtilizationDetails(assetId, queryObj)),
});

/**
 * @export AssetDetails - Asset Details Component.
 */
export default connect(mapStateToProps, mapDispatchToProps)(AssetDetails);
