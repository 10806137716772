import React from "react";
const SVG = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2011.000000, -747.000000)">
        <g transform="translate(2008.000000, 744.000000)">
          <g id="notes">
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M3.01,3 L15,3 L21,9 L21,21 L3,21 L3.01,3 Z M14,10 L19.5,10 L14,4.5 L14,10 Z"
              id="🎨Color"
              fill="#524F53"
              transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
