import React from "react";
const SVG = () => (
  <svg width="57px" height="43px" viewBox="0 0 57 43" version="1.1">
    <g id="Screens---Web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="States/empty-state/large" transform="translate(-613.000000, -491.000000)">
        <g id="WIDGET-/-Empty-state-/-Large" transform="translate(440.000000, 114.000000)">
          <g id="MODULES/Empty-screen/Icon-+-text-+-cta" transform="translate(112.000000, 361.000000)">
            <g id="ICON" transform="translate(52.000000, 0.000000)">
              <path
                d="M56.6024374,16.2824248 L65.0877188,24.7677062 L31.1378498,58.7175752 L9.92464637,37.5043718 L18.4099277,29.0190904 L31.1376464,41.7464248 L56.6024374,16.2824248 Z"
                id="🎨Color"
                fill="#524F53"
              />
              <rect id="Bounding-box" x="0" y="0" width="72" height="72" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
