export const isScanCodeInRange = (value: string) => {
  const minRange = 305000000;
  const maxRange = 309999999;
  const radix = 10;
  const code = parseInt(value, radix);
  return code >= minRange && code <= maxRange;
};

export const isScanCodeInOffBoardingRange = (value: string) => {
  const minRange = 300000000;
  const maxRange = 309999999;
  const radix = 10;
  const code = parseInt(value, radix);
  return code >= minRange && code <= maxRange;
};
