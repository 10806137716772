// @ts-ignore
import { APIMessages } from "custom-actions";

import IAssignedServiceList from "../components/services/model/iAssignedServiceList";
import { ServiceStatus } from "../components/services/serviceFormController/serviceFormEnum";
import Gateway from "../gateway/gateway";
import { IToolReturnSetting, IWorkerCertificate } from "../models/alert/alertDetails";
import IFetchAssetQuery from "../models/asset/fetchAssetQuery";
import { IAssetsList } from "../models/assets.list";
import { ICustomViewListModel } from "../models/customView/iCustomViewListModel";
import { IReceiveNotificationModal } from "../models/iAlertSettingsModels";
import { IErrorResponse } from "../models/iApiError";
import {
  IGetLabelResponse,
  ILabelAssociationReponse,
  IUtilizationSummaryResponse,
  IUtilizationDetailsResponse,
} from "../models/labels.model";
import {
  IAssetsHistory,
  IAssetsHistoryErrors,
} from "../modules/asset/components/importAssets/importHistoryWrapper/iAssetImportHistoryProps";
import { IGetTransferDate } from "../modules/transferCart/components/list/model/iSetDates";
import {
  ITransferHistoryAsset,
  ITransferHistoryList,
  ITransferHistorySearchList,
} from "../modules/transferCart/components/list/model/iTransferHistoryModal";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import {
  getAssetMgmtEndpoint,
  getAssetOrchMgmtEndpoint,
  getOrgMgmtEndpoint,
  getTrackUnitEndpoint,
  getUserMgmtEndpoint,
  removeField,
} from "../utils/apiUtils";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import { AssetServiceCategory } from "../utils/enums/serviceEnum";
import { ISubmitExtendReturnDate } from "../modules/alert/components/extendReturnDate/iExtendReturnDateProps";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";

/**
 * Returns list of Assets by calling relevant API
 * @param page : page size of the list.
 * @param size : size of the page.
 */
export const getAssetsList = async (queryObj, _loader = true): Promise<IAssetsList | IErrorResponse> => {
  queryObj = { ...queryObj, q: queryObj.q?.trim() };
  const filter = queryObj.filter;
  delete queryObj.filter;
  let url = null;

  // excluding "toolLastSeen" - related to AT (not AM), part of fields because of custom views
  let updatedFields = removeField(queryObj.fields, "toolLastSeen");
  updatedFields = removeField(updatedFields, "lastSeenBy");

  if (filter) {
    url = `unique-asset?${filter}`;
  } else {
    url = "unique-asset";
  }
  return Gateway.get(
    getAssetMgmtEndpoint(`/${url}`),
    { ...queryObj, fields: updatedFields },
    null,
    null,
    false,
  ) as unknown as IAssetsList;
};

/**
 * Returns response of upload file by calling relevant API
 * @param file :file to upload.
 * @param config :configuration for additional header
 */

export const uploadFile = async (file, config) => {
  return Gateway.post(getAssetMgmtEndpoint("/assets/unique-asset/import/basic"), null, file, null, config, false).then(
    (response) => {
      if (
        response.error &&
        response.error.response &&
        response.error.response.data &&
        response.error.response.data.errors
      ) {
        return response.error.response.data.errors[0];
      }
      return {
        importSuccess: true,
        response,
      };
    },
  );
};

/**
 * API for service closure bulk upload excel
 * @param file :file to upload.
 * @param config :configuration for additional header
 */

export const uploadServiceClosureFile = async (file, config) => {
  const response = await Gateway.post(
    getAssetMgmtEndpoint("/assets/serviceClosure/import"),
    null,
    file,
    null,
    config,
    false,
  );

  if (
    response.error &&
    response.error.response &&
    response.error.response.data &&
    response.error.response.data.errors
  ) {
    return response.error.response.data.errors[0];
  }
  return {
    importSuccess: true,
    response,
  };
};

export const getServiceImportHistory = async (): Promise<IAssetsHistory | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint("/assets/service-closure/import"),
    null,
    null,
    null,
    false,
  ) as unknown as IAssetsHistory;
};

export const getImportHistory = async (): Promise<IAssetsHistory | IErrorResponse> => {
  return Gateway.get(getAssetMgmtEndpoint("/assets/import"), null, null, null, false) as unknown as IAssetsHistory;
};

export const getImportHistoryErrors = async (historyId: number): Promise<IAssetsHistoryErrors | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/assets/import/${historyId}/error-details`),
    null,
    null,
  ) as unknown as IAssetsHistoryErrors;
};

/**
 * @description gets service import history errors
 * @memberof AssetApi
 */
export const getServiceImportHistoryErrors = async (
  historyId: number,
): Promise<IAssetsHistoryErrors | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/assets/service-closure/import/${historyId}/error-details`),
    null,
    null,
  ) as unknown as IAssetsHistoryErrors;
};
/**
 * @description gets assets import history status
 * @memberof AssetApi
 */
export const getImportHistoryStatus = async (historyId: number): Promise<IAssetsHistoryErrors | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/assets/import/${historyId}/status`),
    null,
    null,
    null,
    false,
  ) as unknown as IAssetsHistoryErrors;
};

/**
 * @description gets assets import history status
 */
export const getServiceClosureImportHistoryStatus = async (
  importId: number,
): Promise<IAssetsHistoryErrors | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/assets/service-closure/import/${importId}/status`),
    null,
    null,
    null,
    false,
  ) as unknown as IAssetsHistoryErrors;
};

/**
 * Returns all assets list in byte array format
 */
export const downloadAssetsList = async (queryObj): Promise<any> => {
  const filter = queryObj.filter;
  delete queryObj.filter;
  let queryUrl = null;
  if (filter) {
    queryUrl = `unique-asset/export?${filter}`;
  } else {
    queryUrl = "unique-asset/export";
  }
  const url = getAssetMgmtEndpoint(`/${queryUrl}`);
  return Gateway.get(url, { ...queryObj }, null, "blob");
};

/**
 * Returns list of Assets by calling relevant API.
 * @param assetId: assetId to get the asset details.
 */
export const getAssetDetails = async (assetId, loader = false): Promise<any> => {
  return Gateway.get(getAssetMgmtEndpoint(`/unique-asset/${assetId}`), null, null, null, loader) as unknown;
};

/**
 * Returns list of Assets by calling relevant API
 * @param assetIds: array of the ids to be deleted.
 */
export const deleteAsset = async (payload): Promise<any> => {
  return Gateway.delete(getAssetMgmtEndpoint("/unique-asset"), null, null, null, payload) as unknown;
};

export const getRecentlyUsedTemplates = () => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/template/recent");
    return Gateway.get(url, null, null);
  } catch (error) {
    return { error };
  }
};

export const createUniqueAsset = async (assetData): Promise<any> => {
  try {
    assetData = { ...assetData, categoryCode: "UNQAST" };
    delete assetData.chargeSetting;
    const url = getAssetMgmtEndpoint("/unique-asset");
    return Gateway.post(url, null, assetData, null);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};
/**
 * updateUniqueAssetStatus used to update the status of asset
 * @param assetIds list of assetId
 * @param assetStatus status that need to update
 */
export const updateUniqueAssetStatus = async (payload): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/asset-status");
    return Gateway.put(url, null, payload, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const updateUniqueAsset = async (assetId, assetData): Promise<any> => {
  try {
    assetData = { ...assetData, categoryCode: "UNQAST" };
    delete assetData.chargeSetting;
    if (assetData.lastTransferDate) {
      delete assetData.lastTransferDate;
    }
    const url = getAssetMgmtEndpoint(`/unique-asset/${assetId}`);
    return Gateway.put(url, null, assetData, null);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getManufacturers = async (params?): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/manufacturers");
    return Gateway.get(url, params, null, null, false);
  } catch (error) {
    return { error };
  }
};

export const getAssetGroups = async (): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/group/child");
    return Gateway.get(url, null, null);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

/**
 * @description - Use to get location on behalf of locationtype to fullfill dropdown
 *
 * @param locationType - Location type like WAREHOUSE;JOBSITE
 */
export const getAssetLocations = async (queryParams) => {
  try {
    const params = {
      fields: queryParams.fields,
      limit: queryParams.limit,
      offset: queryParams.offset,
      order_by: queryParams.orderBy,
      q: queryParams.q,
      searchFields: queryParams.searchFields,
    };
    const url = getOrgMgmtEndpoint(`/locations?${queryParams.filterQuery}`);
    return Gateway.get(url, params, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getWorkersData = async (responsibilityType): Promise<any> => {
  try {
    const params = {
      filter: `responsibilityType:in=${responsibilityType}`,
      order_by: "+firstName",
      view: "DropDown",
    };
    const url = getUserMgmtEndpoint("/workers?");
    return Gateway.get(url, params, null);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getPDPTemplate = async (modelVal): Promise<any> => {
  const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/catalogs/1/product-templates/${modelVal}`);
  return Gateway.get(url, null, null);
};

export const getManufacturerAndModel = async (manufacturerId, model): Promise<any> => {
  try {
    const params = {
      manufacturerId,
      model,
    };
    const url = getAssetMgmtEndpoint("/unique-asset/template/manufacturer-model");
    return Gateway.get(url, params, null);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getCommonAttributes = async (payload): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint("/unique-asset/common/attributes");
    return Gateway.post(url, null, payload, null);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in Asset grid
 * @param filters : used to apply filters
 */
export const getAssetFilterAttributes = async (filter?: string): Promise<any> => {
  let url = null;
  if (filter) {
    url = getAssetMgmtEndpoint(`/unique-asset/attributes?${filter}`);
  } else {
    url = getAssetMgmtEndpoint("/unique-asset/attributes");
  }
  return Gateway.get(url, null, null, null, false);
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in Asset grid
 * @param filters : used to apply filters
 */
export const saveMultiEditAsset = async (model?: object): Promise<any> => {
  const url = getAssetMgmtEndpoint("/unique-asset/");
  return Gateway.patch(url, null, model, null);
};

/**
 * Returns list of searched transfer history by calling relevant API
 * @param page : page size of the list.
 * @param size : size of the page.
 */

export const fetchTransferHistorySearchList = async (queryObj): Promise<any> => {
  const url = getAssetMgmtEndpoint("/assets/transfers/search");
  return Gateway.get(url, { ...queryObj }, null) as unknown as ITransferHistorySearchList;
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in Asset grid
 * @param filters : used to apply filters
 */
export const submitTransfer = async (model?: object, hasConsent?: boolean): Promise<any> => {
  const url = getAssetMgmtEndpoint(`/assets/transfers?transferOrigin=WEB${hasConsent ? "&confirmDuplicate=true" : ""}`);
  return Gateway.post(url, null, model, null);
};

/**
 * Method to Make API call for submit cancletransfer
 * their values in Asset grid
 * @param filters : used to apply filters
 */
export const cancleLastTransfer = (selectedAssetDetail): Promise<any> => {
  const url = getAssetMgmtEndpoint(
    `/assets/${selectedAssetDetail.payload.assetId}/transfers/${selectedAssetDetail.payload.transferId}/cancel?transferOrigin=WEB `,
  );
  return Gateway.put(url, null, selectedAssetDetail.formData, null);
};

/**
 * Method to Make API call for retreiving transfer histories.
 */
export const fetchTransferHistoriesList = (offset, loader = true) => {
  try {
    const url = getAssetMgmtEndpoint(`/assets/transfers?limit=100&offset=${offset}`);
    return Gateway.get(url, null, null, null, loader) as unknown as ITransferHistoryAsset;
  } catch (error) {
    return { error };
  }
};

/**
 * Method to Make API call for retreiving transfer history.
 */
export const fetchTransferHistoryList = (id) => {
  try {
    const url = getAssetMgmtEndpoint(`/assets/transfers/${id}`);
    return Gateway.get(url, null, null) as unknown as ITransferHistoryList;
  } catch (error) {
    return { error };
  }
};

export const getTransferDate = async (ids): Promise<IGetTransferDate> => {
  const params = {
    assetIds: ids,
  };
  return Gateway.get(
    getAssetMgmtEndpoint("/assets/transfers/transferDate"),
    params,
    null,
  ) as unknown as IGetTransferDate;
};

/**
 * Returns all tranfer history list in byte array format
 */
export const downloadTransferHistoryList = async (queryObj: { q: string }) => {
  try {
    const searchString = queryObj.q;
    const queryUrl = "/assets/transfers/export" + (searchString ? `?q=${searchString}` : "");
    const url = getAssetMgmtEndpoint(queryUrl);
    return Gateway.get<Blob>(url, null, null, "blob");
  } catch (error) {
    return { error };
  }
};

/**
 * @description To create custom view for asset filter and sort.
 */
export const createAssetCustomView = async (customViewData): Promise<any> => {
  return Gateway.post(getAssetMgmtEndpoint("/views?entityName=Asset"), null, customViewData, null);
};

/**
 * @description To get custom view for asset filter and sort.
 */
export const getAssetCustomViewFields = async (): Promise<ICustomViewListModel | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint("/views?entityName=Asset"),
    null,
    null,
    null,
    null,
  ) as unknown as ICustomViewListModel;
};

/**
 * @description To update custom view for asset filter and sort.
 */
export const updateAssetCustomViewApi = async (customViewId, customViewData): Promise<any> => {
  return Gateway.put(getAssetMgmtEndpoint(`/views/${customViewId}`), null, customViewData, null);
};

/**
 * @description To update custom grid fields
 */
export const updateCustomGridFieldsApi = async (customViewId, listOfFields): Promise<any> => {
  return Gateway.patch(
    getAssetMgmtEndpoint(`/views/${customViewId}/update-fields/?entityName=Asset`),
    null,
    listOfFields,
    null,
  );
};

/**
 * @description To delete custom view for asset.
 * @param {customViewId} Id
 */
export const deleteCustomView = async (customViewId): Promise<any> => {
  return Gateway.delete(getAssetMgmtEndpoint(`/views/${customViewId}?entityName=asset`), null, null);
};

/**
 * @description To rename custom view.
 * @param {number} customViewId - Custom view id.
 * @param {string} customViewName - Custom view name.
 */

export const renameCustomViewApi = async (customViewId: number, customViewName: string): Promise<any> => {
  return Gateway.patch(
    getAssetMgmtEndpoint(`/views/${customViewId}/rename/?entityName=Asset`),
    null,
    customViewName,
    null,
  );
};

/**
 * @description To set default custom view.
 * @param {number} customViewId - Custom view id.
 * @param {boolean} defaultView - Flag to set defaultView.
 */
export const setDefaultCustomViewApi = async (customViewId: number, defaultView: any): Promise<any> => {
  return Gateway.patch(
    getAssetMgmtEndpoint(`/views/${customViewId}/setDefault/?entityName=Asset`),
    null,
    defaultView,
    null,
  );
};

/**
 * @description To set  service with existing asset.
 * @param {number} assetId - Asset  id.
 * @param {boolean} historyServiceData -  Service data
 */
export const assignHistoryServiceApi = async (historyServiceData: any, assetId: number): Promise<any> => {
  return Gateway.patch(
    getAssetMgmtEndpoint(`/unique-asset/${assetId}/service-association`),
    null,
    historyServiceData,
    null,
  );
};

export type AssignServiceApi = {
  limit: number;
  offset: number;
  totalRecords: number;
  response: IAssignedServiceList[];
};

export const getAssignServiceApi = async (
  id: number,
  queryParams: any,
  moduleType: any = "assetId",
  loader = false,
) => {
  const assetId = id && `${moduleType}=${id}`;
  const params = {
    fields: queryParams.fields,
    limit: queryParams.limit,
    offset: queryParams.offset,
    order_by: queryParams.orderBy,
    q: queryParams.q,
    searchFields: queryParams.searchFields,
  };
  return Gateway.get<AssignServiceApi>(getAssetMgmtEndpoint(`/services?${assetId}`), params, null, null, loader);
};

export const getAssignServiceDetailOperation = async (
  assetId: number,
  serviceId,
  serviceStatus,
  loader: true,
): Promise<any> => {
  if (SingletonFeatureManager.isFeatureEnabled(FEATURES.ASSET_SERVICES)) {
    return Gateway.get(
      getAssetOrchMgmtEndpoint(`/assets/${assetId}/service/${serviceId}?serviceStatus=${serviceStatus}`),
      null,
      null,
      null,
      loader,
    );
  } else {
    return Gateway.get(
      getAssetMgmtEndpoint(`/assets/${assetId}/service/${serviceId}?serviceStatus=${serviceStatus}`),
      null,
      null,
      null,
      loader,
    );
  }
};
/**
 * @description To set getServicesAssignList.
 * @param {number} assetId - asset id.
 */
export const getServicesAssignList = async (
  queryObj,
  assetId: number,
  serviceStatus: string,
  loader = true,
): Promise<any> => {
  const filter = queryObj.filter;
  delete queryObj.filter;
  let url = null;
  if (filter) {
    url = serviceStatus === ServiceStatus.OPEN ? `open-services?${filter}` : `history-services?${filter}`;
  } else {
    url = serviceStatus === ServiceStatus.OPEN ? "open-services" : "history-services";
  }

  return Gateway.get(getAssetMgmtEndpoint(`/assets/${assetId}/${url}`), { ...queryObj }, null, null, loader);
};

/**
 * Method to Make API call for retreiving values for filter attributes &
 * their values in service grid
 * @param filters : used to apply filters
 */
export const getServiceFilterAttributes = async (currentServiceType, filters, assetId): Promise<any> => {
  if (filters) {
    return Gateway.get(getAssetMgmtEndpoint(`/assets/${assetId}/services/attributes?${filters}`), {
      serviceStatus: currentServiceType,
    });
  } else {
    return Gateway.get(getAssetMgmtEndpoint(`/assets/${assetId}/services/attributes`), {
      serviceStatus: currentServiceType,
    });
  }
};
/*
 * @description To complete Assigned Service.
 * @param {number} assetId - Asset id.
 * @param {boolean} serviceData - serviceData
 */
export const completeAssignedServiceOperation = async (assetId: number, serviceData: any): Promise<any> => {
  return Gateway.put(
    getAssetMgmtEndpoint(`/assets/${assetId}/service/${serviceData.id}/complete`),
    null,
    serviceData,
    null,
  );
};

/**
 * @description To set getServicesAssignList.
 * @param {number} assetId - asset id.
 */
export const getProviderList = async (queryObj, loader = true): Promise<any> => {
  delete queryObj.filter;
  const url = "services/provider";
  return Gateway.get(getAssetMgmtEndpoint(`/${url}`), { ...queryObj }, null, null, loader);
};

/**
 * @description To complete Assigned Service.
 * @param {number} assetId - Asset id.
 * @param {boolean} serviceData - serviceData
 */
export const editAssignedServiceOperation = async (
  assetId: number,
  serviceData: any,
  serviceId: string,
  serviceStatus: string,
  isRecurrentDateChange = false,
): Promise<any> => {
  const compleationDataFlag =
    serviceData.basedOn === AssetServiceCategory.PERIOD ? "completionDateFlag" : "completionAtFlag";
  const query =
    serviceStatus === ServiceStatus.OPEN ? "open" : `history?${compleationDataFlag}=${isRecurrentDateChange}`;
  return Gateway.put(getAssetMgmtEndpoint(`/assets/${assetId}/service/${serviceId}/${query}`), null, serviceData, null);
};
/*
 * @description Function to remove service from asset.
 * @param number assetId - Asset id.
 * @param number serviceId - Service id.
 * @param string serviceType - Service type.
 */
export const removeServiceApi = async (assetId: number, serviceId: number, serviceType: string): Promise<any> => {
  const params = {
    serviceId,
    serviceType,
  };
  return Gateway.delete(getAssetMgmtEndpoint(`/assets/${assetId}/service`), params, null) as unknown;
};

/*
 * @description Function to fetch all child assets of given asset.
 * @param number assetId - Asset id.
 */
export const fetchChildAsset = async (query: IFetchAssetQuery, loader = false): Promise<any> => {
  // excluding "toolLastSeen" - related to AT (not AM), part of fields because of custom views
  let updatedFields = removeField(query.fields, "toolLastSeen");
  updatedFields = removeField(updatedFields, "lastSeenBy");
  return Gateway.get(
    getAssetMgmtEndpoint(`/${"unique-asset"}`),
    { ...query, fields: updatedFields },
    null,
    null,
    loader,
  ) as unknown as IAssetsList;
};

export const receiveNotificationsApi = async (action): Promise<IReceiveNotificationModal | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/services-template/app-setting/${action.alertId}`),
    null,
    null,
    null,
  ) as unknown as IReceiveNotificationModal;
};

export const putInAppSettingsApi = async (
  alertId,
  model: object,
): Promise<IReceiveNotificationModal | IErrorResponse> => {
  return Gateway.patch(
    getAssetMgmtEndpoint(`/services-template/${alertId}`),
    null,
    model,
    null,
  ) as unknown as IReceiveNotificationModal;
};

export const toolReturnAlertSettingApi = async (toolReturnName): Promise<IToolReturnSetting | IErrorResponse> => {
  return Gateway.get(
    getAssetMgmtEndpoint(`/unique-asset/app-setting?alertType=${toolReturnName}`),
    null,
    null,
    null,
  ) as unknown as IToolReturnSetting;
};

export const putToolReturnAlertSettingsApi = async (
  toolReturnName,
  model: object,
): Promise<IReceiveNotificationModal | IErrorResponse> => {
  return Gateway.put(
    getAssetMgmtEndpoint(`/unique-asset/app-setting?alertType=${toolReturnName}`),
    null,
    model,
    null,
  ) as unknown as IReceiveNotificationModal;
};

export const getWorkerCertificateApi = async (action): Promise<IWorkerCertificate | IErrorResponse> => {
  const url = getUserMgmtEndpoint(`/worker-certificate-templates/${action.alertId}`);
  return Gateway.get(url, null, null, null) as unknown as IWorkerCertificate;
};

export const setWorkerCertificateApi = async (id, model: object): Promise<IWorkerCertificate | IErrorResponse> => {
  return Gateway.patch(
    getUserMgmtEndpoint(`/worker-certificate-templates-notification/${id}`),
    null,
    model,
    null,
  ) as unknown as IWorkerCertificate;
};

export const getAssetGroup = async (queryParams): Promise<any> => {
  const params = {
    fields: queryParams.fields,
    limit: queryParams.limit,
    offset: queryParams.offset,
    order_by: queryParams.orderBy,
    q: queryParams.q,
    searchFields: queryParams.searchFields,
  };
  try {
    const url = getAssetMgmtEndpoint("/group/child");
    return Gateway.get(url, params, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getWorkersDataWithPagination = async (queryParams): Promise<any> => {
  try {
    const params = {
      limit: queryParams.limit,
      offset: queryParams.offset,
      order_by: queryParams.orderBy,
      q: queryParams.q,
      view: queryParams.view,
    };
    const url = getUserMgmtEndpoint(`/workers?${queryParams.filterQuery}`);
    return Gateway.get(url, params, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

/**
 * @description : draws the list of labels.
 * @queryObj : its use to provide query related to sorting and ordering
 */
export const getLabels = async (queryObj): Promise<IGetLabelResponse | IErrorResponse> => {
  const url = getAssetMgmtEndpoint(
    `/labels?limit=${queryObj.limit}&offset=${queryObj.offset}&q=${queryObj.q}&${queryObj.filter}`,
  );
  return Gateway.get(url, null, null, null, false) as unknown as IGetLabelResponse;
};

/**
 * @description : associates a  label to a specific asset.
 */
export const associateLabel = async (data): Promise<ILabelAssociationReponse | IErrorResponse> => {
  const url = getAssetMgmtEndpoint("/labels/association");
  return Gateway.put(url, null, data, null) as unknown as any;
};

/**
 * @description : associated labels details to a specific asset.
 */
export const associateLabelDetail = async (data): Promise<any> => {
  const url = getAssetMgmtEndpoint("/labels/common-labels");
  return Gateway.post(url, null, data, null, true) as unknown as any;
};

/**
 * @description : Get Asset Details with the Identification mechanism type scan code
 *    or inventory number.
 */
export const getAssetWithScanCodeOrInventory = async (scanCodeOrInventory): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint(
      `/unique-asset/identification-mechanism?identificationMechanismType=${scanCodeOrInventory}`,
    );
    return Gateway.get(url, {}, null, null, true);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};

export const getRenewalReturnAssetsCount = async (): Promise<any> =>
  Gateway.get(getAssetMgmtEndpoint("/fleet/expiring-count"), {}, null, null, false);

/**
 * Method to Make API call for retreiving asset and qty Count
 *
 * @param queryObj : contains entity and numbers array
 */
export const fetchAssetQtyCountApi = async (queryObj: { entity: string; ids: number[]; moduleName: string }) => {
  try {
    const url = getAssetMgmtEndpoint(`/count/${queryObj.moduleName}?entity=${queryObj.entity}`);
    return Gateway.post(url, null, { ids: queryObj.ids }, null);
  } catch (error) {
    return { error };
  }
};

/**
 * Returns asset utilzation summary by calling relevant API.
 * @param assetId: assetId to get the asset ustilization summary.
 */
export const getAssetUtilizationSummary = async (
  assetId: number,
  queryObj: { duration?: number; startDate?: string; endDate?: string },
): Promise<IUtilizationSummaryResponse | IErrorResponse> => {
  return Gateway.get(
    getTrackUnitEndpoint(`/asset/${assetId}/utilizationSummary`),
    { ...queryObj },
    null,
    null,
    false,
  ) as unknown as IUtilizationSummaryResponse;
};

/**
 * Returns asset utilzation details by calling relevant API and responsible for creating bar chart.
 * @param assetId: assetId to get the asset ustilization details.
 */
export const getAssetUtilizationDetails = async (
  assetId: number,
  queryObj: { duration?: number; startDate?: string; endDate?: string },
): Promise<IUtilizationDetailsResponse | IErrorResponse> => {
  return Gateway.get(
    getTrackUnitEndpoint(`/asset/${assetId}/utilization`),
    { ...queryObj },
    null,
    null,
    false,
  ) as unknown as IUtilizationDetailsResponse;
};

export const submitExtendReturnDate = async (payload: ISubmitExtendReturnDate) => {
  const { assetId, transferId, returnDate, reasonForChange } = payload;
  const url = getAssetMgmtEndpoint(`/assets/${assetId}/transfers/${transferId}/extendReturnDate`);
  return Gateway.patch(
    url,
    null,
    {
      returnDate,
      reasonForChange,
    },
    null,
  );
};
