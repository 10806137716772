// @ts-ignore
import { ServiceTemplateActions } from "custom-actions";

/**
 * @description Function to fetch the Service Templates list with given offset
 */
export const fetchServiceTemplatesList = (queryParams: { limit?: number; offset: number }) => ({
  ...queryParams,
  type: ServiceTemplateActions.AM_SERVICE_TEMPLATE_GET_LIST,
});

/**
 * @description Function to dispatch the method to reset query params to default
 */
export const resetQueryObjToDefault = () => ({
  type: ServiceTemplateActions.AM_SERVICE_TEMPLATE_RESET_QUERY_PARAM,
});

/**
 * @description Function to dispatch the method to sort with parameter sortQuery
 * that gives two things to sort on which parameter and ASCENDING|DESCENDING order
 * @param sortQuery - It gives two things to sort on which parameter and ASCENDING|DESCENDING order
 */
export const setSortQuery = (sortQuery) => ({
  sortQuery,
  type: ServiceTemplateActions.AM_SET_SERVICE_TEMPLATE_SORT_QUERY,
});

/**
 * @description Function to  get the filter attributes of selected certificate templates
 *
 * @param {object} filters
 */
export const getServiceTemplateFilterAttributes = () => ({
  type: ServiceTemplateActions.GET_SERVICE_TEMPLATE_FILTER_ATTRIBUTES,
});

export const setAppliedAttributes = (filterQuery) => ({
  payload: {
    filterQuery,
  },
  type: ServiceTemplateActions.AN_SET_SERVICE_TEMPLATE_FILTER_APPLIED_FIELD,
});

/**
 * @description Function to set filter query
 *
 * @param {object} filterQuery
 */
export const setFilterQuery = (filterQuery) => ({
  currentSelectedFilter: filterQuery.currentSelectedFilter,
  filterQuery: filterQuery.filters,
  type: ServiceTemplateActions.SET_SERVICE_TEMPLATE_FILTER_QUERY,
});

/**
 * @description Function to dispatch the method to set search value in the store
 * @param {string} searchString - takes entered search string.
 */
export const setSearchQuery = (searchQuery) => ({
  searchQuery,
  type: ServiceTemplateActions.AM_SET_SERVICE_TEMPLATE_SEARCH_QUERY,
});

/**
 * @description Function to create the Service Template
 */
export const createServiceTemplate = (addServiceTemplateData) => ({
  addServiceTemplateData,
  type: ServiceTemplateActions.AM_ADD_SERVICE_TEMPLATE,
});

/**
 * @description Function to edit the Service Template
 */
export const updateServiceTemplate = (updateServiceTemplateData, serviceTemplateId) => ({
  serviceTemplateId,
  type: ServiceTemplateActions.AM_EDIT_SERVICE_TEMPLATE,
  updateServiceTemplateData,
});

/**
 * @description Function to get the service template code
 */
export const getServiceTemplateCodes = () => ({
  type: ServiceTemplateActions.AM_GET_SERVICE_TEMPLATE_CODES,
});

/**
 * @description Function to delete service template.
 * @param number id - Template id.
 */
export const deleteServiceTemplate = (id: number) => ({
  id,
  type: ServiceTemplateActions.AM_DELETE_SERVICE_TEMPLATE,
});

/**
 * @description Function to get service template details.
 * @param number id - Template id.
 */
export const getServiceTemplateDetails = (id: number, module = null) => ({
  id,
  module,
  type: ServiceTemplateActions.AM_GET_SERVICE_TEMPLATE_DETAILS,
});

/**
 * @description Function to clear service template details.
 */
export const clearServiceTemplateDetails = () => ({
  type: ServiceTemplateActions.CLEAR_SERVICE_TEMPLATE_DETAILS,
});
