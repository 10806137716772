import React from "react";
const SVG = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2010.000000, -1058.000000)" fill="#524F53">
        <g transform="translate(2008.000000, 1056.000000)">
          <path
            d="M16.6177513,14.496431 L21.4852814,19.363961 L19.363961,21.4852814 L14.496431,16.6177513 C13.2150179,17.4900987 11.6670559,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C18,11.6670559 17.4900987,13.2150179 16.6177513,14.496431 Z M10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
