
import React from 'react';
import MenuItem from "@mui/material/MenuItem";
import type { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import type { MenuProps } from "@mui/material/Menu";

import { StyledSelect } from "./DropDown.styled";


type Props = {
  list: {code: string, value: string}[];
  menuProps: Partial<MenuProps>;
  onChange: (event: SelectChangeEvent<string>) => void;
  value: string;
};

export type DropDownProps = Props & Pick<SelectProps, 'label' | 'MenuProps'>;

const DropDown = ({ list, onChange, value, menuProps }: DropDownProps) => {
  return (
    <StyledSelect
      data-testid="select"
      value={value}
      onChange={onChange}
      inputProps={{ "aria-label": "Without label" }}
      MenuProps={menuProps}
    >
      {list?.map((item) => (
        <MenuItem key={item.code} value={item.code}>
          {item.value}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
export default DropDown;
