import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M12,2 L18,8 L18.0003581,8.25212715 C17.3610104,8.08753869 16.6907281,8 16,8 C15.3092719,8 14.6389896,8.08753869 13.9996419,8.25212715 L14,7 L5,7 L5,9 L12.1231128,9.00048842 C11.2090802,9.5078351 10.4048631,10.1890213 9.75511684,10.9993916 L5,11 L5,13 L8.58190884,12.9990323 C8.32651536,13.6297228 8.14921906,14.3003914 8.06201291,14.9990449 L5,15 L5,17 L8.06188768,16.9999513 C8.30932759,18.9843601 9.28355963,20.7430635 10.709804,22.001282 L2,22 L2.01,2 L12,2 Z M16,10 C19.3137085,10 22,12.6862915 22,16 C22,19.3137085 19.3137085,22 16,22 C12.6862915,22 10,19.3137085 10,16 C10,12.6862915 12.6862915,10 16,10 Z M16,12 C13.790861,12 12,13.790861 12,16 C12,18.209139 13.790861,20 16,20 C18.209139,20 20,18.209139 20,16 C20,13.790861 18.209139,12 16,12 Z M16.4254963,14 L18.6180556,16.1875 L16.4254963,18.3973327 L15.7380314,17.7673611 L16.812,16.673 L14,16.6736111 L14,15.7013889 L16.751,15.701 L15.7380314,14.6874649 L16.4254963,14 Z" />
    </g>
  </svg>
);

export default SVG;
