import Gateway from "../../common/gateway/gateway";
import { getFeatureFlagsEndpoint } from "../utils/apiUtils";

export const getfeatureFlagsData = async (product, employeeId): Promise<any> => {
  return Gateway.get(
    getFeatureFlagsEndpoint(`/products/${product}/feature-flags?userId=${employeeId}`),
    null,
    null,
    null,
    false,
  ) as unknown as any;
};
