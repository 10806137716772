import ControlType from "../../../../../components/filter/controlType";
import FilterOperator from "../../../../../components/filter/filterOperator";
import { IGridFilter } from "../../../../../components/filter/iGridFilter";

/**
 * defines all possible filters in the Asset List
 */
const AssetsViewGridFilters: IGridFilter[] = [
  {
    filterDisplayName: "filters:MANUFACTURER_NAME",
    filterName: "manufacturerName",
    operator: FilterOperator.IN,
    type: {
      moreFilter: ControlType.CheckboxGroup,
      searchBar: ControlType.MultiSelectDropdown,
    },
  },
  {
    filterDisplayName: "filters:MODEL",
    filterName: "model",
    operator: FilterOperator.IN,
    type: {
      moreFilter: ControlType.CheckboxGroup,
      searchBar: ControlType.MultiSelectDropdown,
    },
  },
  {
    filterDisplayName: "filters:INVENTORY_TYPE",
    isUnclearable: true,
    filterName: "inventoryName",
    operator: FilterOperator.EQ,
    type: {
      moreFilter: ControlType.RadioGroup,
      searchBar: ControlType.RadioSelectDropdown,
    },
    useDefault: false,
  },
];

export default AssetsViewGridFilters;
