import React from "react";
import styled from "styled-components";

const StyledSup = styled.sup`
  color: ${(props) => props.theme.colors.base.hiltiRedError};
  margin-left: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;

const MandatoryMark = () => {
  return <StyledSup>*</StyledSup>;
};
export default MandatoryMark;
