import { GridView } from "am-web-ui-shared/components";
import React from "react";
import AssetStatus from "../../../modules/asset/components/list/presentational/assetStatus";
import { getIconByAssetSubType } from "../../../../common/utils/assetTypeUtils";
import { getPropertyLabel } from "../../../utils/commonUtils";
import { useTranslation } from "react-i18next";

/**
 * @description - Function to return AssetsStatus component.
 * @param column - Column settings.
 * @param rowData - API response Data.
 * @param {number} rowIndex - Row index.
 * @param {number} columnIndex - Column index.
 */
export const getTooltip = (property, getPropertyLabel, t) => getPropertyLabel(property, t);

const TransferHeader = (props) => {
  const { headerGridSetting, selectedRow } = props;
  const updatedRowObj = Object.assign({}, selectedRow);
  updatedRowObj.isSelected = false;
  updatedRowObj.metaData = { ...updatedRowObj.metaData, isChecked: false };

  const getAssetsStatus = (column: any, rowData: any, rowIndex: number, columnIndex: number) => {
    return (
      <AssetStatus
        showTooltip={true}
        tooltipValue={t("common:LABEL_ASSET_STATUS")}
        column={column}
        data={rowData}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
      />
    );
  };

  const { t } = useTranslation();

  return (
    <GridView
      columns={headerGridSetting}
      dependencies={{
        getAssetsStatus,
        getAlternateDefaultImage: getIconByAssetSubType,
        getPropertyLabel: (property) => getTooltip(property, getPropertyLabel, t),
      }}
      rowsData={[updatedRowObj]}
      idKey="transferHistoryId"
      disableCheckBoxColumn={true}
      rowAttributes={{ style: { height: "79px", width: "100%", border: "none" } }}
      lazyLoadList={false}
      showTooltip={true}
    />
  );
};

export default TransferHeader;
