import React from "react";
import Map from "@am/web-ui-components/extra/maps";
import { getMapKey } from "../../utils/configUtil";
import { getGoogleLanguage } from "./languageMap";
import { useSelector } from "react-redux";
import { RootState } from "TARGET_BUILD/common/store/store";
import { useGoogleMapStore } from "TARGET_BUILD/googleMapProvider";

const GOOGLE_MAP_KEY = getMapKey();

export const getLanguage = ({ UserSetting }: RootState) => UserSetting?.userSettingData?.applyLanguageCode || "en";

export const GoogleMapWrapper = (props) => {
  const userLanguage = useSelector(getLanguage);
  const googleLanguage = getGoogleLanguage(userLanguage) || "en";
  const { setLoader } = useGoogleMapStore();

  return (
    <>
      <Map
        // callback is supported by @googlemaps/react-wrapper but it is not exposed as loader option hence ignoring the type
        // @ts-ignore
        callback={(status, loader) => status === "SUCCESS" && setLoader(loader)}
        language={googleLanguage}
        libraries={["core", "marker", "places", "drawing"]}
        apiKey={GOOGLE_MAP_KEY}
        version="3"
      >
        {props.children}
      </Map>
    </>
  );
};

export default GoogleMapWrapper;
