/**
 * @description Action constants for reducers of MasterData
 *
 * format: {feature}_{action}
 */
const MasterActionsConstants = {
  ALLOCATION_STATUS_LIST_SUCCESS: "ALLOCATION_STATUS_LIST_SUCCESS",
  AM_ASSET_CODES_SUCCESS: "AM_ASSET_CODES_SUCCESS",
  AM_ASSET_USAGE_CONDITION_SUCCESS: "AM_ASSET_USAGE_CONDITION_SUCCESS",
  AM_CURRENCIES_SUCCESS: "AM_CURRENCIES_SUCCESS",
  AM_SCAN_CODE_TYPE_SUCCESS: "AM_SCAN_CODE_TYPE_SUCCESS",
  AM_GET_SERVICE_RESULTS_LIST: "AM_GET_SERVICE_RESULTS_LIST",
  GET_ALLOCATION_STATUS_LIST: "GET_ALLOCATION_STATUS_LIST",
  GET_ALLOCATION_STOCK_LEVEL: "GET_ALLOCATION_STOCK_LEVEL",
  AM_GET_SERVICE_STATUS_LIST: "AM_GET_SERVICE_STATUS_LIST",
  AM_GET_CERTIFICATE_STATUS_LIST: "AM_GET_CERTIFICATE_STATUS_LIST",
  CERTIFICATE_STATUS_LIST_SUCCESS: "CERTIFICATE_STATUS_LIST_SUCCESS",
  ALLOCATION_STOCK_LEVEL_SUCCESS: "ALLOCATION_STOCK_LEVEL_SUCCESS",
  SERVICE_STATUS_LIST_SUCCESS: "SERVICE_STATUS_LIST_SUCCESS",
  SERVICE_RESULTS_LIST_SUCCESS: "SERVICE_RESULTS_LIST_SUCCESS",
  GET_ASSET_CODE_LIST: "GET_ASSET_CODE_LIST",
  GET_ASSET_USAGE_CONDITION_LIST: "GET_ASSET_USAGE_CONDITION_LIST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_CURRENCIES_LIST: "GET_CURRENCIES_LIST",
  GET_LANGUAGE_LIST: "GET_LANGUAGE_LIST",
  GET_LOCATION_STATES: "GET_LOCATION_STATES", // action to get location states list
  GET_REPORT_TEMPLATES_CATEGORIES: "GET_REPORT_TEMPLATES_CATEGORIES",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_SCAN_CODE_TYPE_LIST: "GET_SCAN_CODE_TYPE_LIST",
  LOCATION_STATES_SUCCESS: "LOCATION_STATES_SUCCESS", // response action to get location states list
  OM_GET_COUNTRIES: "OM_GET_COUNTRIES",
  OM_GET_LANGUAGE_LIST: "OM_GET_LANGUAGE_LIST",
  OM_GET_ROLES_LIST: "OM_GET_ROLES_LIST",
  OM_LANGUAGE_RESPONSE: "OM_LANGUAGE_RESPONSE",
  REPORT_TEMPLATES_CATEGORIES_SUCCESS: "REPORT_TEMPLATES_CATEGORIES_SUCCESS",
  RESET_MASTER_DATA: "RESET_MASTER_DATA",
  RESET_ROLES_LIST: "RESET_ROLES_LIST",
  AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST: "AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST",
  AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST_SUCCESS: "AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST_SUCCESS",
  HI_GET_HILTI_ASSET_STATUS_LIST: "HI_GET_HILTI_ASSET_STATUS_LIST",
  HI_GET_HILTI_ASSET_STATUS_LIST_SUCCESS: "HI_GET_HILTI_ASSET_STATUS_LIST_SUCCESS",
  GET_TIME_BASED_ENABLED_TENANT_LIST: "GET_TIME_BASED_ENABLED_TENANT_LIST",
  PUT_TIME_BASED_ENABLED_TENANT_LIST: "PUT_TIME_BASED_ENABLED_TENANT_LIST",
  ACR_COST_CODE_SUCCESS: "ACR_COST_CODE_SUCCESS",
  GET_COST_CODE_LIST: "GET_COST_CODE_LIST",
  AT_UI_SHOW_ONE_MAP_BANNER: "AT_UI_SHOW_ONE_MAP_BANNER",
};

export default MasterActionsConstants;
