// @ts-ignore
import { ToastrActions } from "custom-actions";

/**
 * @description - Types of the toastr messages that can be shown to the user.
 */
export enum ToastrType {
  warning = "warning",
  error = "error",
  info = "info",
  success = "success",
}

/**
 * @description - This method is used to trigger the action to show the toastr message.
 *
 * @param type - This is the type of toastr needs to be shown.
 * @param toastrMessage - This is the message that needs to be shown on the toastr.
 */
export const showToastr = (
  type: ToastrType,
  toastrMessage,
  toastrDynamicMessageParams?,
  gtmEvent?,
  toastrActionInfo?: { name: string; clickHandler: () => void },
) => {
  const toastrParams = { toastrType: type, toastrMessage, toastrDynamicMessageParams, gtmEvent, toastrActionInfo };
  Object.keys(toastrParams).forEach((key) => {
    if (toastrParams[key] === undefined) {
      delete toastrParams[key];
    }
  });

  return {
    payload: toastrParams,
    type: ToastrActions.SHOW_TOASTR,
  };
};

/**
 * @description - This method is used to trigger the success action of the toastr.
 *
 * @param toastrMessage - This is the message that needs to be shown on the toastr.
 */
export const showSuccessToastr = (toastrMessage) => ({
  payload: { toastrType: ToastrType.success, toastrMessage },
  type: ToastrActions.SHOW_TOASTR,
});

/**
 * @description - This method is used to trigger the close action of the toastr.
 */
export const closeToastr = () => ({
  type: ToastrActions.CLOSE_TOASTR,
});

/**
 * @description - This method is used to trigger the close action of multiple toastr.
 */
export const closeMultipleToastr = () => ({
  type: ToastrActions.CLOSE_MULTIPLE_TOASTR,
});

/**
 * @description - This method is used to trigger the multiple toastr with their particular action.
 *
 * @param toastrData - This is the collection of multiple toastr type and toastr message.
 */
export const showMultipleToastr = (toastrData: Array<{ type: ToastrType; toastrMessage }>) => ({
  payload: toastrData,
  type: ToastrActions.SHOW_MULTIPLE_TOASTR,
});
