// @ts-ignore
import { APIMessages } from "custom-actions";
import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { IGetLabelResponse, ILabelAssociationReponse } from "../models/labels.model";
import LocationGroupModel from "../models/location/locationGroupModel";
import LocationListModel from "../models/location/locationListModel";
import Location from "../models/location/locationModel";
import { IArchivedLocation } from "../modules/administration/location/archived/interfaces/iArchivedLocations";
import {
  ILocationHistory,
  ILocationHistoryErrors,
} from "../modules/location/importLocation/iLocationImportHistoryProps";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";

/**
 * This class will expose methods related to Location API
 */
export default class LocationApi {
  /**
   * Returns list of locations by calling relevant API
   */
  static getLocationsList = async (
    queryObj,
    loader = false,
  ): Promise<LocationListModel | IErrorResponse | [Location]> => {
    const filter = queryObj.filter;
    delete queryObj.filter;
    let url = null;
    if (filter) {
      url = `locations?${filter}`;
    } else {
      url = "locations";
    }
    return Gateway.get(
      getOrgMgmtEndpoint(`/${url}`),
      { ...queryObj },
      null,
      null,
      loader,
    ) as unknown as LocationListModel;
  };

  static getLocationNextLevelDetails = async (
    locationId,
    query = null,
    loader = true,
  ): Promise<any | IErrorResponse> => {
    const filter = query && query.filter;
    let url = null;
    if (filter) {
      delete query.filter;
      url = `root-child-locations?${filter}`;
    } else {
      url = "root-child-locations";
    }
    return Gateway.get(
      getOrgMgmtEndpoint(`/locations/${locationId}/${url}`),
      { ...query },
      null,
      null,
      loader,
    ) as unknown as LocationListModel;
  };

  /**
   * @description Returns the detail of the clicked marker id
   *
   * @static
   * @memberof LocationApi
   */

  static getLocationById = async (locationId): Promise<any | IErrorResponse> => {
    return Gateway.get(getOrgMgmtEndpoint(`/locations/${locationId}`), null, null, null, false) as unknown as Location;
  };

  /**
   * @description Returns the list of Location Types from API
   *
   * @static
   * @memberof LocationApi
   */
  static getLocationTypesList = async (selectedRowId?): Promise<LocationListModel | IErrorResponse> => {
    let url = null;
    if (selectedRowId) {
      url = `locations/types?locationId=${selectedRowId}`;
    } else {
      url = "locations/types";
    }

    return Gateway.get(getOrgMgmtEndpoint(`/${url}`), null, null, null, false) as unknown as LocationListModel;
  };

  /**
   * @description Returns the response of Add Location from API
   *
   * @static
   * @memberof LocationApi
   * @param locationData : data of the location
   */
  static postLocation = async (locationData: object): Promise<Location | IErrorResponse> => {
    return Gateway.post(getOrgMgmtEndpoint(`/locations`), null, locationData, null) as unknown as Location;
  };

  /**
   * @description Returns the response of Edit Location from API
   *
   * @static
   * @memberof LocationApi
   * @param locationData : data of the location
   * @param id : id of the location selected
   */
  static editLocation = async (locationData: object, id: number): Promise<Location | IErrorResponse> => {
    return Gateway.put(getOrgMgmtEndpoint(`/locations/${id}`), null, locationData, null) as unknown as Location;
  };

  /**
   * @description Returns the response of  Location patch data from API
   *
   * @static
   * @memberof LocationApi
   * @param locationData : data of the location
   * @param id : id of the location selected
   */
  static locationPatchApi = async (restoreArchivedLocationData: object): Promise<any> => {
    return Gateway.patch(getOrgMgmtEndpoint(`/locations`), null, restoreArchivedLocationData, null) as unknown;
  };

  static deleteLocation = async (params): Promise<any> => {
    return Gateway.delete(getOrgMgmtEndpoint(`/locations`), null, null, true, params) as unknown;
  };

  /**
   * @description Returns list of location-groups.
   */
  static getLocationGroupsList = async (queryObj, loader = false): Promise<LocationGroupModel | IErrorResponse> => {
    const filter = queryObj && queryObj.filter;
    let url = null;
    if (filter) {
      delete queryObj.filter;
      url = `locations/root-locations?${filter}`;
    } else {
      url = "locations/root-locations";
    }
    return Gateway.get(
      getOrgMgmtEndpoint(`/${url}`),
      { ...queryObj },
      null,
      null,
      loader,
    ) as unknown as LocationGroupModel;
  };

  /**
   * @description Returns list of location-groups with back tree for given group id.
   */
  static getBackTrackLocationGroupsList = async (groupId, loader): Promise<any> => {
    return Gateway.get(
      getOrgMgmtEndpoint(`/locations/${groupId}/backtrace-till-root`),
      null,
      null,
      null,
      loader,
    ) as unknown as LocationGroupModel;
  };

  /**
   * @description Returns list of children for given location group id.
   */
  static getChildrenLocationGroups = async (
    groupId,
    query = null,
    loader = true,
  ): Promise<LocationGroupModel | IErrorResponse> => {
    return LocationApi.getLocationNextLevelDetails(groupId, query, loader);
  };
  /**
   * Method to Make API call for retreiving values for filter attributes &
   * their values in Asset grid
   * @param filters : used to apply filters
   */
  static getLocationFilterAttributes = async (filter?: string): Promise<any> => {
    let url = null;
    if (filter) {
      url = getOrgMgmtEndpoint(`/locations/attributes?${filter}`);
    } else {
      url = getOrgMgmtEndpoint(`/locations/attributes`);
    }
    return Gateway.get(url, null, null, null, false);
  };

  /**
   * Returns list of archived locations by calling relevant API
   */
  static getArchivedLocations = async (queryObj): Promise<IArchivedLocation[] | IErrorResponse> => {
    return Gateway.get(
      getOrgMgmtEndpoint(`/locations/archived`),
      { ...queryObj },
      null,
    ) as unknown as IArchivedLocation[];
  };

  /**
   * downloads the file data in byte array format
   * @param fileType : file type of the template.
   */
  static downloadImportExcelTemplate = async (): Promise<any> => {
    const url = getOrgMgmtEndpoint(`/locations/download-template/`);
    return Gateway.get(url, null, null, "blob");
  };

  /**
   * uploads the file
   * @param file : file type of the template.
   * @param config :configuration for additional header
   */

  static uploadFile = async (file, config) => {
    return Gateway.post(getOrgMgmtEndpoint(`/locations/import`), null, file, null, config).then((response) => {
      if (
        response.error &&
        response.error.response &&
        response.error.response.data &&
        response.error.response.data.errors
      ) {
        return response.error.response.data.errors[0];
      }
      return {
        importSuccess: true,
        response,
      };
    });
  };
  /**
   * Returns all assets list in locatins in byte array format
   */
  static downloadLocationList = async (queryObj): Promise<any> => {
    if (queryObj) {
      const filter = queryObj.filter;
      delete queryObj.filter;
      let url = null;
      if (filter) {
        url = `locations/export?${filter}`;
      } else {
        url = "locations/export";
      }
      return Gateway.get(getOrgMgmtEndpoint(`/${url}`), { ...queryObj }, null, "blob");
    } else {
      return null;
    }
  };

  /**
   * API call to get import file history
   */
  static getLocationImportHistory = async (): Promise<ILocationHistory | IErrorResponse> => {
    return Gateway.get(getOrgMgmtEndpoint(`/locations/import`), null, null, null, false) as unknown as ILocationHistory;
  };

  static getStatusById = async (historyId: number): Promise<ILocationHistoryErrors | IErrorResponse> => {
    return Gateway.get(
      getOrgMgmtEndpoint(`/locations/import/${historyId}/status`),
      null,
      null,
      null,
      false,
    ) as unknown as ILocationHistoryErrors;
  };

  static getImportLocationHistoryErrors = async (
    historyId: number,
  ): Promise<ILocationHistoryErrors | IErrorResponse> => {
    return Gateway.get(
      getOrgMgmtEndpoint(`/locations/import/${historyId}/error-details`),
      null,
      null,
    ) as unknown as ILocationHistoryErrors;
  };

  static getLabels = async (searchQuery?: any): Promise<IGetLabelResponse | IErrorResponse> => {
    const url = getOrgMgmtEndpoint(
      `/labels?limit=${searchQuery.limit}&offset=${searchQuery.offset}&q=${searchQuery.q}&${searchQuery.filter}`,
    );
    return Gateway.get(url, null, null, null, false) as unknown as IGetLabelResponse;
  };

  /**
   * @description : associates a  label to a specific asset.
   */
  static associateLabel = async (data): Promise<ILabelAssociationReponse | IErrorResponse> => {
    const url = getOrgMgmtEndpoint(`/labels/association`);
    return Gateway.put(url, null, data, null) as unknown as any;
  };
  /**
   * @description : associated labels details to a specific asset.
   */
  static associateLabelDetail = async (data): Promise<any> => {
    const url = getOrgMgmtEndpoint(`/labels/common-labels`);
    return Gateway.get(url, data, null, null, true) as unknown as any;
  };
}
export const getLocationHierarchy = async (queryParams): Promise<any> => {
  const params = {
    limit: queryParams.limit,
    offset: queryParams.offset,
    q: queryParams.q,
  };
  try {
    const url = getOrgMgmtEndpoint(`/locations/hierarchy`);
    return Gateway.get(url, params, null, null, false);
  } catch (error) {
    return { error: APIMessages.API_GENERIC_ERR_MSG };
  }
};
