import React from "react";
const SVG = () => (
  <svg width="23px" height="20px" viewBox="0 0 23 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2008.000000, -1141.000000)" fill="#524F53">
        <g transform="translate(2008.000000, 1139.000000)">
          <path
            d="M14,11.5857864 L16.9497475,14.5355339 L15.5355339,15.9497475 L12,12.4142136 L12,12 L12,7 L14,7 L14,11.5857864 Z M3.04937524,11 C3.55109967,5.94667731 7.8146519,2 13,2 C18.5228475,2 23,6.4771525 23,12 C23,17.5228475 18.5228475,22 13,22 C9.98408071,22 7.27999175,20.6648956 5.44652311,18.5534769 L7.57541768,16.4245823 C8.85905176,17.996444 10.8123293,19 13,19 C16.8659932,19 20,15.8659932 20,12 C20,8.13400675 16.8659932,5 13,5 C9.47352809,5 6.55611852,7.60770586 6.07088886,11 L9,11 L4.5,16 L2.84217094e-14,11 L3.04937524,11 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
