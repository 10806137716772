import { IconName } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { Trans } from "react-i18next";
import { CountExpansionPanel } from "../DetailsPanel";
import { IAttachmentDocument } from "./attachmentDocument";
import { DetailComponents } from "./detailComponent";
/**
 * @description - Returns attachments section.
 * @param - accepts data,but using dummy data right now, so not passing any arguments
 */
const attachmentProperty: IAttachmentDocument = {
  desciption: "description",
  displayName: "displayName",
  fileId: "attachmentId",
  fileName: "fileName",
  id: "id",
  type: "type",
  fileSource: "fileSource",
  path: "path",
};

const AttachmentContainer = styled.div`
  padding: 0 16px;
`;

export const attachments = (files, property = attachmentProperty) => {
  return files && Array.isArray(files) ? (
    <CountExpansionPanel
      panelId="attachmentsSection"
      headingId="headAttachments"
      iconUrl={IconName.Attachment}
      boldId="lblAttachments"
      boldText={<Trans>{"common:ATTACHMENTS"}</Trans>}
      countId="lblAttachmentsCount"
      countText={files.length.toString()}
      showExpandIcon={files.length > 0}
    >
      <AttachmentContainer id="attachmentContainer"> {DetailComponents(files, property)}</AttachmentContainer>
    </CountExpansionPanel>
  ) : null;
};

export default attachments;
