const importWorkerHistoryConstants = {
  CLEAR_IMPORT_WORKER_HISTORY: "CLEAR_IMPORT_WORKER_HISTORY",
  ORG_MGMT_GET_IMPORT_WORKER_HISTORY: "ORG_MGMT_GET_IMPORT_WORKER_HISTORY",
  ORG_MGMT_GET_IMPORT_WORKER_HISTORY_ERRORS: "ORG_MGMT_GET_IMPORT_WORKER_HISTORY_ERRORS",
  ORG_MGMT_GET_IMPORT_WORKER_HISTORY_ERRORS_RESPONSE: "ORG_MGMT_GET_IMPORT_WORKER_HISTORY_ERRORS_RESPONSE",
  ORG_MGMT_GET_IMPORT_WORKER_HISTORY_RESPONSE: "ORG_MGMT_GET_IMPORT_WORKER_HISTORY_RESPONSE",
  ORG_MGMT_IMPORT_WORKER_HISTORY_RESPONSE: "ORG_MGMT_IMPORT_WORKER_HISTORY_RESPONSE",
};

export default importWorkerHistoryConstants;
