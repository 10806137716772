import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { SquareIcon } from "../../../icons/iconWrapper";
import { HelperLodash } from "../../../../helpers";
import Tooltip from "@hilti/components/core/tooltip";

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 16px 0 0;
  .multi-options {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inherit;
  }
`;
const DropdownContent = styled.div`
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.base.steel};
  border: 2px solid ${(props) => props.theme.colors.base.steel};
  box-shadow: none;
  width: 159px;
  height: 47px;
`;

/**
 * Header styles for the checkbox list
 */
const HeaderText = styled.span`
  color: ${(props) => props.theme.colors.base.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
`;
export const Cross = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 8px;
  .icon {
    cursor: pointer;
  }
  svg path {
    fill: ${(props) => props.theme.colors.base.white};
  }
`;
export const ReadOnlyControl = (props) => {
  /**
   * clear seelcted records
   */
  const clearSelection = () => {
    props.input.onChange(null);
    return props.onSelectionChange && props.onSelectionChange([]);
  };
  const isMultipleOptions = props.selectedOptions.length > 1;
  return (
    <Tooltip
      placement="bottom"
      title={
        props.tooltip ? (
          isMultipleOptions ? (
            <span className="multi-options">{HelperLodash.join(props.selectedOptions, ",")} </span>
          ) : (
            props.toolTip
          )
        ) : (
          ""
        )
      }
    >
      <DropdownWrapper data-tip="" data-for={props.id} data-tip-disable={false}>
        <DropdownContent className="readonly-dropdown">
          <HeaderText>{isMultipleOptions ? props.header : props.selectedOptions[0]}</HeaderText>
          <Cross id="readOnlyFilterCross" onClick={clearSelection}>
            <SquareIcon name="Cross" className="icon small" />
          </Cross>
        </DropdownContent>
      </DropdownWrapper>
    </Tooltip>
  );
};

export class ReadOnlyFilter extends React.PureComponent<any> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={ReadOnlyControl}
        header={this.props.header}
        toolTip={this.props.toolTip}
        selectedOptions={this.props.selectedOptions}
        {...this.props}
      />
    );
  }
}

export default ReadOnlyFilter;
