import { HelperLodash } from "am-web-ui-shared/helpers";
import { ToastrType } from "../actions/toastrAction";
import ApplicationConstants from "../applicationConstants";
import ErrorHandler from "../models/errorHandler";
import { ErrorType } from "../models/errorType";
import { getArrayValuesNotInAnotherArray, setScrollOnField } from "./commonUtils";
import Delimiter from "./enums/delimiterEnum";
import { ExportError } from "../utils/enums/commonError";
import { ValidationConstants } from "./enums/validationConstants";

/**
 * @description - Localization keys.
 */
export const locales = {
  char: "common:VALIDATION_CHARACTERS",
  charAllowed: "common:VALIDATION_CHARACTERS_ALLOWED",
  countryCode: "common:VALIDATION_CHARACTERS_ALLOWED_COUNTRY_CODE",
  max: "common:VALIDATION_MAXIMUM",
  maxOf: "common:VALIDATION_MAXIMUM_OF",
  minOf: "common:VALIDATION_MIN_OF",
  pleaseEnter: "common:VALIDATION_PLEASE_ENTER",
  pleaseInter: "common:VALIDATION_PLEASE_ENTER",
  pleaseProvide: "common:VALIDATION_PLEASE_PROVIDE",
  pleaseProvideValid: "common:VALIDATION_PLEASE_PROVIDE_VALID",
  pleaseSelect: "common:VALIDATION_PLEASE_SELECT_A",
  unhandled: "common:UNHANDLED_EXCEPTION",
  vaildId: "common:VALIDATION_PLEASE_ENTER_VALID_VALUE",
};

export interface IFieldForValidation {
  dependentKeys?: string[];
  key: string;
  name: string;
  isRequired: boolean;
  regex?: RegExp;
  isValid?: boolean;
}

/**
 * @description - Internal function that gets called to validate the required fields with different messages.
 *
 * @param value - Current value of field typed by the user.
 * @param allValues - It contains all form field values.
 * @param props - Props of the redux form.
 * @param t - Translation function.
 * @param localeMsg - Localization message key.
 * @param fieldName - Localization key to translate the field name shown into the error message.
 * @param name - Name of the field.
 */
const requireValidator = (value, allValues, props, t, localeMsg, fieldName, name) => {
  return value && (!Array.isArray(value) || value.length) && allValues && props
    ? undefined
    : t(localeMsg) + (fieldName ? t(fieldName) : name);
};

export const selectRequiredAllowZero =
  (t, fieldName = null) =>
  (value, allValues, props, name) => {
    const newValue = value === 0 ? "0" : value;
    return requireValidator(newValue, allValues, props, t, locales.pleaseSelect, fieldName, name);
  };

/**
 * @description - Required field validator with the "Please enter" values.
 *
 * @param t - Translation function.
 * @param fieldName - Localization key to translate the field name shown into the error message.
 */
export const required =
  (t, fieldName = null) =>
  (value, allValues, props, name) => {
    return requireValidator(value, allValues, props, t, locales.pleaseInter, fieldName, name);
  };

export const requiredValidation =
  (t, fieldName = null) =>
  (value, allValues, props, name) => {
    return requireValidator(value, allValues, props, t, "", fieldName, name);
  };

export const requireCheckbox = (message) => (value) => {
  return value ? undefined : message;
};
/**
 * @description - Required field validator with the "Please provide" values.
 *
 * @param t - Translation function.
 * @param fieldName - Localization key to translate the field name shown into the error message.
 */
export const requiredProvide =
  (t, fieldName = null) =>
  (value, allValues, props, name) => {
    return requireValidator(value, allValues, props, t, locales.pleaseProvide, fieldName, name);
  };

export const selectRequired =
  (t, fieldName = null) =>
  (value, allValues, props, name) => {
    return requireValidator(value, allValues, props, t, locales.pleaseSelect, fieldName, name);
  };

export const selectRequiredWithMessage = (t, fieldName, localeMessage) => (value, allValues, props, name) => {
  return requireValidator(value, allValues, props, t, localeMessage, fieldName, name);
};

/**
 * @description function check Creatable dropdown is required
 * @param t - Translation function.
 * @param fieldId - creatable dropDown contain two field which field set to required param decide
 * @param fieldName - fieldname is resolved name after translator
 */
export const selectCreatableRequired =
  (t, fieldId, fieldName = null) =>
  (value, allValues, props, name) => {
    return value || (HelperLodash.get(allValues, fieldId) && props)
      ? undefined
      : t(locales.pleaseEnter) + (fieldName ? t(fieldName) : name);
  };

/**
 * @description - Required field validator with the custom message.
 *
 * @param t - Translation function.
 * @param message - Localization key of the custom message.
 */
export const customRequired = (t, message) => (value, allValues, props) => {
  return value && allValues && props ? undefined : t(message);
};

/**
 * @description - Function that validates the maximum number of allowed characters.
 *
 * @param t - Translation function.
 * @param max - Maximum length threshold value.
 */
export const maxLength = (t, max) => (value) => {
  return max > -1 && value && value.length > max ? `${t(locales.maxOf)} ${max} ${t(locales.charAllowed)}` : undefined;
};

/**
 * @description - Function that validates the maximum number of allowed characters.
 *
 * @param t - Translation function.
 * @param max - Maximum length threshold value.
 */
export const maxLengthValidation = (t, max) => (value) => {
  return max > -1 && value && value.length > max ? `${t(locales.max)} ${max} ${t(locales.char)}` : undefined;
};

/**
 * @description - Function that validates the minimum number of required characters.
 *
 * @param t - Translation function.
 * @param max - Minimum length threshold value.
 */
export const minLength = (t, min) => (value) => {
  return min > -1 && value && value.length < min ? `${t(locales.minOf)} ${min} ${t(locales.charAllowed)}` : undefined;
};

/**
 * @description  function throw an error when compare field length with specified value.
 * @param {Array} - fieldArray - Contains array of field attributes.
 */
export const maxLengthFieldSet = (t, fieldArray) => (value, allValues, props, name) => {
  const fieldName = HelperLodash.get(fieldArray, name);
  return fieldName > -1 && value && value.length > fieldName && allValues && props && name
    ? `${t(locales.maxOf)} ${fieldName} ${t(
        Object.keys(fieldArray).indexOf(name) === 0 ? locales.countryCode : locales.charAllowed,
      )}`
    : undefined;
};

/**
 * @description  function throw an error when field1 is available but field2 is not available or vice versa.
 * @param {field1} - First Field.
 * @param {field2} - Second Field.
 * @param {field1Name} - field1Name is used to show error for field1.
 * @param {field2Name} - field2Name is used to show error for field2.
 * @param {isRequired} - isRequired is used to indicate whether both fields are required or not, if required we are handling required validation also.
 */
export const validateAssociatedFields =
  (t, field1, field2, field1Name, field2Name, isRequired: any) => (_value, allValues) => {
    const val1 = HelperLodash.get(allValues, field1);
    const val2 = HelperLodash.get(allValues, field2);
    if ((val1 && val2) || (!val1 && !val2 && !isRequired)) {
      return undefined;
    } else if (!val1 && !val2 && isRequired) {
      return `${t(locales.pleaseProvideValid)} ${field1Name} & ${field2Name}`;
    } else {
      return `${t(locales.pleaseProvideValid)} ${!val1 ? field1Name : field2Name}`;
    }
  };

const validateField = (isRequired, value, regex) => {
  let isValid = false;
  if (isRequired) {
    isValid = regex ? !!value && regex.test(value) : !!value;
  } else {
    isValid = !!value && regex ? regex.test(value) : true;
  }
  return isValid;
};

const getIsRequiredForDependentField = (field, allValues) => {
  if (field.dependentKeys) {
    const dependentValues = field.dependentKeys.map((key) => {
      return !!HelperLodash.get(allValues, key);
    });
    return field.isRequired || dependentValues.includes(true);
  }
  return field.isRequired;
};

export const modifiedValidateAssociatedFields = (t, fields: IFieldForValidation[]) => (_value, allValues) => {
  const fieldsInfo = fields.map((field) => {
    const value = HelperLodash.get(allValues, field.key);
    return {
      ...field,
      isValid: validateField(getIsRequiredForDependentField(field, allValues), value, field.regex),
      value,
    };
  });

  const fieldsWithError = fieldsInfo.filter((field) => field.isValid === false);
  if (fieldsWithError.length === 0) {
    return undefined;
  }
  return `${t(locales.pleaseProvideValid)} ${t("common:COUNTRY_CODE_AND_MOBILE")}`;
};

/**
 * @description - Regex validator.
 *
 * @param regex - Regex rule.
 * @param message - Message to be shown on failing the regex match.
 */
export const regexValidation = (regex, message) => (value) => {
  return value && !regex.test(value) ? message : undefined;
};

export const regexValidationOnGivenValue = (regex, givenValue, message) => (value) => {
  let finalvalue = parseFloat(value) + givenValue;
  finalvalue = parseFloat(finalvalue).toFixed(3);
  return value && !regex.test(finalvalue) ? message : undefined;
};

/**
 * @@description function resolved error from field and return a error object
 * @param error collection of errors
 */
export const errorResolver = (error, splitWithDot) => {
  const errorCollection = Array.isArray(error) ? error : [error];
  const errorObject = new ErrorHandler();
  errorCollection.forEach((item) => {
    if (item && item.fieldName && item.fieldName !== "N/A") {
      // This is the condition for the combinational field errors e.g. "scanCode/inventoryNumber".
      let fieldName = item.fieldName.split("/").pop();
      // This is the condition for the nested field errors i.e. "ownedAssetDetails.purchasePrice".
      if (splitWithDot) {
        fieldName = fieldName ? fieldName.split(".").pop() : "";
      }

      errorObject.add({ errorType: ErrorType.SingleField, ...item, fieldName });
    } else {
      errorObject.add({ errorType: ErrorType.UnhandledException, ...item });
    }
  });
  return errorObject;
};

/**
 * @description Inject error to the field
 * @param error error collection come from APi
 * @param props props contain redux-form state
 * @param callBack callback is used to handle unExecptional error
 */
export const injectError = (error, props, t, callBack = null, splitWithDot = true) => {
  if (error.response && error.response.data) {
    const errorObject = errorResolver(error.response.data.errors, splitWithDot);
    const errorField = errorObject.getNormalizedSubmitError(
      [ErrorType.SingleField],
      props.registeredFields,
      t,
      props.values,
    );
    setTimeout(() => {
      setScrollOnField();
      props.stopSubmit(errorField);
    }, ApplicationConstants.TIMEOUT.TOUT200);

    if (callBack) {
      callBack(
        errorObject.getErrorMessage([ErrorType.UnhandledException, ErrorType.CombinationField], t, props.values),
        errorField,
      );
    }
  }
};

// @ts-ignore
export const validateNewOptions = (range, min?, selectValueMaxLength?) => (inputValue, selectValue, selectOptions) => {
  /**
   * range: given range of input
   * inputValue: The value which is being entered in the input box
   * selectValue: The selected value from the options
   * selectOptions: The filtered options
   */
  let inputExist = false;
  selectOptions.filter((ele: any) => {
    if (ele.label && ele.label.toLowerCase() === inputValue.trim().toLowerCase()) {
      inputExist = true;
      return;
    }
  });
  const condition = inputValue && inputValue.trim().length > 0 && inputValue.length <= range && !inputExist;
  if (min && selectValueMaxLength) {
    return condition && inputValue.length >= min && selectValue.length < selectValueMaxLength;
  } else {
    return condition;
  }
};

export const labelsValidateNewOptions =
  (selectValueMaxLength, max, delimiter = Delimiter.COMMA) =>
  (inputValue, selectValue, selectOptions) => {
    const inputExist =
      selectOptions.filter((ele: any) => {
        return ele.label && ele.label.toLowerCase && ele.label.toLowerCase() === inputValue.trim().toLowerCase();
      }).length > 0;

    const trimItemValue = inputValue.trim();
    const validCondition = getValidCondition(trimItemValue, inputExist, selectValue, selectValueMaxLength, inputValue);
    if (trimItemValue.includes(delimiter)) {
      const trimItemValueArray = HelperLodash.uniqBy(
        trimItemValue
          .split(delimiter)
          .map((item) => item.trim())
          .filter((item) => item.length > 0),
        HelperLodash.toLower,
      );
      return (
        validCondition &&
        !(trimItemValue.split(delimiter).filter((item) => item.length > 0 && item.length > max).length > 0) &&
        getArrayValuesNotInAnotherArray(
          trimItemValueArray,
          selectValue.map((item) => item.label),
        ).length > 0
      );
    } else {
      return validCondition && trimItemValue.length <= max;
    }
  };

const getValidCondition = (trimItemValue, inputExist, selectValue, selectValueMaxLength, inputValue) =>
  trimItemValue &&
  trimItemValue.length > 0 &&
  !inputExist &&
  selectValue.length < selectValueMaxLength &&
  !(inputValue.includes(",") && inputValue.split("").every((char) => char === inputValue[0])) &&
  !(selectValue && selectValue.filter((item) => item.label.toLowerCase() === trimItemValue.toLowerCase()).length > 0);

/**
 * @description  function throw a error when compare field not filed with value .
 * @param {String} - fieldName - current field on which validation added
 * @param {String} = comparefield use to check on which field we have to compare
 */
export const requiredCompare =
  (t, fieldName = null, CompareField) =>
  (value, allValues, props, name) => {
    return value && allValues && props
      ? undefined
      : HelperLodash.get(allValues, CompareField)
        ? undefined
        : t(locales.pleaseInter) + (fieldName ? t(fieldName) : name);
  };

/**
 * @description - Function that validates --Space is not allowed into the field.
 *
 * @param t - Translation function.
 */
export const onlySpaceNotAllowed = (t) => (value) => {
  return value && String(value).trim() !== "" ? undefined : t(locales.vaildId);
};

/**
 * @description - Function to validate if all the fields value in form is empty.
 *
 * @param t - Translation function.
 * @param message - Error message to be shown.
 */
export const anyOneFieldRequired =
  (t, message = null) =>
  (_value, allValues, props) => {
    return !HelperLodash.isObjectValueEmpty(allValues) && props ? undefined : message ? t(message) : true;
  };
/*
function to  get the max File error
*/

export const getInjectError = (error, t, count) => {
  if (error) {
    return t(error, { count });
  }
  return t("assets:MULTIPLE_FILE_ERROR");
};

/*
  If maxFileValidation error shown with dynamic value to be inserted
  in the translation, then dynamic property name should be "count"

*/
export const maxFileValidation =
  (count: number, error?) =>
  (files, file, t, existingCount = 0) => {
    return file && existingCount + files.length > count && getInjectError(error, t, count);
  };

export const fileSizeValidation = (sizeInByte: number) => (files, file, t) => {
  return file.size <= sizeInByte && files ? null : t("errorCodes:70207");
};

export const fileTypeValidation = (fileTypes: string[]) => (files, file, t) => {
  return files && fileTypes.includes(file.name.split(".").pop().toLowerCase())
    ? null
    : t("error:SELECT_ALLOWED_FILE_FORMATS");
};

/**
 * @description - Function to validate response having errors
 *
 * @param response - response from API
 */
export const validateResponse = (response) => {
  return (((((response || {}).error || {}).response || {}).data || {}).errors || [])[0];
};

/**
 * @description - Function to validate response of chargSetting having errors
 *
 * @param response - response from API
 */
export const validateChargeSettingResponse = (response) => {
  const errorData = (((response || {}).error || {}).response || {}).data || {};
  return errorData.code || errorData.error || errorData.errors;
};

/**
 * @description - Function to validate response of chargSetting having single/multiple errors
 *
 * @param response - response from API
 */
export const validateChargeSettingErrors = (response, t) => {
  const networkError = "networkError";
  const errorData = (((response || {}).error || {}).response || {}).data || {};
  const errors = errorData.code || errorData.error || errorData.errors;
  const chargeSettingsErrors = [];
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      chargeSettingsErrors.push({
        toastrMessage: t(`errorCodes:${typeof error.code !== "undefined" ? error.code : networkError}`),
        type: ToastrType.error,
      });
    });
  } else {
    chargeSettingsErrors.push({
      toastrMessage: t(`errorCodes:${typeof errors !== "undefined" ? errors : networkError}`),
      type: ToastrType.error,
    });
  }
  return chargeSettingsErrors;
};

export const validateDownloadErrors = (response, t, showToaster, pushAnalyticsData) => {
  let responseObj;
  try {
    responseObj = JSON.parse(response);
  } catch (e) {
    return showToaster(ToastrType.error, t(`errorCodes:networkError`));
  }
  if (Object.keys(responseObj).length) {
    if (responseObj.code) {
      return showToaster(ToastrType.error, t(`errorCodes:${responseObj.code}`));
    } else if (responseObj.error && responseObj.error.code) {
      pushAnalyticsData(responseObj.error.code);
      return showToaster(ToastrType.error, `errorCodes:${responseObj.error.code}`);
    } else if (responseObj.errors) {
      showToaster(ToastrType.error, `errorCodes:${ExportError[responseObj.errors[0].code]}`);
      pushAnalyticsData(responseObj.errors[0].code);
    }
  }
  return null;
};

/**
 * @description - Function to validate response of Hint having errors
 *
 * @param response - response from API
 */
export const isErrorResponse = (response) => {
  return Boolean(response?.error || response?.errors);
};

/**
 * @description - Function to validate response having errors
 *
 * @param response - response from API
 */
export function* parseDocument(response) {
  const error = yield (response.error && response.error.isCancel) || response.isSystemError || parseError(response);
  return error
    ? {
        error,
        isCancel: response.error && response.error.isCancel,
        isSystemError: response.isSystemError,
        success: false,
      }
    : { success: true, response, isCancel: false };
}

const getValidatedResponse = (response, error) => {
  return validateResponse(response) || (error && error.error) || error || (response.error && response.error.isCancel);
};

export function* parseError(response) {
  const error = response && response.error && response.error.response && response.error.response.data;
  let result = getValidatedResponse(response, error);

  if (result instanceof Blob) {
    result = yield new Response(result).text();
    const parsedResult = JSON.parse(result);
    return ((parsedResult || {}).errors || [])[0];
  }
  return result;
}

/**
 * @description - Function to validate response having errors
 *
 * @param response - response from API
 */
export const parseInvalidResponse = (response) => {
  return (
    response &&
    response.invalidResponse &&
    Array.isArray(response.invalidResponse) &&
    response.invalidResponse.length > 0 &&
    response.invalidResponse[0] &&
    response.invalidResponse[0].errors &&
    response.invalidResponse[0].errors.length > 0 &&
    response.invalidResponse[0].errors[0]
  );
};

/**
 * @description - Function to validate response having range
 * @param response - response from API
 */
export const rangeValidator = (minValue: number, maxValue: number, message: string) => (value) => {
  return value < minValue || value > maxValue ? message : undefined;
};

export const minValueValidator = (minValue: any, message: string, isEqualAllowed?) => (value) => {
  if (isEqualAllowed) {
    return parseFloat(value) < parseFloat(minValue) ? message : undefined;
  }
  return parseFloat(value) <= parseFloat(minValue) ? message : undefined;
};

export const maxValueValidator = (maxValue: any, message: string) => (value) => {
  return parseFloat(value) > parseFloat(maxValue) ? message : undefined;
};

export const errorDataGenerator = (totalCount: number, response: any, checkedRecords) => {
  const errorObj = {
    failedCount: 0,
    isSingleFail: (totalCount === response.invalidResponse.length && totalCount === 1) || checkedRecords.length === 1,
    listItemName: response.fieldType,
    totalCount,
  };
  errorObj.failedCount = response.invalidResponse.length;
  return errorObj;
};

export const shouldNotEqual = (inputValue: any, message: string) => (value) => {
  return inputValue === value ? message : undefined;
};

/**
 * @description returns toast message when file downloads failed for specific error code/message.
 * @param - t: translation property
 */
export const displayMessageFromErrorCode = (error, t, showToaster, pushAnalyticsData) => {
  const promise: any = parseError(error).next().value;

  return promise.then((val) => validateDownloadErrors(val, t, showToaster, pushAnalyticsData));
};

/**
 * @description returns error message if value has more than two decimal values.
 * @param - message: message to be returned if more than two decimal values.
 * @param - value: value to be validated for two decimals.
 */
export const twoDecimalValidator = (message) => (value) => {
  const val = String(value);
  const indexOfDecimal = val.indexOf(".");
  const decimalCount = indexOfDecimal > -1 ? val.substr(indexOfDecimal + 1, val.length) : "";
  return decimalCount.length > ValidationConstants.MAX_LENGTH.NUM_2 ? message : undefined;
};

export const isDuplicateTransfer = (response) => response?.error?.response?.data?.errors?.[0]?.code === "21106";

export const regexValidationOnEqualValue =
  (regex: RegExp, message: string) =>
  (value: string): string => {
    return regex.test(value) ? message : undefined;
  };
