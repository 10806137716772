import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";
import colorPalette from "../../materials/Colors";
import HelperLodash from "../../helpers/helperLodash";
import { default as TextField } from "../Field/field";
import IAttachmentInputProps from "./iAttachmentInput";
// @ts-ignore
import removeIcon from "./remove.svg";
import ApplicationConstants from "../../constant/applicationConstants";

enum FILE_SOURCE {
  DM = "DM",
  CLOUD = "HC",
}

const Container = styled.div`
  background: ${(props) => props.theme.colors.divBorder.backgroundLightGrey};
  padding: 16px;
  cursor: default;
`;

const InputStyle = styled.input`
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  border: 2px solid
    ${(props) =>
      props["data-error"]
        ? colorPalette.hiltiRedError
        : props.disabled
          ? colorPalette.heavyConcrete20
          : colorPalette.heavyConcrete60};
  background-color: ${colorPalette.white};
  color: ${(props) => (props.disabled ? colorPalette.steel20 : colorPalette.steel)};
  &::placeholder {
    /* Chrome, Firefox, Opera  */
    color: ${colorPalette.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colorPalette.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colorPalette.steel40};
    font-family: ${(props) => props.theme.fontNormal.fontFamily};
  }
`;

const DisplayNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  label {
    word-break: break-all;
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
  div:first-child {
    flex: 1;
  }
`;

const DisplayFileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  label {
    word-break: break-all;
  }
  div:first-child {
    word-break: break-all;
    padding-right: 16px;
  }
`;

const RemoveImg = styled.img`
  margin: 0 0 0 16px;
  cursor: pointer;
  opacity: ${(props) => (props["data-disable"] ? ApplicationConstants.OPACITY.O50 : 1)};
`;

const AttachmentName = styled.span`
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.colors.base.steel};
  & + span {
    margin-left: 16px;
    cursor: pointer;
  }
`;

export const TextComponent = ({
  input,
  label,
  placeholder,
  required,
  onSelectChange,
  selectedValue,
  disabled,
  ref,
  readOnly,
  onBlur,
  error,
  meta: { touched, warning },
}) => (
  <TextField
    name={input.name}
    htmlFor={input.name}
    label={label}
    error={error}
    touched={touched}
    warning={warning}
    required={required}
  >
    <InputStyle
      {...input}
      ref={ref}
      id={input.name}
      data-error={error && touched ? error : ""}
      placeholder={placeholder}
      type="text"
      value={selectedValue ? selectedValue : input.value}
      disabled={disabled}
      readonly={readOnly}
      onChange={(val) => {
        input.onChange(val);
        if (onSelectChange) {
          onSelectChange(val);
        }
      }}
      onBlur={(val) => {
        const trimVal = val.target.value.trim();
        input.onBlur(trimVal);
        if (onBlur) {
          onBlur(trimVal);
        }
      }}
    />
  </TextField>
);
const AttachmentID = () => <span />;
const AttachmentDetail = (props) => (
  <>
    <AttachmentName id={`${props.input.name}File`}>{`${props.input.value}`}</AttachmentName>
    {props.allowDownloadAttachment && (
      <SquareIcon name={IconName.Download} onClick={() => props.downloadAttachement(props.attachmentData)} />
    )}
  </>
);
const AttachmentLabel = (props) => <label>{props.input.value}</label>;

class AttachmentFormInput extends React.PureComponent<IAttachmentInputProps> {
  getFieldName = (propertyName) => {
    const { name, responseFormatMapper } = this.props;
    const responseFormatMapperPropertyName = HelperLodash.get(responseFormatMapper, propertyName);
    if (responseFormatMapper && responseFormatMapperPropertyName) {
      return `${name}.${responseFormatMapperPropertyName}`;
    }
    return `${name}.${propertyName}`;
  };

  render() {
    const {
      attachmentData,
      id,
      name,
      label,
      type,
      placeholder,
      required,
      validation,
      ref,
      removeHandler,
      error,
      index,
    } = this.props;
    // the varible for the HC services to be read only is going to be added from backend
    const disableRemove = attachmentData.isReadOnly || attachmentData.fileSource === FILE_SOURCE.CLOUD;
    return (
      <Container id={`${name}Container`}>
        <DisplayNameWrapper>
          <Field
            id={id || name}
            name={this.getFieldName("displayName")}
            label={label}
            type={type}
            placeholder={placeholder}
            required={required}
            component={attachmentData.isNew ? TextComponent : AttachmentLabel}
            ref={ref}
            attachmentData={attachmentData}
            error={error && HelperLodash.get(error, index) && HelperLodash.get(error, [index, "displayName"])}
          />
          <div>
            <RemoveImg
              data-disable={disableRemove}
              src={removeIcon}
              id={`${name}Img`}
              onClick={!attachmentData.isReadOnly && removeHandler}
              aria-label={`remove attachment ${attachmentData.fileName}`}
            />

            <Field
              {...this.props}
              id={id || name}
              name={this.getFieldName("attachmentId")}
              label={label}
              type={type}
              placeholder={placeholder}
              required={required}
              component={AttachmentID}
              ref={ref}
            />
          </div>
        </DisplayNameWrapper>
        <DisplayFileNameWrapper>
          <Field
            {...this.props}
            id={id || name}
            name={this.getFieldName("fileName")}
            label={label}
            type={type}
            placeholder={placeholder}
            required={required}
            component={AttachmentDetail}
            validate={validation}
            ref={ref}
          />
        </DisplayFileNameWrapper>
      </Container>
    );
  }
}

export default AttachmentFormInput;
