import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { removeClassOnBody } from "../../helpers/domHelper";
import ComponentHeader from "../componentHeader/componentHeader";
import IDialogProps from "./iDialogProps";
const ActualWrapper = styled("div")<{ zIndex: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.colors.base.heavyConcrete10};
  z-index: ${(props) => props.zIndex};
  .search-bar,
  .modal-header-grid .custom-cell:first-child {
    padding-left: 0;
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
`;

/**
 * @const StyledText - Styled component for the title text.
 */
const StyledText = styled("h1")`
  color: ${(props) => props.theme.colors.base.black};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  text-align: left;
  cursor: default;
`;

const modalRoot = document.getElementById("modalRoot");

/**
 * Class to show Dialog on the top of app pages
 *
 * @class Dialog
 * @extends {React.PureComponent<IDialogProps>}
 */
class Dialog extends React.PureComponent<IDialogProps> {
  el = document.createElement("div");

  /**
   * Show Dialog once this component is mounted
   *
   * @memberof Dialog
   */
  componentDidMount = () => {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
      window.scrollTo(0, 0);
      document.getElementById("root").style.visibility = "hidden";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    removeClassOnBody("thead-sticky");
  };

  /**
   * Hide Dialog once this component is unmounted
   *
   * @memberof Dialog
   */
  componentWillUnmount = () => {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
      if (modalRoot.childNodes.length === 0) {
        window.scrollTo(0, 0);
        document.getElementById("root").style.visibility = "visible";
        document.getElementsByTagName("body")[0].style.overflow = "";
      }
    }
  };

  /**
   * @description - Render the content header of the Dialog.
   */
  renderContentHeader = () => {
    const { headerText } = this.props;
    const HeaderComponent = this.props.headerComponent;
    return HeaderComponent ? (
      <HeaderComponent handleClose={this.props.onClose} header={headerText} />
    ) : (
      <> {React.isValidElement(headerText) ? headerText : <StyledText id="dialogTitle">{headerText}</StyledText>}</>
    );
  };
  render() {
    const { children, containerId, headerId, onClose, zIndex = 9 } = this.props;
    return ReactDOM.createPortal(
      <ActualWrapper id="dialogPortal" zIndex={zIndex}>
        <ComponentHeader id={headerId} panelCloseHandler={onClose}>
          {this.renderContentHeader()}
        </ComponentHeader>
        <Container id={containerId}>{children}</Container>
      </ActualWrapper>,
      this.el,
    );
  }
}

export default Dialog;
