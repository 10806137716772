import { SquareIcon } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import ImageTypeEnum from "../../../utils/enums/imageTypeEnum";
import ISquareImageProps from "./iSquareImage";
/**
 * @description - Styling the component.
 */
const DivWrapper = styled("div")<ISquareImageProps>`
  background-color: ${(props) => props.theme.colors.base.white};
  border: 1px solid ${(prop) => prop.theme.squareImage.borderColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * @description - Styling the component.
 */
const StyledSquareImage = styled("img")<ISquareImageProps>`
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
`;

/**
 * @description - Styled component that renders the image square in shape.
 * "size" property can be used to make the image square in shape.
 *
 * @param props - Object that holds the component properties.
 */
class SquareImage extends React.Component<ISquareImageProps> {
  static defaultProps = {
    selfLoadImage: true,
  };
  componentDidMount = () => {
    const { documentId, getDocument, selfLoadImage, type, thumbnailHeight, thumbnailWidth } = this.props;
    const imageType = type ? type : ImageTypeEnum.original;
    const document = getDocument(`${documentId}:${imageType}`);
    /*
      If document is present in the store , prevent API call.
    */
    if (selfLoadImage && !document && this.props.documentId && (!thumbnailHeight || !thumbnailWidth)) {
      this.props.getDocumentById(this.props.documentId, imageType);
    } else if (this.props.documentId && thumbnailHeight && thumbnailWidth) {
      this.props.getDocumentById(this.props.documentId, type, thumbnailWidth, thumbnailHeight);
    }
  };

  render = () => {
    const { className, id, defaultSrc, title, documentId, getDocument, wrapperAttributes, type, imageSize } =
      this.props;
    const imageType = type ? type : ImageTypeEnum.original;
    const document = getDocument(`${documentId}:${imageType}`);
    const { width, height, style, ...wrapperAttrs } = wrapperAttributes;
    return (
      <DivWrapper width={width} height={height} style={style} {...wrapperAttrs}>
        {document && document.url ? (
          <StyledSquareImage id={id} src={document && document.url} className={className} alt={title ? title : ""} />
        ) : (
          <SquareIcon name={defaultSrc} className={className} imageSize={imageSize} />
        )}
      </DivWrapper>
    );
  };
}

/**
 * @export SquareImage - Styled Component.
 */
export default SquareImage;
