import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="05-Icons-/-Shelf_Box" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g id="shelf_box">
        <rect id="Rectangle" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
        <path
          d="M4,12 L4,18 L20,18 L20,12 L4,12 Z M4,10 L20,10 L20,3 L22,3 L22,21 L20,21 L20,20 L4,20 L4,21 L2,21 L2,3 L4,3 L4,10 Z M5,13 L9,13 L9,17 L5,17 L5,13 Z M10,13 L14,13 L14,17 L10,17 L10,13 Z M15,13 L19,13 L19,17 L15,17 L15,13 Z M5,5 L9,5 L9,9 L5,9 L5,5 Z M10,5 L14,5 L14,9 L10,9 L10,5 Z"
          id="🎨Color"
          fill="#524F53"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
