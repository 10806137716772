import React from "react";
import { SquareIcon } from "../icons/iconWrapper";
import { OutsideClickAlerter } from "../outSideClickAlerter/outSideClickComponet";
import { disableDependencyResolver, tooltipDependencyResolver } from "./actionBarUtil";
import IActionDropDownValuesProps from "./iactionDropDownValuesProps";
import Tooltip from "@hilti/components/core/tooltip";
import { TooltipProps } from "@hilti/components/core/tooltip";

export class ActionDropDownValues extends React.Component<IActionDropDownValuesProps> {
  valueRef;

  /**
   * @description This method is use to return message key on the basis of given label text value.
   *
   * @returns {string}
   */
  getLabelText = () => `actionBar:${this.props.actionItem.name.toUpperCase()}`;

  /**
   * @description This method is use to retrun tooltip content on the basis of given dependencyn injection.
   */
  getTooltipContent = () => {
    const { actionItem, dependencies } = this.props;

    return tooltipDependencyResolver(actionItem, dependencies);
  };

  /**
   * @description This method is use to check whether item will disable or not.
   */
  isDisable = () => {
    const { actionItem, dependencies } = this.props;

    return disableDependencyResolver(actionItem, dependencies);
  };

  /**
   * @description This method is use to set value referene.
   */
  setValueReference = (ref) => (this.valueRef = ref);

  /**
   * @description This method is use to handle on click event.
   */
  handleOnClick = () => {
    const {
      onActionClicked,
      actionItem: { name },
    } = this.props;

    return onActionClicked ? () => onActionClicked(name, this.valueRef) : null;
  };

  /**
   * @description This method is use to retrun dropdown items grouping text and icon.
   */
  renderDropdownItems = () => {
    const {
      actionItem: { name, iconName, tooltipPosition },
    } = this.props;
    const label = this.props.t(this.getLabelText());

    return (
      <Tooltip
        title={this.isDisable() ? this.getTooltipContent() : ""}
        placement={tooltipPosition as TooltipProps["placement"]}
      >
        <div>
          <div
            id={name}
            className={`sort-value ${this.isDisable() && "disabled"}`}
            ref={this.setValueReference}
            onClick={this.handleOnClick()}
            aria-label={label}
          >
            <SquareIcon name={iconName ? iconName : name} />
            {`${label}`}
          </div>
        </div>
      </Tooltip>
    );
  };

  render() {
    const { handleOnClickAway } = this.props;

    return (
      <>
        <OutsideClickAlerter
          onClickAway={handleOnClickAway}
          ignoreClickedElement={[
            "actionDropDownWrapper",
            "Import",
            "Export",
            "Setting",
            "EditAssetsTemplate",
            "EditAssetsNotLinkedTemplate",
          ]}
          render={this.renderDropdownItems}
        />
      </>
    );
  }
}

export default ActionDropDownValues;
