import Grid from "@mui/material/Grid";
import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { CreatableAutoComplete, LoaderElement } from "../../elements";
import { default as TextField } from "../../elements/Field/field";
import ICommonProps from "../commonInterfaceProps/iCommonProps";
import IconComponent from "../icons/iconComponent/iconComponent";
import { IconName } from "../icons/iconEnum";
import IDropdownOrInfoIconProps from "./iDropdownOrInfoIconProps";

const TextIconStyle = styled.div`
  display: flex;
  flex-direction: row;
`;

const GridStyle = styled(Grid)`
  position: relative;
`;

export const TextHeadingStyle = styled(TextField)`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  text-align: left;
`;

export const TextValueStyle = styled.span`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 17px;
  margin-right: 16px;
  margin-top: 4px;
  text-align: left;
`;

interface ICreatableDropdownOrInfoIcon extends IDropdownOrInfoIconProps {
  readonly labelField: string;
  readonly liveSearch?: any;
  readonly t?: any;
  readonly isCreatable?: any;
  readonly noOptionsMessage?: () => string;
  readonly isClearable?: boolean;
  readonly footerControl?: (name?) => void;
  readonly defaultOptions?: boolean;
  readonly menuGridInfo?: any;
  readonly menuType?: string;
  readonly image?: any;
}

/**
 * common Interface implementaion
 */
type DropdownOrInfoIconProps = ICreatableDropdownOrInfoIcon & ICommonProps;

/**
 * @description - A class for Dropdown or Info Icon Component
 *
 * @class DropdownOrInfoIcon
 * @extends {React.PureComponent<dropDownProps, {}>}
 */
class CreatableDropdownOrInfoIcon extends React.PureComponent<DropdownOrInfoIconProps, {}> {
  static defaultProps = {
    showIcon: true,
  };
  getContentVal = () => this.props.getContent(this.props.textValue);
  renderTextControl = () => {
    const { blankLiterals, id, textHeading, place, textValue, showIcon } = this.props;
    return (
      <div>
        <GridStyle item={true} xs={12}>
          <TextHeadingStyle name={textHeading} htmlFor={textHeading} label={textHeading}>
            <TextIconStyle>
              <TextValueStyle>{textValue || blankLiterals}</TextValueStyle>
              {showIcon && (
                <IconComponent
                  icon={IconName.Info}
                  id={id}
                  isIconDisabled={true}
                  getContent={this.getContentVal}
                  place={place}
                />
              )}
            </TextIconStyle>
          </TextHeadingStyle>
        </GridStyle>
      </div>
    );
  };

  renderField = (id, name, isFormControl) => {
    if (isFormControl) {
      return <Field id={id} name={name} component={this.renderTextControl} {...this.props} />;
    } else {
      return this.renderTextControl;
    }
  };

  render() {
    const {
      autoComplete,
      data,
      defaultOptions,
      footerControl,
      id,
      inactiveMessage,
      isClearable,
      isCreatable,
      isFormControl,
      label,
      menuType,
      name,
      noOptionsMessage,
      placeholder,
      validation,
      showDropdown,
      required,
      onSelectChange,
      valueKey,
      valueId,
      menuPlacement,
      isDisabled,
      labelField,
      liveSearch,
      t,
      menuGridInfo,
      image,
    } = this.props;

    if (showDropdown) {
      return (
        <CreatableAutoComplete
          autoComplete={autoComplete}
          className="form-control"
          defaultOptions={defaultOptions}
          footerControl={footerControl}
          id={id}
          isFormControl={isFormControl}
          label={label}
          name={name}
          required={required}
          validation={validation}
          placeholder={placeholder}
          onSelectChange={onSelectChange}
          data={data}
          icon="search"
          valueKey={valueKey}
          valueId={valueId}
          menuPlacement={menuPlacement}
          isDisabled={isDisabled}
          labelField={labelField}
          loadingMessage={() => <LoaderElement />}
          liveSearch={liveSearch}
          t={t}
          isCreatable={isCreatable}
          noOptionsMessage={noOptionsMessage}
          isClearable={isClearable}
          menuGridInfo={menuGridInfo}
          menuType={menuType}
          image={image}
          inactiveMessage={inactiveMessage}
        />
      );
    } else {
      return this.renderField(id, name, isFormControl);
    }
  }
}

export default CreatableDropdownOrInfoIcon;
