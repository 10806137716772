import React from "react";

const SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="24px" height="24px">
    <path
      fill="#524F53"
      fillRule="evenodd"
      d="M10.667 5.333v-4h4v4h8v4h-3.394c-1.203 1.82-2.814 4.13-4.833 6.93A72.354 72.354 0 0 0 18 19.014L16 22.907a276.702 276.702 0 0 1-3.947-3.368c-2.833 3.837-4.828 6.335-5.985 7.492l-3.401-2.667C4.77 22.122 6.9 19.61 9.06 16.826a73.004 73.004 0 0 1-3.155-3.243l2.584-2.678c.917.922 1.896 1.85 2.938 2.782a94.792 94.792 0 0 0 3.031-4.354H1.333v-4h9.334zM21.63 22.667h3.202l-1.5-4-1.702 4zm4.702 4h-6.405l-1.135 2.666h-4.126L21.804 14h3.2l6.33 15.333h-4l-1-2.666z"
    />
  </svg>
);

export default SVG;
