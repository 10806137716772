import ControlType from "./controlType";
import FilterOperator from "./filterOperator";

/**
 * Defines contract for defining module specific grid filter
 *
 * @property filterName: name of filter property to read map data
 * @property filterDisplayName: display property of the filter
 * @property operator: operator to use when making filter query
 */
export interface IGridFilter {
  displayProperty?: boolean;
  exclude?: boolean;
  filterDisplayName: string;
  filterName: string;
  type: IFilterType;
  operator: FilterOperator;
  labels?: any;
  hasDependency?: boolean;
  dependencies?: any;
  nullTextDisplay?: any;
  useDefault?: boolean;
  isDefault?: boolean;
  toolTip?: string;
  isUnclearable?: boolean;
  supportShortName?: boolean;
}

/**
 * Define contract for type of filters
 *
 * @property searchBar: type when control rendered on search bar
 * @property moreFilter: type when control rendered in more filter
 */
export interface IFilterType {
  searchBar: ControlType;
  moreFilter: ControlType;
}

/**
 * defines possible locations for rendering filter control type
 */
export enum RenderLocation {
  searchBar = "searchBar",
  moreFilter = "moreFilter",
}
