// @ts-ignore
import { HINTMANUFACTURERS } from "TARGET_BUILD/config";
import AssetModel from "../../../../models/asset/assetModel";

export const HILTI_SAP_FIELDS = [
  "vendor",
  "serialNumber",
  "purchaseDate",
  "fleetDeliveryDate",
  "fleetExchangeOrWarrantyDate",
  "fleetOrganizationReferenceNumber",
  "ownerShipType",
  "fleetInventoryNumber",
  "fleetCostCenter",
  "warrantyExpirationDate",
  "fleetPurchaseOrderNumber",
  "purchaseOrderNumber",
  "loanToolClaim",
  "theftInsurance",
  "rentalStartDate",
  "loanStartDate",
  "rentalReturnDate",
  "loanEndDate",
  "previousSerialNumber",
  "replacementSerialNumber",
  "hiltiAssetOwnerName",
];

const MANUFACTURE_FIELD_NAME = "manufacturerName";

/**
 * @description Function to check if the manufacuture selected has HINT support
 * @param {value} - Manufacture name
 * @param {HINTMANUFACTURERS} - Array of list of manufacture having HINT support
 * @return {boolean} -
 */
export const isHINTManufacturerSelected = (value = "") => {
  return value && HINTMANUFACTURERS && HINTMANUFACTURERS.includes(value);
};

export const checkSapField = (fieldName: string, makeReadOnly: boolean): boolean => {
  return makeReadOnly && (HILTI_SAP_FIELDS.includes(fieldName) || MANUFACTURE_FIELD_NAME === fieldName);
};

export const shouldFetchTemplate = (unregisteredHiltiAsset) => {
  const productData = unregisteredHiltiAsset && unregisteredHiltiAsset.productData;
  if (productData && productData.model) {
    return Boolean(productData.rangeId || productData.templateId);
  } else {
    return false;
  }
};

/**
 * @description functions decides which API to call to get template
 * "getTemplateById" if templateId is present,
 * else "getPDPTemplate" if rangeId is present
 * else "getManufacturerAndModel"
 * @param getTemplateDetails function to make "getTemplateById" call
 * @param getPDPTemplate function to make "getPDPTemplate" call
 * @param getManufacturerAndModel function to make "getManufacturerAndModel" call
 * @param action
 */

export const getTemplateCallForUnregisteredTool = (
  getTemplateDetails,
  getPDPTemplate,
  getManufacturerAndModel,
  action,
) => {
  const { templateId, rangeId, name } = action.model;
  return templateId
    ? getTemplateDetails(templateId)
    : rangeId
      ? getPDPTemplate(rangeId)
      : getManufacturerAndModel(action.manufacturerId, name);
};

export const removeMandatoryToolInfo = (asset: AssetModel) => {
  if (asset) {
    asset.isSapAsset = false;
    asset.hiltiIntegratedAsset = false;

    asset.materialNumber = null;
    asset.originEquipmentNumber = null;

    asset.hiltiAssetOwnerName = null;

    asset.hiltiAssetStatus = null;
    asset.hiltiAssetStatusValue = null;

    asset.previousSerialNumber = null;
  }
};

export const isSameTool = (asset, tool) => {
  return asset && tool && asset.materialNumber === tool.materialNumber && asset.serialNumber === tool.serialNumber;
};

export const removeNonMandatoryToolInfo = (asset: AssetModel) => {
  if (asset) {
    asset.hiltiAssetOwnerName = null;
    asset.hiltiAssetStatus = null;
    asset.hiltiAssetStatusValue = null;
    asset.previousSerialNumber = null;

    // remove non-mandatory tool information for owned
    asset.ownedAssetDetails.vendor = null;
    asset.ownedAssetDetails.purchaseDate = null;
    asset.ownedAssetDetails.purchaseOrderNumber = null;
    asset.ownedAssetDetails.warrantyExpirationDate = null;

    // remove non-mandatory tool information for fleet
    asset.fleetAssetDetails.fleetCostCenter = null;
    asset.fleetAssetDetails.fleetDeliveryDate = null;
    asset.fleetAssetDetails.fleetExchangeOrWarrantyDate = null;
    asset.fleetAssetDetails.fleetInventoryNumber = null;
    asset.fleetAssetDetails.fleetOrganizationReferenceNumber = null;
    asset.fleetAssetDetails.fleetPurchaseOrderNumber = null;
    asset.fleetAssetDetails.loanToolClaim = null;
    asset.fleetAssetDetails.theftInsurance = null;

    // remove non-mandatory tool information for loaned asset
    asset.loanedAssetDetails.loanEndDate = null;
    asset.loanedAssetDetails.loanStartDate = null;

    // remove non-mandatory tool information for rented asset
    asset.rentedAssetDetails.rentalReturnDate = null;
    asset.rentedAssetDetails.rentalStartDate = null;
  }
};
