// @ts-ignore
import { DocumentActions } from "custom-actions";

const intitialState = {
  document: {},
  error: {},
  documentKeys: [],
};

const documentReducer = (state = intitialState, action) => {
  switch (action.type) {
    case DocumentActions.SET_DOCUMENT:
      return {
        ...state,
        document: { ...state.document, [action.payload.documentId]: { metaData: action.payload } },
        documentKeys: [...state.documentKeys, action.payload.documentTitle],
      };
    case DocumentActions.SET_DOCUMENT_URL:
      return {
        ...state,
        document: {
          ...state.document,
          [`${action.payload.documentId}:${action.payload.imageType}`]: {
            ...state.document[action.payload.documentId],
            type: action.payload.imageType,
            url: action.payload.url,
          },
        },
      };
    case DocumentActions.SET_DOCUMENT_ERROR:
      return {
        ...state,
        error: {
          [action.payload.documentKey]: action.payload.error,
        },
      };
    case DocumentActions.SET_DOCUMENT_ERROR_CLEAN:
      return {
        ...state,
        error: {},
      };
    case DocumentActions.CLEAR_ATTACHMENT_DATA:
      return {
        ...state,
        attachmentInfo: {},
        documentKey: [],
        error: {},
        imageData: "",
        imageInfo: {},
      };
    default:
      return state;
  }
};

export default documentReducer;
