import { ISagaModule } from "redux-dynamic-modules-saga";
import ILockedFeatureInfoModuleState from "./iLockedFeatureInfoModuleState";
import lockedFeatureInfoReducer from "./lockedFeatureInfoReducer";
import lockedFeatureInfoSaga from "./lockedFeatureInfoSaga";

export const lockedFeatureInfoModule: ISagaModule<ILockedFeatureInfoModuleState> = {
  id: "lockedFeatureInfoModule",
  reducerMap: {
    LockedFeatureInfoReducer: lockedFeatureInfoReducer,
  },
  sagas: [lockedFeatureInfoSaga],
};

export default lockedFeatureInfoModule;
