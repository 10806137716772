// Enum that contains the possible list of text types.
export enum StyledTextType {
  INNER_TEXT = "innerText",
  OUTER_TEXT = "outerText",
}

export enum PieChartColor {
  WORKING = "#00B67C",
  IDLE = "#E6AE45",
  ENGINE_HOUR = "#8E9EC0",
}
