import { gridUtils } from "am-web-ui-shared/components";
// @ts-ignore
import { CommonActions } from "custom-actions";
import { HelperLodash } from "am-web-ui-shared/helpers";
import AssetList from "../../../../models/asset/assetList";
import { assetsGridSetting } from "../../../../modules/asset/components/list/presentational/assetSetting";
import {
  createReducer,
  deepCloneArray,
  updateArray,
  setFilterAttributesToReducer,
} from "../../../../utils/commonUtils";
import {
  getDefaultView,
  isCustomViewUpdated,
  revertSortQueryObject,
  setSelectedCustomView,
  getCustomViewSuccess,
  getCustomViewFailure,
} from "../../../../utils/customViewUtils";
import { StatusCode } from "../../../../utils/enums/assetEnum";
import ModuleName from "../../../../utils/enums/moduleNameEnum";
import { ListMetaEnum, rowActionType } from "../../../../utils/list/list";
import {
  appendMetaPropertyToList,
  appendSelectedPropertyToList,
  clearAllCheckedRows,
  getListForPath,
  setAllCheckedRows,
  setCheckboxWithRowSelection,
  updateChildListToAsset,
  updateMetaPropertyToRow,
} from "../../../../utils/list/listUtils";
import { mergedCancelTransferList } from "../../../../utils/transferUtil";
import { IAssetInitialState } from "./iAssetModuleState";
import { IAssetsList } from "../../../../models/assets.list";

const primaryId = "assetId";
export const assetsInitialState: IAssetInitialState = {
  appliedFilterQuery: [],
  assetDeleteResponse: null,
  assetsList: {} as IAssetsList,
  associateLabels: {
    isUpdated: false,
    list: null,
  },
  selectedAssetTransferDetail: null,
  commonAttributes: [],
  customViewsList: null,
  filterQuery: null,
  filterAttributes: {
    idleTimeDuration: [
      {
        min: "",
        max: "",
      },
    ],
  },
  isAllSelected: false,
  loader: {
    isDetailsLoading: false,
    isListLoading: false,
  },
  resetScrollPosition: false,
  searchString: "",
  selectAllAssets: [],
  selectedAssetDetail: null,
  showNoRecordFoundView: false,
  transferHistory: null,
  utilizationSummary: {},
};

const handleAssociatedLabelFormat = (labelDetails) => {
  const commonLabels = [];
  if (labelDetails) {
    labelDetails.map((labelData) => {
      commonLabels.push(labelData.labelName);
    });
  }
  return commonLabels;
};

function getList(assetInfo: AssetList, assetsList: any) {
  const assetStatusCode = assetInfo.assetStatusName && assetInfo.assetStatusName.code;
  const list = assetsList.list.slice();
  let updatedList = null;
  if (assetStatusCode !== StatusCode.Retired && assetStatusCode !== StatusCode.Disposed) {
    updatedList = updateArray(list, { assetId: assetInfo.assetId }, assetInfo);
    return {
      list: updatedList,
    };
  }
  updatedList = list.filter(
    (item) =>
      !(
        item.assetId === assetInfo.assetId &&
        (assetInfo.assetStatusName.code === StatusCode.Retired ||
          assetInfo.assetStatusName.code === StatusCode.Disposed)
      ),
  );
  const totalRecords = assetsList.totalRecords - 1;
  return {
    list: updatedList,
    totalRecords,
  };
}

const getSelectedValue = (row, selected) => {
  return (
    row.metaData && (!row.metaData.isExpandable ? selected : row.metaData[ListMetaEnum.IsRowCheckedOnCollapsedMode])
  );
};
const getAddedAssetItem = (action) => {
  return action.payload.assetsData && action.payload.assetsData.list.length > 0 && action.payload.assetsData.list[0];
};

const getSelectedRowList = (action) => {
  return action.payload.assetsData && action.payload.assetsData.list && action.payload.assetsData.list.slice(0);
};
const getSelectedItem = (action) => {
  return action.payload && action.payload.selectFirstRow && getAddedAssetItem(action);
};

const setShowNoRecordFoundView = (state, action) =>
  (state.searchString || (state.filterQuery && state.filterQuery.length)) && !action.payload.assetsData.totalRecords;

const selectAllAssetsSuccess = (state, action) => {
  return {
    ...state,
    isAllSelected: false,
    selectAllAssets: [
      ...state.assetsList.list,
      ...appendSelectedPropertyToList(
        appendMetaPropertyToList(action.payload.assetsData.response, {
          type: ModuleName.asset,
        }),
        true,
      ),
    ],
  };
};
/**
 * @description Function updates the state value with updated assets response object
 * @param {object} state - takes entered search string.
 * @param action
 */
const putAssetList = (state, action) => {
  const isAllSelected = !!(state.isAllSelected && action.payload.offset);
  const oldResponse = action.payload.offset ? state.assetsList.list : [];
  action.payload.assetsData.list = [
    ...oldResponse,
    ...appendSelectedPropertyToList(
      appendMetaPropertyToList(action.payload.assetsData.response, {
        type: ModuleName.asset,
      }),
      isAllSelected,
    ),
  ];
  delete action.payload.assetsData.response;
  const selectRowList = getSelectedRowList(action);
  const selectedItem = getSelectedItem(action);
  return {
    ...state,
    assetsList: {
      ...action.payload.assetsData,
      list: selectedItem
        ? setCheckboxWithRowSelection(selectRowList, selectedItem, true, primaryId)
        : action.payload.assetsData.list,
    },
    associateLabels: {
      isUpdated: false,
      list: null,
    },
    isAllSelected,
    resetScrollPosition: state.assetsList.offset !== null && !action.payload.offset,
    showNoRecordFoundView: setShowNoRecordFoundView(state, action),
  };
};

/**
 * @description Function updates the state value with default query params
 * @param {object} state - takes entered search string.
 */
const assetResetQueryParam = (state) => {
  const selectedCustomView = state.customViewsList && getDefaultView(state.customViewsList);
  const sortQuery = revertSortQueryObject(selectedCustomView && selectedCustomView.sortField);

  return {
    ...state,
    appliedFilterQuery: [],
    assetsList: {
      list: null,
      offset: null,
    },
    filterQuery: null,
    isCustomViewUpdated: false,
    searchString: "",
    selectedCustomView: null,
    showNoRecordFoundView: false,
    sortQuery: {
      sortField: sortQuery && sortQuery.name,
      sortType: sortQuery && sortQuery.sortType,
    },
  };
};

/**
 * @description Function updates the state value with given sort query
 * @param {object} state - asset state
 * @param action
 */
const setAssetSortQuery = (state, action) => {
  return {
    ...state,
    sortQuery: action.sortQuery,
  };
};

/**
 * @description Function updates the state value with given filter query. Idle  here.
 * @param {object} state - asset state
 * @param action
 */
const setAssetFilterQuery = (state, action) => {
  return {
    ...state,
    currentSelectedFilter: action.currentSelectedFilter,
    filterQuery: action.filterQuery,
    replaceFilterQuery: action.replaceFilterQuery,
  };
};

const setAssetFilterAppliedAttributes = (state, action) => ({
  ...state,
  appliedFilterQuery: action.payload && action.payload.filterQuery,
});

/**
 * @description Function updates the state value with default query params
 * @param {object} state - takes entered search string.
 * @param action
 */
const setAssetSearchQuery = (state, action) => {
  return {
    ...state,
    loader: { ...state.loader, isListLoading: false },
    searchString: action.searchQuery,
  };
};

/**
 * @description - Function to update the state value with fields name for which we need value from api.
 * @param {object} action - Action object.
 * @param {object} state - State object.
 */
const setAssetListFieldsQuery = (state, action) => {
  return {
    ...state,
    fields: gridUtils.createFieldsQuery(action.fields, assetsGridSetting.defaultQueryFields),
    gridFields: gridUtils.getGridSettingsBindPropertyKey(action.fields),
  };
};

/**
 * @description Function updates the state value with asset details and updates the selectedRowId
 * @param {object} state - asset state
 * @param action
 */
const setAssetDetailsWithId = (state, action) => {
  return {
    ...state,
    selectedAssetDetail: action.payload,
    assignedServicesCount: action.payload.openServicesCount + action.payload.historyServicesCount,
    associateLabels: {
      isUpdated: false,
      list: HelperLodash.get(action.payload, "labelNames", null),
    },
    closeMoreFilter: false,
    resetScrollPosition: false,
  };
};

/**
 * @description Function updates the state value with null to selectedAssetDetail and selectedRowId
 * @param {object} state - asset state
 */
const closeAssetDetails = (state) => {
  return {
    ...state,
    selectedAssetDetail: null,
    resetScrollPosition: false,
  };
};

/**
 * @description Function updates the state value with empty row selection values
 * @param {object} state - asset state
 * @param action
 */
const clearRowSelection = (state, action) => {
  return {
    ...state,
    selectedAssetDetail: null,
    assetsList: action.payload,
    resetScrollPosition: false,
    showNoRecordFoundView: state.selectedCustomView && !state.selectedCustomView.originalView,
    totalRecords: action.payload.totalRecords,
  };
};

/**
 * @description Function updates the state value updated response list
 * @param {object} state - asset state
 * @param action
 */
const refreshAssetsList = (state, action) => {
  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      ...getList(action.payload, state.assetsList),
    },
  };
};

/**
 * @description Function updates the state value with given asset details
 * @param {object} state - asset state
 * @param action
 */
const setAssetDetails = (state, action) => {
  return {
    ...state,
    selectedAssetDetail: action.payload,
  };
};

/**
 * @description Function updates the state value with loader start boolean value
 * @param {object} state - asset state
 */
const assetListLoaderStart = (state) => {
  return {
    ...state,
    loader: {
      isDetailsLoading: false,
      isListLoading: true,
    },
  };
};
/**
 * @description Function updates the state value with loader end to true
 * @param {object} state - asset state
 */
const assetListLoaderEnd = (state) => {
  return {
    ...state,
    loader: {
      isDetailsLoading: false,
      isListLoading: false,
    },
  };
};
const assetSelectRow = (state, action) => {
  const assetsList = deepCloneArray(state.assetsList && state.assetsList.list);
  setCheckboxWithRowSelection(assetsList, action.item, true, primaryId);
  updateMetaPropertyToRow(
    assetsList,
    action.item.assetId,
    ListMetaEnum.IsRowCheckedOnCollapsedMode,
    getSelectedValue(action.item, true),
    false,
  );

  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: assetsList,
    },
    resetScrollPosition: false,
  };
};

const assetUnselectRow = (state, action) => {
  const assetsList = deepCloneArray(state.assetsList.list);
  setCheckboxWithRowSelection(assetsList, action.item, false, primaryId);

  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: assetsList,
    },
  };
};

const setAssetCommonAttributes = (state, action) => {
  return {
    ...state,
    commonAttributes: action.payload,
  };
};

const setAssetTransferDetails = (state, action) => {
  const oldResponse = action.payload.offset ? state.transferHistory.list : [];
  action.payload.transferHistory.list = [
    ...oldResponse,
    ...appendSelectedPropertyToList(action.payload.transferHistory.response),
  ];
  let mergedTransferList = [];
  let totalTransfers = 0;
  if (action.payload.transferHistory.limit > 1) {
    const { transferdList, totalRecords } = mergedCancelTransferList(action.payload.transferHistory.list);
    mergedTransferList = transferdList;
    totalTransfers = totalRecords;
    action.payload.transferHistory.list = mergedTransferList;
  }

  delete action.payload.transferHistory.response;

  return {
    ...state,
    mergedTransferList,
    transferHistory: {
      ...action.payload.transferHistory,
      assetId: action.payload.assetId,
      totalTransfersLength: totalTransfers,
    },
  };
};

// To clear the selection of assets, setting the "isSelected" value as false.
const clearAllAssetsSelection = (state, _action) => {
  const assetsList = deepCloneArray(state.assetsList && state.assetsList.list);
  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: clearAllCheckedRows(assetsList, true),
    },
    isAllSelected: false,
  };
};
// To clear the selection of assets, setting the "isSelected" value as false.
const selectAllRows = (state, _action) => {
  const assetsList = deepCloneArray(state.assetsList && state.assetsList.list);
  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: setAllCheckedRows(assetsList, true),
    },
    isAllSelected: true,
  };
};

const getChildAssetsSuccess = (state, action) => {
  const assetsList = deepCloneArray(state.assetsList && state.assetsList.list);
  const rowList = getListForPath(assetsList, action.id.path, primaryId);
  updateChildListToAsset({
    childList: action.payload,
    id: action.id.assetId,
    list: rowList,
  });
  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: assetsList,
    },
    resetScrollPosition: false,
  };
};

const setAssetRowExpandStatus = (state, action) => {
  const assetsList = deepCloneArray(state.assetsList && state.assetsList.list);
  const rowList = getListForPath(assetsList, action.rowObj.path, primaryId);
  updateMetaPropertyToRow(rowList, action.rowObj.assetId, "isExpandable", undefined, true);
  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: assetsList,
    },
    resetScrollPosition: false,
  };
};

const handleCheckboxSelection = (state, action) => {
  const { item, value } = action;
  const assetsList = deepCloneArray(state.assetsList && state.assetsList.list);
  setCheckboxWithRowSelection(assetsList, item, value, primaryId, rowActionType.checked);
  updateMetaPropertyToRow(
    assetsList,
    item.assetId,
    ListMetaEnum.IsRowCheckedOnCollapsedMode,
    getSelectedValue(item, value),
    false,
  );

  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: assetsList,
    },
    resetScrollPosition: false,
  };
};

const updateStorageAssetGatewayStatus = (state, action) => {
  const { assetId, gatewayStatus } = action;
  const newAssetsList = deepCloneArray(state.assetsList && state.assetsList.list);
  const assetToBeUpdated = newAssetsList.find((asset) => asset.assetId === assetId);
  if (assetToBeUpdated) {
    assetToBeUpdated.gatewayStatus = gatewayStatus;
  }
  return {
    ...state,
    assetsList: {
      ...state.assetsList,
      list: newAssetsList,
    },
  };
};

const setAssetDeleteResponse = (state, action) => {
  return {
    ...state,
    assetDeleteResponse: action.payload,
  };
};
const resetMultiDeleteResponse = (state, _action) => {
  return {
    ...state,
    assetDeleteResponse: undefined,
  };
};
const addRenameCustomViewResponse = (state, action) => {
  return {
    ...state,
    addRenameCustomViewResponse: action.payload,
  };
};
const cleanAndRenameCustomViewData = (state, _action) => {
  return {
    ...state,
    addRenameCustomViewResponse: null,
  };
};
const assetAssociateLabelsResponse = (state, action) => {
  return {
    ...state,
    associateLabels: {
      isUpdated: false,
      list: action.payload && handleAssociatedLabelFormat(action.payload.labelResponseList),
    },
  };
};
const setLabelUpdated = (state, _action) => {
  return {
    ...state,
    associateLabels: {
      ...state.associateLabels,
      isUpdated: true,
    },
  };
};
const setCustomViewModifiedFlag = (state, _action) => {
  return {
    ...state,
    isCustomViewUpdated: isCustomViewUpdated(state),
  };
};

const setSelectedAssetTransferDetails = (state, action) => {
  return {
    ...state,
    selectedAssetTransferDetail: action.payload.transferHistory && action.payload.transferHistory.response,
  };
};
const resetModuleData = (state, _action) => {
  return {
    ...state,
    customViewsList: assetsInitialState.customViewsList,
  };
};

/**
 * @description Function updates the possible asset filters
 * @param {object} state - asset state
 * @param action
 */
const setAssetFilterAttributes = (state, action) => {
  const { filterAttributes, filterAttributesCount, filterAttributesSearchCount } = setFilterAttributesToReducer(
    state,
    action,
  );
  return {
    ...state,
    filterAttributes: { ...state.filterAttributes, ...filterAttributes },
    filterAttributesCount,
    filterAttributesSearchCount,
  };
};

/**
 * @description Function to set default grid settings
 * @param {object} state - asset state
 * @param action
 */
const getSystemViewSuccess = (state, action) => {
  return {
    ...state,
    defaultGridFields: action.fields,
  };
};

/**
 * @description Function to clear default grid settings
 * @param {object} state - asset state
 */
const clearDefaultGridFields = (state) => {
  return {
    ...state,
    defaultGridFields: [],
  };
};

const clearAssetDetails = (state) => {
  return {
    ...state,
    selectedAssetDetail: null,
    transferHistory: null,
  };
};

const updateAssetDetailsChildGatewayStatus = (state, action) => {
  return {
    ...state,
    selectedAssetDetail: {
      ...state.selectedAssetDetail,
      childGatewayStatus: action.payload,
    },
  };
};

const clearTransferHistoryDetail = (state) => {
  return {
    ...state,
    transferHistory: null,
  };
};

/**
 * @description Function updates the state value with given asset utilization summary
 * @param {object} state - asset state
 * @param action
 */
const setAssetUtilizationSummary = (state, action) => {
  return {
    ...state,
    utilizationSummary: action.payload,
  };
};

/**
 * @description Function updates the state value with given asset utilization Details
 * @param {object} state - asset state
 * @param action
 */
const setAssetUtilizationDetails = (state, action) => {
  return {
    ...state,
    utilizationDetails: action.payload,
  };
};

const assetListReducer = createReducer(assetsInitialState, {
  AM_ADD_RENAME_CUSTOM_VIEW_RESPONSE: addRenameCustomViewResponse,
  AM_ASSET_ASSOCIATE_LABELS_RESPONSE: assetAssociateLabelsResponse,
  AM_ASSET_LIST_LOADER_END: assetListLoaderEnd,
  AM_ASSET_LIST_LOADER_START: assetListLoaderStart,
  AM_ASSET_RESET_QUERY_PARAM: assetResetQueryParam,
  AM_ASSET_SELECT_GRID_CHECKBOX: handleCheckboxSelection,
  AM_ASSET_SELECT_ROW: assetSelectRow,
  AM_ASSET_SET_COMMON_ATTRIBUTES: setAssetCommonAttributes,
  AM_ASSET_UNSELECT_GRID_CHECKBOX: handleCheckboxSelection,
  AM_ASSET_UNSELECT_ROW: assetUnselectRow,
  AM_CLEAN_ADD_RENAME_CUSTOM_VIEW_DATA: cleanAndRenameCustomViewData,
  AM_CLEAR_DEFAULT_GRID_FIELDS: clearDefaultGridFields,
  AM_GET_ASSET_CUSTOM_VIEW_FAILURE: getCustomViewFailure,
  AM_GET_ASSET_CUSTOM_VIEW_SUCCESS: getCustomViewSuccess,
  AM_GET_SYSTEM_VIEW_SUCCESS: getSystemViewSuccess,
  AM_SELECT_ALL_ROWS: selectAllRows,
  AM_SET_ASSET_FILTER_APPLIED_FIELD: setAssetFilterAppliedAttributes,
  AM_SET_LABEL_UPDATED: setLabelUpdated,
  AM_UPDATE_STORAGE_ASSET_GATEWAY_STATUS: updateStorageAssetGatewayStatus,
  ASSET_FILTER_FIELD_SUCCESS: setAssetFilterAttributes,
  CLEAR_ALL_ASSETS_SELECTION: clearAllAssetsSelection,
  CLEAR_ASSET_DETAILS: clearAssetDetails,
  CLEAR_ROW_SELECTION: clearRowSelection,
  CLEAR_SELECTED_ASSET_TRANSFER_HISTORY: clearTransferHistoryDetail,
  CLOSE_ASSET_DETAILS: closeAssetDetails,
  GET_CHILD_ASSETS_SUCCESS: getChildAssetsSuccess,
  PUT_ASSET_LIST: putAssetList,
  REFRESH_ASSETS_LIST: refreshAssetsList,
  [CommonActions.RESET_MODULE_DATA]: resetModuleData,
  RESET_MULTI_DELETE_RESPONSE: resetMultiDeleteResponse,
  SELECT_ALL_ASSETS_SUCCESS: selectAllAssetsSuccess,
  SET_ASSET_DELETE_RESPONSE: setAssetDeleteResponse,
  SET_ASSET_DETAILS: setAssetDetails,
  SET_ASSET_DETAILS_WITH_ID: setAssetDetailsWithId,
  SET_ASSET_FILTER_QUERY: setAssetFilterQuery,
  SET_ASSET_LIST_FIELDS_QUERY: setAssetListFieldsQuery,
  SET_ASSET_ROW_EXPAND_STATUS: setAssetRowExpandStatus,
  SET_ASSET_SEARCH_QUERY: setAssetSearchQuery,
  SET_ASSET_SORT_QUERY: setAssetSortQuery,
  SET_ASSET_TRANSFER_DETAILS: setAssetTransferDetails,
  SET_CHECKED_ASSET_TRANSFER_DETAILS: setSelectedAssetTransferDetails,
  SET_CUSTOM_VIEW_MODIFIED_FLAG: setCustomViewModifiedFlag,
  SET_SELECTED_VIEW: setSelectedCustomView,
  UPDATE_ASSET_DETAILS_CHILD_GATEWAY_STATUS: updateAssetDetailsChildGatewayStatus,
  SET_ASSET_UTILIZATION_SUMMARY: setAssetUtilizationSummary,
  SET_ASSET_UTILIZATION_DETAILS: setAssetUtilizationDetails,
});

export default assetListReducer;
