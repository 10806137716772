// @ts-ignore
import { AssetServiceActions, AssetTemplatesActions } from "custom-actions";

/**
 * @description - Action defined for creating asset templates. It will be used from "Create Asset Templates" section.
 *
 * @param value - Data object that should be passed to create a new asset templates.
 */
export const createAssetTemplatesData = (value) => ({
  assetTemplatesData: value,
  type: AssetTemplatesActions.CREATE_ASSET_TEMPLATES,
});

/**
 * @description - Action defined for refresh asset templates. It will be used after tempalte creation.
 *
 * @param templateId - Template Id.
 */
export const refreshTemplatesList = (templateId) => ({
  templateId,
  type: AssetTemplatesActions.REFRESH_TEMPLATES,
});

export const getAssetTemplateDetails = (templateId) => ({
  templateId,
  type: AssetTemplatesActions.GET_TEMPLATE_DETAILS,
});

/**
 * @description - Get the asset templates assets details once it watches the "GET_TEMPLATE_ASSET_LIST" action. It gets triggered once user clicks on the asset count.
 * @param templateId - templateId.
 */
export const getTemplateAssetList = (templateId) => ({
  templateId,
  type: AssetTemplatesActions.GET_TEMPLATE_ASSET_LIST,
});

/**
 * @description - Get the asset templates assets details once it watches the "CLEAR_TEMPLATE_ASSET_LIST" action. It gets triggered once user clicks on the asset count.
 * @param templateId - templateId.
 */
export const clearTemplateAssetList = () => ({
  type: AssetTemplatesActions.CLEAR_TEMPLATE_ASSET_LIST,
});

export const getAssetTemplateDetailsById = (id) => ({
  id,
  type: AssetTemplatesActions.GET_TEMPLATE_DETAILS_BY_ID,
});
/**
 * @description - Action to merge the selected asset template.
 *
 * @param mergeAssetTemplatesData - asset template data that needs to be merged.
 */
export const updateMergeAssetTemplates = (mergeAssetTemplatesData) => ({
  mergeAssetTemplatesData,
  type: AssetTemplatesActions.AM_MERGE_ASSETTEMPLATES_REQUEST,
});

export const clearTemplateList = () => ({
  type: AssetTemplatesActions.CLEAR_TEMPLATES_LIST,
});

export const resetToInitialState = () => ({
  type: AssetTemplatesActions.CLEAR_ASSET_TEMPLATE_DETAILS,
});

/**
 * @description Function to fetch the open service list with given offset
 */

export const fetchServiceList = (offset: number, id: number) => ({
  id,
  offset: offset || 0,
  type: AssetTemplatesActions.AM_GET_SELECTED_SERVICE_TEMPLAE_LIST,
});

/**
 * @description Function to remove service to existing asset
 */
export const removeTemplateServices = (assetTemplateId, serviceId) => ({
  assetTemplateId,
  serviceId,
  type: AssetTemplatesActions.REMOVE_TEMPLATE_SERVICE,
});
/**
 * @description Function to assign service to existing asset
 */
export const assignServices = (assetTemplateId, serviceData) => ({
  assetTemplateId,
  serviceData,
  type: AssetTemplatesActions.ASSIGN_ASSET_TEMPLATE_SERVICE,
});

/**
 * @description dispatcher to set the sort value in the store
 * @param {string} searchString - takes entered search string.
 */
export const setSortingQuery = (sortQuery) => ({
  sortQuery,
  type: AssetServiceActions.SET_SERVICE_SORT_QUERY,
});

/**
 * @description Function to get assignedServiceList
 */
export const assignedServiceList = (assetTemplateId) => ({
  assetTemplateId,
  type: AssetTemplatesActions.ASSIGNED_ASSET_TEMPLATE_SERVICE_LIST,
});
/**
 * @description Function to dispatch the method to set search value in the store
 * @param {string} searchString - takes entered search string.
 */
export const setSearchQuery = (searchQuery) => ({
  searchQuery,
  type: AssetServiceActions.SET_SERVICE_SEARCH_QUERY,
});
export const resetQueryObjToDefault = () => ({
  type: AssetServiceActions.RESET_SERVICE_QUERY_PARAM,
});
export const getAssetTemplateServiceFilterAttributes = (filter: string, templateId: number) => ({
  filter,
  templateId,
  type: AssetServiceActions.GET_ASSET_TEMPLATE_SERVICE_FILTER_ATTRIBUTES,
});
export const setAssetTemplateServiceFilterAttributes = (filterQuery) => ({
  currentSelectedFilter: filterQuery.currentSelectedFilter,
  filterQuery: filterQuery.filters,
  type: AssetServiceActions.SET_SERVICE_FILTER_QUERY,
});
export const setAssetTemplateServiceAppliedAttributes = (filterQuery) => ({
  payload: {
    filterQuery,
  },
  type: AssetServiceActions.SET_ASSET_TEMPLATE_SERVICE_APPLIED_FILTER_ATTRIBUTES,
});
