import React from "react";
import styled from "styled-components";
import { IconName } from "./icons/iconEnum";
import { SquareIcon } from "./icons/iconWrapper";

const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 0px;
  position: relative;
  overflow: auto;
  flex: 1 1 0%;
  span,
  a {
    align-self: center;
  }
`;

const NoRecordsSecondaryText = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  margin: 16px 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;
const ShowRecord = styled.a.attrs({
  id: "btnNoRecordsShowRecord",
})`
  color: ${(props) => props.theme.colors.base.linkColor};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
`;
const RecordSymbol = styled.div`
  display: flex;
  justify-content: center;
  span {
    width: 126px;
    height: 70px;
  }
  svg {
    width: 126px;
    height: 70px;
    opacity: 0.5;
  }
`;
interface INoRecord {
  searchString: string;
  objectType: string;
  resetHandler: () => void;
  t: any;
}

const NoRecord = (props: INoRecord) => {
  const { objectType, resetHandler, searchString, t } = props;
  const locales = {
    ShowRecord: "common:GO_TO_LIST",
  };
  return (
    <FixedContainer className="wrapper">
      <RecordSymbol>
        <SquareIcon name={IconName.NoSearchResult} id="noResult" />
      </RecordSymbol>
      <NoRecordsSecondaryText>
        {searchString ? `${t("common:NO_RECORD")}"${searchString.trim()}"` : t("common:NO_RECORD_FILTER")}
      </NoRecordsSecondaryText>
      <ShowRecord title={t("common:GO_TO_LIST", { moduleType: t(objectType) })} onClick={resetHandler}>
        {t(locales.ShowRecord, { moduleType: t(objectType) })}
      </ShowRecord>
    </FixedContainer>
  );
};

export default NoRecord;
