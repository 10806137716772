const UserSettingConstants = {
  GET_APP_LINKS: "GET_APP_LINKS",
  GET_APP_LINKS_RESPONSE: "GET_APP_LINKS_RESPONSE",
  GET_TOU_LINKS: "GET_TOU_LINKS",
  GET_TOU_LINKS_RESPONSE: "GET_TOU_LINKS_RESPONSE",
  OM_GET_USER_SETTING_LOCATION: "OM_GET_USER_SETTING_LOCATION",
  OM_USER_SETTING_LOCATIONS_RESPONSE: "OM_USER_SETTING_LOCATIONS_RESPONSE",
  UM_GET_ACTIVATION_USER_INFO: "UM_GET_ACTIVATION_USER_INFO",
  UM_GET_ACTIVATION_USER_INFO_SUCCESS: "UM_GET_ACTIVATION_USER_INFO_SUCCESS",
  UM_GET_USER_SETTING: "UM_GET_USER_SETTING",
  UM_GET_USER_SETTING_DETAIL: "UM_GET_USER_SETTING_DETAIL",
  UM_PUT_USER_SETTING: "UM_PUT_USER_SETTING",
  UM_USER_SETTING_DETAIL_RESPONSE: "UM_GET_USER_SETTING_DETAIL_RESPONSE",
  UM_USER_SETTING_RESPONSE: "UM_USER_SETTING_RESPONSE",
  UPDATE_USER_LANGUAGE: "UPDATE_USER_LANGUAGE",
};

export default UserSettingConstants;
