/**
 * @description Action constants for the charges location module
 *
 */
const ChargesLocationActionConstants = {
  CH_CHARGE_LOCATIONS_ATTRIBUTES: "CH_CHARGE_LOCATIONS_ATTRIBUTES",
  CH_CHARGE_LOCATIONS_ATTRIBUTES_SUCCESS: "CH_CHARGE_LOCATIONS_ATTRIBUTES_SUCCESS",
  CH_CHARGE_LOCATIONS_CLEAR_RESPONSE: "CH_CHARGE_LOCATIONS_CLEAR_RESPONSE",
  CH_CHARGE_LOCATIONS_CUSTOM_VIEW: "CH_CHARGE_LOCATIONS_CUSTOM_VIEW",
  CH_CHARGE_LOCATIONS_CUSTOM_VIEW_SUCCESS: "CH_CHARGE_LOCATIONS_CUSTOM_VIEW_SUCCESS",
  CH_CHARGE_LOCATIONS_FILTER_FIELD_SUCCESS: "CH_CHARGE_LOCATIONS_FILTER_FIELD_SUCCESS",
  CH_CHARGE_LOCATIONS_GET_FILTER_FIELD: "CH_CHARGE_LOCATIONS_GET_FILTER_FIELD",
  CH_CHARGE_LOCATIONS_GET_LIST: "CH_CHARGE_LOCATIONS_GET_LIST",
  CH_CHARGE_LOCATIONS_SELECT_ROW: "CH_CHARGE_LOCATIONS_SELECT_ROW",
  CH_CHARGE_LOCATIONS_SET_LIST_RESPONSE: "CH_CHARGE_LOCATIONS_SET_LIST_RESPONSE",
  CH_CHARGE_LOCATIONS_UNSELECT_ROW: "CH_CHARGE_LOCATIONS_UNSELECT_ROW",
  CH_CHARGE_LOCATION_DETAILS_CLEAR_RESPONSE: "CH_CHARGE_LOCATION_DETAILS_CLEAR_RESPONSE",
  CH_CHARGE_LOCATION_DETAILS_SET_RESPONSE: "CH_CHARGE_LOCATION_DETAILS_SET_RESPONSE",
  CH_CHARGE_LOCATION_GET_DETAILS: "CH_CHARGE_LOCATION_GET_DETAILS",
  CH_DISPLAY_EDIT_DIALOG: "CH_DISPLAY_EDIT_DIALOG",
  CH_RESET_COMPANY_SETTINGS: "CH_RESET_COMPANY_SETTINGS",
  CH_ROOT_CHARGE_LOCATION_DETAILS_SET_RESPONSE: "CH_ROOT_CHARGE_LOCATION_DETAILS_SET_RESPONSE",
  CH_ROOT_CHARGE_LOCATION_GET_DETAILS: "CH_ROOT_CHARGE_LOCATION_GET_DETAILS",
  CH_SET_FILTER_APPLIED_FIELD: "CH_SET_FILTER_APPLIED_FIELD",
  CH_UPDATE_COST_LOCATION_SETTINGS: "CH_UPDATE_COST_LOCATION_SETTINGS",
  CH_UPDATE_COST_LOCATION_SETTINGS_SUCCESS: "CH_UPDATE_COST_LOCATION_SETTINGS_SUCCESS",
  SET_CHARGE_LOCATIONS_FILTER_QUERY: "SET_CHARGE_LOCATIONS_FILTER_QUERY",
  SET_CHARGE_LOCATIONS_SORT_QUERY: "SET_CHARGE_LOCATIONS_SORT_QUERY",
  SET_COST_LOCATIONS_LIST_FIELDS_QUERY: "SET_COST_LOCATIONS_LIST_FIELDS_QUERY",
  SET_COST_LOCATIONS_SEARCH_STRING: "SET_COST_LOCATIONS_SEARCH_STRING",
  RESET_COST_LOCATIONS_QUERY_PARAM: "RESET_COST_LOCATIONS_QUERY_PARAM",
};

export default ChargesLocationActionConstants;
