import { ISagaModule } from "redux-dynamic-modules-saga";
import ICompanyModuleState from "./redux/iCompanyModuleState";
import companyReducers from "./redux/companyReducers";
import companySaga from "./redux/companySaga";

export const _companyModule: ISagaModule<ICompanyModuleState> = {
  id: "companyModule",
  reducerMap: {
    Company: companyReducers,
  },
  sagas: [companySaga],
};

export default _companyModule;
