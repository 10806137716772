import { DialogContainer, Footer, IconName, NotificationModal } from "am-web-ui-shared/components";
import { SharedUtils, CheckAuthorization, HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import { AssetServiceActions } from "custom-actions";
import React from "react";
import { withTranslation } from "react-i18next";
// @ts-ignore
import { connect } from "react-redux";
import { change, formValueSelector, submit } from "redux-form";
// @ts-ignore
import SuccessIcon from "TARGET_BUILD/ui-assets/icons/positive_feedback_small.svg";
// @ts-ignore
import { SortList } from "TARGET_BUILD/config";
import { clearRowSelection, selectRow } from "../../actions/listActions";
import { getCurrenciesList } from "../../modules/masterData/masterDataActionCreator";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import GTMConstants from "../../gtmConstants";
import {
  clearServiceTemplateDetails,
  fetchServiceTemplatesList,
  getServiceTemplateDetails,
} from "../../modules/administration/asset/service/actions/serviceTemplateActions";
import { fetchAssetDetails } from "../../modules/asset/actions/assetsActionRoot";
import {
  appendServiceList,
  assignHistoryService,
  clearAssetServiceList,
  clearAssignedServiceData,
  clearAssignedServiceList,
  clearCompleteServiceData,
  clearServiceData,
  closeServiceFilter,
  completeAssignedService,
  editAssignedService,
  fetchAssetServiceList,
  getAssignServiceDetail,
  getAssignServicesList,
  removeAddAssetService,
  removeAssetService,
  resetQueryObjToDefault,
  setServiceFilterQuery,
  setSortQuery as setSortingQuery,
  showServiceForm,
  showServiceList,
} from "../../modules/asset/actions/assetServiceAction";
import { ActionItem } from "../../utils/enums/actionItemEnum";
import { ModuleName } from "../../utils/enums/moduleNameEnum";
import { ValidationConstants } from "../../utils/enums/validationConstants";
import { getSelectedRowDetail } from "../../utils/list/listUtils";
import { IServiceListContainerProps, IServiceListContainerState } from "./model/iserviceList";
import { AssetInfo } from "./presentational/assetInfo";
import ServiceListView, { serviceTemplateGridSetting } from "./presentational/serviceList";
import ServicesSummary from "./presentational/servicesSummary";
import ServiceFilterContainer from "./serviceFilterContainer";
import { FieldType, ServiceFormEnum, ServiceStatus } from "./serviceFormController/serviceFormEnum";
export class ServiceViewContainer extends React.Component<IServiceListContainerProps, IServiceListContainerState> {
  SERVICE_MANAGE = "service.manage";

  ActionBarSettings = {
    actionItems: [
      {
        authKey: this.SERVICE_MANAGE,
        dependencies: {
          disable: "disableAdd",
          toolTip: "addToolTip",
        },
        iconName: IconName.Assign,
        isDisable: false,
        isVisible: true,
        name: IconName.Assign,
        showTooltipOnDisable: false,
        showTooltipOnEnable: false,
        tooltipPosition: "bottom",
        type: "TextWithIcon",
      },
      {
        authKey: this.SERVICE_MANAGE,
        dependencies: { disable: "disableItem", toolTip: "editTooltip", visible: "actionItemVisible" },
        isDisable: true,
        isVisible: true,
        name: IconName.Edit,
        showTooltipOnDisable: false,
        showTooltipOnEnable: false,
        tooltipPosition: "bottom",
        type: "TextWithIcon",
      },
      {
        authKey: this.SERVICE_MANAGE,
        dependencies: { disable: "disableComplete", toolTip: "completeToolTip", visible: "actionItemVisible" },
        isDisable: true,
        isVisible: true,
        name: IconName.Complete,
        showTooltipOnDisable: false,
        showTooltipOnEnable: false,
        tooltipPosition: "bottom",
        type: "TextWithIcon",
      },
      {
        authKey: "service.delete",
        dependencies: { disable: "disableRemove", toolTip: "removeTooltip" },
        iconName: "RemoveAll",
        isDisable: true,
        isVisible: true,
        name: IconName.Remove,
        showTooltipOnDisable: false,
        showTooltipOnEnable: false,
        tooltipPosition: "bottom",
        type: "TextWithIcon",
      },
    ],
    actionViews: [
      {
        dependencies: { visible: "removeSort" },
        isDisable: false,
        isVisible: true,
        name: IconName.Sort,
        showTooltipOnDisable: true,
        showTooltipOnEnable: true,
        tooltipPosition: "bottom",
        type: "TextWithIcon",
      },
    ],
    moduleType: {
      moduleName: ModuleName.services.toUpperCase(),
    },
  };
  state = {
    serviceStatus: this.props.serviceStatusType,
    selectedServiceInfo: getSelectedRowDetail(this.props.service[this.props.serviceStatusType].list),
  };

  componentWillUnmount() {
    this.props.clearAssignedServiceData();
    this.props.clearServiceTemplateDetails();
  }

  handleServiceList = (item) => {
    if (this.props.actionType === ActionItem.edit) {
      this.getServicesList(this.state.serviceStatus);
      this.props.getAssetDetails(this.props.assetId);
    } else if (this.props.actionType === ActionItem.remove) {
      this.props.getAssetDetails(this.props.assetId);
    } else {
      this.setServiceStatusFilterState([{ value: item.serviceStatus }]);
      this.props.getServiceTemplateDetails(item.serviceId, item);
    }
  };

  checkOpenServiceCount = (prevProps) =>
    this.props.selectedAssetDetail.openServicesCount &&
    this.props.selectedAssetDetail.openServicesCount !== prevProps.selectedAssetDetail.openServicesCount;

  checkHistoryServiceCount = (prevProps) =>
    this.props.selectedAssetDetail.historyServicesCount &&
    this.props.selectedAssetDetail.historyServicesCount !== prevProps.selectedAssetDetail.historyServicesCount;

  componentDidMount() {
    if (this.props.actionType === ActionItem.edit) {
      this.setSortQuery();
      this.props.fetchAssetServiceList(0, this.props.assetId, this.state.serviceStatus);
    } else {
      SharedUtils.pushDataToGA(GTMConstants.EVENTS.MANAGE_SERVICES_VIA_ADD_ASSET);
    }
  }
  setSortQuery = () => {
    if (this.state.serviceStatus === ServiceStatus.OPEN) {
      this.props.setSortQuery({
        sortField: SortList.ASSET_SERVICE.DEFAULT_SORT.name,
        sortType: SortList.ASSET_SERVICE.DEFAULT_SORT.sortType,
      });
    } else if (this.state.serviceStatus === ServiceStatus.HISTORY) {
      this.props.setSortQuery({
        sortField: SortList.ASSET_SERVICE.DEFAULT_HISTORY_SORT.name,
        sortType: SortList.ASSET_SERVICE.DEFAULT_HISTORY_SORT.sortType,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      !HelperLodash.isEqual(
        this.props.service[this.state.serviceStatus].list,
        prevProps.service[this.state.serviceStatus].list,
      )
    ) {
      this.setState({ selectedServiceInfo: getSelectedRowDetail(this.props.service[this.state.serviceStatus].list) });
    }
  };

  clearRowSelection = () => {
    const selectedRow = this.state.selectedServiceInfo;
    if (selectedRow) {
      this.props.onClearRowSelection(selectedRow.id, AssetServiceActions.ASSET_SERVICE_UNSELECT_ROW);
    }
  };

  setServiceStatusFilterState = (currentFilterStatus) => {
    this.clearRowSelection();
    this.props.clearAssignedServiceData();
    this.setState({
      serviceStatus: currentFilterStatus[0].value,
    });
  };
  getServicesList = (status: string) => {
    this.props.fetchAssetServiceList(0, this.props.assetId, status);
  };
  closeServiceList = () => {
    this.props.showServiceList(false);
    if (this.props.closeServices) {
      this.props.closeServices();
    }
  };
  loadPaginatedData = () => {
    const { service } = this.props;
    const nextOffset = (service.offset || 0) + (service.limit || ValidationConstants.MAX_LENGTH.NUM_100);
    if (nextOffset < service[this.state.serviceStatus].totalRecords) {
      this.props.fetchAssetServiceList(nextOffset, this.props.assetId, this.state.serviceStatus);
    }
  };

  /**
   * handle click on row
   */
  handleRowClick = (data) => {
    this.props.closeMoreFilter();
    if (data && data.isSelected) {
      this.props.onClearRowSelection(data.id, AssetServiceActions.ASSET_SERVICE_UNSELECT_ROW);
    } else {
      if (this.props.actionType === ActionItem.edit) {
        this.props.getAssignServiceDetail(this.props.assetId, data.id, this.state.serviceStatus);
      }
      this.state.serviceStatus === ServiceStatus.OPEN
        ? this.props.onSelectRow(data.id, AssetServiceActions.ASSET_OPEN_SERVICE_SELECT_ROW, false)
        : this.props.onSelectRow(data.id, AssetServiceActions.ASSET_HISTORY_SERVICE_SELECT_ROW, false);
    }
    this.props.closeMoreFilter();
  };

  handleRemoveConfirm = (serviceId: number) => {
    if (this.props.actionType === ActionItem.edit) {
      this.props.removeAssetService(this.props.assetId, serviceId, this.state.serviceStatus);
    } else {
      this.props.removeAddAssetService(this.state.selectedServiceInfo, this.state.serviceStatus);
      this.props.showToaster(ToastrType.success, this.props.t("common:REMOVED_SUCCESSFULLY"));
    }
  };

  resetHandler = () => {
    this.props.resetQueryObjToDefault();
    this.props.fetchAssetServiceList(0, this.props.assetId, this.props.serviceStatusType);
  };

  clickHandler = () => {
    this.setServiceStatusFilterState([
      {
        label: this.props.t("services:HISTORIC_SERVICES"),
        value: ServiceStatus.HISTORY,
      },
    ]);
  };
  render() {
    const {
      actionType,
      assetId,
      selectedAssetDetail,
      serviceOperationError,
      assignServiceSuccess,
      assignedServiceDetail,
      assignedServicesList,
      closeMoreFilter,
      currenciesList,
      completionDate,
      completeServiceResponse,
      costCurrency,
      getAssignedServicesList,
      onFormchange,
      serviceValue,
      scheduleDate,
      showToaster,
      t,
      assetFieldsData,
      assetLocationsList,
      assetStatusList,
      manufacturerList,
      responsibleWorkersList,
      sortQuery,
      showManageServices,
      isSortListUpdate,
      ignoreSortProperties,
      setSortQuery,
      isAssetDetails,
      submitForm,
      showNoRecordFoundView,
      distanceUnitValue,
    } = this.props;

    const currentServiceList = this.props.service[this.state.serviceStatus];

    const renderServiceSummary = () => {
      return (
        <ServicesSummary
          actionType={actionType}
          assignHistoryService={this.props.assignHistoryService}
          assetId={assetId}
          closeMoreFilter={closeMoreFilter}
          onFormchange={onFormchange}
          serviceOperationError={serviceOperationError}
          getCurrenciesList={this.props.getCurrenciesList}
          currenciesList={currenciesList}
          serviceValue={serviceValue}
          assignedServicesList={assignedServicesList}
          getAssignServicesList={getAssignedServicesList}
          getServicesList={this.getServicesList}
          serviceList={currentServiceList.list}
          clearServiceData={this.props.clearServiceData}
          handleServiceList={this.handleServiceList}
          submitForm={submitForm}
          serviceStatus={this.state.serviceStatus}
          assignServiceSuccess={assignServiceSuccess}
          scheduleDate={scheduleDate}
          completionDate={completionDate}
          costCurrency={costCurrency}
          showServiceForm={this.props.showServiceForm}
          showManageServices={showManageServices}
          showToaster={showToaster}
          assignedServiceDetail={assignedServiceDetail}
          actionBarSettings={this.ActionBarSettings}
          isSortListUpdate={isSortListUpdate}
          ignoreSortProperties={ignoreSortProperties}
          columns={serviceTemplateGridSetting.columns}
          defaultSort={{ sortField: "serviceName", sortType: "+" }}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          assetFieldsData={isAssetDetails ? selectedAssetDetail : assetFieldsData}
          editAssignedService={this.props.editAssignedService}
          completeAssignedService={this.props.completeAssignedService}
          handleRemoveConfirm={this.handleRemoveConfirm}
          moduleName={ModuleName.asset}
          totalRecords={currentServiceList.totalRecords}
          setServiceFilterQuery={this.props.setServiceFilterQuery}
          defaultCurrency={this.props.defaultCurrency}
          getServiceTemplateDetails={this.props.getServiceTemplateDetails}
          clearAssignedServiceList={this.props.clearAssignedServiceList}
          serviceTemplateDetails={this.props.serviceTemplateDetails}
          clearServiceTemplateDetails={this.props.clearServiceTemplateDetails}
          allAssignedService={this.props.service}
          selectedServiceInfo={this.state.selectedServiceInfo}
          distanceUnitValue={distanceUnitValue}
        />
      );
    };

    return (
      <>
        <AssetInfo
          assetFieldsData={isAssetDetails ? selectedAssetDetail : assetFieldsData}
          assetLocationsList={assetLocationsList}
          assetStatusList={assetStatusList}
          manufacturerList={manufacturerList}
          responsibleWorkersList={responsibleWorkersList}
          isAssetDetails={isAssetDetails}
          t={t}
        />
        <DialogContainer>
          <ServiceFilterContainer
            assetId={this.props.assetId}
            serviceStatusType={this.state.serviceStatus}
            actionType={actionType}
            setServiceStatusFilterState={this.setServiceStatusFilterState}
            setServiceFilterQuery={this.props.setServiceFilterQuery}
            setSortQuery={this.props.setSortQuery}
          />
          {((currentServiceList && currentServiceList.list && currentServiceList.list.length > 0) ||
            showNoRecordFoundView) && (
            <CheckAuthorization yes={renderServiceSummary} permissionKey={"service.manage"} />
          )}
          <ServiceListView
            assignedServiceDetail={assignedServiceDetail}
            actionType={actionType}
            assetFieldsData={isAssetDetails ? selectedAssetDetail : assetFieldsData}
            serviceOperationError={serviceOperationError}
            assignHistoryService={this.props.assignHistoryService}
            assetId={assetId}
            onFormchange={onFormchange}
            getCurrenciesList={this.props.getCurrenciesList}
            currenciesList={currenciesList}
            serviceList={currentServiceList}
            serviceValue={serviceValue}
            getServicesList={this.getServicesList}
            clearServiceData={this.props.clearServiceData}
            assignServiceSuccess={assignServiceSuccess}
            handleServiceList={this.handleServiceList}
            serviceStatus={this.state.serviceStatus}
            showServiceList={showServiceList}
            scheduleDate={scheduleDate}
            submitForm={submitForm}
            completionDate={completionDate}
            costCurrency={costCurrency}
            showServiceForm={this.props.showServiceForm}
            handleRowClick={this.handleRowClick}
            showToaster={showToaster}
            loadPaginatedData={this.loadPaginatedData}
            moduleName={ModuleName.asset}
            getAssignServicesList={getAssignedServicesList}
            assignedServicesList={assignedServicesList}
            showNoRecordFoundView={showNoRecordFoundView}
            resetHandler={this.resetHandler}
            defaultCurrency={this.props.defaultCurrency}
            getServiceTemplateDetails={this.props.getServiceTemplateDetails}
            clearAssignedServiceList={this.props.clearAssignedServiceList}
            serviceTemplateDetails={this.props.serviceTemplateDetails}
            clearServiceTemplateDetails={this.props.clearServiceTemplateDetails}
            allAssignedService={this.props.service}
            isMoreFilterOpen={this.props.isMoreFilterOpen}
            selectedServiceInfo={this.state.selectedServiceInfo}
            distanceUnitValue={distanceUnitValue}
          />
        </DialogContainer>
        {currentServiceList.totalRecords > 0 && (
          <Footer buttonType="cta" onClick={this.closeServiceList} buttonText={t("common:DONE")} type="submit" />
        )}
        {completeServiceResponse && (
          <NotificationModal
            notificationIcon={SuccessIcon}
            message={t("common:SAVED_SUCCESSFULLY")}
            actionInfo={{ name: t("common:SHOW"), clickHandler: this.clickHandler }}
            handleClose={this.props.clearCompleteAssignedServiceData}
          />
        )}
      </>
    );
  }
}
const selector = formValueSelector(ServiceFormEnum.SERVICE_FORM_NAME);
const assetSelector = formValueSelector("addAssets");
const isFilterPanelOpen = (state) => state.AssetService && state.AssetService.isFilterPanelOpen;
const getDistanceUnitValue = (state) => state.Company?.companyDetails?.distanceUnitValue;
const getAssetDetails = (state) => state.Assets && state.Assets.selectedAssetDetail;
const getAssignServiceStatus = (state) => state.AssetService && state.AssetService.assignServiceSuccess;
const getAssignedServiceDetails = (state) => state.AssetService && state.AssetService.assignedServiceDetail;
const getAssignedServiceList = (state) => state.AssetService && state.AssetService.assignedServicesList;
const getAddEditResponse = (state) => state.assetTemplates && state.assetTemplates.addEditResponse;
const getCurrencyList = (state) => state.MasterData.currenciesList;
const getDefaultCurrency = (state) =>
  state.Company && state.Company.companyDetails && state.Company.companyDetails.currency;
const getFilterQuery = (state) => state.Assets && state.Assets.filterQuery;
const getProviderFilter = (state) => state.AssetService && state.AssetService.provider;
const getServiceDetails = (state) => state.AssetService && state.AssetService.service;
const getServiceFilter = (state) => state.AssetService && state.AssetService.serviceFilterAttributes;
const getServiceOperationError = (state) => state.AssetService && state.AssetService.serviceOperationError;
const getServiceTemplatesData = (state) => state.Services && state.Services.serviceTemplateDetails;
const getManageServicesStatus = (state) => state.AssetService && state.AssetService.showManageServices;
const getNoRecordFoundViewStatus = (state) => state.AssetService && state.AssetService.showNoRecordFoundView;
const getSortQuery = (state) => state.AssetService && state.AssetService.sortQuery;
const getTotalRecords = (state) => state.AssetService && state.AssetService.service.totalRecords;
const getServiceCompletionResponse = (state) => state.AssetService && state.AssetService.completeServiceResponse;

export const mapStateToProps = (state) => ({
  selectedAssetDetail: getAssetDetails(state),
  distanceUnitValue: getDistanceUnitValue(state),
  assetFieldsData: assetSelector(
    state,
    "manufacturerName",
    "name",
    "model",
    "modelName",
    "responsibleEmployeeId",
    "currentLocationName",
    "scanCode",
    "inventoryNumber",
    "statusCode",
    "assetSubTypeCode",
  ),
  assignServiceSuccess: getAssignServiceStatus(state),
  assignedServiceDetail: getAssignedServiceDetails(state),
  assignedServicesList: getAssignedServiceList(state),
  clearAppliedFilter: getAddEditResponse(state),
  completeServiceResponse: getServiceCompletionResponse(state),
  completionDate: selector(state, FieldType.COMPLETION_DATE),
  costCurrency: selector(state, FieldType.COST_CURRENCY),
  currenciesList: getCurrencyList(state),
  defaultCurrency: getDefaultCurrency(state),
  filterQuery: getFilterQuery(state),
  isMoreFilterOpen: isFilterPanelOpen(state),
  provider: getProviderFilter(state),
  scheduleDate: selector(state, FieldType.SCHEDULE_DATE),
  service: getServiceDetails(state),
  serviceFilterAttributes: getServiceFilter(state),
  serviceOperationError: getServiceOperationError(state),
  serviceResult: selector(state, FieldType.SERVICE_RESULT),
  serviceTemplateDetails: getServiceTemplatesData(state),
  serviceValue: selector(state, ServiceFormEnum.SERVICE),
  showManageServices: getManageServicesStatus(state),
  showNoRecordFoundView: getNoRecordFoundViewStatus(state),
  sortQuery: getSortQuery(state),
  totalRecords: getTotalRecords(state),
});

export const mapDispatchToProps = (dispatch) => ({
  appendServiceList: (row) => dispatch(appendServiceList(row)),
  assignHistoryService: (assetId, serviceData, serviceStatus) =>
    dispatch(assignHistoryService(assetId, serviceData, serviceStatus)),
  clearAssetServiceList: () => dispatch(clearAssetServiceList()),
  clearAssignedServiceData: () => dispatch(clearAssignedServiceData()),
  clearAssignedServiceList: () => dispatch(clearAssignedServiceList()),
  clearCompleteAssignedServiceData: () => dispatch(clearCompleteServiceData()),
  clearServiceData: () => dispatch(clearServiceData()),
  clearServiceTemplateDetails: () => dispatch(clearServiceTemplateDetails()),
  closeMoreFilter: () => dispatch(closeServiceFilter()),
  completeAssignedService: (assetId: number, serviceData: any) =>
    dispatch(completeAssignedService(assetId, serviceData)),

  editAssignedService: (
    assetId: number,
    serviceData: string,
    serviceId,
    serviceStatus: string,
    isRecurrentDateChange = false,
  ) => dispatch(editAssignedService(assetId, serviceData, serviceId, serviceStatus, isRecurrentDateChange)),
  fetchAssetServiceList: (offset: number, assetId: number, status: string, filters: string) =>
    dispatch(fetchAssetServiceList(offset, assetId, status, filters)),
  getAssetDetails: (paramAssetId) => dispatch(fetchAssetDetails(paramAssetId, true)),
  getAssignServiceDetail: (assetId, serviceId, serviceStatus) =>
    dispatch(getAssignServiceDetail(assetId, serviceId, serviceStatus)),
  getAssignedServicesList: (assetId?: number, actionType?: string) =>
    dispatch(getAssignServicesList(assetId, actionType)),
  getCurrenciesList: () => getCurrenciesList(),
  getServiceTemplateDetails: (id: number, moduleInfo: any) => dispatch(getServiceTemplateDetails(id, moduleInfo)),
  getServiceTemplateList: (queryObj: { limit: number; offset: number }) =>
    dispatch(fetchServiceTemplatesList(queryObj)),
  onClearRowSelection: (id, source) => dispatch(clearRowSelection(id, source)),
  onFormchange: (serviceFormName: string, field: string, value: any) => dispatch(change(serviceFormName, field, value)),
  onSelectRow: (id, source, isMultiSelect) => dispatch(selectRow(id, source, isMultiSelect)),
  removeAddAssetService: (rowData, status) => dispatch(removeAddAssetService(rowData, status)),
  removeAssetService: (assetId: number, serviceId: number, serviceStatus: string) =>
    dispatch(removeAssetService(assetId, serviceId, serviceStatus)),
  resetQueryObjToDefault: () => dispatch(resetQueryObjToDefault()),
  setServiceFilterQuery: (filterQuery) => dispatch(setServiceFilterQuery(filterQuery)),
  setSortQuery: (sortQuery: any) => dispatch(setSortingQuery(sortQuery)),
  showServiceForm: (isOpen) => dispatch(showServiceForm(isOpen)),
  showServiceList: (isOpen) => dispatch(showServiceList(isOpen)),
  showToaster: (type: ToastrType, message: string) => dispatch(showToastr(type, message)),
  submitForm: (formName) => dispatch(submit(formName)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ServiceViewContainer));
