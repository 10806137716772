import React from "react";
const SVG = () => (
  <svg width="18px" height="23px" viewBox="0 0 18 23" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3533.000000, -337.000000)" fillRule="nonzero">
        <g transform="translate(3530.000000, 336.000000)">
          <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
          <path
            d="M17.7326158,19.4908939 L20.3749999,19.4908939 L20.3749999,21.2524833 L17.7326158,21.2524833 L17.7326158,23.8948674 L15.9710264,23.8948674 L15.9710264,21.2524833 L13.3286423,21.2524833 L13.3286423,19.4908939 L15.9710264,19.4908939 L15.9710264,16.8485098 L17.7326158,16.8485098 L17.7326158,19.4908939 Z M11.4569536,19.4908939 L3.75,19.4908939 L3.75880796,1.875 L12.5579469,1.875 L17.8427151,7.15976816 L17.8427151,14.9768211 L14.959437,14.9768211 L14.959437,17.9495032 L11.4569536,17.9495032 L11.4569536,19.4908939 Z M11.1328125,8.296875 L11.1328125,10.078125 L15.140625,10.078125 L15.140625,8.296875 L11.1328125,8.296875 Z M6.234375,8.296875 L6.234375,10.078125 L10.2421875,10.078125 L10.2421875,8.296875 L6.234375,8.296875 Z M11.1328125,10.96875 L11.1328125,12.75 L15.140625,12.75 L15.140625,10.96875 L11.1328125,10.96875 Z M6.234375,13.640625 L6.234375,15.421875 L10.2421875,15.421875 L10.2421875,13.640625 L6.234375,13.640625 Z M6.234375,10.96875 L6.234375,12.75 L10.2421875,12.75 L10.2421875,10.96875 L6.234375,10.96875 Z"
            id="Combined-Shape"
            fill="#524F53"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
