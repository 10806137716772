import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M18,9 L18,21 L6,21 L6,9 L18,9 Z M14,2 L14,4 L19,4 L19,7 L5,7 L5,4 L10,4 L10,2 L14,2 Z" />
    </g>
  </svg>
);

export default SVG;
