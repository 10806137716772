/**
 * @description - List of action constants that will be used for for multiple actions associated with the asset templates list.
 */
const AssetTemplatesConstants = {
  ADD_ASSET_TEMPLATES_ERROR: "ADD_ASSET_TEMPLATES_ERROR",
  ADD_ASSET_TEMPLATES_SUCCESS: "ADD_ASSET_TEMPLATES_SUCCESS",
  AM_ASSETTEMPLATE_LIST_LOADER_END: "AM_ASSETTEMPLATE_LIST_LOADER_END",
  AM_ASSETTEMPLATE_LIST_LOADER_START: "AM_ASSETTEMPLATE_LIST_LOADER_START",
  AM_ASSET_TEMPLATES_RESET_QUERY_PARAM: "AM_ASSET_TEMPLATES_RESET_QUERY_PARAM",
  AM_ASSET_TEMPLATES_SELECT_ROW: "AM_ASSET_TEMPLATES_SELECT_ROW",
  AM_ASSET_TEMPLATES_UNSELECT_ROW: "AM_ASSET_TEMPLATES_UNSELECT_ROW",
  AM_GET_ASSETTEMPLATES_INFO: "AM_GET_ASSETTEMPLATES_INFO",
  AM_GET_ASSET_TEMPLATES_FILTER_FIELD: "AM_GET_ASSET_TEMPLATES_FILTER_FIELD",
  AM_GET_ASSET_TEMPLATES_LIST: "AM_GET_ASSET_TEMPLATES_LIST",
  AM_GET_ASSET_TEMPLATES_LIST_FOR_SEARCH: "AM_GET_ASSET_TEMPLATES_LIST_FOR_SEARCH",
  AM_GET_MODELS_LIST: "AM_GET_MODELS_LIST",
  AM_GET_SELECTED_SERVICE_TEMPLAE_LIST: "AM_GET_SELECTED_SERVICE_TEMPLAE_LIST",
  AM_MERGE_ASSETTEMPLATES_REQUEST: "AM_MERGE_ASSETTEMPLATES_REQUEST",
  AM_MERGE_ASSETTEMPLATES_RESPONSE: "AM_MERGE_ASSETTEMPLATES_RESPONSE",
  AM_PUT_ASSET_TEMPLATES: "AM_PUT_ASSET_TEMPLATES",
  AM_SET_ASSET_TEMPLATES_FILTER_APPLIED_FIELD: "AM_SET_ASSET_TEMPLATES_FILTER_APPLIED_FIELD",
  AM_SET_ASSET_TEMPLATES_SEARCH_QUERY: "AM_SET_ASSET_TEMPLATES_SEARCH_QUERY",
  AM_SET_ASSET_TEMPLATES_SORT_QUERY: "AM_SET_ASSET_TEMPLATES_SORT_QUERY",
  AM_SET_SELECTED_ASSETTEMPLATES_INFO: "AM_SET_SELECTED_ASSETTEMPLATES_INFO",
  AM_TEMPLATE_ADD_EDIT: "AM_TEMPLATE_ADD_EDIT", // use to add edit api call
  ASSET_TEMPLATES_SELECT_ALL_ROWS: "ASSET_TEMPLATES_SELECT_ALL_ROWS",
  ASSET_TEMPLATES_SET_SELECTED_ROW: "ASSET_TEMPLATES_SET_SELECTED_ROW",
  ASSET_TEMPLATES_UNSELECT_ALL_ROWS: "ASSET_TEMPLATES_UNSELECT_ALL_ROWS",
  ASSET_TEMPLATE_FILTER_FIELD_SUCCESS: "ASSET_TEMPLATE_FILTER_FIELD_SUCCESS",
  ASSIGNED_ASSET_TEMPLATE_SERVICE_LIST: "ASSIGNED_ASSET_TEMPLATE_SERVICE_LIST",
  ASSIGN_ASSET_TEMPLATE_SERVICE: "ASSIGN_ASSET_TEMPLATE_SERVICE",
  ASSET_TEMPLATE_CLEAN_TEMPLATE_DATA: "ASSET_TEMPLATE_CLEAN_TEMPLATE_DATA", // clear the response of api
  CLEAR_ASSET_TEMPLATE_DELETE_FLAG: "CLEAR_ASSET_TEMPLATE_DELETE_FLAG",
  CLEAR_ASSET_TEMPLATE_DETAILS: "CLEAR_ASSET_TEMPLATE_DETAILS",
  CLEAR_TEMPLATES_LIST: "CLEAR_TEMPLATES_LIST",
  CLEAR_TEMPLATE_ASSET_LIST: "CLEAR_TEMPLATE_ASSET_LIST",
  CREATE_ASSET_TEMPLATES: "CREATE_ASSET_TEMPLATES",
  DELETE_ASSET_TEMPLATE: "DELETE_ASSET_TEMPLATE",
  DELETE_ASSET_TEMPLATE_FAIL: "DELETE_ASSET_TEMPLATE_FAIL",
  DELETE_ASSET_TEMPLATE_SUCCESS: "DELETE_ASSET_TEMPLATE_SUCCESS",
  GET_TEMPLATE_ASSET_LIST: "GET_TEMPLATE_ASSET_LIST",
  GET_TEMPLATE_DETAILS: "GET_TEMPLATE_DETAILS",
  GET_TEMPLATE_DETAILS_BY_ID: "GET_TEMPLATE_DETAILS_BY_ID",
  GET_TEMPLATE_INFO_DETAILS: "GET_TEMPLATE_INFO_DETAILS",
  PUT_ASSET_TEMPLATES: "PUT_ASSET_TEMPLATES",
  PUT_TEMPLATE_ASSET_LIST: "PUT_TEMPLATE_ASSET_LIST",
  PUT_TEMPLATE_DETAILS: "PUT_TEMPLATE_DETAILS",
  REFRESH_TEMPLATES: "REFRESH_TEMPLATES",
  REFRESH_TEMPLATES_LIST: "REFRESH_TEMPLATES_LIST",
  REMOVE_TEMPLATE_DETAILS: "REMOVE_TEMPLATE_DETAILS",
  REMOVE_TEMPLATE_SERVICE: "REMOVE_TEMPLATE_SERVICE",
  RESET_CHECKED_TEMPLATE_DETAIL: "RESET_CHECKED_TEMPLATE_DETAIL",
  RESET_MULTI_DELETE_ASSET_TEMPLATE_RESPONSE: "RESET_MULTI_DELETE_ASSET_TEMPLATE_RESPONSE",
  SEARCH_TEMPLATES: "SEARCH_TEMPLATES",
  SEARCH_TEMPLATES_LIST: "SEARCH_TEMPLATES_LIST",
  SERVICE_SELECT_ROW: "SERVICE_SELECT_ROW",
  SERVICE_UNSELECT_ROW: "SERVICE_UNSELECT_ROW",
  SET_MODELS_LIST: "SET_MODELS_LIST",
  SET_TEMPLATE_FILTER_QUERY: "SET_TEMPLATE_FILTER_QUERY",
  TEMPLATE_ADD_EDIT_RESPONSE: "TEMPLATE_ADD_EDIT_RESPONSE", // get the response od api (add/edit)
  TEMPLATE_SELECT_GRID_CHECKBOX: "TEMPLATE_SELECT_GRID_CHECKBOX",
  TEMPLATE_UNSELECT_GRID_CHECKBOX: "TEMPLATE_UNSELECT_GRID_CHECKBOX",
};

export default AssetTemplatesConstants;
