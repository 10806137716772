import React from "react";
import styled from "styled-components";
import { StyledBeforeIconContainer, StyledRowsWrapper } from "../index";
import StyledNormalText from "../Styled/NormalText/styledNormalText";
import ITextArea from "./iTextArea";
import { autoLinkText } from "../../../../common/utils/commonUtils";
/**
 * @const StyleNormalTextArea - Styled component that is having text with hilti-roman font-family and paragraphed.
 */
const StyledNormalWrapper = styled(StyledNormalText)`
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  color: #524f53;
  white-space: normal;
`;

/**
 * @export StyleNormalTextArea -  Styled component.
 */

const StyleNormalTextArea = (props: ITextArea) => {
  const { headingId, textId, text } = props;
  return (
    <StyledBeforeIconContainer id={headingId}>
      <StyledRowsWrapper>
        <StyledNormalWrapper id={textId}>{autoLinkText(text)}</StyledNormalWrapper>
      </StyledRowsWrapper>
    </StyledBeforeIconContainer>
  );
};

/**
 * @export StyleNormalTextArea - Styled Component.
 */
export default StyleNormalTextArea;
