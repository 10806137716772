import React from "react";
const SVG = () => (
  <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3238.000000, -3349.000000)" fill="#524F53">
        <g transform="translate(3236.000000, 3345.000000)">
          <path
            d="M21,16 L21,19 L24,19 L24,21 L21,21 L21,24 L19,24 L19,21 L16,21 L16,19 L19,19 L19,16 L21,16 Z M20,4 L20,14 L17,14 L17,16 L16.4,16 L14,13 L12,15 L9,12 L4,18 L14,18 L14,20 L2,20 L2,4 L20,4 Z M15.5,7 C14.6715729,7 14,7.67157288 14,8.5 C14,9.3284271 14.6715729,10 15.5,10 C16.3284271,10 17,9.3284271 17,8.5 C17,7.67157288 16.3284271,7 15.5,7 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
