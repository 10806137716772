import { ISagaModule } from "redux-dynamic-modules-saga";
import IUserLicenseModuleState from "./iUserLicenseModuleState";
import userLicenseReducer from "./userLicenseReducer";
import userLicenseSaga from "./userLicenseSaga";

export const userLicenseModule: ISagaModule<IUserLicenseModuleState> = {
  id: "userLicenseModule",
  reducerMap: {
    userLicense: userLicenseReducer,
  },
  sagas: [userLicenseSaga],
};

export default userLicenseModule;
