import DateHelpers from "../dateHelpers";
import NumberHelpers from "../numberHelpers";

class LocaleManager {
  locale: string;
  localeText: string;
  dateHelpers;
  numberHelpers;

  constructor() {
    this.dateHelpers = new DateHelpers();
    this.numberHelpers = new NumberHelpers();
  }

  initUserData(dateFormat, locale: string, localeText: string) {
    this.locale = locale;
    this.localeText = localeText;

    this.dateHelpers.init(dateFormat, locale);
  }

  initCompanyData(currencySettings, numberSettings) {
    this.numberHelpers.init(numberSettings, currencySettings);
  }
}

const localeManager = new LocaleManager();
export default localeManager;
