import React from "react";

// @ts-ignore
import styled from "styled-components";
import IItemsCountProps from "./itemsCountProps";

const SelectedCount = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 24px;
  white-space: nowrap;
  .heading {
    color: ${(props) => props.theme.colors.base.steel};
    cursor: default;
  }
  .sub-heading {
    color: #979598;
    cursor: default;
  }
`;

const CountValue = styled.span`
  padding-left: 0;
`;

export class ItemsCount extends React.Component<IItemsCountProps> {
  render() {
    const { actionBarItemInfo, actionBarSettings, setCountRef, t } = this.props;
    const moduleName = `actionBar:${actionBarSettings?.moduleType?.moduleName}`;
    const moduleNameWithoutLastCharacter = `actionBar:${actionBarSettings?.moduleType?.moduleName.slice(0, -1)}`;
    return (
      <SelectedCount id="SelectedCount" ref={(element) => setCountRef(element)}>
        <span className="heading">
          <CountValue>{actionBarItemInfo && actionBarItemInfo.totalRecords} </CountValue>
          {actionBarItemInfo && actionBarItemInfo.totalRecords > 1
            ? `${t(moduleName)}`
            : `${t(moduleNameWithoutLastCharacter)}`}
        </span>
        {actionBarItemInfo && actionBarItemInfo.noOfSelectedRecords >= 0 && (
          <span className="sub-heading">
            {actionBarItemInfo && actionBarItemInfo.noOfSelectedRecords > 0
              ? `${actionBarItemInfo.noOfSelectedRecords} ${t("actionBar:SELECTED")}`
              : `0 ${t("actionBar:SELECTED")}`}
          </span>
        )}
      </SelectedCount>
    );
  }
}

export default ItemsCount;
