// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import INormalListProps from "./iNormalList";

/**
 * @const StyledWrapper - styled wrapper
 */
const StyledWrapper = styled("div")`
  width: auto;
  word-break: break-all;
  margin: 2px;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.colors.base.steel};
  line-height: 20px;
`;

/**
 * @const StyledNormalListText - styled wrapper
 */
const StyledNormalListText = styled("div")`
  width: auto;
  word-break: break-all;
  margin: 2px;
`;
/**
 * @const NormalList is a Styled Component that consist of list of texts with roman font-family.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 */
const NormalList = (props: INormalListProps) => {
  const { id, normalTexts, uniqueKey } = props;
  return (
    <StyledWrapper id={id}>
      {normalTexts.map((item, index) => {
        return (
          <React.Fragment key={`keyNormalList${uniqueKey}${index}`}>
            <StyledNormalListText id={`lblNormalList${uniqueKey}${index}`}>{item}</StyledNormalListText>
          </React.Fragment>
        );
      })}
    </StyledWrapper>
  );
};

/**
 * @export NormalList - Styled Component.
 */
export default NormalList;
