import { SharedUtils } from "am-web-ui-shared/helpers";

export const VIEW_TRANSFER_HISTORY_ADMIN = "view_transfer_history_admin";
export const TRANSFER_HISTORY_SEARCH_RESULT_LOADED = "transfer_history_search_result_loaded";
export const TRANSFER_HISTORY_SEARCH_RESULT_ROW_CLICKED = "transfer_history_search_result_row_clicked";
export const EXPORT_TRANSFER_HISTORY = "export_transfer_history";

export const trackTransferHistoryModuleClicked = () => {
  SharedUtils.pushDataToGA(VIEW_TRANSFER_HISTORY_ADMIN);
};

export const trackTransferHistorySearchResult = (totalResults: number) => {
  SharedUtils.pushDataToGA(TRANSFER_HISTORY_SEARCH_RESULT_LOADED, null, {
    total_result_count: totalResults,
  });
};

export const trackTransferHistorySearchResultRowClicked = (transferId: number) => {
  SharedUtils.pushDataToGA(TRANSFER_HISTORY_SEARCH_RESULT_ROW_CLICKED, null, {
    transfer_id: transferId,
  });
};

export const trackExportTransferHistoryClicked = (totalResults: number) => {
  SharedUtils.pushDataToGA(EXPORT_TRANSFER_HISTORY, null, {
    total_result_count: totalResults,
  });
};
