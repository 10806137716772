import Grid from "@mui/material/Grid";
import { DatePicker } from "am-web-ui-shared/elements";
import { LocaleManager } from "am-web-ui-shared/helpers";
import React from "react";
import { getDatePickerLocale } from "../../../../utils/dateUtils";

export class DateField extends React.PureComponent<any> {
  // To identify by name which date field has been changed
  handleDateChange = (selectedDate) => {
    const { onSelectChange, name } = this.props;
    if (onSelectChange) {
      onSelectChange(name, selectedDate);
    }
  };

  render() {
    const { disabled, name, t, minDate, maxDate, validation, selected } = this.props;

    return (
      name && (
        <Grid item={true} xs={this.props.viewSize}>
          <DatePicker
            name={name}
            id={`serviceDetailPanel${name}`}
            key={`serviceDetailPanel${name}`}
            label={t(`services:FIELD_NAME_${name.toUpperCase()}`)}
            isFormControl={true}
            required={true}
            format={LocaleManager.dateHelpers.getLocalizedDatePickerFormat()}
            locale={getDatePickerLocale()}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={t("common:SELECT_DATE")}
            onSelectChange={this.handleDateChange}
            disabled={disabled}
            isClearable={this.props.isClearable}
            selected={selected}
            validation={validation}
            openToDate={LocaleManager.dateHelpers.getNativeDateInTenantTimezone()}
          />
        </Grid>
      )
    );
  }
}
