import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";
import ApplicationConstants from "../../applicationConstants";

export default class QtyItemPendingCharge {
  allocationStatus: string;
  assetGroupName: string;
  chargeCenter: string;
  workdaysOnSiteDisplay: string;
  costLocationManagerName: string;
  costType: string;
  costTypeValue: string;
  currency: string;
  currentCosts: string;
  daysOnSite: string;
  quantityItemName: string;
  stockQuantity: string;
  dateArrived: string;
  quantityItemCostId: number;
  isSelected: boolean;
  id: number;
  manufacturerName: string;
  model: string;
  dateLeft: string;
  totalRecords: number;
  inventoryNumber: string;
  scanCode: string;
  scanCodeType: string;
  stockUnit: string;
  stockUnitName: string;
  workdaysOnSite: string;
  quantityItemId: number;
  totalStockQuantity: number;

  constructor(response: any) {
    this.dateArrived = this.getFieldValue(response, "dateArrived");
    this.chargeCenter = this.getFieldValue(response, "chargeCenter");
    this.workdaysOnSiteDisplay = this.getFieldValue(response, "workdaysOnSiteDisplay");
    this.assetGroupName = this.getFieldValue(response, "assetGroupName");
    this.costLocationManagerName = this.getFieldValue(response, "costLocationManagerName");
    this.daysOnSite = this.getFieldValue(response, "daysOnSite");
    this.currency = this.getFieldValue(response, "currency");
    this.currentCosts = this.formatCostDisplay(this.getFieldValue(response, "currentCost"));
    this.costTypeValue = this.formatCostDisplay(this.getFieldValue(response, "costTypeValue"));
    this.costType = this.getFieldValue(response, "costType");
    this.allocationStatus = this.getFieldValue(response, "allocationStatus");
    this.quantityItemCostId = this.getFieldValue(response, "quantityItemCostId");
    this.isSelected = this.getFieldValue(response, "isSelected");
    this.stockQuantity = this.getFieldValue(response, "stockQuantity");
    this.id = this.getFieldValue(response, "quantityItemId");
    this.manufacturerName = this.getFieldValue(response, "manufacturerName");
    this.model = this.getFieldValue(response, "model");
    this.dateLeft = this.getFieldValue(response, "dateLeft");
    this.totalRecords = this.getFieldValue(response, "totalRecords");
    this.inventoryNumber = this.getFieldValue(response, "inventoryNumber");
    this.scanCode = this.getFieldValue(response, "scanCode");
    this.scanCodeType = this.getFieldValue(response, "scanCodeType");
    this.stockUnit = this.getFieldValue(response, "stockUnit");
    this.workdaysOnSite = this.getFieldValue(response, "workdaysOnSite");
    this.stockUnitName = this.getFieldValue(response, "stockUnit");
    this.totalStockQuantity = this.getFieldValue(response, "stockQuantity");
  }

  getFieldValue = (pendingCharge, field: string) => HelperLodash.get(pendingCharge, field, "");

  formatCostDisplay = (cost) => {
    return cost === ""
      ? ""
      : LocaleManager.numberHelpers.formatCurrency(cost, this.currency, ApplicationConstants.COUNT.CNT2);
  };
}
