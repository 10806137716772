import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M17.9800199,11 L18.3674912,12.5497235 C18.7744992,12.7013102 19.149482,12.9186783 19.4795134,13.1889017 L21.0185256,12.7491684 L21.9970911,14.4440935 L20.8581154,15.5462241 C20.895981,15.7648084 20.9157163,15.9896074 20.9157163,16.2190158 C20.9157163,16.4491279 20.8958597,16.6746022 20.8577665,16.8938189 L22,17.9994378 L21.0214345,19.6943629 L19.4755799,19.2523463 C19.1465035,19.5210785 18.7728831,19.7373233 18.3674912,19.8883081 L17.9800199,21.4380316 L16.022889,21.4380316 L15.6354177,19.8883081 C15.2300258,19.7373233 14.8564054,19.5210785 14.527329,19.2523463 L12.9814744,19.6943629 L12.0029089,17.9994378 L13.1451424,16.8938189 C13.1070492,16.6746022 13.0871926,16.4491279 13.0871926,16.2190158 C13.0871926,15.9913667 13.1066264,15.7682565 13.1439255,15.5512539 L12,14.4440935 L12.9785655,12.7491684 L14.5214303,13.1905118 C14.8519381,12.9195417 15.2276016,12.7016112 15.6354177,12.5497235 L16.022889,11 L17.9800199,11 Z M17.0014545,14.5880734 C16.1007098,14.5880734 15.370512,15.3182712 15.370512,16.2190158 C15.370512,17.1197604 16.1007098,17.8499582 17.0014545,17.8499582 C17.9021991,17.8499582 18.6323969,17.1197604 18.6323969,16.2190158 C18.6323969,15.3182712 17.9021991,14.5880734 17.0014545,14.5880734 Z M22,7 L22,11.7818182 L21.4545455,11.2363636 L19.2727273,11.7818182 L18.7272727,9.6 L14.9090909,9.6 L14.3636364,11.7818182 L11.6363636,11.2363636 L10,14.5090909 L11.6363636,16.1454545 L10.781,17 L4,17 C2.8954305,17 2,16.1045695 2,15 L2,7 L22,7 Z M20,2 C21.1045695,2 22,2.8954305 22,4 L22,5 L2,5 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 Z"></path>
    </g>
  </svg>
);

export default SVG;
