import React from "react";
import Checkbox from "../../elements/Checkbox/checkbox";
import { CellCheckbox, Row, TemplateData, TemplateDataFullWidth, ChildWrapper, CountWrapper } from "./gridStyle";
import { IRowTemplateProps } from "./iRowTemplate";
import { dependencyResolver } from "../grid/gridUtils";
import { HelperLodash } from "../../helpers";
/**
 * @description - Generic Row template accepts View model
 *
 * @class RowTemplate
 * @interface IRowTemplateProps<T>
 */
class RowTemplate<T> extends React.Component<IRowTemplateProps<T>> {
  applyStyles = (isSelect, isChecked, rowSettings, dependencies, data) => {
    let additionalText;
    const selectedClass = isSelect ? "selected" : "";
    const checkedClass = isChecked ? "selected-checked-multi " : "";
    if (HelperLodash.get(rowSettings, "customInfo.additionalText") && dependencies) {
      additionalText = dependencyResolver(dependencies, rowSettings.customInfo.additionalText)(data);
    }
    const additionalTextClass = additionalText ? "additional-text" : "";
    return `row ${selectedClass} ${checkedClass} ${additionalTextClass}`;
  };
  rowTemplateClick = (rowClick, data) => {
    return () => {
      if (rowClick) {
        rowClick(data);
      }
    };
  };
  getAdditionalText = (dependencies, rowSettings, data, rowClick) => {
    let additionalText;
    if (HelperLodash.get(rowSettings, "customInfo.additionalText") && dependencies) {
      additionalText = dependencyResolver(dependencies, rowSettings.customInfo.additionalText)(data);
    }
    if (additionalText && additionalText.length > 0) {
      return (
        <ChildWrapper className="show-more" onClick={this.rowTemplateClick(rowClick, data)}>
          <CountWrapper>{additionalText}</CountWrapper>
        </ChildWrapper>
      );
    }
    return null;
  };

  render() {
    const {
      children,
      disableCheckBoxColumn,
      selected,
      model,
      rowClick,
      id,
      rowAttributes,
      notActionable,
      rowDisabled,
      isChecked,
      indeterminate,
      checkBoxClick,
      hideRowArrow,
      dependencies,
      rowSettings,
      noHoverBackground,
    } = this.props;

    return (
      <>
        <Row
          id={id}
          data-notactionable={notActionable}
          data-selected={selected}
          data-disabled={rowDisabled}
          data-arrow={hideRowArrow}
          data-no-hover={noHoverBackground}
          className={this.applyStyles(selected, isChecked, rowSettings, dependencies, model)}
          {...rowAttributes}
        >
          {!disableCheckBoxColumn && (
            <CellCheckbox
              className={isChecked ? "cell-checkbox selected-multi" : "cell-checkbox"}
              onClick={this.rowTemplateClick(checkBoxClick, model)}
              data-testid={`checkBox_${id}_wrapper`}
            >
              <Checkbox
                disabled={true}
                noDisableDesign={true}
                checked={!!(isChecked || indeterminate)}
                id={`checkBox_${id}`}
                indeterminate={indeterminate}
              />
            </CellCheckbox>
          )}
          {disableCheckBoxColumn && (
            <TemplateDataFullWidth
              container={true}
              data-selected={selected}
              spacing={4}
              onClick={this.rowTemplateClick(rowClick, model)}
              id={`rowFullWidth_${id}`}
            >
              {children}
            </TemplateDataFullWidth>
          )}
          {!disableCheckBoxColumn && (
            <TemplateData
              container={true}
              data-selected={selected}
              spacing={4}
              onClick={this.rowTemplateClick(rowClick, model)}
              data-testid={`row-${id}-content`}
            >
              {children}
            </TemplateData>
          )}
        </Row>
        {this.getAdditionalText(dependencies, rowSettings, model, rowClick)}
      </>
    );
  }
}

export default RowTemplate;
