import React from "react";
import Activate from "./activate";
import AccessLocation from "./accessLocation";
import Add from "./add";
import AddAllocation from "./addAllocation";
import AddFolder from "./addFolder";
import AddPurchase from "./addPurchase";
import Promote from "./addUser";
import AddUser from "./addUser";
import Alert from "./alert";
import AlertSetting from "./alertSetting";
import AllocationStatus from "./allocationStatus";
import ArrowDown from "./arrowRight";
import Arrow from "./arrowRight";
import ArrowUpward from "./arrowUpward";
import AssetCosts from "./assetCosts";
import Attachment from "./attachment";
import BackToAssetList from "./backToAssetList";
import Basket from "./basket";
import Battery from "./battery";
import Bell from "./bell";
import BusinessHours from "./businessHours";
import Calendar from "./calendar";
import CallBack from "./callback";
import CartAdd from "./cartAdd";
import Category from "./category";
import Certificate from "./certificate";
import CertificateEmpty from "./certificateEmpty";
import Chat from "./chat";
import Checkmark from "./checkmark";
import Chevron from "./chevron";
import Comodity from "./commodity";
import Complete from "./complete";
import CompleteService from "./completeService";
import ConfirmTransfer from "./confirmTransfer";
import Contact from "./contact";
import CostLocation from "./costLocation";
import CostLocationEmpty from "./costLocationEmpty";
import Cross from "./crossIcon";
import DaysOfOperation from "./daysOfOperation";
import Deactivate from "./deactivate";
import DefaultTemplate from "./defaultTemplate";
import Delete from "./delete";
import Download from "./download";
import DragArrow from "./dragY";
import DropFile from "./dropFile";
import DuplicateReport from "./duplicateReport";
import DocumentChart from "./documentChart";
import Edit from "./edit";
import EditAssetsNotLinkedTemplate from "./edit";
import EditAssetsTemplate from "./editDocument";
import MultiEdit from "./editMulti";
import Email from "./email";
import EmptyAsset from "./emptyAsset";
import EmptyCost from "./emptyCosts";
import EmptyQTYItem from "./emptyQTYItem";
import ExchangeBattery from "./exchangeBattery";
import Export from "./export";
import Filter from "./filter";
import FilterWhite from "./filterWhite";
import Folder from "./folder";
import GenerateReport from "./generateReport";
import HeavyEquipment from "./heavyEquipment";
import HistoricServices from "./historicServices";
import Holiday from "./holiday";
import HomeIcon from "./home";
import UploadImage from "./imageAdd";
import Image from "./image";
import Import from "./import";
import Info from "./info";
import Label from "./labels";
import LastTransfer from "./lastTransfer";
import Link from "./link";
import ListView from "./listView";
import Location from "./location";
import LocationEmpty from "./locationEmpty";
import LocationGroup from "./locationGroup";
import LocationGroupEmpty from "./locationGroupEmpty";
import LocationJobSite from "./locationJobSite";
import LocationManager from "./locationManager";
import Logout from "./logout";
import Merge from "./merge";
import More from "./more";
import MoveCursor from "./moveCursor";
import Move from "./moveFolder";
import NoSearchResult from "./noSearchResult";
import Notes from "./notes";
import NotificationDismiss from "./notificationDismiss";
import OpenNew from "./openNew";
import Person from "./person";
import PersonGroup from "./personGroup";
import PlugCost from "./plugCost";
import Feedback from "./positiveFeedback";
import QuickTransfer from "./quickTransfer";
import Radio from "./radio";
import RadioChecked from "./radioChecked";
import RecallTransfer from "./recallTransfer";
import Refresh from "./refresh";
import Remove from "./remove";
import RemoveCircle from "./removeCircle";
import Repeat from "./repeat";
import Clone from "./clone";
import RescheduleService from "./rescheduleService";
import Resync from "./resync";
import Search from "./search";
import Send from "./send";
import Services from "./services";
import Setting from "./setting";
import SettingCompany from "./settingCompany";
import SettingUser from "./settingUser";
import ShelfBox from "./shelfBox";
import SortAscending from "./sortAscending";
import SortDescending from "./sortDescending";
import Star from "./star";
import Status from "./status";
import StockChange from "./stockChange";
import StorageAsset from "./storageAsset";
import StoredAsset from "./storedAsset";
import Success from "./success";
import TemplateEmpty from "./templateEmpty";
import Time from "./time";
import TransferAdd from "./transferAdd";
import TransferCart from "./transferCart";
import TransferCartFull from "./transferCartFull";
import TransferHistory from "./transferHistory";
import Truck from "./truck";
import UserEmpty from "./userEmpty";
import UserGroupEmpty from "./userGroupEmpty";
import EllipsisVertical from "./verticalEllipsis";
import View from "./view";
import ViewRequest from "./viewRequest";
import WareHouse from "./warehouse";
import Warning from "./warning";
import Worker from "./worker";
import Purchase from "./purchase";
import Repair from "./repair";
import HiltiServices from "./hiltiServices";
import RepairHiltiAssets from "./repairHiltiAssets";
import IdleAsset from "./idleAsset";
import FullScreen from "./fullScreen";
import RepairStatus from "./repairStatus";
import AssetCapSetting from "./assetCapSetting";
import LockIcon from "./lockIcon";
import BriefCase from "./briefcase";
import Container from "./container";
import VanGateway from "./vanGateway";
import TelematicsGateway from "./telematicsGateway";
import RenewTool from "./renewTool";
import SmartInventory from "./smartInventory";
import ExpiredLink from "./expiredLink";
import UniteTenant from "./uniteTenant";
import UpgradeButton from "./upgradeButton";
import DatePostponeIcon from "./DatePostponeIcon";
import SandClock from "./sandClock";
import ReturnHiltiAssets from "./returnHiltiAssets";
import EditQuantityItemsCosts from "./editDocument";
import Translation from "./translation";
import Dollar from "./dollar";
import Target from "./target";
import Undo from "./undo";

const icons = {
  Access: PersonGroup,
  AccessLocation,
  Activate,
  Add,
  AddAllocation,
  AddFolder,
  AddPurchase,
  AddUser,
  Alert,
  AlertSetting,
  AllocationStatus,
  Arrow,
  ArrowDown,
  ArrowUpward,
  AssetCosts,
  Assign: Services,
  Attachment,
  BackToAssetList,
  Basket,
  Battery,
  Bell,
  BusinessHours,
  Calendar,
  CallBack,
  CartAdd,
  Category,
  Certificate,
  CertificateEmpty,
  Chat,
  Checkmark,
  Chevron,
  Clone,
  Comodity,
  Complete,
  CompleteService,
  ConfirmTransfer,
  Contact,
  CostLocation,
  CostLocationEmpty,
  Cross,
  DaysOfOperation,
  Deactivate,
  DefaultTemplate,
  Delete,
  Dollar,
  DeliveryNotes: Notes,
  Dismiss: NotificationDismiss,
  Download,
  DragArrow,
  DropFile,
  DuplicateReport,
  Edit,
  EditAssetsNotLinkedTemplate,
  EditAssetsTemplate,
  EditQuantityItemsCosts,
  EllipsisVertical,
  Email,
  EmptyAsset,
  EmptyCost,
  EmptyQTYItem,
  ExchangeBattery,
  Export,
  Feedback,
  Filter,
  FilterWhite,
  Folder,
  GenerateReport,
  HeavyEquipment,
  HistoricServices,
  Holiday,
  HomeIcon,
  IdleAsset,
  Import,
  Info,
  Label,
  LastTransfer,
  Link,
  ListView,
  Location,
  LocationEmpty,
  LocationGroup,
  LocationGroupEmpty,
  LocationJobSite,
  LocationManager,
  Logout,
  MapIcon: Location,
  Merge,
  More,
  Move,
  MoveCursor,
  MultiEdit,
  NoSearchResult,
  Notes,
  NotificationDismiss,
  OpenNew,
  Person,
  PersonGroup,
  PlugCost,
  Promote,
  Purchase,
  QuickTransfer,
  Radio,
  RadioChecked,
  RecallTransfer,
  Refresh,
  Remove,
  RemoveAll: Remove,
  RemoveCircle,
  RenewTool,
  Repeat,
  RescheduleService,
  Resync,
  Search,
  Send,
  Services,
  Setting,
  SettingCompany,
  SettingUser,
  ShelfBox,
  Sort: SortDescending,
  SortAscending,
  SortDescending,
  Star,
  Status,
  StockChange,
  StorageAsset,
  StoredAsset,
  Success,
  Template: DefaultTemplate,
  TemplateEmpty,
  Time,
  TransferAdd,
  TransferCart,
  TransferCartFull,
  TransferHistory,
  Truck,
  UploadImage,
  Image,
  User: Person,
  UserEmpty,
  UserGroupEmpty,
  View,
  ViewRequest,
  WareHouse,
  Warning,
  Worker,
  Repair,
  FullScreen,
  RepairStatus,
  HiltiServices,
  RepairHiltiAssets,
  AssetCapSetting,
  LockIcon,
  BriefCase,
  Container,
  VanGateway,
  TelematicsGateway,
  SmartInventory,
  ExpiredLink,
  DocumentChart,
  UniteTenant,
  UpgradeButton,
  DatePostponeIcon,
  SandClock,
  Translation,
  ReturnHiltiAssets,
  Target,
  Undo,
};

export const Icons = (props) => {
  if (icons[props.name]) {
    const Icon = icons[props.name];
    return <Icon />;
  }
  return <span />;
};

export default Icons;
