import React from "react";
import styled from "styled-components";
import { IconName } from "../../components/icons/iconEnum";
import { SquareIcon } from "../../components/icons/iconWrapper";

interface IContainerProps {
  readonly defaultImage;
}
const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props: IContainerProps) => (props.defaultImage ? "center" : "space-around")};
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 156px;
  img {
    max-width: 100%;
    max-height: 100%;
    flex: 0 0 auto;
  }
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
`;
const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`;
const Label = styled.label`
  margin-left: 8px;
`;

export default class Image extends React.Component<any> {
  componentDidMount() {
    if (this.props.input.value && this.props.onImageChange) {
      this.props.onImageChange(this.props.input.value);
    }
  }

  render() {
    return (
      <AttachmentContainer id="imageContainer" defaultImage={!this.props.currentImage}>
        {this.props.defaultImage && <SquareIcon name={this.props.defaultImage} className="medium" imageSize={64} />}
        {this.props.currentImage && (
          <>
            <ImageWrapper>
              <img src={this.props.currentImage} alt="" />
            </ImageWrapper>
            <ActionsWrapper>
              <ActionWrapper id="changeImage" onClick={this.props.editImage}>
                <SquareIcon name={IconName.Edit} />
                <Label>{this.props.changeText}</Label>
              </ActionWrapper>
              <ActionWrapper id="removeImage" onClick={this.props.onRemove}>
                <SquareIcon name={IconName.RemoveAll} />
                <Label>{this.props.removeText}</Label>
              </ActionWrapper>
            </ActionsWrapper>
          </>
        )}
      </AttachmentContainer>
    );
  }
}
