export enum AlertGroup {
  GATEWAY_ALERT_GROUP = "GATEWAY_ALERT_GROUP",
  QTY_ITEM_ALERT_GROUP = "QTY_ITEM_ALERT_GROUP",
}

export enum GatewayStatus {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  NONE = "NONE",
}

export enum ReplacementAlert {
  REPLACEMENT_HILTI_ASSET = "REPLACEMENT_HILTI_ASSET",
  REPLACEMENT_HILTI_ASSET_ALERT = "REPLACEMENT_HILTI_ASSET_ALERT",
}

export enum ReplacementStatus {
  REPLACEMENT_COMPLETE = "REPLACEMENT_COMPLETE",
}

export const LostAndFound = "LOST_AND_FOUND";
export const LostAndFoundAlert = "LOST_FOUND_ALERT";
export const LostAndFoundAlertType = "LOST_FOUND";
export enum LostAndFoundStatus {
  NOT_FOUND = "NOT_FOUND",
  MONITORING = "MONITORING",
  FOUND_INTERNALLY = "FOUND_INTERNALLY",
  FOUND_EXTERNALLY = "FOUND_EXTERNALLY",
}

export enum QuantityItemAlert {
  QTY_ITEM_STOCK_ALERT = "QTY_ITEM_STOCK_ALERT",
}

// TODO: use the correct enum string after the API contract will be provided for "PROACTIVE_BATTERY_ALERT"
export enum PBEAlert {
  PBE_ALERT = "PROACTIVE_BATTERY_ALERT",
}
