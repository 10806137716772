// @ts-ignore
import { ActiveTrackingActions, AssetActions } from "custom-actions";
import { ISmartInventoryFetchAssets } from "../../../models/activeTracking/SmartInventory";
import { SmartInventoryDetailPanel } from "../../../models/activeTracking/smartINventoryEnum";
import { IGeoJSONFeature, IOneMapRequest } from "../../oneMap/model/cluster";
import { ViewType } from "TARGET_BUILD/common/utils/enums/viewTypeEnum";
import AssetList from "TARGET_BUILD/common/models/asset/assetList";
import { IClusterAssetModel } from "../../oneMap/store/oneMapReducer";
import { IAssetDetailsData } from "TARGET_BUILD/common/models/asset/assetModel";

export const getLocationGatewayAssets = (locationId: number) => ({
  payload: locationId,
  type: ActiveTrackingActions.AT_GET_LOCATION_GATEWAY,
});

/**
 * @description dispatcher to gateway fetch storage asset gateway assets
 */
export const getStorageAssetGatewayAssets = (assetId: number) => ({
  payload: assetId,
  type: ActiveTrackingActions.AT_GET_STORAGE_ASSET_GATEWAY,
});

/**
 * @description reset storage smart inventory asset
 */
export const resetStorageAssetGatewayAssets = () => ({
  type: ActiveTrackingActions.AT_RESET_STORAGE_ASSET_GATEWAY,
});

/**
 * @description dispatcher to gateway toggle asset list dialog
 */
export const toggleAssetListDialog = (showDialog: boolean) => ({
  payload: showDialog,
  type: ActiveTrackingActions.TOGGLE_ASSET_LIST_DIALOG_STATE,
});

export const updateLastSeen = ({ response, assetIds, responseState }) => ({
  type: ActiveTrackingActions.AT_PUT_ASSETS_LAST_SEEN,
  payload: {
    assetIds,
    response,
    responseState,
  },
});

export const initLastSeen = (payload) => ({ type: ActiveTrackingActions.INIT_ASSETS_LAST_SEEN, payload });

export const getLastSeen = (payload) => ({ type: ActiveTrackingActions.AT_GET_ASSETS_LAST_SEEN, payload });

export const clearLastSeen = () => ({ type: ActiveTrackingActions.CLEAR_ASSETS_LAST_SEEN });

/**
 * @description dispatcher to get the filter attributes
 */
export const getAssetFilterAttributes = (id: number, statsType: string, filters: any, urlDetail: string) => ({
  filters,
  id,
  statsType,
  type: ActiveTrackingActions.AT_GET_ASSET_FILTER_FIELD,
  urlDetail,
});

export const setAppliedAttributes = (filterQuery) => ({
  payload: {
    filterQuery,
  },
  type: ActiveTrackingActions.AT_SET_ASSET_FILTER_APPLIED_FIELD,
});

/**
 * @description dispatcher to set the sort value in the store
 */
export const setFilterQuery = (filterQuery) => ({
  currentSelectedFilter: filterQuery.currentSelectedFilter,
  filterQuery: filterQuery.filters,
  type: ActiveTrackingActions.SET_ASSET_VIEW_FILTER_QUERY,
});

/**
 * @description Function to dispatch the method to get gateway asset list
 */
export const fetchAssetsList = (page: SmartInventoryDetailPanel, options: ISmartInventoryFetchAssets) => ({
  id: options.id,
  limit: options.limit,
  offset: options.offset || 0,
  statsType: options.statsType,
  transferAllAssetsToCart: options.isTransfer,
  type: ActiveTrackingActions.AT_GET_ASSET_LIST,
  urlDetail: page,
});

/**
 * @description Function to dispatch the method to set sorting query
 */
export const setSortingQuery = (sortQuery) => ({
  sortQuery,
  type: ActiveTrackingActions.SET_GATEWAY_ASSET_SORT_QUERY,
});

/**
 * @description Function to dispatch the method to reset query params to default
 */
export const resetQueryObjToDefault = () => ({
  type: ActiveTrackingActions.AT_RESET_ASSET_VIEW_QUERY_PARAMS,
});

/**
 * @description Function to dispatch action and update the assetType
 */
export const updateAssetType = (assetType: string) => ({
  payload: assetType,
  type: ActiveTrackingActions.AT_UPDATE_ASSET_TYPE,
});

export const updateChildGatewayStatus = (status: { code: "ONLINE" | "OFFLINE"; value: string }) => ({
  payload: status,
  type: AssetActions.UPDATE_ASSET_DETAILS_CHILD_GATEWAY_STATUS,
});

export const getAssetForOneMap = (payload: IOneMapRequest) => ({
  payload,
  type: ActiveTrackingActions.GET_ASSET_ONE_MAP_DATA,
});

export const getOneMapAssetsForSingleCluster = (payload: IClusterAssetModel) => ({
  payload,
  type: ActiveTrackingActions.AT_GET_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER,
});

export const clearOneMapAssetsForSingleCluster = () => ({
  type: ActiveTrackingActions.AT_CLEAR_ONE_MAP_ASSETS_FOR_SINGLE_CLUSTER,
});

export const selectClusterAsset = (asset: IAssetDetailsData) => {
  return {
    payload: asset,
    type: ActiveTrackingActions.AT_SELECT_ONE_MAP_ASSET_FOR_SINGLE_CLUSTER,
  };
};
