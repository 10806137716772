export enum StatusCode {
  Broken = "BRK",
  Completed = "Completed",
  Disposed = "DISPOSED",
  Lost = "LSTORSTOL",
  Operational = "OPR",
  Repair = "INREP",
  Retired = "RETR",
  BateryLevel = "TAG_BATTERY_LOW",
  GatewayOnline = "ONLINE",
  GatewayOffline = "OFFLINE",
  Intransit = "INTRAN",
  New = "NEW",
}

// enum for hilti asset status
export enum HiltiStatusCode {
  ACTIVE = "ACTIVE",
  LOST = "LOST",
  STOLEN = "STOLEN",
  TBCOLLECTED = "TBCOLLECTED",
  DISPOSEBYCUST = "DISPOSEBYCUST",
  FORMERFLEET = "FORMERFLEET",
  FLEETINEXTN = "FLEETINEXTN",
  SPECIALEXTN = "SPECIALEXTN",
  AWAITNEWPRODUCT = "AWAITNEWPRODUCT",
  LOSTSTOLENNOTCOLLECT = "LOSTSTOLENNOTCOLLECT",
  COLLECTORRETURN = "COLLECTORRETURN",
}

// Enum that contains the possible list of the "Scan code type".
export enum ScanCodeType {
  BARCODE = "BAC",
  ONTRACK_SMART_TAG = "OST",
}

// Enum that contains the possible asset group id.
export enum AssetGroup {
  UNGROUPED = 0,
  UNGROUPED_NAME = "Ungrouped",
}
// Enum that contains the possible asset group id.
export enum AssetType {
  Normal = "NORMAL",
  Storage = "STORAGE",
  Gateway = "GATEWAY",
  HeavyEquipment = "HEAVY",
}

export enum AssetTypeField {
  AssetTypeCode = "assetTypeCode",
  AssetSubTypeCode = "assetSubTypeCode",
}

export enum AssetSubType {
  Unique = "UNIQUE",
  Stationary = "STATIONARY",
  VanGateway = "VAN_GATEWAY",
  Telematics = "TELEMATICS",
  Container = "CONTAINER",
  Van = "VAN",
  HeavyEquipment = "HEAVY_EQUIPMENT",
  Heavy = "HEAVY", // temporary sub type for existing telematics
  LOCKER = "LOCKER",
}

export enum View {
  Child = "child",
  Parent = "parent",
}

export enum AssetKeys {
  id = "assetId",
  primaryRowIds = "assetIds",
  excludedIds = "excludedAssetIds",
  hierarchyRows = "storageAsset",
}

export enum TenantCategories {
  ENTERPRISE = "ENTERPRISE",
  STANDARD = "STANDARD",
  UNITE_TEST = "UNITE_TEST",
}

export type CapReachedResponse = "YES" | "NO";
