import assetTemplatesReducer from "./assetTemplatesReducer";
import assetTemplatesSaga from "./assetTemplatesSaga";
import { ISagaModule } from "redux-dynamic-modules-saga";
import { IAssetTemplateModuleState } from "./iAssetTemplatesState";

export const _assetTemplateModule: ISagaModule<IAssetTemplateModuleState> = {
  id: "assetTemplateModule",
  reducerMap: {
    assetTemplates: assetTemplatesReducer,
  },
  sagas: [assetTemplatesSaga],
};

export default _assetTemplateModule;
