import React from "react";
import styled from "styled-components";
// @ts-ignore
import CrossImage from "TARGET_BUILD/ui-assets/icons/cross_icon.svg";
import ITitleProps from "./iTitle";

/**
 * @const StyledTitle - This is any styled component that will be used to create the title of the details panel.
 */
const StyledTitle = styled("div")`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.medium};
  padding: 16px;
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  justify-content: space-between;
  color: ${(props) => props.theme.header.color};
  min-height: 62px;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
`;

/**
 * @const StyledText - Styled component for the title text.
 */
const StyledText = styled("div")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
`;

/**
 * @description - Cross icon at the top-right corner of asset details panel.
 */
export const StyledCross = styled("span")`
  position: relative;
  right: -1px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  ::after {
    content: "";
    height: 24px;
    width: 24px;
    background-size: 24px;
    display: inline-block;
    background: url(${(props: ITitleProps) => props.iconUrl}) no-repeat;
    background-position: center;
    cursor: pointer;
  }
`;

/**
 * @description - This is any styled component that will be used to create the title of the details panel.
 * It is having the text row along with a cross icon on its right (::after).
 *
 * @param props - Properties of the component.
 * @const Title - It holds Title  component.
 * @interface ITitleProps
 */
const Title = (props: ITitleProps) => {
  const { id, children, panelCloseHandler, showCloseIcon } = props;
  return (
    <StyledTitle id="sidePanelMainTitle">
      <StyledText id={id}>{children}</StyledText>
      {showCloseIcon && (
        <StyledCross data-testid="cross-icon" id="btnCloseSidePanel" iconUrl={CrossImage} onClick={panelCloseHandler} />
      )}
    </StyledTitle>
  );
};

Title.defaultProps = {
  showCloseIcon: false,
};

/**
 * @export Title - Styled Component.
 */
export default Title;
