// @ts-ignore
import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";
import AttachmentFormInput from "./attachmentFormInput";

const Wrapper = styled("div")`
  margin-top: 16px;
`;

const RenderFormInput = (props) => {
  const {
    allowDownloadAttachment,
    attachmentData,
    id,
    type,
    validation,
    required,
    onBlur,
    ref,
    attachmentName,
    value,
    name,
    label,
    disabled,
    onChange,
    placeholder,
    removeHandler,
    error,
    index,
    downloadAttachement,
  } = props;

  return (
    <AttachmentFormInput
      id={id || name}
      name={name}
      label={label}
      type={type}
      placeholder={placeholder}
      validation={validation}
      required={required}
      selectedValue={value}
      disabled={disabled}
      onSelectChange={onChange}
      onBlur={onBlur}
      ref={ref}
      attachmentName={attachmentName}
      removeHandler={removeHandler}
      attachmentData={attachmentData}
      error={error}
      index={index}
      responseFormatMapper={props.responseFormatMapper}
      downloadAttachement={downloadAttachement}
      allowDownloadAttachment={allowDownloadAttachment}
    />
  );
};

export default class AttachmentInput extends React.Component<any> {
  componentDidMount() {
    this.props.setFields(this.props.fields);
  }

  removeItem = (index) => {
    this.props.fields.remove(index);
  };

  render() {
    const { allowDownloadAttachment, downloadAttachement, fields, meta } = this.props;
    return (
      <Wrapper>
        <Grid container={true} spacing={2}>
          {fields.map((item, key) => (
            <Grid item={true} xs={6} lg={6}>
              <RenderFormInput
                key={key}
                index={key}
                {...this.props}
                name={item}
                removeHandler={() => {
                  this.removeItem(key);
                }}
                attachmentData={fields.get(key)}
                error={meta.error}
                downloadAttachement={downloadAttachement}
                allowDownloadAttachment={allowDownloadAttachment}
              />
            </Grid>
          ))}
        </Grid>
      </Wrapper>
    );
  }
}
