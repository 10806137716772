/**
 * @description This file shows tabs that we display
 * in the header. And based on the tab selection we
 * load the particular module.
 */
import { Tab } from "am-web-ui-shared/elements";
import React, { lazy } from "react";
import { AuthorizationCore, CheckAuthorization } from "am-web-ui-shared/helpers";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// @ts-ignore
import styled from "custom-styled-components";
import { redirect } from "../utils/sessionUtils";
import { INavBarProps } from "./iNavBarProps";
import { INavBarState } from "./iNavBarState";
import { ITabItemsLicenseCode, ITabMenu } from "./iTabMenu";
import { HelperLodash } from "am-web-ui-shared/helpers";
import SingletonFeatureManager from "../../featureFlagManager/featureFlagManager";
import { lockedFeature, clearLockedFeature } from "../actions/lockedFeatureInfoActions";
import { SuspenseWrapper } from "../reactSuspenseWrapper";
import { compose } from "redux";

const LockedFeatureDialog = lazy(() => import("../../../../shared/components/lockedFeatureDialog/lockedFeatureDialog"));
import { OutsideClickAlerter } from "am-web-ui-shared/components/outSideClickAlerter/outSideClickComponet";
import withLicenseContext from "TARGET_BUILD/licenseStrategy/withLicenseContext";
import {
  trackLockedItemNav,
  trackLockedItemLink,
  TrackLocketItemLink,
  TrackLockedItem,
} from "TARGET_BUILD/analytics/events/lockedItems";
import { pushRoutesDataToGA } from "TARGET_BUILD/analytics/events/routes";

const TabWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.base.white};
  position: relative;
`;
const tabTypes = {
  menuItem: "menuItem",
  menuList: "menuList",
  subMenuItem: "subMenuItem",
};

export class NavBar extends React.Component<INavBarProps, INavBarState> {
  private tabLinks = [];

  constructor(props) {
    super(props);
    this.tabLinks = props.licenseContext.getTabLinks() || [];
    this.state = {
      isAdminTabClicked: false,
      selectedIndex: this.getSelectedTabIndex(),
      showAdminSubMenu: null,
      lockedTabItemClicked: null,
    };
  }

  fetchLockedFeatureData(featureKey) {
    const { lockedFeatureInfoData, getLockedFeatureInfo, clearLockedFeatureInfo } = this.props;
    if (!lockedFeatureInfoData || lockedFeatureInfoData.featureKey !== featureKey) {
      clearLockedFeatureInfo();
      getLockedFeatureInfo(featureKey);
    }
  }

  /**
   * @description Opening the locked feature dialog for disabled items
   * @param featureKey {string} license key of the tab
   */
  openLockedFeatureDialog = (licenseCode: ITabItemsLicenseCode) => {
    this.fetchLockedFeatureData(licenseCode);

    const trackLockedItem = this.getLockedTabItem(licenseCode);

    if (trackLockedItem) {
      trackLockedItemNav({ entity: trackLockedItem });
    }

    this.setState({ lockedTabItemClicked: licenseCode });
  };

  /**
   * @description Closing the locked feature dialog
   */
  handleCloseLockedFeatureDialog = (event: unknown) => {
    if (typeof event === "string") {
      const trackLockedItem = this.getLockedTabItem(this.state.lockedTabItemClicked);

      if (trackLockedItem) {
        trackLockedItemLink({ entity: trackLockedItem, link: event as TrackLocketItemLink });
      }
    }

    this.setState({ lockedTabItemClicked: null });
  };

  getLockedTabItem = (licenseCode: ITabItemsLicenseCode): TrackLockedItem | null => {
    const lockedTabItems = new Map([
      [ITabItemsLicenseCode.ASSET_COST, TrackLockedItem.AssetCost],
      [ITabItemsLicenseCode.QUANTITY_ITEMS, TrackLockedItem.QuantityItem],
    ]);

    if (lockedTabItems.has(licenseCode)) {
      return lockedTabItems.get(licenseCode);
    }

    return null;
  };

  /**
   * @description Function to get Menu Items of subrouting
   * @param t : this is for translation
   * @param tabmenuItems: these are the items to display
   * @memberof NavBar
   */

  initializeMenuData = (t, tabMenuItems: ITabMenu[]): JSX.Element[] | any => {
    return tabMenuItems.map((tabMenuItem) => {
      if (
        (tabMenuItem.licenseCode &&
          !AuthorizationCore.hasActiveModule(tabMenuItem.licenseCode) &&
          !tabMenuItem.isDisabled) ||
        (tabMenuItem.featureKey && !SingletonFeatureManager.isFeatureEnabled(tabMenuItem.featureKey))
      ) {
        return null;
      }

      return !tabMenuItem.isClickable ? (
        {
          authKey: tabMenuItem.authKey,
          menuHead: tabMenuItem.labelKey && t(tabMenuItem.labelKey),
          menuList: tabMenuItem.subMenuItems ? this.initializeMenuData(t, tabMenuItem.subMenuItems) : undefined,
        }
      ) : (
        <Tab.TabItem
          authKey={tabMenuItem.authKey}
          htmlId={tabMenuItem.htmlId}
          key={tabMenuItem.index}
          label={tabMenuItem.labelKey ? t(tabMenuItem.labelKey) : null}
          type={tabTypes.subMenuItem}
          index={tabMenuItem.index}
          disabled={tabMenuItem.isDisabled}
          licenseCode={tabMenuItem.licenseCode}
          handleLockedTabClick={() => this.openLockedFeatureDialog(tabMenuItem.licenseCode)}
        />
      );
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedIndex: this.getSelectedTabIndex() });
    }
  }

  /**
   * @description Function to get index of tab
   * @memberof NavBar
   */
  getSelectedTabIndex() {
    const path = this.props.location.pathname;
    return this.getSelectedTab("path", path, this.tabLinks)?.index;
  }

  /**
   * @description Function to get path of tab
   * @param compareByFieldName : value is path
   * @param compareByFieldValue : path to redirect to
   * @memberof NavBar
   */
  getSelectedTabPath(compareByFieldName, compareByFieldValue) {
    return this.getSelectedTab(compareByFieldName, compareByFieldValue, this.tabLinks)?.path;
  }

  /**
   * @description Function to get tab selected
   * @param compareByFieldName : value is path
   * @param compareByFieldValue : path to redirect to
   * @param tabs : tabs list
   * @memberof NavBar
   */
  getSelectedTab = (compareByFieldName: string, compareByFieldValue: string, tabs: ITabMenu[]) => {
    if (tabs) {
      let tab = this.filterTabs(compareByFieldName, compareByFieldValue, tabs);
      if (HelperLodash.get(tab, compareByFieldName) !== compareByFieldValue && tab?.subMenuItems) {
        tab = this.getSelectedTab(compareByFieldName, compareByFieldValue, tab?.subMenuItems);
      }
      return tab;
    } else {
      return null;
    }
  };

  /**
   * @description Function to get tab selected until SubMenuItems is there
   *  @param compareByFieldName {String} - value is path
   *  @param compareByFieldValue {String} - path to redirect to
   *  @param tabs {ITabMenu[]} - tabs to perform routing
   * @memberof NavBar
   */

  filterTabs = (compareByFieldName: string, compareByFieldValue: string, tabs: ITabMenu[]) => {
    return tabs?.find((tab) => {
      if (tab.subMenuItems) {
        return this.filterTabs(compareByFieldName, compareByFieldValue, tab.subMenuItems);
      } else if (compareByFieldName === "path" && tab.matchOn) {
        return tab.matchOn.test(compareByFieldValue);
      } else {
        return HelperLodash.get(tab, compareByFieldName) === compareByFieldValue;
      }
    });
  };

  /**
   * @description Function for selection of particular tab
   * @memberof NavBar
   * @param {String} - get the index number of the tab
   */
  handleTabSelection(index, type) {
    if (type === tabTypes.menuList) {
      this.toggleMenuListHandler();
    } else if (type === tabTypes.subMenuItem && this.state.selectedIndex === index) {
      this.setState({ showAdminSubMenu: false });
    } else {
      const selectedPath = this.getSelectedTabPath("index", index);
      pushRoutesDataToGA(selectedPath);
      redirect(selectedPath, this.props.history);
      this.setState({ showAdminSubMenu: false, selectedIndex: index });
    }
  }

  /**
   * @description Function to open close menuList
   * @memberof NavBar
   */
  toggleMenuListHandler = () => {
    this.setState((prevState) => {
      return {
        isAdminTabClicked: !prevState.showAdminSubMenu,
        showAdminSubMenu: !prevState.showAdminSubMenu,
      };
    });
  };

  getTabLinksDetails = () => {
    return this.props.licenseContext.getTabLinks() || [];
  };

  render() {
    const { t, lockedFeatureInfoData } = this.props;
    return (
      <TabWrapper>
        <Tab
          selected={this.state.selectedIndex}
          showContent={false}
          onSelect={(nextIndex, type) => this.handleTabSelection(nextIndex, type)}
          isAdminTabClicked={this.state.isAdminTabClicked}
          authorizationComponent={CheckAuthorization}
        >
          {this.getTabLinksDetails()?.map((tabMenuItem: ITabMenu, key) => {
            if (
              (tabMenuItem.licenseCode &&
                !AuthorizationCore.hasActiveModule(tabMenuItem.licenseCode) &&
                !tabMenuItem.isDisabled) ||
              (tabMenuItem.featureKey && !SingletonFeatureManager.isFeatureEnabled(tabMenuItem.featureKey))
            ) {
              return null;
            }
            const tabProps = tabMenuItem.subMenuItems
              ? {
                  menuList: this.initializeMenuData(t, tabMenuItem.subMenuItems),
                  showMenuList: this.state.showAdminSubMenu,
                  toggleMenuListHandler: this.toggleMenuListHandler,
                }
              : {};
            return tabMenuItem.isHidden ? null : (
              <Tab.TabItem
                authKey={tabMenuItem.authKey}
                htmlId={tabMenuItem.htmlId}
                key={key}
                label={tabMenuItem.labelKey ? t(tabMenuItem.labelKey) : null}
                index={tabMenuItem.index}
                {...tabProps}
                type={tabMenuItem.subMenuItems ? tabTypes.menuList : tabTypes.menuItem}
                disabled={tabMenuItem.isDisabled}
                locked={tabMenuItem.isLocked}
                licenseCode={tabMenuItem.licenseCode}
                handleLockedTabClick={() => this.openLockedFeatureDialog(tabMenuItem.licenseCode)}
                isBeta={tabMenuItem.isBeta}
              />
            );
          })}
        </Tab>
        {this.state.lockedTabItemClicked !== null && lockedFeatureInfoData && (
          <OutsideClickAlerter
            ignoreClickedElement={["dialogBox"]}
            render={() => (
              <SuspenseWrapper>
                <LockedFeatureDialog
                  learnMoreUrl={lockedFeatureInfoData.learnMoreUrl}
                  contactSalesUrl={lockedFeatureInfoData.contactSalesUrl}
                  handleClose={this.handleCloseLockedFeatureDialog}
                  imageUrl={lockedFeatureInfoData.imageUrl}
                  description={lockedFeatureInfoData.description}
                  title={lockedFeatureInfoData.title}
                />
              </SuspenseWrapper>
            )}
            onClickAway={this.handleCloseLockedFeatureDialog}
          />
        )}
      </TabWrapper>
    );
  }
}

export const mapDispatchToProps = (dispatch) => ({
  getLockedFeatureInfo: (featureKey: string) => dispatch(lockedFeature(featureKey)),
  clearLockedFeatureInfo: () => dispatch(clearLockedFeature()),
});

export const mapStateToProps = (state) => ({
  lockedFeatureInfoData: state?.LockedFeatureInfoReducer?.lockedFeatureData,
});

/**
 * @description - This is used to wrap the NavBar component with withTranslation HOC.
 */
const translatedNavBar: any = withTranslation()(NavBar);

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withLicenseContext)(translatedNavBar);
