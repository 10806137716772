export enum ServiceStatus {
  OPEN = "open",
  HISTORY = "history",
}

export enum StatusTypeFilter {
  OVERDUE = "OVERDUE",
  OPEN_SERVICE = "OPEN_SERVICE",
  HISTORY_SERVICE = "HISTORY_SERVICE",
  UPCOMING = "UPCOMING",
}

export enum ServiceFormEnum {
  SERVICE_FORM_NAME = "assignServiceForm",
  SERVICE = "service",
  SERVICE_TEMPLATE = "serviceTemplateId",
  FILTER_FORM = "serviceFilter",
  STATUS_FILTER = "serviceStatusResponse",
  EDIT_COMPLETE_SERVICE_FORM = "editCompleteServiceForm",
  ONE_TIME_SERVICE = "ONETIME_SERVICE",
  IS_CRITICAL = "isCritical",
}

export enum FieldType {
  SCHEDULE_DATE = "scheduleDate",
  COMPLETION_DATE = "completionDate",
  COST = "cost",
  COST_CURRENCY = "currency",
  PROVIDER = "provider",
  NOTES = "notes",
  SERVICE_RESULT = "serviceResult",
}

export enum ServiceResultEnum {
  PASSED = "Passed",
  FAILED = "Failed",
}
