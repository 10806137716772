import React from "react";
import { IAccuracyCircleMap, IAccuracyCircleMapState } from "../../iMapWrapper";
import AccuracyCircleMap from "../view/accuracyCircleMapView";
/**
 * @description Accuracy circle map , renders the map and marker with given position details
 */
class AccuracyCircleMapContainer extends React.Component<IAccuracyCircleMap, IAccuracyCircleMapState> {
  mapRef;
  state: IAccuracyCircleMapState = {
    isFullView: false,
  };

  /*
    Toggles full view mode and pass the props to map view
  */
  toggleFullView = (isFullView: boolean) => {
    this.setState({
      isFullView,
    });
  };
  render() {
    return (
      <AccuracyCircleMap
        infoDetails={this.props.infoDetails}
        isFullView={this.state.isFullView}
        mapClicked={this.toggleFullView}
        {...this.props}
      />
    );
  }
}

export default AccuracyCircleMapContainer;
