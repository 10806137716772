import { IconName } from "am-web-ui-shared/components";
import React from "react";
import { withTranslation } from "react-i18next";
import { SimpleExpansionPanel } from "../../../../../components/DetailsPanel/index";
import { ITransferDetailsView } from "./model/iTransferDetails";
import TransferDetailsExpanded from "./transferDetailsExpanded";
import ApplicationConstants from "../../../../../applicationConstants";

/**
 * @description - This is the transfer history section on the asset details panel.
 */
class TransferDetailsView extends React.Component<ITransferDetailsView> {
  state = {
    isExpanded: false,
  };
  allowAccordianControl = (_event: object, expanded: boolean) => {
    if (expanded) {
      const {
        selectedRow: { assetId },
        getAssetTransferDetails,
        transferHistory,
      } = this.props;
      if (this.getTransferHistoryCount(transferHistory) <= ApplicationConstants.LENGTH.LEN0) {
        getAssetTransferDetails(assetId, ApplicationConstants.OFFSET.OFF0, ApplicationConstants.LIMIT.LIM1);
      }
      this.setState({ isExpanded: true });
    } else {
      this.setState({ isExpanded: false });
    }
  };
  getTransferHistoryCount = (transferHistory) => transferHistory && transferHistory.list && transferHistory.list.length;
  render() {
    const { t, transferHistory, handleCancelTransfer, fetchList, selectedRow, getAssetTransferDetails } = this.props;
    return (
      <SimpleExpansionPanel
        panelId="pnlTransferHistory"
        headingId="headTransferHistory"
        iconUrl={IconName.LastTransfer}
        boldId="lblTransferHistory"
        boldText={t("common:LABEL_LAST_TRANSFER")}
        hasValue={true}
        data={transferHistory}
        accordianOnChange={this.allowAccordianControl}
        t={t}
        isExpanded={this.state.isExpanded}
      >
        {this.getTransferHistoryCount(transferHistory) > ApplicationConstants.LENGTH.LEN0 ? (
          <TransferDetailsExpanded
            handleCancelTransfer={handleCancelTransfer}
            fetchList={fetchList}
            transferHistory={transferHistory}
            selectedRow={selectedRow}
            t={t}
            getAssetTransferDetails={getAssetTransferDetails}
          />
        ) : null}
      </SimpleExpansionPanel>
    );
  }
}

/**
 * @exports TransferDetailsView - Transfer history details section that renders both header and detail components.
 */
export default withTranslation()(TransferDetailsView);
