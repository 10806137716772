import React from "react";
import styled from "styled-components";
import DatePicker from "../../../elements/datePicker/datePicker";
import { HelperLodash } from "../../../helpers";
import { IGridDatePicker } from "./iGridDatePicker";

const DatePickerContainer = styled.div`
  #calendarSetDatesErr {
    display: none;
  }
  &&& {
    input[type="text"] {
      width: 100%;
    }
  }
`;
class GridDatePicker extends React.PureComponent<IGridDatePicker> {
  // This is used to stop the event propagation of dropdown to stop the row click of gridView.
  onDropdownContainerClick = (event) => {
    event.stopPropagation();
  };

  render() {
    const { data, dependencies, idKey, languageCode } = this.props;
    const { attributes, validations } = this.props.column;

    let validationsArray;
    if (validations && dependencies) {
      validationsArray = validations.map((validationName) => {
        return HelperLodash.get(dependencies, validationName);
      });
    }

    return (
      <DatePickerContainer onClick={this.onDropdownContainerClick}>
        <DatePicker
          placeholder={dependencies.t(attributes.placeholder)}
          name={`returnDate${HelperLodash.get(data, idKey)}`}
          key="uniqueSetReturnDate"
          label={""}
          isFormControl={true}
          setInitialDate={attributes.setInitialDate}
          format={attributes.dateFormat}
          disabled={this.props.disabled}
          id={`returnDate${data.assetId}`}
          validation={validationsArray}
          locale={languageCode}
          isClearable={attributes.isClearable}
          openToDate={attributes.openToDate}
          minDate={attributes.minDate}
        />
      </DatePickerContainer>
    );
  }
}

export default GridDatePicker;
