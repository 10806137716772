import { Ellipsis, IconName, SquareIcon } from "am-web-ui-shared/components";
import React from "react";
import { LoaderElement, LoaderElementType } from "am-web-ui-shared/elements";
import styled from "styled-components";
import { formatCount } from "../../utils/commonUtils";
import { LoactionsStateEnum } from "../../utils/enums/locationEnums";
import { ILocationCountProps } from "./iLocationCountProps";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import SingletonFeatureManager from "../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../featureFlagManager/featureConfig";
import { LocaleManager } from "am-web-ui-shared/helpers";
import ApplicationConstants from "am-web-ui-shared/constant/applicationConstants";

const WrapperLoader = styled.div`
  width: 20px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const LocationCount = (props: ILocationCountProps) => {
  const { count, label, handleLoader = () => undefined, type, index, style } = props;
  const { t } = useTranslation();
  const loaderState = useSelector(({ Locations }: RootState) => Locations?.countLoader);

  /**
   * @description - Function to format Quantity Item count/Asset Count cell.
   * @param value - Asset Count/QTY Count.
   */
  const formatQuantityItemCount = (value: string) => {
    const qtyMessages = {
      noValueText: t("common:LABEL_NO_QTYITEMS"),
      pluralText: t("common:MAIN_MENU_TAB_QTYITEMS"),
      singulerText: t("common:LABEL_QTYITEM_SINGULAR"),
    };

    const assetMessages = {
      noValueText: t("common:LABEL_NO_ASSETS"),
      pluralText: t("common:LABEL_ASSETS_PLURAL"),
      singulerText: t("common:LABEL_ASSET_SINGULAR"),
    };

    const messages = type === "asset" ? assetMessages : qtyMessages;

    return formatCount(value, messages);
  };

  const renderCount = () => {
    switch (loaderState) {
      case LoactionsStateEnum.Success: {
        return !type ? (
          LocaleManager.numberHelpers.formatNumber(count)
        ) : (
          <Ellipsis id={label} showTooltip={true} tooltipValue={label} style={style}>
            {formatQuantityItemCount(count)}
          </Ellipsis>
        );
      }
      case LoactionsStateEnum.Failure: {
        return (
          <SquareIcon name={IconName.Warning} className="warning" imageSize={type ? 16 : 20} onClick={handleLoader} />
        );
      }
      case LoactionsStateEnum.Loading: {
        return (
          <WrapperLoader data-testid="wrapperLoader">
            <LoaderElement type={LoaderElementType.GRAY} />
          </WrapperLoader>
        );
      }
      default:
        return LocaleManager.numberHelpers.formatNumber(count);
    }
  };

  const showCount = () => {
    return (
      <>
        {index ? (
          <Wrapper
            className={index % ApplicationConstants.NUMBER.NUM2 === 0 ? "cell-top-element" : "cell-bottom-element"}
            data-testid="count-wrapper"
          >
            {renderCount()}
          </Wrapper>
        ) : (
          renderCount()
        )}
      </>
    );
  };

  return (
    <>
      {!SingletonFeatureManager.isFeatureEnabled(FEATURES.LOCATION_COUNT)
        ? LocaleManager.numberHelpers.formatNumber(count)
        : showCount()}
    </>
  );
};

export default LocationCount;
