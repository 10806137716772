import Grid from "@mui/material/Grid";
import { DetailsPanel } from "am-web-ui-shared/components";
import { Field, Radio, LoaderElement } from "am-web-ui-shared/elements";
import { HelperLodash } from "am-web-ui-shared/helpers";
import React from "react";
import { connect } from "react-redux";
import { FormSection, formValueSelector } from "redux-form";
import styled from "styled-components";
// @ts-ignore
import ErrorIcon from "TARGET_BUILD/ui-assets/icons/error.svg";
import { getChargeSetting as getInfo, isDelink, isValidTemplate } from "../../models/charges/chargesOrchestration";
import { getChargeSetting, getTemplateChargeSetting } from "../../modules/charges/actions/chargesActionRoot";
import { shouldShowCappingAndTimeBasedFields, addExternalLabel } from "../../utils/commonUtils";
import { AssetCapSettings, CurrencyInputField, RenderAssetCapValue } from "./assetCapSettings";
import RenderEachField, { ReadOnlyField } from "./chargePanelField";
import { ChargesScheduler, ChargesSchedulerReadOnly } from "./chargesScheduler";
import { getTimeBasedEnabledTenantList } from "../../modules/masterData/masterDataActionCreator";

import {
  CHARGE_SETTING_CHARGES_SCHEDULER,
  MONTHLYASSETCOST,
  TIME_BASED_DAILY_ASSET_COST,
  WEEKLYASSETCOST,
  WORKDAYS_FOR_MONTHLY_ASSET_COST,
  WORKDAYS_FOR_WEEKLY_ASSET_COST,
} from "./chargesSchedulerConstants";
import { AssetCapType, CostCodeType, costTypeErrorCode } from "./costCodeTypeEnum";
import IAssociateChargesPanel from "./iAssociateChargesPanelprops";
import { FEATURES } from "TARGET_BUILD/featureFlagManager/featureConfig";
import SingletonFeatureManager from "TARGET_BUILD/featureFlagManager/featureFlagManager";
export const InfoChargeMessage = styled.p`
  color: ${(props) => props.theme.colors.base.hiltiRed};
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 24px; /* text-align: left; */
  margin: 25px 0 25px 0;
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    margin: 0 12px 4px 16px;
    vertical-align: middle;
  }
`;

const COMMON_FROM_TEMPLATE = "common:FROM_TEMPLATE";

export const CappedLabelMapping = {
  [AssetCapType.NOT_CAPPED]: "charges:NOT_CAPPED",
  [AssetCapType.CAPPED_PER_LOCATION]: "charges:CAPPED_PER_LOCATION",
  [AssetCapType.CAPPED_ASSET_VALUE]: "charges:CAPPED_AT_ASSET_VALUE",
};
export class AssociateChargesPanel extends React.PureComponent<IAssociateChargesPanel, any> {
  chargeSetting = { assetValue: null, costTypeCode: null };

  state = {
    editable: true,
    isError: false,
    isNeverSet: true,
    isFormValueSet: false,
  };
  timeBasedFF = SingletonFeatureManager.isFeatureEnabled(FEATURES.TIME_BASED_FEATURE_FLAG);
  cappingRecalculationFlag = SingletonFeatureManager.isFeatureEnabled(FEATURES.CAPPING_RECALCULATION_FLAG);
  componentDidMount() {
    const { selectedAssetDetail, fetchTemplateChargeSetting, fetchAssetChargeSetting, templateInfo, entityId } =
      this.props;
    if (isValidTemplate(templateInfo)) {
      fetchTemplateChargeSetting(templateInfo.id);
    } else if (
      !isValidTemplate(templateInfo) &&
      selectedAssetDetail &&
      selectedAssetDetail.assetTemplateId &&
      entityId
    ) {
      // de-link case -- fetch both
      fetchTemplateChargeSetting(selectedAssetDetail.assetTemplateId);
      fetchAssetChargeSetting(entityId);
    } else if (entityId) {
      fetchAssetChargeSetting(entityId);
    }
  }

  componentDidUpdate() {
    const {
      selectedAssetDetail,
      templateChargeSetting,
      templateInfo,
      assetChargeSetting,
      entityId,
      change,
      modelName,
      manufacturerId,
      manufacturerName,
    } = this.props;
    const isDelinkCase = isDelink(selectedAssetDetail, templateInfo, modelName, manufacturerName);

    const templateId = isValidTemplate(templateInfo)
      ? templateInfo.id
      : isDelinkCase
        ? selectedAssetDetail.assetTemplateId
        : 0;

    let assetSetting;

    if (entityId && !isValidTemplate(templateInfo) && !isDelinkCase) {
      assetSetting = assetChargeSetting && HelperLodash.get(assetChargeSetting, entityId);
    }

    if (!this.state.isFormValueSet && (HelperLodash.get(templateChargeSetting, templateId) || assetSetting)) {
      const value = getInfo({
        assetDetails: selectedAssetDetail,
        assetSetting,
        entityId,
        manufacturerId,
        manufacturerName,
        modelName,
        templateChargeSetting,
        templateInfo,
      });

      change("chargeSetting", value.chargeSetting);
      this.chargeSetting = value.chargeSetting;
      this.setState({
        isFormValueSet: true,
        editable: !value.disable,
        isError: value.isError,
        isNeverSet: value.isNeverSet,
      });
    }
  }
  passTimeBasedEnabledTenantList = (timeBasedEnabledTenantList) => {
    const { getTimeBasedEnabledTenantList } = this.props;
    return timeBasedEnabledTenantList.length ? timeBasedEnabledTenantList : getTimeBasedEnabledTenantList();
  };

  getCostTypeField = (chargeSetting) => {
    const code = chargeSetting.costTypeCode
      ? chargeSetting.costTypeCode
      : chargeSetting.error
        ? chargeSetting.code
        : "";
    const value =
      code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_ASSET ||
      code === costTypeErrorCode.CHARGE_SETTING_NOT_AVAILABLE_TEMPLATE
        ? costTypeErrorCode.NONE_NEVERSET
        : [CostCodeType.DAILY, CostCodeType.NONE, CostCodeType.TIME_BASED].includes(code)
          ? CostCodeType[code.toUpperCase()]
          : "";
    return value && this.props.t(`common:${value.toUpperCase()}`);
  };

  getCappingLabel = (cappingType) => {
    return this.props.t(HelperLodash.get(CappedLabelMapping, cappingType));
  };

  getTitle = () => {
    const { t } = this.props;
    return !this.state.editable ? `${t("common:ASSET_COSTS")} (${t(COMMON_FROM_TEMPLATE)})` : t("common:ASSET_COSTS");
  };

  getLabel = (initialLabel: string, fieldName: string) => {
    const { shouldCheckExternalLabels, externalAssetsFields, t } = this.props;
    return addExternalLabel(shouldCheckExternalLabels, initialLabel, t, externalAssetsFields, fieldName);
  };

  showDailyCostFields = () => {
    const {
      cappingPercentage,
      alwaysFollowAssetCapSetting,
      cappingType,
      currency,
      t,
      isAssetStatusDisposedOrRetired,
      change,
      timeBasedDailyAssetCost,
      weeklyAssetCost,
      monthlyAssetCost,
      workdaysForMonthlyAssetCost,
      workdaysForWeeklyAssetCost,
      timeBasedEnabledTenantList,
      tenantId,
      costTypeValue,
    } = this.props;
    const { editable, isNeverSet } = this.state;
    return editable ? (
      <>
        {(shouldShowCappingAndTimeBasedFields(timeBasedEnabledTenantList, tenantId) ||
          this.cappingRecalculationFlag) && (
          <AssetCapSettings
            t={t}
            companyOrLocation={false}
            disabled={isAssetStatusDisposedOrRetired}
            cappingType={cappingType}
            change={change}
            isCapSettingNeverSet={isNeverSet}
          />
        )}
      </>
    ) : (
      <>
        <ChargesSchedulerReadOnly
          timeBasedDailyAssetCost={timeBasedDailyAssetCost || costTypeValue}
          timeBasedWeeklyDays={workdaysForWeeklyAssetCost}
          weeklyAssetCost={weeklyAssetCost}
          timeBasedMonthlyDays={workdaysForMonthlyAssetCost}
          monthlyAssetCost={monthlyAssetCost}
          currency={currency}
        />

        {(shouldShowCappingAndTimeBasedFields(timeBasedEnabledTenantList, tenantId) ||
          this.cappingRecalculationFlag) && (
          <>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                {ReadOnlyField({
                  label: t("charges:ASSET_CAP_SETTINGS"),
                  value: this.getCappingLabel(cappingType),
                  currencyCode: null,
                })}
              </Grid>
            </Grid>
            {cappingPercentage && (
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  {ReadOnlyField({
                    label: t("charges:ASSET_CHARGE_VALUE_CAPPED_AT"),
                    value: cappingPercentage || "-",
                    currencyCode: null,
                  })}
                </Grid>
              </Grid>
            )}
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                {ReadOnlyField({
                  label: t("charges:ALWAYS_FOLLOW_ASSET_CAP_SETTINGS"),
                  value: alwaysFollowAssetCapSetting ? t("common:YES") : t("common:NO"),
                  currencyCode: null,
                })}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };
  renderAssetValue = () => {
    const { editable } = this.state;
    const { costTypeCode, cappingType, currency, t, isAssetStatusDisposedOrRetired } = this.props;
    return editable ? (
      <RenderAssetCapValue
        t={t}
        cappingType={cappingType}
        costTypeCode={costTypeCode}
        currency={currency}
        disabled={isAssetStatusDisposedOrRetired}
      />
    ) : (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          {ReadOnlyField({
            label: this.getLabel(t("common:ASSET_VALUE"), "chargeSetting.costTypeValue"),
            value: this.chargeSetting.assetValue || "-",
            currencyCode: currency?.code,
          })}
        </Grid>
      </Grid>
    );
  };
  renderCostType = (label) => {
    const { isNeverSet } = this.state;
    const {
      t,
      isAssetStatusDisposedOrRetired,
      timeBasedEnabledTenantList,
      tenantId,
      costTypeCode,
      currency,
      timeBasedDailyAssetCost,
      workdaysForWeeklyAssetCost,
      weeklyAssetCost,
      monthlyAssetCost,
      workdaysForMonthlyAssetCost,
    } = this.props;
    return (
      <Field
        htmlFor={t("common:ASSET_COST_TYPE")}
        label={label}
        required={true}
        name={"assetValue"}
        getContent={() => t("charges:ASSET_COST_TYPE_INFO")}
        place={"right"}
      >
        <Radio
          name={"costTypeCode"}
          id={"costCodeTypeNone"}
          radioLabel={isNeverSet ? t("common:NONE-NEVERSET") : t("common:NONE")}
          radioValue={CostCodeType.NONE}
          isFormControl={true}
          disabled={isAssetStatusDisposedOrRetired}
        />
        <Radio
          name={"costTypeCode"}
          id={"costCodeTypeDaily"}
          radioLabel={t("common:DAILY")}
          radioValue={CostCodeType.DAILY}
          isFormControl={true}
          disabled={isAssetStatusDisposedOrRetired}
        />
        {costTypeCode === CostCodeType.DAILY && (
          <CurrencyInputField
            name={"costTypeValue"}
            label={t("common:DAILY_ASSET_COST")}
            required={true}
            currency={currency}
          />
        )}
        {
          <Radio
            name={"costTypeCode"}
            id={"costCodeTypeTimeBased"}
            radioLabel={t("common:TIME_BASED")}
            radioValue={CostCodeType.TIME_BASED}
            isFormControl={true}
            disabled={isAssetStatusDisposedOrRetired}
            style={{ marginTop: "8px" }}
          />
        }
        {costTypeCode === CostCodeType.TIME_BASED && (
          <>
            <CurrencyInputField
              name={TIME_BASED_DAILY_ASSET_COST}
              label={t("common:DAILY_ASSET_COST")}
              required={true}
              currency={currency}
            />
            <ChargesScheduler
              currency={currency}
              timeBasedDailyAssetCost={timeBasedDailyAssetCost}
              timeBasedWeeklyAssetCost={weeklyAssetCost}
              timeBasedMonthlyAssetCost={monthlyAssetCost}
              workdaysForMonthlyAssetCost={workdaysForMonthlyAssetCost}
              workdaysForWeeklyAssetCost={workdaysForWeeklyAssetCost}
            />
          </>
        )}
      </Field>
    );
  };
  assetCostPanel = () => {
    const { editable } = this.state;
    const { costTypeCode, t } = this.props;
    return (
      <>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <RenderEachField
              editable={editable}
              label={this.getLabel(t("common:ASSET_COST_TYPE"), "chargeSetting.costTypeCode")}
              chargeSetting={this.getCostTypeField(this.chargeSetting)}
            >
              {this.renderCostType}
            </RenderEachField>
          </Grid>
        </Grid>
        {(costTypeCode === CostCodeType.DAILY || costTypeCode === CostCodeType.TIME_BASED) &&
          this.showDailyCostFields()}
        {this.renderAssetValue()}
      </>
    );
  };
  render() {
    const { isError, isFormValueSet } = this.state;
    const { t, entityId } = this.props;
    return (
      <FormSection name="chargeSetting">
        <DetailsPanel panelTitleId={this.props.id} title={this.getTitle()}>
          {isError ? (
            <InfoChargeMessage>
              <img src={ErrorIcon} alt="errorIcon" />
              {t("charges:CHARGE_MODULE_LOAD_ERROR")}
            </InfoChargeMessage>
          ) : !entityId || isFormValueSet ? (
            this.assetCostPanel()
          ) : (
            <LoaderElement />
          )}
        </DetailsPanel>
      </FormSection>
    );
  }
}

export const getTenantIdFromState = (state) => state.Company?.companyDetails?.tenantId;

export const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  const manufacturerName = selector(state, "manufacturerName");
  const modelName = selector(state, "model");
  const costTypeCode = selector(state, "chargeSetting.costTypeCode");
  const cappingType = selector(state, "chargeSetting.assetCapSetting.cappingType");
  const costTypeValue = selector(state, "chargeSetting.costTypeValue");
  const cappingPercentage = selector(state, "chargeSetting.assetCapSetting.cappingPercentage");
  const alwaysFollowAssetCapSetting = selector(state, "chargeSetting.assetCapSetting.alwaysFollowAssetCapSetting");
  const workdaysForWeeklyAssetCost = selector(
    state,
    `${CHARGE_SETTING_CHARGES_SCHEDULER}.${WORKDAYS_FOR_WEEKLY_ASSET_COST}`,
  );
  const weeklyAssetCost = selector(state, `${CHARGE_SETTING_CHARGES_SCHEDULER}.${WEEKLYASSETCOST}`);
  const workdaysForMonthlyAssetCost = selector(
    state,
    `${CHARGE_SETTING_CHARGES_SCHEDULER}.${WORKDAYS_FOR_MONTHLY_ASSET_COST}`,
  );
  const monthlyAssetCost = selector(state, `${CHARGE_SETTING_CHARGES_SCHEDULER}.${MONTHLYASSETCOST}`);
  const timeBasedDailyAssetCost = selector(state, `${CHARGE_SETTING_CHARGES_SCHEDULER}.${TIME_BASED_DAILY_ASSET_COST}`);

  return {
    assetChargeSetting: state.Charges && state.Charges.chargesDetail,
    selectedAssetDetail: state.Assets && state.Assets.selectedAssetDetail,
    costTypeCode,
    currency: state.Company && state.Company.companyDetails && state.Company.companyDetails.currency,
    manufacturerName,
    modelName,
    templateChargeSetting: state.Charges && state.Charges.templateChargeSetting,
    templateInfo: state.AddAsset && state.AddAsset.templateInfo,
    cappingType,
    costTypeValue,
    alwaysFollowAssetCapSetting,
    cappingPercentage,
    workdaysForMonthlyAssetCost,
    workdaysForWeeklyAssetCost,
    weeklyAssetCost,
    monthlyAssetCost,
    timeBasedDailyAssetCost,
    tenantId: getTenantIdFromState(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchAssetChargeSetting: (assetId) => dispatch(getChargeSetting(assetId)),
  fetchTemplateChargeSetting: (templateId) => dispatch(getTemplateChargeSetting(templateId)),
  getTimeBasedEnabledTenantList: () => getTimeBasedEnabledTenantList(),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociateChargesPanel);
