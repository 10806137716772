// extracted outside the component to make it easier to mock during testing
export const getOffsetParent = (element: HTMLDivElement) => element?.offsetParent;

/**
 * @description - InfiniteScroll component to load the content in chunk with properties
 * @interface IAssetDetailsProps
 */

import React from "react";
import { IProps } from "./iInfiniteScrollProps";
import { getParentElement } from "./infiniteScrollUtils";
class InfiniteScrollComponent extends React.PureComponent<IProps, {}> {
  scrollComponent;
  pageLoaded;
  constructor(props) {
    super(props);
    this.scrollListener = this.scrollListener.bind(this);
  }
  componentDidMount() {
    this.pageLoaded = this.props.pageStart || 0;
    this.scrollComponent = this.props.item;
    this.attachScrollListener();
  }
  componentDidUpdate(prevProps) {
    const { resetPage } = this.props;
    if (resetPage) {
      this.pageLoaded = this.props.pageStart || 0;
    }
    if (this.props.item && this.props.item !== prevProps.item) {
      this.scrollComponent = this.props.item;
    }
    this.attachScrollListener();
  }

  /*
    detach the events when DOM is removed. Else scroll events may trigger
  */
  componentWillUnmount = () => {
    this.detachScrollListener();
  };
  /**
   * @description Function to attach scroll  listeners to scroll element
   * @memberof InfiniteScrollComponent
   */
  attachScrollListener() {
    const { isWindowScroll } = this.props;
    const scrollElement = this.scrollComponent;
    if (!this.props.hasMore || !scrollElement) {
      /*
        if the rows data are updated or scrollElement is removed we need to detach scroll.
      */
      this.detachScrollListener();
    } else {
      if (isWindowScroll) {
        window.addEventListener("scroll", this.windowScrollListener, false);
      } else if (scrollElement && scrollElement.addEventListener) {
        scrollElement.addEventListener("scroll", this.scrollListener, false);
      }
    }
  }
  /**
   * @description Function to detatch listeners
   * @memberof InfiniteScrollComponent
   */
  detachScrollListener() {
    const scrollElement = this.scrollComponent;
    const { isWindowScroll } = this.props;
    if (scrollElement) {
      if (isWindowScroll) {
        window.removeEventListener("scroll", this.windowScrollListener, false);
      } else if (scrollElement.current && scrollElement.current.removeEventListener) {
        scrollElement.current.removeEventListener("scroll", this.scrollListener, false);
      } else {
        scrollElement.removeEventListener("scroll", this.scrollListener, false);
      }
    }
  }
  windowScrollListener = () => {
    const { threshold } = this.props;
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom + threshold >= docHeight) {
      this.detachScrollListener();
      if (typeof this.props.loadMore === "function") {
        this.pageLoaded += 1;
        this.props.loadMore(this.pageLoaded);
      }
    }
  };
  /**
   * @description Function fires when scroll is done and load more callback is fired.
   * @memberof InfiniteScrollComponent
   */
  scrollListener() {
    const el = this.scrollComponent;
    const parentNode = getParentElement(el);
    const offset = el.scrollHeight - parentNode.scrollTop - parentNode.clientHeight;
    const offsetParent = getOffsetParent(el);
    if (offset < Number(this.props.threshold) && offsetParent !== null) {
      this.detachScrollListener();
      /*
        detach scroll listener after scroll end. Re-Attach it if data is updated.
      */
      if (typeof this.props.loadMore === "function") {
        this.pageLoaded += 1;
        this.props.loadMore(this.pageLoaded);
      }
    }
  }
  render() {
    const { children, element, hasMore, isWindowScroll, loader, loadEnd, pageStart, loadMore, resetPage, ...props } =
      this.props;
    return (
      <div id="infinite" {...props}>
        {children}
        {hasMore ? <>{loader ? loader() : null}</> : <>{loadEnd ? loadEnd() : null}</>}
      </div>
    );
  }
}

export default InfiniteScrollComponent;
