import React, { Component } from "react";
import styled from "styled-components";
import OutsideClickAlerter from "../outSideClickAlerter/outSideClickComponet";
import { ISortObject, ISortProps, ISortState } from "./iSortProps";
import { SortTypes } from "./sort.enum";
import SortViewComponent from "./sortViewComponent";

const SortingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Sortdetailwrapper = styled.div`
  display: flex;
`;

/**
 * @description Component that takes sort fields and returs the callback
 * with sort field and sort type.
 */
class SortComponent extends Component<ISortProps, ISortState> {
  readonly state: ISortState = {
    isOpen: false,
    selectedField: { name: "", label: "" },
    sortType: SortTypes.ASCENDING,
  };
  componentDidMount() {
    const { selectedSortField } = this.props;
    if (selectedSortField) {
      this.setState({
        selectedField: selectedSortField,
        sortType: selectedSortField.sortType,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSortField && this.props.selectedSortField !== prevProps.selectedSortField) {
      this.setState({ selectedField: this.props.selectedSortField, sortType: this.props.selectedSortField.sortType });
    }
  }

  /**
   * @description Function to handle sort field selection. Sets state with selected field
   * and invokes callback to parent.
   * @memberof SortComponent
   */
  handleSelectOption = (data: ISortObject) => {
    const sortType = data.sortType || SortTypes.ASCENDING;
    this.setState({ selectedField: data, sortType }, () => this.setSortType(sortType));
  };

  closeMenu = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };
  setSortType = (sortType) => {
    const { selectedField } = this.state;
    this.props.onFieldSelected(selectedField, sortType);
    this.props.closeMenu();
  };

  /**
   * @description Function to toggle sort type. Sort Types are ASCENDING | DESCENDING
   * @memberof SortComponent
   */
  sortTypeToggle = () => {
    const { sortType } = this.state;
    if (SortTypes.ASCENDING === sortType) {
      this.setState({ sortType: SortTypes.DESCENDING }, () => this.setSortType(this.state.sortType));
    } else {
      this.setState({ sortType: SortTypes.ASCENDING }, () => this.setSortType(this.state.sortType));
    }
  };

  render() {
    const { list, isTranslation, showSortingModal } = this.props;

    const { selectedField, isOpen, sortType } = this.state;
    const viewProps = {
      closeMenu: this.props.closeMenu,
      handleSelectOption: this.handleSelectOption,
      isOpen,
      isTranslation,
      list,
      selectedField,
      showSortingModal,
      sortType,
      sortTypeToggle: this.sortTypeToggle,
      t: this.props.t,
    };
    return (
      <SortingWrapper>
        <OutsideClickAlerter
          ignoreClickedElement={["SortAction"]}
          render={() => (
            <Sortdetailwrapper>
              <SortViewComponent {...viewProps} />
            </Sortdetailwrapper>
          )}
          onClickAway={this.props.closeMenu}
        />
      </SortingWrapper>
    );
  }
}
export default SortComponent;
