// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { statusColor } from "../../../../utils/styledUtils";
import IStatusProps from "./iStatus";
// @ts-ignore;
import theme from "TARGET_BUILD/theme";

/**
 * @description - Styled component for the status component.
 */
const StyledStatus = styled("span")`
  font-family: ${() => theme.fontRoman.fontFamily};
  font-size: ${() => theme.fontSize.base};
  font-weight: 300;
  line-height: 24px;
  height: 24px;
  align-self: flex-start;
  padding: 0px 8px 4px 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 12px;
  ${(props: IStatusProps) => statusColor(props.statusCode)};
`;

/**
 * @description - This shows the status of the asset.
 * ID and Text for the component is configurable from the component using this component.
 *
 * @param - Properties object of the component.
 */
const Status = (props: IStatusProps) => {
  const { id, statusText, statusCode } = props;
  return (
    <React.Fragment>
      <StyledStatus id={id} statusCode={statusCode} statusText={statusText}>
        {statusText}
      </StyledStatus>
    </React.Fragment>
  );
};

/**
 * @export Status - Styled Component.
 */
export default Status;
