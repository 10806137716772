// @ts-ignore
import { GatewayReportSettingsConstants } from "custom-actions";

const initialStates = {
  gatewayReportSettingFrequency: null,
  initialValues: null,
};
const gatewayReportSettingsReducer = (state = initialStates, action) => {
  switch (action.type) {
    case GatewayReportSettingsConstants.RM_SET_FREQUENCY: {
      return {
        ...state,
        gatewayReportSettingFrequency: action.payload,
      };
    }
    case GatewayReportSettingsConstants.RM_SET_SCHEDULER_SETTINGS: {
      return {
        ...state,
        initialValues: action.payload,
      };
    }
    case GatewayReportSettingsConstants.RM_RESET_DATA: {
      return {
        ...state,
        initialValues: null,
      };
    }
    default:
      return { ...state };
  }
};

export default gatewayReportSettingsReducer;
