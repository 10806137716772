import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M19,15 C21.209139,15 23,16.790861 23,19 C23,21.209139 21.209139,23 19,23 C16.790861,23 15,21.209139 15,19 C15,16.790861 16.790861,15 19,15 Z M19.4,18.6 L18.6,18.6 L18.6,21 L19.4,21 L19.4,18.6 Z M19.4,17 L18.6,17 L18.6,17.8 L19.4,17.8 L19.4,17 Z M19,13 L19,14 C16.9495025,14 15.1872936,15.23431 14.4158423,17.0004611 L11,17 L11,13 L19,13 Z M17.5,1 L19,2.5 L19,6 L18.125,7 L16.515,7 L17.5,12 L13.025974,12 L11.9,7 L8.5,7 L5,6 L5,2 L8.5,1 L17.5,1 Z M4,3 L4,5 L1,5 L1,3 L4,3 Z"></path>
    </g>
  </svg>
);

export default SVG;
