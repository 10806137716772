export enum CostCodeType {
  DAILY = "DAILY",
  NONE = "NONE",
  TIME_BASED = "TIME_BASED",
}

export enum AssetCapType {
  NOT_CAPPED = "NOT_CAPPED",
  CAPPED_PER_LOCATION = "CAPPED_PER_LOCATION",
  CAPPED_ASSET_VALUE = "CAPPED_AT_ASSET",
}

export enum costTypeErrorCode {
  CHARGE_SETTING_NOT_AVAILABLE_ASSET = "600024",
  CHARGE_SETTING_NOT_AVAILABLE_TEMPLATE = "600025",
  NONE_NEVERSET = "NONE-NEVERSET",
}

export enum AssetCostType {
  DAILY = "Daily",
  NONE = "None",
  TIME_BASED = "Time based",
}
