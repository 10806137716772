import React from "react";
import { Field as ReduxField } from "redux-form";
import styled from "styled-components";
import FieldWrapper from "../../components/fieldWrapper";
import Field from "./field";
import {
  DefaultChecked,
  DefaultUnChecked,
  ReadOnlyCheckBoxContainer,
  ReadOnlyCheckBoxLabel,
  StyledBoldText,
} from "./formStyledUtils";

interface IToopTipProps {
  readonly getContent?: () => void;
  readonly place?: string;
  readonly width?: string;
}

interface IReadOnlyFormFieldProps {
  component: any;
  readOnly: boolean;
  label: string;
  name: string;
  tooltipInfo?: any;
  id?: string;
}

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

export const readOnlyField = (label, value, tooltipInfo?: IToopTipProps) => {
  return (
    <Wrapper className="read-only-field">
      <Field
        label={label}
        name={label && label.split(" ").join("")}
        html={true}
        place={tooltipInfo && tooltipInfo.place}
        getContent={tooltipInfo && tooltipInfo.getContent}
        width={tooltipInfo && tooltipInfo.width}
      >
        {getBoldText(value)}
      </Field>
    </Wrapper>
  );
};

const getBoldText = (value) => {
  return <StyledBoldText>{value || "-"}</StyledBoldText>;
};

export const ReadOnlyPopupField = (label, value) => {
  return <FieldWrapper labelName={label}>{getBoldText(value)}</FieldWrapper>;
};

export const ReadOnlyCheckBoxField = ({ checked, name, t }) => {
  const label = `assets:FIELD_NAME_${name.toUpperCase()}`;
  return (
    <ReadOnlyCheckBoxContainer>
      {checked ? <DefaultChecked /> : <DefaultUnChecked />}
      {name && (
        <ReadOnlyCheckBoxLabel className={"styledText lbl"} id={`${name}Lbl`}>
          {`${t(label)} (${t("common:PROVIDED_BY_HILTI")})`}
        </ReadOnlyCheckBoxLabel>
      )}
    </ReadOnlyCheckBoxContainer>
  );
};

export const ReadOnlyComponent = (props: any) => {
  const { label, input = {}, tooltipInfo } = props;
  return readOnlyField(label, input.value, tooltipInfo);
};

export const ReadOnlyFormField = (fieldObj: IReadOnlyFormFieldProps) => {
  const { id, component, label, name, tooltipInfo, readOnly } = fieldObj;
  if (readOnly) {
    return <ReduxField id={id} name={name} label={label} tooltipInfo={tooltipInfo} component={ReadOnlyComponent} />;
  } else {
    return component;
  }
};
