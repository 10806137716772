import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <path
      fill="#524F53"
      fillRule="evenodd"
      d="M18.305 15.237a4.068 4.068 0 0 1 4.068 4.068H24l-2.44 2.44-2.441-2.44h1.627a2.44 2.44 0 1 0-1.17 2.085l1.17 1.17a4.068 4.068 0 1 1-2.44-7.322zm-1.688-1.034a5.381 5.381 0 0 0-3.585 4.069h-2.863v-4.069h6.448zM16.78 2l1.525 1.525v3.56l-.89 1.017-1.638-.001 1.003 5.085h-4.55l-1.146-5.085H7.627l-3.56-1.016V3.017L7.628 2h9.153zM3.05 4.034v2.034H0V4.034h3.05z"
    />
  </svg>
);

export default SVG;
