import React from "react";
const SVG = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3236.000000, -986.000000)" fill="#524F53">
        <g transform="translate(3232.000000, 982.000000)">
          <path
            d="M20,16 L20,20 L4,20 L4,16 L20,16 Z M14,4 L14,8 L17,8 L12,15 L7,8 L10,8 L10,4 L14,4 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
