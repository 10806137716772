import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getVersionedApiUrl, HelperLodash } from "am-web-ui-shared/helpers";

declare global {
  interface Window {
    __LIST_PAGE_SIZE: number;
  }
}

const DEFAULTPAGESIZE = 100;

/**
 * @description Function to return API endpoint
 * @param {String} - serviceURl - take service URL
 *
 */
export const getApiUrl = (seriveUrl: ServiceUrl) => {
  return (
    window.ASSET_MANAGEMENT_URLS &&
    (window.ASSET_MANAGEMENT_URLS[ServiceUrl[seriveUrl]] || window.ASSET_MANAGEMENT_URLS.USER_MGMT)
  );
};

export const getPageSize = () => {
  return window.__LIST_PAGE_SIZE || DEFAULTPAGESIZE;
};

export const getAssetMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.ASSET_MGMT, path, params);
};

export const getOrgMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.ORG_MGMT, path, params);
};

export const getUserMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.USER_MGMT, path, params);
};

export const getDocMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.DOC_MGMT, path, params);
};

export const getAuditingEndpoint = (path: string) => getVersionedApiUrl(ServiceUrl.AUDITING, path);

export const getCompanyAssetStatusEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.ORG_MGMT, path, params);
};

export const getAssetOrchMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.ORCHESTRATION, path, params);
};

export const getHiltiCloudRepairOrdersEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.HILTI_CLOUD_REPAIR_ORDERS, path, params);
};

export const getHiltiCloudTechnicalUsersEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.HILTI_CLOUD_TECHNICAL_USERS, path, params);
};

export const getHiltiCloudWarrantyExchangeEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.HILTI_CLOUD_WARRANTY_EXCHANGE, path, params);
};
export const getFeatureFlagsEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.FEATURE_FLAGS, path, params);
};
export const getFleetMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.HILTI_CLOUD_FLEET, path, params);
};
export const getHiltiCloudEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.HILTI_CLOUD, path, params);
};
export const removeField = (fields: string, fieldToRemove: string): string => {
  if (!fields) {
    return fields;
  }
  const fieldCopy = HelperLodash.clone(fields);
  return fieldCopy
    .split(",")
    .filter((field) => field !== fieldToRemove)
    .join(",");
};

export const getTrackUnitEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.TRACK_UNIT, path, params);
};

export const getChargesEndpoint = (path) => {
  return getVersionedApiUrl(ServiceUrl.CHARGES, path);
};
