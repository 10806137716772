import styled from "styled-components";
import AsyncCreatableSelect from "react-select-async-paginate";
import ApplicationConstants from "../../constant/applicationConstants";
import colorPalette from "../../materials/Colors";
import { get } from "../../helpers/helperLodash";

const dataSearchable = "data-searchable";
const disabled = "isDisabled";
const showClearIcon = "showClearIcon";

const AutoCompleteStyledComponent = styled(AsyncCreatableSelect)`
  > div {
    > div {
      color: ${(props) => props.theme.colors.base.steel};
      && {
        :hover {
          border: 0;
        }
      }
      :before {
        border: 0 !important;
        transition: none !important;
      }
      :after {
        transition: none !important;
        border: 0 !important;
      }
    }

    .dropDownSingleValue {
      margin-right: ${(props) => (get(props, showClearIcon) ? "32px" : "8px")};
      p {
        position: ${(props) => (get(props, dataSearchable) ? "absolute" : "relative")};
        line-height: 0;
        left: 0px;
        font-size: ${(props) => props.theme.fontSize.medium};
        font-family: ${(props) =>
          props["data-value"] ? props.theme.fontBold.fontFamily : props.theme.fontRoman.fontFamily};
        color: ${(props) =>
          props["data-value"]
            ? colorPalette.steel
            : get(props, dataSearchable)
              ? colorPalette.steel40
              : colorPalette.steel80};
        max-width: 100%;
        line-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          top: ${(props) => (get(props, dataSearchable) ? "4px" : "0")};
        }
        + div {
          position: relative;
        }
      }
      & > div {
        background: transparent !important;
        margin: 0 !important;
      }
      + div {
        background: ${(props) => props.theme.colors.base.heavyConcrete20};
        height: 44px;
        width: 44px;
        position: relative;
        div {
          padding: 8px 10px 8px 0px;
          color: ${(props) => props.theme.colors.base.steel};
          display: inline-block;
          > svg {
            display: none;
          }
        }
        .imgarrow {
          padding: 0;
          position: absolute;
          top: 10px;
          right: 8px;
          span {
            display: block;
          }
          .arrow {
            transform: rotate(90deg);
            &.up {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }

  .typeselect {
    opacity: ${(props) => get(props, disabled) && ApplicationConstants.OPACITY.O50};
    > div:last-child {
      background-color: ${(props) => (get(props, dataSearchable) ? colorPalette.white : colorPalette.heavyConcrete20)};
      border-color: ${(props) => (props["data-error"] ? colorPalette.hiltiRedError : colorPalette.heavyConcrete60)};
      color: ${(props) => props.theme.colors.base.steel};
      height: 48px;
      > div {
        padding: 0 0 0 16px;
        height: ${(props) => (get(props, dataSearchable) ? "44px" : "")};
        border: 2px solid
          ${(props) => (props["data-error"] ? colorPalette.hiltiRedError : colorPalette.heavyConcrete60)};
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      cursor: initial;
      span {
        display: none;
      }
    }
  }
  &.searchBox {
    > div {
      .dropDownSingleValue {
        p {
          left: 44px;
          max-width: calc(100% - 42px);
        }
        + div {
          left: 2px;
          background: ${(props) => props.theme.colors.base.white};
          position: absolute;
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            top: 2px;
          }
        }
        input {
          padding-left: 44px !important;
        }
      }
    }
    .typeselect {
      > div:last-child {
        > div {
          padding: 0;
        }
      }
    }
  }
  &.multi-label {
    .typeselect {
      height: auto;
      & > div {
        height: auto;
        & > div {
          cursor: pointer;
          height: auto;
          min-height: 44px;
          & > .dropDownSingleValue + div {
            div[class*="loadingIndicator"] {
              display: none;
            }
            min-height: 44px;
            height: auto;
            .imgarrow {
              top: 24%;
            }
          }
        }
      }
    }
    .dropDownSingleValue {
      flex-wrap: wrap;
      width: calc(100% - 72px);
      max-width: calc(100% - 72px);
      min-height: 44px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 8px;
      }
      p {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          top: 0px;
        }
      }
      & > div {
        background-color: ${(props) => props.theme.colors.base.black10} !important;
        margin: 3px !important;
        border-radius: 20px;
        cursor: default;
        &:hover {
          background-color: transparent;
        }
        & > div + div {
          background-color: transparent !important;
        }
        &:last-child {
          background-color: transparent !important;
        }
        svg {
          background-color: ${(props) => props.theme.colors.base.steel};
          border-radius: 100%;
          fill: ${(props) => props.theme.colors.base.white};
          height: 15px;
          width: 15px;
          cursor: pointer;
        }
        svg:hover {
          background-color: ${(props) => props.theme.boldBlackText.color};
        }
      }
    }
  }
  &.multi-select-autocomplete {
    .typeselect {
      height: auto;
      & > div {
        height: auto;
        & > div {
          height: auto;
          min-height: 44px;
          max-height: 200px;
          overflow: auto;
          @media screen and (max-height: 700px) {
            max-height: 90px;
          }
        }
      }
    }
    .dropDownSingleValue {
      margin-left: 23px;
      flex-wrap: wrap;
      width: calc(100% - 23px);
      min-height: 44px;
      & + div {
        left: 0px;
        background: none;
        position: absolute;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          top: 8%;
        }
      }
      p {
        left: 4px;
      }
      & > div {
        background-color: ${(props) => props.theme.colors.base.black10} !important;
        margin: 3px !important;
        border-radius: 20px;
        cursor: default;
        &:hover {
          background-color: transparent;
        }
        & > div + div {
          background-color: transparent !important;
        }
        &:last-child {
          background-color: transparent !important;
        }
        svg {
          background-color: ${(props) => props.theme.colors.base.steel};
          border-radius: 100%;
          fill: ${(props) => props.theme.colors.base.white};
          height: 15px;
          width: 15px;
          cursor: pointer;
        }
        svg:hover {
          background-color: ${(props) => props.theme.boldBlackText.color};
        }
      }
    }
  }
  &.select-scrollable {
    .typeselect {
      height: auto;
      & > div {
        height: auto;
        & > div {
          height: auto;
          min-height: 44px;
          max-height: 200px;
          overflow: auto;
          @media screen and (max-height: 700px) {
            max-height: 250px;
          }
        }
      }
    }
  }

  &.mh-none {
    .typeselect + div > div {
      max-height: none;
    }
  }
`;

export default AutoCompleteStyledComponent;
