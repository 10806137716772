import React from "react";
import { default as TextField } from "../Field/field";
import SelectDropdown from "./formSeachComplete";
import ISelectAutoCompleteProps from "./iSelectAutoCompleteProps";

export const AutoCompleteComponent = (props) => (
  <TextField
    name={props.input.name}
    htmlFor={props.input.name}
    required={props.required}
    label={props.label}
    error={props.meta.error}
    touched={props.meta.touched}
    warning={props.meta.warning}
    cssSetting={props.cssSetting}
  >
    <SelectDropdown
      key={props.key}
      autoComplete={props.autoComplete}
      className={props.className}
      error={props.meta.error && props.meta.touched ? props.meta.error : ""}
      name={props.input.name}
      placeholder={props.placeholder}
      isMulti={props.isMulti}
      options={props.data ? createOptions(props) : []}
      icon={props.icon}
      valueKey={props.valueKey}
      valueId={props.valueId}
      input={props.input}
      isDisabled={props.isDisabled}
      inactiveMessage={props.inactiveMessage}
      {...props}
      id={`${props.input.name}-input-container`}
      inputId={props.input.name}
    />
  </TextField>
);

/**
 * @description - Function to create options.
 * @param {ISelectAutoCompleteProps} props - Props object.
 */
const createOptions = (props: ISelectAutoCompleteProps) => {
  const data = props.data;
  return data.map((item) => ({
    isDisabled: !!item.isDisabled,
    item,
    label: item[props.valueKey],
    value: (item[props.valueId] || item[props.valueId] === 0) && item[props.valueId].toString(),
  }));
};

class AutoComplete extends React.PureComponent<ISelectAutoCompleteProps> {
  render() {
    const input = { value: this.props.selectedValue, onChange: this.props.onSelectChange, ...this.props };
    const meta = { error: this.props.error };
    return <AutoCompleteComponent input={input} meta={meta} {...this.props} />;
  }
}

export default AutoComplete;
