import React from "react";
import styled from "styled-components";
import ExpandRight from "@hilti/components/core/icons/svgs/ExpandRight";
import Divider from "@mui/material/Divider";
import { IDetailsPanelLinkProps } from "./iDetailsPanelLinkProps";

const StyledButton = styled.button`
  border: 0;
  background-color: ${(props) => props.theme.detailsPanel.detailBackground};
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-weight: ${(props) => props.theme.fontRoman.fontWeight};
  font-size: ${(props) => props.theme.fontSize.base};
  &:focus-visible {
    filter: brightness(0.9);
  }
`;

const DetailsPanelLink = ({ onClick, id, text, className }: IDetailsPanelLinkProps) => (
  <>
    <Divider sx={{ mx: -2 }} />
    <StyledButton id={id} className={className} type="button" onClick={onClick}>
      {text}
      <ExpandRight width="24" height="24" />
    </StyledButton>
  </>
);

export default DetailsPanelLink;
