const IdleAssetsActionsConstants = {
  GET_IDLE_ASSETS_LIST: "GET_IDLE_ASSETS_LIST",
  PUT_IDLE_ASSETS_LIST: "PUT_IDLE_ASSETS_LIST",
  GET_IDLE_ASSETS_LIST_EXIST: "GET_IDLE_ASSETS_LIST_EXIST",
  PUT_IDLE_ASSETS_LIST_EXIST: "PUT_IDLE_ASSETS_LIST_EXIST",
  IDLE_ASSETS_RESET_TO_INITIAL_STATE: "IDLE_ASSETS_RESET_TO_INITIAL_STATE",
  SELECT_ALL_IDLE_ASSETS: "SELECT_ALL_IDLE_ASSETS",
  CLEAR_ALL_IDLE_ASSETS: "CLEAR_ALL_IDLE_ASSETS",
  IDLE_ASSETS_SELECT_GRID_CHECKBOX: "IDLE_ASSETS_SELECT_GRID_CHECKBOX",
  IDLE_ASSETS_UNSELECT_GRID_CHECKBOX: "IDLE_ASSETS_UNSELECT_GRID_CHECKBOX",
  IDLE_ASSETS_SELECT_ROW: "IDLE_ASSETS_SELECT_ROW",
  SET_IDLE_ASSETS_FILTER_QUERY: "SET_IDLE_ASSETS_FILTER_QUERY",
  SET_IDLE_ASSETS_SEARCH_QUERY: "SET_IDLE_ASSETS_SEARCH_QUERY",
  SET_IDLE_ASSETS_SORT_QUERY: "SET_IDLE_ASSETS_SORT_QUERY",
  GET_IDLE_ASSETS_LIST_FOR_SEARCH: "GET_IDLE_ASSETS_LIST_FOR_SEARCH",
  SET_IDLE_ASSETS_FILTER_APPLIED_FIELD: "SET_IDLE_ASSETS_FILTER_APPLIED_FIELD",
  GET_IDLE_ASSETS_FILTER_FIELD: "GET_IDLE_ASSETS_FILTER_FIELD",
  SET_IDLE_ASSETS_FILTER_FIELD_SUCCESS: "SET_IDLE_ASSETS_FILTER_FIELD_SUCCESS",
  IDLE_ASSETS_RESET_QUERY_PARAM: "IDLE_ASSETS_RESET_QUERY_PARAM",
  SET_IDLE_ASSETS_LIST_FIELDS_QUERY: "SET_IDLE_ASSETS_LIST_FIELDS_QUERY",
  FETCH_LOCATION_TYPE_FILTER: "FETCH_LOCATION_TYPE_FILTER",
  FETCH_LOCATION_TYPE_FILTER_SUCCESS: "FETCH_LOCATION_TYPE_FILTER_SUCCESS",
  FETCH_IDLE_ASSET_REPORT: "FETCH_IDLE_ASSET_REPORT",
  FETCH_IDLE_ASSET_REPORT_SUCCESS: "FETCH_IDLE_ASSET_REPORT_SUCCESS",
  SAVE_WIDGET_PREFERENCE: "SAVE_WIDGET_PREFERENCE",
  SAVE_WIDGET_PREFERENCE_SUCCESS: "SAVE_WIDGET_PREFERENCE_SUCCESS",
  GET_IDLE_ASSETS_DATA: "GET_IDLE_ASSETS_DATA",
  PUT_IDLE_ASSETS_DATA: "PUT_IDLE_ASSETS_DATA",
};

export default IdleAssetsActionsConstants;
