import React from "react";
const SVG = () => (
  <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2009.000000, -3628.000000)">
        <g transform="translate(2008.000000, 3627.000000)">
          <g>
            <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="24" height="24" />
            <path
              d="M20.9497475,19.5355339 L23.0710678,21.6568542 L21.6568542,23.0710678 L19.5355339,20.9497475 L17.4142136,23.0710678 L16,21.6568542 L18.1213203,19.5355339 L16,17.4142136 L17.4142136,16 L19.5355339,18.1213203 L21.6568542,16 L23.0710678,17.4142136 L20.9497475,19.5355339 Z M19,14.7573593 L17.4142136,13.1715729 L13.5857864,17 L11,17 L11,13 L19,13 L19,14.7573593 Z M16.5151515,7 L17.5,12 L13.025974,12 L11.9004329,7 L8.5,7 L5,6 L5,2 L8.5,1 L17.5,1 L19,2.5 L19,6 L18.125,7 L16.5151515,7 Z M1,3 L4,3 L4,5 L1,5 L1,3 Z"
              id="🎨Color"
              fill="#524F53"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
