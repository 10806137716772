import {
  alertSettingsModule,
  archivedLocationModule,
  assetGroupModule,
  assetsModule,
  certificateTemplatesModule,
  chargeLocationsModule,
  chargesModule,
  companyModule,
  contactModule,
  dashboardModule,
  gatewayReportSetting,
  labelModule,
  locationGroupModule,
  locationsModule,
  manufactureModule,
  qtyItemsModule,
  reportsModule,
  roleModule,
  serviceModule,
  templatesModule,
  transferCartModule,
  transferHistoryModule,
  unitManagementModule,
  userSettingModule,
  workersModule,
  hiltiToolsModule,
  oneMapModule,
} from "../common/loadableModule";

export default [
  {
    component: dashboardModule,
    path: "/dashboard",
  },
  {
    component: assetsModule,
    exact: true,
    path: "/",
  },
  {
    component: assetsModule,
    path: "/assets",
  },
  {
    component: qtyItemsModule,
    dependencies: {
      allowedPermission: "qtyItem.view",
      moduleActivated: "QTY_ITEMS",
    },
    path: "/qtyItems",
  },
  {
    component: chargesModule,
    path: "/assetCosts",
    dependencies: {
      allowedPermission: "assetCost.view",
    },
  },
  {
    component: locationsModule,
    path: "/locations",
  },
  {
    component: reportsModule,
    path: "/reports",
  },
  {
    component: workersModule,
    path: "/workers",
  },
  {
    component: companyModule,
    path: "/assetTracking",
  },
  {
    component: companyModule,
    path: "/company",
  },
  {
    component: companyModule,
    path: "/license",
  },
  {
    component: userSettingModule,
    path: "/userSetting",
  },
  {
    component: companyModule,
    path: "/checkInventory",
  },
  {
    component: companyModule,
    path: "/privateModeSettings",
  },
  {
    component: companyModule,
    path: "/deletionSettings",
  },
  {
    component: companyModule,
    path: "/assetCostCompanySettings",
  },
  {
    component: companyModule,
    path: "/transferSettings",
  },
  {
    component: companyModule,
    path: "/fieldRequestSettings",
  },
  {
    component: companyModule,
    path: "/hiltiAssetStatus",
  },
  {
    component: companyModule,
    path: "/apiAccess",
  },
  /*** ADMIN Routes, currently set to templates, will be changed as developed*/
  {
    component: templatesModule, // create a redirectmodule & handle redirect to a URL
    exact: true,
    path: "/administration",
  },
  {
    component: labelModule,
    exact: true,
    path: "/administration/assets",
  },
  {
    component: assetGroupModule,
    path: "/administration/assets/groups",
  },
  {
    component: manufactureModule,
    path: "/administration/assets/manufacturers",
  },
  {
    component: serviceModule,
    path: "/administration/assets/services",
  },
  {
    component: templatesModule,
    path: "/administration/assets/services",
  },
  {
    component: templatesModule,
    path: "/administration/assets/templates",
  },
  {
    component: hiltiToolsModule,
    path: "/administration/assets/hiltiTools",
    dependencies: {
      allowedPermission: "hiltiTools.view",
    },
  },
  {
    component: transferHistoryModule,
    path: "/administration/general/transferHistory",
  },
  {
    component: templatesModule,
    exact: true,
    path: "/administration/location",
  },
  {
    component: locationGroupModule,
    path: "/administration/location/groups",
  },
  {
    component: workersModule,
    exact: true,
    path: "/administration/workers",
  },
  {
    component: certificateTemplatesModule,
    path: "/administration/workers/certificateTemplates",
    dependencies: {
      allowedPermission: "worker.edit",
    },
  },
  {
    component: roleModule,
    path: "/administration/workers/roles",
    dependencies: {
      allowedPermission: "role.view",
    },
  },
  {
    component: archivedLocationModule,
    path: "/administration/location/archived",
    dependencies: {
      allowedPermission: "location.archived.view",
    },
  },
  {
    component: transferCartModule,
    path: "/transferCart",
  },
  {
    component: contactModule,
    path: "/contact",
  },
  {
    component: labelModule,
    path: "/administration/general/labels",
    dependencies: {
      allowedPermission: "label.view",
    },
  },
  {
    component: alertSettingsModule,
    path: "/administration/general/alertSettings",
    dependencies: {
      allowedPermission: "alertSettings.view",
    },
  },
  {
    component: unitManagementModule,
    dependencies: {
      allowedPermission: "qtyItem.view",
      moduleActivated: "QTY_ITEMS",
    },
    exact: true,
    path: "/administration/general/unit",
  },
  {
    component: gatewayReportSetting,
    path: "/administration/general/gatewayReportSettings",
    dependencies: {
      allowedPermission: "gatewayReportSettings.view",
    },
  },
  {
    component: chargeLocationsModule,
    path: "/administration/location/costLocationSettings",
    dependencies: {
      allowedPermission: "assetCost.settings.view",
    },
  },
  {
    component: oneMapModule,
    path: "/smartMap",
  },
];
