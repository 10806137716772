// @ts-ignore
import styled from "custom-styled-components";

/**
 * @const StyledBoldText - Styled component that is having text with bold font-familiy.
 * @interface IBoldText
 */
const StyledBoldText = styled.div`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  color: ${(props) => props.theme.colors.base.steel};
  max-width: 370px;
  word-break: break-word;
  cursor: default;
  @media (max-width: 1280px) {
    max-width: 230px;
  }
`;

/**
 * @export StyledBoldText -  Styled Component.
 */
export default StyledBoldText;
