import { SquareIcon } from "am-web-ui-shared/components";
import React from "react";
import styled from "styled-components";
// @ts-ignore
import theme from "TARGET_BUILD/theme";
import StyledBeforeIconContainer from "../../Styled/Containers/BeforeIconContainer/styledBeforeIconContainer";
import { IIconBoldCountProps } from "./iIconBoldCount";

interface IStyleHeadingText {
  isPanelOverlap?: boolean;
}
/**
 * @description - Styled heading text.
 */
const StyledHeadingText = styled("div")<IStyleHeadingText>`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  color: ${(props) => props.theme.locationDetail.locationBadge.steel};
  margin-right: 8px;
  padding-right: ${(props) => (props.isPanelOverlap ? "8px" : "0")};
`;

/**
 * @description - Styled heading count. It will change the color and opacity if the count is 0.
 */
const StyledHeadingCount = styled("div")`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  display: inline;
  padding-left: 4px;
`;

/**
 * @description - IconBoldCount component that holds the icon at the left of the heading text and count at the right side.
 * @param props
 */
const IconBoldCount = (props: IIconBoldCountProps) => {
  const { panelId, iconUrl, boldId, boldText, countId, countText, isPanelOverlap } = props;
  return (
    <StyledBeforeIconContainer id={panelId}>
      <SquareIcon name={iconUrl} className="mr-16" />
      <StyledHeadingText id={boldId} isPanelOverlap={isPanelOverlap}>
        {boldText}:<StyledHeadingCount id={countId}>{countText}</StyledHeadingCount>
      </StyledHeadingText>
    </StyledBeforeIconContainer>
  );
};

/**
 * @export IconBoldCount - Styled Component.
 */
export default IconBoldCount;
