// @ts-ignore
import { DocumentActions } from "custom-actions";
import ImageTypeEnum from "../utils/enums/imageTypeEnum";
import store, { RootState } from "../store/store";

const dispatchAction = (action) => {
  store.dispatch(action);
};

export const uploadDocument = (formData, config, responseFormatMapper: null, width?, height?, imageType?) => ({
  type: DocumentActions.AM_UPLOAD_DOCUMENT,
  payload: {
    config,
    documentAttachmentName: formData.attachmentIdKey,
    documentType: formData.documentType,
    fieldName: formData.fieldName,
    formName: formData.formName,
    formData: formData.fileData,
    responseFormatMapper,
    height,
    width,
    imageType,
  },
});

export const getDocumentById = (
  id,
  showLoader?,
  imageType = ImageTypeEnum.original,
  thumbnailWidth?,
  thumbnailHeight?,
) => ({
  id,
  imageType,
  showLoader,
  thumbnailHeight,
  thumbnailWidth,
  type: DocumentActions.AM_GET_DOCUMENT_URL,
});

export const getAsyncDocumentById = (id, showLoader?, imageType = ImageTypeEnum.original) => {
  const currentState = store.getState() as RootState;
  const isDocumentExist = currentState.Document.document && currentState.Document.document[`${id}:${imageType}`];
  if (!isDocumentExist) {
    dispatchAction({ id, imageType, showLoader, type: DocumentActions.AM_GET_DOCUMENT_URL });
  }
};

export const clearAttachmentData = () => ({
  type: DocumentActions.CLEAR_ATTACHMENT_DATA,
});
