import { SharedUtils } from "am-web-ui-shared/helpers";

export const CONFIRM_DELIVERY_TRANSFER_LIST = "confirm_delivery_transfer_list";
export const CONFIRM_DELIVERY_SELECT_TRANSFER = "confirm_delivery_select_transfer";
export const RECALL_TRANSFER_CONFIRM_DELIVERY = "recall_transfer_confirm_delivery";
export const DOWNLOAD_INTRAN_DOC_CONFIRM_DELIVERY = "download_intran_doc_confirm_delivery";

export interface ITransferAction {
  status: string;
  interval: number;
  asset_count: number;
  qty_count: number;
}

export interface IDownloadDocAction extends ITransferAction {
  errorCode?: string;
}

export const trackConfirmDeliveryTransferList = (count: number) => {
  SharedUtils.pushDataToGA(CONFIRM_DELIVERY_TRANSFER_LIST, null, {
    count,
  });
};

export const trackConfirmDeliveryTransfer = (confirmData: ITransferAction) => {
  const { asset_count, qty_count, status, interval } = confirmData;
  SharedUtils.pushDataToGA(CONFIRM_DELIVERY_SELECT_TRANSFER, null, {
    asset_count,
    qty_count,
    status,
    interval,
  });
};

export const trackRecallTransfer = (recallData: ITransferAction) => {
  const { asset_count, qty_count, status, interval } = recallData;
  SharedUtils.pushDataToGA(RECALL_TRANSFER_CONFIRM_DELIVERY, null, {
    asset_count,
    qty_count,
    status,
    interval,
  });
};

export const trackDownloadIntranDoc = (downloadData: IDownloadDocAction) => {
  const { asset_count, qty_count, status, interval, errorCode } = downloadData;
  let params: IDownloadDocAction = {
    asset_count,
    qty_count,
    status,
    interval,
  };
  if (errorCode) {
    params = { ...params, errorCode };
  }
  SharedUtils.pushDataToGA(DOWNLOAD_INTRAN_DOC_CONFIRM_DELIVERY, null, {
    ...params,
  });
};
