export const SERVICE_PERIOD_DAYS = {
  code: "DAYS",
  value: "Days",
};
export const SERVICE_PERIOD_MONTHS = {
  code: "MONTHS",
  value: "Months",
};
export const SERVICE_PERIOD_YEARS = {
  code: "YEARS",
  value: "Years",
};
export const SERVICE_PERIOD_WEEKS = {
  code: "WEEKS",
  value: "Weeks",
};
export const DEFAULT_NOTIFICATION_NUMBER_BASEDON = {
  PERIOD: 10,
  DISTANCE: 250,
  OPERATING_HOUR: 150,
};
export const DEFAULT_NOTIFICATION_NUMBER = 10;

export const FREQUENCY_TYPE = {
  RECURRENT: "RECURRENT",
};

export const CERTIFICATE_PERIOD_DAYS = {
  code: "DAY",
  value: "Days",
};
export const SERVICE_BASED_ON_PERIOD = {
  code: "PERIOD",
  value: "Period",
};
export const SERVICE_BASED_ON_DISTANCE = {
  code: "DISTANCE",
  value: "Distance",
};
export const SERVICE_BASED_ON_KMS = {
  code: "KMS",
  value: "Kms",
};
export const SERVICE_BASED_ON_MILES = {
  code: "MILES",
  value: "Miles",
};
export const SERVICE_BASED_ON_HOURS = {
  code: "HOURS",
  value: "Hours",
};
export const SERVICE_BASED_ON_ENGINE_HOURS = {
  code: "OPERATING_TIME",
  value: "Engine hours",
};

// enum for hilti asset template based on
export enum AssetServiceCategory {
  PERIOD = "PERIOD",
  DISTANCE = "DISTANCE",
  OPERATING_TIME = "OPERATING_TIME",
}
