import { SharedUtils } from "am-web-ui-shared/helpers";
import IFilterFieldModel from "../../common/models/customView/iFilterFieldModel";
import { getGTMFiltersFields } from "./utils/analyticsUtils";
import { ASSET_TEMPLATES } from "TARGET_BUILD/common/components/charges/chargesSchedulerConstants";
import GTMConstants from "TARGET_BUILD/common/gtmConstants";
import { IHolidaysListItems } from "TARGET_BUILD/common/components/charges/holidays/model/iHolidaysList";
import importHistoryConstants from "TARGET_BUILD/common/actionConstants/importHistoryConstants";
import { CostCodeType } from "TARGET_BUILD/common/components/charges/costCodeTypeEnum";

export const FILTER_SEARCH_VALUE = "filter_search_value";
export const FILTER_SHOW_MORE = "filter_show_more";

export const COMBINED_FILTER = "combined_filter";
export const QTY_ITEMS_TAB = "/qtyItems";
export const COST_LOCATIONS_TAB = "/administration/location/costLocationSettings";

export enum EntityTitle {
  ASSETCOSTS = "asset_costs",
  ASSETCOST = "asset_cost",
}

export enum FiltersMapping {
  assetGroupName = 1,
  chargeCenter,
  chargeCalculationByCalenderMonth,
  manufacturerName,
  ownerShipType,
  costCategory,
  assetStatus,
}

export const EventNames = {
  assetGroupName: "asset_group_filter",
  chargeCenter: "cost_locations_filter",
  chargeCalculationByCalenderMonth: "month_filter",
  manufacturerName: "manufacturer_filter",
  ownerShipType: "ownership_type_filter",
  costCategory: "cost_category_filter",
  assetStatus: "status_filter",
};

type FilterType = {
  customAttribute: string;
  query?: string;
};
const FilterValuesNames = {
  manufacturerName: "manufacturer",
};

export const trackEventsForAppliedFilter = (filters, resultCount: number) => {
  const appliedFilter = filters[filters.length - 1];
  SharedUtils.pushDataToGA(EventNames[appliedFilter?.filterName], null, {
    entity: EntityTitle.ASSETCOSTS,
    count: appliedFilter?.filterValue?.length,
    total_result_count: resultCount,
  });
};

export const trackEventsForFilterSearch = (filter: FilterType, eventName: string) => {
  SharedUtils.pushDataToGA(eventName, null, {
    entity: EntityTitle.ASSETCOSTS,
    field: FilterValuesNames[filter.customAttribute] || filter.customAttribute,
  });
};

export const trackChargesCombinedFilters = (filters: IFilterFieldModel[]) => {
  const field = getGTMFiltersFields(filters, FiltersMapping);
  SharedUtils.pushDataToGA(COMBINED_FILTER, null, {
    entity: EntityTitle.ASSETCOST,
    count: filters.length,
    field,
  });
};

export const trackEventsOnFileUpload = (importType: string) => {
  if (importType === importHistoryConstants.IMPORT_TYPE_QUANTITY_ITEMS) {
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.UPLOAD_TEMPLATE_QTY_ITEM_COSTS);
  } else if (importType === ASSET_TEMPLATES) {
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.UPLOAD_ASSET_COSTS_TEMPLATE);
  } else {
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.UPLOAD_ASSET_COSTS_NOT_TEMPLATE);
  }
};

export const trackEventsOnAddHolidays = (holiday: IHolidaysListItems) => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.ADD_HOLIDAYS_TO_COST_LOCATION, null, {
    holiday_recurrence: holiday.holidayRecurrenceCode.value,
  });
};

export const trackEventsOnEditCompanySettings = () => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.EDITING_ASSET_COSTS_COMPANY_SETTINGS);
};

export const trackEventsForACRReport = (reportType: string) => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.GENERATE_ASSET_COSTS_REPORT, null, {
    report_type: reportType,
  });
};

export const trackViewCostLocationsModule = () => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.VIEW_COST_LOCATIONS);
};

export const trackEventsOnEditCostLocation = (costLocationType: string) => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.EDIT_COST_LOCATION, null, {
    cost_location_type: costLocationType,
  });
};

export const trackDeletePlugCostEvent = () => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.DELETE_PLUG_COST);
};

export const trackQtyItemCostsTabEvent = () => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.OPEN_QTY_ITEM_COSTS_LIST);
};

export const trackEventOnAddingQtyItemDailyCosts = () => {
  SharedUtils.pushDataToGA(GTMConstants.EVENTS.ADD_DAILY_COSTS_QTY_ITEM);
};

export const trackEventOnListExport = (isQtyItemCosts: boolean) => {
  isQtyItemCosts
    ? SharedUtils.pushDataToGA(GTMConstants.EVENTS.EXPORT_QTY_ITEM_COSTS)
    : SharedUtils.pushDataToGA(GTMConstants.EVENTS.EXPORT_ASSET_COST);
};

export const trackEventOnTemplateDownload = (importType: string) => {
  if (importType === importHistoryConstants.IMPORT_TYPE_QUANTITY_ITEMS) {
    SharedUtils.pushDataToGA(GTMConstants.EVENTS.DOWNLOAD_IMPORT_TEMPLATE_QTY_ITEM_COSTS);
  }
};
