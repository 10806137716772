import {
  accountActivated,
  accountActivation,
  accountActivationLinkExpired,
  accountAlreadyActivated,
  errorModule,
  info,
  loginModule,
  downloadModule,
} from "../common/loadableModule";

export default [
  {
    component: loginModule,
    path: "/login",
  },
  {
    component: errorModule,
    path: "/error/:errCode",
  },
  {
    component: errorModule,
    path: "/error",
  },
  {
    component: accountActivationLinkExpired,
    noHeader: true,
    path: "/account/activation/linkExpired",
  },
  {
    component: accountActivation,
    noHeader: true,
    path: "/account/activation",
  },
  {
    component: accountActivated,
    noHeader: true,
    path: "/account/activated",
  },
  {
    component: accountAlreadyActivated,
    noHeader: true,
    path: "/account/alreadyActivated",
  },
  {
    component: info,
    noHeader: true,
    path: "/info/:tabName",
  },
  {
    component: info,
    noHeader: true,
    path: "/info",
  },
  {
    component: downloadModule,
    path: "/download/report",
  },
];
