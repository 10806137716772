import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import { StyledDiv, StyledInput, StyledLabel } from "../styled";

const RadioContainer = styled.div`
  padding: 8px 0;
`;

interface IRadioProps {
  checked?: boolean;
  onChange?: (event: any) => void;
  isFormControl?: boolean;
  name?: string;
  label?: string;
  style?: React.CSSProperties;
  selectedValue?: boolean;
  id?: string;
  disabled?: boolean;
}
export const RadioElement = (props) => (
  <RadioContainer>
    <StyledDiv style={props.style}>
      <StyledInput
        {...props.input}
        id={`${props.input.name}Ck`}
        onChange={(event) => {
          return props.input.onChange(event.target.checked);
        }}
      />
      <StyledLabel>
        <span className="styledBox" />
        {props.label && <span className="styledText">{props.label}</span>}
      </StyledLabel>
    </StyledDiv>
  </RadioContainer>
);

class Radio extends React.PureComponent<IRadioProps> {
  render() {
    const { onChange, style, id, label } = this.props;
    return !this.props.isFormControl ? (
      <StyledDiv style={style}>
        <StyledInput
          checked={this.props.checked}
          onChange={(event) => {
            return onChange(event.target.checked);
          }}
          id={id}
          disabled={this.props.disabled}
        />
        <StyledLabel>
          <span className="styledBox" />
          {label && <span className="styledText">{label}</span>}
        </StyledLabel>
      </StyledDiv>
    ) : (
      <Field
        name={this.props.name}
        component={RadioElement}
        label={this.props.label}
        id={this.props.id || this.props.name}
        {...this.props}
      />
    );
  }
}

export default Radio;
