/**
 * @description Action constants to be used across the project
 *
 * format: {feature}_{action}
 */
const ActivityActionConstants = {
  ACTIVITY_REQUEST_FINISHED: "ACTIVITY_REQUEST_FINISHED",
  ACTIVITY_REQUEST_INITIATED: "ACTIVITY_REQUEST_INITIATED",
};

export default ActivityActionConstants;
