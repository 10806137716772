import React from "react";
import Radio from "../../../elements/Radio/radio";
import { HelperLodash } from "../../../helpers";
import { dependencyResolver, getElementId } from "../gridUtils";
import { IGridCell } from "./iGridCell";
/**
 * Grid cell for combinational field and column having multiple values
 */

export const RadioCombinationCell = (props: IGridCell) => {
  const { column, rowIndex, columnIndex, dependencies } = props;
  return (
    <>
      {column.bindProperties.map((bindProperty, index) => {
        return renderCombinationColumn(bindProperty, rowIndex, columnIndex, index, dependencies, column.dataKey, props);
      })}
    </>
  );
};

/**
 * @description renderCombinationColumn will render the values for single colum.
 * @param bindProperty { Array Object} contains the keys of the combination.
 * @param rowIndex
 * @param columnIndex
 * @param index
 * @param dependenciesFunction
 * @param dataKey
 * @param props
 * @index {number} contains the index of the data.
 */
export const renderCombinationColumn = (
  bindProperty,
  rowIndex: number,
  columnIndex: number,
  index: number,
  dependenciesFunction,
  dataKey,
  props,
) => {
  const handleClick = (event) => {
    dependencyResolver(dependenciesFunction, bindProperty.properties[0].dependencies.format)(event, props.data);
  };
  const getCheckedValue = (keyName) => {
    return dependencyResolver(dependenciesFunction, bindProperty.properties[0].isChecked)(keyName, props.data);
  };
  const isRadioDisabled = (keyName) => {
    const isDisabled = bindProperty.properties[0].isDisabled;
    return isDisabled && dependencyResolver(dependenciesFunction, isDisabled)(keyName, props.data);
  };

  const getRadioLabel = (name) => {
    return bindProperty.properties[0].label
      ? dependencyResolver(dependenciesFunction, bindProperty.properties[0].label)(name)
      : null;
  };

  const id = getElementId(rowIndex, columnIndex, index, bindProperty);
  const radioName = `${HelperLodash.get(props.data, dataKey)}${bindProperty.key}`;
  return (
    <Radio
      name={radioName}
      id={id}
      key={`radio_${rowIndex}${index}`}
      radioValue={bindProperty.properties[0].name}
      radioLabel={getRadioLabel(bindProperty.properties[0].name)}
      isFormControl={false}
      onSelectionChange={(event) => {
        return handleClick(event);
      }}
      checked={getCheckedValue(bindProperty.properties[0].name)}
      disabled={isRadioDisabled(bindProperty.key)}
    />
  );
};

export default React.memo(RadioCombinationCell);
