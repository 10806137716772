import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M18.1944558,17.7339782 L18.1944558,22.2168618 L17.3871245,22.2168618 L17.3871245,22.770614 C17.3871245,22.8974035 17.284416,23 17.1577385,23 C17.0521739,23 16.9632545,22.9287525 16.936547,22.8316297 L16.9283525,22.770614 L16.9283525,22.2168618 L16.229386,22.2168618 C16.1242531,22.2168618 16.0350459,22.1451865 16.0082305,22.0482776 L16,21.9874758 L16,17.7339782 L18.1944558,17.7339782 Z M22.4228083,17.7339782 L22.4228083,21.9874758 C22.4228083,22.0926204 22.3511329,22.1818199 22.2542241,22.2086323 L22.1934223,22.2168618 L21.4944558,22.2168618 L21.4944558,22.770614 C21.4944558,22.8974035 21.3917473,23 21.2650698,23 C21.1595051,23 21.0705857,22.9287525 21.0438782,22.8316297 L21.0356837,22.770614 L21.0356837,22.2168618 L20.2283525,22.2168618 L20.2283525,17.7339782 L22.4228083,17.7339782 Z M19.7688245,17.8057733 L19.7688245,22.2168618 L18.6539978,22.2168618 L18.6539978,17.8057733 L19.7688245,17.8057733 Z M20,8 L20,9.999 L16.0017903,10 L16.001,12.999 L14,13 L14,17.5 L13.9976122,18.3181818 L14,19.1363636 L14,20 L2,20 L2,8 L20,8 Z M20.3583342,12 C20.4634788,12 20.5526782,12.0700808 20.5794907,12.167787 L20.5877202,12.229386 L20.5877202,14.7618165 L22.1934223,14.7618165 C22.2985668,14.7618165 22.3877663,14.8334919 22.4145788,14.9304007 L22.4228083,14.9912025 L22.4228083,17.2758782 L16,17.2758782 L16,14.9912025 C16,14.8860579 16.0716851,14.7968585 16.1685891,14.770046 L16.229386,14.7618165 L17.8350881,14.7618165 L17.8350881,12.229386 C17.8350881,12.122328 17.9067731,12.0344042 18.0036771,12.0080701 L18.0644741,12 L20.3583342,12 Z M12,15 L10,15 L10,17 L12,17 L12,15 Z M7,15 L5,15 L5,17 L7,17 L7,15 Z M20.1289482,12.458772 L18.2938601,12.458772 L18.2938601,14.7618165 L20.1289482,14.7618165 L20.1289482,12.458772 Z M7,11 L5,11 L5,13 L7,13 L7,11 Z M12,11 L10,11 L10,13 L12,13 L12,11 Z M7,1 L7,3 L15,3 L15,1 L18,1 L18,3 L20,3 L20,6 L2,6 L2,3 L4,3 L4,1 L7,1 Z"
        id="Combined-Shape"
        fill="#524F53"
      />
    </g>
  </svg>
);

export default SVG;
