import { DialogContainer, Footer, ListContainer } from "am-web-ui-shared/components";
import Tooltip from "@hilti/components/core/tooltip";
import { Button, Form } from "am-web-ui-shared/elements";
import { LocaleManager } from "am-web-ui-shared/helpers";
import ApplicationConstants from "../../../applicationConstants";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { appConstants } from "../../../../config";
import { ToastrType } from "../../../actions/toastrAction";
import { ActionItem } from "../../../utils/enums/actionItemEnum";
import { ModuleName } from "../../../utils/enums/moduleNameEnum";
import { ValidationConstants } from "../../../utils/enums/validationConstants";
import { injectError } from "../../../utils/errorUtils";
import AdditionalInformationPanel from "./additionalInformation/additionalInformationPanel";
import AttachmentPanel from "./attachment/attachmentPanel";
import { IServiceFormProps } from "./iServiceFormProps";
import OtherDetailsPanel from "./otherDetail/otherDetailsPanel";
import ServiceDetailsPanel from "./serviceDetail/serviceDetailsPanel";
import { ServiceResultEnum, ServiceStatus } from "./serviceFormEnum";
import ServiceFrequencyPanel from "./serviceFrequency/serviceFrequencyPanel";
import { isServiceResultSettingEnabled } from "../../../../common/utils/configUtil";
import SingletonFeatureManager from "../../../../featureFlagManager/featureFlagManager";
import { FEATURES } from "../../../../featureFlagManager/featureConfig";
import { AssetServiceCategory } from "../../../../common/utils/enums/serviceEnum";
const AllPanel = styled.div`
  width: 100%;
`;
const ButtonWrapper = styled.div`
  margin-left: auto;
`;
export class AssignServiceForm extends React.PureComponent<IServiceFormProps, {}> {
  state = {
    isAssginButtonDisable: false,
    selectedService: { basedOn: { code: null, value: null } },
    serviceId: null,
    serviceStatus: this.props.serviceStatus || ServiceStatus.OPEN,
    serviceResult: {
      code: ServiceResultEnum.PASSED.toUpperCase(),
      value: ServiceResultEnum.PASSED,
    },
    scheduleAt: null,
  };

  componentDidMount() {
    this.props.getCurrenciesList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.serviceOperationError && this.props.serviceOperationError !== prevProps.serviceOperationError) {
      this.props.submitForm("assignServiceForm");
    }
    if (this.props.assignServiceSuccess && this.props.assignServiceSuccess !== prevProps.assignServiceSuccess) {
      this.closeAssignForm();
    }
  }

  componentWillUnmount() {
    this.props.clearAssignedServiceList();
  }

  serviceDropDownHandler = (value) => {
    if (value && value.id) {
      this.setState({ serviceId: value.id, selectedService: value.item });
      this.props.getServiceTemplateDetails(value.id);
    }
  };

  handleServiceResult = (value) => {
    if (value) {
      this.setState({ serviceResult: value.item });
      this.props.serviceResultValue(value.item);
    }
  };

  /**
   * To handle status of service
   */

  handleStatusRadioChange = (event) => {
    this.setState({ serviceStatus: event.target.value });
  };

  /**
   * To handle Next service scheduled at
   */

  handleScheduledAtChange = (event) => {
    this.setState({
      scheduleAt: parseInt(event?.target?.value, 10),
    });
  };

  /**
   * To render history section
   */
  renderHistorySection = () => {
    return (
      <>
        <ServiceFrequencyPanel
          distanceUnitValue={this.props.distanceUnitValue}
          selectedService={this.state.selectedService}
        />
        <OtherDetailsPanel currenciesList={this.props.currenciesList} defaultCurrency={this.props.defaultCurrency} />
        <AttachmentPanel
          formName="assignServiceForm"
          associatedEntity="assetHistoryService"
          viewOnlyAttachment={
            this.props.serviceTemplateDetails ? this.props.serviceTemplateDetails.serviceAttachments : []
          }
        />
        <AdditionalInformationPanel />
      </>
    );
  };

  /**
   * its a callback to show unhandle error
   */
  getErrorMessage = (error) => {
    this.setState({ errorMessage: error });
  };

  handleErrorMessage = (error, props) => {
    injectError(error, props, this.props.t, null);
    this.props.clearServiceData();
  };

  closeAssignForm = () => {
    this.props.handleServiceList(this.state);
    this.props.closeAddAssetDialog();
    this.props.clearServiceData();
  };

  formatSelectedService = (serviceInfo, serviceStatus) => {
    const { scheduleDate, completionDate, costCurrency } = this.props;
    const { serviceId, selectedService } = this.state;
    const isServiceOfHeavyEquipment = [AssetServiceCategory.DISTANCE, AssetServiceCategory.OPERATING_TIME].includes(
      selectedService?.basedOn?.code,
    );
    const { provider, notes, cost, serviceAttachments, nextServiceDate, completionAt, scheduleAt, isSystemService } =
      serviceInfo;

    if (serviceStatus === ServiceStatus.HISTORY) {
      let payload =
        SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE) && isServiceOfHeavyEquipment
          ? {
              ...this.state.selectedService,
              completionDate: null,
              cost: cost ? parseFloat(cost).toFixed(ApplicationConstants.COUNT.CNT2) : null,
              costCurrency,
              notes,
              provider,
              scheduleDate: null,
              serviceAttachments,
              serviceId,
              serviceStatus,
              serviceResult: this.state.serviceResult,
              completionAt,
              scheduleAt,
              isSystemService,
            }
          : {
              ...this.state.selectedService,
              completionDate: LocaleManager.dateHelpers.getDateInAPIFormat(completionDate),
              cost: cost ? parseFloat(cost).toFixed(ApplicationConstants.COUNT.CNT2) : null,
              costCurrency,
              notes,
              provider,
              scheduleDate: LocaleManager.dateHelpers.getDateInAPIFormat(scheduleDate),
              serviceAttachments,
              serviceId,
              serviceStatus,
              serviceResult: this.state.serviceResult,
              isSystemService,
            };
      if (isServiceResultSettingEnabled()) {
        payload = { ...payload, serviceResult: this.state.serviceResult };
      } else {
        delete payload.serviceResult;
      }
      return payload;
    }
    return SingletonFeatureManager.isFeatureEnabled(FEATURES.HEAVY_EQUIPMENT_TEMPLATE) && isServiceOfHeavyEquipment
      ? {
          serviceId,
          serviceStatus,
          scheduleDate: null,
          scheduleAt: this.state.scheduleAt,
        }
      : {
          serviceId,
          serviceStatus,
          scheduleDate: LocaleManager.dateHelpers.getDateInAPIFormat(nextServiceDate),
        };
  };

  editAssetServiceSubmit = (serviceInfo, props) => {
    const selectedService = this.formatSelectedService(serviceInfo, this.state.serviceStatus);
    this.isserviceOperationError(selectedService, props, this.state.serviceStatus);
  };

  /**
   * form handle submit
   */
  handleServiceSubmit = (serviceInfo, _dispatch, props) => {
    const { actionType, handleServiceList, showToaster, t } = this.props;
    if (actionType === ActionItem.edit) {
      const selectedService = {
        serviceTemplateId: this.state.serviceId,
      };
      this.isAssetModule()
        ? this.editAssetServiceSubmit(serviceInfo, props)
        : this.isserviceOperationError(selectedService, props);
    } else {
      const selectedService = this.formatSelectedService(serviceInfo, this.state.serviceStatus);
      handleServiceList({ ...this.state, ...selectedService });
      showToaster(ToastrType.success, t("services:ASSIGNED_SUCCESSFULLY"));
    }
  };

  checkHistoryService = () => {
    return this.state.serviceStatus === ServiceStatus.HISTORY && this.isAssetModule();
  };
  /**
   * check where assigned service having error or not
   */
  isserviceOperationError = (selectedService, props, status = null) => {
    const { assetId, assignHistoryService, serviceOperationError } = this.props;
    if (!serviceOperationError) {
      assignHistoryService(assetId, selectedService, status);
    } else if (serviceOperationError) {
      this.handleErrorMessage(this.props.serviceOperationError, props);
    }
  };

  /**
   * check where it is serviceStatus exist or not
   */
  isAssetModule = () => {
    const { moduleName } = this.props;
    return moduleName === ModuleName.asset;
  };
  checkAssociatedFlag = (info: any) => {
    return info.associatedFlag === false;
  };
  checkIsALLAssigned = (actionType) => {
    if (actionType === ActionItem.edit) {
      return this.state.isAssginButtonDisable;
    } else {
      return this.props.serviceList && this.props.serviceList.length === this.props.assignedServicesList.length;
    }
  };
  tooltipMessage = () => {
    let toolTipText;
    if (this.checkAssetTemplateAssignedServicesMaxLength()) {
      toolTipText = "errorCodes:20502";
    } else {
      toolTipText = "services:SELECT_SERVICE_MESSAGE";
    }
    return toolTipText;
  };
  assignedButtonDisable = () => {
    const { actionType, moduleName, t, serviceStatus } = this.props;
    if (serviceStatus !== ServiceStatus.HISTORY) {
      const isAllDisable = this.checkIsALLAssigned(actionType);

      if (isAllDisable) {
        return this.isAssetModule()
          ? t("services:ALL_ASSIGNED_SERVICES", { moduleName })
          : t("services:ALL_ASSIGNED_SERVICES_TEMPLATE");
      }
    }
    return t(this.tooltipMessage());
  };
  /**
   * Method to check length of assignedServicesList
   */
  checkAssetTemplateAssignedServicesMaxLength = () => {
    return (
      this.props.moduleName === ModuleName.assetTemplate &&
      this.props.serviceList &&
      this.props.serviceList.length === ValidationConstants.MAX_LENGTH.NUM_30
    );
  };
  /**
   * check is all assoication disabled
   */
  isAllAssigned = (value: boolean) => {
    if (value !== this.state.isAssginButtonDisable) {
      this.setState({ isAssginButtonDisable: value });
    }
  };

  render() {
    const {
      actionType,
      assignedServicesList,
      onFormchange,
      moduleName,
      serviceList,
      serviceValue,
      serviceActionType,
      t,
      distanceUnitValue,
    } = this.props;
    const isHistoryService = this.checkHistoryService();

    return (
      <Form
        form="assignServiceForm"
        initialValues={
          serviceActionType === ActionItem.assign
            ? {
                currency: this.props.defaultCurrency && this.props.defaultCurrency.code,
                serviceResult: ServiceResultEnum.PASSED.toUpperCase(),
              }
            : {}
        }
        onSubmit={this.handleServiceSubmit}
      >
        <DialogContainer>
          <ListContainer modalFooter={appConstants.MODAL_FOOTER_HEIGHT}>
            <AllPanel>
              <ServiceDetailsPanel
                actionType={actionType}
                assignedServicesList={assignedServicesList}
                serviceDropDownHandler={this.serviceDropDownHandler}
                selectedService={this.state.selectedService}
                serviceStatus={this.state.serviceStatus}
                serviceList={serviceList}
                serviceValue={this.state.serviceId}
                showDescription={isHistoryService}
                showScheduleDate={isHistoryService}
                showCompletionDate={isHistoryService}
                showServiceDropDown={true}
                showServiceRadioButton={true}
                onFormchange={onFormchange}
                handleStatusRadioChange={this.handleStatusRadioChange}
                handleScheduledAtChange={this.handleScheduledAtChange}
                moduleName={moduleName}
                serviceActionType={serviceActionType}
                isAllServiceAssigned={this.checkIsALLAssigned(actionType)}
                assetId={this.props.assetId}
                allAssignedService={this.props.allAssignedService}
                isAllAssignedFlag={this.isAllAssigned}
                handleServiceResult={this.handleServiceResult}
                distanceUnitValue={distanceUnitValue}
              />
              {this.state.serviceStatus === ServiceStatus.HISTORY &&
                this.isAssetModule() &&
                this.renderHistorySection()}
            </AllPanel>
          </ListContainer>
        </DialogContainer>
        <Footer shouldRenderChildren={true}>
          <Tooltip
            id="footerAssignButton"
            title={
              (!this.props.serviceValue || this.checkAssetTemplateAssignedServicesMaxLength()) &&
              this.assignedButtonDisable()
            }
            placement="left"
          >
            <ButtonWrapper>
              <Button
                id="footerAssignButton"
                buttonType={serviceValue && !this.checkAssetTemplateAssignedServicesMaxLength() ? "cta" : "disabled"}
                type="submit"
              >
                {t("common:ASSIGN")}
              </Button>
            </ButtonWrapper>
          </Tooltip>
        </Footer>
      </Form>
    );
  }
}

export default withTranslation()(AssignServiceForm);
