import { GridView } from "am-web-ui-shared/components";
import React from "react";
import { getIconByAssetSubType } from "../../../../../utils/assetTypeUtils";
import { formatCount, getDisplayContact } from "../../../../../utils/commonUtils";
import { EntityInfoBoxWrapper } from "../../../../../utils/styledUtils";

import { ILocationInfoProps } from "./iLocationInfoProps";

/**
 * @description - Function to return asset info box.
 * @param {IAssetInfo} props - Properties of the component.
 *
 */

export const LocationInfo = (props: ILocationInfoProps) => {
  const { columnConfig, t } = props;
  const formatAssetCount = (value: string) => {
    const messages = {
      noValueText: t("common:LABEL_NO_ASSETS"),
      pluralText: t("common:LABEL_ASSETS_PLURAL"),
      singulerText: t("common:LABEL_ASSET_SINGULAR"),
    };

    return formatCount(value, messages);
  };

  const styleAttr = { style: { width: "992px", margin: "0 auto", borderBottom: "0" } };

  return (
    <EntityInfoBoxWrapper className="modal-header-grid">
      <GridView
        columns={columnConfig.columns}
        dependencies={{
          getAlternateDefaultImage: getIconByAssetSubType,
          propertyDependencies: {
            assetsCount: formatAssetCount,
            locationManagerMobileNumber: getDisplayContact,
            locationPhone: getDisplayContact,
          },
        }}
        rowsData={[props.rowData]}
        rowAttributes={styleAttr}
        idKey="locationId"
        showTooltip={false}
        disableCheckBoxColumn={true}
        lazyLoadList={false}
      />
    </EntityInfoBoxWrapper>
  );
};
