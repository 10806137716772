import React from "react";
import styled from "styled-components";
import IDropdownProps from "./iDropdown";

const Container = styled("div")`
  min-height: 48px;
  width: 360px;
  position: absolute;
  box-shadow:
    -7px 5px 7px 0 ${(props) => props.theme.colors.base.borderColor},
    inset 0 0 0 0 ${(props) => props.theme.colors.base.borderColor};
  right: ${(props: IContainerProps) =>
    props.dropDownPosition && props.dropDownPosition.right ? props.dropDownPosition.right : 0};
  background: ${(props) => props.theme.colors.base.white};
  z-index: 99;
  top: ${(props: IContainerProps) =>
    props.dropDownPosition && props.dropDownPosition.top ? props.dropDownPosition.top : 0};
`;

interface IContainerProps {
  readonly dropDownPosition?: any;
}

class Dropdown extends React.PureComponent<IDropdownProps> {
  render() {
    const { renderAsProps, dropDownPosition } = this.props;
    return <Container dropDownPosition={dropDownPosition}>{renderAsProps()}</Container>;
  }
}

export default Dropdown;
