// @ts-ignore
import { LogoutActions } from "custom-actions";
import { takeLatest } from "redux-saga/effects";
import { logoutWebUI } from "../../../api/logoutApi";
import { call } from "../../../sagas/common/commonSaga";

export function* logoutSaga() {
  yield takeLatest(LogoutActions.LOGOUT, logout);
}

function* logout() {
  const response = yield call(() => {
    return logoutWebUI();
  });
  if (response && !response.error) {
    window.location.href = response.redirectUrl;
  }
}

export default logoutSaga;
