// @ts-ignore
import { APIMessages } from "custom-actions";
import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { IGetLabelResponse } from "../models/labels.model";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import { getAssetMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description : get Label Type API URL path.
 * @labelModule : Label type for Asset, Location and Worker.
 */

const getLabelAPIUrl = (labelModule) => {
  const msUrl = {
    assets: ServiceUrl.ASSET_MGMT,
    locations: ServiceUrl.ORG_MGMT,
    workers: ServiceUrl.USER_MGMT,
  };
  return msUrl[labelModule];
};

/**
 * @description : draws the list of labels.
 * @queryObj : its use to provide query related to sorting and ordering
 */
export const getLabels = async (queryObj, loader = true, labelModule?): Promise<IGetLabelResponse | IErrorResponse> => {
  const url = getVersionedApiUrl(getLabelAPIUrl(labelModule), `/labels`);
  return Gateway.get(url, { ...queryObj }, null, null, loader) as unknown as IGetLabelResponse;
};

/**
 * @description : deletes a  label associated to a specific asset.
 */
export const deleteLabels = async (assetId, assetData): Promise<any> => {
  try {
    const url = getAssetMgmtEndpoint(`/unique-asset/${assetId}`);
    assetData = { ...assetData, categoryCode: "UNQAST" };
    return Gateway.put(url, null, assetData, null, false) as unknown as any;
  } catch (error) {
    return { error: APIMessages.UNKNOWN_ERROR };
  }
};

/**
 * @description : creata a new label not existing in nature.
 */
export const createLabel = async (label, labelModule?): Promise<any> => {
  try {
    const url = getVersionedApiUrl(getLabelAPIUrl(labelModule), `/labels`);
    const labels = [];
    if (!Array.isArray(label)) {
      label.split(",").map((item) => labels.push({ labelName: item }));
    } else {
      label.forEach((item) => labels.push({ labelName: item }));
    }

    const labelData = { labels };
    return Gateway.post(url, null, labelData, null) as unknown as any;
  } catch (error) {
    return { error: APIMessages.UNKNOWN_ERROR };
  }
};

/**
 * @description : update label.
 * @param id: id is used to update the record.
 * @param labelData: label form data
 */
export const updateLabel = async (id, labelData, labelModule): Promise<any> => {
  try {
    const url = getVersionedApiUrl(getLabelAPIUrl(labelModule), `/labels/${id}`);
    return Gateway.put(url, null, labelData, null) as unknown as any;
  } catch (error) {
    return { error: APIMessages.UNKNOWN_ERROR };
  }
};
/**
 * @description - This method is used to delete the existing label based on label Id.
 *
 * @param {number} labelId - Label Id.
 */
export const deleteLabel = async (labelId: number, labelModule): Promise<any> => {
  return Gateway.delete(
    getVersionedApiUrl(getLabelAPIUrl(labelModule), `/labels/${labelId}`),
    null,
    null,
    false,
  ) as unknown;
};
