import { ActionBar, handleActionItemDisable } from "am-web-ui-shared/components";
import { CheckAuthorization, HelperLodash } from "am-web-ui-shared/helpers";
import React from "react";
import IActionBarWrapperState from "../../components/commonState/iActionBarWrapperState";
import { ActionItem } from "../../utils/enums/actionItemEnum";
import IActionBarWrapperProps from "./iActionBarWrapperProps";

export class ActionBarWrapper extends React.Component<IActionBarWrapperProps, IActionBarWrapperState> {
  state = new IActionBarWrapperState(this.props.actionBarSettings);

  componentDidUpdate(prevProps) {
    const {
      checkedAssetListInfo: { checkedRows, isAnyRowChecked, isMultipleRowChecked },
    } = this.props;

    let actionItems = this.state.actionBarSettings.actionItems;

    if (checkedRows && !HelperLodash.isEqual(prevProps.checkedAssetListInfo.checkedRows, checkedRows)) {
      actionItems = handleActionItemDisable(
        actionItems,
        [ActionItem.edit, ActionItem.delete, ActionItem.transfer],
        isAnyRowChecked,
      );

      if (isMultipleRowChecked) {
        actionItems = handleActionItemDisable(
          actionItems,
          [ActionItem.delete, ActionItem.transfer, ActionItem.multiEdit],
          true,
        );
      }

      this.setState({
        actionBarSettings: {
          ...this.state.actionBarSettings,
          actionItems,
        },
      });
    }
  }

  handleSelectAll = (checked) => {
    const { onClearAllSelection, onSelectAll, closeMoreFilter, getAssetCodeList } = this.props;
    if (!checked) {
      onClearAllSelection();
    } else {
      onSelectAll();
    }
    closeMoreFilter();
    getAssetCodeList && getAssetCodeList();
  };

  render() {
    const {
      actionBarItemInfo,
      checkedAssetListInfo: { checkedRows },
      dependencies,
      onActionItemClicked,
      shouldRenderCheckBox,
    } = this.props;

    return (
      <ActionBar
        actionBarSettings={this.state.actionBarSettings}
        actionBarItemInfo={actionBarItemInfo}
        onActionItemClicked={onActionItemClicked}
        shouldRenderCheckBox={shouldRenderCheckBox}
        dependencies={dependencies}
        handleSelectAll={this.handleSelectAll}
        selectedItemListCount={checkedRows?.length}
        checkAuthorization={CheckAuthorization}
      />
    );
  }
}

export default ActionBarWrapper;
