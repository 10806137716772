import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#524F53"
        fillRule="evenodd"
        d="M18.5 13a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9zM14 1l2 2-3 3 3 3 3-3 2 2-2.402 4.002L18.5 12c-1.177 0-2.268.37-3.163 1H13l-8 8-4-4 8-8V4l5-3zm6.48 14.848l-2.546 2.545-1.273-1.272-.636.636 1.91 1.91 3.181-3.183-.636-.636z"
      />
    </g>
  </svg>
);

export default SVG;
