import Gateway from "../gateway/gateway";

const publicUrl = window.PUBLIC_API;

export interface ILanguage {
  value: string;
  code: string;
}

export const getLanguageTermsAndCondition = async (countryCode: string) => {
  const url = `${publicUrl}languages?countryCode=${countryCode}`;
  return Gateway.get(url, null, null) as unknown as ILanguage[];
};

export const getLicenseAgreementContent = (landuageCode: string, countryCode: string) => {
  return `${publicUrl}mobile/eula/${landuageCode}_${countryCode}`;
};
