import axios from "axios";
import { HelperLodash } from "am-web-ui-shared/helpers";
import { getMapKey } from "../../../common/utils/mapUtil";

const MAP_KEY = getMapKey();
const GEOCODE_BASE_URL = "https://maps.googleapis.com/maps/api/geocode/json";

export type GeocoderResult = {
  results: {
    types: string[];
    formatted_address: string;
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
    address_components: {
      short_name: string;
      long_name: string;
      postcode_localities: string[];
      types: string[];
    }[];
  }[];
};

export const getGeoCode = (params) => {
  HelperLodash.set(params, "key", MAP_KEY);
  return axios.get<GeocoderResult>(GEOCODE_BASE_URL, { params }).then((r) => r?.data);
};
