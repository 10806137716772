import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" fill="#524F53">
      <path d="M19.8181818,13.1818182 L21.2727273,14.6363636 L19.0909091,16.8191515 L21.2727273,19 L19.8181818,20.4545455 L17.6363636,18.2727273 L15.4545455,20.4545455 L14,19 L16.1818182,16.8181818 L14,14.6363636 L15.4545455,13.1818182 L17.6363636,15.3646061 L19.8181818,13.1818182 Z M7.45454545,10.2727273 L7.45454545,19.3636364 L2,14.8181818 L7.45454545,10.2727273 Z M13.8244848,12.9995152 L12.2060053,14.6196404 L14.2220606,16.6358788 L7.45454545,16.6363636 L7.45454545,13 L13.8244848,12.9995152 Z M16.5454545,3 L22,7.54545455 L16.5454545,12.0909091 L16.5454545,9.36363636 L9.27272727,9.36363636 L9.27272727,5.72727273 L16.5454545,5.72727273 L16.5454545,3 Z"></path>
    </g>
  </svg>
);

export default SVG;
