import styled from "styled-components";

export const ButtonsHolder = styled.div<{ showCheckbox?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.showCheckbox ? "space-between" : "flex-end")};
  width: ${(props) => (props.showCheckbox ? "100%" : "unset")};
`;

export const BodyWrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: ${(props) => props.theme.colors.base.blackrgba};
`;
export const Error = styled.div`
  position: absolute;
  top: 37px;
  font-family: ${(props) => props.theme.fontNormal.fontFamily};
  color: ${(props) => props.theme.colors.base.hiltiRed};
  font-size: ${(props) => props.theme.fontSize.small};
`;

export const DialogOuter = styled.div`
  display: flex;
  justify-content: center;
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.base.blackrgba};
  @media (min-height: 401px) {
    align-items: center;
  }
`;

export const DialogScroll = styled.div`
  position: relative;
  box-sizing: inherit;
  &.high-dialog {
    height: 100%;
    margin: 32px 0;
  }
  &.filter-high-dialog {
    height: 100%;
  }
  &.auto-margin-dialog {
    margin: auto;
  }
`;

export const DialogBoxWrapper = styled.div`
  width: 488px;
  border: 12px solid transparent;
  box-sizing: inherit;
`;

export const DialogImage = styled.div`
  position: relative;
  left: -24px;
  top: -24px;
  height: 220px;
  width: 465px;
  border: 12px solid transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  object-fit: cover;
  img {
    position: relative;
    left: -6px;
    top: -36px;
    height: 220px;
    width: 465px;
    vertical-align: middle;
  }
`;

export const CrossLockeItem = styled.div`
  display: flex;
  justify-content: flex-end;
  .icon {
    cursor: pointer;
    z-index: 999;
    background-color: ${(props) => props.theme.colors.base.blackrgba20};
  }
  &.image-cross path {
    fill: ${(props) => props.theme.colors.base.white};
  }
`;

export const Cross = styled.div`
  display: flex;
  justify-content: flex-end;
  .icon {
    cursor: pointer;
    z-index: 999;
  }
`;

export const ContentFooter = styled.footer<{ slim?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${(props) => (props.slim ? "10px" : "24px")};
  position: relative;
  &.space-between {
    justify-content: space-between;
  }
`;

export const ContentHeader = styled.header`
  line-height: 19px;
  margin-bottom: 10px;
  &.header-alignment {
    margin-top: -24px;
    margin-right: 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  align-self: center;
  width: 100%;
`;

export const ContentBody = styled.div`
  word-break: normal;
`;

export const DialogBox = styled.div<any>`
  height: 100%;
  padding: 24px 24px 24px 18px;
  display: flex;
  flex-direction: column;
  box-sizing: inherit;
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow: 0px 16px 24px 0px ${(props) => props.theme.colors.base.blackrgba20};
  &.no-paddings {
    padding: 0;
    position: relative;
    ${Cross} {
      position: absolute;
      top: 20px;
      right: 16px;
    }
    ${ContentHeader} {
      margin-bottom: 0;
      padding: 20px 16px;
    }
    ${ContentFooter} {
      padding: 8px 16px;
      margin-top: 0;
      box-shadow: inset 0 2px 0 0 #e5e7e2;
    }
    ${Wrapper} {
      flex: 1;
      max-height: calc(100% - 64px);
    }
    ${ContentWrapper} {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      height: 100%;
    }
    ${ContentBody} {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 58px);
    }
  }
  &.hint-dialog {
    padding: 0px;
    footer {
      margin: 0px;
      padding: 8px 16px;
      #okButtonTooltip {
        font-size: ${(props) => props.theme.fontSize.small};
        padding: 16px;
      }
    }
  }
  &.hint-dialog-withList {
    footer {
      border-top: 1px solid ${(props) => props.theme.colors.base.blackrgba20};
    }
  }
`;

export const IconWrapper = styled.div`
  flex-basis: 64px;
  width: 64px;
  margin-right: 16px;
  &.inactive path {
    fill: ${(props) => props.theme.colors.base.heavyConcrete40};
  }
  &.warning path {
    fill: ${(props) => props.theme.colors.base.orange};
  }
  &.error path {
    fill: ${(props) => props.theme.colors.base.hiltiRedError};
  }
`;

export const LabelName = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  line-height: 19px;
`;

export const LeftFooterComponent = styled.span`
  text-decoration: underline;
  font-size: ${(props) => props.theme.fontSize.base};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.base.steel10};
  }
`;

export const ConfirmHeading = styled.h5`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  font-size: ${(props) => props.theme.fontSize.medium};
  margin-bottom: 16px;
  &.mt-16 {
    margin-top: 16px;
  }
  &.locked-feature-heading {
    text-align: center;
  }
`;

export const ConfirmInfoText = styled.p``;

export const LabelText = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 17px;
  word-break: break-word;
  -ms-word-break: break-all;
`;

export const LabelStaticText = styled.label`
  color: ${(props) => props.theme.colors.base.steel};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 300;
  line-height: 17px;
  width: 346px;
  text-align: left;
`;

export const IconLabel = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
  }
`;

export const ButtonIconWrapper = styled.div`
  float: right;
  margin-left: 10px;
  &.locked-feature-heading path {
    fill: ${(props) => props.theme.colors.base.white};
  }
`;

export const LinksHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .link {
    padding: 12px 24px;
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
    font-size: ${(props) => props.theme.fontSize.medium};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }
  #secondary-link {
    background-color: ${(props) => props.theme.buttonStyle.secondary.backgroundColor};
    border: 2px solid ${(props) => props.theme.buttonStyle.secondary.borderColor};
    color: ${(props) => props.theme.buttonStyle.secondary.color};
    &:hover {
      background-color: ${(props) => props.theme.buttonStyle.secondary.backgroundHoverColor};
      border: 2px solid ${(props) => props.theme.buttonStyle.secondary.borderColor};
      transition: background-color 0.3s ease;
    }
  }
  #cta-link {
    background-color: ${(props) => props.theme.buttonStyle.cta.backgroundColor};
    border: 2px solid ${(props) => props.theme.buttonStyle.cta.borderColor};
    color: ${(props) => props.theme.buttonStyle.cta.color};
    margin-left: 16px;
    &:hover {
      background-color: ${(props) => props.theme.buttonStyle.cta.backgroundHoverColor};
      border: 2px solid ${(props) => props.theme.buttonStyle.cta.backgroundHoverColor};
      transition: background-color 0.3s ease;
    }
  }
`;
