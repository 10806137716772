// @ts-ignore
import { MasterActions } from "custom-actions";
import { createReducer } from "../../utils/commonUtils";
import { QtyItemAllocationEnum } from "../../utils/enums/qtyItemEnum";
import { IMasterDataState } from "./iMasterDataModuleState";

export const masterDataInitialState: IMasterDataState = {
  allocationStatusList: null,
  allocationStockLevel: null,
  assetStateResponse: null,
  assetStatusResponse: null,
  assetUsageConditionStateResponse: null,
  certificateStatusList: null,
  countryList: null,
  currenciesList: null,
  hiltiAssetStatusList: null,
  languageList: null,
  locationStatesList: null,
  ownerShipTypeResponse: null,
  qtyItemActivityTypeList: null,
  reportTemplateCategoriesList: null,
  rolesList: null,
  serviceStatusList: null,
  scanCodeTypeList: null,
  allocationStatusListWithoutINTRAN: null,
  serviceResultsList: null,
  timeBasedEnabledTenantList: [],
  costTypeResponse: null,
  costCategoryResponse: null,
  showSmartMapBanner: true,
};
const setAssetCodesSuccess = (state, action) => {
  return {
    ...state,
    assetStateResponse: action.payload && action.payload.assetStateResponse,
    assetStatusResponse: action.payload && action.payload.assetStatusResponse,
    ownerShipTypeResponse: action.payload && action.payload.ownerShipTypeResponse,
  };
};
const setAssetUsageConditionSuccess = (state, action) => {
  return {
    ...state,
    assetUsageConditionStateResponse: action.payload && action.payload.assetUsageConditionStateResponse,
  };
};
const setScanCodeTypeSuccess = (state, action) => {
  return {
    ...state,
    scanCodeTypeList: action.payload && action.payload.assetIdentificationMechanismResponses,
  };
};
const setCurrenciesList = (state, action) => {
  return {
    ...state,
    currenciesList: action.payload,
  };
};
const resetRolesList = (state) => {
  return {
    ...state,
    rolesList: null,
  };
};
const setRolesList = (state, action) => {
  return {
    ...state,
    rolesList: action.payload,
  };
};
const resetMasterData = (state) => {
  return {
    ...state,
    ...masterDataInitialState,
  };
};
const setCountryList = (state, action) => {
  return {
    ...state,
    countryList: action.payload,
  };
};
const setLocationStatesList = (state, action) => {
  return {
    ...state,
    locationStatesList: action.payload,
  };
};
const setLanguageList = (state, action) => {
  return {
    ...state,
    languageList: action.payload,
  };
};
const setAllocationStatusList = (state, action) => {
  return {
    ...state,
    allocationStatusList: action.payload,
    allocationStatusListWithoutINTRAN: action.payload.filter((status) => status.code !== QtyItemAllocationEnum.INTRAN),
  };
};
const setReportCategoriesList = (state, action) => {
  return {
    ...state,
    reportTemplateCategoriesList: action.payload,
  };
};
const setAllocationStockLevel = (state, action) => {
  return {
    ...state,
    allocationStockLevel: action.payload,
  };
};
const setServiceStatusList = (state, action) => {
  return {
    ...state,
    serviceStatusList: action.payload,
  };
};
const setServiceResultsList = (state, action) => {
  return {
    ...state,
    serviceResultsList: action.payload,
  };
};
const setCertificateStatusList = (state, action) => {
  return {
    ...state,
    certificateStatusList: action.payload,
  };
};

const setHiltiAssetStatusList = (state, action) => {
  return {
    ...state,
    hiltiAssetStatusList: action.payload,
  };
};

const setQtyItemActivityTypeList = (state, action) => {
  return {
    ...state,
    qtyItemActivityTypeList: action.payload,
  };
};

const setTimeBasedEnabledTenantList = (state, action) => {
  return {
    ...state,
    timeBasedEnabledTenantList: action.payload,
  };
};

const setCostCodesSuccess = (
  state: IMasterDataState,
  action: { payload: { costTypeResponse: unknown; costCategoryResponse: unknown } },
) => {
  return {
    ...state,
    costTypeResponse: action.payload.costTypeResponse,
    costCategoryResponse: action.payload.costCategoryResponse,
  };
};

const showSmartMapBanner = (state, action) => {
  return {
    ...state,
    showSmartMapBanner: action.payload,
  };
};

const masterDataReducer = createReducer(masterDataInitialState, {
  [MasterActions.AM_ASSET_CODES_SUCCESS]: setAssetCodesSuccess,
  [MasterActions.AM_ASSET_USAGE_CONDITION_SUCCESS]: setAssetUsageConditionSuccess,
  [MasterActions.AM_SCAN_CODE_TYPE_SUCCESS]: setScanCodeTypeSuccess,
  [MasterActions.AM_CURRENCIES_SUCCESS]: setCurrenciesList,
  [MasterActions.RESET_ROLES_LIST]: resetRolesList,
  [MasterActions.GET_ROLES_SUCCESS]: setRolesList,
  [MasterActions.RESET_MASTER_DATA]: resetMasterData,
  [MasterActions.GET_COUNTRIES_SUCCESS]: setCountryList,
  [MasterActions.LOCATION_STATES_SUCCESS]: setLocationStatesList,
  [MasterActions.OM_LANGUAGE_RESPONSE]: setLanguageList,
  [MasterActions.ALLOCATION_STATUS_LIST_SUCCESS]: setAllocationStatusList,
  [MasterActions.REPORT_TEMPLATES_CATEGORIES_SUCCESS]: setReportCategoriesList,
  [MasterActions.ALLOCATION_STOCK_LEVEL_SUCCESS]: setAllocationStockLevel,
  [MasterActions.SERVICE_STATUS_LIST_SUCCESS]: setServiceStatusList,
  [MasterActions.SERVICE_RESULTS_LIST_SUCCESS]: setServiceResultsList,
  [MasterActions.CERTIFICATE_STATUS_LIST_SUCCESS]: setCertificateStatusList,
  [MasterActions.HI_GET_HILTI_ASSET_STATUS_LIST_SUCCESS]: setHiltiAssetStatusList,
  [MasterActions.AU_GET_QTY_ITEM_ACTIVITY_TYPE_LIST_SUCCESS]: setQtyItemActivityTypeList,
  [MasterActions.PUT_TIME_BASED_ENABLED_TENANT_LIST]: setTimeBasedEnabledTenantList,
  [MasterActions.ACR_COST_CODE_SUCCESS]: setCostCodesSuccess,
  [MasterActions.AT_UI_SHOW_ONE_MAP_BANNER]: showSmartMapBanner,
});

export default masterDataReducer;
