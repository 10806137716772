import React from "react";
const SVG = () => (
  <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-857.000000, -796.000000)" fill="#524F53">
        <g transform="translate(855.000000, 794.000000)">
          <polygon
            id="🎨Color"
            points="12 17.27 18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
