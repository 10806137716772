import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { keySeparator } from "./types";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: () => "/hilti/locales/messages_{{lng}}.json",
    },
    fallbackLng: "en-US",
    keySeparator,
    load: "currentOnly",
    react: {
      useSuspense: false,
    },
    returnEmptyString: false,
    interpolation: { escapeValue: false },
  });

export default i18n;
