import Grid from "@mui/material/Grid";
import { DetailsPanel } from "am-web-ui-shared/components";
import { TextArea } from "am-web-ui-shared/elements";
import React from "react";
import { withTranslation } from "react-i18next";
import { ValidationConstants } from "../../../../utils/enums/validationConstants";
import { maxLength } from "../../../../utils/errorUtils";
import { IServiceFormProps } from "../iServiceFormProps";

class AdditionalInformationPanel extends React.PureComponent<IServiceFormProps> {
  render() {
    const { t } = this.props;
    return (
      <DetailsPanel panelTitleId="pnlAdditionalInformation" title={t("services:ADDITIONAL_INFORMATION")}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TextArea
              htmlFor="notes"
              isFormControl={true}
              label={t("common:LABEL_NOTES")}
              name="notes"
              validation={[maxLength(t, ValidationConstants.MAX_LENGTH.NUM_1024)]}
            />
          </Grid>
        </Grid>
      </DetailsPanel>
    );
  }
}

export default withTranslation()(AdditionalInformationPanel);
