import React from "react";
const SVG = () => (
  <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2012.000000, -658.000000)" fill="#524F53">
        <g transform="translate(2008.000000, 654.000000)">
          <path
            d="M4,4 L7,4 L7,7 L4,7 L4,4 Z M4,9 L7,9 L7,12 L4,12 L4,9 Z M4,14 L7,14 L7,17 L4,17 L4,14 Z M9,14 L16,14 L13,17 L9,17 L9,14 Z M9,9 L20,9 L20,10 L18,12 L9,12 L9,9 Z M9,4 L20,4 L20,7 L9,7 L9,4 Z M23.2630564,14.0961941 L17.523491,19.8533224 L15.3566989,17.6946881 L21.1668623,12 L23.2630564,14.0961941 Z M14.0957945,19.0710678 L16.1618937,21.137167 L13,22.217914 L14.0957945,19.0710678 Z"
            id="🎨Color"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
