// @ts-ignore
import styled from "custom-styled-components";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { filtersQuery } from "../../../../../utils/commonUtils";
import FilterContainer from "../../../../../components/filter/filterContainer";
import AssetsViewGridFilters from "../models/assetsViewGridFilters";
import { ISmartInventoryFilterProps } from "./iSmartInventoryAssetsFilterProps";
import { AppDispatch, RootState } from "TARGET_BUILD/common/store/store";
import { updateAssetType } from "../../../actions/activeTrackingActionRoot";

const FilterBar = styled.div`
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
  position: relative;
  min-height: 64px;
  .field-wrapper {
    margin-bottom: 0;
  }
`;

const getInventoryFilterValue = (filter) => {
  if (filter) {
    for (const v of filter) {
      if (v?.filterName === "inventoryName") {
        return v?.filterValue[0];
      }
    }
  }
  return false;
};

const SmartInventoryAssetsFilter = (props: ISmartInventoryFilterProps) => {
  const assetType = useSelector(({ ActiveTracking }: RootState) => ActiveTracking?.assetType);
  const [inventoryName] = useState([{ inventoryName: [assetType] }]);
  const { id, setFilterAppliedAttributes, getFilterAttributes, getList, setFilterQuery } = props;

  const dispatch: AppDispatch = useDispatch();

  const handleFilterChange = useCallback(
    (filter: any, currentFilter: any = null) => {
      const type = getInventoryFilterValue(filter);
      if (type) {
        dispatch(updateAssetType(type));
      }
      const filterQuery = filtersQuery(filter, currentFilter);
      setFilterAppliedAttributes(filter);
      getFilterAttributes(id, type, filterQuery);
      setFilterQuery({
        currentSelectedFilter: currentFilter,
        filters: filter,
      });
      getList({ id, statsType: type });
    },
    [id],
  );

  return (
    <FilterBar>
      <FilterContainer
        refreshFilters={handleFilterChange}
        filterSettings={AssetsViewGridFilters}
        filterAttributes={props.filterAttributes}
        filterFormName="gatewayFilter"
        appliedFilterQuery={props.appliedFilterQuery}
        filterFormValues={props.filterFormValues}
        changeFilterFormValues={props.changeFilterFormValues}
        selectedFilters={inventoryName}
      />
    </FilterBar>
  );
};

export default React.memo(SmartInventoryAssetsFilter);
