import { SquareIcon } from "am-web-ui-shared/components";
import React from "react";
import { StyledTextType } from "../../../../utils/enums/styledEnum";
import IIconNormalBoldProps from "./iIconNormalBold";

import {
  StyledBeforeIconContainer,
  StyledBoldText,
  StyledInnerBoldText,
  StyledNormalText,
  StyledRowsWrapper,
} from "../../index";

/**
 * @description - Styled Component that consist of 3 things:
 * 1. Icon: An Icon that will be visible at the left side of the content (::before).
 * 2. Normal Text: This is the text with normal font-familiy and it is the top row.
 * 3. Bold Text: This is the text with bold font-familiy and it is the bottom row.
 *
 * IDs and Texts for the rows are configurable from the component using this component.
 * @const IconNormalBold
 * @interface IIconNormalBoldProps
 */
const IconNormalBold: React.FC<IIconNormalBoldProps> = (props: IIconNormalBoldProps) => {
  const {
    id,
    iconUrl,
    normalId,
    normalText,
    boldId,
    boldText,
    type = StyledTextType.INNER_TEXT,
    withoutIcon = false,
    children,
  } = props;
  return (
    <StyledBeforeIconContainer id={id}>
      {withoutIcon ? null : <SquareIcon data-testid="squareIcon" name={iconUrl} className="mr-16" />}
      <StyledRowsWrapper>
        <StyledNormalText id={normalId}>{normalText}</StyledNormalText>
        {type === StyledTextType.INNER_TEXT ? (
          <StyledInnerBoldText data-testid="styledInnerBoldText" title={boldText} id={boldId}>
            {boldText}
          </StyledInnerBoldText>
        ) : (
          <StyledBoldText data-testid="styledBoldText" title={boldText} id={boldId}>
            {boldText}
          </StyledBoldText>
        )}
      </StyledRowsWrapper>
      {children}
    </StyledBeforeIconContainer>
  );
};

/**
 * @export IconNormalBold - Styled Component.
 */
export default IconNormalBold;
