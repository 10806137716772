import React from "react";
const SVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <title>ic_Battery</title>
    <g stroke="none" fill="#524F53">
      <path d="M10,2 L14,2 L14,4 L18,4 L18,22 L6,22 L6,4 L10,4 L10,2 Z"></path>
    </g>
  </svg>
);

export default SVG;
