import React from "react";
const SVG = () => (
  <svg width="16px" height="18px" viewBox="0 0 16 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-859.000000, -882.000000)" fill="#524F53">
        <g transform="translate(855.000000, 878.000000)">
          <polygon id="Path" points="14 12.0920485 14 19 9.94974747 22 10 12.0920485 4 4 20 4" />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
