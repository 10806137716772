import React from "react";
import { Field } from "redux-form";
import DateRangePicker from "./dateRangePicker";

interface IDateRangePickerProps {
  readonly name: string;
  readonly minDate?: Date;
  readonly maxDate?: Date;
  readonly startDate?: Date;
  readonly endDate?: Date;
  readonly onSelectionChange?: (
    selectedValue: string,
    startDate: Date,
    endDate: Date,
    count: number,
    label: string,
  ) => void;
  readonly placeholder?: string;
  readonly dateFormat: string;
  readonly apiFormat?: string;
  readonly label?: string;
  readonly clearButtonText: string;
  readonly inputFocus?: () => void;
  readonly disableFocus?: () => void;
  readonly endDateLabel: string;
  readonly startDateLabel: string;
  readonly locale: string;
}

const DateRangePickerForm = (props: IDateRangePickerProps) => {
  return (
    <Field
      name={props.name}
      placeholder={props.placeholder}
      dateFormat={props.dateFormat}
      apiFormat={props.apiFormat}
      component={DateRangePicker}
      onSelectChange={props.onSelectionChange}
      label={props.label}
      clearButtonText={props.clearButtonText}
      endDateLabel={props.endDateLabel}
      startDateLabel={props.startDateLabel}
      minDate={props.minDate}
      maxDate={props.maxDate}
      locale={props.locale}
      {...props} // cannot avoid as there are many props from react field control to be passed down the component
    />
  );
};

export default DateRangePickerForm;
